import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { Thunk } from 'editor/src/store/hooks';
import getAllMatchingVariations from 'editor/src/store/variants/helpers/getAllMatchingVariations';
import changeEcommerceVariantPageCountOperation from 'editor/src/store/variants/operation/changeEcommerceVariantPageCountOperation';
import checkForMissingTemplatesOperation from 'editor/src/store/variants/operation/checkForMissingTemplatesOperation';
import { setPageCountAction } from 'editor/src/store/variants/slice';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import { removeSpreadAction } from '../../slice';

const removeSpreadOperation =
  (spreadIndex: number): Thunk =>
  (dispatch, getState) => {
    batch(() => {
      dispatch(saveUndoRedoStateOperation('remove spread'));
      dispatch(removeSpreadAction(spreadIndex));
      let state = getState();
      if (state.design.designData?.page_count) {
        dispatch(setPageCountAction(state.design.designData.page_count));
        dispatch(changeEcommerceVariantPageCountOperation(state.design.designData.page_count));
        sendPostMessage('design.switchToProduct', {
          productUid: state.design.designData.product_uid,
          pageCount: state.design.designData.page_count,
        });

        state = getState();
        const { product, configuration, selectedMultiOptions, selectedSingleOptions, replaceControlKeys } =
          state.variants;
        if (!configuration.singleSelection) {
          const matchingVariations = getAllMatchingVariations(
            product.productVariations,
            selectedMultiOptions,
            selectedSingleOptions,
            false,
            replaceControlKeys,
          );
          dispatch(checkForMissingTemplatesOperation(matchingVariations));
        }
      }
    });
  };

export default removeSpreadOperation;

import React from 'react';

import { Coords, MediaImage } from 'editor/src/store/design/types';
import { DraggableItem } from 'editor/src/store/editor/types';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import useCanvasRotation from 'editor/src/component/EditorArea/useCanvasRotation';

import { DRAG_STROKE_COLOR } from './Content';
import useDropZone from './useDropZone';

interface Props {
  element: MediaImage;
  pageCoords: Coords;
  spreadCoords: Coords;
  relativeZIndex: number;
  onDrop: (item: DraggableItem) => void;
  canvasRotation: CanvasRotation;
}

function DropZone({ element, pageCoords, spreadCoords, onDrop, relativeZIndex, canvasRotation }: Props) {
  const { mm2px } = useFabricUtils();
  const { events, isDraggingOver, isDraggingActive } = useDropZone(
    spreadCoords,
    onDrop,
    true,
    element.uuid,
    canvasRotation,
  );
  const rotatedPoint = useCanvasRotation(
    canvasRotation,
    pageCoords.left + mm2px(element.x || 0),
    pageCoords.top + mm2px(element.y || 0),
  );

  if (!isDraggingActive) {
    return null;
  }

  return (
    <FabricRectComponent
      angle={Number(element.r) + canvasRotation.angleDeg}
      left={rotatedPoint.x}
      top={rotatedPoint.y}
      height={mm2px(element.height)}
      width={mm2px(element.width)}
      zIndex={zIndex.DROP_BOX + relativeZIndex}
      fill="transparent"
      selectable={false}
      stroke={DRAG_STROKE_COLOR}
      strokeWidth={isDraggingOver ? 2 : 0}
      events={events}
      objectCaching={false}
    />
  );
}

export default React.memo(DropZone);

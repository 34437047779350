import { CanShow } from 'editor/src/component/Menu/type';

import ButtonLineEdge1 from './ButtonLineEdge1';
import ButtonLineEdge1DropDown from './ButtonLineEdge1DropDown';

export const canShow: CanShow = (state, { isPersonalizationLocked, hasSelection }) =>
  hasSelection && !isPersonalizationLocked;

export { ButtonLineEdge1, ButtonLineEdge1DropDown };

export default ButtonLineEdge1;

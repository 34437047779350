/* eslint-disable react/jsx-props-no-spreading */
import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PluginIcon } from 'editor/src/store/plugins/selector/getPluginIconByLevel';

import ControlButton from 'editor/src/component/ControlButton';
import { ButtonMode } from 'editor/src/component/Menu/type';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './MenuButton.module.scss';

export interface Props<IP> {
  Icon?: React.FC<{ className?: string } & Omit<IP, 'className'>>;
  iconProps?: IP;
  i18nKey?: string;
  label?: React.ReactNode;
  className?: string;
  mode: ButtonMode;
  disabled?: boolean;
  on?: boolean;
  handleClick: () => void;
  premium?: boolean;
  showTooltip?: boolean;
  PremiumIcon?: PluginIcon;
}

function MenuButton<T>({
  Icon,
  i18nKey,
  label,
  mode,
  className,
  handleClick,
  disabled,
  on,
  iconProps,
  premium,
  showTooltip = true,
  PremiumIcon,
}: Props<T>) {
  const { t } = useTranslation();

  const props = iconProps || ({} as T);
  const modeCn = useMemo(() => {
    if (mode === 'label-icon') {
      return 'labelIcon';
    }
    if (mode === 'label-icon-vertical') {
      return 'labelIconVertical';
    }
    return mode;
  }, [mode]);

  const button = (
    <ControlButton
      disabled={disabled}
      on={on}
      onClick={handleClick}
      className={cn(styles.button, styles[modeCn], className)}
    >
      {(mode === 'icon' || mode === 'label-icon' || mode === 'label-icon-vertical') && Icon && (
        <div className={styles.iconContainer}>
          <Icon className={styles.icon} {...props} />
          {premium && PremiumIcon && (
            <div className={styles.iconPremiumBox}>
              <PremiumIcon className={styles.iconPremium} />
            </div>
          )}
        </div>
      )}
      {(mode === 'label' || mode === 'label-icon' || mode === 'label-icon-vertical') && (
        <div className={styles.label}>{i18nKey ? t(i18nKey) : label}</div>
      )}
    </ControlButton>
  );

  if (mode === 'icon' && showTooltip) {
    return <WithTooltip overlay={i18nKey ? t(i18nKey) : label}>{button}</WithTooltip>;
  }

  return button;
}

export default React.memo(MenuButton);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconRedo({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M16.375 31.667C13.7363 31.667 11.4587 30.785 9.54199 29.021C7.62533 27.257 6.66699 25.0693 6.66699 22.458C6.66699 19.8473 7.62533 17.653 9.54199 15.875C11.4587 14.097 13.7363 13.208 16.375 13.208H28.042L24.417 9.583C24.139 9.30567 24 8.97933 24 8.604C24 8.22933 24.139 7.903 24.417 7.625C24.6943 7.375 25.0207 7.25 25.396 7.25C25.7707 7.25 26.097 7.375 26.375 7.625L32.375 13.625C32.5137 13.7917 32.611 13.9513 32.667 14.104C32.7223 14.2567 32.75 14.4303 32.75 14.625C32.75 14.7917 32.7223 14.9583 32.667 15.125C32.611 15.2917 32.5137 15.4443 32.375 15.583L26.375 21.583C26.097 21.861 25.7707 22 25.396 22C25.0207 22 24.6943 21.861 24.417 21.583C24.139 21.3057 24 20.9793 24 20.604C24 20.2293 24.139 19.903 24.417 19.625L28.042 16H16.375C14.4863 16 12.8613 16.618 11.5 17.854C10.1387 19.09 9.45799 20.6247 9.45799 22.458C9.45799 24.2913 10.1387 25.8193 11.5 27.042C12.8613 28.264 14.4863 28.875 16.375 28.875H26.958C27.3473 28.875 27.674 29.007 27.938 29.271C28.2013 29.535 28.333 29.8753 28.333 30.292C28.333 30.6807 28.2013 31.007 27.938 31.271C27.674 31.535 27.3473 31.667 26.958 31.667H16.375Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconRedo;

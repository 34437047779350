import { captureException } from '@sentry/react';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import getDesignDataForExport from 'editor/src/store/design/selector/getDesignDataForExport';
import getVariantWarnings from 'editor/src/store/editorModules/warnings/selector/getVariantWarnings';
import { setVariantWarningsAction } from 'editor/src/store/editorModules/warnings/slice';
import calculateDesignDataWarnings from 'editor/src/store/editorModules/warnings/utils/calculateDesignDataWarnings';
import {
  extendedWarningsToExportedWarnings,
  extendedWarningsToWarnings,
} from 'editor/src/store/editorModules/warnings/utils/warningMapper';
import getGalleryImages from 'editor/src/store/gallery/selector/getGalleryImages';
import { useStore, useDispatch, useSelector } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getProducts from 'editor/src/store/variants/selector/getProducts';
import { ExportVariationGroup, VariationGroup } from 'editor/src/store/variants/types';

import formatDesignDataForLogging from 'editor/src/util/formatDesignDataForLogging';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

function useVariantWatcher() {
  const store = useStore();
  const dispatch = useDispatch();
  const { variationGroups, isVariantFlow, enabledWarnings, designOptionsControl } = useSelector(
    (state) => ({
      isVariantFlow: state.variants.isVariantFlow,
      variationGroups: state.variants.variationGroups,
      enabledWarnings: state.hostSettings.enabledWarnings,
      designOptionsControl: state.variants.designOptionsControl,
    }),
    shallowEqual,
  );

  const products = useSelector(getProducts);
  const selectedProductId = useSelector((state) => state.variants.selectedProductId);

  const productsVariationGroups = useMemo(() => {
    return !products
      ? undefined
      : Object.entries(products).reduce<{ [productId: string]: VariationGroup[] }>(
          (multipleVariationGroups, [productId, productData]) => {
            multipleVariationGroups[productId] = productData.variantGroups;
            return multipleVariationGroups;
          },
          {},
        );
  }, [products]);

  const galleryInitialized = useSelector((state) => getGalleryImages(state).length > 0);

  const getExportVariationGroups = useCallback(
    (variationGroups: VariationGroup[], state: RootState, updateWarnings = true) => {
      let hasAllDesignData = true;
      const { images } = state.gallery;
      const addons = state.editorModules.addons.inUse;

      const exportedVariationGroups = variationGroups.map((group) => ({
        title: group.title,
        linked: group.linked,
        variations: group.variationsInfo.map(({ variation, designData }) => {
          if (!designData) {
            hasAllDesignData = false;
          }

          if (designData && variation.productUid !== designData.product_uid) {
            captureException(new Error('Product UID mismatch'), {
              extra: {
                variation,
                designData: formatDesignDataForLogging(designData),
              },
            });
          }

          const extendedWarnings = designData ? calculateDesignDataWarnings(designData, state) : [];
          if (updateWarnings) {
            const warnings = extendedWarningsToWarnings(extendedWarnings);
            const warningsChanged = !isEqual(warnings, getVariantWarnings(state, variation.productUid));
            if (warningsChanged) {
              dispatch(
                setVariantWarningsAction({
                  productId: variation.productUid,
                  warnings,
                }),
              );
            }
          }

          const exportDesign = designData
            ? getDesignDataForExport(designData, images, addons, extendedWarningsToExportedWarnings(extendedWarnings))
            : undefined;

          return {
            productUid: variation.productUid,
            designDataJSON: exportDesign ? JSON.stringify(exportDesign) : undefined,
            dimensions: designData?.related_dimensions || designData?.dimensions,
            pageCount: designData?.page_count_limit ? designData?.page_count : undefined,
          };
        }),
        designOptions: group.designOptions,
      }));

      return {
        exportedVariationGroups,
        hasAllDesignData,
      };
    },
    [],
  );

  useEffect(() => {
    // single product flow
    if (!isVariantFlow || (productsVariationGroups && Object.keys(productsVariationGroups).length)) {
      return;
    }

    const state = store.getState();
    const { exportedVariationGroups, hasAllDesignData } = getExportVariationGroups(variationGroups, state);

    if (hasAllDesignData && exportedVariationGroups.length > 0) {
      sendPostMessage('variants.variationGroups', exportedVariationGroups);
    }
  }, [isVariantFlow, variationGroups, enabledWarnings, galleryInitialized, productsVariationGroups]);

  useEffect(() => {
    if (!isVariantFlow) {
      return;
    }

    sendPostMessage('variants.designOptionControls', designOptionsControl ?? []);
  }, [isVariantFlow, designOptionsControl]);

  useEffect(() => {
    // multiple flow
    if (!isVariantFlow || !productsVariationGroups) {
      return;
    }

    const state = store.getState();
    let productsHasAllDesignData = true;

    const allProductsExportGroups = Object.entries(productsVariationGroups).reduce<{
      [productId: string]: Array<ExportVariationGroup>;
    }>((productsExportGroups, [productId, productItemVariationGroups]) => {
      const { exportedVariationGroups, hasAllDesignData } =
        productId !== selectedProductId
          ? getExportVariationGroups(productItemVariationGroups, state, false)
          : getExportVariationGroups(variationGroups, state, true);

      productsHasAllDesignData = productsHasAllDesignData && hasAllDesignData;
      productsExportGroups[productId] = exportedVariationGroups as ExportVariationGroup[];
      return productsExportGroups;
    }, {});

    if (productsHasAllDesignData && Object.keys(allProductsExportGroups).length) {
      sendPostMessage('variants.multipleVariationGroups', allProductsExportGroups);
    }
  }, [isVariantFlow, productsVariationGroups, selectedProductId, variationGroups, galleryInitialized, enabledWarnings]);
}

export default useVariantWatcher;

import cn from 'classnames';
import React from 'react';

import noop from 'editor/src/util/noop';

import styles from './index.module.scss';

interface Props {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}
type Context = { close: () => void };
export const MenuItemContext = React.createContext<Context>({ close: noop });

function SettingsMenuItem({ children, className, onClick }: Props) {
  const itemContext = React.useContext(MenuItemContext);

  function handleClick(e: React.MouseEvent) {
    e.stopPropagation();
    itemContext.close();
    onClick();
  }

  return (
    <button onClick={handleClick} className={cn(styles.SettingsMenuItem, className)}>
      {children}
    </button>
  );
}

export default SettingsMenuItem;

import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import limitPrecision from 'editor/src/util/limitPrecision';

import styles from './index.module.scss';

export interface Props {
  min: number;
  max: number;
  step: number;
  value: number;
  startPoint?: number;
  className?: string;
  disabled?: boolean;
  getHandleStyle?: (value: number) => React.CSSProperties;
  railStyle?: React.CSSProperties;
  hideTrack?: boolean;
  hideHandle?: boolean;
  onChange: (value: number) => void;
  onBeforeChange?: (value: number) => void;
  onAfterChange?: (value: number) => void;
}

function getPrecision(a: number) {
  if (!Number.isFinite(a)) {
    return 0;
  }
  let e = 1;
  let p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p += 1;
  }
  return p;
}

function findClosestStep(value: number, min: number, step: number, precision: number) {
  const halfStep = step / 2;
  let n = min;
  while (n < value + step) {
    if (value - n < halfStep) {
      break;
    }
    n += step;
  }

  // float addition in JS has some precision issues
  // try b = 1.1, then b += 0.1, you get: 1.2000000000000002 instead of 1.2
  return limitPrecision(n, precision + 1);
}

function Slider({
  min,
  max,
  step,
  value,
  startPoint = min,
  disabled,
  className,
  railStyle,
  getHandleStyle,
  hideTrack,
  hideHandle,
  onChange,
  onBeforeChange,
  onAfterChange,
}: Props) {
  const divRailRef = useRef<HTMLDivElement>(null);
  const [currentValue, setCurrentValue] = useState(value);
  const lastValue = useRef(value);
  const precision = useMemo(() => getPrecision(step), [step]);

  useEffect(() => {
    setCurrentValue(value);
    lastValue.current = value;
  }, [value]);

  function getValue(x: number, left: number, width: number) {
    const pos = (x - left) / width;
    const newValue = pos * (max - min);
    const stepValue = findClosestStep(newValue + min, min, step, precision);
    return Math.max(min, Math.min(stepValue, max));
  }

  function onPointerStart(x: number) {
    setDown(true);
    onBeforeChange?.(value);

    const bbox = divRailRef.current?.getBoundingClientRect();
    offsetX.current = 0;
    const newValue = getValue(x, bbox?.left || 0, bbox?.width || 1);
    if (lastValue.current !== newValue) {
      setCurrentValue(newValue);
      onChange(newValue);
      lastValue.current = newValue;
    }
  }

  function onRailMouseDown(e: React.MouseEvent) {
    onPointerStart(e.clientX);
  }

  function onRailTouchStart(e: React.TouchEvent) {
    onPointerStart(e.touches[0].clientX);
  }

  const offsetX = useRef(0);
  const [down, setDown] = useState(false);
  function onHandleMouseDown(e: React.MouseEvent) {
    e.stopPropagation();
    const { left, width } = (e.target as HTMLDivElement).getBoundingClientRect();
    offsetX.current = left + width / 2 - e.clientX;
    e.preventDefault();
    setDown(true);
    onBeforeChange?.(value);
  }

  useEffect(() => {
    if (!down || !divRailRef.current) {
      return undefined;
    }

    const bbox = divRailRef.current.getBoundingClientRect();

    function onMove(x: number) {
      const newValue = getValue(x + offsetX.current, bbox.left, bbox.width);
      if (lastValue.current !== newValue) {
        setCurrentValue(newValue);
        onChange(newValue);
        lastValue.current = newValue;
      }
    }

    function onMouseMove(e: MouseEvent) {
      e.preventDefault();
      onMove(e.clientX);
    }

    function onTouchMove(e: TouchEvent) {
      e.preventDefault();
      onMove(e.touches[0].clientX);
    }

    function onMoveEnd(e: MouseEvent | TouchEvent) {
      e.preventDefault();
      onAfterChange?.(lastValue.current);
      setDown(false);
    }

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMoveEnd);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchend', onMoveEnd);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMoveEnd);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onMoveEnd);
    };
  }, [down, onChange, onAfterChange]);

  const handleLeft = ((currentValue - min) / (max - min)) * 100;
  const trackScaleX = Math.abs(currentValue - startPoint) / (max - min);
  const trackTranslateX = currentValue > startPoint ? ((startPoint - min) / (max - min)) * 100 : handleLeft;

  return (
    <div
      className={cn(styles.Slider, className, {
        [styles.disabled]: disabled,
        [styles.down]: down,
      })}
      onMouseDown={onRailMouseDown}
      onTouchStart={onRailTouchStart}
    >
      <div className={cn(styles.rail, 'cy-slider')} style={railStyle} ref={divRailRef}>
        {!hideTrack && (
          <div
            className={styles.track}
            style={{
              transform: `translateX(${trackTranslateX}%) scaleX(${trackScaleX})`,
            }}
          />
        )}
        <div
          onMouseDown={onHandleMouseDown}
          className={cn(styles.handle, 'cy-handle')}
          style={{
            ...(getHandleStyle?.(currentValue) ?? {}),
            left: `${handleLeft}%`,
            display: hideHandle ? 'none' : '',
          }}
        />
      </div>
    </div>
  );
}

export default React.memo(Slider);

import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconProducts from 'editor/src/component/Icon/IconProducts';
import useMobileMenu from 'editor/src/component/useMobileMenu';

import ProductTabContent from '../../../TabContents/ProductTabContent';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
  active: boolean;
}

function MobileProductTab({ pluginName, customTabInfo, active }: Props) {
  const { t } = useTranslation();
  const activeTab = useSelector(getSidebarActiveTab);
  const menu = useMobileMenu(false, true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeTab === TAB_NAMES.PRODUCT) {
      // this is used when host wants to decide the active tab
      // we need to wait for the UI to be loaded
      const handle = window.setTimeout(() => {
        menu.open();
      }, 100);

      return () => window.clearTimeout(handle);
    }

    menu.close();
    return undefined;
  }, [activeTab]);

  const onMobileClick = () => {
    menu.open();
  };

  const closeMenu = useCallback(() => {
    menu.close();
    dispatch(setSidebarActiveTabOperation(-1));
  }, []);

  return (
    <>
      <Tab
        key={TAB_NAMES.PRODUCT}
        name={TAB_NAMES.PRODUCT}
        title={t('editor-product')}
        icon={<IconProducts active={active} />}
        pluginName={pluginName}
        onMobileClick={onMobileClick}
        customTabInfo={customTabInfo}
      />
      {menu.render(<ProductTabContent closeMenuCb={closeMenu} />)}
    </>
  );
}

export default React.memo(MobileProductTab);

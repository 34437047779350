import getSpread from 'editor/src/store/design/selector/getSpread';
import { MediaElement } from 'editor/src/store/design/types';
import getFocusedContent from 'editor/src/store/editor/selector/getFocusedContent';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import { RootState } from 'editor/src/store/index';

import canvasService from 'editor/src/service/canvasService';
import { findCanvasAreaAverageColor, getPerceivedBrightness } from 'editor/src/util/color';
import RGBA from 'editor/src/util/color/rgbaUtils';

import colors from 'editor/src/component/ColorPicker/colors';

const BLACK = '#000000';
const WHITE = '#FFFFFF';

const BRIGHT_THRESHOLD = 50;

function isBright(brightness: number) {
  return brightness >= BRIGHT_THRESHOLD;
}

function getVisibleColorAtElementPosition(element: MediaElement, spreadIndex: number, state: RootState) {
  const spread = getSpread(state, spreadIndex);
  const availableColors = getHostSetting(state, 'colorPickerConfig')?.allowedColors || colors;
  const { dark: darkColor, bright: brightColor } = getDarkAndBrightColors(availableColors);

  if (!spread) {
    return darkColor;
  }
  const content = getFocusedContent(state);

  const elementBbox = canvasService.getElementCanvasBbox(element, spread, content?.rotate ?? 0);
  const canvas = canvasService.getHTMLCanvas();
  if (!canvas || !elementBbox) {
    return darkColor;
  }

  const averageColor = findCanvasAreaAverageColor(
    canvas,
    elementBbox.x,
    elementBbox.y,
    elementBbox.width,
    elementBbox.height,
  );
  const brightness = getPerceivedBrightness(averageColor);
  return isBright(brightness) ? darkColor : brightColor;
}

export const getDarkAndBrightColors = (availableColors: string[]): { bright: string; dark: string } => {
  if (!availableColors.length) {
    return { dark: BLACK, bright: WHITE };
  }

  const isBlackAvailable = availableColors.some((color) => color.toUpperCase() === BLACK);
  const isWhiteAvailable = availableColors.some((color) => color.toUpperCase() === WHITE);
  let brightnessIndexes: { hex: string; brightness: number }[] = [];
  if (!isBlackAvailable || !isWhiteAvailable) {
    brightnessIndexes = availableColors
      .map((color) => {
        const rgbaColor = RGBA.fromHex(color);
        return {
          hex: color,
          brightness: getPerceivedBrightness(rgbaColor ? [rgbaColor.r, rgbaColor.g, rgbaColor.b] : [255, 255, 255]),
        };
      })
      .sort((indexA, indexB) => indexA.brightness - indexB.brightness);
  }

  return {
    dark: isBlackAvailable ? BLACK : brightnessIndexes[0].hex,
    bright: isWhiteAvailable ? WHITE : brightnessIndexes[brightnessIndexes.length - 1].hex,
  };
};

export default getVisibleColorAtElementPosition;

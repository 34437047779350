import React from 'react';

import { MediaAddon, MediaImage, StructureIndex } from 'editor/src/store/design/types';

import ElementName from 'editor/src/component/PersonalizationContent/PersonalizationElements/ElementName';

import styles from './index.module.scss';

interface Props {
  element: MediaImage | MediaAddon;
  address: StructureIndex;
}
function ImageLayer({ element, address }: Props) {
  return (
    <ElementName
      element={element}
      address={address}
      ImageElement={<img src={element.url} alt={element.name} className={styles.img} />}
      isNameEditable={false}
      isBuilding
      className={styles.elementContainer}
    />
  );
}

export default React.memo(ImageLayer);

import React from 'react';

import stretchToFitOperation from 'editor/src/store/design/operation/distribute/stretchToFitOperation';
import { useDispatch } from 'editor/src/store/hooks';

import IconTick from 'editor/src/component/Icon/IconTick';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import { canShow as canShowCropButton } from './ButtonCrop';
import MenuButton from './MenuButton';

export const canShow: CanShow = (state, context) => {
  const { hasSelection, isSelectionLocked } = context;
  // we check if crop button can be shown, because stretch to fit is only available when crop is available
  return !isSelectionLocked && hasSelection && canShowCropButton(state, context);
};
export const canShowInTopBar: CanShow = (state, menuContext) =>
  !state.hostSettings.editImageFlag && canShow(state, menuContext);

type Props = Pick<MenuItemProps, 'mode' | 'context'>;

function ButtonStretchToFit({ mode, context }: Props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(stretchToFitOperation(context.structureIndexes[0]));
  };

  return (
    <MenuButton
      Icon={IconTick}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-stretch-to-fit"
      className="cy-button-stretch-to-fit"
    />
  );
}

export default React.memo(ButtonStretchToFit);

import React from 'react';

import { TopMenuDropdown } from 'editor/src/store/app/types';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { useSelector } from 'editor/src/store/hooks';

import IconStrokeWidth from 'editor/src/component/Icon/IconStrokeWidth';
import LineStylesControl from 'editor/src/component/LineStylesControl';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import ButtonWithDropdownNotWrapped from 'editor/src/component/TopMenuDesktop/buttons/ButtonWithDropdownNotWrapped';
import MenuDropDownButton from 'editor/src/component/TopMenuDesktop/buttons/MenuDropDownButton';

function ButtonLineStylesDropDown({ mode, context }: MenuItemProps) {
  const selectedElement = useSelector((state) =>
    context.structureIndexes[0] ? getMediaElement(state, context.structureIndexes[0]) : undefined,
  );
  if (selectedElement?.type !== 'line') {
    return null;
  }

  return (
    <ButtonWithDropdownNotWrapped
      dropDownName={TopMenuDropdown.LineStyles}
      buttonExtraClass="cy-button-line-styles"
      dropDownContent={<LineStylesControl selectedElement={selectedElement} />}
      buttonContent={<MenuDropDownButton Icon={IconStrokeWidth} mode={mode} i18nKey="Line Styles" />}
    />
  );
}

export default React.memo(ButtonLineStylesDropDown);

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import { getRectangleBoundingBox } from 'editor/src/util/reflectDesignData/utils';

import getFocusBox from './getFocusBox';

const alignMediaToSpreadTopOperation =
  (elementAddress: ElementAddress) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const element = getMediaElement(state, elementAddress);
    if (!element) {
      return;
    }

    const focusBox = getFocusBox(state, elementAddress.spreadIndex);

    if (element.type === 'line') {
      const highestY = Math.min(element.y1, element.y2);
      const yOffset = focusBox.y - highestY;
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: { y1: element.y1 + yOffset, y2: element.y2 + yOffset },
        }),
      );
    } else {
      const elementBox = getRectangleBoundingBox(element.x, element.y, element.width, element.height, element.r);
      const yOffset = focusBox.y - elementBox.y;
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: { y: element.y + yOffset },
        }),
      );
    }
  };

export default alignMediaToSpreadTopOperation;

import cn from 'classnames';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import isMobileSelector from 'editor/src/store/app/selector/isMobile';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import applyImageOperation from 'editor/src/store/editor/operation/applyImageOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getAddonsCategory from 'editor/src/store/editorModules/addons/selector/getAddonsCategory';
import getAddonsElements from 'editor/src/store/editorModules/addons/selector/getAddonsElements';
import { AddonType } from 'editor/src/store/editorModules/addons/types';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import isPremiumIconVisible from 'editor/src/store/plugins/selector/isPremiumIconVisible';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import AddonsListItem, { VectorOrBitmap } from './AddonListItem';
import AddonsListItemCategory from './AddonListItemCategory';
import useAddonsInUse from './useAddonsInUse';
import useCategoriesObserver from './useCategoriesObserver';

import styles from './index.module.scss';

function AddonsList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const addonsCategory = useSelector(getAddonsCategory);
  const addonsElements = useSelector(getAddonsElements);
  const isMobile = useSelector(isMobileSelector);
  const spreadIndex = useSelector(getCurrentSpreadIndex);
  const pluginDisabled = useSelector(
    (state) => getPlugin(state, PluginName.Graphics)?.state === PluginState.NON_FUNCTIONAL,
  );
  const premiumIconVisible = useSelector((state) => isPremiumIconVisible(state, PluginName.Graphics));

  const addonInUse = useAddonsInUse();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const categories = addonsCategory?.subcategories?.filter((elt) => elt.elements.length > 0) || [];
  const { observer, visibleCategories } = useCategoriesObserver(wrapperRef, categories.length);

  const addAddon = useCallback(
    (addonId: string) => {
      if (pluginDisabled) {
        sendPostMessage('plugins.disabledPluginClick', {
          name: PluginName.Graphics,
        });
        return;
      }

      batch(() => {
        if (isMobile) {
          dispatch(setSidebarActiveTabOperation(-1));
        }
        dispatch(applyImageOperation(addonId, 'addon'));
      });
    },
    [spreadIndex, isMobile, pluginDisabled],
  );

  if (Array.isArray(addonsElements) && addonsElements?.length) {
    return (
      <div className={styles.addonsList}>
        {!!addonsElements?.length && (
          <div className={cn(styles.section, 'cy-addons-search')}>
            <h3 className={styles.header}>{t('editor-search-results')}</h3>
            <div className={styles.list}>
              {addonsElements.map((addon) => {
                switch (addon.type) {
                  case AddonType.ADDON_VECTOR:
                  case AddonType.ADDON_BITMAP:
                    return (
                      <AddonsListItem
                        addAddon={addAddon}
                        isMobile={isMobile}
                        addon={addon as VectorOrBitmap}
                        key={addon.id}
                        usage={addonInUse.get(addon) ?? 0}
                        visible
                        pluginDisabled={pluginDisabled}
                        showPremiumIcon={pluginDisabled || premiumIconVisible}
                      />
                    );
                  case AddonType.ADDON_DESIGN:
                    return null;
                  default:
                    return null;
                }
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  if (Array.isArray(addonsElements)) {
    return <div className={cn(styles.noResults, 'cy-addons-no-results')}>{t('editor-no-results')}</div>;
  }

  return (
    <div className={styles.addonsList} ref={wrapperRef}>
      {addonInUse.size > 0 && (
        <div className={cn(styles.section, 'cy-addons-in-use')}>
          <h3 className={styles.header}>{t('editor-in-use')}</h3>
          <div className={styles.list}>
            {Array.from(addonInUse).map(([addon, usage]) => {
              switch (addon.type) {
                case AddonType.ADDON_VECTOR:
                case AddonType.ADDON_BITMAP:
                  return (
                    <AddonsListItem
                      addAddon={addAddon}
                      isMobile={isMobile}
                      addon={addon as VectorOrBitmap}
                      key={addon.id}
                      usage={usage}
                      visible
                      showPremiumIcon={pluginDisabled || premiumIconVisible}
                      pluginDisabled={pluginDisabled}
                    />
                  );
                case AddonType.ADDON_DESIGN:
                  return null;
                default:
                  return null;
              }
            })}
          </div>
        </div>
      )}
      {categories.map((subcategory, i) => (
        <div className={cn(styles.section, 'cy-addons-categories')} key={subcategory.id}>
          <AddonsListItemCategory
            addAddon={addAddon}
            isMobile={isMobile}
            category={subcategory}
            addonInUse={addonInUse}
            observer={observer}
            visible={visibleCategories.has(i)}
            index={i}
            showPremiumIcon={pluginDisabled || premiumIconVisible}
            pluginDisabled={pluginDisabled}
          />
        </div>
      ))}
      {(addonsCategory?.elements.length || 0) > 0 && (
        <div className={cn(styles.section, 'cy-addons-category')}>
          <div className={styles.list}>
            {addonsCategory?.elements.map((addon) => {
              switch (addon.type) {
                case AddonType.ADDON_VECTOR:
                case AddonType.ADDON_BITMAP:
                  return (
                    <AddonsListItem
                      visible
                      addAddon={addAddon}
                      isMobile={isMobile}
                      addon={addon as VectorOrBitmap}
                      key={addon.id}
                      usage={addonInUse.get(addon) ?? 0}
                      showPremiumIcon={pluginDisabled || premiumIconVisible}
                      pluginDisabled={pluginDisabled}
                    />
                  );
                case AddonType.ADDON_DESIGN:
                  return null;
                default:
                  return null;
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(AddonsList);

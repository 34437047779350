import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconShutterstock from 'editor/src/component/Icon/IconShutterstock';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
}

function ShutterstockTab({ pluginName, customTabInfo }: Props) {
  const { t } = useTranslation();

  return (
    <Tab
      key={TAB_NAMES.SHUTTERSTOCK}
      name={TAB_NAMES.SHUTTERSTOCK}
      title={t('editor-shutterstock')}
      icon={<IconShutterstock />}
      pluginName={pluginName}
      customTabInfo={customTabInfo}
    />
  );
}

export default React.memo(ShutterstockTab);

import { ContentPluginRootState } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const getIsControlsModified = (state: ContentPluginRootState) => {
  const appliedFilters = state.data.results?.search.filters || {};
  const activeFilters = state.data.activeFilters || {};

  if (Object.keys(appliedFilters).sort().toString() !== Object.keys(activeFilters).sort().toString()) {
    return true;
  }

  return Object.entries(activeFilters).some(([key, activeFilterValue]) => {
    const appliedFilterValue = appliedFilters[key];
    if (Array.isArray(activeFilterValue)) {
      return (
        !Array.isArray(appliedFilterValue) ||
        activeFilterValue.slice().sort().toString() !== appliedFilterValue.slice().sort().toString()
      );
    }

    return Array.isArray(appliedFilterValue) || activeFilterValue !== appliedFilterValue;
  });
};

export default getIsControlsModified;

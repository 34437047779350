import { useCallback } from 'react';

import setSettingsValueOperation from 'editor/src/store/editor/operation/setSettingsValueOperation';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

interface StripesControlData {
  showStripes: boolean;
  showControl: boolean;
  toggleStripes: () => void;
}

function useShowRollerStripesControl(): StripesControlData {
  const dispatch = useDispatch();

  const showStripes = useSelector((state) => getSettingsValue(state, SettingsProperty.showRollerStripes));
  const showControl = useSelector((state) => getHostSetting(state, 'allowShowRollerStripesChange'));
  const toggleStripes = useCallback(() => {
    dispatch(setSettingsValueOperation(SettingsProperty.showRollerStripes, !showStripes));
  }, [showStripes]);

  return {
    showStripes,
    showControl,
    toggleStripes,
  };
}

export default useShowRollerStripesControl;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconFlipX({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M18.5 31.8518C18.5 32.67 19.1716 33.3333 20 33.3333C20.8284 33.3333 21.5 32.67 21.5 31.8518L21.5 29.3121C21.5 28.4939 20.8284 27.8306 20 27.8306C19.1716 27.8306 18.5 28.4939 18.5 29.3121L18.5 31.8518Z"
          fill="#212121"
        />
        <path
          d="M18.5 22.5396C18.5 23.3578 19.1716 24.0211 20 24.0211C20.8284 24.0211 21.5 23.3578 21.5 22.5396V17.4603C21.5 16.6421 20.8284 15.9788 20 15.9788C19.1716 15.9788 18.5 16.6421 18.5 17.4603V22.5396Z"
          fill="#212121"
        />
        <path
          d="M18.5 10.6878C18.5 11.506 19.1716 12.1693 20 12.1693C20.8284 12.1693 21.5 11.506 21.5 10.6878L21.5 8.14811C21.5 7.32991 20.8284 6.66663 20 6.66663C19.1716 6.66663 18.5 7.32991 18.5 8.14811L18.5 10.6878Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35 25.9259C35 26.4954 34.6695 27.0144 34.1498 27.2611C33.6301 27.5078 33.0132 27.4385 32.563 27.0827L25.063 21.1568C24.7071 20.8756 24.5 20.45 24.5 19.9999C24.5 19.5499 24.7071 19.1242 25.063 18.8431L32.563 12.9172C33.0132 12.5614 33.6301 12.4921 34.1498 12.7388C34.6695 12.9855 35 13.5045 35 14.074L35 25.9259ZM32 17.1564L28.4012 19.9999L32 22.8435L32 17.1564Z"
          fill="#212121"
        />
        <path
          d="M5 14.074C5 13.5046 5.33052 12.9855 5.85022 12.7388C6.36992 12.4921 6.98679 12.5614 7.43705 12.9172L14.937 18.8431C15.2929 19.1243 15.5 19.5499 15.5 20C15.5 20.45 15.2929 20.8757 14.937 21.1568L7.43704 27.0827C6.98679 27.4385 6.36992 27.5079 5.85022 27.2612C5.33052 27.0145 5 26.4954 5 25.9259L5 14.074Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconFlipX;

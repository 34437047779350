import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import isAnyImageSelected from 'editor/src/store/design/selector/isAnyImageSelected';
import { MediaImage } from 'editor/src/store/design/types';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import { RootState } from 'editor/src/store/index';

export enum ImageAction {
  ADD = 'ADD',
  REPLACE = 'REPLACE',
  NONE = 'NONE',
}

const getImageAction = (state: RootState) => {
  const imageSelected = isAnyImageSelected(state);
  if (imageSelected) {
    const selectedImageEmpty = !(getSelectedMediaElementsObjects(state)[0] as MediaImage)?.imageId;
    const addElementsAllowed = isAddElementsAllowed(state);
    if (selectedImageEmpty && addElementsAllowed) {
      return ImageAction.ADD;
    }
    return ImageAction.REPLACE;
  }
  return ImageAction.ADD;
};

export default getImageAction;

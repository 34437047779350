import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { StructureIndex } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import applyToMultipleMediaOperation from './applyToMultipleMediaOperation';
import fitMediaToSpreadOperation from './distribute/fitMediaToSpreadOperation';

const applyImageFitOperation = (structureIndexes: StructureIndex[]) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(saveUndoRedoStateOperation('fit element'));
    dispatch(applyToMultipleMediaOperation(structureIndexes, fitMediaToSpreadOperation));
  });
};

export default applyImageFitOperation;

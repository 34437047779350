import { DesignData } from 'editor/src/store/design/types';
import { GalleryImage, ImageState, MimeType } from 'editor/src/store/gallery/types';

const findNotUsedImages = (allImages: GalleryImage[], design: DesignData) => {
  const usedImages = new Set<string>();

  design.spreads.forEach((spread) => {
    spread.pages[0].groups.media?.forEach((element) => {
      if (element.type === 'image' && element.imageId) {
        usedImages.add(element.imageId);
      }
    });
  });

  return allImages.filter(
    (image) => !usedImages.has(image.id) && (image.type !== MimeType.PDF || image.state === ImageState.UPLOADED),
  );
};

export default findNotUsedImages;

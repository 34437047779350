import React from 'react';

import TextAlignButtons from 'editor/src/component/TopMenuDesktop/buttons/ButtonTextAlignment/TextAlignButtons';

import styles from './index.module.scss';

function PersonalizationActionBar() {
  return (
    <div className={styles.PersonalizationActionBar}>
      <TextAlignButtons />
    </div>
  );
}

export default React.memo(PersonalizationActionBar);

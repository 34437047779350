import React from 'react';

import geActiveSpreadSpotFinishingType from 'editor/src/store/design/selector/geActiveSpreadSpotFinishingType';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import IconDiamond from 'editor/src/component/Icon/IconDiamond';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection }) =>
  !isEmbedded && !!geActiveSpreadSpotFinishingType(state) && hasSelection;

type Props = Pick<MenuItemProps, 'mode'>;

function ButtonFoilLayers({ mode }: Props) {
  const dispatch = useDispatch();

  const isFoilLayersTabActive = useSelector((state) => isSidebarTabActive(state, TAB_NAMES.FOIL_LAYERS));

  const handleClick = () => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.FOIL_LAYERS));
  };

  return (
    <MenuButton
      Icon={IconDiamond}
      mode={mode}
      handleClick={handleClick}
      on={isFoilLayersTabActive}
      i18nKey="Foil"
      className="cy-button-foil-layers"
    />
  );
}

export default React.memo(ButtonFoilLayers);

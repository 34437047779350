import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorInfo } from 'editor/src/store/hostSettings/types';

import { EMPTY_RGBA_STRING } from 'editor/src/util/color/rgbaUtils';

import ColorPicker from 'editor/src/component/ColorPicker';
import ColorTile from 'editor/src/component/ColorPicker/ColorTileControl/ColorTile';
import ControlButton from 'editor/src/component/ControlButton';
import DropDown from 'editor/src/component/DropDown';
import IconArrowDown from 'editor/src/component/Icon/IconArrowDown';
import IconArrowForward from 'editor/src/component/Icon/IconArrowForward';
import IconCopy from 'editor/src/component/Icon/IconCopy';
import useCurrentElementColors from 'editor/src/component/TopMenuDesktop/buttons/ButtonImageColor/useCurrentElementColors';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  originalColor: string;
  colorIndex: number;
  newColor: string;
  colorInfo: ColorInfo | undefined;
  colors: string[] | undefined;
  usedColors: string[];
  onColorChange: (color: string | undefined, index: number) => void;
}

function ThreadColorItem({
  originalColor,
  newColor,
  colorInfo = {},
  colors,
  usedColors,
  onColorChange,
  colorIndex,
}: Props) {
  const { t } = useTranslation();
  const imageColors = useCurrentElementColors();
  const newColorInfo = colorInfo[newColor];
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [copyHexCodeTooltipText, setCopyHexCodeTooltipText] = useState(t('Copy to clipboard'));
  const dropdownBtnRef = useRef<HTMLDivElement>(null);
  const isEmptyColor = newColor === EMPTY_RGBA_STRING;

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleCopyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyHexCodeTooltipText(t('Copied'));
    } catch (err) {
      setCopyHexCodeTooltipText(t('Failed to copy'));
    }
  };

  const resetCopyHexTooltipText = () => {
    setCopyHexCodeTooltipText(t('Copy to clipboard'));
  };

  const onClickOutside = (event?: MouseEvent | TouchEvent) => {
    if (event && event?.target === dropdownBtnRef.current) {
      return;
    }

    setIsDropdownVisible(false);
  };

  const onColorChangeCb = (color: string | undefined) => {
    onColorChange(color, colorIndex);
  };

  // if image is not svg or if there aren't detected any colors and it's not digitized, do not render
  if (!imageColors.length || (!isEmptyColor && !newColorInfo)) {
    return null;
  }

  return (
    <div className="row-flex row-flex--middle mt-1">
      <ColorTile color={originalColor} isActive={false} i18ColorName={colorInfo[originalColor]?.name} />
      <IconArrowForward className="mx-2" />
      <div className={styles.dropdownButtonContainer}>
        <div
          ref={dropdownBtnRef}
          className={cn('cy-thread-color-dropdown-btn', styles.dropdownButton, {
            [styles.opened]: isDropdownVisible,
          })}
          onClick={toggleDropdown}
        >
          <div className={styles.dropdownColorTile}>
            <ColorTile color={newColor} isActive={false} noBorder i18ColorName={newColorInfo?.name} />
          </div>
          {newColorInfo ? `${newColorInfo.madeira_ref} ${t(newColorInfo.name)}` : t('No color')}
          <IconArrowDown className={cn(styles.arrow)} />
        </div>
        <DropDown
          isVisible={isDropdownVisible}
          onClickOutside={onClickOutside}
          openPosition="bottom-left"
          className={cn(styles.displayDropdown, 'cy-display-section-dropdown thread-color-dropdown')}
          matchWidth
        >
          <div className={styles.dropdownContent} data-ignore-outside-click>
            {!isEmptyColor && (
              <div className={cn(styles.hexCodeContainer, 'row-flex row-flex--between row-flex--middle mb-1')}>
                {newColor.toUpperCase()}
                <div onMouseLeave={resetCopyHexTooltipText}>
                  <WithTooltip overlay={copyHexCodeTooltipText} placement="top">
                    <ControlButton onClick={() => handleCopyToClipboard(newColor.toUpperCase())} stopPropagation>
                      <IconCopy />
                    </ControlButton>
                  </WithTooltip>
                </div>
              </div>
            )}
            <ColorPicker
              color={newColor}
              onColorChange={onColorChangeCb}
              allowNoColor
              allowNoColorLabel={t('Remove thread color')}
              paletteColorsInRowCount={5}
              colors={colors}
              suggestedColors={usedColors}
              hidePickFromPaletteNote
            />
          </div>
        </DropDown>
      </div>
    </div>
  );
}

export default React.memo(ThreadColorItem);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconStrikethrough({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M20.75 33.333C18.8887 33.333 17.1873 32.8887 15.646 32C14.104 31.1113 12.861 29.889 11.917 28.333C11.6943 27.9163 11.6663 27.479 11.833 27.021C11.9997 26.5623 12.3193 26.236 12.792 26.042C13.1807 25.8753 13.5833 25.8683 14 26.021C14.4167 26.1737 14.75 26.4443 15 26.833C15.6113 27.7777 16.424 28.5207 17.438 29.062C18.4513 29.604 19.5693 29.875 20.792 29.875C22.1253 29.875 23.2363 29.5347 24.125 28.854C25.0137 28.1733 25.458 27.208 25.458 25.958C25.458 25.3473 25.34 24.7363 25.104 24.125C24.868 23.5137 24.5417 22.972 24.125 22.5H28C28.3053 23.0553 28.5347 23.611 28.688 24.167C28.8407 24.7223 28.917 25.3193 28.917 25.958C28.917 28.1807 28.139 29.9653 26.583 31.312C25.0277 32.6593 23.0833 33.333 20.75 33.333ZM4.70801 19.708C4.31934 19.708 3.99301 19.576 3.72901 19.312C3.46501 19.0487 3.33301 18.7223 3.33301 18.333C3.33301 17.9443 3.46501 17.618 3.72901 17.354C3.99301 17.09 4.31934 16.958 4.70801 16.958H35.292C35.6807 16.958 36.007 17.09 36.271 17.354C36.535 17.618 36.667 17.9443 36.667 18.333C36.667 18.7223 36.535 19.0487 36.271 19.312C36.007 19.576 35.6807 19.708 35.292 19.708H4.70801ZM12.208 14.167C12.1807 14.0277 12.16 13.8817 12.146 13.729C12.132 13.5763 12.125 13.4307 12.125 13.292C12.125 11.292 12.8403 9.64599 14.271 8.35399C15.7017 7.06266 17.5697 6.41699 19.875 6.41699C21.375 6.41699 22.736 6.71566 23.958 7.31299C25.1807 7.90966 26.1947 8.76366 27 9.87499C27.278 10.2917 27.3337 10.736 27.167 11.208C27.0003 11.6807 26.667 12.028 26.167 12.25C25.8337 12.4167 25.4587 12.4373 25.042 12.312C24.6253 12.1873 24.2503 11.9307 23.917 11.542C23.4723 11.042 22.9027 10.639 22.208 10.333C21.514 10.0277 20.7363 9.87499 19.875 9.87499C18.597 9.87499 17.5553 10.1807 16.75 10.792C15.9447 11.4027 15.528 12.2497 15.5 13.333C15.5 13.4723 15.507 13.6113 15.521 13.75C15.535 13.8887 15.5697 14.0277 15.625 14.167H12.208Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconStrikethrough;

import React from 'react';
import { shallowEqual } from 'react-redux';

import getSpreadBackgroundImage from 'editor/src/store/design/selector/getSpreadBackgroundImage';
import getSpreadForegroundImage from 'editor/src/store/design/selector/getSpreadForegroundImage';
import { Coords, SpreadInfo } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';
import { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import useCreateSnapController from 'editor/src/component/EditorArea/snapping/useCreateSnapController';
import { SnapControllerContext } from 'editor/src/component/EditorArea/snapping/useSnapController';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import GuideLines from './GuideLines';
import PageElement from './Page';
import PageMedia from './Page/PageMedia';
import SpreadGroundImageElement from './SpreadGroundImageElement';
import zIndex from './zIndex';

interface Props {
  spreadIndex: number;
  spreadWidth: number;
  spreadHeight: number;
  spreadCoords: Coords;
  canvasRotation: CanvasRotation;
  spreadsInfo: SpreadInfo[];
}

function Spread({ spreadWidth, spreadHeight, spreadCoords, spreadIndex, canvasRotation, spreadsInfo }: Props) {
  const { mm2px } = useFabricUtils();
  const focusedContentAddress = useSelector((state) => state.editor.focusedContentAddress);

  const { spreadBackground, spreadForeground, pages, designKey, spotFinishingType } = useSelector((state) => {
    const spread = state.design.designData?.spreads[spreadIndex];
    return {
      spreadBackground: getSpreadBackgroundImage(state, spread?.name || ''),
      spreadForeground: getSpreadForegroundImage(state, spread?.name || ''),
      pages: spread?.pages,
      spotFinishingType: spread?.spot_finishing_type,
      designKey: state.design.designData ? getDesignKeyFromDesign(state.design.designData) : '',
    };
  }, shallowEqual);

  const isMainSpread = spreadsInfo[0].spreadIndex === spreadIndex;

  const snapController = useCreateSnapController();

  return (
    <SnapControllerContext.Provider value={snapController}>
      {spreadBackground && (
        <SpreadGroundImageElement
          imageData={spreadBackground}
          spreadWidth={spreadWidth}
          spreadHeight={spreadHeight}
          spreadCoords={spreadCoords}
          zIndex={zIndex.BACKGROUND}
          canvasRotation={canvasRotation}
        />
      )}
      {pages?.map((page, index) => (
        <PageElement
          key={index}
          page={page}
          hasBackgroundImage={!!spreadBackground}
          spreadIndex={spreadIndex}
          pageIndex={index}
          spreadWidth={mm2px(spreadWidth)}
          spreadHeight={mm2px(spreadHeight)}
          spreadCoords={spreadCoords}
          canvasRotation={canvasRotation}
          focusedContentAddress={focusedContentAddress}
        />
      ))}
      {pages && isMainSpread && (
        <PageMedia
          pages={pages}
          pagesKey={`${designKey}-${spreadIndex}`}
          spreadIndex={spreadIndex}
          spreadCoords={spreadCoords}
          canvasRotation={canvasRotation}
          focusedContentAddress={focusedContentAddress}
          spreadWidth={mm2px(spreadWidth)}
          spreadHeight={mm2px(spreadHeight)}
          spreadsInfo={spreadsInfo}
          spotFinishingType={spotFinishingType}
        />
      )}
      {spreadForeground && (
        <SpreadGroundImageElement
          imageData={spreadForeground}
          spreadWidth={spreadWidth}
          spreadHeight={spreadHeight}
          spreadCoords={spreadCoords}
          zIndex={zIndex.FOREGROUND}
          canvasRotation={canvasRotation}
        />
      )}
      <GuideLines />
    </SnapControllerContext.Provider>
  );
}

export default React.memo(Spread);

import React from 'react';
import { useTranslation } from 'react-i18next';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconShapes from 'editor/src/component/Icon/IconShapes';
import useMobileMenu from 'editor/src/component/useMobileMenu';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
}

function ShapesTab({ pluginName, customTabInfo }: Props) {
  const dispatch = useDispatch();
  const topMenu = useMobileMenu();
  const { t } = useTranslation();
  const active = useSelector((state) => isSidebarTabActive(state, TAB_NAMES.SHAPES));

  const onMobileClick = () => {
    if (active) {
      dispatch(setSidebarActiveTabOperation(-1));
    } else {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.SHAPES));
    }
    topMenu.close();
  };

  return (
    <Tab
      key={TAB_NAMES.SHAPES}
      name={TAB_NAMES.SHAPES}
      title={t('Shapes')}
      icon={<IconShapes active={active} />}
      pluginName={pluginName}
      customTabInfo={customTabInfo}
      onMobileClick={onMobileClick}
    />
  );
}

export default React.memo(ShapesTab);

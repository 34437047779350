import { RootState } from 'editor/src/store/index';
import { ProductDataPayload } from 'editor/src/store/variants/reducer/setProductDataReducer';
import {
  ExistingVariant,
  isExternalProductControl,
  isVariationProductControl,
  Product,
  VariationGroup,
} from 'editor/src/store/variants/types';

import getAllMatchingVariants from './getAllMatchingVariants';
import getAllMatchingVariations from './getAllMatchingVariations';
import getDesignOptionKey from './getDesignOptionKey';
import getInitialExternalOptions from './getInitialExternalOptions';
import getInitialMultiOptions from './getInitialMultiOptions';
import getInitialSingleOptions from './getInitialSingleOptions';
import groupVariations, { getVariantInfoFromVariant } from './groupVariantsBy';

import type { i18n } from 'i18next';

const getProductDataVariationGroups = (
  data: ProductDataPayload,
  updatedVariants: ExistingVariant[],
  state: RootState,
  i18n: i18n,
) => {
  const {
    product,
    defaultVariation,
    defaultProductUids,
    configuration,
    defaultVariationsInfo,
    existingVariants = [],
    layoutPerProductUids = {},
    groupedSpreadsPerProductUids = {},
  } = data;

  let groups: VariationGroup[] | undefined;
  if (defaultVariation) {
    const productControls = product.productControls.filter(isVariationProductControl);

    const splitControlProduct: Product = {
      ...product,
      productControls,
      externalProductControls: product.productControls.filter(isExternalProductControl),
      unavailableProducts: product.unavailableProducts ?? {},
    };

    const selectedMultiOptions = getInitialMultiOptions(
      productControls,
      product.productVariations,
      defaultProductUids,
      updatedVariants,
      !!configuration.singleSelection,
    );
    const selectedExternalOptions = getInitialExternalOptions(
      splitControlProduct.externalProductControls,
      updatedVariants,
    );
    let singleControlVariation = defaultVariation;
    if (existingVariants.length) {
      singleControlVariation =
        product.productVariations.find((variation) => variation.productUid === updatedVariants[0].productUid) ??
        defaultVariation;
    }
    const replaceControlKeys = product.productControls
      .filter((control) => control.type === 'single' && control.behavior === 'replace')
      .map((control) => control.key);
    const selectedSingleOptions = getInitialSingleOptions(splitControlProduct.productControls, singleControlVariation);

    const matchingVariations = getAllMatchingVariations(
      product.productVariations,
      selectedMultiOptions,
      selectedSingleOptions,
      !!configuration.singleSelection,
      replaceControlKeys,
    );

    const matchingVariants = getAllMatchingVariants(
      matchingVariations,
      selectedExternalOptions,
      splitControlProduct.externalProductControls,
      state.variants.selectedPageCount,
    );
    groups = groupVariations(
      matchingVariants,
      splitControlProduct,
      state.variants.designTemplates,
      (variations) => getVariantInfoFromVariant(variations, updatedVariants),
      state,
      i18n,
      layoutPerProductUids,
      groupedSpreadsPerProductUids,
      configuration.forceLayout,
    );
    if (defaultVariationsInfo) {
      groups.forEach((group) => {
        group.linked = group.variationsInfo[0]
          ? !!defaultVariationsInfo[group.variationsInfo[0].variation.productUid]?.linked
          : true;
      });
    }
  }

  return getDesignOptionsVariationGroups(data, groups);
};

const getDesignOptionsVariationGroups = (productData: ProductDataPayload, groups: VariationGroup[] | undefined) => {
  const { product, existingVariants = [] } = productData;

  // happens when there are no variations.
  if (!groups) {
    return undefined;
  }

  const newGroups: VariationGroup[] = [];
  const { designOptionControls } = product;
  if (designOptionControls) {
    groups.forEach((group) => {
      designOptionControls.forEach((designOptionControl) => {
        designOptionControl.options.forEach((option) => {
          newGroups.push({
            ...group,
            designOptions: [
              {
                optionKey: option.value,
                designOptionControlSubKey: designOptionControl.subtype,
              },
            ],
            key: `${group.key}-${option.value}`,
          });
        });
      });
    });

    newGroups.forEach((group) => {
      group.variationsInfo = group.variationsInfo.map((variationInfo) => {
        const matchingVariant = existingVariants.find(
          (existingVariant) =>
            existingVariant.productUid === variationInfo.designData?.product_uid &&
            getDesignOptionKey(existingVariant) === getDesignOptionKey(group) &&
            existingVariant.designOptions?.[0].designOptionControlSubKey ===
              group.designOptions?.[0].designOptionControlSubKey,
        );
        if (matchingVariant) {
          const updatedVariationInfo = {
            ...variationInfo,
            designData: matchingVariant.designData,
          };

          group.linked = matchingVariant.linked;

          // Use the new variable in the return statement
          return updatedVariationInfo;
        }
        return variationInfo;
      });
    });
  }

  return newGroups.length ? newGroups : groups;
};

export default getProductDataVariationGroups;

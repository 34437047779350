import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { ElementAddress } from 'editor/src/store/design/types';
import { removeImageAction } from 'editor/src/store/gallery/slice';
import type { Thunk } from 'editor/src/store/hooks';

import toastController from 'editor/src/util/toastController';

import clearImagesOrRemoveElementsOperation from './clearImagesOrRemoveElementsOperation';

const addonUploadFailedOperation =
  ({ addonId }: { addonId: string }): Thunk =>
  (dispatch, getState, { i18n }) => {
    const state = getState();
    const addressesToRemove: ElementAddress[] = [];
    state.design.designData?.spreads.forEach((spread, spreadIndex) => {
      spread.pages.forEach((page, pageIndex) => {
        page.groups.media?.forEach((element, elementIndex) => {
          if (element.type === 'image' && element.imageId === addonId) {
            addressesToRemove.push({ spreadIndex, pageIndex, elementIndex });
          }
        });
      });
    });

    batch(() => {
      if (addressesToRemove.length) {
        dispatch(clearImagesOrRemoveElementsOperation(addressesToRemove));
      }
      dispatch(removeImageAction(addonId));
      toastController.warningPersist(
        i18n.t('editor-image-loading-failed'),
        i18n.t('editor-image-loading-failed-cant-add'),
      );
    });
  };

export default addonUploadFailedOperation;

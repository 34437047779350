import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import errorIcon from 'editor/src/static/error-icon.svg';

import Accordion from 'editor/src/component/Accordion';

import styles from './index.module.scss';

interface Props {
  selectedVariantsAmount: number;
  productVariantsLimit?: number;
}

function ErrorMessage({ productVariantsLimit, selectedVariantsAmount }: Props) {
  const { t } = useTranslation();
  const [isCollapsed, setCollapsed] = useState(true);

  const accordionHeader = (
    <div className={cn(styles.accordionHeader)}>
      <div>
        {t('Too many variants', {
          limit: productVariantsLimit,
          selected: selectedVariantsAmount,
        })}
      </div>
      <img src={errorIcon} alt="Error icon" className={cn(styles.errorIcon, 'mr-1', 'ml-1')} />
    </div>
  );

  return (
    <div className={cn(styles.ErrorMessageAccordion, 'mb-2', 'pl-2', 'pr-2')}>
      <Accordion header={accordionHeader} collapsed={isCollapsed} setCollapsed={setCollapsed}>
        {t('You have reached the limit of variants', {
          limit: productVariantsLimit,
          selected: selectedVariantsAmount,
        })}
      </Accordion>
    </div>
  );
}

export default React.memo(ErrorMessage);

import getAddonsSearch from 'editor/src/store/editorModules/addons/selector/getAddonsSearch';
import getCategoryId from 'editor/src/store/editorModules/addons/selector/getCategoryId';
import getRequestedVersion from 'editor/src/store/editorModules/addons/selector/getRequestedVersion';
import { increaseRequestedVersionAction } from 'editor/src/store/editorModules/addons/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const requestAddonsOperation =
  (categoryId?: string | number) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    dispatch(increaseRequestedVersionAction());
    const state = getState();

    sendPostMessage('addons.requestAddons', {
      requestVersion: getRequestedVersion(state),
      search: getAddonsSearch(state),
      categoryId: categoryId !== undefined ? categoryId : getCategoryId(state),
    });
  };

export default requestAddonsOperation;

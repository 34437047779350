import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { ElementAddress } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';

import getImageById from './getImageById';

const getImageByElementAddress = (state: RootState, elementAddress: ElementAddress) => {
  const mediaElement = getMediaElement(state, elementAddress);
  if (mediaElement?.type !== 'image') {
    return undefined;
  }

  return getImageById(state, mediaElement.imageId);
};

export default getImageByElementAddress;

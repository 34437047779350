import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import setMirroringModeOperation from 'editor/src/store/design/operation/setMirroringModeOperation';
import { MirroringMode } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { VariationProductControl, ProductControlOption } from 'editor/src/store/variants/types';

import ButtonElement from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/elements/ButtonElement';
import SingleValueProductControl from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/single/SingleValueProductControl';
import useMirroring from 'editor/src/component/EditorArea/Spread/Page/useMirroring';

import buttonStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/single/SingleControl/index.module.scss';

import type { TFunction } from 'i18next';

interface Props {
  noTopBar?: boolean;
}

export type Option = { value: MirroringMode | undefined; label: string };

export const GET_LABEL = (option: Option) => option.label;

export function getOptions(t: TFunction): Array<{ title: string; value: string }> {
  return [
    { title: t('editor-mirroring-wrap'), value: '' },
    { title: t('editor-mirroring-mirror'), value: 'mirror' },
  ];
}

function MirroringControl({ noTopBar }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { spreadIndex, pageIndex, page } = useSelector((state) => {
    const spreadIndex = getCurrentSpreadIndex(state);
    const pageIndex = 0;
    const page = state.design.designData?.spreads[spreadIndex].pages[0];
    return {
      spreadIndex,
      pageIndex,
      page,
    };
  }, shallowEqual);

  const { mirroringMode, contentAddress } = useMirroring(spreadIndex, pageIndex, page);

  function onSelect(_: VariationProductControl, option: ProductControlOption) {
    if (contentAddress) {
      dispatch(
        setMirroringModeOperation({
          mirroringMode: (option.value as MirroringMode) || undefined,
          contentAddress,
        }),
      );
    }
  }

  const title = t('editor-print-on-sides');

  const control: VariationProductControl = useMemo(
    () => ({
      type: 'single',
      uiType: 'button',
      key: 'print',
      title,
      options: getOptions(t),
    }),
    [title], // to force update on locale change
  );

  return (
    <SingleValueProductControl
      noTopBar={noTopBar}
      className={cn(buttonStyles.ButtonControl, 'cy-mirroring-control')}
      title={control.title}
      control={control}
      options={control.options}
      toggleOption={onSelect}
      selectedValue={mirroringMode || ''}
      Element={ButtonElement}
    />
  );
}

export default React.memo(MirroringControl);

import React from 'react';
import { useTranslation } from 'react-i18next';

import removeImagesOperation from 'editor/src/store/gallery/operation/removeImagesOperation';
import getSelectedGalleryImagesIds from 'editor/src/store/gallery/selector/getSelectedGalleryImagesIds';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import ConfirmationMenu from 'editor/src/component/ConfirmationMenu';
import IconBin from 'editor/src/component/Icon/IconBin';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';
import useMobileMenu from 'editor/src/component/useMobileMenu';
import WithTooltip from 'editor/src/component/WithTooltip';

export const DeleteSelectedImagesHeight = 65;

function DeleteSelectedImages() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const deleteImageConfirmation = useMobileMenu();
  const selectedImageIds = useSelector((state) => getSelectedGalleryImagesIds(state));

  const onClick = () => {
    if (!isMobile) {
      removeImages();
    } else {
      deleteImageConfirmation.open();
    }
  };

  const handleCancel = () => {
    deleteImageConfirmation.close();
  };

  const removeImages = () => {
    dispatch(removeImagesOperation(selectedImageIds));
    deleteImageConfirmation.close();
  };

  return (
    <>
      <WithTooltip overlay={t('Remove selected images')}>
        <Button className="cy-delete-selected-images-button" stopPropagation onClick={onClick}>
          <IconBin fill="#D0412D" />
        </Button>
      </WithTooltip>
      {isMobile &&
        deleteImageConfirmation.render(
          <ConfirmationMenu
            headerText={t('Delete images')}
            headerDescriptionText={t('Are you sure you want to delete the image you selected')}
            confirmButtonText={t('Delete images')}
            cancellButtonText={t('Cancel')}
            onCancel={handleCancel}
            onConfirm={removeImages}
            onCrossClick={handleCancel}
          />,
        )}
    </>
  );
}

export default React.memo(DeleteSelectedImages);

/* eslint-disable max-len */
import React from 'react';

function IconCrown() {
  return (
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 4.5L1 5C0.851258 5 0.710238 4.93378 0.615245 4.81932C0.520253 4.70486 0.481152 4.55405 0.508564 4.40786L1 4.5ZM2 4.5L2 4L2 4L2 4.5ZM1.75 0.5L1.25856 0.407856C1.29053 0.237353 1.40859 0.095605 1.57051 0.0333296C1.73242 -0.0289458 1.91504 -0.00284847 2.05302 0.102284L1.75 0.5ZM3.81818 2.07576L4.21818 2.37576C4.13822 2.48237 4.01901 2.55265 3.88702 2.571C3.75502 2.58934 3.62116 2.55424 3.51516 2.47347L3.81818 2.07576ZM5 0.5L4.6 0.2C4.69443 0.0740973 4.84262 1.95028e-07 5 2.22545e-07C5.15738 2.50062e-07 5.30557 0.0740974 5.4 0.2L5 0.5ZM6.18182 2.07576L6.48484 2.47347C6.37884 2.55424 6.24498 2.58934 6.11298 2.571C5.98099 2.55265 5.86178 2.48237 5.78182 2.37576L6.18182 2.07576ZM8.25 0.500001L7.94698 0.102285C8.08497 -0.00284786 8.26759 -0.0289451 8.4295 0.0333303C8.59141 0.0956057 8.70947 0.237354 8.74144 0.407856L8.25 0.500001ZM9 4.5L9.49144 4.40786C9.51885 4.55405 9.47975 4.70486 9.38476 4.81932C9.28976 4.93378 9.14874 5 9 5L9 4.5ZM8 4.5L8 5L8 4.5ZM7 4.5L7 4L7 4.5ZM3 4.5L3 5L3 4.5ZM1 4L2 4L2 5L1 5L1 4ZM2.24144 0.592144L1.49144 4.59214L0.508564 4.40786L1.25856 0.407856L2.24144 0.592144ZM3.51516 2.47347L1.44698 0.897716L2.05302 0.102284L4.1212 1.67804L3.51516 2.47347ZM5.4 0.8L4.21818 2.37576L3.41818 1.77576L4.6 0.2L5.4 0.8ZM5.78182 2.37576L4.6 0.8L5.4 0.2L6.58182 1.77576L5.78182 2.37576ZM8.55302 0.897717L6.48484 2.47347L5.8788 1.67804L7.94698 0.102285L8.55302 0.897717ZM8.50856 4.59214L7.75856 0.592145L8.74144 0.407856L9.49144 4.40786L8.50856 4.59214ZM8 4L9 4L9 5L8 5L8 4ZM7 4L8 4L8 5L7 5L7 4ZM3 4L7 4L7 5L3 5L3 4ZM2 4L3 4L3 5L2 5L2 4Z"
        fill="white"
      />
    </svg>
  );
}

export default React.memo(IconCrown);

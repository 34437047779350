import React, { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import setActiveAddLayerDropdownOperation from 'editor/src/store/app/operation/setActiveAddLayerDropdownOperation';
import addNewTextToPageOperation from 'editor/src/store/design/operation/addNewTextToPageOperation';
import createConditionOperation from 'editor/src/store/design/operation/createConditionOperation';
import logPersonalizationLayerActivatedOperation from 'editor/src/store/editor/operation/logPersonalizationLayerActivatedOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { logEvent } from 'editor/src/amplitude';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import ControlButton from 'editor/src/component/ControlButton';
import DropDown from 'editor/src/component/DropDown';
import { DropdownPosition } from 'editor/src/component/DropDown/getPosition';
import IconAddCondition from 'editor/src/component/Icon/IconAddCondition';
import IconCollections from 'editor/src/component/Icon/IconCollections';
import IconGallery from 'editor/src/component/Icon/IconGallery';
import IconText from 'editor/src/component/Icon/IconText';
import { UploaderContext } from 'editor/src/component/Uploader/useUploader';

import styles from './index.module.scss';

interface Props {
  isDropdownVisible: boolean;
  menuPosition: DropdownPosition;
  showCollection?: boolean;
  showCondition?: boolean;
  className?: string;
  isPersonalized?: boolean;
}

function AddLayerDropdown({
  isDropdownVisible,
  className,
  menuPosition,
  showCondition,
  showCollection,
  isPersonalized,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const spreadIndex = useSelector(getCurrentSpreadIndex);
  const uploader = useContext(UploaderContext);

  const closeDropdown = () => {
    dispatch(setActiveAddLayerDropdownOperation(undefined));
  };

  const onAddTextClick = () => {
    dispatch(addNewTextToPageOperation(spreadIndex, 0, undefined, isPersonalized));
    closeDropdown();
  };

  const onAddImageClick = () => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.GALLERY));
    uploader.open();
    closeDropdown();
  };

  const newConditionRef = useRef<string>();
  const addCondition = useCallback(() => {
    const conditionId = dispatch(createConditionOperation());
    if (conditionId) {
      newConditionRef.current = conditionId;
    }

    dispatch(logPersonalizationLayerActivatedOperation('condition'));
    logEvent('condtion created', undefined);
    closeDropdown();
  }, []);

  const onAddCollection = () => {
    sendPostMessage('advancedPersonalisation.openLibrary', undefined);
    closeDropdown();
  };

  return (
    <DropDown
      isVisible={isDropdownVisible}
      wrapperClassName={className}
      className={styles.dropDown}
      onClickOutside={closeDropdown}
      openPosition={menuPosition}
    >
      <ControlButton onClick={onAddImageClick} stopPropagation className="cy-button-add-image">
        <IconGallery className={styles.icon} />
        {t('editor-add-image')}
      </ControlButton>
      <ControlButton onClick={onAddTextClick} stopPropagation className="cy-button-add-text">
        <IconText className={styles.icon} />
        {t('editor-add-text')}
      </ControlButton>
      {showCollection && (
        <ControlButton onClick={onAddCollection} stopPropagation className="cy-button-add-collection">
          <IconCollections className={styles.icon} />
          {t('Add collection')}
        </ControlButton>
      )}
      {showCondition && (
        <ControlButton onClick={addCondition} stopPropagation className="cy-button-add-condition">
          <IconAddCondition className={styles.icon} />
          {t('Add condition')}
        </ControlButton>
      )}
    </DropDown>
  );
}

export default React.memo(AddLayerDropdown);

import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { MediaText } from 'editor/src/store/design/types';
import updateTextPropertiesWithoutRender from 'editor/src/store/design/util/updateTextPropertiesWithoutRender';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Accordion from 'editor/src/component/Accordion';
import useDragHandle from 'editor/src/component/ConditionGroupBuilder/ConditionElements/ReorganizableList/useDragHandle';
import useReorganizableListUpdate from 'editor/src/component/ConditionGroupBuilder/ConditionElements/ReorganizableList/useReorganizableListUpdate';
import useOverlay from 'editor/src/component/ConditionGroupBuilder/Overlay/useOverlay';
import ElementName from 'editor/src/component/PersonalizationContent/PersonalizationElements/ElementName';
import TextInput from 'editor/src/component/PersonalizationContent/PersonalizationElements/TextInput';
import useFocusElementOnFlag from 'editor/src/component/PersonalizationContent/PersonalizationElements/useFocusElementOnFlag';
import SwitchControl from 'editor/src/component/SwitchControl';

import styles from './index.module.scss';

interface Props {
  elementId: number;
  isBuilding?: boolean;
  className?: string;
}

const DEFAULT_CHARACTER_LIMIT = 20;

function PersonalizationText({ elementId, isBuilding, className }: Props) {
  const { element, address, isSelected } = useSelector(
    (state) => ({
      isSelected: state.editor.selectedElementUuids.includes(elementId),
      element: getMediaElementByUuid(state, elementId) as MediaText | undefined,
      address: getStructureIndexByElementUuidMemoized(state, elementId),
    }),
    shallowEqual,
  );

  const [characterLimit, setCharacterLimit] = useState<number | undefined>(
    element?.extra.maxCharCount ? element.extra.maxCharCount : element?.extra?.text.length || DEFAULT_CHARACTER_LIMIT,
  );
  const [isAdditionalOptionsCollapsed, setIsAdditionalOptionsCollapsed] = useState(true);
  const [isCharacterCountLimitEnabled, setIsCharacterCountLimitEnabled] = useState(
    element?.extra.maxCharCount !== undefined,
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dragHandle = useDragHandle();

  useEffect(() => {
    updateList();
  }, [!!element?.name]);

  const updateList = useReorganizableListUpdate();

  const onSwitcherTransitionEnd = useCallback(() => {
    updateList();
  }, [updateList]);

  const onAdditionalOptionsCollapseToggle = useCallback(
    (isCollapsed: boolean) => {
      setIsAdditionalOptionsCollapsed(isCollapsed);
      updateList();
    },
    [setIsAdditionalOptionsCollapsed, updateList],
  );

  const onTextChange = useCallback(
    (text: string) => {
      if (address && element) {
        const elementUpdate = { extra: { ...element.extra, text } };
        updateTextPropertiesWithoutRender(element, elementUpdate, undefined);
        dispatch(updateMediaElementOperation(address, elementUpdate, MediaUpdateActionName.TEXT_UPDATED, true));
      }
    },
    [address, element?.extra],
  );

  const onToggleCharacterLimitSwitch = useCallback(
    (isSwitchedOn: boolean) => {
      setIsCharacterCountLimitEnabled(isSwitchedOn);
      if (isSwitchedOn) {
        const maxCharValue = element?.extra?.maxCharCount
          ? element.extra.maxCharCount
          : element?.extra?.text.length || DEFAULT_CHARACTER_LIMIT;

        setCharacterLimit(maxCharValue);
        onCharacterLimitInputChange(maxCharValue);
      }

      if (!isSwitchedOn && characterLimit) {
        onCharacterLimitInputChange(undefined);
      }
    },
    [characterLimit, element?.extra],
  );

  const onCharacterLimitInputChange = useCallback(
    (value: number | undefined) => {
      if (value === 0) {
        return;
      }

      if (address && element) {
        let elementUpdate;
        if (value !== undefined && value < element.extra.text.length) {
          elementUpdate = {
            extra: { ...element.extra, maxCharCount: value, text: element.extra.text.slice(0, value) },
          };
        } else {
          elementUpdate = { extra: { ...element.extra, maxCharCount: value } };
        }

        dispatch(updateMediaElementOperation(address, elementUpdate, MediaUpdateActionName.TEXT_UPDATED, true));
        setCharacterLimit(value);
      }
    },
    [address, element?.extra],
  );

  const textEditorOverlay = useOverlay();
  const divRef = useRef<HTMLDivElement>(null);

  const active = textEditorOverlay.visible || isSelected;
  useFocusElementOnFlag(active, divRef);

  if (!element || !address || element?.personalizationLocked) {
    return null;
  }

  return (
    <div
      ref={divRef}
      className={cn(className, styles.PersonalizationText, 'cy-personalization-text', {
        [styles.building]: isBuilding,
        [styles.active]: active,
        [styles.static]: !isBuilding,
      })}
      onMouseDown={dragHandle.onMouseDown}
    >
      <ElementName element={element} isBuilding={isBuilding} address={address} />
      <div
        className={cn(styles.textContainer, {
          [styles.isBuilding]: isBuilding,
        })}
      >
        <TextInput
          value={!isBuilding && element?.sample ? '' : element?.extra?.text || ''}
          className={styles.input}
          maxCharCount={element?.extra?.maxCharCount}
          placeholder={!isBuilding && element?.sample ? element?.extra.text : element?.name}
          onChange={onTextChange}
        />
        {!isBuilding && element?.extra.maxCharCount && (
          <div className={styles.charCounter}>
            {element?.extra.text.length}/{element.extra.maxCharCount}
          </div>
        )}
      </div>
      {isBuilding && (
        <div className={styles.textOptions}>
          <Accordion
            header={t('Text options')}
            headerClassName={cn(styles.textOptionsHeader, 'cy-text-options-accordion')}
            collapsed={isAdditionalOptionsCollapsed}
            setCollapsed={onAdditionalOptionsCollapseToggle}
            onTransitionEnd={onSwitcherTransitionEnd}
          >
            <div className={styles.characterLimitOption}>
              <div className={styles.switchControl}>
                <div className={styles.characterLimitOptionTitle}>{t('Character count limit')}</div>
                <SwitchControl
                  title=""
                  className="cy-character-count-switch"
                  on={isCharacterCountLimitEnabled}
                  disabled={false}
                  onSwitch={onToggleCharacterLimitSwitch}
                />
              </div>
              <div
                onTransitionEnd={onSwitcherTransitionEnd}
                className={cn(styles.characterLimitInputContainer, { [styles.visible]: isCharacterCountLimitEnabled })}
              >
                {isCharacterCountLimitEnabled && (
                  <input
                    className={cn(styles.characterLimitInput, 'cy-character-limit-input')}
                    value={characterLimit}
                    type="number"
                    min={element?.extra?.text?.length ?? 1}
                    onChange={(e) => onCharacterLimitInputChange(Number(e.target.value))}
                  />
                )}
              </div>
            </div>
          </Accordion>
        </div>
      )}
    </div>
  );
}

export default React.memo(PersonalizationText);

import React from 'react';
import { useTranslation } from 'react-i18next';

import getSelectedTextElementObject from 'editor/src/store/design/selector/getSelectedTextElementObject';
import getStructureIndexesOfSelectedElements from 'editor/src/store/design/selector/getStructureIndexesOfSelectedElements';
import isPersonalizationLockElementsAllowed from 'editor/src/store/editor/selector/isPersonalizationLockElementsAllowed';
import { useSelector } from 'editor/src/store/hooks';

import TextPersonalizationPromotionBanner from 'editor/src/component/Banners/TextPersonalizationPromotionBanner';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import PersonalizationOptions from 'editor/src/component/TopMenuDesktop/buttons/ButtonPersonalization/PersonalizationOptions';

import ButtonAddText from './ButtonAddText';

function TextsTabContent({ title }: TabContentProps) {
  const { t } = useTranslation();
  const firstSelectedElementAddress = useSelector((state) => getStructureIndexesOfSelectedElements(state)[0]);
  const selectedTextElement = useSelector(getSelectedTextElementObject);
  const personalizationLockElementsAllowed = useSelector(isPersonalizationLockElementsAllowed);

  return (
    <>
      <TabContentHeader title={title || t('editor-text')} />
      <ContentBlock>
        <ButtonAddText />
        <TextPersonalizationPromotionBanner />
        {!!(selectedTextElement && personalizationLockElementsAllowed) && (
          <div className="mt-3">
            <PersonalizationOptions
              enableSampleElementShortDescription
              toggleSampleElementView
              origin="text_tab"
              addSeparator={false}
              allowPersonalizationControlType="toggle"
              structureIndex={firstSelectedElementAddress}
              element={selectedTextElement}
            />
          </div>
        )}
      </ContentBlock>
    </>
  );
}

export default React.memo(TextsTabContent);

import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store';
import requestDataOperation from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/operation/requestDataOperation';
import { setActiveGroupAction as setActiveGroupOperation } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/slice';
import { GroupElement } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconArrowLeft from 'editor/src/component/Icon/IconArrowLeft';

import styles from './index.module.scss';

interface Props {
  title: string;
  icon?: React.ReactNode;
  activeGroup?: Partial<GroupElement>;
}

function Header(props: Props) {
  const { activeGroup } = props;
  let { icon, title = '' } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleBackClick = useCallback(() => {
    dispatch(setActiveGroupOperation());
    dispatch(requestDataOperation());
  }, []);

  if (activeGroup) {
    title = activeGroup.title || t('editor-back');
    icon = (
      <div onClick={handleBackClick} className={cn('cy-button-back', styles.backButton)}>
        <IconArrowLeft />
      </div>
    );
  }

  return <TabContentHeader title={title} icon={icon} />;
}

export default React.memo(Header);

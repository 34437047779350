import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getLayoutSchemas from 'editor/src/store/editor/selector/getLayoutSchemas';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import applyLayoutSchemaToSpreadOperation from './applyLayoutSchemaToSpreadOperation';

const applyLayoutSchemaByIndexToCurrentSpreadOperation =
  (layoutSchemaIndex: number) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const spreadIndex = getCurrentSpreadIndex(state);
    const layoutSchema = getLayoutSchemas(state)[layoutSchemaIndex];
    dispatch(applyLayoutSchemaToSpreadOperation(spreadIndex, layoutSchema));
  };

export default applyLayoutSchemaByIndexToCurrentSpreadOperation;

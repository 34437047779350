import { fabric } from 'fabric';
import { RefObject } from 'react';

import { Coords, MediaAddon, MediaImage } from 'editor/src/store/design/types';

import { getRectangleBoundingBox } from 'editor/src/util/reflectDesignData/utils';

import fabricElementToObjectRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/fabricElementToObjectRect';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import { POINT_0_0 } from 'editor/src/component/EditorArea/useCanvasRotation';

import { ObjectRect } from './types';

function fabricPropsToImageUpdate(
  pageCoords: Coords,
  canvasRotation: CanvasRotation,
  px2mm: (size: number) => number,
  currentFrameRect: ObjectRect,
  frameRef: RefObject<fabric.Object>,
  imageRef: RefObject<fabric.Object>,
): Partial<Omit<MediaImage | MediaAddon, 'type' | 'subType'>> | null {
  const image = imageRef.current || frameRef.current;
  if (!frameRef.current || !image) {
    return null;
  }
  const frameRect = fabricElementToObjectRect(frameRef.current, currentFrameRect);

  const unrotatedFrameCoords = fabric.util.rotatePoint(
    new fabric.Point(frameRect.left, frameRect.top),
    canvasRotation.canvasCenter,
    -canvasRotation.angleRad,
  );

  const unrotatedImageCoords = fabric.util.rotatePoint(
    new fabric.Point((image.left || 0) - frameRect.left, (image.top || 0) - frameRect.top),
    POINT_0_0,
    fabric.util.degreesToRadians(-frameRect.angle),
  );

  const pr = (image.angle || 0) - frameRect.angle;
  // minimum size needed by the image to cover the frame
  const { width: minPw, height: minPh } = getRectangleBoundingBox(0, 0, frameRect.width, frameRect.height, pr);
  return {
    // frame
    x: px2mm(unrotatedFrameCoords.x - pageCoords.left),
    y: px2mm(unrotatedFrameCoords.y - pageCoords.top),
    width: px2mm(frameRect.width),
    height: px2mm(frameRect.height),
    r: frameRect.angle - canvasRotation.angleDeg,

    // image
    px: px2mm(unrotatedImageCoords.x),
    py: px2mm(unrotatedImageCoords.y),
    pw: px2mm(Math.max(image.getScaledWidth() || frameRect.width, minPw)),
    ph: px2mm(Math.max(image.getScaledHeight() || frameRect.height, minPh)),
    pr,

    cropOrigin: undefined,
  };
}

export default fabricPropsToImageUpdate;

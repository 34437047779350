import React from 'react';
import { useTranslation } from 'react-i18next';

import { MaxColorCountWarning } from 'editor/src/store/editorModules/warnings/types';
import { useSelector } from 'editor/src/store/hooks';

import AbstractWarningItem from './AbstractWarningItem';

interface Props {
  warning: MaxColorCountWarning;
  selected: boolean;
}

function MaxColorCountWarningItem({ warning, selected }: Props) {
  const { t } = useTranslation();
  const maxColor = useSelector((state) => state.hostSettings.digitizationRules.maxColorsCount);

  return (
    <AbstractWarningItem warning={warning} selected={selected} title={t('thread color limit')}>
      {t('product has too many thread colors', { color: maxColor })}
    </AbstractWarningItem>
  );
}

export default React.memo(MaxColorCountWarningItem);

import cn from 'classnames';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getImageUrl } from 'editor/src/store/design/selector/getImageElementUrl';
import { Condition } from 'editor/src/store/design/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { useSelector } from 'editor/src/store/hooks';
import shouldDigitizeCurrentSpread from 'editor/src/store/utils/shouldDigitizeCurrentSpread';

import IconAddImage from 'editor/src/component/Icon/IconAddImage';
import IconCross from 'editor/src/component/Icon/IconCross';

import { OPENING_ANIM_TIME } from '../../Sections';

import styles from './index.module.scss';

interface Props {
  option: Condition['options'][0];
  canRemove: boolean;
  focused: boolean;
  onUpdate: (optionId: string, label: string) => void;
  onRemove: (optionId: string) => void;
  updateImage: (optionId: string) => void;
  onBlur: () => void;
}

function ConditionValueBuilder({ option, canRemove, onUpdate, updateImage, onRemove, focused, onBlur }: Props) {
  const [label, setLabel] = useState(option.label);
  const image = useSelector((state) => (option.imageId ? getImageById(state, option.imageId) : undefined));
  const digitizeCurrentSpread = useSelector(shouldDigitizeCurrentSpread);
  const imageUrl = getImageUrl(image, false, digitizeCurrentSpread);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!focused) {
      return undefined;
    }
    const handle = window.setTimeout(() => {
      inputRef.current?.focus();
    }, OPENING_ANIM_TIME + 1);

    return () => window.clearTimeout(handle);
  }, [focused]);

  const onLabelUpdate = useCallback(
    debounce((optionId: string, label: string) => onUpdate(optionId, label), 300),
    [onUpdate],
  );

  function onChange(e: React.ChangeEvent) {
    const label = (e.target as HTMLInputElement).value;
    setLabel(label);
    onLabelUpdate(option.id, label);
  }

  function remove() {
    onRemove(option.id);
  }

  function onUpdateImage() {
    updateImage(option.id);
  }

  return (
    <div className={cn(styles.ConditionValueBuilder, 'cy-condition-value-builder')}>
      {image ? (
        <img
          className={cn(styles.thumbnail, 'cy-condition-value-builder-image')}
          crossOrigin="anonymous"
          src={imageUrl}
          onClick={onUpdateImage}
          alt={option.label}
        />
      ) : (
        <div
          className={cn(styles.emptyThumbnail, 'cy-condition-value-builder-empty-thumbnail')}
          onClick={onUpdateImage}
        >
          <IconAddImage className={styles.uploadIcon} />
        </div>
      )}
      <input
        ref={inputRef}
        onBlur={onBlur}
        value={label}
        onChange={onChange}
        className="cy-condition-value-builder-input"
      />
      {canRemove && (
        <div className={styles.remove} onClick={remove}>
          <IconCross />
        </div>
      )}
    </div>
  );
}

export default React.memo(ConditionValueBuilder);

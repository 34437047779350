/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconMoveBottom({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M12.2339 3.04234C12.0827 2.98589 11.9173 2.98589 11.7661 3.04234L3.45858 6.14388C3.09872 6.27823 2.91172 6.69055 3.0409 7.06481C3.17008 7.43907 3.56652 7.63356 3.92638 7.49921L12 4.48499L20.0736 7.49921C20.4335 7.63356 20.8299 7.43907 20.9591 7.06481C21.0883 6.69055 20.9013 6.27823 20.5414 6.14388L12.2339 3.04234Z"
          fill="#212121"
        />
        <path
          d="M3.45858 9.02389L11.7661 5.92234C11.9173 5.86589 12.0827 5.86589 12.2339 5.92234L20.5414 9.02389C20.9013 9.15824 21.0883 9.57055 20.9591 9.94481C20.8299 10.3191 20.4335 10.5136 20.0736 10.3792L12 7.36499L3.92638 10.3792C3.56652 10.5136 3.17008 10.3191 3.0409 9.94481C2.91172 9.57055 3.09872 9.15824 3.45858 9.02389Z"
          fill="#212121"
        />
        <path
          d="M12.2339 8.80235C12.0827 8.7459 11.9173 8.7459 11.7661 8.80235L3.4586 11.9039C3.18362 12.0066 3.00021 12.2777 3.00021 12.5816C3.00021 12.8854 3.18362 13.1566 3.4586 13.2592L8.6508 15.1977C9.01066 15.332 9.40711 15.1375 9.53629 14.7633C9.66547 14.389 9.47846 13.9767 9.1186 13.8424L5.74153 12.5816L12 10.245L18.2585 12.5816L14.8814 13.8424C14.5216 13.9767 14.3346 14.389 14.4638 14.7633C14.5929 15.1375 14.9894 15.332 15.3492 15.1977L20.5414 13.2592C20.8164 13.1566 20.9998 12.8854 20.9998 12.5816C20.9998 12.2777 20.8164 12.0066 20.5414 11.9039L12.2339 8.80235Z"
          fill="#212121"
        />
        <path
          d="M12.6923 13.0799C12.6923 12.6823 12.3824 12.3599 12 12.3599C11.6177 12.3599 11.3077 12.6823 11.3077 13.0799V18.5418L9.5524 16.7162C9.28204 16.435 8.8437 16.435 8.57335 16.7162C8.30299 16.9974 8.30299 17.4532 8.57335 17.7344L11.5105 20.7891C11.7809 21.0703 12.2192 21.0703 12.4895 20.7891L15.4267 17.7344C15.6971 17.4532 15.6971 16.9974 15.4267 16.7162C15.1563 16.435 14.718 16.435 14.4476 16.7162L12.6923 18.5418V13.0799Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconMoveBottom;

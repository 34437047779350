import cn from 'classnames';
import React, { useCallback } from 'react';

import RGBA from 'editor/src/util/color/rgbaUtils';

import TextInput from '../../PersonalizationContent/PersonalizationElements/TextInput';

interface Props {
  rgba: RGBA;
  onColorChange: (rgba: RGBA) => void;
  allowOpacity: boolean;
  className: string;
  isUnset: boolean;
}

const ALLOWED_NUMBER_CHAR_REGEX = /^[0-9, ]*$/;
export const IS_ALLOWED_CHAR = (char: string) => ALLOWED_NUMBER_CHAR_REGEX.test(char);

function RGBInput({ rgba, onColorChange, allowOpacity, className, isUnset }: Props) {
  const onRGBChanged = useCallback(
    (unparsedRGBA: string) => {
      // replace spaces by commas then remove duplicate commas and trim
      const normalizedInput = unparsedRGBA.replace(/\s+/g, ',').replace(/,+/g, ',').trim();
      const parts = normalizedInput.split(',');
      if (parts.length !== (allowOpacity ? 4 : 3)) {
        return;
      }

      const rgbaValues = [];
      for (let i = 0; i < parts.length; i += 1) {
        const value = parseInt(parts[i], 10);

        if (Number.isNaN(value) || value < 0 || value > 255) {
          return;
        }

        rgbaValues.push(value);
      }

      onColorChange(new RGBA({ r: rgbaValues[0], g: rgbaValues[1], b: rgbaValues[2], a: rgbaValues[3] }));
    },
    [allowOpacity],
  );

  return (
    <TextInput
      className={cn(className, 'cy-rgb-input')}
      onChange={onRGBChanged}
      value={isUnset ? '' : `${rgba.r}, ${rgba.g}, ${rgba.b}${allowOpacity ? `, ${rgba.a}` : ''}`}
      isAllowedChar={IS_ALLOWED_CHAR}
    />
  );
}

export default React.memo(RGBInput);

import cn from 'classnames';
import React, { useState } from 'react';

import ImageWithLoader from 'editor/src/component/ImageWithLoader';

import styles from './index.module.scss';

interface Props {
  title: string;
  previewUrls: string[];
}

function PreviewCaroussel({ previewUrls, title }: Props) {
  const [selectedPreview, setSelectedPreview] = useState(0);

  return (
    <div className={styles.PreviewCaroussel}>
      {previewUrls.length > 0 && (
        <ImageWithLoader
          className={styles.mainPreview}
          src={previewUrls[Math.min(selectedPreview, previewUrls.length - 1)]}
          alt={title}
          loaderMinHeight={300}
        />
      )}
      <div className={styles.minatures}>
        {previewUrls.map((url, i) => (
          <div
            onClick={() => setSelectedPreview(i)}
            className={cn(styles.miniature, 'cy-miniature', {
              [styles.active]: i === selectedPreview,
            })}
            style={{ backgroundImage: `url(${url})` }}
            key={url}
          />
        ))}
      </div>
    </div>
  );
}

export default React.memo(PreviewCaroussel);

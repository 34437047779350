import { MediaElement } from 'editor/src/store/design/types';
import isPersonalizationLockIgnored from 'editor/src/store/editor/selector/isPersonalizationLockIgnored';
import { RootState } from 'editor/src/store/index';

const isElementInteractable = (state: RootState, element: MediaElement) => {
  const ignorePersonalizationLock = isPersonalizationLockIgnored(state);
  return (element && !element.personalizationLocked) || ignorePersonalizationLock;
};

export default isElementInteractable;

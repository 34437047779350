import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormatOptionDisplay, FormatOption } from 'editor/src/store/editorModules/formats/types';

import ColorDot from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/elements/ColorElement/ColorDot';

import styles from './index.module.scss';

interface Props {
  option: FormatOption;
  display: FormatOptionDisplay;
  openDetails: (content: string) => void;
  selectOption: (callback: FormatOption['callback']) => void;
}

function ComplexOption({ option, display, selectOption, openDetails }: Props) {
  const hasPrice = Boolean(option.priceFormatted);
  const hasDiscount = option.priceFormatted !== option.priceInitialFormatted && option.priceInitialFormatted;
  const { t } = useTranslation();

  function onClick() {
    selectOption(option.callback);
  }

  function readMoreClick(e: React.MouseEvent) {
    e.stopPropagation();
    openDetails(option.description || '');
  }

  return (
    <div
      className={cn(styles.option, 'cy-format-option', {
        [styles.active]: option.active,
        [styles.noPrice]: !hasPrice,
        [styles.fill]: display === 'fill',
      })}
      onClick={onClick}
    >
      {(option.hexColor || option.texture) && (
        <ColorDot
          selected={!!option.active}
          colorHex={option.hexColor}
          disabled={option.disabled}
          texture={option.texture}
        />
      )}
      {option.iconUrl && <img className={styles.icon} src={option.iconUrl} alt={option.name} />}
      <div className={styles.content}>
        <div className={styles.name}>{option.name}</div>
        {option.description && (
          <button className={cn(styles.description, 'cy-description')} onClick={readMoreClick}>
            {t('read more')}
          </button>
        )}
        {hasPrice && (
          <div className={styles.price}>
            {hasDiscount ? (
              <>
                <span className={styles.priceInitial}>{option.priceInitialFormatted}</span>
                <span className={styles.priceDiscounted}>{option.priceFormatted}</span>
              </>
            ) : (
              <span className={styles.priceRegular}>{option.priceFormatted}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(ComplexOption);

import type { ThunkDispatch } from 'editor/src/store/hooks';

import { batch } from '../../batchedSubscribeEnhancer';
import applyImageOperation from '../../editor/operation/applyImageOperation';
import saveUndoRedoStateOperation from '../../editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import { unselectImagesAction } from '../../gallery/slice';

const addMultipleImagesToPageOperation = (imagesIds: string[]) => (dispatch: ThunkDispatch) => {
  batch(() => {
    // when we add multiple images to the page, we want to save the undo/redo state only once
    dispatch(saveUndoRedoStateOperation('Elements add'));

    // we want to apply the images with a shift in the x and y position
    const shift = 10; // px
    imagesIds.forEach((id, i) => dispatch(applyImageOperation(id, 'image', false, i * shift, i * shift)));
    dispatch(unselectImagesAction(imagesIds));
  });
};
export default addMultipleImagesToPageOperation;

import React from 'react';
import { useTranslation } from 'react-i18next';

import isAnyImageFailed from 'editor/src/store/gallery/selector/isAnyImageFailed';
import { useSelector } from 'editor/src/store/hooks';

import ButtonReuploadAll from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/ButtonReuploadAll';

import styles from './index.module.scss';

function ReuploadFailed() {
  const anyImageFailed = useSelector(isAnyImageFailed);
  const { t } = useTranslation();

  if (!anyImageFailed) {
    return null;
  }

  return (
    <div className={styles.reuploadFailed}>
      <div className={styles.message}>{t('editor-images-failed-to-upload')}</div>
      <ButtonReuploadAll />
    </div>
  );
}

export default React.memo(ReuploadFailed);

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SpreadGroup } from 'editor/src/store/design/types';
import applyLayoutSchemaToCurrentSpreadOperation from 'editor/src/store/editor/operation/applyLayoutSchemaToCurrentSpreadOperation';
import getCurrentPage from 'editor/src/store/editor/selector/getCurrentPage';
import { LayoutGeneratedTextType, LayoutGroup, LayoutSchema } from 'editor/src/store/editorModules/layouts/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import getGroupedSpreadMultiplier from 'editor/src/util/getGroupedSpreadMultiplier';
import generateLayout from 'editor/src/util/layouts/generateLayout';

import IconGrid from 'editor/src/component/Icon/IconGrid';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

const T_LETTER_SIZE = 16;
const now = new Date();
const YEAR = now.getFullYear();
const MIN_SPACING = 4;
const SPACING_RATIO = 4 / 100; // 4 mm for 100 mm

function getDisplayPerTextType(textType: LayoutGeneratedTextType, locale: string) {
  switch (textType) {
    case LayoutGeneratedTextType.MONTH:
      return now.toLocaleString(locale, { month: 'short' });
    case LayoutGeneratedTextType.YEAR:
      return YEAR;
    default:
      return '';
  }
}

interface Props {
  isActive: boolean;
  schemaSize: { width: number; height: number };
  layoutSchemas: LayoutSchema[];
  schema?: LayoutSchema;
  groupLayout?: LayoutGroup;
  spreadGroup?: SpreadGroup;
}

function SchemaItem({ schema, schemaSize, isActive, layoutSchemas, groupLayout, spreadGroup }: Props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const pageData = useSelector(getCurrentPage);
  const minDim = Math.min(pageData?.width ?? 0, pageData?.height ?? 0);
  const overriddenValue = Math.max(MIN_SPACING, minDim * SPACING_RATIO);
  const groupLayouts = groupLayout?.layouts;
  const schemas = groupLayouts
    ? groupLayouts.map((groupLayout) => layoutSchemas.find((schema) => schema.name === groupLayout) ?? layoutSchemas[0])
    : [schema ?? layoutSchemas[0]];
  const { widthMultiplier, heightMultiplier } = getGroupedSpreadMultiplier(
    spreadGroup?.spreadIndexes?.length ?? 1,
    spreadGroup?.position,
  );
  const layoutsFrameData = schemas.map((layoutSchema, i) => {
    const offsetY =
      groupLayouts && spreadGroup?.position === 'vertical' ? (pageData?.height ?? 0) * (heightMultiplier - i - 1) : 0;
    const offsetX =
      groupLayouts && spreadGroup?.position === 'horizontal' ? (pageData?.width ?? 0) * (widthMultiplier - i - 1) : 0;
    return generateLayout(
      layoutSchema,
      offsetX,
      offsetY,
      schemaSize.width,
      schemaSize.height,
      overriddenValue,
      undefined,
      overriddenValue,
    );
  });

  const onSchemaSelect = React.useCallback((schema: LayoutSchema) => {
    dispatch(applyLayoutSchemaToCurrentSpreadOperation(true, true, undefined, schema));
  }, []);

  const onGroupedSchemaSelect = React.useCallback((layoutGroup: LayoutGroup, spreadGroup: SpreadGroup) => {
    dispatch(
      applyLayoutSchemaToCurrentSpreadOperation(true, true, {
        layoutGroup,
        spreadGroup,
      }),
    );
  }, []);

  function onSelect() {
    if (spreadGroup && groupLayout) {
      onGroupedSchemaSelect(groupLayout, spreadGroup);
    }

    if (schema) {
      onSchemaSelect(schema);
    }
  }

  const isVertical = schemaSize?.height > schemaSize?.width || spreadGroup?.position === 'vertical';

  return (
    <WithTooltip
      overlay={t('editor-apply-layout-hint')}
      placement="bottom"
      className={cn(styles.schema, 'cy-layout-list-item', {
        [styles.active]: isActive,
        [styles.schemaVertical]: isVertical,
        [styles.schemaHorizontal]: !isVertical,
        'cy-schema-vertical': isVertical,
      })}
    >
      <svg
        width={schemaSize.width * widthMultiplier}
        height={schemaSize.height * heightMultiplier}
        viewBox={`0, 0, ${schemaSize.width * widthMultiplier}, ${schemaSize.height * heightMultiplier}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        // preserveAspectRatio="xMidYMid meet"
        className={styles.schemaPreview}
        onClick={onSelect}
      >
        {layoutsFrameData.map((framesData) =>
          framesData.map((frame, index) => {
            switch (frame.frameArea.type) {
              case 'text':
              case 'generated-text':
                return (
                  <React.Fragment key={index}>
                    <rect
                      x={frame.x}
                      y={frame.y}
                      width={frame.width}
                      height={frame.height}
                      stroke="#c3c3c3"
                      strokeDasharray="4,4"
                      strokeWidth="1"
                      fill="transparent"
                    />
                    <text
                      x={frame.x + frame.width / 2}
                      y={frame.y + frame.height / 2}
                      dominantBaseline="middle"
                      textAnchor="middle"
                      fontSize={T_LETTER_SIZE}
                      fill="#c3c3c3"
                    >
                      {frame.frameArea.type === 'text'
                        ? 'T'
                        : getDisplayPerTextType(frame.frameArea.textType, i18n.languageWithDash)}
                    </text>
                  </React.Fragment>
                );
              case 'grid': {
                const width = Math.floor(frame.width / 4);
                const height = Math.floor(frame.height / 4);
                return (
                  <React.Fragment key={index}>
                    <rect
                      x={frame.x}
                      y={frame.y}
                      width={frame.width}
                      height={frame.height}
                      stroke="#c3c3c3"
                      strokeWidth="1"
                      fill="transparent"
                    />
                    <IconGrid
                      x={frame.x + (frame.width - width) / 2}
                      y={frame.y + (frame.height - height) / 2}
                      width={width}
                      height={height}
                    />
                  </React.Fragment>
                );
              }
              case 'image':
              default:
                return (
                  <rect
                    key={index}
                    x={frame.x}
                    y={frame.y}
                    width={frame.width}
                    height={frame.height}
                    stroke="#c3c3c3"
                    strokeWidth="1"
                    fill="#dcdcdc"
                  />
                );
            }
          }),
        )}
      </svg>
    </WithTooltip>
  );
}

export default React.memo(SchemaItem);

import { useEffect } from 'react';

import loadFontOperation from './fonts/operation/loadFontOperation';
import { useDispatch, useSelector } from './hooks';

function useDefaultFontPreloader() {
  const dispatch = useDispatch();
  const defaultFontFamily = useSelector((state) => state.fonts.defaultFontStyles.fontFamily);
  useEffect(() => {
    void dispatch(loadFontOperation(defaultFontFamily));
  }, [defaultFontFamily]);
}

export default useDefaultFontPreloader;

import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormatMultiOptions, SizeGuide } from 'editor/src/store/editorModules/formats/types';

import { SelectorDetail } from 'editor/src/component/DesktopSidebar/TabContents/FormatsTabContent/Details';

import FormatSelectorOptions from './FormatSelectorOptions';

import styles from './index.module.scss';

import selectorStyles from 'editor/src/component/DesktopSidebar/TabContents/FormatsTabContent/selector.module.scss';

interface Props {
  selector: FormatMultiOptions;
  openDetails: (detail: SelectorDetail) => void;
}

function MultiOptionsSelector({ selector, openDetails }: Props) {
  const { t } = useTranslation();

  const openSizeGuide = (sizeGuide: SizeGuide) => {
    openDetails({ type: 'size-guide', data: sizeGuide });
  };

  const openOptionDetails = useCallback((content: string) => {
    openDetails({ type: 'text', content });
  }, []);

  if (selector.options.length === 0) {
    return null;
  }

  return (
    <div className={selectorStyles.selector}>
      <div className={selectorStyles.selectorName}>
        {selector.name}
        {selector.sizeGuide && (
          <div
            className={cn(styles.sizeGuide, 'cy-size-guide-button')}
            onClick={() => selector.sizeGuide && openSizeGuide(selector.sizeGuide)}
          >
            {t('editor-size-guide')}
          </div>
        )}
      </div>
      <FormatSelectorOptions selector={selector} openDetails={openOptionDetails} />
    </div>
  );
}

export default React.memo(MultiOptionsSelector);

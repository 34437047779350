import React from 'react';
import { useTranslation } from 'react-i18next';

import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import IconDiamond from 'editor/src/component/Icon/IconDiamond';

import Tab from './index';

interface Props {
  isActive: boolean;
}

function FoilLayersTab({ isActive }: Props) {
  const { t } = useTranslation();

  return (
    <Tab
      key={TAB_NAMES.FOIL_LAYERS}
      name={TAB_NAMES.FOIL_LAYERS}
      title={t('Foil')}
      icon={<IconDiamond isActive={isActive} />}
    />
  );
}
export default React.memo(FoilLayersTab);

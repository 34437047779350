import cn from 'classnames';
import React from 'react';

import isLoaderActive from 'editor/src/store/app/selector/isLoaderActive';
import { LoaderType } from 'editor/src/store/app/types';
import { useSelector } from 'editor/src/store/hooks';

import styles from './index.module.scss';

interface Props {
  overlay?: boolean;
  id: LoaderType;
}

function Loader({ overlay, id }: Props) {
  const loaderActive = useSelector((state) => isLoaderActive(state, id));
  if (!loaderActive) {
    return null;
  }

  return (
    <div className={cn(styles.Loader, `cy-loader-${id}`)}>
      {overlay && <div className={cn({ [styles.overlay]: overlay })} />}
      <div className={styles.spinner} />
    </div>
  );
}

export default Loader;

import React from 'react';

export const SCROLL_WIDTH = 10;
export interface Range {
  min: number;
  max: number;
}

export interface ScrollLocation {
  width: number;
  position: number;
}

export interface ScrollbarCommonProps {
  bottomBarHeight: number;
  bottomBarOffset: number;
  canvasSize: number;
  getTransformBoundaryCoordinates: () => { min: number; max: number };
}

export interface ScrollLocations {
  wrapper: ScrollLocation;
  control: ScrollLocation;
  viewportTransform: number;
}

export const getDeltaOnWrapperClick = (clickedPosition: number, initialPosition: number, scrollControlWidth: number) =>
  clickedPosition - initialPosition - scrollControlWidth / 2;

export const stopPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
};

export const getFullCanvasWidth = (transformMin: number, transformMax: number, canvasDimension: number) =>
  canvasDimension + Math.abs(transformMin - transformMax);
export const getCurrentCanvasPosition = (transformMin: number, transformMax: number, viewportTransform: number) =>
  1 - (viewportTransform - transformMax) / (transformMin - transformMax);

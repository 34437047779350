import React from 'react';

import { StructureIndex } from 'editor/src/store/design/types';

import IconMoveTop from 'editor/src/component/Icon/IconMoveTop';
import DistributeActionButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/DistributeActionButton';

interface Props {
  structureIndexes: StructureIndex[];
  disabled?: boolean;
}

function DistributeToFrontButton({ structureIndexes, disabled }: Props) {
  return (
    <DistributeActionButton
      structureIndexes={structureIndexes}
      icon={<IconMoveTop />}
      distributeTo="to-front"
      disabled={disabled}
    />
  );
}

export default React.memo(DistributeToFrontButton);

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import { Spread } from 'editor/src/store/design/types';
import getTemplates from 'editor/src/store/editorModules/templates/selector/getTemplates';
import { useSelector, useDispatch } from 'editor/src/store/hooks';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import { PopupName } from 'editor/src/component/Popup/popups';
import { useCanvasRendering } from 'editor/src/component/SpreadPreview';

import EmptyTemplatesPage from './EmptyTemplatesPage';
import TemplateItem from './TemplateItem';

import styles from './index.module.scss';

function TemplatesTabContent() {
  const { t } = useTranslation();
  const templates = useSelector(getTemplates);
  const dispatch = useDispatch();
  const { requestRender } = useCanvasRendering(templates?.length > 0);

  // TODO translate
  const onDelete = useCallback((id: string, name: string) => {
    dispatch(
      openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
        popupTitle: t('Delete design template'),
        textLines: [t(`Are you sure you want to delete the design temaplate "${name}"?`)],
        options: [
          {
            title: t('editor-cancel'),
          },
          {
            title: t('Delete'),
            onConfirm: () => {
              sendPostMessage('design.deleteDesignTemplate', { id });
            },
          },
        ],
        hideTitleIcon: true,
      }),
    );
  }, []);

  const onApply = useCallback((spread: Spread) => {
    // TODO implement apply
    // eslint-disable-next-line
    const a = spread;
  }, []);

  if (!templates?.length) {
    return <EmptyTemplatesPage />;
  }

  return (
    <>
      <TabContentHeader title={t('Templates')} />
      <ContentBlock scroll>
        <div className={styles.wrapper}>
          {templates.map((template, index) => (
            <TemplateItem
              key={template.id}
              id={template.id}
              // TODO support renaming
              name={`${template.name} ${index + 1}`}
              requestRender={requestRender}
              spread={template.structure.spreads[0]}
              onDelete={onDelete}
              onApply={onApply}
            />
          ))}
        </div>
      </ContentBlock>
    </>
  );
}

export default React.memo(TemplatesTabContent);

import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  scroll?: boolean;
  scrollAlways?: boolean;
  fill?: boolean;
  fillFlex?: boolean;
  noBorder?: boolean;
  id?: string;
  className?: string;
  noOverflow?: boolean;
}

function ContentBlock({ children, scroll, fill, fillFlex, scrollAlways, noBorder, id, className, noOverflow }: Props) {
  return (
    <div
      id={id}
      className={cn(styles.contentBlock, className, {
        [styles.scroll]: scroll,
        [styles.scrollAlways]: scrollAlways,
        [styles.fill]: fill,
        [styles.noBorder]: noBorder,
        [styles.fillFlex]: fillFlex,
        [styles.noOverflow]: noOverflow,
      })}
    >
      {children}
    </div>
  );
}

export default ContentBlock;

import React from 'react';

import Icon from './index';

interface Props {
  className?: string;
}

function IconMockup({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="5"
          y="5.5"
          width="10"
          height="10"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="1"
          y="1.5"
          width="18"
          height="18"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="4 4"
        />
      </svg>
    </Icon>
  );
}

export default React.memo(IconMockup);

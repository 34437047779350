import React from 'react';

import { Coords, DesignDimensions } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricLineComponent from 'editor/src/component/EditorArea/fabricComponents/FabricLineComponent';
import getDelimiters from 'editor/src/component/EditorArea/utils/getDelimiters';

interface Props {
  spreadWidth: number;
  spreadCoords: Coords;
  zIndex: number;
  dimensions: DesignDimensions;
}

const DASH_ARRAY = [3, 3];

function RollerStripes({ spreadCoords, spreadWidth, zIndex, dimensions }: Props) {
  const { mm2px } = useFabricUtils();
  const fabricCanvas = useFabricCanvas();
  const {
    tl: { y: topY },
    bl: { y: bottomY },
  } = fabricCanvas.calcViewportBoundaries();

  const repeatDesign = useSelector((state) => state.hostSettings.repeatDesign);

  const bleedWith = Math.abs(spreadWidth - dimensions.width);
  const delimiterWidth = dimensions.min_width + bleedWith;
  let maxWidth = spreadWidth;
  let { left } = spreadCoords;
  if (repeatDesign) {
    maxWidth = spreadWidth * (repeatDesign.count * 2) + 1;
    left = spreadCoords.left - mm2px(delimiterWidth) * repeatDesign.count;
  }
  const stripes = getDelimiters(delimiterWidth, maxWidth);

  return (
    <>
      {stripes.map((stripe, index) => (
        <FabricLineComponent
          key={index}
          x1={left + mm2px(stripe) - 0.5}
          y1={topY - 1000}
          x2={left + mm2px(stripe) - 0.5}
          y2={bottomY + 1000}
          evented={false}
          stroke="rgba(51, 51, 51, 0.5)"
          strokeDashArray={DASH_ARRAY}
          zIndex={zIndex}
          strokeUniform
          strokeWidth={1}
          objectCaching={false}
        />
      ))}
    </>
  );
}

export default React.memo(RollerStripes);

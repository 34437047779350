import React from 'react';

import requestPreviewsOperation from 'editor/src/store/editorModules/preview/operation/requestPreviewsOperation';
import getFlatPreviewAvailable from 'editor/src/store/editorModules/preview/selector/getFlatPreviewAvailable';
import { PreviewType } from 'editor/src/store/editorModules/preview/types';
import { useDispatch } from 'editor/src/store/hooks';

import IconEye from 'editor/src/component/Icon/IconEye';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection }) => !hasSelection && getFlatPreviewAvailable(state);

type Props = Pick<MenuItemProps, 'mode'>;

function ButtonFlatPreview({ mode }: Props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(requestPreviewsOperation(PreviewType.PREVIEW_FLAT));
  };

  return (
    <MenuButton
      Icon={IconEye}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-flat-preview"
      className="cy-button-flat-preview"
    />
  );
}

export default React.memo(ButtonFlatPreview);

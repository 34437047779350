import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setCurrentSpreadIndexAction } from 'editor/src/store/editor/slice';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { Thunk } from 'editor/src/store/hooks';

import { DisplayPage } from 'editor/src/component/EditorArea/utils/displayPageUtils';

import { reOrderSpreadsAction } from '../../slice';

const reOrderSpreadsOperation =
  (sourceIndex: number, destinationIndex: number, displayPages: DisplayPage[]): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const spreads = state.design.designData?.spreads;
    if (!spreads) {
      return;
    }

    const oldSpreadIndex = displayPages[sourceIndex]?.spreadIndex;
    const newSpreadIndex = displayPages[destinationIndex]?.spreadIndex;

    if (oldSpreadIndex === undefined || newSpreadIndex === undefined || oldSpreadIndex === newSpreadIndex) {
      return;
    }

    batch(() => {
      dispatch(saveUndoRedoStateOperation('spread reorder'));

      // calculating the new active spread index after reordering
      const spread = spreads[oldSpreadIndex];
      const newSpreads = [...spreads];
      newSpreads.splice(oldSpreadIndex, 1);
      newSpreads.splice(newSpreadIndex, 0, spread);
      const currentSpreadName = spreads[state.editor.currentSpreadIndex].name;
      const newCurrentSpreadIndex = newSpreads.findIndex((s) => s.name === currentSpreadName);
      dispatch(setCurrentSpreadIndexAction(newCurrentSpreadIndex));

      dispatch(reOrderSpreadsAction({ sourceIndex, destinationIndex }));
    });
  };

export default reOrderSpreadsOperation;

import React from 'react';

import { MockupPreviewVariant } from 'editor/src/store/mockup/types';

import { EntryProps } from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/MockupList';
import MockupListEntry from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/MockupListEntry';

function StoreProductVariantEntry({ entry, isLoading, onSelect }: EntryProps<MockupPreviewVariant>) {
  return (
    <MockupListEntry
      imageUrl={entry.previewUrl}
      title={entry.name}
      isLoading={isLoading}
      onClick={() => onSelect(entry)}
      noRightArrow
    />
  );
}

export default React.memo(StoreProductVariantEntry);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconMoreHorizontal({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="16"
        height="2"
        viewBox="0 0 16 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M8 2C7.44772 2 7 1.55228 7 1C7 0.447715 7.44772 0 8 0V2ZM8.5 0C9.05228 0 9.5 0.447715 9.5 1C9.5 1.55228 9.05228 2 8.5 2V0ZM8 0H8.5V2H8V0Z"
          fill="#212121"
        />
        <path
          d="M1 2C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0V2ZM1.5 0C2.05228 0 2.5 0.447715 2.5 1C2.5 1.55228 2.05228 2 1.5 2V0ZM1 0H1.5V2H1V0Z"
          fill="#212121"
        />
        <path
          d="M14.5 2C13.9477 2 13.5 1.55228 13.5 1C13.5 0.447715 13.9477 0 14.5 0V2ZM15 0C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2V0ZM14.5 0H15V2H14.5V0Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconMoreHorizontal;

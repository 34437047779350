/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

function IconLinked() {
  return (
    <Icon className={styles.icon}>
      <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.22368 6.44474L10.4474 4.22106C11.1842 3.4842 11.1842 2.28951 10.4474 1.55265C9.71049 0.815785 8.5158 0.815785 7.77894 1.55265L5.55526 3.77632M3.77632 5.55527L1.55265 7.77894C0.815785 8.5158 0.815785 9.71049 1.55265 10.4474C2.28951 11.1842 3.4842 11.1842 4.22106 10.4474L6.44474 8.22368M7.77894 4.22106L4.22106 7.77894"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

export default React.memo(IconLinked);

import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import { MediaImage, MediaText, DropShadow, LeaningShadow } from 'editor/src/store/design/types';
import { setCurrentStyleAction } from 'editor/src/store/editor/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import { logEvent } from 'editor/src/amplitude';
import isShadowEmpty from 'editor/src/util/isShadowEmpty';

import { getElementShadow } from 'editor/src/component/DesktopSidebar/TabContents/EffectsTabContent/MediaShadow/ShadowControls/utils';

import applyToSelectedMediaTypeOperation from './applyToSelectedMediaTypeOperation';

const applyShadowToSelectionOperation =
  (shadow: DropShadow | LeaningShadow | undefined, preventSavingState = false) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const selectedElement = getSelectedMediaElementsObjects(state)?.[0];
    if (!selectedElement || (selectedElement.type !== 'text' && selectedElement.type !== 'image')) {
      return;
    }

    dispatch(
      applyToSelectedMediaTypeOperation(
        selectedElement.type,
        (element) => {
          let elementUpdate;
          if (selectedElement.type === 'text') {
            elementUpdate = {} as Partial<MediaText>;
            elementUpdate.extra = { ...element?.extra, shadow };
          } else {
            elementUpdate = {} as Partial<MediaImage>;
            elementUpdate.shadow = shadow;
          }

          logEvent('element modified', {
            type: selectedElement.type,
            property: 'shadow',
            value: !isShadowEmpty(shadow),
          });
          dispatch(
            setCurrentStyleAction({
              type: selectedElement.type,
              update: { shadow: shadow ?? getElementShadow(element) },
            }),
          );

          return elementUpdate;
        },
        preventSavingState,
      ),
    );
  };

export default applyShadowToSelectionOperation;

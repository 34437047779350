import React from 'react';
import { useTranslation } from 'react-i18next';

import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

function ErrorBadge() {
  const { t } = useTranslation();

  return (
    <WithTooltip overlay={t('editor-image-failed-to-upload')}>
      <div className={styles.errorBadge}>{t('editor-error')}</div>
    </WithTooltip>
  );
}

export default ErrorBadge;

import cn from 'classnames';
import React from 'react';

import ProductIntro from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductIntro';
import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';

import PreviewCaroussel from './PreviewCaroussel';

import styles from './index.module.scss';

interface Props {
  productTitle: string;
  productDescription: string;
  categoryTitle: string;
  previewUrls: string[];
}

function ProductInfo({ productTitle, productDescription, categoryTitle, previewUrls }: Props) {
  return (
    <div className={cn(styles.ProductInfo, 'cy-product-info')}>
      <PreviewCaroussel previewUrls={previewUrls} title={productTitle} />
      <ProductIntro title={productTitle} categoryTitle={categoryTitle} />
      <PropertySeparator bigMargin />
      <div
        className={styles.productDescription}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: productDescription }}
      />
    </div>
  );
}

export default React.memo(ProductInfo);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { MediaMockupPlaceholder } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const updatePlaceholderFitOperation = (fit: boolean) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const placeholderIndex =
    state.design.designData?.spreads[0].pages[0].groups.media?.findIndex(isMediaMockupPlaceholder);
  if (placeholderIndex === undefined || placeholderIndex === -1) {
    return;
  }

  const element = state.design.designData?.spreads[0].pages[0].groups.media?.[
    placeholderIndex
  ] as MediaMockupPlaceholder;

  batch(() => {
    dispatch(saveUndoRedoStateOperation('change placeholder fit'));
    let { width, height, x, y } = element;
    if (fit) {
      width = Math.max(width, height);
      height = width;
    } else if (width / element.variant.width > height / element.variant.height) {
      width = (height * element.variant.width) / element.variant.height;
    } else {
      height = (width * element.variant.height) / element.variant.width;
    }

    x += (element.width - width) / 2;
    y += (element.height - height) / 2;

    dispatch(
      updateMediaElementAction({
        elementAddress: {
          spreadIndex: 0,
          pageIndex: 0,
          elementIndex: placeholderIndex,
        },
        elementUpdate: {
          area_fit: fit || undefined,
          width,
          height,
          x,
          y,
        },
      }),
    );
  });
};

export default updatePlaceholderFitOperation;

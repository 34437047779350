import { RootState } from 'editor/src/store/index';

import getAddonById from './getAddonById';
import getAddonURL from './getAddonURL';

const getAddonUrlById = (state: RootState, addonId: string) => {
  if (!addonId) {
    return undefined;
  }

  const addon = getAddonById(state, addonId);
  return addon ? getAddonURL(addon) : undefined;
};

export default getAddonUrlById;

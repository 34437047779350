import React from 'react';
import { useTranslation } from 'react-i18next';

import { MediaText } from 'editor/src/store/design/types';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { PluginName } from 'editor/src/store/plugins/types';

import EffectsElement from 'editor/src/component/DesktopSidebar/TabContents/EffectsTabContent/EffectsElement';
import IconTextOutline from 'editor/src/component/Icon/IconTextOutline';

import TextOutlineControls from './TextOutlineControls';

interface Props {
  selectedElement: MediaText;
}

function TextOutline({ selectedElement }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hasStroke = !!selectedElement.extra.stroke;
  const defaultStroke = useSelector((state) => state.editor.currentStyles.text.stroke);

  const onStrokeSwitched = () => {
    dispatch(
      applyFontStylesToSelectionOperation({
        stroke: hasStroke ? undefined : defaultStroke,
      }),
    );
  };

  return (
    <EffectsElement
      controlId="outline"
      title={t('editor-text-outline')}
      icon={<IconTextOutline />}
      switchStatus={hasStroke}
      pluginName={selectedElement.type === 'text' ? PluginName.TextEffects : undefined}
      onToggled={onStrokeSwitched}
    >
      <TextOutlineControls selectedElement={selectedElement} stroke={selectedElement.extra.stroke ?? defaultStroke} />
    </EffectsElement>
  );
}

export default React.memo(TextOutline);

import React from 'react';

import { useDispatch, useSelector } from 'editor/src/store/hooks';
import startMockupDownloadOperation from 'editor/src/store/mockup/operation/startMockupDownloadOperation';

import IconDownload from 'editor/src/component/Icon/IconDownload';
import IconLoading from 'editor/src/component/Icon/IconLoading';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state) => state.mockup.scenes.categories.length > 0; // hack to know if we're in the mockup editor

type Props = Pick<MenuItemProps, 'mode'>;

function ButtonDownload({ mode }: Props) {
  const appliedVariant = useSelector((state) => state.mockup.preview.appliedVariant);
  const downloading = useSelector((state) => state.mockup.preview.downloading);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(startMockupDownloadOperation());
  };

  return (
    <MenuButton
      disabled={!appliedVariant || downloading}
      Icon={downloading ? IconLoading : IconDownload}
      mode={mode}
      handleClick={handleClick}
      i18nKey="Download"
      className="cy-button-download"
    />
  );
}

export default React.memo(ButtonDownload);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { getImageUrl } from 'editor/src/store/design/selector/getImageElementUrl';
import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import type { Thunk } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import { PluginName } from 'editor/src/store/plugins/types';
import shouldDigitizeCurrentSpread from 'editor/src/store/utils/shouldDigitizeCurrentSpread';

import getNewImage from 'editor/src/util/getNewImage';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import { elementUuids } from 'editor/src/util/uuids';

import { canShow as canChangeThreadColors } from 'editor/src/component/TopMenuDesktop/buttons/ButtonThreadColor';

import getReplacableImageElement from '../../editor/selector/getReplacableImageElement';
import shouldDigitizeSpreadIndex from '../../utils/shouldDigitizeSpreadIndex';

import addMediaElementOperation from './addMediaElementOperation';
import updateImageIdByUuidOperation from './updateImageIdByUuidOperation';

const addNewImageToPageOperation =
  (
    spreadIndex: number,
    pageIndex: number,
    imageId: string,
    x?: number,
    y?: number,
    saveUndoRedoStep = true,
    shiftX?: number,
    swiftY?: number,
  ): Thunk =>
  (dispatch, getState, { i18n }) => {
    let state = getState();
    const galleryImage = getImageById(state, imageId);
    const digitizeCurrentSpread = shouldDigitizeCurrentSpread(state);
    const imageUrl = getImageUrl(galleryImage, false, digitizeCurrentSpread);
    const defaultElementPositioning = getHostSetting(state, 'defaultElementPositioning');
    const replacableElementUuid = getReplacableImageElement(state, spreadIndex, true, pageIndex)?.uuid;

    if (!imageUrl || !galleryImage) {
      return undefined;
    }

    const uuid = elementUuids.generate();

    const image = getNewImage(
      state,
      {
        ...galleryImage,
        ...{
          url: imageUrl,
          name: galleryImage.name || `${i18n.t('editor-image')} ${uuid}`,
        },
      },
      defaultElementPositioning,
      spreadIndex,
      uuid,
      x,
      y,
      shiftX,
      swiftY,
    );

    if (!image) {
      return undefined;
    }

    if (galleryImage.from?.type === PluginName.Graphics) {
      image.plugins = {
        [PluginName.Graphics]: { addonId: galleryImage.from.addonId },
      };
    }

    batch(() => {
      if (replacableElementUuid) {
        dispatch(addSelectedMediaElementOperation(replacableElementUuid));
        dispatch(updateImageIdByUuidOperation(replacableElementUuid, imageId, saveUndoRedoStep));
      } else {
        dispatch(addMediaElementOperation(spreadIndex, pageIndex, image, saveUndoRedoStep));
      }

      sendPostMessage('log.addImage', undefined);
      if (shouldDigitizeSpreadIndex(state, spreadIndex)) {
        dispatch(addSelectedMediaElementOperation(uuid));
        state = getState();
        if (canChangeThreadColors(state)) {
          dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.THREAD_COLOR));
        }
      }
    });

    return image;
  };

export default addNewImageToPageOperation;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { MediaElement } from 'editor/src/store/design/types';
import { NotVisibleWarning } from 'editor/src/store/editorModules/warnings/types';

import AbstractWarningItem from './AbstractWarningItem';

interface Props {
  warning: NotVisibleWarning;
  element: MediaElement;
  selected: boolean;
}

function NotVisibleWarningItem({ warning, element, selected }: Props) {
  const { t } = useTranslation();
  return (
    <AbstractWarningItem
      warning={warning}
      element={element}
      selected={selected}
      title={t('Element outside of the print area')}
    >
      {t('element-outside-print-area-explanation')}
    </AbstractWarningItem>
  );
}

export default React.memo(NotVisibleWarningItem);

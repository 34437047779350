import cn from 'classnames';
import React, { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'editor/src/store/hooks';
import addDesignOptionOperation from 'editor/src/store/variants/operation/addDesignOptionOperation';
import setDesignOptionsEnabledOperation from 'editor/src/store/variants/operation/setDesignOptionsEnabledOperation';
import { updateDesignOptionControlTitleAction } from 'editor/src/store/variants/slice';

import DesignOptionsImg from 'editor/src/static/design-options.png';

import DropDown from 'editor/src/component/DropDown';
import IconInfo from 'editor/src/component/Icon/IconInfo';
import IconPlus from 'editor/src/component/Icon/IconPlus';
import SwitchControl from 'editor/src/component/SwitchControl';

import OptionEntry from './OptionEntry';

import styles from './index.module.scss';

function DesignOptions() {
  const [isDesignOptionToolTipVisible, setIsDesignOptionToolTipVisible] = useState(false);
  const designOptionsRef = useRef<HTMLDivElement>(null);
  const designOptions = useSelector((state) => state.variants.designOptionsControl)?.[0];
  const isDesignOptionsEnabled = useSelector((state) => state.variants.designOptionsEnabled);
  const variationGroups = useSelector((state) => state.variants.variationGroups);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onDesignOptionSwitch = () => {
    dispatch(setDesignOptionsEnabledOperation(!isDesignOptionsEnabled));
  };

  const onDesignOptionToolTipClick = () => {
    setIsDesignOptionToolTipVisible(!isDesignOptionToolTipVisible);
  };
  const onDesignOptionToolTipClose = () => {
    setIsDesignOptionToolTipVisible(false);
  };

  const onDesignOptionTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch(
      updateDesignOptionControlTitleAction({
        controlKey: 'design-option',
        title: value,
      }),
    );
  };

  const onAddDesignOption = () => {
    dispatch(addDesignOptionOperation());
  };

  return (
    <>
      <div className={cn(styles.designOptionsContainer)}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{t('Design Options')}</div>
          <div ref={designOptionsRef} onClick={onDesignOptionToolTipClick} className={styles.infoIcon}>
            <IconInfo className={styles.infoIcon} />
          </div>
        </div>
        <DropDown
          isVisible={isDesignOptionToolTipVisible}
          className={styles.tooltip}
          onClickOutside={onDesignOptionToolTipClose}
          openPosition="right-fromtop"
        >
          <img src={DesignOptionsImg} alt="Design Options" className={styles.tooltipImg} />
          <div className={cn(styles.tooltipContent, 'm-2')}>
            {t('When on, your customer will be able to choose between different design options in the product page')}
          </div>
        </DropDown>
        <SwitchControl
          title=""
          on={isDesignOptionsEnabled}
          onSwitch={onDesignOptionSwitch}
          disabled={!variationGroups.length}
          className="cy-design-options-switch"
        />
      </div>
      {isDesignOptionsEnabled && (
        <>
          <div className={styles.fieldName}>{t('Field Name')}</div>
          <input
            placeholder={t('Choose a style')}
            className={cn(styles.inputField, 'cy-design-options-field-name')}
            value={designOptions?.title ?? ''}
            onChange={onDesignOptionTitleChange}
          />
          {designOptions?.options.map((designOption, index) => (
            <OptionEntry
              designOption={designOption}
              showBinIcon={designOptions?.options.length > 2}
              key={index}
              index={index}
            />
          ))}
          <button
            className={cn(styles.addNewDesignOption, 'mt-2 mb-2', 'cy-design-option-add')}
            onClick={onAddDesignOption}
          >
            <IconPlus className={styles.icon} />
            {t('Add new design option')}
          </button>
        </>
      )}
    </>
  );
}

export default React.memo(DesignOptions);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconRight({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M5 17C4.5875 17 4.23437 16.8531 3.94062 16.5594C3.64687 16.2656 3.5 15.9125 3.5 15.5V4.5C3.5 4.0875 3.64687 3.73438 3.94062 3.44063C4.23437 3.14688 4.5875 3 5 3H9.75C9.9625 3 10.1406 3.07145 10.2844 3.21435C10.4281 3.35727 10.5 3.53435 10.5 3.7456C10.5 3.95687 10.4281 4.13542 10.2844 4.28125C10.1406 4.42708 9.9625 4.5 9.75 4.5H5V15.5H16V10.75C16 10.5375 16.0715 10.3594 16.2144 10.2156C16.3573 10.0719 16.5344 10 16.7456 10C16.9569 10 17.1354 10.0719 17.2812 10.2156C17.4271 10.3594 17.5 10.5375 17.5 10.75V15.5C17.5 15.9125 17.3531 16.2656 17.0594 16.5594C16.7656 16.8531 16.4125 17 16 17H5ZM16 5.5625L9.08333 12.4792C8.93056 12.6319 8.75694 12.7049 8.5625 12.6979C8.36806 12.691 8.19444 12.6111 8.04167 12.4583C7.88889 12.3056 7.8125 12.1285 7.8125 11.9271C7.8125 11.7257 7.88889 11.5486 8.04167 11.3958L14.9375 4.5H13.25C13.0375 4.5 12.8594 4.42855 12.7156 4.28565C12.5719 4.14273 12.5 3.96565 12.5 3.7544C12.5 3.54313 12.5719 3.36458 12.7156 3.21875C12.8594 3.07292 13.0375 3 13.25 3H17.5V7.25C17.5 7.4625 17.4285 7.64063 17.2856 7.78438C17.1427 7.92813 16.9656 8 16.7544 8C16.5431 8 16.3646 7.92813 16.2188 7.78438C16.0729 7.64063 16 7.4625 16 7.25V5.5625Z"
          fill="#007CB4"
        />
      </svg>
    </Icon>
  );
}

export default IconRight;

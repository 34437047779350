import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import applyLayoutSchemaToSpreadOperation from 'editor/src/store/editor/operation/applyLayoutSchemaToSpreadOperation';
import { LayoutGroup } from 'editor/src/store/editorModules/layouts/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const applyGroupedLayoutToAllSpreadsOperation =
  (layoutGroup: LayoutGroup, saveUndoRedo = true, force: 'layout-applied' | 'same-layout' | undefined = undefined) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const { designData } = state.design;
    batch(() => {
      saveUndoRedo && dispatch(saveUndoRedoStateOperation('Apply layout to all spreads'));

      designData?.spreads.forEach((spread, spreadIndex) => {
        if (spread.layoutSchemaName && !force) {
          return;
        }

        const spreadGroup = designData.spread_groups?.find(
          (spreadGroup) => spreadGroup.spreadIndexes[0] === spreadIndex,
        );
        if (
          !spreadGroup ||
          (spread.layoutSchemaName === layoutGroup.name && force !== 'same-layout') ||
          spreadGroup.layoutTag !== layoutGroup.tag
        ) {
          return;
        }
        dispatch(applyLayoutSchemaToSpreadOperation(spreadIndex, undefined, undefined, { spreadGroup, layoutGroup }));
      });
    });
  };

export default applyGroupedLayoutToAllSpreadsOperation;

import { MediaImage } from 'editor/src/store/design/types';

import limitPrecision from 'editor/src/util/limitPrecision';
import { getAdjustedCropPosition } from 'editor/src/util/reflectDesignData/updateImageElement';
import { getRectangleBoundingBox } from 'editor/src/util/reflectDesignData/utils';

const getCropZoomScale = (image: MediaImage, scale: number) => {
  const bbox = getRectangleBoundingBox(0, 0, image.width, image.height, image.pr);

  let ph;
  let pw;
  // scale by shortest side
  if (image.pw > image.ph) {
    ph = bbox.height * scale;
    pw = ph * (image.pw / image.ph);
  } else {
    pw = bbox.width * scale;
    ph = pw * (image.ph / image.pw);
  }

  if (pw < bbox.width) {
    pw = bbox.width;
    ph = pw * (image.ph / image.pw);
  } else if (ph < bbox.height) {
    ph = bbox.height;
    pw = ph * (image.pw / image.ph);
  }

  const [px, py] = getAdjustedCropPosition(
    image,
    bbox,
    {
      width: image.width,
      height: image.height,
      pw,
      ph,
    },
    bbox,
    image.pr,
  );

  return {
    px,
    py,
    ph: limitPrecision(ph),
    pw: limitPrecision(pw),
  };
};

export default getCropZoomScale;

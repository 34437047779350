import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { Dimensions } from 'editor/src/store/design/types';
import resetSessionImageOperation from 'editor/src/store/gallery/operation/resetSessionImageOperation';
import { replaceImageAction, replaceSessionImageAction } from 'editor/src/store/gallery/slice';
import { ImageState, ImageColors } from 'editor/src/store/gallery/types';
import getMimeTypeEnum from 'editor/src/store/gallery/utils/getMimeTypeEnum';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import isWebPSupported from 'editor/src/util/isWebPSupported';
import loadImageDimensions from 'editor/src/util/loadImageDimensions';

import replaceImageAfterUploadOperation from './replaceImageAfterUploadOperation';

const addonUploadedOperation =
  ({
    addonId,
    assetId,
    url,
    thumbnailUrl,
    mimeType,
    colors,
    quantizedColors,
  }: {
    addonId: string;
    assetId: string;
    url: string;
    thumbnailUrl: string;
    mimeType: string;
    colors?: ImageColors;
    quantizedColors?: string[];
  }) =>
  async (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const image = state.gallery.images.find((image) => image.id === addonId);
    if (!image) {
      return;
    }

    const finalThumbnailUrl = isWebPSupported ? thumbnailUrl || url : url;

    let dim: Dimensions = { width: 100, height: 100 };
    try {
      dim =
        image.width && image.height
          ? { width: image.width, height: image.height }
          : await loadImageDimensions(finalThumbnailUrl, 'anonymous', {
              executor: 'addonUploadedOperation',
              addonId,
              assetId,
            });
    } catch {
      /* */
    }

    batch(() => {
      dispatch(replaceImageAfterUploadOperation(addonId, assetId, url));
      const uniqColors = new Set([...(colors?.fill ?? []), ...(colors?.stroke ?? [])]);
      dispatch(
        replaceImageAction({
          prevImageId: addonId,
          image: {
            ...image,
            ...dim,
            state: ImageState.UPLOADED,
            id: assetId,
            url,
            thumbnailUrl: finalThumbnailUrl,
            type: getMimeTypeEnum(mimeType),
            colors: uniqColors.size > 0 ? [...uniqColors] : undefined,
            quantizedColors,
          },
        }),
      );
      dispatch(
        replaceSessionImageAction({
          prevImageId: addonId,
          currentImageId: assetId,
        }),
      );
      const state = getState();
      const uploadingImagesCount = state.gallery.images.filter(
        (image) =>
          state.gallery.sessionImages.includes(image.id) &&
          (image.state === ImageState.LOCAL_PREVIEW || image.state === ImageState.REMOTE_PREVIEW),
      ).length;
      if (uploadingImagesCount === 0) {
        dispatch(resetSessionImageOperation());
      }
    });
  };

export default addonUploadedOperation;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch } from 'editor/src/store/hooks';

import FittingsRow from 'editor/src/component/FittingsRow';
import LinkButton from 'editor/src/component/LinkButton';

import styles from './index.module.scss';

interface Props {
  fillVisible: boolean;
  fitVisible: boolean;
  stretchVisible: boolean;
  propertiesVisible: boolean;
}

function FittingsContainer({ fillVisible, fitVisible, stretchVisible, propertiesVisible }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showProperties = () => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.PROPERTIES));
  };

  if (!propertiesVisible && !fillVisible && !fitVisible && !stretchVisible) {
    return null;
  }

  return (
    <div className="mb-2 cy-fittings-container">
      <div className={cn(styles.title, 'row-flex row-flex--between')}>
        <div>{t('editor-fittings')}</div>
        {propertiesVisible && (
          <LinkButton className="cy-show-properties" onClick={showProperties}>
            {t('editor-properties')}
          </LinkButton>
        )}
      </div>
      <FittingsRow fillVisible={fillVisible} fitVisible={fitVisible} stretchVisible={stretchVisible} />
    </div>
  );
}

export default FittingsContainer;

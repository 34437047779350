/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconTextOutline({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 6.66654C5 5.74607 5.74619 4.99988 6.66667 4.99988H33.3333C34.2538 4.99988 35 5.74607 35 6.66654V16.3635C35 17.284 34.2538 18.0302 33.3333 18.0302H27.0006V33.3331C27.0006 34.2536 26.2544 34.9998 25.3339 34.9998H14.6672C13.7468 34.9998 13.0006 34.2536 13.0006 33.3331V18.0302H6.66667C5.74619 18.0302 5 17.284 5 16.3635V6.66654ZM8.33333 8.33321V14.6968H14.6672C15.5877 14.6968 16.3339 15.443 16.3339 16.3635V31.6665H23.6672V16.3635C23.6672 15.443 24.4134 14.6968 25.3339 14.6968H31.6667V8.33321H8.33333Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconTextOutline;

import { MediaRectangle } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';

import getSelectedMediaElementsObjects from './getSelectedMediaElementsObjects';

const getSelectedElementProperty = <E extends MediaRectangle, T extends keyof E>(
  state: RootState,
  key: T,
  type: 'rectangle' | 'line',
): E[T] => {
  const selectedElements = getSelectedMediaElementsObjects(state);

  const element = selectedElements.find((elt) => elt?.type === type) as E;
  return element && element[key];
};

export default getSelectedElementProperty;

import cn from 'classnames';
import React, { useState, useEffect, useImperativeHandle, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Unit } from 'editor/src/store/editor/types';

import limitPrecision from 'editor/src/util/limitPrecision';
import { convertMmToUnit, convertUnitToMm } from 'editor/src/util/math';

import { FieldType } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ManualControls/ProductSizeControl/ProductSize/index';
import IconHeight from 'editor/src/component/Icon/IconHeight';
import IconWidth from 'editor/src/component/Icon/IconWidth';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  fieldType: FieldType;
  baseValue?: number;
  onBlur?: (input: Ref, fieldType: FieldType) => void;
  onKeyUp?: (input: Ref, fieldType: FieldType) => void;
  unit: Unit;
}

export interface Ref {
  getValue: () => number;
  setValue: (value: number) => void;
  isActive: () => boolean;
  focus: () => void;
}

function Dimension({ fieldType, baseValue, onBlur, onKeyUp, unit }: Props, ref: React.Ref<Ref>) {
  const { t } = useTranslation();
  const convValue = baseValue !== undefined ? limitPrecision(convertMmToUnit(baseValue, unit), 1) : '';
  const [inputValue, setInputValue] = useState(`${convValue}`);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (baseValue !== undefined) {
      setInputValue(`${limitPrecision(convertMmToUnit(Math.floor(baseValue), unit), 1)}`);
    } else {
      setInputValue('');
    }
  }, [baseValue, unit]);

  const refObject = useMemo(
    () => ({
      getValue: () => {
        try {
          const value = convertUnitToMm(Number.parseFloat(inputRef.current?.value || ''), unit);
          if (Number.isNaN(value)) {
            return baseValue !== undefined ? baseValue : 0;
          }
          return value;
        } catch {
          return baseValue !== undefined ? baseValue : 0;
        }
      },
      setValue: (value: number) => setInputValue(`${limitPrecision(convertMmToUnit(Math.floor(value), unit), 1)}`),
      focus: () => {
        inputRef.current?.focus();
        inputRef.current?.select();
      },
      isActive: () => document.activeElement === inputRef.current,
    }),
    [baseValue, unit],
  );

  useImperativeHandle(ref, () => refObject, [refObject]);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = e.target.value;
    if (!Number.isNaN(+inputValue)) {
      setInputValue(inputValue);
    }
  }

  return (
    <div className={cn(styles.Dimension, 'cy-dimension')}>
      {fieldType === 'width' ? (
        <WithTooltip overlay={t('editor-width')}>
          <IconWidth />
        </WithTooltip>
      ) : (
        <WithTooltip overlay={t('editor-height')}>
          <IconHeight />
        </WithTooltip>
      )}
      <input
        ref={inputRef}
        className={cn(styles.dimensionInput, `cy-dimension-input-${fieldType}`)}
        type="text"
        value={inputValue}
        onChange={onChange}
        onBlur={() => onBlur?.(refObject, fieldType)}
        onKeyUp={() => onKeyUp?.(refObject, fieldType)}
      />
      <span className={styles.unit}>{unit}</span>
    </div>
  );
}

export default React.memo(React.forwardRef(Dimension));

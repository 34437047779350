/* eslint-disable max-len */

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

function IconUnlocked({ className, onClick }: Props) {
  return (
    <Icon className={className} onClick={onClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M19.25 16.45L17.75 14.9C18.4167 14.7167 18.9584 14.3623 19.375 13.837C19.7917 13.3123 20 12.7 20 12C20 11.1667 19.7084 10.4583 19.125 9.875C18.5417 9.29167 17.8334 9 17 9H13V7H17C18.3834 7 19.5627 7.48734 20.538 8.462C21.5127 9.43734 22 10.6167 22 12C22 12.95 21.7544 13.825 21.263 14.625C20.771 15.425 20.1 16.0333 19.25 16.45ZM15.85 13L13.85 11H16V13H15.85ZM19.8 22.6L1.40002 4.2L2.80002 2.8L21.2 21.2L19.8 22.6ZM11 17H7.00002C5.61669 17 4.43769 16.5123 3.46302 15.537C2.48769 14.5623 2.00002 13.3833 2.00002 12C2.00002 10.85 2.35002 9.825 3.05002 8.925C3.75002 8.025 4.65002 7.43334 5.75002 7.15L7.60002 9H7.00002C6.16669 9 5.45836 9.29167 4.87502 9.875C4.29169 10.4583 4.00002 11.1667 4.00002 12C4.00002 12.8333 4.29169 13.5417 4.87502 14.125C5.45836 14.7083 6.16669 15 7.00002 15H11V17ZM8.00002 13V11H9.62502L11.6 13H8.00002Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconUnlocked;

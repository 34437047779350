import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getSpread from 'editor/src/store/design/selector/getSpread';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getLayoutSchemas from 'editor/src/store/editor/selector/getLayoutSchemas';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import groupLayouts from 'editor/src/util/layouts/groupLayouts';

import applyGroupedLayoutToAllSpreadsOperation from './applyGroupedLayoutToAllSpreadsOperation';
import applyLayoutToAllSpreadsOperation from './applyLayoutToAllSpreadsOperation';

const applyCurrentLayoutSchemaToAllSpreadsOperation =
  (force = false) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const spread = getSpread(state, getCurrentSpreadIndex(state));
    if (!spread) {
      return;
    }

    batch(() => {
      dispatch(saveUndoRedoStateOperation('Apply layout to all spreads'));

      const layoutGroup = groupLayouts.find((groupLayout) => groupLayout.name === spread.layoutSchemaName);
      if (layoutGroup) {
        dispatch(applyGroupedLayoutToAllSpreadsOperation(layoutGroup, false, force ? 'same-layout' : undefined));
        return;
      }

      const layout = getLayoutSchemas(state).find((layout) => layout.name === spread.layoutSchemaName);
      if (layout) {
        dispatch(applyLayoutToAllSpreadsOperation([layout], false, force ? 'same-layout' : undefined));
      }
    });
  };

export default applyCurrentLayoutSchemaToAllSpreadsOperation;

import cloneDeep from 'lodash/cloneDeep';

import isMobile from 'editor/src/store/app/selector/isMobile';
import controlDesignDataAndGetColorOverrides, {
  ColorOverride,
} from 'editor/src/store/design/operation/controlDesignDataAndGetColorOverrides';
import setupUuidGenerators from 'editor/src/store/design/operation/setupUuidGenerators';
import getSpreadsCount from 'editor/src/store/design/selector/getSpreadsCount';
import { DesignData } from 'editor/src/store/design/types';
import requestVariantsColorOverrides from 'editor/src/store/design/util/requestVariantsColorOverrides';
import updateResizableLayoutAreaOperation from 'editor/src/store/editor/operation/resizableElement/updateResizableLayoutAreaOperation';
import setCurrentSpreadIndexOperation from 'editor/src/store/editor/operation/setCurrentSpreadIndexOperation';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import loadFontOperation from 'editor/src/store/fonts/operation/loadFontOperation';
import loadDesignDataFonts from 'editor/src/store/fonts/utils/loadDesignDataFonts';
import type { AsyncThunk } from 'editor/src/store/hooks';
import applyDefaultMockupSceneIfNoneAppliedOperation from 'editor/src/store/mockup/operation/applyDefaultMockupSceneIfNoneAppliedOperation';
import getDesignOptionKey from 'editor/src/store/variants/helpers/getDesignOptionKey';
import getProductDataVariationGroups from 'editor/src/store/variants/helpers/getProductDataVariationGroups';
import { ProductDataPayload } from 'editor/src/store/variants/reducer/setProductDataReducer';
import getResizableProductForGivenVariant from 'editor/src/store/variants/selector/getResizableProductForGivenVariant';
import { setProductDataAction } from 'editor/src/store/variants/slice';
import { ExistingVariant } from 'editor/src/store/variants/types';

import applyLayoutPerProductUid from 'editor/src/util/layouts/applyLayoutPerProductUid';

import { batch } from '../../batchedSubscribeEnhancer';
import toggleDynamicTabOperation from '../../editorModules/sidebar/operation/toggleDynamicTabOperation';
import hasSpotFinishing from '../helpers/hasSpotFinishing';

import checkForMissingTemplatesOperation from './checkForMissingTemplatesOperation';
import displaySelectedVariationOperation from './displaySelectedVariationOperation';
import setupProductSizeOperation from './setupProductSizeOperation';

const setProductDataOperation =
  (data: ProductDataPayload): AsyncThunk =>
  async (dispatch, getState, { i18n }) => {
    let state = getState();
    const designDataItems: DesignData[] = [];
    const variantColorOverrides: {
      productUid: string;
      optionKey: string | undefined;
      colorOverrides: ColorOverride[];
    }[] = [];
    data.existingVariants?.forEach((variant) => {
      if (variant.designData) {
        const colorOverrides = controlDesignDataAndGetColorOverrides({
          designData: variant.designData,
          i18n,
          spreadGroups: data?.groupedSpreadsPerProductUids?.[variant.productUid],
        });
        designDataItems.push(variant.designData);
        variantColorOverrides.push({
          productUid: variant.productUid,
          optionKey: getDesignOptionKey(variant),
          colorOverrides,
        });
      }
    });

    if (designDataItems.length) {
      try {
        await loadDesignDataFonts(designDataItems, state.fonts.availableFonts, state.gridDesigns.grids);
      } catch (e) {
        throw e || new Error('failed loading design fonts');
      }

      setupUuidGenerators(designDataItems);
    }

    await dispatch(loadFontOperation(state.fonts.defaultFontStyles.fontFamily));

    const { configuration, existingVariants = [], layoutPerProductUids = {}, groupedSpreadsPerProductUids = {} } = data;

    const updatedVariants: ExistingVariant[] = [];
    existingVariants.forEach((variant) => {
      if (!variant.designData || !layoutPerProductUids[variant.designData.product_uid]) {
        updatedVariants.push(variant);
        return;
      }
      const variantClone = cloneDeep(variant);
      if (variantClone.designData) {
        variantClone.designData = applyLayoutPerProductUid(
          variantClone.designData,
          state,
          true,
          i18n,
          layoutPerProductUids,
          groupedSpreadsPerProductUids,
          configuration.forceLayout,
        );
      }
      updatedVariants.push(variantClone);
    });

    const groups = getProductDataVariationGroups(data, updatedVariants, state, i18n);
    // request color overrides for each variant groups
    if (groups) {
      groups.forEach((group) => {
        variantColorOverrides.some(({ productUid, colorOverrides, optionKey }) => {
          if (
            !group.variationsInfo.some(
              ({ variation }) => variation.productUid === productUid && getDesignOptionKey(group) === optionKey,
            )
          ) {
            return false;
          }

          // if productUid matches we can request color overrides for current group
          requestVariantsColorOverrides(colorOverrides, group.key, optionKey);
          return true;
        });
      });
    }

    batch(() => {
      state = getState();
      dispatch(
        setProductDataAction({
          productData: data,
          updatedVariants,
          groups,
        }),
      );
      dispatch(setupProductSizeOperation());

      state = getState();
      // on mobile, if no default variant are selected, we open the product tab to force the user to select one
      if (
        !data.defaultProductUids.length &&
        !data.existingVariants?.length &&
        getSidebarActiveTab(state) !== TAB_NAMES.PRODUCT &&
        isMobile(state)
      ) {
        dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.PRODUCT));
      }

      if (!data.configuration.singleSelection) {
        dispatch(checkForMissingTemplatesOperation([]));
      }

      dispatch(displaySelectedVariationOperation());

      state = getState();
      if (getSpreadsCount(state) - 1 < state.editor.currentSpreadIndex) {
        dispatch(setCurrentSpreadIndexOperation(0));
      }

      const resizableElement = getResizableProductForGivenVariant(state);
      if (resizableElement && state.design.designData?.related_dimensions) {
        dispatch(updateResizableLayoutAreaOperation(resizableElement, state.design.designData?.related_dimensions));
      }

      dispatch(applyDefaultMockupSceneIfNoneAppliedOperation());

      dispatch(toggleDynamicTabOperation(TAB_NAMES.FOIL_LAYERS, hasSpotFinishing(data.product.productVariations), 4));
    });
  };

export default setProductDataOperation;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconCrop({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M17 23V19H7C6.45 19 5.97933 18.8043 5.588 18.413C5.196 18.021 5 17.55 5 17V7H1V5H5V1H7V17H23V19H19V23H17ZM17 15V7H9V5H17C17.55 5 18.021 5.196 18.413 5.588C18.8043 5.97933 19 6.45 19 7V15H17Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default React.memo(IconCrop);

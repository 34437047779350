/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';

import useShowRollerStripesControl from 'editor/src/util/useShowRollerStripesControl';

import Checkbox from 'editor/src/component/Checkbox';
import SettingsItem from 'editor/src/component/DesktopSidebar/TabContents/SettingsTabContent/SettingsItem';

function ShowRollerStripesCheckbox() {
  const { t } = useTranslation();
  const { showStripes, showControl, toggleStripes } = useShowRollerStripesControl();

  if (!showControl) {
    return null;
  }

  return (
    <SettingsItem descriptionTranslationKey="editor-settings-roller-stripes-description">
      <Checkbox
        on={showStripes}
        onChange={toggleStripes}
        label={t('editor-settings-roller-stripes')}
        className="cy-show-roller-stripes-checkbox"
      />
      <svg width="286" height="1" viewBox="0 0 286 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0" y1="0" x2="286" y2="0" stroke="white" />
        <line x1="0" y1="0" x2="286" y2="0" stroke="#FF00FF" strokeDasharray="3" />
      </svg>
    </SettingsItem>
  );
}

export default ShowRollerStripesCheckbox;

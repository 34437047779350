import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { setProductScenesAction } from 'editor/src/store/mockup/slice';
import { ProductSceneCategory } from 'editor/src/store/mockup/types';

import { fetching } from './fetchProductScenesOperation';
import setSelectedProductSceneOperation from './setSelectedProductSceneOperation';

export interface Input {
  productUid: string;
  categories: ProductSceneCategory[];
}

const setProductScenesOperation =
  ({ productUid, categories }: Input) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    batch(() => {
      fetching.delete(productUid);
      dispatch(setProductScenesAction({ productUid, categories }));

      const state = getState();
      const placeholder = state.design.designData?.spreads[0].pages[0].groups.media?.find(isMediaMockupPlaceholder);
      if (
        placeholder &&
        placeholder.variant.productUid === productUid &&
        !placeholder.variant.scene &&
        categories[0]?.scenes[0]
      ) {
        dispatch(setSelectedProductSceneOperation(categories[0].scenes[0], productUid, false));
      }
    });
  };

export default setProductScenesOperation;

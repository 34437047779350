/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconFilter({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g fill="#292F38" fillRule="evenodd" stroke="none" strokeWidth="0">
          <path d="M9 9a2 2 0 11.001-4.001A2 2 0 019 9zm13-3h-9.142A3.988 3.988 0 009 3a3.988 3.988 0 00-3.858 3H2v2h3.142A3.988 3.988 0 009 11a3.988 3.988 0 003.858-3H22V6z" />
          <path d="M15 19a2 2 0 11.001-4.001A2 2 0 0115 19zm7-3h-3.142A3.988 3.988 0 0015 13a3.988 3.988 0 00-3.858 3H2v2h9.142A3.988 3.988 0 0015 21a3.988 3.988 0 003.858-3H22v-2z" />
        </g>
      </svg>
    </Icon>
  );
}

export default IconFilter;

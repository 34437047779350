import { MediaElement } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';

import getSelectedMediaElementsObjects from './getSelectedMediaElementsObjects';

const getSelectedImagePersonalizationLocked = (state: RootState) =>
  Boolean(
    getSelectedMediaElementsObjects(state)?.find((element?: MediaElement) => element?.type === 'image')
      ?.personalizationLocked,
  );

export default getSelectedImagePersonalizationLocked;

import cn from 'classnames';
import React from 'react';

import Slider from '../../Slider';

interface Props {
  hue: number;
  className?: string;
  onChange: (hue: number) => void;
  onChanged: (hue: number) => void;
  hideCursor?: boolean;
}

const SLIDER_STYLE: React.CSSProperties = {
  height: 12,
  background:
    'linear-gradient(90deg, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%)',
};

const getHandleStyle = (hue: number) => {
  return {
    backgroundColor: `hsl(${hue}, 100%, 50%)`,
    width: 20,
    height: 20,
  };
};

function HueSelector({ hue, onChange, onChanged, className, hideCursor }: Props) {
  return (
    <Slider
      min={0}
      max={359}
      step={1}
      value={hue}
      onChange={onChange}
      onAfterChange={onChanged}
      hideTrack
      railStyle={SLIDER_STYLE}
      getHandleStyle={getHandleStyle}
      className={cn(className, 'cy-hue-selector')}
      hideHandle={hideCursor}
    />
  );
}

export default React.memo(HueSelector);

import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import downloadAndApplyFontFamilyOperation from 'editor/src/store/design/operation/downloadAndApplyFontFamilyOperation';
import getCurrentFontDefinition from 'editor/src/store/fonts/selector/getCurrentFontDefinition';
import { FontDefinition } from 'editor/src/store/fonts/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import getPluginIconByLevel from 'editor/src/store/plugins/selector/getPluginIconByLevel';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import { logEvent } from 'editor/src/amplitude';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import IconSearch from 'editor/src/component/Icon/IconSearch';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import FontFamily from './FontFamily';
import { extractSearchableTokens, getFilteredFonts } from './searchUtils';

import styles from './index.module.scss';

function FontFamiliesTabContent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { fontList, currentFontDef, pluginState, premiumLevel } = useSelector((state) => {
    const plugin = getPlugin(state, PluginName.Fonts);
    return {
      pluginState: plugin?.state,
      fontList: state.fonts.availableFonts,
      currentFontDef: getCurrentFontDefinition(state),
      premiumLevel: plugin?.extra?.premiumLevel,
    };
  }, shallowEqual);

  const availableFonts = useMemo(() => {
    if (pluginState === PluginState.ENABLED || pluginState === PluginState.NON_FUNCTIONAL) {
      return fontList;
    }
    return fontList.filter((font) => !font.metadata.isPremium);
  }, [fontList.length, pluginState]);

  const [filteredList, setFilteredList] = useState(availableFonts);
  useEffect(() => setFilteredList(availableFonts), [availableFonts]);

  const searchableTokens = useMemo(() => extractSearchableTokens(availableFonts), [availableFonts]);
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = useIsMobile();

  const popularFonts = useMemo(
    () => availableFonts.filter((font) => font.isPopular).sort((a, b) => (a.popularOrder || 0) - (b.popularOrder || 0)),
    [availableFonts],
  );

  // focus the search input on mount on desktop
  useEffect(() => {
    if (inputRef.current && !isMobile) {
      inputRef.current.focus();
    }
  }, []);

  const onSelect = useCallback(
    ({ metadata }: FontDefinition) => {
      const { fontFile } = metadata;
      if (pluginState === PluginState.NON_FUNCTIONAL && metadata.isPremium) {
        sendPostMessage('plugins.disabledPluginClick', {
          name: PluginName.Fonts,
          fontFamily: fontFile,
        });
        return;
      }

      void dispatch(downloadAndApplyFontFamilyOperation(fontFile));

      if (metadata.isPremium) {
        sendPostMessage('fonts.premiumFontSelected', undefined);
      }
    },
    [pluginState],
  );

  const searchedTermsRef = useRef(new Set<string>());
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (value.length > 2) {
        searchedTermsRef.current.add(value);
      }
    }, 500),
    [],
  );

  useEffect(
    () => () => {
      if (searchedTermsRef.current.size > 0) {
        logEvent('fonts searched', [...searchedTermsRef.current]);
      }
    },
    [],
  );

  function onFilter(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    if (!value) {
      setFilteredList(availableFonts);
    } else {
      const filteredFonts = getFilteredFonts(searchableTokens, value);
      debouncedSearch(value);
      setFilteredList(filteredFonts);
    }
  }

  if (!currentFontDef) {
    return null;
  }

  const PremiumIcon = getPluginIconByLevel(premiumLevel);
  const isFiltered = availableFonts.length > filteredList.length;

  return (
    <>
      <div className={styles.header}>
        <div className={styles.filterInput}>
          <IconSearch />
          <input
            type="text"
            className="cy-font-search"
            placeholder={t('editor-search')}
            onChange={onFilter}
            ref={inputRef}
          />
        </div>
      </div>
      <ContentBlock scroll>
        {!isFiltered && (
          <div className={styles.popularFonts}>
            <div className={styles.sectionTitle}>{t('Popular fonts')}</div>
            {popularFonts.map((fd) => (
              <FontFamily
                key={fd.metadata.fontFile}
                fontDefinition={fd}
                isActive={fd.metadata.fontFile === currentFontDef.metadata.fontFile}
                onSelect={onSelect}
                PremiumIcon={PremiumIcon}
                scrollIntoView
              />
            ))}
          </div>
        )}
        {!isFiltered && <div className={styles.sectionTitle}>{t('All fonts')}</div>}
        <div className="cy-all-fonts">
          {filteredList.map((fd) => (
            <FontFamily
              key={fd.metadata.fontFile}
              fontDefinition={fd}
              isActive={fd.metadata.fontFile === currentFontDef.metadata.fontFile}
              onSelect={onSelect}
              PremiumIcon={PremiumIcon}
              scrollIntoView={isFiltered || !fd.isPopular}
            />
          ))}
        </div>
      </ContentBlock>
    </>
  );
}

export default React.memo(FontFamiliesTabContent);

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconArrowFill2({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 10.2266L23 16.0001L13 21.7736V17.0001H0V15.0001H13V10.2266Z"
          fill="#383838"
        />
      </svg>
    </Icon>
  );
}

export default IconArrowFill2;

import React, { useCallback, useEffect } from 'react';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconProperties from 'editor/src/component/Icon/IconProperties';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import PropertiesTabContent from '../../DesktopSidebar/TabContents/PropertiesTabContent';
import { useIsMobile } from '../../useDetectDeviceType';
import useMobileMenu from '../../useMobileMenu';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { isSelectionLocked, isElementEmpty, selectedElementType }) =>
  !isSelectionLocked &&
  !isElementEmpty &&
  (selectedElementType === 'image' ||
    selectedElementType === 'text' ||
    selectedElementType === 'addon' ||
    selectedElementType === 'rectangle');
export const canShowInTopBar: CanShow = (state, menuContext) =>
  !state.hostSettings.editImageFlag && canShow(state, menuContext);

type Props = Pick<MenuItemProps, 'mode' | 'close'>;

function ButtonProperties({ mode, close }: Props) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const activeTab = useSelector(getSidebarActiveTab);
  const isActive = useSelector((state) => isSidebarTabActive(state, TAB_NAMES.PROPERTIES));
  const propertiesMenu = useMobileMenu(false, true);

  useEffect(() => {
    if (activeTab === TAB_NAMES.PROPERTIES && isMobile) {
      propertiesMenu.open();
    }
  }, [activeTab, isMobile]);

  const handleClick = () => {
    if (isMobile) {
      propertiesMenu.open();
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.PROPERTIES));
    } else {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.PROPERTIES));
      close?.();
    }
  };

  const closeMenu = useCallback(() => {
    propertiesMenu.close();
    dispatch(setSidebarActiveTabOperation(-1));
  }, []);

  return (
    <>
      <MenuButton
        Icon={IconProperties}
        on={isActive}
        mode={mode}
        handleClick={handleClick}
        i18nKey="editor-properties"
        className="cy-button-properties"
      />
      {propertiesMenu.render(<PropertiesTabContent closeMenuCb={closeMenu} />)}
    </>
  );
}

export default React.memo(ButtonProperties);

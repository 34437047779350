import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import getDesignCalendarConfigs from 'editor/src/store/design/selector/getDesignCalendarConfigs';
import getFirstGridElement from 'editor/src/store/design/selector/getFirstGridElement';
import { addOrUpdateCalendarEventsAction, setAutoApplyPublicHolidaysFlagAction } from 'editor/src/store/design/slice';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import { isWithinInterval, getYearDatesFrame } from 'editor/src/util/dates/dateUtils';

import Checkbox from 'editor/src/component/Checkbox';
import SwitchButton from 'editor/src/component/SwitchButton';

import EventRow from '../EventRow';

import styles from './index.module.scss';

function PublicHolidays() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    events: calendarEvents = [],
    showEvents,
    autoApplyPublicHolidays,
  } = useSelector((state) => getDesignCalendarConfigs(state));
  const gridElement = useSelector((state) => getFirstGridElement(state)?.grid);
  const { startDate, endDate } = getYearDatesFrame(gridElement?.gridDate);

  const publicHolidays = useMemo(() => {
    return (
      calendarEvents
        ?.filter(
          ({ custom, merchant, month, year, day }) =>
            (isEmbedded ? !custom : !merchant) && isWithinInterval(new Date(year, month, day), startDate, endDate),
        )
        .sort(
          (e1, e2) => new Date(e1.year, e1.month, e1.day).getTime() - new Date(e2.year, e2.month, e2.day).getTime(),
        ) || []
    );
  }, [calendarEvents]);

  const isAllSelected = useMemo(() => {
    return publicHolidays.every(({ checked }) => checked);
  }, [publicHolidays]);

  const toggleAllPublicEvents = () => {
    dispatch(addOrUpdateCalendarEventsAction(publicHolidays.map((e) => ({ ...e, checked: !isAllSelected }))));
  };

  const toggleAllowPersonalization = () => {
    dispatch(setAutoApplyPublicHolidaysFlagAction(!autoApplyPublicHolidays));
  };

  return (
    <div className={styles.publicHolidaysContentBlock}>
      {!isEmbedded && (
        <div
          className={cn(styles.autoApplyPublicHolidaysBlock, {
            [styles.autoApplyPublicHolidaysBlockDisabled]: !showEvents,
          })}
        >
          <SwitchButton
            on={Boolean(autoApplyPublicHolidays)}
            onSwitch={toggleAllowPersonalization}
            disabled={!showEvents}
          />
          {t('Automatically add public holidays based on the country of the customer.')}
        </div>
      )}
      {publicHolidays.length > 1 && (
        <Checkbox
          className={cn('cy-select-all-events-btn', styles.selectAllBlock)}
          on={isAllSelected}
          disabled={!showEvents}
          onChange={toggleAllPublicEvents}
          label={<span className={styles.selectAllLabel}>{t('Select all')}</span>}
        />
      )}
      <div className={cn('cy-public-holidays-list', styles.calendarEventsContentBlock)}>
        {publicHolidays.map((event) => (
          <EventRow event={event} key={event.id} disabled={!showEvents} />
        ))}
      </div>
    </div>
  );
}

export default React.memo(PublicHolidays);

import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import isMobileSelector from 'editor/src/store/app/selector/isMobile';
import getSpread from 'editor/src/store/design/selector/getSpread';
import getSpreadBackgroundImage from 'editor/src/store/design/selector/getSpreadBackgroundImage';
import getSpreadForegroundImage from 'editor/src/store/design/selector/getSpreadForegroundImage';
import { Dimensions } from 'editor/src/store/design/types';
import getDefaultZoom from 'editor/src/store/editorModules/panAndZoom/selector/getDefaultZoom';
import { useSelector } from 'editor/src/store/hooks';

import { convFn } from 'editor/src/util/convFn';

import getBestFitZoom from 'editor/src/component/EditorArea/utils/getBestFitZoom';
import { MAX_ZOOM } from 'editor/src/component/EditorArea/utils/zoomUtils';

import { useBottomBarHeight } from '../EditorAreaControls/BottomControls/BottomBarHeightProvider';

function useMinZoom(
  canvasDim: Dimensions,
  spreadIndex: number,
  spreadWidth: number,
  spreadHeight: number,
  mm2px: convFn,
) {
  const { background, foreground, focusedContent, hasMultipleSpreads, isMobile, defaultZoom } = useSelector((state) => {
    const isMobile = isMobileSelector(state);
    const spread = getSpread(state, spreadIndex);
    const background = getSpreadBackgroundImage(state, spread?.name || '');
    const foreground = getSpreadForegroundImage(state, spread?.name || '');
    const hasMultipleSpreads = (state.design.designData?.spreads.length || 0) > 1;

    const { focusedContentAddress } = state.editor;
    const focusedContent =
      focusedContentAddress &&
      spread?.pages[focusedContentAddress.pageIndex].groups.content?.[focusedContentAddress.contentIndex];

    return {
      background,
      foreground,
      focusedContent,
      hasMultipleSpreads,
      isMobile,
      defaultZoom: getDefaultZoom(state),
    };
  }, shallowEqual);

  const { bottomBarHeight } = useBottomBarHeight();

  const { minZoom, fitZoom } = useMemo(() => {
    const fitZoom = getBestFitZoom(
      canvasDim,
      spreadWidth,
      spreadHeight,
      background,
      foreground,
      focusedContent,
      mm2px,
      bottomBarHeight,
    );
    return { fitZoom, minZoom: Math.min(1, fitZoom, defaultZoom || 1, MAX_ZOOM) };
  }, [
    canvasDim,
    spreadWidth,
    spreadHeight,
    defaultZoom,
    background,
    foreground,
    focusedContent,
    hasMultipleSpreads,
    isMobile,
    mm2px,
    bottomBarHeight,
  ]);

  return { minFittedZoom: minZoom, minZoom: minZoom / 2, fitZoom };
}

export default useMinZoom;

import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getMultiOption from 'editor/src/store/variants/helpers/getMultiOption';
import isObjectMatchingDepOptions from 'editor/src/store/variants/helpers/isObjectMatchingDepOptions';
import { ProductControlMulti, ProductControlOption } from 'editor/src/store/variants/types';

import updateMultiOptionsOperation from './updateMultiOptionsOperation';

const selectMultiOptionOperation =
  (productControl: ProductControlMulti, controlOption: ProductControlOption) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const variantState = state.variants;
    const { selectedSingleOptions, configuration, product } = variantState;

    const multiOptions = { ...variantState.selectedMultiOptions };
    const newMultiOption = getMultiOption(
      controlOption.value,
      product,
      selectedSingleOptions,
      !configuration.singleSelection && productControl.dependsOnSingleControls,
    );

    if (configuration.singleSelection) {
      // replace option
      multiOptions[productControl.key] = [newMultiOption];
    } else {
      // toggle option
      const optionsValues = [...multiOptions[productControl.key]];
      multiOptions[productControl.key] = optionsValues;
      const index = optionsValues.findIndex(
        (option) => option.value === controlOption.value && isObjectMatchingDepOptions(selectedSingleOptions, option),
      );
      if (index === -1) {
        optionsValues.push(newMultiOption);
      } else {
        optionsValues.splice(index, 1);
      }
    }

    dispatch(updateMultiOptionsOperation(multiOptions));
  };

export default selectMultiOptionOperation;

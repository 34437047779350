import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import {
  getBleedDimensions,
  getProductDimensionsBasedOnAsset,
} from 'editor/src/store/design/operation/checkToResizeProductOperation';
import getCurrentDpiLevels from 'editor/src/store/design/selector/getCurrentDpiLevels';
import getFirstImageElementAndAsset from 'editor/src/store/design/selector/getFirstImageElementAndAsset';
import getDimensionRestrictions from 'editor/src/store/design/util/getDimensionRestrictions';
import setSettingsValueOperation from 'editor/src/store/editor/operation/setSettingsValueOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty, Unit } from 'editor/src/store/editor/types';
import { FormatProductSize } from 'editor/src/store/editorModules/formats/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import useStateWithAutoReset from 'editor/src/util/useStateWithAutoReset';

import { SelectorDetail } from 'editor/src/component/DesktopSidebar/TabContents/FormatsTabContent/Details';
import IconHeight from 'editor/src/component/Icon/IconHeight';
import IconInfo from 'editor/src/component/Icon/IconInfo';
import IconWidth from 'editor/src/component/Icon/IconWidth';
import SelectButtonGroup from 'editor/src/component/SelectButtonGroup';

import DimensionField, { Field } from './DimensionField';

import styles from './index.module.scss';

import selectorStyles from 'editor/src/component/DesktopSidebar/TabContents/FormatsTabContent/selector.module.scss';

interface Props {
  selector: FormatProductSize;
  openDetails: (detail: SelectorDetail) => void;
}

function ProductSizeSelector({ selector, openDetails }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const unit = useSelector((state) => getSettingsValue(state, SettingsProperty.units));
  const productUid = useSelector((state) => state.design.designData?.product_uid);
  const dimensions = useSelector((state) => state.design.designData?.dimensions);
  const [error, setError] = useStateWithAutoReset<{
    field: Field;
    message: string;
  }>(2000);

  const { imageElement, imageAsset } = useSelector(getFirstImageElementAndAsset, shallowEqual);

  const bleedDimensions = useSelector((state) => {
    const { designData } = state.design;
    if (!designData || !dimensions) {
      return undefined;
    }
    const spreadIndex = getCurrentSpreadIndex(state);
    return getBleedDimensions(designData.spreads[spreadIndex], dimensions);
  }, shallowEqual);
  const dpiLevels = useSelector(getCurrentDpiLevels, shallowEqual);
  const dimensionRestrictions = useMemo(() => {
    if (!dimensions) {
      return undefined;
    }
    return getDimensionRestrictions(dimensions, {
      width: dimensions.width,
      height: dimensions.height,
    });
  }, [dimensions]);

  const maxImageDimensions = useMemo(() => {
    if (
      !dimensions ||
      !imageAsset ||
      !dpiLevels ||
      !imageElement ||
      !bleedDimensions ||
      !dimensionRestrictions ||
      imageAsset.type === 'SVG'
    ) {
      return undefined;
    }
    return getProductDimensionsBasedOnAsset(
      imageAsset,
      dimensionRestrictions,
      bleedDimensions,
      dpiLevels.low,
      imageElement,
    );
  }, [dimensions, imageAsset, imageElement, bleedDimensions, dpiLevels?.low]);

  const handleDimensionChange = useCallback(
    (field: Field, value: number) => {
      if (dimensions && productUid) {
        sendPostMessage('editor.dimensionChangeRequested', {
          width: Math.floor(dimensions.width),
          height: Math.floor(dimensions.height),
          [field]: value,
        });
      }
    },
    [dimensions],
  );

  const onFieldError = useCallback((field: Field, message: string | undefined) => {
    setError(message ? { field, message } : undefined);
  }, []);

  const options: Array<{ label: string; value: Unit }> = useMemo(
    () => [
      { label: t('editor-cm'), value: 'cm' },
      { label: t('editor-inch'), value: 'inch' },
    ],
    [],
  );

  const handleUnitChange = useCallback((value: Unit) => {
    dispatch(setSettingsValueOperation(SettingsProperty.units, value));
  }, []);

  if (!dimensions || !dimensionRestrictions) {
    return null;
  }

  return (
    <div className={cn(selectorStyles.selector, styles.ProductSizeSelector, 'cy-product-size-selector')}>
      <div className={cn(selectorStyles.selectorName, styles.name)}>
        {selector.name}
        <SelectButtonGroup
          items={options}
          onChange={handleUnitChange}
          value={unit}
          className={cn(styles.units, 'cy-dimension-units')}
        />
      </div>

      <div className={styles.inputs}>
        <DimensionField
          field="width"
          min={dimensionRestrictions.min_width}
          max={dimensionRestrictions.max_width}
          maxSurface={dimensions.max_surface}
          imageMax={maxImageDimensions?.width}
          valueMm={dimensions.width}
          onChange={handleDimensionChange}
          onError={onFieldError}
          Icon={IconWidth}
          unit={unit}
          className={styles.field}
          error={error?.field === 'width'}
        />
        <DimensionField
          field="height"
          min={dimensionRestrictions.min_height}
          max={dimensionRestrictions.max_height}
          maxSurface={dimensions.max_surface}
          imageMax={maxImageDimensions?.height}
          valueMm={dimensions.height}
          onChange={handleDimensionChange}
          onError={onFieldError}
          Icon={IconHeight}
          unit={unit}
          className={styles.field}
          error={error?.field === 'height'}
        />
      </div>

      {error && <div className={cn(styles.error, 'cy-resize-error')}>{error.message}</div>}

      <div className={styles.description}>
        {selector.description}
        <button
          className={cn(styles.infoIcon, 'cy-information')}
          onClick={() =>
            openDetails({
              type: 'text',
              title: selector.detailsTitle,
              content: selector.details,
            })
          }
          aria-label={selector.detailsTitle}
        >
          <IconInfo />
        </button>
      </div>
    </div>
  );
}

export default React.memo(ProductSizeSelector);

import React, { useRef, useEffect, useCallback } from 'react';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import { ElementAddress, MediaAddon, MediaImage, PerspectiveTransform } from 'editor/src/store/design/types';
import setElementManipulationModeOperation from 'editor/src/store/editor/operation/setElementManipulationModeOperation';
import { ManipulationMode } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import FabricCircle from 'editor/src/fabric/FabricCircle';
import FabricImage from 'editor/src/fabric/FabricImage';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import FabricCircleComponent from 'editor/src/component/EditorArea/fabricComponents/FabricCircleComponent';
import { DEFAULT_TRANSFORM } from 'editor/src/component/EditorArea/fabricFilters/PerspectiveTransformFilter';
import { ELEMENT_FRAME_COLOR } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/config';
import { CONTROL_POINT_RADIUS } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Line';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { VIEWPORT_CHANGED_EVENT } from 'editor/src/component/EditorArea/types';

import { ObjectRect } from './types';
import { getFilters } from './useFilters';

function usePerspectiveTranform(
  element: MediaImage | MediaAddon,
  elementAddress: ElementAddress,
  imageIsEmpty: boolean,
  selected: boolean,
  frameRect: ObjectRect,
  imageRef: React.RefObject<FabricImage>,
  disableMode: boolean,
) {
  const tranformControlTL = useRef<FabricCircle>(null);
  const tranformControlTR = useRef<FabricCircle>(null);
  const tranformControlBL = useRef<FabricCircle>(null);
  const tranformControlBR = useRef<FabricCircle>(null);
  const fabricCanvas = useFabricCanvas();
  const dispatch = useDispatch();
  const isModeActive = useSelector(
    (state) =>
      state.editor.elementManipulationMode?.uuid === element.uuid &&
      state.editor.elementManipulationMode.mode === ManipulationMode.perspectiveTransform,
  );

  useEffect(() => {
    function onViewPortTransform() {
      const update = {
        radius: CONTROL_POINT_RADIUS / fabricCanvas.getZoom(),
      };
      tranformControlTL.current?.set(update);
      tranformControlTR.current?.set(update);
      tranformControlBL.current?.set(update);
      tranformControlBR.current?.set(update);
    }
    fabricCanvas.on(VIEWPORT_CHANGED_EVENT, onViewPortTransform);
    return () => {
      fabricCanvas.off(VIEWPORT_CHANGED_EVENT, onViewPortTransform);
    };
  }, [fabricCanvas, selected]);

  const turnModeOff = useCallback(() => {
    dispatch(setElementManipulationModeOperation(undefined));
  }, []);

  const toggleMode = useCallback(() => {
    if (isModeActive) {
      turnModeOff();
    } else {
      dispatch(
        setElementManipulationModeOperation({
          uuid: element.uuid,
          mode: ManipulationMode.perspectiveTransform,
        }),
      );
    }
  }, [isModeActive, element.uuid]);

  const mounted = useRef(false); // avoid effects on first mount
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return undefined;
    }

    if (isModeActive && !selected) {
      turnModeOff();
    }

    if (isModeActive) {
      fabricCanvas.on('mouse:down:after', turnModeOff);
      return () => {
        fabricCanvas.off('mouse:down:after', turnModeOff);
      };
    }

    return undefined;
  }, [isModeActive, selected]);

  // when deleting image while in crop mode
  useEffect(() => {
    if (imageIsEmpty && isModeActive) {
      turnModeOff();
    }
  }, [imageIsEmpty, isModeActive, turnModeOff]);

  const perspectiveTransform = element.perspective_transform || (isModeActive ? DEFAULT_TRANSFORM : undefined);

  function getCurrentPerspectiveTransform(perspectiveTransform: PerspectiveTransform, imageRect: ObjectRect) {
    const newTransform = {
      ...perspectiveTransform,
    };

    if (tranformControlTL.current) {
      newTransform.tl_x = ((tranformControlTL.current.left ?? 0) - imageRect.left) / imageRect.width;
      newTransform.tl_y = ((tranformControlTL.current.top ?? 0) - imageRect.top) / imageRect.height;
    }
    if (tranformControlTR.current) {
      newTransform.tr_x = ((tranformControlTR.current.left ?? 0) - imageRect.left) / imageRect.width;
      newTransform.tr_y = ((tranformControlTR.current.top ?? 0) - imageRect.top) / imageRect.height;
    }
    if (tranformControlBL.current) {
      newTransform.bl_x = ((tranformControlBL.current.left ?? 0) - imageRect.left) / imageRect.width;
      newTransform.bl_y = ((tranformControlBL.current.top ?? 0) - imageRect.top) / imageRect.height;
    }
    if (tranformControlBR.current) {
      newTransform.br_x = ((tranformControlBR.current.left ?? 0) - imageRect.left) / imageRect.width;
      newTransform.br_y = ((tranformControlBR.current.top ?? 0) - imageRect.top) / imageRect.height;
    }
    return newTransform;
  }

  const tranformControlOnModified = useCallback(() => {
    if (!perspectiveTransform) {
      return;
    }
    const newTransform = getCurrentPerspectiveTransform(perspectiveTransform, frameRect);
    dispatch(
      updateMediaElementOperation(
        elementAddress,
        { perspective_transform: newTransform },
        MediaUpdateActionName.TRANSFORM_PERSPECTIVE_UPDATED,
      ),
    );
  }, [elementAddress, perspectiveTransform, frameRect]);

  const tranformControlOnMove = useCallback(
    (e: fabric.IEvent) => {
      if (e.transform?.action !== 'drag' || !imageRef.current || !perspectiveTransform) {
        return;
      }
      const newTransform = getCurrentPerspectiveTransform(perspectiveTransform, frameRect);
      imageRef.current.applyFilters(getFilters(element, newTransform));
    },
    [perspectiveTransform, frameRect, element],
  );

  const render = () => {
    if (!isModeActive || !perspectiveTransform) {
      return null;
    }

    return (
      <>
        <FabricCircleComponent
          left={frameRect.left + frameRect.width * perspectiveTransform.tl_x}
          top={frameRect.top + frameRect.height * perspectiveTransform.tl_y}
          onMouseMove={tranformControlOnMove}
          fill={ELEMENT_FRAME_COLOR}
          hasControls={false}
          hasBorders={false}
          zIndex={zIndex.HOVER_BOX}
          hoverCursor="pointer"
          radius={CONTROL_POINT_RADIUS / fabricCanvas.getZoom()}
          onModified={tranformControlOnModified}
          originX="center"
          originY="center"
          objectCaching={false}
          ref={tranformControlTL}
        />
        <FabricCircleComponent
          left={frameRect.left + frameRect.width * perspectiveTransform.tr_x}
          top={frameRect.top + frameRect.height * perspectiveTransform.tr_y}
          onMouseMove={tranformControlOnMove}
          fill={ELEMENT_FRAME_COLOR}
          hasControls={false}
          hasBorders={false}
          zIndex={zIndex.HOVER_BOX}
          hoverCursor="pointer"
          radius={CONTROL_POINT_RADIUS / fabricCanvas.getZoom()}
          onModified={tranformControlOnModified}
          originX="center"
          originY="center"
          objectCaching={false}
          ref={tranformControlTR}
        />
        <FabricCircleComponent
          left={frameRect.left + frameRect.width * perspectiveTransform.bl_x}
          top={frameRect.top + frameRect.height * perspectiveTransform.bl_y}
          onMouseMove={tranformControlOnMove}
          fill={ELEMENT_FRAME_COLOR}
          hasControls={false}
          hasBorders={false}
          zIndex={zIndex.HOVER_BOX}
          hoverCursor="pointer"
          radius={CONTROL_POINT_RADIUS / fabricCanvas.getZoom()}
          onModified={tranformControlOnModified}
          originX="center"
          originY="center"
          objectCaching={false}
          ref={tranformControlBL}
        />
        <FabricCircleComponent
          left={frameRect.left + frameRect.width * perspectiveTransform.br_x}
          top={frameRect.top + frameRect.height * perspectiveTransform.br_y}
          onMouseMove={tranformControlOnMove}
          fill={ELEMENT_FRAME_COLOR}
          hasControls={false}
          hasBorders={false}
          zIndex={zIndex.HOVER_BOX}
          hoverCursor="pointer"
          radius={CONTROL_POINT_RADIUS / fabricCanvas.getZoom()}
          onModified={tranformControlOnModified}
          originX="center"
          originY="center"
          objectCaching={false}
          ref={tranformControlBR}
        />
      </>
    );
  };

  return {
    isActive: isModeActive,
    toggleMode,
    value: disableMode ? undefined : perspectiveTransform,
    render,
  };
}

export default usePerspectiveTranform;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ButtonRemove from './buttons/ButtonRemove';
import SplitterVertical from './buttons/SplitterVertical';
import { DesktopMenuItem, PLACE_HOLDER } from './menuItems';

export type MenuItemRef = {
  ref: React.RefObject<HTMLDivElement>;
  weight: number;
  index: number;
  menuElt: DesktopMenuItem;
};

function placeElement(element: HTMLDivElement, x: number) {
  element.style.left = `${x}px`;
  element.style.opacity = '1';
  element.style.pointerEvents = '';
}

function hideElement(element: HTMLDivElement, x: number) {
  element.style.left = `${x}px`;
  element.style.opacity = '';
  element.style.pointerEvents = 'none';
}

function compareWeightThenIndex(e1: MenuItemRef, e2: MenuItemRef) {
  return e1.weight - e2.weight || e2.index - e1.index;
}

function useMenuDisplay(
  containerRef: React.RefObject<HTMLDivElement>,
  itemMenuRef: React.MutableRefObject<MenuItemRef[]>,
  menuMoreRef: React.RefObject<HTMLDivElement>,
  menuItems: DesktopMenuItem[],
) {
  const [hiddenMenuItems, setHiddenMenuItems] = useState<DesktopMenuItem[]>([]);

  const updateMenu = useCallback(() => {
    if (!containerRef.current) {
      return false;
    }

    const containerWidth = containerRef.current.clientWidth;
    if (containerWidth === 0) {
      return false;
    }

    const items: MenuItemRef[] = [];
    let lastItem: MenuItemRef;
    itemMenuRef.current.forEach((item, i) => {
      if (
        item.ref.current &&
        (item.menuElt.Component === SplitterVertical || item.menuElt.Component === PLACE_HOLDER)
      ) {
        if (
          (lastItem && lastItem.menuElt.Component === item.menuElt.Component) ||
          i === itemMenuRef.current.length - 1
        ) {
          // same elt twice or last elt
          hideElement(item.ref.current, 0);
          return;
        }
      }

      lastItem = item;
      items.push(item);
    });

    let placeHolderCount = 0;
    let totalWidth = 0;
    items.forEach(({ menuElt, ref }) => {
      if (menuElt.Component === PLACE_HOLDER) {
        placeHolderCount += 1;
      } else {
        totalWidth += ref.current?.clientWidth || 0;
      }
    });

    if (totalWidth <= containerWidth) {
      const placeHolderWidth = placeHolderCount ? (containerWidth - totalWidth) / placeHolderCount : 0;
      let x = 0;
      items.forEach(({ menuElt, ref }) => {
        if (!ref.current) {
          return;
        }
        placeElement(ref.current, x);
        x += menuElt.Component === PLACE_HOLDER ? placeHolderWidth : ref.current.clientWidth;
      });
      if (menuMoreRef.current) {
        menuMoreRef.current.style.left = '100%';
      }
      return true;
    }

    // remove the button more size
    totalWidth += menuMoreRef.current?.clientWidth || 0;

    // remove lower weights & last items until it fits;
    const eltFitting: MenuItemRef[] = [];
    const eltNotFitting: MenuItemRef[] = [];
    placeHolderCount = 0;
    items.sort(compareWeightThenIndex).forEach((elt) => {
      if (totalWidth > containerWidth) {
        eltNotFitting.push(elt);
        totalWidth -= elt.ref.current?.clientWidth || 0;
      } else {
        eltFitting.push(elt);
        if (elt.menuElt.Component === PLACE_HOLDER) {
          placeHolderCount += 1;
        }
      }
    });

    let x = 0;
    let lastWidth = 0;
    const placeHolderWidth = placeHolderCount ? (containerWidth - totalWidth) / placeHolderCount : 0;
    eltFitting
      .sort((e1, e2) => e1.index - e2.index)
      .forEach(({ menuElt, ref }) => {
        if (!ref.current) {
          return;
        }
        placeElement(ref.current, x);
        lastWidth = menuElt.Component === PLACE_HOLDER ? placeHolderWidth : ref.current.clientWidth;
        x += lastWidth;
      });
    if (menuMoreRef.current) {
      const lastElt = eltFitting[eltFitting.length - 1];
      // special case to put the Remove button behind the more menu
      if (lastElt?.menuElt.Component === ButtonRemove) {
        x -= lastWidth;
        if (lastElt.ref.current) {
          lastElt.ref.current.style.left = `${x + menuMoreRef.current.clientWidth}px`;
        }
      }
      placeElement(menuMoreRef.current, x);
    }

    eltNotFitting.forEach(({ ref }) => {
      if (!ref.current) {
        return;
      }
      hideElement(ref.current, x);
    });

    setHiddenMenuItems(eltNotFitting.sort((e1, e2) => e1.index - e2.index).map((e) => e.menuElt));

    return true;
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateMenu);
    return () => window.removeEventListener('resize', updateMenu);
  }, [updateMenu]);

  const updateRef = useRef(0);
  useEffect(() => {
    function update() {
      if (!updateMenu()) {
        updateRef.current = window.setTimeout(update, 50);
      }
    }
    updateRef.current = window.setTimeout(update, 0);
    return () => window.clearTimeout(updateRef.current);
  }, [updateMenu]);

  // updates of hidden menus is done with useEffect and so is delayed. we just filter to make sure the current hiden are still part of the current menu.
  const filteredHiddenMenuItems = useMemo(
    () => hiddenMenuItems.filter((hiddenItem) => menuItems.some((item) => item.Component === hiddenItem.Component)),
    [menuItems, hiddenMenuItems],
  );

  return { updateMenu, hiddenMenuItems: filteredHiddenMenuItems };
}

export default useMenuDisplay;

import cn from 'classnames';
import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getDesignDataForExport from 'editor/src/store/design/selector/getDesignDataForExport';
import { setPreviewStatusAction } from 'editor/src/store/editorModules/preview/slice';
import { PreviewStatus } from 'editor/src/store/editorModules/preview/types';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';
import { PluginName } from 'editor/src/store/plugins/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import usePostMessage from 'editor/src/util/usePostMessage';

import Button from '../../Button';

import styles from './index.module.scss';

function PersonalizationBar() {
  const { t } = useTranslation();
  const store = useStore();
  const dispatch = useDispatch();
  const aiPersonalizationEnabled = useSelector((state) =>
    state.design.globalPlugins.includes(PluginName.AIPersonalization),
  );
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState<'default' | 'ai'>('ai');
  useEffect(() => {
    if (mode === 'ai' && aiPersonalizationEnabled) {
      sendPostMessage('log.magicPreviewInitiated', undefined);
    }
  }, [mode, aiPersonalizationEnabled]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const { value } = form.elements[0] as any;
    if (!value) {
      return;
    }

    const state = store.getState();
    if (state.design.designData) {
      const designData = getDesignDataForExport(
        state.design.designData,
        state.gallery.images,
        state.editorModules.addons.inUse,
        [],
      );
      sendPostMessage('plugins.requestNewPreviewsFromText', {
        text: value,
        designData,
      });
      setLoading(true);
      dispatch(setPreviewStatusAction(PreviewStatus.LOADING));
    }
  };

  usePostMessage('plugins.setExtractedFields', () => setLoading(false));

  return (
    <div className={cn(styles.PersonalizationBar, 'p-2', 'cy-personalization-bar')}>
      <div className={styles.title}>{t('Personalization methods')}</div>
      <Button
        className={cn(styles.button, 'mt-1')}
        secondary={mode === 'default'}
        primary={mode === 'ai'}
        onClick={() => setMode('ai')}
      >
        {t('Magic personalization')}
      </Button>
      <Button
        className={cn(styles.button, 'mt-1')}
        secondary={mode === 'ai'}
        primary={mode === 'default'}
        onClick={() => setMode('default')}
      >
        {t('Default')}
      </Button>
      {mode === 'default' && (
        <>
          <div className={cn(styles.title, 'mt-3')}>{t('default automation title')}</div>
          <div className={styles.content}>
            <ul>
              <li>{t('default automation explanation 1')}</li>
              <li>{t('default automation explanation 2')}</li>
              <li>{t('default automation explanation 3')}</li>
              <li>{t('default automation explanation 4')}</li>
            </ul>
          </div>
        </>
      )}
      {mode === 'ai' && (
        <>
          <div className={cn(styles.title, 'mt-3')}>{t('magic personalization title')}</div>
          <div className={styles.content}>
            <ul>
              <li>{t('magic personalization explanation 1')}</li>
              <li>{t('magic personalization explanation 2')}</li>
              <li>{t('magic personalization explanation 3')}</li>
            </ul>
          </div>
          <div className={cn(styles.title, 'mt-3')}>{t('Try it out')}</div>
          <div className={styles.content}>{t('try your personalization request')}</div>
          <form
            onSubmit={onSubmit}
            className={cn(styles.form, 'mt-3', {
              [styles.disabled]: !aiPersonalizationEnabled || loading,
            })}
          >
            <textarea defaultValue={t('Your personalization request')} />
            <Button className={cn(styles.submit, 'mt-3')} type="submit" primary showLoader={loading}>
              {t('See how it looks')}
            </Button>
          </form>
        </>
      )}
    </div>
  );
}

export default React.memo(PersonalizationBar);

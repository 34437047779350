import React from 'react';

import CentralSectionMobile from './CentralSectionMobile';
import LeftSectionMobile from './LeftSectionMobile';
import RightSectionMobile from './RightSectionMobile';

import styles from './index.module.scss';

function TopMenu() {
  return (
    <div className={styles.topMenuBar}>
      <LeftSectionMobile />
      <CentralSectionMobile />
      <RightSectionMobile />
    </div>
  );
}

export default React.memo(TopMenu);

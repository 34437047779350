import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';

import ControlButton from 'editor/src/component/ControlButton';
import Search from 'editor/src/component/DesktopSidebar/TabContents/Elements/Search';
import {
  useDispatch,
  useSelector,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store';
import requestDataOperation from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/operation/requestDataOperation';
import setSearchValueOperation from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/operation/setSearchValueOperation';
import getFilterControls from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getFilterControls';
import getSearchFilters from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getSearchFilters';
import getSearchValue from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getSearchValue';
import {
  setActiveGroupAction as setActiveGroupOperation,
  setActiveTabAction as setActiveTabOperation,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/slice';
import { ContentProviderTabNames } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';
import Badge from 'editor/src/component/DesktopSidebar/Tabs/Tab/Badge';
import IconContentPluginFilters from 'editor/src/component/Icon/IconContentPluginFilters';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

function FilterAndSearch({ className }: Props) {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const search = useSelector(getSearchValue) || '';
  const hasFilterControls = useSelector((state) => !!getFilterControls(state)?.length);
  const searchFilters = useSelector(getSearchFilters);

  const handleSearchChange = useCallback((value: string) => {
    batch(() => {
      dispatch(setSearchValueOperation(value));
      dispatch(setActiveGroupOperation());
      dispatch(requestDataOperation());
    });
  }, []);

  const activateFiltersTab = useCallback(() => {
    dispatch(setActiveTabOperation(ContentProviderTabNames.FILTERS));
  }, []);

  const searchFiltersCount = useMemo(
    () => Object.values(searchFilters).filter((value) => value !== undefined).length,
    [searchFilters],
  );

  return (
    <div
      className={cn(styles.filtersAndSearch, className, {
        [styles.mobile]: isMobile,
      })}
    >
      <Search isMobile={isMobile} onChange={handleSearchChange} value={search} />
      {hasFilterControls && (
        <WithTooltip overlay="Filters">
          <ControlButton className={cn('ml-1', 'cy-asset-provider-filters')} onClick={activateFiltersTab}>
            <IconContentPluginFilters />
            {!!searchFiltersCount && <Badge className={styles.filtersCounterBadge}>{searchFiltersCount}</Badge>}
          </ControlButton>
        </WithTooltip>
      )}
    </div>
  );
}

export default React.memo(FilterAndSearch);

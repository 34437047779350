import { Dimensions, MediaImage } from 'editor/src/store/design/types';
import { Addon } from 'editor/src/store/editorModules/addons/types';
import { addImageAction, addImageToSessionAction } from 'editor/src/store/gallery/slice';
import { ImageState, MimeType } from 'editor/src/store/gallery/types';
import getMimeTypeEnum from 'editor/src/store/gallery/utils/getMimeTypeEnum';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { PluginName } from 'editor/src/store/plugins/types';

import { ImageSource } from 'editor/src/amplitude';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const uploadAddonOperation =
  (image: MediaImage, dim: Dimensions, addon: Addon, hasAssetDimensions: boolean) => (dispatch: ThunkDispatch) => {
    sendPostMessage('addons.uploadAddon', { addonId: addon.id });
    dispatch(
      addImageAction({
        state: ImageState.REMOTE_PREVIEW,
        id: addon.id,
        name: image.name,
        width: dim.width,
        height: dim.height,
        type: addon.mimeType ? getMimeTypeEnum(addon.mimeType) : MimeType.SVG,
        url: addon?.preview || image.url,
        source: ImageSource.Graphic,
        from: { type: PluginName.Graphics, addonId: addon.id },
        hasAssetDimensions,
      }),
    );
    dispatch(addImageToSessionAction(addon.id));
  };

export default uploadAddonOperation;

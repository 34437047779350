import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import getGalleryImagesSizeSum from 'editor/src/store/gallery/selector/getGalleryImagesSizeSum';
import getGalleryImagesUploadedSum from 'editor/src/store/gallery/selector/getGalleryImagesUploadedSum';
import getSessionImages from 'editor/src/store/gallery/selector/getSessionImages';
import getSessionImagesCount from 'editor/src/store/gallery/selector/getSessionImagesCount';
import getSessionUploadedImagesCount from 'editor/src/store/gallery/selector/getSessionUploadedImagesCount';
import { useSelector } from 'editor/src/store/hooks';

import toFixedMB from 'editor/src/util/toFixedMB';

import IconLoading from 'editor/src/component/Icon/IconLoading';

import styles from './index.module.scss';

function AllFilesUploadProgress() {
  const totalFiles = useSelector(getSessionImagesCount);
  const uploadedFiles = useSelector(getSessionUploadedImagesCount);
  const totalFilesSize = useSelector(getGalleryImagesSizeSum);
  const uploadedFilesSize = useSelector(getGalleryImagesUploadedSum);
  const images = useSelector(getSessionImages);

  const hideProgressPercentage = images.some((image) => image.hideProgressPercentage);

  const { t } = useTranslation();

  if (uploadedFilesSize === totalFilesSize && totalFiles === uploadedFiles) {
    return null;
  }

  const barStyles = {
    width: `${totalFilesSize ? (uploadedFilesSize / totalFilesSize) * 100 : 0}%`,
  };

  const percent = hideProgressPercentage
    ? 100
    : Math.round(totalFilesSize ? (uploadedFilesSize / totalFilesSize) * 100 : 0);

  return (
    <div className={cn(styles.allFilesUploadProgress, 'cy-gallery-progress')}>
      <div className={styles.progressBackground}>
        {percent !== 100 ? (
          <div className={styles.progressBar} style={barStyles} />
        ) : (
          <div className={cn(styles.progressBarInfinite, 'cy-progress-infinite')} />
        )}
      </div>
      {percent !== 100 && (
        <div className={styles.progressText}>
          <div className={styles.iconLoading}>
            <IconLoading />
          </div>
          <div className={styles.captions}>
            <div className={styles.line1}>{t('editor-progress-percent', { percent })}</div>
            <div className={cn(styles.line2, 'cy-files-uploading')}>
              {t('editor-uploaded-no-of-no', {
                uploaded: uploadedFiles,
                total: totalFiles,
              })}
              <span className={styles.mbytes}>
                {`(${toFixedMB(uploadedFilesSize)} / ${toFixedMB(totalFilesSize)} ${t('editor-mb')})`}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(AllFilesUploadProgress);

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconFlatLine1({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2042_11491)">
          <path d="M5 16H32" stroke="#383838" strokeWidth="3" strokeLinecap="square" />
        </g>
        <defs>
          <clipPath id="clip0_2042_11491">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}

export default IconFlatLine1;

import { DesignData } from 'editor/src/store/design/types';

function getImageIdsWithPersonalizationLocked(designData: DesignData | undefined) {
  const imageIds = new Set<string>();

  designData?.spreads.forEach((spread) => {
    spread.pages[0].groups.media?.forEach((element) => {
      if (element.type === 'image' && element.personalizationLocked) {
        imageIds.add(element.imageId);
      }
    });
  });

  return imageIds;
}

export default getImageIdsWithPersonalizationLocked;

import cn from 'classnames';
import React from 'react';

import getThe3dPreviews from 'editor/src/store/editorModules/preview/selector/getThe3dPreviews';
import { useSelector } from 'editor/src/store/hooks';

import styles from './index.module.scss';

function The3dPreview() {
  const preview = useSelector(getThe3dPreviews);

  return (
    <div className={cn(styles.the3dPreview, 'cy-3d-preview')}>
      <iframe src={preview?.url} title="preview" />
    </div>
  );
}

export default React.memo(The3dPreview);

import cn from 'classnames';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dimensions } from 'editor/src/store/design/types';

import IconHeight from 'editor/src/component/Icon/IconHeight';
import IconSmallLock from 'editor/src/component/Icon/IconSmallLock';
import IconWidth from 'editor/src/component/Icon/IconWidth';
import Input from 'editor/src/component/Input';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  activeSize: Dimensions;
  onChange: (size: Dimensions) => void;
}

const MIN = 50;
const MAX = 8000;

function getValidValue(value: string, defaultValue: number): number {
  try {
    return Math.max(MIN, Math.min(MAX, parseInt(value || `${defaultValue}`, 10)));
  } catch {
    return defaultValue;
  }
}

function MockupCustomSizeController({ activeSize, onChange }: Props) {
  const { t } = useTranslation();
  const [width, setWidth] = useState(`${activeSize.width}`);
  const [height, setHeight] = useState(`${activeSize.height}`);
  const [proportionLocked, setProportionLocked] = useState(true);

  function onWidthChange(e: ChangeEvent<HTMLInputElement>) {
    if (proportionLocked) {
      try {
        const currentWidthNum = parseInt(width || `${activeSize.width}`, 10);
        const currentHeightNum = parseInt(height || `${activeSize.height}`, 10);
        const widthNum = parseInt(e.target.value || `${activeSize.width}`, 10);
        setHeight(`${widthNum * (currentHeightNum / currentWidthNum)}`);
      } catch {
        //
      }
    }
    setWidth(e.target.value);
  }

  function onHeightChange(e: ChangeEvent<HTMLInputElement>) {
    if (proportionLocked) {
      try {
        const currentWidthNum = parseInt(width || `${activeSize.width}`, 10);
        const currentHeightNum = parseInt(height || `${activeSize.height}`, 10);
        const heightNum = parseInt(e.target.value || `${activeSize.height}`, 10);
        setWidth(`${heightNum * (currentWidthNum / currentHeightNum)}`);
      } catch {
        //
      }
    }
    setHeight(e.target.value);
  }

  function changeSize() {
    const validWidth = getValidValue(width, activeSize.width);
    const validHeight = getValidValue(height, activeSize.height);
    onChange({ width: validWidth, height: validHeight });
    setWidth(`${validWidth}`);
    setHeight(`${validHeight}`);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      changeSize();
    }
  }

  function toggleLock() {
    setProportionLocked((locked) => !locked);
  }

  return (
    <div className={styles.MockupCustomSizeController}>
      <div className={styles.inputWrap}>
        <WithTooltip overlay={t('editor-width')}>
          <IconWidth />
        </WithTooltip>
        <Input
          className={cn(styles.input, 'cy-input-width')}
          label="px"
          value={width}
          onChange={onWidthChange}
          onBlur={changeSize}
          onKeyDown={onKeyDown}
          type="number"
        />
      </div>
      <div className={styles.inputWrap}>
        <WithTooltip overlay={t('editor-height')}>
          <IconHeight />
        </WithTooltip>
        <Input
          className={cn(styles.input, 'cy-input-height')}
          label="px"
          value={height}
          onChange={onHeightChange}
          onBlur={changeSize}
          onKeyDown={onKeyDown}
          type="number"
        />
      </div>
      <button
        className={cn(styles.lock, { [styles.on]: proportionLocked })}
        onClick={toggleLock}
        aria-label="size controller"
      >
        <svg
          className={styles.bar}
          width="18"
          height="8"
          viewBox="0 0 18 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1H17V7" stroke="#525252" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <IconSmallLock className={styles.icon} />
        <svg
          className={styles.bar}
          width="18"
          height="8"
          viewBox="0 0 18 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 7H17V1" stroke="#525252" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
    </div>
  );
}

export default React.memo(MockupCustomSizeController);

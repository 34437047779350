import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setBaseTabsAction } from 'editor/src/store/editorModules/sidebar/slice';
import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import environment from 'editor/src/environment';

import updateSidebarTabsOperation from './updateSidebarTabsOperation';

const setBaseTabsOperation = (tabs: Array<TAB_NAMES | CustomTabInfo>) => (dispatch: ThunkDispatch) => {
  batch(() => {
    const updatedTabs =
      environment.enforceTabs?.reduce(
        (tabs, tab) => {
          if (tabs.indexOf(tab) === -1) {
            tabs.push(tab);
          }
          return tabs;
        },
        [...tabs],
      ) ?? tabs;

    dispatch(setBaseTabsAction(updatedTabs));
    dispatch(updateSidebarTabsOperation());
  });
};

export default setBaseTabsOperation;

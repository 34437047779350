import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { UNSELECTED_PAGE_COUNT } from 'editor/src/store/variants/reducer/setPageCountReducer';
import { VariantInfo } from 'editor/src/store/variants/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const sendProductSwitchedOperation = (variant: VariantInfo) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const { selectedPageCount } = getState().variants;

  sendPostMessage('design.productSwitched', {
    productUid: variant.variation.productUid,
    width: variant.dimensions?.width,
    height: variant.dimensions?.height,
    pageCount: selectedPageCount === UNSELECTED_PAGE_COUNT ? undefined : selectedPageCount,
  });
};

export default sendProductSwitchedOperation;

import React, { useEffect } from 'react';

import { setDesignSavingStatusAction } from 'editor/src/store/design/slice';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { CanShow } from '../Menu/type';
import { canShow as saveDraftCanShow } from '../TopMenuDesktop/buttons/ButtonSaveDraft';

import Status from './Status';

export const canShow: CanShow = (state, context) =>
  !!context.hostSettings.editorMode &&
  ['add-with-draft', 'draft'].includes(context.hostSettings.editorMode) &&
  !saveDraftCanShow(state, context);

function DesignSavingStatus() {
  const dispatch = useDispatch();
  const designSavingStatus = useSelector((state) => state.design.designSavingStatus);

  useEffect(() => {
    function handleOnline() {
      dispatch(setDesignSavingStatusAction('saved'));
    }

    function handleOffline() {
      dispatch(setDesignSavingStatusAction('offline'));
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return <Status status={designSavingStatus} />;
}
export default DesignSavingStatus;

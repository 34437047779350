import { RootState } from 'editor/src/store/index';

import { isEmbedded } from '../../watchers/useDisplayMode';

const isChangeBackgroundAllowed = (state: RootState) =>
  isEmbedded
    ? !!state.design?.designData?.personalizationSettings?.allowChangeBackgroundColor &&
      state.hostSettings.allowSpreadBackgroundColorChange
    : state.hostSettings.allowSpreadBackgroundColorChange;

export default isChangeBackgroundAllowed;

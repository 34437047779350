import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { MimeType } from 'editor/src/store/gallery/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import isPremiumIconVisible from 'editor/src/store/plugins/selector/isPremiumIconVisible';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import Adjustment from 'editor/src/component/DesktopSidebar/TabContents/AdjustmentsTabContent/Adjustment';
import ADJUSTMENTS from 'editor/src/component/DesktopSidebar/TabContents/AdjustmentsTabContent/adjustments';
import LinkButton from 'editor/src/component/LinkButton';

import styles from './index.module.scss';

const FILTERED_ADJUSTMENTS = ADJUSTMENTS.filter((adjustment) => !adjustment.hidden).slice(0, 3);
const ALLOW_FREE_ADJUSTMENTS = 2;

function AdjustmentsContainer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const uuid = useSelector(getSelectedImageUuid);
  const structureIndex = useSelector((store) => getStructureIndexByElementUuidMemoized(store, uuid || -1));
  const nonFunctional = useSelector(
    (state) => getPlugin(state, PluginName.Filters)?.state === PluginState.NON_FUNCTIONAL,
  );
  const premiumIconVisible = useSelector((state) => isPremiumIconVisible(state, PluginName.Filters));
  const elements = useSelector(getSelectedMediaElementsObjects);
  const firstElement = elements[0];
  const imageType = useSelector((state) =>
    firstElement?.type === 'image' ? getImageById(state, firstElement.imageId)?.type : MimeType.SVG,
  );

  if (!uuid || !structureIndex || imageType === MimeType.SVG || firstElement?.type !== 'image') {
    return null;
  }

  const showAll = () => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.ADJUSTMENTS));
  };

  return (
    <div className={cn(styles.container, 'mb-2 cy-adjustments-container')}>
      <div className={cn(styles.title, 'row-flex row-flex--between')}>
        <div>{t('editor-adjustments')}</div>
        <LinkButton className={cn('cy-plugin-show-all', styles.showAll)} onClick={() => showAll()}>
          {t('show-all')}
        </LinkButton>
      </div>
      <div className="mt-1">
        {FILTERED_ADJUSTMENTS.map((adjustment, idx) => (
          <Adjustment
            element={firstElement}
            address={structureIndex}
            adjustment={adjustment}
            key={adjustment.name}
            disabled={nonFunctional && idx >= ALLOW_FREE_ADJUSTMENTS}
            showPremiumIcon={premiumIconVisible && idx >= ALLOW_FREE_ADJUSTMENTS}
          />
        ))}
      </div>
    </div>
  );
}

export default AdjustmentsContainer;

import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'editor/src/component/Button';
import { useDispatch } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store';
import requestDataOperation from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/operation/requestDataOperation';
import SpinnerLocal from 'editor/src/component/SpinnerLocal';

import styles from './index.module.scss';

function Error() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const resetResults = useCallback(() => {
    dispatch(requestDataOperation());
  }, []);

  return (
    <div className={cn(styles.error, 'cy-content-provider-plugin-error')}>
      <div className={styles.info}>
        <div className={styles.spinner}>
          <SpinnerLocal show color="#FD5F5C" />
        </div>
        <div className={styles.text}>
          <p>
            <strong>{t('editor-plugin-error-content-cannot-be-reached-1')}</strong>
          </p>
          <p>{t('editor-plugin-error-content-cannot-be-reached-2')}</p>
        </div>
      </div>
      <Button secondary className={cn(styles.button, 'cy-reconnect-button')} onClick={resetResults}>
        {t('editor-reconnect')}
      </Button>
    </div>
  );
}

export default Error;

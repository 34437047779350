import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setDefaultSettingsValueAction, setSettingsValueAction } from 'editor/src/store/editor/slice';
import { Settings } from 'editor/src/store/editor/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const setHostDefaultSettingsOperation = (settings: Partial<Settings>) => (dispatch: ThunkDispatch) => {
  batch(() => {
    Object.entries(settings).forEach(([key, value]) => {
      const property = key as keyof Settings;
      dispatch(setSettingsValueAction({ property, value }));
      dispatch(setDefaultSettingsValueAction({ property, value }));
    });
  });
};

export default setHostDefaultSettingsOperation;

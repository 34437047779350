import { RootState } from 'editor/src/store/index';

const getVisibleWarningsCount = (state: RootState) => {
  const { hiddenWarnings, showWarningsOnlyAfterDesignRequest: showWarningOnlyAfterDesignRequest } = state.hostSettings;
  return showWarningOnlyAfterDesignRequest
    ? 0
    : state.editorModules.warnings.list.reduce((acc, warning) => (hiddenWarnings[warning.type] ? acc : acc + 1), 0);
};

export default getVisibleWarningsCount;

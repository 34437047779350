import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  titleTranslationKey?: string;
  descriptionTranslationKey?: string;
}

function SettingsItem({ titleTranslationKey, children, descriptionTranslationKey }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.settingsItem}>
      {titleTranslationKey ? <div className={styles.title}>{t(titleTranslationKey)}:</div> : null}
      <div>{children}</div>
      {descriptionTranslationKey ? <div className={styles.description}>{t(descriptionTranslationKey)}</div> : null}
    </div>
  );
}

export default SettingsItem;

import getSpread from 'editor/src/store/design/selector/getSpread';
import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { RootState } from 'editor/src/store/index';

function getFocusCenter(state: RootState, spreadIndex: number) {
  const { focusedContentAddress } = state.editor;
  let x: number;
  let y: number;

  const spread = getSpread(state, spreadIndex);
  if (focusedContentAddress) {
    const page = spread?.pages[focusedContentAddress.pageIndex];
    const content = page?.groups.content?.[focusedContentAddress.contentIndex];
    x = (page?.x || 0) + (content ? content.x + content.width / 2 : 0);
    y = (page?.y || 0) + (content ? content.y + content.height / 2 : 0);
  } else {
    const spreadHeight = spread ? getSpreadHeightFromSpread(spread) : 0;
    const spreadWidth = spread ? getSpreadWidthFromSpread(spread) : 0;
    x = spreadWidth / 2;
    y = spreadHeight / 2;
  }

  return { x, y };
}

export default getFocusCenter;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { Thunk } from 'editor/src/store/hooks';
import getDesignOptionKey from 'editor/src/store/variants/helpers/getDesignOptionKey';
import {
  setDesignOptionsEnabledAction,
  setDesignOptionsAction,
  updateVariationGroupsAction,
  setSelectedDesignOptionAction,
  setSelectedGroupAction,
} from 'editor/src/store/variants/slice';
import { DesignOptionControl } from 'editor/src/store/variants/types';

import getVariationGroups from 'editor/src/util/getVariationGroups';

const getInitialDesignOptions = (): DesignOptionControl => ({
  type: 'external',
  subtype: 'design-option-control-1',
  key: 'design-option',
  title: 'Choose Your Option',
  options: [
    { value: 'design-option-1', title: 'Design Option 1' },
    { value: 'design-option-2', title: 'Design Option 2' },
  ],
});

const setDesignOptionsEnabledOperation =
  (enabled: boolean): Thunk =>
  (dispatch, getState, { i18n }) => {
    const initialDesignOptions = getInitialDesignOptions();
    batch(() => {
      dispatch(saveUndoRedoStateOperation('design options'));
      dispatch(setDesignOptionsEnabledAction(enabled));
      if (enabled) {
        dispatch(setDesignOptionsAction([initialDesignOptions]));
        dispatch(setSelectedDesignOptionAction(initialDesignOptions.options[0].value));
      } else {
        dispatch(setDesignOptionsAction([]));
        dispatch(setSelectedDesignOptionAction(undefined));
      }
    });
    const state = getState();
    const variationGroups = getVariationGroups(state, i18n);
    dispatch(updateVariationGroupsAction(variationGroups));
    const group = variationGroups.find(
      (group) => getDesignOptionKey(group) === state.variants.selectedDesignOptionValue,
    );
    group && dispatch(setSelectedGroupAction(group.key));
  };

export default setDesignOptionsEnabledOperation;

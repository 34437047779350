import cn from 'classnames';
import React from 'react';

import { ImageManipulationPlugin } from 'editor/src/store/plugins/types';

import EditTabButton from 'editor/src/component/DesktopSidebar/TabContents/EditImageTabContent/EditTabButton';
import WithTooltip from 'editor/src/component/WithTooltip';

import useImageManipulationButton from './useImageManipulationButton';

interface Props {
  className: string;
  plugin: ImageManipulationPlugin;
}

function ImageManipulationButton({ className, plugin }: Props) {
  const { handleClick, disabled, premiumEnabled, PremiumIcon, imageModified, tooltip } = useImageManipulationButton(
    plugin.name,
  );

  return (
    <WithTooltip overlay={tooltip} disabled={!tooltip}>
      <EditTabButton
        image={plugin.icon}
        label={imageModified ? plugin.extra.resetImageLabel : plugin.extra.modifyImageLabel}
        className={cn(
          className,
          imageModified ? 'cy-button-request-original-image' : 'cy-button-request-modified-image',
        )}
        onClick={handleClick}
        premium={premiumEnabled}
        PremiumIcon={PremiumIcon}
        disabled={disabled}
      />
    </WithTooltip>
  );
}

export default ImageManipulationButton;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import { useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import showPremiumPrompt from 'editor/src/store/plugins/selector/showPremiumPrompt';
import { PluginName } from 'editor/src/store/plugins/types';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import GelatoPrompt from 'editor/src/component/GelatoPrompt';

import MediaShadow from './MediaShadow';
import TextCurve from './TextCurve';
import TextOutline from './TextOutline';

import styles from 'editor/src/component/DesktopSidebar/TabContents/AdjustmentsTabContent/index.module.scss';

function EffectsTabContent({ title }: TabContentProps) {
  const { t } = useTranslation();
  const { gelatoPrompt, selectedElement } = useSelector((state) => {
    const plugin = getPlugin(state, PluginName.TextEffects);
    return {
      selectedElement: getSelectedMediaElementsObjects(state)?.[0],
      gelatoPrompt: plugin && showPremiumPrompt(plugin),
    };
  }, shallowEqual);
  const allowCurvedText = useSelector((state) => getHostSetting(state, 'allowCurvedText'));

  if (!selectedElement || (selectedElement.type !== 'text' && selectedElement.type !== 'image')) {
    return null;
  }

  return (
    <>
      <TabContentHeader
        title={title || selectedElement.type === 'text' ? t('editor-text-effects') : t('Image effects')}
      />
      <ContentBlock>
        <MediaShadow selectedElement={selectedElement} />
        {selectedElement.type === 'text' && (
          <>
            <TextOutline selectedElement={selectedElement} />
            {allowCurvedText && <TextCurve selectedElement={selectedElement} />}
          </>
        )}
      </ContentBlock>
      {gelatoPrompt && (
        <GelatoPrompt className={styles.prompt} prompt={gelatoPrompt} pluginName={PluginName.TextEffects} />
      )}
    </>
  );
}

export default React.memo(EffectsTabContent);

import { fabric } from 'fabric';
import { Polygon } from 'polygon-clipping';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import getCurrentDpiLevels from 'editor/src/store/design/selector/getCurrentDpiLevels';
import getImageElementUrl from 'editor/src/store/design/selector/getImageElementUrl';
import { getMediaImageDPI } from 'editor/src/store/design/selector/getMediaElementDpi';
import { Coords, ElementAddress, MediaAddon, MediaImage } from 'editor/src/store/design/types';
import getIsFocusedMediaElementSelector from 'editor/src/store/editor/selector/getIsFocusedMediaElementSelector';
import isSampleFlagChangeAllowed from 'editor/src/store/editor/selector/isSampleFlagChangeAllowed';
import isZoomMode from 'editor/src/store/editor/selector/isZoomMode';
import { WarningType } from 'editor/src/store/editorModules/warnings/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import FabricImage from 'editor/src/fabric/FabricImage';
import FabricRect from 'editor/src/fabric/FabricRect';
import { emptyGreyImageIcon, imageAddIcon } from 'editor/src/util/emptyImageIcon';
import imagePlaceholder from 'editor/src/util/imagePlaceholder';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import { currentOperationManager } from 'editor/src/component/EditorArea/ElementOperationOverlay';
import FabricImageComponent from 'editor/src/component/EditorArea/fabricComponents/FabricImageComponent';
import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';
import FabricSVGComponent from 'editor/src/component/EditorArea/fabricComponents/FabricSVGComponent';
import useSnapMatch from 'editor/src/component/EditorArea/snapping/useSnapMatch';
import ElementLoader from 'editor/src/component/EditorArea/Spread/Page/MediaElement/ElementLoader';
import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';
import getElementRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getElementRect';
import useHoverBox from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useHoverBox';
import useIsInteractable from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useIsInteractable';
import useStoreSelection from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useStoreSelection';
import WarningIcon, {
  WarningIconInterface,
} from 'editor/src/component/EditorArea/Spread/Page/MediaElement/WarningIcon';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import useEmptyPlaceholderEventHandlers from 'editor/src/component/EditorArea/useEmptyPlaceholderEventHandlers';
import { UploaderContext } from 'editor/src/component/Uploader/useUploader';

import ActiveImageOverlay from './ActiveImageOverlay';
import {
  FRAME_PROPS,
  FRAME_PROPS_HIGH_WARNING,
  FRAME_PROPS_MEDIUM_WARNING,
  GHOST_CONTROL_VISIBILITY,
  GHOST_PROPS,
  GHOST_PROPS_HIGH_WARNING,
  GHOST_PROPS_MEDIUM_WARNING,
  LOCKED_CONTROL_VISIBILITY,
  UNLOCKED_CONTROL_VISIBILITY,
} from './elementsConstantProps';
import fabricPropsToImageUpdate from './fabricPropsToImageUpdate';
import getEmptyImageDimensions from './getEmptyImageDimensions';
import getImageClipPath from './getImageClipPath';
import getImageRect from './getImageRect';
import ImageShadow, { Ref as ShadowRef } from './ImageShadow';
import getImageShadow from './ImageShadow/getImageShadow';
import isImageEmpty from './isImageEmpty';
import getElementOperationByAction from './updateUtils/getElementOperationByAction';
import updateOnImageGhostChange from './updateUtils/updateOnImageGhostChange';
import useCropMode from './useCropMode';
import useDigitizedImage from './useDigitizedImage';
import useFilters from './useFilters';
import useFrameLoading from './useFrameLoading';
import useFrameManipulation from './useFrameManipulation';
import useImageModificationMode from './useImageModificationMode';
import usePerspectiveTranform from './usePerspectiveTranform';
import useUnselectOnClick from './useUnselectOnClick';

interface Props {
  elementData: MediaImage | MediaAddon;
  elementAddress: ElementAddress;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
  isMobile: boolean;
  selected: boolean;
  contentClipPolygons: Polygon[];
  ignorePersonalizationLock: boolean;
  showGuides: boolean;
  contentClipPath: fabric.Object | undefined;
}

function Image({
  elementData,
  elementAddress,
  pageCoords,
  canvasRotation,
  isMobile,
  selected,
  contentClipPolygons,
  ignorePersonalizationLock,
  showGuides,
  contentClipPath,
}: Props) {
  const dispatch = useDispatch();
  const { mm2px, px2mm } = useFabricUtils();
  const fabricCanvas = useFabricCanvas();
  const uploader = useContext(UploaderContext);

  const frameRef = useRef<FabricRect>(null);
  const emptyImageRef = useRef<FabricImage>(null);
  const imageRef = useRef<FabricImage>(null);
  const shadowRef = useRef<ShadowRef>(null);
  const ghostRef = useRef<FabricImage>(null);
  const warningRef = useRef<WarningIconInterface>(null);

  const { liveElement: element, liveUpdate } = useMediaElementLiveUpdates(elementData);

  const { sampleFlagChangeAllowed, zoomMode, imageUrl, crossOrigin } = useSelector((state) => {
    const imageUrlData = getImageElementUrl(state, element);
    const { pluginPreviewMode } = state.editor;

    return {
      sampleFlagChangeAllowed: isSampleFlagChangeAllowed(state),
      zoomMode: isZoomMode(state),
      crossOrigin: imageUrlData.crossOrigin,
      imageUrl:
        element.uuid === pluginPreviewMode?.elementUuid && pluginPreviewMode?.url !== undefined
          ? pluginPreviewMode.url
          : imageUrlData.imageUrl,
    };
  }, shallowEqual);

  const imageCrop = useSelector((state) => state.hostSettings.coreFeatures.imageCrop);
  const dpiWarningsEnabled = useSelector((state) => state.hostSettings.enabledWarnings[WarningType.LowDPI]);
  const dpiWarningsHidden = useSelector((state) => state.hostSettings.hiddenWarnings[WarningType.LowDPI]);
  const showCroppedImageArea = useSelector((state) => state.hostSettings.showCroppedImageArea);
  const changeImageFrameColorBasedOnDPI = useSelector((state) => state.hostSettings.changeImageFrameColorBasedOnDPI);
  useStoreSelection(frameRef, element.uuid, element.type, selected);

  const imageIsEmpty = isImageEmpty(element);
  const { isInCropMode, toggleCropMode } = useCropMode(element.uuid, imageIsEmpty, selected, frameRef, ghostRef);
  const isFocusedElement = useSelector((state) => getIsFocusedMediaElementSelector(state, element.uuid));
  useImageModificationMode(element, imageIsEmpty, selected, frameRef, ghostRef);

  const interactable = useIsInteractable(element, ignorePersonalizationLock);

  const shadow = useMemo(
    () => (element.type === 'image' ? getImageShadow(element.shadow, mm2px) : undefined),
    [element],
  );
  const { frameRect, imageRect } = useMemo(() => {
    const frameRect = getElementRect(element, pageCoords, canvasRotation, mm2px);
    const imageRect = getImageRect(element, pageCoords, canvasRotation, mm2px);

    return { frameRect, imageRect };
  }, [element, pageCoords, canvasRotation, mm2px]);

  const galleryImage = useSelector((state) =>
    element.type === 'image' ? getImageById(state, element.imageId) : undefined,
  );
  const limits = useSelector(getCurrentDpiLevels, shallowEqual);

  const canShowDPIIndication = changeImageFrameColorBasedOnDPI && !dpiWarningsHidden && dpiWarningsEnabled;
  const dpiLevel =
    canShowDPIIndication && galleryImage && element.type === 'image' && !element.sample
      ? getMediaImageDPI(element, galleryImage)
      : undefined;

  let frameProps = FRAME_PROPS;
  let ghostProps = GHOST_PROPS;
  if (dpiLevel !== undefined && limits && dpiLevel < limits.low) {
    frameProps = FRAME_PROPS_HIGH_WARNING;
    ghostProps = GHOST_PROPS_HIGH_WARNING;
  } else if (dpiLevel !== undefined && limits && dpiLevel < limits.medium) {
    frameProps = FRAME_PROPS_MEDIUM_WARNING;
    ghostProps = GHOST_PROPS_MEDIUM_WARNING;
  }

  const hoverBox = useHoverBox(frameRect, isMobile, selected || !interactable, canvasRotation, frameProps.borderColor);
  const snapMatch = useSnapMatch(element.uuid, showGuides && !element.locked, pageCoords);
  const perspectiveTransform = usePerspectiveTranform(
    element,
    elementAddress,
    imageIsEmpty,
    selected,
    frameRect,
    imageRef,
    isInCropMode,
  );

  const onLiveUpdate = (action: 'move' | 'resize') => {
    const imageUpdate = fabricPropsToImageUpdate(pageCoords, canvasRotation, px2mm, frameRect, frameRef, imageRef);
    const newElement = { ...element, ...imageUpdate };
    liveUpdate(newElement);
    if (frameRef.current?.oCoords) {
      currentOperationManager.emit('objectUpdating', newElement, frameRef.current.oCoords, action);
    }
  };

  const pattern = element.type === 'image' ? element.pattern : undefined;

  const frameManipulation = useFrameManipulation(
    contentClipPolygons,
    frameRect,
    !!element.locked,
    frameRef,
    imageRef,
    emptyImageRef,
    ghostRef,
    warningRef,
    galleryImage,
    canShowDPIIndication ? limits : undefined,
    pattern,
    onLiveUpdate,
    shadowRef,
    !!element.sample,
    contentClipPath,
  );

  const [imageLoaded, setImageLoaded] = useState(false);
  const onImageLoaded = useCallback(() => {
    if (imageUrl !== imagePlaceholder) {
      setImageLoaded(true);
    }
  }, [imageUrl]);
  const elementLoading = element.type === 'image' && !!element.loading;
  const isLoading = (!element.hidden && !imageIsEmpty && !!imageUrl && !imageLoaded) || elementLoading;
  useFrameLoading(isLoading, frameRef, fabricCanvas);

  const { onPlaceholderClick: onElementSampleClick } = useEmptyPlaceholderEventHandlers(uploader);

  // if image element selected open gallery. Open uploader if there are no images in gallery
  const openUploaderIfEmpty = useCallback(() => {
    if (!selected && element.type === 'image' && (imageIsEmpty || (element.sample && !sampleFlagChangeAllowed))) {
      // does it make sense to change it to true?
      const openUploaderIfGalleryTabOpened = false;
      onElementSampleClick(openUploaderIfGalleryTabOpened);
    }
  }, [selected, imageIsEmpty, element.type, element.sample, sampleFlagChangeAllowed]);

  const isMouseDown = useRef(false);
  const elementClickUnselector = useUnselectOnClick(fabricCanvas, isMobile, isInCropMode, toggleCropMode);

  const onFrameMouseDown = useCallback(
    (e: fabric.IEvent) => {
      isMouseDown.current = true;
      snapMatch.onMouseDown(e);
      frameManipulation.onMouseDown();
      elementClickUnselector.onMouseDown(e);
      openUploaderIfEmpty();
    },
    [frameManipulation.onMouseDown, snapMatch.onMouseDown, openUploaderIfEmpty, elementClickUnselector.onMouseDown],
  );

  const onFrameMouseMove = useCallback(
    (e: fabric.IEvent) => {
      if (isMouseDown.current) {
        snapMatch.onMouseMove(e);
        frameManipulation.onMouseMove(e);
      }
    },
    [snapMatch.onMouseMove, frameManipulation.onMouseMove],
  );

  const onFrameMouseUp = useCallback(
    (e) => {
      isMouseDown.current = false;
      snapMatch.onMouseUp();
      frameManipulation.onMouseUp();
      currentOperationManager.emit('objectUpdateStop');
      elementClickUnselector.onFrameMouseUp(e);
    },
    [snapMatch.onMouseUp, frameManipulation.onMouseUp, elementClickUnselector.onFrameMouseUp],
  );

  const onGhostMouseDown = useCallback(
    (e) => {
      isMouseDown.current = true;
      elementClickUnselector.onMouseDown(e);
    },
    [elementClickUnselector.onMouseDown],
  );

  const onGhostMouseMove = useCallback(
    (e: fabric.IEvent) => {
      if (isMouseDown.current) {
        updateOnImageGhostChange(
          frameRef,
          ghostRef,
          imageRef,
          e,
          fabricCanvas.uniScaleKey === 'shiftKey' && (e.e as MouseEvent).shiftKey,
          px2mm,
          galleryImage,
          canShowDPIIndication ? limits : undefined,
          pattern,
          onLiveUpdate,
        );
      }
    },
    [fabricCanvas.uniScaleKey, galleryImage, limits, px2mm, pattern],
  );

  const onGhostMouseUp = useCallback(
    (e) => {
      isMouseDown.current = false;
      elementClickUnselector.onGhostMouseUp(e);
    },
    [elementClickUnselector.onGhostMouseUp],
  );

  const hasPattern = element.type === 'image' && !!element.pattern;
  const onFrameDoubleClick = useCallback(() => {
    if (!imageIsEmpty && !hasPattern && imageCrop) {
      toggleCropMode();
    }
  }, [imageIsEmpty, hasPattern, toggleCropMode, imageCrop]);

  const frameCustomEvents = useMemo(
    () => ({
      'object:enter': () => imageCrop && toggleCropMode(),
      'object:escape': () => fabricCanvas.discardActiveObject(),
    }),
    [toggleCropMode, imageCrop],
  );

  const ghostCustomEvents = useMemo(
    () => ({
      'object:escape': () => toggleCropMode(),
    }),
    [toggleCropMode],
  );

  const imageOpacity = element.sample || elementLoading ? 0.3 : 1;

  const getFabricOptionsOnUpdate = useCallback(
    (image: FabricImage) => {
      const img = image.getElement();
      let scaleX = element.pw / px2mm(img.width);
      let scaleY = element.ph / px2mm(img.height);

      // when the image is uploading and the user plays with the ghost, we need to update the ghost image
      // because the ghost image will only be updated after the next React render, which won't happen while
      // moving the ghost
      if (ghostRef.current) {
        ghostRef.current.set({ scaleX, scaleY });
        ghostRef.current.setElement(image.getElement());
      }

      if (perspectiveTransform.value) {
        scaleX = element.width / px2mm(img.width);
        scaleY = element.height / px2mm(img.height);
      }

      return {
        scaleX,
        scaleY,
        opacity: !image.isLoaded || element.hidden ? 0 : imageOpacity,
      };
    },
    [element.pw, element.ph, px2mm, element.hidden, imageOpacity, !!perspectiveTransform.value],
  );

  const getFabricGhostOptionsOnUpdate = useCallback(
    (image: FabricImage) => ({
      scaleX: element.pw / px2mm(image.getElement().width),
      scaleY: element.ph / px2mm(image.getElement().height),
    }),
    [element.pw, element.ph, px2mm],
  );

  const getEmptyIconOnUpdate = useCallback(
    (obj: fabric.Object) => getEmptyImageDimensions(obj, frameRect, 0.4),
    [frameRect.width, frameRect.height, frameRect.left, frameRect.top, element.sample],
  );

  const imageClipPath = useMemo(() => {
    if (imageIsEmpty || !imageLoaded || perspectiveTransform.value) {
      return getClipPath(frameRect, contentClipPolygons, false, contentClipPath);
    }
    return getImageClipPath(frameRect, imageRect, contentClipPolygons, isInCropMode, contentClipPath);
  }, [
    contentClipPolygons,
    frameRect,
    imageRect,
    imageIsEmpty,
    imageLoaded,
    isInCropMode,
    !!perspectiveTransform.value,
    contentClipPath,
  ]);

  const cornerSize = isMobile ? 18 : 12;

  const saveObjectState = useCallback(
    (action: MediaUpdateActionName) => {
      const imageUpdate = fabricPropsToImageUpdate(pageCoords, canvasRotation, px2mm, frameRect, frameRef, imageRef);
      if (imageUpdate) {
        dispatch(updateMediaElementOperation(elementAddress, imageUpdate, action));
      }
    },
    [elementAddress, frameRect, pageCoords, canvasRotation, px2mm, isInCropMode],
  );

  const saveObjectStateFrame = useCallback(
    (e: fabric.EditorIEvent) => {
      saveObjectState(getElementOperationByAction(e.transform?.action));
    },
    [saveObjectState],
  );
  const saveObjectStateGhost = useCallback(() => saveObjectState(MediaUpdateActionName.CROPPED), [saveObjectState]);

  useEffect(() => {
    // during initial render, we don't want to trigger that change if the image is not loaded.
    if (!frameManipulation.isMovingGhost && imageRef.current?.isLoaded) {
      saveObjectStateGhost();
    }
  }, [frameManipulation.isMovingGhost]);

  const filters = useFilters(element.type === 'image' ? element : undefined);

  const { frameBackground, frameOpacity, emptyIcon, frameClippath } = useMemo(() => {
    let frameBackground: string | undefined;
    let frameOpacity: number | undefined;
    let emptyIcon = '';
    let frameClippath: fabric.Object | undefined;
    if (!element.hidden && (imageIsEmpty || !imageUrl)) {
      frameBackground = '#212121';
      frameOpacity = 0.1;
      emptyIcon = emptyGreyImageIcon;
      frameClippath = imageClipPath;
    } else if ((!element.hidden && !imageLoaded) || elementLoading) {
      frameBackground = '#c1c1c1';
      frameOpacity = 0.2;
      emptyIcon = emptyGreyImageIcon;
      frameClippath = imageClipPath;
    }
    return {
      frameBackground,
      frameOpacity,
      emptyIcon,
      frameClippath,
    };
  }, [element.hidden, imageIsEmpty, !!imageUrl, imageLoaded, imageClipPath, elementLoading]);

  const { digitizedAssetUrl, showDigitizedAsset } = useDigitizedImage(element, galleryImage, imageUrl);

  const isImageCropped = element.width !== element.pw || element.height !== element.ph;
  const showGhost =
    isInCropMode ||
    (showCroppedImageArea && isImageCropped) ||
    (element.locked && frameManipulation.isMovingGhost && !imageIsEmpty);
  const fabricZIndex = zIndex.MEDIA + elementAddress.elementIndex;

  let fabricSource = imageUrl;
  if (showDigitizedAsset && digitizedAssetUrl) {
    fabricSource = digitizedAssetUrl;
  }

  let imageIcon: JSX.Element | null = null;
  if (element.sample) {
    imageIcon = (
      <FabricSVGComponent
        svg={imageAddIcon}
        evented={false}
        angle={frameRect.angle}
        originX="left"
        originY="top"
        zIndex={fabricZIndex + 0.2}
        opacity={element.hidden ? 0 : 1}
        ref={emptyImageRef}
        getFabricOptionsOnUpdate={getEmptyIconOnUpdate}
        clipPath={imageClipPath}
      />
    );
  } else if (imageIsEmpty || !imageUrl || !imageLoaded || elementLoading) {
    imageIcon = (
      <FabricSVGComponent
        svg={emptyIcon}
        evented={false}
        angle={frameRect.angle}
        originX="left"
        originY="top"
        zIndex={fabricZIndex}
        opacity={element.hidden ? 0 : 1}
        ref={emptyImageRef}
        getFabricOptionsOnUpdate={getEmptyIconOnUpdate}
        clipPath={frameClippath}
      />
    );
  }

  return (
    <>
      <FabricRectComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...frameProps}
        angle={frameRect.angle}
        width={frameRect.width}
        height={frameRect.height}
        left={frameRect.left}
        top={frameRect.top}
        onMouseDownBefore={onFrameMouseDown} // 'before' so that it's emitted before fabric 'selected' event
        onMouseMove={onFrameMouseMove}
        onMouseUp={onFrameMouseUp}
        onMouseOver={hoverBox.onMouseOver}
        onMouseOut={hoverBox.onMouseOut}
        onModified={saveObjectStateFrame}
        onMouseDblClick={onFrameDoubleClick}
        events={frameCustomEvents}
        zIndex={fabricZIndex}
        uuid={element.uuid}
        ref={frameRef}
        lockMovementX={element.locked}
        lockMovementY={element.locked}
        lockUniScaling
        lockScalingX={element.locked}
        lockScalingY={element.locked}
        lockRotation={element.locked}
        controlVisibility={
          element.locked || perspectiveTransform.isActive ? LOCKED_CONTROL_VISIBILITY : UNLOCKED_CONTROL_VISIBILITY
        }
        hoverCursor={selected ? 'move' : 'pointer'}
        evented={interactable}
        selectable={!isMobile || zoomMode}
        cornerSize={cornerSize}
        visible={!zoomMode}
        objectCaching={false}
        backgroundColor={frameBackground}
        opacity={frameOpacity}
        clipPath={frameClippath}
      />
      {imageIcon}
      {showGhost && imageLoaded && imageRef.current && imageUrl && (
        <FabricImageComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...ghostProps}
          type="clone-of-image"
          source={imageRef.current.getElement()}
          cacheKey={perspectiveTransform.value ? undefined : imageRef.current.cacheKey}
          crossOrigin={crossOrigin}
          angle={imageRect.angle}
          left={imageRect.left}
          top={imageRect.top}
          evented={isInCropMode}
          cornerSize={cornerSize}
          zIndex={fabricZIndex + 100}
          controlVisibility={GHOST_CONTROL_VISIBILITY}
          onMouseDown={onGhostMouseDown}
          onMouseMove={onGhostMouseMove}
          onMouseUp={onGhostMouseUp}
          onModified={saveObjectStateGhost}
          events={ghostCustomEvents}
          uuid={element.uuid}
          hasBorders={!zoomMode}
          hasControls={!zoomMode}
          ref={ghostRef}
          strokeWidth={0}
          flipX={element.flipX}
          flipY={element.flipY}
          getFabricOptionsOnUpdate={getFabricGhostOptionsOnUpdate}
        />
      )}
      {!imageIsEmpty && imageUrl && fabricSource && (
        <>
          {shadow && imageLoaded && imageRef.current && (
            <ImageShadow
              ref={shadowRef}
              source={imageRef.current.getElement()}
              crossOrigin={crossOrigin}
              element={element}
              zIndex={fabricZIndex + (isFocusedElement ? 100 : 0.1) - 0.01}
              frameRect={frameRect}
              shadow={shadow}
              contentClipPolygons={contentClipPolygons}
              contentClipPath={contentClipPath}
            />
          )}
          <FabricImageComponent
            source={fabricSource}
            crossOrigin={crossOrigin}
            angle={perspectiveTransform.value ? frameRect.angle : imageRect.angle}
            left={perspectiveTransform.value ? frameRect.left : imageRect.left}
            top={perspectiveTransform.value ? frameRect.top : imageRect.top}
            evented={false}
            zIndex={fabricZIndex + (isFocusedElement ? 100 : 0.1)}
            clipPath={imageClipPath}
            getFabricOptionsOnUpdate={getFabricOptionsOnUpdate}
            onImageLoaded={onImageLoaded}
            strokeWidth={0}
            ref={imageRef}
            uuid={element.uuid}
            frameRect={frameRect}
            filters={filters}
            flipX={element.flipX}
            flipY={element.flipY}
          />
        </>
      )}
      {isFocusedElement && <ActiveImageOverlay zIndex={fabricZIndex + 99} />}
      {hoverBox.render()}
      {perspectiveTransform.render()}
      {!dpiWarningsHidden && (
        <WarningIcon ref={warningRef} frameRect={frameRect} zIndex={zIndex.WARNING_WATERMARK} element={element} />
      )}
      {(element.digitizing || (showDigitizedAsset && !digitizedAssetUrl && elementData.stitch_count !== -1)) && (
        <ElementLoader frameRect={frameRect} element={element} />
      )}
    </>
  );
}

export default React.memo(Image);

import { Dimensions, MediaImage } from 'editor/src/store/design/types';
import addImageToSessionOperation from 'editor/src/store/gallery/operation/addImageToSessionOperation';
import { addImageAction } from 'editor/src/store/gallery/slice';
import { ImageState, MimeType } from 'editor/src/store/gallery/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const uploadImageAssetOperation =
  (
    image: MediaImage,
    assetId: string,
    pluginName: string,
    url: string,
    dimensions: Dimensions,
    hasAssetDimensions: boolean,
  ) =>
  (dispatch: ThunkDispatch) => {
    sendPostMessage('plugins.uploadAsset', {
      pluginAssetId: assetId,
      plugin: pluginName,
    });
    dispatch(
      addImageAction({
        state: ImageState.REMOTE_PREVIEW,
        id: assetId,
        name: image.name,
        width: dimensions.width,
        height: dimensions.height,
        type: MimeType.JPG,
        url,
        hasAssetDimensions,
      }),
    );
    dispatch(addImageToSessionOperation(assetId));
  };

export default uploadImageAssetOperation;

import { useMemo } from 'react';

import { ContentAddress, Page } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

function useMirroring(spreadIndex: number, pageIndex: number, page: Page | undefined) {
  const mirroringEnabled = useSelector((state) => state.hostSettings.enableMirroring);

  const contentIndex = useMemo(
    () => page?.groups.content?.findIndex((content) => content.name === 'Content Front'),
    [page?.groups.content],
  );

  const frontContent = contentIndex !== undefined ? page?.groups.content?.[contentIndex] : undefined;
  const mediaBox = page?.groups.mediabox?.[0];
  const canMirror = !!mirroringEnabled && !!frontContent && !!mediaBox;
  const mirroringMode = frontContent?.extra?.mirroring;

  const contentAddress: ContentAddress | undefined = useMemo(
    () => (contentIndex ? { spreadIndex, pageIndex, contentIndex } : undefined),
    [spreadIndex, pageIndex, contentIndex],
  );

  return {
    mirrorInfo: canMirror ? { frontContent, mediaBox } : undefined,
    mirroringMode,
    canMirror,
    contentAddress,
  };
}

export default useMirroring;

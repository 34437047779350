import React from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyImageBoxWarning } from 'editor/src/store/editorModules/warnings/types';

import getSpreadName from 'editor/src/util/design/getSpreadName';

import AbstractWarningItem from './AbstractWarningItem';

interface Props {
  warning: EmptyImageBoxWarning;
  selected: boolean;
}

function EmptyImageBoxWarningItem({ warning, selected }: Props) {
  const { t } = useTranslation();

  const spreadName = getSpreadName({ spreadIndex: warning.spreadIndex }).join(' - ');

  return (
    <AbstractWarningItem warning={warning} selected={selected} title={t('Empty photo box')} subtitle={spreadName}>
      {t('There are empty photo boxes')}
    </AbstractWarningItem>
  );
}

export default React.memo(EmptyImageBoxWarningItem);

import cn from 'classnames';
import React from 'react';

import Button from 'editor/src/component/Button';
import IconChevronDown from 'editor/src/component/Icon/IconChevronDown';

import styles from './index.module.scss';

interface Props {
  onClick: () => void;
}

function ScrollDownArrow({ onClick }: Props) {
  return (
    <Button className={cn(styles.ScrollDownArrow, 'cy-button-scroll-controls-down')} onClick={onClick}>
      <IconChevronDown />
    </Button>
  );
}

export default React.memo(ScrollDownArrow);

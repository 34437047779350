import IconCollections from 'editor/src/component/Icon/IconCollections';
import IconFormats from 'editor/src/component/Icon/IconFormats';
import IconGallery from 'editor/src/component/Icon/IconGallery';
import IconLayers from 'editor/src/component/Icon/IconLayers';
import IconLayouts from 'editor/src/component/Icon/IconLayouts';
import IconProducts from 'editor/src/component/Icon/IconProducts';
import IconSettings from 'editor/src/component/Icon/IconSettings';
import IconShutterstock from 'editor/src/component/Icon/IconShutterstock';
import IconStickers from 'editor/src/component/Icon/IconStickers';
import IconTemplates from 'editor/src/component/Icon/IconTemplates';
import IconText from 'editor/src/component/Icon/IconText';
import IconWarning from 'editor/src/component/Icon/IconWarning';

const customIconsList: {
  [iconName: string]: React.FC<{ className?: string }>;
} = {
  products: IconProducts,
  formats: IconFormats,
  addons: IconStickers,
  gallery: IconGallery,
  layers: IconLayers,
  layouts: IconLayouts,
  settings: IconSettings,
  templates: IconTemplates,
  text: IconText,
  warning: IconWarning,
  shutterstock: IconShutterstock,
  collections: IconCollections,
};

export default customIconsList;

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import { isElementWarning, WarningLevel, WarningType } from 'editor/src/store/editorModules/warnings/types';
import { useSelector } from 'editor/src/store/hooks';
import getDpiLevelsSettings from 'editor/src/store/hostSettings/selector/getDpiLevelsSettings';

import toastController from 'editor/src/util/toastController';

function useWarningWatcher() {
  const selectedImageUuid = useSelector(getSelectedImageUuid);
  const dpiLevels = useSelector(getDpiLevelsSettings);
  const enabledWarnings = useSelector((state) => state.hostSettings.enabledWarnings);
  const warnings = useSelector((state) => state.editorModules.warnings.list);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedImageUuid === undefined || !dpiLevels) {
      return;
    }
    const warning = warnings.find((warning) => isElementWarning(warning) && warning.uuid === selectedImageUuid);
    if (warning?.type !== WarningType.LowDPI || !enabledWarnings.LowDPI) {
      return;
    }

    if (
      (dpiLevels.showHighToastr && warning.level === WarningLevel.High) ||
      (dpiLevels.showMediumToastr && warning.level === WarningLevel.Medium)
    ) {
      toastController.warning(
        t(warning.level === WarningLevel.Medium ? 'editor-average-quality' : 'editor-poor-quality'),
        t(warning.level === WarningLevel.Medium ? 'editor-average-quality-info' : 'editor-poor-quality-info', {
          dpi: Math.round(warning.dpi),
        }),
      );
    }
  }, [warnings, selectedImageUuid]);
}

export default useWarningWatcher;

import { ElementAddressesIndexedByNames, StructureIndex } from '../types';

function getElementWithDuplicatedName(
  elementAddressesIndexedByNames: ElementAddressesIndexedByNames | undefined,
  changedName: string,
  structureIndex: StructureIndex | undefined,
) {
  if (!structureIndex || !elementAddressesIndexedByNames) {
    return undefined;
  }

  const elementWithDuplicatedName = elementAddressesIndexedByNames[changedName];

  if (
    !elementWithDuplicatedName ||
    (elementWithDuplicatedName.spreadIndex === structureIndex.spreadIndex &&
      elementWithDuplicatedName.pageIndex === structureIndex.pageIndex &&
      elementWithDuplicatedName.elementIndex === structureIndex.elementIndex)
  ) {
    return undefined;
  }

  return elementWithDuplicatedName;
}

export default getElementWithDuplicatedName;

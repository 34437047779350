import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SizeGuide } from 'editor/src/store/editorModules/formats/types';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import IconArrowLeft from 'editor/src/component/Icon/IconArrowLeft';

import styles from './index.module.scss';

export type SelectorDetail =
  | { type: 'text'; content: string; title?: string }
  | { type: 'size-guide'; data: SizeGuide };

interface Props {
  back: () => void;
  children: React.ReactNode;
}

function Details({ back, children }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div onClick={back} className={cn(styles.backButton, 'cy-formats-back-button')}>
        <IconArrowLeft className={styles.arrow} />
        {t('editor-back')}
      </div>
      <ContentBlock scroll className={styles.content}>
        {children}
      </ContentBlock>
    </>
  );
}

export default Details;

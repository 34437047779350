import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { Spread } from 'editor/src/store/design/types';

import { RectMediaElement } from 'editor/src/util/reflectDesignData/utils';

export type Dimension = 'width' | 'height';

export function checkRatio(element: RectMediaElement, value: number, dimension: Dimension, minValue: number): boolean {
  const otherDimension: Dimension = dimension === 'width' ? 'height' : 'width';
  return (element[otherDimension] * value) / element[dimension] >= minValue;
}

export function getDimensionUpdate(
  value: number,
  dimension: Dimension,
  element: RectMediaElement,
  ratioLocked: boolean,
) {
  const update: Partial<RectMediaElement> = { [dimension]: value };
  if (ratioLocked) {
    const otherDimension: Dimension = dimension === 'width' ? 'height' : 'width';
    const newOtherValue = (value * element[otherDimension]) / element[dimension];
    update[otherDimension] = newOtherValue;
  }

  return update;
}

export function getElementMaxSize(spread: Spread) {
  return Math.max(getSpreadWidthFromSpread(spread), getSpreadHeightFromSpread(spread)) * 100;
}

import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  onClick?: (e: MouseEvent) => void;
  className?: string;
  children: React.ReactNode;
}

function LinkButton({ children, onClick, className }: Props) {
  const handleClick: any = (e: MouseEvent) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button className={cn(styles.linkButton, className)} onClick={handleClick}>
      {children}
    </button>
  );
}

export default LinkButton;

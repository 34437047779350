import { captureException } from '@sentry/react';

import LOCALES from './locales';

import type { i18n } from 'i18next';

const getLocaleList = (i18n: i18n) => {
  const locales = Object.entries(LOCALES);
  try {
    if (Intl.DisplayNames) {
      const languageNames = new Intl.DisplayNames([i18n.languageWithDash], {
        type: 'language',
      });
      if (languageNames) {
        return locales.map(([key, value]) => ({
          label: languageNames.of(key) ?? value,
          value: key,
        }));
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error translating locales ', err);
    captureException(new Error(err || 'getLocaleList: Error translating locales '), {
      extra: {
        language: i18n.language,
      },
    });
  }
  return locales.map(([key, value]) => ({ label: value, value: key }));
};

export default getLocaleList;

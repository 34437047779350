import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import setAutoApplyLayoutSchemaNamesOperation from 'editor/src/store/design/operation/setAutoApplyLayoutSchemaNamesOperation';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import type { Thunk } from 'editor/src/store/hooks';

import applyOrFetchSceneStructureOperation from './applyOrFetchSceneStructureOperation';
import fetchProductScenesOperation from './fetchProductScenesOperation';

const applyDefaultMockupSceneIfNoneAppliedOperation =
  (layoutName = 'mockup'): Thunk =>
  (dispatch, getState) => {
    let state = getState();
    const { scenePlaceholder, productPlaceholder, scenes } = state.mockup;
    const firstScene = scenes.categories[0]?.scenes[0];
    const { designData } = state.design;

    batch(() => {
      if (scenePlaceholder.productCategories.length || productPlaceholder.activeProductUid) {
        dispatch(setAutoApplyLayoutSchemaNamesOperation([layoutName]));

        if (firstScene && designData && !designData.spreads[0].pages[0].groups.media?.length) {
          dispatch(applyOrFetchSceneStructureOperation(firstScene.id));
        }
      }

      state = getState();
      const placeholder = state.design.designData?.spreads[0].pages[0].groups.media?.find(isMediaMockupPlaceholder);
      if (placeholder && scenePlaceholder.productCategories.length) {
        dispatch(fetchProductScenesOperation(placeholder.variant.productUid));
      }
    });
  };

export default applyDefaultMockupSceneIfNoneAppliedOperation;

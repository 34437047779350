/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconTick({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M9.55 17.575C9.41667 17.575 9.29167 17.554 9.175 17.512C9.05833 17.4707 8.95 17.4 8.85 17.3L4.55 13C4.36667 12.8167 4.279 12.579 4.287 12.287C4.29567 11.9957 4.39167 11.7584 4.575 11.575C4.75833 11.3917 4.99167 11.3 5.275 11.3C5.55833 11.3 5.79167 11.3917 5.975 11.575L9.55 15.15L18.025 6.67502C18.2083 6.49169 18.446 6.40002 18.738 6.40002C19.0293 6.40002 19.2667 6.49169 19.45 6.67502C19.6333 6.85836 19.725 7.09569 19.725 7.38702C19.725 7.67902 19.6333 7.91669 19.45 8.10002L10.25 17.3C10.15 17.4 10.0417 17.4707 9.925 17.512C9.80833 17.554 9.68333 17.575 9.55 17.575Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconTick;

import cn from 'classnames';
import React, { useLayoutEffect, useMemo, useRef } from 'react';

import { Dimensions } from 'editor/src/store/design/types';

import useForceUpdate from 'editor/src/component/useForceUpdate';

import styles from './index.module.scss';

interface Props<T extends Dimensions> {
  elements: Array<T>;
  cols: number;
  render: (element: T, x: number, y: number, width: number, height: number) => React.ReactNode;
  spacing?: number;
  minHeight?: number;
  maxHeight?: number;
  extraBottomPadding?: number;
  className?: string;
}

function VerticalMasonry<T extends Dimensions>({
  render,
  elements,
  className,
  cols,
  spacing = 0,
  minHeight = 0,
  maxHeight = 0,
  extraBottomPadding = 0,
}: Props<T>) {
  const divRef = useRef<HTMLDivElement>(null);
  const forceUpdate = useForceUpdate();

  const positions = useMemo(() => {
    if (!divRef.current) {
      return undefined;
    }

    const currentHeights = new Array(cols).fill(0);
    const width = (divRef.current.clientWidth - (cols - 1) * spacing) / cols;

    const positions = elements.map((element) => {
      let lowIndex = 0;
      currentHeights.forEach((height, i) => {
        if (height < currentHeights[lowIndex]) {
          lowIndex = i;
        }
      });

      const elementRatio = !!element.width && !!element.height ? element.height / element.width : 1;
      const pos = currentHeights[lowIndex];
      const height = Math.min(Math.max(minHeight, width * elementRatio), maxHeight);
      currentHeights[lowIndex] += height + spacing;
      return {
        x: lowIndex * (width + spacing),
        y: pos,
        width,
        height,
      };
    });

    const lowestY = Math.max(...currentHeights);

    return { positions, lowestY };
  }, [elements, cols, spacing, minHeight, !!divRef.current]);

  useLayoutEffect(() => {
    forceUpdate();
  }, []);

  return (
    <div className={cn(styles.VerticalMasonry, className)} ref={divRef}>
      {positions?.positions.map((position, i) =>
        render(elements[i], position.x, position.y, position.width, position.height),
      )}
      {!!extraBottomPadding && !!positions && (
        <div className={styles.extraBottomPadding} style={{ top: positions.lowestY, height: extraBottomPadding }} />
      )}
    </div>
  );
}

export default React.memo(VerticalMasonry) as <T extends Dimensions>(props: Props<T>) => JSX.Element;

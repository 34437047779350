import cn from 'classnames';
import React, { useContext } from 'react';

import useEditorModeTranslations from 'editor/src/util/useEditorModeTranslations';

import Button from 'editor/src/component/Button';
import IconAddImage from 'editor/src/component/Icon/IconAddImage';
import { UploaderContext } from 'editor/src/component/Uploader/useUploader';

import styles from './index.module.scss';

function ButtonOpenUploader() {
  const uploader = useContext(UploaderContext);
  const { galleryFilesButton } = useEditorModeTranslations();

  const onClick = () => {
    uploader.open();
  };

  return (
    <Button
      secondary
      stopPropagation
      className={cn(styles.buttonOpenUploader, 'cy-button-choose-file')} // classname use by the Dashboard for tests
      onClick={onClick}
    >
      <IconAddImage className={styles.icon} />
      {galleryFilesButton}
    </Button>
  );
}

export default React.memo(ButtonOpenUploader);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { setDisabledSpreadsAction } from 'editor/src/store/editor/slice';
import { DisabledSpread } from 'editor/src/store/editor/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import setCurrentSpreadIndexOperation from './setCurrentSpreadIndexOperation';

const setDisabledSpreadsOperation =
  (disabledSpreads: DisabledSpread[]) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const currentSpreadIndex = getCurrentSpreadIndex(state);
    const isCurrentSpreadDisabled = disabledSpreads.some((spread) => spread.spreadIndex === currentSpreadIndex);
    batch(() => {
      dispatch(setDisabledSpreadsAction(disabledSpreads));
      if (!isCurrentSpreadDisabled) {
        return;
      }

      const disabledSpreadsBySpreadIdx = disabledSpreads.reduce<{
        [spreadId: number]: DisabledSpread;
      }>((result, disabledSpread) => {
        result[disabledSpread.spreadIndex] = disabledSpread;
        return result;
      }, {});
      const newSpreadIndex =
        state.design.designData?.spreads.findIndex((spread, idx) => !disabledSpreadsBySpreadIdx[idx]) || 0;
      dispatch(setCurrentSpreadIndexOperation(newSpreadIndex));
    });
  };

export default setDisabledSpreadsOperation;

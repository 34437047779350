/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function Icon3d({ className }: Props) {
  return (
    <Icon className={cn(className, styles.icon3d)}>
      <svg width="26" height="23" viewBox="0 0 26 23" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          stroke="none"
          d="M5.4749 18.277L2.42804 19.8004C1.93406 20.0474 1.33339 19.8472 1.0864 19.3532C0.839415 18.8592 1.03964 18.2586 1.53362 18.0116L4.64505 16.4559L4.55942 8.81641C4.5554 8.45836 4.74314 8.12548 5.05164 7.94368C5.14679 7.8876 5.24895 7.84854 5.35387 7.82652L11.9998 4.08819L11.9998 1.00002C11.9998 0.447739 12.4475 2.36965e-05 12.9998 2.37448e-05C13.5521 2.37931e-05 13.9998 0.447738 13.9998 1.00002L13.9998 4.08816L20.8361 7.93357L20.8536 7.94367C21.1396 8.11222 21.322 8.41073 21.3436 8.73911C21.3451 8.761 21.3458 8.78302 21.3458 8.80515L21.3458 8.80612L21.3458 16.4515L24.466 18.0116C24.96 18.2586 25.1602 18.8592 24.9133 19.3532C24.6663 19.8472 24.0656 20.0474 23.5716 19.8004L20.3759 18.2026L13.7337 21.9787C13.6685 22.0491 13.5926 22.1107 13.5076 22.1608C13.3369 22.2614 13.1436 22.3073 12.9521 22.2982C12.761 22.3071 12.5681 22.2612 12.3976 22.1608C12.317 22.1132 12.2445 22.0554 12.1816 21.9894L5.4749 18.277ZM11.9248 19.5613L6.64725 16.64L6.57881 10.5351L11.9915 13.6121L11.9248 19.5613ZM13.9998 13.5221L13.9998 19.5268L19.3458 16.4876L19.3458 10.515L13.9998 13.5221ZM12.9998 5.82037L18.2936 8.79813L12.9447 11.7589L7.69356 8.80514L12.9998 5.82037Z"
        />
      </svg>
    </Icon>
  );
}

export default Icon3d;

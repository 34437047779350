import React from 'react';

import { Coords, SafeArea, Page } from 'editor/src/store/design/types';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import useCanvasRotation from 'editor/src/component/EditorArea/useCanvasRotation';

import InfoIcon from './InfoIcon';

interface Props {
  safeArea: SafeArea;
  page: Page;
  spreadCoords: Coords;
  spreadHeight: number;
  bleedThickness: number;
  canvasRotation: CanvasRotation;
}

const STOKE_DASH_ARRAY = [3, 3];

function SafeAreaElement({ safeArea, page, spreadCoords, spreadHeight, bleedThickness, canvasRotation }: Props) {
  const { mm2px } = useFabricUtils();
  const contentX = spreadCoords.left + mm2px(page?.x ?? 0);
  const contentY = spreadCoords.top + mm2px(page?.y ?? 0);
  const rotatedPoint = useCanvasRotation(canvasRotation, contentX, contentY);

  return (
    <>
      <FabricRectComponent
        left={rotatedPoint.x + mm2px(safeArea.x) + mm2px(bleedThickness)}
        top={rotatedPoint.y + mm2px(safeArea.y) + mm2px(bleedThickness)}
        height={mm2px(safeArea.height)}
        width={mm2px(safeArea.width)}
        zIndex={zIndex.SAFE_AREA}
        fill="rgba(255, 255, 255, 0.6)"
        selectable={false}
        stroke="rgb(153, 153, 153)"
        strokeWidth={mm2px(0.5)}
        strokeDashArray={STOKE_DASH_ARRAY}
        objectCaching={false}
        angle={canvasRotation.angleDeg || 0}
      />
      <InfoIcon page={page} spreadCoords={spreadCoords} spreadHeight={spreadHeight} canvasRotation={canvasRotation} />
    </>
  );
}

export default React.memo(SafeAreaElement);

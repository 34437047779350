import isMobile from 'editor/src/store/app/selector/isMobile';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginState } from 'editor/src/store/plugins/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import setSidebarActiveTabByNameOperation from './setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from './setSidebarActiveTabOperation';

const activatePluginTabOperation =
  (pluginName: string, tabName: TAB_NAMES) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const plugin = getPlugin(state, pluginName);
    const premiumEnabled = plugin?.state === PluginState.NON_FUNCTIONAL || plugin?.state === PluginState.ENABLED;
    const isActive = isSidebarTabActive(state, tabName);

    if (!premiumEnabled) {
      sendPostMessage('plugins.disabledPluginClick', { name: pluginName });
      return;
    }

    batch(() => {
      if (isMobile(state)) {
        if (isActive) {
          dispatch(setSidebarActiveTabOperation(-1));
        } else {
          dispatch(setSidebarActiveTabByNameOperation(tabName));
        }
      } else {
        dispatch(setSidebarActiveTabByNameOperation(tabName));
      }

      sendPostMessage('plugins.pluginOpened', { pluginName });
    });
  };

export default activatePluginTabOperation;

/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

function IconElevateGold({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      className={cn(className, 'cy-icon-premium', 'cy-icon-elevate-gold')}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 2C10.8208 2 11.1083 2.19781 11.223 2.49737L13.3307 8.00237L17.1605 3.91037C17.3863 3.66918 17.7402 3.59779 18.0418 3.73263C18.3434 3.86746 18.5262 4.17881 18.497 4.50787L17.6469 14.0828C17.6114 14.4823 17.2767 14.7885 16.8757 14.7885H4.1243C3.72326 14.7885 3.38861 14.4823 3.35314 14.0828L2.50305 4.50787C2.47384 4.17881 2.65664 3.86746 2.95822 3.73263C3.2598 3.59779 3.61372 3.66918 3.83945 3.91037L7.66925 8.00237L9.77699 2.49737C9.89169 2.19781 10.1792 2 10.5 2ZM10.5 4.93937L8.67273 9.71184C8.57732 9.96103 8.36032 10.1435 8.09846 10.1948C7.83661 10.2461 7.56681 10.1589 7.38448 9.96405L4.24403 6.6086L4.8328 13.2401H16.1672L16.756 6.6086L13.6155 9.96405C13.4332 10.1589 13.1634 10.2461 12.9015 10.1948C12.6397 10.1435 12.4227 9.96103 12.3273 9.71184L10.5 4.93937ZM3.30298 17.2258C3.30298 16.7982 3.6496 16.4516 4.07717 16.4516H16.923C17.3506 16.4516 17.6972 16.7982 17.6972 17.2258C17.6972 17.6534 17.3506 18 16.923 18H4.07717C3.6496 18 3.30298 17.6534 3.30298 17.2258Z"
        fill="#CBA543"
      />
    </svg>
  );
}

export default React.memo(IconElevateGold);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconTextShadow({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 6.5C5 5.67157 5.67157 5 6.5 5H25.7857C26.6141 5 27.2857 5.67157 27.2857 6.5V10.7858H33.5C34.3284 10.7858 35 11.4573 35 12.2858V20C35 20.8285 34.3284 21.5 33.5 21.5H29.2143V33.5C29.2143 34.3284 28.5427 35 27.7143 35H20C19.1716 35 18.5 34.3284 18.5 33.5V29.2142H12.2859C11.4575 29.2142 10.7859 28.5427 10.7859 27.7142V15.7143H6.5C5.67157 15.7143 5 15.0427 5 14.2143V6.5ZM19.8609 12.7207C19.0977 12.7908 18.5 13.4328 18.5 14.2143V26.2142H13.7859V14.2143C13.7859 13.3859 13.1144 12.7143 12.2859 12.7143H8V8H24.2857V12.7143H20.0002C19.9533 12.7143 19.9068 12.7164 19.8609 12.7207Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconTextShadow;

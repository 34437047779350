import cn from 'classnames';
import React, { Suspense, useLayoutEffect } from 'react';

import isVariantsSidebarVisible from 'editor/src/store/app/selector/isVariantsSidebarVisible';
import { useSelector } from 'editor/src/store/hooks';

import retryPromiseFn from 'editor/src/util/retryPromiseFn';

import styles from './index.module.scss';

const ProductVariantList = React.lazy(retryPromiseFn(() => import('editor/src/component/ProductVariantList')));

function RightSidebar() {
  const showVariantList = useSelector(
    (state) => !state.variants.configuration.singleSelection && isVariantsSidebarVisible(state),
  );

  useLayoutEffect(() => {
    window.dispatchEvent(new Event('resizeCanvas'));
  }, [showVariantList]);

  if (showVariantList) {
    return (
      <div className={cn(styles.rightBar, 'cy-right-bar')}>
        <Suspense fallback="">
          <ProductVariantList />
        </Suspense>
      </div>
    );
  }

  return null;
}

export default React.memo(RightSidebar);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { StructureIndex } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import applyToMultipleMediaOperation from './applyToMultipleMediaOperation';
import fillMediaToSpreadOperation from './distribute/fillMediaToSpreadOperation';

const applyImageFillOperation = (structureIndexes: StructureIndex[]) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(saveUndoRedoStateOperation('fill element'));
    dispatch(applyToMultipleMediaOperation(structureIndexes, fillMediaToSpreadOperation));
  });
};

export default applyImageFillOperation;

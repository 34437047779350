/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  active?: boolean;
}

function IconProducts({ className, active }: Props) {
  return (
    <Icon className={cn(className, styles.iconProduct, { active })}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        {!active ? (
          <path
            d="M14.25 21.4C13.8667 21.7833 13.3917 21.975 12.825 21.975C12.2583 21.975 11.7833 21.7833 11.4 21.4L2.6 12.6C2.41667 12.4167 2.271 12.2 2.163 11.95C2.05433 11.7 2 11.4333 2 11.15V4C2 3.45 2.196 2.979 2.588 2.587C2.97933 2.19567 3.45 2 4 2H11.15C11.4333 2 11.7 2.054 11.95 2.162C12.2 2.27067 12.4167 2.41667 12.6 2.6L21.4 11.425C21.7833 11.8083 21.975 12.279 21.975 12.837C21.975 13.3957 21.7833 13.8667 21.4 14.25L14.25 21.4ZM12.825 20L19.975 12.85L11.15 4H4V11.15L12.825 20ZM6.5 8C6.91667 8 7.27067 7.854 7.562 7.562C7.854 7.27067 8 6.91667 8 6.5C8 6.08333 7.854 5.72933 7.562 5.438C7.27067 5.146 6.91667 5 6.5 5C6.08333 5 5.72933 5.146 5.438 5.438C5.146 5.72933 5 6.08333 5 6.5C5 6.91667 5.146 7.27067 5.438 7.562C5.72933 7.854 6.08333 8 6.5 8Z"
            fill="#212121"
          />
        ) : (
          <path
            d="M14.75 21.4C14.3667 21.7833 13.8917 21.975 13.325 21.975C12.7583 21.975 12.2833 21.7833 11.9 21.4L3.1 12.6C2.91667 12.4167 2.771 12.2 2.663 11.95C2.55433 11.7 2.5 11.4333 2.5 11.15V4C2.5 3.45 2.696 2.979 3.088 2.587C3.47933 2.19567 3.95 2 4.5 2H11.65C11.9333 2 12.2 2.054 12.45 2.162C12.7 2.27067 12.9167 2.41667 13.1 2.6L21.9 11.425C22.2833 11.8083 22.475 12.279 22.475 12.837C22.475 13.3957 22.2833 13.8667 21.9 14.25L14.75 21.4ZM7 8C7.41667 8 7.77067 7.854 8.062 7.562C8.354 7.27067 8.5 6.91667 8.5 6.5C8.5 6.08333 8.354 5.72933 8.062 5.438C7.77067 5.146 7.41667 5 7 5C6.58333 5 6.22933 5.146 5.938 5.438C5.646 5.72933 5.5 6.08333 5.5 6.5C5.5 6.91667 5.646 7.27067 5.938 7.562C6.22933 7.854 6.58333 8 7 8Z"
            fill="#212121"
          />
        )}
      </svg>
    </Icon>
  );
}

export default IconProducts;

import React, { useCallback, useState } from 'react';

import { useSelector } from 'editor/src/store/hooks';
import { MockupPreviewProduct, MockupPreviewStore } from 'editor/src/store/mockup/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import BackHeader from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/BackHeader';
import MockupList from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/MockupList';
import StoreProductVariantList from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/StoreProductVariantList';

import StoreProductEntry from './StoreProductEntry';

interface Props {
  store: MockupPreviewStore;
  listClassName?: string;
  backClassName?: string;
  back: () => void;
}

function StoreProductList({ store, back, listClassName, backClassName }: Props) {
  const products = useSelector((state) => state.mockup.preview.products[store.id]);
  const [selectedProduct, setSelectedProduct] = useState<MockupPreviewProduct | undefined>();

  const loadStoreProducts = useCallback(
    (offset: number) => {
      sendPostMessage('mockup.getStoreProducts', {
        storeId: store.id,
        limit: 15,
        offset,
      });
    },
    [store.id],
  );

  const unSelectProduct = useCallback(() => setSelectedProduct(undefined), []);
  const selectProduct = useCallback((product: MockupPreviewProduct) => {
    setSelectedProduct(product);
  }, []);

  return selectedProduct ? (
    <StoreProductVariantList
      storeId={store.id}
      product={selectedProduct}
      back={unSelectProduct}
      listClassName={listClassName}
      backClassName={backClassName}
    />
  ) : (
    <>
      <BackHeader className={backClassName} title={store.name} onClick={back} />
      <MockupList
        className={listClassName}
        entries={products.entries}
        hasMore={products.hasMore}
        EntryElt={StoreProductEntry}
        loadEntries={loadStoreProducts}
        onSelect={selectProduct}
      />
    </>
  );
}

export default React.memo(StoreProductList);

import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import { MediaLine } from 'editor/src/store/design/types';
import defaultLineStyles, { LineStyles } from 'editor/src/store/design/util/defaultLineStyles';
import { RootState } from 'editor/src/store/index';

const getSelectedLineStylesProperty = <T extends keyof LineStyles>(state: RootState, key: T): LineStyles[T] => {
  const selectedElements = getSelectedMediaElementsObjects(state);

  const lineElement = selectedElements.find((elt) => elt?.type === 'line') as MediaLine | undefined;

  if (lineElement) {
    return lineElement[key];
  }
  return defaultLineStyles[key];
};

export default getSelectedLineStylesProperty;

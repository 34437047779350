import React, { useEffect } from 'react';

function useFocusElementOnFlag(flag: boolean, eltRef: React.RefObject<HTMLElement>) {
  useEffect(() => {
    if (!flag) {
      return undefined;
    }
    const handle = window.setTimeout(() => eltRef.current?.scrollIntoView({ behavior: 'smooth' }), 100);
    return () => window.clearTimeout(handle);
  }, [flag, !!eltRef.current]);
}

export default useFocusElementOnFlag;

import React, { useCallback } from 'react';
import Select, { SingleValue } from 'react-select';

import styles from './index.module.scss';

export type Option<T> = { value: T; label: string };

interface Props<T> {
  title: string;
  options: Option<T>[];
  selectedOption: Option<T> | undefined;
  onChange: (value: T) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
function SelectControl<T extends any>({ title, options, selectedOption, onChange }: Props<T>) {
  const onSelect = useCallback(
    (option: SingleValue<Option<T>>) => {
      if (option) {
        onChange(option.value);
      }
    },
    [onChange],
  );

  return (
    <div className={styles.SelectControl}>
      {title && <div className={styles.label}>{title}</div>}
      <Select
        options={options}
        onChange={onSelect}
        className={styles.select}
        value={selectedOption}
        isSearchable={false}
      />
    </div>
  );
}

export default React.memo(SelectControl) as <T>(props: Props<T>) => JSX.Element;

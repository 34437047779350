import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import isMultipleImageSelectAllowed from 'editor/src/store/editor/selector/isMultipleImageSelectAllowed';
import selectAllImagesOperation from 'editor/src/store/gallery/operation/selectAllImagesOperation';
import unselectAllImagesOperation from 'editor/src/store/gallery/operation/unselectAllImagesOperation';
import getGalleryImagesCount from 'editor/src/store/gallery/selector/getGalleryImagesCount';
import getIsAllImagesSelected from 'editor/src/store/gallery/selector/getIsAllImagesSelected';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';

import styles from './index.module.scss';

function ButtonToggleSelectAll() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMultiSelectEnabled, galleryImagesCount, allImagesSelected, canvasSelectedImageUuid } = useSelector(
    (state) => ({
      isMultiSelectEnabled: isMultipleImageSelectAllowed(state),
      galleryImagesCount: getGalleryImagesCount(state),
      allImagesSelected: getIsAllImagesSelected(state),
      canvasSelectedImageUuid: getSelectedImageUuid(state),
    }),
    shallowEqual,
  );

  if (!isMultiSelectEnabled || canvasSelectedImageUuid !== undefined || galleryImagesCount === 0) {
    return null;
  }

  const buttonText = allImagesSelected ? t('Unselect all') : t('Select all');

  const onClick = () => {
    if (allImagesSelected) {
      dispatch(unselectAllImagesOperation());
    } else {
      dispatch(selectAllImagesOperation());
    }
  };

  return (
    <Button tertiary className="cy-toggle-select-all-gallery-images-button" stopPropagation onClick={onClick}>
      <span className={styles.buttonText}>{t(buttonText)}</span>
    </Button>
  );
}

export default React.memo(ButtonToggleSelectAll);

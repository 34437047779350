import cn from 'classnames';
import React, { useCallback } from 'react';

import applyRectangleStylesToSelectionOperation from 'editor/src/store/design/operation/shapes/applyRectangleStylesToSelectionOperation';
import getSelectedElementProperty from 'editor/src/store/design/selector/getSelectedElementProperty';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import ColorPicker from 'editor/src/component/ColorPicker';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from 'editor/src/component/DesktopSidebar/TabContents/colorTabContent.module.scss';

function RectangleStrokeColorTabContent() {
  const currentColor = useSelector((state) => getSelectedElementProperty(state, 'stroke', 'rectangle'));
  const colorPickerConfig = useSelector((state) => getHostSetting(state, 'colorPickerConfig'));

  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const clickHandler = useCallback((stroke: string | undefined) => {
    dispatch(applyRectangleStylesToSelectionOperation({ stroke }));
  }, []);

  return (
    <ContentBlock scroll>
      <div
        className={cn('cy-rectangle-stroke-color-tab-content', styles.wrapper, {
          [styles.mobile]: isMobile,
        })}
      >
        <ColorPicker
          color={currentColor}
          onColorChange={clickHandler}
          allowOpacity={colorPickerConfig.enableOpacity}
          colors={colorPickerConfig.allowedColors}
          allowNoColor
        />
      </div>
    </ContentBlock>
  );
}

export default React.memo(RectangleStrokeColorTabContent);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconAlignRight({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M21 22C20.7167 22 20.4793 21.904 20.288 21.712C20.096 21.5207 20 21.2833 20 21V3C20 2.71667 20.096 2.479 20.288 2.287C20.4793 2.09567 20.7167 2 21 2C21.2833 2 21.5207 2.09567 21.712 2.287C21.904 2.479 22 2.71667 22 3V21C22 21.2833 21.904 21.5207 21.712 21.712C21.5207 21.904 21.2833 22 21 22ZM3.5 10C3.08333 10 2.72933 9.854 2.438 9.562C2.146 9.27067 2 8.91667 2 8.5C2 8.08333 2.146 7.72933 2.438 7.438C2.72933 7.146 3.08333 7 3.5 7H16.5C16.9167 7 17.2707 7.146 17.562 7.438C17.854 7.72933 18 8.08333 18 8.5C18 8.91667 17.854 9.27067 17.562 9.562C17.2707 9.854 16.9167 10 16.5 10H3.5ZM9.5 17C9.08333 17 8.72933 16.854 8.438 16.562C8.146 16.2707 8 15.9167 8 15.5C8 15.0833 8.146 14.7293 8.438 14.438C8.72933 14.146 9.08333 14 9.5 14H16.5C16.9167 14 17.2707 14.146 17.562 14.438C17.854 14.7293 18 15.0833 18 15.5C18 15.9167 17.854 16.2707 17.562 16.562C17.2707 16.854 16.9167 17 16.5 17H9.5Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconAlignRight;

import React from 'react';
import { useTranslation } from 'react-i18next';

import DrawerHeader from 'editor/src/component/BottomBarMobile/DrawerHeader';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconVariants from 'editor/src/component/Icon/IconVariants';
import ProductVariantList from 'editor/src/component/ProductVariantList';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

function VariantsTabContent({ title, icon, closeMenuCb }: TabContentProps) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <div data-ignore-outside-click className={styles.wrapper}>
      {isMobile ? (
        <DrawerHeader title={title || t('editor-variants')} closeMenu={closeMenuCb} />
      ) : (
        <TabContentHeader title={title || t('editor-variants')} icon={icon || <IconVariants />} />
      )}
      <ContentBlock fill>
        <ProductVariantList />
      </ContentBlock>
    </div>
  );
}

export default React.memo(VariantsTabContent);

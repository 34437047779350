import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import getSpread from 'editor/src/store/design/selector/getSpread';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress, MediaImage } from 'editor/src/store/design/types';
import getContentAreaFromSpread from 'editor/src/store/design/util/getContentAreaFromSpread';
import type { Thunk } from 'editor/src/store/hooks';

import applyFillImageToSpread from 'editor/src/util/design/applyFillImageToSpread';

const fillMediaToSpreadOperation =
  (elementAddress: ElementAddress): Thunk =>
  (dispatch, getState) => {
    const state = getState();

    const spread = getSpread(state, elementAddress.spreadIndex);
    const area = getContentAreaFromSpread(spread);
    if (!area) {
      return;
    }

    const element = getMediaElement<MediaImage>(state, elementAddress);
    if (element) {
      const elementUpdate = applyFillImageToSpread(element, area);
      dispatch(updateMediaElementAction({ elementAddress, elementUpdate }));
    }
  };

export default fillMediaToSpreadOperation;

export type PointList = [number, number][];

// from https://stackoverflow.com/questions/9043805/test-if-two-lines-intersect-javascript-function
/**
 * @returns whether 2 segments intersect
 */
function doSegmentsIntersect(
  l1x1: number,
  l1y1: number,
  l1x2: number,
  l1y2: number,
  l2x1: number,
  l2y1: number,
  l2x2: number,
  l2y2: number,
): boolean {
  const det = (l1x2 - l1x1) * (l2y2 - l2y1) - (l2x2 - l2x1) * (l1y2 - l1y1);
  if (det === 0) {
    return false;
  }
  const lambda = ((l2y2 - l2y1) * (l2x2 - l1x1) + (l2x1 - l2x2) * (l2y2 - l1y1)) / det;
  const gamma = ((l1y1 - l1y2) * (l2x2 - l1x1) + (l1x2 - l1x1) * (l2y2 - l1y1)) / det;
  return lambda > 0 && lambda < 1 && gamma > 0 && gamma < 1;
}

export default doSegmentsIntersect;

import { isNotDefaultTab, isPluginTab, TabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { RootState } from 'editor/src/store/index';

const isSidebarTabEnabled = (state: RootState, tab: TabInfo) => {
  if (isNotDefaultTab(tab) || isPluginTab(tab)) {
    return !!state.design.designData;
  }

  switch (tab) {
    case TAB_NAMES.PRODUCT:
      return true;
    default:
      return !!state.design.designData;
  }
};

export default isSidebarTabEnabled;

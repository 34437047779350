import React from 'react';
import { useTranslation } from 'react-i18next';

import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';

import IconPersonsalisation from '../../../../Icon/IconPersonsalisation';

interface Props {
  isActive: boolean;
}

function PersonalizeLayersTab({ isActive }: Props) {
  const { t } = useTranslation();

  return (
    <Tab
      key={TAB_NAMES.PERSONALIZE_LAYERS}
      name={TAB_NAMES.PERSONALIZE_LAYERS}
      title={t('Personalize')}
      icon={<IconPersonsalisation active={isActive} />}
    />
  );
}
export default React.memo(PersonalizeLayersTab);

import React from 'react';

import { MockupProductEntry } from 'editor/src/store/mockup/types';

import { EntryProps } from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/MockupList';
import MockupListEntry from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/MockupListEntry';

function ProductCategoryEntry({ entry, onSelect }: EntryProps<MockupProductEntry>) {
  return <MockupListEntry imageUrl={entry.previewUrl} title={entry.name} onClick={() => onSelect(entry)} />;
}

export default React.memo(ProductCategoryEntry);

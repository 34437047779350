import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import applyShadowToSelectionOperation from 'editor/src/store/design/operation/applyShadowToSelectionOperation';
import { MediaImage, MediaText, DropShadow, LeaningShadow } from 'editor/src/store/design/types';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch } from 'editor/src/store/hooks';

import limitPrecision from 'editor/src/util/limitPrecision';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import ColorTileControl from 'editor/src/component/ColorPicker/ColorTileControl';
import SliderWithInput from 'editor/src/component/SliderWithInput';

import {
  calculateOffsetAndDirectionFromOffsets,
  calculateOffsetsFromDirectionAndOffset,
  getRangeMultiplier,
  SHADOW_DIRECTION_DEFAULT,
  SHADOW_OFFSET_DEFAULT,
} from './utils';

interface Props {
  selectedElement: MediaText | MediaImage;
  shadow: DropShadow | LeaningShadow;
}

function ShadowControls({ selectedElement, shadow }: Props) {
  const [offset, setOffset] = useState(SHADOW_OFFSET_DEFAULT);
  const [direction, setDirection] = useState(SHADOW_DIRECTION_DEFAULT);
  const [blur, setBlur] = useState(0);
  const [scale, setScale] = useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (shadow.type === 'drop-shadow') {
      const { offset: offsetValue, direction: directionValue } = calculateOffsetAndDirectionFromOffsets(
        shadow.offsetX,
        shadow.offsetY,
        getRangeMultiplier(selectedElement.type, 'offset'),
      );
      setOffset(offsetValue);
      setDirection(directionValue);
      setScale(Math.round(!shadow.scale ? 0 : shadow.scale * 100 - 100));
    }
    setBlur(Math.round(shadow.blur / getRangeMultiplier(selectedElement.type, 'blur')));
  }, [selectedElement.type, selectedElement.uuid]);

  const { liveUpdate } = useMediaElementLiveUpdates(selectedElement);

  const activateColorPickerTab = useCallback(() => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.SHADOW_COLOR_PICKER));
  }, []);

  const calculateCoordinates = useCallback(
    (offset: number, direction: number): { offsetX: number; offsetY: number; blur: number } => ({
      ...calculateOffsetsFromDirectionAndOffset(direction, offset, getRangeMultiplier(selectedElement.type, 'offset')),
      blur: getFabricBlur(blur),
    }),
    [blur, selectedElement.type],
  );

  const applyLiveUpdate = useCallback(
    (changedShadow: Partial<DropShadow>) => {
      const newShadow = { ...shadow, ...changedShadow };
      if (selectedElement.type === 'text') {
        liveUpdate({
          ...selectedElement,
          extra: { ...selectedElement.extra, shadow: newShadow as DropShadow },
        });
      } else {
        liveUpdate({ ...selectedElement, shadow: newShadow as DropShadow });
      }
    },
    [selectedElement, liveUpdate],
  );

  const updateShadowInStore = useCallback(
    (offsetValue: number, directionValue: number) => {
      const shadowValue = {
        ...shadow,
        ...calculateCoordinates(offsetValue, directionValue),
      };
      dispatch(applyShadowToSelectionOperation(shadowValue));
    },
    [shadow, calculateCoordinates],
  );

  const onOffsetChange = useCallback(
    (value: number) => applyLiveUpdate(calculateCoordinates(value, direction)),
    [direction, applyLiveUpdate, calculateCoordinates],
  );

  const onDirectionChange = useCallback(
    (value: number) => applyLiveUpdate(calculateCoordinates(offset, value)),
    [offset, applyLiveUpdate, calculateCoordinates],
  );

  const onBlurChange = useCallback((blur: number) => applyLiveUpdate({ blur: getFabricBlur(blur) }), [applyLiveUpdate]);
  const onScaleChange = useCallback(
    (scale: number) => applyLiveUpdate({ scale: getFabricScale(scale) }),
    [applyLiveUpdate],
  );

  const onOffsetChanged = useCallback(
    (value: number) => {
      setOffset(value);
      if (value !== offset) {
        updateShadowInStore(value, direction);
      }
    },
    [offset, updateShadowInStore, direction],
  );

  const onDirectionChanged = useCallback(
    (value: number) => {
      if (value === direction) {
        return;
      }

      setDirection(value);
      updateShadowInStore(offset, value);
    },
    [offset, direction, updateShadowInStore],
  );

  const onBlurChanged = useCallback(
    (blurValue: number) => {
      if (blurValue === blur) {
        return;
      }

      setBlur(blurValue);
      dispatch(
        applyShadowToSelectionOperation({
          ...shadow,
          blur: getFabricBlur(blurValue),
        }),
      );
    },
    [blur, shadow],
  );

  const onScaleChanged = useCallback(
    (scaleValue: number) => {
      if (scaleValue === scale) {
        return;
      }
      setScale(scaleValue);

      dispatch(
        applyShadowToSelectionOperation({
          ...(shadow as DropShadow),
          scale: getFabricScale(scaleValue),
        }),
      );
    },
    [scale, shadow],
  );

  const getFabricBlur = (blurValue: number) =>
    limitPrecision(blurValue * getRangeMultiplier(selectedElement.type, 'blur'));
  const getFabricScale = (scaleValue: number) => scaleValue / 100 + 1;

  return (
    <>
      {shadow.type === 'drop-shadow' && (
        <>
          <div className="mb-1">
            <SliderWithInput
              inputClassName="cy-shadow-offset-input"
              Label={t('offset')}
              onChange={onOffsetChange}
              onAfterChange={onOffsetChanged}
              startPoint={0}
              min={0}
              max={Math.max(selectedElement.width, selectedElement.height) / 2}
              step={0.1}
              value={offset}
              position="twoRows"
            />
          </div>
          <div className="mb-1">
            <SliderWithInput
              inputClassName="cy-shadow-direction-input"
              Label={t('Direction')}
              onChange={onDirectionChange}
              onAfterChange={onDirectionChanged}
              startPoint={0}
              min={-180}
              max={180}
              step={1}
              value={direction}
              position="twoRows"
            />
          </div>
        </>
      )}
      <SliderWithInput
        inputClassName="cy-shadow-blur-input"
        Label={t('Blur')}
        onChange={onBlurChange}
        onAfterChange={onBlurChanged}
        startPoint={0}
        min={0}
        max={100}
        step={0.1}
        value={blur}
        position="twoRows"
      />
      {selectedElement.type === 'image' && (
        <div className="mb-1">
          <SliderWithInput
            inputClassName="cy-shadow-scale-input"
            // TODO add translation
            Label={t('Spread')}
            onChange={onScaleChange}
            onAfterChange={onScaleChanged}
            startPoint={0}
            min={-50}
            max={100}
            step={0.1}
            value={scale}
            position="twoRows"
          />
        </div>
      )}
      <div className="row-flex row-flex--between mt-2 row-flex--middle">
        <span>{t('editor-display-option-color')}</span>
        <ColorTileControl className="m-0" onClick={activateColorPickerTab} isActive color={shadow.color} />
      </div>
    </>
  );
}

export default React.memo(ShadowControls);

import React, { useEffect, useRef, useState } from 'react';

export const ATTR_NAMNE = 'data-index';

function useCategoriesObserver(wrapperRef: React.RefObject<HTMLDivElement>, categoriesCount: number) {
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const [visibleCategories, setCategoriesVisible] = useState<Set<number>>(new Set());
  useEffect(() => {
    if (!wrapperRef.current) {
      return undefined;
    }

    let visible = new Set<number>();

    if (!('IntersectionObserver' in window)) {
      for (let i = 0; i < categoriesCount; i += 1) {
        visible.add(i);
      }
      setCategoriesVisible(visible);
      return undefined;
    }

    function onIntersect(entries: IntersectionObserverEntry[]) {
      visible = new Set([...visible]);
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = parseInt(entry.target.getAttribute(ATTR_NAMNE) || '', 10);
          if (!Number.isNaN(index)) {
            visible.add(index);
          }
        }
      });
      setCategoriesVisible(visible);
    }

    const observer = new IntersectionObserver(onIntersect, {
      root: wrapperRef.current.parentElement,
      rootMargin: '0px',
      threshold: 0,
    });
    setObserver(observer);
    return () => {
      observer.disconnect();
    };
  }, [wrapperRef.current, categoriesCount]);

  return { observer, visibleCategories };
}

export function useObserverCategory(observer: IntersectionObserver | null, index: number) {
  const targetDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const target = targetDivRef.current;
    if (!observer || !target) {
      return undefined;
    }

    observer.observe(target);
    target.setAttribute(ATTR_NAMNE, `${index}`);
    return () => observer.unobserve(target);
  }, [observer]);

  return targetDivRef;
}

export default useCategoriesObserver;

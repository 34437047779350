import getDesignData from 'editor/src/store/design/selector/getDesignData';
import getGalleryImages from 'editor/src/store/gallery/selector/getGalleryImages';
import { RootState } from 'editor/src/store/index';

import findNotUsedImages from 'editor/src/util/autofill/findNotUsedImages';

const isAllImagesUsed = (state: RootState) => {
  const galleryImages = getGalleryImages(state);
  const designData = getDesignData(state);
  if (!designData) {
    return true;
  }
  return findNotUsedImages(galleryImages, designData).length === 0;
};

export default isAllImagesUsed;

import { generateFileID, getFileType } from '@gelatoas/file-uploader';

function generateUppyIdFromFile(file: File): string {
  const fileDescriptor: any = {
    name: file.name,
    type: file.type,
    size: file.size,
    data: file,
  };

  return generateFileID({
    ...fileDescriptor,
    type: getFileType(fileDescriptor),
  });
}

export default generateUppyIdFromFile;

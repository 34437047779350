import { CanShow } from 'editor/src/component/Menu/type';

import ButtonLineStyles from './ButtonLineStyles';
import ButtonLineStylesDropDown from './ButtonLineStylesDropDown';

export const canShow: CanShow = (state, { isPersonalizationLocked, hasSelection }) =>
  hasSelection && !isPersonalizationLocked;

export { ButtonLineStyles, ButtonLineStylesDropDown };

export default ButtonLineStyles;

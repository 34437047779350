import { useEffect, useState } from 'react';

import loadFontOperation from 'editor/src/store/fonts/operation/loadFontOperation';
import isFontLoaded from 'editor/src/store/fonts/selector/isFontLoaded';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';

function useFontLoader(fontFamily: string) {
  const fabricCanvas = useFabricCanvas();
  const dispatch = useDispatch();
  const fontLoaded = useSelector((state) => isFontLoaded(state, fontFamily));
  const [loadedFontFamily, setLoadedFontFamily] = useState(fontFamily);

  useEffect(() => {
    if (!fontLoaded) {
      void dispatch(loadFontOperation(fontFamily));
    } else {
      setLoadedFontFamily(fontFamily);
      fabricCanvas.requestRenderAll();
    }
  }, [fontLoaded, fontFamily]);

  return { loadedFontFamily, isFontLoaded: fontLoaded };
}

export default useFontLoader;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress, MediaImage } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import calculateCurrentCropZoomScale from 'editor/src/util/design/crop/calculateCurrentCropZoomScale';
import getCropZoomScale from 'editor/src/util/design/crop/getCropZoomScale';

const imageCropZoomScaleOperation =
  (elementAddress: ElementAddress, newZoomScale: number) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const image = getMediaElement<MediaImage>(state, elementAddress);
    if (!image) {
      return;
    }

    const currentZoom = calculateCurrentCropZoomScale(image);
    if (Math.abs(newZoomScale - currentZoom) < 0.01) {
      return;
    }

    batch(() => {
      dispatch(saveUndoRedoStateOperation('Zoom image'));
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: getCropZoomScale(image, newZoomScale),
        }),
      );
    });
  };

export default imageCropZoomScaleOperation;

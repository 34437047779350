import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  title?: string;
  content: string;
}

function HTMLDetails({ title, content }: Props) {
  return (
    <div className={cn(styles.HTMLDetails, 'cy-details')}>
      {title && <div className={styles.title}>{title}</div>}
      <div
        className={styles.content}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}

export default React.memo(HTMLDetails);

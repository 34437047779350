import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { Dimensions, MediaAddon, MediaImage } from 'editor/src/store/design/types';
import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import getDefaultPersonalizationLockState from 'editor/src/store/editor/selector/getDefaultPersonalizationLockState';
import getAddonById from 'editor/src/store/editorModules/addons/selector/getAddonById';
import getAddonByIdFromCurrent from 'editor/src/store/editorModules/addons/selector/getAddonByIdFromCurrent';
import { addAddonInUseAction } from 'editor/src/store/editorModules/addons/slice';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import type { AsyncThunk } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import loadImageDimensions from 'editor/src/util/loadImageDimensions';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import toastController from 'editor/src/util/toastController';
import { elementUuids } from 'editor/src/util/uuids';

import { canShow as canChangeThreadColors } from 'editor/src/component/TopMenuDesktop/buttons/ButtonThreadColor';

import shouldDigitizeSpreadIndex from '../../utils/shouldDigitizeSpreadIndex';

import addMediaElementOperation from './addMediaElementOperation';
import addNewImageToPageOperation from './addNewImageToPageOperation';
import getNewAddonDisplaySize from './getNewAddonDisplaySize';
import getNewElementDisplay from './getNewElementDisplay';
import uploadAddonOperation from './uploadAddonOperation';

const addNewAddonToPageOperation =
  (spreadIndex: number, pageIndex: number, addonId: string, x?: number, y?: number): AsyncThunk =>
  async (dispatch, getState, { i18n }) => {
    let state = getState();
    let addon = getAddonByIdFromCurrent(state, addonId);
    const addToInUse = !!addon;
    if (!addon) {
      addon = getAddonById(state, addonId);
    }

    if (!addon || !addon.preview) {
      return;
    }

    const graphicPlugin = getPlugin(state, PluginName.Graphics);
    if (graphicPlugin && graphicPlugin.state !== PluginState.ENABLED) {
      return;
    }

    const useImage = !!graphicPlugin;
    const galleryImage = state.gallery.images.find(
      (image) => image.from?.type === PluginName.Graphics && image.from.addonId === addon?.id,
    );
    if (galleryImage) {
      dispatch(addNewImageToPageOperation(spreadIndex, pageIndex, galleryImage.id, x, y));
      return;
    }

    let imageDimensions: Dimensions;
    try {
      imageDimensions = await loadImageDimensions(addon.preview, 'anonymous', {
        executor: 'addNewAddonToPageOperation',
        addon,
      });
    } catch {
      toastController.warningPersist(
        i18n.t('editor-image-loading-failed'),
        i18n.t('editor-image-loading-failed-message'),
      );
      return;
    }

    const display = getNewElementDisplay(
      state,
      spreadIndex,
      (area) => getNewAddonDisplaySize(area, imageDimensions.width, imageDimensions.height),
      x,
      y,
    );
    if (!display) {
      return;
    }

    const { center, rotation, width, height } = display;

    let imageOrAddon: MediaImage | MediaAddon;

    const uuid = elementUuids.generate();

    if (useImage) {
      const defaultPersonalizationLockState = getDefaultPersonalizationLockState(state);
      imageOrAddon = {
        type: 'image',
        group: '',
        imageId: addonId,
        plugins: {
          [PluginName.Graphics]: { addonId },
        },
        url: addon.preview,
        name: addon.name || `${i18n.t('editor-graphic')} ${uuid}`,
        x: center.x,
        y: center.y,
        width,
        height,
        r: rotation,
        px: 0,
        py: 0,
        pw: width,
        ph: height,
        pr: 0,
        uuid,
        personalizationLocked: defaultPersonalizationLockState || undefined,
      };
    } else {
      imageOrAddon = {
        addonId,
        type: 'addon',
        group: '',
        url: addon.preview,
        name: addon.name || `${i18n.t('editor-addon')} ${uuid}`,
        x: center.x,
        y: center.y,
        width,
        height,
        r: rotation,
        px: 0,
        py: 0,
        pw: width,
        ph: height,
        pr: 0,
        uuid,
        personalizationLocked: true,
      };
    }

    batch(() => {
      if (addToInUse && addon) {
        dispatch(addAddonInUseAction(addon));
      }
      dispatch(addMediaElementOperation(spreadIndex, pageIndex, imageOrAddon, true));
      if (useImage) {
        sendPostMessage('log.imageAdded', imageOrAddon as MediaImage);
      } else {
        sendPostMessage('log.addonAdded', imageOrAddon as MediaAddon);
      }

      if (useImage && addon) {
        // only upload if we don't have that addon uploaded yet
        dispatch(uploadAddonOperation(imageOrAddon as MediaImage, imageDimensions, addon, false));
      }

      if (shouldDigitizeSpreadIndex(state, spreadIndex)) {
        dispatch(addSelectedMediaElementOperation(uuid));
        state = getState();
        if (canChangeThreadColors(state)) {
          dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.THREAD_COLOR));
        }
      }
    });
  };

export default addNewAddonToPageOperation;

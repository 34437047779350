import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import {
  SpreadGroundImageWODimensions,
  loadSpreadImages,
} from 'editor/src/store/design/operation/setSpreadBackgroundsOperation';
import { ProductImage } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { addVariantAction } from 'editor/src/store/mockup/slice';
import { MockupVariant } from 'editor/src/store/mockup/types';

import updatePlaceholderOperation from './updatePlaceholderOperation';

export interface ExtendedMockupVariant extends MockupVariant {
  backgrounds: SpreadGroundImageWODimensions[];
  foregrounds: SpreadGroundImageWODimensions[];
  productImage?: ProductImage;
}

const saveVariantOperation =
  (variantData: ExtendedMockupVariant) => async (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const selectedScene =
      state.design.designData?.spreads[0].pages[0].groups.media?.find(isMediaMockupPlaceholder)?.variant.scene;
    const { foregrounds: foregroundsWODim, backgrounds: backgroundsWODim, productImage, ...variant } = variantData;
    const [foregrounds, backgrounds] = await Promise.all([
      loadSpreadImages(foregroundsWODim),
      loadSpreadImages(backgroundsWODim),
    ]);
    variant.scene = selectedScene;
    batch(() => {
      dispatch(
        addVariantAction({
          variant,
          foregrounds,
          backgrounds,
          productImage,
        }),
      );
      dispatch(updatePlaceholderOperation());
    });
  };

export default saveVariantOperation;

import { DeviceType } from 'editor/src/store/app/types';
import { updateSidebarTabsAction } from 'editor/src/store/editorModules/sidebar/slice';
import { SystemTabNames, TabInfo } from 'editor/src/store/editorModules/sidebar/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getCustomerDeviceType from 'editor/src/util/getCustomerDeviceType';

const updateSidebarTabsOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const { baseTabs, pluginTabs, requestedBaseTabs, dynamicTabs } = state.editorModules.sidebar;
  const newTabs: TabInfo[] = [...baseTabs];

  dynamicTabs.forEach((dynamicTab) => {
    if (dynamicTab.position === undefined) {
      newTabs.push(dynamicTab.tab);
      return;
    }

    newTabs.splice(dynamicTab.position, 0, dynamicTab.tab);
  });

  // add plugin tab to specific index in the tab list if specified
  pluginTabs.forEach((pluginTab) => {
    const plugin = state.plugins.list.find((plugin) => plugin.name === pluginTab.plugin);
    if (plugin?.indexInTabs !== undefined) {
      newTabs.splice(plugin.indexInTabs, 0, pluginTab);
      return;
    }

    const baseTabPosition = requestedBaseTabs.findIndex((tab) => tab === pluginTab.id);
    if (baseTabPosition !== -1) {
      newTabs.splice(baseTabPosition, 0, pluginTab);
      return;
    }

    newTabs.push(pluginTab);
  });

  dispatch(
    updateSidebarTabsAction({
      tabs: [...newTabs, ...SystemTabNames],
      isMobile: getCustomerDeviceType() !== DeviceType.Desktop,
    }),
  );
};

export default updateSidebarTabsOperation;

import { ImageManipulationPlugin } from 'editor/src/store/plugins/types';

import { MenuContext } from 'editor/src/component/Menu/type';

import canUseModificationOnImage from './canUseModificationOnImage';
import isImageManipulationPlugin from './isImageManipulationPlugin';

const getActiveImageManipulationPlugins = (menuContext: MenuContext) => {
  const { plugins } = menuContext;
  return plugins.filter(
    (plugin) => isImageManipulationPlugin(plugin) && canUseModificationOnImage(menuContext, plugin),
  ) as ImageManipulationPlugin[];
};

export default getActiveImageManipulationPlugins;

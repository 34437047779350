import cn from 'classnames';
import React from 'react';

import { EMPTY_RGBA_STRING } from 'editor/src/util/color/rgbaUtils';

import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import ColorTile from './ColorTile';

import styles from './index.module.scss';

interface Props {
  color: string;
  isActive: boolean;
  colorName?: string;
  isSuggested?: boolean;
  onClick: (color: string) => void;
  className?: string;
}

function ColorTileControl({ color, isActive, isSuggested, onClick, className, colorName }: Props) {
  const isMobile = useIsMobile();

  const noColor = !color || color === EMPTY_RGBA_STRING;

  return (
    <div
      onClick={() => onClick(color)}
      className={cn('cy-color-tile', styles.fontColorBox, className, {
        'cy-active': isActive,
        [styles.mobile]: isMobile,
        [styles.noColorWrapper]: noColor,
      })}
    >
      <ColorTile color={color} isActive={isActive} suggested={isSuggested} i18ColorName={colorName} />
    </div>
  );
}

export default React.memo(ColorTileControl);

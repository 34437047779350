import { useLayoutEffect, useCallback } from 'react';

import getTextEditMode from 'editor/src/store/editor/selector/getTextEditMode';
import { setTextEditModeAction as setTextEditModeOperation } from 'editor/src/store/editor/slice';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import FabricPathText from 'editor/src/fabric/FabricPathText';

function useTextEditMode(fabricElementRef: React.RefObject<FabricPathText>, selected: boolean) {
  const dispatch = useDispatch();
  const textEditModeEnabled = useSelector((state) => getTextEditMode(state) && selected);

  // support element edit mode through store
  // useLayoutEffect is used because keyboard was not visible on iOS Safari when used useEffect.
  // Safari on touchscreen devices is stingy when it comes to focusing textboxes. Safari wants
  // the focus to appear only if a user clicks on the field and focus() is being called while executing the click event.
  // So basically - synchronously and that's the reason why we used useLayoutEffect.
  useLayoutEffect(() => {
    if (!fabricElementRef.current) {
      return;
    }
    if (fabricElementRef.current.isEditing && !textEditModeEnabled) {
      fabricElementRef.current.exitEditing();
    } else if (!fabricElementRef.current.isEditing && textEditModeEnabled) {
      fabricElementRef.current.enterEditing();
      fabricElementRef.current.selectAll();
    }
  }, [textEditModeEnabled]);

  const onEnterEditing = useCallback(() => {
    dispatch(setTextEditModeOperation(true));
  }, []);

  const onExitEditing = useCallback(() => {
    dispatch(setTextEditModeOperation(false));
  }, []);

  return { onEnterEditing, onExitEditing, enabled: textEditModeEnabled };
}

export default useTextEditMode;

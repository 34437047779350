import cn from 'classnames';
import React from 'react';

import { isNotDefaultTab, isPluginTab, TabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  name: TAB_NAMES;
  noPreserve?: boolean;
  fullWidth?: boolean;
  activeTab: TabInfo | undefined;
}

function ActionBar({ children, noPreserve, name, activeTab, fullWidth }: Props) {
  let tabId;
  if (activeTab) {
    tabId = isNotDefaultTab(activeTab) || isPluginTab(activeTab) ? activeTab.id : activeTab;
  }

  if (noPreserve && tabId !== name) {
    return null;
  }

  return (
    <div
      className={cn(styles.actionBar, `cy-${name.toLowerCase()}`, {
        [styles.active]: tabId === name,
        [styles.fullWidth]: fullWidth,
      })}
    >
      {children}
    </div>
  );
}

export default ActionBar;

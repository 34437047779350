import cn from 'classnames';
import React, { useCallback } from 'react';

import updateConditionChildPositionOperation from 'editor/src/store/design/operation/updateConditionChildPositionOperation';
import getConditionChildrenKey from 'editor/src/store/design/reducer/getConditionChildrenKey';
import { Condition, ConditionChild, ConditionGroup, conditionGroupChildenKey } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import PersonalizationCondition from 'editor/src/component/PersonalizationContent/PersonalizationElements/PersonalizationCondition';
import PersonalizationImage from 'editor/src/component/PersonalizationContent/PersonalizationElements/PersonalizationImage';
import PersonalizationText from 'editor/src/component/PersonalizationContent/PersonalizationElements/PersonalizationText';

import PersonalizationShape from '../../PersonalizationContent/PersonalizationElements/PersonalizationShape';

import ReorganizableElement from './ReorganizableList/Element';
import ReorganizableList from './ReorganizableList/List';

import styles from './index.module.scss';

interface Props {
  isEditing: boolean;
  spreadIndex: number;
  conditionGroup: ConditionGroup;
  parentCondition: Condition | undefined;
  elements: ConditionChild[];
  textOnly: boolean;
  onConditionCreated?: (conditionId: string, edit: () => void) => void;
  className?: string;
}

function ConditionElements({
  spreadIndex,
  conditionGroup,
  elements,
  parentCondition,
  isEditing,
  onConditionCreated,
  textOnly,
  className,
}: Props) {
  const dispatch = useDispatch();

  const onIndexUpdate = useCallback(
    (prevIndex: number, newIndex: number) => {
      dispatch(
        updateConditionChildPositionOperation({
          spreadIndex,
          childrenKey: parentCondition
            ? getConditionChildrenKey(parentCondition.id, parentCondition.activeOptionId)
            : conditionGroupChildenKey,
          prevIndex,
          newIndex,
        }),
      );
    },
    [spreadIndex, parentCondition],
  );

  return (
    <ReorganizableList
      className={cn(styles.ConditionElements, {
        [styles.topLevel]: !parentCondition,
      })}
      margin={12}
      onIndexUpdate={onIndexUpdate}
    >
      {elements.map((element) => {
        if (textOnly && element.type !== 'text') {
          return null;
        }

        const key = element.type + element.id;
        switch (element.type) {
          case 'condition':
            return (
              <ReorganizableElement key={key} showBorder={isEditing}>
                <PersonalizationCondition
                  spreadIndex={spreadIndex}
                  conditionGroup={conditionGroup}
                  condition={conditionGroup.conditions[element.id]}
                  onConditionCreated={onConditionCreated}
                  isBuilding
                  textOnly={textOnly}
                />
              </ReorganizableElement>
            );
          case 'image':
          case 'addon':
            return (
              <ReorganizableElement key={key} className={styles.element}>
                <PersonalizationImage isBuilding elementId={element.id} className={className} />
              </ReorganizableElement>
            );
          case 'text':
            return (
              <ReorganizableElement key={key} className={styles.element}>
                <PersonalizationText isBuilding elementId={element.id} className={className} />
              </ReorganizableElement>
            );
          case 'line':
          case 'rectangle':
            return (
              <ReorganizableElement key={key} className={styles.element}>
                <PersonalizationShape isBuilding elementId={element.id} className={className} />
              </ReorganizableElement>
            );
          default:
            return null;
        }
      })}
    </ReorganizableList>
  );
}

export default React.memo(ConditionElements);

/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

function IconPremiumGold({ className }: Props) {
  return (
    <Icon className={cn(className, styles.iconPremium, 'cy-icon-premium', 'cy-icon-premium-gold')}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          className={styles.bg}
          stroke="none"
          d="M1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9Z"
        />
        <path
          stroke="none"
          d="M5 11L5 11.5C4.85126 11.5 4.71024 11.4338 4.61525 11.3193C4.52025 11.2049 4.48115 11.0541 4.50856 10.9079L5 11ZM6 11L6 10.5L6 10.5L6 11ZM5.75 7L5.25856 6.90786C5.29053 6.73735 5.40859 6.5956 5.57051 6.53333C5.73242 6.47105 5.91504 6.49715 6.05302 6.60228L5.75 7ZM7.81818 8.57576L8.21818 8.87576C8.13822 8.98237 8.01901 9.05265 7.88702 9.071C7.75502 9.08934 7.62116 9.05424 7.51516 8.97347L7.81818 8.57576ZM9 7L8.6 6.7C8.69443 6.5741 8.84262 6.5 9 6.5C9.15738 6.5 9.30557 6.5741 9.4 6.7L9 7ZM10.1818 8.57576L10.4848 8.97347C10.3788 9.05424 10.245 9.08934 10.113 9.071C9.98099 9.05265 9.86178 8.98237 9.78182 8.87576L10.1818 8.57576ZM12.25 7L11.947 6.60228C12.085 6.49715 12.2676 6.47105 12.4295 6.53333C12.5914 6.59561 12.7095 6.73735 12.7414 6.90786L12.25 7ZM13 11L13.4914 10.9079C13.5188 11.0541 13.4797 11.2049 13.3848 11.3193C13.2898 11.4338 13.1487 11.5 13 11.5L13 11ZM11 11L11 10.5L11 11ZM7 11L7 11.5L7 11.5L7 11ZM5 10.5L6 10.5L6 11.5L5 11.5L5 10.5ZM6.24144 7.09214L5.49144 11.0921L4.50856 10.9079L5.25856 6.90786L6.24144 7.09214ZM7.51516 8.97347L5.44698 7.39772L6.05302 6.60228L8.1212 8.17804L7.51516 8.97347ZM9.4 7.3L8.21818 8.87576L7.41818 8.27576L8.6 6.7L9.4 7.3ZM9.78182 8.87576L8.6 7.3L9.4 6.7L10.5818 8.27576L9.78182 8.87576ZM12.553 7.39772L10.4848 8.97347L9.8788 8.17804L11.947 6.60228L12.553 7.39772ZM12.5086 11.0921L11.7586 7.09214L12.7414 6.90786L13.4914 10.9079L12.5086 11.0921ZM11 10.5L13 10.5L13 11.5L11 11.5L11 10.5ZM7 10.5L11 10.5L11 11.5L7 11.5L7 10.5ZM6 10.5L7 10.5L7 11.5L6 11.5L6 10.5Z"
          fill="white"
        />
        <path
          stroke="none"
          d="M9 16C5.13401 16 2 12.866 2 9H0C0 13.9706 4.02944 18 9 18V16ZM16 9C16 12.866 12.866 16 9 16V18C13.9706 18 18 13.9706 18 9H16ZM9 2C12.866 2 16 5.13401 16 9H18C18 4.02944 13.9706 0 9 0V2ZM9 0C4.02944 0 0 4.02944 0 9H2C2 5.13401 5.13401 2 9 2V0Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
}

export default IconPremiumGold;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getConditionChildrenKey from 'editor/src/store/design/reducer/getConditionChildrenKey';
import { updateConditionActiveOptionAction } from 'editor/src/store/design/slice';
import { Condition, ConditionGroup } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import updateHiddenElementsOperation from './updateHiddenElementsOperation';

function findOptionWithElementId(condition: Condition, conditionGroup: ConditionGroup, elementId: number) {
  return condition.options.find((option) => {
    const children = conditionGroup.children[getConditionChildrenKey(condition.id, option.id)];
    return !!children.find((child) => child.type !== 'condition' && child.id === elementId);
  });
}

const openElementConditionTreeOperation =
  (condition: Condition, elementId: number) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const spreadIndex = getCurrentSpreadIndex(state);
    const conditionGroup = state.design.designData?.spreads[spreadIndex].conditionGroup;
    if (!conditionGroup) {
      return;
    }

    const option = findOptionWithElementId(condition, conditionGroup, elementId);
    if (!option) {
      return;
    }

    batch(() => {
      dispatch(
        updateConditionActiveOptionAction({
          address: { spreadIndex, conditionId: condition.id },
          optionId: option.id,
        }),
      );

      let { parent } = condition;
      while (parent) {
        const parentCondition = conditionGroup.conditions[parent.conditionId];
        if (parent.optionIds.length) {
          dispatch(
            updateConditionActiveOptionAction({
              address: { spreadIndex, conditionId: parentCondition.id },
              optionId: parent.optionIds[0],
            }),
          );
        }
        parent = parentCondition.parent;
      }

      dispatch(updateHiddenElementsOperation());
    });
  };

export default openElementConditionTreeOperation;

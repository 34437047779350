import cn from 'classnames';
import React, { useRef } from 'react';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import { FABRIC_SCROLL_EVENT } from 'editor/src/component/EditorArea/types';

import { ScrollbarCommonProps, ScrollLocations, stopPropagation } from './scrollbarUtils';
import useScrollbarUpdates from './useScrollbarUpdates';

import styles from './index.module.scss';

function HorizontalScrollbar({
  bottomBarHeight,
  bottomBarOffset,
  canvasSize,
  getTransformBoundaryCoordinates,
}: ScrollbarCommonProps) {
  const fabricCanvas = useFabricCanvas();

  const control = useRef<HTMLDivElement>(null);
  const controlWrapper = useRef<HTMLDivElement>(null);

  const getScrollLocations = (): ScrollLocations | undefined => {
    if (!fabricCanvas.vptCoords || !control.current || !controlWrapper.current) {
      return undefined;
    }

    const { x: scrollControlPosition, width: scrollControlWidth } = control.current.getBoundingClientRect();
    const { x: scrollWrapperPosition, width: scrollWrapperWidth } = controlWrapper.current.getBoundingClientRect();

    return {
      wrapper: { position: scrollWrapperPosition, width: scrollWrapperWidth },
      control: { position: scrollControlPosition, width: scrollControlWidth },
      viewportTransform: fabricCanvas?.viewportTransform?.[4] || 0,
    };
  };

  const moveScrollControl = (scrollDeltaX: number, scrollWidth?: number) => {
    if (!control.current) {
      return;
    }

    control.current.style.transform = `translateX(${scrollDeltaX}px)`;
    control.current.style.width = `${scrollWidth || 0}px`;
  };

  const fireScrollEvent = (canvasDeltaX: number) => {
    if (canvasDeltaX) {
      fabricCanvas.fire(FABRIC_SCROLL_EVENT, {
        deltaX: canvasDeltaX,
        deltaY: 0,
      });
    }
  };

  const { handleWrapperClick } = useScrollbarUpdates(
    control,
    moveScrollControl,
    Hammer.DIRECTION_HORIZONTAL,
    (event) => event.deltaX,
    fireScrollEvent,
    getScrollLocations,
    getTransformBoundaryCoordinates,
    canvasSize,
  );

  return (
    <div
      ref={controlWrapper}
      onClick={({ clientX }) => handleWrapperClick(clientX)}
      className={cn(styles.scrollbarContainer, styles.horizontal)}
      style={{
        bottom: !bottomBarOffset ? `${bottomBarHeight + bottomBarOffset}px` : undefined,
      }}
    >
      <div ref={control} onClick={stopPropagation} className={cn(styles.scrollbarControl, styles.horizontal)} />
    </div>
  );
}

export default React.memo(HorizontalScrollbar);

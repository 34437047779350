import React from 'react';

import VariantLock from 'editor/src/component/ProductVariantList/VariantLock';

import styles from './index.module.scss';

interface WidgetProps {
  imageSrc?: string;
  headerText: string;
  onLinkClick: () => void;
  linked: boolean;
  hideLink: boolean;
}

function WidgetHeader({ imageSrc, headerText, onLinkClick, linked, hideLink }: WidgetProps) {
  return (
    <div className={styles.widgetHeader}>
      <div className={styles.imageTitleContainer}>
        <div className={styles.designOptionImage}>
          {imageSrc && <img className={styles.image} alt={headerText} src={imageSrc} />}
        </div>
        <div className={styles.header}>{headerText}</div>
      </div>
      <div className={styles.icon}>
        {!hideLink && <VariantLock isLinked={linked} onToggle={onLinkClick} disabled={linked} />}
      </div>
    </div>
  );
}

export default React.memo(WidgetHeader);

import cn from 'classnames';
import React from 'react';

import { ConditionGroup, ConditionChild } from 'editor/src/store/design/types';

import PersonalizationCondition from './PersonalizationCondition';
import PersonalizationImage from './PersonalizationImage';
import PersonalizationShape from './PersonalizationShape';
import PersonalizationText from './PersonalizationText';

import styles from './index.module.scss';

interface Props {
  spreadIndex: number;
  conditionGroup: ConditionGroup;
  elements: ConditionChild[];
  personalizationElementClassName?: string;
}

function PersonalizationElements({ spreadIndex, conditionGroup, elements, personalizationElementClassName }: Props) {
  return (
    <div
      className={cn(styles.PersonalizationElements, {
        [styles.notEmpty]: !!elements.length,
      })}
    >
      {elements.map((element) => {
        const key = element.type + element.id;
        switch (element.type) {
          case 'text':
            return <PersonalizationText key={key} elementId={element.id} className={personalizationElementClassName} />;
          case 'image':
          case 'addon':
            return (
              <PersonalizationImage key={key} elementId={element.id} className={personalizationElementClassName} />
            );
          case 'line':
          case 'rectangle':
            return (
              <PersonalizationShape key={key} elementId={element.id} className={personalizationElementClassName} />
            );
          case 'condition':
            return (
              <PersonalizationCondition
                key={key}
                spreadIndex={spreadIndex}
                conditionGroup={conditionGroup}
                condition={conditionGroup.conditions[element.id]}
                isBuilding={false}
                textOnly={false}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
}

export default React.memo(PersonalizationElements);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getCurrentState from 'editor/src/store/editorModules/undoRedo/selector/getCurrentState';
import { popPreviousStateAction, pushNextStateAction } from 'editor/src/store/editorModules/undoRedo/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import applySavedStoreStateOperation from './applySavedStoreStateOperation';

const undoStoreOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const previousElementsCount = state.editorModules.undoRedo.previous.length;
  if (!previousElementsCount) {
    return;
  }

  batch(() => {
    const currentState = getCurrentState(state);
    const previousState = state.editorModules.undoRedo.previous[previousElementsCount - 1];
    dispatch(applySavedStoreStateOperation(previousState, state));
    const previousDesign = previousState.design.designData;
    if (previousDesign && previousDesign.product_uid !== currentState.design.designData?.product_uid) {
      sendPostMessage('design.productSwitched', {
        productUid: previousDesign.product_uid,
        width: previousDesign.dimensions?.width,
        height: previousDesign.dimensions?.height,
        pageCount: previousDesign.page_count_limit ? previousDesign.page_count : undefined,
      });
    }

    dispatch(
      pushNextStateAction({
        key: 'undo',
        ...currentState,
      }),
    );
    dispatch(popPreviousStateAction());
  });
};

export default undoStoreOperation;

import React from 'react';

import styles from './index.module.scss';

export const CONTAINER_ID = 'overlay-container-id';

function OverlayContainer() {
  return <div id={CONTAINER_ID} className={styles.OverlayContainer} />;
}

export default OverlayContainer;

import cn from 'classnames';
import React from 'react';

import { ProductControlOptionColor } from 'editor/src/store/variants/types';

import ColorDot from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/elements/ColorElement/ColorDot';
import { ControlOptionProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';

import styles from './index.module.scss';

const MaterialElement = React.memo(
  ({ option, selected, disabled, toggle, control }: ControlOptionProps<ProductControlOptionColor>) => (
    <div className={styles.wrapper}>
      <div className={styles.colorContainer}>
        <ColorDot
          toggle={() => toggle(control, option)}
          className={cn(`cy-color-box-${option.value}`, styles.colorItem)}
          disabled={disabled}
          selected={selected}
          texture={option.value}
        />
        <span>{option.title}</span>
      </div>
    </div>
  ),
);

export default React.memo(MaterialElement);

import cn from 'classnames';
import React from 'react';

import Section from 'editor/src/component/DesktopSidebar/TabContents/Section';

import styles from './index.module.scss';

function AddonsLoader() {
  return (
    <div className={cn(styles.addonsList, 'cy-addons-loader')}>
      <Section noBackground>
        <div className={styles.list}>
          {Array(28)
            .fill(null)
            .map((_, index) => (
              <div className={cn(styles.item, 'cy-addons-loader-item')} key={index} />
            ))}
        </div>
      </Section>
    </div>
  );
}

export default AddonsLoader;

import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  noBackground?: boolean;
}

function Section({ children, className, noBackground }: Props) {
  return (
    <div
      className={cn({
        [styles.section]: true,
        [className || '']: true,
        [styles.noBackground]: noBackground,
      })}
    >
      {children}
    </div>
  );
}

export default Section;

import { removeElementFromGroupConditionAction } from 'editor/src/store/design/slice';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const removeElementFromGroupConditionOperation =
  (elementId: number) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const spreadIndex = getCurrentSpreadIndex(state);
    dispatch(removeElementFromGroupConditionAction({ spreadIndex, elementId }));
  };

export default removeElementFromGroupConditionOperation;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconUnderline({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M9.70801 35C9.31934 35 8.99301 34.868 8.72901 34.604C8.46501 34.34 8.33301 34.0137 8.33301 33.625C8.33301 33.2083 8.46501 32.868 8.72901 32.604C8.99301 32.34 9.31934 32.208 9.70801 32.208H30.292C30.6807 32.208 31.007 32.347 31.271 32.625C31.535 32.903 31.667 33.2363 31.667 33.625C31.667 34.0137 31.535 34.34 31.271 34.604C31.007 34.868 30.6807 35 30.292 35H9.70801ZM20 28.333C17.1947 28.333 14.8267 27.3747 12.896 25.458C10.9653 23.5413 10 21.1803 10 18.375V6.792C10 6.292 10.1737 5.86833 10.521 5.521C10.8683 5.17367 11.292 5 11.792 5C12.3193 5 12.7567 5.17367 13.104 5.521C13.4513 5.86833 13.625 6.292 13.625 6.792V18.458C13.625 20.208 14.236 21.6873 15.458 22.896C16.6807 24.104 18.1947 24.708 20 24.708C21.8053 24.708 23.3193 24.104 24.542 22.896C25.764 21.6873 26.375 20.208 26.375 18.458V6.792C26.375 6.292 26.5557 5.86833 26.917 5.521C27.2777 5.17367 27.708 5 28.208 5C28.708 5 29.1317 5.17367 29.479 5.521C29.8263 5.86833 30 6.292 30 6.792V18.375C30 21.1803 29.0347 23.5413 27.104 25.458C25.1733 27.3747 22.8053 28.333 20 28.333Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconUnderline;

import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import getBoxedValue from 'editor/src/util/getBoxedValue';

import Slider from 'editor/src/component/Slider';

import styles from './index.module.scss';

interface Props {
  onChange: (value: number) => void;
  onAfterChange: (value: number) => void;
  min: number;
  max: number;
  step: number;
  startPoint: number;
  value: number;
  Label: React.FC | React.VFC | string;
  className?: string;
  inputClassName?: string;
  position?: 'singleRow' | 'twoRows';
}

function SliderWithInput({
  onChange,
  onAfterChange,
  className,
  min,
  max,
  step,
  startPoint,
  value,
  Label,
  inputClassName,
  position = 'singleRow',
}: Props) {
  const [inputValue, setInputValue] = useState<string>(value.toString());

  useEffect(() => setInputValue(value.toString()), [value]);

  const onSliderChange = useCallback(
    (sliderValue: number) => {
      setInputValue(sliderValue.toString());
      onChange(sliderValue);
    },
    [onChange],
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: targetValue } = e.target;

    // if user is in the middle of typing
    if (
      /^[+-]?\d+\.$/.test(targetValue) ||
      targetValue === '' ||
      (targetValue === '0' && min > 0) ||
      (targetValue === '-' && min < 0)
    ) {
      return setInputValue(targetValue);
    }

    if (Number.isNaN(Number(targetValue))) {
      return false;
    }

    const roundedValue = step.toString().includes('.')
      ? parseFloat(parseFloat(targetValue).toFixed(step.toString().split('.')[1].length))
      : parseInt(targetValue, 10);
    const boxedValue = getBoxedValue(roundedValue, min, max);

    return boxedValue !== value ? onAfterChange(boxedValue) : setInputValue(boxedValue.toString());
  };

  return (
    <div className={cn(styles.mainContainer, styles[position], className)}>
      <div className={styles.label}>{typeof Label === 'string' ? Label : <Label />}</div>
      <Slider
        className={styles.slider}
        min={min}
        max={max}
        step={step}
        startPoint={startPoint}
        value={value}
        onChange={onSliderChange}
        onAfterChange={onAfterChange}
      />
      <input className={cn(styles.input, inputClassName)} value={inputValue} type="text" onChange={onInputChange} />
    </div>
  );
}

export default React.memo(SliderWithInput);

import showLoaderOperation from 'editor/src/store/app/operation/showLoaderOperation';
import { LoaderType } from 'editor/src/store/app/types';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import addNewAddonToPageOperation from 'editor/src/store/design/operation/addNewAddonToPageOperation';
import addNewImageToPageOperation from 'editor/src/store/design/operation/addNewImageToPageOperation';
import updateImageIdByUuidOperation from 'editor/src/store/design/operation/updateImageIdByUuidOperation';
import updateImageWithGraphicOperation from 'editor/src/store/design/operation/updateImageWithGraphicOperation';
import getSelectedImagePersonalizationLocked from 'editor/src/store/design/selector/getSelectedImagePersonalizationLocked';
import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getReplacableImageElement from 'editor/src/store/editor/selector/getReplacableImageElement';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import isPersonalizationLockIgnored from 'editor/src/store/editor/selector/isPersonalizationLockIgnored';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { ImageState } from 'editor/src/store/gallery/types';
import type { Thunk } from 'editor/src/store/hooks';
import { MockupRole } from 'editor/src/store/mockup/types';

import addSelectedMediaElementOperation from './addSelectedMediaElementOperation';
import selectNextEmptyImageFrameOperation from './selectNextEmptyImageFrameOperation';

const applyImageOperation =
  (
    imageId: string,
    type: 'image' | 'addon' = 'image',
    saveUndoRedoStep = true,
    shiftX?: number,
    shiftY?: number,
  ): Thunk =>
  (dispatch, getStore, {}) => {
    const state = getStore();
    const currentSpreadIndex = getCurrentSpreadIndex(state);
    const selectedImageElement = getSelectedMediaElementsObjects(state).find((element) => element?.type === 'image');
    const selectedImageUuid = selectedImageElement?.uuid;

    const selectedImagePersonalizationLocked =
      getSelectedImagePersonalizationLocked(state) && !isPersonalizationLockIgnored(state);
    const addElementsAllowed = isAddElementsAllowed(state);
    const replacableElementUuid = getReplacableImageElement(state, currentSpreadIndex, true)?.uuid;

    batch(() => {
      let updateOperation;
      if (type === 'image') {
        updateOperation = updateImageIdByUuidOperation;
        const galleryImage = getImageById(state, imageId);
        if (galleryImage?.state !== ImageState.UPLOADED) {
          dispatch(showLoaderOperation(LoaderType.EditorArea));
        }
      } else {
        updateOperation = updateImageWithGraphicOperation;
      }

      const isMockupPlaceholder =
        selectedImageElement?.type === 'image' && selectedImageElement.role === MockupRole.Placeholder;
      if (selectedImageUuid && !isMockupPlaceholder) {
        if (!selectedImagePersonalizationLocked) {
          dispatch(selectNextEmptyImageFrameOperation(selectedImageUuid));
          void dispatch(updateOperation(selectedImageUuid, imageId, saveUndoRedoStep));
        }
      } else if (addElementsAllowed) {
        if (type === 'image') {
          if (replacableElementUuid && !selectedImagePersonalizationLocked) {
            dispatch(addSelectedMediaElementOperation(replacableElementUuid));
            dispatch(selectNextEmptyImageFrameOperation(replacableElementUuid));
            void dispatch(updateOperation(replacableElementUuid, imageId, saveUndoRedoStep));
          } else {
            dispatch(
              addNewImageToPageOperation(
                currentSpreadIndex,
                0,
                imageId,
                undefined,
                undefined,
                saveUndoRedoStep,
                shiftX,
                shiftY,
              ),
            );
          }
        } else if (type === 'addon') {
          void dispatch(addNewAddonToPageOperation(currentSpreadIndex, 0, imageId));
        }
      } else if (replacableElementUuid && !selectedImagePersonalizationLocked) {
        dispatch(addSelectedMediaElementOperation(replacableElementUuid));
        dispatch(selectNextEmptyImageFrameOperation(replacableElementUuid));
        void dispatch(updateOperation(replacableElementUuid, imageId, saveUndoRedoStep));
      }
    });
  };

export default applyImageOperation;

import cn from 'classnames';
import React, { useCallback } from 'react';

import { MediaShape } from 'editor/src/store/design/types';

import { PresetItem, ShapeIconProps, ShapeStylesFromType } from 'editor/src/util/shapePresets';

import CarouselContainer from 'editor/src/component/Carousel/CarouselContainer';

import styles from './index.module.scss';

interface Props<T extends MediaShape['type']> {
  type: T;
  title: string;
  className: string;
  presets: PresetItem<Props<T>['type']>[];
  onAddShapeClick: (type: T, shapeStyles: ShapeStylesFromType<T>) => void;
}

function ShapesPresetCarousel<T extends MediaShape['type']>(props: Props<T>) {
  const { type, title, className, presets, onAddShapeClick } = props;

  const getIconProps = useCallback((shapeStyles: ShapeStylesFromType<T>): ShapeIconProps => {
    const iconProps: (keyof ShapeIconProps)[] = ['dashPattern', 'fill', 'stroke', 'strokeWidth'];

    return Object.entries(shapeStyles).reduce<ShapeIconProps>((result, [key, value]) => {
      const shapeIconProp = key as keyof ShapeIconProps;
      if (iconProps.includes(shapeIconProp) && value) {
        result[shapeIconProp] = value as any;
      }

      return result;
    }, {});
  }, []);

  return (
    <CarouselContainer title={title} className={cn(className, `cy-shape-preset-${type}`)}>
      {presets.map(({ Icon, shapeStyles, key }) => (
        <div
          className={cn(styles.shapePreset, `cy-add-${key}-button`)}
          onClick={() => onAddShapeClick(type, shapeStyles)}
          key={key}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Icon className="shapeIcon" {...getIconProps(shapeStyles)} />
        </div>
      ))}
    </CarouselContainer>
  );
}

export default React.memo(ShapesPresetCarousel);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { loadSpreadImages } from 'editor/src/store/design/operation/setSpreadBackgroundsOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { addDesignTemplatesAction } from 'editor/src/store/variants/slice';

import applyTemplatesIfNeededOperation, { DesignTemplate } from './applyTemplatesIfNeededOperation';

const handleNewDesignTemplatesOperation = (templates: DesignTemplate[]) => async (dispatch: ThunkDispatch) => {
  await Promise.all(
    templates.map(async (template) => {
      if (template.spreadForegrounds) {
        template.spreadForegrounds = await loadSpreadImages(template.spreadForegrounds);
      }

      if (template.spreadBackgrounds) {
        template.spreadBackgrounds = await loadSpreadImages(template.spreadBackgrounds);
      }
    }),
  );

  batch(() => {
    dispatch(addDesignTemplatesAction(templates));
    dispatch(applyTemplatesIfNeededOperation(templates));
  });
};

export default handleNewDesignTemplatesOperation;

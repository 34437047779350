/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconUndo({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M13.042 31.667C12.6527 31.667 12.326 31.535 12.062 31.271C11.7987 31.007 11.667 30.6807 11.667 30.292C11.667 29.8753 11.7987 29.535 12.062 29.271C12.326 29.007 12.6527 28.875 13.042 28.875H23.625C25.5137 28.875 27.1387 28.264 28.5 27.042C29.8613 25.8193 30.542 24.2913 30.542 22.458C30.542 20.6247 29.8613 19.09 28.5 17.854C27.1387 16.618 25.5137 16 23.625 16H11.958L15.583 19.625C15.861 19.903 16 20.2293 16 20.604C16 20.9793 15.861 21.3057 15.583 21.583C15.3057 21.861 14.9793 22 14.604 22C14.2293 22 13.903 21.861 13.625 21.583L7.625 15.583C7.48633 15.4443 7.389 15.2917 7.333 15.125C7.27767 14.9583 7.25 14.7917 7.25 14.625C7.25 14.4303 7.27767 14.2567 7.333 14.104C7.389 13.9513 7.48633 13.7917 7.625 13.625L13.625 7.625C13.903 7.375 14.2293 7.25 14.604 7.25C14.9793 7.25 15.3057 7.375 15.583 7.625C15.861 7.903 16 8.22933 16 8.604C16 8.97933 15.861 9.30567 15.583 9.583L11.958 13.208H23.625C26.2637 13.208 28.5413 14.097 30.458 15.875C32.3747 17.653 33.333 19.8473 33.333 22.458C33.333 25.0693 32.3747 27.257 30.458 29.021C28.5413 30.785 26.2637 31.667 23.625 31.667H13.042Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconUndo;

import React from 'react';

import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { useDispatch } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import IconCross from 'editor/src/component/Icon/IconCross';

interface Props {
  closeBtnShowTabIndex?: number;
}

function ButtonClose({ closeBtnShowTabIndex }: Props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    const tabIndex = closeBtnShowTabIndex ?? -1;
    dispatch(setSidebarActiveTabOperation(tabIndex));
  };

  return (
    <ControlButton onClick={handleClick} className="cy-tab-close">
      <IconCross />
    </ControlButton>
  );
}

export default ButtonClose;

import React from 'react';

import applyImageFillOperation from 'editor/src/store/design/operation/applyImageFillOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import { useDispatch } from 'editor/src/store/hooks';

import IconFill from 'editor/src/component/Icon/IconFill';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection, isSelectionLocked, structureIndexes }) => {
  if (isSelectionLocked || !hasSelection) {
    return false;
  }

  const element = getMediaElement(state, structureIndexes[0]);
  return !!element && !isMediaMockupPlaceholder(element);
};
export const canShowInTopBar: CanShow = (state, menuContext) =>
  !state.hostSettings.editImageFlag && canShow(state, menuContext);

type Props = Pick<MenuItemProps, 'context' | 'mode'>;

function ButtonFill({ context, mode }: Props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(applyImageFillOperation(context.structureIndexes));
  };

  return (
    <MenuButton
      Icon={IconFill}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-fill-edit-image"
      className="cy-button-fill"
    />
  );
}

export default React.memo(ButtonFill);

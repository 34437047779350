import cn from 'classnames';
import React from 'react';

import { Scene, SceneCategory } from 'editor/src/store/mockup/types';

import Button from 'editor/src/component/Button';
import SceneList from 'editor/src/component/DesktopSidebar/TabContents/SceneTabContent/SceneList';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconArrowLeft from 'editor/src/component/Icon/IconArrowLeft';

import styles from './index.module.scss';

interface Props {
  category: SceneCategory;
  selectedSceneId: string | undefined;
  onSceneSelect: (scene: Scene) => void;
  back: () => void;
}

function ScenesCategoryContent({ category, selectedSceneId, back, onSceneSelect }: Props) {
  return (
    <>
      <TabContentHeader
        className="py-0"
        title={category.name}
        icon={
          <Button onClick={back} className={cn('cy-button-back', 'mr-1', styles.backButton)}>
            <IconArrowLeft />
          </Button>
        }
      />
      <SceneList category={category} onSceneSelect={onSceneSelect} selectedSceneId={selectedSceneId} />
    </>
  );
}

export default React.memo(ScenesCategoryContent);

/* eslint-disable max-len */
import React from 'react';

function IconExpandMore() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99967 12.4571C9.88834 12.4571 9.78067 12.4365 9.67667 12.3951C9.57267 12.3531 9.47201 12.2838 9.37467 12.1871L5.60367 8.41614C5.43701 8.24947 5.36067 8.04114 5.37467 7.79114C5.38867 7.54114 5.47201 7.3398 5.62467 7.18714C5.80534 7.00647 6.01367 6.91947 6.24967 6.92614C6.48567 6.93347 6.68701 7.02047 6.85367 7.18714L9.99967 10.3531L13.1667 7.18714C13.3333 7.02047 13.531 6.93714 13.7597 6.93714C13.989 6.93714 14.194 7.02714 14.3747 7.20714C14.5413 7.3738 14.6247 7.5788 14.6247 7.82214C14.6247 8.06547 14.5413 8.27047 14.3747 8.43714L10.6247 12.1871C10.5273 12.2838 10.4267 12.3531 10.3227 12.3951C10.2187 12.4365 10.111 12.4571 9.99967 12.4571Z"
        fill="#212121"
      />
    </svg>
  );
}

export default React.memo(IconExpandMore);

import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch } from 'editor/src/store/hooks';

import LinkButton from 'editor/src/component/LinkButton';

import styles from './index.module.scss';

function ButtonOpenTemplatesTab() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onOpenTemplatesTab = useCallback(() => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.DESIGN_TEMPLATES));
  }, []);

  return (
    <div className={styles.wrapper}>
      <LinkButton onClick={onOpenTemplatesTab} className={cn(styles.button, 'cy-button-open-templates-tab')}>
        {/* TODO translate */}
        {t('Show Templates')}
      </LinkButton>
    </div>
  );
}

export default React.memo(ButtonOpenTemplatesTab);

import React from 'react';
import { useTranslation } from 'react-i18next';

import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import Icon from 'editor/src/component/Icon';
import IconPersonsalisation from 'editor/src/component/Icon/IconPersonsalisation';
import PersonalizationContent from 'editor/src/component/PersonalizationContent';

import styles from './index.module.scss';

function PersonalisationFlowContent({ title, icon }: TabContentProps) {
  const { t } = useTranslation();

  return (
    <>
      <TabContentHeader
        title={title || t('editor-personalization')}
        icon={
          icon || (
            <Icon>
              <IconPersonsalisation className={styles.icon} />
            </Icon>
          )
        }
      />
      <ContentBlock className={styles.PersonalisationFlowContent} scroll fillFlex>
        <PersonalizationContent />
      </ContentBlock>
    </>
  );
}

export default React.memo(PersonalisationFlowContent);

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}
/* eslint-disable max-len */
function IconArrow2({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2042_11461)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3432 8.92912L23.7072 15.2931C24.0977 15.6836 24.0977 16.3168 23.7072 16.7073L17.3432 23.0713C16.9527 23.4618 16.3195 23.4618 15.929 23.0713C15.5385 22.6807 15.5385 22.0476 15.929 21.657L20.5859 17.0002H0V15.0002H20.5859L15.929 10.3433C15.5385 9.95281 15.5385 9.31965 15.929 8.92912C16.3195 8.5386 16.9527 8.5386 17.3432 8.92912Z"
            fill="#383838"
          />
        </g>
        <defs>
          <clipPath id="clip0_2042_11461">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}

export default IconArrow2;

import getDesignData from 'editor/src/store/design/selector/getDesignData';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getGalleryImages from 'editor/src/store/gallery/selector/getGalleryImages';
import { useStore, useDispatch } from 'editor/src/store/hooks';

import findNotUsedImages from 'editor/src/util/autofill/findNotUsedImages';

import { Uploader } from 'editor/src/component/Uploader/useUploader';

const useEmptyPlaceholderEventHandlers = (uploader: Uploader) => {
  const store = useStore();
  const dispatch = useDispatch();

  const openGallery = (openUploaderIfGalleryTabOpened = false) => {
    const state = store.getState();
    const activeTab = getSidebarActiveTab(state);
    const images = getGalleryImages(state);
    const designData = getDesignData(state);

    if (activeTab !== TAB_NAMES.GALLERY) {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.GALLERY));
    }

    if (
      (openUploaderIfGalleryTabOpened && activeTab === TAB_NAMES.GALLERY) ||
      (designData && !findNotUsedImages(images, designData).length)
    ) {
      setTimeout(() => {
        uploader.open();
      }, 50);
    }
  };

  return {
    onPlaceholderClick: openGallery,
  };
};

export default useEmptyPlaceholderEventHandlers;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconSpreadView({ className }: Props) {
  return (
    <Icon className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="currentColor">
        <path
          d="M6.64279 11.1478C6.1741 11.1478 5.77288 10.9809 5.43912 10.6472C5.10536 10.3134 4.93848 9.9122 4.93848 9.44351V6.03488C4.93848 5.56619 5.10536 5.16497 5.43912 4.83121C5.77288 4.49745 6.1741 4.33057 6.64279 4.33057H10.0514C10.5201 4.33057 10.9213 4.49745 11.2551 4.83121C11.5889 5.16497 11.7557 5.56619 11.7557 6.03488V9.44351C11.7557 9.9122 11.5889 10.3134 11.2551 10.6472C10.9213 10.9809 10.5201 11.1478 10.0514 11.1478H6.64279ZM6.64279 19.6694C6.1741 19.6694 5.77288 19.5025 5.43912 19.1688C5.10536 18.835 4.93848 18.4338 4.93848 17.9651V14.5565C4.93848 14.0878 5.10536 13.6865 5.43912 13.3528C5.77288 13.019 6.1741 12.8521 6.64279 12.8521H10.0514C10.5201 12.8521 10.9213 13.019 11.2551 13.3528C11.5889 13.6865 11.7557 14.0878 11.7557 14.5565V17.9651C11.7557 18.4338 11.5889 18.835 11.2551 19.1688C10.9213 19.5025 10.5201 19.6694 10.0514 19.6694H6.64279ZM15.1644 11.1478C14.6957 11.1478 14.2945 10.9809 13.9607 10.6472C13.6269 10.3134 13.4601 9.9122 13.4601 9.44351V6.03488C13.4601 5.56619 13.6269 5.16497 13.9607 4.83121C14.2945 4.49745 14.6957 4.33057 15.1644 4.33057H18.573C19.0417 4.33057 19.4429 4.49745 19.7767 4.83121C20.1104 5.16497 20.2773 5.56619 20.2773 6.03488V9.44351C20.2773 9.9122 20.1104 10.3134 19.7767 10.6472C19.4429 10.9809 19.0417 11.1478 18.573 11.1478H15.1644ZM15.1644 19.6694C14.6957 19.6694 14.2945 19.5025 13.9607 19.1688C13.6269 18.835 13.4601 18.4338 13.4601 17.9651V14.5565C13.4601 14.0878 13.6269 13.6865 13.9607 13.3528C14.2945 13.019 14.6957 12.8521 15.1644 12.8521H18.573C19.0417 12.8521 19.4429 13.019 19.7767 13.3528C20.1104 13.6865 20.2773 14.0878 20.2773 14.5565V17.9651C20.2773 18.4338 20.1104 18.835 19.7767 19.1688C19.4429 19.5025 19.0417 19.6694 18.573 19.6694H15.1644ZM6.64279 9.44351H10.0514V6.03488H6.64279V9.44351ZM15.1644 9.44351H18.573V6.03488H15.1644V9.44351ZM15.1644 17.9651H18.573V14.5565H15.1644V17.9651ZM6.64279 17.9651H10.0514V14.5565H6.64279V17.9651Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}

export default IconSpreadView;

import { StructureIndex } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';

import getMediaElement from './getMediaElement';

const isElementPersonalizationLocked = (state: RootState, structureIndex: StructureIndex) => {
  if (!structureIndex) {
    return false;
  }
  const selectedElement = getMediaElement(state, structureIndex);
  if (!selectedElement) {
    return false;
  }
  return Boolean(selectedElement.personalizationLocked);
};

export default isElementPersonalizationLocked;

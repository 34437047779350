/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconTail({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        style={{ strokeWidth: 0 }}
      >
        <g id="iconTail">
          <path
            id="icon"
            d="M4.5 15.5H5.5625L13.375 7.6875L12.3125 6.625L4.5 14.4375V15.5ZM3.75379 17C3.54293 17 3.36458 16.9285 3.21875 16.7855C3.07292 16.6425 3 16.4652 3 16.2538V14.4443C3 14.2453 3.03472 14.0556 3.10417 13.875C3.17361 13.6944 3.28472 13.5278 3.4375 13.375L13.375 3.4375C13.5278 3.28472 13.6933 3.17361 13.8716 3.10417C14.0499 3.03472 14.2374 3 14.4341 3C14.6308 3 14.8194 3.03472 15 3.10417C15.1806 3.17361 15.3472 3.28472 15.5 3.4375L16.5625 4.5C16.7153 4.65278 16.8264 4.81944 16.8958 5C16.9653 5.18056 17 5.36487 17 5.55296C17 5.75357 16.9651 5.94478 16.8954 6.12658C16.8256 6.3084 16.7147 6.47454 16.5625 6.625L6.625 16.5625C6.47222 16.7153 6.3059 16.8264 6.12604 16.8958C5.94619 16.9653 5.75704 17 5.55856 17H3.75379ZM12.8344 7.16556L12.3125 6.625L13.375 7.6875L12.8344 7.16556Z"
            fill="#6B6B6B"
          />
        </g>
      </svg>
    </Icon>
  );
}

export default IconTail;

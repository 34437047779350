import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getVariationGroupsFromDesignOptions from 'editor/src/store/variants/selector/getVariationGroupsFromDesignOptions';
import { deleteDesignOptionAction, updateVariationGroupsAction } from 'editor/src/store/variants/slice';
import { DesignOption } from 'editor/src/store/variants/types';

const deleteDesignOptionOperation =
  (designOption: DesignOption) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const designOptionVariationGroups = getVariationGroupsFromDesignOptions(state);
    const targetedDesignOptionGroup = designOptionVariationGroups?.find(
      (group) => group.name === designOption.title && group.key === designOption.value,
    )?.value;
    const targetedDesignOptionGroupKeys = targetedDesignOptionGroup?.map((group) => group.key);
    const variationGroups = state.variants.variationGroups.filter((group) =>
      targetedDesignOptionGroupKeys?.find((key) => key !== group.key),
    );
    batch(() => {
      dispatch(saveUndoRedoStateOperation('design options'));
      dispatch(deleteDesignOptionAction({ designOption, controlKey: 'design-option' }));
      dispatch(updateVariationGroupsAction(variationGroups));
    });
  };

export default deleteDesignOptionOperation;

import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import getVisibleWarningsCount from 'editor/src/store/editorModules/warnings/selector/getVisibleWarningsCount';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import Badge from 'editor/src/component/DesktopSidebar/Tabs/Tab/Badge';
import DropDown from 'editor/src/component/DropDown';
import IconMoreActive from 'editor/src/component/Icon/IconMoreActive';
import { MenuContext } from 'editor/src/component/Menu/type';
import ButtonWarnings from 'editor/src/component/TopMenuDesktop/buttons/ButtonWarnings';
import { DesktopMenuItem, PLACE_HOLDER } from 'editor/src/component/TopMenuDesktop/menuItems';

import styles from './index.module.scss';

interface Props {
  elements: DesktopMenuItem[];
  context: MenuContext;
  isMobile: boolean;
}

function ButtonMenuMore({ elements, context, isMobile }: Props) {
  const dispatch = useDispatch();
  const warningsCount = useSelector(getVisibleWarningsCount);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  function toggle() {
    dispatch(setActiveTopMenuDropdownOperation(undefined));
    setDropdownVisible(!dropdownVisible);
  }

  const close = useCallback(() => {
    setDropdownVisible(false);
  }, []);

  return (
    <>
      <ControlButton onClick={toggle} stopPropagation className={cn(styles.buttonMenuMore, 'cy-button-more')}>
        {warningsCount > 0 && elements.some((elt) => elt.Component === ButtonWarnings) ? (
          <Badge className={styles.badge}>{warningsCount}</Badge>
        ) : null}
        <IconMoreActive />
      </ControlButton>
      <DropDown
        isVisible={dropdownVisible}
        onClickOutside={close}
        openPosition="bottom-right"
        wrapperClassName={styles.dropDown}
        className="cy-more-dropdown"
      >
        <div className={styles.itemList}>
          {elements.length > 0 &&
            elements.map(({ Component, DropDown, dropDownMode }, i) => {
              const Comp = DropDown || Component;
              if (Comp === PLACE_HOLDER) {
                return null;
              }

              return (
                <Comp key={i} context={context} isMobile={isMobile} mode={dropDownMode || 'label-icon'} close={close} />
              );
            })}
        </div>
      </DropDown>
    </>
  );
}

export default React.memo(ButtonMenuMore);

import { fabric } from 'fabric';

import FabricImage from 'editor/src/fabric/FabricImage';

import { bl2tl, br2tl, tl2tl, tr2tl } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/transformUtils';

type TransformData = [
  center: fabric.Point,
  dragPoint: fabric.Point,
  dragPointOrigin: fabric.Point,
  imageDragPointOrigin: fabric.Point,
  coordFn: (x: number, y: number, imageOriginal: fabric.Object) => { x: number; y: number },
];

function getTransformationData(
  corner: fabric.Transform['corner'],
  mask: fabric.Object,
  maskOrigin: fabric.Object,
  imageOrigin: fabric.Object,
): TransformData | null {
  if (!mask.aCoords || !maskOrigin.aCoords || !imageOrigin.aCoords) {
    return null;
  }

  switch (corner) {
    case 'tl': {
      return [maskOrigin.aCoords.br, mask.aCoords.tl, maskOrigin.aCoords.tl, imageOrigin.aCoords.tl, tl2tl];
    }
    case 'tr': {
      return [maskOrigin.aCoords.bl, mask.aCoords.tr, maskOrigin.aCoords.tr, imageOrigin.aCoords.tr, tr2tl];
    }
    case 'bl': {
      return [maskOrigin.aCoords.tr, mask.aCoords.bl, maskOrigin.aCoords.bl, imageOrigin.aCoords.bl, bl2tl];
    }
    case 'br': {
      return [maskOrigin.aCoords.tl, mask.aCoords.br, maskOrigin.aCoords.br, imageOrigin.aCoords.br, br2tl];
    }
    default:
      return null;
  }
}

const getImageUpdateOnResize = (
  transform: fabric.Transform,
  mask: fabric.Object,
  maskOrigin: fabric.Object,
  imageOrigin: fabric.Object,
): Partial<FabricImage> => {
  const transformData = getTransformationData(transform.corner, mask, maskOrigin, imageOrigin);
  if (!transformData) {
    return {};
  }

  const [center, dragPoint, dragPointOrigin, imageDragPoint, coordFn] = transformData;
  const originalDistance = dragPointOrigin.distanceFrom(center);
  const newDistance = dragPoint.distanceFrom(center);
  const ratio = newDistance / originalDistance;

  const adjustedCoords = coordFn(imageDragPoint.x - center.x, imageDragPoint.y - center.y, imageOrigin);

  return {
    left: center.x + adjustedCoords.x * ratio,
    top: center.y + adjustedCoords.y * ratio,
    scaleX: (imageOrigin.scaleX || 1) * ratio,
    scaleY: (imageOrigin.scaleY || 1) * ratio,
  };
};

export default getImageUpdateOnResize;

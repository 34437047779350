import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getCurrentState from 'editor/src/store/editorModules/undoRedo/selector/getCurrentState';
import { popNextStateAction, pushPreviousStateAction } from 'editor/src/store/editorModules/undoRedo/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import applySavedStoreStateOperation from './applySavedStoreStateOperation';

const redoStoreOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const nextElementsCount = state.editorModules.undoRedo.next.length;
  const currentState = getCurrentState(state);
  if (nextElementsCount) {
    batch(() => {
      const nextState = state.editorModules.undoRedo.next[nextElementsCount - 1];
      const nextDesign = nextState.design.designData;
      if (nextDesign && nextDesign.product_uid !== currentState.design.designData?.product_uid) {
        sendPostMessage('design.productSwitched', {
          productUid: nextDesign.product_uid,
          width: nextDesign.dimensions?.width,
          height: nextDesign.dimensions?.height,
          pageCount: nextDesign.page_count_limit ? nextDesign.page_count : undefined,
        });
      }

      dispatch(applySavedStoreStateOperation(nextState, state));

      dispatch(
        pushPreviousStateAction({
          key: 'redo',
          ...currentState,
        }),
      );
      dispatch(popNextStateAction());
    });
  }
};

export default redoStoreOperation;

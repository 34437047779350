import cn from 'classnames';
import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Unit } from 'editor/src/store/editor/types';

import limitPrecision from 'editor/src/util/limitPrecision';
import { convertMmToUnit, convertUnitToMm } from 'editor/src/util/math';

import styles from './index.module.scss';

export type Field = 'width' | 'height';

interface Props {
  field: Field;
  Icon: React.FC;
  valueMm: number;
  min: number;
  max: number;
  maxSurface: number | undefined;
  imageMax: number | undefined;
  unit: Unit;
  error: boolean;
  className?: string;
  onChange: (fieldName: Field, value: number) => void;
  onError: (fieldName: Field, message: string | undefined) => void;
}

function DimensionField({
  field,
  Icon,
  valueMm,
  min,
  max,
  maxSurface,
  imageMax,
  unit,
  onChange,
  onError,
  error,
  className,
}: Props) {
  const initValue = useMemo(() => limitPrecision(convertMmToUnit(valueMm, unit), 2).toString(), [valueMm, unit]);

  const { t } = useTranslation();
  const [value, setValue] = useState(initValue);

  useEffect(() => setValue(initValue), [initValue]);

  const handleValidityCheck = (value: string) => {
    let val = convertUnitToMm(Number(value), unit);

    if (val < min) {
      val = limitPrecision(convertMmToUnit(min, unit), unit === 'inch' ? 2 : 1);
      setValue(val.toString());
      onError(field, t(`${field}-too-small`, { value: `${val} ${unit}` }));
    } else if (val > max) {
      val = limitPrecision(convertMmToUnit(max, unit), unit === 'inch' ? 2 : 0);
      setValue(val.toString());
      onError(
        field,
        t(`${field}-too-big-with-surface`, {
          value: `${val} ${unit}`,
          surface: ((maxSurface || 0) / 1000000).toString(),
        }),
      );
    } else if (imageMax !== undefined && val > imageMax) {
      val = limitPrecision(convertMmToUnit(imageMax, unit), unit === 'inch' ? 2 : 0);
      setValue(val.toString());
      onError(field, t(`${field}-image-too-big`, { value: `${val} ${unit}` }));
    } else {
      onError(field, undefined);
    }

    onChange(field, val);
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;

    if (
      !Number.isNaN(+inputValue) &&
      ((unit === 'cm' && inputValue.length <= 4) || (unit === 'inch' && inputValue.length <= 10))
    ) {
      setValue(inputValue);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleValidityCheck(e.currentTarget.value);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleValidityCheck(e.currentTarget.value);
  };

  const valueNum = parseFloat(value) || 0;
  return (
    <div
      className={cn(`cy-dimension-${field}`, styles.DimensionField, className, {
        [styles.error]: error,
      })}
    >
      <div className={styles.icon}>
        <Icon />
      </div>
      <input type="text" value={value} onChange={handleInputChange} onBlur={handleBlur} onKeyDown={handleKeyDown} />
      {unit === 'cm' && <div className={styles.meter}>{`${(valueNum / 100).toFixed(2)}m`}</div>}
    </div>
  );
}

export default React.memo(DimensionField);

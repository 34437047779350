import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'editor/src/component/Button';
import IconReupload from 'editor/src/component/Icon/IconReupload';
import { UploaderContext } from 'editor/src/component/Uploader/useUploader';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  imageId: string;
}

function ReuploadImageButton({ imageId }: Props) {
  const { t } = useTranslation();
  const uploader = useContext(UploaderContext);

  const onClick = () => {
    uploader.retryUpload(imageId);
  };

  return (
    <div className={styles.reuploadImageButtonWrap}>
      <WithTooltip overlay={t('editor-reupload-image')}>
        <Button primary className={styles.reuploadImageButton} noPadding onClick={onClick} stopPropagation>
          <IconReupload />
        </Button>
      </WithTooltip>
    </div>
  );
}

export default React.memo(ReuploadImageButton);

import cn from 'classnames';
import React from 'react';

import IconCheck from 'editor/src/component/Icon/IconCheck';

import styles from './index.module.scss';

interface Props {
  label?: string | React.ReactNode;
  disabled?: boolean;
  on: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  children?: React.ReactNode;
}

function Checkbox({ label, disabled, on, onChange, className, children }: Props) {
  const handleClick = () => {
    if (!disabled) {
      onChange(!on);
    }
  };

  return (
    <div
      className={cn(styles.checkbox, className, {
        [styles.disabled]: disabled,
        'cy-checkbox-selected': on,
      })}
      onClick={handleClick}
    >
      <IconCheck className={cn(styles.check, { [styles.on]: on })} />
      {label && <div className={styles.label}>{label}</div>}
      {children}
    </div>
  );
}

export default Checkbox;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import isAnyImageFailed from 'editor/src/store/gallery/selector/isAnyImageFailed';
import { useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import IconReupload from 'editor/src/component/Icon/IconReupload';
import { UploaderContext } from 'editor/src/component/Uploader/useUploader';

import styles from './index.module.scss';

function ButtonReuploadAll() {
  const { t } = useTranslation();
  const anyImageFailed = useSelector(isAnyImageFailed);
  const uploader = useContext(UploaderContext);

  if (!anyImageFailed) {
    return null;
  }

  const onClick = () => {
    uploader.retryAll();
  };

  return (
    <Button primary stopPropagation className={styles.buttonReuploadAll} onClick={onClick}>
      {t('editor-reupload-all')}
      <IconReupload className={styles.icon} />
    </Button>
  );
}

export default React.memo(ButtonReuploadAll);

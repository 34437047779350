import cn from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Button from 'editor/src/component/Button';
import AbstractPopup, { PopupType } from 'editor/src/component/Popup/AbstractPopup';
import { CONTAINER_ID } from 'editor/src/component/Popup/AbstractPopup/AbstractPopupContainer';
import { PopupName } from 'editor/src/component/Popup/popups';

import styles from './index.module.scss';

interface Props {
  onClose: () => void;
  onAccept: () => void;
}

function ResetEffectsModal({ onClose, onAccept }: Props) {
  const { t } = useTranslation();
  const container = document.getElementById(CONTAINER_ID);
  if (!container) {
    return null;
  }

  return ReactDOM.createPortal(
    <AbstractPopup
      className="cy-reset-effects-modal"
      onClickOutside={onClose}
      type={PopupType.INFO}
      title={t('reset-all-effects-modal-title')}
      buttons={
        <>
          <Button className={cn(styles.declineButton, 'cy-decline-button')} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button className={cn(styles.acceptButton, 'cy-accept-button')} onClick={onAccept} primary>
            {t('Reset all effects')}
          </Button>
        </>
      }
      name={PopupName.RESET_EFFECTS_POPUP}
    >
      {t('reset-all-effects-modal-description')}
    </AbstractPopup>,
    container,
  );
}

export default React.memo(ResetEffectsModal);

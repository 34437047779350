import React from 'react';

import { MockupPreviewStore } from 'editor/src/store/mockup/types';

import { EntryProps } from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/MockupList';
import MockupListEntry from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/MockupListEntry';

function StoreEntry({ entry, onSelect }: EntryProps<MockupPreviewStore>) {
  return <MockupListEntry imageUrl={entry.previewUrl} title={entry.name} onClick={() => onSelect(entry)} />;
}

export default React.memo(StoreEntry);

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import getCurrentDpiLevels from 'editor/src/store/design/selector/getCurrentDpiLevels';
import { getMediaImageDPI } from 'editor/src/store/design/selector/getMediaElementDpi';
import { MediaImage } from 'editor/src/store/design/types';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { getDpiLevel } from 'editor/src/store/editorModules/warnings/utils/getDpiWarning';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IndicatorBadge from 'editor/src/component/IndicatorBadge';
import {
  canShowGoodDPI,
  getDPIWarningLabel,
  getDPIWarningTooltip,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonWarnings/useElementWarnings';

import styles from './index.module.scss';

interface Props {
  image: MediaImage;
}

function DPIBadge({ image }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dpi, canShow } = useSelector((state) => {
    const galleryImage = getImageById(state, image.imageId);
    return {
      dpi: galleryImage ? getMediaImageDPI(image, galleryImage) : undefined,
      canShow: !!image.imageId && canShowGoodDPI(galleryImage),
    };
  }, shallowEqual);
  const limits = useSelector(getCurrentDpiLevels, shallowEqual);
  if (image.sample || !canShow || !limits) {
    return null;
  }

  const level = getDpiLevel(dpi, limits);
  const tooltip = getDPIWarningTooltip(dpi, level, t);
  const text = getDPIWarningLabel(dpi, t);

  const handleClick = () => {
    if (level) {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.WARNINGS));
    }
  };

  return (
    <div
      className={cn(styles.Badge, 'cy-dpi-badge', {
        [styles.noButton]: !level,
      })}
      onClick={handleClick}
    >
      <IndicatorBadge level={level} tooltip={tooltip} text={text} />
    </div>
  );
}

export default React.memo(DPIBadge);

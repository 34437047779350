import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import setPreviewStatusOperation from 'editor/src/store/editorModules/preview/operation/setPreviewStatusOperation';
import { PreviewStatus } from 'editor/src/store/editorModules/preview/types';
import { useDispatch } from 'editor/src/store/hooks';

import usePostMessage from 'editor/src/util/usePostMessage';

import Information from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/Information';
import StoreList from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/StoreList';

import styles from './index.module.scss';

function MockupPreview() {
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  const dispatch = useDispatch();

  usePostMessage('mockup.setPreview', ({ url }) => {
    setPreviewUrl(url);
  });

  const onLoad = useCallback(() => {
    dispatch(setPreviewStatusOperation(PreviewStatus.LOADED));
  }, []);

  return (
    <>
      <div className={styles.sidebar}>
        <StoreList mode="preview" listClassName="px-2" backClassName="px-2">
          <div className="px-2">
            <Information />
          </div>
        </StoreList>
      </div>
      <div className={cn(styles.content, 'p-1')}>
        {previewUrl && (
          <img onLoad={onLoad} draggable={false} className={styles.image} src={previewUrl} alt="mockup preview" />
        )}
      </div>
    </>
  );
}

export default React.memo(MockupPreview);

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextWrapping } from 'editor/src/store/design/types';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import getCurrentFontStylesProperty from 'editor/src/store/fonts/selector/getCurrentFontStylesProperty';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Checkbox from '../Checkbox';
import IconInfo from '../Icon/IconInfo';
import SwitchControl from '../SwitchControl';
import WithTooltip from '../WithTooltip';

function TextFittingControl() {
  const wrapping = useSelector((state) => getCurrentFontStylesProperty(state, 'wrapping')) ?? TextWrapping.Wrap;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onFitSwitch = useCallback(
    (on: boolean) => {
      dispatch(
        applyFontStylesToSelectionOperation({
          wrapping: on ? TextWrapping.Fit : undefined,
        }),
      );
    },
    [wrapping],
  );

  const onMultiLineSwitch = useCallback(
    (on: boolean) => {
      dispatch(
        applyFontStylesToSelectionOperation({
          wrapping: on ? TextWrapping.Fit : TextWrapping.FitOneLine,
        }),
      );
    },
    [wrapping],
  );

  const autoFitElt = useMemo(
    () => (
      <>
        {t('Autofit text')}
        <WithTooltip overlay={t('The font resizes automatically to fit the text box')}>
          <IconInfo size="small" className="ml-1" />
        </WithTooltip>
      </>
    ),
    [],
  );

  return (
    <>
      <SwitchControl
        title={autoFitElt}
        on={wrapping === TextWrapping.Fit || wrapping === TextWrapping.FitOneLine}
        onSwitch={onFitSwitch}
        className="mb-2"
      />
      <Checkbox
        disabled={wrapping === TextWrapping.Wrap}
        on={wrapping !== TextWrapping.FitOneLine}
        label="Allow multiline"
        onChange={onMultiLineSwitch}
      />
    </>
  );
}

export default React.memo(TextFittingControl);

import React from 'react';

import { PremiumLevel } from 'editor/src/store/plugins/types';

import IconElevateGold from 'editor/src/component/Icon/IconElevateGold';
import IconElevateSilver from 'editor/src/component/Icon/IconElevateSilver';
import IconPremiumGold from 'editor/src/component/Icon/IconPremiumGold';
import IconPremiumSilver from 'editor/src/component/Icon/IconPremiumSilver';

interface PluginIconProps {
  className?: string;
}

export type PluginIcon = React.FC<PluginIconProps>;

const getPluginIconByLevel = (premiumLevel: PremiumLevel | undefined): PluginIcon => {
  switch (premiumLevel) {
    case PremiumLevel.SILVER:
      return IconPremiumSilver;
    case PremiumLevel.GOLD:
      return IconPremiumGold;
    case PremiumLevel.SILVER_NEW:
      return IconElevateSilver;
    case PremiumLevel.GOLD_NEW:
    default:
      return IconElevateGold;
  }
};

export default getPluginIconByLevel;

import React from 'react';

import RGBA from 'editor/src/util/color/rgbaUtils';

import Slider from '../../Slider';

interface Props {
  rgba: RGBA;
  onOpacityChanged: () => void;
  onOpacityChanging: (opacity: number) => void;
  className: string;
  hideCursor?: boolean;
}

function getOpacityRailStyle(r: number, g: number, b: number): React.CSSProperties {
  return {
    height: 12,
    background:
      `linear-gradient(to right, rgba(${r}, ${g}, ${b}, 0), rgba(${r}, ${g}, ${b}, 1)) left top / auto,` +
      // eslint-disable-next-line max-len
      'conic-gradient(rgb(102, 102, 102) 0.25turn, rgb(153, 153, 153) 0.25turn, rgb(153, 153, 153) 0.5turn, rgb(102, 102, 102) 0.5turn, rgb(102, 102, 102) 0.75turn, rgb(153, 153, 153) 0.75turn) left top / 12px 12px repeat',
  };
}

function getOpacityHandleStyle(opacity: number, rgba: RGBA): React.CSSProperties {
  return {
    backgroundColor: `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${opacity})`,
    width: 20,
    height: 20,
  };
}

function OpacitySlider({ rgba, onOpacityChanged, onOpacityChanging, className, hideCursor }: Props) {
  return (
    <Slider
      value={rgba.a}
      min={0}
      max={1}
      step={0.01}
      onChange={onOpacityChanging}
      onAfterChange={onOpacityChanged}
      railStyle={getOpacityRailStyle(rgba.r, rgba.g, rgba.b)}
      hideTrack
      hideHandle={hideCursor}
      className={className}
      getHandleStyle={(opacity) => getOpacityHandleStyle(opacity, rgba)}
    />
  );
}

export default React.memo(OpacitySlider);

import type { Thunk } from 'editor/src/store/hooks';
import { setMockupDownloadingAction } from 'editor/src/store/mockup/slice';

import toastController from 'editor/src/util/toastController';

const onMockupDownloadFailedOperation =
  (): Thunk =>
  (dispatch, _, { i18n }) => {
    dispatch(setMockupDownloadingAction(false));
    toastController.errorPersist(
      i18n.t('Download Failed'),
      i18n.t('We were unable to generate the mockup. Please try again later or contact our support.'),
    );
  };

export default onMockupDownloadFailedOperation;

import { RootState } from 'editor/src/store/index';

import countElementsByTypeInLayoutSchema from 'editor/src/util/autofill/countElementsByTypeInLayoutSchema';

import getLayoutSchemas from './getLayoutSchemas';

const getLayoutSchemasBySlotNumbers = (state: RootState, imageSlots: number[], noTextSlots = false) => {
  const layouts = getLayoutSchemas(state);

  // TODO deal with tags later
  return layouts.filter(
    (schema) =>
      (!schema.tags || schema.tags.includes('*')) &&
      imageSlots.indexOf(countElementsByTypeInLayoutSchema(schema)) >= 0 &&
      (!noTextSlots || countElementsByTypeInLayoutSchema(schema, 'text') === 0),
  );
};

export default getLayoutSchemasBySlotNumbers;

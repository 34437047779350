import cn from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getFirstGridElement from 'editor/src/store/design/selector/getFirstGridElement';
import { addOrUpdateCalendarEventsAction } from 'editor/src/store/design/slice';
import { CalendarEvent } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import { getYearDatesFrame } from 'editor/src/util/dates/dateUtils';
import createCalendarEventId from 'editor/src/util/reflectDesignData/createCalendarEventId';

import Button from 'editor/src/component/Button';
import useOverlay from 'editor/src/component/ConditionGroupBuilder/Overlay/useOverlay';
import DatePicker from 'editor/src/component/DatePicker';
import IconCross from 'editor/src/component/Icon/IconCross';
import Input from 'editor/src/component/Input';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';
import useMobileMenu from 'editor/src/component/useMobileMenu';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-datepicker/dist/react-datepicker.css';

import styles from './index.module.scss';

type Props = {
  closeForm: () => void;
  event?: CalendarEvent;
  elementRef?: React.RefObject<HTMLDivElement>;
};

const minEventNameLength = 2;
const maxEventNameLength = 30;

function AddEditEventForm({ closeForm, event, elementRef }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const gridElement = useSelector(getFirstGridElement);
  const addEditFormOverlay = useOverlay();
  const addEditFormMobileOverlay = useMobileMenu(true);
  const [error, setError] = useState('');
  const [newEventName, setNewEventName] = useState('');
  const [newEventDate, setNewEventDate] = useState<Date | null>(null);
  const { startDate: minDate, endDate: maxDate } = getYearDatesFrame(gridElement?.grid.gridDate);

  useEffect(() => {
    if (!elementRef) {
      return;
    }
    const rect = elementRef?.current?.getBoundingClientRect();
    isMobile ? addEditFormMobileOverlay.open() : addEditFormOverlay.open(rect);

    setNewEventName(event?.label || '');
    setNewEventDate(event ? new Date(event.year, event.month, event.day) : null);
  }, [elementRef, event]);

  const changeEventNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    checkIfEventNameValid(newValue);
    setNewEventName(newValue);
  };

  const checkIfEventNameValid = (eventName: string) => {
    if (eventName.length < minEventNameLength || eventName.length > maxEventNameLength) {
      setError(t('calendar-event-name-length', { min: minEventNameLength, max: maxEventNameLength }));
      return false;
    }
    error && setError('');
    return true;
  };

  const onAddNewEventClick = () => {
    if (!newEventName || !newEventDate || !checkIfEventNameValid(newEventName)) {
      return;
    }

    const newCalendarEvent = {
      id: event?.id || createCalendarEventId(isEmbedded, !isEmbedded),
      day: newEventDate.getDate(),
      month: newEventDate.getMonth(),
      year: newEventDate.getFullYear(),
      label: newEventName,
      checked: event?.checked || true,
      custom: isEmbedded,
      merchant: !isEmbedded,
    };
    dispatch(addOrUpdateCalendarEventsAction([newCalendarEvent]));
    setNewEventName('');
    setNewEventDate(null);
    closeEditForm();
  };

  const closeEditForm = () => {
    isMobile ? addEditFormMobileOverlay.close() : addEditFormOverlay.close();
    closeForm();
  };

  const addEditEventFormContent = (
    <div className={cn('cy-add-edit-custom-event-form', styles.addEditEventForm)}>
      {isMobile && (
        <div className={styles.closeEditMode}>
          <h4>{t(event ? 'Edit event' : 'Add event')}</h4>
          <Button tertiary onClick={closeEditForm} className={styles.closeEditFormBtn}>
            <IconCross />
          </Button>
        </div>
      )}
      <Input placeholder={t('Event name')} value={newEventName} onChange={changeEventNameHandler} type="string" />
      <DatePicker
        placeholderText={t('Event date')}
        selected={newEventDate}
        minDate={minDate}
        maxDate={maxDate}
        openToDate={minDate}
        onSelect={(date) => setNewEventDate(date)}
      />
      <div className={styles.error}>{error}</div>
      <div className={styles.addEditEventFormActionBtns}>
        <Button secondary rounded fullWidth stopPropagation onClick={closeEditForm}>
          {t('Cancel')}
        </Button>
        <Button
          primary
          rounded
          fullWidth
          stopPropagation
          onClick={onAddNewEventClick}
          disabled={!newEventName || !newEventDate || Boolean(error)}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );

  return isMobile
    ? addEditFormMobileOverlay.render(addEditEventFormContent)
    : addEditFormOverlay.render(t(event ? 'Edit event' : 'Add event'), addEditEventFormContent, true);
}

export default React.memo(AddEditEventForm);

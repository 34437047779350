import getSpread from 'editor/src/store/design/selector/getSpread';
import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { Rect } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';

function getFocusBox(state: RootState, spreadIndex: number): Rect {
  const { focusedContentAddress } = state.editor;
  let x: number;
  let y: number;
  let width: number;
  let height: number;

  const spread = getSpread(state, spreadIndex);
  if (focusedContentAddress) {
    const page = spread?.pages[focusedContentAddress.pageIndex];
    const content = page?.groups.content?.[focusedContentAddress.contentIndex];
    x = (page?.x || 0) + (content?.x || 0);
    y = (page?.y || 0) + (content?.y || 0);
    width = content?.width || 0;
    height = content?.height || 0;
  } else {
    x = 0;
    y = 0;
    width = spread ? getSpreadWidthFromSpread(spread) : 0;
    height = spread ? getSpreadHeightFromSpread(spread) : 0;
  }

  return {
    x,
    y,
    width,
    height,
  };
}

export default getFocusBox;

import { setFontListAction } from 'editor/src/store/fonts/slice';
import fontMetadataToFontDefinition from 'editor/src/store/fonts/utils/fontMetadataToFontDefinition';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import type { FontMetaData } from '@gelatoas/design-editor-fonts';

const setFontListOperation = (fonts: FontMetaData[]) => (dispatch: ThunkDispatch) => {
  dispatch(setFontListAction(fonts.map(fontMetadataToFontDefinition)));
};

export default setFontListOperation;

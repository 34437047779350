import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useMultiValues from 'editor/src/store/variants/selector/useMultiValues';
import { ProductControlOptionSize } from 'editor/src/store/variants/types';

import MultiSelectProductControl from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/multi/MultiSelectProductControl';
import useFilteredOptionsWithVariations from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/multi/useFilteredOptionsWithVariations';
import { MultiProductControlProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';

import groupOptionsByUnit from './groupOptionsByUnit';

function MultiControlSize({
  toggleOption,
  multiMode,
  control,
  selectAll,
  deselectAll,
  element,
  variations,
  multiOptions,
  singleOptions,
  noTopBar,
  IconElement,
  availableOptions,
  disabled,
  disabledControlDescription,
}: MultiProductControlProps) {
  const { selectedOptions, totalCount } = useMultiValues(control, multiOptions, singleOptions, !multiMode);
  const { t } = useTranslation();

  const options = useFilteredOptionsWithVariations(control, variations, singleOptions, disabledControlDescription);
  const { imperial, metric } = useMemo(() => groupOptionsByUnit(options as ProductControlOptionSize[]), [options]);

  const commonProps = {
    control,
    multiMode,
    availableOptions,
    toggleOption,
    selectAll,
    deselectAll,
    selectedOptions,
    selectedCount: totalCount,
    Element: element,
    noTopBar,
    disabled,
  };

  return (
    <>
      {imperial.length > 0 && (
        <MultiSelectProductControl
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...commonProps}
          title={t('edito-format-size-imperial-field-label') /* txt_format_size_imperial_field_label */}
          options={imperial}
          IconElement={IconElement}
          allowSelectAll
        />
      )}
      {metric.length > 0 && (
        <MultiSelectProductControl
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...commonProps}
          title={t('edito-format-size-metric-field-label') /* txt_format_size_metric_field_label */}
          options={metric}
          IconElement={IconElement}
          allowSelectAll
        />
      )}
    </>
  );
}

export default React.memo(MultiControlSize);

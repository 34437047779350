/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconMoveUp({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 14.8824C11.5751 14.8824 11.2308 14.5137 11.2308 14.0589V7.8117L9.28034 9.89978C8.97994 10.2214 8.49289 10.2214 8.19248 9.89978C7.89208 9.57818 7.89208 9.05675 8.19248 8.73514L11.4561 5.24121C11.7565 4.9196 12.2435 4.9196 12.5439 5.24121L15.8075 8.73514C16.1079 9.05675 16.1079 9.57818 15.8075 9.89979C15.5071 10.2214 15.02 10.2214 14.7196 9.89978L12.7692 7.8117V14.0589C12.7692 14.5137 12.4248 14.8824 12 14.8824Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2599 18.9516C12.0919 19.0161 11.9081 19.0161 11.7401 18.9516L2.50934 15.4041C2.2038 15.2866 2 14.9765 2 14.629C2 14.2814 2.2038 13.9713 2.50934 13.8539L8.27857 11.6367C8.67842 11.483 9.11892 11.7055 9.26246 12.1335C9.40599 12.5616 9.19821 13.0332 8.79836 13.1869L5.04597 14.629L12 17.3015L18.954 14.629L15.2016 13.1869C14.8018 13.0332 14.594 12.5616 14.7375 12.1335C14.8811 11.7055 15.3216 11.483 15.7214 11.6367L21.4907 13.8539C21.7962 13.9713 22 14.2814 22 14.629C22 14.9765 21.7962 15.2866 21.4907 15.4041L12.2599 18.9516Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconMoveUp;

import activatePluginTabOperation from 'editor/src/store/editorModules/sidebar/operation/activatePluginTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPluginIconByLevel from 'editor/src/store/plugins/selector/getPluginIconByLevel';
import { Plugin, PluginState } from 'editor/src/store/plugins/types';

import { MenuItemProps } from 'editor/src/component/Menu/type';

type Params = Pick<MenuItemProps, 'close' | 'isMobile'> & {
  plugin: Plugin | undefined;
  tabName: TAB_NAMES;
  onActivatePlugin?: () => void;
};

function usePluginButton({ close, plugin, tabName, isMobile, onActivatePlugin }: Params) {
  const dispatch = useDispatch();
  const isActive = useSelector((state) => isSidebarTabActive(state, tabName));

  const activatePluginTab = () => {
    plugin && dispatch(activatePluginTabOperation(plugin.name, tabName));
    onActivatePlugin?.();

    if (!isMobile) {
      close?.();
    }
  };

  return {
    isActive,
    activatePluginTab,
    premiumEnabled: plugin?.state === PluginState.NON_FUNCTIONAL || plugin?.state === PluginState.ENABLED,
    PremiumIcon: getPluginIconByLevel(plugin?.extra?.premiumLevel),
  };
}

export default usePluginButton;

import { PayloadAction } from '@reduxjs/toolkit';

import { DataState } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const setSearchValueReducer = (state: DataState, action: PayloadAction<string>) => {
  if (state.results) {
    state.results.search.value = action.payload;
  }
};

export default setSearchValueReducer;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store';
import getSearchValue from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getSearchValue';

import styles from './index.module.scss';

function NoResults() {
  const searchValue = useSelector(getSearchValue);
  const { t } = useTranslation();

  return (
    <div className={searchValue ? 'cy-no-result-for-search' : 'cy-no-result-for-filters'}>
      <h2 className={cn(styles.heading, 'my-2')}>
        {searchValue ? t('getty-no-result-for-search', { value: searchValue }) : t('getty-no-result-for-filters')}
      </h2>
      <h3 className={cn(styles.subheading, 'm-0')}>
        {searchValue ? t('getty-no-result-hint-for-search') : t('getty-no-result-hint-for-filters')}
      </h3>
    </div>
  );
}

export default NoResults;

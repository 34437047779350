import React from 'react';
import { useTranslation } from 'react-i18next';

import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import getCurrentFontStylesProperty from 'editor/src/store/fonts/selector/getCurrentFontStylesProperty';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import ColorPicker from 'editor/src/component/ColorPicker';
import ButtonBackToTab from 'editor/src/component/DesktopSidebar/TabContents/Buttons/ButtonBackToTab';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';

const DEFAULT_COLOR = '#FF0000';

function TextOutlineColorPickerTabContent() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const stroke = useSelector((state) => getCurrentFontStylesProperty(state, 'stroke'));
  const colorPickerConfig = useSelector((state) => getHostSetting(state, 'colorPickerConfig'));

  return (
    <>
      <TabContentHeader
        title={t('editor-text-outline-color')}
        icon={<ButtonBackToTab mode="icon" tabName={TAB_NAMES.EFFECTS} />}
      />
      <ContentBlock scroll>
        <ColorPicker
          color={stroke.color || DEFAULT_COLOR}
          onColorChange={(color) =>
            color &&
            dispatch(
              applyFontStylesToSelectionOperation({
                stroke: { ...stroke, color },
              }),
            )
          }
          allowOpacity={colorPickerConfig.enableOpacity}
          colors={colorPickerConfig.allowedColors}
        />
      </ContentBlock>
    </>
  );
}

export default React.memo(TextOutlineColorPickerTabContent);

import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  imageUrl: string;
  title: string;
  handleTransformation: () => void;
}

function FittingItem({ imageUrl, title, handleTransformation }: Props) {
  return (
    <div className={styles.fittingItem}>
      <div className={cn(styles.border, 'cy-fitting-image')} onClick={handleTransformation}>
        <div className={styles.imagePlaceholder}>
          <img className={styles.image} crossOrigin="anonymous" src={imageUrl} alt="fitting" />
        </div>
        <span className={styles.label}>{title}</span>
      </div>
    </div>
  );
}

export default FittingItem;

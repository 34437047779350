import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { setAssetProviderDataOnElementAction } from 'editor/src/store/design/slice';
import { Dimensions, MediaImage } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { AsyncThunk } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginState } from 'editor/src/store/plugins/types';

import loadImageDimensions from 'editor/src/util/loadImageDimensions';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import toastController from 'editor/src/util/toastController';

import applyImageToElementOperation from './applyImageToElementOperation';
import uploadImageAssetOperation from './uploadImageAssetOperation';

const updateImageWithAssetOperation =
  (
    uuid: number,
    assetId: string,
    pluginName: string,
    assetThumbnail: string,
    dimensions?: { width: number; height: number },
    saveUndoRedoStep = false,
  ): AsyncThunk =>
  async (dispatch, getStore, { i18n }) => {
    const state = getStore();
    const address = getStructureIndexByElementUuid(state.design.designData, uuid);

    if (!address) {
      return;
    }
    const element = getMediaElement<MediaImage>(state, address);
    if (!element) {
      return;
    }

    if (getPlugin(state, pluginName)?.state !== PluginState.ENABLED) {
      return;
    }

    let imageDimensions: Dimensions;
    try {
      imageDimensions =
        dimensions ||
        (await loadImageDimensions(assetThumbnail, undefined, {
          executor: 'updateImageWithAssetOperation',
          assetId,
          pluginName,
        }));
    } catch {
      toastController.warningPersist(
        i18n.t('editor-image-loading-failed'),
        i18n.t('editor-image-loading-failed-message'),
      );
      return;
    }

    if (saveUndoRedoStep) {
      dispatch(saveUndoRedoStateOperation('Replace image in frame'));
    }

    batch(() => {
      dispatch(uploadImageAssetOperation(element, assetId, pluginName, assetThumbnail, imageDimensions, !!dimensions));
      dispatch(applyImageToElementOperation(element, assetId, imageDimensions));
      dispatch(setAssetProviderDataOnElementAction({ address, pluginName, assetId }));
      sendPostMessage('log.imageAdded', element);
    });
  };

export default updateImageWithAssetOperation;

import cn from 'classnames';
import React, { useRef, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import updateImageIdByUuidOperation from 'editor/src/store/design/operation/updateImageIdByUuidOperation';
import getImageElementUrl from 'editor/src/store/design/selector/getImageElementUrl';
import { MediaImage, MediaAddon, StructureIndex } from 'editor/src/store/design/types';
import { GalleryImage, ImageState } from 'editor/src/store/gallery/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import useDragHandle from 'editor/src/component/ConditionGroupBuilder/ConditionElements/ReorganizableList/useDragHandle';
import useReorganizableListUpdate from 'editor/src/component/ConditionGroupBuilder/ConditionElements/ReorganizableList/useReorganizableListUpdate';
import useOverlay from 'editor/src/component/ConditionGroupBuilder/Overlay/useOverlay';
import ElementName from 'editor/src/component/PersonalizationContent/PersonalizationElements/ElementName';
import useFocusElementOnFlag from 'editor/src/component/PersonalizationContent/PersonalizationElements/useFocusElementOnFlag';
import { UploaderContext } from 'editor/src/component/Uploader/useUploader';

import styles from './index.module.scss';

interface Props {
  element: MediaImage | MediaAddon;
  address: StructureIndex;
  isSelected: boolean;
  isBuilding?: boolean;
  className?: string;
}

function PersonalizationSimpleImage({ element, address, isSelected, isBuilding, className }: Props) {
  const { imageUrl, crossOrigin } = useSelector((state) => getImageElementUrl(state, element), shallowEqual);
  const lastImageAdded = useSelector((state) => state.gallery.images[0] as GalleryImage | undefined);
  const [isSelectingImage, setIsSelectingImage] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const dispatch = useDispatch();
  const divRef = useRef<HTMLDivElement>(null);
  const uploader = useContext(UploaderContext);
  const { t } = useTranslation();

  const dragHandle = useDragHandle();

  useEffect(() => {
    if (!isSelectingImage || !lastImageAdded) {
      return;
    }

    const selectedImageUuid = element.uuid;

    if (selectedImageUuid) {
      dispatch(updateImageIdByUuidOperation(selectedImageUuid, lastImageAdded.id, true));
    }

    setIsLoadingImage(lastImageAdded.state !== ImageState.UPLOADED);
    if (lastImageAdded.state === ImageState.UPLOADED) {
      setIsSelectingImage(false);
    }
  }, [lastImageAdded?.id, lastImageAdded?.state]);

  const updateList = useReorganizableListUpdate();
  useEffect(() => {
    updateList();
  }, [element.name]);

  const imageEditorOverlay = useOverlay();
  const active = imageEditorOverlay.visible || isSelected;
  useFocusElementOnFlag(active, divRef);

  if (element.personalizationLocked) {
    return null;
  }

  function uploadImage() {
    setIsSelectingImage(true);
    uploader.open(() => {
      if (!uploader.isUploading()) {
        setIsSelectingImage(false);
      }
    });
  }

  return (
    <div
      ref={divRef}
      className={cn(className, styles.PersonalizationSimpleImage, 'cy-personalization-image', {
        [styles.building]: isBuilding,
        [styles.active]: active,
        [styles.static]: !isBuilding,
      })}
      onMouseDown={dragHandle.onMouseDown}
    >
      <ElementName element={element} isBuilding={isBuilding} address={address} />
      <div
        className={cn(styles.imageContainer, {
          [styles.isBuilding]: isBuilding,
        })}
      >
        <img
          className={cn(styles.imgPreviewStyle)}
          key={(imageUrl || '') + (crossOrigin || '')} // needed to avoid some CORS issue with safari
          crossOrigin={crossOrigin}
          draggable={false}
          src={imageUrl}
          alt={element.name}
        />
        <Button
          transparent
          className={cn(styles.uploadBtn, 'cy-image-upload-btn')}
          onClick={uploadImage}
          disabled={isLoadingImage}
          showLoader={isLoadingImage}
        >
          {t('Upload')}
        </Button>
      </div>
    </div>
  );
}

export default React.memo(PersonalizationSimpleImage);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setActivePreviewAction, setPreviewStatusAction } from 'editor/src/store/editorModules/preview/slice';
import { PreviewStatus, PreviewType } from 'editor/src/store/editorModules/preview/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const setActivePreviewOperation = (previewType: PreviewType | undefined) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(setActivePreviewAction(previewType));
    if (previewType) {
      dispatch(setPreviewStatusAction(PreviewStatus.LOADING));
    }
  });
};

export default setActivePreviewOperation;

import React from 'react';

import ButtonAcceptModifiedImage from 'editor/src/component/TopMenuDesktop/buttons/ButtonAcceptModifiedImage';
import ButtonDeclineModifiedImage from 'editor/src/component/TopMenuDesktop/buttons/ButtonDeclineModifiedImage';

function ImageManipulationControls() {
  return (
    <div className="row-flex">
      <ButtonAcceptModifiedImage mode="label-icon" isMobile />
      <ButtonDeclineModifiedImage mode="label-icon" />
    </div>
  );
}

export default React.memo(ImageManipulationControls);

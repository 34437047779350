import React from 'react';

import { setActiveTopMenuDropdownAction } from 'editor/src/store/app/slice';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import stopCropModeOperation from 'editor/src/store/editor/operation/stopCropModeOperation';
import { setZoomModeAction as setZoomModeOperation } from 'editor/src/store/editor/slice';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { useDispatch } from 'editor/src/store/hooks';

import IconTick from 'editor/src/component/Icon/IconTick';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = () => true;

type Props = Pick<MenuItemProps, 'mode' | 'isMobile'>;

function ButtonCropDone({ mode, isMobile }: Props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    batch(() => {
      dispatch(stopCropModeOperation());
      if (isMobile) {
        dispatch(setSidebarActiveTabOperation(-1));
      } else {
        dispatch(setActiveTopMenuDropdownAction());
      }
      dispatch(setZoomModeOperation(false));
    });
  };

  return (
    <MenuButton
      Icon={IconTick}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-crop-done"
      className="cy-button-crop-done"
    />
  );
}

export default React.memo(ButtonCropDone);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { removeConditionFromConditionAction } from 'editor/src/store/design/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import updateHiddenElementsOperation from './updateHiddenElementsOperation';

const removeConditionFromConditionOperation =
  (spreadIndex: number, conditionId: string, parent: { conditionId: string; optionId: string }) =>
  (dispatch: ThunkDispatch) => {
    batch(() => {
      dispatch(
        removeConditionFromConditionAction({
          spreadIndex,
          conditionId,
          parent,
        }),
      );
      dispatch(updateHiddenElementsOperation());
    });
  };

export default removeConditionFromConditionOperation;

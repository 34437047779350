import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import getSelectedElementUuids from 'editor/src/store/editor/selector/getSelectedElementUuids';
import isWallpaperEmptyScreenVisible from 'editor/src/store/editor/selector/isWallpaperEmptyScreenVisible';

import useShowPerspectiveLinesControl from 'editor/src/util/useShowPerspectiveLinesControl';

import Button from 'editor/src/component/Button';
import IconPerspectiveLines from 'editor/src/component/Icon/IconPerspectiveLines';
import { CanShow } from 'editor/src/component/Menu/type';

import styles from './RulerRollsButton.module.scss';

export const canShow: CanShow = (state) => {
  const { allowShowPerspectiveLines } = state.hostSettings;
  const dimensionsExist = !!state.design.designData?.dimensions;
  const showedWallpaperEmptyScreen = isWallpaperEmptyScreenVisible(state);
  const hasSelectedElement = getSelectedElementUuids(state).length > 0;

  return allowShowPerspectiveLines && dimensionsExist && !showedWallpaperEmptyScreen && !hasSelectedElement;
};

function ButtonPerspectiveLines() {
  const { t } = useTranslation();
  const { showPerspectiveLines, showControl, togglePerspectiveLines } = useShowPerspectiveLinesControl();

  if (!showControl) {
    return null;
  }

  return (
    <Button
      onClick={togglePerspectiveLines}
      className={cn('px-2', styles.Button, 'cy-button-perspective-lines', {
        [styles.active]: showPerspectiveLines,
      })}
    >
      <IconPerspectiveLines />
      <span className="ml-2">{t('editor-perspective-lines')}</span>
    </Button>
  );
}

export default React.memo(ButtonPerspectiveLines);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconLockLocked({ className }: Props) {
  return (
    <Icon className={className}>
      <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
        <g stroke="#333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <path d="m10 10c0-3.31371 2.6863-6 6-6 3.3137 0 6 2.68629 6 6v4h-12z" />
          <rect fill="#333" height="14" rx="2" width="20" x="6" y="14" />
        </g>
      </svg>
    </Icon>
  );
}

export default IconLockLocked;

import cn from 'classnames';
import React from 'react';

import { MediaRectangle } from 'editor/src/store/design/types';

import IconLayersRectangle from 'editor/src/component/Icon/IconLayersRectangle';

import abstractStyles from './AbstractLayerItem/index.module.scss';

interface Props {
  element: MediaRectangle;
}

function RectangleLayerItem({ element }: Props) {
  return (
    <>
      <div className={abstractStyles.thumb}>
        <IconLayersRectangle />
      </div>
      <div className={cn(abstractStyles.text, 'cy-layer-text')}>{`Rectangle ${element.uuid}`}</div>
    </>
  );
}

export default React.memo(RectangleLayerItem);

import { useEffect, useMemo, useRef } from 'react';
import { shallowEqual } from 'react-redux';

import getCurrentSpreadGroup from 'editor/src/store/design/selector/getCurrentSpreadGroup';
import getSpread from 'editor/src/store/design/selector/getSpread';
import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { DraggableItem } from 'editor/src/store/editor/types';
import { useSelector, useStore } from 'editor/src/store/hooks';
import { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';

import canvasService from 'editor/src/service/canvasService';

import useCanvasDimensions from 'editor/src/component/EditorArea//useCanvasDimensions';
import useCreateFabricCanvas from 'editor/src/component/EditorArea/useCreateFabricCanvas';
import useSetupCanvasDisplay from 'editor/src/component/EditorArea/useSetupCanvasDisplay';
import useSetupFabricUtils from 'editor/src/component/EditorArea/useSetupFabricUtils';

import { DROP_EVENT } from './dnd/useDrag';
import getGroupedSpreadDimensions from './getGroupedSpreadDimensions';
import getGroupedSpreadsInfo from './getGroupedSpreadsInfo';

import type { fabric } from 'fabric';

export const CANVAS_ID = 'canvas-editor';

const useCommonCanvasUtils = (isMobile: boolean, autoZoom: boolean) => {
  const canvasDiv = useRef<HTMLDivElement>(null);
  const fabricCanvas = useCreateFabricCanvas(CANVAS_ID);
  useCanvasDimensions(canvasDiv, fabricCanvas, isMobile);
  const store = useStore();

  useEffect(() => {
    if (!canvasDiv.current || !fabricCanvas) {
      return undefined;
    }

    let mouseMoveEvent: fabric.IEvent | undefined;
    function onMouseMove(e: fabric.IEvent) {
      mouseMoveEvent = e;
    }

    function onDrop(e: Event) {
      e.preventDefault();
      if (mouseMoveEvent?.target) {
        mouseMoveEvent.target.fire('drop', {
          x: mouseMoveEvent.absolutePointer?.x ?? mouseMoveEvent.pointer?.x ?? 0,
          y: mouseMoveEvent.absolutePointer?.y ?? mouseMoveEvent.pointer?.y ?? 0,
          draggableItem: (e as CustomEvent<DraggableItem>).detail,
        });
      }
    }

    fabricCanvas.on('mouse:move', onMouseMove);
    canvasDiv.current.addEventListener(DROP_EVENT, onDrop);

    return () => {
      fabricCanvas.off('mouse:move', onMouseMove);
      canvasDiv.current?.removeEventListener(DROP_EVENT, onDrop);
    };
  }, [canvasDiv.current, fabricCanvas]);

  const { focusedContentAddress, spreadIndex, canvasWidth, canvasHeight, currentSpreadGroup, designKey, hasDesign } =
    useSelector((state) => {
      const currentSpreadIndex = getCurrentSpreadIndex(state);
      return {
        focusedContentAddress: state.editor.focusedContentAddress,
        spreadIndex: currentSpreadIndex,
        canvasWidth: state.canvas.width,
        canvasHeight: state.canvas.height,
        currentSpreadGroup: getCurrentSpreadGroup(state),
        designKey: state.design.designData ? getDesignKeyFromDesign(state.design.designData) : '',
        hasDesign: !!state.design.designData?.spreads,
      };
    }, shallowEqual);

  const spreadKey = `${designKey}-${spreadIndex}`;

  const { spreadHeight, spreadWidth, spotFinishingType } = useMemo(() => {
    const spread = getSpread(store.getState(), spreadIndex);
    const spreadWidth = spread ? getSpreadWidthFromSpread(spread) : 0;
    const spreadHeight = spread ? getSpreadHeightFromSpread(spread) : 0;
    return {
      spreadWidth,
      spreadHeight,
      spotFinishingType: spread?.spot_finishing_type,
    };
  }, [spreadKey, spreadIndex]);

  const fabricUtils = useSetupFabricUtils(isMobile, canvasWidth, canvasHeight, spreadWidth, spreadHeight);

  useEffect(() => {
    if (fabricCanvas && fabricUtils.setup) {
      canvasService.setup(fabricCanvas, fabricUtils.mm2px);
    }
  }, [fabricCanvas, fabricUtils]);

  const dimensions = useMemo(() => ({ width: canvasWidth, height: canvasHeight }), [canvasWidth, canvasHeight]);

  const spreadsInfo = useMemo(
    () =>
      getGroupedSpreadsInfo(fabricUtils.mm2px, spreadWidth, spreadHeight, dimensions, spreadIndex, currentSpreadGroup),
    [fabricUtils.mm2px, spreadWidth, spreadHeight, dimensions, spreadIndex, currentSpreadGroup],
  );

  const spreadDimensions = getGroupedSpreadDimensions(spreadWidth, spreadHeight, currentSpreadGroup);
  const { spreadCoords } = spreadsInfo[spreadsInfo.length - 1];
  const displayInfo =
    hasDesign && spreadDimensions.spreadWidth && spreadDimensions.spreadHeight
      ? {
          spreadCoords,
          spreadIndex: spreadsInfo[0].spreadIndex,
          spreadWidth: spreadDimensions.spreadWidth,
          spreadHeight: spreadDimensions.spreadHeight,
          spotFinishingType,
        }
      : undefined;

  const canvasRotation = useSetupCanvasDisplay(
    fabricCanvas,
    dimensions,
    designKey,
    spreadIndex,
    spreadCoords,
    focusedContentAddress,
    fabricUtils.mm2px,
    fabricUtils.setup,
    spreadDimensions.spreadWidth,
    spreadDimensions.spreadHeight,
    autoZoom,
  );

  return {
    canvasDiv,
    fabricCanvas,
    canvasRotation,
    fabricUtils,
    dimensions,
    spreadsInfo,
    displayInfo,
  };
};

export default useCommonCanvasUtils;

import React, { useEffect } from 'react';

import getDesignDataTouched from 'editor/src/store/design/selector/getDesignDataTouched';
import { markDesignDataAsTouchedAction as markDesignDataAsTouchedOperation } from 'editor/src/store/design/slice';
import { Coords } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { CanvasRotation } from '../types';

import EmptyContentPlaceholder from './EmptyContentPlaceholder';

interface Props {
  coords: Coords;
  spreadIndex: number;
  canvasRotation: CanvasRotation;
}

const CONTENT_NAME_TO_IGNORE = new Set(['Content Sides', 'Content Side']); // for canvas and phonecase

function EmptyContentPlaceholderWrapper({ spreadIndex, coords, canvasRotation }: Props) {
  const isSpreadEmpty = useSelector(
    (state) => !state.design.designData?.spreads[spreadIndex]?.pages[0]?.groups.media?.length,
  );
  const content = useSelector((state) => state.design.designData?.spreads[spreadIndex]?.pages[0]?.groups.content);
  const focusedContentAddress = useSelector((state) => state.editor.focusedContentAddress);
  const focusedContent =
    focusedContentAddress && focusedContentAddress.pageIndex === 0
      ? content?.[focusedContentAddress.contentIndex]
      : content?.find((content) => content.type !== 'sample' && !CONTENT_NAME_TO_IGNORE.has(content.name));

  const dispatch = useDispatch();
  const designDataTouched = useSelector(getDesignDataTouched);

  useEffect(() => {
    if (!designDataTouched && !isSpreadEmpty) {
      dispatch(markDesignDataAsTouchedOperation());
    }
  }, [isSpreadEmpty, designDataTouched]);

  if (!isSpreadEmpty || designDataTouched || !focusedContent) {
    return null;
  }

  return (
    <EmptyContentPlaceholder
      content={focusedContent}
      spreadIndex={spreadIndex}
      spreadCoords={coords}
      canvasRotation={canvasRotation}
    />
  );
}

export default React.memo(EmptyContentPlaceholderWrapper);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';

import { ThunkDispatch } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/index';
import getActiveFilters from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getActiveFilters';
import { setActiveFiltersAction } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/slice';
import { ContentPluginRootState } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const setActiveFilterControlOperation =
  (key: string, value: string | string[] | undefined) =>
  (dispatch: ThunkDispatch, getState: () => ContentPluginRootState) => {
    const state = getState();
    const activeFilters = getActiveFilters(state);

    batch(() => {
      dispatch(setActiveFiltersAction({ ...activeFilters, [key]: value }));
    });
  };

export default setActiveFilterControlOperation;

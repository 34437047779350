import React from 'react';

import { StructureIndex } from 'editor/src/store/design/types';

import IconAlignRight from 'editor/src/component/Icon/IconAlignRight';
import AlignActionButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignActionButton';

interface Props {
  structureIndexes: StructureIndex[];
}

function AlignRightButton({ structureIndexes }: Props) {
  return (
    <AlignActionButton structureIndexes={structureIndexes} icon={<IconAlignRight />} alignTo="right" keepTopDropdown />
  );
}

export default React.memo(AlignRightButton);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconLockUnlocked({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3C9.15266 3 6.84445 5.08668 6.84445 7.66073V7.98215C6.84445 8.42595 7.24241 8.78573 7.73333 8.78573C8.22425 8.78573 8.62222 8.42595 8.62222 7.98215V7.66073C8.62222 5.97428 10.1345 4.60715 12 4.60715C13.8655 4.60715 15.3778 5.97428 15.3778 7.66073V10.3928H6.66667C5.19391 10.3928 4 11.4721 4 12.8036V18.5893C4 19.9207 5.19391 21 6.66667 21H17.3333C18.8061 21 20 19.9207 20 18.5893V12.8036C20 11.4721 18.8061 10.3928 17.3333 10.3928H17.1556V7.66073C17.1556 5.08668 14.8473 3 12 3ZM16.2578 12C16.2608 12 16.2637 12 16.2667 12C16.2696 12 16.2726 12 16.2755 12H17.3333C17.8243 12 18.2222 12.3598 18.2222 12.8036V18.5893C18.2222 19.0331 17.8243 19.3929 17.3333 19.3929H6.66667C6.17575 19.3929 5.77778 19.0331 5.77778 18.5893V12.8036C5.77778 12.3598 6.17575 12 6.66667 12H16.2578Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconLockUnlocked;

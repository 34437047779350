import React from 'react';

import { StructureIndex } from 'editor/src/store/design/types';

import IconMoveBottom from 'editor/src/component/Icon/IconMoveBottom';
import DistributeActionButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/DistributeActionButton';

interface Props {
  structureIndexes: StructureIndex[];
  disabled?: boolean;
}

function DistributeToBackButton({ structureIndexes, disabled }: Props) {
  return (
    <DistributeActionButton
      structureIndexes={structureIndexes}
      icon={<IconMoveBottom />}
      distributeTo="to-back"
      disabled={disabled}
    />
  );
}

export default React.memo(DistributeToBackButton);

import showLoaderOperation from 'editor/src/store/app/operation/showLoaderOperation';
import { LoaderType } from 'editor/src/store/app/types';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import addNewImageAssetToPageOperation from 'editor/src/store/design/operation/addNewImageAssetToPageOperation';
import updateImageWithAssetOperation from 'editor/src/store/design/operation/updateImageWithAssetOperation';
import getSelectedImagePersonalizationLocked from 'editor/src/store/design/selector/getSelectedImagePersonalizationLocked';
import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import { Dimensions } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getReplacableImageElement from 'editor/src/store/editor/selector/getReplacableImageElement';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import isPersonalizationLockIgnored from 'editor/src/store/editor/selector/isPersonalizationLockIgnored';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { MockupRole } from 'editor/src/store/mockup/types';

import selectNextEmptyImageFrameOperation from './selectNextEmptyImageFrameOperation';

// TODO think about shared action with applyImageOperation
const applyAssetImageOperation =
  (imageId: string, pluginName: string, assetThumbnail: string, dimensions?: Dimensions) =>
  (dispatch: ThunkDispatch, getStore: () => RootState) => {
    const state = getStore();
    const currentSpreadIndex = getCurrentSpreadIndex(state);
    const selectedImageElement = getSelectedMediaElementsObjects(state).find((element) => element?.type === 'image');
    const selectedImageUuid = selectedImageElement?.uuid;
    const selectedImagePersonalizationLocked =
      getSelectedImagePersonalizationLocked(state) && !isPersonalizationLockIgnored(state);
    const addElementsAllowed = isAddElementsAllowed(state);
    const replacableElementUuid = getReplacableImageElement(state, currentSpreadIndex)?.uuid;

    batch(() => {
      if (selectedImageUuid || addElementsAllowed || (replacableElementUuid && !selectedImagePersonalizationLocked)) {
        dispatch(showLoaderOperation(LoaderType.EditorArea));
      }

      const isMockupPlaceholder =
        selectedImageElement?.type === 'image' && selectedImageElement.role === MockupRole.Placeholder;
      if (selectedImageUuid && !isMockupPlaceholder) {
        if (!selectedImagePersonalizationLocked) {
          dispatch(selectNextEmptyImageFrameOperation(selectedImageUuid));
          void dispatch(
            updateImageWithAssetOperation(selectedImageUuid, imageId, pluginName, assetThumbnail, dimensions, true),
          );
        }
      } else if (addElementsAllowed) {
        void dispatch(
          addNewImageAssetToPageOperation(
            currentSpreadIndex,
            0,
            imageId,
            pluginName,
            assetThumbnail,
            undefined,
            dimensions,
          ),
        );
      } else if (replacableElementUuid && !selectedImagePersonalizationLocked) {
        dispatch(selectNextEmptyImageFrameOperation(replacableElementUuid));
        void dispatch(
          updateImageWithAssetOperation(replacableElementUuid, imageId, pluginName, assetThumbnail, dimensions, true),
        );
      }
    });
  };

export default applyAssetImageOperation;

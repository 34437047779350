import updateMediaElementByUuidOperation from 'editor/src/store/design/operation/updateMediaElementByUuidOperation';
import { Dimensions, MediaImage } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getCenteredImagePosition from 'editor/src/util/2d/getCenteredImagePosition';

const placeResizableScaledImageOperation =
  (imageElement: MediaImage, dimensions: Dimensions) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();

    let centeredPosition = {};
    if (imageElement.imageId) {
      const image = state.gallery.images.find((image) => image.id === imageElement.imageId);
      if (image) {
        centeredPosition = getCenteredImagePosition({ ...imageElement, minDimensions: dimensions }, image);
      }
    }

    dispatch(
      updateMediaElementByUuidOperation(imageElement.uuid, {
        ...centeredPosition,
        minDimensions: dimensions,
      }),
    );
  };

export default placeResizableScaledImageOperation;

import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import getCurrentFontStylesProperty from 'editor/src/store/fonts/selector/getCurrentFontStylesProperty';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import DropDown from 'editor/src/component/DropDown';
import IconMinus from 'editor/src/component/Icon/IconMinus';
import IconPlus from 'editor/src/component/Icon/IconPlus';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';
import WithTooltip from 'editor/src/component/WithTooltip';

import FontSizeButton from './FontSizeButton';
import getFontSizes from './getFontSizes';

import styles from './index.module.scss';

export const canShow: CanShow = (state, { isPersonalizationLocked }) => !isPersonalizationLocked;

function ButtonFontSize({ mode }: MenuItemProps) {
  const minFontSize = useSelector((state) => getHostSetting(state, 'minFontSize'));
  const maxFontSize = useSelector((state) => getHostSetting(state, 'maxFontSize'));
  const currentFontSize = useSelector((state) => Math.round(getCurrentFontStylesProperty(state, 'fontSize')));
  const currentWrapping = useSelector((state) => getCurrentFontStylesProperty(state, 'wrapping'));
  const dispatch = useDispatch();
  const [editSize, setEditSize] = useState(false);
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [fontInputValue, setFontInputValue] = useState<string>(`${currentFontSize}`);
  const inputWrapRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const fontSizes = useMemo(() => getFontSizes(minFontSize, maxFontSize), [minFontSize, maxFontSize]);

  useEffect(() => {
    setFontInputValue(`${currentFontSize}`);
  }, [currentFontSize]);

  const handleWindowEvent = (e: MouseEvent) => {
    if (
      e.target &&
      inputWrapRef?.current &&
      e.target instanceof Node &&
      !inputWrapRef.current.contains(e.target) &&
      editSize
    ) {
      setEditSize(false);
      setFontInputValue(`${currentFontSize}`);
    }
  };

  useEffect(() => {
    if (!editSize) {
      return undefined;
    }

    inputRef.current?.focus();
    inputRef.current?.select();

    const handle = window.setTimeout(() => window.addEventListener('click', handleWindowEvent), 0);
    return () => {
      window.clearTimeout(handle);
      window.removeEventListener('click', handleWindowEvent);
    };
  }, [editSize]);

  const isWrappingFit = currentWrapping === 'fit';

  const canIncreaseFontSize = () => currentFontSize + 1 <= maxFontSize && !isWrappingFit;

  const canDecreaseFontSize = () => currentFontSize - 1 >= minFontSize && !isWrappingFit;

  const handlePlusButtonClick = () => {
    if (canIncreaseFontSize()) {
      setFontSize(currentFontSize + 1);
    }
  };

  const handleMinusButtonClick = () => {
    if (canDecreaseFontSize()) {
      setFontSize(currentFontSize - 1);
    }
  };

  const handleClickOutsideDropdown = useCallback(() => {
    setDropDownVisible(false);
    setEditSize(false);
  }, []);

  const handleValueButtonClick = () => {
    setEditSize(true);
    if (mode !== 'label-icon') {
      setDropDownVisible(true);
    }
  };

  const setFontSize = (fontSize: number) => {
    dispatch(setActiveTopMenuDropdownOperation(undefined));
    dispatch(applyFontStylesToSelectionOperation({ fontSize }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFontInputValue(e.currentTarget.value);
    setDropDownVisible(false);
  };

  const isValueCorrect = (fontSizeText: string) => {
    const fontSize = parseInt(fontSizeText, 10);
    return fontSize <= maxFontSize && fontSize >= minFontSize;
  };

  const filterInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyFontSize();
      return;
    }

    if (
      e.key === '.' ||
      e.key === ',' ||
      e.key === 'e' ||
      (currentFontSize <= minFontSize && e.key === 'ArrowDown') ||
      (currentFontSize >= maxFontSize && e.key === 'ArrowUp')
    ) {
      e.preventDefault();
    }
  };

  const applyFontSize = () => {
    if (isValueCorrect(fontInputValue)) {
      const parsedInputValue = parseInt(fontInputValue, 10);
      if (currentFontSize !== parsedInputValue) {
        setFontSize(parsedInputValue);
      }
    }
  };

  return (
    <div className={cn(styles.ButtonFontSize, 'cy-font-size')}>
      <button
        className={cn(
          styles.minusButton,
          styles.button,
          { [styles.disabled]: !canDecreaseFontSize() },
          'cy-button-font-size-minus',
        )}
        onClick={handleMinusButtonClick}
        aria-label="Decrease font size"
      >
        <IconMinus />
      </button>
      {editSize && !isWrappingFit ? (
        <div ref={inputWrapRef} className={styles.inputWrap}>
          <WithTooltip
            overlay={t('editor-font-size-must-be-between', {
              min: minFontSize,
              max: maxFontSize,
            })}
            visible={!isValueCorrect(fontInputValue)}
            placement="bottom"
          >
            <input
              ref={inputRef}
              className={cn(
                styles.input,
                { [styles.wrongValue]: !isValueCorrect(fontInputValue) },
                'cy-font-size-input',
              )}
              type="number"
              value={fontInputValue}
              onChange={handleInputChange}
              onKeyDown={filterInput}
              onClick={handleValueButtonClick}
              onBlur={applyFontSize}
            />
          </WithTooltip>
          {dropDownVisible && (
            <DropDown
              isVisible
              wrapperClassName={styles.dropDownWrapper}
              className={styles.dropDown}
              openPosition="bottom-center"
              onClickOutside={handleClickOutsideDropdown}
              maxHeight={250}
            >
              {fontSizes.map((value) => (
                <FontSizeButton
                  key={value}
                  value={value}
                  isActive={value === currentFontSize}
                  onClick={handleClickOutsideDropdown}
                />
              ))}
            </DropDown>
          )}
        </div>
      ) : (
        <button
          className={cn(styles.valueButton, styles.button, { [styles.disabled]: isWrappingFit }, 'cy-button-font-size')}
          onClick={handleValueButtonClick}
        >
          {currentFontSize}
        </button>
      )}
      <button
        className={cn(
          styles.plusButton,
          styles.button,
          { [styles.disabled]: !canIncreaseFontSize() },
          'cy-button-font-size-plus',
        )}
        onClick={handlePlusButtonClick}
        aria-label="Increase font size"
      >
        <IconPlus />
      </button>
    </div>
  );
}

export default React.memo(ButtonFontSize);

import {
  applyMiddleware,
  createStore,
  compose,
  AnyAction,
  createSlice,
  combineReducers,
  ThunkDispatch as ReduxThunkDispatch,
  ThunkAction as ReduxThunkAction,
} from '@reduxjs/toolkit';
import { createDispatchHook, createSelectorHook, TypedUseSelectorHook, createStoreHook } from 'react-redux';
import { thunk } from 'redux-thunk';

import LocalStoreContext from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/localStoreContext';

import slice, { dataInitState } from './slice';
import { ContentPluginRootState } from './types';

export type ThunkDispatch = ReduxThunkDispatch<ContentPluginRootState, undefined, AnyAction>;
export type ThunkAction<R> = ReduxThunkAction<R, ContentPluginRootState, undefined, AnyAction>;

export const initState: ContentPluginRootState = {
  data: dataInitState,
  pluginName: '',
};

let composeEnhancers = compose;
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const stores: { [key: string]: any } = {};

const rootReducer = combineReducers({
  data: slice.reducer,
  pluginName: createSlice({ name: 'pluginName', initialState: '', reducers: {} }).reducer,
});

const createReduxStore = (pluginName: string) =>
  createStore(rootReducer, { ...initState, pluginName }, composeEnhancers(applyMiddleware<ThunkDispatch>(thunk)));

const getStore = (pluginName: string) => {
  if (!stores[pluginName]) {
    stores[pluginName] = createReduxStore(pluginName);
  }
  return stores[pluginName];
};

export default getStore;

export const useSelector: TypedUseSelectorHook<ContentPluginRootState> = createSelectorHook(LocalStoreContext);
export const useDispatch: () => ThunkDispatch = createDispatchHook<ThunkDispatch>(LocalStoreContext);
export const useStore = createStoreHook<ContentPluginRootState>(LocalStoreContext);

import cn from 'classnames';
import React from 'react';

import { WarningLevel } from 'editor/src/store/editorModules/warnings/types';

import IconCross from 'editor/src/component/Icon/IconCross';
import IndicatorBadge from 'editor/src/component/IndicatorBadge';

import styles from './index.module.scss';

interface Props {
  onDismiss?: () => void;
  children: React.ReactNode;
  className?: string;
}

function WarningBanner({ onDismiss, children, className }: Props) {
  return (
    <div className={cn(styles.warningBanner, className)}>
      <IndicatorBadge level={WarningLevel.Medium} />
      <div className={styles.content}>{children}</div>
      {onDismiss && (
        <div onClick={onDismiss}>
          <IconCross className={cn(styles.icon, styles.iconClose)} />
        </div>
      )}
    </div>
  );
}

export default React.memo(WarningBanner);

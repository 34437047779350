import React from 'react';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import { useDispatch } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';

interface Props {
  value: number;
  isActive: boolean;
  onClick?: () => void;
}

function FontSizeButton({ value, isActive, onClick }: Props) {
  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent) => {
    dispatch(setActiveTopMenuDropdownOperation(undefined));
    dispatch(applyFontStylesToSelectionOperation({ fontSize: value }));
    e.stopPropagation();
    onClick && onClick();
  };

  return (
    <ControlButton onClick={handleClick} on={isActive} stopPropagation className="cy-font-size-option">
      {value}
    </ControlButton>
  );
}

export default React.memo(FontSizeButton);

const IS_ETSY_PERSONALIZATION_OPTIONS_MODAL_CLOSE = 'isEtsyPersonalizationOptionsModalClose';

export const isEtsyPersonalizationOptionsModalClose = (): boolean =>
  !!window.localStorage.getItem(IS_ETSY_PERSONALIZATION_OPTIONS_MODAL_CLOSE);

export const setIsEtsyPersonalizationOptionsModalClose = (close: boolean): void => {
  if (close) {
    window.localStorage.setItem(IS_ETSY_PERSONALIZATION_OPTIONS_MODAL_CLOSE, '1');
  } else {
    window.localStorage.removeItem(IS_ETSY_PERSONALIZATION_OPTIONS_MODAL_CLOSE);
  }
};

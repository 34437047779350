import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DrawerHeader from 'editor/src/component/BottomBarMobile/DrawerHeader';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import FoilLayerItemsList from './FoilLayerItemsList';

import styles from './index.module.scss';

function FoilLayersTabContent({ closeMenuCb }: TabContentProps) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? <DrawerHeader title={t('Foil')} closeMenu={closeMenuCb} /> : <TabContentHeader title={t('Foil')} />}
      <ContentBlock scroll fill>
        <div className={cn(styles.subtitle)}>{t('Select foil layer')}</div>
        <div className={cn(styles.hint)}>{t('foil-layers-tab-hint')}</div>
        <FoilLayerItemsList />
      </ContentBlock>
    </>
  );
}

export default React.memo(FoilLayersTabContent);

import cn from 'classnames';
import React from 'react';

import ControlButton from 'editor/src/component/ControlButton';

import styles from './index.module.scss';

interface Props {
  Icon: JSX.Element;
  title: string;
  onClick: () => void;
  children?: React.ReactNode;
  className?: string;
}

function MenuButton({ Icon, title, onClick, children, className }: Props) {
  return (
    <ControlButton className={cn(styles.MenuButton, className)} onClick={() => onClick()} stopPropagation>
      {children}
      {Icon}
      {title}
    </ControlButton>
  );
}

export default React.memo(MenuButton);

import cn from 'classnames';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';

import IconChevronLeft from 'editor/src/component/Icon/IconChevronLeft';
import IconChevronRight from 'editor/src/component/Icon/IconChevronRight';

import styles from './index.module.scss';

interface Props {
  onClick?: () => void;
  type: 'left' | 'right';
}

function CarouselArrow({ onClick, type }: Props) {
  if (!onClick) {
    return null;
  }

  return (
    <div
      className={cn(styles.arrow, `cy-plugin-carousel-button-${type}`, 'row-flex row-flex--middle', {
        [styles.arrowLeft]: type === 'left',
        [styles.arrowRight]: type === 'right',
      })}
      onClick={() => onClick()}
    >
      {type === 'left' ? <IconChevronLeft /> : <IconChevronRight />}
    </div>
  );
}

export default React.memo(CarouselArrow);

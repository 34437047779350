import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import getVisibleWarningsCount from 'editor/src/store/editorModules/warnings/selector/getVisibleWarningsCount';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';

import WarningItemsList from './WarningItemsList';

function WarningTabContent({ title }: TabContentProps) {
  const warningCount = useSelector(getVisibleWarningsCount);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!warningCount) {
      dispatch(setSidebarActiveTabOperation(0));
    }
  }, [warningCount]);

  return (
    <>
      <TabContentHeader title={title || t('editor-warnings')} />
      <ContentBlock scroll>
        <WarningItemsList />
      </ContentBlock>
    </>
  );
}

export default WarningTabContent;

import React from 'react';
import { useTranslation } from 'react-i18next';

import setSettingsValueOperation from 'editor/src/store/editor/operation/setSettingsValueOperation';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import Checkbox from 'editor/src/component/Checkbox';
import SettingsItem from 'editor/src/component/DesktopSidebar/TabContents/SettingsTabContent/SettingsItem';

function ShowRulersCheckbox() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isChecked = useSelector((state) => getSettingsValue(state, SettingsProperty.showRulers));
  const allowShowRulersChange = useSelector((state) => getHostSetting(state, 'allowShowRulersChange'));

  const handleChange = () => {
    dispatch(setSettingsValueOperation(SettingsProperty.showRulers, !isChecked));
  };

  if (!allowShowRulersChange) {
    return null;
  }

  return (
    <SettingsItem descriptionTranslationKey="editor-settings-rulers-description">
      <Checkbox
        on={isChecked}
        onChange={handleChange}
        label={t('editor-settings-rulers')}
        className="cy-show-ruler-checkbox"
      />
      <svg width="286" height="1" viewBox="0 0 286 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0" y1="0" x2="286" y2="0" stroke="white" />
        <line x1="0" y1="0" x2="286" y2="0" stroke="#666666" strokeDasharray="5" />
      </svg>
    </SettingsItem>
  );
}

export default ShowRulersCheckbox;

import React from 'react';
import { useTranslation } from 'react-i18next';

import IconDiamond from 'editor/src/component/Icon/IconDiamond';

import Badge from './Badge';

function SpotFinishingBadge() {
  const { t } = useTranslation();
  return (
    <Badge tooltip={t('Foil will be applied to this element')} className="cy-foil-badge">
      <IconDiamond />
    </Badge>
  );
}

export default React.memo(SpotFinishingBadge);

import React from 'react';
import { useTranslation } from 'react-i18next';

import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';

import IconPersonsalisation from '../../../../Icon/IconPersonsalisation';
import MobilePersonalizeLayersContent from '../../../../MobilePersonalizeLayersContent';
import useMobileMenu from '../../../../useMobileMenu';

interface Props {
  isActive: boolean;
}

function MobilePersonalizeLayersTab({ isActive }: Props) {
  const menu = useMobileMenu(false, true);
  const { t } = useTranslation();
  const closeMenu = () => {
    menu.close();
  };

  const onMobileClick = () => {
    menu.open();
  };

  return (
    <>
      <Tab
        key={TAB_NAMES.PERSONALIZE_LAYERS}
        name={TAB_NAMES.PERSONALIZE_LAYERS}
        title={t('Personalize')}
        icon={<IconPersonsalisation active={isActive} />}
        onMobileClick={onMobileClick}
      />
      {menu.render(<MobilePersonalizeLayersContent closeMobileMenuCb={closeMenu} />)}
    </>
  );
}
export default React.memo(MobilePersonalizeLayersTab);

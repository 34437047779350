import cn from 'classnames';
import React from 'react';

import IconArrowLeft from 'editor/src/component/Icon/IconArrowLeft';

import styles from './index.module.scss';

interface Props {
  title: string;
  className?: string;
  minMode?: boolean;
}

function UploaderDropZone({ title, className, minMode }: Props) {
  return (
    <div className={cn(styles.UploaderDropZone, 'p-2', className)}>
      <IconArrowLeft className={styles.arrow} />
      {!minMode && <div className={cn(styles.title, 'mt-2')}>{title}</div>}
      {!minMode && (
        <div className={cn(styles.description, 'm-2')}>
          You can upload
          <br />
          .jpg, .png, .svg, .heic, .pdf
        </div>
      )}
    </div>
  );
}

export default React.memo(UploaderDropZone);

import { useEffect, useRef } from 'react';

import { InboundPostMessage } from './postMessages/messages';

function usePostMessage<T extends keyof InboundPostMessage>(
  messageType: T,
  callback: (data: InboundPostMessage[T]) => void,
  plugin?: string,
  filter?: (data: InboundPostMessage[T]) => boolean,
) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  const filterRef = useRef(filter);
  filterRef.current = filter;

  useEffect(() => {
    function receiveMessage(e: MessageEvent) {
      if (
        e.data.type === messageType &&
        (!plugin || plugin === e.data.plugin) &&
        (!filterRef.current || filterRef.current?.(e.data.data))
      ) {
        callbackRef.current(e.data.data);
      }
    }

    window.addEventListener('message', receiveMessage);
    return () => window.removeEventListener('message', receiveMessage);
  }, [messageType, plugin]);
}

export default usePostMessage;

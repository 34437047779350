import { useMemo } from 'react';

import { DesignDimensions, DimensionRestrictions, Dimensions } from 'editor/src/store/design/types';
import getDimensionRestrictions from 'editor/src/store/design/util/getDimensionRestrictions';
import { GalleryImage } from 'editor/src/store/gallery/types';

import calculateDPI from 'editor/src/util/calculateDPI';

import { Size } from './ProductSize';
import { AdditionalSizeInfo, sizeMeetsRestrictions } from './utils';

function useSizesWithAdditionalInfo(
  sizes: Dimensions[],
  dimensions: DesignDimensions,
  imageAsset: GalleryImage | undefined,
  getMaxImageDimensions: (dimensionRestrictions: DimensionRestrictions) => Dimensions | undefined,
) {
  return useMemo<{
    sizes: Size[];
    additionalInfo: Map<Size, AdditionalSizeInfo>;
  }>(() => {
    const additionalInfo = new Map<Size, AdditionalSizeInfo>();
    const filteredSizes = sizes.filter((size) => {
      const dimensionRestrictions = getDimensionRestrictions(dimensions, size);
      const maxImageDimensions = getMaxImageDimensions(dimensionRestrictions);
      if (!sizeMeetsRestrictions(size, dimensionRestrictions)) {
        return false;
      }

      const dpi = imageAsset ? calculateDPI(imageAsset.width, imageAsset.height, size.width, size.height) : undefined;
      if (maxImageDimensions && dpi) {
        additionalInfo.set(size, { maxImageDimensions, dpi });
      }

      return true;
    });

    return { sizes: filteredSizes, additionalInfo };
  }, [sizes, dimensions, getMaxImageDimensions, imageAsset]);
}

export default useSizesWithAdditionalInfo;

import React from 'react';

import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import useEditorModeTranslations from 'editor/src/util/useEditorModeTranslations';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconGallery from 'editor/src/component/Icon/IconGallery';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
  active: boolean;
}

function GalleryTab({ pluginName, customTabInfo, active }: Props) {
  const { galleryTab } = useEditorModeTranslations();

  return (
    <Tab
      key={TAB_NAMES.GALLERY}
      name={TAB_NAMES.GALLERY}
      title={galleryTab}
      icon={<IconGallery active={active} />}
      pluginName={pluginName}
      customTabInfo={customTabInfo}
    />
  );
}

export default React.memo(GalleryTab);

import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'editor/src/store/hooks';

import noop from 'editor/src/util/noop';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconProducts from 'editor/src/component/Icon/IconProducts';
import IconRectangle from 'editor/src/component/Icon/IconRectangle';
import IconStore from 'editor/src/component/Icon/IconStore';

import Information from './Information';
import MockupList, { EntryProps } from './MockupList';
import MockupListEntry from './MockupListEntry';
import ProductPlaceholderTabContent from './ProductPlaceholderTabContent';
import ScenePlaceholderTabContent from './ScenePlaceholderTabContent';
import StoreList from './StoreList';

type ProductOption = 'placeholders' | 'stores';
type Entry = {
  id: ProductOption;
  icon: React.FunctionComponent<{ className?: string }>;
  title: string;
  Content: React.FunctionComponent<{ back: () => void }>;
};

function ProductStoreList({ back }: { back: () => void }) {
  return <StoreList mode="product" back={back} />;
}

function StoreEntry({ entry, onSelect }: EntryProps<Entry>) {
  return <MockupListEntry id={entry.id} icon={entry.icon} title={entry.title} onClick={() => onSelect(entry)} />;
}

function PlaceholderTabContent() {
  const hasProductCategories = useSelector((state) => state.mockup.scenePlaceholder.productCategories.length > 0);
  const PlaceholderContent = hasProductCategories ? ScenePlaceholderTabContent : ProductPlaceholderTabContent;

  const { t } = useTranslation();

  const [entry, setEntry] = useState<Entry>();

  const back = useCallback(() => setEntry(undefined), []);

  const entries: Entry[] = useMemo(
    () => [
      {
        id: 'placeholders',
        icon: IconRectangle,
        title: t('Placeholders'),
        Content: PlaceholderContent,
      },
      {
        id: 'stores',
        icon: IconStore,
        title: t('Stores'),
        Content: ProductStoreList,
      },
    ],
    [],
  );

  return entry ? (
    <entry.Content back={back} />
  ) : (
    <>
      <TabContentHeader title={t('Product')} icon={<IconProducts />} />
      <ContentBlock>
        <Information />
        <MockupList entries={entries} hasMore={false} EntryElt={StoreEntry} loadEntries={noop} onSelect={setEntry} />
      </ContentBlock>
    </>
  );
}

export default PlaceholderTabContent;

export function passMouseMoveEventToCanvas(e: TouchEvent) {
  const mouseEvent = new MouseEvent('mousemove', {
    clientX: e.touches[0].clientX,
    clientY: e.touches[0].clientY,
  });
  const canvas = document.querySelector('.upper-canvas');
  canvas?.dispatchEvent(mouseEvent);
}

export function createClone(
  sourceElement: HTMLElement,
  posX: number,
  posY: number,
  draggableElementsCount: number,
): HTMLElement {
  const clone = sourceElement.cloneNode(true) as HTMLElement;
  clone.classList.add('draggableClone');
  clone.style.transform = `translate3d(${posX - sourceElement.clientWidth / 2}px,${posY - sourceElement.clientHeight / 2}px,0)`;
  clone.style.top = '';
  clone.style.left = '';
  clone.style.bottom = '';
  clone.style.right = '';
  clone.style.height = `${sourceElement.clientHeight}px`;
  clone.style.width = `${sourceElement.clientWidth}px`;

  if (draggableElementsCount > 1) {
    const elementsCountDiv = document.createElement('div');
    elementsCountDiv.textContent = draggableElementsCount.toString();
    elementsCountDiv.style.display = 'flex';
    elementsCountDiv.style.justifyContent = 'center';
    elementsCountDiv.style.alignItems = 'center';
    elementsCountDiv.style.position = 'absolute';
    elementsCountDiv.style.left = '50%';
    elementsCountDiv.style.top = '50%';
    elementsCountDiv.style.transform = 'translate(-50%, calc(-50% + 25px))';
    elementsCountDiv.style.width = '24px';
    elementsCountDiv.style.height = '24px';
    elementsCountDiv.style.fontFamily = '"Gelato Sans"';
    elementsCountDiv.style.fontSize = '12px';
    elementsCountDiv.style.fontWeight = '700';
    elementsCountDiv.style.color = '#212121;';
    elementsCountDiv.style.backgroundColor = '#FFF';
    elementsCountDiv.style.borderRadius = '4px';
    elementsCountDiv.style.boxShadow = '0px 1px 3px 1px rgba(33, 33, 33, 0.15), 0px 1px 2px 0px rgba(33, 33, 33, 0.30)';
    clone.appendChild(elementsCountDiv);
  }

  return clone;
}

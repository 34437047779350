import React from 'react';

import clearImagesOrRemoveElementsOperation from 'editor/src/store/design/operation/clearImagesOrRemoveElementsOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import isRemoveElementsAllowed from 'editor/src/store/editor/selector/isRemoveElementsAllowed';
import { useDispatch } from 'editor/src/store/hooks';

import IconBin from 'editor/src/component/Icon/IconBin';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection, structureIndexes }) => {
  if (!hasSelection || !isRemoveElementsAllowed(state)) {
    return false;
  }
  const element = getMediaElement(state, structureIndexes[0]);
  return !!element && !isMediaMockupPlaceholder(element);
};

type Props = Pick<MenuItemProps, 'context' | 'mode' | 'close'>;

function ButtonRemove({ context, mode, close }: Props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(clearImagesOrRemoveElementsOperation(context.structureIndexes));
    close?.();
  };

  return (
    <MenuButton
      Icon={IconBin}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-remove"
      className="cy-button-remove"
    />
  );
}

export default React.memo(ButtonRemove);

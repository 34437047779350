import { getGroupElementsByKey } from 'editor/src/store/design/selector/getSpreadGroupElementsByKey';
import { Spread } from 'editor/src/store/design/types';
import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import getBoxesMinMax from 'editor/src/util/getBoxesMinMax';

function getPerPageBBox(layoutSchema: LayoutSchema, spread: Spread, pageNum?: number) {
  const mediaBoxes = getGroupElementsByKey(spread, 'mediabox', undefined, pageNum);

  return getBoxesMinMax(mediaBoxes);
}

export default getPerPageBBox;

import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { getImageUrl } from 'editor/src/store/design/selector/getImageElementUrl';
import { Condition } from 'editor/src/store/design/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { useStore } from 'editor/src/store/hooks';
import shouldDigitizeCurrentSpread from 'editor/src/store/utils/shouldDigitizeCurrentSpread';

import PersonalisationDropDown from 'editor/src/component/PersonalizationContent/PersonalizationElements/PersonalisationDropDown';

import styles from './index.module.scss';

interface Props {
  condition: Condition;
  onSelectOption: (conditionId: string, optionId: string) => void;
}

function ConditionOptionsDropDown({ condition, onSelectOption }: Props) {
  const store = useStore();
  const options = useMemo(() => {
    const state = store.getState();
    const digitizeCurrentSpread = shouldDigitizeCurrentSpread(state);
    return condition.options.map((option) => {
      let imageUrl;
      if (option.imageId) {
        const image = getImageById(state, option.imageId);
        imageUrl = image ? getImageUrl(image, false, digitizeCurrentSpread) : undefined;
      }
      return { imageUrl, label: option.label };
    });
  }, [condition.options]);

  const activeOptionIndex = condition.options.findIndex((option) => option.id === condition.activeOptionId);

  const onSelect = useCallback(
    (index: number) => {
      const option = condition.options[index];
      onSelectOption(condition.id, option.id);
    },
    [condition.options, condition.id],
  );

  return (
    <PersonalisationDropDown
      options={options}
      activeOptionIndex={activeOptionIndex}
      selectOption={onSelect}
      className={cn(styles.dropdown, 'cy-condition-options-dropdown')}
    />
  );
}

export default React.memo(ConditionOptionsDropDown);

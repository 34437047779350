import cn from 'classnames';
import React from 'react';

import IconCrown from 'editor/src/component/Icon/IconCrown';

import styles from './index.module.scss';

interface Props {
  onClick?: () => void;
}

function ButtonPremium({ onClick }: Props) {
  return (
    <button className={cn(styles.buttonPremium, 'cy-button-gelato-premium')} onClick={onClick}>
      <IconCrown />
      Gelato+
    </button>
  );
}

export default React.memo(ButtonPremium);

import { fabric } from 'fabric';
import React, { useImperativeHandle, useRef } from 'react';

import { MediaElement } from 'editor/src/store/design/types';
import { WarningLevel } from 'editor/src/store/editorModules/warnings/types';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import { highWarningIcon, mediumWarningIcon } from 'editor/src/util/warningIcon';

import FabricSVGComponent, { SVGElement } from 'editor/src/component/EditorArea/fabricComponents/FabricSVGComponent';
import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';
import useElementWarnings from 'editor/src/component/TopMenuDesktop/buttons/ButtonWarnings/useElementWarnings';

import getWarningIconDimensions from './getWarningIconDimensions';
import useWarningIconViewport from './useWarningIconViewport';

export interface WarningIconInterface {
  set: (options: Partial<fabric.Group>) => void;
}

interface Props {
  element: MediaElement;
  frameRect: ObjectRect;
  zIndex: number;
}

const WarningIcon = React.forwardRef(({ element, frameRect, zIndex }: Props, ref: React.Ref<WarningIconInterface>) => {
  const fabricCanvas = useFabricCanvas();
  const { warnings } = useElementWarnings(element);
  const icon = warnings.some((warning) => warning.level === WarningLevel.High) ? highWarningIcon : mediumWarningIcon;

  const warningRef = useRef<SVGElement | null>(null);
  useImperativeHandle(
    ref,
    () => ({
      set: (options) => warningRef.current?.set(options),
    }),
    [warningRef],
  );

  useWarningIconViewport(frameRect, warningRef);

  if (!warnings.length) {
    return null;
  }

  return (
    <FabricSVGComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getWarningIconDimensions(frameRect, fabricCanvas.getZoom())}
      ref={warningRef}
      svg={icon}
      evented={false}
      zIndex={zIndex}
    />
  );
});

export default React.memo(WarningIcon);

import React from 'react';

import { RootState } from 'editor/src/store';

import { MenuContext } from 'editor/src/component/Menu/type';
import ButtonDuplicate, {
  canShow as canShowDuplicate,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonDuplicate';
import { ButtonPersonalization } from 'editor/src/component/TopMenuDesktop/buttons/ButtonPersonalization';
import ButtonRectangleFillColor from 'editor/src/component/TopMenuDesktop/buttons/ButtonRectangleFillColor';
import ButtonRectangleStrokeColor from 'editor/src/component/TopMenuDesktop/buttons/ButtonRectangleStrokeColor';
import { ButtonRectangleStyles } from 'editor/src/component/TopMenuDesktop/buttons/ButtonRectangleStyles';
import ButtonRemove, { canShow as canShowRemove } from 'editor/src/component/TopMenuDesktop/buttons/ButtonRemove';

interface Props {
  menuContext: MenuContext;
  rootState: RootState;
}

function RectangleControls({ rootState, menuContext }: Props) {
  return (
    <div className="row-flex row-flex--center my-2">
      <ButtonRectangleFillColor isMobile mode="label-icon-vertical" />
      <ButtonRectangleStrokeColor isMobile mode="label-icon-vertical" />
      <ButtonRectangleStyles isMobile mode="label-icon-vertical" />
      <ButtonPersonalization isMobile mode="label-icon-vertical" />
      {canShowDuplicate(rootState, menuContext) && <ButtonDuplicate mode="label-icon-vertical" context={menuContext} />}
      {canShowRemove(rootState, menuContext) && <ButtonRemove mode="label-icon-vertical" context={menuContext} />}
    </div>
  );
}

export default RectangleControls;

import React from 'react';

import { Condition, ConditionDisplayOption } from 'editor/src/store/design/types';

import ConditionOptionsButton from './ConditionOptionsButton';
import ConditionOptionsDropDown from './ConditionOptionsDropDown';
import ConditionOptionsThumbnail from './ConditionOptionsThumbnail';

interface Props {
  condition: Condition;
  onSelectOption: (conditionId: string, optionId: string) => void;
}

function ConditionOptions({ condition, onSelectOption }: Props) {
  switch (condition.displayOption) {
    case ConditionDisplayOption.Button:
      return <ConditionOptionsButton condition={condition} onSelectOption={onSelectOption} />;
    case ConditionDisplayOption.Dropdown:
      return <ConditionOptionsDropDown condition={condition} onSelectOption={onSelectOption} />;
    case ConditionDisplayOption.Thumbnail:
      return <ConditionOptionsThumbnail condition={condition} onSelectOption={onSelectOption} />;
    default:
      return null;
  }
}

export default React.memo(ConditionOptions);

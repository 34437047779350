import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { Unit } from 'editor/src/store/editor/types';
import { Size } from 'editor/src/store/variants/types';

import limitPrecision from 'editor/src/util/limitPrecision';
import { convertMmToUnit } from 'editor/src/util/math';

import Checkbox from 'editor/src/component/Checkbox';

import ButtonMore from '../ButtonMore';

import styles from './index.module.scss';

interface Props {
  size: Size;
  unit: Unit;
  index: number;
  disabled: boolean;
  selected: boolean;
  immutable: boolean;
  multi: boolean;
  onEdit?: () => void;
  onDelete?: (index: number) => void;
  onSelect: (size: Size, selected: boolean) => void;
}

function ProductSizeItem({
  size,
  unit,
  index,
  disabled,
  multi,
  selected,
  immutable,
  onEdit,
  onDelete,
  onSelect,
}: Props) {
  const toggleCb = useCallback(() => {
    onSelect(size, selected);
  }, [onSelect]);

  const onSizeEdit = useCallback(() => {
    onEdit?.();
  }, [onEdit]);

  const onSizeDelete = useCallback(() => {
    onDelete?.(index);
  }, [onDelete]);

  const label = useMemo(() => {
    const widthValue = limitPrecision(convertMmToUnit(size.width, unit), 1);
    const heightValue = limitPrecision(convertMmToUnit(size.height, unit), 1);
    return `${widthValue}x${heightValue} ${unit}`;
  }, [size.width, size.height, unit]);

  return (
    <div className={cn(styles.container, 'cy-product-size-item')}>
      <Checkbox
        className={cn(styles.checkbox, 'cy-product-size-checkbox', [{ 'cy-selected': selected }])}
        label={label}
        on={selected}
        disabled={disabled && multi}
        onChange={toggleCb}
      />
      {!immutable && (
        <ButtonMore className={styles.more} onEdit={onSizeEdit} onRemove={onSizeDelete} disabled={disabled} />
      )}
    </div>
  );
}

export default React.memo(ProductSizeItem);

import { designs } from '@gelatoas/design-editor-calendar';

import { LayoutFrameArea, LayoutGeneratedTextType, LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import numberToOffset from '../numberToOffset';
import numberToPadding from '../numberToPadding';

import schemas, { LAYOUT_TAG_GRID } from './defaultSchemas';

const INTERNAL_SPACING = 5;
const EXTERNAL_PADDING = 12;

const CALENDAR_GRID_SECTION = (width = 2, height = 6, padding = 0): LayoutFrameArea[] => [
  {
    // TODO we need to get the grid design dynamycally from the store
    type: 'grid',
    designName: designs.defaultGridDesign.name,
    width,
    height,
    padding: {
      left: padding,
      top: 0,
      right: padding,
      bottom: padding,
    },
  },
];
const CALENDAR_HEADER_SECTION = (width = 1, height = 1, padding = 0): LayoutFrameArea[] => [
  {
    type: 'generated-text',
    width,
    height,
    textType: LayoutGeneratedTextType.MONTH,
    vAlignment: 'middle',
    padding: {
      left: padding,
      top: 0,
      right: 0,
      bottom: 0,
    },
  },
  {
    type: 'generated-text',
    width,
    height,
    textType: LayoutGeneratedTextType.YEAR,
    vAlignment: 'middle',
    padding: {
      left: 0,
      top: 0,
      right: padding,
      bottom: 0,
    },
  },
];

const layoutSchemasCalendar: LayoutSchema[] = [
  ...schemas,
  {
    name: 'ca_i2t0_1',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [{ width: 1, height: 3, shiftHeight: 0.5, shiftWidth: 0.1, unlocked: true }],
      [],
      [],
      [{}, { width: 1, height: 3, shiftY: -0.5, shiftX: -0.1, unlocked: true }],
      [],
      [],
      CALENDAR_HEADER_SECTION(),
      CALENDAR_GRID_SECTION(),
    ],
  },
  {
    name: 'ca_i2t0_2',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 28,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(0),
      offset: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: true,
    },
    frameRows: [
      [{ width: 1, height: 7, shiftHeight: 2, shiftWidth: 0.15, unlocked: true }],
      ...Array(6).fill([]),
      [{}, { width: 1, height: 7, shiftY: -2, shiftX: -0.15, unlocked: true }],
      ...Array(6).fill([]),
      CALENDAR_HEADER_SECTION(1, 2, 10),
      [],
      CALENDAR_GRID_SECTION(2, 12, 10),
    ],
  },
  {
    name: 'ca_i3t0_1',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 10,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [{}, {}, {}, { width: 4, height: 3, shiftHeight: 0.5, unlocked: true }],
      [],
      [],
      [
        { width: 4, height: 3, shiftY: -0.5, unlocked: true },
        {},
        {},
        { width: 4, height: 3, shiftY: -0.5, unlocked: true },
      ],
      [],
      [],
      CALENDAR_HEADER_SECTION(5, 1),
      CALENDAR_GRID_SECTION(10, 6),
    ],
  },
  {
    name: 'ca_i3t0_2',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [{ width: 1, height: 2, shiftHeight: 0.6, shiftWidth: 0.1, unlocked: true }],
      [],
      [{}, { width: 1, height: 2, shiftY: -0.4, shiftHeight: 0.4, shiftX: -0.1, unlocked: true }],
      [],
      [{ width: 1, height: 2, shiftY: -0.6, shiftWidth: 0.1, unlocked: true }, {}],
      [],
      CALENDAR_HEADER_SECTION(),
      CALENDAR_GRID_SECTION(),
    ],
  },
  {
    name: 'ca_i3t0_3',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
      ],
      ...Array(5).fill([]),
      CALENDAR_HEADER_SECTION(3),
      CALENDAR_GRID_SECTION(6),
    ],
  },
  {
    name: 'ca_i3t0_12',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [{ width: 2, height: 2, unlocked: true }],
      [],
      [{ width: 2, height: 2, unlocked: true }],
      [],
      [{ width: 2, height: 2, unlocked: true }],
      [],
      CALENDAR_HEADER_SECTION(),
      CALENDAR_GRID_SECTION(),
    ],
  },
  {
    name: 'ca_i4t0_1',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 1, height: 3, unlocked: true, shiftWidth: -0.2, shiftHeight: 0.4 },
        { width: 1, height: 3, unlocked: true, shiftX: 0.2, shiftHeight: 0.4 },
      ],
      [],
      [],
      [
        { width: 1, height: 3, unlocked: true, shiftX: 0.2, shiftY: -0.4 },
        { width: 1, height: 3, unlocked: true, shiftWidth: -0.2, shiftY: -0.4 },
      ],
      [],
      [],
      CALENDAR_HEADER_SECTION(),
      CALENDAR_GRID_SECTION(),
    ],
  },
  {
    name: 'ca_i4t0_2',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 1, height: 3, unlocked: true },
        { width: 1, height: 3, unlocked: true },
      ],
      [],
      [],
      [
        { width: 1, height: 3, unlocked: true },
        { width: 1, height: 3, unlocked: true },
      ],
      [],
      [],
      CALENDAR_HEADER_SECTION(),
      CALENDAR_GRID_SECTION(),
    ],
  },
  {
    name: 'ca_i4t0_3',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 28,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(0),
      offset: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: true,
    },
    frameRows: [
      [
        { width: 1, height: 7, unlocked: true },
        { width: 1, height: 7, unlocked: true },
      ],
      ...Array(6).fill([]),
      [
        { width: 1, height: 7, unlocked: true },
        { width: 1, height: 7, unlocked: true },
      ],
      ...Array(6).fill([]),
      CALENDAR_HEADER_SECTION(1, 2, 10),
      [],
      CALENDAR_GRID_SECTION(2, 12, 10),
    ],
  },
  {
    name: 'ca_i4t0_4',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 26,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [{ width: 6, height: 7, unlocked: true }],
      ...Array(6).fill([]),
      [
        { width: 2, height: 5, unlocked: true },
        { width: 2, height: 5, unlocked: true },
        { width: 2, height: 5, unlocked: true },
      ],
      ...Array(4).fill([]),
      CALENDAR_HEADER_SECTION(3, 2),
      [],
      CALENDAR_GRID_SECTION(6, 12),
    ],
  },
  {
    name: 'ca_i4t0_5',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 26,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 2, height: 5, unlocked: true },
        { width: 2, height: 5, unlocked: true },
        { width: 2, height: 5, unlocked: true },
      ],
      ...Array(4).fill([]),
      [{ width: 6, height: 7, unlocked: true }],
      ...Array(6).fill([]),
      CALENDAR_HEADER_SECTION(3, 2),
      [],
      CALENDAR_GRID_SECTION(6, 12),
    ],
  },
  {
    name: 'ca_i4t0_6',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 26,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 2, height: 5, unlocked: true },
        { width: 2, height: 5, unlocked: true },
        { width: 2, height: 12, unlocked: true },
      ],
      ...Array(4).fill([]),
      [{ width: 4, height: 7, unlocked: true }],
      ...Array(6).fill([]),
      CALENDAR_HEADER_SECTION(3, 2),
      [],
      CALENDAR_GRID_SECTION(6, 12),
    ],
  },
  {
    name: 'ca_i6t0_1',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 26,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
      ],
      ...Array(5).fill([]),
      [
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
      ],
      ...Array(5).fill([]),
      CALENDAR_HEADER_SECTION(3, 2),
      [],
      CALENDAR_GRID_SECTION(6, 12),
    ],
  },
  {
    name: 'ca_i5t0_1',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 26,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
      ],
      ...Array(5).fill([]),
      [
        { width: 3, height: 6, unlocked: true },
        { width: 3, height: 6, unlocked: true },
      ],
      ...Array(5).fill([]),
      CALENDAR_HEADER_SECTION(3, 2),
      [],
      CALENDAR_GRID_SECTION(6, 12),
    ],
  },
  {
    name: 'ca_i3t0_4',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 1, height: 6, unlocked: true },
        { width: 1, height: 3, unlocked: true },
      ],
      [],
      [],
      [{}, { width: 1, height: 3, unlocked: true }],
      [],
      [],
      CALENDAR_HEADER_SECTION(),
      CALENDAR_GRID_SECTION(),
    ],
  },
  {
    name: 'ca_i3t0_5',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [{ width: 2, height: 3, unlocked: true }],
      [],
      [],
      [
        { width: 1, height: 3, unlocked: true },
        { width: 1, height: 3, unlocked: true },
      ],
      [],
      [],
      CALENDAR_HEADER_SECTION(),
      CALENDAR_GRID_SECTION(),
    ],
  },
  {
    name: 'ca_i3t0_6',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 1, height: 3, unlocked: true },
        { width: 1, height: 3, unlocked: true },
      ],
      [],
      [],
      [{ width: 2, height: 3, unlocked: true }],
      [],
      [],
      CALENDAR_HEADER_SECTION(),
      CALENDAR_GRID_SECTION(),
    ],
  },
  {
    name: 'ca_i3t0_7',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 1, height: 3, unlocked: true },
        { width: 1, height: 6, unlocked: true },
      ],
      [],
      [],
      [{ width: 1, height: 3, unlocked: true }],
      [],
      [],
      CALENDAR_HEADER_SECTION(),
      CALENDAR_GRID_SECTION(),
    ],
  },
  {
    name: 'ca_i3t0_8',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 12,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 5, height: 6, unlocked: true },
        { width: 5, height: 3, unlocked: true },
      ],
      [],
      [],
      [{}, {}, {}, {}, {}, { width: 5, height: 3, unlocked: true }],
      [],
      [],
      CALENDAR_HEADER_SECTION(6),
      CALENDAR_GRID_SECTION(12),
    ],
  },
  {
    name: 'ca_i3t0_9',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 12,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [{ width: 10, height: 3, unlocked: true }],
      [],
      [],
      [
        { width: 5, height: 3, unlocked: true },
        { width: 5, height: 3, unlocked: true },
      ],
      [],
      [],
      CALENDAR_HEADER_SECTION(6),
      CALENDAR_GRID_SECTION(12),
    ],
  },
  {
    name: 'ca_i3t0_10',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 12,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 5, height: 3, unlocked: true },
        { width: 5, height: 3, unlocked: true },
      ],
      [],
      [],
      [{ width: 10, height: 3, unlocked: true }],
      [],
      [],
      CALENDAR_HEADER_SECTION(6),
      CALENDAR_GRID_SECTION(12),
    ],
  },
  {
    name: 'ca_i3t0_11',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 12,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 5, height: 3, unlocked: true },
        { width: 5, height: 6, unlocked: true },
      ],
      [],
      [],
      [{ width: 5, height: 3, unlocked: true }],
      [],
      [],
      CALENDAR_HEADER_SECTION(6),
      CALENDAR_GRID_SECTION(12),
    ],
  },
  {
    name: 'ca_i3t1_13',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 26,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 4, height: 10, unlocked: true },
        { width: 2, height: 5, unlocked: true },
      ],
      ...Array(4).fill([]),
      [{}, {}, {}, {}, { width: 2, height: 5, unlocked: true }],
      ...Array(4).fill([]),
      [{ type: 'text', width: 6, height: 2, unlocked: true }],
      [],
      CALENDAR_HEADER_SECTION(3, 2),
      [],
      CALENDAR_GRID_SECTION(6, 12),
    ],
  },
  {
    name: 'ca_i3t1_14',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 26,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 3, height: 10, unlocked: true },
        { width: 3, height: 5, unlocked: true },
      ],
      ...Array(4).fill([]),
      [{}, {}, {}, { width: 3, height: 5, unlocked: true }],
      ...Array(4).fill([]),
      [{ type: 'text', width: 6, height: 2, unlocked: true }],
      [],
      CALENDAR_HEADER_SECTION(3, 2),
      [],
      CALENDAR_GRID_SECTION(6, 12),
    ],
  },
  {
    name: 'ca_i3t0_15',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 2, height: 2, unlocked: true },
        { width: 4, height: 6, unlocked: true },
      ],
      [],
      [{ width: 2, height: 4, unlocked: true }],
      [],
      [],
      [],
      CALENDAR_HEADER_SECTION(3),
      CALENDAR_GRID_SECTION(6),
    ],
  },
  {
    name: 'ca_i1t1_2',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [{}, { width: 4, height: 5, unlocked: true }],
      ...Array(4).fill([]),
      [{}, { type: 'text', width: 4, height: 1, unlocked: true }],
      CALENDAR_HEADER_SECTION(3),
      CALENDAR_GRID_SECTION(6),
    ],
  },
  {
    name: 'ca_i6t0_2',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 26,
      shiftSteps: 1,
      spacing: numberToOffset(0),
      padding: numberToPadding(0),
      offset: {
        top: EXTERNAL_PADDING,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
      ],
      ...Array(5).fill([]),
      [
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
      ],
      ...Array(5).fill([]),
      CALENDAR_HEADER_SECTION(3, 2, 10),
      [],
      CALENDAR_GRID_SECTION(6, 12, 10),
    ],
  },
  {
    name: 'ca_i6t0_3',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 26,
      shiftSteps: 1,
      spacing: numberToOffset(EXTERNAL_PADDING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
      ],
      ...Array(5).fill([]),
      [
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
        { width: 2, height: 6, unlocked: true },
      ],
      ...Array(5).fill([]),
      CALENDAR_HEADER_SECTION(3, 2),
      [],
      CALENDAR_GRID_SECTION(6, 12),
    ],
  },
  {
    name: 'ca_i3t1_16',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 14,
      shiftSteps: 1,
      spacing: numberToOffset(3),
      padding: numberToPadding(0),
      offset: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: true,
    },
    frameRows: [
      [
        { width: 1, height: 3, unlocked: true },
        { width: 1, height: 7, unlocked: true },
      ],
      [],
      [],
      [{ type: 'text', width: 1, height: 1, unlocked: true }],
      [{ width: 1, height: 3, unlocked: true }],
      [],
      [],
      CALENDAR_HEADER_SECTION(1, 1, 10),
      CALENDAR_GRID_SECTION(2, 6, 10),
    ],
  },
  {
    name: 'ca_i3t3_17',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 6,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(INTERNAL_SPACING),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { width: 2, height: 5, unlocked: true },
        { width: 2, height: 5, unlocked: true },
        { width: 2, height: 5, unlocked: true },
      ],
      ...Array(4).fill([]),
      [
        { type: 'text', width: 2, height: 1, unlocked: true },
        { type: 'text', width: 2, height: 1, unlocked: true },
        { type: 'text', width: 2, height: 1, unlocked: true },
      ],
      CALENDAR_HEADER_SECTION(3),
      CALENDAR_GRID_SECTION(6),
    ],
  },
  {
    name: 'ca_i4t0_7',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 4,
      rows: 28,
      shiftSteps: 1,
      spacing: numberToOffset(0),
      padding: numberToPadding(0),
      offset: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: true,
    },
    frameRows: [
      [
        { width: 2, height: 14, unlocked: true },
        { width: 1, height: 7, unlocked: true },
        { width: 1, height: 7, unlocked: true },
      ],
      ...Array(6).fill([]),
      [{}, {}, { width: 2, height: 7, unlocked: true }],
      ...Array(6).fill([]),
      CALENDAR_HEADER_SECTION(2, 2, 10),
      [],
      CALENDAR_GRID_SECTION(4, 12, 10),
    ],
  },
  {
    name: 'ca_i3t0_18',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 4,
      rows: 42,
      shiftSteps: 1,
      spacing: numberToOffset(4),
      padding: numberToPadding(0),
      offset: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [{ width: 1, height: 7, unlocked: true }],
      ...Array(6).fill([]),
      [{ width: 1, height: 7, unlocked: true }],
      ...Array(6).fill([]),
      [{ width: 1, height: 7, unlocked: true }],
      ...Array(6).fill([]),
      CALENDAR_HEADER_SECTION(2, 3, 10),
      ...Array(2).fill([]),
      CALENDAR_GRID_SECTION(4, 18, 10),
    ],
  },
];

export default layoutSchemasCalendar;

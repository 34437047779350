import React from 'react';

import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import getCurrentFontStylesProperty from 'editor/src/store/fonts/selector/getCurrentFontStylesProperty';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconUnderline from 'editor/src/component/Icon/IconUnderline';
import { MenuItemProps, CanShow } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

type Props = Pick<MenuItemProps, 'mode'>;

export const canShow: CanShow = () => true;

function ButtonUnderline({ mode }: Props) {
  const dispatch = useDispatch();
  const currentUnderlineStatus = useSelector((state) => getCurrentFontStylesProperty(state, 'underline'));

  const onUnderlineStatusSwitched = () => {
    dispatch(
      applyFontStylesToSelectionOperation({
        underline: !currentUnderlineStatus,
      }),
    );
  };

  return (
    <MenuButton
      Icon={IconUnderline}
      on={currentUnderlineStatus}
      mode={mode}
      handleClick={onUnderlineStatusSwitched}
      i18nKey="editor-underline"
      className="cy-button-underline"
    />
  );
}

export default React.memo(ButtonUnderline);

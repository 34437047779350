import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginName } from 'editor/src/store/plugins/types';

import IconInfo from 'editor/src/component/Icon/IconInfo';

import ContentBlock from '../../Elements/ContentBlock';
import {
  DEFAULT_CURRENCY,
  ShutterstockAssetProviderKeys,
  ShutterstockAssetProviderType,
  ShutterstockFeeType,
} from '../constants';

import styles from './index.module.scss';

interface Props {
  assetProviderType: ShutterstockAssetProviderType;
}

function FooterBlock({ assetProviderType }: Props) {
  const { t } = useTranslation();
  const plugin = useSelector((state) => getPlugin(state, PluginName.Shutterstock));

  const { pricing, userCurrency, premiumLevel } = plugin?.extra || {};

  // TODO change the logic when dashboard will move to graphql plugin configs
  let feeLabel =
    assetProviderType === ShutterstockAssetProviderType.premium
      ? plugin?.extra?.dynamicFeeInfo
      : plugin?.extra?.fixedFeeInfo;

  if (plugin?.extra?.userCurrency) {
    const feePostfix =
      assetProviderType === ShutterstockAssetProviderType.premium
        ? ShutterstockFeeType.dynamic
        : ShutterstockFeeType.fixed;
    const feeTranslationKeyLabel = `shutterstock-fee-${feePostfix}`;
    const feeLocalCurrency = userCurrency && userCurrency !== DEFAULT_CURRENCY ? '-local-currency' : '';

    feeLabel = t(feeTranslationKeyLabel + feeLocalCurrency, {
      priceInUsd: pricing[ShutterstockAssetProviderKeys[assetProviderType]]?.[DEFAULT_CURRENCY],
      price: pricing[ShutterstockAssetProviderKeys[assetProviderType]]?.[userCurrency],
      localCurrency: userCurrency,
    });
  }

  return (
    <ContentBlock>
      {!(premiumLevel && assetProviderType === ShutterstockAssetProviderType.essential) && (
        <div className={styles.shutterstockFee}>
          <IconInfo />
          {feeLabel}
        </div>
      )}
      <div
        className={styles.termsOfService}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('shutterstock-terms'),
        }}
      />
    </ContentBlock>
  );
}

export default React.memo(FooterBlock);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updateGridElementOnAllSpreadsOperation from 'editor/src/store/design/operation/updateGridElementOnAllSpreadsOperation';
import getIsWeekNumberChecked from 'editor/src/store/design/selector/getIsWeekNumberChecked';
import selectFirstSpreadWithGridOperation from 'editor/src/store/editor/operation/selectFirstSpreadWithGridOperation';
import type { Thunk } from 'editor/src/store/hooks';

import { getWeekDays } from 'editor/src/util/dateUtils';
import toastController from 'editor/src/util/toastController';

const MONDAY = 1;

const setGridFirstDayOfWeekOperation =
  (firstDayOfWeek: number): Thunk =>
  (dispatch, getState, { i18n }) => {
    const state = getState();
    const gridDesigns = state.gridDesigns.grids;
    const fonts = state.fonts.availableFonts;

    const cannotShowWeekNumber = firstDayOfWeek > MONDAY; // we do not show week number if it does't start by sunday or monday
    const showWeekNumber = getIsWeekNumberChecked(state);
    if (cannotShowWeekNumber && showWeekNumber) {
      const day = getWeekDays(i18n).find((day) => day.value === firstDayOfWeek);
      toastController.info('', i18n.t('editor-week-num-disabled-message', { day: day?.label }));
    }

    batch(() => {
      dispatch(
        updateGridElementOnAllSpreadsOperation({
          gridDesigns,
          fonts,
          override: { firstDayOfWeek },
        }),
      );
      dispatch(selectFirstSpreadWithGridOperation());
    });
  };

export default setGridFirstDayOfWeekOperation;

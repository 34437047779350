import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import isShopifyOptionsInfoBannerDismissed from 'editor/src/store/editorModules/sidebar/selector/isShopifyOptionsInfoBannerDismissed';
import { setIsShopifyOptionsInfoBannerDismissedAction } from 'editor/src/store/editorModules/sidebar/slice';
import { useSelector, useDispatch } from 'editor/src/store/hooks';

import IconInfoFilled from 'editor/src/component/Icon/IconInfoFilled';

import { BannerType } from '../banner-type.enum';
import InfoBanner from '../InfoBanner';
import { increaseBannerDismissedCounter, isBannerDismissedEnoughToHideIt } from '../utils';

interface Props {
  appEnabled: boolean;
  enableAppUrl: string | undefined;
}

const ENABLE_APP_EMBED_LINK =
  'https://support.gelato.com/en/articles/8996330-how-to-set-up-gelato-s-personalization-studio-on-shopify';

function ShopifyOptionsInfoBanner({ appEnabled, enableAppUrl }: Props) {
  const { t } = useTranslation();
  const isBannerDismissedInCurrentSession = useSelector(isShopifyOptionsInfoBannerDismissed);
  const isShopifyBannerDismissedEnoughToHideIt = useMemo(
    () => isBannerDismissedEnoughToHideIt(BannerType.SHOPIFY_OPTIONS_INFO_BANNER),
    [],
  );
  const dispatch = useDispatch();
  const dismiss = () => {
    increaseBannerDismissedCounter(BannerType.SHOPIFY_OPTIONS_INFO_BANNER);
    dispatch(setIsShopifyOptionsInfoBannerDismissedAction(true));
  };

  if (isBannerDismissedInCurrentSession || isShopifyBannerDismissedEnoughToHideIt || appEnabled) {
    return null;
  }

  return (
    <InfoBanner icon={<IconInfoFilled />} onDismiss={dismiss} className="cy-shopify-options-info-banner">
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('shopify-options-info-banner', {
            link: enableAppUrl || ENABLE_APP_EMBED_LINK,
          }),
        }}
      />
    </InfoBanner>
  );
}

export default React.memo(ShopifyOptionsInfoBanner);

import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginName } from 'editor/src/store/plugins/types';
import updatePersonalizationOperation, {
  PersonalizationType,
} from 'editor/src/store/variants/operation/updateProductPersonalizationOperation';

import Accordion from 'editor/src/component/Accordion';
import ShopifyOptionsInfoBanner from 'editor/src/component/Banners/ShopifyOptionsInfoBanner';
import IconInfo from 'editor/src/component/Icon/IconInfo';
import RadioButtonElement, { ElementOption } from 'editor/src/component/RadioButtonElement';
import WithTooltip from 'editor/src/component/WithTooltip';

import { isShopifyPersonalizationOptionsModalClose, setIsShopifyPersonalizationOptionsModalClose } from './utils';

import styles from './index.module.scss';

function ShopifyPersonalizationOptions() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inPagePersonalizationEnabled = useSelector((state) =>
    state.design.globalPlugins.includes(PluginName.shopifyPersonalization),
  );
  const [selectedOption, setSelectedOption] = useState(
    inPagePersonalizationEnabled ? 'in-page-personalization' : 'editor-personalization',
  );
  const [collapsed, setCollapsed] = useState(isShopifyPersonalizationOptionsModalClose());
  const shopifyPlugin = useSelector((state) => getPlugin(state, PluginName.shopifyPersonalization));
  const isShopifyInPagePersonalizationDisabled = shopifyPlugin?.state === 'disabled';

  const onChange = useCallback((option: PersonalizationType) => {
    dispatch(updatePersonalizationOperation(option));
    setSelectedOption(option);
  }, []);

  const InPagePersonalizationBadge = (
    <div className={cn(styles.badgeContainer, 'cy-coming-soon-badge')}>
      <div className={cn(styles.badge)}>{t('Coming Soon')}</div>
      <WithTooltip className={styles.info} overlay={t('shopify personalization tooltip explanation')}>
        <IconInfo />
      </WithTooltip>
    </div>
  );
  const options: ElementOption<PersonalizationType>[] = [
    {
      value: 'in-page-personalization',
      label: t('Shopify in-page personalization'),
      disabled: isShopifyInPagePersonalizationDisabled,
      content: t('shopify same page personalization toggle-on content'),
      badge: isShopifyInPagePersonalizationDisabled ? InPagePersonalizationBadge : undefined,
    },
    {
      value: 'editor-personalization',
      label: t('Editor personalization'),
      disabled: false,
      content: t('shopify same page personalization toggle-off content'),
    },
  ];

  const setModalCollapsed = useCallback((isCollapsed: boolean) => {
    setCollapsed(isCollapsed);
    setIsShopifyPersonalizationOptionsModalClose(isCollapsed);
  }, []);

  const subheader = options.find((option) => option.value === selectedOption)?.label;

  return (
    <div
      className={cn(styles.shopifyOptionContainer, 'mb-2', 'pl-2', 'pr-2', {
        'pb-2': !collapsed,
      })}
    >
      <Accordion
        header={t('Type')}
        headerClassName={styles.header}
        collapsed={collapsed}
        setCollapsed={setModalCollapsed}
        subheader={subheader}
        subheaderClassName={styles.subheader}
      >
        <RadioButtonElement options={options} selected={selectedOption} onChange={onChange} />
        <ShopifyOptionsInfoBanner
          appEnabled={!!shopifyPlugin?.extra?.appEnabled}
          enableAppUrl={shopifyPlugin?.extra?.enableAppUrl}
        />
      </Accordion>
    </div>
  );
}

export default React.memo(ShopifyPersonalizationOptions);

import React, { useRef } from 'react';

import addUploadedImagesOperation from 'editor/src/store/gallery/operation/addUploadedImagesOperation';
import { useDispatch } from 'editor/src/store/hooks';
import useDisplayMode from 'editor/src/store/watchers/useDisplayMode';

import { successPayloadToUploadedImageData } from 'editor/src/util/localUploader/fileMappers';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import DesktopLayout from 'editor/src/component/DesktopLayout';
import LoadingBackdrop from 'editor/src/component/LoadingBackdrop';
import LocalUploader from 'editor/src/component/LocalUploader';
import MobileLayout from 'editor/src/component/MobileLayout';
import useUploader, { SuccessPayload, UploaderContext } from 'editor/src/component/Uploader/useUploader';
import useDetectDeviceType, { mobileDevices, useIsMobile } from 'editor/src/component/useDetectDeviceType';

interface Props {
  localUploader?: true;
}

function Home({ localUploader }: Props) {
  const deviceType = useDetectDeviceType();
  const isMobile = useIsMobile();
  const displayMode = useDisplayMode();
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const onUploadSuccess = (payload: SuccessPayload) => {
    if (localUploader) {
      void dispatch(addUploadedImagesOperation([successPayloadToUploadedImageData(payload)]));
    } else {
      sendPostMessage('uploader.assetUploaded', payload);
    }
  };
  const uploader = useUploader(mobileDevices.has(deviceType), onUploadSuccess, localUploader, fileInputRef);

  if (displayMode === 'loading') {
    return <LoadingBackdrop />;
  }

  const App = isMobile ? MobileLayout : DesktopLayout;

  return (
    <UploaderContext.Provider value={uploader}>
      <App />
      {uploader.renderMenu?.()}
      {localUploader && <LocalUploader ref={fileInputRef} multiple onChange={uploader.upload} />}
    </UploaderContext.Provider>
  );
}

export default React.memo(Home);

import React from 'react';
import { useTranslation } from 'react-i18next';

import removeAllSelectedMediaElementsOperation from 'editor/src/store/editor/operation/removeAllSelectedMediaElementsOperation';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch } from 'editor/src/store/hooks';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconStickers from 'editor/src/component/Icon/IconStickers';
import useMobileMenu from 'editor/src/component/useMobileMenu';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
  active?: boolean;
}

function AddonsTab({ pluginName, customTabInfo, active }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const topMenu = useMobileMenu();

  const onMobileClick = () => {
    dispatch(removeAllSelectedMediaElementsOperation());
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.ADDONS));
    topMenu.close();
  };

  return (
    <Tab
      key={TAB_NAMES.ADDONS}
      name={TAB_NAMES.ADDONS}
      title={t('editor-stickers')}
      icon={<IconStickers active={active} />}
      pluginName={pluginName}
      onMobileClick={onMobileClick}
      customTabInfo={customTabInfo}
    />
  );
}

export default React.memo(AddonsTab);

import cn from 'classnames';
import React from 'react';

import H2 from 'editor/src/component/Header/H2';

import styles from './index.module.scss';

interface Props {
  title: string;
  categoryTitle: string;
}

function ProductIntro({ title, categoryTitle }: Props) {
  return (
    <>
      <H2 className="cy-title">{title}</H2>
      <div className={cn(styles.categoryTitle, 'cy-category')}>{categoryTitle}</div>
    </>
  );
}

export default React.memo(ProductIntro);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';

import { ThunkDispatch } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/index';
import getSearchFilters from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getSearchFilters';
import { setActiveFiltersAction } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/slice';
import { ContentPluginRootState } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const resetActiveFiltersOperation = () => (dispatch: ThunkDispatch, getState: () => ContentPluginRootState) => {
  const state = getState();
  const activeFilters = getSearchFilters(state);

  batch(() => {
    dispatch(setActiveFiltersAction(activeFilters));
  });
};

export default resetActiveFiltersOperation;

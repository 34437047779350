import React from 'react';

import { StructureIndex } from 'editor/src/store/design/types';

import IconMoveUp from 'editor/src/component/Icon/IconMoveUp';
import DistributeActionButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/DistributeActionButton';

interface Props {
  structureIndexes: StructureIndex[];
  disabled?: boolean;
}

function DistributeForwardButton({ structureIndexes, disabled }: Props) {
  return (
    <DistributeActionButton
      structureIndexes={structureIndexes}
      icon={<IconMoveUp />}
      distributeTo="forward"
      disabled={disabled}
    />
  );
}

export default React.memo(DistributeForwardButton);

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import applyToMultipleMediaOperation from 'editor/src/store/design/operation/applyToMultipleMediaOperation';
import moveMediaElementZindexBottomOperation from 'editor/src/store/design/operation/distribute/moveMediaElementZindexBottomOperation';
import moveMediaElementZindexDownOperation from 'editor/src/store/design/operation/distribute/moveMediaElementZindexDownOperation';
import moveMediaElementZindexTopOperation from 'editor/src/store/design/operation/distribute/moveMediaElementZindexTopOperation';
import moveMediaElementZindexUpOperation from 'editor/src/store/design/operation/distribute/moveMediaElementZindexUpOperation';
import { StructureIndex } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import { useDispatch } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

interface Props {
  icon: React.ReactElement;
  distributeTo: string;
  structureIndexes: StructureIndex[];
  noLabel?: boolean;
  keepTopDropdown?: boolean;
  disabled?: boolean;
}

function DistributeActionButton({
  icon,
  distributeTo,
  structureIndexes,
  noLabel,
  disabled,
  keepTopDropdown = false,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const handleClick = () => {
    if (!keepTopDropdown) {
      setActiveTopMenuDropdownOperation();
    }

    dispatch(saveUndoRedoStateOperation('Distribute element'));
    switch (distributeTo) {
      case 'to-front':
        dispatch(applyToMultipleMediaOperation(structureIndexes, moveMediaElementZindexTopOperation));
        break;
      case 'forward':
        dispatch(applyToMultipleMediaOperation(structureIndexes, moveMediaElementZindexUpOperation));
        break;
      case 'backward':
        dispatch(applyToMultipleMediaOperation(structureIndexes, moveMediaElementZindexDownOperation));
        break;
      case 'to-back':
        dispatch(applyToMultipleMediaOperation(structureIndexes, moveMediaElementZindexBottomOperation));
        break;
      default:
        break;
    }
  };

  return (
    <ControlButton
      className={cn(styles.alignTabButton, `cy-button-${distributeTo}`)}
      onClick={handleClick}
      disabled={disabled}
      stopPropagation
    >
      {icon}
      {noLabel || isMobile ? null : t(`editor-${distributeTo}`)}
    </ControlButton>
  );
}

export default React.memo(DistributeActionButton);

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'editor/src/store/hooks';

import IconCollections from 'editor/src/component/Icon/IconCollections';

import Badge from './Badge';

interface Props {
  folderId: string;
}

function CollectionBadge({ folderId }: Props) {
  const folderName = useSelector((state) => state.collections.folders[folderId]?.name);
  const { t } = useTranslation();
  return (
    <Badge tooltip={t('editor-collection-name', { name: folderName || '' })} className="cy-collection-badge">
      <IconCollections />
    </Badge>
  );
}

export default React.memo(CollectionBadge);

import React, { ChangeEvent, ForwardedRef, forwardRef } from 'react';

import styles from './index.module.scss';

interface Props {
  multiple?: true;
  onChange: (files: File[]) => void;
}

function LocalUploader({ multiple, onChange }: Props, ref: ForwardedRef<HTMLInputElement>) {
  return (
    <input
      className={styles.LocalUploader}
      type="file"
      multiple={!!multiple}
      ref={ref}
      // TODO add .heic format
      accept=".png,.jpg,.jpeg,.svg"
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e?.target?.files ? [...e.target.files] : [])}
    />
  );
}

export default forwardRef(LocalUploader);

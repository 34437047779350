import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

const ELEMENT_COUNT = 50;

export function getMasonryDimensions() {
  return Array(ELEMENT_COUNT)
    .fill(null)
    .map(() => ({
      width: 70 + Math.floor(Math.random() * 20),
      height: 50 + Math.floor(Math.random() * 20),
    }));
}

function ElementsLoader() {
  return (
    <>
      {Array(ELEMENT_COUNT)
        .fill(null)
        .map((_, index) => (
          <div className={cn(styles.element, 'cy-plugin-element-loader')} key={index} />
        ))}
    </>
  );
}

export default ElementsLoader;

import getSpreadPageMediaElements from 'editor/src/store/design/selector/getSpreadPageMediaElements';
import { RootState } from 'editor/src/store/index';

const getReplacableImageElement = (
  state: RootState,
  spreadIndex: number,
  checkForEmptyPageOnly = false,
  pageIndex = 0,
) => {
  const elements = getSpreadPageMediaElements(state, spreadIndex, pageIndex);
  const emptyImage = elements?.find((media) => media.type === 'image' && !media.imageId);
  return checkForEmptyPageOnly
    ? emptyImage
    : emptyImage || (elements?.length === 1 && elements[0].type === 'image' ? elements[0] : undefined);
};

export default getReplacableImageElement;

import {
  ExportedWarning,
  ExtendedWarning,
  Warning,
  isGlobalWarning,
  isSpreadWarning,
} from 'editor/src/store/editorModules/warnings/types';

export const extendedWarningsToWarnings = (extendedWarnings: ExtendedWarning[]): Warning[] =>
  extendedWarnings.map((extendedWarning) => {
    if (isSpreadWarning(extendedWarning) || isGlobalWarning(extendedWarning)) {
      return extendedWarning;
    }

    const { elementAddress, ...warning } = extendedWarning;
    return warning;
  });

export const extendedWarningsToExportedWarnings = (extendedWarnings: ExtendedWarning[]): ExportedWarning[] =>
  extendedWarnings.map((extendedWarning) => {
    if (isSpreadWarning(extendedWarning) || isGlobalWarning(extendedWarning)) {
      const { level, ...exportedWarning } = extendedWarning;
      return exportedWarning;
    }

    const { spreadIndex, level, uuid, ...exportedWarning } = extendedWarning;
    return exportedWarning;
  });

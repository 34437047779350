import { Slice } from '@reduxjs/toolkit';
import React from 'react';
import { Provider } from 'react-redux';

import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';

import ContentProviderPlugin from './ContentProviderPlugin';
import LocalStoreContext from './localStoreContext';
import getStore from './store';
import { DataState } from './store/types';

interface Props extends TabContentProps {
  pluginName: string;
}

export const Context = React.createContext<Slice<DataState> | undefined>(undefined);

function AssetsSourcePlugin({ title, pluginName }: Props) {
  const localStore = getStore(pluginName);

  return (
    <Provider store={localStore} context={LocalStoreContext}>
      <ContentProviderPlugin title={title || ''} pluginName={pluginName} />
    </Provider>
  );
}

export default React.memo(AssetsSourcePlugin);

import cn from 'classnames';
import React from 'react';

import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import { useSelector } from 'editor/src/store/hooks';

import usePluginControls from 'editor/src/component/BottomBarMobile/Plugins/usePluginsControls';

import ScrollHelper from './ScrollHelper';
import SectionContent from './SectionContent';

import styles from './index.module.scss';

function ControlsSection() {
  const firstElement = useSelector((state) => getSelectedMediaElementsObjects(state)[0]);
  const { controls, overwrite } = usePluginControls(firstElement);

  const isOpen = overwrite || !!firstElement;

  return (
    <div className={cn(styles.ControlsSection, { [styles.open]: isOpen, [styles.close]: !isOpen })}>
      <ScrollHelper className={styles.scroller}>
        <div className={styles.sectionContent}>
          <SectionContent controls={controls} overwrite={overwrite} />
        </div>
      </ScrollHelper>
    </div>
  );
}

export default React.memo(ControlsSection);

import React from 'react';
import { useTranslation } from 'react-i18next';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch } from 'editor/src/store/hooks';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconFormats from 'editor/src/component/Icon/IconFormats';
import useMobileMenu from 'editor/src/component/useMobileMenu';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
}

function FormatsTab({ pluginName, customTabInfo }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const topMenu = useMobileMenu();

  const onMobileClick = () => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.FORMATS));
    topMenu.close();
  };

  return (
    <Tab
      key={TAB_NAMES.FORMATS}
      name={TAB_NAMES.FORMATS}
      title={t('editor-formats')}
      icon={<IconFormats />}
      pluginName={pluginName}
      customTabInfo={customTabInfo}
      onMobileClick={onMobileClick}
    />
  );
}

export default React.memo(FormatsTab);

import { fabric } from 'fabric';

import { ObjectRect } from './types';

function getEmptyImageDimensions(
  emptyImage: fabric.Object,
  frameRect: ObjectRect,
  additionalScale = 1,
): Partial<fabric.IObjectOptions> {
  const { width = 1, height = 1 } = emptyImage;
  const padding = Math.max(Math.min(frameRect.width * 0.1, frameRect.height * 0.1), 10);
  const scaleX = Math.min(100, frameRect.width - padding) / width;
  const scaleY = Math.min(100, frameRect.height - padding) / height;
  const scale = Math.min(scaleX, scaleY) * additionalScale;
  const offset = fabric.util.rotatePoint(
    new fabric.Point((frameRect.width - width * scale) / 2, (frameRect.height - height * scale) / 2),
    new fabric.Point(0, 0),
    fabric.util.degreesToRadians(frameRect.angle),
  );
  return {
    left: frameRect.left + offset.x,
    top: frameRect.top + offset.y,
    scaleX: scale,
    scaleY: scale,
    angle: frameRect.angle,
  };
}

export default getEmptyImageDimensions;

import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import applyAssetImageOperation from 'editor/src/store/editor/operation/applyAssetImageOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import getImageAction from 'editor/src/store/gallery/selector/getImageAction';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPluginIcon from 'editor/src/store/plugins/selector/getPluginIcon';
import isPremiumIconVisible from 'editor/src/store/plugins/selector/isPremiumIconVisible';
import { PluginState } from 'editor/src/store/plugins/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import CarouselContainer from 'editor/src/component/Carousel/CarouselContainer';
import {
  useDispatch as useLocalDispatch,
  useSelector as useLocalSelector,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store';
import applySortOperation from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/operation/applySortOperation';
import requestDataOperation from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/operation/requestDataOperation';
import requestMoreDataOperation from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/operation/requestMoreDataOperation';
import getError from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getError';
import getMore from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getMore';
import getRequestVersion from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getRequestVersion';
import getResultsVersion from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getResultsVersion';
import getSort from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getSort';
import getSortOptions from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getSortOptions';
import { setActiveGroupAction as setActiveGroupOperation } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/slice';
import {
  ELEMENT_TYPE,
  GroupElement,
  ImageElement,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';
import IconLoading from 'editor/src/component/Icon/IconLoading';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import ElementImage from './ElementImage';
import ElementsLoader, { getMasonryDimensions } from './ElementsLoader';
import Error from './Error';
import HorizontalMasonry from './HorizontalMasonry';
import NoResults from './NoResults';
import SortButton from './SortButton';

import styles from './index.module.scss';

interface Props {
  pluginName: string;
  pluginState: PluginState;
}

function ElementsList({ pluginState, pluginName }: Props) {
  const localDispatch = useLocalDispatch();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const elements = useLocalSelector((state) => state.data.results?.elements);
  const totalCount = useLocalSelector((state) => state.data.results?.totalCount);
  const error = useLocalSelector(getError);
  const requestVersion = useLocalSelector(getRequestVersion);
  const resultsVersion = useLocalSelector(getResultsVersion);
  const hasMoreElements = useLocalSelector(getMore);
  const sortOptions = useLocalSelector(getSortOptions);
  const searchSort = useLocalSelector(getSort);
  const spreadIndex = useSelector(getCurrentSpreadIndex);
  const imageAction = useSelector(getImageAction);

  const premiumIconVisible = useSelector((state) => isPremiumIconVisible(state, pluginName));

  // Only show icon if account is premium and if it's not legacy premium (state = ENABLED and no premiumLevels)
  const showPremiumIcon = pluginState !== PluginState.ENABLED || premiumIconVisible;
  const PremiumIcon = useSelector((state) => (showPremiumIcon ? getPluginIcon(state, pluginName) : undefined));

  const onImageSelect = useCallback(
    (element: ImageElement) => {
      if (pluginState === PluginState.NON_FUNCTIONAL) {
        sendPostMessage('plugins.disabledPluginClick', { name: pluginName });
        return;
      }

      batch(() => {
        if (isMobile) {
          dispatch(setSidebarActiveTabOperation(-1));
        }
        dispatch(applyAssetImageOperation(element.id, pluginName, element.thumbnail, element));
      });
    },
    [spreadIndex, pluginState, pluginName, isMobile],
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const getElementImage = (className: string) =>
    // eslint-disable-next-line react/no-unstable-nested-components
    function ElementImageContainer(element: ImageElement) {
      return (
        <ElementImage
          key={element.id}
          element={element}
          pluginName={pluginName}
          onSelect={onImageSelect}
          PremiumIcon={PremiumIcon}
          className={className}
          imageAction={imageAction}
          pluginState={pluginState}
        />
      );
    };

  const requestGroup = useCallback((group: GroupElement) => {
    const { id, title } = group;
    batch(() => {
      localDispatch(setActiveGroupOperation({ id, title }));
      localDispatch(requestDataOperation(id));
    });
  }, []);

  const onSortChange = useCallback((sort: string) => {
    localDispatch(applySortOperation(sort));
  }, []);

  const { images, groups } = useMemo(() => {
    const images: ImageElement[] = [];
    const groups: GroupElement[] = [];
    elements?.forEach((element) => {
      if (element.type === ELEMENT_TYPE.IMAGE) {
        images.push(element);
      } else {
        groups.push(element);
      }
    });
    return { images, groups };
  }, [elements]);

  function onScroll(e: React.UIEvent) {
    if (!e.target || !(e.target instanceof HTMLElement)) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + 50 >= scrollHeight - clientHeight && hasMoreElements && requestVersion === resultsVersion) {
      localDispatch(requestMoreDataOperation());
    }
  }

  const loadingEmpty = !elements?.length && requestVersion !== resultsVersion;
  const masonryDimensions = useMemo(() => (loadingEmpty ? getMasonryDimensions() : images), [loadingEmpty, images]);
  const noResults = !elements?.length && requestVersion > -1 && requestVersion === resultsVersion;
  const showSort = !!(sortOptions?.length && searchSort && !groups.length && !noResults);

  return (
    <div
      className={cn(styles.ElementsList, 'mt-2 cy-plugin-elements', {
        [styles.loadingEmpty]: loadingEmpty,
      })}
      onScroll={onScroll}
    >
      {!error || requestVersion !== resultsVersion ? (
        <>
          {groups.map((group) => (
            <div className={styles.groupItemContainer} key={group.id}>
              <CarouselContainer showAll={() => requestGroup(group)} title={group.title}>
                {group.children.map(getElementImage(styles.elementGroupImage))}
              </CarouselContainer>
            </div>
          ))}
          <div className="row-flex row-flex--between row-flex--middle mb-2">
            <div className={cn(styles.totalCount)}>
              {images.length > 0 && !!totalCount && <span>{`${totalCount} results`}</span>}
            </div>
            {showSort && <SortButton onChange={onSortChange} searchSort={searchSort} options={sortOptions} />}
          </div>
          {images.length > 0 && (
            <HorizontalMasonry dimensions={masonryDimensions} className={styles.masonry} spacing={5}>
              {images.map(getElementImage(styles.elementImage))}
            </HorizontalMasonry>
          )}
          {loadingEmpty && (
            <HorizontalMasonry dimensions={masonryDimensions} className={styles.masonry} spacing={5}>
              <ElementsLoader />
            </HorizontalMasonry>
          )}
          {noResults && <NoResults />}
          {!!elements?.length && hasMoreElements && (
            <div className={cn(styles.loader, 'cy-scroll-loader')}>
              <IconLoading />
            </div>
          )}
        </>
      ) : (
        <Error />
      )}
    </div>
  );
}

export default React.memo(ElementsList);

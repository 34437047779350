import cn from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import getActivePreview from 'editor/src/store/editorModules/preview/selector/getActivePreview';
import getPreviewStatus from 'editor/src/store/editorModules/preview/selector/getPreviewStatus';
import { setActivePreviewAction as setActivePreviewOperation } from 'editor/src/store/editorModules/preview/slice';
import { PreviewStatus, PreviewType } from 'editor/src/store/editorModules/preview/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import isPluginEnabled from 'editor/src/store/plugins/selector/isPluginEnabled';
import { PluginName } from 'editor/src/store/plugins/types';

import { logEvent } from 'editor/src/amplitude';

import ControlButton from 'editor/src/component/ControlButton';
import IconCross from 'editor/src/component/Icon/IconCross';
import IconEye from 'editor/src/component/Icon/IconEye';
import SpinnerLocal from 'editor/src/component/SpinnerLocal';
import ButtonDownload from 'editor/src/component/TopMenuDesktop/buttons/ButtonDownload';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import FlatPreview from './FlatPreview';
import MockupPreview from './MockupPreview';
import PersonalizationBar from './PersonalizationBar';
import The3dPreview from './The3dPreview';

import styles from './index.module.scss';

function previewTypeToEventType(previewType: PreviewType) {
  switch (previewType) {
    case PreviewType.PREVIEW_FLAT:
      return 'flat';
    case PreviewType.PREVIEW_3D:
      return '3d';
    case PreviewType.MOCKUP:
      return 'mockup';
    default:
      return 'unknown';
  }
}

function Preview() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const previewType = useSelector(getActivePreview);
  const status = useSelector(getPreviewStatus);
  const aiPersonalizationEnabled = useSelector((state) => isPluginEnabled(state, PluginName.AIPersonalization));

  const closePreview = () => {
    dispatch(setActivePreviewOperation(undefined));
  };

  useEffect(() => {
    if (!previewType) {
      return;
    }
    logEvent('preview opened', { type: previewTypeToEventType(previewType) });
  }, [previewType]);

  if (!previewType) {
    return null;
  }

  return (
    <div
      className={cn(styles.Preview, 'cy-preview-container', {
        [styles.desktop]: !isMobile,
      })}
    >
      <div className={styles.bg} onClick={closePreview} />
      <div className={styles.Popup}>
        <div className={cn(styles.topMenu, 'p-2')}>
          <div className={styles.title}>
            <IconEye className="mx-1" />
            {t('editor-flat-preview')}
          </div>
          {previewType === PreviewType.MOCKUP && <ButtonDownload mode="label" />}
          <ControlButton onClick={closePreview} className={cn(styles.close, 'cy-preview-close')}>
            <IconCross />
          </ControlButton>
        </div>
        {status === PreviewStatus.ERROR ? (
          <div className={styles.errorMessage}>
            <h2>{t('editor-error')}</h2>
            <p>{t('editor-preview-error-message')}</p>
          </div>
        ) : (
          <>
            {previewType === PreviewType.MOCKUP && <MockupPreview />}
            {previewType === PreviewType.PREVIEW_FLAT && <FlatPreview />}
            {status !== PreviewStatus.LOADING && previewType === PreviewType.PREVIEW_3D && <The3dPreview />}
            <SpinnerLocal
              className={styles.spinner}
              show={status === PreviewStatus.LOADING && previewType !== PreviewType.PREVIEW_FLAT}
            />
          </>
        )}
        {aiPersonalizationEnabled && <PersonalizationBar />}
      </div>
    </div>
  );
}

export default React.memo(Preview);

import cn from 'classnames';
import Hammer from 'hammerjs';
import React, { useRef } from 'react';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import { FABRIC_SCROLL_EVENT } from 'editor/src/component/EditorArea/types';

import { SCROLL_WIDTH, ScrollbarCommonProps, ScrollLocations, stopPropagation } from './scrollbarUtils';
import useScrollbarUpdates from './useScrollbarUpdates';

import styles from './index.module.scss';

function VerticalScrollbar({
  bottomBarHeight,
  bottomBarOffset,
  canvasSize,
  getTransformBoundaryCoordinates,
}: ScrollbarCommonProps) {
  const fabricCanvas = useFabricCanvas();

  const control = useRef<HTMLDivElement>(null);
  const controlWrapper = useRef<HTMLDivElement>(null);

  const getScrollLocations = (): ScrollLocations | undefined => {
    if (!fabricCanvas.vptCoords || !control.current || !controlWrapper.current) {
      return undefined;
    }

    const { y: scrollControlPosition, height: scrollControlWidth } = control.current.getBoundingClientRect();
    const { y: scrollWrapperPosition, height: scrollWrapperWidth } = controlWrapper.current.getBoundingClientRect();

    return {
      wrapper: { position: scrollWrapperPosition, width: scrollWrapperWidth },
      control: { position: scrollControlPosition, width: scrollControlWidth },
      viewportTransform: fabricCanvas?.viewportTransform?.[5] || 0,
    };
  };

  const moveScrollControl = (scrollDeltaY: number, scrollHeight?: number) => {
    if (!control.current) {
      return;
    }

    control.current.style.transform = `translateY(${scrollDeltaY}px)`;
    control.current.style.height = `${scrollHeight || 0}px`;
  };

  const fireScrollEvent = (canvasDeltaY: number) => {
    if (canvasDeltaY) {
      fabricCanvas.fire(FABRIC_SCROLL_EVENT, {
        deltaX: 0,
        deltaY: canvasDeltaY,
      });
    }
  };

  const { handleWrapperClick } = useScrollbarUpdates(
    control,
    moveScrollControl,
    Hammer.DIRECTION_VERTICAL,
    (event) => event.deltaY,
    fireScrollEvent,
    getScrollLocations,
    getTransformBoundaryCoordinates,
    canvasSize,
  );

  return (
    <div
      ref={controlWrapper}
      className={cn(styles.scrollbarContainer, styles.vertical)}
      onClick={({ clientY }) => handleWrapperClick(clientY)}
      style={{
        height: !bottomBarOffset ? `calc(100% - ${bottomBarHeight + SCROLL_WIDTH}px)` : undefined,
      }}
    >
      <div ref={control} className={cn(styles.scrollbarControl, styles.vertical)} onClick={stopPropagation} />
    </div>
  );
}

export default React.memo(VerticalScrollbar);

import cn from 'classnames';
import React from 'react';

import { TopMenuDropdown } from 'editor/src/store/app/types';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import startCropModeOperation from 'editor/src/store/editor/operation/startCropModeOperation';
import { setZoomModeAction as setZoomModeOperation } from 'editor/src/store/editor/slice';
import { useDispatch } from 'editor/src/store/hooks';

import IconZoomIn from 'editor/src/component/Icon/IconZoomIn';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import LeftSectionButtonWithDropdown from 'editor/src/component/TopMenuDesktop/buttons/ButtonWithDropdown';
import MenuDropDownButton from 'editor/src/component/TopMenuDesktop/buttons/MenuDropDownButton';

import ButtonCropZoomIn from './ButtonCropZoomIn';
import ButtonCropZoomOut from './ButtonCropZoomOut';
import ZoomSlider from './ZoomSlider';

import styles from './index.module.scss';

function ButtonCropZoomDropDown({ context, mode }: MenuItemProps) {
  const dispatch = useDispatch();

  const { structureIndexes } = context;

  const enterCropMode = () => {
    batch(() => {
      const { uuid } = structureIndexes[0];
      dispatch(startCropModeOperation(uuid));
      dispatch(setZoomModeOperation(true));
    });
  };

  return (
    <LeftSectionButtonWithDropdown
      onClick={enterCropMode}
      dropDownName={TopMenuDropdown.CropZoom}
      buttonExtraClass={cn('cy-button-crop-zoom', styles.zoomInIcon)}
      buttonContent={<MenuDropDownButton Icon={IconZoomIn} mode={mode} i18nKey="editor-crop-zoom" />}
      dropDownContent={
        <div className={cn(styles.cropZoomDropdown, 'cy-dropdown-crop-zoom')}>
          <ButtonCropZoomOut structureIndexes={structureIndexes} />
          <ZoomSlider structureIndexes={structureIndexes} />
          <ButtonCropZoomIn structureIndexes={structureIndexes} />
        </div>
      }
    />
  );
}

export default React.memo(ButtonCropZoomDropDown);

import { useMemo } from 'react';

import { MediaElement } from 'editor/src/store/design/types';
import getPreviewModePluginName from 'editor/src/store/editor/selector/getPreviewModePluginName';
import { useSelector } from 'editor/src/store/hooks';
import { PluginType } from 'editor/src/store/plugins/types';

import ImageManipulationControls from './ImageManipulation/ImageManipulationControls';
import { PluginTypeConfig } from './types';

const config: PluginTypeConfig = {
  [PluginType.IMAGE_MANIPULATION]: {
    previewControls: ImageManipulationControls,
    overwrite: true,
  },
};

function usePluginControls(mediaElement: MediaElement | undefined): {
  controls: React.FC[];
  overwrite: boolean;
} {
  const plugins = useSelector((state) => state.plugins).list;
  const previewModePluginName = useSelector((state) =>
    mediaElement ? getPreviewModePluginName(state, mediaElement.uuid) : undefined,
  );
  const { controls, overwrite } = useMemo(() => {
    let controls: React.FC[] = [];
    let overwrite = false;

    for (let i = 0; i < plugins.length; i += 1) {
      const plugin = plugins[i];
      const pluginConfig = plugin.type && config[plugin.type];
      if (!pluginConfig) {
        continue;
      }

      const PluginControls = previewModePluginName === plugin.name ? pluginConfig.previewControls : undefined;
      if (PluginControls) {
        if (pluginConfig.overwrite) {
          controls = [PluginControls];
          overwrite = true;
          break;
        }

        controls.push(PluginControls);
      }
    }

    return { controls, overwrite };
  }, [mediaElement, plugins]);

  return { controls, overwrite };
}

export default usePluginControls;

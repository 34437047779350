import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import getSelectedElementUuids from 'editor/src/store/editor/selector/getSelectedElementUuids';
import isWallpaperEmptyScreenVisible from 'editor/src/store/editor/selector/isWallpaperEmptyScreenVisible';

import useShowRulersControl from 'editor/src/util/useShowRulersControl';

import Button from 'editor/src/component/Button';
import IconRuler from 'editor/src/component/Icon/IconRuler';
import { CanShow } from 'editor/src/component/Menu/type';

import styles from './RulerRollsButton.module.scss';

export const canShow: CanShow = (state) => {
  const { allowShowRulersChange } = state.hostSettings;
  const dimensionsExist = !!state.design.designData?.dimensions;
  const showedWallpaperEmptyScreen = isWallpaperEmptyScreenVisible(state);
  const hasSelectedElement = getSelectedElementUuids(state).length > 0;

  return allowShowRulersChange && dimensionsExist && !showedWallpaperEmptyScreen && !hasSelectedElement;
};

function ButtonRulers() {
  const { t } = useTranslation();
  const { showRulers, showControl, toggleRulers } = useShowRulersControl();

  if (!showControl) {
    return null;
  }

  return (
    <Button onClick={toggleRulers} className={cn('px-2', styles.Button, { [styles.active]: showRulers })}>
      <IconRuler />
      <span className="ml-2">{t('Ruler')}</span>
    </Button>
  );
}

export default React.memo(ButtonRulers);

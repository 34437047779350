/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconCheckCircle({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M11.1 13.8L8.925 11.625C8.74167 11.4417 8.51667 11.35 8.25 11.35C7.98333 11.35 7.75 11.45 7.55 11.65C7.36667 11.8333 7.275 12.0667 7.275 12.35C7.275 12.6333 7.36667 12.8667 7.55 13.05L10.4 15.9C10.5833 16.0833 10.8167 16.175 11.1 16.175C11.3833 16.175 11.6167 16.0833 11.8 15.9L17.475 10.225C17.6583 10.0417 17.75 9.81667 17.75 9.55C17.75 9.28333 17.65 9.05 17.45 8.85C17.2667 8.66667 17.0333 8.575 16.75 8.575C16.4667 8.575 16.2333 8.66667 16.05 8.85L11.1 13.8ZM12.5 22C11.1167 22 9.81667 21.7373 8.6 21.212C7.38333 20.6873 6.325 19.975 5.425 19.075C4.525 18.175 3.81267 17.1167 3.288 15.9C2.76267 14.6833 2.5 13.3833 2.5 12C2.5 10.6167 2.76267 9.31667 3.288 8.1C3.81267 6.88333 4.525 5.825 5.425 4.925C6.325 4.025 7.38333 3.31233 8.6 2.787C9.81667 2.26233 11.1167 2 12.5 2C13.8833 2 15.1833 2.26233 16.4 2.787C17.6167 3.31233 18.675 4.025 19.575 4.925C20.475 5.825 21.1873 6.88333 21.712 8.1C22.2373 9.31667 22.5 10.6167 22.5 12C22.5 13.3833 22.2373 14.6833 21.712 15.9C21.1873 17.1167 20.475 18.175 19.575 19.075C18.675 19.975 17.6167 20.6873 16.4 21.212C15.1833 21.7373 13.8833 22 12.5 22Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
}

export default IconCheckCircle;

import { DesignDimensions, DimensionRestrictions } from 'editor/src/store/design/types';

import { Size } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ManualControls/ProductSizeControl/ProductSize';

export function getMaxWidth(maxSurface: number | undefined, height: number, maxWidth: number | undefined): number {
  if (!maxSurface) {
    return maxWidth || 0;
  }

  return Math.floor(maxSurface / height);
}

export function getMaxHeight(maxSurface: number | undefined, width: number, maxHeight: number): number {
  if (!maxSurface) {
    return maxHeight || 0;
  }

  return Math.floor(Math.min(maxSurface / width, maxHeight));
}

function getDimensionRestrictions(dimensions: DesignDimensions, size: Size): DimensionRestrictions {
  return {
    min_height: dimensions.min_height,
    min_width: dimensions.min_width,
    max_height: getMaxHeight(dimensions.max_surface, size.width, dimensions.max_height),
    max_width: getMaxWidth(dimensions.max_surface, size.height, dimensions.max_width),
  };
}

export default getDimensionRestrictions;

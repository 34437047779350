import React from 'react';

import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';
import SkeletonBlock from 'editor/src/component/SkeletonBlock';

import styles from './index.module.scss';

function TabContentLoadingBlocks() {
  return (
    <>
      <SkeletonBlock className={styles.skeletontBlockTitle} />
      <PropertySeparator bigMargin />
      <SkeletonBlock className={styles.skeletontBlockContentTitle} />
      <SkeletonBlock className={styles.skeletontBlockContent} />
      <SkeletonBlock className={styles.skeletontBlockContentTitle} />
      <SkeletonBlock className={styles.skeletontBlockContent} />
    </>
  );
}

export default TabContentLoadingBlocks;

import cn from 'classnames';
import React from 'react';

import { MockupSize } from 'editor/src/store/mockup/types';

import styles from './index.module.scss';

interface Props {
  size: MockupSize;
  selected: boolean;
  onSelect: (size: MockupSize) => void;
}

function MockupSizeComponent({ size, selected, onSelect }: Props) {
  return (
    <div
      className={cn(styles.MockupSizeComponent, {
        [styles.selected]: selected,
      })}
      onClick={() => onSelect(size)}
    >
      <div className={styles.name}>{size.name}</div>
      <div className={styles.size}>{`${size.width}x${size.height}px`}</div>
    </div>
  );
}

export default React.memo(MockupSizeComponent);

import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import { MediaElement } from 'editor/src/store/design/types';
import getPreviewModePluginName from 'editor/src/store/editor/selector/getPreviewModePluginName';
import isAnyMediaElementInCropMode from 'editor/src/store/editor/selector/isAnyMediaElementInCropMode';
import { ManipulationMode } from 'editor/src/store/editor/types';
import { useSelector } from 'editor/src/store/hooks';

import useFilteredMenuItems from 'editor/src/component/Menu/useFilteredMenuItems';

import {
  CROP_ELTS,
  DEFAULT_ELTS,
  GRID_SELECTED_ELTS,
  IMAGE_SELECTED_ELTS,
  ADDON_SELECTED_ELTS,
  TEXT_SELECTED_ELTS,
  PLUGIN_PREVIEW_MODE_ELTS,
  LINE_SELECTED_ELTS,
  RECTANGLE_SELECTED_ELTS,
  PERSPECTIVE_TRANSFORM_ELTS,
} from './menuItems';

function getMenuItems(
  inCropMode: boolean,
  isPerspectiveTransformMode: boolean,
  inPluginPreviewMode: boolean,
  firstElement: MediaElement | undefined,
) {
  if (inCropMode) {
    return CROP_ELTS;
  }
  if (isPerspectiveTransformMode) {
    return PERSPECTIVE_TRANSFORM_ELTS;
  }
  if (inPluginPreviewMode) {
    return PLUGIN_PREVIEW_MODE_ELTS;
  }
  if (!firstElement?.type) {
    return DEFAULT_ELTS;
  }

  switch (firstElement.type) {
    case 'text':
      return TEXT_SELECTED_ELTS;
    case 'addon':
      return ADDON_SELECTED_ELTS;
    case 'image':
      return IMAGE_SELECTED_ELTS;
    case 'grid':
      return GRID_SELECTED_ELTS;
    case 'line':
      return LINE_SELECTED_ELTS;
    case 'rectangle':
      return RECTANGLE_SELECTED_ELTS;
    default:
      return DEFAULT_ELTS;
  }
}

function useMenuItems() {
  const firstElement = useSelector((state) =>
    state.editor.selectedElementUuids.length
      ? getMediaElementByUuid(state, state.editor.selectedElementUuids[0])
      : undefined,
  );
  const inCropMode = useSelector(isAnyMediaElementInCropMode);
  const isPerspectiveTransformMode = useSelector(
    (state) => state.editor.elementManipulationMode?.mode === ManipulationMode.perspectiveTransform,
  );
  const isPluginPreviewMode = useSelector((state) =>
    firstElement ? !!getPreviewModePluginName(state, firstElement?.uuid) : false,
  );

  const items = getMenuItems(inCropMode, isPerspectiveTransformMode, isPluginPreviewMode, firstElement);
  return useFilteredMenuItems(items);
}

export default useMenuItems;

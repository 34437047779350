import { useMemo } from 'react';

import { useSelector } from 'editor/src/store/hooks';
import { ECOMMERCE_PAGE_COUNT_CONTROL_NAME } from 'editor/src/store/variants/operation/changeEcommerceVariantPageCountOperation';
import getECommerceProduct from 'editor/src/store/variants/selector/getECommerceProduct';

function usePageCountLimit() {
  const designPageCountLimit = useSelector((state) => state.design.designData?.page_count_limit);
  const ecommerceProduct = useSelector(getECommerceProduct);
  const ecommercePageCountLimit = useMemo(() => {
    if (!ecommerceProduct) {
      return undefined;
    }

    const pageCountControlIndex = ecommerceProduct.options.findIndex(
      (option) => option.name === ECOMMERCE_PAGE_COUNT_CONTROL_NAME,
    );
    if (pageCountControlIndex === -1) {
      return undefined;
    }

    const sortedPagesCount = ecommerceProduct.variants
      .map((variant) => parseInt(variant.optionValues[pageCountControlIndex], 10))
      .sort((a, b) => a - b);
    return { min: sortedPagesCount[0], max: sortedPagesCount[sortedPagesCount.length - 1] };
  }, [ecommerceProduct]);

  return useMemo(() => {
    if (designPageCountLimit && ecommercePageCountLimit) {
      return {
        min: Math.max(designPageCountLimit.min, ecommercePageCountLimit.min),
        max: Math.min(designPageCountLimit.max, ecommercePageCountLimit.max),
      };
    }

    return designPageCountLimit || ecommercePageCountLimit;
  }, [designPageCountLimit, ecommercePageCountLimit]);
}

export default usePageCountLimit;

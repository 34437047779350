import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import deleteConditionOperation from 'editor/src/store/design/operation/deleteConditionOperation';
import updateConditionActiveOptionOperation from 'editor/src/store/design/operation/updateConditionActiveOptionOperation';
import getConditionChildrenKey from 'editor/src/store/design/reducer/getConditionChildrenKey';
import { ConditionGroup, Condition } from 'editor/src/store/design/types';
import removeAllSelectedMediaElementsOperation from 'editor/src/store/editor/operation/removeAllSelectedMediaElementsOperation';
import { useDispatch } from 'editor/src/store/hooks';

import { logEvent } from 'editor/src/amplitude';

import ConditionElements from 'editor/src/component/ConditionGroupBuilder/ConditionElements';
import useDragHandle from 'editor/src/component/ConditionGroupBuilder/ConditionElements/ReorganizableList/useDragHandle';
import useOverlay from 'editor/src/component/ConditionGroupBuilder/Overlay/useOverlay';
import IconBin from 'editor/src/component/Icon/IconBin';
import IconEdit from 'editor/src/component/Icon/IconEdit';
import PersonalizationElements from 'editor/src/component/PersonalizationContent/PersonalizationElements/index';
import SettingsMenu from 'editor/src/component/PersonalizationContent/PersonalizationElements/SettingsMenu';
import SettingsMenuItem from 'editor/src/component/PersonalizationContent/PersonalizationElements/SettingsMenu/SettingsMenuItem';
import useFocusElementOnFlag from 'editor/src/component/PersonalizationContent/PersonalizationElements/useFocusElementOnFlag';

import ConditionOptions from './ConditionOptions';
import ConditionSettings from './ConditionSettings';

import styles from './index.module.scss';

interface Props {
  spreadIndex: number;
  conditionGroup: ConditionGroup;
  condition: Condition;
  isBuilding: boolean;
  textOnly: boolean;
  onConditionCreated?: (conditionId: string, edit: () => void) => void;
}

function PersonalizationCondition({
  condition,
  spreadIndex,
  conditionGroup,
  isBuilding,
  onConditionCreated,
  textOnly,
}: Props) {
  const children = conditionGroup.children[getConditionChildrenKey(condition.id, condition.activeOptionId)] || [];
  const dispatch = useDispatch();
  const dragHandle = useDragHandle();
  const { t } = useTranslation();

  const conditionEditorOverlay = useOverlay();
  const divRef = useRef<HTMLDivElement>(null);

  function onSelectOption(conditionId: string, optionId: string) {
    dispatch(updateConditionActiveOptionOperation({ spreadIndex, conditionId }, optionId));
  }

  function onEdit() {
    dispatch(removeAllSelectedMediaElementsOperation());
    const rect = divRef.current?.getBoundingClientRect();
    conditionEditorOverlay.open(rect);
  }

  function onDelete() {
    dispatch(deleteConditionOperation({ spreadIndex, conditionId: condition.id }));
    logEvent('condtion deleted', undefined);
  }

  useFocusElementOnFlag(conditionEditorOverlay.visible, divRef);

  useEffect(() => {
    onConditionCreated?.(condition.id, onEdit);
  }, []);

  return (
    <div
      ref={divRef}
      className={cn(styles.PersonalizationCondition, 'cy-personalization-condition', {
        [styles.isEditing]: conditionEditorOverlay.visible,
        [styles.static]: !isBuilding,
      })}
    >
      <div className={cn({ [styles.content]: isBuilding }, styles.condition)} onMouseDown={dragHandle.onMouseDown}>
        <div className={cn(styles.name, 'cy-personalization-condition-name')}>
          {condition.name}
          {isBuilding && (
            <>
              <SettingsMenu className={cn(styles.menuBtn, 'cy-more-button-personalization')}>
                <SettingsMenuItem className={cn(styles.button, 'cy-edit')} onClick={onEdit}>
                  <IconEdit className={styles.editIcon} />
                  {t('editor-edit')}
                </SettingsMenuItem>

                <SettingsMenuItem className={cn(styles.button, 'cy-remove')} onClick={onDelete}>
                  <IconBin />
                  {t('editor-remove')}
                </SettingsMenuItem>
              </SettingsMenu>
              {conditionEditorOverlay.render(
                t('editor-condition-settings'),
                <ConditionSettings condition={condition} conditionGroup={conditionGroup} spreadIndex={spreadIndex} />,
              )}
            </>
          )}
        </div>
        <ConditionOptions condition={condition} onSelectOption={onSelectOption} />
      </div>
      {isBuilding ? (
        <ConditionElements
          spreadIndex={spreadIndex}
          conditionGroup={conditionGroup}
          parentCondition={condition}
          elements={children}
          isEditing={conditionEditorOverlay.visible}
          textOnly={textOnly}
          className={styles.conditionElement}
        />
      ) : (
        <PersonalizationElements
          spreadIndex={spreadIndex}
          conditionGroup={conditionGroup}
          elements={children}
          personalizationElementClassName={styles.conditionElement}
        />
      )}
    </div>
  );
}

export default React.memo(PersonalizationCondition);

import { combineReducers } from '@reduxjs/toolkit';

import addonsReducer from './addons/slice';
import formatsReducer from './formats/slice';
import layoutsReducer from './layouts/slice';
import panAndZoomReducer from './panAndZoom/slice';
import previewReducer from './preview/slice';
import sidebarReducer from './sidebar/slice';
import templatesReducer from './templates/slice';
import undoRedoReducer from './undoRedo/slice';
import warningsReducer from './warnings/slice';

const editorModulesReducer = combineReducers({
  addons: addonsReducer,
  formats: formatsReducer,
  warnings: warningsReducer,
  panAndZoom: panAndZoomReducer,
  preview: previewReducer,
  sidebar: sidebarReducer,
  undoRedo: undoRedoReducer,
  layouts: layoutsReducer,
  templates: templatesReducer,
});

export default editorModulesReducer;

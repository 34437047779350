import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { setMockupDownloadingAction } from 'editor/src/store/mockup/slice';
import { PreviewFormat, Scene } from 'editor/src/store/mockup/types';

const onMockupDownloadReadyOperation =
  (url: string, format: PreviewFormat) => async (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const { categories, selectedSceneId } = state.mockup.scenes;
    let selectedScene: Scene | undefined;
    categories.some((category) => {
      selectedScene = category.scenes.find((scene) => scene.id === selectedSceneId);
      return !!selectedScene;
    });

    dispatch(setMockupDownloadingAction(false));

    const blob = await fetch(url).then((res) => res.blob());
    const localUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = localUrl;
    a.download = selectedScene ? `${selectedScene.name}.${format}` : `mockup.${format}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(localUrl);
  };

export default onMockupDownloadReadyOperation;

import type { Thunk } from 'editor/src/store/hooks';

import { PopupName } from '../../../component/Popup/popups';
import sendPostMessage from '../../../util/postMessages/sendPostMessage';
import openPopupOperation from '../../app/module/popup/operation/openPopupOperation';
import { batch } from '../../batchedSubscribeEnhancer';
import { DesignData } from '../../design/types';
import setSidebarActiveTabByNameOperation from '../../editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from '../../editorModules/sidebar/types';
import setSelectedVariationGroupOperation from '../../variants/operation/setSelectedVariationGroupOperation';

const checkVariantsForErrorsOperation = (): Thunk => {
  return (dispatch, getState, { i18n }) => {
    const getPopupData = (onConfirm: () => void) => {
      return {
        popupTitle: i18n.t('Foil not applied'),
        textLines: [
          i18n.t('We noticed that some product variants have foil selected, but no foil is applied to any layer.'),
          i18n.t('Go to the finishes section and select the layers where you want foil applied.'),
        ],
        options: [
          { title: i18n.t('editor-cancel') },
          {
            title: i18n.t('Select foil layers'),
            onConfirm,
          },
        ],
      };
    };
    const hasUnAppliedFoilInDesign = (designData: DesignData | undefined) =>
      designData?.spreads.every(
        (spread) =>
          spread.spot_finishing_type && !spread.pages[0].groups.media?.some((media) => media.has_spot_finishing),
      );

    const state = getState();
    const { designData } = state.design;

    const { variationGroups } = state.variants;

    const variationWithUnAppliedFoil = variationGroups.find((variationGroup) =>
      variationGroup.variationsInfo.some((variation) => hasUnAppliedFoilInDesign(variation.designData)),
    );

    const designWithUnAppliedFoil = hasUnAppliedFoilInDesign(designData);

    if (variationWithUnAppliedFoil || designWithUnAppliedFoil) {
      dispatch(
        openPopupOperation(
          PopupName.LOCAL_CONFIRMATION_POPUP,
          getPopupData(() => {
            batch(() => {
              variationWithUnAppliedFoil &&
                dispatch(setSelectedVariationGroupOperation(variationWithUnAppliedFoil.key));
              dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.FOIL_LAYERS));
            });
          }),
        ),
      );
    } else {
      sendPostMessage('editor.continueButtonClickSuccess', undefined);
    }
  };
};

export default checkVariantsForErrorsOperation;

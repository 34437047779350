import React from 'react';

import requestPreviewsOperation from 'editor/src/store/editorModules/preview/operation/requestPreviewsOperation';
import getThe3dPreviewAvailable from 'editor/src/store/editorModules/preview/selector/getThe3dPreviewAvailable';
import { PreviewType } from 'editor/src/store/editorModules/preview/types';
import { useDispatch } from 'editor/src/store/hooks';

import Icon3d from 'editor/src/component/Icon/Icon3d';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection }) => !hasSelection && getThe3dPreviewAvailable(state);

type Props = Pick<MenuItemProps, 'mode'>;

function Button3dPreview({ mode }: Props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(requestPreviewsOperation(PreviewType.PREVIEW_3D));
  };

  return (
    <MenuButton
      Icon={Icon3d}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-3D-model"
      className="cy-button-3d-preview"
    />
  );
}

export default React.memo(Button3dPreview);

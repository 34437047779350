import { ShutterstockKeys } from 'editor/src/environment/types';

import { FilterOption, SearchPageConfig } from './types';

export const DEFAULT_CURRENCY = 'USD';

export enum ShutterstockAssetProviderType {
  essential = 'shutterstock-essentials',
  premium = 'shutterstock-full',
}

export enum ShutterstockLicenseType {
  commercial = 'commercial',
  editorial = 'editorial', // Do not use this license type for commercial purposes.
}

export enum ShutterstockImageOrientation {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export enum ShutterstockFeeType {
  dynamic = 'dynamic',
  fixed = 'fixed',
}

export enum ShutterstockImageType {
  photo = 'photo',
  illustration = 'illustration',
  vector = 'vector',
}

export const ShutterstockAssetProviderKeys: Record<ShutterstockAssetProviderType, keyof ShutterstockKeys> = {
  [ShutterstockAssetProviderType.essential]: 'essentials',
  [ShutterstockAssetProviderType.premium]: 'full',
};

export const filterOptions: FilterOption[] = [
  {
    key: 'imageType',
    label: 'shutterstock-image-type',
    options: [
      {
        key: ShutterstockImageType.photo,
        value: ShutterstockImageType.photo,
        label: 'shutterstock-image-type-photo',
      },
      {
        key: ShutterstockImageType.illustration,
        value: ShutterstockImageType.illustration,
        label: 'shutterstock-image-type-illustration',
      },
      {
        key: ShutterstockImageType.vector,
        value: ShutterstockImageType.vector,
        label: 'shutterstock-image-type-vector',
      },
      {
        key: 'ALL',
        value: undefined,
        label: 'shutterstock-all',
      },
    ],
  },
  {
    key: 'orientation',
    label: 'shutterstock-orientation',
    options: [
      {
        key: ShutterstockImageOrientation.horizontal,
        value: ShutterstockImageOrientation.horizontal,
        label: 'shutterstock-orientation-horizontal',
      },
      {
        key: ShutterstockImageOrientation.vertical,
        value: ShutterstockImageOrientation.vertical,
        label: 'shutterstock-orientation-vertical',
      },
      {
        key: 'ALL',
        value: undefined,
        label: 'shutterstock-all',
      },
    ],
  },
];

export const searchPageConfig: SearchPageConfig = {
  name: 'searchPage',
  props: {
    mediaType: 'images',
    dynamicTitle: true,
    dynamicSubtitle: true,
    showMore: true,
    showSearchBar: false,
    assetsPerPage: 26,
    languageCode: 'en',
    license: [ShutterstockLicenseType.commercial],
    theme: {
      widgetBody: 'cy-shutterstock-widget-body',
      header: {
        container: 'cy-header-container',
        title: 'cy-header-title',
        subtitle: 'cy-header-subtitle',
      },
      pagination: {
        container: 'cy-pagination-container',
        showMore: 'cy-pagination-show-more-button',
      },
      emptySearchResults: {
        container: 'cy-empty-search-results-container',
        title: 'cy-empty-search-results-title',
      },
    },
  },
};

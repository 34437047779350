import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import { InboundPostMessage } from 'editor/src/util/postMessages/messages';

import { RootState } from '../..';

import updateMediaElementOperation, { MediaUpdateActionName } from './updateMediaElementOperation';

const setQuantizedAssetErrorOperation =
  (data: InboundPostMessage['design.setQuantizedAssetError']) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    batch(() => {
      const state = getState();
      state.design.designData?.spreads.forEach((spread, spreadIndex) => {
        spread.pages[0].groups.media?.forEach((media, elementIndex) => {
          if (media.type === 'image' && media.imageId === data.assetId) {
            dispatch(
              updateMediaElementOperation(
                { spreadIndex, pageIndex: 0, elementIndex },
                { digitizing: undefined },
                MediaUpdateActionName.DIGITIZING,
              ),
            );
          }
        });
      });
    });
  };

export default setQuantizedAssetErrorOperation;

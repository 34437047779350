import React from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyDesignWarning } from 'editor/src/store/editorModules/warnings/types';

import AbstractWarningItem from './AbstractWarningItem';

interface Props {
  warning: EmptyDesignWarning;
}

function EmptyDesignWarningItem({ warning }: Props) {
  const { t } = useTranslation();

  return (
    <AbstractWarningItem
      isSelectable={false}
      warning={warning}
      selected={false}
      title={t('Empty design')}
      subtitle={t('editor-design-empty-description')}
    />
  );
}

export default React.memo(EmptyDesignWarningItem);

import { useCallback } from 'react';

import { MediaText } from 'editor/src/store/design/types';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import { useDispatch } from 'editor/src/store/hooks';

import useMediaElementLiveUpdates from './useMediaElementLiveUpdates';

export const useFontSizeActions = (element: MediaText) => {
  const dispatch = useDispatch();
  const { liveUpdate } = useMediaElementLiveUpdates(element);

  const onFontSizeChange = useCallback(
    (fontSize: number) => {
      liveUpdate({ ...element, extra: { ...element.extra, fontSize } });
    },
    [element, liveUpdate],
  );

  const updateElementFontSize = useCallback((size: number) => {
    dispatch(applyFontStylesToSelectionOperation({ fontSize: size }));
  }, []);

  return {
    onFontSizeChange,
    updateElementFontSize,
  };
};

import { Area } from './getNewElementDisplay';

function getNewAddonDisplaySize(area: Area, imageWidth: number, imageHeight: number) {
  let width = 0;
  let height = 0;

  const minAreaDimension = Math.min(area.width, area.height);
  if (imageWidth > imageHeight) {
    width = minAreaDimension / 2;
    height = (width * imageHeight) / imageWidth;
  } else {
    height = minAreaDimension / 2;
    width = (height * imageWidth) / imageHeight;
  }

  return { width, height };
}

export default getNewAddonDisplaySize;

import { MultiOptions, VariantInfo, VariationProductControl } from 'editor/src/store/variants/types';

const getUnavailableValues = (
  allSelectedOptions: MultiOptions,
  groupBy: string[],
  productControls: VariationProductControl[],
  variationsInfo: VariantInfo[],
) => {
  if (!groupBy.length) {
    return null;
  }

  const joinedValues = productControls.reduce<string[]>((values, control) => {
    const valuesName = values.map((value) => control.options.find((option) => option.value === value)?.title ?? value);
    if (groupBy.includes(control.key)) {
      return valuesName;
    }

    const selectedOptions = allSelectedOptions[control.key];
    if (!selectedOptions) {
      return valuesName;
    }

    const optionValues = variationsInfo.reduce<Set<string>>((acc, variationInfo) => {
      const value = variationInfo.variation[control.key];
      if (value) {
        acc.add(value);
      }
      return acc;
    }, new Set<string>());

    const unavailable: string[] = [];
    selectedOptions.forEach((option) => {
      if (!optionValues.has(option.value)) {
        const valueName = control.options.find((o) => o.value === option.value)?.title ?? option.value;
        unavailable.push(valueName);
      }
    });

    if (unavailable.length) {
      values.push(unavailable.join(', '));
    }
    return values;
  }, []);

  return joinedValues.join(' - ');
};

export default getUnavailableValues;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';

import { ThunkDispatch } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/index';
import {
  setActiveGroupAction as setActiveGroupOperation,
  setSearchSortAction,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/slice';

import requestDataOperation from './requestDataOperation';

const applySortOperation = (sort: string) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(setSearchSortAction(sort));
    dispatch(setActiveGroupOperation());
    dispatch(requestDataOperation());
  });
};

export default applySortOperation;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconLayers from 'editor/src/component/Icon/IconLayers';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
  active: boolean;
}

function LayersTab({ pluginName, customTabInfo, active }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  if (isMobile) {
    return null;
  }
  return (
    <Tab
      key={TAB_NAMES.LAYERS}
      name={TAB_NAMES.LAYERS}
      title={t('editor-layers')}
      icon={<IconLayers active={active} />}
      pluginName={pluginName}
      customTabInfo={customTabInfo}
    />
  );
}

export default React.memo(LayersTab);

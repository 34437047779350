import cn from 'classnames';
import React from 'react';

import IconNoColor from 'editor/src/component/Icon/IconNoColor';

import styles from './index.module.scss';

interface Props {
  className?: string;
  label: string;
  active: boolean;
  onReset: () => void;
}

function ResetButton({ label, active, onReset, className }: Props) {
  return (
    <div
      className={cn(className, styles.resetButton, { [styles.active]: active }, 'cy-color-reset-button')}
      onClick={onReset}
    >
      <IconNoColor className="mr-1" />
      {label}
    </div>
  );
}

export default React.memo(ResetButton);

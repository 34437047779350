import isEmpty from 'lodash/isEmpty';

import { DesignData, MediaElement } from 'editor/src/store/design/types';
import { GalleryImage } from 'editor/src/store/gallery/types';
import { Plugin, PluginName, PluginState, PluginType, ShutterstockPluginSource } from 'editor/src/store/plugins/types';
import { getGraphicDataFromElement } from 'editor/src/store/plugins/utils/graphicPluginUtils';
import { getImageManipulationPluginData } from 'editor/src/store/plugins/utils/imageManipulationUtils';

import { personalizationPluginNames } from '../../variants/operation/updateProductPersonalizationOperation';

import applyPersonalizationPluginsByCondition from './applyPersonalizationPluginsByCondition';
import containsImageFilters from './containsImageFilters';
import containsPremiumFont from './containsPremiumFont';

const SHUTTERSTOCK_ESSENTIAL_PLUGIN = 'app-shutterstock-essential';
const SHUTTERSTOCK_FULL_PLUGIN = 'app-shutterstock-full';

const getAppliedPlugins = (
  design: DesignData,
  plugins: Plugin[],
  images: GalleryImage[],
  globalPlugins: string[],
): Set<string> => {
  let appliedPluginNames = new Set<string | PluginName>();
  const notAppliedShutterstockSources = new Set([SHUTTERSTOCK_ESSENTIAL_PLUGIN, SHUTTERSTOCK_FULL_PLUGIN]);
  const notAppliedPlugins = plugins.reduce((map, plugin) => {
    if (plugin.state === PluginState.ENABLED || plugin.state === PluginState.NON_FUNCTIONAL) {
      map.set(plugin.name, plugin);
    }
    return map;
  }, new Map<PluginName | string, Plugin>());
  let hasPersonalizationText = false;
  let hasPersonalizableLayer = false;

  design.spreads.forEach((spread) => {
    // remove advanced personalisation plugin if there are no conditions
    // TODO remove this after cleanup from apps n services
    if (notAppliedPlugins.get(PluginName.AdvancedPersonalisation) && !isEmpty(spread.conditionGroup?.conditions)) {
      appliedPluginNames.add(PluginName.AdvancedPersonalisation);
      notAppliedPlugins.delete(PluginName.AdvancedPersonalisation);
    }

    if (notAppliedPlugins.get(PluginName.personalizationStudio) && !isEmpty(spread.conditionGroup?.conditions)) {
      // if condition is present, that means we have personalization layer present.
      hasPersonalizableLayer = true;
    }

    spread.pages[0].groups.media?.forEach((media) => {
      if (media.type === 'text' && !media.personalizationLocked) {
        hasPersonalizationText = true;
      }

      if (!media.personalizationLocked) {
        hasPersonalizableLayer = true;
      }
      const currentMediaPlugins = getMediaPlugins(notAppliedPlugins, images, media);
      if (!currentMediaPlugins.size) {
        return;
      }

      // delete applied plugins from notAppliedPlugins
      currentMediaPlugins.forEach((pluginName) => {
        appliedPluginNames.add(pluginName);
        if (
          pluginName !== PluginName.Shutterstock &&
          pluginName !== SHUTTERSTOCK_ESSENTIAL_PLUGIN &&
          pluginName !== SHUTTERSTOCK_FULL_PLUGIN
        ) {
          notAppliedPlugins.delete(pluginName);
        }
      });

      // delete shutterstock from notAppliedPlugins if all sources are applied
      if (currentMediaPlugins.has(PluginName.Shutterstock)) {
        if (currentMediaPlugins.has(SHUTTERSTOCK_ESSENTIAL_PLUGIN)) {
          notAppliedShutterstockSources.delete(SHUTTERSTOCK_ESSENTIAL_PLUGIN);
        } else if (currentMediaPlugins.has(SHUTTERSTOCK_FULL_PLUGIN)) {
          notAppliedShutterstockSources.delete(SHUTTERSTOCK_FULL_PLUGIN);
        }

        if (!notAppliedShutterstockSources.size) {
          notAppliedPlugins.delete(PluginName.Shutterstock);
        }
      }
    });
  });

  const personalizationPluginApplied = globalPlugins.find((plugin) =>
    personalizationPluginNames.has(plugin as PluginName),
  );

  appliedPluginNames = applyPersonalizationPluginsByCondition(
    hasPersonalizableLayer,
    hasPersonalizationText,
    Boolean(design.personalizationSettings?.allowAddElements),
    globalPlugins,
    personalizationPluginApplied,
    appliedPluginNames,
    plugins,
  );

  return appliedPluginNames;
};

export const getMediaPlugins = (
  activePlugins: Map<PluginName | string, Plugin>,
  images: GalleryImage[],
  media: MediaElement,
) => {
  const currentMediaPlugins = new Set<string | PluginName>();
  activePlugins.forEach((plugin, pluginName) => {
    if (isPluginApplied(plugin, media)) {
      currentMediaPlugins.add(pluginName);
      return;
    }

    // as we provide additional data with shutterstock plugin, process it separately
    if (pluginName === PluginName.Shutterstock && media.type === 'image') {
      const galleryImage = images.find((image) => image.id === media.imageId);
      if (galleryImage?.source === ShutterstockPluginSource.Essentials) {
        currentMediaPlugins.add(SHUTTERSTOCK_ESSENTIAL_PLUGIN).add(PluginName.Shutterstock);
      } else if (galleryImage?.source === ShutterstockPluginSource.Full) {
        currentMediaPlugins.add(SHUTTERSTOCK_FULL_PLUGIN).add(PluginName.Shutterstock);
      }
    }
  });

  return currentMediaPlugins;
};

const isPluginApplied = (plugin: Plugin, media: MediaElement) => {
  let isPluginApplied = false;
  switch (plugin.name) {
    case PluginName.Filters:
      isPluginApplied = containsImageFilters(media);
      break;
    case PluginName.Graphics:
      isPluginApplied = !!getGraphicDataFromElement(media);
      break;
    case PluginName.AdvancedPersonalisation:
      isPluginApplied = media.type === 'image' && !!media.plugins?.[PluginName.AdvancedPersonalisation];
      break;
    case PluginName.Fonts:
      isPluginApplied = containsPremiumFont(media);
      break;
    default:
      break;
  }

  if (media.type === 'image') {
    switch (plugin.type) {
      case PluginType.IMAGE_MANIPULATION:
        isPluginApplied = getImageManipulationPluginData(plugin.name, media)?.imageModified === true;
        break;
      case PluginType.ASSETS_SOURCE:
        isPluginApplied = !!(media.plugins && media.plugins[plugin.name]?.assetId);
        break;
      default:
        break;
    }
  }

  return isPluginApplied;
};

export default getAppliedPlugins;

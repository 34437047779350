/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */

import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

function IconCollections({ className }: Props) {
  return (
    <Icon className={cn(styles.iconCollections, className)}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.0611 5.43934C20.4753 4.85355 19.5255 4.85355 18.9397 5.43934L14.0306 10.3484C13.4449 10.9342 13.4449 11.884 14.0306 12.4698L18.9397 17.3788C19.5255 17.9646 20.4753 17.9646 21.0611 17.3788L25.9701 12.4698C26.5559 11.884 26.5559 10.9342 25.9701 10.3484L21.0611 5.43934ZM20.0004 14.1969L17.2126 11.4091L20.0004 8.62132L22.7882 11.4091L20.0004 14.1969Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4697 14.0303C11.884 13.4445 10.9342 13.4445 10.3484 14.0303L5.43934 18.9394C4.85355 19.5252 4.85355 20.4749 5.43934 21.0607L10.3484 25.9698C10.9342 26.5556 11.884 26.5556 12.4697 25.9698L17.3788 21.0607C17.9646 20.4749 17.9646 19.5252 17.3788 18.9394L12.4697 14.0303ZM11.4091 22.7878L8.62132 20L11.4091 17.2123L14.1969 20L11.4091 22.7878Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.0611 22.6212C20.4753 22.0354 19.5255 22.0354 18.9397 22.6212L14.0306 27.5303C13.4449 28.1161 13.4449 29.0659 14.0306 29.6516L18.9397 34.5607C19.5255 35.1465 20.4753 35.1465 21.0611 34.5607L25.9701 29.6516C26.5559 29.0659 26.5559 28.1161 25.9701 27.5303L21.0611 22.6212ZM20.0004 31.3788L17.2126 28.591L20.0004 25.8032L22.7882 28.591L20.0004 31.3788Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.5302 14.0303C28.1159 13.4445 29.0657 13.4445 29.6515 14.0303L34.5606 18.9394C35.1464 19.5252 35.1464 20.4749 34.5606 21.0607L29.6515 25.9698C29.0657 26.5556 28.1159 26.5556 27.5302 25.9698L22.6211 21.0607C22.0353 20.4749 22.0353 19.5252 22.6211 18.9394L27.5302 14.0303ZM25.803 20L28.5908 22.7878L31.3786 20L28.5908 17.2123L25.803 20Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default React.memo(IconCollections);

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import copyMediaElementOperation from 'editor/src/store/design/operation/copyMediaElementOperation';
import removeMediaElementOperation from 'editor/src/store/design/operation/removeMediaElementOperation';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { MediaElement } from 'editor/src/store/design/types';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import isRemoveElementsAllowed from 'editor/src/store/editor/selector/isRemoveElementsAllowed';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import DropDown from 'editor/src/component/DropDown';
import IconBin from 'editor/src/component/Icon/IconBin';
import IconCopy from 'editor/src/component/Icon/IconCopy';
import IconEdit from 'editor/src/component/Icon/IconEdit';
import IconMore from 'editor/src/component/Icon/IconMore';
import IconMoreActive from 'editor/src/component/Icon/IconMoreActive';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  element: MediaElement;
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
  onEdit?: () => void;
}

function ButtonMore({ element, onEdit, isVisible, setVisible }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { structureIndex, addElementsAllowed, allowRemove } = useSelector(
    (state) => ({
      structureIndex: getStructureIndexByElementUuidMemoized(state, element.uuid),
      addElementsAllowed: isAddElementsAllowed(state),
      allowRemove: isRemoveElementsAllowed(state),
    }),
    shallowEqual,
  );

  if (!structureIndex) {
    return null;
  }

  const hideDropdown = () => {
    setVisible(false);
  };

  const handleClick = () => {
    if (isVisible) {
      hideDropdown();
    } else {
      setVisible(true);
    }
  };

  const handleCopyClick = () => {
    dispatch(copyMediaElementOperation(structureIndex));
    hideDropdown();
  };

  const handleRemoveClick = () => {
    dispatch(removeMediaElementOperation(structureIndex, true));
    hideDropdown();
  };

  const handleEditClick = () => {
    hideDropdown();
    onEdit?.();
  };

  const allowAdd = addElementsAllowed && !element.unduplicable;
  if (!allowAdd && !allowRemove && !onEdit) {
    return null;
  }

  return (
    <div className={cn(styles.moreButton, 'cy-layer-more-button')}>
      <WithTooltip overlay={t('editor-more-options')} placement="bottom">
        <ControlButton className={styles.controlButton} onClick={handleClick} on={isVisible} stopPropagation>
          {isVisible ? <IconMoreActive /> : <IconMore />}
        </ControlButton>
      </WithTooltip>
      <DropDown isVisible={isVisible} className={styles.dropDown} onClickOutside={hideDropdown}>
        {onEdit && (
          <ControlButton className={cn(styles.button, 'cy-rename')} onClick={handleEditClick} stopPropagation>
            <div className={styles.editIcon}>
              <IconEdit />
            </div>
            {t('editor-rename')}
          </ControlButton>
        )}
        {allowAdd && (
          <ControlButton className={cn(styles.button, 'cy-duplicate')} onClick={handleCopyClick} stopPropagation>
            <IconCopy />
            {t('editor-duplicate')}
          </ControlButton>
        )}
        {allowRemove && (
          <ControlButton className={cn(styles.button, 'cy-remove')} onClick={handleRemoveClick} stopPropagation>
            <IconBin />
            {t('editor-remove')}
          </ControlButton>
        )}
      </DropDown>
    </div>
  );
}

export default React.memo(ButtonMore);

import cn from 'classnames';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ListManagerContext } from './useListManager';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  showBorder?: boolean;
  className?: string;
}

type ActionHook = {
  startMove: () => void;
  moveElement: (y: number) => void;
  endMove: () => void;
};

type ReorganizableElementContextType = {
  onMouseDown: (e: React.MouseEvent) => void;
  onUpdate: () => void;
};
export const ReorganizableElementContext = React.createContext<ReorganizableElementContextType>(null as any);

const MIN_DIST = 5;

function ReorganizableElement({ children, className, showBorder }: Props) {
  const manager = useContext(ListManagerContext);
  const divRef = useRef<HTMLDivElement>(null);
  const dragMarkerRef = useRef<HTMLDivElement>(null);
  const initPos = useRef(0);
  const started = useRef(false);
  const [height, setHeight] = useState(0);
  const [positioned, setPositioned] = useState(false);
  const currentY = useRef(0);
  const [y, setY] = useState(-1);
  const actionRef = useRef<ActionHook>();

  useEffect(() => {
    currentY.current = y;
  }, [y]);

  useEffect(() => {
    const { unregister, startMove, moveElement, endMove } = manager.register({
      getHeight: () => divRef.current?.clientHeight || 0,
      setY,
      isDragging: (dragging) => dragMarkerRef.current?.classList.toggle(styles.showBorder, dragging),
    });
    actionRef.current = { startMove, moveElement, endMove };
    return () => unregister();
  }, [manager]);

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!divRef.current) {
        return;
      }

      if (!started.current) {
        if (Math.abs(e.clientY - initPos.current) < MIN_DIST) {
          return;
        }
        started.current = true;
        actionRef.current?.startMove();
        divRef.current.style.transition = 'none';
        divRef.current.style.zIndex = '1';
      }

      const totalHeight = manager.getAvailableHeight();
      let pos = currentY.current + e.clientY - initPos.current;
      if (pos < 0) {
        pos /= 4;
      } else if (pos > totalHeight) {
        pos = totalHeight + (pos - totalHeight) / 4;
      }

      actionRef.current?.moveElement(pos);
      divRef.current.style.transform = `translate3d(0,${pos}px,0)`;
    },
    [manager],
  );

  const onClickCapture = useCallback((e: Event | React.MouseEvent) => {
    if (started.current) {
      e.stopPropagation();
      e.preventDefault();
    }
  }, []);

  const onMouseUp = useCallback(
    (e: MouseEvent) => {
      if (started.current) {
        const newY = Math.max(0, currentY.current + e.clientY - initPos.current);
        setY(newY);
        if (divRef.current) {
          divRef.current.style.transform = `translate3d(0,${newY}px,0)`;
          divRef.current.style.transition = '';
          divRef.current.style.zIndex = '';
        }
        actionRef.current?.endMove();
      }
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mouseleave', onMouseUp);
      window.removeEventListener('click', onClickCapture, true);
    },
    [onMouseMove, manager],
  );

  const onMouseDown = useCallback(
    (e: React.MouseEvent) => {
      started.current = false;
      if (!(e.target instanceof Element) || e.target.closest('input, button, select, textarea')) {
        return;
      }

      initPos.current = e.clientY;
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('mouseleave', onMouseUp);
      window.addEventListener('click', onClickCapture, true);
    },
    [onMouseMove, onMouseUp],
  );

  useEffect(
    () => () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mouseleave', onMouseUp);
      window.removeEventListener('click', onClickCapture, true);
    },
    [onMouseMove],
  );

  function updateHeight() {
    const divHeight = divRef.current?.clientHeight || 0;
    setHeight(divHeight > 0 ? divHeight + manager.margin : 0);
  }

  useEffect(updateHeight);

  useEffect(() => manager.update(), [height]);

  useEffect(() => {
    const handle = window.setTimeout(() => setPositioned(y !== -1), 0);
    return () => window.clearTimeout(handle);
  }, [y === -1]);

  const contentElt = useMemo(
    () => ({
      onMouseDown,
      onUpdate: updateHeight,
    }),
    [onMouseDown, manager.margin],
  );

  return (
    <ReorganizableElementContext.Provider value={contentElt}>
      <div
        onClickCapture={onClickCapture}
        className={cn(styles.Element, className, {
          [styles.positioned]: positioned,
        })}
        ref={divRef}
        style={{ transform: `translate3d(0,${y}px,0)` }}
      >
        <div className={styles.children}>{children}</div>
        {height > 0 && (
          <div
            className={cn(styles.dragMarker, {
              [styles.showBorder]: showBorder,
            })}
            ref={dragMarkerRef}
          />
        )}
      </div>
      <div className={styles.placeholder} style={{ height: `${height}px` }} />
    </ReorganizableElementContext.Provider>
  );
}

export default ReorganizableElement;

import React from 'react';

import styles from './index.module.scss';

interface Props {
  data: {
    rows: Array<{
      cells: Array<string>;
    }>;
  };
  rotate?: boolean;
}

function Table({ data, rotate = false }: Props) {
  let rows = data.rows.map((row) => row.cells);
  if (rotate) {
    const columns = Math.max(...data.rows.map((row) => row.cells.length));
    rows = [];
    for (let i = 0; i < columns; i += 1) {
      rows.push(data.rows.map((row) => row.cells[i]));
    }
  }
  return (
    <table className={styles.table}>
      {rows.map((row, i) => (
        <tbody key={i}>
          <tr>{row.map((cell, j) => (i === 0 || j === 0 ? <th key={j}>{cell}</th> : <td key={j}>{cell}</td>))}</tr>
        </tbody>
      ))}
    </table>
  );
}

export default React.memo(Table);

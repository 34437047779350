import { useRef, useEffect, useState } from 'react';

import { DraggableItem } from 'editor/src/store/editor/types';
import { useSelector } from 'editor/src/store/hooks';

import { DROP_EVENT } from './useDrag';

function useOnDrop(onDrop: (draggedItem: DraggableItem) => void) {
  const elementRef = useRef<HTMLDivElement>(null);
  const isDragging = useSelector((state) => state.editor.dragState === 'local');
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  useEffect(() => {
    if (!elementRef.current) {
      return undefined;
    }
    function onEnter() {
      setIsDraggingOver(true);
    }

    function onLeave() {
      setIsDraggingOver(false);
    }

    elementRef.current.addEventListener('mouseenter', onEnter);
    elementRef.current.addEventListener('mouseleave', onLeave);
    return () => {
      elementRef.current?.removeEventListener('mouseenter', onEnter);
      elementRef.current?.removeEventListener('mouseleave', onLeave);
    };
  }, [isDragging]);

  useEffect(() => {
    if (!elementRef.current || !isDragging) {
      return undefined;
    }
    function onCustomDrop(e: Event) {
      onDrop((e as CustomEvent<DraggableItem>).detail);
    }

    elementRef.current.addEventListener(DROP_EVENT, onCustomDrop);
    return () => {
      elementRef.current?.removeEventListener(DROP_EVENT, onCustomDrop);
    };
  }, [isDragging]);

  return { elementRef, isDraggingOver, isDragging };
}

export default useOnDrop;

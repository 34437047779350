import './App.module.scss';
import React, { Suspense, useEffect } from 'react';
import 'react-redux-toastr/src/styles/index.scss';
import ReduxToastr from 'react-redux-toastr';

import OverlayContainer from './component/ConditionGroupBuilder/Overlay/OverlayContainer';
import DropDownContainer from './component/DropDown/DropDownContainer';
import { BottomBarHeightProvider } from './component/EditorArea/EditorAreaControls/BottomControls/BottomBarHeightProvider';
import AbstractPopupContainer from './component/Popup/AbstractPopup/AbstractPopupContainer';
import ApplyTemplatePopup from './component/Popup/ApplyTemplatePopup';
import ConfirmationPopup from './component/Popup/ConfirmationPopup';
import LocalConfirmationPopup from './component/Popup/LocalConfirmationPopup';
import WarningsPopup from './component/Popup/WarningsPopup';
import PostMessageReceiver from './component/PostMessageReceiver';
import useDetectDeviceType, { DeviceTypeContext } from './component/useDetectDeviceType';
import TooltipContainer from './component/WithTooltip/TooltipContainer';
import useDefaultFontPreloader from './store/useDefaultFontPreloader';
import useDesignWatcher from './store/watchers/useDesignWatcher';
import useVariantWatcher from './store/watchers/useVariantWatcher';
import useWarningWatcher from './store/watchers/useWarningWatcher';
import useSetupColorConversion from './util/color/useSetupColorConversion';
import Home from './view/Home';

interface Props {
  localUploader?: true;
}

function App({ localUploader }: Props) {
  const deviceType = useDetectDeviceType();
  useDesignWatcher();
  useVariantWatcher();
  useWarningWatcher();
  useDefaultFontPreloader();
  useSetupColorConversion();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`Gelato Editor Version: ${process.env.REACT_APP_VERSION}`);
  }, []);

  return (
    <DeviceTypeContext.Provider value={deviceType}>
      <Suspense fallback="">
        <BottomBarHeightProvider>
          <Home localUploader={localUploader} />
        </BottomBarHeightProvider>
        <WarningsPopup />
        <ConfirmationPopup />
        <LocalConfirmationPopup />
        <ApplyTemplatePopup />
      </Suspense>
      <ReduxToastr
        timeOut={4000}
        newestOnTop
        preventDuplicates
        position="top-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
      <OverlayContainer />
      <AbstractPopupContainer />
      <DropDownContainer />
      <TooltipContainer />
      <PostMessageReceiver />
    </DeviceTypeContext.Provider>
  );
}

export default React.memo(App);

import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { Spread, SpreadGroundImage, SpreadGroup } from 'editor/src/store/design/types';

const getGroupedSpreadInfo = (
  spreadGroup: SpreadGroup,
  spreads: Spread[],
  spreadBackgrounds?: SpreadGroundImage[],
  spreadForegrounds?: SpreadGroundImage[],
) =>
  spreadGroup.spreadIndexes.map((groupedSpreadIndex, index) => {
    const groupSpread = spreads[groupedSpreadIndex];
    const spreadWidth = getSpreadWidthFromSpread(groupSpread);
    const spreadHeight = getSpreadHeightFromSpread(groupSpread);

    // preview dimensions used for calculating the groupedSpreadOffsets
    const previewWidth = spreadGroup.position === 'horizontal' ? spreadWidth : 0;
    const previewHeight = spreadGroup.position === 'vertical' ? spreadHeight : 0;

    return {
      // used to offset the pages to make them appropriate to the spread grouping.
      spreadOffsetCoords: {
        left: (spreadGroup.spreadIndexes.length - 1 - index) * previewWidth,
        top: (spreadGroup.spreadIndexes.length - 1 - index) * previewHeight,
      },
      backgroundImage: spreadBackgrounds?.find((image) => image.name === groupSpread.name),
      foregroundImage: spreadForegrounds?.find((image) => image.name === groupSpread.name),
      groupSpread,
      spreadWidth,
      spreadHeight,
    };
  });

export default getGroupedSpreadInfo;

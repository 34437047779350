/* eslint-disable max-len */
import React from 'react';

import Icon from './index';

interface Props {
  className?: string;
}

function IconAddCondition({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.75 8.40939C12.0439 8.07636 13 6.90179 13 5.50391C13 3.84705 11.6569 2.50391 10 2.50391C8.34315 2.50391 7 3.84705 7 5.50391C7 6.90179 7.95608 8.07636 9.25 8.40939V9.25391L5.5 9.25391C5.08579 9.25391 4.75 9.58969 4.75 10.0039L4.75 11.5039H3.25C2.83579 11.5039 2.5 11.8397 2.5 12.2539V16.7539C2.5 17.1681 2.83579 17.5039 3.25 17.5039H7.75C8.16421 17.5039 8.5 17.1681 8.5 16.7539L8.5 12.2539C8.5 11.8397 8.16421 11.5039 7.75 11.5039H6.25V10.7539L13.75 10.7539V11.5039H12.25C11.8358 11.5039 11.5 11.8397 11.5 12.2539V16.7539C11.5 17.1681 11.8358 17.5039 12.25 17.5039H16.75C17.1642 17.5039 17.5 17.1681 17.5 16.7539V12.2539C17.5 11.8397 17.1642 11.5039 16.75 11.5039H15.25V10.0039C15.25 9.58969 14.9142 9.25391 14.5 9.25391H10.75V8.40939ZM10 4.00391C10.8284 4.00391 11.5 4.67548 11.5 5.50391C11.5 6.33233 10.8284 7.00391 10 7.00391C9.17157 7.00391 8.5 6.33233 8.5 5.50391C8.5 4.67548 9.17157 4.00391 10 4.00391ZM13 13.0039V16.0039H16V13.0039H13ZM4 13.0039L4 16.0039H7L7 13.0039H4Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default React.memo(IconAddCondition);

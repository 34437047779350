import React from 'react';
import { useTranslation } from 'react-i18next';

import applyImageFillOperation from 'editor/src/store/design/operation/applyImageFillOperation';
import applyImageFitOperation from 'editor/src/store/design/operation/applyImageFitOperation';
import stretchToFitOperation from 'editor/src/store/design/operation/distribute/stretchToFitOperation';
import getStructureIndexesOfSelectedElements from 'editor/src/store/design/selector/getStructureIndexesOfSelectedElements';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import fillLogo from 'editor/src/static/fill.png';
import fitLogo from 'editor/src/static/fit.png';
import stretchLogo from 'editor/src/static/stretch.png';

import FittingItem from './FittingItem';

interface Props {
  fillVisible: boolean;
  fitVisible: boolean;
  stretchVisible: boolean;
}

function FittingsRow({ fillVisible, fitVisible, stretchVisible }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const structureIndexes = useSelector(getStructureIndexesOfSelectedElements);

  const executeImageFit = () => {
    dispatch(applyImageFitOperation(structureIndexes));
  };

  const executeImageFill = () => {
    dispatch(applyImageFillOperation(structureIndexes));
  };

  const executeImageStretch = () => {
    dispatch(stretchToFitOperation(structureIndexes[0]));
  };

  if (!fitVisible && !fillVisible && !stretchVisible) {
    return null;
  }

  return (
    <div className="row-flex row-flex--between">
      {fitVisible && (
        <FittingItem imageUrl={fitLogo} title={t('editor-fit-edit-image')} handleTransformation={executeImageFit} />
      )}
      {fillVisible && (
        <FittingItem imageUrl={fillLogo} title={t('editor-fill-edit-image')} handleTransformation={executeImageFill} />
      )}
      {stretchVisible && (
        <FittingItem imageUrl={stretchLogo} title={t('editor-stretch')} handleTransformation={executeImageStretch} />
      )}
    </div>
  );
}

export default FittingsRow;

import getCurrentDpiLevels from 'editor/src/store/design/selector/getCurrentDpiLevels';
import { getMediaImageDPI } from 'editor/src/store/design/selector/getMediaElementDpi';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { PluginName, ShutterstockPluginSource } from 'editor/src/store/plugins/types';

import { RootState } from 'editor/src/store';
import isShadowEmpty from 'editor/src/util/isShadowEmpty';

import { DesignDataStats } from './types';

function getDesignDataStats(state: RootState): DesignDataStats {
  const imageMimeTypes = new Set<string>();
  const usedFilters = new Set<string>();
  const elements: {
    [type: string]: { count: number; personalizable: number };
  } = {};
  const usedFontFamilies = new Set<string>();
  let conditionCount = 0;
  let lowDPIImageCount = 0;
  let mediumDPIImageCount = 0;
  let textWithNotDefaultLetterSpacingCount = 0;
  let textWithNotDefaultShadowsCount = 0;
  const dpiLevels = getCurrentDpiLevels(state);
  const layouts: string[] = [];

  state.design.designData?.spreads.forEach((spread) => {
    if (spread.conditionGroup) {
      conditionCount += Object.keys(spread.conditionGroup.conditions).length;
    }

    if (spread.layoutSchemaName) {
      layouts.push(spread.layoutSchemaName);
    }

    spread.pages[0]?.groups.media?.forEach((media) => {
      let type = media.type as string;
      if (media.type === 'text') {
        usedFontFamilies.add(media.extra.fontFamily);
        if (media.extra.letterSpacing !== undefined && media.extra.letterSpacing !== 0) {
          textWithNotDefaultLetterSpacingCount += 1;
        }

        if (!isShadowEmpty(media.extra.shadow)) {
          textWithNotDefaultShadowsCount += 1;
        }
      } else if (media.type === 'image') {
        const image = getImageById(state, media.imageId);
        if (image?.type) {
          imageMimeTypes.add(image.type);
        }

        if (media.plugins?.[PluginName.AdvancedPersonalisation] || media.plugins?.[PluginName.personalizationStudio]) {
          type = 'collection';
        } else if (media.plugins?.[PluginName.Graphics]) {
          type = 'graphic';
        } else if (
          image?.source === ShutterstockPluginSource.Essentials ||
          image?.source === ShutterstockPluginSource.Full
        ) {
          type = 'shutterstock';
        }

        if (media.filters) {
          media.filters.forEach((filter) => usedFilters.add(filter.name));
        }

        if (dpiLevels) {
          const dpi = image ? getMediaImageDPI(media, image) : undefined;
          if (dpi !== undefined && dpi <= dpiLevels.low) {
            lowDPIImageCount += 1;
          } else if (dpi !== undefined && dpi <= dpiLevels.medium) {
            mediumDPIImageCount += 1;
          }
        }
      }
      if (!elements[type]) {
        elements[type] = { count: 0, personalizable: 0 };
      }
      elements[type].count += 1;
      elements[type].personalizable += media.personalizationLocked ? 0 : 1;
    });
  });

  const { isVariantFlow, variationGroups } = state.variants;
  const productUids: string[] = [];
  let allLinked = true;
  variationGroups.forEach((group) => {
    group.variationsInfo.forEach((variation) => productUids.push(variation.variation.productUid));
    if (!group.linked) {
      allLinked = false;
    }
  });

  return {
    isEmpty: !state.design.designData?.spreads?.[0]?.pages[0]?.groups.media?.length,
    pluginUsed: state.design.designData?.plugins?.filter((name) => name !== PluginName.Shutterstock) || [],
    elements,
    imageMimeTypes: [...imageMimeTypes],
    usedFontFamilies: [...usedFontFamilies],
    usedFilters: [...usedFilters],
    conditionCount,
    variants: {
      enabled: isVariantFlow,
      productCount: productUids.length,
      productUids,
      allLinked,
    },
    lowDPIImageCount,
    mediumDPIImageCount,
    textWithNotDefaultLetterSpacingCount,
    textWithNotDefaultShadowsCount,
    layouts,
  };
}

export default getDesignDataStats;

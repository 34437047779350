import cn from 'classnames';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import updateMediaElementAdjustmentsOperation, {
  getUpdatedAdjustments,
} from 'editor/src/store/design/operation/updateMediaElementAdjustmentsOperation';
import getAdjustmentByUuid from 'editor/src/store/design/selector/getAdjustmentByUuid';
import { ElementAddress, MediaImage } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPluginIcon from 'editor/src/store/plugins/selector/getPluginIcon';
import { PluginName } from 'editor/src/store/plugins/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import AdjustmentSlider from 'editor/src/component/AdjustmentSlider';
import { AdjustmentType } from 'editor/src/component/DesktopSidebar/TabContents/AdjustmentsTabContent/adjustments';
import { getSanitizeValue } from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/utils';

import styles from './index.module.scss';

interface Props {
  element: MediaImage;
  address: ElementAddress;
  adjustment: AdjustmentType;
  hideName?: boolean;
  disabled?: boolean;
  showPremiumIcon?: boolean;
}

function Adjustment({ adjustment, hideName, disabled, element, address, showPremiumIcon }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const adjustmentValue = useSelector((store) => getAdjustmentByUuid(store, element.uuid, adjustment.name));
  const value = adjustmentValue ?? adjustment.default;
  const [currentValue, setCurrentValue] = useState(value);
  const PremiumIcon = useSelector((state) => getPluginIcon(state, PluginName.Filters));

  useEffect(() => setCurrentValue(value), [value]);

  const { liveUpdate } = useMediaElementLiveUpdates(element);

  const onLiveChange = useCallback(
    (value: number) => {
      const adjustments = getUpdatedAdjustments(element.adjustments, adjustment, value);
      liveUpdate({ ...element, adjustments });
      setCurrentValue(value);
    },
    [address, adjustment, element],
  );

  const onChange = useCallback(
    (value: number) => {
      dispatch(updateMediaElementAdjustmentsOperation(address, adjustment, value));
    },
    [address, adjustment],
  );

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const numberValue = getSanitizeValue(value, adjustment.default, adjustment.min, adjustment.max);
    if (numberValue !== adjustmentValue) {
      dispatch(updateMediaElementAdjustmentsOperation(address, adjustment, numberValue));
    }
  };

  const onDisabledClick = () => {
    sendPostMessage('plugins.disabledPluginClick', {
      name: PluginName.Filters,
      adjustment: adjustment.name,
    });
  };

  return (
    <div
      className={cn(styles.adjustment, `cy-adjustment-${adjustment.name}`)}
      onMouseDown={disabled ? onDisabledClick : undefined}
    >
      {!hideName && (
        <div className={styles.label}>
          <span>{t(adjustment.translationKey)}</span>
          {(disabled || showPremiumIcon) && <PremiumIcon className="cy-premium-icon" />}
        </div>
      )}

      <div className={cn(styles.sliderRow, { [styles.disabled]: disabled })}>
        <div className={styles.icon}>{adjustment.icon}</div>
        <div className={styles.slider}>
          <AdjustmentSlider
            disabled={disabled}
            min={adjustment.min}
            max={adjustment.max}
            step={adjustment.step}
            value={value}
            railBackground={adjustment.railBackground}
            onChange={onLiveChange}
            onAfterChange={onChange}
            className={cn('cy-adjustment-slider', `cy-adjustment-slider-${adjustment.name}`)}
          />
        </div>
        <input
          className={cn(styles.input, 'cy-adjustment-input', `cy-adjustment-input-${adjustment.name}`)}
          value={`${currentValue}`}
          type="text"
          onKeyDown={(e) => e.stopPropagation()}
          onChange={onInputChange}
          onFocus={(e) => (e.target as HTMLInputElement).select()}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default React.memo(Adjustment);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import addMediaElementOperation from 'editor/src/store/design/operation/addMediaElementOperation';
import getNewElementDisplay from 'editor/src/store/design/operation/getNewElementDisplay';
import { MediaRectangle } from 'editor/src/store/design/types';
import getVisibleColorAtElementPosition from 'editor/src/store/design/util/getVisibleColorAtElementPosition';
import { defaultRectangleStyles, getNewRectangleDisplaySize } from 'editor/src/store/design/util/rectangleUtil';
import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import getDefaultPersonalizationLockState from 'editor/src/store/editor/selector/getDefaultPersonalizationLockState';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import { logEvent } from 'editor/src/amplitude';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import { RectConfigurableStyles } from 'editor/src/util/shapePresets';
import { elementUuids } from 'editor/src/util/uuids';

const addNewRectangleToPageOperation =
  (spreadIndex: number, pageIndex: number, styles: RectConfigurableStyles) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();

    const display = getNewElementDisplay(state, spreadIndex, getNewRectangleDisplaySize);
    if (!display) {
      return;
    }

    const { center, width, height } = display;

    const newElementUuid = elementUuids.generate();
    const defaultPersonalizationLockState = getDefaultPersonalizationLockState(state);

    const rectangleElement: MediaRectangle = {
      type: 'rectangle',
      name: `rectangle ${newElementUuid}`,
      uuid: newElementUuid,
      personalizationLocked: defaultPersonalizationLockState || undefined,
      stroke: defaultRectangleStyles.stroke,
      strokeWidth: defaultRectangleStyles.strokeWidth,
      fill: defaultRectangleStyles.fill,
      x: center.x,
      y: center.y,
      width,
      height,
      r: 0,
      ...styles,
    };

    if (!rectangleElement.stroke) {
      rectangleElement.fill = getVisibleColorAtElementPosition(rectangleElement, spreadIndex, state);
    } else {
      rectangleElement.stroke = getVisibleColorAtElementPosition(rectangleElement, spreadIndex, state);
    }

    batch(() => {
      dispatch(addMediaElementOperation(spreadIndex, pageIndex, rectangleElement, true));
      dispatch(addSelectedMediaElementOperation(newElementUuid, false));
      sendPostMessage('log.rectangleAdded', rectangleElement);
      logEvent('element added', { type: 'rectangle' });
    });
  };

export default addNewRectangleToPageOperation;

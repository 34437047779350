import { batch } from 'editor/src/store/batchedSubscribeEnhancer';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import { ThunkDispatch } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/index';
import getGroupId from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getGroupId';
import getRequestVersion from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getRequestVersion';
import getSearchFilters from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getSearchFilters';
import getSearchValue from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getSearchValue';
import {
  increaseRequestVersionAction,
  resetSearchAction,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/slice';
import { ContentPluginRootState } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const requestDataOperation =
  (groupId?: string) => (dispatch: ThunkDispatch, getState: () => ContentPluginRootState) => {
    batch(() => {
      dispatch(resetSearchAction());
      dispatch(increaseRequestVersionAction());

      const state = getState();
      sendPostMessage('plugins.requestData', {
        groupId: groupId !== undefined ? groupId : getGroupId(state),
        requestVersion: getRequestVersion(state),
        search: {
          value: getSearchValue(state),
          filters: getSearchFilters(state),
          sort: state.data.results?.search.sort,
        },
        plugin: state.pluginName,
      });
    });
  };

export default requestDataOperation;

import { useEffect, useMemo } from 'react';

import { Coords, MediaElement, Page, Spread, SpreadInfo } from 'editor/src/store/design/types';
import { useStore } from 'editor/src/store/hooks';

import { convFn } from 'editor/src/util/convFn';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import {
  getContentSnaps,
  Snap,
  mergeSameLineSnaps,
  getBleedSnaps,
  getElementSnaps,
  getMediaboxSnaps,
} from './snapsDataUtils';
import useSnapController from './useSnapController';

function getRectSnaps(
  getSnapFn: (spreadCoords: Coords, mm2px: convFn, pages: Page[], canvasRotation: CanvasRotation) => Snap[],
  spreads: Spread[],
  mm2px: convFn,
  canvasRotation: CanvasRotation,
  spreadsInfo: SpreadInfo[],
) {
  const snaps: Snap[] = [];
  spreadsInfo.forEach((spreadInfo) =>
    snaps.push(...getSnapFn(spreadInfo.spreadCoords, mm2px, spreads[spreadInfo.spreadIndex].pages, canvasRotation)),
  );
  return snaps;
}

function getPagesSnaps(spreads: Spread[], mm2px: convFn, canvasRotation: CanvasRotation, spreadsInfo: SpreadInfo[]) {
  const contentSnaps = getRectSnaps(getContentSnaps, spreads, mm2px, canvasRotation, spreadsInfo);
  const bleedSnaps = getRectSnaps(getBleedSnaps, spreads, mm2px, canvasRotation, spreadsInfo);
  const boxSnaps = getRectSnaps(getMediaboxSnaps, spreads, mm2px, canvasRotation, spreadsInfo);
  const snaps = [...contentSnaps, ...bleedSnaps, ...boxSnaps];
  mergeSameLineSnaps(snaps);
  return snaps;
}

function useSetupSnaps(
  pagesKey: string,
  mm2px: convFn,
  canvasRotation: CanvasRotation,
  media: MediaElement[] | undefined,
  spreadsInfo: SpreadInfo[],
) {
  const store = useStore();
  const snapController = useSnapController();

  const pageSnaps = useMemo(() => {
    const state = store.getState();
    const spreads = state.design.designData?.spreads;

    if (spreads) {
      return getPagesSnaps(spreads, mm2px, canvasRotation, spreadsInfo);
    }

    return [];
  }, [pagesKey, mm2px, canvasRotation, spreadsInfo]);

  useEffect(() => {
    const elementsSnaps = getElementSnaps(spreadsInfo[0].spreadCoords, mm2px, media || [], canvasRotation);
    const snaps = elementsSnaps.length ? [...pageSnaps, ...elementsSnaps] : [];
    // mergeSameLineSnaps(snaps);
    snapController.setSnaps(snaps);
  }, [pageSnaps, media, mm2px, canvasRotation]);
}

export default useSetupSnaps;

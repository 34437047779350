import { fabric } from 'fabric';
import { Polygon } from 'polygon-clipping';
import React, { useCallback, useMemo, useRef } from 'react';

import { Coords, ElementAddress, MediaRectangle } from 'editor/src/store/design/types';

import FabricGroup from 'editor/src/fabric/FabricGroup';
import FabricRect from 'editor/src/fabric/FabricRect';
import useBrowserColor from 'editor/src/util/useBrowserColor';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import { currentOperationManager } from 'editor/src/component/EditorArea/ElementOperationOverlay';
import FabricGroupComponent from 'editor/src/component/EditorArea/fabricComponents/FabricGroupComponent';
import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';
import useSnapMatch from 'editor/src/component/EditorArea/snapping/useSnapMatch';
import { ELEMENT_FRAME_COLOR } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/config';
import fabricElementToObjectRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/fabricElementToObjectRect';
import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';
import getElementRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getElementRect';
import useHoverBox from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useHoverBox';
import useIsInteractable from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useIsInteractable';
import useStoreSelection from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useStoreSelection';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import fabricPropsToRectangleElementData from './fabricPropsToRectangleElementData';
import getGroupInternalStrokeClipPath from './getGroupInternalStrokeClipPath';
import getGroupRectangleRect from './getGroupRectangleRect';
import rectangleElementDataToFabricProps from './rectangleElementDataToFabricProps';
import useRectangleUpdates from './useRectangleUpdates';
import { validateCornerRounding, getMinScaleLimit, LimitBasedOn, validateStrokeWidth } from './utils';

interface Props {
  elementData: MediaRectangle;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
  selected: boolean;
  elementAddress: ElementAddress;
  contentClipPolygons: Polygon[];
  showGuides: boolean;
  ignorePersonalizationLock: boolean;
  isMobile: boolean;
  contentClipPath: fabric.Object | undefined;
}

function Rectangle({
  elementData,
  pageCoords,
  canvasRotation,
  selected,
  elementAddress,
  contentClipPolygons,
  showGuides,
  ignorePersonalizationLock,
  isMobile,
  contentClipPath,
}: Props) {
  const fabricCanvas = useFabricCanvas();
  const { mm2px, px2mm } = useFabricUtils();
  const controlledElementRef = useRef<FabricRect>(null);
  const groupElementRef = useRef<FabricGroup>(null);
  const isMouseDown = useRef(false);

  const { liveElement: rectangleData, liveUpdate } = useMediaElementLiveUpdates(elementData);
  const isInteractable = useIsInteractable(rectangleData, ignorePersonalizationLock);
  const frameRect = useMemo(
    () => getElementRect(rectangleData, pageCoords, canvasRotation, mm2px),
    [pageCoords, canvasRotation, mm2px, rectangleData],
  );
  const snapMatch = useSnapMatch(rectangleData.uuid, showGuides && !rectangleData.locked, pageCoords);
  const fabricProps = rectangleElementDataToFabricProps(rectangleData, elementAddress.elementIndex, mm2px);
  fabricProps.fill = useBrowserColor(fabricProps.fill);
  fabricProps.stroke = useBrowserColor(fabricProps.stroke);

  const hoverBox = useHoverBox(frameRect, isMobile, selected || !isInteractable, canvasRotation);
  const clipPath = useMemo(
    () => getClipPath(frameRect, contentClipPolygons, false, contentClipPath),
    [frameRect, contentClipPolygons, contentClipPath],
  );

  useStoreSelection(controlledElementRef, rectangleData.uuid, rectangleData.type, selected);

  const onMouseDown = useCallback(
    (e: fabric.IEvent) => {
      isMouseDown.current = true;
      snapMatch.onMouseDown(e);
    },
    [snapMatch.onMouseDown],
  );

  const onMouseMove = useCallback(
    (e: fabric.IEvent) => {
      if (!isMouseDown.current || fabricCanvas.getActiveObject() !== controlledElementRef.current) {
        return;
      }

      snapMatch.onMouseMove(e);

      if (!controlledElementRef?.current || !groupElementRef.current) {
        return;
      }

      const { scaleX = 1, scaleY = 1, height = 1, width = 1 } = controlledElementRef.current;

      const xScaleChanged = scaleX !== 1;
      const yScaleChanged = scaleY !== 1;
      if (xScaleChanged) {
        groupElementRef.current.set('width', width * scaleX);
      }
      if (yScaleChanged) {
        groupElementRef.current.set('height', height * scaleY);
      }

      if (!controlledElementRef.current.minScaleLimit) {
        let scaleLimitBasedOn: LimitBasedOn | undefined;
        if (xScaleChanged && yScaleChanged) {
          scaleLimitBasedOn = 'both';
        } else if (xScaleChanged) {
          scaleLimitBasedOn = 'width';
        } else if (yScaleChanged) {
          scaleLimitBasedOn = 'height';
        }

        const minScaleLimit = scaleLimitBasedOn
          ? getMinScaleLimit(scaleLimitBasedOn, frameRect, fabricProps.strokeWidth)
          : 1;
        controlledElementRef.current.set('minScaleLimit', minScaleLimit);
      }

      const fabricGroupElement = fabricElementToObjectRect(controlledElementRef.current);
      const groupRectangleItem = groupElementRef.current.getObjects()[0] as FabricRect;
      groupElementRef.current.set({
        clipPath: getClipPath(
          fabricElementToObjectRect(controlledElementRef.current),
          contentClipPolygons,
          false,
          contentClipPath,
        ),
        top: controlledElementRef.current.top,
        left: controlledElementRef.current.left,
        angle: controlledElementRef.current.angle,
      });

      const strokeWidth = validateStrokeWidth({
        ...fabricGroupElement,
        strokeWidth: mm2px(rectangleData.strokeWidth * 2),
      });
      const groupRectangleRect = getGroupRectangleRect(fabricGroupElement, strokeWidth);
      const cornerRounding = validateCornerRounding({
        ...groupRectangleRect,
        cornerRounding: rectangleData.cornerRounding && mm2px(rectangleData.cornerRounding),
      });

      groupRectangleItem.set({
        ...groupRectangleRect,
        rx: cornerRounding,
        ry: cornerRounding,
        strokeWidth,
        clipPath: getGroupInternalStrokeClipPath(fabricGroupElement, cornerRounding),
      });

      const rectangleUpdate = fabricPropsToRectangleElementData(px2mm, pageCoords, controlledElementRef.current);
      const newElement = { ...rectangleData, ...rectangleUpdate };
      liveUpdate(newElement);
      if (controlledElementRef.current.oCoords && e.transform) {
        currentOperationManager.emit(
          'objectUpdating',
          newElement,
          controlledElementRef.current.oCoords,
          (e.transform as any).action === 'drag' ? 'move' : 'resize',
        );
      }
    },
    [snapMatch.onMouseMove, frameRect, fabricProps, px2mm, pageCoords, contentClipPolygons, contentClipPath],
  );

  const onMouseUp = useCallback(() => {
    isMouseDown.current = false;
    snapMatch.onMouseUp();
    controlledElementRef?.current?.set('minScaleLimit', undefined);
  }, [snapMatch.onMouseUp]);

  const rectangleUpdates = useRectangleUpdates(pageCoords, rectangleData, elementAddress, controlledElementRef);
  const cornerRounding = validateCornerRounding({
    width: frameRect.width,
    height: frameRect.height,
    cornerRounding: mm2px(rectangleData.cornerRounding ?? 0),
  });

  return (
    <>
      <FabricGroupComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...frameRect}
        zIndex={fabricProps.zIndex}
        ref={groupElementRef}
        clipPath={clipPath}
        evented={false}
        objectCaching={false}
        uuid={rectangleData.uuid}
        type="gelato-rectangle"
      >
        <FabricRectComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getGroupRectangleRect(frameRect, fabricProps.strokeWidth)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...fabricProps}
          clipPath={getGroupInternalStrokeClipPath(frameRect, cornerRounding)}
        />
      </FabricGroupComponent>
      <FabricRectComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...frameRect}
        ref={controlledElementRef}
        onModified={rectangleUpdates.onObjectModified}
        onSelected={rectangleUpdates.onSelected}
        onMouseOver={hoverBox.onMouseOver}
        onMouseOut={hoverBox.onMouseOut}
        onMouseDownBefore={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        hoverCursor={selected ? 'move' : 'pointer'}
        borderColor={ELEMENT_FRAME_COLOR}
        cornerColor={ELEMENT_FRAME_COLOR}
        cornerStrokeColor={ELEMENT_FRAME_COLOR}
        borderOpacityWhenMoving={1}
        zIndex={fabricProps.zIndex}
        opacity={0}
        strokeWidth={0}
        scaleX={1}
        scaleY={1}
        lockScalingFlip
        lockMovementY={elementData.locked}
        lockMovementX={elementData.locked}
        lockRotation={elementData.locked}
        hasControls={!elementData.locked}
        objectCaching={false}
        evented={isInteractable}
        selectable={!isMobile}
      />
      {hoverBox.render()}
    </>
  );
}

export default React.memo(Rectangle);

import React from 'react';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch } from 'editor/src/store/hooks';

import IconArrowLeft from 'editor/src/component/Icon/IconArrowLeft';
import { ButtonMode } from 'editor/src/component/Menu/type';
import MenuButton from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';

type Props = {
  mode: ButtonMode;
  tabName: TAB_NAMES;
};

function ButtonBackToTab({ mode, tabName }: Props) {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setSidebarActiveTabByNameOperation(tabName));
  };
  return (
    <MenuButton
      Icon={IconArrowLeft}
      mode={mode}
      handleClick={handleClick}
      className="cy-button-back"
      showTooltip={false}
    />
  );
}

export default React.memo(ButtonBackToTab);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setPreviewStatusAction, setSpreadPreviewAction } from 'editor/src/store/editorModules/preview/slice';
import { PreviewStatus, SpreadPreview } from 'editor/src/store/editorModules/preview/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const setSpreadPreviewOperation = (flatPreview: SpreadPreview) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(setSpreadPreviewAction(flatPreview));
    dispatch(setPreviewStatusAction(PreviewStatus.LOADED));
  });
};

export default setSpreadPreviewOperation;

import React from 'react';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { MediaElement } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import isLockElementsAllowed from 'editor/src/store/editor/selector/isLockElementsAllowed';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconLockedMove from 'editor/src/component/Icon/IconLockedMove';
import IconUnlockedMove from 'editor/src/component/Icon/IconUnlockedMove';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const ALLOWED_TYPES: Set<MediaElement['type']> = new Set(['image', 'text', 'addon', 'rectangle', 'line']);

export const canShow: CanShow = (state, { hasSelection, structureIndexes }) => {
  if (!hasSelection) {
    return false;
  }
  const element = getMediaElement(state, structureIndexes[0]);
  return (
    !!element && isLockElementsAllowed(state) && ALLOWED_TYPES.has(element.type) && !isMediaMockupPlaceholder(element)
  );
};

type Props = Pick<MenuItemProps, 'close' | 'context' | 'mode'>;

function ButtonLock({ context, mode, close }: Props) {
  const locked = useSelector((state) => !!getMediaElement(state, context.structureIndexes[0])?.locked);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      updateMediaElementOperation(
        context.structureIndexes[0],
        { locked: !locked || undefined },
        MediaUpdateActionName.TOGGLE_LOCK,
      ),
    );
    close?.();
  };

  return (
    <MenuButton
      Icon={locked ? IconLockedMove : IconUnlockedMove}
      on={locked}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-lock-move"
      className="cy-button-lock"
    />
  );
}

export default React.memo(ButtonLock);

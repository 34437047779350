import { PayloadAction } from '@reduxjs/toolkit';

import { SpreadDesignTemplate, TemplatesState } from 'editor/src/store/editorModules/templates/types';

const addDesignTemplateReducer = (state: TemplatesState, action: PayloadAction<SpreadDesignTemplate>) => {
  const newTemplate = action.payload;
  const designTemplates = state.designTemplates.filter((template) => template.id !== newTemplate.id);
  state.designTemplates = [newTemplate, ...designTemplates];
};

export default addDesignTemplateReducer;

import React, { JSX, useCallback } from 'react';
import { BasicToastrOptions } from 'react-redux-toastr';

import toastController from 'editor/src/util/toastController';

import CustomToastrComponent from '.';

import toastrStyles from './index.module.scss';

export type ToastrType = 'info' | 'success';

interface Options extends BasicToastrOptions {
  ActionButton?: JSX.Element;
}

const useCustomToastr = () => {
  const showToastr = useCallback((type: ToastrType, message: string, options?: Options) => {
    const { timeOut = 4000, ActionButton } = options || {};
    toastController.customInfo({
      className: `${toastrStyles.customToastr}`,
      component: <CustomToastrComponent type={type} message={message} ActionButton={ActionButton} />,
      timeOut,
    });
  }, []);

  return showToastr;
};

export default useCustomToastr;

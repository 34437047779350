import React from 'react';
import { useTranslation } from 'react-i18next';

import applyShadowToSelectionOperation from 'editor/src/store/design/operation/applyShadowToSelectionOperation';
import { MediaImage, MediaText } from 'editor/src/store/design/types';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { PluginName } from 'editor/src/store/plugins/types';

import EffectsElement from 'editor/src/component/DesktopSidebar/TabContents/EffectsTabContent/EffectsElement';
import IconTextShadow from 'editor/src/component/Icon/IconTextShadow';

import ShadowControls from './ShadowControls';
import { getElementShadow } from './ShadowControls/utils';

interface Props {
  selectedElement: MediaText | MediaImage;
}

function MediaShadow({ selectedElement }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const elementShadow = getElementShadow(selectedElement);
  const defaultShadow = useSelector((state) => state.editor.currentStyles[selectedElement.type].shadow);

  const onShadowSwitched = () => {
    const shadow = elementShadow ? undefined : defaultShadow;
    if (selectedElement.type === 'text' && shadow?.type === 'drop-shadow') {
      dispatch(applyFontStylesToSelectionOperation({ shadow }));
    } else {
      dispatch(applyShadowToSelectionOperation(shadow));
    }
  };
  return (
    <EffectsElement
      controlId="shadow"
      title={t('Shadow')}
      icon={<IconTextShadow />}
      switchStatus={!!elementShadow}
      onToggled={onShadowSwitched}
      pluginName={selectedElement.type === 'text' ? PluginName.TextEffects : undefined}
    >
      <ShadowControls selectedElement={selectedElement} shadow={elementShadow ?? defaultShadow} />
    </EffectsElement>
  );
}

export default React.memo(MediaShadow);

import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import requestDigitizedAssetOperation from 'editor/src/store/design/operation/requestDigitizedAssetOperation';
import getDigitizedAsset from 'editor/src/store/design/selector/getDigitizedAsset';
import { DigitizedAssetFromType, MediaAddon, MediaImage, MediaText } from 'editor/src/store/design/types';
import getDigitizedElementId from 'editor/src/store/design/util/getDigitizedElementId';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import shouldDigitizeCurrentSpread from 'editor/src/store/utils/shouldDigitizeCurrentSpread';

import { RequestDigitizedAssetDataFromType } from 'editor/src/util/postMessages/messages';

const DEBOUNCE_TIME = 1000;

function useDigitizedAsset<
  T extends MediaText | MediaImage | MediaAddon,
  R extends RequestDigitizedAssetDataFromType<T['type']>,
  D extends DigitizedAssetFromType<T['type']>,
>(
  element: T,
  disable: boolean,
  getRequestPayload: (
    imageId: string | undefined,
    productUid: string,
    variantKey: string | undefined,
    elementId: string,
  ) => R | undefined,
) {
  const dispatch = useDispatch();

  const digitizeDesignElements = useSelector(shouldDigitizeCurrentSpread);
  const variantKey = useSelector((state) => state.variants.selectedGroupKey);
  const elementId = useMemo(
    () => (digitizeDesignElements ? getDigitizedElementId(element) : undefined),
    [digitizeDesignElements, element],
  );
  const digitizedAsset = useSelector((state) => getDigitizedAsset<D>(state, element));
  const productUid = useSelector((state) => state.design.designData?.product_uid);
  const requestPayloadRef = useRef(getRequestPayload);
  requestPayloadRef.current = getRequestPayload;

  const debounceRequest = useCallback(
    debounce(
      (request: R) => {
        dispatch(requestDigitizedAssetOperation(request));
      },
      DEBOUNCE_TIME,
      { leading: true, trailing: true },
    ),
    [element.uuid],
  );

  useEffect(() => {
    if (disable || !digitizeDesignElements || !productUid || !elementId) {
      return;
    }

    const requestRef = requestPayloadRef.current(digitizedAsset?.imageId, productUid, variantKey, elementId);
    if (requestRef) {
      debounceRequest(requestRef);
    }
  }, [digitizeDesignElements, elementId, disable, productUid, variantKey, digitizedAsset?.imageId]);

  return {
    digitizedAsset,
    showDigitizedAsset: !disable && digitizeDesignElements,
  };
}

export default useDigitizedAsset;

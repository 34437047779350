import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import getFontDefinition from 'editor/src/store/fonts/selector/getFontDefinition';
import { FontDefinition, FontStatus } from 'editor/src/store/fonts/types';
import { useSelector } from 'editor/src/store/hooks';
import { PluginIcon } from 'editor/src/store/plugins/selector/getPluginIconByLevel';

import IconLoading from 'editor/src/component/Icon/IconLoading';

import styles from './index.module.scss';

interface Props {
  fontDefinition: FontDefinition;
  isActive: boolean;
  onSelect: (fontDef: FontDefinition) => void;
  PremiumIcon: PluginIcon;
  scrollIntoView: boolean;
}

function FontFamily({ fontDefinition, isActive, onSelect, PremiumIcon, scrollIntoView }: Props) {
  function onClick() {
    onSelect(fontDefinition);
  }
  const loadingStatus = useSelector((state) => getFontDefinition(state, fontDefinition.metadata.fontFile)?.status);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollIntoView && isActive && divRef.current) {
      if ('scrollIntoViewIfNeeded' in divRef.current) {
        (divRef.current as any).scrollIntoViewIfNeeded(true);
      } else {
        divRef.current.scrollIntoView();
      }
    }
  }, [isActive, scrollIntoView]);

  return (
    <div
      ref={divRef}
      onClick={onClick}
      className={cn(styles.fontFamily, 'cy-font-family', {
        [styles.active]: isActive,
        'cy-active': isActive,
      })}
    >
      <div className={styles.box}>
        <img src={fontDefinition.metadata.previewUrl} alt={fontDefinition.metadata.family} loading="lazy" />
        {loadingStatus === FontStatus.loading && <IconLoading />}
        {fontDefinition.metadata.isPremium && <PremiumIcon className="cy-premium" />}
      </div>
    </div>
  );
}

export default React.memo(FontFamily);

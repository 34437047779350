import React from 'react';
import { useTranslation } from 'react-i18next';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { AnchoringX, AnchoringY } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import { useDispatch } from 'editor/src/store/hooks';
import updatePlaceholderAnchoringOperation from 'editor/src/store/mockup/operation/updatePlaceholderAnchoringOperation';

import ControlButton from 'editor/src/component/ControlButton';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

interface Props {
  icon: React.ReactElement;
  anchorTo: string;
  on: boolean;
}

function AnchorActionButton({ icon, anchorTo, on }: Props) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const handleClick = () => {
    batch(() => {
      dispatch(saveUndoRedoStateOperation('Anchor element'));

      switch (anchorTo) {
        case 'left':
          dispatch(
            updatePlaceholderAnchoringOperation({
              anchoring_x: AnchoringX.Left,
            }),
          );
          break;
        case 'center':
          dispatch(
            updatePlaceholderAnchoringOperation({
              anchoring_x: AnchoringX.Center,
            }),
          );
          break;
        case 'right':
          dispatch(
            updatePlaceholderAnchoringOperation({
              anchoring_x: AnchoringX.Right,
            }),
          );
          break;
        case 'top':
          dispatch(
            updatePlaceholderAnchoringOperation({
              anchoring_y: AnchoringY.Top,
            }),
          );
          break;
        case 'middle':
          dispatch(
            updatePlaceholderAnchoringOperation({
              anchoring_y: AnchoringY.Middle,
            }),
          );
          break;
        case 'bottom':
          dispatch(
            updatePlaceholderAnchoringOperation({
              anchoring_y: AnchoringY.Bottom,
            }),
          );
          break;
        default:
          break;
      }
    });
  };

  return (
    <ControlButton className={styles.alignTabButton} onClick={handleClick} stopPropagation on={on}>
      {icon}
      {!isMobile && t(`editor-${anchorTo}`)}
    </ControlButton>
  );
}

export default React.memo(AnchorActionButton);

import { shallowEqual } from 'react-redux';

import getSpread from 'editor/src/store/design/selector/getSpread';
import getSpreadGroups from 'editor/src/store/design/selector/getSpreadGroups';
import { useSelector } from 'editor/src/store/hooks';

import { DisplayPage } from 'editor/src/component/EditorArea/utils/displayPageUtils';

import getSpreadGroup from '../getSpreadGroup';

import useSpreadTitle from './useSpreadTitle';

function getSpreadName(displayPage: DisplayPage): string[] {
  const { spreadIndex, focusedContent } = displayPage;

  const spreadGroups = useSelector(getSpreadGroups);

  const spread = useSelector((state) => getSpread(state, spreadIndex), shallowEqual);

  const currSpreadGroup = getSpreadGroup(spreadIndex, spreadGroups);

  const spreadTitle = useSpreadTitle(currSpreadGroup?.name ?? spread?.name ?? '', spreadIndex, focusedContent);

  const spreadNames = spreadTitle.split(' - ');

  return spreadNames;
}

export default getSpreadName;

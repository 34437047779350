import React from 'react';
import { useTranslation } from 'react-i18next';

import imageCropZoomOutOperation from 'editor/src/store/design/operation/crop/imageCropZoomOutOperation';
import isCropZoomOutAvailable from 'editor/src/store/design/selector/isCropZoomOutAvailable';
import { StructureIndex } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import IconZoomOut from 'editor/src/component/Icon/IconZoomOut';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  structureIndexes: StructureIndex[];
}

function ButtonCropZoomOut({ structureIndexes }: Props) {
  const dispatch = useDispatch();
  const enabled = useSelector((state) => isCropZoomOutAvailable(state, structureIndexes[0]));
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(imageCropZoomOutOperation(structureIndexes[0]));
  };

  return (
    <WithTooltip overlay={t('editor-crop-zoom-out')}>
      <ControlButton onClick={handleClick} className="cy-button-crop-zoom-out" disabled={!enabled}>
        <IconZoomOut className={styles.zoomOutIcon} />
      </ControlButton>
    </WithTooltip>
  );
}

export default React.memo(ButtonCropZoomOut);

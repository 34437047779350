import cn from 'classnames';
import React from 'react';

import { GlobalWarning, WarningType } from 'editor/src/store/editorModules/warnings/types';

import EmptyDesignWarningItem from './EmptyDesignWarningItem';

import styles from './index.module.scss';

interface Props {
  warning: GlobalWarning;
}

function GlobalWarningItemContainer({ warning }: Props) {
  return (
    <div className={cn(styles.WarningItemContainer)}>
      {warning.type === WarningType.EmptyDesign && <EmptyDesignWarningItem warning={warning} />}
    </div>
  );
}

export default React.memo(GlobalWarningItemContainer);

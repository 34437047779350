import { CanShow } from 'editor/src/component/Menu/type';

import ButtonRectangleStyles from './ButtonRectangleStyles';
import ButtonRectangleStylesDropDown from './ButtonRectangleStylesDropDown';

export const canShow: CanShow = (state, { isPersonalizationLocked, hasSelection }) =>
  hasSelection && !isPersonalizationLocked;

export { ButtonRectangleStyles, ButtonRectangleStylesDropDown };

export default ButtonRectangleStyles;

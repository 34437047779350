import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconGrid from 'editor/src/component/Icon/IconGrid';

import abstractStyles from './AbstractLayerItem/index.module.scss';

function GridLayerItem() {
  const { t } = useTranslation();

  return (
    <>
      <div className={abstractStyles.thumb}>
        <IconGrid />
      </div>
      <div className={cn(abstractStyles.text, 'cy-layer-text')}>{t('editor-calendar-grid')}</div>
    </>
  );
}

export default React.memo(GridLayerItem);

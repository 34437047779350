/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconMoreActive({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M6.60005 13.8C6.10005 13.8 5.67525 13.6248 5.32565 13.2744C4.97525 12.9248 4.80005 12.5 4.80005 12C4.80005 11.5 4.97525 11.0752 5.32565 10.7256C5.67525 10.3752 6.10005 10.2 6.60005 10.2C7.10005 10.2 7.52485 10.3752 7.87445 10.7256C8.22485 11.0752 8.40005 11.5 8.40005 12C8.40005 12.5 8.22485 12.9248 7.87445 13.2744C7.52485 13.6248 7.10005 13.8 6.60005 13.8ZM12 13.8C11.5 13.8 11.0752 13.6248 10.7256 13.2744C10.3752 12.9248 10.2 12.5 10.2 12C10.2 11.5 10.3752 11.0752 10.7256 10.7256C11.0752 10.3752 11.5 10.2 12 10.2C12.5001 10.2 12.9249 10.3752 13.2745 10.7256C13.6249 11.0752 13.8 11.5 13.8 12C13.8 12.5 13.6249 12.9248 13.2745 13.2744C12.9249 13.6248 12.5001 13.8 12 13.8ZM17.4001 13.8C16.9001 13.8 16.4753 13.6248 16.1257 13.2744C15.7753 12.9248 15.6 12.5 15.6 12C15.6 11.5 15.7753 11.0752 16.1257 10.7256C16.4753 10.3752 16.9001 10.2 17.4001 10.2C17.9001 10.2 18.3249 10.3752 18.6745 10.7256C19.0249 11.0752 19.2001 11.5 19.2001 12C19.2001 12.5 19.0249 12.9248 18.6745 13.2744C18.3249 13.6248 17.9001 13.8 17.4001 13.8Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconMoreActive;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { WarningLevel, WarningType } from 'editor/src/store/editorModules/warnings/types';
import { useDispatch } from 'editor/src/store/hooks';

import IndicatorBadge from 'editor/src/component/IndicatorBadge';

import styles from './index.module.scss';

interface Props {
  type: WarningType;
}

function WarningBadge({ type }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.WARNINGS));
  };

  const tooltip =
    type === WarningType.NotVisible ? t('Element outside of the print area') : t('Element has incorrect dimensions');

  return (
    <div className={cn(styles.Badge, 'cy-warning-badge')} onClick={handleClick}>
      <IndicatorBadge level={WarningLevel.Medium} tooltip={tooltip} />
    </div>
  );
}

export default React.memo(WarningBadge);

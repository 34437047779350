/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconLockedMove({ className }: Props) {
  return (
    <Icon className={className} style={{ position: 'relative', top: '1px' }}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8406 5C10.779 5 8.29715 7.41766 8.29715 10.4L8.29714 13.3333C6.93644 13.3333 5.83337 14.4078 5.83337 15.7333L5.83338 20.9333C5.83338 22.2588 6.93644 23.3333 8.29715 23.3333H19.3841C20.7448 23.3333 21.8479 22.2588 21.8479 20.9333L21.8479 15.7333C21.8479 14.4079 20.7448 13.3333 19.3841 13.3333L19.3841 10.4C19.3841 7.41766 16.9022 5 13.8406 5ZM16.9203 10.4L16.9203 13.3333H10.7609L10.7609 10.4C10.7609 8.74315 12.1397 7.4 13.8406 7.4C15.5415 7.4 16.9203 8.74315 16.9203 10.4Z"
          fill="#212121"
        />
        <path
          d="M25.1827 16.1515C24.8304 15.8083 24.3005 15.7056 23.8402 15.8914C23.3799 16.0771 23.0798 16.5147 23.0798 17V24.2H15.6884C15.1902 24.2 14.741 24.4924 14.5503 24.9408C14.3597 25.3892 14.4651 25.9053 14.8174 26.2485L17.2811 28.6485C17.7622 29.1172 18.5422 29.1172 19.0233 28.6485C19.5044 28.1799 19.5044 27.4201 19.0233 26.9515L18.6625 26.6H23.0798V30.9029L22.7189 30.5515C22.2379 30.0828 21.4579 30.0828 20.9768 30.5515C20.4957 31.0201 20.4957 31.7799 20.9768 32.2485L23.4406 34.6485C23.9216 35.1172 24.7016 35.1172 25.1827 34.6485L27.6465 32.2485C28.1276 31.7799 28.1276 31.0201 27.6465 30.5515C27.1654 30.0828 26.3854 30.0828 25.9043 30.5515L25.5435 30.9029V26.6H29.9608L29.6 26.9515C29.1189 27.4201 29.1189 28.1799 29.6 28.6485C30.0811 29.1172 30.8611 29.1172 31.3421 28.6485L33.8059 26.2485C34.287 25.7799 34.287 25.0201 33.8059 24.5515L31.3421 22.1515C30.8611 21.6828 30.0811 21.6828 29.6 22.1515C29.1189 22.6201 29.1189 23.3799 29.6 23.8485L29.9608 24.2H25.5435V19.8971L25.9043 20.2486C26.3854 20.7172 27.1654 20.7172 27.6465 20.2486C28.1276 19.7799 28.1276 19.0201 27.6465 18.5515L25.1827 16.1515Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconLockedMove;

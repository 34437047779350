import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props<T> {
  tabs: { key: T; label: string }[];
  activeTab: T;
  onSelect: (tabKey: T) => void;
  tabClassName?: string;
  children?: React.ReactNode;
}

function TabBar<T extends string>({ tabs, activeTab, onSelect, tabClassName, children }: Props<T>) {
  return (
    <div className="cy-tabs-container">
      <div className={styles.tabsBlock}>
        {tabs.map((tabItem) => (
          <div
            key={tabItem.key}
            className={cn(
              styles.tabItem,
              {
                [styles.active]: activeTab === tabItem.key,
              },
              tabClassName,
            )}
            onClick={() => onSelect(tabItem.key)}
          >
            {tabItem.label}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
}

export default React.memo(TabBar) as <T extends string>(props: Props<T>) => JSX.Element;

import { useCallback } from 'react';

import { DigitizedAssetText, MediaText } from 'editor/src/store/design/types';

import FabricImage from 'editor/src/fabric/FabricImage';
import { convFn } from 'editor/src/util/convFn';
import isNumber from 'editor/src/util/isNumber';

import getDigitizedTextPosition from './getDigitizedTextPosition';

function useDigitizedTextFabricOptionsUpdate(
  element: MediaText,
  px2mm: convFn,
  digitizedAsset: DigitizedAssetText | undefined,
) {
  return useCallback(
    (image: FabricImage) => {
      if (
        !isNumber(image.left) ||
        !isNumber(image.top) ||
        !isNumber(image.width) ||
        !isNumber(image.height) ||
        !digitizedAsset
      ) {
        return {};
      }

      const margin = digitizedAsset?.margin || 0;
      const img = image.getElement();
      const imageWidth = img.width - margin * 2;
      const imageHeight = img.height - margin * 2;

      const scaleX = element.width / px2mm(imageWidth);
      const scaleY = element.height / px2mm(imageHeight);

      return {
        scaleX,
        scaleY,
        ...getDigitizedTextPosition({
          originalLeft: image.left,
          originalTop: image.top,
          scaleX,
          scaleY,
          angle: image.angle || 0,
          margin: digitizedAsset?.margin || 0,
          originalWidth: image.width,
          originalHeight: image.height,
        }),
      };
    },
    [element.width, element.height, digitizedAsset, px2mm],
  );
}

export default useDigitizedTextFabricOptionsUpdate;

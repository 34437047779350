import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import addConditionToConditionOperation from 'editor/src/store/design/operation/addConditionToConditionOperation';
import addElementtoConditionOperation from 'editor/src/store/design/operation/addElementToConditionOperation';
import removeConditionFromConditionOperation from 'editor/src/store/design/operation/removeConditionFromConditionOperation';
import removeElementFromConditionOperation from 'editor/src/store/design/operation/removeElementFromConditionOperation';
import updateConditionActiveOptionOperation from 'editor/src/store/design/operation/updateConditionActiveOptionOperation';
import getConditionChildrenKey from 'editor/src/store/design/reducer/getConditionChildrenKey';
import { Condition, ConditionGroup, conditionGroupChildenKey, MediaElement } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconCondition from 'editor/src/component/Icon/IconCondition';
import IconText from 'editor/src/component/Icon/IconText';

import getGroupActiveLayerIds from './getGroupActiveLayerIds';
import OptionsDropDown from './OptionsDropDown';

import styles from './index.module.scss';

interface Props {
  spreadIndex: number;
  condition: Condition;
  conditionGroup: ConditionGroup;
}

function isMediaElement(e: MediaElement | Condition): e is MediaElement {
  return 'type' in e && 'uuid' in e;
}

function getParentConditionIds(
  conditionId: string,
  conditionGroup: ConditionGroup,
  ids = new Set<string>(),
): Set<string> {
  const condition = conditionGroup.conditions[conditionId];
  let { parent } = condition;
  while (parent) {
    ids.add(parent.conditionId);
    parent = conditionGroup.conditions[parent.conditionId].parent;
  }
  return ids;
}

function ElementsSection({ condition, spreadIndex, conditionGroup }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mediaElements = useSelector((state) => state.design.designData?.spreads[spreadIndex]?.pages[0].groups.media);
  const usedElementIds = useMemo(
    () => getGroupActiveLayerIds(conditionGroup, condition.id),
    [conditionGroup, condition.id],
  );

  const parentConditionIds = useMemo(
    () => getParentConditionIds(condition.id, conditionGroup),
    [condition.id, conditionGroup],
  );

  const elementList = useMemo(() => {
    const elements: Array<MediaElement | Condition> = (mediaElements || []).filter(
      (element) => !usedElementIds.has(element.uuid),
    );
    condition.options.forEach((option) => {
      const children = conditionGroup.children[getConditionChildrenKey(condition.id, option.id)];
      children?.forEach((child) => {
        if (child.type === 'condition' && !elements.find((elt) => !isMediaElement(elt) && elt.id === child.id)) {
          elements.push(conditionGroup.conditions[child.id]);
        } else {
          const element = mediaElements?.find((element) => element.uuid === child.id);
          if (element && !elements.find((elt) => isMediaElement(elt) && elt.uuid === child.id)) {
            elements.push(element);
          }
        }
      });
    });

    conditionGroup.children[conditionGroupChildenKey]?.forEach((child) => {
      if (child.type !== 'condition' || child.id === condition.id || parentConditionIds.has(child.id)) {
        return;
      }

      if (!elements.find((element) => !isMediaElement(element) && element.id === child.id)) {
        elements.push(conditionGroup.conditions[child.id]);
      }
    });
    return elements;
  }, [mediaElements, usedElementIds, conditionGroup, parentConditionIds, condition.id]);

  function addLayer(element: MediaElement) {
    dispatch(
      addElementtoConditionOperation({ spreadIndex, conditionId: condition.id }, condition.activeOptionId, element),
    );
  }

  function removeLayer(element: MediaElement) {
    dispatch(
      removeElementFromConditionOperation(
        { spreadIndex, conditionId: condition.id },
        condition.activeOptionId,
        element,
      ),
    );
  }

  function addCondition(conditionId: string) {
    dispatch(
      addConditionToConditionOperation(spreadIndex, conditionId, {
        conditionId: condition.id,
        optionId: condition.activeOptionId,
      }),
    );
  }

  function removeCondition(conditionId: string) {
    dispatch(
      removeConditionFromConditionOperation(spreadIndex, conditionId, {
        conditionId: condition.id,
        optionId: condition.activeOptionId,
      }),
    );
  }

  function setActiveOptionId(optionId: string) {
    dispatch(updateConditionActiveOptionOperation({ spreadIndex, conditionId: condition.id }, optionId));
  }

  const children = conditionGroup.children[getConditionChildrenKey(condition.id, condition.activeOptionId)];

  return (
    <div className={cn(styles.ElementsSection, 'cy-elements-section')}>
      <OptionsDropDown
        options={condition.options}
        activeOptionId={condition.activeOptionId}
        selectOption={setActiveOptionId}
      />
      {elementList.length > 0 ? (
        <div className={cn(styles.elements, 'cy-elements-section-elements')}>
          {elementList.map((element) => {
            if (!isMediaElement(element)) {
              const isLinked = children?.some((child) => child.id === element.id);
              return (
                <div key={`condition-${element.id}`} className={cn(styles.element, 'cy-elements-section-element')}>
                  <div className={styles.icon}>
                    <IconCondition />
                  </div>
                  <div className={styles.label}>{element.name}</div>
                  <div
                    className={cn(styles.link, { [styles.isLinked]: isLinked })}
                    onClick={isLinked ? () => removeCondition(element.id) : () => addCondition(element.id)}
                  >
                    {isLinked ? t('editor-unlink') : t('editor-link')}
                  </div>
                </div>
              );
            }

            const isLinked = children?.some((child) => child.id === element.uuid);
            const linkUnlinked = (
              <div
                className={cn(styles.link, { [styles.isLinked]: isLinked }, 'cy-link')}
                onClick={isLinked ? () => removeLayer(element) : () => addLayer(element)}
              >
                {isLinked ? t('editor-unlink') : t('editor-link')}
              </div>
            );

            switch (element.type) {
              case 'text':
                return (
                  <div key={element.uuid} className={cn(styles.element, 'cy-elements-section-element')}>
                    <div className={styles.icon}>
                      <IconText />
                    </div>
                    <div className={styles.label}>{element.extra.text}</div>
                    {linkUnlinked}
                  </div>
                );
              case 'image':
              case 'addon':
                return (
                  <div key={element.uuid} className={cn(styles.element, 'cy-elements-section-element')}>
                    {element.url && (
                      <img crossOrigin="anonymous" className={styles.icon} src={element.url} alt={element.name} />
                    )}
                    <div className={styles.label}>{element.name}</div>
                    {linkUnlinked}
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
      ) : (
        <div className={styles.empty}>{t('editor-no-elements-to-link')}</div>
      )}
    </div>
  );
}

export default React.memo(ElementsSection);

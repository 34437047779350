import React from 'react';
import { useTranslation } from 'react-i18next';

import { Condition, ConditionGroup } from 'editor/src/store/design/types';

import { DropDownContext } from 'editor/src/component/DropDown/DropDownContainer';

import DisplaySection from './DisplaySection';
import ElementsSection from './ElementsSection';
import OptionsSection from './OptionsSection';
import Sections from './Sections';
import Section from './Sections/Section';

import styles from './index.module.scss';

interface Props {
  condition: Condition;
  conditionGroup: ConditionGroup;
  spreadIndex: number;
}

const CONTAINER_ID = 'condition-container';

function ConditionSettings({ condition, conditionGroup, spreadIndex }: Props) {
  const { t } = useTranslation();

  return (
    <DropDownContext.Provider value={CONTAINER_ID}>
      <Sections className={styles.ConditionSettings}>
        <Section title={t('editor-condition-display-view')}>
          <DisplaySection spreadIndex={spreadIndex} condition={condition} />
        </Section>
        <Section title={t('editor-condition-options')}>
          <OptionsSection spreadIndex={spreadIndex} condition={condition} />
        </Section>
        <Section title={t('editor-condition-associated-elements')}>
          <ElementsSection spreadIndex={spreadIndex} conditionGroup={conditionGroup} condition={condition} />
        </Section>
      </Sections>
      <div id={CONTAINER_ID} className={styles.dropdownContainer} />
    </DropDownContext.Provider>
  );
}

export default React.memo(ConditionSettings);

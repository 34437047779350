import getSpreadCoordinates from 'editor/src/store/design/selector/getSpreadCoordinates';
import { Coords, Dimensions, SpreadGroup } from 'editor/src/store/design/types';

import { convFn } from './convFn';

const getGroupedSpreadsInfo = (
  mm2px: convFn,
  spreadWidth: number,
  spreadHeight: number,
  canvasDim: Dimensions,
  currentSpreadIndex: number,
  currentSpreadGroup?: SpreadGroup,
) => {
  const spreadWidthPx = mm2px(spreadWidth);
  const spreadHeightPx = mm2px(spreadHeight);

  if (!currentSpreadGroup) {
    return [
      {
        spreadIndex: currentSpreadIndex,
        spreadCoords: getSpreadCoordinates(canvasDim.width, canvasDim.height, spreadWidthPx, spreadHeightPx),
        spreadWidth,
        spreadHeight,
      },
    ];
  }

  const { position } = currentSpreadGroup;
  return currentSpreadGroup.spreadIndexes.map((spreadIndex, index) => {
    let spreadCoords: Coords;
    switch (position) {
      case 'vertical':
        spreadCoords = {
          left: canvasDim.width / 2 - spreadWidthPx / 2,
          top: canvasDim.height / currentSpreadGroup.spreadIndexes.length - spreadHeightPx * index,
        };
        break;
      case 'horizontal':
        spreadCoords = {
          left: canvasDim.width / currentSpreadGroup.spreadIndexes.length - spreadWidthPx * index,
          top: canvasDim.height / 2 - spreadHeightPx / 2,
        };
        break;
      default:
        spreadCoords = getSpreadCoordinates(canvasDim.width, canvasDim.height, spreadWidthPx, spreadHeightPx);
    }
    return {
      spreadIndex,
      spreadWidth,
      spreadHeight,
      spreadCoords,
    };
  });
};

export default getGroupedSpreadsInfo;

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { setDesignAutoZoomAction as setDesignAutoZoomOperation } from 'editor/src/store/design/slice';
import { useSelector, useDispatch } from 'editor/src/store/hooks';

import Checkbox from 'editor/src/component/Checkbox';

function AutoZoomControl() {
  const dispatch = useDispatch();
  const autoZoom = useSelector((state) => state.design?.designData?.auto_zoom);
  const { t } = useTranslation();

  const onChange = useCallback((enabled) => {
    dispatch(setDesignAutoZoomOperation(enabled));
  }, []);

  return (
    <div className="my-1">
      <Checkbox on={!!autoZoom} onChange={onChange} label={t('Auto Zoom')} />
    </div>
  );
}

export default React.memo(AutoZoomControl);

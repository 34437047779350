import isEqual from 'lodash/isEqual';

import { Plugin, PluginName } from 'editor/src/store/plugins/types';

import {
  EtsyPersonalizationPlugins,
  ShopifyPersonalizationPlugins,
} from 'editor/src/component/DesktopSidebar/TabContents/PersonalizationTabContent/plugins';

import { setGlobalPluginAction } from '../../design/slice';

const removeNotRelevantGlobalPlugins = (globalPlugins: PluginName[], plugins: Plugin[]) => (dispatch: any) => {
  const activePluginNames = plugins.map(({ name }) => name);
  let updatedGlobalPlugins = globalPlugins.filter((plugin) => activePluginNames.includes(plugin));

  if (!updatedGlobalPlugins.length) {
    const etsyPersonalizationPlugins = EtsyPersonalizationPlugins.filter((plugin) =>
      activePluginNames.includes(plugin),
    );
    const shopifyPersonalizationPlugins = ShopifyPersonalizationPlugins.filter((plugin) =>
      activePluginNames.includes(plugin),
    );

    if (etsyPersonalizationPlugins.length) {
      updatedGlobalPlugins = [PluginName.emailWorkflowPersonalization];
    } else if (shopifyPersonalizationPlugins.length) {
      updatedGlobalPlugins = [PluginName.shopifyPersonalizationInEditor];
    }

    if (!isEqual(updatedGlobalPlugins, globalPlugins)) {
      // setup personalization with default enabled plugin
      dispatch(setGlobalPluginAction(updatedGlobalPlugins));
    }
  }
};

export default removeNotRelevantGlobalPlugins;

import { Dimensions, MediaImage } from 'editor/src/store/design/types';

function getImageEltToImageInfo(imageId: string, dimensions: Dimensions, elt?: MediaImage) {
  return {
    imageId,
    dimensions,
    adjustments: elt?.adjustments,
    filters: elt?.filters,
    plugins: elt?.plugins,
    name: elt?.name,
    personalizationLocked: elt?.personalizationLocked,
    sample: elt?.sample,
    url: elt?.url,
    colorOverrides: elt?.colorOverrides,
    createdByLayout: elt?.createdByLayout,
    layoutFrameId: elt?.layoutFrameId,
  };
}

export default getImageEltToImageInfo;

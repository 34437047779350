import React from 'react';
import { useTranslation } from 'react-i18next';

import { MediaElement } from 'editor/src/store/design/types';
import { OutOfRangeWarning } from 'editor/src/store/editorModules/warnings/types';

import AbstractWarningItem from './AbstractWarningItem';

interface Props {
  warning: OutOfRangeWarning;
  element: MediaElement;
  selected: boolean;
}

function OutOfRangeWarningItem({ warning, element, selected }: Props) {
  const { t } = useTranslation();
  return (
    <AbstractWarningItem
      warning={warning}
      element={element}
      selected={selected}
      title={t('Element has incorrect dimensions')}
    >
      {t('element-out-of-range-explanation')}
    </AbstractWarningItem>
  );
}

export default React.memo(OutOfRangeWarningItem);

import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';

import isTopMenuDropdownVisible from 'editor/src/store/app/selector/isTopMenuDropdownVisible';
import { TopMenuDropdown } from 'editor/src/store/app/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import changeVariantProductOperation from 'editor/src/store/variants/operation/changeVariantProductOperation';
import getProducts from 'editor/src/store/variants/selector/getProducts';
import { VariationGroup } from 'editor/src/store/variants/types';

import IconChevronDown from 'editor/src/component/Icon/IconChevronDown';
import IconChevronUp from 'editor/src/component/Icon/IconChevronUp';
import ButtonWithDropdownNotWrapped from 'editor/src/component/TopMenuDesktop/buttons/ButtonWithDropdownNotWrapped';

import ProductSwitcherContent from './ProductSwitcherContent';
import ProductSwitcherItem from './ProductSwitcherItem';

import styles from './index.module.scss';

export interface MinifiedProductData {
  title: string;
  variantGroups: VariationGroup[];
  previewUrl: string | undefined;
}
export interface MinifiedProductsData {
  [productId: string]: MinifiedProductData;
}

function ProductSwitcher() {
  const multipleProductsData = useSelector(getProducts);
  const selectedProductId = useSelector((state) => state.variants.selectedProductId);
  const isMenuOpened = useSelector((state) => isTopMenuDropdownVisible(state, TopMenuDropdown.ProductSwitcher));
  const product = useSelector((state) => state.variants.product);
  const activeVariationGroups = useSelector((state) => state.variants.variationGroups);
  const dispatch = useDispatch();

  const switchProduct = useCallback((productId: string) => {
    dispatch(changeVariantProductOperation(productId));
  }, []);

  if (!selectedProductId || !multipleProductsData || Object.keys(multipleProductsData).length <= 1) {
    return null;
  }

  const minifiedProductsData = useMemo(() => {
    const allData: MinifiedProductsData = {};
    Object.entries(multipleProductsData).forEach(([productId, data]) => {
      if (productId !== selectedProductId) {
        allData[productId] = {
          previewUrl: data.product.previewUrls?.[0],
          title: data.product.title,
          variantGroups: data.variantGroups,
        };
      } else {
        allData[productId] = {
          previewUrl: product.previewUrls?.[0],
          title: product.title,
          variantGroups: activeVariationGroups,
        };
      }
    });

    return allData;
  }, [multipleProductsData, product, activeVariationGroups]);

  return (
    <div>
      <ButtonWithDropdownNotWrapped
        dropDownName={TopMenuDropdown.ProductSwitcher}
        buttonExtraClass={cn(styles.productSwitcherButton, 'cy-button-product-switcher', 'my-1')}
        dropDownContent={
          <ProductSwitcherContent
            multipleProductsData={minifiedProductsData}
            selectedProductId={selectedProductId}
            switchProduct={switchProduct}
          />
        }
        keepTabOpenedOnClose={false}
        dropdownExtraClass={styles.productSwitcherContent}
        buttonContent={
          <ProductSwitcherItem
            productData={minifiedProductsData[selectedProductId]}
            Icon={isMenuOpened ? IconChevronUp : IconChevronDown}
          />
        }
      />
    </div>
  );
}

export default React.memo(ProductSwitcher);

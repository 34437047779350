import React, { useMemo } from 'react';

import { RootState } from 'editor/src/store';
import getActiveImageManipulationPlugins from 'editor/src/util/plugins/getActiveImageManipulationPlugins';

import ButtonReplace, { canShow as replaceCanShow } from 'editor/src/component/BottomBarMobile/Buttons/ButtonReplace';
import { MenuContext } from 'editor/src/component/Menu/type';
import ButtonAdjustments, {
  canShowInTopBar as adjustmentsCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonAdjustments';
import ButtonCrop, { canShow as canShowCropButton } from 'editor/src/component/TopMenuDesktop/buttons/ButtonCrop';
import ButtonCropRotate90, {
  canShow as cropRotateCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonCropRotate90';
import ButtonCropZoom, { canShow as cropZoomCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonCropZoom';
import ButtonDuplicate, {
  canShow as duplicateCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonDuplicate';
import ButtonEditImage, {
  canShow as canShowButtonEditImage,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonEditImage';
import ButtonFill, { canShowInTopBar as fillCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonFill';
import ButtonFilters, {
  canShowInTopBar as filtersCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonFilters';
import ButtonFit, { canShowInTopBar as fitCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonFit';
import ButtonFlipX, { canShow as flipXCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonFlipX';
import ButtonFlipY, { canShow as flipYCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonFlipY';
import ButtonImageColor, {
  canShow as imageColorCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonImageColor';
import ButtonImageManipulation from 'editor/src/component/TopMenuDesktop/buttons/ButtonImageManipulation';
import ButtonImageShadow, {
  canShow as canShowImageShadow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonImageShadow';
import ButtonLock, { canShow as lockCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonLock';
import ButtonPattern, {
  canShow as canShowButtonPattern,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonPattern';
import ButtonPersonalization, {
  canShow as personalizationCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonPersonalization';
import ButtonPosition, { canShow as canShowPosition } from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition';
import ButtonProperties, {
  canShowInTopBar as propertiesCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonProperties';
import ButtonRemove, { canShow as removeCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonRemove';
import ButtonThreadColor, {
  canShow as threadColorCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonThreadColor';
import SplitterVertical from 'editor/src/component/TopMenuDesktop/buttons/SplitterVertical';

interface Props {
  menuContext: MenuContext;
  rootState: RootState;
}

function ImageControls({ menuContext, rootState }: Props) {
  const activeManipulationPlugins = useMemo(() => {
    if (rootState.hostSettings.editImageFlag) {
      return [];
    }

    return getActiveImageManipulationPlugins(menuContext);
  }, [menuContext, rootState.hostSettings.editImageFlag]);

  return (
    <>
      {removeCanShow(rootState, menuContext) && <ButtonRemove mode="label-icon-vertical" context={menuContext} />}
      <SplitterVertical />
      {imageColorCanShow(rootState, menuContext) && (
        <>
          <ButtonImageColor isMobile mode="label-icon-vertical" context={menuContext} />
          <SplitterVertical />
        </>
      )}
      {threadColorCanShow(rootState) && (
        <>
          <ButtonThreadColor isMobile mode="label-icon-vertical" context={menuContext} />
          <SplitterVertical />
        </>
      )}
      {fillCanShow(rootState, menuContext) && <ButtonFill mode="label-icon-vertical" context={menuContext} />}
      {fitCanShow(rootState, menuContext) && <ButtonFit mode="label-icon-vertical" context={menuContext} />}
      {cropZoomCanShow(rootState, menuContext) && (
        <ButtonCropZoom isMobile mode="label-icon-vertical" context={menuContext} />
      )}
      {cropRotateCanShow(rootState, menuContext) && (
        <ButtonCropRotate90 mode="label-icon-vertical" context={menuContext} />
      )}
      {flipXCanShow(rootState, menuContext) && <ButtonFlipX mode="label-icon-vertical" context={menuContext} />}
      {flipYCanShow(rootState, menuContext) && <ButtonFlipY mode="label-icon-vertical" context={menuContext} />}
      {!menuContext.isSelectionLocked && <SplitterVertical />}
      {filtersCanShow(rootState, menuContext) && (
        <ButtonFilters isMobile mode="label-icon-vertical" context={menuContext} />
      )}
      {adjustmentsCanShow(rootState, menuContext) && (
        <ButtonAdjustments isMobile mode="label-icon-vertical" context={menuContext} />
      )}
      {activeManipulationPlugins.map((plugin) => (
        <ButtonImageManipulation key={plugin.name} mode="label-icon-vertical" plugin={plugin} />
      ))}
      {canShowCropButton(rootState, menuContext) && (
        <ButtonCrop isMobile mode="label-icon-vertical" context={menuContext} />
      )}
      {canShowButtonEditImage(rootState, menuContext) && <ButtonEditImage isMobile mode="label-icon-vertical" />}
      {canShowButtonPattern(rootState, menuContext) && (
        <ButtonPattern isMobile mode="label-icon-vertical" context={menuContext} />
      )}
      {replaceCanShow(rootState, menuContext) && <ButtonReplace />}
      {canShowImageShadow(rootState, menuContext) && <ButtonImageShadow isMobile mode="label-icon-vertical" />}
      {canShowPosition(rootState, menuContext) && <ButtonPosition isMobile mode="label-icon-vertical" />}
      {propertiesCanShow(rootState, menuContext) && <ButtonProperties mode="label-icon-vertical" />}
      {personalizationCanShow(rootState, menuContext) && <ButtonPersonalization isMobile mode="label-icon-vertical" />}
      {duplicateCanShow(rootState, menuContext) && <ButtonDuplicate mode="label-icon-vertical" context={menuContext} />}
      {lockCanShow(rootState, menuContext) && <ButtonLock mode="label-icon-vertical" context={menuContext} />}
    </>
  );
}

export default React.memo(ImageControls);

import React from 'react';

import IconRefresh from 'editor/src/component/Icon/IconRefresh';
import { ButtonMode } from 'editor/src/component/Menu/type';
import MenuButton from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';

import styles from './index.module.scss';

type Props = {
  onClick: () => void;
  mode: ButtonMode;
  disabled?: boolean;
};

function ButtonResetImageEffects({ disabled, onClick, mode }: Props) {
  return (
    <MenuButton
      Icon={IconRefresh}
      iconProps={{ className: styles.refreshIcon }}
      mode={mode}
      handleClick={onClick}
      i18nKey="Reset all effects"
      className="cy-button-reset-image-effects"
      disabled={disabled}
    />
  );
}

export default React.memo(ButtonResetImageEffects);

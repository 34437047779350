import { ElementAddress, ImagePattern, MediaImage } from 'editor/src/store/design/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { GalleryImage, ImageState, MimeType } from 'editor/src/store/gallery/types';
import { RootState } from 'editor/src/store/index';

import calculateDPI from 'editor/src/util/calculateDPI';

import { getPatternRepeat } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/useFilters';

import getMediaElement from './getMediaElement';

export function getImageDPI(
  pw: number,
  ph: number,
  galleryImage: GalleryImage,
  pattern: { pattern: ImagePattern; elementWidth: number; elementHeight: number } | undefined,
) {
  if (
    galleryImage.type === MimeType.SVG ||
    galleryImage.type === MimeType.PDF ||
    galleryImage.type === MimeType.Unknown ||
    !galleryImage.hasAssetDimensions ||
    galleryImage.state !== ImageState.UPLOADED
  ) {
    return undefined;
  }

  const patternRepeat = pattern
    ? getPatternRepeat(
        pattern.pattern.scale,
        pattern.pattern.scaleBase,
        pattern.elementWidth,
        pattern.elementHeight,
        pw,
        ph,
      )
    : 1;

  return calculateDPI(galleryImage.width, galleryImage.height, pw / patternRepeat, ph / patternRepeat);
}

export function getMediaImageDPI(image: MediaImage, galleryImage: GalleryImage) {
  return getImageDPI(
    image.pw,
    image.ph,
    galleryImage,
    image.pattern
      ? {
          pattern: image.pattern,
          elementWidth: image.width,
          elementHeight: image.height,
        }
      : undefined,
  );
}

const getMediaElementDpi = (state: RootState, elementAddress: ElementAddress) => {
  const element = getMediaElement(state, elementAddress);
  if (!element || element.type !== 'image') {
    return undefined;
  }

  const galleryImage = getImageById(state, element.imageId);
  return galleryImage ? getMediaImageDPI(element, galleryImage) : undefined;
};

export default getMediaElementDpi;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconTextAlignRight({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0001 10C35.0001 10.9205 34.2539 11.6667 33.3334 11.6667H3.33341C2.41294 11.6667 1.66675 10.9205 1.66675 10C1.66675 9.07957 2.41294 8.33337 3.33341 8.33337H33.3334C34.2539 8.33337 35.0001 9.07957 35.0001 10Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0001 16.6667C35.0001 17.5872 34.2539 18.3334 33.3334 18.3334H15.3334C14.4129 18.3334 13.6667 17.5872 13.6667 16.6667C13.6667 15.7462 14.4129 15 15.3334 15H33.3334C34.2539 15 35.0001 15.7462 35.0001 16.6667Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0001 23.3334C35.0001 24.2538 34.2539 25 33.3334 25H9.33342C8.41294 25 7.66675 24.2538 7.66675 23.3334C7.66675 22.4129 8.41294 21.6667 9.33342 21.6667H33.3334C34.2539 21.6667 35.0001 22.4129 35.0001 23.3334Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0001 30C35.0001 30.9205 34.2539 31.6667 33.3334 31.6667H24.3334C23.4129 31.6667 22.6667 30.9205 22.6667 30C22.6667 29.0796 23.4129 28.3334 24.3334 28.3334H33.3334C34.2539 28.3334 35.0001 29.0796 35.0001 30Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconTextAlignRight;

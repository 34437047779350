import React from 'react';

import getImageElementUrl from 'editor/src/store/design/selector/getImageElementUrl';
import { MediaElement } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

import IconGrid from 'editor/src/component/Icon/IconGrid';
import IconLayersLine from 'editor/src/component/Icon/IconLayersLine';
import IconLayersRectangle from 'editor/src/component/Icon/IconLayersRectangle';
import IconText from 'editor/src/component/Icon/IconText';

interface Props {
  element: MediaElement;
}

function ElementIcon({ element }: Props) {
  const imageInfo = useSelector((state) =>
    element.type === 'image' || element.type === 'addon' ? getImageElementUrl(state, element) : undefined,
  );

  switch (element.type) {
    case 'text':
      return <IconText />;
    case 'rectangle':
      return <IconLayersRectangle />;
    case 'line':
      return <IconLayersLine />;
    case 'grid':
      return <IconGrid />;
    case 'addon':
    case 'image':
      return (
        <img
          key={(imageInfo?.imageUrl || '') + (imageInfo?.crossOrigin || '')} // needed to avoid some CORS issue with safari
          crossOrigin={imageInfo?.crossOrigin}
          draggable={false}
          src={imageInfo?.imageUrl}
          alt={element.name}
        />
      );
    default:
      return null;
  }
}

export default ElementIcon;

import { Dimensions } from 'editor/src/store/design/types';
import type { Thunk } from 'editor/src/store/hooks';
import getMockupId from 'editor/src/store/mockup/utils/getMockupId';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import applyMockupStructureOperation from './applyMockupStructureOperation';

export const mockupFetching = new Set<string>();

const applyOrFetchMockupStructureOperation =
  (size: Dimensions): Thunk =>
  (dispatch, getState) => {
    const sizeId = getMockupId(size);
    const structure = getState().mockup.mockup.structure[sizeId];
    if (structure) {
      dispatch(applyMockupStructureOperation(structure));
    } else if (!mockupFetching.has(sizeId)) {
      mockupFetching.add(sizeId);
      sendPostMessage('mockup.requestMockupStructure', size);
    }
  };

export default applyOrFetchMockupStructureOperation;

import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import IconLoading from 'editor/src/component/Icon/IconLoading';

import styles from './index.module.scss';

export interface EntryProps<T extends { id: string }> {
  entry: T;
  onSelect: (entry: T) => void;
  isLoading?: boolean;
}

interface Props<T extends { id: string }> {
  entries: T[] | undefined;
  hasMore: boolean;
  loadEntries: (offset: number) => void;
  onSelect: (item: T) => void;
  EntryElt: React.FunctionComponent<EntryProps<T>>;
  loadingEntry?: T;
  className?: string;
}

function MockupList<T extends { id: string }>({
  entries,
  hasMore,
  loadEntries,
  onSelect,
  EntryElt,
  loadingEntry,
  className,
}: Props<T>) {
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => setIsFetching(false), [entries, hasMore]);

  useEffect(() => {
    if (!entries && !isFetching) {
      setIsFetching(true);
      loadEntries(0);
    }
  }, [!!entries]);

  function onScroll(e: React.UIEvent) {
    if (!e.target || !(e.target instanceof HTMLElement)) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (!isFetching && hasMore && scrollTop + 50 >= scrollHeight - clientHeight) {
      setIsFetching(true);
      loadEntries(entries?.length ?? 0);
    }
  }

  return (
    <div className={cn(styles.MockupList, 'pt-2', 'cy-mockup-list', className)} onScroll={onScroll}>
      {entries?.map((entry) => (
        <EntryElt key={entry.id} entry={entry} onSelect={onSelect} isLoading={loadingEntry === entry} />
      ))}
      {isFetching && (
        <div className={cn(styles.loader, 'cy-scroll-loader')}>
          <IconLoading />
        </div>
      )}
    </div>
  );
}

export default React.memo(MockupList) as <T extends { id: string }>(props: Props<T>) => JSX.Element;

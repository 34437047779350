import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';

import { CanShow } from 'editor/src/component/Menu/type';

import ButtonProductFitting from './ButtonProductFitting';
import ButtonProductFittingDropDown from './ButtonProductFittingDropDown';

export const canShow: CanShow = (state, { hasSelection, structureIndexes }) => {
  const element = hasSelection && getMediaElement(state, structureIndexes[0]);
  return !!element && isMediaMockupPlaceholder(element) && state.hostSettings.enableProductFitting;
};

export { ButtonProductFittingDropDown as ButtonProductAreaDropDown, ButtonProductFitting as ButtonProductArea };

export default ButtonProductFitting;

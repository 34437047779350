import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import setSelectedVariationGroupOperation from 'editor/src/store/variants/operation/setSelectedVariationGroupOperation';
import toggleLinkVariantGroupOperation from 'editor/src/store/variants/operation/toggleLinkVariantGroupOperation';
import { linkAllDesignOptionsVariantsAction, setSelectedDesignOptionAction } from 'editor/src/store/variants/slice';
import { DesignOption, DesignOptionGroup, VariationGroup } from 'editor/src/store/variants/types';

import { RootState } from 'editor/src/store';
import useSpreadPreview from 'editor/src/util/design/useSpreadPreview';

import { PopupName } from 'editor/src/component/Popup/popups';
import ProductVariantEntry from 'editor/src/component/ProductVariantList/ProductVariantEntry';
import { useCanvasRendering } from 'editor/src/component/SpreadPreview';
import Widget from 'editor/src/component/Widget';

import WidgetHeader from './WidgetHeader';

import styles from './index.module.scss';

interface Props {
  designGroup: DesignOptionGroup;
  selectedDesignOptionValue: string;
  onDelete?: (key: string) => void;
}

function selector(state: RootState) {
  const { variationGroups, selectedGroupKey, selectedMultiOptions, product, configuration } = state.variants;
  return {
    variationGroups,
    selectedGroupKey,
    options: selectedMultiOptions,
    product,
    configuration,
    unit: getSettingsValue(state, SettingsProperty.units),
    spreadIndex: state.editor.currentSpreadIndex,
  };
}

function DesignOptionVariationGroup({ designGroup, selectedDesignOptionValue, onDelete }: Props) {
  const dispatch = useDispatch();
  const { requestRender } = useCanvasRendering();
  const { selectedGroupKey, options, product, configuration, unit, spreadIndex } = useSelector(selector, shallowEqual);
  const { disableLinkFeature } = configuration;

  const variationGroup = designGroup.value[0];
  const { designData } = variationGroup.variationsInfo[0] ?? {};
  const spread = designData?.spreads[spreadIndex];
  const spreadGroup = designData?.spread_groups?.find((group) => group.spreadIndexes.includes(spreadIndex));
  const allLinked = useMemo(
    () => designGroup.value.reduce((linked, group) => linked && group.linked, true),
    [designGroup],
  );
  const unavailableProducts = useSelector((state) => state.variants.product.unavailableProducts);

  const firstDesignGroupKey = designGroup.value[0].key;
  const { preview } = useSpreadPreview(
    requestRender,
    spread,
    firstDesignGroupKey,
    spreadIndex,
    { dimension: 'height', value: 40 },
    { showBlanks: true, showEmptyImages: true },
    undefined,
    spreadGroup,
  );

  const onGroupClick = useCallback(
    (groupKey: string) => {
      dispatch(setSelectedDesignOptionAction(designGroup.key));
      dispatch(setSelectedVariationGroupOperation(groupKey));
    },
    [designGroup.key],
  );

  const { t } = useTranslation();
  const onLinkToggle = useCallback((group: VariationGroup) => {
    if (group.linked) {
      dispatch(toggleLinkVariantGroupOperation(group));
      return;
    }

    dispatch(
      openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
        popupTitle: t('editor-link-variant-confirmation-title', {
          formatName: group.title,
        }),
        textLines: [t('editor-link-variant-confirmation-text')],
        options: [
          { title: t('editor-cancel') },
          {
            title: t('editor-link-all-variants-confirmation-yes-option'),
            onConfirm: () => {
              dispatch(toggleLinkVariantGroupOperation(group));
            },
          },
        ],
      }),
    );
  }, []);

  const linkAllVariants = useCallback((designOption: DesignOption) => {
    dispatch(
      openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
        popupTitle: t('editor-link-all-variants-confirmation-title'),
        textLines: [t('editor-link-all-variants-confirmation-text')],
        options: [
          { title: t('editor-cancel') }, // txt_cancel
          {
            title: t('editor-link-all-variants-confirmation-yes-option'),
            onConfirm: () => dispatch(linkAllDesignOptionsVariantsAction(designOption)),
          },
        ],
      }),
    );
  }, []);

  return (
    <div
      key={designGroup.key}
      className={cn(styles.variationGroupContainer, {
        [styles.selected]: selectedDesignOptionValue === designGroup.key,
      })}
      onClick={() => onGroupClick(designGroup.value[0].key)}
    >
      <Widget
        headerText={designGroup.name}
        className={cn(styles.widget, 'mt-2')}
        isOpenInitially
        Header={
          <WidgetHeader
            headerText={designGroup.name}
            onLinkClick={() => {
              linkAllVariants({
                value: designGroup.key,
                title: designGroup.name,
              });
            }}
            imageSrc={preview?.dataURL}
            linked={allLinked}
            hideLink={!!disableLinkFeature}
          />
        }
      >
        <div className={styles.separator} />
        <div className={cn(styles.list, 'cy-design-variation-group')}>
          {designGroup.value.map((groupValue) => (
            <ProductVariantEntry
              key={groupValue.key}
              group={groupValue}
              isSelected={groupValue.key === selectedGroupKey}
              options={options}
              linkEnabled={!disableLinkFeature}
              onSelect={onGroupClick}
              onLinkToggle={onLinkToggle}
              onDelete={designGroup.value.length > 1 ? onDelete : undefined}
              requestRender={requestRender}
              product={product}
              unit={unit}
              spreadIndex={spreadIndex}
              className={cn(styles.variantEntry, 'cy-design-variation-entry-group')}
              overrideStyles={false}
              unavailableProducts={unavailableProducts}
            />
          ))}
        </div>
      </Widget>
    </div>
  );
}

export default React.memo(DesignOptionVariationGroup);

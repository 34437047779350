import cn from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import addConditionOptionOperation from 'editor/src/store/design/operation/addConditionOptionOperation';
import removeConditionOptionOperation from 'editor/src/store/design/operation/removeConditionOptionOperation';
import updateConditionOptionImageIdOperation from 'editor/src/store/design/operation/updateConditionOptionImageIdOperation';
import updateConditionOptionLabelOperation from 'editor/src/store/design/operation/updateConditionOptionLabelOperation';
import { Condition } from 'editor/src/store/design/types';
import { GalleryImage } from 'editor/src/store/gallery/types';
import { useDispatch } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';

import ConditionValueBuilder from './ConditionValueBuilder';
import ImagePicker from './ImagePicker';

import styles from './index.module.scss';

interface Props {
  spreadIndex: number;
  condition: Condition;
}

function OptionsSection({ condition, spreadIndex }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeImageOptionId, setActiveImageOptionId] = useState<string>();

  const focusedElementIndexRef = useRef<number>(-1);
  function addValue() {
    focusedElementIndexRef.current = condition.options.length;
    dispatch(addConditionOptionOperation({ spreadIndex, conditionId: condition.id }));
  }

  const onBlur = useCallback(() => {
    focusedElementIndexRef.current = -1;
  }, [spreadIndex, condition.id]);

  const onValueUpdate = useCallback(
    (optionId: string, label: string) => {
      dispatch(updateConditionOptionLabelOperation({ spreadIndex, conditionId: condition.id }, optionId, label));
    },
    [spreadIndex, condition.id],
  );

  const updateImage = useCallback((optionId: string) => {
    setActiveImageOptionId(optionId);
  }, []);

  const onImageSelected = useCallback(
    (image: GalleryImage) => {
      if (!activeImageOptionId) {
        return;
      }

      dispatch(
        updateConditionOptionImageIdOperation({
          address: { spreadIndex, conditionId: condition.id },
          optionId: activeImageOptionId,
          imageId: image.id,
        }),
      );

      setActiveImageOptionId(undefined);
    },
    [spreadIndex, condition.id, activeImageOptionId],
  );

  const closePicker = useCallback(() => {
    setActiveImageOptionId(undefined);
  }, []);

  const removeValue = useCallback(
    (optionId: string) => {
      dispatch(removeConditionOptionOperation({ spreadIndex, conditionId: condition.id }, optionId));
    },
    [spreadIndex, condition.id],
  );

  return (
    <div
      className={cn(
        styles.OptionsSection,
        { [styles.slide]: !!activeImageOptionId },
        'cy-personalisation-options-section',
      )}
    >
      <div className={styles.subSection}>
        <div className={styles.options}>
          {condition.options.map((option, i) => (
            <ConditionValueBuilder
              key={option.id}
              option={option}
              canRemove={condition.options.length > 2}
              onRemove={removeValue}
              onUpdate={onValueUpdate}
              updateImage={updateImage}
              onBlur={onBlur}
              focused={focusedElementIndexRef.current === i}
            />
          ))}
        </div>
        <Button secondary className={cn(styles.addOption, 'cy-add-option-button')} onClick={addValue}>
          {t('editor-condition-add-option')}
        </Button>
      </div>
      <div className={styles.subSection}>
        <ImagePicker close={closePicker} onImageSelected={onImageSelected} />
      </div>
    </div>
  );
}

export default React.memo(OptionsSection);

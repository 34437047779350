import getSpreadPage from 'editor/src/store/design/selector/getSpreadPage';
import { RootState } from 'editor/src/store/index';

import getCurrentSpreadIndex from './getCurrentSpreadIndex';

const getCurrentPage = (state: RootState) => {
  const spreadIndex = getCurrentSpreadIndex(state);
  const pageIndex = 0;
  return getSpreadPage(state, spreadIndex, pageIndex);
};

export default getCurrentPage;

import { CanShow } from 'editor/src/component/Menu/type';

import ButtonLineEdge2 from './ButtonLineEdge2';
import ButtonLineEdge2DropDown from './ButtonLineEdge2DropDown';

export const canShow: CanShow = (state, { isPersonalizationLocked, hasSelection }) =>
  hasSelection && !isPersonalizationLocked;

export { ButtonLineEdge2, ButtonLineEdge2DropDown };

export default ButtonLineEdge2;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginName } from 'editor/src/store/plugins/types';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';

import ButtonAddLayer from './ButtonAddLayer';
import LayerItemsList from './LayerItemsList';

function LayersTabContent({ title }: TabContentProps) {
  const { t } = useTranslation();
  const personalizationPlugin = useSelector((state) => getPlugin(state, PluginName.personalizationStudio));

  return (
    <>
      <TabContentHeader title={title || t('editor-layers')} />
      <ContentBlock>
        <ButtonAddLayer showCollection={!personalizationPlugin?.extra?.hideCollections} />
      </ContentBlock>
      <ContentBlock id="layersContainer" scroll fill>
        <LayerItemsList />
      </ContentBlock>
    </>
  );
}

export default React.memo(LayersTabContent);

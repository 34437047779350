import { batch } from 'editor/src/store/batchedSubscribeEnhancer';

import { ThunkDispatch } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/index';
import getActiveFilters from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getActiveFilters';
import {
  setActiveGroupAction as setActiveGroupOperation,
  setSearchFiltersAction,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/slice';
import { ContentPluginRootState } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

import requestDataOperation from './requestDataOperation';

const applyActiveFiltersOperation = () => (dispatch: ThunkDispatch, getState: () => ContentPluginRootState) => {
  const state = getState();
  const activeFilters = getActiveFilters(state);

  batch(() => {
    dispatch(setSearchFiltersAction(activeFilters));
    dispatch(setActiveGroupOperation());
    dispatch(requestDataOperation());
  });
};

export default applyActiveFiltersOperation;

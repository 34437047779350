import cn from 'classnames';
import React from 'react';

import { Adjustment } from 'editor/src/store/design/types';

import useFilterPreview from 'editor/src/component/DesktopSidebar/TabContents/FiltersTabContent/Filter/useFilterPreview';
import IconLoading from 'editor/src/component/Icon/IconLoading';

import styles from './index.module.scss';

interface Props {
  url: string;
  id: string;
  adjustments: Adjustment[] | undefined;
  onSelect: (id: string, url: string) => void;
  isActive: boolean;
}

function ImageThumbnail({ id, url, adjustments, isActive, onSelect }: Props) {
  const imageData = useFilterPreview(url, adjustments, 56, 56, 'fit');

  return (
    <button className={cn(styles.ImageThumbnail, { [styles.active]: isActive })} type="button">
      {!imageData && (
        <div className={styles.loader}>
          <IconLoading />
        </div>
      )}
      <img
        crossOrigin="anonymous"
        alt=""
        src={imageData}
        onClick={() => onSelect(id, url)}
        draggable={false}
        className={cn({ [styles.loaded]: !!imageData })}
      />
    </button>
  );
}
export default React.memo(ImageThumbnail);

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Barcode, Coords } from 'editor/src/store/design/types';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';
import FabricTextComponent from 'editor/src/component/EditorArea/fabricComponents/FabricTextComponent';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import useCanvasRotation from 'editor/src/component/EditorArea/useCanvasRotation';

const FONT_SIZE_SCALE = 0.021;

interface Props {
  barcodeArea: Barcode;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
}

function BarcodeElement({ barcodeArea, pageCoords, canvasRotation }: Props) {
  const { mm2px } = useFabricUtils();
  const { t } = useTranslation();

  const rotatedPoint = useCanvasRotation(
    canvasRotation,
    pageCoords.left + mm2px(barcodeArea.x) - 0.5,
    pageCoords.top + mm2px(barcodeArea.y) - 0.5,
  );

  const { textFontSize, widthPx, heightPx } = useMemo(
    () => ({
      textFontSize: barcodeArea.width * FONT_SIZE_SCALE,
      widthPx: mm2px(barcodeArea.width),
      heightPx: mm2px(barcodeArea.height),
    }),
    [barcodeArea.width, barcodeArea.height, mm2px],
  );

  const textRotatedPoint = useCanvasRotation(
    canvasRotation,
    pageCoords.left + mm2px(barcodeArea.x) - 0.5,
    pageCoords.top + mm2px(barcodeArea.y + (barcodeArea.height - textFontSize) / 2) - 0.5,
  );

  const r = 0 || canvasRotation.angleDeg;

  return (
    <>
      <FabricRectComponent
        left={rotatedPoint.x}
        top={rotatedPoint.y}
        width={widthPx}
        height={heightPx}
        fill="rgba(255, 255, 255, 0.6)"
        zIndex={zIndex.BLEED - 2}
        objectCaching={false}
        evented={false}
        selectable={false}
        angle={r}
        strokeWidth={mm2px(0.5)}
        stroke="rgba(189, 189, 189, 0.6)"
      />
      <FabricTextComponent
        text={t('Space reserved for the barcode. Avoid placing heavy designs here.')}
        left={textRotatedPoint.x}
        top={textRotatedPoint.y}
        fontSize={mm2px(textFontSize)}
        textAlign="center"
        fill="#383838"
        fontFamily="Gelato Sans, sans-serif"
        zIndex={zIndex.BLEED - 1}
        evented={false}
        width={widthPx}
        angle={r}
        lineHeight={1}
      />
    </>
  );
}

export default React.memo(BarcodeElement);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconFilters({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H13C13.2833 3 13.521 3.09567 13.713 3.287C13.9043 3.479 14 3.71667 14 4C14 4.28333 13.9043 4.52067 13.713 4.712C13.521 4.904 13.2833 5 13 5H5V19H19V11C19 10.7167 19.096 10.479 19.288 10.287C19.4793 10.0957 19.7167 10 20 10C20.2833 10 20.5207 10.0957 20.712 10.287C20.904 10.479 21 10.7167 21 11V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5ZM10.75 13.25L9 12.45C8.81667 12.3667 8.725 12.2167 8.725 12C8.725 11.7833 8.81667 11.6333 9 11.55L10.75 10.75L11.55 9C11.6333 8.81667 11.7833 8.725 12 8.725C12.2167 8.725 12.3667 8.81667 12.45 9L13.25 10.75L15 11.55C15.1833 11.6333 15.275 11.7833 15.275 12C15.275 12.2167 15.1833 12.3667 15 12.45L13.25 13.25L12.45 15C12.3667 15.1833 12.2167 15.275 12 15.275C11.7833 15.275 11.6333 15.1833 11.55 15L10.75 13.25ZM16.05 7.95L14.725 7.35C14.5917 7.28333 14.525 7.16667 14.525 7C14.525 6.83333 14.5917 6.71667 14.725 6.65L16.05 6.05L16.65 4.725C16.7167 4.59167 16.8333 4.525 17 4.525C17.1667 4.525 17.2833 4.59167 17.35 4.725L17.95 6.05L19.275 6.65C19.4083 6.71667 19.475 6.83333 19.475 7C19.475 7.16667 19.4083 7.28333 19.275 7.35L17.95 7.95L17.35 9.275C17.2833 9.40833 17.1667 9.475 17 9.475C16.8333 9.475 16.7167 9.40833 16.65 9.275L16.05 7.95Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconFilters;

import { useMemo } from 'react';

import limitPrecision from 'editor/src/util/limitPrecision';

const MARGIN_PX_DESKTOP = 100;
const MARGIN_PX_MOBILE_H = 50;
const MARGIN_PX_MOBILE_V = 50;

function useSetupFabricUtils(
  isMobile: boolean,
  canvasWidth: number,
  canvasHeight: number,
  spreadWidth: number,
  spreadHeight: number,
) {
  return useMemo(() => {
    const marginPxH = isMobile ? MARGIN_PX_MOBILE_H : MARGIN_PX_DESKTOP;
    const marginPxV = isMobile ? MARGIN_PX_MOBILE_V : MARGIN_PX_DESKTOP;
    let pxSize = 1;
    let mmSize = 1;
    if (canvasWidth / spreadWidth > canvasHeight / spreadHeight) {
      pxSize = canvasHeight > marginPxV * 2 ? canvasHeight - marginPxV * 2 : canvasHeight;
      mmSize = spreadHeight;
    } else {
      pxSize = canvasWidth > marginPxH * 2 ? canvasWidth - marginPxH * 2 : canvasWidth;
      mmSize = spreadWidth;
    }

    // fabric has 14 digits precision internally
    return {
      px2mm: (size: number) => limitPrecision(size * (mmSize / pxSize), 13),
      mm2px: (size: number) => limitPrecision(size * (pxSize / mmSize), 13),
      setup: canvasWidth > 0 && canvasHeight > 0,
    };
  }, [canvasWidth, canvasHeight, spreadWidth, spreadHeight, isMobile]);
}

export default useSetupFabricUtils;

import { MediaElement } from 'editor/src/store/design/types';
import getPreviewModePluginName from 'editor/src/store/editor/selector/getPreviewModePluginName';
import { RootState } from 'editor/src/store/index';
import { ImageManipulationPlugin } from 'editor/src/store/plugins/types';

import isImageManipulationPlugin from 'editor/src/util/plugins/isImageManipulationPlugin';

const getActiveImageManipulationPlugin = (state: RootState, mediaImage: MediaElement | undefined) => {
  if (mediaImage?.type !== 'image') {
    return undefined;
  }

  const previewModePluginName = getPreviewModePluginName(state, mediaImage.uuid);
  return state.plugins.list.find(
    (plugin) => isImageManipulationPlugin(plugin) && plugin.name === previewModePluginName,
  ) as ImageManipulationPlugin | undefined;
};

export default getActiveImageManipulationPlugin;

import { CanShow } from 'editor/src/component/Menu/type';

import ButtonTextAlignment from './ButtonTextAlignment';
import ButtonTextAlignmentDropDown from './ButtonTextAlignmentDropDown';

export const canShow: CanShow = (state, { isPersonalizationLocked, hasSelection }) =>
  hasSelection && !isPersonalizationLocked;

export { ButtonTextAlignmentDropDown, ButtonTextAlignment };

export default ButtonTextAlignment;

import React from 'react';

import { ValueWithDisabled } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ECommerceProductContent/eCommerceVariantUtils';

import ECommerceProductControlOption from './ECommerceProductControlOption';

import styles from './index.module.scss';

interface Props {
  title: string;
  selectedValue: string;
  options: ValueWithDisabled[];
  onChange: (value: string) => void;
}

function ECommerceProductControl({ title, selectedValue, options, onChange }: Props) {
  return (
    <div className="cy-ecommerce-product-control">
      <div className={styles.controlTitle}>{title}</div>
      <div>
        {options.map((option) => (
          <ECommerceProductControlOption
            key={option.name}
            option={option}
            selected={selectedValue === option.name}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
}

export default React.memo(ECommerceProductControl);

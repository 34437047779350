import { Plugin, PluginName, PluginState } from '../../plugins/types';

function applyPersonalizationPluginsByCondition(
  hasPersonalizableLayer: boolean,
  hasPersonalizationText: boolean,
  isAllowedToAddPersonalizationLayer: boolean,
  globalPlugins: string[],
  personalizationPluginApplied: string | undefined,
  appliedPluginNames: Set<string>,
  plugins: Plugin[],
): Set<string> {
  // if PersonalizationStudio is disabled, then apply empty plugin list
  const hasPersonalizationStudioEnabled =
    plugins.find((plugin) => plugin.name === PluginName.personalizationStudio)?.state === PluginState.ENABLED;
  if (!hasPersonalizationStudioEnabled) {
    return appliedPluginNames;
  }
  const hasAIPersonalizationEnabled =
    plugins.find((plugin) => plugin.name === PluginName.AIPersonalization)?.state === PluginState.ENABLED;
  const hasEmailWorkflowPersonalizationEnabled =
    plugins.find((plugin) => plugin.name === PluginName.emailWorkflowPersonalization)?.state === PluginState.ENABLED;
  const hasShopifyPersonalizationPluginEnabled =
    plugins.find((plugin) => plugin.name === PluginName.shopifyPersonalization)?.state === PluginState.ENABLED;

  if (hasPersonalizableLayer || hasPersonalizationText || isAllowedToAddPersonalizationLayer) {
    if (personalizationPluginApplied) {
      appliedPluginNames.add(PluginName.personalizationStudio);
      appliedPluginNames.add(personalizationPluginApplied);
    } else {
      appliedPluginNames.add(PluginName.personalizationStudio);
      if (hasAIPersonalizationEnabled || hasEmailWorkflowPersonalizationEnabled) {
        if (hasPersonalizationText && globalPlugins.includes(PluginName.AIPersonalization)) {
          appliedPluginNames.add(PluginName.AIPersonalization);
        } else {
          appliedPluginNames.add(PluginName.emailWorkflowPersonalization);
        }
      } else if (hasShopifyPersonalizationPluginEnabled && globalPlugins.includes(PluginName.shopifyPersonalization)) {
        appliedPluginNames.add(PluginName.shopifyPersonalization);
      } else {
        appliedPluginNames.add(PluginName.shopifyPersonalizationInEditor);
      }
    }
  }

  return appliedPluginNames;
}

export default applyPersonalizationPluginsByCondition;

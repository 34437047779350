import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconCheck from 'editor/src/component/Icon/IconCheck';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  usage: number;
}

function UsageBadge({ usage }: Props) {
  const { t } = useTranslation();
  return (
    <WithTooltip overlay={t('editor-image-use-counter')}>
      <div className={cn(styles.usageBadge, 'cy-usage-badge')}>
        <IconCheck />
        {usage}
      </div>
    </WithTooltip>
  );
}

export default UsageBadge;

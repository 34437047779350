import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import removeAllSelectedMediaElementsOperation from 'editor/src/store/editor/operation/removeAllSelectedMediaElementsOperation';
import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch } from 'editor/src/store/hooks';

import LayoutsMobileMenu from 'editor/src/component/BottomBarMobile/ActionBarSection/LayoutsMobileMenu';
import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconLayouts from 'editor/src/component/Icon/IconLayouts';
import useMobileMenu from 'editor/src/component/useMobileMenu';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
  active?: boolean;
}

function LayoutsMobileTab({ pluginName, customTabInfo, active }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const topMenu = useMobileMenu();
  const layoutsMenu = useMobileMenu(false, true);

  const onMobileCLick = () => {
    layoutsMenu.open();
    dispatch(removeAllSelectedMediaElementsOperation());
    topMenu.close();
  };

  const closeMenu = useCallback(() => {
    layoutsMenu.close();
  }, []);

  return (
    <>
      <Tab
        key={TAB_NAMES.LAYOUTS}
        name={TAB_NAMES.LAYOUTS}
        title={t('editor-layouts')}
        icon={<IconLayouts active={active} />}
        pluginName={pluginName}
        onMobileClick={onMobileCLick}
        customTabInfo={customTabInfo}
      />
      {layoutsMenu.render(<LayoutsMobileMenu closeMenuCb={closeMenu} />)}
    </>
  );
}

export default React.memo(LayoutsMobileTab);

import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import setDesignColorSpaceOperation from 'editor/src/store/design/operation/setDesignColorSpaceOperation';
import getDesignColorSpace from 'editor/src/store/design/selector/getDesignColorSpace';
import { ColorSpace } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { VariationProductControl, ProductControlOption } from 'editor/src/store/variants/types';

import ButtonElement from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/elements/ButtonElement';
import SingleValueProductControl from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/single/SingleValueProductControl';

import buttonStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/single/SingleControl/index.module.scss';

interface Props {
  noTopBar?: boolean;
}

function ColorSpaceControl({ noTopBar }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const colorSpace = useSelector(getDesignColorSpace);

  function onSelect(_: VariationProductControl, option: ProductControlOption) {
    dispatch(setDesignColorSpaceOperation(option.value as ColorSpace));
  }

  const control: VariationProductControl = useMemo(
    () => ({
      type: 'single',
      uiType: 'button',
      key: 'print',
      title: t('Color space'),
      options: [
        { title: 'sRGB', value: ColorSpace.sRGB },
        { title: 'CMYK', value: ColorSpace.CMYK },
      ],
    }),
    [],
  );

  return (
    <SingleValueProductControl
      noTopBar={noTopBar}
      className={cn(buttonStyles.ButtonControl, 'cy-colorspace-control')}
      title={control.title}
      control={control}
      options={control.options}
      toggleOption={onSelect}
      selectedValue={colorSpace}
      Element={ButtonElement}
    />
  );
}

export default React.memo(ColorSpaceControl);

import React from 'react';

import styles from './index.module.scss';

export interface Props {
  message: string;
  optionCount: number;
  maxCount: number;
}

function MaxMessageInfo({ message, optionCount, maxCount }: Props) {
  if (optionCount <= maxCount) {
    return null;
  }

  return <div className={styles.MaxMessageInfo}>{message}</div>;
}

export default React.memo(MaxMessageInfo);

import isMobile from 'editor/src/store/app/selector/isMobile';
import { setActiveTopMenuDropdownAction } from 'editor/src/store/app/slice';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import { RootState } from 'editor/src/store/index';

const activateTabAfterImageModifiedOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();

  const activeTab = getSidebarActiveTab(state);
  batch(() => {
    if (getHostSetting(state, 'editImageFlag')) {
      activeTab !== TAB_NAMES.EDIT_IMAGE && dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.EDIT_IMAGE));
    } else if (getSidebarActiveTab(state) === TAB_NAMES.IMAGE_MANIPULATION) {
      dispatch(setSidebarActiveTabOperation(-1));
    }

    if (!isMobile(state)) {
      dispatch(setActiveTopMenuDropdownAction());
    }
  });
};

export default activateTabAfterImageModifiedOperation;

import { fabric } from 'fabric';

import { ImagePattern } from 'editor/src/store/design/types';

import { convFn } from 'editor/src/util/convFn';

import { getPatternRepeat } from './useFilters';

function updatePatternFilter(
  image: fabric.Image,
  frame: fabric.Object,
  pattern: ImagePattern | undefined,
  px2mm: convFn,
) {
  if (!pattern?.scaleBase || !image.getElement()) {
    return;
  }

  const brickFilter = image.filters?.find((filter) => (filter as any).type === 'BrickFilter');
  if (brickFilter && pattern.scaleBase) {
    const { scaleBase, scale } = pattern;
    (brickFilter as any).repeat = getPatternRepeat(
      scale,
      scaleBase,
      px2mm(frame.width ?? 0),
      px2mm(frame.height ?? 0),
      px2mm(image.getScaledWidth()),
      px2mm(image.getScaledHeight()),
    );

    image.applyFilters(image.filters);
  }
}

export default updatePatternFilter;

import React from 'react';
import { useTranslation } from 'react-i18next';

import explanation from './explaination.mp4';

import styles from './index.module.scss';

function TextFittingTooltip() {
  const { t } = useTranslation();

  return (
    <div className={styles.overlay}>
      <video autoPlay loop muted playsInline className={styles.video} src={explanation} />
      <div className={styles.container}>
        <div className={styles.title}>{t('Autofit text')}</div>
        <div className={styles.description}>{t('autofit-description')}</div>
      </div>
    </div>
  );
}

export default React.memo(TextFittingTooltip);

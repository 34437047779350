import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import resetSettingsToDefaultOperation from 'editor/src/store/editor/operation/resetSettingsToDefaultOperation';
import { useDispatch } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';

import styles from './index.module.scss';

function ButtonResetSettings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(resetSettingsToDefaultOperation());
  };

  return (
    <div className={styles.settingsResetButtonWrapper}>
      <Button
        secondary
        className={cn(styles.settingsResetButton, 'cy-reset-to-default-settings')}
        onClick={handleClick}
      >
        {t('editor-settings-reset')}
      </Button>
    </div>
  );
}

export default React.memo(ButtonResetSettings);

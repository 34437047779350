import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { UNSELECTED_PAGE_COUNT } from 'editor/src/store/variants/reducer/setPageCountReducer';
import { VariantInfo } from 'editor/src/store/variants/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const sendSwitchProductOperation = (variant: VariantInfo) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const { selectedPageCount } = getState().variants;
  const { dimensions, variation } = variant;

  sendPostMessage('design.switchToProduct', {
    productUid: variation.productUid,
    width: dimensions ? Math.floor(dimensions.width) : undefined,
    height: dimensions ? Math.floor(dimensions.height) : undefined,
    pageCount: selectedPageCount === UNSELECTED_PAGE_COUNT ? undefined : selectedPageCount,
  });
};

export default sendSwitchProductOperation;

import cn from 'classnames';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import updateMediaElementFiltersOperation, {
  getUpdatedFilters,
} from 'editor/src/store/design/operation/updateMediaElementFiltersOperation';
import getFilterByUuid from 'editor/src/store/design/selector/getFilterByUuid';
import { ElementAddress, MediaImage } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPluginIcon from 'editor/src/store/plugins/selector/getPluginIcon';
import { PluginName } from 'editor/src/store/plugins/types';

import { logEvent } from 'editor/src/amplitude';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import AdjustmentSlider from 'editor/src/component/AdjustmentSlider';
import { FilterInfo } from 'editor/src/component/DesktopSidebar/TabContents/FiltersTabContent/filters';
import { getSanitizeValue } from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/utils';

import useFilterPreview from './useFilterPreview';

import styles from './index.module.scss';

interface Props {
  element: MediaImage;
  address: ElementAddress;
  imageUrl: string;
  filter: FilterInfo;
  active?: boolean;
  rowActive?: boolean;
  onClick?: (name: string) => void;
  mobile?: boolean;
  disabled?: boolean;
  showPremiumIcon?: boolean;
  showAdjustmentSlider?: boolean;
  filterClassName?: string;
}

function Filter({
  element,
  address,
  imageUrl,
  filter,
  active,
  onClick,
  rowActive,
  mobile,
  disabled,
  showPremiumIcon,
  showAdjustmentSlider = true,
  filterClassName,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterValue = useSelector((store) => getFilterByUuid(store, element.uuid, filter.name) ?? filter.default);
  const PremiumIcon = useSelector((state) => getPluginIcon(state, PluginName.Filters));

  const [currentValue, setCurrentValue] = useState(filterValue);

  useEffect(() => setCurrentValue(filterValue), [filterValue]);
  const { liveUpdate } = useMediaElementLiveUpdates(element);

  const imageWithFilterUrl = useFilterPreview(imageUrl, filter.adjustments, 150, 150, 'fill');

  const onLiveChange = useCallback(
    (value: number) => {
      const { filters, adjustments } = getUpdatedFilters(filter, value);
      liveUpdate({ ...element, filters, adjustments });
      setCurrentValue(value);
    },
    [address, element],
  );

  const onChange = useCallback(
    (value: number) => {
      dispatch(updateMediaElementFiltersOperation(address, filter, value));
    },
    [address, filter],
  );

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    const numberValue = getSanitizeValue(value, filter.default, 0, 100);
    if (numberValue !== filterValue) {
      onChange(numberValue);
    }
  };

  const onFilterClick = () => {
    if (disabled) {
      sendPostMessage('plugins.disabledPluginClick', {
        name: PluginName.Filters,
        filter: filter.name,
      });
    } else {
      onClick?.(filter.name);
      onChange(filterValue);
      logEvent('element modified', {
        type: 'image',
        property: 'filter',
        value: filter.name,
      });
    }
  };

  return (
    <div
      className={cn(styles.filterItem, filterClassName, `cy-filter-${filter.name}`, {
        'cy-active': active,
        [styles.active]: active,
        [styles.rowActive]: rowActive,
        [styles.mobile]: mobile,
      })}
    >
      <div className={cn(styles.border, 'cy-filter-image')} onClick={onFilterClick}>
        <div className={styles.imagePlaceholder}>
          {imageWithFilterUrl && (
            <img className={styles.image} crossOrigin="anonymous" src={imageWithFilterUrl} alt="filter" />
          )}
          {(disabled || showPremiumIcon) && <PremiumIcon className={cn(styles.premium, 'cy-premium-icon')} />}
        </div>
        <span className={styles.label}>{t(filter.translationKey)}</span>
      </div>
      {(active || mobile) && !filter.empty && showAdjustmentSlider && (
        <div className={styles.options}>
          <span className={styles.sliderLabel}>{t('editor-intensity')}</span>
          <div className={styles.slider}>
            <AdjustmentSlider
              min={0}
              max={100}
              step={1}
              value={filterValue}
              onChange={onLiveChange}
              onAfterChange={onChange}
              className="cy-filter-slider"
            />
          </div>
          <input
            className={cn(styles.input, 'cy-filter-input')}
            value={`${currentValue}`}
            type="text"
            onChange={onInputChange}
          />
        </div>
      )}
    </div>
  );
}

export default Filter;

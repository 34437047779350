import { MediaImage } from 'editor/src/store/design/types';

import limitPrecision from 'editor/src/util/limitPrecision';
import { getRotatedCropPosition } from 'editor/src/util/reflectDesignData/updateImageElement';
import { getRectangleBoundingBox } from 'editor/src/util/reflectDesignData/utils';

const getCropRotationUpdates = (image: MediaImage, angle: number) => {
  const bbox = getRectangleBoundingBox(0, 0, image.width, image.height, angle);

  const scale = Math.max(bbox.width / image.pw, bbox.height / image.ph);
  const pw = Math.max(bbox.width, image.pw * scale);
  const ph = Math.max(bbox.height, image.ph * scale);

  const centeredPx = (bbox.width - pw) / 2;
  const centeredPy = (bbox.height - ph) / 2;
  const boxedPx = Math.min(0, Math.max(bbox.width - pw, centeredPx));
  const boxedPy = Math.min(0, Math.max(bbox.height - ph, centeredPy));
  const [px, py] = getRotatedCropPosition(boxedPx, boxedPy, bbox, image, angle);

  return {
    px: limitPrecision(px),
    py: limitPrecision(py),
    ph: limitPrecision(ph),
    pw: limitPrecision(pw),
    pr: limitPrecision(angle),
  };
};

export default getCropRotationUpdates;

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import updateImageElementColorsOperation from 'editor/src/store/design/operation/updateImageElementColorsOperation';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { ElementAddress } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import { EMPTY_RGBA_STRING } from 'editor/src/util/color/rgbaUtils';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import useCurrentElementColors from 'editor/src/component/TopMenuDesktop/buttons/ButtonImageColor/useCurrentElementColors';

import ThreadColorGuidelines from './ThreadColorGuidelines';
import ThreadColorItem from './ThreadColorItem';

import styles from './index.module.scss';

function ThreadColorTabContent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const imageColors = useCurrentElementColors();

  const selectedMediaElementId = useSelector((state) => getSelectedImageUuid(state));
  const digitizationRules = useSelector((state) => getHostSetting(state, 'digitizationRules'));
  const structureIndex = useSelector((state) =>
    selectedMediaElementId ? getStructureIndexByElementUuidMemoized(state, selectedMediaElementId) : undefined,
  ) as ElementAddress | undefined;

  const colorPickerConfig = useSelector((state) => getHostSetting(state, 'colorPickerConfig'));
  const usedColors = useMemo(() => imageColors.map(({ new: newColor }) => newColor), [imageColors]);

  const handleColorChange = useCallback(
    (newColor: string | undefined, colorIndex: number) => {
      const currentColor = imageColors[colorIndex];
      if (!structureIndex || currentColor.new.toUpperCase() === newColor?.toUpperCase()) {
        return;
      }

      const color = {
        previous: currentColor.previous,
        new: newColor || EMPTY_RGBA_STRING,
      };
      dispatch(updateImageElementColorsOperation(structureIndex, color));
    },
    [structureIndex, colorPickerConfig.noColor, imageColors],
  );

  // if image is not svg or if there aren't detected any colors and it's not digitized, do not render
  if (!imageColors.length) {
    return null;
  }

  return (
    <>
      <TabContentHeader title={t('Thread colors')} className="row-flex row-flex--between row-flex--middle">
        <ThreadColorGuidelines />
      </TabContentHeader>
      <ContentBlock scroll className="py-4">
        <div className={styles.notes}>
          {t('thread colors-selection-notes', {
            count: digitizationRules.maxColorsCount,
          })}
        </div>
        {imageColors.map(({ previous, new: newColor }, colorIndex) => (
          <ThreadColorItem
            key={previous}
            originalColor={previous}
            newColor={newColor.toLowerCase()}
            colorIndex={colorIndex}
            colorInfo={colorPickerConfig.colorsInfo}
            colors={colorPickerConfig.allowedColors}
            usedColors={usedColors}
            onColorChange={handleColorChange}
          />
        ))}
      </ContentBlock>
    </>
  );
}

export default React.memo(ThreadColorTabContent);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconStrokeWidth({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 10.5L30 10.5" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <rect
          x="10"
          y="24.5"
          width="20"
          height="6"
          fill="#212121"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="10"
          y="16.5"
          width="20"
          height="3"
          fill="#212121"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

export default IconStrokeWidth;

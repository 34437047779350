import React from 'react';

import getSelectedLineStylesProperty from 'editor/src/store/design/selector/shapes/getSelectedLineStylesProperty';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useBrowserColor from 'editor/src/util/useBrowserColor';

import IconStrokeColor from 'editor/src/component/Icon/IconStrokeColor';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton, { Props as MenuProps } from './MenuButton';

const MenuColorButton = MenuButton as React.FC<MenuProps<{ color: string }>>;

export const canShow: CanShow = (state, { isPersonalizationLocked }) => !isPersonalizationLocked;

type Props = Pick<MenuItemProps, 'mode' | 'close' | 'isMobile'>;

function ButtonLineStrokeColor({ mode, close, isMobile }: Props) {
  const currentColor = useSelector((state) => getSelectedLineStylesProperty(state, 'stroke'));
  const isActive = useSelector((state) => isSidebarTabActive(state, TAB_NAMES.LINE_STROKE_COLOR));
  const dispatch = useDispatch();
  const color = useBrowserColor(currentColor);

  const handleClick = () => {
    if (isMobile) {
      if (isActive) {
        dispatch(setSidebarActiveTabOperation(-1));
      } else {
        dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.LINE_STROKE_COLOR));
      }
    } else {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.LINE_STROKE_COLOR));
      close?.();
    }
  };

  return (
    <MenuColorButton
      Icon={IconStrokeColor}
      iconProps={{ color }}
      on={isActive}
      mode={mode}
      handleClick={handleClick}
      className="cy-button-line-stroke-color"
      label="Stroke color"
    />
  );
}

export default React.memo(ButtonLineStrokeColor);

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import autoFillOperation from 'editor/src/store/editor/operation/autoFillOperation';
import isAllImagesUsed from 'editor/src/store/editor/selector/isAllImagesUsed';
import isAutofillAllowed from 'editor/src/store/editor/selector/isAutofillAllowed';
import isUseNotUploadedImagesAllowed from 'editor/src/store/editor/selector/isUseNotUploadedImagesAllowed';
import areAllImagesUploaded from 'editor/src/store/gallery/selector/areAllImagesUploaded';
import getGalleryImagesCount from 'editor/src/store/gallery/selector/getGalleryImagesCount';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import IconAutoFill from 'editor/src/component/Icon/IconAutoFill';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  onAutofillCb?: () => void;
}

const LOADING_IMAGES_TIMEOUT = 2000;

function ButtonAutoFill({ onAutofillCb }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { noUnusedImages, autofillAllowed, imageCount, allowedUseNotUploadedImages, allImagesUploaded } = useSelector(
    (state) => ({
      autofillAllowed: isAutofillAllowed(state),
      noUnusedImages: isAllImagesUsed(state),
      imageCount: getGalleryImagesCount(state),
      allowedUseNotUploadedImages: isUseNotUploadedImagesAllowed(state),
      allImagesUploaded: areAllImagesUploaded(state),
    }),
    shallowEqual,
  );
  const loadingImagesTimeout = useRef<any | undefined>(undefined);
  const [loadingImages, setLoadingImages] = useState(false);
  const [previousImageCount, setPreviousImageCount] = useState(imageCount);

  useEffect(() => {
    setPreviousImageCount(imageCount);
    if (loadingImagesTimeout.current) {
      clearTimeout(loadingImagesTimeout.current);
    }
    if (previousImageCount < imageCount) {
      setLoadingImages(true);
      loadingImagesTimeout.current = setTimeout(() => {
        setLoadingImages(false);
      }, LOADING_IMAGES_TIMEOUT);

      return () => clearTimeout(loadingImagesTimeout.current);
    }

    return undefined;
  }, [imageCount]);

  if (!autofillAllowed) {
    return null;
  }

  const onClick = () => {
    dispatch(autoFillOperation());
    onAutofillCb?.();
  };

  let toltipKey = 'editor-fill-design-with-images';
  if (loadingImages) {
    toltipKey = 'editor-wait-for-generating-thumbnails';
  }
  if (noUnusedImages) {
    toltipKey = 'editor-no-unused-images';
  }
  if (imageCount === 0) {
    toltipKey = 'editor-no-images';
  }

  if (!allowedUseNotUploadedImages && !allImagesUploaded) {
    toltipKey = 'editor-uploading-...';
  }

  return (
    <WithTooltip overlay={t(toltipKey)} className="cy-autofill-btn">
      <Button
        tertiary
        stopPropagation
        className={styles.buttonAutoFill}
        disabled={noUnusedImages || loadingImages || !(allowedUseNotUploadedImages || allImagesUploaded)}
        onClick={onClick}
      >
        <IconAutoFill className={styles.icon} />
        {t('editor-auto-fill')}
      </Button>
    </WithTooltip>
  );
}

export default React.memo(ButtonAutoFill);

import React from 'react';

import { StructureIndex } from 'editor/src/store/design/types';

import IconAlignCenter from 'editor/src/component/Icon/IconAlignCenter';
import AlignActionButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignActionButton';

interface Props {
  structureIndexes: StructureIndex[];
}

function AlignCenterButton({ structureIndexes }: Props) {
  return (
    <AlignActionButton
      structureIndexes={structureIndexes}
      icon={<IconAlignCenter />}
      alignTo="center"
      keepTopDropdown
    />
  );
}

export default React.memo(AlignCenterButton);

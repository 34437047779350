/* eslint-disable max-len */
import React from 'react';

interface Props {
  className?: string;
}

function IconWarning2({ className }: Props) {
  return (
    <svg
      className={className}
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.647777 10.75C0.414444 10.75 0.237111 10.6478 0.115777 10.4434C-0.00602261 10.2395 -0.00858931 10.0356 0.108077 9.83162L5.47498 0.585319C5.59164 0.381386 5.76664 0.279419 5.99998 0.279419C6.23331 0.279419 6.40831 0.381386 6.52498 0.585319L11.8919 9.83162C12.0085 10.0356 12.006 10.2395 11.8842 10.4434C11.7628 10.6478 11.5855 10.75 11.3522 10.75H0.647777ZM6.01468 4.34782C5.84948 4.34782 5.70598 4.40872 5.58418 4.53052C5.46284 4.65185 5.40218 4.79512 5.40218 4.96032V6.59342C5.40218 6.75909 5.46284 6.90259 5.58418 7.02392C5.70598 7.14572 5.84948 7.20662 6.01468 7.20662C6.17988 7.20662 6.32314 7.14572 6.44448 7.02392C6.56628 6.90259 6.62718 6.75909 6.62718 6.59342V4.96032C6.62718 4.79512 6.56628 4.65185 6.44448 4.53052C6.32314 4.40872 6.17988 4.34782 6.01468 4.34782ZM6.01468 9.00002C6.17988 9.00002 6.32314 8.93935 6.44448 8.81802C6.56628 8.69622 6.62718 8.55272 6.62718 8.38752C6.62718 8.22232 6.56628 8.07882 6.44448 7.95702C6.32314 7.83569 6.17988 7.77502 6.01468 7.77502C5.84948 7.77502 5.70598 7.83569 5.58418 7.95702C5.46284 8.07882 5.40218 8.22232 5.40218 8.38752C5.40218 8.55272 5.46284 8.69622 5.58418 8.81802C5.70598 8.93935 5.84948 9.00002 6.01468 9.00002Z" />
    </svg>
  );
}

export default React.memo(IconWarning2);

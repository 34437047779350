import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { loadSpreadImages } from 'editor/src/store/design/operation/setSpreadBackgroundsOperation';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import {
  setActiveProductAction,
  setMockDataAction,
  setPlaceholderImageAction,
  updateMockupDataWithVariantAction,
} from 'editor/src/store/mockup/slice';
import { MockupCategory, MockupSize, SceneCategory } from 'editor/src/store/mockup/types';
import { ProductVariation, VariationProductControl } from 'editor/src/store/variants/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import applyDefaultMockupSceneIfNoneAppliedOperation from './applyDefaultMockupSceneIfNoneAppliedOperation';
import { ExtendedMockupVariant } from './saveVariantOperation';

export interface Input {
  scenesCategories: SceneCategory[];
  currentVariant: ExtendedMockupVariant;
  controls: VariationProductControl[];
  variations: ProductVariation[];
  sizes: MockupSize[];
  categories: MockupCategory[];
}

const setMockupDataOperation =
  ({ scenesCategories, currentVariant, controls, variations, sizes, categories }: Input) =>
  async (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const placeholder = state.design.designData?.spreads[0].pages[0].groups.media?.find(isMediaMockupPlaceholder);
    const { foregrounds: foregroundsWODim, backgrounds: backgroundsWODim, ...variant } = currentVariant;
    const [foregrounds, backgrounds] = await Promise.all([
      loadSpreadImages(foregroundsWODim),
      loadSpreadImages(backgroundsWODim),
    ]);
    const variants =
      placeholder?.variant && variations.some((variation) => variation.productUid === placeholder.variant.productUid)
        ? [{ variant: placeholder.variant }, { variant, foregrounds, backgrounds }]
        : [{ variant, foregrounds, backgrounds }];

    if (placeholder) {
      // to get the background/foreground of the placeholder
      sendPostMessage('mockup.requestVariant', placeholder.variant.productUid);
    }

    batch(() => {
      dispatch(
        setMockDataAction({
          scenesCategories,
          variants,
          controls,
          variations,
          sizes,
          categories,
        }),
      );
      dispatch(setActiveProductAction(variants[0].variant.productUid));
      dispatch(updateMockupDataWithVariantAction(variants[0].variant.productUid));
      dispatch(applyDefaultMockupSceneIfNoneAppliedOperation());
      currentVariant.productImage &&
        dispatch(
          setPlaceholderImageAction({
            productUid: currentVariant.productUid,
            image: currentVariant.productImage,
          }),
        );
    });
  };

export default setMockupDataOperation;

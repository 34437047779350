/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconReplace({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 25 24"
        fill="none"
        style={{ strokeWidth: 0 }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.7456 11.935C20.7456 11.7909 20.7424 11.6482 20.7361 11.5068C20.7413 11.6233 20.7444 11.7407 20.7453 11.859L20.7456 11.935Z"
          fill="#212121"
        />
        <path
          d="M12.4999 20.22C10.2726 20.22 8.37545 19.4221 6.79835 17.8211C5.22083 16.2205 4.4342 14.3046 4.4342 12.0638V11.3785L3.46777 12.4046C3.28039 12.5945 3.06881 12.684 2.82529 12.684C2.58687 12.684 2.37652 12.5894 2.18733 12.3822L2.18046 12.3747L2.1728 12.368C1.97376 12.1941 1.87988 11.9888 1.87988 11.7417C1.87988 11.4942 1.97515 11.2672 2.18293 11.0552L4.73725 8.44902C4.8596 8.32418 4.97956 8.24071 5.09614 8.19166C5.2179 8.14042 5.34151 8.11532 5.46823 8.11532C5.59589 8.11532 5.71989 8.14047 5.84153 8.19166C5.95811 8.24071 6.07807 8.32418 6.20042 8.44902L8.78 11.0809C8.96717 11.2726 9.05657 11.4902 9.05657 11.7417C9.05657 11.9938 8.96752 12.2002 8.78509 12.3726L8.78045 12.3773C8.57276 12.5885 8.35138 12.684 8.11117 12.684C7.87275 12.684 7.66249 12.5894 7.47329 12.3821L6.50226 11.3914V12.039C6.50226 13.7033 7.09034 15.1375 8.26189 16.3319C9.43311 17.5269 10.849 18.1274 12.4999 18.1274C12.848 18.1274 13.1922 18.0967 13.5315 18.0353C13.8713 17.973 14.1986 17.8794 14.5132 17.7544C14.6992 17.6817 14.8858 17.6674 15.0775 17.7105C15.274 17.7553 15.4194 17.8414 15.5252 17.963L15.5327 17.9706C15.8556 18.3001 15.9703 18.6369 15.9132 18.9906C15.8841 19.1734 15.8182 19.3193 15.72 19.4351C15.6214 19.5513 15.4829 19.6459 15.2958 19.7144L15.2941 19.7151C14.85 19.8828 14.389 20.0091 13.9111 20.0939C13.4311 20.1781 12.9608 20.22 12.4999 20.22Z"
          fill="#212121"
        />
        <path
          d="M22.1866 11.264C22.439 11.264 22.6539 11.3545 22.8401 11.5434C23.0303 11.7527 23.1199 11.9804 23.1199 12.2323C23.1199 12.4835 23.0302 12.7011 22.8423 12.8928L20.2625 15.525C20.1415 15.6485 20.0244 15.7258 19.9124 15.7665C19.7886 15.8107 19.662 15.8327 19.5315 15.8327C19.4002 15.8327 19.2731 15.8107 19.1494 15.7665C19.0375 15.7258 18.9204 15.6485 18.7993 15.525L16.2197 12.8929C16.0326 12.7013 15.9432 12.4837 15.9432 12.2323C15.9432 11.9836 16.0383 11.7566 16.245 11.5457C16.4328 11.3541 16.6447 11.264 16.8886 11.264C17.1308 11.264 17.352 11.3536 17.5577 11.547L18.5218 12.5689V11.961C18.5218 10.2963 17.9298 8.86206 16.7508 7.66767C15.5709 6.47228 14.1508 5.87132 12.4999 5.87132C12.168 5.87132 11.8364 5.90253 11.5051 5.96484C11.1769 6.02657 10.8495 6.1102 10.5228 6.21568L10.5203 6.21651C10.3504 6.27408 10.1623 6.28646 9.95225 6.24829C9.74886 6.21134 9.60311 6.12941 9.50008 6.01099L9.4926 6.00336C9.1704 5.67462 9.05985 5.33971 9.12356 4.98704C9.18766 4.63155 9.38983 4.39325 9.75495 4.25954L9.75664 4.25891C10.2002 4.09141 10.6563 3.96984 11.1252 3.89413C11.5971 3.81794 12.0553 3.78 12.4999 3.78C14.7274 3.78 16.6247 4.57356 18.2018 6.1656C19.7787 7.75759 20.5656 9.6773 20.5656 11.935V12.5566L21.5565 11.5458L21.5614 11.5404C21.7293 11.3546 21.9336 11.264 22.1866 11.264Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconReplace;

import React from 'react';

import flipImageYOperation from 'editor/src/store/design/operation/flipImageYOperation';
import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconFlipY from 'editor/src/component/Icon/IconFlipY';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection, isElementEmpty }) => {
  const { imageFlip } = state.hostSettings.coreFeatures;
  return !isElementEmpty && hasSelection && imageFlip;
};

type Props = Pick<MenuItemProps, 'context' | 'mode'>;

function ButtonFlipY({ context, mode }: Props) {
  const dispatch = useDispatch();
  const active = useSelector((state) => {
    const element = getSelectedMediaElementsObjects(state)[0];
    if (element && (element.type === 'image' || element.type === 'addon')) {
      return element.flipY;
    }
    return false;
  });
  const handleClick = () => {
    dispatch(saveUndoRedoStateOperation('Flip Y'));
    dispatch(flipImageYOperation(context.structureIndexes[0]));
  };

  return (
    <MenuButton
      Icon={IconFlipY}
      mode={mode}
      on={active}
      handleClick={handleClick}
      i18nKey="editor-flip-y-shortened"
      className="cy-button-flip-y"
    />
  );
}

export default React.memo(ButtonFlipY);

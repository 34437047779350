import { useCallback, useMemo } from 'react';

import closePopupOperation from 'editor/src/store/app/module/popup/operation/closePopupOperation';
import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import resetImageStateOperation from 'editor/src/store/design/operation/resetImageStateOperation';
import { MediaElement } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';
import requestOriginalImageOperation from 'editor/src/store/plugins/operation/imageManipulation/requestOriginalImageOperation';
import { Plugin } from 'editor/src/store/plugins/types';
import { getAppliedManipulationPluginNames } from 'editor/src/store/plugins/utils/imageManipulationUtils';

import { PopupName } from 'editor/src/component/Popup/popups';

function useResetEffectsModal(element: MediaElement | undefined, plugins: Plugin[]) {
  const dispatch = useDispatch();
  const appliedManipulationPluginNames = useMemo(
    () => (element?.type === 'image' ? getAppliedManipulationPluginNames(element, plugins) : undefined),
    [element, plugins],
  );

  const hasEffectsApplied = useMemo(
    () =>
      element?.type === 'image' &&
      (appliedManipulationPluginNames?.length || element.filters?.length || element.adjustments?.length),
    [element],
  );

  const openConfirmModal = useCallback(() => {
    dispatch(openPopupOperation(PopupName.RESET_EFFECTS_POPUP));
  }, []);

  const closeConfirmModal = useCallback(() => {
    dispatch(closePopupOperation());
  }, []);

  const resetEffects = (resetPosition?: true) =>
    batch(() => {
      closeConfirmModal();
      if (element?.type !== 'image') {
        return;
      }

      if (appliedManipulationPluginNames?.length) {
        dispatch(
          requestOriginalImageOperation({
            pluginName: appliedManipulationPluginNames[0],
            elementId: element.uuid,
            assetId: element.imageId,
          }),
        );
      }

      dispatch(
        resetImageStateOperation(element, {
          adjustments: true,
          position: resetPosition,
        }),
      );
    });

  return {
    openConfirmModal,
    closeConfirmModal,
    resetEffects,
    hasEffectsApplied,
  };
}

export default useResetEffectsModal;

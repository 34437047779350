import getSelectedElementUuids from 'editor/src/store/editor/selector/getSelectedElementUuids';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import loadFontOperation from 'editor/src/store/fonts/operation/loadFontOperation';
import isFontFileLoaded from 'editor/src/store/fonts/utils/isFontFileLoaded';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

// used if the user clicks on multiple fonts before they finish loaded. then we apply the last one
const fontFamilyMapToApply: { [uuid: number]: string } = {};

const downloadAndApplyFontFamilyOperation =
  (fontFile: string) => async (dispatch: ThunkDispatch, getState: () => RootState) => {
    const uuid = getSelectedElementUuids(getState())[0];
    if (isFontFileLoaded(fontFile)) {
      dispatch(applyFontStylesToSelectionOperation({ fontFamily: fontFile }));
      return;
    }

    fontFamilyMapToApply[uuid] = fontFile;
    await dispatch(loadFontOperation(fontFile));

    if (isFontFileLoaded(fontFile) && fontFamilyMapToApply[uuid] === fontFile) {
      delete fontFamilyMapToApply[uuid];
      dispatch(applyFontStylesToSelectionOperation({ fontFamily: fontFile }));
    }
  };

export default downloadAndApplyFontFamilyOperation;

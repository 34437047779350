import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'editor/src/store/hooks';
import { MockupPreviewStore } from 'editor/src/store/mockup/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import BackHeader from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/BackHeader';
import MockupList from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/MockupList';
import StoreProductEntryList from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/StoreProductList';

import MockupStoreContext from './MockupStoreContext';
import StoreEntry from './StoreEntry';

interface Props {
  back?: () => void;
  children?: React.ReactNode | undefined;
  mode: 'preview' | 'product';
  backClassName?: string;
  listClassName?: string;
}

function StoreList({ back, mode, children, listClassName, backClassName }: Props) {
  const { t } = useTranslation();
  const stores = useSelector((state) => state.mockup.preview.stores);
  const [selectedStore, setSelectedStore] = useState<MockupPreviewStore | undefined>();

  const loadStores = useCallback((offset: number) => {
    sendPostMessage('mockup.getStores', { limit: 15, offset });
  }, []);

  const unSelectStore = useCallback(() => setSelectedStore(undefined), []);

  return (
    <MockupStoreContext.Provider value={mode}>
      {selectedStore ? (
        <StoreProductEntryList
          store={selectedStore}
          back={unSelectStore}
          listClassName={listClassName}
          backClassName={backClassName}
        />
      ) : (
        <>
          {back && <BackHeader className={backClassName} title={t('Stores')} onClick={back} />}
          {children}
          <MockupList
            className={listClassName}
            entries={stores.entries}
            hasMore={stores.hasMore}
            EntryElt={StoreEntry}
            loadEntries={loadStores}
            onSelect={setSelectedStore}
          />
        </>
      )}
    </MockupStoreContext.Provider>
  );
}

export default React.memo(StoreList);

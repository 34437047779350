import cn from 'classnames';
import React from 'react';

import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  tooltip: string;
  children: React.ReactNode;
  className?: string;
  noBackground?: boolean;
}

function Badge({ children, tooltip, className, noBackground }: Props) {
  return (
    <WithTooltip
      overlay={tooltip}
      className={cn(styles.Badge, className, {
        [styles.noBackground]: noBackground,
      })}
    >
      {children}
    </WithTooltip>
  );
}

export default React.memo(Badge);

import { MediaImage } from 'editor/src/store/design/types';

import { getRectangleBoundingBox } from 'editor/src/util/reflectDesignData/utils';

const calculateCurrentCropZoomScale = (image: MediaImage) => {
  const bbox = getRectangleBoundingBox(0, 0, image.width, image.height, image.pr);
  return Math.min(image.pw / bbox.width, image.ph / bbox.height);
};

export default calculateCurrentCropZoomScale;

import isEqual from 'lodash/isEqual';

import { setInstalledPluginsAction } from 'editor/src/store/design/slice';
import { DesignData } from 'editor/src/store/design/types';
import { GalleryImage } from 'editor/src/store/gallery/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { Plugin, PluginName } from 'editor/src/store/plugins/types';
import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import removeNotRelevantGlobalPlugins from '../../variants/operation/removeUnrelevantGlobalPersonalizationPlugin';

import getAppliedPlugins from './getAppliedPlugins';

function checkPluginUse(
  designData: DesignData,
  images: GalleryImage[],
  dispatch: ThunkDispatch,
  plugins: Plugin[],
  globalPlugins: string[],
  defaultPersonalizationPlugin = false,
) {
  if (isEmbedded) {
    return; // skip modifying plugins in case of embedded mode
  }
  if (!defaultPersonalizationPlugin) {
    dispatch(removeNotRelevantGlobalPlugins(globalPlugins as PluginName[], plugins));
  }
  const appliedPlugins = [...getAppliedPlugins(designData, plugins, images, globalPlugins)];
  if (!isEqual(appliedPlugins, designData.plugins)) {
    dispatch(setInstalledPluginsAction(appliedPlugins));
  }
}

export default checkPluginUse;

import debounce from 'lodash/debounce';
import React, { useCallback, useRef } from 'react';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import { Coords, ElementAddress, MediaText } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import FabricPathText from 'editor/src/fabric/FabricPathText';
import mm2pt from 'editor/src/util/mm2pt';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import fabricPropsToTextElementData from './fabricPropsToTextElementData';

function useTextUpdates(
  pageCoords: Coords,
  elementData: MediaText,
  elementAddress: ElementAddress,
  fabricElementRef: React.RefObject<FabricPathText>,
  canvasRotation: CanvasRotation,
) {
  const dispatch = useDispatch();
  const { px2mm } = useFabricUtils();

  const elementDataRef = useRef(elementData);
  elementDataRef.current = elementData;

  const updateText = useCallback(
    debounce((textUpdates: Partial<MediaText>) => {
      dispatch(updateMediaElementOperation(elementAddress, textUpdates, MediaUpdateActionName.TEXT_UPDATED));
    }, 200),
    [px2mm, pageCoords, elementAddress, canvasRotation],
  );

  const onTextChanged = useCallback(() => {
    const elt = fabricElementRef.current;
    if (!elt) {
      return;
    }
    const textUpdate = fabricPropsToTextElementData(px2mm, pageCoords, elt, canvasRotation);
    textUpdate.extra = {
      ...elementDataRef.current.extra,
      text: elt.text,
      fontSize: mm2pt(px2mm(elt.fontSize ?? 1)),
    };
    updateText(textUpdate);
  }, [updateText]);

  const onObjectModified = useCallback(() => {
    if (fabricElementRef.current) {
      const textUpdate = fabricPropsToTextElementData(px2mm, pageCoords, fabricElementRef.current, canvasRotation);
      if (fabricElementRef.current.fontSize) {
        textUpdate.extra = {
          ...elementDataRef.current.extra,
          fontSize: mm2pt(px2mm(fabricElementRef.current.fontSize)),
        };
      }
      dispatch(updateMediaElementOperation(elementAddress, textUpdate, MediaUpdateActionName.MOVED));
    }
  }, [px2mm, pageCoords, elementAddress, canvasRotation]);

  return { onObjectModified, onTextChanged };
}

export default useTextUpdates;

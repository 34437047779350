import React, { useEffect, useCallback, useMemo } from 'react';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { MediaElement } from 'editor/src/store/design/types';
import getPreviewModePluginName from 'editor/src/store/editor/selector/getPreviewModePluginName';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import resetModifiedImageOperation from 'editor/src/store/plugins/operation/imageManipulation/resetModifiedImageOperation';
import getPlugins from 'editor/src/store/plugins/selector/getPlugins';
import { getImageManipulationPluginData } from 'editor/src/store/plugins/utils/imageManipulationUtils';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useIsMounted from 'editor/src/util/useIsMounted';

function useImageModificationMode(
  mediaElement: MediaElement,
  imageIsEmpty: boolean,
  selected: boolean,
  maskRef: React.RefObject<fabric.Object>,
  ghostRef: React.RefObject<fabric.Object>,
) {
  const fabricCanvas = useFabricCanvas();
  const isMounted = useIsMounted();

  const dispatch = useDispatch();
  const plugins = useSelector(getPlugins);
  const previewModePluginName = useSelector((state) => getPreviewModePluginName(state, mediaElement.uuid));
  const { uuid, imageId, activeManipulationPluginData } = useMemo(() => {
    if (mediaElement.type !== 'image') {
      return {};
    }

    return {
      uuid: mediaElement.uuid,
      imageId: mediaElement.imageId,
      activeManipulationPluginData: previewModePluginName
        ? getImageManipulationPluginData(previewModePluginName, mediaElement)
        : undefined,
    };
  }, [mediaElement, plugins]);
  const originalUrl = useSelector((state) => (imageId ? getImageById(state, imageId)?.url : undefined));

  const turnImageModificationModeOff = useCallback(() => {
    if (imageId && originalUrl && previewModePluginName) {
      batch(() => {
        dispatch(setSidebarActiveTabOperation(-1));
        dispatch(
          resetModifiedImageOperation({
            assetId: imageId,
            elementId: uuid,
          }),
        );
      });
    }
  }, [activeManipulationPluginData, imageId, uuid]);

  const onClickOut = useCallback(
    (e: fabric.IEvent) => {
      if (e.target !== ghostRef.current) {
        turnImageModificationModeOff();
      }
    },
    [turnImageModificationModeOff],
  );

  useEffect(() => {
    if (!isMounted()) {
      return undefined;
    }

    if (previewModePluginName && !selected) {
      turnImageModificationModeOff();
    }

    if (previewModePluginName && selected) {
      if (ghostRef.current) {
        fabricCanvas.setActiveObject(ghostRef.current);
      }
    } else if (maskRef.current && selected) {
      fabricCanvas.setActiveObject(maskRef.current);
    }

    if (previewModePluginName) {
      fabricCanvas.on('mouse:down:after', onClickOut);
      return () => {
        fabricCanvas.off('mouse:down:after', onClickOut);
      };
    }

    return undefined;
  }, [previewModePluginName, selected]);

  // when deleting image while in bg-remove mode
  useEffect(() => {
    imageIsEmpty && previewModePluginName && turnImageModificationModeOff();
  }, [imageIsEmpty, previewModePluginName, turnImageModificationModeOff]);
}

export default useImageModificationMode;

import { RootState } from 'editor/src/store/index';

import getCurrentSpreadIndex from '../../editor/selector/getCurrentSpreadIndex';

const hasOneReplacableImage = (state: RootState) => {
  const currentSpreadIndex = getCurrentSpreadIndex(state);
  const { ignorePersonalizationLock } = state.hostSettings;
  const spread = state.design.designData?.spreads[currentSpreadIndex];
  return !!spread?.pages[0]?.groups.media?.some(
    (element) => element.type === 'image' && (!element.personalizationLocked || ignorePersonalizationLock),
  );
};

export default hasOneReplacableImage;

import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DesignDimensions, Dimensions } from 'editor/src/store/design/types';
import { getMaxHeight, getMaxWidth } from 'editor/src/store/design/util/getDimensionRestrictions';
import { Unit } from 'editor/src/store/editor/types';

import limitPrecision from 'editor/src/util/limitPrecision';
import { convertMmToUnit } from 'editor/src/util/math';
import useIsMounted from 'editor/src/util/useIsMounted';
import useStateWithAutoReset from 'editor/src/util/useStateWithAutoReset';

import Button from 'editor/src/component/Button';
import DropDown from 'editor/src/component/DropDown';
import { DropdownPosition } from 'editor/src/component/DropDown/getPosition';
// import SelectButtonGroup from 'editor/src/component/SelectButtonGroup';

import Dimension, { Ref } from './Dimension';

import styles from './index.module.scss';

export type FieldType = 'width' | 'height';

export type ProductSizeDropdownMode = 'add_size' | 'edit_size';

export interface Size {
  width: number;
  height: number;
}

interface Props {
  isVisible: boolean;
  mode: ProductSizeDropdownMode;
  dropdownPosition: DropdownPosition;
  sizeControlOptions: Size[];
  size?: Size;
  dimensions: DesignDimensions;
  maxImageDimensions: Dimensions | undefined;
  unit: Unit;
  index?: number;
  // onUnitChange: (unit: Unit) => void;
  onEditEnd: (index: number, size: Size) => boolean;
  onAddSize?: (size: Size) => void;
  onClickOutside: () => void;
}

function ProductSizeDropdown({
  isVisible,
  mode,
  dropdownPosition,
  sizeControlOptions,
  size,
  dimensions,
  unit,
  index,
  maxImageDimensions,
  // onUnitChange,
  onAddSize,
  onEditEnd,
  onClickOutside,
}: Props) {
  const widthInputRef = useRef<Ref>(null);
  const heightInputRef = useRef<Ref>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [dimensionsHaveValue, setDimensionsHaveValue] = useState<{ width: boolean; height: boolean }>({
    width: false,
    height: false,
  });
  const [error, setError] = useStateWithAutoReset<string>(4000);
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const isValid = useRef(true);

  // const unitOptions: Array<{ label: string; value: Unit }> = useMemo(
  //   () => [
  //     { label: t('editor-cm'), value: 'cm' },
  //     { label: t('editor-inch'), value: 'inch' },
  //   ],
  //   [],
  // );

  useEffect(() => {
    if (isVisible) {
      widthInputRef?.current?.focus();
    }
  }, [isVisible]);

  useEffect(() => {
    setDimensionsHaveValue({ width: size?.width !== undefined, height: size?.height !== undefined });
  }, [size]);

  const validateEntry = useCallback(
    (ref: Ref, dim: 'height' | 'width') => {
      const numberValue = ref.getValue();

      let minValue: number;
      let maxValue: number;
      if (dim === 'width') {
        minValue = dimensions.min_width;
        maxValue = getMaxWidth(
          dimensions.max_surface,
          heightInputRef.current?.getValue() || dimensions.min_height,
          dimensions.max_width,
        );
      } else {
        minValue = dimensions.min_height;
        maxValue = getMaxHeight(
          dimensions.max_surface,
          widthInputRef.current?.getValue() || dimensions.min_width,
          dimensions.max_height,
        );
      }

      if (numberValue < minValue) {
        const key = dim === 'width' ? 'width-too-small' : 'height-too-small';
        setError(
          t(key, {
            value: `${limitPrecision(convertMmToUnit(minValue, unit), 1)} ${unit}`,
          }),
        );
        isValid.current = false;
      } else if (numberValue > maxValue) {
        let key: string;
        if (dim === 'width') {
          key = dimensions.max_surface !== undefined ? 'width-too-big-with-surface' : 'width-too-big-no-surface';
        } else {
          key = dimensions.max_surface !== undefined ? 'height-too-big-with-surface' : 'height-too-big-no-surface';
        }
        setError(
          t(key, {
            value: `${limitPrecision(convertMmToUnit(maxValue, unit), 1)} ${unit}`,
            surface: ((dimensions.max_surface || 0) / 1000000).toString(),
          }),
        );
        isValid.current = false;
      }

      const value = Math.min(Math.max(numberValue, minValue), maxValue, maxValue);
      ref.setValue(value);

      if (!isValid.current) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          isValid.current = true;
        }, 200);
      }
    },
    [unit, dimensions, maxImageDimensions],
  );

  const finishEditing = useCallback(() => {
    if (isMounted() && index !== undefined && widthInputRef.current && heightInputRef.current) {
      const width = widthInputRef.current.getValue();
      const height = heightInputRef.current.getValue();
      onEditEnd(index, { width, height });
    }
  }, [index, onEditEnd]);

  const onKeyUp = (input: Ref, dim: 'width' | 'height') => {
    setDimensionsHaveValue((prev) => ({ ...prev, [dim]: !!input.getValue() }));
  };

  const onBlur = useCallback(
    (input: Ref, dim: 'width' | 'height') => {
      if (!widthInputRef.current?.getValue() && !heightInputRef.current?.getValue()) {
        return;
      }

      validateEntry(input, dim);

      const newSize = {
        width: widthInputRef.current?.getValue(),
        height: heightInputRef.current?.getValue(),
      };

      if (newSize.height === size?.height && newSize.width === size?.width) {
        return;
      }

      const sizeExist = sizeControlOptions.some(
        (option) => option.width === newSize.width && option.height === newSize.height,
      );

      if (sizeExist) {
        setError('Size already exists');
        isValid.current = false;
      }
    },
    [validateEntry, finishEditing, size],
  );

  const onCtaButtonClick = useCallback(() => {
    if (!isValid.current) {
      return;
    }

    if (mode === 'add_size') {
      const newSize = {
        width: widthInputRef.current?.getValue() || dimensions.min_width,
        height: heightInputRef.current?.getValue() || dimensions.min_height,
      };
      const sizeExist = sizeControlOptions.some(
        (option) => option.width === newSize.width && option.height === newSize.height,
      );
      if (sizeExist) {
        setError('Size already exists');
      } else {
        onAddSize?.(newSize);
        setDimensionsHaveValue({ width: size?.width !== undefined, height: size?.height !== undefined });
      }
    }
    if (mode === 'edit_size') {
      finishEditing();
      setDimensionsHaveValue({ width: size?.width !== undefined, height: size?.height !== undefined });
    }
  }, [mode, widthInputRef, finishEditing]);

  const onClickOutsideClick = useCallback(() => {
    setDimensionsHaveValue({ width: size?.width !== undefined, height: size?.height !== undefined });
    setError('');
    onClickOutside();
  }, []);

  useEffect(() => {
    let timeoutId: number;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (!dimensionsHaveValue.width || !dimensionsHaveValue.height) {
        return;
      }

      if (event.key === 'Enter') {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }

        timeoutId = window.setTimeout(() => {
          onCtaButtonClick();
        }, 0);
      }
    };

    if (isVisible) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isVisible, dimensionsHaveValue, onCtaButtonClick]);

  return (
    <DropDown isVisible={isVisible} onClickOutside={onClickOutsideClick} openPosition={dropdownPosition}>
      <div className={cn(styles.ProductSize, 'cy-product-size')} data-ignore-outside-click>
        {/* <SelectButtonGroup
          items={unitOptions}
          onChange={onUnitChange}
          value={unit}
          className={cn(styles.units, 'cy-dimension-units')}
        /> */}
        <div className={styles.content}>
          <Dimension
            ref={widthInputRef}
            baseValue={size?.width}
            unit={unit}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            fieldType="width"
          />
          <Dimension
            ref={heightInputRef}
            baseValue={size?.height}
            unit={unit}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            fieldType="height"
          />
        </div>
        <div className={cn(styles.error, 'cy-error')}>{error}</div>

        <Button
          disabled={!dimensionsHaveValue.width || !dimensionsHaveValue.height}
          primary
          stopPropagation
          className={cn(styles.ctaButton, 'cy-size-cta-button')}
          onClick={onCtaButtonClick}
        >
          <span>{mode === 'add_size' ? t('Add size') : t('Save')}</span>
        </Button>
      </div>
    </DropDown>
  );
}

export default React.memo(ProductSizeDropdown);

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import noVariantSelected from 'editor/src/static/no-variant-selected.svg';

import H2 from 'editor/src/component/Header/H2';

import styles from './index.module.scss';

function EmptyPlaceholder() {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const handle = window.setTimeout(() => setVisible(true), 100);
    return () => window.clearTimeout(handle);
  }, []);

  const title = t('editor-no-variant-title');

  return (
    <div className={styles.EmptyPlaceholder} style={{ opacity: visible ? 1 : 0 }}>
      <div className={styles.container}>
        <img src={noVariantSelected} alt={title} draggable={false} />
        <div className={styles.textBox}>
          <H2>{title}</H2>
          <div className={styles.description}>{t('editor-no-variant-description')}</div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(EmptyPlaceholder);

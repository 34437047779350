import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import controlDesignDataAndGetColorOverrides from 'editor/src/store/design/operation/controlDesignDataAndGetColorOverrides';
import { DesignData } from 'editor/src/store/design/types';
import requestVariantsColorOverrides from 'editor/src/store/design/util/requestVariantsColorOverrides';
import type { Thunk } from 'editor/src/store/hooks';
import { saveSceneStructureAction } from 'editor/src/store/mockup/slice';

import { sceneFetching } from './applyOrFetchSceneStructureOperation';
import applySceneStructureOperation from './applySceneStructureOperation';

const loadSceneOperation =
  ({ sceneId, structure }: { sceneId: string; structure: DesignData }): Thunk =>
  (dispatch, getState, { i18n }) => {
    batch(() => {
      const state = getState();
      sceneFetching.delete(sceneId);
      const spreadGroups = state.variants.groupedSpreadsPerProductUids[structure.product_uid];
      const variantKey = state.variants.selectedGroupKey;
      const designOptionKey = state.variants.selectedDesignOptionValue;

      const colorOverrides = controlDesignDataAndGetColorOverrides({
        designData: structure,
        i18n,
        spreadGroups,
      });

      requestVariantsColorOverrides(colorOverrides, variantKey, designOptionKey);
      dispatch(saveSceneStructureAction({ sceneId, structure }));
      if (state.mockup.scenes.selectedSceneId === sceneId) {
        dispatch(applySceneStructureOperation(structure));
      }
    });
  };

export default loadSceneOperation;

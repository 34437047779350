import cn from 'classnames';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import { Unit } from 'editor/src/store/editor/types';
import { useDispatch } from 'editor/src/store/hooks';
import getProductVariationSpecificTitle from 'editor/src/store/variants/helpers/getProductVariationSpecificTitle';
import { DesignOption, MultiOptions, Product, VariationGroup } from 'editor/src/store/variants/types';

import useSpreadPreview from 'editor/src/util/design/useSpreadPreview';

import IconBin from 'editor/src/component/Icon/IconBin';
import { PopupName } from 'editor/src/component/Popup/popups';
import getNotGroupedValues from 'editor/src/component/ProductVariantList/getNotGroupedValues';
import getUnavailableValues from 'editor/src/component/ProductVariantList/getUnavailableValues';
import VariantLock from 'editor/src/component/ProductVariantList/VariantLock';
import { RequestRenderFn } from 'editor/src/component/SpreadPreview';

import styles from './index.module.scss';

export interface Props {
  group: VariationGroup;
  options: MultiOptions;
  spreadIndex: number;
  product: Product;
  unit: Unit;
  isSelected: boolean;
  linkEnabled: boolean;
  unavailableProducts: Product['unavailableProducts'];
  onSelect: (groupKey: string, designOption?: DesignOption) => void;
  onLinkToggle: (group: VariationGroup) => void;
  onDelete?: (key: string) => void;
  requestRender: RequestRenderFn;
  className?: string;
  overrideStyles?: boolean;
}

export interface Ref {
  getHeight(): number;
  setY(y: number): void;
}

function ProductVariantEntry(
  {
    group,
    isSelected,
    linkEnabled,
    onSelect,
    onLinkToggle,
    onDelete,
    requestRender,
    product,
    unit,
    spreadIndex,
    options,
    className,
    overrideStyles = true,
    unavailableProducts,
  }: Props,
  ref: React.Ref<Ref>,
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const divRef = useRef<HTMLDivElement>(null);
  const [y, setY] = useState(-1);
  const [positionned, setPositionned] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      getHeight: () => divRef.current?.clientHeight || 0,
      setY,
    }),
    [],
  );

  const notGroupedValues = useMemo(
    () => getNotGroupedValues(options, product.groupBy, product.productControls, group.variationsInfo),
    [options, product.groupBy, product.productControls, group.variationsInfo],
  );

  const unAvailableValues = useMemo(
    () => getUnavailableValues(options, product.groupBy, product.productControls, group.variationsInfo),
    [options, product.groupBy, product.productControls, group.variationsInfo],
  );

  useEffect(() => {
    if (y === -1) {
      return undefined;
    }

    const handle = setTimeout(() => setPositionned(true), 0);
    return () => window.clearTimeout(handle);
  }, [y === -1]);

  const onLinkClick = useCallback(() => {
    onLinkToggle(group);
  }, [group, onLinkToggle]);

  const onEntryClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSelect(group.key);
  };

  const onEntryDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
        popupTitle: t('Delete variant'),
        textLines: [t('Are you sure you want to delete "{{variant}}"?', { variant: variantTitle })],
        options: [
          {
            title: t('editor-cancel'),
          },
          {
            title: t('Delete'),
            onConfirm: () => onDelete?.(group.key),
          },
        ],
        hideTitleIcon: true,
      }),
    );
  };

  const { designData } = group.variationsInfo[0];
  const spread = designData?.spreads[spreadIndex];
  const spreadGroup = designData?.spread_groups?.find((group) => group.spreadIndexes.includes(spreadIndex));
  const variantTitle = getProductVariationSpecificTitle(product, group.variationsInfo[0], unit);

  const { preview } = useSpreadPreview(
    requestRender,
    spread,
    group.key,
    spreadIndex,
    { dimension: 'height', value: 40 },
    { showBlanks: true, showEmptyImages: true },
    undefined,
    spreadGroup,
  );

  const style = y === -1 ? { opacity: 0 } : { transform: `translate3d(0,${y}px,0)` };
  const hasUnavailableProducts = group.variationsInfo.some(
    (variation) => !!unavailableProducts[variation.variation.productUid],
  );

  return (
    <div
      className={cn(className, styles.ProductVariantEntry, 'cy-variant', {
        [styles.selected]: isSelected,
        [styles.positionned]: positionned,
        'cy-selected': isSelected,
      })}
      onClick={onEntryClick}
      ref={divRef}
      style={overrideStyles ? style : undefined}
    >
      <div className={styles.content}>
        <div
          className={cn(styles.entryContent, {
            [styles.outOfStock]: group.outOfStock,
          })}
        >
          <div className={styles.thumbnail}>{preview && <img src={preview.dataURL} alt={group.title} />}</div>
          <div className={styles.entryText}>
            {variantTitle}
            {notGroupedValues && (
              <div>
                {notGroupedValues}
                {(unAvailableValues?.length ?? 0) > 0 && (
                  <span className={styles.unavailable}>,{unAvailableValues}</span>
                )}
              </div>
            )}
          </div>
          {onDelete && (
            <IconBin className={cn(styles.deleteIcon, 'cy-variant-delete-button')} onClick={onEntryDeleteClick} />
          )}
          {linkEnabled && !group.linkingDisabled && <VariantLock isLinked={group.linked} onToggle={onLinkClick} />}
        </div>
        {hasUnavailableProducts && (
          <div className={styles.entryUnavailable}>{t('Some or all products are unavailable')}</div>
        )}
        {!hasUnavailableProducts && group.outOfStock && (
          <div className={styles.entryOutOfStock}>{t('editor-product-out-of-stock-warning')}</div>
        )}
      </div>
    </div>
  );
}

export default React.memo(React.forwardRef(ProductVariantEntry));

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconProperties({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="15"
          y="1"
          width="4"
          height="4"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M15 17H5" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 15L3 5" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 3H5" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17 15L17 5" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M19 15V19H15L15 15L19 15Z"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="1"
          y="15"
          width="4"
          height="4"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M5 1V5H1L1 1L5 1Z" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export default React.memo(IconProperties);

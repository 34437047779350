import getCurrentSpreadGroup from 'editor/src/store/design/selector/getCurrentSpreadGroup';
import getSpread from 'editor/src/store/design/selector/getSpread';
import applyLayoutSchemaToCurrentSpreadOperation from 'editor/src/store/editor/operation/applyLayoutSchemaToCurrentSpreadOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import groupLayouts from 'editor/src/util/layouts/groupLayouts';
import layoutSchemas from 'editor/src/util/layouts/layoutSchemas/defaultSchemas';

const applyLayoutOperation = (layoutName: string) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const spreadGroup = getCurrentSpreadGroup(state);
  const currentSpread = getSpread(state, getCurrentSpreadIndex(state));
  const layoutGroup = groupLayouts.find((groupLayout) => groupLayout.name === layoutName);
  const layoutGroupInfo =
    spreadGroup && layoutGroup
      ? {
          spreadGroup,
          layoutGroup,
        }
      : undefined;
  let layoutSchema = layoutSchemas.find((schema) => schema.name === layoutName);

  const isLayoutTagDiffFromSpreadTag =
    currentSpread?.tags && !currentSpread.tags.some((tag) => !layoutSchema?.tags || layoutSchema.tags.includes(tag));

  if (isLayoutTagDiffFromSpreadTag) {
    layoutSchema = undefined;
  }
  if (layoutSchema) {
    dispatch(applyLayoutSchemaToCurrentSpreadOperation(false, false, layoutGroupInfo, layoutSchema));
  }
};

export default applyLayoutOperation;

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import applyDashStylesToSelectionOperation from 'editor/src/store/design/operation/shapes/applyDashStylesToSelectionOperation';
import { MediaShape } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import SliderWithInput from 'editor/src/component/SliderWithInput';

export interface Props {
  elementType: MediaShape['type'];
  selectedElement: MediaShape;
}

const MIN_GAP = 1;
const MIN_DASH = 1;

function StrokeDecorationControls({ elementType, selectedElement }: Props) {
  const { liveUpdate } = useMediaElementLiveUpdates(selectedElement);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { dashPattern } = selectedElement;

  const onDashChange = useCallback(
    (dash: number) => {
      liveUpdate({
        ...selectedElement,
        dashPattern: { dash, gap: selectedElement.dashPattern?.gap || MIN_GAP },
      });
    },
    [selectedElement],
  );

  const onAfterDashChange = useCallback(
    (dash: number) => {
      dispatch(
        applyDashStylesToSelectionOperation(elementType, {
          dash,
          gap: dashPattern?.gap || MIN_GAP,
        }),
      );
    },
    [dashPattern?.gap, elementType],
  );

  const onGapChange = useCallback(
    (gap: number) => {
      liveUpdate({
        ...selectedElement,
        dashPattern: {
          gap,
          dash: selectedElement.dashPattern?.dash || MIN_DASH,
        },
      });
    },
    [selectedElement],
  );

  const onAfterGapChange = useCallback(
    (gap: number) => {
      dispatch(
        applyDashStylesToSelectionOperation(elementType, {
          gap,
          dash: dashPattern?.dash || MIN_DASH,
        }),
      );
    },
    [dashPattern?.dash, elementType],
  );

  return (
    <>
      <div className="mt-2">
        <SliderWithInput
          inputClassName="cy-line-dash-input"
          position="twoRows"
          Label={t('Dash')}
          onChange={onDashChange}
          onAfterChange={onAfterDashChange}
          min={1}
          max={10}
          step={1}
          startPoint={1}
          value={dashPattern?.dash || MIN_DASH}
        />
      </div>
      <div className="mt-2">
        <SliderWithInput
          inputClassName="cy-line-gap-input"
          position="twoRows"
          Label={t('Gap')}
          onChange={onGapChange}
          onAfterChange={onAfterGapChange}
          min={1}
          max={10}
          step={1}
          startPoint={1}
          value={dashPattern?.gap || MIN_GAP}
        />
      </div>
    </>
  );
}

export default React.memo(StrokeDecorationControls);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { Dimensions, MediaImage } from 'editor/src/store/design/types';
import getAddonById from 'editor/src/store/editorModules/addons/selector/getAddonById';
import getAddonByIdFromCurrent from 'editor/src/store/editorModules/addons/selector/getAddonByIdFromCurrent';
import { addAddonInUseAction } from 'editor/src/store/editorModules/addons/slice';
import { Addon } from 'editor/src/store/editorModules/addons/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { AsyncThunk } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import loadImageDimensions from 'editor/src/util/loadImageDimensions';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import toastController from 'editor/src/util/toastController';

import applyImageToElementOperation from './applyImageToElementOperation';
import updateImageIdByUuidOperation from './updateImageIdByUuidOperation';
import uploadAddonOperation from './uploadAddonOperation';

const updateImageWithGraphicOperation =
  (uuid: number, graphicId: string, saveUndoRedoStep = false): AsyncThunk =>
  async (dispatch, getStore, { i18n }) => {
    const state = getStore();
    let addon = getAddonByIdFromCurrent(state, graphicId);
    const addToInUse = !!addon;
    if (!addon) {
      addon = getAddonById(state, graphicId);
    }

    if (!addon || !addon.preview) {
      return;
    }

    const galleryImage = state.gallery.images.find(
      (image) => image.from?.type === PluginName.Graphics && image.from.addonId === addon?.id,
    );

    if (galleryImage) {
      dispatch(updateImageIdByUuidOperation(uuid, graphicId, saveUndoRedoStep));
      return;
    }

    const address = getStructureIndexByElementUuid(state.design.designData, uuid);
    if (!address) {
      return;
    }

    if (getPlugin(state, PluginName.Graphics)?.state !== PluginState.ENABLED) {
      return;
    }

    let imageDimensions: Dimensions;
    try {
      imageDimensions = await loadImageDimensions(addon.preview, 'anonymous', {
        executor: 'updateImageWithGraphicOperation',
        addon,
        graphicId,
      });
    } catch {
      toastController.warningPersist(
        i18n.t('editor-image-loading-failed'),
        i18n.t('editor-image-loading-failed-message'),
      );
      return;
    }

    const element = getMediaElement<MediaImage>(state, address);
    if (!element) {
      return;
    }

    batch(() => {
      if (saveUndoRedoStep) {
        dispatch(saveUndoRedoStateOperation('Replace image in frame'));
      }

      if (addToInUse && addon) {
        dispatch(addAddonInUseAction(addon));
      }

      dispatch(uploadAddonOperation(element, imageDimensions, addon as Addon, false));
      dispatch(applyImageToElementOperation(element, graphicId, imageDimensions, false, true));
      sendPostMessage('log.imageAdded', element);
    });
  };

export default updateImageWithGraphicOperation;

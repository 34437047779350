import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import applyDashStylesToSelectionOperation from 'editor/src/store/design/operation/shapes/applyDashStylesToSelectionOperation';
import applyRectangleStylesToSelectionOperation from 'editor/src/store/design/operation/shapes/applyRectangleStylesToSelectionOperation';
import { MediaRectangle } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import SliderWithInput from 'editor/src/component/SliderWithInput';
import StrokeDecorationControls from 'editor/src/component/StrokeDecorationControls';
import StrokeStylesTab, { STROKE_STYLE } from 'editor/src/component/StrokeDecorationControls/StrokeStylesTab';

export interface Props {
  selectedElement: MediaRectangle;
}

const MIN_STROKE_WIDTH = 0;
const MAX_STROKE_WIDTH = 100;

function RectangleStylesControl({ selectedElement }: Props) {
  const { liveUpdate } = useMediaElementLiveUpdates(selectedElement);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const defaultDashedPattern = useSelector((state) => state.editor.currentStyles.rectangle.dashPattern);
  const { strokeWidth: currentStrokeWidth, dashPattern } = selectedElement;

  const onStrokeWidthChange = useCallback(
    (strokeWidth: number) => {
      liveUpdate({
        ...selectedElement,
        strokeWidth,
      });
    },
    [selectedElement],
  );

  const onAfterStrokeWidthChange = useCallback((strokeWidth: number) => {
    dispatch(applyRectangleStylesToSelectionOperation({ strokeWidth }));
  }, []);

  const onCornerRoundingChange = useCallback(
    (cornerRounding: number) => {
      liveUpdate({ ...selectedElement, cornerRounding });
    },
    [selectedElement],
  );

  const onAfterCornerRoundingChange = useCallback((cornerRounding: number) => {
    dispatch(applyRectangleStylesToSelectionOperation({ cornerRounding }));
  }, []);

  const onLineStyleTabChange = useCallback(
    (tab: STROKE_STYLE) => {
      const dashPattern = tab === STROKE_STYLE.DASHED ? defaultDashedPattern : undefined;
      let strokeWidth;
      if (tab === STROKE_STYLE.NO_OUTLINE) {
        strokeWidth = 0;
      } else if (selectedElement.strokeWidth === 0) {
        strokeWidth = 1;
      }

      dispatch(applyDashStylesToSelectionOperation('rectangle', dashPattern, false, strokeWidth));
    },
    [defaultDashedPattern, selectedElement],
  );

  return (
    <div className="px-3">
      <div className="mb-1">
        <SliderWithInput
          inputClassName="cy-corner-rounding-input"
          Label={t('Corner rounding')}
          onChange={onCornerRoundingChange}
          onAfterChange={onAfterCornerRoundingChange}
          min={0}
          max={100}
          step={1}
          startPoint={0}
          value={selectedElement.cornerRounding || 0}
          position="twoRows"
        />
      </div>
      <div className="mb-1">
        <SliderWithInput
          inputClassName="cy-stroke-width-input"
          Label={t('Weight')}
          onChange={onStrokeWidthChange}
          onAfterChange={onAfterStrokeWidthChange}
          min={MIN_STROKE_WIDTH}
          max={MAX_STROKE_WIDTH}
          step={1}
          startPoint={1}
          value={currentStrokeWidth}
          position="twoRows"
        />
      </div>
      <StrokeStylesTab
        hasDashedPattern={!!dashPattern}
        onTabChange={onLineStyleTabChange}
        showNoOutline
        noOutlineSelected={selectedElement.strokeWidth === 0}
      />
      {dashPattern && <StrokeDecorationControls elementType="rectangle" selectedElement={selectedElement} />}
    </div>
  );
}

export default RectangleStylesControl;

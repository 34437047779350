import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { Thunk } from 'editor/src/store/hooks';
import getDesignOptionKey from 'editor/src/store/variants/helpers/getDesignOptionKey';
import {
  addDesignOptionAction,
  setSelectedGroupAction,
  updateVariationGroupsAction,
} from 'editor/src/store/variants/slice';

import getVariationGroups from 'editor/src/util/getVariationGroups';

const addDesignOptionOperation =
  (): Thunk =>
  (dispatch, getState, { i18n }) => {
    batch(() => {
      dispatch(saveUndoRedoStateOperation('design options'));
      dispatch(addDesignOptionAction({ controlKey: 'design-option' }));
      const state = getState();
      const variationGroups = getVariationGroups(state, i18n);
      dispatch(updateVariationGroupsAction(variationGroups));
      const groupToSelect = variationGroups.find(
        (group) => getDesignOptionKey(group) === state.variants.selectedDesignOptionValue,
      );
      groupToSelect && dispatch(setSelectedGroupAction(groupToSelect.key));
    });
  };

export default addDesignOptionOperation;

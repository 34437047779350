import { fabric } from 'fabric';
import { useEffect, useState } from 'react';

import { Adjustment } from 'editor/src/store/design/types';

import useFilters from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/getAdjustmentFilters';

let canvas: fabric.StaticCanvas;
function getCanvas() {
  if (!canvas) {
    const domCanvas = document.createElement('canvas');
    domCanvas.id = 'filter-preview';
    canvas = new fabric.StaticCanvas(domCanvas, {
      backgroundColor: '#EEEEEE',
      renderOnAddRemove: false,
    });
  }

  return canvas;
}

export function useFilterPreview(
  imageUrl: string | undefined,
  adjustments: Adjustment[] | undefined,
  outputWidth: number,
  outputHeight: number,
  mode: 'fit' | 'fill',
) {
  const [imageData, setImageData] = useState('');
  const filters = useFilters(adjustments);

  useEffect(() => {
    if (!imageUrl) {
      return undefined;
    }

    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      const fabricImage = new fabric.Image(image, {
        angle: 0,
        left: 0,
        top: 0,
        objectCaching: false,
        strokeWidth: 0, // avoid wrong size when calling getScaledXxxx()
      });

      const { width, height } = fabricImage.getOriginalSize();
      if (mode === 'fill') {
        if (width > height) {
          fabricImage.scaleToHeight(outputWidth);
        } else {
          fabricImage.scaleToWidth(outputHeight);
        }
      } else if (width > height) {
        fabricImage.scaleToWidth(outputWidth);
      } else {
        fabricImage.scaleToHeight(outputHeight);
      }

      fabricImage.set({
        left: -((fabricImage.getScaledWidth() - outputWidth) / 2),
        top: -((fabricImage.getScaledHeight() - outputHeight) / 2),
      });
      fabricImage.applyFilters(filters);
      const fabricCanvas = getCanvas();
      fabricCanvas.add(fabricImage);
      fabricCanvas.renderAll();

      const previewData = fabricCanvas.toDataURL({
        format: 'jpeg',
        quality: 0.6,
        enableRetinaScaling: true,
        left: 0,
        top: 0,
        width: outputWidth,
        height: outputHeight,
      });
      fabricImage.dispose();
      fabricCanvas.remove(fabricImage);
      setImageData(previewData);
    };
    image.onerror = () => setImageData(imageUrl);
    image.src = imageUrl;

    return () => {
      image.onload = null;
      image.onerror = null;
    };
  }, [imageUrl, filters]);

  return imageData;
}

export default useFilterPreview;

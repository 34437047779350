import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dimensions } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import applyOrFetchMockupStructureOperation from 'editor/src/store/mockup/operation/applyOrFetchMockupStructureOperation';
import getMockupSizeFromDesign from 'editor/src/store/mockup/utils/getMockupSizeFromDesign';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconMockup from 'editor/src/component/Icon/IconMockup';
import SelectButtonGroup from 'editor/src/component/SelectButtonGroup';

import MockupCustomSizeController from './MockupCustomSizeController';
import MockupList from './MockupList';

import styles from './index.module.scss';

type DimenionType = 'standard' | 'custom';
const options: Array<{ key: string; value: DimenionType }> = [
  { key: 'Standard', value: 'standard' },
  { key: 'Custom', value: 'custom' },
];

function MockupTabContent() {
  const { t } = useTranslation();

  const dimensionTypeOptions = useMemo(() => options.map(({ key, value }) => ({ label: t(key), value })), []);
  const [dimensionType, setDimensionType] = useState<DimenionType>('standard');
  const activeSize = useSelector((state) =>
    state.design.designData ? getMockupSizeFromDesign(state.design.designData) : undefined,
  );

  const dispatch = useDispatch();
  const onSelectSize = useCallback((size: Dimensions) => {
    dispatch(applyOrFetchMockupStructureOperation(size));
  }, []);

  return (
    <>
      <TabContentHeader title={t('Mockup size')} icon={<IconMockup />} />
      <ContentBlock scroll>
        <div className={styles.title}>{t('Dimensions')}</div>
        <SelectButtonGroup
          items={dimensionTypeOptions}
          onChange={setDimensionType}
          value={dimensionType}
          className={styles.dimensionTypes}
        />
        <div className={styles.title}>{t('Size')}</div>
        {activeSize &&
          (dimensionType === 'standard' ? (
            <MockupList activeSize={activeSize} onSelect={onSelectSize} />
          ) : (
            <MockupCustomSizeController activeSize={activeSize} onChange={onSelectSize} />
          ))}
      </ContentBlock>
    </>
  );
}

export default React.memo(MockupTabContent);

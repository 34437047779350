import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getVisibleWarningsCount from 'editor/src/store/editorModules/warnings/selector/getVisibleWarningsCount';
import { useSelector } from 'editor/src/store/hooks';

import Badge from 'editor/src/component/DesktopSidebar/Tabs/Tab/Badge';
import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconWarning from 'editor/src/component/Icon/IconWarning';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
}

function WarningsTab({ pluginName, customTabInfo }: Props) {
  const { t } = useTranslation();
  const warningsCount = useSelector(getVisibleWarningsCount);
  const isMobile = useIsMobile();

  if (!warningsCount || isMobile) {
    return null;
  }
  return (
    <Tab
      key={TAB_NAMES.WARNINGS}
      name={TAB_NAMES.WARNINGS}
      title={t('editor-warnings')}
      badge={<Badge>{warningsCount}</Badge>}
      icon={<IconWarning />}
      pluginName={pluginName}
      customTabInfo={customTabInfo}
    />
  );
}

export default React.memo(WarningsTab);

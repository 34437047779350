import React from 'react';

function GridIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="8"
        y="8"
        width="16"
        height="16"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13 8V24" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 19L24 19" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 8V24" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 8V24" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 13L24 13" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default React.memo(GridIcon);

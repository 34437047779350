import React, { useMemo } from 'react';

import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { useSelector } from 'editor/src/store/hooks';

import getActiveImageManipulationPlugins from 'editor/src/util/plugins/getActiveImageManipulationPlugins';

import { MenuContext } from 'editor/src/component/Menu/type';

import ImageManipulationButton from './ImageManipulationButton';

interface Props {
  menuContext: MenuContext;
}

function ImageManipulationContainer({ menuContext }: Props) {
  const element = useSelector((state) => {
    const elementId = getSelectedImageUuid(state);
    return elementId !== undefined ? getMediaElementByUuid(state, elementId) : undefined;
  });
  const selectedImage = useSelector((state) =>
    element?.type === 'image' && element.imageId ? getImageById(state, element.imageId) : undefined,
  );
  const activePlugins = useMemo(() => getActiveImageManipulationPlugins(menuContext), [menuContext]);

  if (!selectedImage?.id || element?.type !== 'image' || !activePlugins.length) {
    return null;
  }

  return (
    <div className="mt-2">
      {activePlugins.map((plugin) => (
        <ImageManipulationButton className="mb-2" key={plugin.name} plugin={plugin} />
      ))}
    </div>
  );
}

export default ImageManipulationContainer;

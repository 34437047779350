type Listener<T extends any[]> = (...args: T) => void;

class EventEmitter<EventsData extends { [event: string]: any[] }> {
  private listenersMap: {
    [Ev in keyof EventsData]?: Listener<EventsData[Ev]>[];
  } = {};

  on<Event extends keyof EventsData>(event: Event, listener: Listener<EventsData[Event]>) {
    if (!this.listenersMap[event]) {
      this.listenersMap[event] = [listener];
    } else {
      this.listenersMap[event]?.push(listener);
    }
  }

  off<Event extends keyof EventsData>(event: Event, listener: Listener<EventsData[Event]>) {
    const listeners = this.listenersMap[event];
    const listenerIndex = listeners?.indexOf(listener);
    if (listeners && listenerIndex !== undefined && listenerIndex !== -1) {
      listeners.splice(listenerIndex, 1);
    }
  }

  emit<Event extends keyof EventsData>(event: Event, ...data: EventsData[Event]) {
    const listeners = this.listenersMap[event];
    listeners?.forEach((listener) => listener(...data));
  }
}

export default EventEmitter;

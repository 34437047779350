import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import Button from 'editor/src/component/Button';
import { CanShow } from 'editor/src/component/Menu/type';

import styles from './index.module.scss';

export const canShow: CanShow = (state, { hostSettings }) =>
  !!hostSettings.editorMode &&
  ['add-with-draft', 'draft'].includes(hostSettings.editorMode) &&
  state.hostSettings.enableManualSaveDraft;

function ButtonSaveDraft() {
  const { t } = useTranslation();

  const onSaveDraftClick = () => {
    sendPostMessage('shopify.saveAsDraft', { triggerSideEffect: true });
  };

  return (
    <div className={styles.saveDraftButtonWrapper}>
      <Button
        secondary
        stopPropagation
        onClick={onSaveDraftClick}
        className={cn(styles.button, 'cy-button-save-draft')}
      >
        {t('Save')}
      </Button>
    </div>
  );
}

export default React.memo(ButtonSaveDraft);

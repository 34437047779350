import React from 'react';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconGrid2 from 'editor/src/component/Icon/IconGrid2';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection }) => hasSelection;

type Props = Pick<MenuItemProps, 'mode' | 'close'>;

function ButtonGrids({ mode, close }: Props) {
  const dispatch = useDispatch();
  const isActive = useSelector((state) => isSidebarTabActive(state, TAB_NAMES.GRID_DESIGNS));

  const handleClick = () => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.GRID_DESIGNS));
    close?.();
  };

  return (
    <MenuButton
      Icon={IconGrid2}
      mode={mode}
      on={isActive}
      handleClick={handleClick}
      i18nKey="editor-grids"
      className="cy-button-grids"
    />
  );
}

export default React.memo(ButtonGrids);

import cn from 'classnames';
import React, { useEffect } from 'react';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import isTopMenuDropdownVisible from 'editor/src/store/app/selector/isTopMenuDropdownVisible';
import { TopMenuDropdown } from 'editor/src/store/app/types';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import stopCropModeOperation from 'editor/src/store/editor/operation/stopCropModeOperation';
import { setZoomModeAction as setZoomModeOperation } from 'editor/src/store/editor/slice';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import DropDown from 'editor/src/component/DropDown';

import styles from './index.module.scss';

interface Props {
  onClick?: () => void;
  dropDownName: TopMenuDropdown;
  dropDownContent: React.ReactNode;
  text?: string;
  buttonContent?: string | React.ReactNode;
  buttonExtraClass?: string;
  disabled?: boolean;
}

function LeftSectionButtonWithDropdown({
  dropDownName,
  text,
  buttonContent,
  dropDownContent,
  buttonExtraClass,
  onClick,
  disabled,
}: Props) {
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => isTopMenuDropdownVisible(state, dropDownName));
  const closeDropdown = (e?: MouseEvent | TouchEvent) => {
    const isKeepZoomModeOn = e && e.target && e.target instanceof Node && e.target.nodeName === 'CANVAS';
    if (!isKeepZoomModeOn) {
      batch(() => {
        dispatch(stopCropModeOperation());
        dispatch(setActiveTopMenuDropdownOperation(undefined));
        dispatch(setZoomModeOperation(false));
      });
    }
  };

  const onClickHandler = () => {
    dispatch(setActiveTopMenuDropdownOperation(dropDownName));
    if (onClick) {
      onClick();
    }
  };

  useEffect(
    () => () => {
      if (isVisible) {
        closeDropdown();
      }
    },
    [isVisible],
  );

  return (
    <div className={styles.container}>
      <ControlButton
        onClick={onClickHandler}
        on={isVisible}
        className={cn(styles.button, buttonExtraClass)}
        disabled={disabled}
        stopPropagation
      >
        {buttonContent || <span>{text}</span>}
      </ControlButton>
      <DropDown
        onClickOutside={closeDropdown}
        isVisible={isVisible}
        wrapperClassName={styles.dropDownWrapper}
        className={styles.dropDown}
      >
        {dropDownContent}
      </DropDown>
    </div>
  );
}

export default LeftSectionButtonWithDropdown;

import React, { useCallback } from 'react';

import applyLineStyleToSelectionOperation from 'editor/src/store/design/operation/shapes/applyLineStyleToSelectionOperation';
import { LineEdge } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import { LINE_EDGE_1_OPTIONS, LINE_EDGE_2_OPTIONS, Props } from 'editor/src/component/LineEdgeControl';
import MenuButton from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';

import styles from './index.module.scss';

function LineEdgeActionBar({ edge, currentEdge }: Props) {
  const dispatch = useDispatch();

  const onChange = useCallback((edgeType: LineEdge) => {
    dispatch(applyLineStyleToSelectionOperation({ [edge]: edgeType }));
  }, []);

  const options = edge === 'edge1' ? LINE_EDGE_1_OPTIONS : LINE_EDGE_2_OPTIONS;

  return (
    <div className={styles.edgeContainer}>
      {options.map(({ value, Icon }) => (
        <MenuButton
          key={value}
          Icon={Icon}
          on={value === currentEdge}
          mode="icon"
          handleClick={() => onChange(value)}
        />
      ))}
    </div>
  );
}

export default LineEdgeActionBar;

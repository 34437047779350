import React, { useEffect, useRef } from 'react';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import addImageRemotePreviewFromUploader from 'editor/src/store/gallery/operation/addImageRemotePreviewFromUploader';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginName } from 'editor/src/store/plugins/types';

import environment from 'editor/src/environment';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import PlusPrompt from 'editor/src/component/GelatoPrompt/PlusPrompt';

import ContentBlock from '../../Elements/ContentBlock';
import { searchPageConfig, ShutterstockAssetProviderKeys, ShutterstockAssetProviderType } from '../constants';
import { ShutterstockImageItem } from '../types';

import styles from './index.module.scss';

interface Props {
  assetProviderType: ShutterstockAssetProviderType;
  setWidgetInstance: (widgetInstance: any) => void;
  onRefresh: () => void;
}

function ShutterstockWidgetBlock({ assetProviderType, setWidgetInstance, onRefresh }: Props) {
  const widgetRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const plugin = useSelector((state) => getPlugin(state, PluginName.Shutterstock));
  const { ShutterstockWidget } = window as any;

  const { gelatoPlusPrompt, premiumLevel } = plugin?.extra || {};

  useEffect(() => {
    if (!ShutterstockWidget || !widgetRef.current) {
      return;
    }

    const searchPage = searchPageConfig;
    searchPage.props.onItemClick = async (e: Event, item: ShutterstockImageItem) => {
      const uppyId = `uppy-shutterstock/${item.id}-${new Date().getTime()}`;

      const imageData = {
        assetProviderUid: assetProviderType,
        image: { ...item, search_id: item.searchId, uppyId },
      };
      sendPostMessage('assets.shutterstockImport', imageData);

      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.GALLERY));
      e.preventDefault();

      await dispatch(
        addImageRemotePreviewFromUploader({
          id: uppyId,
          source: 'Shutterstock',
          preview: item.url,
          height: item.height,
          width: item.width,
          type: item.media_type,
          name: item.description,
          hideProgressPercentage: true,
        }),
      );
    };

    const widgetConfig = {
      key: environment.shutterstockKeys[ShutterstockAssetProviderKeys[assetProviderType]],
      pages: [{ ...searchPage, component: ShutterstockWidget?.components.SearchPage }],
      container: widgetRef.current,
    };
    const newWidgetInstance = new ShutterstockWidget(widgetConfig);

    setWidgetInstance(newWidgetInstance);
    onRefresh();
  }, [ShutterstockWidget, assetProviderType, widgetRef?.current]);

  return (
    <ContentBlock scroll className={styles.shutterstockWidgetContainer}>
      {!premiumLevel && gelatoPlusPrompt && (
        <PlusPrompt className={styles.prompt} prompt={gelatoPlusPrompt} pluginName={PluginName.Shutterstock} />
      )}
      <div ref={widgetRef} />
    </ContentBlock>
  );
}

export default React.memo(ShutterstockWidgetBlock);

import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

export type ElementOption<T extends string> = {
  value: T;
  label: string;
  disabled?: boolean;
  content?: string | React.ReactNode;
  badge?: React.ReactNode;
};

interface Props<T extends string> {
  options: ElementOption<T>[];
  selected: string;
  onChange: (value: T) => void;
}

function RadioButtonElement<T extends string>({ onChange, options, selected }: Props<T>) {
  return (
    <>
      {options.map((option) => (
        <div
          key={option.value}
          className={cn(styles.option, { [styles.disabled]: option.disabled })}
          onClick={() => !option.disabled && onChange(option.value)}
        >
          <div className={styles.buttonContainer}>
            <div className={styles.radioContainer}>
              <input
                type="radio"
                value={option.value}
                checked={selected === option.value}
                disabled={option.disabled}
                className={cn(styles.input, { [styles.disabled]: option.disabled }, `cy-${option.value}`)}
                readOnly
              />
            </div>
            <div
              className={cn(styles.label, {
                [styles.disabled]: option.disabled,
              })}
            >
              {option.label}
            </div>
            <div>{option.badge}</div>
          </div>
          <div
            className={cn(styles.content, {
              [styles.disabled]: option.disabled,
            })}
          >
            {option.content}
          </div>
        </div>
      ))}
    </>
  );
}

export default React.memo(RadioButtonElement) as <T extends string>(props: Props<T>) => JSX.Element;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import addElementToConditionGroupOperation from 'editor/src/store/design/operation/addElementToConditionGroupOperation';
import removeElementFromGroupConditionOperation from 'editor/src/store/design/operation/removeElementFromGroupConditionOperation';
import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import isElementACollection from 'editor/src/store/design/selector/isElementACollection';
import isElementPersonalizationLocked from 'editor/src/store/design/selector/isElementPersonalizationLocked';
import { MediaElement, StructureIndex } from 'editor/src/store/design/types';
import isShape from 'editor/src/store/design/util/isShape';
import logPersonalizationLayerActivatedOperation from 'editor/src/store/editor/operation/logPersonalizationLayerActivatedOperation';
import isSampleFlagChangeAllowed from 'editor/src/store/editor/selector/isSampleFlagChangeAllowed';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import hasPersonalizationPluginEnabled from 'editor/src/store/plugins/selector/hasPersonalizationPluginEnabled';

import Checkbox from 'editor/src/component/Checkbox';
import SwitchButton from 'editor/src/component/SwitchButton';

import styles from './index.module.scss';

export interface Props {
  structureIndex: StructureIndex;
  element: MediaElement;
  allowPersonalizationControlType?: 'checkbox' | 'toggle';
  enableSampleElementShortDescription?: boolean;
  addSeparator?: boolean;
  toggleSampleElementView?: boolean;
  origin: string | undefined;
}

function PersonalizationOptions({
  structureIndex,
  element,
  origin,
  allowPersonalizationControlType = 'checkbox',
  enableSampleElementShortDescription = false,
  addSeparator = true,
  toggleSampleElementView = false,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const personalizationLocked = useSelector((state) => isElementPersonalizationLocked(state, structureIndex));
  const sampleFlagChangeAllowed = useSelector(isSampleFlagChangeAllowed);
  const personalizationEnabled = useSelector(hasPersonalizationPluginEnabled);

  const handlePersonalizationClick = () => {
    batch(() => {
      if (!isShape(element) && !personalizationLocked && element.sample) {
        dispatch(
          updateMediaElementOperation(structureIndex, { sample: undefined }, MediaUpdateActionName.TOGGLE_SAMPLE),
        );
      }
      dispatch(
        updateMediaElementOperation(
          structureIndex,
          { personalizationLocked: !personalizationLocked || undefined },
          MediaUpdateActionName.TOGGLE_PERSO_LOCK,
        ),
      );

      if (personalizationEnabled) {
        if (personalizationLocked) {
          batch(() => {
            dispatch(addElementToConditionGroupOperation(element));
            if (origin) {
              dispatch(logPersonalizationLayerActivatedOperation(element.type, origin));
            }
          });
        } else {
          dispatch(removeElementFromGroupConditionOperation(element.uuid));
        }
      }
    });
  };

  const handleSampleClick = () => {
    if (isShape(element)) {
      return;
    }
    dispatch(
      updateMediaElementOperation(
        structureIndex,
        { sample: !element.sample || undefined },
        MediaUpdateActionName.TOGGLE_SAMPLE,
      ),
    );
  };

  const personalisable = !personalizationLocked;
  const isACollection = isElementACollection(element);
  const baseShowSampleCondition = sampleFlagChangeAllowed && !isACollection && !isShape(element);

  return (
    <div className={cn(styles.optionsWrapper, 'cy-personalization-options')}>
      {allowPersonalizationControlType === 'checkbox' && (
        <div
          className={cn(styles.persoCheckboxWrap, {
            [styles.disabled]: isACollection,
          })}
        >
          <Checkbox
            on={personalisable}
            onChange={handlePersonalizationClick}
            label={t('editor-allow-personalization')}
            className="cy-personalization-checkbox"
            disabled={isACollection}
          />
          <p>{t('editor-let-your-customers-change-this-element')}</p>
        </div>
      )}
      {allowPersonalizationControlType === 'toggle' && (
        <div
          className={cn(styles.persoToggleWrap, {
            [styles.disabled]: isACollection,
          })}
        >
          <div className={styles.toggleControl}>
            <SwitchButton
              on={personalisable}
              disabled={isACollection}
              onSwitch={handlePersonalizationClick}
              className="cy-personalization-switch"
            />
            <div className={styles.label}>{t('editor-allow-personalization')}</div>
          </div>
          <p className={styles.description}>{t('editor-let-your-customers-change-this-element')}</p>
        </div>
      )}
      {(toggleSampleElementView ? personalisable && baseShowSampleCondition : baseShowSampleCondition) && (
        <div className={addSeparator ? undefined : 'mt-2'}>
          {addSeparator && <div className={styles.separator} />}
          <Checkbox
            disabled={!personalisable}
            on={!isShape(element) && !!element.sample}
            onChange={handleSampleClick}
            label={t('editor-sample-element')}
            className="cy-sample-checkbox"
          />
          <p>
            {enableSampleElementShortDescription
              ? t('editor-sample-element-text-short')
              : t('editor-sample-element-text')}
          </p>
        </div>
      )}
    </div>
  );
}

export default React.memo(PersonalizationOptions);

import { getMediaImageDPI } from 'editor/src/store/design/selector/getMediaElementDpi';
import { MediaImage } from 'editor/src/store/design/types';
import { DPIWarning, WarningLevel, WarningType } from 'editor/src/store/editorModules/warnings/types';
import { GalleryImage } from 'editor/src/store/gallery/types';
import { DpiLevels } from 'editor/src/store/hostSettings/types';

export function getDpiLevel(dpi: number | undefined, limits: DpiLevels) {
  if (!dpi || dpi >= limits.medium) {
    return undefined;
  }
  return dpi < limits.low ? WarningLevel.High : WarningLevel.Medium;
}

const getDpiWarning = (
  element: MediaImage,
  spreadIndex: number,
  galleryImage: GalleryImage,
  limits: DpiLevels,
): DPIWarning | undefined => {
  if (element.sample) {
    return undefined;
  }
  const dpi = getMediaImageDPI(element, galleryImage);
  const level = getDpiLevel(dpi, limits);
  if (!level || !dpi) {
    return undefined;
  }

  return {
    uuid: element.uuid,
    spreadIndex,
    level,
    type: WarningType.LowDPI,
    dpi,
  };
};

export default getDpiWarning;

import React from 'react';

import { StructureIndex } from 'editor/src/store/design/types';

import IconMoveDown from 'editor/src/component/Icon/IconMoveDown';
import DistributeActionButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/DistributeActionButton';

interface Props {
  structureIndexes: StructureIndex[];
  disabled?: boolean;
}

function DistributeBackwardButton({ structureIndexes, disabled }: Props) {
  return (
    <DistributeActionButton
      structureIndexes={structureIndexes}
      icon={<IconMoveDown />}
      distributeTo="backward"
      disabled={disabled}
    />
  );
}

export default React.memo(DistributeBackwardButton);

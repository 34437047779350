import React from 'react';
import { shallowEqual } from 'react-redux';

import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { MediaImage, MediaAddon } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';
import { getAdvPersoDataFromElement } from 'editor/src/store/plugins/utils/advPersoUtils';

import PersonalisationCollection from './PersonalisationCollection';
import PersonalizationSimpleImage from './PersonalizationSimpleImage';

interface Props {
  elementId: number;
  isBuilding?: boolean;
  className?: string;
}

function PersonalizationImage({ elementId, isBuilding, className }: Props) {
  const { element, address, isSelected } = useSelector(
    (state) => ({
      isSelected: state.editor.selectedElementUuids.includes(elementId),
      element: getMediaElementByUuid(state, elementId) as MediaImage | MediaAddon | undefined,
      address: getStructureIndexByElementUuidMemoized(state, elementId),
    }),
    shallowEqual,
  );

  if (!element || !address || element.personalizationLocked) {
    return null;
  }

  const collectionInfo = getAdvPersoDataFromElement(element);
  if (collectionInfo && element.type === 'image') {
    return (
      <PersonalisationCollection
        isSelected={isSelected}
        image={element}
        address={address}
        collectionInfo={collectionInfo}
        isBuilding={isBuilding}
        className={className}
      />
    );
  }

  return (
    <PersonalizationSimpleImage
      isSelected={isSelected}
      element={element}
      address={address}
      isBuilding={isBuilding}
      className={className}
    />
  );
}

export default React.memo(PersonalizationImage);

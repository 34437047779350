import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { removeMediaElementAdjustmentsAction } from 'editor/src/store/design/slice';
import { ElementAddress } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const removeMediaElementAdjustmentsOperation = (address: ElementAddress) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(saveUndoRedoStateOperation('Apply filter'));
    dispatch(removeMediaElementAdjustmentsAction(address));
  });
};

export default removeMediaElementAdjustmentsOperation;

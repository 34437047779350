import cn from 'classnames';
import React from 'react';

import { MediaMockupPlaceholder } from 'editor/src/store/design/types';

import IconRectangle from 'editor/src/component/Icon/IconRectangle';

import abstractStyles from './AbstractLayerItem/index.module.scss';

interface Props {
  element: MediaMockupPlaceholder;
}

function PlaceholderLayerItem({ element }: Props) {
  return (
    <>
      <div className={abstractStyles.thumb}>
        <IconRectangle />
      </div>
      <div className={cn(abstractStyles.text, 'cy-layer-text')}>
        {/* TODO add translation */}
        {`Placeholder ${element.variant.width}x${element.variant.height}`}
      </div>
    </>
  );
}

export default React.memo(PlaceholderLayerItem);

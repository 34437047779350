import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

function HorizontalScrollableArea({ children, className }: Props) {
  return <div className={cn(styles.scrollableArea, className || '')}>{children}</div>;
}

export default React.memo(HorizontalScrollableArea);

import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import getDesignData from 'editor/src/store/design/selector/getDesignData';
import getSpread from 'editor/src/store/design/selector/getSpread';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { SpreadDesignTemplate } from 'editor/src/store/editorModules/templates/types';
import designStructureToSpreadStructure from 'editor/src/store/editorModules/templates/utils/designStructureToSpreadStructure';
import { useStore } from 'editor/src/store/hooks';

import { LAYOUT_TAG_GRID } from 'editor/src/util/layouts/layoutSchemas/defaultSchemas';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import Button from 'editor/src/component/Button';
import useCustomToastr from 'editor/src/component/CustomToastrComponent/useCustomToastr';
import IconPlus from 'editor/src/component/Icon/IconPlus';
import { CanShow } from 'editor/src/component/Menu/type';

import styles from './index.module.scss';

const DESIGN_TEMPLATE_DEFAULT_NAME = 'My design';

export const canShow: CanShow = (state, context) => {
  const spread = getSpread(state, getCurrentSpreadIndex(state));

  const containsGrid = spread?.tags?.includes(LAYOUT_TAG_GRID);
  const hasSaveDesignTab = state.editorModules.sidebar.tabs.some((tab) => tab === TAB_NAMES.DESIGN_TEMPLATES);
  return !context.hasSelection && !!state.design?.designData && hasSaveDesignTab && !containsGrid;
};

function ButtonSaveTemplate() {
  const { t } = useTranslation();
  const store = useStore();
  const showToastr = useCustomToastr();

  const onSaveTemplate = useCallback(() => {
    const state = store.getState();
    const currentSpreadIndex = getCurrentSpreadIndex(state);
    const currentDesignData = getDesignData(state);

    if (!currentDesignData?.spreads?.[currentSpreadIndex].pages[0].groups.media?.length) {
      // TODO translate
      showToastr('info', t('There are no media applied to design'));
      return;
    }

    const spreadDesignStructure = designStructureToSpreadStructure(currentDesignData, currentSpreadIndex);
    const templateExists = state.editorModules.templates.designTemplates.some((template: SpreadDesignTemplate) => {
      return JSON.stringify(spreadDesignStructure) === JSON.stringify(template.structure);
    });

    // if template already exists we should not duplicate it
    if (templateExists) {
      showToastr('info', t('Current design already exists'));
      return;
    }
    sendPostMessage('design.saveDesignTemplate', {
      name: DESIGN_TEMPLATE_DEFAULT_NAME,
      structure: spreadDesignStructure,
    });
  }, []);

  return (
    <div className={styles.saveDesignTemplateButtonWrapper}>
      <Button
        secondary
        stopPropagation
        onClick={onSaveTemplate}
        className={cn(styles.button, 'cy-button-add-template')}
      >
        <IconPlus className={styles.icon} />
        {/* TODO translate */}
        {t('Save design')}
      </Button>
    </div>
  );
}

export default React.memo(ButtonSaveTemplate);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconChevronUp({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M5.625 12.188C5.45833 12.0213 5.375 11.8163 5.375 11.573C5.375 11.3297 5.45833 11.1247 5.625 10.958L9.375 7.20799C9.47233 7.11065 9.573 7.04132 9.677 6.99999C9.781 6.95865 9.88867 6.93799 10 6.93799C10.1113 6.93799 10.219 6.95865 10.323 6.99999C10.427 7.04132 10.5277 7.11065 10.625 7.20799L14.396 10.979C14.5627 11.1457 14.6493 11.3437 14.656 11.573C14.6633 11.8023 14.5767 12.0073 14.396 12.188C14.2293 12.3547 14.0243 12.438 13.781 12.438C13.5383 12.438 13.3337 12.3547 13.167 12.188L10 9.04199L6.833 12.208C6.66633 12.3747 6.46867 12.458 6.24 12.458C6.01067 12.458 5.80567 12.368 5.625 12.188Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconChevronUp;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  active?: boolean;
}

function IconLayers({ className, active }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        {!active ? (
          <path
            d="M12 20.525C11.7833 20.525 11.571 20.4877 11.363 20.413C11.1543 20.3377 10.9583 20.2333 10.775 20.1L4.025 14.85C3.75833 14.65 3.62933 14.3873 3.638 14.062C3.646 13.7373 3.78333 13.475 4.05 13.275C4.23333 13.1417 4.43333 13.075 4.65 13.075C4.86667 13.075 5.06667 13.1417 5.25 13.275L12 18.5L18.75 13.275C18.9333 13.1417 19.1333 13.075 19.35 13.075C19.5667 13.075 19.7667 13.1417 19.95 13.275C20.2167 13.475 20.3543 13.7373 20.363 14.062C20.371 14.3873 20.2417 14.65 19.975 14.85L13.225 20.1C13.0417 20.2333 12.846 20.3377 12.638 20.413C12.4293 20.4877 12.2167 20.525 12 20.525ZM12 15.475C11.7833 15.475 11.571 15.4377 11.363 15.363C11.1543 15.2877 10.9583 15.1833 10.775 15.05L4.025 9.8C3.89167 9.7 3.79167 9.57933 3.725 9.438C3.65833 9.296 3.625 9.15 3.625 9C3.625 8.85 3.65833 8.704 3.725 8.562C3.79167 8.42067 3.89167 8.3 4.025 8.2L10.775 2.95C10.9583 2.81667 11.1543 2.71233 11.363 2.637C11.571 2.56233 11.7833 2.525 12 2.525C12.2167 2.525 12.4293 2.56233 12.638 2.637C12.846 2.71233 13.0417 2.81667 13.225 2.95L19.975 8.2C20.1083 8.3 20.2083 8.42067 20.275 8.562C20.3417 8.704 20.375 8.85 20.375 9C20.375 9.15 20.3417 9.296 20.275 9.438C20.2083 9.57933 20.1083 9.7 19.975 9.8L13.225 15.05C13.0417 15.1833 12.846 15.2877 12.638 15.363C12.4293 15.4377 12.2167 15.475 12 15.475ZM12 13.45L17.75 9L12 4.55L6.25 9L12 13.45Z"
            fill="#212121"
          />
        ) : (
          <path
            d="M4.52509 14.8499C4.25842 14.6499 4.12925 14.3874 4.13759 14.0624C4.14592 13.7374 4.28342 13.4749 4.55009 13.2749C4.73342 13.1416 4.93342 13.0749 5.15009 13.0749C5.36675 13.0749 5.56675 13.1416 5.75009 13.2749L12.5001 18.4999L19.2501 13.2749C19.4334 13.1416 19.6334 13.0749 19.8501 13.0749C20.0668 13.0749 20.2668 13.1416 20.4501 13.2749C20.7168 13.4749 20.8543 13.7374 20.8626 14.0624C20.8709 14.3874 20.7418 14.6499 20.4751 14.8499L13.7251 20.0999C13.3584 20.3832 12.9501 20.5249 12.5001 20.5249C12.0501 20.5249 11.6418 20.3832 11.2751 20.0999L4.52509 14.8499ZM11.2751 15.0499L5.52509 10.5749C5.00842 10.1749 4.75009 9.6499 4.75009 8.9999C4.75009 8.3499 5.00842 7.8249 5.52509 7.4249L11.2751 2.9499C11.6418 2.66657 12.0501 2.5249 12.5001 2.5249C12.9501 2.5249 13.3584 2.66657 13.7251 2.9499L19.4751 7.4249C19.9918 7.8249 20.2501 8.3499 20.2501 8.9999C20.2501 9.6499 19.9918 10.1749 19.4751 10.5749L13.7251 15.0499C13.3584 15.3332 12.9501 15.4749 12.5001 15.4749C12.0501 15.4749 11.6418 15.3332 11.2751 15.0499Z"
            fill="#1C1B1F"
          />
        )}
      </svg>
    </Icon>
  );
}

export default IconLayers;

import cn from 'classnames';
import React from 'react';

import Button from '../Button';
import IconBin from '../Icon/IconBin';
import IconX from '../Icon/IconX';

import styles from './index.module.scss';

interface Props {
  headerText: string;
  headerDescriptionText?: string;
  confirmButtonText: string;
  cancellButtonText: string;
  onCrossClick: (e: React.MouseEvent) => void;
  onConfirm: () => void;
  onCancel: () => void;
}

function ConfirmationMenu({
  headerText,
  headerDescriptionText,
  confirmButtonText,
  cancellButtonText,
  onCrossClick,
  onConfirm,
  onCancel,
}: Props) {
  return (
    <div className={styles.confirmationMenu}>
      <div className="row-flex row-flex--middle row-flex--between">
        <div className={styles.menuHeading}>{headerText}</div>
        <div className={styles.closeIconWrapper}>
          <IconX className={styles.closeIcon} onClick={onCrossClick} />
        </div>
      </div>
      {headerDescriptionText && (
        <div className={cn(styles.headerDescription, 'row-flex', 'mt-4', 'mb-4')}>{headerDescriptionText}</div>
      )}

      <div className={styles.menuButtonsWrapper}>
        <Button danger stopPropagation className={cn(styles.menuButton, styles.removeButton)} onClick={onConfirm}>
          <IconBin />
          <span>{confirmButtonText}</span>
        </Button>
        <Button secondary stopPropagation className={cn(styles.menuButton, styles.cancelButton)} onClick={onCancel}>
          {cancellButtonText}
        </Button>
      </div>
    </div>
  );
}

export default React.memo(ConfirmationMenu);

import cn from 'classnames';
import React from 'react';

import { Scene } from 'editor/src/store/mockup/types';

import styles from './index.module.scss';

interface Props {
  scene: Scene;
  selected: boolean;
  onSelect: (scene: Scene) => void;
}

function SceneItem({ scene, selected, onSelect }: Props) {
  return (
    <img
      className={cn(styles.SceneItem, { [styles.selected]: selected })}
      crossOrigin="anonymous"
      src={scene.thumbnailPreview}
      alt={scene.name}
      onClick={() => onSelect(scene)}
    />
  );
}

export default React.memo(SceneItem);

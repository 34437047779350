import cn from 'classnames';
import React from 'react';

import { VariationProductControl, ProductControlOption, ProductUnAvailability } from 'editor/src/store/variants/types';

import { ControlOptionProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';
import { getUnAvailableMessage } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/utils';
import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';
import WithTooltip from 'editor/src/component/WithTooltip';

import controlStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControl.module.scss';

export interface Props {
  className?: string;
  control: VariationProductControl;
  options: ProductControlOption[];
  selectedValue: string;
  availableOptions?: Set<string>;
  toggleOption(control: VariationProductControl, option: ProductControlOption): void;
  Element: React.FC<ControlOptionProps>;
  title: string;
  IconElement?: React.FC;
  noTopBar?: boolean;
  unAvailableOptions?: { [option: string]: ProductUnAvailability[] };
  disabled?: boolean;
  disabledControlDescription?: string;
}

function SingleValueProductControl({
  options,
  selectedValue,
  toggleOption,
  title,
  Element,
  className,
  control,
  noTopBar,
  IconElement,
  availableOptions,
  unAvailableOptions,
  disabled,
  disabledControlDescription,
}: Props) {
  return (
    <>
      {!noTopBar && <PropertySeparator bigMargin />}
      <div className={controlStyles.controlTitleContainer}>
        <div className={controlStyles.controlTitle}>{title}</div>
        {IconElement && <IconElement />}
      </div>
      <div
        className={cn(className, 'cy-single-value-product-control', `cy-single-value-product-control-${control.key}`)}
      >
        {options.map((option) => {
          const element = (
            <Element
              key={option.value}
              option={option}
              control={control}
              toggle={toggleOption}
              selected={option.value === selectedValue}
              disabled={disabled || (!!availableOptions && !availableOptions.has(option.value))}
              unavailable={
                !!availableOptions && !availableOptions.has(option.value)
                  ? getUnAvailableMessage(option.value, unAvailableOptions)
                  : undefined
              }
            />
          );

          if (!disabledControlDescription) {
            return element;
          }

          return (
            <WithTooltip overlay={disabledControlDescription} placement="top" key={option.value}>
              {element}
            </WithTooltip>
          );
        })}
      </div>
    </>
  );
}

export default React.memo(SingleValueProductControl);

import React from 'react';

import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { GalleryImage } from 'editor/src/store/gallery/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import shouldDigitizeCurrentSpread from 'editor/src/store/utils/shouldDigitizeCurrentSpread';

import { RootState } from 'editor/src/store';

import IconThreadColor from 'editor/src/component/Icon/IconThreadColor';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import MenuButton from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';

export const canQuantizedImageBeColored = (image: GalleryImage) => !!image.quantizedColors?.length;

export const canShow = (state: RootState) => {
  const selectedMediaElementUuid = getSelectedImageUuid(state);

  if (selectedMediaElementUuid && shouldDigitizeCurrentSpread(state)) {
    const mediaObject = getMediaElementByUuid(state, selectedMediaElementUuid);
    if (mediaObject?.type === 'image') {
      const selectedImage = getImageById(state, mediaObject.imageId);
      return !!selectedImage && canQuantizedImageBeColored(selectedImage);
    }
  }

  return false;
};

function ButtonThreadColor({ mode }: MenuItemProps) {
  const dispatch = useDispatch();

  const isActive = useSelector((state) => isSidebarTabActive(state, TAB_NAMES.THREAD_COLOR));

  const handleClick = () => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.THREAD_COLOR));
  };

  return (
    <MenuButton
      Icon={IconThreadColor}
      on={isActive}
      mode={mode}
      handleClick={handleClick}
      i18nKey="Thread colors"
      className="pr-1 cy-button-thread-colors"
    />
  );
}

export default React.memo(ButtonThreadColor);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getSpread from 'editor/src/store/design/selector/getSpread';
import { Dimensions, MediaText } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getLayoutSchemaByName from 'editor/src/store/editor/selector/getLayoutSchemaByName';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { ResizableElement } from 'editor/src/store/variants/types';

import placeResizableFullSizeImageOperation from './placeResizableFullSizeImageOperation';
import placeResizableScaledImageOperation from './placeResizableScaledImageOperation';
import updateResizableLayoutAreaLabelsOperation from './updateResizableLayoutLabelsOperation';

const updateResizableLayoutAreaOperation =
  (resizableElement: ResizableElement, dimensions: Dimensions) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const spread = getSpread(state, getCurrentSpreadIndex(state));
    if (!spread) {
      return;
    }

    const { media } = spread.pages[0].groups || {};
    if (!media) {
      return;
    }

    const { fullSizeFrameId, fullSizeFrameLabelId, scaledFrameId, scaledFrameLabelId } = resizableElement;
    const layout = spread.layoutSchemaName ? getLayoutSchemaByName(state, spread.layoutSchemaName) : undefined;

    const fullElement = media.find((element) => element.type === 'image' && element.layoutFrameId === fullSizeFrameId);
    const scaledElement = media.find((element) => element.type === 'image' && element.layoutFrameId === scaledFrameId);
    const fullElementLabel = fullSizeFrameLabelId
      ? media.find((el) => el.type === 'text' && el.layoutFrameId === fullSizeFrameLabelId)
      : undefined;
    const scaledElementLabel = scaledFrameLabelId
      ? media.find((el) => el.type === 'text' && el.layoutFrameId === scaledFrameLabelId)
      : undefined;

    if (scaledElement?.type !== 'image' || fullElement?.type !== 'image') {
      return;
    }

    batch(() => {
      dispatch(placeResizableFullSizeImageOperation(fullElement, dimensions));
      dispatch(placeResizableScaledImageOperation(scaledElement, dimensions));

      if (!layout) {
        return;
      }
      if (fullElementLabel) {
        dispatch(updateResizableLayoutAreaLabelsOperation(fullElementLabel as MediaText, layout, dimensions));
      }

      if (scaledElementLabel) {
        dispatch(updateResizableLayoutAreaLabelsOperation(scaledElementLabel as MediaText, layout, scaledElement));
      }
    });
  };

export default updateResizableLayoutAreaOperation;

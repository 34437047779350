import React from 'react';

function IconFeedback() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 3H3V17.4H11L16.5 21V17.4H21V3Z" stroke="#212121" strokeWidth="2" strokeLinejoin="round" />
    </svg>
  );
}

export default React.memo(IconFeedback);

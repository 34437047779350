import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'editor/src/store/hooks';
import { MockupProductCategory } from 'editor/src/store/mockup/types';

import ProductCategoryList from './ProductCategoryList';

interface Props {
  back: () => void;
}

function ScenePlaceholderTabContent({ back }: Props) {
  const productCategories = useSelector((state) => state.mockup.scenePlaceholder.productCategories);
  const { t } = useTranslation();

  const rootCategory: MockupProductCategory = useMemo(
    () => ({
      id: 'root',
      type: 'category',
      name: t('Placeholder'),
      previewUrl: '',
      children: productCategories,
    }),
    [productCategories],
  );

  return <ProductCategoryList category={rootCategory} back={back} />;
}

export default React.memo(ScenePlaceholderTabContent);

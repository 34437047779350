import React from 'react';

import getVisibleWarnings from 'editor/src/store/editorModules/warnings/selector/getVisibleWarnings';
import {
  ElementWarning,
  isElementWarning,
  isGlobalWarning,
  isSpreadWarning,
  SpreadWarning,
} from 'editor/src/store/editorModules/warnings/types';
import { useSelector } from 'editor/src/store/hooks';

import ElementWarningItemContainer from './WarningItem/ElementWarningItemContainer';
import GlobalWarningItemContainer from './WarningItem/GlobalWarningItemContainer';
import SpreadWarningItemContainer from './WarningItem/SpreadWarningItemContainer';

function compareUuid(w1: ElementWarning, w2: ElementWarning) {
  return w1.uuid - w2.uuid;
}

function compareSpreadIndex(w1: SpreadWarning, w2: SpreadWarning) {
  return w1.spreadIndex - w2.spreadIndex;
}

interface Props {
  reviewMode?: boolean;
}

function WarningItemsList({ reviewMode }: Props) {
  const warnings = useSelector(getVisibleWarnings);

  return (
    <>
      {warnings
        .filter(isSpreadWarning)
        .sort(compareSpreadIndex)
        .map((warning) => (
          <SpreadWarningItemContainer
            key={`${warning.spreadIndex}-${warning.type}`}
            warning={warning}
            reviewMode={reviewMode}
          />
        ))}
      {warnings
        .filter(isElementWarning)
        .sort(compareUuid)
        .map((warning) => (
          <ElementWarningItemContainer key={`${warning.uuid}-${warning.type}`} warning={warning} />
        ))}
      {warnings.filter(isGlobalWarning).map((warning, i) => (
        <GlobalWarningItemContainer key={`${warning.type}-${i}`} warning={warning} />
      ))}
    </>
  );
}

export default React.memo(WarningItemsList);

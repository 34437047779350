import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import applyToSelectedMediaOperation from 'editor/src/store/design/operation/applyToSelectedMediaTypeOperation';
import { DashPattern, MediaShape } from 'editor/src/store/design/types';
import { setCurrentStyleAction } from 'editor/src/store/editor/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const applyDashStylesToSelectionOperation =
  (type: MediaShape['type'], dashPattern?: DashPattern, updateCurrentStyle = true, strokeWidth?: number) =>
  (dispatch: ThunkDispatch) => {
    batch(() => {
      dispatch(
        applyToSelectedMediaOperation(type, (element) => {
          if (updateCurrentStyle) {
            dispatch(
              setCurrentStyleAction({
                type,
                update: { dashPattern: dashPattern ?? element.dashPattern },
              }),
            );
          }

          return {
            dashPattern,
            strokeWidth: strokeWidth ?? element.strokeWidth,
          };
        }),
      );
    });
  };

export default applyDashStylesToSelectionOperation;

/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconAddImage({ className }: Props) {
  return (
    <Icon className={cn(className, styles.iconAddImage)}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M14 3V5H5V19H19V10H21V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H14ZM19 3V5H21V7H19V9H17V7H15V5H17V3H19ZM6 17H18L14.25 12L11.25 16L9 13L6 17Z"
          fill="#212121"
          strokeWidth={0}
        />
      </svg>
    </Icon>
  );
}

export default IconAddImage;

import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props {
  className?: string;
  color: string | undefined;
}

function IconFillColor({ className, color }: Props) {
  return (
    <Icon className={cn(className, styles.iconFillColor, 'cy-icon-fill-color')}>
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="32" height="32" rx="16" fill="white" />
        <g clipPath="url(#clip0_2471_7869)">
          <rect x="4" y="4.5" width="24" height="24" rx="12" fill={color} />
          <rect x="4.5" y="5" width="23" height="23" rx="11.5" stroke="black" strokeOpacity="0.1" />
        </g>
        <rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="black" strokeOpacity="0.3" />
        <defs>
          <clipPath id="clip0_2471_7869">
            <rect width="24" height="24" fill="white" transform="translate(4 4.5)" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}

export default IconFillColor;

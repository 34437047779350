import { MediaElement } from 'editor/src/store/design/types';

import { RectMediaElement } from 'editor/src/util/reflectDesignData/utils';

export function round2Decimals(value: number): number {
  return Math.round(value * 100) / 100;
}

export function roundToDecimals(value: number, decimals: number): number {
  const offset = 10 ** decimals;
  return Math.round(value * offset) / offset;
}

export function getSanitizeValue(inputValue: string, defaultValue: number, minValue: number, maxValue: number): number {
  const value = parseFloat(inputValue);
  if (Number.isNaN(value)) {
    return defaultValue;
  }
  return Math.max(minValue, Math.min(value, maxValue));
}

const TYPES: RectMediaElement['type'][] = ['image', 'addon', 'text', 'rectangle'];

const SUPPORTED_TYPES = new Set<MediaElement['type']>(TYPES);
export function isSupportedElement(element: MediaElement): element is RectMediaElement {
  return SUPPORTED_TYPES.has(element.type);
}

export const titleKey: { [type in RectMediaElement['type']]: string } = {
  image: 'editor-image-frame',
  text: 'editor-text-frame',
  addon: 'editor-image-frame',
  rectangle: 'editor-rectangle-frame',
  grid: 'editor-grid-frame',
};

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { moveMediaElementZindexTopAction } from 'editor/src/store/design/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import updateMediaElementOperation, { MediaUpdateActionName } from './updateMediaElementOperation';

const updateMediaElementSpotFinishingTypeOperation =
  (uuid: number, on: boolean) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    batch(() => {
      const elementAddress = getStructureIndexByElementUuid(getState().design.designData, uuid);
      if (!elementAddress) {
        return;
      }

      dispatch(
        updateMediaElementOperation(
          elementAddress,
          { has_spot_finishing: on || undefined },
          MediaUpdateActionName.TOGGLE_FOIL,
        ),
      );
      // elements with spot_finishing_type should be on top
      dispatch(moveMediaElementZindexTopAction(elementAddress));
    });
  };

export default updateMediaElementSpotFinishingTypeOperation;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  active?: boolean;
}

function IconGallery({ className, active }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        {!active ? (
          <>
            <path
              d="M5.31429 21C4.67143 21 4.125 20.775 3.675 20.325C3.225 19.875 3 19.3286 3 18.6857V7.88571C3 7.24286 3.225 6.69643 3.675 6.24643C4.125 5.79643 4.67143 5.57143 5.31429 5.57143H16.1143C16.7571 5.57143 17.3036 5.79643 17.7536 6.24643C18.2036 6.69643 18.4286 7.24286 18.4286 7.88571V18.6857C18.4286 19.3286 18.2036 19.875 17.7536 20.325C17.3036 20.775 16.7571 21 16.1143 21H5.31429ZM5.31429 19.4571H16.1143C16.3329 19.4571 16.5159 19.3831 16.6635 19.235C16.8117 19.0874 16.8857 18.9043 16.8857 18.6857V7.88571C16.8857 7.66714 16.8117 7.4838 16.6635 7.33569C16.5159 7.18809 16.3329 7.11429 16.1143 7.11429H5.31429C5.09571 7.11429 4.91237 7.18809 4.76426 7.33569C4.61666 7.4838 4.54286 7.66714 4.54286 7.88571V18.6857C4.54286 18.9043 4.61666 19.0874 4.76426 19.235C4.91237 19.3831 5.09571 19.4571 5.31429 19.4571ZM6.08571 17.9143L9.17143 14.8286L10.56 16.1979L12.2571 14.0571L15.3429 17.9143H6.08571ZM7.62857 11.7429C7.20429 11.7429 6.8412 11.5917 6.53931 11.2893C6.23691 10.9874 6.08571 10.6243 6.08571 10.2C6.08571 9.77571 6.23691 9.41263 6.53931 9.11074C6.8412 8.80834 7.20429 8.65714 7.62857 8.65714C8.05286 8.65714 8.4162 8.80834 8.7186 9.11074C9.02049 9.41263 9.17143 9.77571 9.17143 10.2C9.17143 10.6243 9.02049 10.9874 8.7186 11.2893C8.4162 11.5917 8.05286 11.7429 7.62857 11.7429Z"
              fill="#212121"
            />
            <path
              d="M8.14286 4.54353C8.15373 4.54353 8.16454 4.5433 8.1753 4.54286H18.6857C18.9043 4.54286 19.0874 4.61666 19.235 4.76426C19.3831 4.91237 19.4571 5.09571 19.4571 5.31429V15.8247C19.4567 15.8354 19.4565 15.8463 19.4565 15.8571C19.4565 16.2834 19.802 16.6289 20.2282 16.6289C20.6545 16.6289 21 16.2834 21 15.8571V5.31429C21 4.67143 20.775 4.125 20.325 3.675C19.875 3.225 19.3286 3 18.6857 3H8.14286C7.71662 3 7.37109 3.34553 7.37109 3.77176C7.37109 4.198 7.71662 4.54353 8.14286 4.54353Z"
              fill="#212121"
            />
          </>
        ) : (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.6753 4.54286C8.66454 4.5433 8.65373 4.54353 8.64286 4.54353C8.21662 4.54353 7.87109 4.198 7.87109 3.77176C7.87109 3.34553 8.21662 3 8.64286 3H19.1857C19.8286 3 20.375 3.225 20.825 3.675C21.275 4.125 21.5 4.67143 21.5 5.31429V15.8571C21.5 16.2834 21.1545 16.6289 20.7282 16.6289C20.302 16.6289 19.9565 16.2834 19.9565 15.8571C19.9565 15.8463 19.9567 15.8354 19.9571 15.8247V5.31429C19.9571 5.09571 19.8831 4.91237 19.735 4.76426C19.5874 4.61666 19.4043 4.54286 19.1857 4.54286H8.6753ZM4.175 20.325C4.625 20.775 5.17143 21 5.81429 21H16.6143C17.2571 21 17.8036 20.775 18.2536 20.325C18.7036 19.875 18.9286 19.3286 18.9286 18.6857V7.88571C18.9286 7.24286 18.7036 6.69643 18.2536 6.24643C17.8036 5.79643 17.2571 5.57143 16.6143 5.57143H5.81429C5.17143 5.57143 4.625 5.79643 4.175 6.24643C3.725 6.69643 3.5 7.24286 3.5 7.88571V18.6857C3.5 19.3286 3.725 19.875 4.175 20.325ZM9.67385 14.8294L6.58813 17.9151H15.8453L12.7596 14.0579L11.0624 16.1986L9.67385 14.8294ZM7.04173 11.29C7.34362 11.5924 7.70671 11.7436 8.13099 11.7436C8.55528 11.7436 8.91862 11.5924 9.22102 11.29C9.52291 10.9882 9.67385 10.6251 9.67385 10.2008C9.67385 9.7765 9.52291 9.41341 9.22102 9.11153C8.91862 8.80913 8.55528 8.65793 8.13099 8.65793C7.70671 8.65793 7.34362 8.80913 7.04173 9.11153C6.73933 9.41341 6.58813 9.7765 6.58813 10.2008C6.58813 10.6251 6.73933 10.9882 7.04173 11.29Z"
            fill="#212121"
          />
        )}
      </svg>
    </Icon>
  );
}

export default IconGallery;

import { useMemo } from 'react';

import {
  SingleOptions,
  ProductVariation,
  VariationProductControl,
  ProductControlOption,
  ProductControlOptionDefault,
} from 'editor/src/store/variants/types';

export function getExistingOptions<P extends ProductControlOption>(
  controlKey: string,
  options: P[],
  singleOptions: SingleOptions,
  variations: ProductVariation[],
): P[] {
  const singleOptionKeys = Object.keys(singleOptions);
  return options.filter((option) =>
    variations.some(
      (v) => v[controlKey] === option.value && singleOptionKeys.every((key) => v[key] === singleOptions[key]),
    ),
  );
}

function useFilteredOptionsWithVariations(
  control: VariationProductControl,
  variations: ProductVariation[],
  singleOptions: SingleOptions,
  disabledControlDescription: string | undefined,
) {
  return useMemo(() => {
    const existingOptions = getExistingOptions(control.key, control.options, singleOptions, variations);
    if (disabledControlDescription) {
      return existingOptions.map<ProductControlOptionDefault>((option) => ({
        ...option,
        disabledTitle: disabledControlDescription,
      }));
    }

    return getExistingOptions(control.key, control.options, singleOptions, variations);
  }, [singleOptions, control, disabledControlDescription]);
}

export default useFilteredOptionsWithVariations;

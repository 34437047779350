import React from 'react';
import { useTranslation } from 'react-i18next';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import alignMediaToSpreadBottomOperation from 'editor/src/store/design/operation/align/alignMediaToSpreadBottomOperation';
import alignMediaToSpreadCenterOperation from 'editor/src/store/design/operation/align/alignMediaToSpreadCenterOperation';
import alignMediaToSpreadLeftOperation from 'editor/src/store/design/operation/align/alignMediaToSpreadLeftOperation';
import alignMediaToSpreadMiddleOperation from 'editor/src/store/design/operation/align/alignMediaToSpreadMiddleOperation';
import alignMediaToSpreadRightOperation from 'editor/src/store/design/operation/align/alignMediaToSpreadRightOperation';
import alignMediaToSpreadTopOperation from 'editor/src/store/design/operation/align/alignMediaToSpreadTopOperation';
import applyToMultipleMediaOperation from 'editor/src/store/design/operation/applyToMultipleMediaOperation';
import { StructureIndex } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import { useDispatch } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

interface Props {
  icon: React.ReactElement;
  alignTo: string;
  structureIndexes: StructureIndex[];
  keepTopDropdown?: boolean;
}

function AlignActionButton({ icon, alignTo, structureIndexes, keepTopDropdown = false }: Props) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const handleClick = () => {
    batch(() => {
      if (!keepTopDropdown) {
        dispatch(setActiveTopMenuDropdownOperation(undefined));
      }

      dispatch(saveUndoRedoStateOperation('Align element'));

      switch (alignTo) {
        case 'left':
          dispatch(applyToMultipleMediaOperation(structureIndexes, alignMediaToSpreadLeftOperation));
          break;
        case 'center':
          dispatch(applyToMultipleMediaOperation(structureIndexes, alignMediaToSpreadCenterOperation));
          break;
        case 'right':
          dispatch(applyToMultipleMediaOperation(structureIndexes, alignMediaToSpreadRightOperation));
          break;
        case 'top':
          dispatch(applyToMultipleMediaOperation(structureIndexes, alignMediaToSpreadTopOperation));
          break;
        case 'middle':
          dispatch(applyToMultipleMediaOperation(structureIndexes, alignMediaToSpreadMiddleOperation));
          break;
        case 'bottom':
          dispatch(applyToMultipleMediaOperation(structureIndexes, alignMediaToSpreadBottomOperation));
          break;
        default:
          break;
      }
    });
  };

  return (
    <ControlButton className={styles.alignTabButton} onClick={handleClick} stopPropagation>
      {icon}
      {!isMobile && t(`editor-${alignTo}`)}
    </ControlButton>
  );
}

export default React.memo(AlignActionButton);

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { updatePersonalizationSettingsAction as updatePersonalizationSettingsOperation } from 'editor/src/store/design/slice';
import isChangeBackgroundAllowed from 'editor/src/store/editor/selector/isChangeBackgroundAllowed';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Checkbox from 'editor/src/component/Checkbox';

import CollapsableContainer from '../CollapsableContainer';

import styles from './index.module.scss';

function CustomerSettings() {
  const { t } = useTranslation();
  const allowSpreadBackgroundColorChange = useSelector(isChangeBackgroundAllowed);
  const addOn = useSelector((state) => !!state.design.designData?.personalizationSettings?.allowAddElements);
  const removeOn = useSelector((state) => !!state.design.designData?.personalizationSettings?.allowRemoveElements);
  const changeBgOn = useSelector(
    (state) => !!state.design.designData?.personalizationSettings?.allowChangeBackgroundColor,
  );
  const dispatch = useDispatch();

  const onAddChange = useCallback((on: boolean) => {
    dispatch(
      updatePersonalizationSettingsOperation({
        allowAddElements: on || undefined,
      }),
    );
  }, []);

  const onRemoveChange = useCallback((on: boolean) => {
    dispatch(
      updatePersonalizationSettingsOperation({
        allowRemoveElements: on || undefined,
      }),
    );
  }, []);

  const onChangeBgChange = useCallback((on: boolean) => {
    dispatch(
      updatePersonalizationSettingsOperation({
        allowChangeBackgroundColor: on || undefined,
      }),
    );
  }, []);

  return (
    <CollapsableContainer title={t('Customer Settings')}>
      <div className={styles.CustomerSettings}>
        <div className="mb-1">{t('Allow customers to')}:</div>
        <Checkbox on={addOn} onChange={onAddChange} label={t('Add layers')} className="cy-add-layers-checkbox" />
        <Checkbox
          on={removeOn}
          onChange={onRemoveChange}
          label={t('Remove layers')}
          className="cy-remove-layers-checkbox"
        />
        {allowSpreadBackgroundColorChange && (
          <Checkbox
            on={changeBgOn}
            onChange={onChangeBgChange}
            label={t('Change background color')}
            className="cy-change-bg-checkbox"
          />
        )}
      </div>
    </CollapsableContainer>
  );
}

export default CustomerSettings;

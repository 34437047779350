import React from 'react';
import { shallowEqual } from 'react-redux';

import getFirstGridElement from 'editor/src/store/design/selector/getFirstGridElement';
import { useSelector } from 'editor/src/store/hooks';

import { RootState } from 'editor/src/store';

import ColorSpaceControl from './ColorSpaceControl';
import FirstDayOfWeekControl from './firstDayOfWeekControl';
import GridDesignControl from './GridDesignControl';
import LocaleControl from './LocaleControl';
import MirroringControl from './MirroringControl';
import StartDateControl from './StartDateControl';
import WeekNumberControl from './WeekNumberControl';

interface Props {
  noTopBar?: boolean;
}

export function hasManualControls(state: RootState) {
  // that should also include the mirroring control check. but i think first we need to change
  // the logic to add a flag on preflight to the content element that indicates "it can be mirrored"
  // at the moment it's very much hardcoded into the `useMirroring()` hook.
  return !!getFirstGridElement(state);
}

function ManualControls({ noTopBar }: Props) {
  const { enableMirroring, gridElement, allowMultipleColorSpaces } = useSelector(
    (state) => ({
      enableMirroring: state.hostSettings.enableMirroring,
      allowMultipleColorSpaces: state.hostSettings.allowMultipleColorSpaces,
      gridElement: getFirstGridElement(state),
    }),
    shallowEqual,
  );

  return (
    <>
      {allowMultipleColorSpaces && <ColorSpaceControl noTopBar={noTopBar} />}
      {enableMirroring && <MirroringControl noTopBar={noTopBar} />}
      {gridElement && (
        <>
          <StartDateControl gridElement={gridElement} noTopBar={noTopBar} />
          <FirstDayOfWeekControl gridElement={gridElement} />
          <WeekNumberControl gridElement={gridElement} />
          <LocaleControl gridElement={gridElement} />
          <GridDesignControl gridElement={gridElement} />
        </>
      )}
    </>
  );
}

export default React.memo(ManualControls);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import addMediaElementOperation from 'editor/src/store/design/operation/addMediaElementOperation';
import updateMediaElementByUuidOperation from 'editor/src/store/design/operation/updateMediaElementByUuidOperation';
import { Spread } from 'editor/src/store/design/types';
import type { Thunk } from 'editor/src/store/hooks';

import applyFillImageToElementPlaceholder from 'editor/src/util/design/applyFillImageToElementPlaceholder';
import { applyImageElement } from 'editor/src/util/design/getAppliedLayoutSchemaToSpreadMedia';
import getPerPageBBox from 'editor/src/util/design/getPerPageBBox';
import isImagePlaceholder from 'editor/src/util/design/isImagePlaceholder';
import getImageEltToImageInfo from 'editor/src/util/getImageEltToImageInfo';
import generateLayout from 'editor/src/util/layouts/generateLayout';
import generateLayoutMediaElements from 'editor/src/util/layouts/generateLayoutMediaElements';

import { AutoFillData } from './autoFillOperation';

const applyAutoFillOperation =
  (autoFillData: AutoFillData[]): Thunk =>
  (dispatch, getState, { i18n }) => {
    const state = getState();
    const spreads = state.design.designData?.spreads;
    if (!spreads) {
      return;
    }
    batch(() => {
      autoFillData?.forEach((data) => {
        const spread: Spread = spreads[data.spreadIndex];
        if (data.replacePlaceholderElements) {
          const placeholderImages = spread.pages[0].groups?.media?.filter(isImagePlaceholder);
          placeholderImages?.forEach((placeholderElement, index) => {
            const imageToApply = data.images?.[index];
            // if there are no left images to apply
            if (!imageToApply || !placeholderElement.width || !placeholderElement.height) {
              return;
            }

            const elementUpdates = applyFillImageToElementPlaceholder(placeholderElement, imageToApply);
            dispatch(updateMediaElementByUuidOperation(placeholderElement.uuid, elementUpdates));
          });

          return;
        }

        const bbox = spread ? getPerPageBBox(data.layoutSchema, spread, data.pageIndex) : undefined;
        if (!bbox?.width || !bbox?.height) {
          return;
        }
        const layoutFrames = generateLayout(data.layoutSchema, bbox.left, bbox.top, bbox.width, bbox.height);
        const layoutElements = generateLayoutMediaElements(state, data.spreadIndex, spread, layoutFrames, i18n);
        const imagesToPlace = data.images?.map((image) => getImageEltToImageInfo(image.id, image));
        const media = layoutElements.map((mediaElement, index) => {
          mediaElement.type === 'image' && applyImageElement(mediaElement, undefined, imagesToPlace?.[index]);
          return mediaElement;
        });
        media.forEach((element) => dispatch(addMediaElementOperation(data.spreadIndex, 0, element)));
      });
    });
  };

export default applyAutoFillOperation;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import getPluginIconByLevel from 'editor/src/store/plugins/selector/getPluginIconByLevel';
import { GelatoPromptInfo, Plugin } from 'editor/src/store/plugins/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import styles from './index.module.scss';

interface Props {
  prompt: GelatoPromptInfo;
  className?: string;
  plugin: Plugin;
}

function ElevatePrompt({ prompt, className, plugin }: Props) {
  const { t } = useTranslation();
  const PremiumIcon = getPluginIconByLevel(plugin.extra?.premiumLevel);

  function onSubscribe() {
    sendPostMessage('plugins.subscribeClick', { name: plugin.name });
  }

  return (
    <div className={cn(styles.ElevatePrompt, className)}>
      <div
        className={styles.description}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: prompt.description }}
      />
      <button onClick={onSubscribe} className={styles.learnMore}>
        {t('Learn more')}
      </button>
      <button onClick={onSubscribe} className={styles.button}>
        <PremiumIcon className="mr-1" />
        {prompt.buttonText || t('Start free trial')}
      </button>
    </div>
  );
}

export default React.memo(ElevatePrompt);

import React, { useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { CONTAINER_ID } from './OverlayContainer';

import ConditionOverlay from './index';

function useOverlay() {
  const [visible, setIsVisible] = useState(false);
  const rectRef = useRef<DOMRect>();

  const open = useCallback((rect: DOMRect | undefined) => {
    rectRef.current = rect;
    setIsVisible(true);
  }, []);
  const close = useCallback(() => setIsVisible(false), []);

  const container = document.getElementById(CONTAINER_ID);

  return {
    open,
    close,
    visible,
    render: (title: string, children: React.ReactNode, showOverRect: boolean = false) => {
      if (container && visible) {
        return ReactDOM.createPortal(
          <ConditionOverlay rect={rectRef.current} title={title} onClose={close} showOverRect={showOverRect}>
            {children}
          </ConditionOverlay>,
          container,
        );
      }

      return null;
    },
  };
}

export default useOverlay;

import React from 'react';
import { useTranslation } from 'react-i18next';

import IconStatic from 'editor/src/component/Icon/IconStatic';

import Badge from './Badge';

function StaticBadge() {
  const { t } = useTranslation();
  return (
    <Badge tooltip={t('editor-static')} className="cy-static-badge">
      <IconStatic />
    </Badge>
  );
}

export default React.memo(StaticBadge);

import getNewElementDisplay from 'editor/src/store/design/operation/getNewElementDisplay';
import getNewImageDisplaySize from 'editor/src/store/design/operation/getNewImageDisplaySize';
import hasUsedImagesInSpread from 'editor/src/store/design/selector/hasUsedImagesInSpread';
import { MediaImage } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getDefaultPersonalizationLockState from 'editor/src/store/editor/selector/getDefaultPersonalizationLockState';

import { RootState } from 'editor/src/store';

import applyFillImageToSpread from './design/applyFillImageToSpread';
import applyFitImageToSpread from './design/applyFitImageToSpread';
import { elementUuids } from './uuids';

const getNewImage = (
  state: RootState,
  image: {
    id: string;
    name: string;
    url: string;
    width: number;
    height: number;
  },
  positioning: 'fill' | 'fit' | undefined = 'fill',
  spreadIndex: number = getCurrentSpreadIndex(state),
  uuid: number = elementUuids.generate(),
  x?: number,
  y?: number,
  shiftX?: number,
  shiftY?: number,
): MediaImage | undefined => {
  const display = getNewElementDisplay(
    state,
    spreadIndex,
    (area) => getNewImageDisplaySize(area, image.width, image.height),
    x,
    y,
  );
  if (!display) {
    return undefined;
  }

  const { center, rotation, width, height, area } = display;

  const defaultPersonalizationLockState = getDefaultPersonalizationLockState(state);

  let newImage: MediaImage = {
    imageId: image.id,
    url: image.url,
    type: 'image',
    group: '',
    name: image.name,
    x: center.x - (shiftX || 0),
    y: center.y - (shiftY || 0),
    width,
    height,
    r: rotation,
    px: 0,
    py: 0,
    pw: width,
    ph: height,
    pr: 0,
    uuid,
    personalizationLocked: defaultPersonalizationLockState || undefined,
  };

  if (positioning) {
    const imageRatio = width / height;
    const areaRatio = area.width / area.height;

    if (!hasUsedImagesInSpread(state, spreadIndex) && Math.abs(imageRatio - areaRatio) <= 0.2) {
      const imageUpdate =
        positioning === 'fit' ? applyFitImageToSpread(newImage, area) : applyFillImageToSpread(newImage, area);
      newImage = { ...newImage, ...imageUpdate };
    }
  }

  return newImage;
};

export default getNewImage;

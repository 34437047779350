import cn from 'classnames';
import React from 'react';

import ControlButton from 'editor/src/component/ControlButton';

import styles from './index.module.scss';

export interface SelectButtonGroupElement<T> {
  label: string;
  value: T;
}
interface Props<T> {
  element: SelectButtonGroupElement<T>;
  onChange: (value: T) => void;
  disabled?: boolean;
  isActive: boolean;
}

function SelectButton<T>({ element, onChange, disabled, isActive }: Props<T>) {
  const handleClick = () => {
    if (!disabled) {
      onChange(element.value);
    }
  };

  return (
    <ControlButton
      className={cn(styles.selectButton, { [styles.disabled]: disabled })}
      onClick={handleClick}
      on={isActive}
    >
      {element.label}
    </ControlButton>
  );
}

export default SelectButton;

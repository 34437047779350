import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import addNewTextToPageOperation from 'editor/src/store/design/operation/addNewTextToPageOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import IconPlus from 'editor/src/component/Icon/IconPlus';

import styles from './index.module.scss';

function ButtonAddText() {
  const dispatch = useDispatch();
  const { addElementsAllowed, spreadIndex } = useSelector(
    (state) => ({
      addElementsAllowed: isAddElementsAllowed(state),
      spreadIndex: getCurrentSpreadIndex(state),
    }),
    shallowEqual,
  );
  const { t } = useTranslation();

  const onAddTextLayerClick = () => {
    dispatch(addNewTextToPageOperation(spreadIndex, 0));
  };

  if (!addElementsAllowed) {
    return null;
  }

  return (
    <div className={styles.addTextButtonWrapper}>
      <Button
        secondary
        stopPropagation
        className={cn(styles.addTextButton, 'cy-add-text-button')}
        onClick={onAddTextLayerClick}
      >
        <IconPlus />
        {t('editor-add-text')}
      </Button>
    </div>
  );
}

export default React.memo(ButtonAddText);

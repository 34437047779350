import React from 'react';
import { useTranslation } from 'react-i18next';

import { MediaElement } from 'editor/src/store/design/types';
import { SampleElementNotReplacedWarning } from 'editor/src/store/editorModules/warnings/types';

import AbstractWarningItem from './AbstractWarningItem';

interface Props {
  warning: SampleElementNotReplacedWarning;
  element: MediaElement;
  selected: boolean;
}

function SampleElementNotReplacedWarningItem({ warning, element, selected }: Props) {
  const { t } = useTranslation();
  return (
    <AbstractWarningItem
      warning={warning}
      element={element}
      selected={selected}
      title={element.type === 'text' ? t('Text not changed') : t('Image not replaced')}
    >
      {t('element-not-replaced-explanation')}
    </AbstractWarningItem>
  );
}

export default React.memo(SampleElementNotReplacedWarningItem);

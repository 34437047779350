import applyToSelectedMediaOperation from 'editor/src/store/design/operation/applyToSelectedMediaOperation';
import { LineStyles } from 'editor/src/store/design/util/defaultLineStyles';
import type { ThunkDispatch } from 'editor/src/store/hooks';

type Update = Partial<Omit<LineStyles, 'rounded'>>;

const applyLineStyleToSelectionOperation = (elementUpdate: Update) => (dispatch: ThunkDispatch) => {
  dispatch(applyToSelectedMediaOperation((element) => (element.type !== 'line' ? undefined : elementUpdate)));
};

export default applyLineStyleToSelectionOperation;

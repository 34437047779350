import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { MediaElement } from 'editor/src/store/design/types';

import { useLiveUpdateMediaElement } from 'editor/src/util/useMediaElementLiveUpdates';

import Slider from 'editor/src/component/Slider';

import styles from './index.module.scss';

interface Props {
  title: string;
  className?: string;
  sliderClassName?: string;
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
  getUpdatedElement: (value: number) => MediaElement | undefined;
}

const AutoSelectFn = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();
const StopPropFn = (e: React.KeyboardEvent) => e.stopPropagation();

function RangeControl({
  title,
  className,
  sliderClassName,
  value,
  min,
  max,
  step,
  onChange,
  getUpdatedElement,
}: Props) {
  const [stringValue, setStringValue] = useState(`${value}`);

  useEffect(() => setStringValue(`${value}`), [value]);

  const liveUpdate = useLiveUpdateMediaElement();

  const onSliderChange = useCallback(
    (value: number) => {
      setStringValue(`${value}`);
      const updatedElement = getUpdatedElement(value);
      if (updatedElement) {
        liveUpdate(updatedElement);
      }
    },
    [getUpdatedElement],
  );

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setStringValue(value);
    const num = +value;
    if (!Number.isNaN(num)) {
      onChange(Math.min(max, Math.max(min, num)));
    }
  }

  return (
    <div className={cn(styles.RangeControl, className)}>
      <div className={styles.label}>{title}</div>
      <input
        className={styles.input}
        value={stringValue}
        type="text"
        onKeyDown={StopPropFn}
        onChange={onInputChange}
        onFocus={AutoSelectFn}
      />
      <Slider
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onSliderChange}
        onAfterChange={onChange}
        className={cn(styles.slider, sliderClassName)}
      />
    </div>
  );
}

export default React.memo(RangeControl);

import React from 'react';
import { shallowEqual } from 'react-redux';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import isElementACollection from 'editor/src/store/design/selector/isElementACollection';
import removeAllSelectedMediaElementsOperation from 'editor/src/store/editor/operation/removeAllSelectedMediaElementsOperation';
import isStaticFlagChangeAllowed from 'editor/src/store/editor/selector/isStaticFlagChangeAllowed';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconLockLocked from 'editor/src/component/Icon/IconLockLocked';
import IconLockUnlocked from 'editor/src/component/Icon/IconLockUnlocked';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection }) => hasSelection && isStaticFlagChangeAllowed(state);

function ButtonStatic({ context, mode }: MenuItemProps) {
  const { structureIndexes } = context;
  const { alwaysPersonalisable, isStatic } = useSelector((state) => {
    const element = getMediaElement(state, structureIndexes[0]);

    return {
      alwaysPersonalisable: element && isElementACollection(element),
      isStatic: !!element?.static,
    };
  }, shallowEqual);
  const dispatch = useDispatch();

  const handleClick = () => {
    batch(() => {
      structureIndexes.forEach((structureIndex) => {
        dispatch(
          updateMediaElementOperation(
            structureIndex,
            { static: !isStatic || undefined },
            MediaUpdateActionName.TOGGLE_LOCK,
          ),
        );
        if (!isStatic) {
          dispatch(removeAllSelectedMediaElementsOperation());
          dispatch(
            updateMediaElementOperation(
              structureIndex,
              {
                sample: undefined,
                personalizationLocked: !alwaysPersonalisable || undefined,
              },
              MediaUpdateActionName.TOGGLE_PERSO_LOCK,
              true,
            ),
          );
        }
      });
    });
  };

  return (
    <MenuButton
      Icon={isStatic ? IconLockLocked : IconLockUnlocked}
      on={isStatic}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-static"
      className="cy-button-static"
    />
  );
}

export default React.memo(ButtonStatic);

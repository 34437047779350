import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { MediaText } from 'editor/src/store/design/types';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import { useDispatch } from 'editor/src/store/hooks';

import RangeControl from 'editor/src/component/DesktopSidebar/TabContents/PatternTabContent/BrickPatternControls/RangeControl';

import styles from './index.module.scss';

interface Props {
  selectedElement: MediaText;
  curve: number;
}

function TextCurveControls({ selectedElement, curve }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getUpdatedTextCurve = useCallback(
    (newCurve: number) => {
      if (!selectedElement) {
        return undefined;
      }

      return {
        ...selectedElement,
        extra: {
          ...selectedElement.extra,
          curve: newCurve,
        },
      };
    },
    [selectedElement],
  );

  const onChange = useCallback((newCurve: number) => {
    dispatch(applyFontStylesToSelectionOperation({ curve: newCurve }));
  }, []);

  return (
    <div className={styles.TextCurveControlsContainer}>
      <RangeControl
        title={t('Direction')}
        value={curve}
        min={-360}
        max={360}
        step={1}
        onChange={onChange}
        getUpdatedElement={getUpdatedTextCurve}
        className="cy-curve"
        sliderClassName="p-0"
      />
    </div>
  );
}

export default React.memo(TextCurveControls);

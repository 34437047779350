import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { AnchoringX, AnchoringY } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const updatePlaceholderOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();

  const { designData } = state.design;
  const { variants, activeProductUid, sceneImages, productImages, variantProductImages } =
    state.mockup.productPlaceholder;

  const variant = variants[activeProductUid];
  // if we don't have this variant yet, we need to request it
  if (!variant) {
    sendPostMessage('mockup.requestVariant', activeProductUid);
    return;
  }

  // we have the variant but not the foregrounds/backgrounds
  if (!sceneImages[activeProductUid] && !productImages[activeProductUid] && !variantProductImages[activeProductUid]) {
    sendPostMessage('mockup.requestVariant', activeProductUid);
  }

  designData?.spreads.forEach((spread, spreadIndex) => {
    spread.pages[0].groups.media?.forEach((media, elementIndex) => {
      if (isMediaMockupPlaceholder(media)) {
        let { width, height } = media;

        if (!media.area_fit) {
          const scale = media.width / media.variant.width;
          width = variant.width * scale;
          height = variant.height * scale;
        }

        const update = {
          width,
          height,
          pw: width,
          ph: height,
          variant,
          x: media.x,
          y: media.y,
        };

        if (!media.area_fit) {
          switch (media.anchoring_x) {
            case AnchoringX.Left:
              break;
            case AnchoringX.Right:
              update.x = media.x + (media.width - width);
              break;
            default:
              update.x = media.x + (media.width - width) / 2;
              break;
          }

          switch (media.anchoring_y) {
            case AnchoringY.Top:
              break;
            case AnchoringY.Bottom:
              update.y = media.y + (media.height - height);
              break;
            default:
              update.y = media.y + (media.height - height) / 2;
              break;
          }
        }

        dispatch(
          updateMediaElementAction({
            elementAddress: { spreadIndex, pageIndex: 0, elementIndex },
            elementUpdate: update,
          }),
        );
      }
    });
  });
};

export default updatePlaceholderOperation;

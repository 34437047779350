import React from 'react';

import { TopMenuDropdown } from 'editor/src/store/app/types';

import IconLineSpacing from 'editor/src/component/Icon/IconLineSpacing';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import TextSpacingControl from 'editor/src/component/TextSpacingControl';
import ButtonWithDropdownNotWrapped from 'editor/src/component/TopMenuDesktop/buttons/ButtonWithDropdownNotWrapped';
import MenuDropDownButton from 'editor/src/component/TopMenuDesktop/buttons/MenuDropDownButton';

function ButtonTextSpacingDropDown({ mode }: MenuItemProps) {
  return (
    <ButtonWithDropdownNotWrapped
      dropDownName={TopMenuDropdown.TextSpacing}
      buttonExtraClass="cy-button-text-spacing"
      dropDownContent={<TextSpacingControl />}
      buttonContent={<MenuDropDownButton Icon={IconLineSpacing} mode={mode} i18nKey="spacing" />}
    />
  );
}

export default React.memo(ButtonTextSpacingDropDown);

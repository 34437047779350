import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { LayoutGroupInfo, LayoutSchema } from 'editor/src/store/editorModules/layouts/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import setSaveStepsOperation from 'editor/src/store/editorModules/undoRedo/operation/setSaveStepsOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import { logEvent } from 'editor/src/amplitude';

import applyLayoutSchemaToSpreadOperation from './applyLayoutSchemaToSpreadOperation';

const applyLayoutSchemaToCurrentSpreadOperation =
  (forceApply: boolean, saveUndoRedo: boolean, layoutGroupInfo?: LayoutGroupInfo, layoutSchema?: LayoutSchema) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const spreadIndex = getCurrentSpreadIndex(state);
    const layoutSchemaName = state.design.designData?.spreads[spreadIndex].layoutSchemaName;
    const schemaName = (layoutSchema?.name || layoutGroupInfo?.layoutGroup.name) ?? '';
    // do not re-apply the schema if it was already applied
    if (layoutSchemaName === schemaName && !forceApply) {
      return;
    }

    batch(() => {
      if (saveUndoRedo) {
        dispatch(saveUndoRedoStateOperation('Apply layout'));
      }
      dispatch(setSaveStepsOperation(false));
      dispatch(applyLayoutSchemaToSpreadOperation(spreadIndex, layoutSchema, undefined, layoutGroupInfo));
      dispatch(setSaveStepsOperation(true));
    });

    logEvent('apply layout', { layoutName: schemaName });
  };

export default applyLayoutSchemaToCurrentSpreadOperation;

import { MediaElement } from 'editor/src/store/design/types';
import isElementOutOfRange from 'editor/src/store/design/util/isElementOutOfRange';
import { OutOfRangeWarning, WarningLevel, WarningType } from 'editor/src/store/editorModules/warnings/types';

const SPREAD_SIZE_FACTOR = 5; // the 5 is fairy arbitrary here

function getOutOrRangeWarning(
  element: MediaElement,
  spreadIndex: number,
  spreadWidth: number,
  spreadHeight: number,
): OutOfRangeWarning | undefined {
  const elementMaxSize = Math.max(spreadWidth, spreadHeight) * SPREAD_SIZE_FACTOR;
  if (isElementOutOfRange(element, elementMaxSize)) {
    return {
      type: WarningType.OutOfRange,
      spreadIndex,
      uuid: element.uuid,
      level: WarningLevel.High,
    };
  }
  return undefined;
}

export default getOutOrRangeWarning;

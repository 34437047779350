import React from 'react';

import closePopupOperation from 'editor/src/store/app/module/popup/operation/closePopupOperation';
import getPopupData from 'editor/src/store/app/module/popup/selector/getPopupData';
import setLoadingWithTimeoutOperation from 'editor/src/store/editor/operation/setLoadingWithTimeoutOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import Button from 'editor/src/component/Button';
import AbstractPopup, { PopupType } from 'editor/src/component/Popup/AbstractPopup';
import Section from 'editor/src/component/Popup/AbstractPopup/Section';
import { PopupName } from 'editor/src/component/Popup/popups';

import styles from './index.module.scss';

export const CONFIRMATION_POPUP_NAME = 'confirmation-popup';

export interface ConfirmationOption {
  title: string;
  callbackMessage: any;
  setLoadingOnConfirm?: boolean;
}

export interface ConfirmationPopupData {
  popupTitle: string;
  textLines: string[];
  cancelCallbackMessage: any;
  options: ConfirmationOption[];
  setIsLoadingOnConfirm?: boolean;
}

function ConfirmationPopup() {
  const dispatch = useDispatch();
  const popupData = useSelector((state) => getPopupData(state, PopupName.CONFIRMATION_POPUP));

  if (!popupData) {
    return null;
  }
  const { popupTitle, textLines, cancelCallbackMessage, options } = popupData;

  const handleClickOutside = () => {
    if (cancelCallbackMessage) {
      sendPostMessage(cancelCallbackMessage.type, cancelCallbackMessage.data);
    }
    dispatch(closePopupOperation());
  };

  const handleOptionClick = (option: ConfirmationOption) => {
    if (option.setLoadingOnConfirm) {
      dispatch(setLoadingWithTimeoutOperation());
    }

    if (option.callbackMessage) {
      sendPostMessage(option.callbackMessage.type, option.callbackMessage.data);
    }
    dispatch(closePopupOperation());
  };

  return (
    <AbstractPopup
      onClickOutside={handleClickOutside}
      name={CONFIRMATION_POPUP_NAME}
      type={PopupType.INFO}
      title={popupTitle}
      className="cy-popup-confirmation"
      hideTitleIcon
      buttons={
        <div className={styles.confirmationButtons}>
          {options.map((option, index) => (
            <Button
              key={`popup-btn${index}`}
              secondary={index < options.length - 1}
              primary={index === options.length - 1}
              onClick={() => handleOptionClick(option)}
              className={`cy-popup-option-${index + 1}`}
            >
              {option.title}
            </Button>
          ))}
        </div>
      }
    >
      <Section>
        {textLines.map((text, i) => (
          <p className={styles.confirmationText} key={i}>
            {text}
          </p>
        ))}
      </Section>
    </AbstractPopup>
  );
}

export default React.memo(ConfirmationPopup);

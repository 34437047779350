import React from 'react';

import { TopMenuDropdown } from 'editor/src/store/app/types';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { useSelector } from 'editor/src/store/hooks';

import IconPersonalizationLocked from 'editor/src/component/Icon/IconPersonalizationLocked';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import ButtonWithDropdownNotWrapped from 'editor/src/component/TopMenuDesktop/buttons/ButtonWithDropdownNotWrapped';
import MenuDropDownButton from 'editor/src/component/TopMenuDesktop/buttons/MenuDropDownButton';

import PersonalizationOptions from './PersonalizationOptions';

import styles from './index.module.scss';

function ButtonPersonalizationDropDown({ context, mode }: MenuItemProps) {
  const { structureIndexes } = context;
  const structureIndex = structureIndexes[0];
  const element = useSelector((state) => getMediaElement(state, structureIndex));

  return (
    <ButtonWithDropdownNotWrapped
      dropDownName={TopMenuDropdown.Personalization}
      buttonContent={
        <MenuDropDownButton Icon={IconPersonalizationLocked} mode={mode} i18nKey="editor-personalization" />
      }
      buttonExtraClass="cy-button-personalization"
      disabled={element?.static}
      dropDownContent={
        <div className={styles.dropdown}>
          {element && (
            <PersonalizationOptions
              origin="personalization_options_menu"
              structureIndex={structureIndex}
              element={element}
            />
          )}
        </div>
      }
    />
  );
}

export default React.memo(ButtonPersonalizationDropDown);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconAlignLeft({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M3 22C2.71667 22 2.47933 21.904 2.288 21.712C2.096 21.5207 2 21.2833 2 21V3C2 2.71667 2.096 2.479 2.288 2.287C2.47933 2.09567 2.71667 2 3 2C3.28333 2 3.521 2.09567 3.713 2.287C3.90433 2.479 4 2.71667 4 3V21C4 21.2833 3.90433 21.5207 3.713 21.712C3.521 21.904 3.28333 22 3 22ZM7.5 10C7.08333 10 6.72933 9.854 6.438 9.562C6.146 9.27067 6 8.91667 6 8.5C6 8.08333 6.146 7.72933 6.438 7.438C6.72933 7.146 7.08333 7 7.5 7H20.5C20.9167 7 21.2707 7.146 21.562 7.438C21.854 7.72933 22 8.08333 22 8.5C22 8.91667 21.854 9.27067 21.562 9.562C21.2707 9.854 20.9167 10 20.5 10H7.5ZM7.5 17C7.08333 17 6.72933 16.854 6.438 16.562C6.146 16.2707 6 15.9167 6 15.5C6 15.0833 6.146 14.7293 6.438 14.438C6.72933 14.146 7.08333 14 7.5 14H14.5C14.9167 14 15.2707 14.146 15.562 14.438C15.854 14.7293 16 15.0833 16 15.5C16 15.9167 15.854 16.2707 15.562 16.562C15.2707 16.854 14.9167 17 14.5 17H7.5Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconAlignLeft;

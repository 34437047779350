import { fabric } from 'fabric';

import { Coords } from 'editor/src/store/design/types';

interface BboxCoordinates {
  tl: fabric.Point;
  br: fabric.Point;
  tr: fabric.Point;
  bl: fabric.Point;
}
interface LineCoordinates {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}
interface LinesCoordinates {
  tl: LineCoordinates;
  br: LineCoordinates;
  tr: LineCoordinates;
  bl: LineCoordinates;
}

const MARGIN = 30;

const getLinesCoordinates = (
  bbox: BboxCoordinates,
  spreadCoords: Coords,
  spreadWidth: number,
  spreadHeight: number,
  mm2px: (size: number) => number,
): LinesCoordinates => ({
  tl: {
    x1: Math.min(bbox.tl.x, spreadCoords.left - MARGIN),
    y1: Math.min(bbox.tl.y, spreadCoords.top - MARGIN),
    x2: spreadCoords.left,
    y2: spreadCoords.top,
  },
  tr: {
    x1: spreadCoords.left + mm2px(spreadWidth),
    y1: spreadCoords.top,
    x2: Math.max(bbox.tr.x, spreadCoords.left + mm2px(spreadWidth) + MARGIN),
    y2: Math.min(bbox.tr.y, spreadCoords.top - MARGIN),
  },
  br: {
    x1: spreadCoords.left + mm2px(spreadWidth),
    y1: spreadCoords.top + mm2px(spreadHeight),
    x2: Math.max(bbox.br.x, spreadCoords.left + mm2px(spreadWidth) + MARGIN),
    y2: Math.max(bbox.br.y, spreadCoords.top + mm2px(spreadHeight) + MARGIN),
  },
  bl: {
    x1: spreadCoords.left,
    y1: spreadCoords.top + mm2px(spreadHeight),
    x2: Math.min(bbox.bl.x, spreadCoords.left - MARGIN),
    y2: Math.max(bbox.bl.y, spreadCoords.top + mm2px(spreadHeight) + MARGIN),
  },
});

export default getLinesCoordinates;

/* eslint-disable max-len */
import React from 'react';

import Icon from './index';

interface Props {
  className?: string;
}

function IconSmallLock({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5579 9.71158L16.1158 6.15369C17.2947 4.97471 17.2947 3.06321 16.1158 1.88423C14.9368 0.705255 13.0253 0.705255 11.8463 1.88423L8.28842 5.44212M5.44212 8.28842L1.88423 11.8463C0.705255 13.0253 0.705255 14.9368 1.88423 16.1158C3.06321 17.2947 4.97472 17.2947 6.15369 16.1158L9.71158 12.5579M11.8463 6.15369L6.15369 11.8463"
          stroke="#525252"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

export default React.memo(IconSmallLock);

import React from 'react';

import { Coords, ElementAddress, MediaMockupPlaceholder } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';
import getVariantProductImageKey from 'editor/src/store/mockup/utils/getVariantProductImageKey';

import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import LocalPlaceholder from './LocalPlaceholder';
import PreflightPlaceholder from './PreflightPlaceholder';

interface Props {
  elementData: MediaMockupPlaceholder;
  elementAddress: ElementAddress;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
  selected: boolean;
  isMobile: boolean;
  ignorePersonalizationLock: boolean;
}

function MockupPlaceholder({
  elementData,
  elementAddress,
  pageCoords,
  canvasRotation,
  selected,
  isMobile,
  ignorePersonalizationLock,
}: Props) {
  const appliedVariant = useSelector((state) => state.mockup.preview.appliedVariant);

  const image = useSelector((state) => {
    const variantProductImage = appliedVariant
      ? state.mockup.productPlaceholder.variantProductImages[getVariantProductImageKey(appliedVariant)]
      : undefined;
    if (variantProductImage) {
      return variantProductImage;
    }

    if (state.hostSettings.enablePreflightGeneratedProductMockupImage) {
      const productImageId = elementData.variant.productUid + (elementData.variant.scene ?? '');
      return state.mockup.productPlaceholder.productImages[productImageId];
    }

    return undefined;
  });

  if (image || appliedVariant) {
    return (
      <PreflightPlaceholder
        elementData={elementData}
        productImage={image}
        elementAddress={elementAddress}
        pageCoords={pageCoords}
        canvasRotation={canvasRotation}
        selected={selected}
        isMobile={isMobile}
        ignorePersonalizationLock={ignorePersonalizationLock}
      />
    );
  }

  return (
    <LocalPlaceholder
      elementData={elementData}
      elementAddress={elementAddress}
      pageCoords={pageCoords}
      canvasRotation={canvasRotation}
      selected={selected}
      isMobile={isMobile}
      ignorePersonalizationLock={ignorePersonalizationLock}
    />
  );
}

export default React.memo(MockupPlaceholder);

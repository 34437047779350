import React from 'react';

import { TopMenuDropdown } from 'editor/src/store/app/types';
import getCurrentFontStylesProperty from 'editor/src/store/fonts/selector/getCurrentFontStylesProperty';
import { useSelector } from 'editor/src/store/hooks';

import { MenuItemProps } from 'editor/src/component/Menu/type';
import ButtonWithDropdownNotWrapped from 'editor/src/component/TopMenuDesktop/buttons/ButtonWithDropdownNotWrapped';
import MenuDropDownButton, { Props } from 'editor/src/component/TopMenuDesktop/buttons/MenuDropDownButton';

import TextAlignButtons from './TextAlignButtons';
import TextAlignIcon from './TextAlignIcon';

import styles from './index.module.scss';

const MenuAlignButton = MenuDropDownButton as React.FC<Props<{ align: string }>>;

function ButtonTextAlignmentDropDown({ mode }: MenuItemProps) {
  const currentTextAlign = useSelector((state) => getCurrentFontStylesProperty(state, 'textAlign'));

  return (
    <ButtonWithDropdownNotWrapped
      dropDownName={TopMenuDropdown.TextAlign}
      buttonExtraClass="cy-button-text-alignment"
      dropDownContent={
        <div className={styles.dropdown}>
          <div className={styles.alignmentButtons}>
            <TextAlignButtons />
          </div>
        </div>
      }
      buttonContent={
        <MenuAlignButton
          Icon={TextAlignIcon}
          iconProps={{ align: currentTextAlign }}
          mode={mode}
          i18nKey="editor-text-alignment"
        />
      }
    />
  );
}

export default React.memo(ButtonTextAlignmentDropDown);

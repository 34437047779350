import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import setMirroringModeOperation from 'editor/src/store/design/operation/setMirroringModeOperation';
import { MirroringMode } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { FormatOption } from 'editor/src/store/editorModules/formats/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { getOptions } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ManualControls/MirroringControl';
import useMirroring from 'editor/src/component/EditorArea/Spread/Page/useMirroring';

import { SelectorDetail } from './Details';
import ComplexOption from './MultiOptionsSelector/FormatSelectorOptions/ComplexOption';

import selectorStyles from './MultiOptionsSelector/index.module.scss';

interface Props {
  openDetails: (details: SelectorDetail) => void;
}

function MirroringSelector({ openDetails }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { spreadIndex, pageIndex, page } = useSelector((state) => {
    const spreadIndex = getCurrentSpreadIndex(state);
    const pageIndex = 0;
    const page = state.design.designData?.spreads[spreadIndex].pages[0];
    return {
      spreadIndex,
      pageIndex,
      page,
    };
  }, shallowEqual);

  const { mirroringMode, contentAddress } = useMirroring(spreadIndex, pageIndex, page);

  const options: FormatOption[] = useMemo(
    () =>
      getOptions(t).map(({ title, value }, i) => ({
        name: title,
        value,
        order: i,
        hexColor: '',
        iconUrl: '',
        active: (value || undefined) === mirroringMode,
        callback: value || undefined,
      })),
    [contentAddress, mirroringMode],
  );

  const selectOption = useCallback(
    (value: MirroringMode) => {
      if (contentAddress) {
        dispatch(setMirroringModeOperation({ mirroringMode: value, contentAddress }));
      }
    },
    [contentAddress],
  );

  const openOptionDetails = useCallback((content: string) => {
    openDetails({ type: 'text', content });
  }, []);

  return (
    <div className={selectorStyles.selector}>
      <div className={selectorStyles.selectorName}>{t('editor-print-on-sides')}</div>
      <div className={selectorStyles.options}>
        {options.map((option, i) => (
          <ComplexOption
            key={i}
            option={option}
            display="wrap"
            selectOption={selectOption}
            openDetails={openOptionDetails}
          />
        ))}
      </div>
    </div>
  );
}

export default React.memo(MirroringSelector);

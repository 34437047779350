import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setDesignDataAction } from 'editor/src/store/design/slice';
import { DesignData } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import type { Thunk } from 'editor/src/store/hooks';
import getSelectedSceneId from 'editor/src/store/mockup/selector/getSelectedSceneId';
import { setActiveProductAction, updateMockupDataWithVariantAction } from 'editor/src/store/mockup/slice';

import reflectDesignData from 'editor/src/util/reflectDesignData';
import getReflectContext from 'editor/src/util/reflectDesignData/getReflectContext';

import fetchProductScenesOperation from './fetchProductScenesOperation';
import setSelectedProductSceneOperation from './setSelectedProductSceneOperation';
import updatePlaceholderOperation from './updatePlaceholderOperation';

const applySceneStructureOperation =
  (structure: DesignData): Thunk =>
  (dispatch, getState) => {
    const state = getState();

    batch(() => {
      const { designData } = state.design;
      if (!designData) {
        return;
      }
      const sceneId = getSelectedSceneId(state);
      const design = reflectDesignData(structure, designData, getReflectContext(state));
      design.spreads[0].sceneId = sceneId;

      const scenePlaceholder =
        structure?.spreads[0].pages[0].groups.media?.find(isMediaMockupPlaceholder) ??
        designData?.spreads[0].pages[0].groups.media?.find(isMediaMockupPlaceholder);
      const { media } = design.spreads[0].pages[0].groups;

      if (scenePlaceholder && media) {
        const scenePlaceholderIndex = media.findIndex(isMediaMockupPlaceholder);
        if (scenePlaceholderIndex !== -1) {
          media[scenePlaceholderIndex] = scenePlaceholder;
        } else {
          media.push(scenePlaceholder);
        }
      }

      dispatch(setDesignDataAction(design));

      if (scenePlaceholder) {
        if (scenePlaceholder.variant.scene) {
          const scene = state.mockup.scenePlaceholder.scenes[scenePlaceholder.variant.scene];
          if (!scene) {
            dispatch(fetchProductScenesOperation(scenePlaceholder.variant.productUid));
          } else {
            dispatch(setSelectedProductSceneOperation(scene, scenePlaceholder.variant.productUid, false));
          }
        } else {
          dispatch(setActiveProductAction(scenePlaceholder.variant.productUid));
          dispatch(updateMockupDataWithVariantAction(scenePlaceholder.variant.productUid));
          dispatch(updatePlaceholderOperation());
        }
      }
    });
  };

export default applySceneStructureOperation;

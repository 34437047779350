import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import getSpread from 'editor/src/store/design/selector/getSpread';
import getSpreadGroups from 'editor/src/store/design/selector/getSpreadGroups';
import getSpreadPageMediaElements from 'editor/src/store/design/selector/getSpreadPageMediaElements';
import { moveMediaElementZindexAction } from 'editor/src/store/design/slice';
import { MediaElement } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';
import { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';

import isElementWithSpotFinishingType from 'editor/src/util/design/isElementWithSpotFinishingType';

import ReorganizableElement from 'editor/src/component/ConditionGroupBuilder/ConditionElements/ReorganizableList/Element';
import ReorganizableList from 'editor/src/component/ConditionGroupBuilder/ConditionElements/ReorganizableList/List';
import { getSpreadDisplay } from 'editor/src/component/EditorArea/useSpreadDisplay';

import AbstractLayerItemContent from './AbstractLayerItemContent';

import styles from './index.module.scss';

function LayerItemsList() {
  const dispatch = useDispatch();
  const store = useStore();

  const {
    spreadIndex,
    mediaElements = [],
    designKey,
  } = useSelector((state) => {
    const spreadIndex = getCurrentSpreadIndex(state);
    return {
      mediaElements: getSpreadPageMediaElements(state, spreadIndex, 0),
      spreadIndex,
      designKey: state.design.designData ? getDesignKeyFromDesign(state.design.designData) : '',
    };
  }, shallowEqual);

  const totalElements = mediaElements.length;
  const onIndexUpdate = useCallback(
    (prevIndex: number, newIndex: number) => {
      dispatch(
        moveMediaElementZindexAction({
          spreadIndex,
          pageIndex: 0,
          prevIndex: totalElements - prevIndex - 1,
          newIndex: totalElements - newIndex - 1,
        }),
      );
    },
    [spreadIndex, totalElements],
  );

  const spreadDisplay = useMemo(() => {
    // we do that to avoid have re-computing for each element update
    const state = store.getState();
    const spread = getSpread(state, spreadIndex);
    if (!spread) {
      return undefined;
    }

    const spreadGroups = getSpreadGroups(state);
    return getSpreadDisplay(spread, spreadIndex, spreadGroups);
  }, [designKey, spreadIndex]);

  const { sortableElements, nonSortableElements } = useMemo<{
    sortableElements: MediaElement[];
    nonSortableElements: MediaElement[];
  }>(() => {
    const sortable: MediaElement[] = [];
    const nonSortable: MediaElement[] = [];
    mediaElements.forEach((element) => {
      if (isElementWithSpotFinishingType(element)) {
        nonSortable.push(element);
      } else {
        sortable.push(element);
      }
    });

    return { sortableElements: sortable, nonSortableElements: nonSortable };
  }, [mediaElements]);

  return (
    <>
      <div className={cn(styles.LayerList, 'cy-not-sortable-layers-list')}>
        {spreadDisplay &&
          nonSortableElements.map((element) => (
            <AbstractLayerItemContent key={element.uuid} element={element} spreadDisplay={spreadDisplay} />
          ))}
      </div>
      <ReorganizableList
        className={cn(styles.LayerList, 'cy-sortable-layers-list')}
        margin={0}
        indexOffset={nonSortableElements.length}
        onIndexUpdate={onIndexUpdate}
        disableDragEffect
      >
        {spreadDisplay &&
          sortableElements
            .map((element) => (
              <ReorganizableElement key={element.uuid}>
                <AbstractLayerItemContent element={element} spreadDisplay={spreadDisplay} />
              </ReorganizableElement>
            ))
            .reverse()}
      </ReorganizableList>
    </>
  );
}

export default React.memo(LayerItemsList);

import cn from 'classnames';
import React, { useCallback } from 'react';

import applyLineStyleToSelectionOperation from 'editor/src/store/design/operation/shapes/applyLineStyleToSelectionOperation';
import { LineEdge } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import IconArrow1 from 'editor/src/component/Icon/IconArrow1';
import IconArrow2 from 'editor/src/component/Icon/IconArrow2';
import IconArrowFill1 from 'editor/src/component/Icon/IconArrowFill1';
import IconArrowFill2 from 'editor/src/component/Icon/IconArrowFill2';
import IconFlatLine1 from 'editor/src/component/Icon/IconFlatLine1';
import IconFlatLine2 from 'editor/src/component/Icon/IconFlatLine2';
import IconTick from 'editor/src/component/Icon/IconTick';

import styles from './index.module.scss';

type LineIconType = { value: LineEdge; Icon: React.FC<{ className?: string }> };

export const LINE_EDGE_1_OPTIONS: LineIconType[] = [
  { value: 'flat', Icon: IconFlatLine1 },
  { value: 'arrow', Icon: IconArrow1 },
  { value: 'arrow-fill', Icon: IconArrowFill1 },
];

export const LINE_EDGE_2_OPTIONS: LineIconType[] = [
  { value: 'flat', Icon: IconFlatLine2 },
  { value: 'arrow', Icon: IconArrow2 },
  { value: 'arrow-fill', Icon: IconArrowFill2 },
];

export type Props = {
  currentEdge: LineEdge;
  edge: 'edge1' | 'edge2';
};

function LineEdgeControl({ currentEdge, edge }: Props) {
  const dispatch = useDispatch();

  const onChange = useCallback((edgeType: LineEdge) => {
    dispatch(applyLineStyleToSelectionOperation({ [edge]: edgeType }));
  }, []);

  const options = edge === 'edge1' ? LINE_EDGE_1_OPTIONS : LINE_EDGE_2_OPTIONS;

  return (
    <>
      {options.map(({ value, Icon }) => (
        <div
          className={cn(styles.edgeOption, 'p-1', {
            [styles.selected]: value === currentEdge,
          })}
          onClick={() => onChange(value)}
          key={value}
        >
          <Icon className={`cy-button-${value}`} />
          {value === currentEdge && <IconTick />}
        </div>
      ))}
    </>
  );
}

export default LineEdgeControl;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconSearch from 'editor/src/component/Icon/IconSearch';
import IconX from 'editor/src/component/Icon/IconX';
import TextInput from 'editor/src/component/PersonalizationContent/PersonalizationElements/TextInput';

import styles from './index.module.scss';

interface Props {
  onChange: (value: string) => void;
  value: string;
  isMobile: boolean;
}

function Search({ onChange, value, isMobile }: Props) {
  const { t } = useTranslation();

  const handleClear = () => {
    onChange('');
  };

  return (
    <div className={styles.search}>
      <IconSearch className={styles.icon} />
      <TextInput
        focusOnMount={!isMobile}
        value={value}
        onChange={onChange}
        className="cy-search"
        placeholder={t('editor-search')}
      />
      {!!value && <IconX className={cn(styles.clear, 'cy-search-clear')} onClick={handleClear} />}
    </div>
  );
}

export default Search;

import React from 'react';

import { RootState } from 'editor/src/store';

import { MenuContext } from 'editor/src/component/Menu/type';
import ButtonDuplicate, {
  canShow as canShowDuplicate,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonDuplicate';
import { ButtonLineEdge1 } from 'editor/src/component/TopMenuDesktop/buttons/ButtonLineEdge1';
import { ButtonLineEdge2 } from 'editor/src/component/TopMenuDesktop/buttons/ButtonLineEdge2';
import ButtonLineStrokeColor from 'editor/src/component/TopMenuDesktop/buttons/ButtonLineStrokeColor';
import ButtonShapeStrokeWidth from 'editor/src/component/TopMenuDesktop/buttons/ButtonLineStyles';
import { ButtonPersonalization } from 'editor/src/component/TopMenuDesktop/buttons/ButtonPersonalization';
import ButtonRemove, { canShow as canShowRemove } from 'editor/src/component/TopMenuDesktop/buttons/ButtonRemove';

import styles from './index.module.scss';

interface Props {
  menuContext: MenuContext;
  rootState: RootState;
}

function LineControls({ menuContext, rootState }: Props) {
  return (
    <div className={styles.lineControls}>
      <ButtonLineStrokeColor isMobile mode="label-icon-vertical" />
      <ButtonShapeStrokeWidth isMobile mode="label-icon-vertical" />
      <ButtonLineEdge1 isMobile mode="label-icon-vertical" />
      <ButtonLineEdge2 isMobile mode="label-icon-vertical" />
      <ButtonPersonalization isMobile mode="label-icon-vertical" />
      {canShowDuplicate(rootState, menuContext) && <ButtonDuplicate mode="label-icon-vertical" context={menuContext} />}
      {canShowRemove(rootState, menuContext) && <ButtonRemove mode="label-icon-vertical" context={menuContext} />}
    </div>
  );
}

export default LineControls;

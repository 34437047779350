import { openPopupAction } from 'editor/src/store/app/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import { PopupName } from 'editor/src/component/Popup/popups';

interface Content {
  message?: { type: string; data?: any };
  setIsLoadingOnConfirm?: boolean;
}

const openWarningsPopupOperation = (isProceedBlocked: boolean, content?: Content) => (dispatch: ThunkDispatch) => {
  dispatch(
    openPopupAction({
      open: PopupName.WARNING_POPUP,
      data: {
        ...content,
        isProceedBlocked,
      } as any,
    }),
  );
};

export default openWarningsPopupOperation;

import React from 'react';
import { useTranslation } from 'react-i18next';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';

import ApplyToAllButton from './ApplyToAllButton';
import SchemasList from './SchemasList';

function LayoutsTabContent({ title }: TabContentProps) {
  const { t } = useTranslation();

  return (
    <>
      <TabContentHeader title={title || t('editor-layouts')} />
      <ApplyToAllButton />
      <ContentBlock scroll className="cy-schema-list">
        <SchemasList />
      </ContentBlock>
    </>
  );
}

export default React.memo(LayoutsTabContent);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { clearImageElementsByImageIdAction } from 'editor/src/store/design/slice';
import { removeImagesAction, unselectImagesAction } from 'editor/src/store/gallery/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const removeImagesOperation = (imageIds: string[]) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const { selectedImagesIds } = state.gallery;
  batch(() => {
    imageIds.forEach((id) => {
      if (selectedImagesIds.includes(id)) {
        dispatch(unselectImagesAction([id]));
      }
      dispatch(clearImageElementsByImageIdAction(id));
    });
    dispatch(removeImagesAction(imageIds));
  });
};

export default removeImagesOperation;

import cn from 'classnames';
import React from 'react';

import { ConditionOption } from 'editor/src/store/design/types';

import IconCheck from 'editor/src/component/Icon/IconCheck';

import styles from './index.module.scss';

interface Props {
  conditionId: string;
  option: ConditionOption;
  isActive: boolean;
  onSelectOption: (conditionId: string, optionId: string) => void;
}

function ConditionOptionButton({ conditionId, option, isActive, onSelectOption }: Props) {
  return (
    <button
      className={cn(styles.option, 'cy-condition-option-button', {
        [styles.active]: isActive,
        'cy-active': isActive,
      })}
      onClick={() => onSelectOption(conditionId, option.id)}
    >
      {option.label}
      {isActive && <IconCheck className={styles.check} />}
    </button>
  );
}

export default React.memo(ConditionOptionButton);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import addSelectedMediaElementOperation from './addSelectedMediaElementOperation';
import setCurrentSpreadIndexOperation from './setCurrentSpreadIndexOperation';

const selectElementOperation = (uuid: number) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const address = getStructureIndexByElementUuid(state.design.designData, uuid);
  if (!address) {
    return;
  }

  batch(() => {
    dispatch(setCurrentSpreadIndexOperation(address.spreadIndex));
    dispatch(addSelectedMediaElementOperation(uuid, false));
  });
};

export default selectElementOperation;

import { GridDesign, designs } from '@gelatoas/design-editor-calendar';
import calendar0Image from '@gelatoas/design-editor-calendar/assets/grid-design-0.png';
import calendar10DarkImage from '@gelatoas/design-editor-calendar/assets/grid-design-10-dark.png';
import calendar10Image from '@gelatoas/design-editor-calendar/assets/grid-design-10.png';
import calendar4Image from '@gelatoas/design-editor-calendar/assets/grid-design-4.png';
import calendar5Image from '@gelatoas/design-editor-calendar/assets/grid-design-5.png';
import calendar6Image from '@gelatoas/design-editor-calendar/assets/grid-design-6.png';
import calendar7Image from '@gelatoas/design-editor-calendar/assets/grid-design-7.png';
import calendar8DarkImage from '@gelatoas/design-editor-calendar/assets/grid-design-8-dark.png';
import calendar8Image from '@gelatoas/design-editor-calendar/assets/grid-design-8.png';
import calendar9DarkImage from '@gelatoas/design-editor-calendar/assets/grid-design-9-dark.png';
import calendar9Image from '@gelatoas/design-editor-calendar/assets/grid-design-9.png';
import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

export const gridDesignImages = {
  [designs.gridDesigns[0].name]: calendar0Image,
  [designs.gridDesigns[1].name]: calendar4Image,
  [designs.gridDesigns[2].name]: calendar5Image,
  [designs.gridDesigns[3].name]: calendar6Image,
  [designs.gridDesigns[4].name]: calendar7Image,
  [designs.gridDesigns[5].name]: calendar8Image,
  [designs.gridDesigns[6].name]: calendar8DarkImage,
  [designs.gridDesigns[7].name]: calendar9Image,
  [designs.gridDesigns[8].name]: calendar9DarkImage,
  [designs.gridDesigns[9].name]: calendar10Image,
  [designs.gridDesigns[10].name]: calendar10DarkImage,
};

interface Props {
  gridDesign: GridDesign;
  selected: boolean;
  selectGridDesign: (gridDesign: GridDesign) => void;
}

function GridDesignButton({ gridDesign, selected, selectGridDesign }: Props) {
  function onClick() {
    selectGridDesign(gridDesign);
  }

  return (
    <div
      onClick={onClick}
      className={cn(styles.GridDesignButton, 'cy-grid-design-button', {
        [styles.selected]: selected,
      })}
    >
      <img src={gridDesignImages[gridDesign.name]} alt={gridDesign.name} />
    </div>
  );
}

export default React.memo(GridDesignButton);

import { Spread, SpreadGroundImage, SpreadGroup } from 'editor/src/store/design/types';

import getGroupedSpreadInfo from 'editor/src/util/design/getGroupedSpreadInfo';

const getSpreadGroupInfoFromSpreads = (
  spreadIndex: number,
  spreads: Spread[],
  spreadGroups?: SpreadGroup[],
  spreadBackgrounds?: SpreadGroundImage[],
  spreadForegrounds?: SpreadGroundImage[],
) => {
  let currSpreadGroup;
  let groupedSpreadInfo;
  if (spreadGroups) {
    currSpreadGroup = spreadGroups.find((spreadGroup) => spreadGroup.spreadIndexes.includes(spreadIndex));
    if (currSpreadGroup) {
      // returns the spread group information for the current spread.
      groupedSpreadInfo = getGroupedSpreadInfo(currSpreadGroup, spreads, spreadBackgrounds, spreadForegrounds);
    }
  }

  return { currSpreadGroup, groupedSpreadInfo };
};

export default getSpreadGroupInfoFromSpreads;

import React from 'react';

import applyImageFitOperation from 'editor/src/store/design/operation/applyImageFitOperation';
import { useDispatch } from 'editor/src/store/hooks';

import IconFit from 'editor/src/component/Icon/IconFit';
import { MenuItemProps } from 'editor/src/component/Menu/type';

import { canShow, canShowInTopBar } from './ButtonFill';
import MenuButton from './MenuButton';

export { canShow, canShowInTopBar };

type Props = Pick<MenuItemProps, 'context' | 'mode'>;

function ButtonFit({ context, mode }: Props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(applyImageFitOperation(context.structureIndexes));
  };

  return (
    <MenuButton
      Icon={IconFit}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-fit-edit-image"
      className="cy-button-fit"
    />
  );
}

export default React.memo(ButtonFit);

import inRange from 'lodash/inRange';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { MediaText } from 'editor/src/store/design/types';
import getVisibleColorAtElementPosition from 'editor/src/store/design/util/getVisibleColorAtElementPosition';
import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import getDefaultPersonalizationLockState from 'editor/src/store/editor/selector/getDefaultPersonalizationLockState';
import { setTextEditModeAction as setTextEditModeOperation } from 'editor/src/store/editor/slice';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import type { Thunk } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import fontStylesToTextElement from 'editor/src/util/fontStylesToTextElement';
import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';
import limitPrecision from 'editor/src/util/limitPrecision';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import pt2mm from 'editor/src/util/pt2mm';
import { elementUuids } from 'editor/src/util/uuids';

import updateTextElementWithoutRender from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Text/updateTextElementWithoutRender';

import shouldDigitizeSpreadIndex from '../../utils/shouldDigitizeSpreadIndex';

import addMediaElementOperation from './addMediaElementOperation';
import getNewElementDisplay, { Area } from './getNewElementDisplay';
import getNewTextDisplaySize from './getNewTextDisplaySize';

function updateElement(textElement: MediaText, area: Area, rotation: number) {
  const centerX = area.left + area.width / 2;
  const centerY = area.top + area.height / 2;
  updateTextElementWithoutRender(textElement, undefined);
  const [x, y] = getPointPositionRotatedOnPoint(
    centerX - textElement.width / 2,
    centerY - textElement.height / 2,
    centerX,
    centerY,
    rotation,
  );
  textElement.x = x;
  textElement.y = y;
}

const FABRIC_SIZE_MULTIPLIER = 1.13;

const addNewTextToPageOperation =
  (spreadIndex: number, pageIndex: number, textContent?: string, isPersonalizable?: boolean): Thunk =>
  (dispatch, getState, { i18n }) => {
    const state = getState();

    const minFontSize = getHostSetting(state, 'minFontSize');
    const maxFontSize = getHostSetting(state, 'maxFontSize');
    const display = getNewElementDisplay(state, spreadIndex, getNewTextDisplaySize(minFontSize, maxFontSize));
    if (!display) {
      return;
    }

    const { center, rotation, width, height, fontSize, area } = display;

    const defaultTextStyle = state.fonts.defaultFontStyles;
    const { fontFamily } = state.editor.currentStyles.text;
    const newElementUuid = elementUuids.generate();
    const defaultPersonalizationLockState = getDefaultPersonalizationLockState(state);

    const text = textContent ? textContent.slice(0, maxFontSize) : i18n.t('editor-new-text-sample'); // limit the amount of new characters

    const textElement: MediaText = {
      type: 'text',
      group: '',
      name: `${i18n.t('editor-text')} ${newElementUuid}`,
      sx: 1,
      sy: 1,
      rendered_with: 'opentype.js',
      x: center.x,
      y: center.y,
      width,
      height: textContent ? height : pt2mm(fontSize) * FABRIC_SIZE_MULTIPLIER,
      uuid: newElementUuid,
      personalizationLocked: isPersonalizable ? undefined : defaultPersonalizationLockState || undefined,
      sample: (!textContent && state.hostSettings.flagNewTextElementsAsSample) || undefined,
      ...fontStylesToTextElement({ ...defaultTextStyle, fontFamily, fontSize }, text),
    };
    textElement.r += rotation; // default font style has a angle property
    updateElement(textElement, area, rotation);

    // if the element is outside of the area limit, we will rescale it to fit
    if (textElement.width > area.width || textElement.height > area.height) {
      const fontSizeToFit = limitPrecision(
        textElement.extra.fontSize * Math.min(area.width / textElement.width, (area.height * 3) / textElement.height),
        2,
      );

      if (inRange(fontSizeToFit, minFontSize, maxFontSize)) {
        textElement.extra.fontSize = fontSizeToFit;
        textElement.width = area.width;
        textElement.height = area.height;
        updateElement(textElement, area, rotation);
      }
    }

    textElement.fill = getVisibleColorAtElementPosition(textElement, spreadIndex, state);

    batch(() => {
      dispatch(addMediaElementOperation(spreadIndex, pageIndex, textElement, true));
      dispatch(addSelectedMediaElementOperation(newElementUuid, false));
      dispatch(setTextEditModeOperation(true));

      if (shouldDigitizeSpreadIndex(state, spreadIndex)) {
        dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.FONT_COLORS));
      }

      sendPostMessage('log.textAdded', textElement);
    });
  };

export default addNewTextToPageOperation;

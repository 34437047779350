import { setHideUsedPhotosAction, unselectImagesAction } from 'editor/src/store/gallery/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import { RootState } from '../..';
import { batch } from '../../batchedSubscribeEnhancer';
import getImageIdsUsage from '../../design/util/getImageIdsUsage';

const setHideUsedPhotosOperation =
  (hideUsedPhotos: boolean) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const usedImagesIds = getImageIdsUsage(state.design?.designData);
    const { selectedImagesIds } = state.gallery;
    const selectedUsedImageIds = selectedImagesIds.filter((imageId) => usedImagesIds.has(imageId));

    batch(() => {
      if (selectedUsedImageIds.length && hideUsedPhotos) {
        dispatch(unselectImagesAction(selectedUsedImageIds));
      }

      dispatch(setHideUsedPhotosAction(hideUsedPhotos));
    });
  };

export default setHideUsedPhotosOperation;

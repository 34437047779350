import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PersonalizationContent from 'editor/src/component/PersonalizationContent';

import DrawerHeader from '../BottomBarMobile/DrawerHeader';

import styles from './index.module.scss';

interface Props {
  closeMobileMenuCb: () => void;
}

function MobilePersonalizeLayersContent({ closeMobileMenuCb }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.MobilePersonalizeLayersContent}>
      <DrawerHeader title={t('editor-personalization')} closeMenu={closeMobileMenuCb} />
      <div className={cn(styles.content, 'p-3')}>
        <PersonalizationContent />
      </div>
    </div>
  );
}
export default MobilePersonalizeLayersContent;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconAlignTop({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M3 4C2.71667 4 2.47933 3.904 2.288 3.712C2.096 3.52067 2 3.28333 2 3C2 2.71667 2.096 2.479 2.288 2.287C2.47933 2.09567 2.71667 2 3 2H21C21.2833 2 21.5207 2.09567 21.712 2.287C21.904 2.479 22 2.71667 22 3C22 3.28333 21.904 3.52067 21.712 3.712C21.5207 3.904 21.2833 4 21 4H3ZM8.5 22C8.08333 22 7.72933 21.854 7.438 21.562C7.146 21.2707 7 20.9167 7 20.5V7.5C7 7.08333 7.146 6.72933 7.438 6.438C7.72933 6.146 8.08333 6 8.5 6C8.91667 6 9.27067 6.146 9.562 6.438C9.854 6.72933 10 7.08333 10 7.5V20.5C10 20.9167 9.854 21.2707 9.562 21.562C9.27067 21.854 8.91667 22 8.5 22ZM15.5 16C15.0833 16 14.7293 15.854 14.438 15.562C14.146 15.2707 14 14.9167 14 14.5V7.5C14 7.08333 14.146 6.72933 14.438 6.438C14.7293 6.146 15.0833 6 15.5 6C15.9167 6 16.2707 6.146 16.562 6.438C16.854 6.72933 17 7.08333 17 7.5V14.5C17 14.9167 16.854 15.2707 16.562 15.562C16.2707 15.854 15.9167 16 15.5 16Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconAlignTop;

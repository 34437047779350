import cn from 'classnames';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import {
  isNotDefaultTab,
  isGenericPluginTabInfo,
  isPluginTab,
  TabInfo,
  TAB_NAMES,
} from 'editor/src/store/editorModules/sidebar/types';

import { getIcon } from 'editor/src/component/DesktopSidebar/Tabs/Tab';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import PluginTab from './PluginTab';

import styles from './index.module.scss';

export interface TabContentProps {
  title?: string;
  icon?: React.ReactNode;
  closeMenuCb?: () => void;
}

interface Props {
  activeTab: TabInfo;
  tabContents: { [t in TAB_NAMES]?: React.FC<TabContentProps> };
  className?: string;
}

function TabContents({ activeTab, className, tabContents }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  let tabElement: JSX.Element | null;
  let tabName: string;

  if (isGenericPluginTabInfo(activeTab)) {
    tabName = activeTab.plugin.toLowerCase();
    tabElement = <PluginTab title={activeTab.title} icon={getIcon(activeTab)} pluginName={activeTab.plugin} />;
  } else if (isNotDefaultTab(activeTab) || isPluginTab(activeTab)) {
    const Tab = tabContents[activeTab.id as TAB_NAMES];
    tabName = activeTab.id.toLowerCase();
    tabElement = Tab ? (
      <Tab title={activeTab.title || t(activeTab.translationKey || '')} icon={getIcon(activeTab)} />
    ) : null;
  } else {
    tabName = activeTab.toLowerCase();
    const Tab = tabContents[activeTab];
    tabElement = Tab ? <Tab /> : null;
  }

  return (
    <div
      className={cn(styles.TabContents, className, 'cy-tab-content', `cy-tab-${tabName}`, {
        [styles.mobile]: isMobile,
      })}
    >
      <Suspense fallback="">{tabElement}</Suspense>
    </div>
  );
}

export default React.memo(TabContents);

import getSpreadPageMediaElements from 'editor/src/store/design/selector/getSpreadPageMediaElements';
import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { MediaElement, MediaImage } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';

const getNextEmptyImageFrameOnPage = (state: RootState, uuid: number) => {
  let nextUuid = -1;
  const elementLocation = getStructureIndexByElementUuid(state.design.designData, uuid);
  if (!elementLocation) {
    return nextUuid;
  }

  const { spreadIndex, pageIndex } = elementLocation;
  const pageElements = (getSpreadPageMediaElements(state, spreadIndex, pageIndex) || []).filter(
    (el: MediaElement) => el.type === 'image',
  );
  const elementsCount = pageElements.length;
  const currentElementIndex = pageElements.findIndex((el: MediaElement) => el.uuid === uuid);
  for (let i = 1; i < elementsCount; i += 1) {
    const index = (currentElementIndex + i) % elementsCount;
    const mediaImage = pageElements[index] as MediaImage;
    if (!mediaImage.imageId) {
      nextUuid = mediaImage.uuid;
      break;
    }
  }
  return nextUuid;
};

export default getNextEmptyImageFrameOnPage;

import cn from 'classnames';
import React, { useCallback, useRef } from 'react';
import { shallowEqual } from 'react-redux';

import { ConditionGroup, conditionGroupChildenKey } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useSelector } from 'editor/src/store/hooks';
import { PluginName } from 'editor/src/store/plugins/types';

import ConditionElements from './ConditionElements';

import styles from './index.module.scss';

interface Props {
  conditionGroup: ConditionGroup;
}

function ConditionGroupBuilder({ conditionGroup }: Props) {
  const { spreadIndex, aiPersonalizationOn } = useSelector((state) => {
    const spreadIndex = getCurrentSpreadIndex(state);
    return {
      spreadIndex,
      conditionGroup: state.design.designData?.spreads[spreadIndex]?.conditionGroup,
      aiPersonalizationOn: state.design.globalPlugins.includes(PluginName.AIPersonalization),
    };
  }, shallowEqual);

  const newConditionRef = useRef<string>();

  const onConditionCreated = useCallback((conditionId: string, edit: () => void) => {
    if (newConditionRef.current === conditionId) {
      newConditionRef.current = undefined;
      edit();
    }
  }, []);

  const children = conditionGroup.children[conditionGroupChildenKey];

  return (
    <div className={cn(styles.ConditionGroupBuilder, 'cy-condition-builder')}>
      {conditionGroup && !!children?.length && (
        <div className={styles.list}>
          <ConditionElements
            spreadIndex={spreadIndex}
            conditionGroup={conditionGroup}
            parentCondition={undefined}
            elements={children}
            isEditing={false}
            onConditionCreated={onConditionCreated}
            textOnly={aiPersonalizationOn}
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(ConditionGroupBuilder);

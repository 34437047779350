import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setCollectionFolderAction } from 'editor/src/store/collections/slice';
import { CollectionFolder, CollectionFolderStatus } from 'editor/src/store/collections/types';
import removeMediaElementOperation from 'editor/src/store/design/operation/removeMediaElementOperation';
import updateHiddenElementsOperation from 'editor/src/store/design/operation/updateHiddenElementsOperation';
import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import { DesignData, ElementAddress, MediaImage } from 'editor/src/store/design/types';
import { addImageAction } from 'editor/src/store/gallery/slice';
import { ImageState } from 'editor/src/store/gallery/types';
import getMimeTypeEnum from 'editor/src/store/gallery/utils/getMimeTypeEnum';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { getAdvPersoDataFromElement } from 'editor/src/store/plugins/utils/advPersoUtils';

import { ImageSource } from 'editor/src/amplitude';
import isWebPSupported from 'editor/src/util/isWebPSupported';
import loadImageDimensions from 'editor/src/util/loadImageDimensions';

function getAllElementsUsingCollection(designData: DesignData, folderId: string) {
  const elements: Array<{ element: MediaImage; address: ElementAddress }> = [];
  designData.spreads.forEach((spread, spreadIndex) => {
    spread.pages[0].groups.media?.forEach((element, elementIndex) => {
      const collection = getAdvPersoDataFromElement(element);
      if (element.type === 'image' && collection && collection.folderId === folderId) {
        elements.push({
          element,
          address: { spreadIndex, pageIndex: 0, elementIndex },
        });
      }
    });
  });
  return elements;
}

const setCollectionFolderOperation =
  (folder: CollectionFolder) => async (dispatch: ThunkDispatch, getState: () => RootState) => {
    const assetHasDimensions = await Promise.all(
      folder.assets.map(async (asset) => {
        asset.thumbnailUrl = isWebPSupported ? asset.thumbnailUrl || asset.url : asset.url;
        if (asset.width && asset.height) {
          return true;
        }

        try {
          const dimensions = await loadImageDimensions(asset.thumbnailUrl, 'anonymous', {
            executor: 'setCollectionFolderOperation',
            asset,
          });
          asset.width = dimensions.width;
          asset.height = dimensions.height;
          return false;
        } catch {
          /* */
        }
        return undefined;
      }),
    );

    batch(() => {
      dispatch(setCollectionFolderAction(folder));

      const state = getState();
      const { images } = state.gallery;
      folder.assets.forEach((asset, i) => {
        const hasAssetDimensions = assetHasDimensions[i];
        if (hasAssetDimensions === undefined) {
          return;
        }

        if (!images.find((image) => image.id === asset.id)) {
          dispatch(
            addImageAction({
              id: asset.id,
              state: ImageState.UPLOADED,
              name: asset.name,
              type: getMimeTypeEnum(asset.mimeType),
              url: asset.url,
              thumbnailUrl: isWebPSupported ? asset.thumbnailUrl : asset.url,
              width: asset.width,
              height: asset.height,
              source: ImageSource.Collection,
              hasAssetDimensions,
            }),
          );
        }
      });

      if (!state.design.designData) {
        return;
      }

      const elements = getAllElementsUsingCollection(state.design.designData, folder.id);
      if (folder.status === CollectionFolderStatus.Deleted || !folder.assets.length) {
        // delete collection if the folder is deleted or has no assets
        elements.forEach(({ address }) => dispatch(removeMediaElementOperation(address, false)));
      } else {
        // update the displayed image if it's not part of the folder asset anymore
        const firstAsset = folder.assets[0];
        elements.forEach(({ element, address }) => {
          if (!folder.assets.find((asset) => asset.id === element.imageId)) {
            const elementUpdate: Partial<MediaImage> = {
              imageId: firstAsset.id,
              url: firstAsset.url,
            };
            dispatch(
              updateMediaElementOperation(address, elementUpdate, MediaUpdateActionName.CHANGE_FOLDER_IMAGE, true),
            );
          }
        });
      }

      dispatch(updateHiddenElementsOperation());
    });
  };

export default setCollectionFolderOperation;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconMoveDown({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 9.11762C12.4249 9.11762 12.7692 9.48633 12.7692 9.94115L12.7692 16.1883L14.7197 14.1002C15.0201 13.7786 15.5071 13.7786 15.8075 14.1002C16.1079 14.4218 16.1079 14.9433 15.8075 15.2649L12.5439 18.7588C12.2435 19.0804 11.7565 19.0804 11.4561 18.7588L8.19252 15.2649C7.89212 14.9433 7.89212 14.4218 8.19252 14.1002C8.49292 13.7786 8.97998 13.7786 9.28038 14.1002L11.2308 16.1883L11.2308 9.94115C11.2308 9.48633 11.5752 9.11762 12 9.11762Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7401 5.04843C11.9081 4.98386 12.0919 4.98386 12.2599 5.04843L21.4907 8.59594C21.7962 8.71336 22 9.02349 22 9.37104C22 9.71858 21.7962 10.0287 21.4907 10.1461L15.7214 12.3633C15.3216 12.517 14.8811 12.2945 14.7375 11.8665C14.594 11.4384 14.8018 10.9668 15.2016 10.8131L18.954 9.37104L12 6.69851L5.04597 9.37103L8.79836 10.8131C9.19821 10.9668 9.40599 11.4384 9.26246 11.8665C9.11892 12.2945 8.67842 12.517 8.27856 12.3633L2.50933 10.1461C2.2038 10.0287 2 9.71858 2 9.37103C2 9.02349 2.2038 8.71336 2.50933 8.59593L11.7401 5.04843Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconMoveDown;

import cn from 'classnames';
import React, { useState } from 'react';

import Accordion from 'editor/src/component/Accordion';

import styles from './index.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
}

function CollapsableContainer({ title, children }: Props) {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className={cn(styles.CollapsableContainer, 'mb-2', 'pl-2', 'pr-2')}>
      <Accordion header={title} headerClassName={styles.header} collapsed={collapsed} setCollapsed={setCollapsed}>
        {children}
      </Accordion>
    </div>
  );
}

export default React.memo(CollapsableContainer);

import cn from 'classnames';
import React from 'react';

import TabElements from 'editor/src/component/DesktopSidebar/Tabs/TabElements';

import ScrollHelper from '../ControlsSection/ScrollHelper';

import styles from './index.module.scss';

function MainMenuContent() {
  return (
    <ScrollHelper addUIBorders={false} className={cn(styles.mainMenuContent, 'cy-mobile-menu-buttons')}>
      <div className={styles.tabElements}>
        <TabElements />
      </div>
    </ScrollHelper>
  );
}

export default React.memo(MainMenuContent);

import React from 'react';

interface Props {
  className?: string;
}

function Unavailable({ className }: Props) {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="white" fillOpacity="0.8" />
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M12.0192 10.4288L1.80395 0L0.229492 1.60735L10.4448 12.0361L0.53125 22.1568L2.10571 23.7641L12.0192 13.6435L21.8952 23.7258L23.4697 22.1184L13.5937 12.0361L23.7714 1.64572L22.197 0.0383628L12.0192 10.4288Z"
        fill="#BDBDBD"
      />
    </svg>
  );
}

export default React.memo(Unavailable);

import cn from 'classnames';
import React from 'react';

import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import getCurrentFontStylesProperty from 'editor/src/store/fonts/selector/getCurrentFontStylesProperty';
import { TextAlign } from 'editor/src/store/fonts/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import IconTextAlignCenter from 'editor/src/component/Icon/IconTextAlignCenter';
import IconTextAlignLeft from 'editor/src/component/Icon/IconTextAlignLeft';
import IconTextAlignRight from 'editor/src/component/Icon/IconTextAlignRight';

import styles from './index.module.scss';

function TextAlignButtons() {
  const dispatch = useDispatch();
  const currentTextAlign = useSelector((state) => getCurrentFontStylesProperty(state, 'textAlign'));

  const alignLeft = () => {
    dispatch(applyFontStylesToSelectionOperation({ textAlign: TextAlign.left }));
  };
  const alignRight = () => {
    dispatch(applyFontStylesToSelectionOperation({ textAlign: TextAlign.right }));
  };
  const alignCenter = () => {
    dispatch(applyFontStylesToSelectionOperation({ textAlign: TextAlign.center }));
  };

  return (
    <>
      <ControlButton
        on={currentTextAlign === TextAlign.left}
        onClick={alignLeft}
        className={cn(styles.textAlignButton, 'cy-button-text-align-left')}
        stopPropagation
      >
        <IconTextAlignLeft className={styles.icon} />
      </ControlButton>
      <ControlButton
        on={currentTextAlign === TextAlign.center}
        onClick={alignCenter}
        className={cn(styles.textAlignButton, 'cy-button-text-align-center')}
        stopPropagation
      >
        <IconTextAlignCenter className={styles.icon} />
      </ControlButton>
      <ControlButton
        on={currentTextAlign === TextAlign.right}
        onClick={alignRight}
        className={cn(styles.textAlignButton, 'cy-button-text-align-right')}
        stopPropagation
      >
        <IconTextAlignRight className={styles.icon} />
      </ControlButton>
    </>
  );
}

export default React.memo(TextAlignButtons);

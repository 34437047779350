import { fabric } from 'fabric';

import { ImagePattern } from 'editor/src/store/design/types';
import { WarningLevel } from 'editor/src/store/editorModules/warnings/types';
import { GalleryImage } from 'editor/src/store/gallery/types';
import { DpiLevels } from 'editor/src/store/hostSettings/types';

import { FRAME_PROPS, FRAME_PROPS_HIGH_WARNING, FRAME_PROPS_MEDIUM_WARNING } from './elementsConstantProps';
import getDpiWarningLevel from './getDpiWarningLevel';

const updateBoundingBoxColors = (
  image: fabric.Object,
  frame: fabric.Object,
  ghostRef: React.RefObject<fabric.Object>,
  px2mm: (size: number) => number,
  galleryImage: GalleryImage | undefined,
  limits: DpiLevels | undefined,
  pattern: ImagePattern | undefined,
) => {
  const warningLevel =
    galleryImage && limits
      ? getDpiWarningLevel(
          px2mm(image.getScaledWidth()),
          px2mm(image.getScaledHeight()),
          galleryImage,
          limits,
          pattern
            ? {
                pattern,
                elementWidth: px2mm(frame.width ?? 0),
                elementHeight: px2mm(frame.height ?? 0),
              }
            : undefined,
        )
      : undefined;

  switch (warningLevel) {
    case WarningLevel.Medium:
      frame.borderColor = FRAME_PROPS_MEDIUM_WARNING.borderColor;
      frame.cornerStrokeColor = FRAME_PROPS_MEDIUM_WARNING.borderColor;

      if (ghostRef.current) {
        ghostRef.current.borderColor = FRAME_PROPS_MEDIUM_WARNING.borderColor;
        ghostRef.current.cornerStrokeColor = FRAME_PROPS_MEDIUM_WARNING.borderColor;
        ghostRef.current.cornerColor = FRAME_PROPS_MEDIUM_WARNING.borderColor;
      }
      break;
    case WarningLevel.High:
      frame.borderColor = FRAME_PROPS_HIGH_WARNING.borderColor;
      frame.cornerStrokeColor = FRAME_PROPS_HIGH_WARNING.borderColor;

      if (ghostRef.current) {
        ghostRef.current.borderColor = FRAME_PROPS_HIGH_WARNING.borderColor;
        ghostRef.current.cornerStrokeColor = FRAME_PROPS_HIGH_WARNING.borderColor;
        ghostRef.current.cornerColor = FRAME_PROPS_HIGH_WARNING.borderColor;
      }
      break;
    default:
      frame.borderColor = FRAME_PROPS.borderColor;
      frame.cornerStrokeColor = FRAME_PROPS.borderColor;

      if (ghostRef.current) {
        ghostRef.current.borderColor = FRAME_PROPS.borderColor;
        ghostRef.current.cornerStrokeColor = FRAME_PROPS.borderColor;
        ghostRef.current.cornerColor = FRAME_PROPS.borderColor;
      }
      break;
  }
};

export default updateBoundingBoxColors;

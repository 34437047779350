import React from 'react';

import { useSelector } from 'editor/src/store/hooks';
import getPluginType from 'editor/src/store/plugins/selector/getPluginType';
import { PluginType } from 'editor/src/store/plugins/types';

import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import AssetsSourcePlugin from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin';

interface Props extends TabContentProps {
  pluginName: string;
}

function PluginTab({ title, icon, pluginName }: Props) {
  const pluginType = useSelector((state) => getPluginType(state, pluginName));

  switch (pluginType) {
    case PluginType.ASSETS_SOURCE: {
      return <AssetsSourcePlugin pluginName={pluginName} title={title} icon={icon} />;
    }
    default: {
      return null;
    }
  }
}

export default React.memo(PluginTab);

/* eslint-disable max-len */

const emptyImageIcon = `
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.125 5V7.792H7.792V32.208H32.208V15.875H35V32.208C35 32.958 34.7223 33.611 34.167 34.167C33.611 34.7223 32.958 35 32.208 35H7.792C7.042 35 6.389 34.7223 5.833 34.167C5.27767 33.611 5 32.958 5 32.208V7.792C5 7.042 5.27767 6.389 5.833 5.833C6.389 5.27767 7.042 5 7.792 5H24.125ZM31.667 5V8.375H35.042V11.125H31.667V14.5H28.875V11.125H25.542V8.375H28.875V5H31.667ZM10 28.292H30L23.917 20.167L18.708 27.042L14.875 21.917L10 28.292Z" fill="#5991FF"/>
</svg>
`;

export const emptyGreyImageIcon = `
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.125 5V7.792H7.792V32.208H32.208V15.875H35V32.208C35 32.958 34.7223 33.611 34.167 34.167C33.611 34.7223 32.958 35 32.208 35H7.792C7.042 35 6.389 34.7223 5.833 34.167C5.27767 33.611 5 32.958 5 32.208V7.792C5 7.042 5.27767 6.389 5.833 5.833C6.389 5.27767 7.042 5 7.792 5H24.125ZM31.667 5V8.375H35.042V11.125H31.667V14.5H28.875V11.125H25.542V8.375H28.875V5H31.667ZM10 28.292H30L23.917 20.167L18.708 27.042L14.875 21.917L10 28.292Z" fill="#999999"/>
</svg>
`;

export const imageAddIcon = `
<svg width="58" height="57" viewBox="0 0 58 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.895874 28.5496C0.895874 12.8549 13.619 0.131836 29.3137 0.131836C45.0084 0.131836 57.7315 12.8549 57.7315 28.5496C57.7315 44.2444 45.0084 56.9674 29.3137 56.9674C13.619 56.9674 0.895874 44.2444 0.895874 28.5496Z" fill="white" fill-opacity="0.8"/>
<path d="M32.9768 15.2285V17.708H18.4721V39.3908H40.1549V24.8861H42.6344V39.3908C42.6344 40.0568 42.3878 40.6367 41.8946 41.1305C41.4009 41.6236 40.821 41.8702 40.1549 41.8702H18.4721C17.8061 41.8702 17.2262 41.6236 16.7324 41.1305C16.2393 40.6367 15.9927 40.0568 15.9927 39.3908V17.708C15.9927 17.0419 16.2393 16.462 16.7324 15.9683C17.2262 15.4751 17.8061 15.2285 18.4721 15.2285H32.9768ZM39.6745 15.2285V18.2257H42.6717V20.6679H39.6745V23.6651H37.195V20.6679H34.2351V18.2257H37.195V15.2285H39.6745ZM20.433 35.9131H38.1941L32.792 28.6977L28.1662 34.8031L24.7622 30.2518L20.433 35.9131Z" fill="#212121"/>
</svg>
`;

export default emptyImageIcon;

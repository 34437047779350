/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconMinus({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M6.00003 13C5.7167 13 5.47903 12.904 5.28703 12.712C5.0957 12.5207 5.00003 12.2833 5.00003 12C5.00003 11.7167 5.0957 11.479 5.28703 11.287C5.47903 11.0957 5.7167 11 6.00003 11H18C18.2834 11 18.5207 11.0957 18.712 11.287C18.904 11.479 19 11.7167 19 12C19 12.2833 18.904 12.5207 18.712 12.712C18.5207 12.904 18.2834 13 18 13H6.00003Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconMinus;

import cn from 'classnames';
import React, { useCallback } from 'react';

import applyDashStylesToSelectionOperation from 'editor/src/store/design/operation/shapes/applyDashStylesToSelectionOperation';
import applyLineRoundedToSelectionOperation from 'editor/src/store/design/operation/shapes/applyLineRoundedToSelectionOperation';
import applyLineStyleToSelectionOperation from 'editor/src/store/design/operation/shapes/applyLineStyleToSelectionOperation';
import { MediaLine } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import {
  MAX_STROKE_WIDTH,
  MIN_STROKE_WIDTH,
} from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Line/utils';
import SliderWithInput from 'editor/src/component/SliderWithInput';
import StrokeDecorationControls from 'editor/src/component/StrokeDecorationControls';
import StrokeStylesTab, { STROKE_STYLE } from 'editor/src/component/StrokeDecorationControls/StrokeStylesTab';
import SwitchControl from 'editor/src/component/SwitchControl';

import { useIsMobile } from '../useDetectDeviceType';

export interface Props {
  selectedElement: MediaLine;
}

function LineStylesControl({ selectedElement }: Props) {
  const { liveUpdate } = useMediaElementLiveUpdates(selectedElement);

  const dispatch = useDispatch();
  const { dashPattern, rounded, strokeWidth: currentStrokeWidth } = selectedElement;
  const defaultDashedPattern = useSelector((state) => state.editor.currentStyles.line.dashPattern);
  const isMobile = useIsMobile();

  const onChange = useCallback(
    (strokeWidth: number) => {
      if (selectedElement) {
        liveUpdate({ ...selectedElement, strokeWidth });
      }
    },
    [selectedElement],
  );

  const onAfterChange = useCallback((strokeWidth: number) => {
    dispatch(applyLineStyleToSelectionOperation({ strokeWidth }));
  }, []);

  const onSwitch = useCallback(() => {
    dispatch(applyLineRoundedToSelectionOperation(!rounded));
  }, [rounded]);

  const onLineStyleTabChange = useCallback(
    (tab: STROKE_STYLE) => {
      dispatch(
        applyDashStylesToSelectionOperation('line', tab === STROKE_STYLE.DASHED ? defaultDashedPattern : undefined),
      );
    },
    [defaultDashedPattern],
  );

  return (
    <>
      <SliderWithInput
        inputClassName="cy-stroke-width-input"
        Label="Weight"
        onChange={onChange}
        onAfterChange={onAfterChange}
        min={MIN_STROKE_WIDTH}
        max={MAX_STROKE_WIDTH}
        step={0.5}
        startPoint={1}
        value={currentStrokeWidth}
        position={isMobile ? 'twoRows' : 'singleRow'}
      />
      <SwitchControl
        title="Rounded Corners"
        on={rounded}
        onSwitch={onSwitch}
        className={cn('cy-switch-rounded-corner', 'py-2')}
      />
      <StrokeStylesTab hasDashedPattern={!!dashPattern} onTabChange={onLineStyleTabChange} />
      {dashPattern && <StrokeDecorationControls elementType="line" selectedElement={selectedElement} />}
    </>
  );
}

export default React.memo(LineStylesControl);

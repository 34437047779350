import getSpreadGroupElementsByKey from 'editor/src/store/design/selector/getSpreadGroupElementsByKey';
import { RootState as state } from 'editor/src/store/index';

import getCurrentSpreadIndex from './getCurrentSpreadIndex';

const getCurrentSpreadContentElements = (state: state) => {
  const spreadIndex = getCurrentSpreadIndex(state);
  return getSpreadGroupElementsByKey(state, spreadIndex, 'content');
};

export default getCurrentSpreadContentElements;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconRotate90({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M18.9468 3.7743C19.5598 3.18623 20.5537 3.18623 21.1667 3.7743L25.2801 7.72052C25.8931 8.30859 25.8931 9.26204 25.2801 9.85011L21.1667 13.7963C20.5537 14.3844 19.5598 14.3844 18.9468 13.7963C18.3339 13.2083 18.3339 12.2548 18.9468 11.6667L20.2659 10.4013C15.226 11.5325 11.4726 15.8713 11.4726 21.0511C11.4726 27.0915 16.5768 31.9882 22.8732 31.9882C23.7401 31.9882 24.4429 32.6624 24.4429 33.4941C24.4429 34.3257 23.7401 34.9999 22.8732 34.9999C14.843 34.9999 8.33325 28.7548 8.33325 21.0511C8.33325 14.1546 13.5502 8.42707 20.4047 7.30247L18.9468 5.90389C18.3339 5.31582 18.3339 4.36237 18.9468 3.7743Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9846 20.0386C18.3717 20.6266 18.3717 21.5801 18.9846 22.1682L23.1525 26.1666C23.7655 26.7546 24.7593 26.7546 25.3723 26.1666L29.5402 22.1682C30.1532 21.5801 30.1532 20.6266 29.5402 20.0386L25.3723 16.0402C24.7593 15.4521 23.7655 15.4521 23.1525 16.0402L18.9846 20.0386ZM24.2624 22.9722L22.3144 21.1034L24.2624 19.2346L26.2104 21.1034L24.2624 22.9722Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconRotate90;

import updateMediaElementByUuidOperation from 'editor/src/store/design/operation/updateMediaElementByUuidOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginState } from 'editor/src/store/plugins/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

export interface RequestModifiedImageOperationParams {
  pluginName: string;
  assetId: string;
  elementId: number;
  preview?: boolean;
}

const requestModifiedImageOperation =
  ({ pluginName, assetId, elementId, preview }: RequestModifiedImageOperationParams) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const plugin = getPlugin(state, pluginName);
    const premiumNotEnabled = plugin?.state === PluginState.NON_FUNCTIONAL || plugin?.state === PluginState.DISABLED;

    if (premiumNotEnabled) {
      sendPostMessage('plugins.disabledPluginClick', { name: pluginName });
      return;
    }

    dispatch(updateMediaElementByUuidOperation(elementId, { loading: true }));
    sendPostMessage('plugins.requestModifiedImage', {
      pluginName,
      assetId,
      elementId,
      preview,
    });
  };

export default requestModifiedImageOperation;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import {
  setThe3dPreviewAction,
  setFlatPreviewsAction,
  setPreviewStatusAction,
} from 'editor/src/store/editorModules/preview/slice';
import { FlatPreview, PreviewStatus, The3dPreview } from 'editor/src/store/editorModules/preview/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const setRendersOperation = (data: { flat: FlatPreview[]; the3d?: The3dPreview }) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(setFlatPreviewsAction(data.flat));
    dispatch(setThe3dPreviewAction(data.the3d));
    dispatch(setPreviewStatusAction(PreviewStatus.LOADED));
  });
};

export default setRendersOperation;

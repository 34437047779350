/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconTemplates({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="25"
          y="7"
          width="9"
          height="18"
          transform="rotate(90 25 7)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="19"
          y="16"
          width="9"
          height="12"
          transform="rotate(90 19 16)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          width="9"
          height="6"
          transform="matrix(1.19249e-08 1 1 -1.19249e-08 19 16)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

export default IconTemplates;

import { fabric } from 'fabric';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

const OFFSET_RIGHT = 24;
const OFFSET_BOTTOM = 27;
const ICON_SIZE = 20;

function getWarningIconDimensions(frameRect: ObjectRect, zoom: number): Partial<fabric.IObjectOptions> {
  const offset = fabric.util.rotatePoint(
    new fabric.Point(frameRect.width - OFFSET_RIGHT / zoom, frameRect.height - OFFSET_BOTTOM / zoom),
    new fabric.Point(0, 0),
    fabric.util.degreesToRadians(frameRect.angle),
  );

  let iconSize = ICON_SIZE;
  if (OFFSET_RIGHT / zoom > frameRect.width || OFFSET_BOTTOM / zoom > frameRect.height) {
    iconSize = 0;
  }

  const left = frameRect.left + offset.x;
  const top = frameRect.top + offset.y;
  return {
    width: iconSize,
    height: iconSize,
    left,
    top,
    scaleX: 1 / zoom,
    scaleY: 1 / zoom,
    angle: frameRect.angle,
  };
}

export default getWarningIconDimensions;

import { RootState } from 'editor/src/store/index';
import { PluginName } from 'editor/src/store/plugins/types';

import getPluginIconByLevel from './getPluginIconByLevel';

const getPluginIcon = (state: RootState, pluginName: PluginName | string) => {
  const plugin = state.plugins.list.find((element) => element.name === pluginName);
  return getPluginIconByLevel(plugin?.extra?.premiumLevel);
};

export default getPluginIcon;

import openWarningsPopupOperation from 'editor/src/store/app/module/popup/operation/openWarningsPopupOperation';
import sendDesignDataPostMessage from 'editor/src/store/app/operation/sendDesignDataPostMessage';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import { batch } from '../../batchedSubscribeEnhancer';
import setLoadingWithTimeoutOperation from '../../editor/operation/setLoadingWithTimeoutOperation';
import { Warning, WarningLevel, WarningType } from '../../editorModules/warnings/types';
import setHostSettingsOperation from '../../hostSettings/operation/setHostSettingsOperation';

type Payload = {
  data?: { checkWarnings: PopupWarningTypes; setIsLoadingOnConfirm?: boolean };
  callback?: { type: string; data?: any };
};

export type PopupWarningTypes = Array<{
  type: WarningType;
  level: WarningLevel[];
}>;

const requestDesignDataOperation = (payload: Payload) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  return batch(() => {
    let success: boolean = false;
    const { data, callback } = payload;

    const warningTypes = data?.checkWarnings || [];

    if (warningTypes.length) {
      dispatch(setHostSettingsOperation({ showWarningsOnlyAfterDesignRequest: false }));
      const warnings = getState().editorModules.warnings.list;

      const showPopup = warnings.some((warning: Warning) =>
        warningTypes.some(
          (warningType) => warningType.type === warning.type && warningType.level.includes(warning.level),
        ),
      );

      const isProceedBlocked = warnings.some((warning: Warning) =>
        warningTypes.some((warningType) => {
          return warningType.type === warning.type && warning.isProceedBlocked;
        }),
      );

      if (showPopup) {
        success = false;
        dispatch(
          openWarningsPopupOperation(isProceedBlocked, {
            message: callback,
            setIsLoadingOnConfirm: data ? data.setIsLoadingOnConfirm : false,
          }),
        );
      } else {
        success = dispatch(sendDesignDataPostMessage());

        if (success && data?.setIsLoadingOnConfirm) {
          dispatch(setLoadingWithTimeoutOperation());
        }
      }
    } else {
      success = dispatch(sendDesignDataPostMessage());
      if (success && data?.setIsLoadingOnConfirm) {
        dispatch(setLoadingWithTimeoutOperation());
      }
    }

    return success;
  });
};

export default requestDesignDataOperation;

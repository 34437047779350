import React from 'react';

import { useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { GelatoPromptInfo, PluginName, PremiumLevel } from 'editor/src/store/plugins/types';

import ElevatePrompt from './ElevatePrompt/ElevatePrompt';
import PlusPrompt from './PlusPrompt';

interface Props {
  prompt: GelatoPromptInfo;
  pluginName: PluginName | string;
  className?: string;
}

function GelatoPrompt({ prompt, pluginName, className }: Props) {
  const plugin = useSelector((state) => getPlugin(state, pluginName));
  const isElevate =
    !plugin?.extra?.premiumLevel ||
    plugin?.extra?.premiumLevel === PremiumLevel.GOLD_NEW ||
    plugin?.extra?.premiumLevel === PremiumLevel.SILVER_NEW;

  return isElevate && plugin ? (
    <ElevatePrompt prompt={prompt} plugin={plugin} className={className} />
  ) : (
    <PlusPrompt prompt={prompt} pluginName={pluginName} className={className} />
  );
}

export default React.memo(GelatoPrompt);

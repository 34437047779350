import React from 'react';
import { useTranslation } from 'react-i18next';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updateMediaElementByUuidOperation from 'editor/src/store/design/operation/updateMediaElementByUuidOperation';
import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import activateTabAfterImageModifiedOperation from 'editor/src/store/plugins/operation/imageManipulation/activateTabAfterImageModifiedOperation';
import resetModifiedImageOperation from 'editor/src/store/plugins/operation/imageManipulation/resetModifiedImageOperation';
import getActiveImageManipulationPlugin from 'editor/src/store/plugins/selector/getActiveImageManipulationPlugin';

import IconX from 'editor/src/component/Icon/IconX';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = () => true;

type Props = Pick<MenuItemProps, 'mode'>;

function ButtonDeclineModifiedImage({ mode }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const element = useSelector((state) => {
    const elementId = getSelectedImageUuid(state);
    return elementId !== undefined ? getMediaElementByUuid(state, elementId) : undefined;
  });

  const activePlugin = useSelector((state) => getActiveImageManipulationPlugin(state, element));
  const originalUrl = useSelector((state) =>
    element?.type === 'image' ? getImageById(state, element.imageId)?.url : undefined,
  );

  if (element?.type !== 'image' || !element.imageId || !element.uuid || !originalUrl || !activePlugin) {
    return null;
  }

  const handleClick = () =>
    batch(() => {
      dispatch(updateMediaElementByUuidOperation(element.uuid, { loading: true }));
      dispatch(activateTabAfterImageModifiedOperation());
      dispatch(
        resetModifiedImageOperation({
          assetId: element.imageId,
          elementId: element.uuid,
        }),
      );
    });

  return (
    <MenuButton
      Icon={IconX}
      mode={mode}
      handleClick={handleClick}
      disabled={element.loading}
      label={activePlugin?.extra.declineModificationLabel || t('Cancel')}
      className="cy-button-decline-modified-image"
    />
  );
}

export default React.memo(ButtonDeclineModifiedImage);

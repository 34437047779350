import { SpreadGroup } from 'editor/src/store/design/types';

// we assume all grouped spreads have the same dimensions
function getGroupedSpreadDimensions(spreadWidth: number, spreadHeight: number, group: SpreadGroup | undefined) {
  return {
    spreadWidth: group?.position === 'horizontal' ? spreadWidth * group.spreadIndexes.length : spreadWidth,
    spreadHeight: group?.position === 'vertical' ? spreadHeight * group.spreadIndexes.length : spreadHeight,
  };
}

export default getGroupedSpreadDimensions;

import React from 'react';

import { MockupPreviewProduct } from 'editor/src/store/mockup/types';

import { EntryProps } from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/MockupList';
import MockupListEntry from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/MockupListEntry';

function StoreProductEntry({ entry, onSelect }: EntryProps<MockupPreviewProduct>) {
  return <MockupListEntry imageUrl={entry.previewUrl} title={entry.name} onClick={() => onSelect(entry)} />;
}

export default React.memo(StoreProductEntry);

/* eslint-disable max-len */
import React from 'react';

function IconExpandLess() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.625 12.1875C5.45833 12.0208 5.375 11.8158 5.375 11.5725C5.375 11.3292 5.45833 11.1242 5.625 10.9575L9.375 7.2075C9.47233 7.11017 9.573 7.04083 9.677 6.9995C9.781 6.95817 9.88867 6.9375 10 6.9375C10.1113 6.9375 10.219 6.95817 10.323 6.9995C10.427 7.04083 10.5277 7.11017 10.625 7.2075L14.396 10.9785C14.5627 11.1452 14.6493 11.3432 14.656 11.5725C14.6633 11.8018 14.5767 12.0068 14.396 12.1875C14.2293 12.3542 14.0243 12.4375 13.781 12.4375C13.5383 12.4375 13.3337 12.3542 13.167 12.1875L10 9.0415L6.833 12.2075C6.66633 12.3742 6.46867 12.4575 6.24 12.4575C6.01067 12.4575 5.80567 12.3675 5.625 12.1875Z"
        fill="#212121"
      />
    </svg>
  );
}

export default React.memo(IconExpandLess);

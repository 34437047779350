import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ControlButton from 'editor/src/component/ControlButton';
import DropDown from 'editor/src/component/DropDown';
import IconBin from 'editor/src/component/Icon/IconBin';
import IconEdit from 'editor/src/component/Icon/IconEdit';
import IconMore from 'editor/src/component/Icon/IconMore';
import IconMoreActive from 'editor/src/component/Icon/IconMoreActive';

import styles from './index.module.scss';

interface Props {
  disabled: boolean;
  onEdit: () => void;
  onRemove: () => void;
  className: string;
}

function ButtonMore({ disabled, onEdit, onRemove, className }: Props) {
  const { t } = useTranslation();
  const [isVisible, setVisible] = useState(false);

  function toggleMenu(e: React.MouseEvent) {
    e.stopPropagation();
    setVisible(!isVisible);
  }

  function close() {
    setVisible(false);
  }

  function handleEditClick() {
    onEdit();
    setVisible(false);
  }

  function handleRemoveClick() {
    onRemove();
    setVisible(false);
  }

  return (
    <div className={cn(styles.ButtonMore, className, 'cy-size-more-button')}>
      <button className={cn(styles.more, { [styles.on]: isVisible })} onClick={toggleMenu}>
        {isVisible ? <IconMoreActive /> : <IconMore />}
      </button>
      <DropDown isVisible={isVisible} className={styles.dropDown} onClickOutside={close}>
        <div data-ignore-outside-click>
          <ControlButton className={cn(styles.button, 'cy-edit')} onClick={handleEditClick} stopPropagation>
            <div className={styles.editIcon}>
              <IconEdit />
            </div>
            {t('editor-edit')}
          </ControlButton>
          {!disabled && (
            <ControlButton className={cn(styles.button, 'cy-remove')} onClick={handleRemoveClick} stopPropagation>
              <IconBin />
              {t('editor-remove')}
            </ControlButton>
          )}
        </div>
      </DropDown>
    </div>
  );
}

export default React.memo(ButtonMore);

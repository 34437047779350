import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import updateConditionNameOperation from 'editor/src/store/design/operation/updateConditionNameOperation';
import { ConditionAddress } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import styles from './index.module.scss';

interface Props {
  spreadIndex: number;
  conditionId: string;
  conditionName: string;
}

export interface Ref {
  focus: () => void;
}

function ConditionNameInput({ spreadIndex, conditionId, conditionName }: Props, ref: React.Ref<Ref>) {
  const [name, setName] = useState(conditionName);
  const dispatch = useDispatch();

  const nameRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => ({ focus: () => nameRef.current?.select() }));

  useEffect(() => {
    setName(conditionName);
  }, [conditionName]);

  const onNameUpdateDispatch = useCallback(
    debounce((address: ConditionAddress, name: string) => {
      dispatch(updateConditionNameOperation(address, name));
    }, 300),
    [],
  );

  function onNameUpdate(event: React.ChangeEvent) {
    const name = (event.target as HTMLInputElement).value;
    setName(name);
    onNameUpdateDispatch({ spreadIndex, conditionId }, name);
  }

  function onNameUpdateNoDebounce(event: React.ChangeEvent) {
    const name = (event.target as HTMLInputElement).value;
    setName(name);
    dispatch(updateConditionNameOperation({ spreadIndex, conditionId }, name));
  }

  return (
    <input
      ref={nameRef}
      className={classNames(styles.ConditionNameInput, 'cy-condition-name-input')}
      type="text"
      value={name}
      onChange={onNameUpdate}
      onBlur={onNameUpdateNoDebounce}
    />
  );
}

export default React.memo(React.forwardRef(ConditionNameInput));

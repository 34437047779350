import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updateResizableLayoutAreaOperation from 'editor/src/store/editor/operation/resizableElement/updateResizableLayoutAreaOperation';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { Thunk } from 'editor/src/store/hooks';
import getAllMatchingVariants from 'editor/src/store/variants/helpers/getAllMatchingVariants';
import getAllMatchingVariations from 'editor/src/store/variants/helpers/getAllMatchingVariations';
import getIsProductResizable from 'editor/src/store/variants/selector/getIsProductResizable';
import getResizableProductForGivenVariant from 'editor/src/store/variants/selector/getResizableProductForGivenVariant';
import { toggleExternalOptionAction } from 'editor/src/store/variants/slice';
import { ExternalProductControl, ProductSizeOption } from 'editor/src/store/variants/types';

import checkForMissingTemplatesOperation from './checkForMissingTemplatesOperation';
import displaySelectedVariationOperation from './displaySelectedVariationOperation';
import sendSwitchProductOperation from './sendSwitchProductOperation';

const selectExternalOptionOperation =
  (productControl: ExternalProductControl, controlOption: ProductSizeOption): Thunk =>
  (dispatch, getState, { i18n }) => {
    const state = getState();
    const { variants: variantState } = state;
    const { product, configuration, selectedExternalOptions, replaceControlKeys } = variantState;
    const isProductResizable = getIsProductResizable(state);
    const resizableElement = getResizableProductForGivenVariant(state);

    const externalOptions = { ...selectedExternalOptions };

    if (configuration.singleSelection) {
      // replace option
      externalOptions[productControl.key] = [controlOption];
    } else {
      // toggle option
      const optionsValues = [...externalOptions[productControl.key]];
      externalOptions[productControl.key] = optionsValues;
      const index = optionsValues.findIndex((option) => option.value === controlOption.value);
      if (index === -1) {
        optionsValues.push(controlOption);
      } else {
        optionsValues.splice(index, 1);
      }
    }

    const matchingVariations = getAllMatchingVariations(
      product.productVariations,
      variantState.selectedMultiOptions,
      variantState.selectedSingleOptions,
      !!configuration.singleSelection,
      replaceControlKeys,
    );
    const matchingVariantInfos = getAllMatchingVariants(
      matchingVariations,
      externalOptions,
      product.externalProductControls,
      state.variants.selectedPageCount,
    );

    batch(() => {
      dispatch(saveUndoRedoStateOperation('Toggle external option'));
      dispatch(
        toggleExternalOptionAction({
          externalOptions,
          matchingVariantInfos,
          rootState: state,
          i18n,
        }),
      );

      if (configuration.singleSelection) {
        const variant = matchingVariantInfos[0];
        if (isProductResizable) {
          dispatch(sendSwitchProductOperation(variant));
        }

        if (resizableElement && variant.dimensions) {
          dispatch(updateResizableLayoutAreaOperation(resizableElement, variant.dimensions));
        }
      } else {
        dispatch(displaySelectedVariationOperation());
        dispatch(checkForMissingTemplatesOperation(matchingVariations));
      }
    });
  };

export default selectExternalOptionOperation;

import { useMemo } from 'react';

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import getStructureIndexesOfSelectedElements from 'editor/src/store/design/selector/getStructureIndexesOfSelectedElements';
import isPersonalizationLockIgnored from 'editor/src/store/editor/selector/isPersonalizationLockIgnored';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { useSelector } from 'editor/src/store/hooks';

import { MenuContext } from './type';

function useMenuContext(): MenuContext {
  const structureIndexes = useSelector(getStructureIndexesOfSelectedElements);
  const firstElement = useSelector((state) =>
    structureIndexes.length ? getMediaElement(state, structureIndexes[0]) : undefined,
  );
  const ignorePersonalizationLock = useSelector(isPersonalizationLockIgnored);
  const hostSettings = useSelector((state) => state.hostSettings);
  const plugins = useSelector((state) => state.plugins).list;
  const isSelectionLocked = !!(firstElement && firstElement.locked);
  const isPersonalizationLocked = !ignorePersonalizationLock && !!(firstElement && firstElement.personalizationLocked);
  const isElementEmpty = !!(firstElement && firstElement.type === 'image' && !firstElement.imageId);
  const imageType = useSelector(
    (state) => getImageById(state, firstElement?.type === 'image' ? firstElement.imageId : '')?.type,
  );

  return useMemo(
    () => ({
      isSelectionLocked,
      isPersonalizationLocked,
      isElementEmpty,
      hasSelection: structureIndexes.length > 0,
      hostSettings,
      structureIndexes,
      plugins,
      imageType,
      selectedElementType: firstElement?.type,
    }),
    [
      isSelectionLocked,
      isPersonalizationLocked,
      isElementEmpty,
      firstElement?.type,
      hostSettings,
      structureIndexes,
      plugins,
      imageType,
    ],
  );
}

export default useMenuContext;

import cn from 'classnames';
import React from 'react';

import { PluginIcon } from 'editor/src/store/plugins/selector/getPluginIconByLevel';

import SwitchButton from 'editor/src/component/SwitchButton';

import styles from './index.module.scss';

interface Props {
  title: React.ReactNode;
  on: boolean;
  disabled?: boolean;
  onSwitch: (on: boolean) => void;
  className?: string;
  PremiumIcon?: PluginIcon;
}

function SwitchControl({ title, on, disabled, onSwitch, className, PremiumIcon }: Props) {
  return (
    <div className={cn(styles.SwitchControl, className)}>
      <div className={styles.label}>{title}</div>
      {PremiumIcon && <PremiumIcon className="cy-premium-icon mr-1" />}
      <SwitchButton on={on} disabled={disabled} onSwitch={onSwitch} className={styles.switch} />
    </div>
  );
}

export default React.memo(SwitchControl);

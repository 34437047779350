import { StructureIndex } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import { RootState } from '../..';
import getElementAddressesIndexedByNames from '../util/getElementAddressesIndexedByNames';
import getElementWithDuplicatedName from '../util/getElementWithDuplicatedName';

import updateMediaElementOperation, { MediaUpdateActionName } from './updateMediaElementOperation';

const updateMediaElementNameOperation =
  (elementAddress: StructureIndex, name: string) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const elementNames = getElementAddressesIndexedByNames(state.design.designData!);
    const elementWithDuplicatedName = getElementWithDuplicatedName(elementNames, name, elementAddress);

    if (elementWithDuplicatedName) {
      return false;
    }

    dispatch(updateMediaElementOperation(elementAddress, { name }, MediaUpdateActionName.RENAMED));

    return true;
  };

export default updateMediaElementNameOperation;

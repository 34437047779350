import React from 'react';
import { useTranslation } from 'react-i18next';

import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useSelector } from 'editor/src/store/hooks';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconProducts from 'editor/src/component/Icon/IconProducts';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
}

function ProductTab({ pluginName, customTabInfo }: Props) {
  const { t } = useTranslation();
  const active = useSelector((state) => isSidebarTabActive(state, TAB_NAMES.PRODUCT));

  return (
    <Tab
      key={TAB_NAMES.PRODUCT}
      name={TAB_NAMES.PRODUCT}
      title={t('editor-product')}
      icon={<IconProducts active={active} />}
      pluginName={pluginName}
      customTabInfo={customTabInfo}
    />
  );
}

export default React.memo(ProductTab);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconUnlockedMove({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M23.6142 16.6667C24.0475 16.4881 24.5462 16.5868 24.8778 16.9168L27.1966 19.2245C27.6494 19.6751 27.6494 20.4057 27.1966 20.8563C26.7438 21.3069 26.0097 21.3069 25.5569 20.8563L25.2174 20.5183V24.6557H29.3748L29.0352 24.3178C28.5824 23.8672 28.5824 23.1366 29.0352 22.686C29.488 22.2354 30.2221 22.2354 30.6749 22.686L32.9937 24.9937C33.4465 25.4443 33.4465 26.1749 32.9937 26.6255L30.6749 28.9332C30.2221 29.3838 29.488 29.3838 29.0352 28.9332C28.5824 28.4826 28.5824 27.752 29.0352 27.3014L29.3748 26.9634H25.2174V31.1009L25.5569 30.7629C26.0097 30.3123 26.7438 30.3123 27.1966 30.7629C27.6494 31.2135 27.6494 31.9441 27.1966 32.3947L24.8778 34.7024C24.425 35.153 23.6909 35.153 23.2381 34.7024L20.9193 32.3947C20.4665 31.9441 20.4665 31.2135 20.9193 30.7629C21.372 30.3123 22.1061 30.3123 22.5589 30.7629L22.8985 31.1009V26.9634H18.7411L19.0807 27.3014C19.5334 27.752 19.5334 28.4826 19.0807 28.9332C18.6279 29.3838 17.8938 29.3838 17.441 28.9332L15.1222 26.6255C14.7906 26.2955 14.6914 25.7992 14.8708 25.368C15.0503 24.9368 15.473 24.6557 15.942 24.6557H22.8985V17.7327C22.8985 17.266 23.181 16.8453 23.6142 16.6667Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2029 5C11.3214 5 8.98547 7.32468 8.98547 10.1923V10.7692C8.98547 11.4065 9.50456 11.9231 10.1449 11.9231C10.7852 11.9231 11.3043 11.4065 11.3043 10.7692V10.1923C11.3043 8.59918 12.602 7.30769 14.2029 7.30769C15.8037 7.30769 17.1014 8.59918 17.1014 10.1923V13.3333H8.98547C7.70481 13.3333 6.66663 14.3665 6.66663 15.641V21.0256C6.66663 22.3001 7.70481 23.3333 8.98547 23.3333H19.4203C20.7009 23.3333 21.7391 22.3001 21.7391 21.0256V15.641C21.7391 14.3665 20.7009 13.3333 19.4203 13.3333V10.1923C19.4203 7.32468 17.0843 5 14.2029 5ZM8.98547 15.641V21.0256H19.4203V15.641H8.98547Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconUnlockedMove;

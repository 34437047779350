import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomTabInfo, TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconSettings from 'editor/src/component/Icon/IconSettings';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

interface Props {
  pluginName?: string;
  customTabInfo?: CustomTabInfo;
}

function SettingsTab({ pluginName, customTabInfo }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  if (isMobile) {
    return null;
  }
  return (
    <Tab
      key={TAB_NAMES.SETTINGS}
      name={TAB_NAMES.SETTINGS}
      title={t('editor-settings')}
      icon={<IconSettings />}
      pluginName={pluginName}
      customTabInfo={customTabInfo}
    />
  );
}

export default React.memo(SettingsTab);

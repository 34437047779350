import React from 'react';

import { MediaElement } from 'editor/src/store/design/types';
import { Warning } from 'editor/src/store/editorModules/warnings/types';

import IconChevronDown from 'editor/src/component/Icon/IconChevronDown';
import IconChevronUp from 'editor/src/component/Icon/IconChevronUp';
import IndicatorBadge from 'editor/src/component/IndicatorBadge';

import ElementIcon from './ElementIcon';

import styles from './index.module.scss';

interface Props {
  warning: Warning;
  title: string;
  isSelectable?: boolean;
  selected: boolean;
  subtitle?: string;
  element?: MediaElement;
  children?: React.ReactNode;
}

function getElementName(element: MediaElement) {
  if (element.type === 'text') {
    return element.extra.text.slice(0, 50);
  }
  return element.name;
}

function AbstractWarningItem({ children, warning, title, element, selected, isSelectable = true, subtitle }: Props) {
  return (
    <div className={styles.AbstractWarningItem}>
      <div className={styles.item}>
        {element && (
          <div className={styles.icon}>
            <ElementIcon element={element} />
          </div>
        )}
        <div className={styles.title}>{title}</div>
        <div className={styles.name}>
          {element && !subtitle && getElementName(element)}
          {!!subtitle && subtitle}
        </div>
        {element && <div className={styles.name}>{getElementName(element)}</div>}
        <IndicatorBadge level={warning.level} className={styles.warning} />
        {isSelectable && <div className={styles.chevron}>{selected ? <IconChevronUp /> : <IconChevronDown />}</div>}
      </div>
      {isSelectable && selected && <div className={styles.content}>{children}</div>}
    </div>
  );
}

export default AbstractWarningItem;

import { useMemo } from 'react';

import getSpreadGroups from 'editor/src/store/design/selector/getSpreadGroups';
import { useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';

import store from 'editor/src/store';

import { extractDisplayPages } from '../EditorArea/utils/displayPageUtils';

function useGetSpreadList() {
  const splitDisplayPerContent = useSelector((state) => getHostSetting(state, 'splitDisplayPerContent'));
  const spreadGroups = useSelector(getSpreadGroups);

  const designKey = useSelector((state) =>
    state.design.designData ? getDesignKeyFromDesign(state.design.designData) : '',
  );

  const spreadsList = useMemo(() => {
    const spreads = store.getState().design.designData?.spreads ?? [];

    return extractDisplayPages(spreads, splitDisplayPerContent, spreadGroups);
  }, [designKey, splitDisplayPerContent, spreadGroups]);

  return { spreadsList, designKey };
}

export default useGetSpreadList;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { CollectionFolderStatus } from 'editor/src/store/collections/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const refreshCollectionCacheOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const { collections } = getState();
  batch(() => {
    Object.keys(collections.folders)
      .filter((folderId) => collections.folders[folderId].status !== CollectionFolderStatus.Deleted)
      .forEach((folderId) => {
        sendPostMessage('advancedPersonalisation.getFolderContent', folderId);
      });
  });
};

export default refreshCollectionCacheOperation;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { addConditionToConditionAction } from 'editor/src/store/design/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import updateHiddenElementsOperation from './updateHiddenElementsOperation';

const addConditionToConditionOperation =
  (spreadIndex: number, conditionId: string, parent: { conditionId: string; optionId: string }) =>
  (dispatch: ThunkDispatch) => {
    batch(() => {
      dispatch(addConditionToConditionAction({ spreadIndex, conditionId, parent }));
      dispatch(updateHiddenElementsOperation());
    });
  };

export default addConditionToConditionOperation;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import setHideUsedPhotosOperation from 'editor/src/store/gallery/operation/setHideUsedPhotosOperation';
import getHideUsedPhotos from 'editor/src/store/gallery/selector/getHideUsedPhotos';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import noop from 'editor/src/util/noop';

import Checkbox from 'editor/src/component/Checkbox';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

function HideUsedCheckbox() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hideUsedPhotos = useSelector(getHideUsedPhotos);
  const isMobile = useIsMobile();

  const handleCheckboxChange = () => {
    dispatch(setHideUsedPhotosOperation(!hideUsedPhotos));
  };

  return (
    <div
      className={cn(styles.hideUsedCheckbox, 'cy-hide-used-checkbox', { [styles.mobile]: isMobile })}
      onClick={handleCheckboxChange}
    >
      <Checkbox on={hideUsedPhotos} onChange={noop} />
      <span className={styles.text}>{t('editor-hide-used-photos')}</span>
    </div>
  );
}

export default React.memo(HideUsedCheckbox);

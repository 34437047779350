import { DesignData, ElementAddressesIndexedByNames } from '../types';

function getElementAddressesIndexedByNames(designData: DesignData): ElementAddressesIndexedByNames {
  const elementNames: ElementAddressesIndexedByNames = {};

  designData?.spreads.forEach((spread, spreadIndex) => {
    spread.pages[0].groups.media?.forEach((element, elementIndex) => {
      if (elementNames[element.name]) {
        // eslint-disable-next-line no-console
        console.error(`Duplicate element name: ${element.name}`);
      }

      elementNames[element.name] = {
        spreadIndex,
        pageIndex: 0,
        elementIndex,
        uuid: element.uuid,
      };
    });
  });

  return elementNames;
}

export default getElementAddressesIndexedByNames;

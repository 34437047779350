import cn from 'classnames';
import React from 'react';

import { MediaElement, StructureIndex } from 'editor/src/store/design/types';

import IconGripDot from 'editor/src/component/Icon/IconGripDot';

import ElementNameWithPersonalizableSwitch from '../ElementNameWithPersonalizableSwitch';

import styles from './index.module.scss';

interface Props {
  element: MediaElement;
  address: StructureIndex;
  ImageElement?: JSX.Element;
  isBuilding?: boolean;
  label?: string;
  className?: string;
  isNameEditable?: boolean;
}

function ElementName({
  element,
  isBuilding,
  address,
  label,
  className,
  ImageElement = <IconGripDot />,
  isNameEditable = true,
}: Props) {
  if (isBuilding) {
    return (
      <ElementNameWithPersonalizableSwitch
        element={element}
        address={address}
        label={label}
        className={className}
        ImageElement={ImageElement}
        isNameEditable={isNameEditable}
      />
    );
  }

  return <div className={cn(styles.label, className, 'cy-element-name')}>{label || element?.name}</div>;
}

export default ElementName;

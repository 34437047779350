import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { setScenePlaceholderMockDataAction } from 'editor/src/store/mockup/slice';
import { MockupCategory, MockupProductCategory, MockupSize, SceneCategory } from 'editor/src/store/mockup/types';

import applyDefaultMockupSceneIfNoneAppliedOperation from './applyDefaultMockupSceneIfNoneAppliedOperation';

export interface Input {
  scenesCategories: SceneCategory[];
  sizes: MockupSize[];
  categories: MockupCategory[];
  productCategories: MockupProductCategory[];
}

const setScenePlaceholderMockupDataOperation =
  ({ scenesCategories, sizes, categories, productCategories }: Input) =>
  (dispatch: ThunkDispatch) => {
    batch(() => {
      dispatch(
        setScenePlaceholderMockDataAction({
          scenesCategories,
          sizes,
          categories,
          productCategories,
        }),
      );
      dispatch(applyDefaultMockupSceneIfNoneAppliedOperation('mockup-without-shadow'));
    });
  };

export default setScenePlaceholderMockupDataOperation;

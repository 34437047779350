import { shallowEqual } from 'react-redux';

import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import isSidebarTabEnabled from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabEnabled';
import { TabInfo } from 'editor/src/store/editorModules/sidebar/types';
import { useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginState } from 'editor/src/store/plugins/types';

function useTabState(name: TabInfo, pluginName: string | undefined) {
  const { showPremium, pluginDisabled } = useSelector((state) => {
    const plugin = pluginName && getPlugin(state, pluginName);
    if (!plugin) {
      return { showPremium: false, pluginDisabled: false };
    }

    return {
      showPremium:
        plugin.state === PluginState.DISABLED ||
        plugin.state === PluginState.NON_FUNCTIONAL ||
        plugin.state === PluginState.ENABLED,
      pluginDisabled: plugin.state === PluginState.DISABLED,
    };
  }, shallowEqual);

  const active = useSelector((state) => isSidebarTabActive(state, name));
  const enabled = useSelector((state) => isSidebarTabEnabled(state, name));
  return {
    pluginDisabled,
    active,
    enabled,
    showPremium,
  };
}

export default useTabState;

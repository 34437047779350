import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SizeGuide } from 'editor/src/store/editorModules/formats/types';

import SelectButtonGroup from 'editor/src/component/SelectButtonGroup';

import Table from './Table';

import styles from './index.module.scss';

interface Props {
  sizeGuide: SizeGuide;
}

function SizeGuideDetails({ sizeGuide }: Props) {
  const [system, setSystem] = useState('metrical');
  const { t } = useTranslation();

  const options = [
    { label: t('editor-metrical-cm'), value: 'metrical' },
    { label: t('editor-imperial-in'), value: 'imperial' },
  ];

  const onSystemChange = useCallback(
    (value: string) => {
      setSystem(value);
    },
    [sizeGuide],
  );

  const table = system === 'metrical' ? sizeGuide?.centimetersTable : sizeGuide?.inchesTable;

  return (
    <div className="cy-size-guide">
      {!!sizeGuide?.images && (
        <div className={styles.section}>
          {sizeGuide?.images?.map((image, i) => (
            <img key={i} className={styles.image} src={image.image} alt={image.title} />
          ))}
        </div>
      )}
      {!!sizeGuide?.description && (
        <div className={styles.section}>
          <div
            className={styles.description}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: sizeGuide.description }}
          />
        </div>
      )}
      {!!sizeGuide?.inchesTable && !!sizeGuide?.centimetersTable && (
        <div className={cn(styles.section, styles.padding)}>
          <SelectButtonGroup fullWidth items={options} onChange={onSystemChange} value={system} />
        </div>
      )}
      {table && (
        <div className={cn(styles.section, styles.padding)}>
          <Table data={table} rotate />
        </div>
      )}
    </div>
  );
}

export default React.memo(SizeGuideDetails);

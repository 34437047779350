import cn from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import styles from './index.module.scss';

function DigitizationColorGuidelines() {
  const { allowedColors, colorsInfo } = useSelector((state) => getHostSetting(state, 'colorPickerConfig'));
  const { maxColorsCount } = useSelector((state) => getHostSetting(state, 'digitizationRules'));
  const { t } = useTranslation();

  if (!colorsInfo || !allowedColors || !maxColorsCount) {
    return null;
  }

  return (
    <>
      <div>
        <Trans
          i18nKey="digitization-allowed-colors-tooltip-info"
          values={{ allowedColorsCount: allowedColors.length, maxColorsCount }}
          components={{
            br: <br />,
          }}
        />
      </div>
      <div className={styles.colorList}>
        {allowedColors?.map((color) => (
          <div key={color} className={cn('row-flex row-flex--middle', styles.colorItem)}>
            <div className={styles.colorTile} style={{ backgroundColor: color }} />
            <div>
              <div>{`${colorsInfo[color].madeira_ref} ${t(colorsInfo[color].name)}`}</div>
              <div className={styles.colorItemSubtitle}>{color.toUpperCase()}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default React.memo(DigitizationColorGuidelines);

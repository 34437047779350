import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import getNonPersonalizableMediaElements from 'editor/src/store/design/selector/getNonPersonalizableMediaElements';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { ConditionChild } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useSelector } from 'editor/src/store/hooks';

import store from 'editor/src/store';

import IconLayersLine from 'editor/src/component/Icon/IconLayersLine';
import IconLayersRectangle from 'editor/src/component/Icon/IconLayersRectangle';

import ImageLayer from './ImageLayer';
import ShapeLayer from './ShapeLayer';
import TextLayer from './TextLayer';

import styles from 'editor/src/component/DesktopSidebar/TabContents/PersonalizationTabContent/index.module.scss';

interface Props {
  personalizedElements: ConditionChild[] | undefined;
}
function NonPersonalizableElements({ personalizedElements }: Props) {
  const { t } = useTranslation();
  const state = store.getState();
  const mediaElements = useSelector((state) => {
    const spreadIndex = getCurrentSpreadIndex(state);
    return getNonPersonalizableMediaElements(state, spreadIndex, 0);
  }, shallowEqual);

  if (!mediaElements?.length) {
    return null;
  }

  return (
    <>
      <div className={cn('mb-1', 'mt-2', styles.personalizedLayerHeader)}>
        {t('Other layers')}
        {personalizedElements?.length === 0 && (
          <div className={cn('mt-1', 'mb-2', styles.personalizedLayerInfo)}>
            {t('Click the toggle to turn on personalization for your existing layers.')}
          </div>
        )}
      </div>
      {mediaElements
        .map((element) => {
          const address = getStructureIndexByElementUuidMemoized(state, element.uuid);
          if (!address) {
            return null;
          }
          switch (element.type) {
            case 'image':
              return <ImageLayer element={element} address={address} key={element.uuid} />;

            case 'text':
              return <TextLayer element={element} address={address} key={element.uuid} />;

            case 'line':
              return <ShapeLayer element={element} address={address} key={element.uuid} icon={<IconLayersLine />} />;
            case 'rectangle':
              return (
                <ShapeLayer element={element} address={address} key={element.uuid} icon={<IconLayersRectangle />} />
              );
            default:
              return null;
          }
        })
        .reverse()}
    </>
  );
}

export default React.memo(NonPersonalizableElements);

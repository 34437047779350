import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import getCurrentDpiLevels from 'editor/src/store/design/selector/getCurrentDpiLevels';
import { getMediaImageDPI } from 'editor/src/store/design/selector/getMediaElementDpi';
import getSpread from 'editor/src/store/design/selector/getSpread';
import getSpreadGroups from 'editor/src/store/design/selector/getSpreadGroups';
import { MediaElement } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { WarningLevel } from 'editor/src/store/editorModules/warnings/types';
import getDpiWarning from 'editor/src/store/editorModules/warnings/utils/getDpiWarning';
import getNotVisibleWarning from 'editor/src/store/editorModules/warnings/utils/getNotVisibleWarning';
import getOutOrRangeWarning from 'editor/src/store/editorModules/warnings/utils/getOutOrRangeWarning';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { GalleryImage, MimeType } from 'editor/src/store/gallery/types';
import { useSelector, useStore } from 'editor/src/store/hooks';
import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import { getSpreadDisplay } from 'editor/src/component/EditorArea/useSpreadDisplay';

export function canShowGoodDPI(galleryImage: GalleryImage | undefined) {
  return galleryImage?.type !== MimeType.Unknown && galleryImage?.type !== MimeType.PDF;
}

export function getDPIWarningTooltip(
  dpi: number | undefined,
  level: WarningLevel | undefined,
  t: TFunction<'translation', undefined>,
) {
  let levelLabel = '';
  switch (level) {
    case WarningLevel.High:
      levelLabel = t('editor-low');
      break;
    case WarningLevel.Medium:
      levelLabel = t('editor-medium');
      break;
    default:
      levelLabel = t('editor-good');
      break;
  }
  const dpiValue = dpi ? `${Math.round(dpi)} ` : '';
  return `${levelLabel} ${dpiValue}${t('editor-dpi')}`;
}

export function getDPIWarningLabel(dpi: number | undefined, t: TFunction<'translation', undefined>) {
  const dpiValue = dpi ? Math.round(dpi) : t('editor-good');
  return `${dpiValue} ${t('editor-dpi')}`;
}

function useElementWarnings(element: MediaElement | undefined) {
  const { t } = useTranslation();
  const galleryImage = useSelector((state) =>
    element?.type === 'image' ? getImageById(state, element.imageId) : undefined,
  );
  const store = useStore();
  const limits = useSelector(getCurrentDpiLevels);
  const spreadIndex = useSelector(getCurrentSpreadIndex);
  const enabledWarnings = useSelector((state) => state.hostSettings.enabledWarnings);
  const hiddenWarnings = useSelector((state) => state.hostSettings.hiddenWarnings);

  const { warnings, level, tooltipLabel, text } = useMemo(() => {
    const warnings = [];
    let level: WarningLevel | undefined;
    let tooltipLabel: string | undefined;
    let text: string | undefined;

    const state = store.getState();
    const spread = getSpread(state, spreadIndex);
    if (element && spread) {
      const dpiWarning =
        !hiddenWarnings.LowDPI &&
        enabledWarnings.LowDPI &&
        element.type === 'image' &&
        !element.sample &&
        galleryImage &&
        limits
          ? getDpiWarning(element, spreadIndex, galleryImage, limits)
          : undefined;
      if (dpiWarning) {
        warnings.push(dpiWarning);
        level = dpiWarning.level;
        tooltipLabel = isEmbedded
          ? t('Low quality image message')
          : getDPIWarningTooltip(dpiWarning.dpi, dpiWarning.level, t);
        text = isEmbedded ? t('Low quality image') : getDPIWarningLabel(dpiWarning.dpi, t);
      }

      const spreadGroups = getSpreadGroups(state);
      const spreadDisplay = getSpreadDisplay(spread, spreadIndex, spreadGroups);

      const outOfRangeWarning =
        !hiddenWarnings.OutOfRange && enabledWarnings.OutOfRange
          ? getOutOrRangeWarning(element, spreadIndex, spreadDisplay.spreadWidth, spreadDisplay.spreadHeight)
          : undefined;
      if (outOfRangeWarning) {
        warnings.push(outOfRangeWarning);
        level ??= WarningLevel.Medium;
        tooltipLabel ||= t('Element has incorrect dimensions');
      }

      const notVisibleWarning =
        !hiddenWarnings.NotVisible &&
        enabledWarnings.NotVisible &&
        getNotVisibleWarning(
          element,
          spreadIndex,
          spreadDisplay.spreadCoords,
          spreadDisplay.spreadWidth,
          spreadDisplay.spreadHeight,
        );
      if (notVisibleWarning) {
        warnings.push(notVisibleWarning);
        level ??= WarningLevel.Medium;
        tooltipLabel ||= t('Element outside of the print area');
      }

      if (warnings.length > 1) {
        tooltipLabel += ` (+${warnings.length - 1})`;
      } else if (
        enabledWarnings.LowDPI &&
        !hiddenWarnings.LowDPI &&
        warnings.length === 0 &&
        element.type === 'image' &&
        !element.sample &&
        limits &&
        galleryImage &&
        canShowGoodDPI(galleryImage)
      ) {
        const dpi = getMediaImageDPI(element, galleryImage);
        tooltipLabel = getDPIWarningTooltip(dpi, undefined, t);
        text = getDPIWarningLabel(dpi, t);
      }
    }

    return {
      warnings,
      level,
      tooltipLabel,
      text,
    };
  }, [element, galleryImage, limits, spreadIndex, enabledWarnings, hiddenWarnings]);

  return {
    warnings,
    level,
    tooltipLabel,
    text,
  };
}

export default useElementWarnings;

import React, { useEffect, useMemo, useState } from 'react';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import FabricLineComponent from 'editor/src/component/EditorArea/fabricComponents/FabricLineComponent';
import { SnapMatch } from 'editor/src/component/EditorArea/snapping/useCreateSnapController';
import useSnapController from 'editor/src/component/EditorArea/snapping/useSnapController';

import zIndex from './zIndex';

const STOKE_DASH_ARRAY_WHITE = [5, 5];

function GuideLines() {
  const fabricCanvas = useFabricCanvas();
  const snapController = useSnapController();
  const zoom = fabricCanvas.getZoom();
  const strokeDashArray = useMemo(() => STOKE_DASH_ARRAY_WHITE.map((n) => n / zoom), [zoom]);

  const [snapMatch, setSnapMatch] = useState<SnapMatch | undefined>();

  useEffect(() => {
    snapController.onSnapUpdate(setSnapMatch);
    return () => snapController.offSnapUpdate(setSnapMatch);
  }, [snapController]);

  if (!snapMatch) {
    return null;
  }

  return (
    <>
      {snapMatch.lines.map(({ type, from, to }, i) => (
        <FabricLineComponent
          key={i}
          x1={from.x}
          y1={from.y}
          x2={to.x}
          y2={to.y}
          evented={false}
          stroke={type === 'pointSnap' ? undefined : '#E500E5'}
          zIndex={zIndex.SNAP_LINE}
          strokeDashArray={type === 'canva' || type === 'bleed' ? undefined : strokeDashArray}
          strokeDashOffset={5}
          strokeWidth={1 / zoom}
          objectCaching={false}
        />
      ))}
    </>
  );
}

export default React.memo(GuideLines);

import { addConditionOptionAction } from 'editor/src/store/design/slice';
import { ConditionAddress } from 'editor/src/store/design/types';
import type { Thunk } from 'editor/src/store/hooks';

import { conditionOptionUuids } from 'editor/src/util/uuids';

const addConditionOptionOperation =
  (address: ConditionAddress): Thunk =>
  (dispatch, _, { i18n }) => {
    const optionId = conditionOptionUuids.generate();
    dispatch(addConditionOptionAction({ address, optionId, label: i18n.t('editor-default-option-label') }));
  };

export default addConditionOptionOperation;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { setVariantProductImageAction } from 'editor/src/store/mockup/slice';
import { MockupPreviewVariant } from 'editor/src/store/mockup/types';

import updatePlaceholderOperation from './updatePlaceholderOperation';

interface ProductPreview {
  preview: { url: string; width: number; height: number };
  variant: MockupPreviewVariant;
}

const setMockupProductPreviewOperation =
  ({ preview, variant }: ProductPreview) =>
  (dispatch: ThunkDispatch) => {
    batch(() => {
      // dispatch(setMockupPreviewAction(variant));
      dispatch(setVariantProductImageAction({ variant, image: preview }));

      if (!variant.scene) {
        dispatch(updatePlaceholderOperation());
      }
    });
  };

export default setMockupProductPreviewOperation;

import { updateConditionOptionLabelAction } from 'editor/src/store/design/slice';
import { ConditionAddress } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const updateConditionOptionLabelOperation =
  (address: ConditionAddress, optionId: string, label: string) => (dispatch: ThunkDispatch) => {
    dispatch(updateConditionOptionLabelAction({ address, optionId, label }));
  };

export default updateConditionOptionLabelOperation;

import React from 'react';
import { shallowEqual } from 'react-redux';

import { conditionGroupChildenKey } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useSelector } from 'editor/src/store/hooks';

import PersonalizationElements from './PersonalizationElements';

import styles from './index.module.scss';

function PersonalizationContent() {
  const { conditionGroup, spreadIndex } = useSelector((state) => {
    const spreadIndex = getCurrentSpreadIndex(state);
    return {
      spreadIndex,
      conditionGroup: state.design.designData?.spreads[spreadIndex]?.conditionGroup,
    };
  }, shallowEqual);

  if (!conditionGroup) {
    return null;
  }

  return (
    <PersonalizationElements
      spreadIndex={spreadIndex}
      conditionGroup={conditionGroup}
      elements={conditionGroup.children[conditionGroupChildenKey] || []}
      personalizationElementClassName={styles.personalisationElement}
    />
  );
}

export default React.memo(PersonalizationContent);

import React from 'react';

import { StructureIndex } from 'editor/src/store/design/types';

import IconAlignBottom from 'editor/src/component/Icon/IconAlignBottom';
import AlignActionButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignActionButton';

interface Props {
  structureIndexes: StructureIndex[];
}

function AlignBottomButton({ structureIndexes }: Props) {
  return (
    <AlignActionButton
      structureIndexes={structureIndexes}
      icon={<IconAlignBottom />}
      alignTo="bottom"
      keepTopDropdown
    />
  );
}

export default React.memo(AlignBottomButton);

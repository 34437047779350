import React, { useCallback } from 'react';

import { FormatMultiOptions, FormatOption } from 'editor/src/store/editorModules/formats/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import ColorOption from './ColorOption';
import ComplexOption from './ComplexOption';

import styles from './index.module.scss';

interface Props {
  selector: FormatMultiOptions;
  openDetails: (content: string) => void;
}

function FormatSelectorOptions({ selector, openDetails }: Props) {
  const selectOption = useCallback((callback: FormatOption['callback']) => {
    if (!callback) {
      return;
    }
    sendPostMessage(callback.type, callback.data);
  }, []);

  const display = selector.optionDisplay || 'wrap';

  if (selector.options.length === 0) {
    return null;
  }

  return (
    <div className={styles.options}>
      {selector.options.map((option, i) => {
        switch (selector.type) {
          case 'color': {
            return <ColorOption key={i} option={option} selectOption={selectOption} />;
          }
          case 'complex': {
            return (
              <ComplexOption
                key={i}
                option={option}
                display={display}
                selectOption={selectOption}
                openDetails={openDetails}
              />
            );
          }
          default: {
            // the old way, to be removed
            return (
              <React.Fragment key={i}>
                {!option.name && option.hexColor && <ColorOption option={option} selectOption={selectOption} />}
                {option.name && (
                  <ComplexOption
                    option={option}
                    display={display}
                    selectOption={selectOption}
                    openDetails={openDetails}
                  />
                )}
              </React.Fragment>
            );
          }
        }
      })}
    </div>
  );
}

export default React.memo(FormatSelectorOptions);

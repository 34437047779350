import React from 'react';
import { useTranslation } from 'react-i18next';

import { Condition } from 'editor/src/store/design/types';

import IconLinked from 'editor/src/component/Icon/IconLinked';

import Badge from './Badge';

interface Props {
  condition: Condition;
  optionName: string;
}

function LinkedBadge({ condition, optionName }: Props) {
  const { t } = useTranslation();
  return (
    <Badge
      tooltip={t('editor-linked-to-condition', {
        name: condition.name,
        option: optionName,
      })}
      className="cy-linked-badge"
    >
      <IconLinked />
    </Badge>
  );
}

export default React.memo(LinkedBadge);

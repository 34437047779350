import getRequestedVersion from 'editor/src/store/editorModules/addons/selector/getRequestedVersion';
import { setAddonsDataAction } from 'editor/src/store/editorModules/addons/slice';
import { AddonData } from 'editor/src/store/editorModules/addons/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import setRequestedVersionOperation from './setRequestedVersionOperation';

const setAddonsDataOperation =
  (data: AddonData, force = false) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const requestedVersion = getRequestedVersion(state);
    if (force || requestedVersion === data.resultsVersion || data.resultsVersion === 0) {
      if (data.resultsVersion === 0) {
        dispatch(setRequestedVersionOperation(0));
      }
      dispatch(setAddonsDataAction(data));
    }
  };

export default setAddonsDataOperation;

import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

function IconNoColor({ className }: Props) {
  return (
    <Icon className={cn(className, styles.iconNoColor, 'cy-icon-no-color')}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="url(#paint0_linear_2654_5132)" />
        <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="black" strokeOpacity="0.1" />
        <defs>
          <linearGradient
            id="paint0_linear_2654_5132"
            x1="3.75"
            y1="3.75"
            x2="20"
            y2="20"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF0000" stopOpacity="0" />
            <stop offset="0.483487" stopColor="#FF0000" stopOpacity="0" />
            <stop offset="0.487551" stopColor="#FF0000" />
            <stop offset="0.552798" stopColor="#FF0000" />
            <stop offset="0.557481" stopColor="#FF0000" stopOpacity="0" />
            <stop offset="1" stopColor="#FF0000" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </Icon>
  );
}

export default IconNoColor;

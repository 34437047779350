import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import getFormatsData from 'editor/src/store/editorModules/formats/selector/getFormatsData';
import { useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconFormats from 'editor/src/component/Icon/IconFormats';

import Details, { SelectorDetail } from './Details';
import TextDetails from './HTMLDetails';
import MirroringSelector from './MirroringSelector';
import MultiOptionsSelector from './MultiOptionsSelector';
import ProductSizeSelector from './ProductSizeSelector';
import SizeGuideDetails from './SizeGuideDetails';

function FormatsTabContent({ title, icon }: TabContentProps) {
  const selectors = useSelector(getFormatsData);
  const isMirroringEnabled = useSelector((state) => getHostSetting(state, 'enableMirroring'));
  const { t } = useTranslation();
  const [details, setDetails] = useState<SelectorDetail>();

  if (details) {
    let content;
    switch (details.type) {
      case 'size-guide':
        content = <SizeGuideDetails sizeGuide={details.data} />;
        break;
      default:
        content = <TextDetails title={details.title} content={details.content} />;
    }

    return <Details back={() => setDetails(undefined)}>{content}</Details>;
  }

  return (
    <>
      <TabContentHeader title={title || t('editor-formats')} icon={icon || <IconFormats />} />
      <ContentBlock scroll>
        {isMirroringEnabled && <MirroringSelector openDetails={setDetails} />}
        {selectors.map((selector) => {
          switch (selector.type) {
            case 'product-size':
              return <ProductSizeSelector key={selector.name} selector={selector} openDetails={setDetails} />;
            default:
              return <MultiOptionsSelector key={selector.name} selector={selector} openDetails={setDetails} />;
          }
        })}
      </ContentBlock>
    </>
  );
}

export default React.memo(FormatsTabContent);

import type { Thunk } from 'editor/src/store/hooks';
import { setSelectedSceneIdAction } from 'editor/src/store/mockup/slice';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import applySceneStructureOperation from './applySceneStructureOperation';

export const sceneFetching = new Set<string>();

const applyOrFetchSceneStructureOperation =
  (sceneId: string): Thunk =>
  (dispatch, getState) => {
    dispatch(setSelectedSceneIdAction(sceneId));
    const sceneStructure = getState().mockup.scenes.structure[sceneId];
    if (sceneStructure) {
      dispatch(applySceneStructureOperation(sceneStructure));
    } else if (!sceneFetching.has(sceneId)) {
      sceneFetching.add(sceneId);
      sendPostMessage('mockup.requestSceneStructure', { id: sceneId });
    }
  };

export default applyOrFetchSceneStructureOperation;

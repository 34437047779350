import React from 'react';

import { CollectionFolder } from 'editor/src/store/collections/types';
import { MediaImage } from 'editor/src/store/design/types';

import isWebPSupported from 'editor/src/util/isWebPSupported';

import ImageThumbnail from 'editor/src/component/PersonalizationContent/PersonalizationElements/ImageThumbnail';

import ColorThumbnail from './ColorThumbnail';

import styles from './index.module.scss';

interface Props {
  image: MediaImage;
  folder: CollectionFolder;
  selectAsset: (assetId: string) => void;
  display: 'thumbnail' | 'color';
}

function PersonalisationCollectionThumbnails({ image, folder, selectAsset, display }: Props) {
  return (
    <div className={styles.PersonalisationCollectionThumbnails}>
      {folder?.assets.map((asset) =>
        display === 'thumbnail' || !asset.color ? (
          <ImageThumbnail
            key={asset.id}
            id={asset.id}
            url={isWebPSupported ? asset.thumbnailUrl : asset.url}
            adjustments={image.adjustments}
            onSelect={selectAsset}
            isActive={asset.id === image.imageId}
          />
        ) : (
          <ColorThumbnail key={asset.id} asset={asset} onSelect={selectAsset} isActive={asset.id === image.imageId} />
        ),
      )}
    </div>
  );
}

export default React.memo(PersonalisationCollectionThumbnails);

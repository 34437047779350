import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import AddonsTabContent from './AddonsTabContent';
import AdjustmentsTabContent from './AdjustmentsTabContent';
import CalendarEventsTabContent from './CalendarEventsTabContent';
import EditImageTabContent from './EditImageTabContent';
import EffectsTabContent from './EffectsTabContent';
import FiltersTabContent from './FiltersTabContent';
import FoilLayersTabContent from './FoilLayersTabContent';
import FontColorsTabContent from './FontColorsTabContent';
import FontFamiliesTabContent from './FontFamiliesTabContent';
import FormatsTabContent from './FormatsTabContent';
import GalleryTabContent from './GalleryTabContent';
import GridDesignsTabContent from './GridDesignsTabContent';
import ImageColorTabContent from './ImageColorTabContent';
import ImageManipulationTabContent from './ImageManipulationTabContent';
import LayersTabContent from './LayersTabContent';
import LayoutsTabContent from './LayoutsTabContent';
import LineStrokeColorTabContent from './LineStrokeColorTabContent';
import MockupTabContent from './MockupTabContent';
import PageBackgroundColorPickerTabContent from './PageBackgroundColorPickerTabContent';
import PatternTabContent from './PatternTabContent';
import PersonalizationTabContent from './PersonalizationTabContent';
import PersonalizeLayersTabContent from './PersonalizeLayersTabContent';
import PlaceholderTabContent from './PlaceholderTabContent';
import ProductTabContent from './ProductTabContent';
import PropertiesTabContent from './PropertiesTabContent';
import RectangleFillColorTabContent from './RectangleFillColorTabContent';
import RectangleStrokeColorTabContent from './RectangleStrokeColorTabContent';
import SceneTabContent from './SceneTabContent';
import SettingsTabContent from './SettingsTabContent';
import ShadowColorPickerTabContent from './ShadowColorPickerTabContent';
import ShapesTabContent from './ShapesTabContent';
import ShutterstockTabContent from './ShutterstockTabContent';
import TemplatesTabContent from './TemplatesTabContent';
import TextOutlineColorPickerTabContent from './TextOutlineColorPickerTabContent';
import TextsTabContent from './TextsTabContent';
import ThreadColorTabContent from './ThreadColorTabContent';
import WarningTabContent from './WarningsTabContent';

import { TabContentProps } from './index';

const tabContents: { [t in TAB_NAMES]?: React.FC<TabContentProps> } = {
  [TAB_NAMES.PRODUCT]: ProductTabContent,
  [TAB_NAMES.GALLERY]: GalleryTabContent,
  [TAB_NAMES.SETTINGS]: SettingsTabContent,
  [TAB_NAMES.WARNINGS]: WarningTabContent,
  [TAB_NAMES.PROPERTIES]: PropertiesTabContent,
  [TAB_NAMES.EDIT_IMAGE]: EditImageTabContent,
  [TAB_NAMES.LAYERS]: LayersTabContent,
  [TAB_NAMES.FOIL_LAYERS]: FoilLayersTabContent,
  [TAB_NAMES.FONT_FAMILIES]: FontFamiliesTabContent,
  [TAB_NAMES.FONT_COLORS]: FontColorsTabContent,
  [TAB_NAMES.LAYOUTS]: LayoutsTabContent,
  [TAB_NAMES.ADDONS]: AddonsTabContent,
  [TAB_NAMES.TEXTS]: TextsTabContent,
  [TAB_NAMES.FORMATS]: FormatsTabContent,
  [TAB_NAMES.GRID_DESIGNS]: GridDesignsTabContent,
  [TAB_NAMES.FILTERS]: FiltersTabContent,
  [TAB_NAMES.IMAGE_MANIPULATION]: ImageManipulationTabContent,
  [TAB_NAMES.ADJUSTMENTS]: AdjustmentsTabContent,
  [TAB_NAMES.COLOR_PICKER]: PageBackgroundColorPickerTabContent,
  [TAB_NAMES.IMAGE_COLOR]: ImageColorTabContent,
  [TAB_NAMES.THREAD_COLOR]: ThreadColorTabContent,
  [TAB_NAMES.PATTERN]: PatternTabContent,
  [TAB_NAMES.SHAPES]: ShapesTabContent,
  [TAB_NAMES.EFFECTS]: EffectsTabContent,
  [TAB_NAMES.IMAGE_SHADOW]: EffectsTabContent,
  [TAB_NAMES.TEXT_OUTLINE_COLOR_PICKER]: TextOutlineColorPickerTabContent,
  [TAB_NAMES.SHADOW_COLOR_PICKER]: ShadowColorPickerTabContent,
  [TAB_NAMES.LINE_STROKE_COLOR]: LineStrokeColorTabContent,
  [TAB_NAMES.RECTANGLE_STROKE_COLOR]: RectangleStrokeColorTabContent,
  [TAB_NAMES.RECTANGLE_FILL_COLOR]: RectangleFillColorTabContent,
  [TAB_NAMES.SCENE]: SceneTabContent,
  [TAB_NAMES.PLACEHOLDER]: PlaceholderTabContent,
  [TAB_NAMES.MOCKUP]: MockupTabContent,
  [TAB_NAMES.PERSONALIZE]: PersonalizationTabContent,
  [TAB_NAMES.PERSONALIZE_LAYERS]: PersonalizeLayersTabContent,
  [TAB_NAMES.SHUTTERSTOCK]: ShutterstockTabContent,
  [TAB_NAMES.CALENDAR_EVENTS]: CalendarEventsTabContent,
  [TAB_NAMES.DESIGN_TEMPLATES]: TemplatesTabContent,
};

export default tabContents;

import cn from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import showPremiumPrompt from 'editor/src/store/plugins/selector/showPremiumPrompt';
import { PluginName } from 'editor/src/store/plugins/types';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import {
  useDispatch,
  useSelector as useLocalSelector,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store';
import requestDataOperation from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/operation/requestDataOperation';
import getActiveGroup from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getActiveGroup';
import getDataAvailable from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getDataAvailable';
import GelatoPrompt from 'editor/src/component/GelatoPrompt';

import ElementsList from './ElementsList';
import FilterAndSearch from './FiltersAndSearch';
import Header from './Header';

import styles from './index.module.scss';

interface Props extends TabContentProps {
  pluginName: string;
}

function ResultsTabContent({ title, icon, pluginName }: Props) {
  const dispatch = useDispatch();
  const plugin = useSelector((state) => getPlugin(state, pluginName));
  const dataAvailable = useLocalSelector(getDataAvailable);
  const activeGroup = useLocalSelector(getActiveGroup);
  const gelatoPrompt = plugin && showPremiumPrompt(plugin);

  useEffect(() => {
    if (!dataAvailable) {
      dispatch(requestDataOperation());
    }
  }, [dataAvailable]);

  const showFilters = useMemo(() => !activeGroup, [activeGroup]);

  return (
    <>
      <Header title={title || ''} icon={icon} activeGroup={activeGroup} />
      {gelatoPrompt && <GelatoPrompt className={styles.prompt} prompt={gelatoPrompt} pluginName={PluginName.Filters} />}
      {showFilters && (
        <ContentBlock>
          <FilterAndSearch className={`cy-search-${pluginName} mt-2`} />
        </ContentBlock>
      )}
      <ContentBlock className="px-0" fill noOverflow noBorder>
        {plugin && <ElementsList pluginState={plugin.state} pluginName={pluginName} />}
      </ContentBlock>
      <div className={cn('py-1 mt-2', styles.footer)}>
        <Trans
          i18nKey="getty-terms-text"
          components={{
            termLink: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label,jsx-a11y/anchor-is-valid
              <a className={styles.linkButton} href="https://www.gettyimages.com/eula" target="_top" />
            ),
          }}
        />
      </div>
    </>
  );
}

export default React.memo(ResultsTabContent);

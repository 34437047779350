/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
  active?: boolean;
}

function IconShapes({ className, active }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        {!active ? (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.85498 3.49751C7.70253 3.1926 7.39089 3 7.05 3C6.70911 3 6.39747 3.1926 6.24502 3.49751L3.09502 9.79751C2.95552 10.0765 2.97043 10.4078 3.13442 10.6732C3.2984 10.9385 3.58808 11.1 3.9 11.1H10.2C10.5119 11.1 10.8016 10.9385 10.9656 10.6732C11.1296 10.4078 11.1445 10.0765 11.005 9.79751L7.85498 3.49751ZM5.35623 9.3L7.05 5.91246L8.74377 9.3H5.35623Z"
              fill="#212121"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.8 3C13.3029 3 12.9 3.40294 12.9 3.9V10.2C12.9 10.6971 13.3029 11.1 13.8 11.1H20.1C20.5971 11.1 21 10.6971 21 10.2V3.9C21 3.40294 20.5971 3 20.1 3H13.8ZM14.7 9.3V4.8H19.2V9.3H14.7Z"
              fill="#212121"
            />
            <path
              d="M10.8364 14.4364C11.1879 14.0849 11.1879 13.5151 10.8364 13.1636C10.4849 12.8121 9.91508 12.8121 9.5636 13.1636L4.1636 18.5636C3.81213 18.9151 3.81213 19.4849 4.1636 19.8364C4.51508 20.1879 5.08492 20.1879 5.4364 19.8364L10.8364 14.4364Z"
              fill="#212121"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.9 16.95C12.9 14.7132 14.7132 12.9 16.95 12.9C19.1868 12.9 21 14.7132 21 16.95C21 19.1868 19.1868 21 16.95 21C14.7132 21 12.9 19.1868 12.9 16.95ZM16.95 14.7C15.7074 14.7 14.7 15.7074 14.7 16.95C14.7 18.1926 15.7074 19.2 16.95 19.2C18.1926 19.2 19.2 18.1926 19.2 16.95C19.2 15.7074 18.1926 14.7 16.95 14.7Z"
              fill="#212121"
            />
          </>
        ) : (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.35499 3.49751C8.20253 3.1926 7.89089 3 7.55 3C7.20911 3 6.89747 3.1926 6.74502 3.49751L3.59502 9.79751C3.45552 10.0765 3.47043 10.4078 3.63442 10.6732C3.7984 10.9385 4.08808 11.1 4.4 11.1H10.7C11.0119 11.1 11.3016 10.9385 11.4656 10.6732C11.6296 10.4078 11.6445 10.0765 11.505 9.79751L8.35499 3.49751Z"
              fill="#212121"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.3 3C13.8029 3 13.4 3.40294 13.4 3.9V10.2C13.4 10.6971 13.8029 11.1 14.3 11.1H20.6C21.0971 11.1 21.5 10.6971 21.5 10.2V3.9C21.5 3.40294 21.0971 3 20.6 3H14.3Z"
              fill="#212121"
            />
            <path
              d="M11.3364 14.4364C11.6879 14.0849 11.6879 13.5151 11.3364 13.1636C10.9849 12.8121 10.4151 12.8121 10.0636 13.1636L4.6636 18.5636C4.31213 18.9151 4.31213 19.4849 4.6636 19.8364C5.01508 20.1879 5.58492 20.1879 5.9364 19.8364L11.3364 14.4364Z"
              fill="#212121"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.4 16.95C13.4 14.7132 15.2132 12.9 17.45 12.9C19.6868 12.9 21.5 14.7132 21.5 16.95C21.5 19.1868 19.6868 21 17.45 21C15.2132 21 13.4 19.1868 13.4 16.95Z"
              fill="#212121"
            />
          </>
        )}
      </svg>
    </Icon>
  );
}

export default IconShapes;

import React, { useCallback } from 'react';
import { shallowEqual } from 'react-redux';

import updateMediaElementByUuidOperation from 'editor/src/store/design/operation/updateMediaElementByUuidOperation';
import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { MockupRole } from 'editor/src/store/mockup/types';

import { isSupportedImageTypeForShadow } from 'editor/src/component/DesktopSidebar/TabContents/EffectsTabContent/MediaShadow/ShadowControls/utils';
import IconProperties from 'editor/src/component/Icon/IconProperties';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state) => {
  if (!state.hostSettings.enableImageShadow) {
    return false;
  }
  const selectedElement = getSelectedMediaElementsObjects(state)[0];
  if (selectedElement?.type !== 'image') {
    return false;
  }

  if (selectedElement.role === MockupRole.Placeholder) {
    return true;
  }

  const galleryImage = state.gallery.images.find((image) => image.id === selectedElement.imageId);
  return !!(galleryImage && isSupportedImageTypeForShadow(galleryImage.type));
};

type Props = Pick<MenuItemProps, 'mode' | 'close' | 'isMobile'>;

function ButtonImageShadow({ mode, close, isMobile }: Props) {
  const dispatch = useDispatch();
  const tabName = TAB_NAMES.IMAGE_SHADOW;
  const isActive = useSelector((state) => isSidebarTabActive(state, tabName));

  const selectedElement = useSelector((state) => getSelectedMediaElementsObjects(state)?.[0], shallowEqual);
  const defaultShadow = useSelector((state) => state.editor.currentStyles.image.shadow);

  const onActivate = useCallback(() => {
    if (selectedElement?.type !== 'image') {
      return;
    }
    if (!selectedElement.shadow) {
      dispatch(
        updateMediaElementByUuidOperation(selectedElement.uuid, {
          shadow: defaultShadow,
        }),
      );
    }

    if (isMobile) {
      if (isActive) {
        dispatch(setSidebarActiveTabOperation(-1));
      } else {
        dispatch(setSidebarActiveTabByNameOperation(tabName));
      }
    } else {
      dispatch(setSidebarActiveTabByNameOperation(tabName));
      close?.();
    }
  }, [selectedElement, defaultShadow, isMobile, isActive]);

  return (
    <MenuButton
      Icon={IconProperties}
      on={isActive}
      mode={mode}
      handleClick={onActivate}
      i18nKey="editor-shadow"
      className="cy-button-image-shadow"
    />
  );
}

export default React.memo(ButtonImageShadow);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function ShopifyIcon({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3268 5.60776C16.3862 5.60776 16.4574 5.65692 16.4693 5.74294C16.4731 5.77077 16.6634 7.10373 16.919 8.89499L16.9225 8.91934C17.4572 12.6661 18.2744 18.3921 18.2744 18.4004L13.156 19.5432L3.71484 17.7122C3.71484 17.7122 4.92616 8.00408 4.97366 7.65999C5.03304 7.20531 5.04492 7.19302 5.50807 7.04555C5.53287 7.03699 5.62101 7.00908 5.76046 6.9649C6.02159 6.88219 6.46271 6.74246 7.0044 6.56629C7.32504 5.16537 8.34635 2.40039 10.484 2.40039C10.7571 2.40039 11.0896 2.56014 11.339 2.90423H11.4221C12.3366 2.90423 12.8591 3.71529 13.156 4.61237C13.2956 4.56794 13.4084 4.53103 13.4913 4.50393C13.5918 4.47107 13.6483 4.45262 13.6548 4.45262C13.726 4.42804 13.916 4.40346 14.011 4.50177C14.1179 4.60008 14.9967 5.50945 14.9967 5.50945C14.9967 5.50945 16.2674 5.60776 16.3268 5.60776ZM11.8378 5.0179C12.1228 4.91959 12.3959 4.83357 12.6335 4.75984C12.4316 4.12082 12.1228 3.56782 11.6478 3.49409C11.7665 3.85047 11.8378 4.29286 11.8378 4.83357V5.0179ZM11.054 3.61698C11.1846 3.96107 11.2678 4.44033 11.2678 5.10392V5.20223C10.97 5.30027 10.6607 5.3983 10.3442 5.4986C10.1053 5.57433 9.86227 5.65136 9.61704 5.73065C9.93769 4.46491 10.5433 3.85047 11.054 3.61698ZM10.6977 3.08855C10.6146 3.02711 10.5196 2.99024 10.4246 2.99024C8.89263 2.99024 8.0257 5.07934 7.66943 6.35737C7.86426 6.29401 8.0643 6.23065 8.26834 6.16602C8.49961 6.09277 8.73601 6.0179 8.97576 5.93955C9.28452 4.25599 10.0089 3.42035 10.6977 3.08855Z"
          fill="white"
        />
        <path
          d="M16.3268 5.59547C16.2674 5.59547 14.9967 5.49716 14.9967 5.49716C14.9967 5.49716 14.1179 4.58779 14.011 4.48948C13.9754 4.45261 13.9279 4.42804 13.8804 4.42804L13.1679 19.5432L18.2863 18.4004C18.2863 18.4004 16.493 5.82896 16.4812 5.74294C16.4574 5.65691 16.3862 5.60776 16.3268 5.59547Z"
          fill="white"
        />
        <path
          d="M11.4221 8.52022L10.7927 10.4618C10.7927 10.4618 10.2346 10.1546 9.55767 10.1546C8.56011 10.1546 8.51261 10.8059 8.51261 10.9657C8.51261 11.8505 10.7452 12.1946 10.7452 14.2714C10.7452 15.9058 9.74768 16.9626 8.39385 16.9626C6.76689 16.9626 5.94747 15.9181 5.94747 15.9181L6.38687 14.4311C6.38687 14.4311 7.24191 15.193 7.95445 15.193C8.42948 15.193 8.61949 14.8121 8.61949 14.5294C8.61949 13.3743 6.79064 13.3251 6.79064 11.4204C6.79064 9.82283 7.89507 8.27444 10.1396 8.27444C10.9946 8.26215 11.4221 8.52022 11.4221 8.52022Z"
          fill="#95BF47"
        />
        <path
          d="M16.327 5.59566C16.2677 5.59566 14.997 5.49735 14.997 5.49735C14.997 5.49735 14.1182 4.58798 14.0113 4.48967C13.9757 4.4528 13.9282 4.42822 13.8807 4.42822L13.1681 19.5434L18.2865 18.4006C18.2865 18.4006 16.4933 5.82914 16.4814 5.74312C16.4577 5.6571 16.3864 5.60795 16.327 5.59566Z"
          fill="#E6E6E6"
        />
      </svg>
    </Icon>
  );
}

export default ShopifyIcon;

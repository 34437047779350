import { Coords, MediaElement, MediaLine } from 'editor/src/store/design/types';
import { NotVisibleWarning, WarningLevel, WarningType } from 'editor/src/store/editorModules/warnings/types';

import doSegmentsIntersect from 'editor/src/util/2d/doSegmentsIntersect';
import isPointInPolygon from 'editor/src/util/2d/isPointInPolygon';
import { Point } from 'editor/src/util/2d/types';
import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

function isPointInsideSpread(x: number, y: number, spread: Point, spreadWidth: number, spreadHeight: number) {
  return x >= spread.x && x <= spread.x + spreadWidth && y >= spread.y && y <= spread.y + spreadHeight;
}

function isSegmentIntersectWithSpread(
  point1: [number, number],
  point2: [number, number],
  spread: Point,
  spreadWidth: number,
  spreadHeight: number,
) {
  return (
    doSegmentsIntersect(
      point1[0],
      point1[1],
      point2[0],
      point2[1],
      spread.x,
      spread.y,
      spread.x + spreadWidth,
      spread.y,
    ) ||
    doSegmentsIntersect(
      point1[0],
      point1[1],
      point2[0],
      point2[1],
      spread.x + spreadWidth,
      spread.y,
      spread.x + spreadWidth,
      spread.y + spreadHeight,
    ) ||
    doSegmentsIntersect(
      point1[0],
      point1[1],
      point2[0],
      point2[1],
      spread.x + spreadWidth,
      spread.y + spreadHeight,
      spread.x,
      spread.y + spreadHeight,
    ) ||
    doSegmentsIntersect(
      point1[0],
      point1[1],
      point2[0],
      point2[1],
      spread.x,
      spread.y + spreadHeight,
      spread.x,
      spread.y,
    )
  );
}

export function isLineVisibleInSpread(
  element: MediaLine,
  spreadCoords: Coords,
  spreadWidth: number,
  spreadHeight: number,
) {
  // then we check if one point of the line is in the spread
  const coords = { x: spreadCoords.left, y: spreadCoords.top };
  if (
    isPointInsideSpread(element.x1, element.y1, coords, spreadWidth, spreadHeight) ||
    isPointInsideSpread(element.x2, element.y2, coords, spreadWidth, spreadHeight)
  ) {
    return true;
  }

  // then we check if the line intersect any side of the spread
  return isSegmentIntersectWithSpread(
    [element.x1, element.y1],
    [element.x2, element.y2],
    coords,
    spreadWidth,
    spreadHeight,
  );
}

export function isRectangleElementVisibleInSpread(
  element: Exclude<MediaElement, MediaLine>,
  spreadCoords: Coords,
  spreadWidth: number,
  spreadHeight: number,
) {
  return checkIfRectangleVisibleInSpread(
    {
      left: element.x,
      top: element.y,
      width: element.width,
      height: element.height,
      angle: element.r,
    },
    { x: spreadCoords.left, y: spreadCoords.top },
    spreadWidth,
    spreadHeight,
  );
}

export function checkIfRectangleVisibleInSpread(
  objectRect: ObjectRect,
  spreadCoords: Point,
  spreadWidth: number,
  spreadHeight: number,
) {
  const tl: [number, number] = [objectRect.left, objectRect.top];
  const tr = getPointPositionRotatedOnPoint(
    objectRect.left + objectRect.width,
    objectRect.top,
    objectRect.left,
    objectRect.top,
    objectRect.angle,
  );
  const br = getPointPositionRotatedOnPoint(
    objectRect.left + objectRect.width,
    objectRect.top + objectRect.height,
    objectRect.left,
    objectRect.top,
    objectRect.angle,
  );
  const bl = getPointPositionRotatedOnPoint(
    objectRect.left,
    objectRect.top + objectRect.height,
    objectRect.left,
    objectRect.top,
    objectRect.angle,
  );

  // then we check if one point of the rectangle is in the spread
  if (
    isPointInsideSpread(tl[0], tl[1], spreadCoords, spreadWidth, spreadHeight) ||
    isPointInsideSpread(tr[0], tr[1], spreadCoords, spreadWidth, spreadHeight) ||
    isPointInsideSpread(br[0], br[1], spreadCoords, spreadWidth, spreadHeight) ||
    isPointInsideSpread(bl[0], bl[1], spreadCoords, spreadWidth, spreadHeight)
  ) {
    return true;
  }

  // check if the spread is inside the rectangle
  const rectPolygon = [tl, tr, br, bl];
  if (
    isPointInPolygon([spreadCoords.x, spreadCoords.y], rectPolygon) ||
    isPointInPolygon([spreadCoords.x + spreadWidth, spreadCoords.y], rectPolygon) ||
    isPointInPolygon([spreadCoords.x + spreadWidth, spreadCoords.y + spreadHeight], rectPolygon) ||
    isPointInPolygon([spreadCoords.x, spreadCoords.y + spreadHeight], rectPolygon)
  ) {
    return true;
  }

  // then we check if the rectangle sides intersect any side of the spread
  return (
    isSegmentIntersectWithSpread(tl, tr, spreadCoords, spreadWidth, spreadHeight) ||
    isSegmentIntersectWithSpread(tr, br, spreadCoords, spreadWidth, spreadHeight) ||
    isSegmentIntersectWithSpread(br, bl, spreadCoords, spreadWidth, spreadHeight) ||
    isSegmentIntersectWithSpread(bl, tl, spreadCoords, spreadWidth, spreadHeight)
  );
}

function getNotVisibleWarning(
  element: MediaElement,
  spreadIndex: number,
  spreadCoords: Coords,
  spreadWidth: number,
  spreadHeight: number,
): NotVisibleWarning | undefined {
  if (element.type === 'line') {
    return isLineVisibleInSpread(element, spreadCoords, spreadWidth, spreadHeight)
      ? undefined
      : {
          type: WarningType.NotVisible,
          uuid: element.uuid,
          spreadIndex,
          level: WarningLevel.Medium,
        };
  }

  return isRectangleElementVisibleInSpread(element, spreadCoords, spreadWidth, spreadHeight)
    ? undefined
    : {
        type: WarningType.NotVisible,
        uuid: element.uuid,
        spreadIndex,
        level: WarningLevel.Medium,
      };
}

export default getNotVisibleWarning;

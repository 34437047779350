import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'editor/src/component/Button';

import styles from './index.module.scss';

interface Props {
  title: string;
  children?: React.ReactNode;
  closeMenu?: () => void;
}
function DrawerHeader({ title, children, closeMenu }: Props) {
  const { t } = useTranslation();

  return (
    <div className="row-flex row-flex--middle py-1 px-2 cy-drawer-header">
      <h1 className={styles.title}>{title}</h1>
      {children}
      <Button className={cn(styles.closeButton, 'cy-close-menu')} tertiary stopPropagation onClick={closeMenu}>
        {t('Done')}
      </Button>
    </div>
  );
}

export default React.memo(DrawerHeader);

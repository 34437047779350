import React, { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import setCurrentSpreadIndexOperation from 'editor/src/store/editor/operation/setCurrentSpreadIndexOperation';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { SpreadWarning, Warning, WarningLevel, WarningType } from 'editor/src/store/editorModules/warnings/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IndicatorBadge from 'editor/src/component/IndicatorBadge';

import styles from './index.module.scss';

function getWarningDescription(warning: Warning, maxColorCount: number, t: TFunction<'translation', undefined>) {
  switch (warning.type) {
    case WarningType.MaxColorCount:
      return t('product has too many thread colors', { color: maxColorCount });
    case WarningType.MaxStitchCount:
      return t('your image has exceeded limitation of stitches');
    default:
      return '';
  }
}

function Alert() {
  const { t } = useTranslation();
  const warnings = useSelector((state) => state.editorModules.warnings.list);
  const dispatch = useDispatch();

  const digitizationWarnings = useMemo(
    () =>
      warnings.filter(
        (warning) => warning.type === WarningType.MaxStitchCount || warning.type === WarningType.MaxColorCount,
      ) as SpreadWarning[],
    [warnings],
  );

  const maxColorCount = useSelector((state) => state.hostSettings.digitizationRules.maxColorsCount);

  if (!digitizationWarnings.length) {
    return null;
  }

  const level = digitizationWarnings.some((warning) => warning.level === WarningLevel.High)
    ? WarningLevel.High
    : WarningLevel.Medium;
  const firstWarning = digitizationWarnings[0];
  const content =
    digitizationWarnings.length > 1
      ? t('several-validation-issues')
      : getWarningDescription(firstWarning, maxColorCount, t);

  const handleClick = () =>
    batch(() => {
      dispatch(setCurrentSpreadIndexOperation(firstWarning.spreadIndex));
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.WARNINGS));
    });

  return (
    <div className={styles.Alert} onClick={handleClick}>
      <IndicatorBadge level={level} className={styles.warning} />
      <span className="ml-2">{content}</span>
    </div>
  );
}

export default React.memo(Alert);

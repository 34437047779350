import { fabric } from 'fabric';

import { ElementRect } from 'editor/src/store/design/types';

import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';

const getElementCenter = (elementDim: ElementRect) =>
  new fabric.Point(
    ...getPointPositionRotatedOnPoint(
      elementDim.x + elementDim.width / 2,
      elementDim.y + elementDim.height / 2,
      elementDim.x,
      elementDim.y,
      elementDim.r,
    ),
  );

export default getElementCenter;

import React from 'react';
import { useTranslation } from 'react-i18next';

import setSettingsValueOperation from 'editor/src/store/editor/operation/setSettingsValueOperation';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import Checkbox from 'editor/src/component/Checkbox';
import SettingsItem from 'editor/src/component/DesktopSidebar/TabContents/SettingsTabContent/SettingsItem';
import IconDottedLines from 'editor/src/component/Icon/IconDottedLines';

function ShowPerspectiveLinesCheckbox() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPerspectiveLinesEnabled = useSelector((store) =>
    getSettingsValue(store, SettingsProperty.showPerspectiveLines),
  );
  const allowShowPerspectiveLinesChange = useSelector((state) => getHostSetting(state, 'allowShowPerspectiveLines'));

  const handleChange = () => {
    dispatch(setSettingsValueOperation(SettingsProperty.showPerspectiveLines, !isPerspectiveLinesEnabled));
  };

  if (!allowShowPerspectiveLinesChange) {
    return null;
  }

  return (
    <SettingsItem descriptionTranslationKey="editor-settings-perspective-lines-description">
      <Checkbox
        on={isPerspectiveLinesEnabled}
        onChange={handleChange}
        label={t('editor-settings-perspective-lines')}
        className="cy-show-perspective-line-checkbox"
      />
      <IconDottedLines />
    </SettingsItem>
  );
}

export default ShowPerspectiveLinesCheckbox;

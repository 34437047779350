import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import { Dimensions } from 'editor/src/store/design/types';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  dimensions: Dimensions[];
  className?: string;
  spacing?: number;
}

const RATIO_PER_LINE = 3.5;

function HorizontalMasonry({ children, dimensions, className, spacing = 0 }: Props) {
  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!topRef.current) {
      return;
    }

    const fullWidth = topRef.current.clientWidth;
    let rowRatio = 0;
    let rowYPosition = 0;
    const cells = new Set<{
      element: HTMLElement;
      width: number;
      height: number;
    }>();

    // eslint-disable-next-line no-restricted-syntax
    const childNodes = topRef.current.children;
    for (let i = 0; i < childNodes.length; i += 1) {
      const child = childNodes[i];
      const childDimensions = dimensions[i];
      if (!(child instanceof HTMLElement) || !childDimensions) {
        continue;
      }

      rowRatio += childDimensions.width / childDimensions.height;
      cells.add({
        element: child,
        width: childDimensions.width,
        height: childDimensions.height,
      });

      if (rowRatio > RATIO_PER_LINE || i === childNodes.length - 1) {
        const rowHeight = (fullWidth - (cells.size - 1) * spacing) / Math.max(rowRatio, RATIO_PER_LINE);
        let leftPos = 0;
        // eslint-disable-next-line no-loop-func
        cells.forEach(({ element, width, height }) => {
          const newWidth = rowHeight * (width / height);
          element.style.top = `${rowYPosition}px`;
          element.style.left = `${leftPos}px`;
          element.style.width = `${newWidth}px`;
          element.style.height = `${rowHeight}px`;
          element.style.zIndex = '1';
          element.style.opacity = '1';
          leftPos += newWidth + spacing;
        });

        rowRatio = 0;
        rowYPosition += rowHeight + spacing;
        cells.clear();
      }
    }

    topRef.current.style.height = `${rowYPosition}px`;
  });

  return (
    <div className={cn(styles.HorizontalMasonry, className)} ref={topRef}>
      {children}
    </div>
  );
}

export default HorizontalMasonry;

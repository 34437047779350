const IS_SHOPIFY_PERSONALIZATION_OPTIONS_MODAL_CLOSE = 'isShopifyPersonalizationOptionsModalClose';

export const isShopifyPersonalizationOptionsModalClose = (): boolean =>
  !!window.localStorage.getItem(IS_SHOPIFY_PERSONALIZATION_OPTIONS_MODAL_CLOSE);

export const setIsShopifyPersonalizationOptionsModalClose = (close: boolean): void => {
  if (close) {
    window.localStorage.setItem(IS_SHOPIFY_PERSONALIZATION_OPTIONS_MODAL_CLOSE, '1');
  } else {
    window.localStorage.removeItem(IS_SHOPIFY_PERSONALIZATION_OPTIONS_MODAL_CLOSE);
  }
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { useSelector, useStore } from 'editor/src/store/hooks';
import getPlugins from 'editor/src/store/plugins/selector/getPlugins';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import LinkButton from 'editor/src/component/LinkButton';
import useMenuContext from 'editor/src/component/Menu/useMenuContext';
import { canShow as adjustmentsCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonAdjustments';
import { canShow as fillCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonFill';
import { canShow as filtersCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonFilters';
import { canShow as fitCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonFit';
import useResetEffectsModal from 'editor/src/component/TopMenuDesktop/buttons/ButtonImageEffectsDropDown/useResetEffectsModal';
import { canShow as canShowProperties } from 'editor/src/component/TopMenuDesktop/buttons/ButtonProperties';
import ResetEffectsModal from 'editor/src/component/TopMenuDesktop/buttons/ButtonResetImageEffects/ResetEffectsModal';
import { canShow as stretchToFitCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonStretchToFit';

import AdjustmentsContainer from './AdjustmentsContainer';
import FiltersCarousel from './FiltersCarousel';
import FittingsContainer from './FittingsContainer';
import ImageManipulationContainer from './ImageManipulationContainer';

import styles from './index.module.scss';

function EditImageTabContent() {
  const { t } = useTranslation();
  const store = useStore();
  const element = useSelector((state) => {
    const elementId = getSelectedImageUuid(state);
    return elementId !== undefined ? getMediaElementByUuid(state, elementId) : undefined;
  });
  const plugins = useSelector(getPlugins);
  const structureIndex = useSelector((store) => getStructureIndexByElementUuidMemoized(store, element?.uuid || -1));
  const menuContext = useMenuContext();
  const state = store.getState();

  const fillVisible = fillCanShow(state, menuContext);
  const fitVisible = fitCanShow(state, menuContext);
  const stretchVisible = stretchToFitCanShow(state, menuContext);
  const propertiesVisible = canShowProperties(state, menuContext);

  const { openConfirmModal, closeConfirmModal, resetEffects } = useResetEffectsModal(element, plugins);

  if (!element?.uuid || !structureIndex) {
    return null;
  }

  return (
    <>
      <TabContentHeader title={t('editor-edit-image')}>
        <div className={styles.resetContainer}>
          <LinkButton className="cy-reset-edit-image" onClick={openConfirmModal}>
            {t('editor-reset')}
          </LinkButton>
        </div>
      </TabContentHeader>
      <ContentBlock noBorder>
        <ImageManipulationContainer menuContext={menuContext} />
        <FittingsContainer
          fillVisible={fillVisible}
          fitVisible={fitVisible}
          stretchVisible={stretchVisible}
          propertiesVisible={propertiesVisible}
        />
        {filtersCanShow(state, menuContext) && <FiltersCarousel />}
        {adjustmentsCanShow(state, menuContext) && <AdjustmentsContainer />}
      </ContentBlock>
      <ResetEffectsModal onClose={closeConfirmModal} onAccept={() => resetEffects(true)} />
    </>
  );
}

export default EditImageTabContent;

/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  active?: boolean;
}

function IconStickers({ className, active }: Props) {
  return (
    <Icon className={cn(className, styles.iconStickers)}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        {!active ? (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0732 3H11.9268C10.1034 2.99997 8.63362 2.99995 7.47767 3.15537C6.27752 3.31672 5.26702 3.66191 4.46447 4.46447C3.66191 5.26702 3.31672 6.27752 3.15537 7.47767C2.99995 8.63362 2.99997 10.1034 3 11.9268V12.0732C2.99997 13.8966 2.99995 15.3664 3.15537 16.5223C3.31672 17.7225 3.66191 18.733 4.46447 19.5355C5.26702 20.3381 6.27752 20.6833 7.47767 20.8446C8.63362 21.0001 10.1034 21 11.9268 21H12C12.1351 21 12.2673 20.9727 12.3893 20.9211C12.447 20.8967 12.5024 20.8669 12.5548 20.832C12.588 20.8099 12.6199 20.7857 12.6505 20.7595C12.67 20.7429 12.6888 20.7254 12.7071 20.7071L20.7071 12.7071C20.8946 12.5196 21 12.2652 21 12V11.9268C21 10.1034 21.0001 8.63362 20.8446 7.47767C20.6833 6.27752 20.3381 5.26702 19.5355 4.46447C18.733 3.66191 17.7225 3.31672 16.5223 3.15537C15.3664 2.99995 13.8966 2.99997 12.0732 3ZM18.999 11.0008C18.9947 9.606 18.973 8.56599 18.8625 7.74416C18.7309 6.7658 18.4903 6.2477 18.1213 5.87868C17.7523 5.50966 17.2342 5.26907 16.2558 5.13753C15.2487 5.00213 13.9139 5 12 5C10.0861 5 8.7513 5.00213 7.74416 5.13753C6.7658 5.26907 6.2477 5.50966 5.87868 5.87868C5.50966 6.2477 5.26907 6.7658 5.13753 7.74416C5.00213 8.7513 5 10.0861 5 12C5 13.9139 5.00213 15.2487 5.13753 16.2558C5.26907 17.2342 5.50966 17.7523 5.87868 18.1213C6.2477 18.4903 6.7658 18.7309 7.74416 18.8625C8.56599 18.973 9.60602 18.9947 11.0008 18.999C11.0048 17.597 11.0272 16.4307 11.1554 15.4776C11.3167 14.2775 11.6619 13.267 12.4645 12.4644C13.267 11.6619 14.2775 11.3167 15.4777 11.1553C16.4307 11.0272 17.597 11.0047 18.999 11.0008ZM15.7442 13.1375C16.2565 13.0686 16.8537 13.0342 17.5687 13.0171L13.0171 17.5687C13.0343 16.8536 13.0686 16.2565 13.1375 15.7441C13.2691 14.7658 13.5097 14.2477 13.8787 13.8787C14.2477 13.5096 14.7658 13.269 15.7442 13.1375Z"
            fill="#212121"
          />
        ) : (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5732 3H12.4268C10.6034 2.99997 9.13362 2.99995 7.97767 3.15537C6.77752 3.31672 5.76702 3.66191 4.96447 4.46447C4.16191 5.26702 3.81672 6.27752 3.65537 7.47767C3.49995 8.63362 3.49997 10.1034 3.5 11.9268V12.0732C3.49997 13.8966 3.49995 15.3664 3.65537 16.5223C3.81672 17.7225 4.16191 18.733 4.96447 19.5355C5.76702 20.3381 6.77752 20.6833 7.97767 20.8446C9.13362 21.0001 10.6034 21 12.4268 21H12.5C12.6351 21 12.7673 20.9727 12.8893 20.9211C12.947 20.8967 13.0024 20.8669 13.0548 20.832C13.088 20.8099 13.1199 20.7857 13.1505 20.7595C13.17 20.7429 13.1888 20.7254 13.2071 20.7071L21.2071 12.7071C21.3946 12.5196 21.5 12.2652 21.5 12V11.9268C21.5 10.1034 21.5001 8.63362 21.3446 7.47767C21.1833 6.27752 20.8381 5.26702 20.0355 4.46447C19.233 3.66191 18.2225 3.31672 17.0223 3.15537C15.8664 2.99995 14.3966 2.99997 12.5732 3ZM16.2442 13.1375C16.7565 13.0686 17.3537 13.0342 18.0687 13.0171L13.5171 17.5687C13.5343 16.8536 13.5686 16.2565 13.6375 15.7441C13.7691 14.7658 14.0097 14.2477 14.3787 13.8787C14.7477 13.5096 15.2658 13.269 16.2442 13.1375Z"
            fill="#212121"
          />
        )}
      </svg>
    </Icon>
  );
}

export default IconStickers;

import { useCallback } from 'react';

import setSettingsValueOperation from 'editor/src/store/editor/operation/setSettingsValueOperation';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

interface PerspectiveLinesControlData {
  showPerspectiveLines: boolean;
  showControl: boolean;
  togglePerspectiveLines: () => void;
}

function useShowPerspectiveLinesControl(): PerspectiveLinesControlData {
  const dispatch = useDispatch();

  const showPerspectiveLines = useSelector((state) => getSettingsValue(state, SettingsProperty.showPerspectiveLines));
  const showControl = useSelector((state) => getHostSetting(state, 'allowShowPerspectiveLines'));
  const togglePerspectiveLines = useCallback(() => {
    dispatch(setSettingsValueOperation(SettingsProperty.showPerspectiveLines, !showPerspectiveLines));
  }, [showPerspectiveLines]);

  return {
    showPerspectiveLines,
    showControl,
    togglePerspectiveLines,
  };
}

export default useShowPerspectiveLinesControl;

export interface CollectionsState {
  folders: { [folderId: string]: CollectionFolder };
  topFolderId: string | undefined;
  assets: { [assetId: string]: CollectionAsset };
}

export interface CollectionFolderPreview {
  id: string;
  name: string;
  thumbnailUrl: string | undefined;
  assetCount: number;
  folderCount: number;
}

export enum CollectionFolderStatus {
  Created = 'created',
  Deleted = 'archived',
}

export interface CollectionFolder extends CollectionFolderPreview {
  parentFolderId: string | undefined;
  subFolders: CollectionFolderPreview[];
  assets: CollectionAsset[];
  status: CollectionFolderStatus;
}

export interface CollectionAsset {
  id: string;
  color: string;
  url: string;
  thumbnailUrl: string;
  width: number;
  height: number;
  name: string;
  mimeType: string;
}

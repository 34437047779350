import { useRef } from 'react';

function useLongPress(callback: () => void, ms: number, onEnd: () => void) {
  const wasLongPressRef = useRef(false);
  const timeoutHandleRef = useRef(-1);
  const positionRef = useRef({ x: 0, y: 0 });

  const isTouchEvent = (e: React.TouchEvent | React.MouseEvent): e is React.TouchEvent => {
    return (e as React.TouchEvent).touches !== undefined;
  };

  const startPress = (e: React.TouchEvent | React.MouseEvent) => {
    const position = { x: 0, y: 0 };

    if (isTouchEvent(e)) {
      position.x = e.touches[0].clientX;
      position.y = e.touches[0].clientY;
    } else {
      position.x = e.clientX;
      position.y = e.clientY;
    }

    positionRef.current = position;

    wasLongPressRef.current = false;
    window.clearTimeout(timeoutHandleRef.current);
    onEnd();

    timeoutHandleRef.current = window.setTimeout(() => {
      wasLongPressRef.current = true;
      callback();
    }, ms);
  };

  const endPress = (e: React.TouchEvent | React.MouseEvent) => {
    if (wasLongPressRef.current) {
      e.preventDefault();
    }
    window.clearTimeout(timeoutHandleRef.current);
  };

  const touchMove = (e: React.TouchEvent) => {
    const touch = (e.touches ? e.touches[0] : e) as Touch;
    const moveX = touch.clientX - positionRef.current.x;
    const moveY = touch.clientY - positionRef.current.y;
    // If movement is more than 10 pixels in any direction, cancel the long press
    if (Math.abs(moveX) > 10 || Math.abs(moveY) > 10) {
      startPress(e);
    }
  };

  // Unified handlers for both touch and mouse events
  const handleTouchStart = (e: React.TouchEvent) => startPress(e);
  const handleTouchEnd = (e: React.TouchEvent) => endPress(e);

  const handleMouseDown = (e: React.MouseEvent) => startPress(e);
  const handleMouseUp = (e: React.MouseEvent) => endPress(e);
  const handleTouchMove = (e: React.TouchEvent) => touchMove(e);

  return {
    onTouchStart: handleTouchStart,
    onTouchEnd: handleTouchEnd,
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onTouchMove: handleTouchMove,
  };
}

export default useLongPress;

import { DesignData } from 'editor/src/store/design/types';

function getImageIdsUsage(designData: DesignData | undefined) {
  const imageUsage = new Map<string, number>();

  designData?.spreads.forEach((spread) => {
    spread.pages[0].groups.media?.forEach((element) => {
      if (element.type === 'image') {
        imageUsage.set(element.imageId, (imageUsage.get(element.imageId) ?? 0) + 1);
      }
    });
  });

  return imageUsage;
}

export default getImageIdsUsage;

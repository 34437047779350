import { MultiOption, Product, SingleOptions } from 'editor/src/store/variants/types';

function getMultiOption(
  value: string,
  product: Product,
  selectedSingleOptions: SingleOptions,
  dependsOnSingleControls: boolean,
): MultiOption {
  const multiOption: MultiOption = { value, dependedOptions: [] };
  if (dependsOnSingleControls) {
    product.productControls.forEach((control) => {
      if (control.type === 'single') {
        multiOption.dependedOptions.push({
          key: control.key,
          value: selectedSingleOptions[control.key],
        });
      }
    });
  }

  return multiOption;
}

export default getMultiOption;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import FontFamiliesTabContent from 'editor/src/component/DesktopSidebar/TabContents/FontFamiliesTabContent';

import DrawerHeader from '../../DrawerHeader';

import styles from './index.module.scss';

interface Props {
  closeMenuCb?: () => void;
}
function FontFamiliesMobileMenu({ closeMenuCb }: Props) {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.MobileMenu, 'cy-tab-font-families')}>
      <DrawerHeader title={t('Font families')} closeMenu={closeMenuCb} />
      <ContentBlock fillFlex scroll>
        <div className={styles.ActionBarWrapper}>
          <FontFamiliesTabContent />
        </div>
      </ContentBlock>
    </div>
  );
}

export default React.memo(FontFamiliesMobileMenu);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconTextAlignLeft({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.6666 10C36.6666 10.9205 35.9204 11.6667 34.9999 11.6667H4.99994C4.07947 11.6667 3.33327 10.9205 3.33327 10C3.33327 9.07957 4.07947 8.33337 4.99994 8.33337H34.9999C35.9204 8.33337 36.6666 9.07957 36.6666 10Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.6666 16.6667C24.6666 17.5872 23.9204 18.3334 22.9999 18.3334H4.99992C4.07944 18.3334 3.33325 17.5872 3.33325 16.6667C3.33325 15.7462 4.07944 15 4.99992 15H22.9999C23.9204 15 24.6666 15.7462 24.6666 16.6667Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.6666 23.3334C30.6666 24.2538 29.9205 25 29 25H4.99998C4.07951 25 3.33331 24.2538 3.33331 23.3334C3.33331 22.4129 4.07951 21.6667 4.99998 21.6667H29C29.9205 21.6667 30.6666 22.4129 30.6666 23.3334Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6666 30C15.6666 30.9205 14.9205 31.6667 14 31.6667H4.99998C4.07951 31.6667 3.33331 30.9205 3.33331 30C3.33331 29.0796 4.07951 28.3334 4.99998 28.3334H14C14.9205 28.3334 15.6666 29.0796 15.6666 30Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconTextAlignLeft;

import { LayoutFrameArea, LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

const countElementsByTypeInLayoutSchema = (layoutSchema: LayoutSchema, frameType: 'text' | 'image' = 'image') => {
  let imageFramesCount = 0;
  layoutSchema.frameRows.forEach((row: LayoutFrameArea[]) => {
    row.forEach((frame: LayoutFrameArea) => {
      if (!frame.width) {
        return;
      }
      if ((!frame.type && frameType === 'image') || frame.type === frameType) {
        imageFramesCount += 1;
      }
    });
  });
  return imageFramesCount;
};

export default countElementsByTypeInLayoutSchema;

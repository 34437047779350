import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { setSelectedGroupAction } from 'editor/src/store/variants/slice';

import displaySelectedVariationOperation from './displaySelectedVariationOperation';

const setSelectedVariationGroupOperation = (groupKey: string) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(setSelectedGroupAction(groupKey));
    dispatch(displaySelectedVariationOperation());
  });
};

export default setSelectedVariationGroupOperation;

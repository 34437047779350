import getCurrentDpiLevels from 'editor/src/store/design/selector/getCurrentDpiLevels';
import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { DesignDimensions, DimensionRestrictions, Dimensions, Spread, MediaImage } from 'editor/src/store/design/types';
import getDimensionRestrictions from 'editor/src/store/design/util/getDimensionRestrictions';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { GalleryImage } from 'editor/src/store/gallery/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { DEFAULT_MEDIUM_DPI } from 'editor/src/store/hostSettings/slice';
import { RootState } from 'editor/src/store/index';
import getSizeOptionValue from 'editor/src/store/variants/helpers/getSizeOptionValue';
import resetControlWithOptionOperation from 'editor/src/store/variants/operation/resetControlWithOptionOperation';

import { calculateMm } from 'editor/src/util/calculateDPI';
import getBoxedValue from 'editor/src/util/getBoxedValue';

import { Size } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ManualControls/ProductSizeControl/ProductSize';
import { getPatternRepeat } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/useFilters';

export function getProductDimensionsBasedOnAsset(
  assetDimensions: Dimensions,
  dimensionRestrictions: DimensionRestrictions,
  bleeds: Dimensions,
  dpi: number,
  image: MediaImage,
): Dimensions {
  const patternScale = image.pattern
    ? getPatternRepeat(image.pattern.scale, image.pattern.scaleBase, image.width, image.height, image.pw, image.ph)
    : 1;
  const imageMmWidth = calculateMm(assetDimensions.width * patternScale, dpi);
  const imageMmHeight = calculateMm(assetDimensions.height * patternScale, dpi);

  const width = getBoxedValue(
    Math.floor(imageMmWidth) - bleeds.width,
    dimensionRestrictions.min_width,
    dimensionRestrictions.max_width,
  );
  const height = getBoxedValue(
    Math.floor(imageMmHeight) - bleeds.height,
    dimensionRestrictions.min_height,
    dimensionRestrictions.max_height,
  );

  if (imageMmWidth < imageMmHeight) {
    return {
      width,
      height: getBoxedValue(
        Math.floor((imageMmHeight * width) / imageMmWidth),
        dimensionRestrictions.min_height,
        dimensionRestrictions.max_height,
      ),
    };
  }

  return {
    height,
    width: getBoxedValue(
      Math.floor((imageMmWidth * height) / imageMmHeight),
      dimensionRestrictions.min_width,
      dimensionRestrictions.max_width,
    ),
  };
}

export function getBleedDimensions(spread: Spread, designDimensions: DesignDimensions): Dimensions {
  return {
    width: Math.abs(getSpreadWidthFromSpread(spread) - designDimensions.width),
    height: Math.abs(getSpreadHeightFromSpread(spread) - designDimensions.height),
  };
}

export const getMaxPossibleImageSize = (image: GalleryImage, designDataDimensions: DesignDimensions): Size => {
  const aspectRatio = image.width / image.height;
  let width = designDataDimensions.max_surface
    ? Math.sqrt(designDataDimensions.max_surface * aspectRatio)
    : designDataDimensions.max_width || 0;
  let height = designDataDimensions.max_surface
    ? Math.sqrt(designDataDimensions.max_surface / aspectRatio)
    : designDataDimensions.max_height || 0;

  if (designDataDimensions.max_height < height) {
    const scaleRate = designDataDimensions.max_height / height;
    width *= scaleRate;
    height = designDataDimensions.max_height;
  }

  return {
    width: Math.floor(width),
    height: Math.floor(height),
  };
};

const checkToResizeProductOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const { dimensions: designDataDimensions } = state.design.designData || {};
  if (!state.hostSettings.resizeProductBasedOnFirstElement || !state.design.designData || !designDataDimensions) {
    return;
  }

  const spreadIndex = getCurrentSpreadIndex(state);
  const spread = state.design.designData.spreads[spreadIndex];

  const spreadMedia = spread.pages[0].groups.media;
  const firstElement = spreadMedia?.[0];
  if (spreadMedia?.length !== 1 || firstElement?.type !== 'image' || !firstElement.imageId) {
    return;
  }

  const image = getImageById(state, firstElement.imageId);
  if (!image) {
    return;
  }

  const sizeControl = state.variants.product.externalProductControls.find((control) => control.key === 'product-size');
  if (!sizeControl) {
    return;
  }

  const bleeds = getBleedDimensions(spread, designDataDimensions);
  const dpiLevels = getCurrentDpiLevels(state);
  const size = getMaxPossibleImageSize(image, designDataDimensions);
  const dimensionRestrictions = getDimensionRestrictions(designDataDimensions, size);
  const dimensions = getProductDimensionsBasedOnAsset(
    image,
    dimensionRestrictions,
    bleeds,
    dpiLevels?.medium ?? DEFAULT_MEDIUM_DPI,
    firstElement,
  );

  dispatch(
    resetControlWithOptionOperation(sizeControl, {
      width: dimensions.width,
      height: dimensions.height,
      value: getSizeOptionValue(dimensions.width, dimensions.height),
    }),
  );
};

export default checkToResizeProductOperation;

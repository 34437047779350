import cn from 'classnames';
import React from 'react';

import IconTextAlignCenter from 'editor/src/component/Icon/IconTextAlignCenter';
import IconTextAlignLeft from 'editor/src/component/Icon/IconTextAlignLeft';
import IconTextAlignRight from 'editor/src/component/Icon/IconTextAlignRight';

import styles from './index.module.scss';

interface Props {
  className?: string;
  align: string;
}

function TextAlignIcon({ align, className }: Props) {
  switch (align) {
    case 'left':
      return <IconTextAlignLeft className={cn(className, styles.icon)} />;
    case 'right':
      return <IconTextAlignRight className={cn(className, styles.icon)} />;
    case 'center':
      return <IconTextAlignCenter className={cn(className, styles.icon)} />;
    default:
  }
  return null;
}

export default React.memo(TextAlignIcon);

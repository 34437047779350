import { SpreadGroup } from 'editor/src/store/design/types';

import groupLayouts from './groupLayouts';

const filterGroupLayouts = (spreadGroup: SpreadGroup) =>
  groupLayouts.filter(
    (layout) => layout.tag === spreadGroup.layoutTag && layout.layouts.length === spreadGroup.spreadIndexes.length,
  );

export default filterGroupLayouts;

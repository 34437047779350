import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import { MediaText } from 'editor/src/store/design/types';
import { FontStyles } from 'editor/src/store/fonts/types';
import defaultFontStyles from 'editor/src/store/fonts/utils/defaultFontStyles';
import { RootState } from 'editor/src/store/index';

const extractTextElementFontStyles = (el: MediaText): FontStyles => ({
  underline: !!el.extra.underline,
  linethrough: !!el.extra.linethrough,
  fontFamily: el.extra.fontFamily,
  fontSize: el.extra.fontSize,
  color: el.fill,
  lineHeight: el.extra.lineHeight ?? defaultFontStyles.lineHeight,
  textAlign: el.extra.textAlign ?? defaultFontStyles.textAlign,
  direction: el.extra.direction ?? defaultFontStyles.direction,
  angle: el.r,
  letterSpacing: el.extra.letterSpacing ?? defaultFontStyles.letterSpacing,
  stroke: el.extra.stroke ?? defaultFontStyles.stroke,
  shadow: el.extra.shadow ?? defaultFontStyles.shadow,
  wrapping: el.extra.wrapping ?? defaultFontStyles.wrapping,
  curve: el.extra.curve ?? defaultFontStyles.curve,
});

const getCurrentFontStylesProperty = <T extends keyof FontStyles>(state: RootState, key: T): FontStyles[T] => {
  const { selectedElementUuids } = state.editor;

  const textElement = getMediaElementByUuid(state, selectedElementUuids[0]);
  if (textElement?.type === 'text') {
    return extractTextElementFontStyles(textElement)[key];
  }

  return state.fonts.defaultFontStyles[key];
};

export default getCurrentFontStylesProperty;

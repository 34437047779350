import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

export interface Props {
  className?: string;
}

function SkeletonBlock({ className }: Props) {
  return <div className={cn(styles.SkeletonBlock, className)} />;
}

export default SkeletonBlock;

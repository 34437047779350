import isMobile from 'editor/src/store/app/selector/isMobile';
import getNextEmptyImageFrameOnPage from 'editor/src/store/editor/selector/getNextEmptyImageFrameOnPage';
import { setSelectedElementUuidsAction } from 'editor/src/store/editor/slice';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import removeAllSelectedMediaElementsOperation from './removeAllSelectedMediaElementsOperation';

const selectNextEmptyImageFrameOperation = (uuid: number) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();

  const nextElementUuid = getNextEmptyImageFrameOnPage(state, uuid);
  if (nextElementUuid < 0) {
    return;
  }
  dispatch(removeAllSelectedMediaElementsOperation());
  dispatch(setSelectedElementUuidsAction([nextElementUuid]));
  if (isMobile(state)) {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.REPLACE_IMAGE));
  }
};

export default selectNextEmptyImageFrameOperation;

import cn from 'classnames';
import React, { useCallback } from 'react';

import getPageBackgroundColor from 'editor/src/store/design/selector/getPageBackgroundColor';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import isChangeBackgroundAllowed from 'editor/src/store/editor/selector/isChangeBackgroundAllowed';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useBrowserColor from 'editor/src/util/useBrowserColor';

import ColorTileControl from 'editor/src/component/ColorPicker/ColorTileControl';
import PageBackgroundColorPickerTabContent from 'editor/src/component/DesktopSidebar/TabContents/PageBackgroundColorPickerTabContent';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';
import useMobileMenu from 'editor/src/component/useMobileMenu';

import styles from './index.module.scss';

type Props = Pick<MenuItemProps, 'close' | 'isMobile'>;

export const canShow: CanShow = (state, { hasSelection }) => !hasSelection && isChangeBackgroundAllowed(state);

function ButtonBackgroundColor({ isMobile, close }: Props) {
  const dispatch = useDispatch();
  const currentSpreadIndex = useSelector((state) => getCurrentSpreadIndex(state));
  const currentColor = useSelector((state) => getPageBackgroundColor(state, currentSpreadIndex));
  const colorMenu = useMobileMenu(false, true, true);
  const color = useBrowserColor(currentColor);

  const handleClick = useCallback(() => {
    if (isMobile) {
      colorMenu.toggle();
    } else {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.COLOR_PICKER));
      close?.();
    }
  }, [colorMenu.close, colorMenu.open, isMobile, close]);

  return (
    <div className={cn('cy-button-background-color', styles.menuItem)}>
      <ColorTileControl onClick={handleClick} isActive color={color ?? '#FFFFFF'} />
      {colorMenu.render(<PageBackgroundColorPickerTabContent closeMenuCb={colorMenu.close} />)}
    </div>
  );
}

export default React.memo(ButtonBackgroundColor);

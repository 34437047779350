import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import defineLayoutSchema from '../../test/defineLayoutSchema';

const INTERNAL_PADDING = 2;
const EXTERNAL_PADDING = 3;

const layoutSchemasPhoneCases: LayoutSchema[] = [
  defineLayoutSchema('pc_i1_1', 1, 1, 10, 0, 0, 0, [[{ width: 1, height: 1 }]], true),
  defineLayoutSchema('pc_i1_2', 1, 1, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [[{ width: 1, height: 1 }]], false),
  defineLayoutSchema(
    'pc_i2_1',
    2,
    1,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i2_2',
    1,
    2,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [[{ width: 1, height: 1 }], [{ width: 1, height: 1 }]],
    false,
  ),
  defineLayoutSchema(
    'pc_i3_1',
    2,
    2,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [{ width: 2, height: 1 }],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i3_2',
    2,
    2,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 2 },
        { width: 1, height: 1 },
      ],
      [{}, { width: 1, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i3_3',
    2,
    2,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 2 },
      ],
      [{ width: 1, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i3_4',
    2,
    2,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [{ width: 2, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i4_1',
    2,
    2,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i4_2',
    2,
    3,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [{ width: 2, height: 1 }],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [{ width: 2, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i4_3',
    3,
    2,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 2 },
        { width: 1, height: 1 },
        { width: 1, height: 2 },
      ],
      [{}, { width: 1, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i5_1',
    2,
    3,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [{ width: 2, height: 1 }],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i5_2',
    3,
    2,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 2 },
        { width: 1, height: 1 },
      ],
      [{ width: 1, height: 1 }, {}, { width: 1, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i6_1',
    3,
    3,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 2, height: 1 },
      ],
      [
        { width: 2, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 2, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i6_2',
    3,
    3,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 2 },
        { width: 1, height: 1 },
      ],
      [{ width: 1, height: 2 }, {}, { width: 1, height: 2 }],
      [{}, { width: 1, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i6_3',
    4,
    4,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 3, height: 2 },
      ],
      [{ width: 1, height: 1 }],
      [
        { width: 3, height: 2 },
        { width: 1, height: 1 },
      ],
      [{}, {}, {}, { width: 1, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i6_4',
    2,
    3,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i6_5',
    3,
    2,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i6_6',
    5,
    4,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 2, height: 1 },
        { width: 3, height: 2 },
      ],
      [{ width: 2, height: 1 }],
      [
        { width: 3, height: 2 },
        { width: 2, height: 1 },
      ],
      [{}, {}, {}, { width: 2, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i6_7',
    4,
    5,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 2, height: 3 },
        { width: 1, height: 2 },
        { width: 1, height: 2 },
      ],
      [],
      [{}, {}, { width: 2, height: 3 }],
      [
        { width: 1, height: 2 },
        { width: 1, height: 2 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i7_1',
    3,
    5,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [{ width: 3, height: 3 }],
      [],
      [],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i7_2',
    5,
    3,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 3, height: 3 },
        { width: 1, height: 1 },
      ],
      [{ width: 1, height: 1 }, {}, {}, {}, { width: 1, height: 1 }],
      [{ width: 1, height: 1 }, {}, {}, {}, { width: 1, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i8_1',
    3,
    4,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 2, height: 1 },
      ],
      [
        { width: 2, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 2, height: 1 },
      ],
      [
        { width: 2, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i8_2',
    4,
    3,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 2 },
        { width: 1, height: 1 },
        { width: 1, height: 2 },
        { width: 1, height: 1 },
      ],
      [{}, { width: 1, height: 2 }, {}, { width: 1, height: 2 }],
      [{ width: 1, height: 1 }, {}, { width: 1, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i8_3',
    4,
    4,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 2 },
        { width: 1, height: 1 },
        { width: 2, height: 1 },
      ],
      [{}, { width: 2, height: 2 }, { width: 1, height: 3 }],
      [{ width: 1, height: 1 }, {}, {}, {}],
      [
        { width: 2, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),

  defineLayoutSchema(
    'pc_i9_1',
    3,
    3,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i9_2',
    3,
    6,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 2, height: 2 },
      ],
      [{ width: 1, height: 1 }],
      [
        { width: 2, height: 2 },
        { width: 1, height: 1 },
      ],
      [{}, {}, { width: 1, height: 1 }],
      [
        { width: 1, height: 1 },
        { width: 2, height: 2 },
      ],
      [{ width: 1, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i9_3',
    6,
    3,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 2, height: 2 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 2, height: 2 },
      ],
      [{}, {}, { width: 2, height: 2 }],
      [{ width: 1, height: 1 }, { width: 1, height: 1 }, {}, {}, { width: 1, height: 1 }, { width: 1, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i12_1',
    3,
    4,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i12_2',
    4,
    3,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i13_1',
    4,
    4,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 2, height: 2 },
        { width: 1, height: 1 },
      ],
      [{ width: 1, height: 1 }, {}, {}, { width: 1, height: 1 }],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i15_1',
    4,
    6,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 2, height: 2 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 2, height: 2 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [{}, {}, { width: 1, height: 1 }, { width: 1, height: 1 }],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 2, height: 2 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i15_2',
    6,
    4,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 2, height: 2 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 2, height: 2 },
      ],
      [{}, {}, { width: 1, height: 1 }, { width: 1, height: 1 }],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 2, height: 2 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [{ width: 1, height: 1 }, { width: 1, height: 1 }, {}, {}, { width: 1, height: 1 }, { width: 1, height: 1 }],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i16_1',
    4,
    4,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i24_1',
    4,
    6,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
  defineLayoutSchema(
    'pc_i24_2',
    6,
    4,
    10,
    INTERNAL_PADDING,
    EXTERNAL_PADDING,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    false,
  ),
];

export default layoutSchemasPhoneCases;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { Thunk } from 'editor/src/store/hooks';
import getAllMatchingVariants from 'editor/src/store/variants/helpers/getAllMatchingVariants';
import getAllMatchingVariations from 'editor/src/store/variants/helpers/getAllMatchingVariations';
import { resetControlWithOptionAction, toggleExternalOptionAction } from 'editor/src/store/variants/slice';
import { ExternalProductControl, ProductSizeOption } from 'editor/src/store/variants/types';

import checkForMissingTemplatesOperation from './checkForMissingTemplatesOperation';
import displaySelectedVariationOperation from './displaySelectedVariationOperation';
import sendSwitchProductOperation from './sendSwitchProductOperation';

const resetControlWithOptionOperation =
  (productControl: ExternalProductControl, option: ProductSizeOption): Thunk =>
  (dispatch, getState, { i18n }) => {
    batch(() => {
      const state = getState();
      const variantState = state.variants;
      const { product, configuration, selectedExternalOptions, replaceControlKeys } = variantState;

      const externalOptions = { ...selectedExternalOptions };
      externalOptions[productControl.key] = [option];

      const matchingVariations = getAllMatchingVariations(
        product.productVariations,
        variantState.selectedMultiOptions,
        variantState.selectedSingleOptions,
        !!configuration.singleSelection,
        replaceControlKeys,
      );
      const matchingVariantInfos = getAllMatchingVariants(
        matchingVariations,
        externalOptions,
        product.externalProductControls,
        state.variants.selectedPageCount,
      );

      batch(() => {
        dispatch(saveUndoRedoStateOperation('reset external option'));
        dispatch(
          toggleExternalOptionAction({
            externalOptions,
            matchingVariantInfos,
            rootState: state,
            i18n,
          }),
        );
        dispatch(
          resetControlWithOptionAction({
            controlKey: productControl.key,
            option,
          }),
        );
        if (configuration.singleSelection) {
          dispatch(sendSwitchProductOperation(matchingVariantInfos[0]));
        } else {
          dispatch(displaySelectedVariationOperation());
          dispatch(checkForMissingTemplatesOperation(matchingVariations));
        }
      });
    });
  };

export default resetControlWithOptionOperation;

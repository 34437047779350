import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  label: string;
  on: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  type?: string;
  className?: string;
}

function RadioButton({ label, on, onChange, disabled, type, className }: Props) {
  const handleClick = () => {
    if (!disabled) {
      onChange(!on);
    }
  };

  return (
    <div
      className={cn(styles.radio, className, {
        [styles.disabled]: disabled,
        [styles.radioButtonElement]: type === 'button',
        'cy-disabled': disabled,
        [styles.active]: on,
      })}
      onClick={handleClick}
    >
      {label}
    </div>
  );
}

export default RadioButton;

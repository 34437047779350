/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import style from './index.module.scss';

interface Props {
  className?: string;
  size?: 'small' | 'normal';
}

function IconInfoFilled({ className, size = 'normal' }: Props) {
  return (
    <Icon className={cn(className, style[size])}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M12 17.9714C12.2833 17.9714 12.521 17.8754 12.713 17.6834C12.9043 17.4921 13 17.2548 13 16.9714V12.9464C13 12.6631 12.9043 12.4298 12.713 12.2464C12.521 12.0631 12.2833 11.9714 12 11.9714C11.7167 11.9714 11.4793 12.0671 11.288 12.2584C11.096 12.4504 11 12.6881 11 12.9714V16.9964C11 17.2798 11.096 17.5131 11.288 17.6964C11.4793 17.8798 11.7167 17.9714 12 17.9714ZM12 9.97144C12.2833 9.97144 12.521 9.87544 12.713 9.68344C12.9043 9.4921 13 9.25477 13 8.97144C13 8.6881 12.9043 8.45044 12.713 8.25844C12.521 8.0671 12.2833 7.97144 12 7.97144C11.7167 7.97144 11.4793 8.0671 11.288 8.25844C11.096 8.45044 11 8.6881 11 8.97144C11 9.25477 11.096 9.4921 11.288 9.68344C11.4793 9.87544 11.7167 9.97144 12 9.97144ZM12 22.9714C10.6167 22.9714 9.31667 22.7088 8.1 22.1834C6.88333 21.6588 5.825 20.9464 4.925 20.0464C4.025 19.1464 3.31267 18.0881 2.788 16.8714C2.26267 15.6548 2 14.3548 2 12.9714C2 11.5881 2.26267 10.2881 2.788 9.07144C3.31267 7.85477 4.025 6.79644 4.925 5.89644C5.825 4.99644 6.88333 4.28377 8.1 3.75844C9.31667 3.23377 10.6167 2.97144 12 2.97144C13.3833 2.97144 14.6833 3.23377 15.9 3.75844C17.1167 4.28377 18.175 4.99644 19.075 5.89644C19.975 6.79644 20.6873 7.85477 21.212 9.07144C21.7373 10.2881 22 11.5881 22 12.9714C22 14.3548 21.7373 15.6548 21.212 16.8714C20.6873 18.0881 19.975 19.1464 19.075 20.0464C18.175 20.9464 17.1167 21.6588 15.9 22.1834C14.6833 22.7088 13.3833 22.9714 12 22.9714Z"
          fill="#007CB4"
        />
      </svg>
    </Icon>
  );
}

export default IconInfoFilled;

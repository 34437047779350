import cn from 'classnames';
import React, { useCallback, useRef, useState } from 'react';

import useOnClickOutside from 'editor/src/util/useOnClickOutside';

import { SortOption } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

import styles from './index.module.scss';

interface Props {
  className?: string;
  searchSort: SortOption;
  options: SortOption[];
  onChange: (sortValue: string) => void;
}

function SortButton({ className, onChange, searchSort, options }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [listOpened, setListOpened] = useState(false);
  useOnClickOutside(containerRef, () => setListOpened(false));

  const toggleDropdown = useCallback(() => {
    setListOpened((currentValue) => !currentValue);
  }, [listOpened]);

  const onSortChange = (value: string) => {
    if (value !== searchSort.value) {
      onChange(value);
      setListOpened(false);
    }
  };

  return (
    <div className={cn(className, styles.buttonContainer)} ref={containerRef}>
      <span
        className={cn('cy-button-sort', `cy-sort-value-${searchSort.value}`, styles.sortButton)}
        onClick={toggleDropdown}
      >
        {searchSort.label}
      </span>
      {listOpened && (
        <div className={styles.dropdownContent}>
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => onSortChange(option.value)}
              className={cn('p-2', `cy-sort-option-${option.value}`)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(SortButton);

import React from 'react';
import { useTranslation } from 'react-i18next';

import { MultiOptions, Product } from 'editor/src/store/variants/types';

import ColorDot from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/elements/ColorElement/ColorDot';
import Widget from 'editor/src/component/Widget';

import getUnavailableVariations from './getUnavailableVariations';

import styles from './index.module.scss';

export interface Props {
  options: MultiOptions;
  product: Product;
}

function UnavailableVariantsInfo({ product, options }: Props) {
  const unAvailableVariations = getUnavailableVariations(product, options);
  let totalUnavailableVariants = 0;
  const { t } = useTranslation();

  Object.keys(unAvailableVariations).forEach((key) => {
    totalUnavailableVariants += unAvailableVariations[key].length;
  });

  if (totalUnavailableVariants === 0) {
    return null;
  }
  const headerText = t('variants are unavailable', { unAvailableVariantCount: totalUnavailableVariants });
  const infoText = t("variants are unavailable and won't be published for purchase", {
    unAvailableVariantCount: totalUnavailableVariants,
  });

  return (
    <Widget headerText={headerText} className={styles.widget}>
      <>
        <div className={styles.info}>{infoText}</div>
        <div className={styles.unAvailableVariantsBlock}>
          {Object.keys(unAvailableVariations).map((key) => (
            <div key={key} className={styles.group}>
              <div className={styles.groupName}>{key}:</div>
              <div className={styles.groupValue}>
                {unAvailableVariations[key].map((value, index) => (
                  <ColorDot
                    key={index}
                    colorHex={value.colorHex}
                    texture={value.texture}
                    selected={false}
                    className={styles.colorDot}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </>
    </Widget>
  );
}

export default UnavailableVariantsInfo;

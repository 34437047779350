import { Area } from 'editor/src/store/design/operation/getNewElementDisplay';

const INITIAL_LINE_HEIGHT = 0;
const INITIAL_LINE_WIDTH_MULTIPLIER = 0.7;

function getNewLineDisplaySize(area: Area) {
  return {
    width: area.width * INITIAL_LINE_WIDTH_MULTIPLIER,
    height: INITIAL_LINE_HEIGHT,
  };
}

export default getNewLineDisplaySize;

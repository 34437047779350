import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconCheck({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
        <rect x="14.0208" y="3" width="2" height="12" transform="rotate(45 14.0208 3)" />
        <rect x="2" y="7.94971" width="2" height="7" transform="rotate(-45 2 7.94971)" />
      </svg>
    </Icon>
  );
}

export default IconCheck;

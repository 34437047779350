import React from 'react';

import { Coords, DesignDimensions } from 'editor/src/store/design/types';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useSelector } from 'editor/src/store/hooks';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import useForceUpdateOnViewportChange from 'editor/src/component/EditorArea/useForceUpdateOnViewportChange';
import getDelimiters from 'editor/src/component/EditorArea/utils/getDelimiters';

import RulersCss from './RulersCss';

interface Props {
  spreadHeight: number;
  spreadWidth: number;
  spreadCoords: Coords;
  dimensions: DesignDimensions;
}

const VERTICAL_RULER_STEP = 500; // in mm

function Rulers({ spreadCoords, spreadWidth, spreadHeight, dimensions }: Props) {
  const fabricCanvas = useFabricCanvas();

  const unit = useSelector((state) => getSettingsValue(state, SettingsProperty.units));
  const repeatDesign = useSelector((state) => state.hostSettings.repeatDesign);
  useForceUpdateOnViewportChange();

  const bleedWith = Math.abs(spreadWidth - dimensions.width);
  const delimiterWidth = dimensions.min_width + bleedWith;
  let maxWidth = spreadWidth;
  let offsetX = 0;
  if (repeatDesign) {
    maxWidth = spreadWidth * (repeatDesign.count * 2) + 1;
    offsetX = -delimiterWidth * repeatDesign.count;
  }
  const xDelimiters = getDelimiters(delimiterWidth, maxWidth);
  const yDelimiters = getDelimiters(VERTICAL_RULER_STEP, spreadHeight);

  return (
    <RulersCss
      spreadHeight={spreadHeight}
      spreadWidth={spreadWidth}
      spreadCoords={spreadCoords}
      xDelimiters={xDelimiters}
      yDelimiters={yDelimiters}
      unit={unit}
      zoom={fabricCanvas.getZoom()}
      dimensions={dimensions}
      offsetX={offsetX}
    />
  );
}

export default React.memo(Rulers);

import { PayloadAction } from '@reduxjs/toolkit';

import {
  DataState,
  ResultsData,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const setResultsReducer = (state: DataState, action: PayloadAction<ResultsData | undefined>) => {
  state.results = action.payload;
};

export default setResultsReducer;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconFlipY({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M14.0747 5C13.5052 5 12.9862 5.33052 12.7395 5.85022C12.4928 6.36992 12.5621 6.98679 12.9179 7.43704L18.8438 14.937C19.1249 15.2929 19.5506 15.5 20.0006 15.5C20.4507 15.5 20.8763 15.2929 21.1575 14.937L27.0834 7.43705C27.4392 6.98679 27.5085 6.36992 27.2618 5.85022C27.0151 5.33052 26.4961 5 25.9266 5L14.0747 5Z"
          fill="#212121"
        />
        <path
          d="M29.3123 21.5H31.8519C32.6701 21.5 33.3334 20.8284 33.3334 20C33.3334 19.1716 32.6701 18.5 31.8519 18.5H29.3123C28.4941 18.5 27.8308 19.1716 27.8308 20C27.8308 20.8284 28.4941 21.5 29.3123 21.5Z"
          fill="#212121"
        />
        <path
          d="M17.4604 21.5L22.5398 21.5C23.358 21.5 24.0212 20.8284 24.0212 20C24.0212 19.1716 23.358 18.5 22.5398 18.5L17.4604 18.5C16.6422 18.5 15.9789 19.1716 15.9789 20C15.9789 20.8284 16.6422 21.5 17.4604 21.5Z"
          fill="#212121"
        />
        <path
          d="M8.14823 21.5H10.6879C11.5061 21.5 12.1694 20.8284 12.1694 20C12.1694 19.1716 11.5061 18.5 10.6879 18.5L8.14823 18.5C7.33003 18.5 6.66675 19.1716 6.66675 20C6.66675 20.8284 7.33003 21.5 8.14823 21.5Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1569 25.063C20.8758 24.7071 20.4501 24.5 20.0001 24.5C19.55 24.5 19.1244 24.7071 18.8432 25.063L12.9173 32.563C12.5616 33.0132 12.4922 33.6301 12.7389 34.1498C12.9856 34.6695 13.5047 35 14.0742 35L25.926 35C26.4955 35 27.0146 34.6695 27.2613 34.1498C27.508 33.6301 27.4386 33.0132 27.0828 32.563L21.1569 25.063ZM20.0001 28.4012L22.8436 32L17.1566 32L20.0001 28.4012Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconFlipY;

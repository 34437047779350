import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress, MediaImage } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getCropRotationUpdates from 'editor/src/util/design/crop/getCropRotationUpdates';

const imageCropRotate90DegreesOperation =
  (elementAddress: ElementAddress) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const image = getMediaElement<MediaImage>(state, elementAddress);
    if (!image) {
      return;
    }

    batch(() => {
      dispatch(saveUndoRedoStateOperation('Rotate 90'));
      const newAngle = ((Math.floor(image.pr / 90) + 1) * 90) % 360;
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: getCropRotationUpdates(image, newAngle),
        }),
      );
    });
  };

export default imageCropRotate90DegreesOperation;

import cn from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import { ElementAddress } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';
import { RectMediaElement } from 'editor/src/util/reflectDesignData/utils';
import rotatePointOnPoint from 'editor/src/util/rotatePointOnPoint';

import {
  getSanitizeValue as getBoundedValue,
  round2Decimals,
} from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/utils';
import IconAngle from 'editor/src/component/Icon/IconAngle';
import Input from 'editor/src/component/Input';
import WithTooltip from 'editor/src/component/WithTooltip';

import getElementCenter from './getElementCenter';

import styles from './index.module.scss';

interface Props {
  address: ElementAddress;
  element: RectMediaElement;
}

function AngleProperty({ address, element }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [rotation, setRotation] = useState('');

  function handleRotationChange(event: ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value;
    setRotation(inputValue);
    const value = getBoundedValue(inputValue, 0, -360, 360);

    const elementUpdate: Partial<RectMediaElement> = {};
    switch (element.type) {
      case 'rectangle':
      case 'text': {
        const [imageCenterLeft, imageCenterTop] = getPointPositionRotatedOnPoint(
          element.x + element.width / 2,
          element.y + element.height / 2,
          element.x,
          element.y,
          element.r,
        );
        const [left, top] = getPointPositionRotatedOnPoint(
          element.x,
          element.y,
          imageCenterLeft,
          imageCenterTop,
          value - element.r,
        );
        elementUpdate.r = value;
        elementUpdate.x = left;
        elementUpdate.y = top;
        break;
      }
      case 'addon':
      case 'image': {
        const imageFrameCenter = getElementCenter(element);
        const imageFramePosition = { x: element.x, y: element.y };
        const notRotatedImageFramePosition = rotatePointOnPoint(imageFramePosition, imageFrameCenter, -element.r);
        const newImageFramePosition = rotatePointOnPoint(notRotatedImageFramePosition, imageFrameCenter, value);
        elementUpdate.r = value;
        elementUpdate.x = newImageFramePosition.x;
        elementUpdate.y = newImageFramePosition.y;
        break;
      }
      default:
        break;
    }
    dispatch(updateMediaElementOperation(address, elementUpdate, MediaUpdateActionName.ROTATED));
  }

  useEffect(() => {
    setRotation(`${round2Decimals(element.r)}`);
  }, [element.r]);

  return (
    <div className={styles.angleProperty}>
      <div className={styles.inputs}>
        <div className={styles.inputWrap}>
          <WithTooltip overlay={t('editor-rotation')}>
            <IconAngle />
          </WithTooltip>
          <Input
            className={cn(styles.input, 'cy-input-rotation')}
            label={t('editor-deg')}
            value={rotation}
            onChange={handleRotationChange}
            type="number"
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(AngleProperty);

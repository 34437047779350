import cn from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import getSpread from 'editor/src/store/design/selector/getSpread';
import { ElementAddress, MediaImage, MediaText } from 'editor/src/store/design/types';
import updateTextPropertiesWithoutRender from 'editor/src/store/design/util/updateTextPropertiesWithoutRender';
import addUnlockSizeRatioMediaElementOperation from 'editor/src/store/editor/operation/addUnlockSizeRatioMediaElementOperation';
import removeUnlockSizeRatioMediaElementOperation from 'editor/src/store/editor/operation/removeUnlockSizeRatioMediaElementOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import isMediaElementSizeRatioUnlocked from 'editor/src/store/editor/selector/isMediaElementSizeRatioUnlocked';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { RectMediaElement } from 'editor/src/util/reflectDesignData/utils';

import ControlButton from 'editor/src/component/ControlButton';
import {
  getSanitizeValue,
  round2Decimals,
} from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/utils';
import IconHeight from 'editor/src/component/Icon/IconHeight';
import IconLocked from 'editor/src/component/Icon/IconLocked';
import IconWidth from 'editor/src/component/Icon/IconWidth';
import Input from 'editor/src/component/Input';
import WithTooltip from 'editor/src/component/WithTooltip';

import getImageUpdateOnSizeChange from './getImageUpdateOnSizeChange';
import { Dimension, checkRatio, getDimensionUpdate, getElementMaxSize } from './sizeUtils';

import styles from './index.module.scss';

interface Props {
  address: ElementAddress;
  element: RectMediaElement;
}

const MIN_VALUE = 1;

function SizeProperty({ address, element }: Props) {
  const { t } = useTranslation();
  const isSizeRatioUnlocked = useSelector((state) => isMediaElementSizeRatioUnlocked(state, element));
  const spreadMaxSize = useSelector((state) => {
    const spread = getSpread(state, getCurrentSpreadIndex(state));
    return spread ? getElementMaxSize(spread) : 0;
  });
  const dispatch = useDispatch();

  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');

  const handleLockButtonClick = () => {
    if (isSizeRatioUnlocked) {
      dispatch(removeUnlockSizeRatioMediaElementOperation(element.uuid));
    } else {
      dispatch(addUnlockSizeRatioMediaElementOperation(element.uuid));
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, dimension: Dimension, currentValue: string) => {
    const inputValue = event.target.value;
    if (!inputValue.length) {
      return inputValue;
    }

    const value = getSanitizeValue(inputValue, MIN_VALUE, MIN_VALUE, spreadMaxSize);
    if (inputValue !== currentValue && (isSizeRatioUnlocked || checkRatio(element, value, dimension, MIN_VALUE))) {
      switch (element.type) {
        case 'rectangle': {
          const update = getDimensionUpdate(value, dimension, element, !isSizeRatioUnlocked);
          dispatch(updateMediaElementOperation(address, update, MediaUpdateActionName.RESIZED));
          break;
        }
        case 'text': {
          const update = getDimensionUpdate(value, dimension, element, !isSizeRatioUnlocked) as MediaText;
          updateTextPropertiesWithoutRender(element, update, undefined);
          dispatch(updateMediaElementOperation(address, update, MediaUpdateActionName.RESIZED));
          break;
        }
        case 'addon':
        case 'image': {
          const update = getDimensionUpdate(value, dimension, element, !isSizeRatioUnlocked);
          const imageUpdate = getImageUpdateOnSizeChange(element, update as Partial<MediaImage>);
          dispatch(updateMediaElementOperation(address, imageUpdate, MediaUpdateActionName.RESIZED));
          break;
        }
        default:
          break;
      }
    }

    return `${value}`;
  };

  const resetToElementWidth = () => {
    setWidth(`${round2Decimals(element.width)}`);
  };

  const handleWidthChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = handleInputChange(event, 'width', width);
    setWidth(value);
  };

  const resetToElementHeight = () => {
    setHeight(`${round2Decimals(element.height)}`);
  };

  const handleHeightChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = handleInputChange(event, 'height', height);
    setHeight(value);
  };

  useEffect(() => {
    resetToElementWidth();
  }, [element.width]);

  useEffect(() => {
    resetToElementHeight();
  }, [element.height]);

  const isText = element.type === 'text';

  return (
    <div className={styles.sizeProperty}>
      <div className={styles.inputs}>
        {(!isText || (element.type === 'text' && !element.extra.curve)) && (
          <div className={styles.inputWrap}>
            <WithTooltip overlay={t('editor-width')}>
              <IconWidth />
            </WithTooltip>
            <Input
              className={cn(styles.input, 'cy-input-width')}
              label="mm"
              value={width}
              onChange={handleWidthChange}
              onBlur={resetToElementWidth}
              type="number"
            />
          </div>
        )}
        {!isText && (
          <div className={styles.inputWrap}>
            <WithTooltip overlay={t('editor-height')}>
              <IconHeight />
            </WithTooltip>
            <Input
              className={cn(styles.input, 'cy-input-height')}
              label="mm"
              value={height}
              onChange={handleHeightChange}
              onBlur={resetToElementHeight}
              type="number"
            />
          </div>
        )}
      </div>
      {!isText && (
        <div className={styles.lock}>
          <WithTooltip overlay={t('editor-lock-aspect-ratio')}>
            <ControlButton
              className={cn(styles.lockButton, 'cy-button-lock')}
              on={!isSizeRatioUnlocked}
              onClick={handleLockButtonClick}
            >
              <IconLocked />
            </ControlButton>
          </WithTooltip>
        </div>
      )}
    </div>
  );
}

export default React.memo(SizeProperty);

import React from 'react';
import DatePicker, { DatePickerProps } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './index.module.scss';

function CustomDatePicker(props: DatePickerProps) {
  const { i18n } = useTranslation();
  const locale = i18n.language.split('-')[0] ?? 'en';

  return (
    <div className={styles.datepickerInputWrap}>
      <DatePicker
        popperPlacement="bottom-end"
        locale={locale}
        placeholderText={props.placeholderText}
        selected={props.selected}
        minDate={props.minDate}
        maxDate={props.maxDate}
        openToDate={props.openToDate}
        onSelect={props.onSelect}
      />
    </div>
  );
}

export default CustomDatePicker;

import { PayloadAction } from '@reduxjs/toolkit';

import {
  DataState,
  GroupElement,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const setActiveGroupReducer = (state: DataState, action: PayloadAction<Partial<GroupElement> | undefined>) => {
  state.activeGroup = action.payload;
};

export default setActiveGroupReducer;

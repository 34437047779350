import { fabric } from 'fabric';
import { useCallback, useRef } from 'react';

import { Coords } from 'editor/src/store/design/types';

import getDistance from 'editor/src/util/2d/getDistance';
import { Segment } from 'editor/src/util/2d/types';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import { createBBoxSnap } from './snapsDataUtils';
import { checkSide, findMultiple, getPointProjectionOnSegment } from './snapsUtils';
import useSnapController from './useSnapController';

const POINT = new fabric.Point(0, 0);
const FROM = {
  left: false,
  right: false,
  top: false,
  bottom: false,
};

function findPrecision(fabricCanvas: fabric.Canvas) {
  let step = 1;
  let half = true;
  const precision = (fabricCanvas.width ?? 1) / (fabricCanvas.getZoom() * (fabricCanvas.width ?? 1));
  while (step >= precision) {
    step /= half ? 2 : 5;
    half = !half;
  }
  return step;
}

function useSnapMatch(uuid: number, enable: boolean, pageCoords: Coords) {
  const fabricCanvas = useFabricCanvas();
  const snapController = useSnapController();
  const { mm2px, px2mm } = useFabricUtils();

  /*
    Used for determining from which side user started to resize the frame.
    Depending on this we handled particular snapping issues which had the effect
    that image could be scaled negatively.
  */
  const startsFrom = useRef(FROM);
  const precisionStep = useRef(1);

  const startInfo = useRef({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    scaleX: 0,
    scaleY: 0,
    tl: POINT,
    tr: POINT,
    bl: POINT,
    br: POINT,
    projOnTop: { x: 0, y: 0 },
    projOnLeft: { x: 0, y: 0 },
    projOnDiagTLBR: { x: 0, y: 0 },
    projOnDiagTRBL: { x: 0, y: 0 },
  });

  const onMouseDown = useCallback((e: fabric.IEvent) => {
    startInfo.current.x = e.pointer?.x || 0;
    startInfo.current.y = e.pointer?.y || 0;
    startInfo.current.width = e.target?.getScaledWidth() || 0;
    startInfo.current.height = e.target?.getScaledHeight() || 0;
    startInfo.current.scaleX = e.target?.scaleX || 1;
    startInfo.current.scaleY = e.target?.scaleY || 1;
    const aCoords = e.target?.aCoords;
    if (aCoords) {
      /*
        Checking if user starts from left|right|top|bottom
        For example, check if the user started from the left side is determined in a way
        that we compare if the point lays on the one side of the opposite segment
      */
      startsFrom.current = {
        left: checkSide(aCoords.tr, aCoords.br, aCoords.tl) > 0,
        right: checkSide(aCoords.tl, aCoords.bl, aCoords.tr) > 0,
        top: checkSide(aCoords.bl, aCoords.br, aCoords.tr) > 0,
        bottom: checkSide(aCoords.tl, aCoords.tr, aCoords.bl) > 0,
      };

      startInfo.current.tl = aCoords.tl;
      startInfo.current.tr = aCoords.tr;
      startInfo.current.bl = aCoords.bl;
      startInfo.current.br = aCoords.br;
      startInfo.current.projOnTop = getPointProjectionOnSegment(startInfo.current, [aCoords.tl, aCoords.tr]);
      startInfo.current.projOnLeft = getPointProjectionOnSegment(startInfo.current, [aCoords.tl, aCoords.bl]);
      startInfo.current.projOnDiagTLBR = getPointProjectionOnSegment(startInfo.current, [aCoords.tl, aCoords.br]);
      startInfo.current.projOnDiagTRBL = getPointProjectionOnSegment(startInfo.current, [aCoords.tr, aCoords.bl]);

      precisionStep.current = findPrecision(fabricCanvas);
    }
  }, []);

  const round = useCallback(
    (x: number, offset: number) => {
      const value = findMultiple(px2mm(x - offset), precisionStep.current);
      return mm2px(value) + offset;
    },
    [mm2px, px2mm],
  );

  const onMouseMove = useCallback(
    (e: fabric.IEvent) => {
      if (!enable || !snapController.isSnappingEnable()) {
        return;
      }

      const { target, transform } = e as any as {
        target: fabric.Object;
        transform: fabric.Transform;
      };
      if (!transform || !target) {
        return;
      }

      const zoom = fabricCanvas.getZoom();
      const currentX = e.pointer?.x || 0;
      const currentY = e.pointer?.y || 0;
      const absPointer = e.absolutePointer || { x: 0, y: 0 };

      switch (transform.action) {
        case 'drag': {
          const offset = {
            x: (currentX - startInfo.current.x) / zoom,
            y: (currentY - startInfo.current.y) / zoom,
          };
          const match = snapController.checkForSnap(offset, uuid);
          if (match) {
            target.left = match.snapPoint.x + match.offsetToTL.x;
            target.top = match.snapPoint.y + match.offsetToTL.y;
            if (match.matchSide === 'x') {
              target.left = round(target.left ?? 0, pageCoords.left);
            } else if (match.matchSide === 'y') {
              target.top = round(target.top ?? 0, pageCoords.top);
            }
          } else {
            target.left = round(target.left ?? 0, pageCoords.left);
            target.top = round(target.top ?? 0, pageCoords.top);
          }
          break;
        }
        case 'scaleX': {
          const { tl, tr, bl, br, width } = startInfo.current;

          /*
          Check if user is dragging middle left handle and if it starts from left and check if the
          requested point is not on the same opposite side as it was before(i.e. that negative scale is happening).
        */
          if (
            (transform.corner === 'ml' && startsFrom.current.left !== checkSide(tr, br, absPointer) > 0) ||
            (transform.corner === 'mr' && startsFrom.current.right !== checkSide(tl, bl, absPointer) > 0)
          ) {
            snapController.stopSnapping();
            return;
          }

          const scaledWidth = round(target.getScaledWidth(), 0);
          if (transform.corner === 'ml') {
            target.left = (target.left ?? 0) + (target.getScaledWidth() - scaledWidth);
          }
          target.scaleX = scaledWidth / (target.width ?? 1);

          const projection = getPointProjectionOnSegment({ x: currentX, y: currentY }, [tl, tr]);
          const offset = {
            x: (projection.x - startInfo.current.projOnTop.x) / zoom,
            y: (projection.y - startInfo.current.projOnTop.y) / zoom,
          };

          const eltSnap = createBBoxSnap(transform.corner === 'ml' ? [tl, bl] : [tr, br], tl, uuid, tl);
          const match = snapController.checkOneSideSnapping(eltSnap, offset);
          if (!match) {
            break;
          }

          const distance =
            transform.corner === 'ml'
              ? getDistance(
                  {
                    x: match.snapPoint.x + match.offsetToTL.x,
                    y: match.snapPoint.y + match.offsetToTL.y,
                  },
                  tr,
                )
              : getDistance(match.snapPoint, match.snappedPoint === br ? bl : tl);

          const newScaleX = (startInfo.current.scaleX * distance) / width;
          if (target.minScaleLimit && target.minScaleLimit > newScaleX) {
            break;
          }

          if (transform.corner === 'ml') {
            target.left = match.snapPoint.x + match.offsetToTL.x;
            target.top = match.snapPoint.y + match.offsetToTL.y;
          }

          target.scaleX = newScaleX;
          break;
        }
        case 'scaleY': {
          const { tl, tr, bl, br, height } = startInfo.current;

          if (
            (transform.corner === 'mt' && startsFrom.current.top !== checkSide(bl, br, absPointer) > 0) ||
            (transform.corner === 'mb' && startsFrom.current.bottom !== checkSide(tl, tr, absPointer) > 0)
          ) {
            snapController.stopSnapping();
            return;
          }

          const scaledHeight = round(target.getScaledHeight(), 0);
          if (transform.corner === 'mt') {
            target.top = (target.top ?? 0) + (target.getScaledHeight() - scaledHeight);
          }
          target.scaleY = scaledHeight / (target.height ?? 1);

          const projection = getPointProjectionOnSegment({ x: currentX, y: currentY }, [tl, bl]);
          const offset = {
            x: (projection.x - startInfo.current.projOnLeft.x) / zoom,
            y: (projection.y - startInfo.current.projOnLeft.y) / zoom,
          };

          const eltSnap = createBBoxSnap(transform.corner === 'mt' ? [tl, tr] : [bl, br], tl, uuid, tl);

          const match = snapController.checkOneSideSnapping(eltSnap, offset);
          if (!match) {
            break;
          }

          const distance =
            transform.corner === 'mt'
              ? getDistance(
                  {
                    x: match.snapPoint.x + match.offsetToTL.x,
                    y: match.snapPoint.y + match.offsetToTL.y,
                  },
                  bl,
                )
              : getDistance(match.snapPoint, match.snappedPoint === br ? tr : tl);

          const newScaleY = (startInfo.current.scaleY * distance) / height;
          if (target.minScaleLimit && target.minScaleLimit > newScaleY) {
            break;
          }

          if (transform.corner === 'mt') {
            target.left = match.snapPoint.x + match.offsetToTL.x;
            target.top = match.snapPoint.y + match.offsetToTL.y;
          }

          target.scaleY = newScaleY;
          break;
        }
        case 'scale': {
          const { tl, tr, bl, br } = startInfo.current;

          const corner = transform.corner as 'tl' | 'bl' | 'br' | 'tr';

          const outsideOfSnappingArea = {
            tl:
              corner === 'tl' &&
              (startsFrom.current.left !== checkSide(tr, br, absPointer) > 0 ||
                startsFrom.current.top !== checkSide(bl, br, absPointer) > 0),
            bl:
              corner === 'bl' &&
              (startsFrom.current.left !== checkSide(tr, br, absPointer) > 0 ||
                startsFrom.current.bottom !== checkSide(tl, tr, absPointer) > 0),
            br:
              corner === 'br' &&
              (startsFrom.current.right !== checkSide(tl, bl, absPointer) > 0 ||
                startsFrom.current.bottom !== checkSide(tl, tr, absPointer) > 0),
            tr:
              corner === 'tr' &&
              (startsFrom.current.right !== checkSide(tl, bl, absPointer) > 0 ||
                startsFrom.current.top !== checkSide(bl, br, absPointer) > 0),
          };

          if (
            outsideOfSnappingArea.tl ||
            outsideOfSnappingArea.bl ||
            outsideOfSnappingArea.br ||
            outsideOfSnappingArea.tr
          ) {
            snapController.stopSnapping();
            return;
          }

          const aspectRatio = target.getScaledWidth() / target.getScaledHeight();
          const scaledWidth = round(target.getScaledWidth(), 0);
          const scaledHeight = scaledWidth / aspectRatio;
          if (transform.corner === 'tl') {
            target.left = (target.left ?? 0) + (target.getScaledWidth() - scaledWidth);
            target.top = (target.top ?? 0) + (target.getScaledHeight() - scaledHeight);
          } else if (transform.corner === 'bl') {
            target.left = (target.left ?? 0) + (target.getScaledWidth() - scaledWidth);
          } else if (transform.corner === 'tr') {
            target.top = (target.top ?? 0) + (target.getScaledHeight() - scaledHeight);
          }
          target.scaleX = scaledWidth / (target.width ?? 1);
          target.scaleY = scaledHeight / (target.height ?? 1);

          const isTLBR = corner === 'tl' || corner === 'br';
          const diagSegment: Segment = isTLBR ? [tl, br] : [tr, bl];
          const projection = getPointProjectionOnSegment({ x: currentX, y: currentY }, diagSegment);
          const origProjection = isTLBR ? startInfo.current.projOnDiagTLBR : startInfo.current.projOnDiagTRBL;
          const offset = {
            x: (projection.x - origProjection.x) / zoom,
            y: (projection.y - origProjection.y) / zoom,
          };

          const cornerSnap = createBBoxSnap(diagSegment, tl, uuid, tl);
          const match = snapController.checkCornerSnapping(cornerSnap, startInfo.current[corner], offset);
          if (!match) {
            break;
          }

          let ratio = 1;
          switch (corner) {
            case 'tl':
              ratio = getDistance(match.snapPoint, br) / getDistance(tl, br);
              break;
            case 'bl':
              ratio = getDistance(match.snapPoint, tr) / getDistance(tr, bl);
              break;
            case 'br':
              ratio = getDistance(match.snapPoint, tl) / getDistance(tl, br);
              break;
            case 'tr':
              ratio = getDistance(match.snapPoint, bl) / getDistance(tr, bl);
              break;
            default:
              break;
          }

          const newScaleX = startInfo.current.scaleX * ratio;
          const newScaleY = startInfo.current.scaleY * ratio;
          if (target.minScaleLimit && (target.minScaleLimit > newScaleY || target.minScaleLimit > newScaleX)) {
            break;
          }

          target.scaleX = newScaleX;
          target.scaleY = newScaleY;
          target.left = match.snapPoint.x + match.offsetToTL.x * ratio;
          target.top = match.snapPoint.y + match.offsetToTL.y * ratio;
          break;
        }
        default:
      }
    },
    [enable, pageCoords, round, uuid],
  );

  const onMouseUp = useCallback(() => {
    snapController.stopSnapping();
  }, [snapController.stopSnapping]);

  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
  };
}

export default useSnapMatch;

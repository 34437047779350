import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import setCollectionFolderOperation from 'editor/src/store/collections/operations/setCollectionFolderOperation';
import { CollectionFolder } from 'editor/src/store/collections/types';
import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getSelectedImagePersonalizationLocked from 'editor/src/store/design/selector/getSelectedImagePersonalizationLocked';
import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import getStructureIndexByElementUuid from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import removeAllSelectedMediaElementsOperation from 'editor/src/store/editor/operation/removeAllSelectedMediaElementsOperation';
import selectNextEmptyImageFrameOperation from 'editor/src/store/editor/operation/selectNextEmptyImageFrameOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getReplacableImageElement from 'editor/src/store/editor/selector/getReplacableImageElement';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import isPersonalizationLockIgnored from 'editor/src/store/editor/selector/isPersonalizationLockIgnored';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import type { AsyncThunk } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import { MockupRole } from 'editor/src/store/mockup/types';
import { CollectionDisplayOption, setAdvPersoDataOnElement } from 'editor/src/store/plugins/utils/advPersoUtils';

import { logEvent } from 'editor/src/amplitude';
import getNewImage from 'editor/src/util/getNewImage';
import isWebPSupported from 'editor/src/util/isWebPSupported';
import loadImageDimensions from 'editor/src/util/loadImageDimensions';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import toastController from 'editor/src/util/toastController';

import logPersonalizationLayerActivatedOperation from '../../editor/operation/logPersonalizationLayerActivatedOperation';

import addMediaElementOperation from './addMediaElementOperation';
import updateImageIdByUuidOperation from './updateImageIdByUuidOperation';

const addOrApplyCollectionFolderToPageOperation =
  (folder: CollectionFolder): AsyncThunk =>
  async (dispatch, getState, { i18n }) => {
    let state = getState();

    const firstAssetPreview = folder.assets[0];
    if (!firstAssetPreview) {
      // we only allow dropping folders with one level
      toastController.warningPersist(i18n.t('editor-folder-no-asset'), i18n.t('editor-folder-no-asset-message'));
      return;
    }

    const asset = folder.assets[0];
    if (!asset) {
      return;
    }

    if (!asset.width || !asset.height) {
      try {
        const dim = await loadImageDimensions(
          isWebPSupported ? asset.thumbnailUrl || asset.url : asset.url,
          'anonymous',
          { executor: 'addCollectionFolderToPageOperation', asset },
        );
        asset.width = dim.width;
        asset.height = dim.height;
      } catch {
        /* */
      }
    }

    const currentSpreadIndex = getCurrentSpreadIndex(state);
    const selectedImageElement = getSelectedMediaElementsObjects(state).find((element) => element?.type === 'image');
    const selectedImageUuid = selectedImageElement?.uuid;
    const selectedImagePersonalizationLocked =
      getSelectedImagePersonalizationLocked(state) && !isPersonalizationLockIgnored(state);
    const addElementsAllowed = isAddElementsAllowed(state);
    const replacableElementUuid = getReplacableImageElement(state, currentSpreadIndex)?.uuid;
    const defaultElementPositioning = getHostSetting(state, 'defaultElementPositioning');

    await dispatch(setCollectionFolderOperation(folder));

    batch(() => {
      let uuid;
      const isMockupPlaceholder =
        selectedImageElement?.type === 'image' && selectedImageElement.role === MockupRole.Placeholder;
      if (selectedImageUuid && !isMockupPlaceholder) {
        if (!selectedImagePersonalizationLocked) {
          dispatch(selectNextEmptyImageFrameOperation(selectedImageUuid));
          dispatch(updateImageIdByUuidOperation(selectedImageUuid, asset.id));
          uuid = selectedImageUuid;
        }
      } else if (addElementsAllowed) {
        const spreadIndex = getCurrentSpreadIndex(state);

        const image = getNewImage(state, { ...asset, ...{ name: folder.name } }, defaultElementPositioning);
        if (!image) {
          return;
        }

        image.personalizationLocked = undefined;
        dispatch(addMediaElementOperation(spreadIndex, 0, image, true));
        dispatch(addSelectedMediaElementOperation(image.uuid, undefined));
        uuid = image.uuid;

        sendPostMessage('log.imageAdded', image);
        logEvent('element added', { type: 'collection' });
      } else if (replacableElementUuid && !selectedImagePersonalizationLocked) {
        dispatch(addSelectedMediaElementOperation(replacableElementUuid));
        dispatch(selectNextEmptyImageFrameOperation(replacableElementUuid));
        dispatch(updateImageIdByUuidOperation(replacableElementUuid, asset.id));
        uuid = replacableElementUuid;
      }

      state = getState();
      const image = uuid ? getMediaElementByUuid(state, uuid) : undefined;
      const elementUpdate = { ...image };
      if (uuid && image?.type === 'image') {
        setAdvPersoDataOnElement(elementUpdate, folder.id, CollectionDisplayOption.Thumbnail);
        const elementAddress = getStructureIndexByElementUuid(state, uuid);
        elementAddress && dispatch(updateMediaElementAction({ elementAddress, elementUpdate }));
      }

      batch(() => {
        dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.PERSONALIZE));
        dispatch(removeAllSelectedMediaElementsOperation());
        dispatch(logPersonalizationLayerActivatedOperation('collection'));
      });
    });
  };

export default addOrApplyCollectionFolderToPageOperation;

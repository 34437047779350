import React from 'react';

import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { Coords, SpotFinishingType, Spread, SpreadGroup } from 'editor/src/store/design/types';

import getGroupedSpreadDimensions from 'editor/src/util/getGroupedSpreadDimensions';
import getSpreadGroup from 'editor/src/util/getSpreadGroup';

export interface SpreadDisplay {
  spreadIndex: number;
  spreadWidth: number;
  spreadHeight: number;
  spreadCoords: Coords;
  spotFinishingType: SpotFinishingType | undefined;
}

export const SpreadDisplayContext = React.createContext<SpreadDisplay>(null as any);

export function getSpreadDisplay(
  spread: Spread,
  spreadIndex: number,
  spreadGroups: SpreadGroup[] | undefined,
): SpreadDisplay {
  const spreadWidth = getSpreadWidthFromSpread(spread);
  const spreadHeight = getSpreadHeightFromSpread(spread);

  const currentSpreadGroup = getSpreadGroup(spreadIndex, spreadGroups);
  const spreadCoords = { left: spread.x, top: spread.y };
  if (currentSpreadGroup) {
    spreadCoords.left = currentSpreadGroup.position === 'horizontal' ? -spreadWidth - spread.x : spread.x;
    spreadCoords.top = currentSpreadGroup.position === 'vertical' ? -spreadHeight - spread.y : spread.y;
  }
  const spreadDimensions = getGroupedSpreadDimensions(spreadWidth, spreadHeight, currentSpreadGroup);

  return {
    spreadCoords,
    spreadWidth: spreadDimensions.spreadWidth,
    spreadHeight: spreadDimensions.spreadHeight,
    spreadIndex,
    spotFinishingType: spread.spot_finishing_type,
  };
}

function useSpreadDisplay() {
  return React.useContext(SpreadDisplayContext);
}

export default useSpreadDisplay;

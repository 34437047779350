import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import IconChevronUp from 'editor/src/component/Icon/IconChevronUp';

import styles from './index.module.scss';

export interface SelectOption {
  label: React.ReactNode; // For the display in the dropdown
  value: string | null; // For the identification of the option.
  content?: React.ReactNode; // Optional additional content
}

interface Props {
  label: string;
  options: SelectOption[];
  onSelect: (selectedOption: SelectOption) => unknown;
  selected?: SelectOption;
}

function Select({ label, options, onSelect, selected }: Props) {
  const defaultOption = { label, value: null };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SelectOption>(selected || defaultOption);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (option: SelectOption) => {
    setIsOpen(false);
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <div ref={dropdownRef} className={cn(styles.wrapper)}>
      <div className={cn(styles.selectLabel)}>{label}</div>

      <div onClick={() => setIsOpen(!isOpen)} className={cn(styles.selectedOption, 'cy-select-input')}>
        <div className={cn(styles.selectedOptionLabel)}>{selectedOption.label}</div>
        <div className={cn(styles.chevronIcon, { [styles.isOpen]: isOpen })}>
          <IconChevronUp />
        </div>
      </div>

      <div className={cn(styles.overlay, { [styles.isOpen]: isOpen })}>
        {options.map((option, index) => (
          <div className={cn(styles.option, 'cy-select-option')} key={index} onClick={() => handleSelect(option)}>
            {option.label}
            {option.content && <div className={cn(styles.content)}>{option.content}</div>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Select;

import { TFunction } from 'react-i18next';

import { EditorMode } from 'editor/src/store/hostSettings/types';

import { ConfirmationPopupData } from '../Popup/ConfirmationPopup';

interface Props {
  editorMode?: EditorMode;
  t: TFunction<'translation', undefined>;
}

function getExitWarningPopupData({ editorMode, t }: Props): ConfirmationPopupData {
  const popupTitle = t('Are you sure you want to exit?');
  let textLines: string[] = [];
  const options = [
    {
      title: t('Cancel'),
      callbackMessage: { type: '' },
      setLoadingOnConfirm: false,
    },
  ];

  switch (editorMode) {
    case 'add': // new product without draft
      textLines = [t('If you exit without saving you will lose all your progress.')];
      options.push({
        title: t('Discard changes'),
        callbackMessage: { type: 'shopify.backButtonClick' },
        setLoadingOnConfirm: false,
      });
      break;
    case 'add-with-draft': // new product with draft enabled
      textLines = [t('Warning message creating draft')];
      options.push({
        title: t('Discard changes'),
        callbackMessage: { type: 'shopify.deleteDraft' },
        setLoadingOnConfirm: true,
      });
      options.push({
        title: t('Save and exit'),
        callbackMessage: { type: 'shopify.saveAsDraft' },
        setLoadingOnConfirm: true,
      });
      break;
    case 'draft': // editing a draft
      textLines = [t('Warning message editing draft')];
      options.push({
        title: t('Save and exit'),
        callbackMessage: { type: 'shopify.saveAsDraft' },
        setLoadingOnConfirm: true,
      });
      break;
    case 'edit': // editor from cart
      textLines = [t('Your changes will be saved and the product will be updated in your cart.')];
      options.push({
        title: t('Discard changes'),
        callbackMessage: { type: 'shopify.backButtonClick' },
        setLoadingOnConfirm: false,
      });
      break;
    default:
      throw new Error('Invalid editor mode');
  }

  return {
    popupTitle,
    cancelCallbackMessage: '',
    textLines,
    options,
  };
}

export default getExitWarningPopupData;

import React, { useEffect } from 'react';

import { Content, Coords } from 'editor/src/store/design/types';
import { DraggableItem } from 'editor/src/store/editor/types';
import { useSelector } from 'editor/src/store/hooks';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import useCanvasRotation from 'editor/src/component/EditorArea/useCanvasRotation';

import useDropZone from './useDropZone';

interface Props {
  content: Content;
  spreadCoords: Coords;
  pageCoords: Coords;
  enableDrop: boolean;
  onDrop: (item: DraggableItem) => void;
  onDraggingOver: (isDraggingOver: boolean) => void;
  isEmpty: boolean;
  anyElementSelected: boolean;
  hasBackgroundImage: boolean;
  canvasRotation: CanvasRotation;
  dragElementToSpreadOnlyAllowed: boolean;
}

export const DRAG_STROKE_COLOR = '#4AF047';

function ContentElement({
  content,
  spreadCoords,
  pageCoords,
  onDrop,
  onDraggingOver,
  enableDrop,
  hasBackgroundImage,
  anyElementSelected,
  isEmpty,
  canvasRotation,
  dragElementToSpreadOnlyAllowed,
}: Props) {
  const { mm2px } = useFabricUtils();
  const { showDoubleLine, linesColor, showPrintAreaLines } = useSelector((state) => state.hostSettings);
  const left = pageCoords.left + mm2px(content.x);
  const top = pageCoords.top + mm2px(content.y);
  const rotatedPoint = useCanvasRotation(canvasRotation, left - 1, top - 1);
  const rotatedInnerPoint = useCanvasRotation(canvasRotation, left - 2, top - 2);
  const { events, isDraggingOver, isDraggingActive } = useDropZone(
    spreadCoords,
    onDrop,
    enableDrop,
    undefined,
    canvasRotation,
  );

  const canShowLines = (anyElementSelected || isEmpty) && hasBackgroundImage && showPrintAreaLines;

  let stroke: string;
  if (isDraggingOver && !dragElementToSpreadOnlyAllowed) {
    stroke = DRAG_STROKE_COLOR;
  } else if (canShowLines) {
    stroke = linesColor.main;
  } else {
    stroke = '';
  }

  useEffect(() => {
    onDraggingOver(isDraggingOver);
  }, [isDraggingOver]);

  const width = mm2px(content.width);
  const height = mm2px(content.height);

  return (
    <>
      <FabricRectComponent
        left={rotatedPoint.x}
        top={rotatedPoint.y}
        width={width + 1}
        height={height + 1}
        fill=""
        selectable={false}
        strokeUniform
        strokeWidth={isDraggingOver ? 2 : 1}
        stroke={stroke}
        zIndex={zIndex.CONTENT}
        evented={isDraggingActive}
        events={events}
        angle={canvasRotation.angleDeg}
        objectCaching={false}
      />
      {showDoubleLine && canShowLines && (
        <FabricRectComponent
          left={rotatedInnerPoint.x}
          top={rotatedInnerPoint.y}
          width={width + 3}
          height={height + 3}
          selectable={false}
          evented={false}
          zIndex={zIndex.CONTENT}
          fill=""
          stroke={linesColor.outer}
          strokeUniform
          strokeWidth={1}
          angle={canvasRotation.angleDeg}
          objectCaching={false}
        />
      )}
    </>
  );
}

export default React.memo(ContentElement);

import {
  ExternalOptions,
  ExternalProductControl,
  ProductVariation,
  VariantInfo,
} from 'editor/src/store/variants/types';

import { UNSELECTED_PAGE_COUNT } from '../reducer/setPageCountReducer';

function getAllMatchingVariants(
  variations: ProductVariation[],
  externalOptions: ExternalOptions,
  externalControls: ExternalProductControl[],
  pageCount: number,
): VariantInfo[] {
  if (externalControls.length === 0) {
    return variations.map((variation) => ({
      variation,
      dimensions: undefined,
      pageCount: pageCount === UNSELECTED_PAGE_COUNT ? undefined : pageCount,
    }));
  }

  const variants: VariantInfo[] = [];
  Object.keys(externalOptions).forEach((key) => {
    const options = externalOptions[key];
    const control = externalControls.find((control) => control.key === key);
    if (!control) {
      return;
    }

    variations.forEach((variation) => {
      options.forEach((option) => {
        if (control.key === 'product-size') {
          variants.push({
            variation,
            dimensions: { width: option.width, height: option.height },
            pageCount: pageCount === UNSELECTED_PAGE_COUNT ? undefined : pageCount,
          });
        }
      });
    });
  });
  return variants;
}

export default getAllMatchingVariants;

import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import updateConditionDisplayOptionOperation from 'editor/src/store/design/operation/updateConditionDisplayOptionOperation';
import { Condition, ConditionDisplayOption } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import DropDown from 'editor/src/component/DropDown';
import IconArrowDown from 'editor/src/component/Icon/IconArrowDown';
import { OPENING_ANIM_TIME } from 'editor/src/component/PersonalizationContent/PersonalizationElements/PersonalizationCondition/ConditionSettings/Sections';
import useSectionActive from 'editor/src/component/PersonalizationContent/PersonalizationElements/PersonalizationCondition/ConditionSettings/Sections/useSectionActive';

import ConditionNameInput, { Ref as NameInputRef } from './ConditionNameInput';

import styles from './index.module.scss';

interface Props {
  spreadIndex: number;
  condition: Condition;
}

const iconDropdown = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="3" fill="#5991FF" />
    <path d="M8 9L10 11L12 9" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const iconButton = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="3" fill="#5991FF" />
    <rect x="4" y="9" width="5" height="3" fill="white" />
    <rect x="11" y="9" width="5" height="3" fill="white" />
  </svg>
);

const iconThumbnail = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="3" fill="#5991FF" />
    <rect x="4.5" y="4.5" width="11" height="11" fill="#5991FF" />
    <rect x="5" y="5" width="5" height="5" fill="white" />
    <rect x="10" y="10" width="5" height="5" fill="white" />
    <rect x="4.5" y="4.5" width="11" height="11" stroke="white" />
  </svg>
);

const DISPLAY_OPTIONS = [
  {
    value: ConditionDisplayOption.Button,
    labelKey: 'editor-display-option-button',
    icon: iconButton,
  },
  {
    value: ConditionDisplayOption.Dropdown,
    labelKey: 'editor-display-option-dropdown',
    icon: iconDropdown,
  },
  {
    value: ConditionDisplayOption.Thumbnail,
    labelKey: 'editor-display-option-thumbnail',
    icon: iconThumbnail,
  },
];

function DisplaySection({ condition, spreadIndex }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const nameInputRef = useRef<NameInputRef>(null);

  const isSectionActive = useSectionActive();

  useEffect(() => {
    if (!isSectionActive) {
      return undefined;
    }

    const handle = window.setTimeout(() => nameInputRef.current?.focus(), OPENING_ANIM_TIME);
    return () => window.clearTimeout(handle);
  }, [isSectionActive]);

  function selectDisplayOption(displayOption: ConditionDisplayOption) {
    dispatch(
      updateConditionDisplayOptionOperation({
        address: { spreadIndex, conditionId: condition.id },
        displayOption,
      }),
    );
    close();
  }

  function close() {
    setDropdownVisible(false);
  }

  const activeOption = DISPLAY_OPTIONS.find((option) => option.value === condition.displayOption);

  return (
    <div className={cn(styles.DisplaySection, 'cy-personalisation-display-section')}>
      <div className={styles.label}>{t('editor-condition-name')}</div>
      <ConditionNameInput
        ref={nameInputRef}
        spreadIndex={spreadIndex}
        conditionId={condition.id}
        conditionName={condition.name}
      />

      <div className={cn(styles.label, styles.labelOptions)}>{t('editor-condition-display-option')}</div>
      <div
        className={cn(styles.option, styles.dropdownBtn, 'cy-condition-display-option-btn')}
        onClick={() => setDropdownVisible(true)}
      >
        {activeOption?.icon}
        {activeOption && <div className={styles.optionLabel}>{t(activeOption.labelKey)}</div>}
        <IconArrowDown />
      </div>
      <DropDown
        isVisible={dropdownVisible}
        onClickOutside={close}
        openPosition="bottom-left"
        className="cy-display-section-dropdown"
        matchWidth
      >
        {DISPLAY_OPTIONS.map(({ value, icon, labelKey }) => (
          <div
            key={value}
            onClick={() => selectDisplayOption(value)}
            className={cn(styles.option, {
              [styles.active]: value === condition.displayOption,
            })}
          >
            {icon}
            <div className={styles.optionLabel}>{t(labelKey)}</div>
          </div>
        ))}
      </DropDown>
    </div>
  );
}

export default React.memo(DisplaySection);

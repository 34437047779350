import React from 'react';

import { RootState } from 'editor/src/store';

import { MenuContext } from 'editor/src/component/Menu/type';
import ButtonDuplicate, {
  canShow as canShowDuplicate,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonDuplicate';
import ButtonLock, { canShow as canShowLock } from 'editor/src/component/TopMenuDesktop/buttons/ButtonLock';
import ButtonPersonalization, {
  canShow as canShowPersonalization,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonPersonalization';
import ButtonPosition, { canShow as canShowPosition } from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition';
import ButtonProperties, {
  canShow as canShowProperties,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonProperties';
import ButtonRemove, { canShow as canShowRemove } from 'editor/src/component/TopMenuDesktop/buttons/ButtonRemove';

import TextToolbar from './TextToolbar';

interface Props {
  menuContext: MenuContext;
  rootState: RootState;
}

function TextControls({ rootState, menuContext }: Props) {
  return (
    <>
      {!menuContext.isPersonalizationLocked && <TextToolbar rootState={rootState} menuContext={menuContext} />}
      {canShowPosition(rootState, menuContext) && <ButtonPosition isMobile mode="label-icon-vertical" />}
      {canShowProperties(rootState, menuContext) && <ButtonProperties mode="label-icon-vertical" />}
      {canShowPersonalization(rootState, menuContext) && <ButtonPersonalization isMobile mode="label-icon-vertical" />}
      {canShowLock(rootState, menuContext) && <ButtonLock mode="label-icon-vertical" context={menuContext} />}
      {canShowDuplicate(rootState, menuContext) && <ButtonDuplicate mode="label-icon-vertical" context={menuContext} />}
      {canShowRemove(rootState, menuContext) && <ButtonRemove mode="label-icon-vertical" context={menuContext} />}
    </>
  );
}

export default React.memo(TextControls);

import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { Coords } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import UploaderDropZone from 'editor/src/component/Uploader/UploaderDropZone';

import styles from './index.module.scss';

interface Props {
  spreadCoords: Coords;
  spreadWidth: number;
  spreadHeight: number;
}

function FileDropZone({ spreadCoords, spreadWidth, spreadHeight }: Props) {
  const isDragging = useSelector((state) => state.editor.dragState === 'system');
  const { mm2px } = useFabricUtils();
  const fabricCanvas = useFabricCanvas();
  const [draggignOver, setDraggingOver] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!divRef.current) {
      return undefined;
    }

    function passDragEventToCanvas(e: DragEvent) {
      const mouseEvent = new MouseEvent('mousemove', {
        clientX: e.clientX,
        clientY: e.clientY,
      });
      const canvas = document.querySelector('.upper-canvas');
      canvas?.dispatchEvent(mouseEvent);
    }

    function onDragEnter(e: DragEvent) {
      setDraggingOver(true);
      passDragEventToCanvas(e);
    }

    let dragEndTimeout: number | undefined;
    function onDragOver(e: DragEvent) {
      setDraggingOver(true);
      passDragEventToCanvas(e);
      window.clearTimeout(dragEndTimeout);
      dragEndTimeout = window.setTimeout(() => {
        setDraggingOver(false);
      }, 200);
    }

    function onDragLeave(e: DragEvent) {
      window.clearTimeout(dragEndTimeout);
      setDraggingOver(false);
      passDragEventToCanvas(e);
    }

    divRef.current.addEventListener('dragenter', onDragEnter);
    divRef.current.addEventListener('dragover', onDragOver);
    divRef.current.addEventListener('dragleave', onDragLeave);
    return () => {
      window.clearTimeout(dragEndTimeout);
      divRef.current?.removeEventListener('dragenter', onDragEnter);
      divRef.current?.removeEventListener('dragover', onDragOver);
      divRef.current?.removeEventListener('dragleave', onDragLeave);
    };
  }, [isDragging]);

  if (!isDragging) {
    return null;
  }

  const width = mm2px(spreadWidth);
  const height = mm2px(spreadHeight);
  const {
    tl: { x: left, y: top },
  } = fabricCanvas.calcViewportBoundaries();
  const zoom = fabricCanvas.getZoom();

  return (
    <div
      className={cn(styles.FileDropZone, 'cy-file-drop', {
        [styles.over]: draggignOver,
        'cy-dragging-over': draggignOver,
      })}
      style={{
        left: (spreadCoords.left - left) * zoom,
        top: (spreadCoords.top - top) * zoom,
        width: width * zoom,
        height: height * zoom,
      }}
      ref={divRef}
    >
      <UploaderDropZone
        title="Upload directly on the artboard"
        className={cn(styles.dropZone, 'cy-gallery-drop-zone')}
        minMode={width * zoom < 180}
      />
    </div>
  );
}

export default React.memo(FileDropZone);

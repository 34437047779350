import { captureException } from '@sentry/react';

function getIsLocalStorageAccessible() {
  try {
    const testKey = '__test__';
    window.localStorage.setItem(testKey, '1');
    window.localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    captureException('localStorage is not accessible:', e);

    return false;
  }
}

const isLocalStorageAccessible = getIsLocalStorageAccessible();

export default isLocalStorageAccessible;

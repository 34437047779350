import {
  ContentPluginRootState,
  SortOption,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const getSort = (state: ContentPluginRootState): SortOption | undefined => {
  const { sort } = state.data.results?.search || {};
  return sort ? state.data.results?.sort?.find((option) => option.value === sort) : undefined;
};

export default getSort;

import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GelatoPromptInfo, PluginName } from 'editor/src/store/plugins/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import IconMinus from 'editor/src/component/Icon/IconMinus';
import IconPlus from 'editor/src/component/Icon/IconPlus';

import styles from './index.module.scss';

interface Props {
  prompt: GelatoPromptInfo;
  pluginName: PluginName | string;
  className?: string;
}

function PlusPrompt({ prompt, pluginName, className }: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  function onSubscribe() {
    sendPostMessage('plugins.subscribeClick', { name: pluginName });
  }

  function onToggleOpen() {
    setIsOpen((open) => !open);
  }

  return (
    <div className={cn(styles.PlusPrompt, className)}>
      <button className={styles.header} onClick={onToggleOpen}>
        <div className={styles.text}>{t('editor-gelato-plus-feature')}</div>
        {isOpen ? <IconMinus /> : <IconPlus />}
      </button>
      {isOpen && (
        <>
          <div
            className={styles.description}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: prompt.description }}
          />
          <button className={cn(styles.button, 'cy-button-gelato-subscribe')} onClick={onSubscribe}>
            {prompt.buttonText ? prompt.buttonText : t('editor-subscribe-plus')}
          </button>
        </>
      )}
    </div>
  );
}

export default React.memo(PlusPrompt);

import applyToSelectedMediaOperation from 'editor/src/store/design/operation/applyToSelectedMediaOperation';
import { MediaRectangle } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const applyRectangleStylesToSelectionOperation =
  (elementUpdates: Partial<MediaRectangle>) => (dispatch: ThunkDispatch) => {
    dispatch(applyToSelectedMediaOperation((element) => (element.type === 'rectangle' ? elementUpdates : undefined)));
  };

export default applyRectangleStylesToSelectionOperation;

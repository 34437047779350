import {
  TabInfo,
  isGenericPluginTabInfo,
  isNotDefaultTab,
  isPluginTab,
  TAB_NAMES,
} from 'editor/src/store/editorModules/sidebar/types';

import tabContents from './tabContents';

export default function hasSidebarContent(tabInfo: TabInfo): boolean {
  if (isGenericPluginTabInfo(tabInfo)) {
    return true;
  }
  if (isNotDefaultTab(tabInfo) || isPluginTab(tabInfo)) {
    return !!tabContents[tabInfo.id as TAB_NAMES];
  }

  return !!tabContents[tabInfo];
}

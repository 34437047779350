/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconCurve({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5669 7.83887C13.5669 7.35562 13.1751 6.96387 12.6919 6.96387C12.2086 6.96387 11.8169 7.35562 11.8169 7.83887V9.18671C9.12211 9.41994 6.50789 10.7329 4.72004 13.1256L4.18749 12.8182C3.76899 12.5765 3.23385 12.7199 2.99222 13.1384C2.7506 13.5569 2.89399 14.0921 3.31249 14.3337L5.77906 15.7578C6.19757 15.9994 6.73271 15.856 6.97433 15.4375C7.21596 15.019 7.07257 14.4839 6.65406 14.2422L6.24882 14.0083C7.68324 12.1871 9.71386 11.166 11.8169 10.9448V12.3296C11.8169 12.8128 12.2086 13.2046 12.6919 13.2046C13.1751 13.2046 13.5669 12.8128 13.5669 12.3296V10.9448C15.6699 11.166 17.7004 12.1871 19.1349 14.0082L18.7295 14.2422C18.311 14.4839 18.1676 15.019 18.4092 15.4375C18.6508 15.856 19.186 15.9994 19.6045 15.7578L20.8306 15.0499L20.8441 15.0421L22.071 14.3337C22.4895 14.0921 22.6329 13.5569 22.3913 13.1384C22.1497 12.7199 21.6145 12.5765 21.196 12.8182L20.6636 13.1255C18.8758 10.7329 16.2616 9.41995 13.5669 9.18672V7.83887Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default React.memo(IconCurve);

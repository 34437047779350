import getFirstSpreadWithGridIndex from 'editor/src/store/design/selector/getFirstSpreadWithGridIndex';
import getSpread from 'editor/src/store/design/selector/getSpread';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import setCurrentSpreadIndexOperation from './setCurrentSpreadIndexOperation';

const selectFirstSpreadWithGridOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();

  const currentSpread = getSpread(state, getCurrentSpreadIndex(state));
  if (!currentSpread) {
    return;
  }

  if (!currentSpread.pages[0].groups.media?.some((media) => media.type === 'grid')) {
    const firstSpreadWithGridIndex = getFirstSpreadWithGridIndex(state);
    firstSpreadWithGridIndex !== -1 && dispatch(setCurrentSpreadIndexOperation(firstSpreadWithGridIndex));
  }
};

export default selectFirstSpreadWithGridOperation;

import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { MediaMockupPlaceholder } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { setMockupPreviewAction } from 'editor/src/store/mockup/slice';
import { ProductScene } from 'editor/src/store/mockup/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const setSelectedProductSceneOperation =
  (scene: ProductScene, productUid: string, isProductVariant: boolean) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    if (scene.isPremium && !scene.canUsePremium) {
      sendPostMessage('mockup.premiumSceneClick', undefined);
      return;
    }
    const sceneMedia = state.design.designData?.spreads[0].pages[0].groups.media;
    const placeholderIndex = sceneMedia?.findIndex(isMediaMockupPlaceholder);
    const placeholder = (placeholderIndex !== undefined ? sceneMedia?.[placeholderIndex] : undefined) as
      | MediaMockupPlaceholder
      | undefined;
    if (placeholderIndex === undefined || !placeholder) {
      return;
    }

    let { width, height } = placeholder;
    const max = Math.max(placeholder.width, placeholder.height);
    if (scene.productImage.width > scene.productImage.height) {
      width = max;
      height = max * (scene.productImage.height / scene.productImage.width);
    } else {
      height = max;
      width = max * (scene.productImage.width / scene.productImage.height);
    }

    const update = {
      width,
      height,
      pw: width,
      ph: height,
      x: placeholder.x + (placeholder.width - width) / 2,
      y: placeholder.y + (placeholder.height - height) / 2,
    };

    if (!isProductVariant) {
      dispatch(setMockupPreviewAction(undefined));
    }

    dispatch(
      updateMediaElementAction({
        elementAddress: {
          spreadIndex: 0,
          pageIndex: 0,
          elementIndex: placeholderIndex,
        },
        elementUpdate: {
          ...update,
          variant: {
            productUid,
            width: scene.productImage.width,
            height: scene.productImage.height,
            scene: scene.id,
          },
        },
      }),
    );
  };

export default setSelectedProductSceneOperation;

import { ECommerceProduct } from 'editor/src/store/variants/types';

function getCurrentProductVariantPrice(currentProduct: ECommerceProduct | undefined): {
  price: string | undefined;
  originalPrice: string | undefined;
} {
  const extractPriceAndCurrency = (
    price: string | undefined,
  ): { numericPrice: number | undefined; currencyCode: string | undefined } => {
    if (!price) {
      return { numericPrice: undefined, currencyCode: undefined };
    }

    // Extract the numeric part (price)
    const numericPrice = parseFloat(price.replace(/[^0-9.,]/g, '').replace(',', '.'));

    // Extract the currency code by removing non-alphabetic characters like $, €, etc.
    const currencyCode = price.replace(/[^A-Za-z]/g, '').trim();

    return { numericPrice, currencyCode };
  };

  const selectedVariant = currentProduct?.variants.find((variant) => variant.id === currentProduct.selectedVariantId);
  const quantity = currentProduct?.quantity || 1;

  const { numericPrice: price, currencyCode: priceCurrency } = extractPriceAndCurrency(selectedVariant?.price);
  const { numericPrice: originalPrice, currencyCode: originalPriceCurrency } = extractPriceAndCurrency(
    selectedVariant?.compareAtPrice,
  );

  return {
    price: price !== undefined && priceCurrency ? `${(price * quantity).toFixed(2)} ${priceCurrency}` : undefined,
    originalPrice:
      originalPrice !== undefined && originalPriceCurrency
        ? `${(originalPrice * quantity).toFixed(2)} ${originalPriceCurrency}`
        : undefined,
  };
}

export default getCurrentProductVariantPrice;

import React from 'react';

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import startCropModeOperation from 'editor/src/store/editor/operation/startCropModeOperation';
import stopCropModeOperation from 'editor/src/store/editor/operation/stopCropModeOperation';
import isAnyMediaElementInCropMode from 'editor/src/store/editor/selector/isAnyMediaElementInCropMode';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconCrop from 'editor/src/component/Icon/IconCrop';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection, isElementEmpty, structureIndexes }) => {
  if (!structureIndexes.length) {
    return false;
  }
  const element = getMediaElement(state, structureIndexes[0]);
  if (!element) {
    return false;
  }

  if (element.type === 'image' && element.pattern) {
    return false;
  }

  const { imageCrop } = state.hostSettings.coreFeatures;
  return !isElementEmpty && hasSelection && imageCrop;
};

type Props = Pick<MenuItemProps, 'context' | 'mode' | 'isMobile' | 'close'>;

function ButtonCrop({ context, mode, isMobile, close }: Props) {
  const isCropModeActive = useSelector(isAnyMediaElementInCropMode);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (isMobile) {
      dispatch(setSidebarActiveTabOperation(-1));
    }
    const { uuid } = context.structureIndexes[0];
    dispatch(isCropModeActive ? stopCropModeOperation() : startCropModeOperation(uuid));
    close?.();
  };

  return (
    <MenuButton
      Icon={IconCrop}
      on={isCropModeActive}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-crop"
      className="cy-button-crop"
    />
  );
}

export default React.memo(ButtonCrop);

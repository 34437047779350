import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  show: boolean;
  color?: string;
  className?: string;
}

function SpinnerLocal({ show, color, className }: Props) {
  const style = color
    ? {
        borderLeft: `0.5em solid ${color}`,
        borderTop: `0.5em solid ${color}33`,
        borderRight: `0.5em solid ${color}33`,
        borderBottom: `0.5em solid ${color}33`,
      }
    : undefined;
  return (
    <div hidden={!show} className={cn(styles.spinnerContainer, className, 'cy-spinner-local')}>
      <div className={styles.spinnerMain}>
        <div className={styles.spinner} style={style} />
      </div>
    </div>
  );
}

export default SpinnerLocal;

import React from 'react';
import { createPortal } from 'react-dom';

import Sidebar from 'editor/src/component/DesktopSidebar';
import EditorArea from 'editor/src/component/EditorArea';
import Preview from 'editor/src/component/Preview';
import TopMenuDesktop from 'editor/src/component/TopMenuDesktop';

import DesktopHeader from '../DesktopHeader';
import LoadingBackdrop from '../LoadingBackdrop';

import RightSidebar from './RightSidebar';
import useEcommerceHeader from './useEcommerceHeader';
import useSystemDrag from './useSystemDrag';

import styles from './index.module.scss';

function DesktopLayout() {
  useSystemDrag();
  const {
    isEditorHeaderEnabled,
    handleHeaderBackButtonClick,
    handleHeaderContinueButtonClick,
    isLoading,
    productPrice,
  } = useEcommerceHeader();

  return (
    <div className={styles.DesktopLayout}>
      {isEditorHeaderEnabled && (
        <DesktopHeader
          onBackButtonClick={handleHeaderBackButtonClick}
          onCartButtonClick={handleHeaderContinueButtonClick}
          productPrice={productPrice}
        />
      )}
      <Sidebar />
      <TopMenuDesktop />
      <EditorArea isMobile={false} />
      <Preview />
      <RightSidebar />
      {isLoading && createPortal(<LoadingBackdrop />, document.body)}
    </div>
  );
}

export default React.memo(DesktopLayout);

import { captureException } from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';

import type { Thunk } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import applyLinkedToGroups from 'editor/src/store/variants/helpers/applyLinkedToGroups';
import getProductDataVariationGroups from 'editor/src/store/variants/helpers/getProductDataVariationGroups';
import { ProductsDataPayload } from 'editor/src/store/variants/reducer/setProductsDataReducer';
import { setProductsDataAction, setSelectedProductIdAction } from 'editor/src/store/variants/slice';
import { ExistingVariant, MultipleProducts } from 'editor/src/store/variants/types';

import applyLayoutPerProductUid from 'editor/src/util/layouts/applyLayoutPerProductUid';

import { batch } from '../../batchedSubscribeEnhancer';

import setProductDataOperation from './setProductDataOperation';

const setMultipleProductsDataOperation =
  (data: ProductsDataPayload): Thunk =>
  (dispatch, getState: () => RootState, { i18n }) => {
    const { products, selectedProductId } = data;
    const activeProduct = products[selectedProductId];

    if (!activeProduct) {
      captureException(new Error('No selectedProductId in multiple products set'), {
        extra: {
          products,
          selectedProductId,
        },
      });
      return;
    }

    const state = getState();
    const variantMultipleProducts: MultipleProducts = {};
    Object.entries(products).forEach(([productId, productData]) => {
      const {
        configuration,
        existingVariants = [],
        layoutPerProductUids = {},
        groupedSpreadsPerProductUids = {},
      } = productData;
      const updatedVariants: ExistingVariant[] = [];

      existingVariants.forEach((variant: ExistingVariant) => {
        if (!variant.designData || !layoutPerProductUids[variant.designData.product_uid]) {
          updatedVariants.push(variant);
          return;
        }
        const variantClone = cloneDeep(variant);
        if (variantClone.designData) {
          variantClone.designData = applyLayoutPerProductUid(
            variantClone.designData,
            state,
            true,
            i18n,
            layoutPerProductUids,
            groupedSpreadsPerProductUids,
            configuration.forceLayout,
          );
        }
        updatedVariants.push(variantClone);
      });

      const groups = getProductDataVariationGroups(productData, updatedVariants, state, i18n) || [];
      applyLinkedToGroups(groups, productData.defaultVariationsInfo);
      variantMultipleProducts[productId] = Object.assign(productData, { variantGroups: groups });
    });

    batch(() => {
      dispatch(setProductsDataAction(variantMultipleProducts));
      dispatch(setSelectedProductIdAction(selectedProductId));
      void dispatch(setProductDataOperation(cloneDeep(activeProduct)));
    });
  };

export default setMultipleProductsDataOperation;

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconCheck2({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 3.78909L14.1574 2L7.54339 8.42185L3.84262 4.82859L2 6.61768L7.54337 12L16 3.78909Z" fill="white" />
      </svg>
    </Icon>
  );
}

export default IconCheck2;

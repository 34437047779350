import React, { useCallback } from 'react';

import useIsMounted from 'editor/src/util/useIsMounted';
import usePostMessage from 'editor/src/util/usePostMessage';

import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';

import FiltersTabContent from './FiltersTabContent';
import ResultsTabContent from './ResultsTabContent';
import { useDispatch, useSelector, useStore as useLocalStore } from './store';
import appendResultsOperation from './store/operation/appendResultsOperation';
import setResultsOperation from './store/operation/setResultsOperation';
import getActiveTab from './store/selector/getActiveTab';
import getLoadingMore from './store/selector/getLoadingMore';
import getRequestVersion from './store/selector/getRequestVersion';
import { ContentProviderTabNames, ResultsData } from './store/types';
import mapResultsData from './utils/mapResultsData';

interface Props extends TabContentProps {
  pluginName: string;
}

function ContentProviderPlugin({ title, icon, pluginName }: Props) {
  const activeTab = useSelector(getActiveTab);
  const dispatch = useDispatch();
  const localStore = useLocalStore();

  const isMounted = useIsMounted();

  const setDataWithDimensions = useCallback(async (data: ResultsData) => {
    await mapResultsData(data);

    function setData(data: ResultsData) {
      if (loadingMore) {
        dispatch(appendResultsOperation(data));
      } else {
        dispatch(setResultsOperation(data));
      }
    }
    const state = localStore.getState();
    const loadingMore = getLoadingMore(state);
    const requestVersion = getRequestVersion(state);

    if (isMounted() && requestVersion === data.resultsVersion) {
      setData(data);
    }
  }, []);

  usePostMessage('plugins.setData', setDataWithDimensions, pluginName);

  return (
    <>
      {activeTab === ContentProviderTabNames.MAIN && (
        <ResultsTabContent title={title || ''} icon={icon} pluginName={pluginName} />
      )}
      {activeTab === ContentProviderTabNames.FILTERS && <FiltersTabContent />}
    </>
  );
}

export default ContentProviderPlugin;

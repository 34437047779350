import { ExistingVariant, VariationGroup } from 'editor/src/store/variants/types';

function getExistingVariantsFromVariationGroups(variationGroups: VariationGroup[]): ExistingVariant[] {
  const existingVariants: ExistingVariant[] = [];
  variationGroups.forEach((group) => {
    group.variationsInfo.forEach((variationInfo) => {
      existingVariants.push({
        productUid: variationInfo.variation.productUid,
        designData: variationInfo.designData,
        linked: group.linked,
        designOptions: group.designOptions,
      });
    });
  });

  return existingVariants;
}

export default getExistingVariantsFromVariationGroups;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import setActiveAddLayerDropdownOperation from 'editor/src/store/app/operation/setActiveAddLayerDropdownOperation';
import isAddLayerDropdownVisible from 'editor/src/store/app/selector/isAddLayerDropdownVisible';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import AddLayerDropdown from 'editor/src/component/AddLayerDropdown';
import Button from 'editor/src/component/Button';
import IconPlus from 'editor/src/component/Icon/IconPlus';

import styles from './index.module.scss';

interface Props {
  showCollection?: boolean;
  showCondition?: boolean;
  title?: string;
  isPersonalized?: boolean;
}

function ButtonAddLayer({ showCollection, showCondition, title, isPersonalized }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isDropdownVisible, addElementsAllowed } = useSelector(
    (state) => ({
      isDropdownVisible: isAddLayerDropdownVisible(state, 'sidebar'),
      addElementsAllowed: isAddElementsAllowed(state),
    }),
    shallowEqual,
  );

  if (!addElementsAllowed) {
    return null;
  }

  const onAddLayerClick = () => {
    dispatch(setActiveAddLayerDropdownOperation('sidebar'));
  };

  return (
    <div className={styles.addLayerButtonWrapper}>
      <Button
        secondary
        stopPropagation
        className={cn(styles.addLayerButton, 'cy-button-add-layer-tab')}
        onClick={onAddLayerClick}
        on={isDropdownVisible}
      >
        <IconPlus />
        {title || t('editor-add-layer')}
      </Button>
      <AddLayerDropdown
        className={styles.dropDown}
        isDropdownVisible={isDropdownVisible}
        menuPosition="right-fromtop"
        showCollection={showCollection}
        showCondition={showCondition}
        isPersonalized={isPersonalized}
      />
    </div>
  );
}

export default React.memo(ButtonAddLayer);

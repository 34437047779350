import { RootState } from 'editor/src/store/index';

import getImageIdsUsage from '../../design/util/getImageIdsUsage';

import getSelectedGalleryImagesIds from './getSelectedGalleryImagesIds';

const getSelectedGalleryImagesCount = (state: RootState): number => {
  const usedImages = getImageIdsUsage(state.design?.designData);
  const selectedGalleryImagesIds = getSelectedGalleryImagesIds(state);

  const filteredImagesCount = state.gallery.hideUsedPhotos
    ? selectedGalleryImagesIds.filter((imageId) => !usedImages.has(imageId)).length
    : selectedGalleryImagesIds.length;

  return filteredImagesCount;
};

export default getSelectedGalleryImagesCount;

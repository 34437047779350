import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  bigMargin?: boolean;
}

function PropertySeparator({ bigMargin }: Props) {
  return (
    <div
      className={cn(styles.propertySeparator, {
        [styles.bigMargin]: bigMargin,
      })}
    />
  );
}

export default PropertySeparator;

import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import updateImageElementColorsOperation from 'editor/src/store/design/operation/updateImageElementColorsOperation';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { ElementAddress } from 'editor/src/store/design/types';
import getSidebarPayload from 'editor/src/store/editorModules/sidebar/selector/getSidebarPayload';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import { EMPTY_RGBA_STRING } from 'editor/src/util/color/rgbaUtils';

import DrawerHeader from 'editor/src/component/BottomBarMobile/DrawerHeader';
import ColorPicker from 'editor/src/component/ColorPicker';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import useCurrentElementColors from 'editor/src/component/TopMenuDesktop/buttons/ButtonImageColor/useCurrentElementColors';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import { TabContentProps } from '..';

import styles from './index.module.scss';

function ImageColorTabContent({ closeMenuCb }: TabContentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeColorIndex = useSelector(getSidebarPayload) ?? 0;
  const imageColors = useCurrentElementColors();
  const isMobile = useIsMobile();
  const currentColor = imageColors[activeColorIndex];

  const selectedMediaElementId = useSelector((state) => getSelectedImageUuid(state));
  const structureIndex = useSelector((state) =>
    selectedMediaElementId ? getStructureIndexByElementUuidMemoized(state, selectedMediaElementId) : undefined,
  ) as ElementAddress | undefined;

  const colorPickerConfig = useSelector((state) => getHostSetting(state, 'colorPickerConfig'));
  const allowOpacity = colorPickerConfig.enableOpacity;

  const handleSvgColorChange = useCallback(
    (newColor: string | undefined) => {
      if (!structureIndex || (!newColor && !colorPickerConfig.noColor) || currentColor.new === newColor) {
        return;
      }

      const color = {
        previous: currentColor.previous,
        new: newColor || EMPTY_RGBA_STRING,
      };
      dispatch(updateImageElementColorsOperation(structureIndex, color));
    },
    [structureIndex, currentColor, colorPickerConfig.noColor],
  );

  // if image is not svg or if there aren't detected any colors and it's not digitized, do not render
  if (!imageColors.length || !currentColor) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <DrawerHeader title={t('editor-recolor')} closeMenu={closeMenuCb} />
      ) : (
        <TabContentHeader title={t('editor-recolor')} />
      )}
      <div className={cn({ [styles.mobile]: isMobile })}>
        <ContentBlock scroll>
          <ColorPicker
            color={currentColor.new}
            onColorChange={handleSvgColorChange}
            allowOpacity={allowOpacity}
            colors={colorPickerConfig.allowedColors}
            allowNoColor={!!colorPickerConfig.noColor}
          />
        </ContentBlock>
      </div>
    </>
  );
}

export default React.memo(ImageColorTabContent);

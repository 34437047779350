import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  children: number;
  className?: string;
}

function Badge({ children, className }: Props) {
  return <div className={cn('cy-tab-badge', styles.badge, className)}>{children > 99 ? '!' : children}</div>;
}

export default React.memo(Badge);

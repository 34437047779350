/* eslint-disable max-len */
import React from 'react';

import Icon from './index';

interface Props {
  className?: string;
}

function IconVariants({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1.5" width="7" height="7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 13.5L19 12.5V19.5L12 18V13.5Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="4.5" cy="16" r="3.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.5 1.5L19 8.5H12L15.5 1.5Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export default IconVariants;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { deleteConditionAction } from 'editor/src/store/design/slice';
import { ConditionAddress } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import updateHiddenElementsOperation from './updateHiddenElementsOperation';

const deleteConditionOperation = (address: ConditionAddress) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(deleteConditionAction(address));
    dispatch(updateHiddenElementsOperation());
  });
};

export default deleteConditionOperation;

import React from 'react';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import isTopMenuDropdownVisible from 'editor/src/store/app/selector/isTopMenuDropdownVisible';
import { TopMenuDropdown } from 'editor/src/store/app/types';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconPosition from 'editor/src/component/Icon/IconPosition';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';
import MenuButton from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';

export const canShow: CanShow = (_, { isSelectionLocked, isElementEmpty }) => !isSelectionLocked && !isElementEmpty;

type Props = Pick<MenuItemProps, 'mode' | 'close' | 'isMobile'>;

function ButtonPosition({ mode, close, isMobile }: Props) {
  const isActive = useSelector((state) =>
    isMobile
      ? isSidebarTabActive(state, TAB_NAMES.POSITION)
      : isTopMenuDropdownVisible(state, TopMenuDropdown.Position),
  );
  const dispatch = useDispatch();

  const handleClick = () => {
    if (isMobile) {
      if (isActive) {
        dispatch(setSidebarActiveTabOperation(-1));
      } else {
        dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.POSITION));
      }
    } else {
      dispatch(setActiveTopMenuDropdownOperation(TopMenuDropdown.Position));
      close?.();
    }
  };

  return (
    <MenuButton
      Icon={IconPosition}
      on={isActive}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-position"
      className="cy-button-position"
    />
  );
}

export default React.memo(ButtonPosition);

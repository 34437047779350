import { MediaText } from 'editor/src/store/design/types';

import { convFn } from 'editor/src/util/convFn';

import { DIGITIZATION_PREVIEW_SIZE } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/useDigitizedImage';
import useDigitizedAsset from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useDigitizedAsset';

import { getRenderedTextObject } from './updateTextElementWithoutRender';
import useDigitizedTextFabricOptionsUpdate from './useDigitizedTextFabricOptionsUpdate';

function useDigitizedText(element: MediaText, editModeEnabled: boolean, px2mm: convFn) {
  const { digitizedAsset, showDigitizedAsset } = useDigitizedAsset(
    element,
    editModeEnabled,
    (imageId, productId, variantKey, elementId) => ({
      elementType: 'text',
      elementId,
      payload: {
        textElement: element,
        output_px_width: DIGITIZATION_PREVIEW_SIZE,
        path: getRenderedTextObject(element).toSVGRelativeCoords(),
        variantKey,
        elementUuid: element.uuid,
      },
      imageId,
      productId,
      reuseIfExist: true,
    }),
  );

  const getFabricOptionsOnImageUpdate = useDigitizedTextFabricOptionsUpdate(element, px2mm, digitizedAsset);

  return {
    digitizedAsset,
    showDigitizedAsset,
    getFabricOptionsOnImageUpdate,
  };
}

export default useDigitizedText;

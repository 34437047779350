import React from 'react';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import CarouselArrow from './CarouselArrow';
import { responsive } from './config';

interface Props {
  children: React.ReactNode;
}

function GroupCarousel({ children }: Props) {
  return (
    <Carousel
      autoPlay={false}
      shouldResetAutoplay={false}
      responsive={responsive}
      draggable={false}
      customLeftArrow={<CarouselArrow type="left" />}
      customRightArrow={<CarouselArrow type="right" />}
    >
      {children}
    </Carousel>
  );
}

export default React.memo(GroupCarousel);

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SchemasList from 'editor/src/component/DesktopSidebar/TabContents/LayoutsTabContent/SchemasList';

import DrawerHeader from '../../DrawerHeader';

import styles from './index.module.scss';

interface Props {
  closeMenuCb?: () => void;
}
function LayoutsMobileMenu({ closeMenuCb }: Props) {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.LayoutsMobileMenu, 'cy-tab-layouts')}>
      <DrawerHeader title={t('editor-layouts')} closeMenu={closeMenuCb} />
      <div className={cn('px-2', styles.layoutsContainer)}>
        <SchemasList />
      </div>
    </div>
  );
}

export default React.memo(LayoutsMobileMenu);

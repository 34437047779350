import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import addNewImageAssetToPageOperation from 'editor/src/store/design/operation/addNewImageAssetToPageOperation';
import getCurrentDpiLevels from 'editor/src/store/design/selector/getCurrentDpiLevels';
import applyImageOperation from 'editor/src/store/editor/operation/applyImageOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { DraggableItem } from 'editor/src/store/editor/types';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { DEFAULT_MEDIUM_DPI } from 'editor/src/store/hostSettings/slice';
import { PluginName } from 'editor/src/store/plugins/types';

import noWallpaperImageSelected from 'editor/src/static/no-wallpaper-image-selected.svg';
import useOnDrop from 'editor/src/util/dnd/useOnDrop';

import Button from 'editor/src/component/Button';
import H2 from 'editor/src/component/Header/H2';

import styles from './index.module.scss';

interface Props {
  spreadWidth?: number;
  spreadHeight?: number;
}

function WallpaperEmptyPlaceholder({ spreadWidth, spreadHeight }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const spreadIndex = useSelector(getCurrentSpreadIndex);

  const onDrop = (draggedItem: DraggableItem) => {
    if (draggedItem.itemType === 'image' && !draggedItem.isMultipleItems) {
      dispatch(applyImageOperation(draggedItem.itemId));
    } else {
      if (!draggedItem.itemThumbnail) {
        return;
      }
      if (!draggedItem.isMultipleItems) {
        void dispatch(
          addNewImageAssetToPageOperation(
            spreadIndex,
            0,
            draggedItem.itemId,
            draggedItem.sourceId as PluginName,
            draggedItem?.itemThumbnail,
            undefined,
          ),
        );
      }
    }
  };

  const { elementRef, isDraggingOver, isDragging } = useOnDrop(onDrop);

  const dimensions = useSelector((state) => state.design.designData?.dimensions);
  const dpiLevels = useSelector(getCurrentDpiLevels, shallowEqual);

  const width = dimensions?.width || spreadWidth;
  const height = dimensions?.height || spreadHeight;

  const openGallery = () => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.GALLERY));
  };

  return (
    <div className={cn(styles.WallpaperEmptyPlaceholder, 'cy-empty-placeholder')}>
      <div
        className={cn(styles.container, 'drop-zone', {
          [styles.dropTarget]: isDraggingOver && isDragging,
          [styles.dropActive]: isDragging,
        })}
        ref={elementRef}
      >
        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        <img src={noWallpaperImageSelected} alt={t('Upload image to create a wallpaper')} draggable={false} />

        <div className={cn(styles.textBox, 'row-flex', 'row-flex--column', 'row-flex--middle')}>
          <H2 className={styles.heading}>{t('Upload image to create a wallpaper')}</H2>
          <div className={styles.description}>
            {t('Simply drop your image file here or upload it from your device')}
          </div>
          {!!(width && height) && (
            <div className={styles.notes}>
              {t('min-image-sizes', {
                width: width / 10,
                height: height / 10,
                dpi: dpiLevels?.medium ?? DEFAULT_MEDIUM_DPI,
              })}
            </div>
          )}
          <Button onClick={openGallery} primary className="mt-2">
            {t('Add photo')}
          </Button>
        </div>
        <div className={styles.dropPlaceholder}>
          <span className={styles.dropText}>{t('Drop your file here')}</span>
        </div>
      </div>
    </div>
  );
}

export default React.memo(WallpaperEmptyPlaceholder);

import React from 'react';

import { Coords, Dimensions } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useSelector } from 'editor/src/store/hooks';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import useGetCenter from 'editor/src/component/EditorArea/Gestures/useGetCenter';
import useMinZoom from 'editor/src/component/EditorArea/Gestures/useMinZoom';

import HorizontalScrollbar from './HorizontalScrollbar';
import VerticalScrollbar from './VerticalScrollbar';

interface Props {
  spreadCoords: Coords;
  spreadWidth: number;
  spreadHeight: number;
  canvasDim: Dimensions;
  bottomBarHeight: number;
  bottomBarOffset: number;
}

function Scrollbar({ spreadCoords, spreadWidth, spreadHeight, canvasDim, bottomBarHeight, bottomBarOffset }: Props) {
  const { mm2px } = useFabricUtils();
  const fabricCanvas = useFabricCanvas();
  const spreadIndex = useSelector(getCurrentSpreadIndex);
  const { minZoom } = useMinZoom(canvasDim, spreadIndex, spreadWidth, spreadHeight, mm2px);
  const { getVtpXBoundaries, getVtpYBoundaries } = useGetCenter(
    fabricCanvas,
    canvasDim,
    spreadIndex,
    spreadCoords,
    spreadWidth,
    spreadHeight,
    minZoom,
    mm2px,
  );

  return (
    <>
      <HorizontalScrollbar
        bottomBarHeight={bottomBarHeight}
        bottomBarOffset={bottomBarOffset}
        canvasSize={canvasDim.width}
        getTransformBoundaryCoordinates={getVtpXBoundaries}
      />
      <VerticalScrollbar
        bottomBarHeight={bottomBarHeight}
        bottomBarOffset={bottomBarOffset}
        canvasSize={canvasDim.height}
        getTransformBoundaryCoordinates={getVtpYBoundaries}
      />
    </>
  );
}

export default React.memo(Scrollbar);

import React from 'react';

import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import IconProperties from 'editor/src/component/Icon/IconProperties';
import { CanShow, MenuContext, MenuItemProps } from 'editor/src/component/Menu/type';
import usePluginButton from 'editor/src/component/TopMenuDesktop/usePluginButton';

import MenuButton from './MenuButton';

const canUsePlugin = (pluginName: PluginName, { plugins }: MenuContext) =>
  plugins.some((plugin) => plugin.name === pluginName && plugin.state && plugin.state !== PluginState.NOT_VISIBLE);

export const canShow: CanShow = (state, menuContext) => canUsePlugin(PluginName.TextEffects, menuContext);

type Props = Pick<MenuItemProps, 'mode' | 'close' | 'isMobile'>;

function ButtonEffects({ mode, close, isMobile }: Props) {
  const plugin = useSelector((state) => getPlugin(state, PluginName.TextEffects));
  const { isActive, activatePluginTab } = usePluginButton({
    close,
    isMobile,
    plugin,
    tabName: TAB_NAMES.EFFECTS,
  });

  return (
    <MenuButton
      Icon={IconProperties}
      on={isActive}
      mode={mode}
      handleClick={activatePluginTab}
      i18nKey="editor-effects"
      className="cy-button-effects"
    />
  );
}

export default React.memo(ButtonEffects);

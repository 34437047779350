import React from 'react';
import { useTranslation } from 'react-i18next';

import setSettingsValueOperation from 'editor/src/store/editor/operation/setSettingsValueOperation';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Checkbox from 'editor/src/component/Checkbox';
import SettingsItem from 'editor/src/component/DesktopSidebar/TabContents/SettingsTabContent/SettingsItem';

function ShowBleedsCheckbox() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isChecked = useSelector((state) => getSettingsValue(state, SettingsProperty.showBleeds));

  const handleChange = () => {
    dispatch(setSettingsValueOperation(SettingsProperty.showBleeds, !isChecked));
  };

  return (
    <SettingsItem descriptionTranslationKey="editor-settings-bleeds-description">
      <Checkbox
        on={isChecked}
        onChange={handleChange}
        label={t('editor-settings-bleeds')}
        className="cy-show-bleeds-checkbox"
      />
      <svg
        width="286"
        height="12"
        viewBox="0 0 286 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="286" height="12" fill="url(#svgPatternBleed)" />
        <defs>
          <image
            id="svgPatternBleedImage"
            width="8"
            height="8"
            // eslint-disable-next-line max-len
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAFhJREFUeNpifCGn2iDx6HYDAxQA+cuAlBqMzwTEPiBFDAiQDsS3kBUwICsCmvYZWRETkk6sipiQjcOmiBEowAtkzER2GBBsgTmcEepynIoYkbyHVRFAgAEAh+sn5/A80SEAAAAASUVORK5CYII="
          />
        </defs>
      </svg>
    </SettingsItem>
  );
}

export default React.memo(ShowBleedsCheckbox);

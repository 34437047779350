import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import addMultipleImagesToPageOperation from 'editor/src/store/design/operation/addMultipleImagesToPageOperation';
import getSelectedGalleryImagesCount from 'editor/src/store/gallery/selector/getSelectedGalleryImagesCount';
import getSelectedGalleryImagesIds from 'editor/src/store/gallery/selector/getSelectedGalleryImagesIds';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import IconPlus from 'editor/src/component/Icon/IconPlus';

import styles from './index.module.scss';

interface Props {
  onAddImagesCb?: () => void;
}

function AddSelectedImagesToDesign({ onAddImagesCb }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedImageCount, selectedGalleryImagesIds } = useSelector(
    (state) => ({
      selectedImageCount: getSelectedGalleryImagesCount(state),
      selectedGalleryImagesIds: getSelectedGalleryImagesIds(state),
    }),
    shallowEqual,
  );

  const buttonText =
    selectedImageCount === 1
      ? t('Add one image to design')
      : t('Add images to design', { imageCount: selectedImageCount });

  const onClick = () => {
    onAddImagesCb?.();
    dispatch(addMultipleImagesToPageOperation(selectedGalleryImagesIds));
  };

  return (
    <Button
      primary
      stopPropagation
      className={cn(styles.button, 'cy-add-selected-images-to-design-button')}
      onClick={onClick}
    >
      <IconPlus />
      <span className={styles.buttonText}>{buttonText}</span>
    </Button>
  );
}

export default React.memo(AddSelectedImagesToDesign);

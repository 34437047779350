import cn from 'classnames';
import React, { useCallback } from 'react';

import redoStoreOperation from 'editor/src/store/editorModules/undoRedo/operation/redoStoreOperation';
import undoStoreOperation from 'editor/src/store/editorModules/undoRedo/operation/undoStoreOperation';
import getNextStatesCount from 'editor/src/store/editorModules/undoRedo/selector/getNextStatesCount';
import getPreviousStatesCount from 'editor/src/store/editorModules/undoRedo/selector/getPreviousStatesCount';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconRedo from 'editor/src/component/Icon/IconRedo';
import IconUndo from 'editor/src/component/Icon/IconUndo';
import { MenuItemProps, CanShow } from 'editor/src/component/Menu/type';
import MenuButton from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';

import styles from './index.module.scss';

type Props = Pick<MenuItemProps, 'mode'>;

export const canShow: CanShow = () => true;

function UndoRedo({ mode }: Props) {
  const nextAvailable = !!useSelector(getNextStatesCount);
  const prevAvailable = !!useSelector(getPreviousStatesCount);
  const dispatch = useDispatch();

  const handleClickUndo = useCallback(() => dispatch(undoStoreOperation()), []);
  const handleClickRedo = useCallback(() => dispatch(redoStoreOperation()), []);

  return (
    <div className={cn(styles.ButtonUndoRedo, mode === 'label-icon' ? 'labelIcon' : mode)}>
      <MenuButton
        Icon={IconUndo}
        mode={mode}
        disabled={!prevAvailable}
        handleClick={handleClickUndo}
        i18nKey="editor-undo"
        className="cy-button-undo"
      />
      <MenuButton
        Icon={IconRedo}
        mode={mode}
        disabled={!nextAvailable}
        handleClick={handleClickRedo}
        i18nKey="editor-redo"
        className="cy-button-redo"
      />
    </div>
  );
}

export default React.memo(UndoRedo);

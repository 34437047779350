/* eslint-disable max-len */
import React from 'react';

import Icon from './index';

interface Props {
  className?: string;
}

function IconStore({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 7L2 19H20V7" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <rect
          x="6"
          y="12"
          width="10"
          height="7"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 1L7 1V4C7 5.65685 5.65685 7 4 7V7C2.34315 7 1 5.65685 1 4L1 1Z"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 1L15 1V3C15 5.20914 13.2091 7 11 7V7C8.79086 7 7 5.20914 7 3V1Z"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 1L21 1V4C21 5.65685 19.6569 7 18 7V7C16.3431 7 15 5.65685 15 4V1Z"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

export default React.memo(IconStore);

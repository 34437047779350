/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  color?: string;
}

function IconFontColor({ className, color }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M7.80982 36C7.04792 36 6.3939 35.7269 5.84776 35.1808C5.30163 34.6347 5.02856 33.9806 5.02856 33.2187C5.02856 32.4318 5.30163 31.7653 5.84776 31.2192C6.3939 30.6731 7.04792 30.4 7.80982 30.4H32.1902C32.9521 30.4 33.6061 30.6731 34.1522 31.2192C34.6984 31.7653 34.9714 32.4318 34.9714 33.2187C34.9714 33.9806 34.6984 34.6347 34.1522 35.1808C33.6061 35.7269 32.9521 36 32.1902 36H7.80982Z"
          fill={color}
        />
        <path
          d="M12.2286 25.333C11.6446 25.333 11.2192 25.1426 10.9522 24.7616C10.6859 24.3806 10.6542 23.911 10.8571 23.3527L17.7902 5.52411C17.9425 5.11756 18.2348 4.7619 18.667 4.45714C19.0985 4.15238 19.5428 4 20 4C20.4571 4 20.9015 4.15238 21.333 4.45714C21.7652 4.7619 22.0574 5.11756 22.2098 5.52411L29.1428 23.3527C29.3458 23.911 29.3205 24.3806 29.067 24.7616C28.8128 25.1426 28.3809 25.333 27.7714 25.333C27.4923 25.333 27.2256 25.2443 26.9714 25.067C26.7172 24.889 26.5396 24.6729 26.4384 24.4187L24.6857 19.6955H15.3143L13.5616 24.4187C13.4604 24.6729 13.2891 24.889 13.0478 25.067C12.8064 25.2443 12.5333 25.333 12.2286 25.333ZM16.3045 17.067H23.6955L20.0759 7.2384H19.9241L16.3045 17.067Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconFontColor;

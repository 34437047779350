import cn from 'classnames';
import React, { useState } from 'react';

import IconArrowDown from 'editor/src/component/Icon/IconArrowDown';

import { Props as SectionProps } from './Section';
import { SectionActiveContext } from './useSectionActive';

import styles from './index.module.scss';

interface Props {
  children: React.ReactElement<SectionProps>[];
  className?: string;
}

const HEADER_HEIGHT = 44;
export const OPENING_ANIM_TIME = 300;

function getTransform(index: number, activeIndex: number, total: number) {
  return index > activeIndex ? `translate3d(0,calc(100% - ${total * HEADER_HEIGHT}px),0)` : undefined;
}

function Sections({ children, className }: Props) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={cn(styles.Sections, className, 'cy-personalization-section')}>
      {children.map((child, i) => (
        <div
          key={i}
          style={{
            top: i * HEADER_HEIGHT,
            transform: getTransform(i, activeIndex, children.length),
          }}
          className={cn(styles.Section, 'cy-section', {
            [styles.active]: activeIndex === i,
          })}
        >
          <div className={styles.header} style={{ height: HEADER_HEIGHT }} onClick={() => setActiveIndex(i)}>
            <div className={styles.title}>{child.props.title}</div>
            <div className={styles.arrow}>
              <IconArrowDown />
            </div>
          </div>
          <div className={styles.content} style={{ marginBottom: HEADER_HEIGHT * (children.length - 1) }}>
            <SectionActiveContext.Provider value={activeIndex === i}>{child}</SectionActiveContext.Provider>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Sections;

import { useTranslation } from 'react-i18next';

import { useSelector } from '../store/hooks';
import getHostSetting from '../store/hostSettings/selector/getHostSetting';

export interface EditorModeTranslationKeys {
  galleryTab: string;
  galleryFilesButton: string;
}

function useEditorModeTranslations(): EditorModeTranslationKeys {
  const editorMode = useSelector((state) => getHostSetting(state, 'editorMode'));
  const { t } = useTranslation();

  const galleryTab = editorMode ? t('editor-gallery') : t('editor-gallery-files');
  const galleryFilesButton = editorMode ? t('editor-add-photos') : t('editor-add-files');

  return {
    galleryTab,
    galleryFilesButton,
  };
}

export default useEditorModeTranslations;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updatePluginsTabsOperation from 'editor/src/store/editorModules/sidebar/operation/updatePluginsTabsOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { setPluginsAction } from 'editor/src/store/plugins/slice';
import { Plugin } from 'editor/src/store/plugins/types';

const setPluginsOperation = (plugins: Plugin[]) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(setPluginsAction(plugins));
    dispatch(updatePluginsTabsOperation());
  });
};

export default setPluginsOperation;

import { useMemo } from 'react';

import { DesignDimensions, DimensionRestrictions, Dimensions } from 'editor/src/store/design/types';
import { GalleryImage } from 'editor/src/store/gallery/types';
import { ProductControlSize, ProductSizeUnit } from 'editor/src/store/variants/types';

import { Size } from './ProductSize';
import useSizesWithAdditionalInfo from './useSizesWithAdditionalInfo';

function useDefaultSizes(
  sizeControl: ProductControlSize,
  unit: ProductSizeUnit,
  dimensions: DesignDimensions,
  imageAsset: GalleryImage | undefined,
  getMaxImageDimensions: (dimensionRestrictions: DimensionRestrictions) => Dimensions | undefined,
) {
  const { defaultSizeCalculationParameters } = sizeControl;

  const sizes = useMemo(() => {
    if (!defaultSizeCalculationParameters) {
      return [];
    }
    const { dimensionIncrement, dimensionMax, referenceDimension, otherDimensionValues } =
      defaultSizeCalculationParameters[unit];
    const sizes: Size[] = [];

    otherDimensionValues.forEach((otherDimensionValue) => {
      let referenceDimensionValue = dimensionIncrement;
      while (referenceDimensionValue <= dimensionMax) {
        sizes.push({
          width: referenceDimension === 'width' ? referenceDimensionValue : otherDimensionValue,
          height: referenceDimension === 'width' ? otherDimensionValue : referenceDimensionValue,
        });
        referenceDimensionValue += dimensionIncrement;
      }
    });

    return sizes;
  }, [defaultSizeCalculationParameters, unit]);

  const { sizes: defaultSizes, additionalInfo: additionalDefaultSizesInfo } = useSizesWithAdditionalInfo(
    sizes,
    dimensions,
    imageAsset,
    getMaxImageDimensions,
  );
  return { defaultSizes, additionalDefaultSizesInfo };
}

export default useDefaultSizes;

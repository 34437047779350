import { captureException } from '@sentry/react';

import { removeDesignTemplateAction } from 'editor/src/store/editorModules/templates/slice';
import { Thunk } from 'editor/src/store/hooks';

export interface RemoveDesignTemplatesPayload {
  id: string;
  success: boolean;
  error?: string;
}

const removeDesignTemplateOperation =
  ({ id, success, error }: RemoveDesignTemplatesPayload): Thunk =>
  (dispatch) => {
    if (!success) {
      captureException(new Error(error));
      return;
    }

    dispatch(removeDesignTemplateAction({ id }));
  };

export default removeDesignTemplateOperation;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function EtsyIcon({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M5.01808 3.67172C5.06155 3.9911 5.16298 4.06714 5.81503 4.20402C7.1626 4.47777 7.09015 4.05193 7.09015 10.9566C7.09015 17.8917 7.1626 17.4811 5.78605 17.8004C5.11951 17.9525 4.9891 18.0438 4.9891 18.3175V18.6521L17.3781 18.5913L17.4795 17.5571C17.5374 16.9944 17.6389 15.9906 17.7113 15.3367C17.8128 14.2569 17.8128 14.1656 17.5664 14.1656C17.3636 14.1656 17.1462 14.5154 16.7405 15.4279C16.3927 16.2188 16.0015 16.8575 15.6537 17.1769L15.1176 17.6636H12.5529C10.7706 17.6484 9.9157 17.6027 9.75631 17.4659C9.26365 17.0704 9.1912 16.6446 9.1912 14.0287V11.4281H10.9735C13.06 11.4281 13.2484 11.5345 13.5382 12.7816C13.6831 13.4356 13.77 13.5573 14.0598 13.5573H14.3931L14.4801 8.08219H14.1178C13.7845 8.08219 13.7266 8.17344 13.5527 8.97949C13.4513 9.48137 13.2484 9.99847 13.118 10.1201C12.9586 10.2722 12.35 10.3787 11.0314 10.4547L9.1912 10.5612L9.22018 7.45864L9.26365 4.3561L11.9443 4.31048C13.9149 4.28006 14.7119 4.32568 14.9727 4.46256C15.3639 4.69069 15.9435 5.93779 16.0884 6.86551C16.1609 7.36739 16.2333 7.47385 16.5231 7.47385C16.8129 7.47385 16.8564 7.3826 16.9289 6.6678C16.9723 6.24196 17.0303 5.29903 17.0593 4.58423L17.1027 3.2915H4.97461L5.01808 3.67172Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
}

export default EtsyIcon;

import { useSelector } from 'editor/src/store/hooks';

export const WIDTH = 150;
export const WIDTH_WITH_HELP = 200;

function useBottomRightControlWidth() {
  const enableHelperCenter = useSelector((state) => state.hostSettings.enableHelperCenter);
  return enableHelperCenter ? WIDTH_WITH_HELP : WIDTH;
}

export default useBottomRightControlWidth;

import { RootState } from 'editor/src/store/index';

import { PluginName } from '../../plugins/types';

function getUsedPersonalizationPlugins(state: RootState): PluginName[] {
  const personalizationPlugins = new Set<PluginName>([
    PluginName.personalizationStudio,
    PluginName.emailWorkflowPersonalization,
    PluginName.shopifyPersonalization,
    PluginName.shopifyPersonalizationInEditor,
    PluginName.AIPersonalization,
  ]);

  return state.design.globalPlugins.filter((plugin) =>
    personalizationPlugins.has(plugin as PluginName),
  ) as PluginName[];
}

export default getUsedPersonalizationPlugins;

import React from 'react';
import { useTranslation } from 'react-i18next';

import getDesignData from 'editor/src/store/design/selector/getDesignData';
import { EmptyPageWarning } from 'editor/src/store/editorModules/warnings/types';
import { useSelector } from 'editor/src/store/hooks';

import getSpreadName from 'editor/src/util/design/getSpreadName';

import AbstractWarningItem from './AbstractWarningItem';

interface Props {
  warning: EmptyPageWarning;
  selected: boolean;
}

function EmptyPageWarningItem({ warning, selected }: Props) {
  const { t } = useTranslation();
  const canRemovePages = useSelector((state) => !!getDesignData(state)?.page_count_limit);

  const spreadName = getSpreadName({ spreadIndex: warning.spreadIndex }).join(' - ');

  return (
    <AbstractWarningItem warning={warning} selected={selected} title={t('Empty page')} subtitle={spreadName}>
      {canRemovePages ? t('editor-empty-page-warning-can-delete') : t('editor-empty-page-warning')}
    </AbstractWarningItem>
  );
}

export default React.memo(EmptyPageWarningItem);

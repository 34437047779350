import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import removeMediaElementAdjustmentsOperation from 'editor/src/store/design/operation/removeMediaElementAdjustmentsOperation';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { MimeType } from 'editor/src/store/gallery/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import isPremiumIconVisible from 'editor/src/store/plugins/selector/isPremiumIconVisible';
import showPremiumPrompt from 'editor/src/store/plugins/selector/showPremiumPrompt';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import DrawerHeader from 'editor/src/component/BottomBarMobile/DrawerHeader';
import Button from 'editor/src/component/Button';
import ButtonBackToTab from 'editor/src/component/DesktopSidebar/TabContents/Buttons/ButtonBackToTab';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import GelatoPrompt from 'editor/src/component/GelatoPrompt';
import IconSettings from 'editor/src/component/Icon/IconSettings';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import Adjustment from './Adjustment';
import ADJUSTMENTS from './adjustments';

import styles from './index.module.scss';

export const ALLOW_FREE = 2;

function AdjustmentsTabContent({ title, closeMenuCb }: TabContentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uuid = useSelector(getSelectedImageUuid);
  const structureIndex = useSelector((store) => getStructureIndexByElementUuidMemoized(store, uuid || -1));
  const nonFunctional = useSelector(
    (state) => getPlugin(state, PluginName.Filters)?.state === PluginState.NON_FUNCTIONAL,
  );
  const { gelatoPrompt, premiumIconVisible, editImageFlag } = useSelector((state) => {
    const plugin = getPlugin(state, PluginName.Filters);
    return {
      gelatoPrompt: plugin && showPremiumPrompt(plugin),
      premiumIconVisible: isPremiumIconVisible(state, PluginName.Filters),
      editImageFlag: state.hostSettings.editImageFlag,
    };
  }, shallowEqual);

  const elements = useSelector(getSelectedMediaElementsObjects);
  const isMobile = useIsMobile();
  const firstElement = elements[0];
  const imageType = useSelector((state) =>
    firstElement?.type === 'image' ? getImageById(state, firstElement.imageId)?.type : MimeType.SVG,
  );

  if (!uuid || !structureIndex || imageType === MimeType.SVG || firstElement?.type !== 'image') {
    return null;
  }

  const resetAdjustments = () => {
    dispatch(removeMediaElementAdjustmentsOperation(structureIndex));
  };

  return (
    <div className={cn(styles.wrapper, 'cy-adjustments-contnent')}>
      {isMobile ? (
        <DrawerHeader title={title || t('editor-adjustments')} closeMenu={closeMenuCb} />
      ) : (
        <TabContentHeader
          title={title || t('editor-adjustments')}
          icon={editImageFlag ? <ButtonBackToTab mode="icon" tabName={TAB_NAMES.EDIT_IMAGE} /> : <IconSettings />}
        />
      )}
      {gelatoPrompt && <GelatoPrompt prompt={gelatoPrompt} pluginName={PluginName.Filters} />}
      <ContentBlock scroll fillFlex>
        <Button className={cn(styles.resetButton, 'cy-adjustment-reset-button')} secondary onClick={resetAdjustments}>
          {t('editor-reset-adjustments')}
        </Button>
        {ADJUSTMENTS.filter((adjustment) => !adjustment.hidden).map((adjustment, idx) => (
          <Adjustment
            element={firstElement}
            address={structureIndex}
            adjustment={adjustment}
            key={adjustment.name}
            disabled={nonFunctional && idx >= ALLOW_FREE}
            showPremiumIcon={premiumIconVisible && idx >= ALLOW_FREE}
          />
        ))}
      </ContentBlock>
    </div>
  );
}

export default AdjustmentsTabContent;

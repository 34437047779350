import React from 'react';

import IconAlignLeft from 'editor/src/component/Icon/IconAlignLeft';

interface Props {
  className?: string;
}

function IconPosition({ className }: Props) {
  return <IconAlignLeft className={className} />;
}

export default React.memo(IconPosition);

import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { RootState } from 'editor/src/store/index';

const getDefaultZoom = (state: RootState) => {
  const spreadZoom = state.editorModules.panAndZoom.defaultSpreadZoom[getCurrentSpreadIndex(state)] as
    | number
    | undefined;
  return spreadZoom ?? state.editorModules.panAndZoom.defaultZoom;
};

export default getDefaultZoom;

import cn from 'classnames';
import React from 'react';

import IconChevronUp from 'editor/src/component/Icon/IconChevronUp';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
  setCollapsed: (collapsed: boolean) => void;
  collapsed: boolean;
  className?: string;
  headerClassName?: string;
  subheader?: React.ReactNode;
  subheaderClassName?: string;
  onTransitionEnd?: () => void;
}

function Accordion({
  header,
  headerClassName,
  children,
  collapsed,
  setCollapsed,
  className,
  subheader,
  subheaderClassName,
  onTransitionEnd,
}: Props) {
  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }

  return (
    <>
      <div
        onTransitionEnd={onTransitionEnd}
        className={cn(styles.Accordion, className, {
          [styles.collapsed]: collapsed,
        })}
        onClick={toggleCollapsed}
      >
        <div className={cn(styles.header, headerClassName)}>
          {header}
          <div
            className={cn(styles.buttonToggle, {
              'cy-accordion-collapse-btn': !collapsed,
              'cy-accordion-expand-btn': collapsed,
            })}
          >
            <IconChevronUp />
          </div>
        </div>
        {subheader && <div className={cn(styles.subheader, 'pb-2', subheaderClassName)}>{subheader}</div>}
      </div>
      <div className={cn(styles.content, { [styles.collapsed]: collapsed })}>
        {children}
        <div className="pt-2" />
      </div>
    </>
  );
}

export default Accordion;

import { Props as FabricLineComponentProps } from 'editor/src/component/EditorArea/fabricComponents/FabricLineComponent';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';

const MARGIN = 150;

export const getBasePropsForLine = (
  i: number,
): Pick<
  FabricLineComponentProps,
  'stroke' | 'strokeWidth' | 'selectable' | 'evented' | 'strokeDashArray' | 'zIndex'
> => ({
  stroke: `rgba(0, 0, 0, ${i % 10 === 0 ? 0.5 : 0.1})`,
  strokeWidth: 1,
  selectable: false,
  evented: false,
  strokeDashArray: [1, 3],
  zIndex: zIndex.GRID_LINE,
});

export const calculateLineCoords = ({
  i,
  left,
  top,
  isVertical,
  mm2px,
  end,
  start,
}: {
  i: number;
  left: number;
  top: number;
  end: number;
  start: number;
  mm2px: (size: number) => number;
  isVertical: boolean;
}): Pick<FabricLineComponentProps, 'x1' | 'x2' | 'y1' | 'y2'> => {
  if (isVertical) {
    return {
      x1: left + mm2px(i),
      y1: top - mm2px(Math.abs(start)),
      x2: left + mm2px(i),
      y2: top + mm2px(end),
    };
  }
  return {
    x1: left - mm2px(Math.abs(start)),
    y1: top + mm2px(i),
    x2: left + mm2px(end),
    y2: top + mm2px(i),
  };
};

export const calculateGridPosition = (spreadSize: number): { start: number; end: number } => {
  const spreadSizeWithMargins = spreadSize + MARGIN * 2;
  const width = spreadSizeWithMargins + (100 - (spreadSizeWithMargins % 100));
  return {
    start: -(width - spreadSize) / 2,
    end: (width + spreadSize) / 2,
  };
};

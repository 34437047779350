import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import getDesignCalendarConfigs from 'editor/src/store/design/selector/getDesignCalendarConfigs';
import { setShowCalendarEventsFlagAction } from 'editor/src/store/design/slice';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import SwitchButton from 'editor/src/component/SwitchButton';
import TabBar from 'editor/src/component/TabBar';

import CustomEvents from './CustomEvents';
import PublicHolidays from './PublicHolidays';

import styles from './index.module.scss';

function CalendarEventsTabContent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const enableCalendarEvents = useSelector((state) => state.hostSettings.enableCalendarEvents);
  const { showEvents, allowPersonalization } = useSelector((state) => getDesignCalendarConfigs(state));

  const calendarEventsTabs: { key: 'holidays' | 'your-events'; label: string }[] = [
    {
      key: 'holidays',
      label: t('calendar-holidays'),
    },
    {
      key: 'your-events',
      label: t('calendar-custom-events'),
    },
  ];
  const [activeTab, setActiveTab] = useState(calendarEventsTabs[0].key);

  const toggleShowEvents = () => {
    dispatch(setShowCalendarEventsFlagAction(!showEvents));
  };

  if (!enableCalendarEvents) {
    return null;
  }

  return (
    <>
      <TabContentHeader className={styles.calendarEventsHeader} title={t('calendar-events')} />
      <ContentBlock className={styles.calendarEventsTabContent}>
        {!isEmbedded && ( // TODO replace with host flag
          <div className={styles.showCalendarEventsToggle}>
            <SwitchButton on={Boolean(showEvents)} onSwitch={toggleShowEvents} />
            {t('Show events in the calendar')}
          </div>
        )}
        {(!isEmbedded || allowPersonalization) && (
          <TabBar<'holidays' | 'your-events'> tabs={calendarEventsTabs} onSelect={setActiveTab} activeTab={activeTab} />
        )}
        {activeTab === calendarEventsTabs[0].key ? <PublicHolidays /> : <CustomEvents />}
      </ContentBlock>
    </>
  );
}

export default React.memo(CalendarEventsTabContent);

import React from 'react';
import { TFunction } from 'react-i18next';

import IconPageView from '../Icon/IconPageView';
import IconSpreadView from '../Icon/IconSpreadView';

import { ViewMode } from '.';

import styles from './index.module.scss';

interface OnChangeCallback {
  (mode: ViewMode): void;
}

function getToggleOptions(
  selectedMode: ViewMode,
  onChangeCallback: OnChangeCallback,
  t: TFunction<'translation', undefined>,
) {
  return [
    {
      content: (
        <div className={styles.toggleOption}>
          <IconSpreadView />
          <div>{t('All pages')}</div>
        </div>
      ),
      value: 'spreads',
      callback: () => onChangeCallback('spreads'),
    },
    {
      content: (
        <div className={styles.toggleOption}>
          <IconPageView />
          <div>{t('Page')}</div>
        </div>
      ),
      value: 'canvas',
      callback: () => onChangeCallback('canvas'),
    },
  ];
}

export default getToggleOptions;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconTopPosition({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M5.14285 4.00014L18.8571 4C19.4883 3.99999 20 4.51166 20 5.14285C20 5.77403 19.4883 6.28571 18.8572 6.28571L13.1429 6.28577L13.1429 16.098L15.7633 13.4776C16.2096 13.0313 16.9332 13.0313 17.3795 13.4776C17.8259 13.9239 17.8259 14.6475 17.3795 15.0938L12.8081 19.6653C12.3618 20.1116 11.6382 20.1116 11.1919 19.6653L6.62045 15.0938C6.17414 14.6475 6.17414 13.9239 6.62045 13.4776C7.06676 13.0313 7.79038 13.0313 8.23669 13.4776L10.8571 16.098L10.8571 6.28579L5.14287 6.28585C4.51169 6.28586 4.00001 5.77419 4 5.14301C3.99999 4.51182 4.51166 4.00014 5.14285 4.00014Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconTopPosition;

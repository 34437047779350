import cn from 'classnames';
import React, { useEffect } from 'react';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import isTopMenuDropdownVisible from 'editor/src/store/app/selector/isTopMenuDropdownVisible';
import { TopMenuDropdown } from 'editor/src/store/app/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { PluginIcon } from 'editor/src/store/plugins/selector/getPluginIconByLevel';

import ControlButton from 'editor/src/component/ControlButton';
import DropDown from 'editor/src/component/DropDown';
import IconPremium from 'editor/src/component/Icon/IconElevateGold';

import styles from './index.module.scss';

interface Props {
  dropDownName: TopMenuDropdown;
  dropDownContent: React.ReactNode;
  text?: string;
  buttonContent?: string | React.ReactNode;
  buttonExtraClass?: string;
  disabled?: boolean;
  dropdownExtraClass?: string;
  dropdownMargin?: number;
  keepTabOpenedOnClose?: boolean;
  premium?: boolean;
  premiumIcon?: PluginIcon;
}

function ButtonWithDropdownNotWrapped({
  dropDownName,
  text,
  buttonContent,
  dropDownContent,
  buttonExtraClass,
  disabled,
  dropdownExtraClass,
  dropdownMargin,
  keepTabOpenedOnClose,
  premium,
  premiumIcon: PremiumIcon = IconPremium,
}: Props) {
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => isTopMenuDropdownVisible(state, dropDownName));

  const closeDropdown = () => {
    dispatch(setActiveTopMenuDropdownOperation(undefined, !keepTabOpenedOnClose));
  };

  const onClick = () => {
    dispatch(setActiveTopMenuDropdownOperation(dropDownName));
  };

  useEffect(
    () => () => {
      if (isVisible) {
        closeDropdown();
      }
    },
    [isVisible],
  );

  return (
    <>
      <ControlButton
        onClick={onClick}
        on={isVisible}
        className={cn(styles.button, buttonExtraClass)}
        disabled={disabled}
        stopPropagation
      >
        {premium && <PremiumIcon className={styles.iconPremium} />}
        {buttonContent || <span>{text}</span>}
      </ControlButton>
      <DropDown
        onClickOutside={closeDropdown}
        isVisible={isVisible}
        wrapperClassName={styles.dropDownWrapper}
        className={cn(styles.dropDown, dropdownExtraClass)}
        margin={dropdownMargin}
      >
        {dropDownContent}
      </DropDown>
    </>
  );
}

export default React.memo(ButtonWithDropdownNotWrapped);

import React from 'react';

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import setElementManipulationModeOperation from 'editor/src/store/editor/operation/setElementManipulationModeOperation';
import { ManipulationMode } from 'editor/src/store/editor/types';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconCrop from 'editor/src/component/Icon/IconCrop';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { structureIndexes }) => {
  if (!structureIndexes.length || !state.hostSettings.coreFeatures.imagePerspectiveTransform) {
    return false;
  }
  const element = getMediaElement(state, structureIndexes[0]);
  return element?.type === 'image' && (!!element.imageId || element.role === 'placeholder');
};

type Props = Pick<MenuItemProps, 'context' | 'mode' | 'isMobile' | 'close'>;

function ButtonPerspectiveTransform({ context, mode, isMobile, close }: Props) {
  const isActive = useSelector((state) => !!state.editor.elementManipulationMode);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (isMobile) {
      dispatch(setSidebarActiveTabOperation(-1));
    }
    const { uuid } = context.structureIndexes[0];
    dispatch(
      setElementManipulationModeOperation(isActive ? undefined : { uuid, mode: ManipulationMode.perspectiveTransform }),
    );
    close?.();
  };

  return (
    <MenuButton
      Icon={IconCrop}
      on={isActive}
      mode={mode}
      handleClick={handleClick}
      i18nKey="Perspective Transform"
      className="cy-button-perspective-transform"
    />
  );
}

export default React.memo(ButtonPerspectiveTransform);

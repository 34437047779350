import { Dimensions } from 'editor/src/store/design/types';

function loadImageFileDimensions(file: File): Promise<Dimensions | undefined> {
  return new Promise<Dimensions | undefined>((res) => {
    const img = new Image();

    img.onload = () => {
      URL.revokeObjectURL(img.src);
      res({ width: img.naturalWidth, height: img.naturalHeight });
    };

    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      res(undefined);
    };

    img.src = URL.createObjectURL(file);
  });
}

export default loadImageFileDimensions;

import { FontDefinition } from 'editor/src/store/fonts/types';
import defaultFontStyles from 'editor/src/store/fonts/utils/defaultFontStyles';
import { RootState } from 'editor/src/store/index';

import getCurrentFontStylesProperty from './getCurrentFontStylesProperty';
import getFontDefinition from './getFontDefinition';

const getCurrentFontDefinition = (state: RootState) => {
  const fontFamily = getCurrentFontStylesProperty(state, 'fontFamily');
  const fontDefinition = getFontDefinition(state, fontFamily);
  if (!fontDefinition) {
    return getFontDefinition(state, defaultFontStyles.fontFamily) as FontDefinition;
  }
  return fontDefinition;
};

export default getCurrentFontDefinition;

import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import setCurrentSpreadIndexOperation from 'editor/src/store/editor/operation/setCurrentSpreadIndexOperation';
import { SpreadWarning, WarningType } from 'editor/src/store/editorModules/warnings/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import EmptyImageBoxWarningItem from './EmptyImageBoxWarningItem';
import EmptyPageWarningItem from './EmptyPageWarningItem';
import MaxColorCountWarningItem from './MaxColorCountWarningItem';
import MaxStitchCountWarningItem from './MaxStitchCountWarningItem';

import styles from './index.module.scss';

interface Props {
  warning: SpreadWarning;
  reviewMode?: boolean;
}

function SpreadWarningItemContainer({ warning, reviewMode }: Props) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<boolean>(false);

  const selected = reviewMode ? false : useSelector((state) => state.editor.currentSpreadIndex === warning.spreadIndex);

  useEffect(() => {
    if (selected) {
      setExpanded(true);
    }
  }, []);

  const handleClick = (spreadIndex: number) => {
    setExpanded(!expanded);

    if (!reviewMode) {
      dispatch(setCurrentSpreadIndexOperation(spreadIndex));
    }
  };

  return (
    <div
      className={cn(styles.WarningItemContainer, 'cy-warning-item', {
        [styles.selected]: expanded,
      })}
      onClick={() => handleClick(warning.spreadIndex)}
    >
      {warning.type === WarningType.MaxColorCount && <MaxColorCountWarningItem warning={warning} selected={expanded} />}
      {warning.type === WarningType.MaxStitchCount && (
        <MaxStitchCountWarningItem warning={warning} selected={expanded} />
      )}
      {warning.type === WarningType.EmptyPage && <EmptyPageWarningItem warning={warning} selected={expanded} />}
      {warning.type === WarningType.EmptyImageBox && <EmptyImageBoxWarningItem warning={warning} selected={expanded} />}
    </div>
  );
}

export default React.memo(SpreadWarningItemContainer);

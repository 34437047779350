import cn from 'classnames';
import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import isRightBarVisible from 'editor/src/store/app/selector/isRightBarVisible';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import getSidebarTabsCount from 'editor/src/store/editorModules/sidebar/selector/getSidebarTabsCount';
import isSidebarVisible from 'editor/src/store/editorModules/sidebar/selector/isSidebarVisible';
import { useSelector } from 'editor/src/store/hooks';

import noop from 'editor/src/util/noop';

import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import ButtonMenuMore from './ButtonMenuMore';
import { PLACE_HOLDER } from './menuItems';
import useMenuDisplay, { MenuItemRef } from './useMenuDisplay';
import useMenuItems from './useMenuItems';

import styles from './index.module.scss';

function TopMenuDesktop() {
  const sidebarVisible = useSelector((state) => isSidebarVisible(state) && !!getSidebarActiveTab(state));
  const tabCount = useSelector(getSidebarTabsCount);
  const rightBarVisible = useSelector(isRightBarVisible);
  const isMobile = useIsMobile();
  const { i18n } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);
  const itemMenuRef = useRef<MenuItemRef[]>([]);
  itemMenuRef.current = [];
  const menuMoreRef = useRef<HTMLDivElement>(null);

  const { items, menuContext } = useMenuItems();
  const { updateMenu, hiddenMenuItems } = useMenuDisplay(containerRef, itemMenuRef, menuMoreRef, items);

  useLayoutEffect(() => {
    updateMenu();
  }, [sidebarVisible, tabCount, rightBarVisible, menuContext, items]);

  useLayoutEffect(() => {
    setTimeout(() => {
      updateMenu();
    }, 300);
  }, [i18n.language]);

  return (
    <div className={cn(styles.TopMenuDesktop, 'cy-top-menu')} ref={containerRef}>
      {items.map((menuElt, index) => {
        const { weight, Component, mode } = menuElt;

        const ref = React.createRef<HTMLDivElement>();
        itemMenuRef.current.push({
          ref,
          weight,
          menuElt,
          index,
        });

        if (Component === PLACE_HOLDER) {
          return <div key={index} className={styles.placeholder} ref={ref} />;
        }

        return (
          <div key={index} className={styles.item} ref={ref}>
            <Component
              mode={mode || 'icon'}
              isMobile={isMobile}
              context={menuContext}
              close={noop}
              refresh={updateMenu}
            />
          </div>
        );
      })}
      <div className={styles.item} ref={menuMoreRef}>
        <ButtonMenuMore context={menuContext} isMobile={isMobile} elements={hiddenMenuItems} />
      </div>
    </div>
  );
}

export default React.memo(TopMenuDesktop);

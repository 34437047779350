import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import closePopupOperation from 'editor/src/store/app/module/popup/operation/closePopupOperation';
import getPopupData from 'editor/src/store/app/module/popup/selector/getPopupData';
import sendDesignDataPostMessage from 'editor/src/store/app/operation/sendDesignDataPostMessage';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import setLoadingWithTimeoutOperation from 'editor/src/store/editor/operation/setLoadingWithTimeoutOperation';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getHighWarningsCount from 'editor/src/store/editorModules/warnings/selector/getHighWarningsCount';
import getVisibleWarnings from 'editor/src/store/editorModules/warnings/selector/getVisibleWarnings';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import setHostSettingsOperation from 'editor/src/store/hostSettings/operation/setHostSettingsOperation';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import Button from 'editor/src/component/Button';
import AbstractPopup, { PopupType } from 'editor/src/component/Popup/AbstractPopup';
import Section from 'editor/src/component/Popup/AbstractPopup/Section';
import { PopupName } from 'editor/src/component/Popup/popups';

import WarningItemsList from '../../DesktopSidebar/TabContents/WarningsTabContent/WarningItemsList';

import styles from './index.module.scss';

export interface WarningPopupData {
  message: { type: string; data?: any };
  isProceedBlocked?: boolean;
  setIsLoadingOnConfirm?: boolean;
}

function WarningsPopup() {
  const dispatch = useDispatch();
  const { callbackMessage, setIsLoadingOnConfirm, highWarningsCount, isProceedBlocked, warnings } = useSelector(
    (state) => ({
      callbackMessage: getPopupData(state, PopupName.WARNING_POPUP)?.message,
      isProceedBlocked: getPopupData(state, PopupName.WARNING_POPUP)?.isProceedBlocked,
      setIsLoadingOnConfirm: getPopupData(state, PopupName.WARNING_POPUP)?.setIsLoadingOnConfirm,
      highWarningsCount: getHighWarningsCount(state),
      warnings: getVisibleWarnings(state),
    }),
    shallowEqual,
  );
  const { t } = useTranslation();

  const handleDoneClick = () => {
    const success = dispatch(sendDesignDataPostMessage());

    if (success && setIsLoadingOnConfirm) {
      dispatch(setLoadingWithTimeoutOperation());
    }

    if (success && callbackMessage) {
      sendPostMessage(callbackMessage.type as any, callbackMessage.data);
    }
    dispatch(closePopupOperation());
  };

  const handleCancelClick = () => {
    batch(() => {
      dispatch(setHostSettingsOperation({ showWarningsOnlyAfterDesignRequest: false }));
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.WARNINGS));
      dispatch(closePopupOperation());
    });
  };

  const handleCloseClick = () => {
    dispatch(closePopupOperation());
  };

  const handleOnWarningsClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation(); // prevents the popup closing
  }, []);

  return (
    <AbstractPopup
      onClickOutside={handleCloseClick}
      name="warning-popup"
      type={highWarningsCount ? PopupType.ERROR : PopupType.WARNING}
      title={t('editor-popup-title-warnings')}
      className="cy-popup-warnings"
      hideTitleIcon
      buttons={
        isProceedBlocked ? (
          <>
            <Button secondary onClick={handleCloseClick} className="cy-popup-warnings-cancel">
              {t('Cancel')}
            </Button>
            <Button primary onClick={handleCancelClick} className="cy-popup-warnings-review">
              {t('Review warnings')}
            </Button>
          </>
        ) : (
          <>
            <Button secondary onClick={handleDoneClick} className="cy-popup-warnings-continue">
              {t('Continue')}
            </Button>
            <Button primary onClick={handleCancelClick} className="cy-popup-warnings-review">
              {warnings.length === 1 ? t('Review warning') : t('Review warnings')}
            </Button>
          </>
        )
      }
    >
      <Section>
        {warnings.length === 1
          ? t('editor-popup-warnings-main-text-singular')
          : t('editor-popup-warnings-main-text-plural')}
      </Section>
      <div className={styles.warningsWrapper} onClick={(e) => handleOnWarningsClick(e)}>
        <WarningItemsList reviewMode />
      </div>

      {isProceedBlocked ? (
        <Section>{t('Warning message cannot proceed')}</Section>
      ) : (
        <Section>
          {warnings.length === 1
            ? t('editor-popup-warning-can-proceed-singular')
            : t('editor-popup-warning-can-proceed-plural')}
        </Section>
      )}
    </AbstractPopup>
  );
}

export default React.memo(WarningsPopup);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconRolls({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 21.495H6.92308C6.92308 21.495 3 21.495 3 17C3 14.5008 3 2.5 3 2.5H16.0769C16.0769 2.5 16.0769 13.5006 16.0769 15.5"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 21C9.5 21 9 17.5 9 16H23C23 19 22.5 21.5 20 21.5"
          stroke="#212121"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

export default IconRolls;

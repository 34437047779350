import { fabric } from 'fabric';
import { Polygon } from 'polygon-clipping';
import React from 'react';

import { LineEdge } from 'editor/src/store/design/types';

import FabricCircle from 'editor/src/fabric/FabricCircle';
import FabricLine from 'editor/src/fabric/FabricLine';
import FabricObject from 'editor/src/fabric/FabricObject';
import FabricRect from 'editor/src/fabric/FabricRect';

import { setClipPath } from 'editor/src/component/EditorArea/fabricComponents/fabricUtils';
import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';

import getLineRectFromRef from './getLineRectFromRef';
import getLineWidth, { getArrowBoundingRectFromRef, getEdgeOffset, getLineAngle, getLineCapOffset } from './utils';

const setLineCoordsFromControlPoint = (
  startPointRef: React.RefObject<FabricCircle>,
  endPointRef: React.RefObject<FabricCircle>,
  rectRef: React.RefObject<FabricRect>,
  edge1Ref: React.RefObject<FabricObject>,
  edge2Ref: React.RefObject<FabricObject>,
  lineRef: React.RefObject<FabricLine>,
  strokeWidth: number,
  contentClipPolygons: Polygon[],
  contentClipPath: fabric.Object | undefined,
  edge1: LineEdge,
  edge2: LineEdge,
) => {
  if (!startPointRef.current || !endPointRef.current) {
    return;
  }
  const { left: x1 = 0, top: y1 = 0 } = startPointRef.current;
  const { left: x2 = 0, top: y2 = 0 } = endPointRef.current;

  const lineWidth = getLineWidth(x1, x2, y1, y2);
  const angle = getLineAngle(x1, x2, y1, y2);
  edge1Ref.current?.set({
    left: x1,
    top: y1,
    angle,
  });
  edge2Ref.current?.set({
    left: x2,
    top: y2,
    angle: -180 + angle,
  });
  const strokeWidthOffset = fabric.util.rotatePoint(
    new fabric.Point(strokeWidth / 2, 0),
    new fabric.Point(0, 0),
    fabric.util.degreesToRadians(angle - 90),
  );
  const rounded = lineRef.current?.strokeLineCap === 'round';
  const lineCapOffset = getLineCapOffset(rounded, strokeWidth, angle);
  const edge1Offset = getEdgeOffset(edge1, strokeWidth, angle);
  const edge2Offset = getEdgeOffset(edge2, strokeWidth, angle);
  rectRef.current?.set({
    left: x1 + strokeWidthOffset.x,
    top: y1 + strokeWidthOffset.y,
    width: lineWidth,
    angle,
  });
  lineRef.current?.set({
    x1: x1 + lineCapOffset.x,
    y1: y1 + lineCapOffset.y,
    x2: x2 - lineCapOffset.x,
    y2: y2 - lineCapOffset.y,
  });
  const lineBBox = getLineRectFromRef(lineRef, strokeWidth);
  const clipPath = getClipPath(lineBBox, contentClipPolygons, false, contentClipPath);
  setClipPath(lineRef.current, clipPath);

  // need to remove the edge offset from each side of the line.
  lineRef.current?.set({
    x1: x1 + lineCapOffset.x + edge1Offset.x,
    y1: y1 + lineCapOffset.y + edge1Offset.y,
    x2: x2 - lineCapOffset.x - edge2Offset.x,
    y2: y2 - lineCapOffset.y - edge2Offset.y,
  });

  setClipPath(
    edge1Ref.current,
    getClipPath(getArrowBoundingRectFromRef(edge1Ref, strokeWidth, angle), contentClipPolygons, false, contentClipPath),
  );
  setClipPath(
    edge2Ref.current,
    getClipPath(
      getArrowBoundingRectFromRef(edge2Ref, strokeWidth, -180 + angle),
      contentClipPolygons,
      false,
      contentClipPath,
    ),
  );
};

export default setLineCoordsFromControlPoint;

import cn from 'classnames';
import React, { RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import getFirstGridElement from 'editor/src/store/design/selector/getFirstGridElement';
import { addOrUpdateCalendarEventsAction, removeCalendarEventAction } from 'editor/src/store/design/slice';
import { CalendarEvent } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { getFormatedDate } from 'editor/src/util/dates/dateUtils';
import { browserLocaleToEditorLocale } from 'editor/src/util/locales';

import Button from 'editor/src/component/Button';
import Checkbox from 'editor/src/component/Checkbox';
import IconBin from 'editor/src/component/Icon/IconBin';
import IconTail from 'editor/src/component/Icon/IconTail';
import { PopupName } from 'editor/src/component/Popup/popups';

import styles from './index.module.scss';

type Props = {
  event: CalendarEvent;
  disabled?: boolean;
  openEditForm?: (event: CalendarEvent, divRef: RefObject<HTMLDivElement>) => void;
};

function EventRow({ event, disabled, openEditForm }: Props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const gridElement = useSelector((state) => getFirstGridElement(state)?.grid);
  const divRef = useRef<HTMLDivElement>(null);

  const deleteEvent = (e: Event, id: string) => {
    dispatch(
      openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
        popupTitle: t('editor-warning'),
        textLines: [t('Are you sure you want to delete this event?')],
        options: [
          {
            title: t('editor-cancel'),
          },
          {
            title: t('Delete'),
            onConfirm: () => dispatch(removeCalendarEventAction({ id })),
          },
        ],
        hideTitleIcon: true,
      }),
    );
    e.stopPropagation();
  };
  const toggleEvent = (event: CalendarEvent) => {
    dispatch(
      addOrUpdateCalendarEventsAction([
        {
          ...event,
          checked: !event.checked,
        },
      ]),
    );
  };

  const editEvent = (e: Event, event: CalendarEvent) => {
    openEditForm?.(event, divRef);
    e.stopPropagation();
  };

  const eventLabel = (
    <div className={styles.eventItemCheckboxLabel}>
      <div>
        {!event.custom && !event.merchant
          ? i18n.t(event.label, { lng: browserLocaleToEditorLocale[gridElement?.locale ?? 'en'] })
          : t(event.label)}
      </div>
      <div className={styles.eventDateLabel}>{getFormatedDate(new Date(event.year, event.month, event.day))}</div>
    </div>
  );

  return (
    <div className={cn(styles.eventRowBlock, { [styles.disabled]: disabled })} ref={divRef}>
      <Checkbox
        key={event.id}
        on={event.checked}
        disabled={disabled}
        className={cn('cy-calendar-event-item', styles.eventItemCheckbox)}
        onChange={() => toggleEvent(event)}
      >
        {eventLabel}
        {openEditForm && (
          <div className={styles.eventActionBtnsBlock}>
            <Button className={styles.editActionButton} tertiary onClick={(e) => editEvent(e, event)}>
              <IconTail />
            </Button>
            <Button className={styles.deleteActionButton} tertiary onClick={(e) => deleteEvent(e, event.id)}>
              <IconBin />
            </Button>
          </div>
        )}
      </Checkbox>
    </div>
  );
}

export default React.memo(EventRow);

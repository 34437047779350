import cn from 'classnames';
import React from 'react';

import { PluginIcon } from 'editor/src/store/plugins/selector/getPluginIconByLevel';

import IconPremium from 'editor/src/component/Icon/IconPremium';

import styles from './index.module.scss';

interface Props {
  label: string;
  image: string;
  className: string;
  premium?: boolean;
  PremiumIcon?: PluginIcon;
  disabled?: boolean;
  onClick: () => void;
}

function EditTabButton({ label, image, className, onClick, disabled, premium, PremiumIcon = IconPremium }: Props) {
  return (
    <div
      className={cn(styles.button, { [styles.disabled]: disabled }, className)}
      onClick={() => !disabled && onClick()}
    >
      <img className={cn(styles.image, 'mr-3')} src={image} alt={label} draggable={false} />
      <span>{label}</span>
      {premium && <PremiumIcon className={styles.iconPremium} />}
    </div>
  );
}

export default EditTabButton;

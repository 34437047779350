import type { ThunkDispatch } from 'editor/src/store/hooks';
import { setPageCountAction } from 'editor/src/store/variants/slice';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const selectPageCountOperation = (pageCount: number) => (dispatch: ThunkDispatch) => {
  dispatch(setPageCountAction(pageCount));
  sendPostMessage('variants.setPageCount', pageCount);
};

export default selectPageCountOperation;

import { fabric } from 'fabric';

import FabricImage from 'editor/src/fabric/FabricImage';

const getImageUpdateOnRotate = (
  _: fabric.Transform,
  mask: fabric.Object,
  maskOrigin: fabric.Object,
  imageOrigin: fabric.Object,
): Partial<FabricImage> => {
  const angleDiff = (mask.angle || 0) - (maskOrigin.angle || 0);
  const rotatedImageCoords = fabric.util.rotatePoint(
    new fabric.Point(imageOrigin.left || 0, imageOrigin.top || 0),
    maskOrigin.getCenterPoint(),
    fabric.util.degreesToRadians(angleDiff),
  );

  return {
    left: rotatedImageCoords.x,
    top: rotatedImageCoords.y,
    angle: (imageOrigin.angle || 0) + angleDiff,
  };
};

export default getImageUpdateOnRotate;

import React, { Suspense, useCallback, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import getCanvasHeight from 'editor/src/store/canvas/selector/getCanvasHeight';
import getCanvasWidth from 'editor/src/store/canvas/selector/getCanvasWidth';
import updateImageIdByUuidOperation from 'editor/src/store/design/operation/updateImageIdByUuidOperation';
import updateImageWithAssetOperation from 'editor/src/store/design/operation/updateImageWithAssetOperation';
import getCurrentSpreadGroup from 'editor/src/store/design/selector/getCurrentSpreadGroup';
import { ContentAddress, Coords, Page, SpotFinishingType, SpreadInfo } from 'editor/src/store/design/types';
import getSelectedElementUuids from 'editor/src/store/editor/selector/getSelectedElementUuids';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import isPersonalizationLockIgnored from 'editor/src/store/editor/selector/isPersonalizationLockIgnored';
import { DraggableItem, DraggableItemType, SettingsProperty } from 'editor/src/store/editor/types';
import getSelectedGalleryImagesIds from 'editor/src/store/gallery/selector/getSelectedGalleryImagesIds';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { PluginName } from 'editor/src/store/plugins/types';

import retryPromiseFn from 'editor/src/util/retryPromiseFn';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import useSetupSnaps from 'editor/src/component/EditorArea/snapping/useSetupSnaps';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import { rectToPolygon } from 'editor/src/component/EditorArea/utils/polygonsUtils';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import { getFocusedVisibleAreaAsPolygons, getVisibleAreaAsPolygons } from './contentClippingUtils';
import DropZone from './DropZone';
import FoilElements from './FoilElements/FoilElements';
import getTrimClipPath from './getTrimClipPath';
import MediaElementComponent from './MediaElement';
import useMirroring from './useMirroring';

const Mirror = React.lazy(retryPromiseFn(() => import('editor/src/component/EditorArea/Spread/Mirror')));
const SideFades = React.lazy(retryPromiseFn(() => import('editor/src/component/EditorArea/Spread/SideFades')));
const DesignRepeat = React.lazy(retryPromiseFn(() => import('editor/src/component/EditorArea/Spread/DesignRepeat')));

interface Props {
  pages: Page[];
  pagesKey: string;
  spreadIndex: number;
  spreadCoords: Coords;
  canvasRotation: CanvasRotation;
  focusedContentAddress: ContentAddress | undefined;
  spreadHeight: number;
  spreadWidth: number;
  spreadsInfo: SpreadInfo[];
  spotFinishingType: SpotFinishingType | undefined;
}

function PageMedia({
  pages,
  pagesKey,
  spreadIndex,
  spreadCoords,
  canvasRotation,
  focusedContentAddress,
  spreadWidth,
  spreadHeight,
  spreadsInfo,
  spotFinishingType,
}: Props) {
  const { mm2px } = useFabricUtils();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const {
    selectedElementUuids,
    ignorePersonalizationLock,
    showBleeds,
    showGuides,
    canvasWidth,
    canvasHeight,
    repeatDesign,
    currentSpreadGroup,
    selectedGalleryImagesIds,
    isSystemDrag,
  } = useSelector(
    (state) => ({
      selectedElementUuids: getSelectedElementUuids(state),
      ignorePersonalizationLock: isPersonalizationLockIgnored(state),
      showGuides: getSettingsValue(state, SettingsProperty.showGuides),
      showBleeds: getSettingsValue(state, SettingsProperty.showBleeds),
      canvasWidth: getCanvasWidth(state),
      canvasHeight: getCanvasHeight(state),
      repeatDesign: state.hostSettings.repeatDesign,
      currentSpreadGroup: getCurrentSpreadGroup(state),
      selectedGalleryImagesIds: getSelectedGalleryImagesIds(state),
      isSystemDrag: state.editor.dragState === 'system',
    }),
    shallowEqual,
  );

  const dropGalleryImageToImage = useCallback((item: DraggableItem) => {
    if (
      item.itemType === DraggableItemType.assetImage &&
      item.dropToElementUuid &&
      !item.isMultipleItems &&
      item.itemId &&
      item.sourceId &&
      item.itemThumbnail
    ) {
      void dispatch(
        updateImageWithAssetOperation(
          item.dropToElementUuid,
          item.itemId,
          item.sourceId as PluginName,
          item.itemThumbnail,
          item.dimensions,
          true,
        ),
      );
    } else {
      item.dropToElementUuid &&
        !item.isMultipleItems &&
        dispatch(updateImageIdByUuidOperation(item.dropToElementUuid, item.itemId, true));
    }
  }, []);

  const firstPage = pages[0];
  const { media, content, bleed, mediabox } = firstPage.groups;

  const pageCoords = useMemo(
    () => ({
      left: spreadCoords.left + mm2px(firstPage.x || 0),
      top: spreadCoords.top + mm2px(firstPage.y || 0),
    }),
    [mm2px, spreadCoords, firstPage.x, firstPage.y],
  );

  const focusedContent = useMemo(
    () =>
      focusedContentAddress &&
      pages[focusedContentAddress.pageIndex].groups.content?.[focusedContentAddress.contentIndex],
    [focusedContentAddress, spreadIndex],
  );

  const focusedRect = useMemo(
    () =>
      focusedContent && {
        x: firstPage.x + focusedContent.x,
        y: firstPage.y + focusedContent.y,
        width: focusedContent.width,
        height: focusedContent.height,
      },
    [focusedContent, firstPage.x, firstPage.y],
  );

  const areaWidth = focusedContent ? mm2px(focusedContent.width) : mm2px(firstPage.width);

  const spreadCoordinates = useMemo(() => {
    if (!currentSpreadGroup) {
      return spreadCoords;
    }

    const { position } = currentSpreadGroup;
    switch (position) {
      case 'vertical':
        return {
          ...spreadCoords,
          top: spreadCoords.top - (currentSpreadGroup.spreadIndexes.length - 1) * spreadHeight,
        };
      case 'horizontal':
        return {
          ...spreadCoords,
          left: spreadCoords.left - (currentSpreadGroup.spreadIndexes.length - 1) * spreadWidth,
        };
      default:
        return spreadCoords;
    }
  }, [currentSpreadGroup, spreadCoords]);

  const { fullArea, contentArea } = useMemo(
    () =>
      focusedContent
        ? getFocusedVisibleAreaAsPolygons(
            pages[focusedContentAddress?.pageIndex || 0],
            focusedContent,
            spreadCoordinates,
            mm2px,
            canvasRotation,
            canvasWidth,
            canvasHeight,
            currentSpreadGroup,
          )
        : getVisibleAreaAsPolygons(pages, spreadCoordinates, mm2px, canvasRotation, currentSpreadGroup),
    [pagesKey, spreadCoords, focusedContent, mm2px, canvasRotation, content, bleed, mediabox],
  );

  const { mirrorInfo, mirroringMode } = useMirroring(spreadIndex, 0, firstPage);

  useSetupSnaps(pagesKey, mm2px, canvasRotation, media, spreadsInfo);

  const mirrorContentClipping = useMemo(
    () =>
      mirrorInfo?.frontContent && mirroringMode
        ? [rectToPolygon(mirrorInfo.frontContent, pageCoords, mm2px)]
        : undefined,
    [mirrorInfo, pageCoords, mm2px],
  );
  const elementContents = useMemo(
    () => content?.filter((content) => content.name !== 'panel' && content.type !== 'sample') || [],
    [content],
  );
  const areaBleeds = useMemo(() => bleed?.filter((b) => b.type === 'area') || [], [bleed]);
  const anyElementSelected = selectedElementUuids.length > 0;

  const trimClipPath = useMemo(
    () => getTrimClipPath(firstPage.groups.bleed, mm2px, showBleeds || anyElementSelected, pageCoords),
    [firstPage.groups.bleed, mm2px, pageCoords, showBleeds || anyElementSelected],
  );

  return (
    <>
      {media?.map((element, index) => {
        const selected = selectedElementUuids.indexOf(element.uuid) !== -1;
        if (
          focusedContentAddress &&
          element.belongs_to?.type === 'content' &&
          (element.belongs_to.pageIndex !== focusedContentAddress.pageIndex ||
            element.belongs_to.contentIndex !== focusedContentAddress.contentIndex)
        ) {
          return null;
        }

        return (
          <MediaElementComponent
            key={element.uuid}
            spreadIndex={spreadIndex}
            pageIndex={0}
            elementIndex={index}
            pageCoords={pageCoords}
            areaWidth={areaWidth}
            elementData={element}
            canvasRotation={canvasRotation}
            contentClipPolygons={
              mirrorContentClipping || ((selected || showBleeds) && !mirroringMode ? fullArea : contentArea)
            }
            selected={selected}
            isMobile={isMobile}
            ignorePersonalizationLock={ignorePersonalizationLock}
            showGuides={showGuides}
            focusedRect={focusedRect}
            contentClipPath={trimClipPath}
          />
        );
      })}
      {media?.map((element, elementIndex) => {
        if ((element.personalizationLocked && !ignorePersonalizationLock) || element.type !== 'image') {
          return null;
        }

        return (
          (selectedGalleryImagesIds.length === 0 || isSystemDrag) && (
            <DropZone
              key={element.uuid}
              element={element}
              pageCoords={pageCoords}
              spreadCoords={spreadCoords}
              relativeZIndex={zIndex.MEDIA + elementIndex}
              onDrop={dropGalleryImageToImage}
              canvasRotation={canvasRotation}
            />
          )
        );
      })}
      {spotFinishingType && media && media.length > 0 && mediabox && mediabox.length > 0 && (
        <FoilElements
          pageCoords={pageCoords}
          contentClippingPolygons={anyElementSelected || showBleeds ? fullArea : contentArea}
          mediaBox={mediabox[0]}
          mediaElements={media}
          contentClipPath={trimClipPath}
          spotFinishingType={spotFinishingType}
        />
      )}
      <Suspense fallback="">
        {mirrorInfo && mirroringMode && (
          <Mirror
            frontContent={mirrorInfo.frontContent}
            pageCoords={pageCoords}
            contentClippingPolygons={anyElementSelected || showBleeds ? fullArea : contentArea}
            mediaBox={mirrorInfo.mediaBox}
            mirroringMode={mirroringMode}
          />
        )}
        {mirrorInfo && elementContents && (
          <SideFades
            pageCoords={pageCoords}
            mediaBox={mirrorInfo.mediaBox}
            contents={elementContents}
            bleeds={areaBleeds}
            showBleeds={showBleeds || anyElementSelected}
          />
        )}
        {repeatDesign && !!mediabox?.length && (
          <DesignRepeat
            pageCoords={pageCoords}
            mediaBox={mediabox[0]}
            repeatCount={repeatDesign.count}
            backgroundColor={firstPage.backgroundColor}
          />
        )}
      </Suspense>
    </>
  );
}

export default React.memo(PageMedia);

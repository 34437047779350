import cn from 'classnames';
import React from 'react';

import { ValueWithDisabled } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ECommerceProductContent/eCommerceVariantUtils';
import RadioButton from 'editor/src/component/RadioButton';

import styles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/elements/RadioElement/index.module.scss';

interface Props {
  option: ValueWithDisabled;
  selected: boolean;
  onChange: (value: string) => void;
}

function ECommerceProductControlOption({ option, selected, onChange }: Props) {
  return (
    <RadioButton
      key={option.name}
      className={cn(styles.RadioElement, 'cy-ecommerce-product-control-option', {
        active: selected,
        [styles.active]: selected,
        [styles.enabled]: !option.disabled,
        'cy-active': selected,
      })}
      label={option.name}
      disabled={option.disabled}
      on={selected}
      onChange={(on) => on && onChange(option.name)}
    />
  );
}

export default React.memo(ECommerceProductControlOption);

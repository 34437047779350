import cn from 'classnames';
import React from 'react';

import IconCheck from 'editor/src/component/Icon/IconCheck';
import { MinifiedProductsData } from 'editor/src/component/ProductVariantList/ProductSwitcher/index';
import ProductSwitcherItem from 'editor/src/component/ProductVariantList/ProductSwitcher/ProductSwitcherItem';

import styles from './index.module.scss';

interface Props {
  multipleProductsData: MinifiedProductsData;
  selectedProductId: string;
  switchProduct: (productId: string) => void;
}

function ProductSwitcherContent({ multipleProductsData, selectedProductId, switchProduct }: Props) {
  return (
    <div className={cn(styles.contentContainer, 'cy-product-dropdown')}>
      {Object.entries(multipleProductsData).map(([productId, productData]) => (
        <ProductSwitcherItem
          key={productId}
          className={cn(styles.item, 'cy-product-switcher-option', { 'cy-active': productId === selectedProductId })}
          productData={productData}
          Icon={productId === selectedProductId ? IconCheck : undefined}
          onClick={() => switchProduct(productId)}
        />
      ))}
    </div>
  );
}

export default React.memo(ProductSwitcherContent);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress, MediaElement } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const applyToSelectedMediaOperation =
  (
    action: (element: MediaElement, address: ElementAddress) => Partial<MediaElement> | undefined,
    preventSavingState = false,
  ) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    batch(() => {
      const state = getState();

      if (!preventSavingState) {
        dispatch(saveUndoRedoStateOperation(`Apply to selected elements: ${action.name}`));
      }

      state.editor.selectedElementUuids.forEach((uuid) => {
        const elementAddress = getStructureIndexByElementUuid(state.design.designData, uuid);
        if (!elementAddress) {
          return;
        }
        const element = getMediaElement(state, elementAddress);
        if (!element) {
          return;
        }
        const elementUpdate = action(element, elementAddress);
        if (elementUpdate) {
          dispatch(updateMediaElementAction({ elementAddress, elementUpdate }));
        }
      });
    });
  };

export default applyToSelectedMediaOperation;

export type PointList = [number, number][];

// https://stackoverflow.com/a/63436180
const between = (p: number, a: number, b: number) => (p >= a && p <= b) || (p <= a && p >= b);

/**
 * @returns whether the point is inside the polygon
 */
function isPointInPolygon(point: [number, number], polygon: [number, number][]) {
  let inside = false;
  for (let i = polygon.length - 1, j = 0; j < polygon.length; i = j, j += 1) {
    const A = polygon[i];
    const B = polygon[j];
    // corner cases
    if ((point[0] === A[0] && point[1] === A[1]) || (point[0] === B[0] && point[1] === B[1])) {
      return true;
    } // on edge
    if (A[1] === B[1] && point[1] === A[1] && between(point[0], A[0], B[0])) {
      return true;
    } // on edge

    if (between(point[1], A[1], B[1])) {
      // if P inside the vertical range
      // filter out "ray pass vertex" problem by treating the line a little lower
      if ((point[1] === A[1] && B[1] >= A[1]) || (point[1] === B[1] && A[1] >= B[1])) {
        continue;
      }
      // calc cross product `PA X PB`, P lays on left side of AB if c > 0
      const c = (A[0] - point[0]) * (B[1] - point[1]) - (B[0] - point[0]) * (A[1] - point[1]);
      if (c === 0) {
        return true;
      } // on edge
      if (A[1] < B[1] === c > 0) {
        inside = !inside;
      }
    }
  }

  return inside;
}

export default isPointInPolygon;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setAutoApplyLayoutSchemaNamesAction } from 'editor/src/store/design/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import autoApplyLayoutSchemasToDesignDataOperation from './autoApplyLayoutSchemasToDesignDataOperation';

const setAutoApplyLayoutSchemaNamesOperation = (payload: string[] | undefined) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(setAutoApplyLayoutSchemaNamesAction(payload));
    dispatch(autoApplyLayoutSchemasToDesignDataOperation());
  });
};

export default setAutoApplyLayoutSchemaNamesOperation;

import cn from 'classnames';
import React from 'react';

import IconChevronRight from 'editor/src/component/Icon/IconChevronRight';
import IconLoading from 'editor/src/component/Icon/IconLoading';

import styles from './index.module.scss';

interface BaseProps {
  title: string;
  id?: string;
  onClick: () => void;
  isLoading?: boolean;
  noRightArrow?: boolean;
}

interface PropsWithImage extends BaseProps {
  imageUrl: string;
}

interface PropsWithIcon extends BaseProps {
  icon: React.FunctionComponent<{ className?: string }>;
}

type Props = PropsWithImage | PropsWithIcon;

function MockupListEntry({ title, onClick, noRightArrow, isLoading, id, ...rest }: Props) {
  return (
    <button className={cn(styles.MockupListEntry, id ? `cy-mockup-entry-${id}` : undefined)} onClick={onClick}>
      {'icon' in rest && <rest.icon className={styles.icon} />}
      {'imageUrl' in rest && <div className={styles.image} style={{ backgroundImage: `url(${rest.imageUrl})` }} />}
      <div className={styles.title}>{title}</div>
      {isLoading && <IconLoading className={styles.loader} />}
      {!noRightArrow && <IconChevronRight className={styles.right} />}
    </button>
  );
}

export default React.memo(MockupListEntry);

import React from 'react';

import { MenuItem } from 'editor/src/component/Menu/type';
import useFilteredMenuItems from 'editor/src/component/Menu/useFilteredMenuItems';
import ButtonUndoRedo, { canShow as undoRedoCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonUndoRedo';

import styles from 'editor/src/component/TopMenuMobile/LeftSectionMobile/index.module.scss';

const MENU_ITEMS: MenuItem[] = [{ Component: ButtonUndoRedo, canShow: undoRedoCanShow, mode: 'icon' }];

function CentralSectionMobile() {
  const { items, menuContext } = useFilteredMenuItems(MENU_ITEMS);

  return (
    <div className={styles.leftSectionMobile}>
      {items.map(({ Component, mode }, i) => (
        <Component key={i} isMobile mode={mode || 'icon'} context={menuContext} />
      ))}
    </div>
  );
}

export default React.memo(CentralSectionMobile);

import cn from 'classnames';
import React, { useMemo } from 'react';

import Slider from 'editor/src/component/Slider';

import styles from './index.module.scss';

interface Props {
  min: number;
  max: number;
  step: number;
  disabled?: boolean;
  value: number;
  railBackground?: string;
  onChange: (value: number) => void;
  onBeforeChange?: () => void;
  onAfterChange: (value: number) => void;
  className?: string;
}

function AdjustmentSlider({
  min,
  max,
  step = 0.01,
  disabled = false,
  value,
  railBackground,
  onChange,
  onBeforeChange,
  onAfterChange,
  className,
}: Props) {
  const railStyle = useMemo(() => ({ background: railBackground }), [railBackground]);

  return (
    <div className={cn(styles.sliderContainer, className)}>
      <Slider
        min={min}
        max={max}
        step={step}
        value={value}
        disabled={disabled}
        className={styles.slider}
        onChange={onChange}
        onAfterChange={onAfterChange}
        onBeforeChange={onBeforeChange}
        startPoint={0}
        railStyle={railStyle}
        hideTrack={!!railBackground}
      />
    </div>
  );
}

export default React.memo(AdjustmentSlider);

import type { Thunk } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

export const fetching = new Set<string>();

const fetchProductScenesOperation =
  (productUid: string): Thunk =>
  (dispatch, getState: () => RootState) => {
    const state = getState();
    if (fetching.has(productUid) || state.mockup.scenePlaceholder.sceneCategories[productUid]) {
      return;
    }

    fetching.add(productUid);
    sendPostMessage('mockup.requestProductScenes', productUid);
  };

export default fetchProductScenesOperation;

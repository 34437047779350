/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconMoveTop({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M11.3077 5.4583V10.9201C11.3077 11.3178 11.6177 11.6401 12 11.6401C12.3824 11.6401 12.6923 11.3178 12.6923 10.9201V5.4583L14.4476 7.28389C14.718 7.56507 15.1563 7.56507 15.4267 7.28389C15.6971 7.00271 15.6971 6.54683 15.4267 6.26565L12.4895 3.21094C12.2192 2.92977 11.7809 2.92977 11.5105 3.21094L8.57334 6.26565C8.30298 6.54683 8.30298 7.00271 8.57334 7.28389C8.8437 7.56506 9.28204 7.56506 9.55239 7.28389L11.3077 5.4583Z"
          fill="#212121"
        />
        <path
          d="M3.4586 12.0962L11.7661 15.1977C11.9173 15.2542 12.0827 15.2542 12.2339 15.1977L20.5414 12.0962C20.8164 11.9935 20.9998 11.7224 20.9998 11.4185C20.9998 11.1147 20.8164 10.8435 20.5414 10.7408L15.3492 8.80238C14.9894 8.66803 14.5929 8.86252 14.4638 9.23678C14.3346 9.61104 14.5216 10.0234 14.8814 10.1577L18.2585 11.4185L12 13.7551L5.74153 11.4185L9.1186 10.1577C9.47846 10.0234 9.66546 9.61104 9.53628 9.23678C9.4071 8.86252 9.01066 8.66803 8.6508 8.80238L3.4586 10.7408C3.18362 10.8435 3.00021 11.1147 3.00021 11.4185C3.00021 11.7224 3.18362 11.9935 3.4586 12.0962Z"
          fill="#212121"
        />
        <path
          d="M11.7661 18.0777L3.45858 14.9762C3.09872 14.8418 2.91172 14.4295 3.0409 14.0552C3.17008 13.681 3.56652 13.4865 3.92638 13.6208L12 16.6351L20.0736 13.6209C20.4335 13.4865 20.8299 13.681 20.9591 14.0552C21.0883 14.4295 20.9013 14.8418 20.5414 14.9762L12.2339 18.0777C12.0827 18.1342 11.9173 18.1342 11.7661 18.0777Z"
          fill="#212121"
        />
        <path
          d="M3.45858 17.8562L11.7661 20.9577C11.9173 21.0142 12.0827 21.0142 12.2339 20.9577L20.5414 17.8562C20.9013 17.7218 21.0883 17.3095 20.9591 16.9353C20.8299 16.561 20.4335 16.3665 20.0736 16.5009L12 19.5151L3.92638 16.5009C3.56652 16.3665 3.17008 16.561 3.0409 16.9353C2.91172 17.3095 3.09872 17.7218 3.45858 17.8562Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconMoveTop;

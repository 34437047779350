import { Adjustment, ElementAddress } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import ADJUSTMENTS from 'editor/src/component/DesktopSidebar/TabContents/AdjustmentsTabContent/adjustments';
import {
  FilterInfo,
  NO_FILTER_FILTER_NAME,
} from 'editor/src/component/DesktopSidebar/TabContents/FiltersTabContent/filters';
import { getAdjustment } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/getAdjustmentFilters';

import updateMediaElementOperation, { MediaUpdateActionName } from './updateMediaElementOperation';

export function getUpdatedFilters(filter: FilterInfo, value: number) {
  if (filter.name === NO_FILTER_FILTER_NAME) {
    return { filters: undefined, adjustments: undefined };
  }

  const filters = [{ name: filter.name, value }];

  const adjustments = filter.adjustments.reduce<Adjustment[]>((list, adjustment) => {
    const adjustmentDefinition = getAdjustment(adjustment.name);
    if (adjustmentDefinition) {
      list.push({
        name: adjustment.name,
        value: Math.round(adjustmentDefinition.default + adjustment.value * ((value || 0) / 100)),
      });
    }
    return list;
  }, []);

  adjustments.sort((a, b) => {
    const aIndex = ADJUSTMENTS.findIndex((adj) => adj.name === a.name);
    const bIndex = ADJUSTMENTS.findIndex((adj) => adj.name === b.name);
    return aIndex - bIndex;
  });

  return { filters, adjustments };
}

const updateMediaElementFiltersOperation =
  (elementAddress: ElementAddress, filter: FilterInfo, value: number) => (dispatch: ThunkDispatch) => {
    const { filters, adjustments } = getUpdatedFilters(filter, value);
    dispatch(
      updateMediaElementOperation(elementAddress, { filters, adjustments }, MediaUpdateActionName.FILTER_APPLIED),
    );
  };

export default updateMediaElementFiltersOperation;

import { PayloadAction } from '@reduxjs/toolkit';

import {
  AppliedFilters,
  DataState,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const setSearchFiltersReducer = (state: DataState, action: PayloadAction<AppliedFilters | undefined>) => {
  if (state.results) {
    state.results.search.filters = action.payload;
  }
};

export default setSearchFiltersReducer;

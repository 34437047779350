import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import getCurrentFontStylesProperty from 'editor/src/store/fonts/selector/getCurrentFontStylesProperty';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import shouldDigitizeCurrentSpread from 'editor/src/store/utils/shouldDigitizeCurrentSpread';

import { EMPTY_RGBA_STRING } from 'editor/src/util/color/rgbaUtils';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import DrawerHeader from 'editor/src/component/BottomBarMobile/DrawerHeader';
import ColorPicker from 'editor/src/component/ColorPicker';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from 'editor/src/component/DesktopSidebar/TabContents/colorTabContent.module.scss';

const DEFAULT_COLOR = '#000000';

interface Props {
  closeMenuCb?: () => void;
}

function FontColorsTabContent({ closeMenuCb }: Props) {
  const currentColor = useSelector((state) => getCurrentFontStylesProperty(state, 'color'));
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const digitizeDesignElements = useSelector(shouldDigitizeCurrentSpread);

  const element = useSelector((state) => getSelectedMediaElementsObjects(state)[0]);
  const colorPickerConfig = useSelector((state) => getHostSetting(state, 'colorPickerConfig'));
  const currentColorInfo = colorPickerConfig?.colorsInfo?.[currentColor.toLowerCase()];

  const { liveUpdate } = useMediaElementLiveUpdates(element);

  const clickHandler = useCallback(
    (color: string | undefined) => {
      (color || colorPickerConfig.noColor) &&
        dispatch(applyFontStylesToSelectionOperation({ color: color || EMPTY_RGBA_STRING }));
    },
    [colorPickerConfig.noColor],
  );

  const changingColor = (fill: string | undefined) => {
    if ((fill || colorPickerConfig.noColor) && element?.type === 'text') {
      liveUpdate({ ...element, fill: fill || EMPTY_RGBA_STRING });
    }
  };

  const tabHeaderTranslationKey = useMemo(() => {
    return digitizeDesignElements ? 'Thread colors' : 'Font color';
  }, [digitizeDesignElements]);

  return (
    <>
      {isMobile ? (
        <DrawerHeader title={tabHeaderTranslationKey} closeMenu={closeMenuCb} />
      ) : (
        digitizeDesignElements && <TabContentHeader title={t(tabHeaderTranslationKey)} />
      )}
      <ContentBlock
        className={cn('cy-font-colors-tab-content', styles.wrapper, { [styles.mobile]: isMobile })}
        scroll
        fillFlex
      >
        {!!currentColorInfo && (
          <div className={styles.colorInfo}>
            {`${currentColorInfo.madeira_ref} ${currentColorInfo.name} ${currentColor.toUpperCase()}`}
          </div>
        )}
        <ColorPicker
          color={currentColor || DEFAULT_COLOR}
          onColorChange={clickHandler}
          allowOpacity={colorPickerConfig.enableOpacity}
          colors={colorPickerConfig.allowedColors}
          allowNoColor={!!colorPickerConfig.noColor && !digitizeDesignElements}
          onColorChanging={changingColor}
        />
      </ContentBlock>
    </>
  );
}

export default React.memo(FontColorsTabContent);

import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getCurrentFontDefinition from 'editor/src/store/fonts/selector/getCurrentFontDefinition';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import FontFamiliesMobileMenu from 'editor/src/component/BottomBarMobile/ActionBarSection/FontFamiliesMobileMenu';
import ControlButton from 'editor/src/component/ControlButton';
import IconArrowDown from 'editor/src/component/Icon/IconArrowDown';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';
import useMobileMenu from 'editor/src/component/useMobileMenu';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

export const canShow: CanShow = (state, { isPersonalizationLocked }) => !isPersonalizationLocked;

type Props = Pick<MenuItemProps, 'isMobile' | 'close'>;

function ButtonFontFamily({ isMobile, close }: Props) {
  const { t } = useTranslation();
  const { metadata, isActive } = useSelector(
    (state) => ({
      metadata: getCurrentFontDefinition(state).metadata,
      isActive: isSidebarTabActive(state, TAB_NAMES.FONT_FAMILIES),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const fontFamiliesMenu = useMobileMenu(false, true);

  const handleClick = () => {
    if (isMobile) {
      fontFamiliesMenu.open();
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.FONT_FAMILIES));
    } else {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.FONT_FAMILIES));
      close?.();
    }
  };

  const closeMenu = useCallback(() => {
    fontFamiliesMenu.close();
    dispatch(setSidebarActiveTabOperation(-1));
  }, []);

  return (
    <>
      <WithTooltip overlay={t('editor-font-family')}>
        <ControlButton
          onClick={handleClick}
          on={isActive}
          className={cn(styles.ButtonFontFamily, 'cy-button-font-family')}
        >
          <span>
            {metadata.name}
            {metadata.weight && metadata.weight !== 'Regular' ? ` ${metadata.weight}` : ''}
            {metadata.style && metadata.style !== 'Normal' ? ` ${metadata.style}` : ''}
          </span>
          <IconArrowDown />
        </ControlButton>
      </WithTooltip>
      {fontFamiliesMenu.render(<FontFamiliesMobileMenu closeMenuCb={closeMenu} />)}
    </>
  );
}

export default React.memo(ButtonFontFamily);

import { PageOptions } from 'editor/src/store/variants/types';

export type Option = { value: number; label: string };

function generatePageOptions(pages: PageOptions): Option[] {
  const options: Option[] = [];

  for (let i = pages.min; i <= pages.max; i += pages.step) {
    options.push({ value: i, label: `${i}` });
  }

  return options;
}

export default generatePageOptions;

import { getImageDPI } from 'editor/src/store/design/selector/getMediaElementDpi';
import { ImagePattern } from 'editor/src/store/design/types';
import { getDpiLevel } from 'editor/src/store/editorModules/warnings/utils/getDpiWarning';
import { GalleryImage } from 'editor/src/store/gallery/types';
import { DpiLevels } from 'editor/src/store/hostSettings/types';

const getDpiWarningLevel = (
  imageWidth: number,
  imageHeight: number,
  galleryImage: GalleryImage,
  limits: DpiLevels,
  pattern: { elementWidth: number; elementHeight: number; pattern: ImagePattern } | undefined,
) => {
  const dpi = getImageDPI(imageWidth, imageHeight, galleryImage, pattern);
  return getDpiLevel(dpi, limits);
};

export default getDpiWarningLevel;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import IconCopy from '../Icon/IconCopy';
import IconEdit from '../Icon/IconEdit';
import IconPlus from '../Icon/IconPlus';
import IconRemove from '../Icon/IconRemove';
import IconX from '../Icon/IconX';

import styles from './index.module.scss';

interface Props {
  canAddAfter: boolean;
  canRemove: boolean;
  onClose: () => void;
  onEdit: () => void;
  onDuplicate: () => void;
  onRemove: () => void;
  onAdd: () => void;
}

function SpreadMobileMenu({ canAddAfter, canRemove, onClose, onEdit, onDuplicate, onRemove, onAdd }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.confirmationMenu}>
      <div className="row-flex row-flex--middle row-flex--between">
        <div className={styles.menuHeading}>{t('Pages options')}</div>
        <div className={styles.closeIconWrapper}>
          <IconX className={styles.closeIcon} onClick={onClose} />
        </div>
      </div>

      <div className={styles.menuButtonsWrapper}>
        <Button tertiary stopPropagation className={cn(styles.menuButton)} onClick={onEdit}>
          <IconEdit className={styles.buttonIcon} />
          <span>{t('Edit pages above')}</span>
        </Button>
        <Button tertiary stopPropagation className={cn(styles.menuButton)} onClick={onAdd} disabled={!canAddAfter}>
          <IconPlus className={styles.buttonIcon} />
          <span>{t('Add pages below')}</span>
        </Button>
        <Button
          tertiary
          stopPropagation
          className={cn(styles.menuButton)}
          onClick={onDuplicate}
          disabled={!canAddAfter}
        >
          <IconCopy className={styles.buttonIcon} />
          <span>{t('Duplicate pages above')}</span>
        </Button>
        <Button
          tertiary
          stopPropagation
          className={cn(styles.menuButton, styles.dangerButton)}
          onClick={onRemove}
          disabled={!canRemove}
        >
          <IconRemove className={styles.buttonIcon} />
          <span>{t('Delete pages above')}</span>
        </Button>
      </div>
    </div>
  );
}

export default React.memo(SpreadMobileMenu);

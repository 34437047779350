import { setDesignDataAction } from 'editor/src/store/design/slice';
import { DesignData } from 'editor/src/store/design/types';
import type { Thunk } from 'editor/src/store/hooks';

import reflectDesignData from 'editor/src/util/reflectDesignData';
import getReflectContext from 'editor/src/util/reflectDesignData/getReflectContext';

const applyMockupStructureOperation =
  (structure: DesignData): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const { designData } = state.design;

    const design = designData ? reflectDesignData(designData, structure, getReflectContext(state)) : structure;

    dispatch(setDesignDataAction(design));
  };

export default applyMockupStructureOperation;

import React from 'react';

import Icon from './index';

interface Props {
  className?: string;
}

function IconRectangle({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.5" y="1" width="16" height="16" stroke="#212121" strokeWidth="2" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export default React.memo(IconRectangle);

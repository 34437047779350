import cn from 'classnames';
import React, { useMemo } from 'react';
import Select, { SingleValue } from 'react-select';

import { useDispatch, useSelector } from 'editor/src/store/hooks';
import resetPageCountOperation from 'editor/src/store/variants/operation/resetPageCountOperation';
import getIsAProductSelected from 'editor/src/store/variants/selector/getIsAProductSelected';
import { PageOptions } from 'editor/src/store/variants/types';

import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';

import generatePageOptions, { Option } from './generatePageOptions';

import styles from './index.module.scss';

import controlStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControl.module.scss';

interface Props {
  selectedPageCount: number;
  pages: PageOptions;
}

function PageCountControl({ pages, selectedPageCount }: Props) {
  const dispatch = useDispatch();
  const isAProductSelected = useSelector(getIsAProductSelected);
  const isSingleSelection = useSelector((state) => state.variants.configuration.singleSelection);
  const productUid = useSelector((state) => state.design.designData?.product_uid) || '';
  const dimensions = useSelector((state) => state.design.designData?.dimensions);
  const options = useMemo(() => generatePageOptions(pages), [pages]);

  function onSelect(option: SingleValue<Option>) {
    if (option) {
      dispatch(resetPageCountOperation(option.value, dimensions, productUid));
    }
  }

  const selectedOption = options.find((option) => option.value === selectedPageCount);

  return (
    <>
      <PropertySeparator bigMargin />
      <div className={controlStyles.controlTitle}>{pages.name}</div>
      <Select
        placeholder=""
        options={options}
        onChange={onSelect}
        className={cn('cy-page-count-select', {
          [styles.disabled]: !isSingleSelection && isAProductSelected,
        })}
        value={selectedOption}
      />
    </>
  );
}

export default React.memo(PageCountControl);

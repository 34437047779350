import cn from 'classnames';
import React from 'react';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import isTopMenuDropdownVisible from 'editor/src/store/app/selector/isTopMenuDropdownVisible';
import { TopMenuDropdown } from 'editor/src/store/app/types';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import startCropModeOperation from 'editor/src/store/editor/operation/startCropModeOperation';
import { setZoomModeAction as setZoomModeOperation } from 'editor/src/store/editor/slice';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconZoomIn from 'editor/src/component/Icon/IconZoomIn';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import MenuButton from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';

import styles from './index.module.scss';

function ButtonCropZoom({ mode, close, isMobile, context }: MenuItemProps) {
  const isActive = useSelector((state) =>
    isMobile
      ? isSidebarTabActive(state, TAB_NAMES.CROP_ZOOM)
      : isTopMenuDropdownVisible(state, TopMenuDropdown.CropZoom),
  );
  const dispatch = useDispatch();
  const { structureIndexes } = context;

  const handleClick = () => {
    if (isMobile) {
      if (isActive) {
        batch(() => {
          dispatch(setSidebarActiveTabOperation(-1));
          dispatch(setZoomModeOperation(false));
        });
      } else {
        batch(() => {
          if (structureIndexes[0]) {
            dispatch(startCropModeOperation(structureIndexes[0].uuid));
          }
          dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.CROP_ZOOM));
          dispatch(setZoomModeOperation(true));
        });
      }
    } else {
      dispatch(setActiveTopMenuDropdownOperation(TopMenuDropdown.CropZoom));
      close?.();
    }
  };

  return (
    <MenuButton
      Icon={IconZoomIn}
      on={isActive}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-zoom"
      className={cn('cy-button-crop-zoom', styles.zoomInIcon)}
    />
  );
}

export default React.memo(ButtonCropZoom);

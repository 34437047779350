import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TabBar from '../../../TabBar';
import TabContentHeader from '../TabContentHeader';

import { ShutterstockAssetProviderType, ShutterstockLicenseType } from './constants';
import FilterBlock from './FilterBlock';
import FooterBlock from './FooterBlock';
import ShutterstockWidgetBlock from './ShutterstockWidgetBlock';
import { SearchParams } from './types';

import styles from './index.module.scss';

function ShutterstockTabContent() {
  const { t } = useTranslation();
  const defaultSearchString = t('shutterstock-default-search-query');
  const { ShutterstockWidget } = window as any;
  const [showContent, setShowContent] = useState(false);
  const [widgetInstance, setWidgetInstance] = useState<typeof ShutterstockWidget | null>(null);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    query: defaultSearchString,
    license: ShutterstockLicenseType.commercial,
  });

  const [activeTab, setActiveTab] = useState(ShutterstockAssetProviderType.essential);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://api-cdn.shutterstock.com/2.0.6/static/js/sstk-widget.js';
    script.addEventListener('load', () => setShowContent(true));
    document.body.appendChild(script);

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = 'https://api-cdn.shutterstock.com/2.0.6/static/css/sstk-widget.css';
    document.head.appendChild(link);

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(link);
    };
  }, []);

  const assetProviderTabs = [
    {
      key: ShutterstockAssetProviderType.essential,
      label: t(ShutterstockAssetProviderType.essential),
    },
    {
      key: ShutterstockAssetProviderType.premium,
      label: t(ShutterstockAssetProviderType.premium),
    },
  ];

  const selectTabHandler = (assetProviderTab: ShutterstockAssetProviderType) => {
    setActiveTab(assetProviderTab);
  };

  const onFilterChangeHandler = (newSearchParams?: SearchParams) => {
    if (widgetInstance) {
      widgetInstance.getPage('searchPage').search({
        query: newSearchParams?.query || defaultSearchString,
        image_type: newSearchParams?.imageType,
        orientation: newSearchParams?.orientation,
        license: newSearchParams?.license || searchParams.license, // Filter the media according to one or more license types; valid values are "commercial", "editorial"
      });
    }
    setSearchParams(newSearchParams || searchParams);
  };

  const onRefresh = () => {
    onFilterChangeHandler(searchParams);
  };

  return (
    <div className={cn('cy-tab-content', 'cy-tab-shutterstock', styles.tabContainer)} data-ignore-outside-click>
      <TabContentHeader title={t('editor-shutterstock')} className={styles.headerBlock} />
      <TabBar<ShutterstockAssetProviderType>
        tabs={assetProviderTabs}
        onSelect={selectTabHandler}
        activeTab={activeTab}
        tabClassName="cy-shutterstock-collection-tab"
      />
      <FilterBlock onChange={onFilterChangeHandler} activeFilters={searchParams} />
      {showContent && (
        <ShutterstockWidgetBlock
          setWidgetInstance={setWidgetInstance}
          assetProviderType={activeTab}
          onRefresh={onRefresh}
        />
      )}
      <FooterBlock assetProviderType={activeTab} />
    </div>
  );
}

export default ShutterstockTabContent;

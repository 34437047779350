import React from 'react';
import { useTranslation } from 'react-i18next';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';

import ButtonResetSettings from './ButtonResetSettings';
import ShowBleedsCheckbox from './ShowBleedsCheckbox';
import ShowGridCheckbox from './ShowGridCheckbox';
import ShowGuidesCheckbox from './ShowGuidesCheckbox';
import ShowPerspectiveLinesCheckbox from './ShowPerspectiveLinesCheckbox';
import ShowRollerStripesCheckbox from './ShowRollerStripesCheckbox';
import ShowRulersCheckbox from './ShowRulersCheckbox';

function SettingsTabContent({ title }: TabContentProps) {
  const { t } = useTranslation();

  return (
    <>
      <TabContentHeader title={title || t('editor-settings')} />
      <ContentBlock scroll>
        <ShowGridCheckbox />
        <ShowGuidesCheckbox />
        <ShowBleedsCheckbox />
        <ShowRulersCheckbox />
        <ShowRollerStripesCheckbox />
        <ShowPerspectiveLinesCheckbox />
        <ButtonResetSettings />
      </ContentBlock>
    </>
  );
}

export default React.memo(SettingsTabContent);

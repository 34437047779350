import { batch } from 'editor/src/store/batchedSubscribeEnhancer';

import { ThunkDispatch } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/index';
import getRequestVersion from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getRequestVersion';
import {
  setResultsAction,
  setRequestVersionAction,
  setActiveFiltersAction,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/slice';
import {
  ResultsData,
  ContentPluginRootState,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const setResultsOperation =
  (data: ResultsData) => (dispatch: ThunkDispatch, getState: () => ContentPluginRootState) => {
    const state = getState();
    const requestVersion = getRequestVersion(state);

    batch(() => {
      if (requestVersion === data.resultsVersion || data.resultsVersion === 0) {
        if (data.resultsVersion === 0) {
          dispatch(setRequestVersionAction(0));
        }

        dispatch(setResultsAction(data));
        dispatch(setActiveFiltersAction(data.search.filters));
      }
    });
  };

export default setResultsOperation;

import type { ThunkDispatch } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import requestEcommerceVariantOperation from './requestEcommerceVariantOperation';

const setECommerceProductIdOperation =
  (newVariantId: string) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const variantSwitchConfirmationEnabled = getHostSetting(state, 'variantSwitchConfirmationEnabled');
    const spreads = state.design.designData?.spreads || [];
    const designDataTouched = spreads.some((spread) => !!spread.pages?.[0].groups.media?.length);
    if (!variantSwitchConfirmationEnabled || !designDataTouched) {
      dispatch(requestEcommerceVariantOperation(newVariantId));
    } else {
      sendPostMessage('variants.requestECommerceVariantDesignToValidate', { gelatoVariantId: newVariantId });
    }
  };

export default setECommerceProductIdOperation;

import { useEffect, useState } from 'react';

const useBottomPadding = () => {
  const [bottomPadding, setBottomPadding] = useState(0);

  useEffect(() => {
    const updateBottomPadding = () => {
      const fixedBottomBar = document.getElementsByClassName('fixed-bottom-bar')[0];
      const height = fixedBottomBar?.clientHeight || 0;
      setBottomPadding(height);
    };

    updateBottomPadding();

    window.addEventListener('resize', updateBottomPadding);
    return () => window.removeEventListener('resize', updateBottomPadding);
  }, []);

  return bottomPadding;
};

export default useBottomPadding;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconArrowLeft({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.6569 4.92894C15.0474 4.53842 15.6805 4.53842 16.0711 4.92894C16.4616 5.31947 16.4616 5.95263 16.0711 6.34316L11.4142 11L24 11L24 13L11.4142 13L16.0711 17.6569C16.4616 18.0474 16.4616 18.6806 16.0711 19.0711C15.6805 19.4616 15.0474 19.4616 14.6569 19.0711L8.29289 12.7071Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconArrowLeft;

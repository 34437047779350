import React from 'react';
import { useTranslation } from 'react-i18next';

import removeImageOperation from 'editor/src/store/gallery/operation/removeImageOperation';
import { useDispatch } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import ConfirmationMenu from 'editor/src/component/ConfirmationMenu';
import IconBin from 'editor/src/component/Icon/IconBin';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';
import useMobileMenu from 'editor/src/component/useMobileMenu';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  imageId: string;
}

function RemoveImageButton({ imageId }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const deleteImageConfirmation = useMobileMenu();

  const onClick = (e: any) => {
    e.stopPropagation();
    if (!isMobile) {
      removeImage();
    } else {
      deleteImageConfirmation.open();
    }
  };

  const handleCancel = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    deleteImageConfirmation.close();
  };
  const removeImage = () => {
    dispatch(removeImageOperation(imageId));
  };

  return (
    <div className={styles.removeImageButtonWrap}>
      <WithTooltip overlay={t('editor-remove-image')}>
        <Button secondary className={styles.removeImageButton} noPadding onClick={onClick} stopPropagation>
          <IconBin />
        </Button>
      </WithTooltip>
      {isMobile &&
        deleteImageConfirmation.render(
          <ConfirmationMenu
            headerText={t('Delete image')}
            headerDescriptionText={t('Are you sure you want to delete the image')}
            confirmButtonText={t('Delete image')}
            cancellButtonText={t('Cancel')}
            onCancel={handleCancel}
            onConfirm={removeImage}
            onCrossClick={handleCancel}
          />,
        )}
    </div>
  );
}

export default React.memo(RemoveImageButton);

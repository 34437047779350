import cn from 'classnames';
import React from 'react';

import IconLocked from 'editor/src/component/Icon/IconLocked';
import IconUnlocked from 'editor/src/component/Icon/IconUnlocked';

import styles from './index.module.scss';

export interface Props {
  isLinked: boolean;
  disabled?: boolean;
  onToggle(): void;
}

function VariantLock({ isLinked, disabled, onToggle }: Props) {
  function onClick(e: React.MouseEvent) {
    e.stopPropagation();
    onToggle();
  }

  return isLinked ? (
    <IconLocked
      className={cn(styles.lockIcon, 'cy-variant-lock-button', 'cy-lock', {
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    />
  ) : (
    <IconUnlocked
      className={cn(styles.unlockIcon, 'cy-variant-lock-button', 'cy-unlock', {
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    />
  );
}

export default React.memo(VariantLock);

import cn from 'classnames';
import React, { useState, useCallback, useEffect, useRef } from 'react';

import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import { ColorOverrides } from 'editor/src/store/design/types';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { GalleryImage, MimeType } from 'editor/src/store/gallery/types';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';
import shouldDigitizeCurrentSpread from 'editor/src/store/utils/shouldDigitizeCurrentSpread';

import ColorTileControl from 'editor/src/component/ColorPicker/ColorTileControl';
import ControlButton from 'editor/src/component/ControlButton';
import ImageColorTabContent from 'editor/src/component/DesktopSidebar/TabContents/ImageColorTabContent';
import DropDown from 'editor/src/component/DropDown';
import IconMoreActive from 'editor/src/component/Icon/IconMoreActive';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';
import useMobileMenu from 'editor/src/component/useMobileMenu';

import useCurrentElementColors from './useCurrentElementColors';
import { COLORS_THRESHOLD } from './utils';

import styles from './index.module.scss';

export const canImageBeColored = (image: GalleryImage) => !!image.colors?.length && image.type === MimeType.SVG;

export const canShow: CanShow = (state) => {
  const selectedMediaElementUuid = getSelectedImageUuid(state);

  if (selectedMediaElementUuid && !shouldDigitizeCurrentSpread(state)) {
    const mediaObject = getMediaElementByUuid(state, selectedMediaElementUuid);
    if (mediaObject?.type === 'image') {
      const selectedImage = getImageById(state, mediaObject.imageId);
      return !!selectedImage && canImageBeColored(selectedImage);
    }
  }

  return false;
};

function ButtonImageColor({ isMobile, refresh }: MenuItemProps) {
  const dispatch = useDispatch();

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const activeColorIndex = useSelector((state) => state.editorModules.sidebar.payload) as number | undefined;
  const imageColors = useCurrentElementColors();
  const selectedMediaElementId = useSelector(getSelectedImageUuid);
  const store = useStore();

  const previousImageColors = useRef<ColorOverrides[]>([]);
  const colorMenu = useMobileMenu(false, true);

  useEffect(() => {
    if (refresh && previousImageColors.current.length !== imageColors.length) {
      refresh();
    }
    previousImageColors.current = imageColors;
  }, [imageColors, refresh]);

  const handleClick = (index: number) => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.IMAGE_COLOR, false, index));

    if (isMobile) {
      colorMenu.open();
    }
  };

  const closeMenu = useCallback(() => {
    colorMenu.close();
    dispatch(setSidebarActiveTabOperation(-1));
  }, []);

  useEffect(() => {
    // when switching between images, we reselect the first color
    if (imageColors.length && getSidebarActiveTab(store.getState()) === TAB_NAMES.IMAGE_COLOR) {
      handleClick(0);
    }
  }, [selectedMediaElementId]);

  const close = useCallback(() => setDropdownVisible(false), []);
  const toggle = useCallback(() => setDropdownVisible((prevValue) => !prevValue), []);

  if (!imageColors.length || !selectedMediaElementId) {
    return null;
  }

  const colorsThreshold = COLORS_THRESHOLD[isMobile ? 'MOBILE' : 'DESKTOP'];
  const shouldShowDropDown = imageColors.length > colorsThreshold;

  return (
    <>
      <div className={cn(styles.controls, 'cy-button-image-colors')}>
        {imageColors.slice(0, colorsThreshold).map((color, index) => (
          <ColorTileControl
            key={index}
            className="cy-svg-tile"
            onClick={() => handleClick(index)}
            isActive={index === activeColorIndex}
            color={color.new}
          />
        ))}
        {shouldShowDropDown && (
          <ControlButton onClick={toggle} stopPropagation className="cy-button-more-colors">
            <IconMoreActive />
          </ControlButton>
        )}
      </div>
      <DropDown
        isVisible={dropdownVisible}
        onClickOutside={close}
        openPosition="bottom-center"
        className={styles.dropdown}
        wrapperClassName={styles.wrapper}
      >
        {imageColors.slice(colorsThreshold).map((color, index) => (
          <div key={index}>
            <ColorTileControl
              isActive={index === activeColorIndex}
              color={color.new}
              onClick={() => handleClick(colorsThreshold + index)}
            />
          </div>
        ))}
      </DropDown>
      {colorMenu.render(<ImageColorTabContent closeMenuCb={closeMenu} />)}
    </>
  );
}

export default React.memo(ButtonImageColor);

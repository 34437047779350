const getDelimiters = (minWidth: number, width: number): number[] => {
  const delimiters = [0];
  const count = Math.ceil(width / minWidth);

  for (let i = 1; i < count + 1; i += 1) {
    delimiters.push(i * minWidth);
  }

  return delimiters;
};

export default getDelimiters;

import { useCallback } from 'react';

import { MediaElement, MediaText } from 'editor/src/store/design/types';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import getCurrentFontStylesProperty from 'editor/src/store/fonts/selector/getCurrentFontStylesProperty';
import { FontStyles } from 'editor/src/store/fonts/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

function useSliderChangeHandlers<T extends keyof FontStyles>(
  selectedElement: MediaText | undefined,
  fontStylePropertyName: T,
  liveUpdate: (element: MediaElement) => void,
) {
  const dispatch = useDispatch();
  const value = useSelector((state) => getCurrentFontStylesProperty(state, fontStylePropertyName));

  const onChange = useCallback(
    (fontStylePropertyValue: number) => {
      if (selectedElement) {
        liveUpdate({
          ...selectedElement,
          extra: {
            ...selectedElement.extra,
            [fontStylePropertyName]: fontStylePropertyValue,
          },
        });
      }
    },
    [selectedElement],
  );

  const onChanged = useCallback((fontStylePropertyValue: number) => {
    dispatch(
      applyFontStylesToSelectionOperation({
        [fontStylePropertyName]: fontStylePropertyValue,
      }),
    );
  }, []);

  return { onChange, onChanged, value };
}

export default useSliderChangeHandlers;

import cn from 'classnames';
import React, { RefObject, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getDesignCalendarConfigs from 'editor/src/store/design/selector/getDesignCalendarConfigs';
import {
  addOrUpdateCalendarEventsAction,
  setAllowPersonalizationCalendarEventsFlagAction,
} from 'editor/src/store/design/slice';
import { CalendarEvent } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import Button from 'editor/src/component/Button';
import Checkbox from 'editor/src/component/Checkbox';
import IconPlus from 'editor/src/component/Icon/IconPlus';
import SwitchButton from 'editor/src/component/SwitchButton';

import AddEditEventForm from '../AddEditEventForm';
import EventRow from '../EventRow';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-datepicker/dist/react-datepicker.css';

import styles from './index.module.scss';

function CustomEvents() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    events: calendarEvents = [],
    allowPersonalization,
    showEvents,
  } = useSelector((state) => getDesignCalendarConfigs(state));
  const [editModeEvent, setEditModeEvent] = useState<CalendarEvent | undefined>();
  const [activeElementRef, setActiveElementRef] = useState<React.RefObject<HTMLDivElement> | undefined>();

  const newEventDivRef = useRef<HTMLDivElement>(null);

  const customEvents = useMemo(() => {
    return (
      calendarEvents
        ?.filter(({ custom, merchant }) => (isEmbedded ? custom : merchant))
        .sort(
          (e1, e2) => new Date(e1.year, e1.month, e1.day).getTime() - new Date(e2.year, e2.month, e2.day).getTime(),
        ) || []
    );
  }, [calendarEvents]);

  const addEvent = (e: Event) => {
    setEditModeEvent(undefined);
    setActiveElementRef(newEventDivRef);
    e.stopPropagation();
  };

  const openEditForm = (event: CalendarEvent, divRef: RefObject<HTMLDivElement>) => {
    setActiveElementRef(divRef);
    setEditModeEvent(event);
  };

  const closeEditForm = () => {
    setEditModeEvent(undefined);
    setActiveElementRef(undefined);
  };

  const toggleAllowPersonalization = () => {
    dispatch(setAllowPersonalizationCalendarEventsFlagAction(!allowPersonalization));
  };

  const isAllSelected = useMemo(() => {
    return customEvents.every(({ checked }) => checked);
  }, [customEvents]);

  const toggleAllPublicEvents = () => {
    dispatch(addOrUpdateCalendarEventsAction(customEvents.map((e) => ({ ...e, checked: !isAllSelected }))));
  };

  return (
    <div className={styles.calendarEventsContentBlock}>
      <div ref={newEventDivRef}>
        <Button
          primary
          rounded
          fullWidth
          stopPropagation
          onClick={addEvent}
          className={cn(styles.addNewEventBtn, 'cy-create-custom-event')}
          disabled={!showEvents}
        >
          <IconPlus />
          {t('Add new event')}
        </Button>
      </div>
      <AddEditEventForm elementRef={activeElementRef} closeForm={closeEditForm} event={editModeEvent} />
      {customEvents.length > 1 && (
        <Checkbox
          className={cn('cy-select-all-events-btn', styles.selectAllBlock)}
          on={isAllSelected}
          disabled={!showEvents}
          onChange={toggleAllPublicEvents}
          label={<span className={styles.selectAllLabel}>{t('Select all')}</span>}
        />
      )}
      {customEvents.map((event) => (
        <EventRow event={event} key={event.id} openEditForm={openEditForm} disabled={!showEvents} />
      ))}
      {!isEmbedded && (
        <div
          className={cn(styles.allowPersonalizationBlock, { [styles.allowPersonalizationBlockDisabled]: !showEvents })}
        >
          <SwitchButton
            on={Boolean(allowPersonalization)}
            onSwitch={toggleAllowPersonalization}
            disabled={!showEvents}
          />
          <div>
            {t('Allow personalization')}
            <div className={styles.allowPersonalizationSubTitle}>{t('Let your customers add their own events')}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(CustomEvents);

import { DataState } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const resetSearchReducer = (state: DataState) => {
  state.loadingMore = false;
  if (state.results) {
    state.results.elements = [];
  }
};

export default resetSearchReducer;

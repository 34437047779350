import memoize from 'lodash/memoize';

import { DesignData } from 'editor/src/store/design/types';
import { DEFAULT_MEDIUM_DPI } from 'editor/src/store/hostSettings/slice';
import { DpiLevels } from 'editor/src/store/hostSettings/types';

const getDpiLevelsFromDesign = (designData: DesignData | undefined): DpiLevels => {
  const restrictions = designData?.resolution?.dpi;
  return restrictions
    ? {
        low: restrictions?.min,
        medium: restrictions?.ok,
      }
    : { low: 50, medium: DEFAULT_MEDIUM_DPI };
};

const getDpiLevelsFromDesignMemoized = memoize(
  (designData: DesignData | undefined) => getDpiLevelsFromDesign(designData),
  (designData: DesignData | undefined) => designData?.resolution?.dpi,
);

export default getDpiLevelsFromDesignMemoized;

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconText({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          // eslint-disable-next-line max-len
          d="M20 33.333C19.3887 33.333 18.868 33.118 18.438 32.688C18.0073 32.2573 17.792 31.7363 17.792 31.125V11.125H10.542C9.93067 11.125 9.40967 10.9097 8.97901 10.479C8.54834 10.0483 8.33301 9.51366 8.33301 8.87499C8.33301 8.26366 8.54834 7.74299 8.97901 7.31299C9.40967 6.88233 9.93067 6.66699 10.542 6.66699H29.458C30.0693 6.66699 30.5903 6.88233 31.021 7.31299C31.4517 7.74299 31.667 8.26366 31.667 8.87499C31.667 9.51366 31.4517 10.0483 31.021 10.479C30.5903 10.9097 30.0693 11.125 29.458 11.125H22.208V31.125C22.208 31.7363 21.9927 32.2573 21.562 32.688C21.132 33.118 20.6113 33.333 20 33.333Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconText;

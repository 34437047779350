import { fabric } from 'fabric';

import { Coords, MediaLine } from 'editor/src/store/design/types';

import FabricRect from 'editor/src/fabric/FabricRect';

import { getLineCapOffset } from './utils';

const fabricPropsToLineElementData = (
  px2mm: (size: number) => number,
  pageCoords: Coords,
  rect: FabricRect,
  rounded: boolean,
): Partial<MediaLine> => {
  const { left = 0, top = 0, width = 0, angle = 0, height = 0 } = rect;
  const endPoint = fabric.util.rotatePoint(
    new fabric.Point(left + width, top),
    new fabric.Point(left, top),
    fabric.util.degreesToRadians(angle),
  );
  const lineCapOffset = getLineCapOffset(rounded, height, angle);
  return {
    x1: px2mm(left - pageCoords.left + lineCapOffset.x),
    y1: px2mm(top - pageCoords.top + lineCapOffset.y),
    x2: px2mm(endPoint.x - pageCoords.left - lineCapOffset.x),
    y2: px2mm(endPoint.y - pageCoords.top - lineCapOffset.y),
  };
};

export default fabricPropsToLineElementData;

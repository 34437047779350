import cn from 'classnames';
import React from 'react';

import SelectButton, { SelectButtonGroupElement } from './SelectButton';

import styles from './index.module.scss';

interface Props<T> {
  items: SelectButtonGroupElement<T>[];
  disabled?: boolean;
  value: T;
  onChange: (value: T) => void;
  fullWidth?: boolean;
  className?: string;
}

function SelectButtonGroup<T>({ items, disabled, value, onChange, fullWidth, className }: Props<T>) {
  return (
    <div
      className={cn(styles.SelectButtonGroup, className, {
        [styles.disabled]: disabled,
        [styles.fullWidth]: fullWidth,
      })}
    >
      {items.map((item, i) => (
        <SelectButton key={i} disabled={disabled} isActive={value === item.value} onChange={onChange} element={item} />
      ))}
    </div>
  );
}

export default React.memo(SelectButtonGroup) as <T>(props: Props<T>) => JSX.Element;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updateMediaElementByUuidOperation from 'editor/src/store/design/operation/updateMediaElementByUuidOperation';
import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import setFocusedMediaElementUuidOperation from 'editor/src/store/editor/operation/setFocusedMediaElementUuidOperation';
import getPreviewModePluginName from 'editor/src/store/editor/selector/getPreviewModePluginName';
import { setPluginPreviewModeAction as setPluginPreviewModeOperation } from 'editor/src/store/editor/slice';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { getAppliedManipulationPluginNames } from 'editor/src/store/plugins/utils/imageManipulationUtils';

export interface ResetModifiedImageOperationParams {
  assetId: string;
  elementId: number;
}

const resetModifiedImageOperation =
  ({ assetId, elementId }: ResetModifiedImageOperationParams) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const mediaImage = getMediaElementByUuid(state, elementId);
    if (mediaImage?.type !== 'image') {
      return;
    }

    const plugins = { ...(mediaImage.plugins || {}) };

    // delete all manipulation plugins data
    getAppliedManipulationPluginNames(mediaImage, state.plugins.list).forEach(
      (pluginName: string) => delete plugins[pluginName],
    );

    batch(() => {
      dispatch(setFocusedMediaElementUuidOperation(undefined));
      dispatch(
        updateMediaElementByUuidOperation(elementId, {
          imageId: assetId,
          url: getImageById(state, assetId)?.url,
          loading: undefined,
          sourceAssetId: undefined,
          plugins,
        }),
      );

      // reset preview mode
      if (getPreviewModePluginName(state, elementId)) {
        dispatch(setPluginPreviewModeOperation());
      }
    });
  };

export default resetModifiedImageOperation;

import { ColorOverrides } from 'editor/src/store/design/types';

function getNewColorOverrides(colorOverrides: ColorOverrides[], color: ColorOverrides): ColorOverrides[] {
  const list = colorOverrides;
  const lookupTable: { [key: string]: string } = {};
  list.forEach((c) => {
    lookupTable[c.previous] = c.new;
  });

  if (color.previous === color.new) {
    delete lookupTable[color.previous];
  } else {
    lookupTable[color.previous] = color.new;
  }

  return Object.keys(lookupTable).map((previous) => ({
    previous,
    new: lookupTable[previous],
  }));
}

export default getNewColorOverrides;

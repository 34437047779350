import { useCallback } from 'react';

import setSettingsValueOperation from 'editor/src/store/editor/operation/setSettingsValueOperation';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

interface RulersControlData {
  showRulers: boolean;
  showControl: boolean;
  toggleRulers: () => void;
}

function useShowRulersControl(): RulersControlData {
  const dispatch = useDispatch();

  const showRulers = useSelector((state) => getSettingsValue(state, SettingsProperty.showRulers));
  const showControl = useSelector((state) => getHostSetting(state, 'allowShowRulersChange'));
  const toggleRulers = useCallback(() => {
    dispatch(setSettingsValueOperation(SettingsProperty.showRulers, !showRulers));
  }, [showRulers]);

  return {
    showRulers,
    showControl,
    toggleRulers,
  };
}

export default useShowRulersControl;

type RGBColor = [r: number, g: number, b: number];

/**
 * Returns the average color of the section of a given canvas
 * @returns the average color
 */
export function findCanvasAreaAverageColor(
  canvas: HTMLCanvasElement,
  x: number,
  y: number,
  width: number,
  height: number,
): RGBColor {
  const scaleX = canvas.width / canvas.clientWidth; // taking into consideration pixel ratio
  const scaleY = canvas.height / canvas.clientHeight; // taking into consideration pixel ratio
  let imgData: ImageData | undefined;
  try {
    imgData = canvas.getContext('2d')?.getImageData(x * scaleX, y * scaleY, width * scaleX, height * scaleY);
  } catch (e) {
    // this is triggered when the canvas is tained after drawing an image while uploading it, without
    // the cors attribute set..
  }

  if (!imgData) {
    return [255, 255, 255];
  }

  const color: RGBColor = [0, 0, 0];
  for (let i = 0; i < imgData.data.length; i += 4) {
    color[0] += imgData.data[i]; // r
    color[1] += imgData.data[i + 1]; // g
    color[2] += imgData.data[i + 2]; // b
  }

  const count = imgData.data.length / 4;
  color[0] = Math.floor(color[0] / count);
  color[1] = Math.floor(color[1] / count);
  color[2] = Math.floor(color[2] / count);

  return color;
}

function sRGBtoLin(colorChannel: number) {
  const normalizedValue = colorChannel / 255;
  if (normalizedValue <= 0.04045) {
    return normalizedValue / 12.92;
  }

  return ((normalizedValue + 0.055) / 1.055) ** 2.4;
}

/**
 * Returns the perceived brightness, a number between 0 - 100;
 * see https://stackoverflow.com/questions/596216/formula-to-determine-perceived-brightness-of-rgb-color
 * @returns a number between 0 and 100
 */
export function getPerceivedBrightness(color: RGBColor) {
  const r = sRGBtoLin(color[0]);
  const g = sRGBtoLin(color[1]);
  const b = sRGBtoLin(color[2]);

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  if (luminance <= 216 / 24389) {
    return luminance * (24389 / 27);
  }
  return luminance ** (1 / 3) * 116 - 16;
}

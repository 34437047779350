import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProductControlOptionColor } from 'editor/src/store/variants/types';

import { ControlOptionProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';
import WithTooltip from 'editor/src/component/WithTooltip';

import ColorDot from './ColorDot';

import styles from './index.module.scss';

function ColorElement({
  option,
  selected,
  disabled,
  toggle,
  control,
  unavailable,
}: ControlOptionProps<ProductControlOptionColor>) {
  let overlayTitle = '';
  const { t } = useTranslation();
  if (disabled && option.disabledTitle) {
    overlayTitle = option.disabledTitle;
  } else if (unavailable) {
    const translatedUnavailableMessage = unavailable.map((unavailableMessage) => t(unavailableMessage));
    overlayTitle = translatedUnavailableMessage.join(',');
  } else {
    overlayTitle = !disabled
      ? `${option.title}, ${option.colorHex.split(';').join('/')}`
      : t('Color/size combination not available');
  }

  const canToggle = !disabled || (!!unavailable && selected);

  return (
    <WithTooltip overlay={overlayTitle} placement="top" className={styles.wrapper}>
      <ColorDot
        toggle={() => !disabled && toggle(control, option)}
        className={cn(`cy-color-box-${option.value}`, { [styles.disabled]: !canToggle, 'cy-disabled': disabled })}
        disabled={!!(unavailable || disabled) && !selected}
        colorHex={option.colorHex}
        selected={selected}
        texture={option.texture}
      />
    </WithTooltip>
  );
}

export default React.memo(ColorElement);

import cn from 'classnames';
import React from 'react';

import { StructureIndex } from 'editor/src/store/design/types';

import ButtonCropZoomIn from 'editor/src/component/TopMenuDesktop/buttons/ButtonCropZoom/ButtonCropZoomIn';
import ButtonCropZoomOut from 'editor/src/component/TopMenuDesktop/buttons/ButtonCropZoom/ButtonCropZoomOut';
import ZoomSlider from 'editor/src/component/TopMenuDesktop/buttons/ButtonCropZoom/ZoomSlider';

import styles from './index.module.scss';

interface Props {
  structureIndexes: StructureIndex[];
}

function CropZoomActionBar({ structureIndexes }: Props) {
  return (
    <div className={cn(styles.cropZoomActionBar, 'cy-crop-zoom-action-bar')}>
      <ButtonCropZoomOut structureIndexes={structureIndexes} />
      <ZoomSlider structureIndexes={structureIndexes} />
      <ButtonCropZoomIn structureIndexes={structureIndexes} />
    </div>
  );
}

export default React.memo(CropZoomActionBar);

import getAllMatchingVariants from 'editor/src/store/variants/helpers/getAllMatchingVariants';
import getAllMatchingVariations from 'editor/src/store/variants/helpers/getAllMatchingVariations';
import groupVariations, { getVariantInfoFromGroup } from 'editor/src/store/variants/helpers/groupVariantsBy';

import { RootState } from 'editor/src/store';

import type { i18n } from 'i18next';

const getVariationGroups = (state: RootState, i18n: i18n) => {
  const matchingVariations = getAllMatchingVariations(
    state.variants.product.productVariations,
    state.variants.selectedMultiOptions,
    state.variants.selectedSingleOptions,
    !!state.variants.configuration.singleSelection,
    state.variants.replaceControlKeys,
  );
  const matchingVariantInfos = getAllMatchingVariants(
    matchingVariations,
    state.variants.selectedExternalOptions,
    state.variants.product.externalProductControls,
    state.variants.selectedPageCount,
  );
  return groupVariations(
    matchingVariantInfos,
    state.variants.product,
    state.variants.designTemplates,
    (variations, groupKey) => getVariantInfoFromGroup(variations, groupKey, state.variants.variationGroups),
    state,
    i18n,
  );
};

export default getVariationGroups;

import { Content, Dimensions, SpreadGroundImage } from 'editor/src/store/design/types';

const MARGIN = 70;
const MARGIN_W_BF = 20;

export function getFitZoom(
  canvasDim: Dimensions,
  contentDim: Dimensions,
  hasBgOrFG: boolean,
  thumbnailsHeight: number,
) {
  const margin = hasBgOrFG ? MARGIN_W_BF : MARGIN;

  const horizontalZoom = (canvasDim.width - margin) / contentDim.width;
  const verticalZoom = (canvasDim.height - thumbnailsHeight - margin) / contentDim.height;
  return Math.min(horizontalZoom, verticalZoom);
}

export function getMaxElementWidth(
  background: SpreadGroundImage | undefined,
  foreground: SpreadGroundImage | undefined,
  contentWidth: number,
) {
  return Math.max(
    (background?.imgWidth || 0) * (contentWidth / (background?.width || contentWidth)),
    (foreground?.imgWidth || 0) * (contentWidth / (foreground?.width || contentWidth)),
    contentWidth,
  );
}

export function getMaxElementHeight(
  background: SpreadGroundImage | undefined,
  foreground: SpreadGroundImage | undefined,
  contentHeight: number,
) {
  return Math.max(
    (background?.imgHeight || 0) * (contentHeight / (background?.height || contentHeight)),
    (foreground?.imgHeight || 0) * (contentHeight / (foreground?.height || contentHeight)),
    contentHeight,
  );
}

// compute the best zoom level to see the biggest between background, foreground and spread.
function getBestFitZoom(
  canvasDim: Dimensions,
  spreadWidth: number,
  spreadHeight: number,
  background: SpreadGroundImage | undefined,
  foreground: SpreadGroundImage | undefined,
  focusedContent: Content | undefined,
  mm2px: (x: number) => number,
  bottomBarHeight: number,
) {
  if (spreadWidth === 0 || spreadHeight === 0) {
    return 1;
  }

  const contentWidth = focusedContent ? mm2px(focusedContent.width) : mm2px(spreadWidth);
  const width = getMaxElementWidth(background, foreground, contentWidth);

  const contentHeight = focusedContent ? mm2px(focusedContent.height) : mm2px(spreadHeight);
  const height = getMaxElementHeight(background, foreground, contentHeight);

  return getFitZoom(canvasDim, { width, height }, !!(background || foreground), bottomBarHeight);
}

export default getBestFitZoom;

import cn from 'classnames';
import React from 'react';

import { RootState } from 'editor/src/store';

import ButtonEnterEditTextMode from 'editor/src/component/BottomBarMobile/Buttons/ButtonEnterEditTextMode';
import ButtonFontSize from 'editor/src/component/BottomBarMobile/Buttons/ButtonFontSize';
import { MenuContext } from 'editor/src/component/Menu/type';
import ButtonEffects, { canShow as canShowEffects } from 'editor/src/component/TopMenuDesktop/buttons/ButtonEffects';
import ButtonFontColor from 'editor/src/component/TopMenuDesktop/buttons/ButtonFontColor';
import ButtonFontFamily from 'editor/src/component/TopMenuDesktop/buttons/ButtonFontFamily';
import ButtonStrikethrough from 'editor/src/component/TopMenuDesktop/buttons/ButtonStrikethrough';
import ButtonTextAlignment from 'editor/src/component/TopMenuDesktop/buttons/ButtonTextAlignment';
import { ButtonTextSpacing } from 'editor/src/component/TopMenuDesktop/buttons/ButtonTextSpacing';
import ButtonUnderline from 'editor/src/component/TopMenuDesktop/buttons/ButtonUnderline';
import SplitterVertical from 'editor/src/component/TopMenuDesktop/buttons/SplitterVertical';

import styles from './index.module.scss';

interface Props {
  rootState: RootState;
  menuContext: MenuContext;
}

function TextToolbar({ rootState, menuContext }: Props) {
  return (
    <div className={cn(styles.textPanel, 'cy-mobile-text-toolbar')}>
      <ButtonEnterEditTextMode />
      <ButtonFontFamily isMobile />
      <ButtonFontSize />
      <ButtonFontColor isMobile mode="label-icon-vertical" />
      <ButtonUnderline mode="label-icon-vertical" />
      <ButtonStrikethrough mode="label-icon-vertical" />
      <ButtonTextAlignment isMobile mode="label-icon-vertical" />
      <ButtonTextSpacing isMobile mode="label-icon-vertical" />
      <SplitterVertical />
      {canShowEffects(rootState, menuContext) && <ButtonEffects mode="label-icon-vertical" isMobile />}
    </div>
  );
}

export default React.memo(TextToolbar);

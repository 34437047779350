import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import RGBA from 'editor/src/util/color/rgbaUtils';

import TextInput from '../../PersonalizationContent/PersonalizationElements/TextInput';

interface Props {
  rgba: RGBA;
  onColorChange: (color: string) => void;
  className: string;
  isUnset: boolean;
}

const ALLOWED_HEX_CHAR_REGEX = /^[0-9a-fA-F#]*$/;
const MIN_HEX_COLOR_SIZE = 6;
const IS_ALLOWED_HEX_CHAR = (char: string) => ALLOWED_HEX_CHAR_REGEX.test(char);

function HexInput({ rgba, onColorChange, className, isUnset }: Props) {
  const [color, setColor] = useState(rgba.toHex());

  const applySelectedColor = (unparsedHex?: string) => {
    const parsedRGBA = RGBA.fromHex(unparsedHex || color);
    if (parsedRGBA) {
      onColorChange(parsedRGBA.toString());
    }
  };

  const onHexChanged = useCallback(
    (unparsedHex: string) => {
      setColor(unparsedHex);
      unparsedHex.length >= MIN_HEX_COLOR_SIZE && applySelectedColor(unparsedHex);
    },
    [rgba.a],
  );

  return (
    <TextInput
      className={cn(className, 'cy-hex-input')}
      onChange={onHexChanged}
      onBlur={() => applySelectedColor()}
      value={isUnset ? '' : rgba.toHex()}
      isAllowedChar={IS_ALLOWED_HEX_CHAR}
    />
  );
}

export default React.memo(HexInput);

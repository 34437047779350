import cn from 'classnames';
import React from 'react';

import IconArrowLeft from 'editor/src/component/Icon/IconArrowLeft';

import styles from './index.module.scss';

interface Props {
  title: string;
  className?: string;
  onClick: () => void;
}

function BackHeader({ title, className, onClick }: Props) {
  return (
    <button className={cn(styles.BackHeader, className)} onClick={onClick}>
      <IconArrowLeft className={styles.icon} />
      <div className={styles.title}>{title}</div>
    </button>
  );
}

export default React.memo(BackHeader);

import cn from 'classnames';
import React, { useCallback } from 'react';

import applyRectangleStylesToSelectionOperation from 'editor/src/store/design/operation/shapes/applyRectangleStylesToSelectionOperation';
import getSelectedElementProperty from 'editor/src/store/design/selector/getSelectedElementProperty';
import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import { EMPTY_RGBA_STRING } from 'editor/src/util/color/rgbaUtils';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import ColorPicker from 'editor/src/component/ColorPicker';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from 'editor/src/component/DesktopSidebar/TabContents/colorTabContent.module.scss';

function RectangleFillColorTabContent() {
  const currentColor = useSelector((state) => getSelectedElementProperty(state, 'fill', 'rectangle'));
  const colorPickerConfig = useSelector((state) => getHostSetting(state, 'colorPickerConfig'));

  const element = useSelector((state) => getSelectedMediaElementsObjects(state)[0]);
  const { liveUpdate } = useMediaElementLiveUpdates(element);

  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const clickHandler = useCallback((fill: string | undefined) => {
    dispatch(applyRectangleStylesToSelectionOperation({ fill }));
  }, []);

  const changingColor = (fill: string | undefined) => {
    if ((fill || colorPickerConfig.noColor) && element?.type === 'rectangle') {
      liveUpdate({ ...element, fill: fill || EMPTY_RGBA_STRING });
    }
  };

  return (
    <ContentBlock scroll>
      <div
        className={cn('cy-rectangle-fill-color-tab-content', styles.wrapper, {
          [styles.mobile]: isMobile,
        })}
      >
        <ColorPicker
          color={currentColor}
          onColorChange={clickHandler}
          onColorChanging={changingColor}
          allowOpacity={colorPickerConfig.enableOpacity}
          colors={colorPickerConfig.allowedColors}
          allowNoColor
        />
      </div>
    </ContentBlock>
  );
}

export default React.memo(RectangleFillColorTabContent);

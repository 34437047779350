import { RootState } from 'editor/src/store/index';
import { PluginName, PremiumLevel } from 'editor/src/store/plugins/types';

const VISIBLE_LEVELS = new Set([
  PremiumLevel.GOLD,
  PremiumLevel.SILVER,
  PremiumLevel.GOLD_NEW,
  PremiumLevel.SILVER_NEW,
]);

const isPremiumIconVisible = (state: RootState, pluginName?: PluginName | string) => {
  const plugin = state.plugins.list.find((element) => element.name === pluginName);
  if (!plugin) {
    return false;
  }

  return plugin.extra?.premiumLevel ? VISIBLE_LEVELS.has(plugin.extra.premiumLevel) : false;
};

export default isPremiumIconVisible;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { DesignDimensions } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import selectPageCountOperation from './selectPageCountOperation';
import sendSwitchProductOperation from './sendSwitchProductOperation';

const resetPageCountOperation =
  (pageCount: number, dimensions: DesignDimensions | undefined, productUid: string) => (dispatch: ThunkDispatch) => {
    batch(() => {
      dispatch(selectPageCountOperation(pageCount));
      dispatch(
        sendSwitchProductOperation({
          dimensions,
          pageCount,
          variation: { productUid },
        }),
      );
    });
  };

export default resetPageCountOperation;

import React from 'react';

import Icon from './index';

interface Props {
  className?: string;
}

function IconDownload({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 11.5L5 7.5M9 11.5L13 7.5M9 11.5V1.5"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M1 15.5H17" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export default React.memo(IconDownload);

import React, { useContext } from 'react';

import { SnapController } from './useCreateSnapController';

export const SnapControllerContext = React.createContext<SnapController>(null as any);

function useSnapController() {
  return useContext(SnapControllerContext);
}

export default useSnapController;

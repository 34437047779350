import cn from 'classnames';
import React from 'react';

import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import { useSelector } from 'editor/src/store/hooks';
import getActiveImageManipulationPlugin from 'editor/src/store/plugins/selector/getActiveImageManipulationPlugin';
import showPremiumPrompt from 'editor/src/store/plugins/selector/showPremiumPrompt';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import GelatoPrompt from 'editor/src/component/GelatoPrompt';
import IconInfo from 'editor/src/component/Icon/IconInfo';

import styles from './index.module.scss';

function ImageManipulationTabContent() {
  const element = useSelector((state) => {
    const elementId = getSelectedImageUuid(state);
    return elementId !== undefined ? getMediaElementByUuid(state, elementId) : undefined;
  });

  const activePlugin = useSelector((state) => getActiveImageManipulationPlugin(state, element));
  if (!activePlugin) {
    return null;
  }

  const gelatoPrompt = showPremiumPrompt(activePlugin);

  return (
    <ContentBlock>
      <TabContentHeader title={activePlugin.title} />
      {gelatoPrompt && <GelatoPrompt className={cn('mt-2')} prompt={gelatoPrompt} pluginName={activePlugin.name} />}
      <div className={cn(styles.warning)}>
        <IconInfo className={styles.infoIcon} />
        <span>{activePlugin.extra.previewModeInfo}</span>
      </div>
    </ContentBlock>
  );
}

export default ImageManipulationTabContent;

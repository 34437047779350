import updateMediaElementByUuidOperation from 'editor/src/store/design/operation/updateMediaElementByUuidOperation';
import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import resetModifiedImageOperation from './resetModifiedImageOperation';

interface RestoreBackgroundOperationParams {
  pluginName: string;
  assetId: string;
  elementId: number;
}

const requestOriginalImageOperation =
  ({ pluginName, assetId, elementId }: RestoreBackgroundOperationParams) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();

    const mediaImage = getMediaElementByUuid(state, elementId);
    if (mediaImage?.type !== 'image') {
      return;
    }
    const sourceAssetId = mediaImage?.sourceAssetId;

    if (sourceAssetId) {
      dispatch(resetModifiedImageOperation({ assetId: sourceAssetId, elementId }));
      return;
    }

    dispatch(updateMediaElementByUuidOperation(elementId, { loading: true }));
    sendPostMessage('plugins.requestOriginalImage', {
      pluginName,
      assetId,
      elementId,
    });
  };

export default requestOriginalImageOperation;

import { useRef, useCallback, useState, SetStateAction } from 'react';

import useIsMounted from './useIsMounted';

function useStateWithAutoReset<S>(timeout: number): [S | undefined, (value: SetStateAction<S | undefined>) => void] {
  const [state, setState] = useState<S>();
  const isMounted = useIsMounted();
  const resetTimeout = useRef(-1);

  const setStateAutoReset = useCallback(
    (value: SetStateAction<S | undefined>) => {
      window.clearTimeout(resetTimeout.current);

      setState(value);
      resetTimeout.current = window.setTimeout(() => {
        if (isMounted()) {
          setState(undefined);
        }
      }, timeout);
    },
    [timeout],
  );

  return [state, setStateAutoReset];
}

export default useStateWithAutoReset;

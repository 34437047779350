import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import {
  ColorOverrides,
  ElementAddress,
  ImageBrushData,
  MediaImage,
  PrintTechnology,
} from 'editor/src/store/design/types';
import getNewColorOverrides from 'editor/src/store/design/util/getNewColorOverrides';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getAssetUrlWithReplacedColors from './getAssetUrlWithReplacedColors';
import updateMediaElementOperation, { MediaUpdateActionName } from './updateMediaElementOperation';

// used to recolor SVGs and quantized images
const updateImageElementColorsOperation =
  (elementAddress: ElementAddress, color: ColorOverrides) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const element = getMediaElement<MediaImage>(state, elementAddress);
    const spread = state.design.designData?.spreads[elementAddress.spreadIndex];
    const variantKey = state.variants.selectedGroupKey;
    const designOptionKey = state.variants.selectedDesignOptionValue;

    if (!spread || !element) {
      return;
    }

    const newFillColors = getNewColorOverrides(element.colorOverrides?.fill || [], color);
    const newStrokeColors = getNewColorOverrides(element.colorOverrides?.stroke || [], color);

    const hasOverrides = newFillColors.length > 0 || newStrokeColors.length > 0;

    const colorOverrides: ImageBrushData | undefined = hasOverrides
      ? {
          fill: newFillColors,
          stroke: newStrokeColors,
          url: undefined,
          urlTimestamp: undefined,
        }
      : undefined;

    dispatch(updateMediaElementOperation(elementAddress, { colorOverrides }, MediaUpdateActionName.COLOR_CHANGED));
    if (colorOverrides) {
      getAssetUrlWithReplacedColors(
        element.imageId,
        element.uuid,
        colorOverrides,
        spread.technology === PrintTechnology.Embroidery,
        variantKey,
        designOptionKey,
      );
    }
  };

export default updateImageElementColorsOperation;

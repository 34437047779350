import { captureException } from '@sentry/react';
import { useContext, useEffect } from 'react';

import hasOneReplacableImage from 'editor/src/store/design/selector/hasOneReplacableImage';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import { setDragStateAction } from 'editor/src/store/editor/slice';
import { DraggableItem, DraggableItemType } from 'editor/src/store/editor/types';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { addImageAction } from 'editor/src/store/gallery/slice';
import { ImageState } from 'editor/src/store/gallery/types';
import getMimeTypeEnum from 'editor/src/store/gallery/utils/getMimeTypeEnum';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';

import { logEvent } from 'editor/src/amplitude';
import { DROP_EVENT } from 'editor/src/util/dnd/useDrag';
import imagePlaceholder from 'editor/src/util/imagePlaceholder';
import loadImageFileDimensions from 'editor/src/util/loadImageFileDimensions';

import { UploaderContext } from 'editor/src/component/Uploader/useUploader';

import generateUppyIdFromFile from './generateUppyIdFromFile';

const IMAGES_TYPES = new Set([
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/svg',
  'image/svg+xml',
  'application/pdf',
  'image/heic',
  'image/heif',
]);
const DEFAULT_DIM = 200;

function useSystemDrag() {
  const uploader = useContext(UploaderContext);
  const dispatch = useDispatch();
  const store = useStore();
  const canUploadImage = useSelector((state) => isAddElementsAllowed(state) || hasOneReplacableImage(state));

  useEffect(() => {
    if (!canUploadImage) {
      return undefined;
    }
    let dragStarted = false;

    async function onDrop(e: DragEvent) {
      e.preventDefault();
      dragStarted = false;
      if (!e.dataTransfer) {
        dispatch(setDragStateAction(undefined));
        return;
      }

      const { files } = e.dataTransfer;
      const imagesToUpload: File[] = [];
      const galleryImages = store.getState().gallery.images;

      for (let i = 0; i < files.length; i += 1) {
        const file = files.item(i);
        if (file && IMAGES_TYPES.has(file.type)) {
          try {
            const id = generateUppyIdFromFile(file);
            const image = galleryImages.find((image) => image.uploaderId === id || image.id === id);
            if (image) {
              const elementUnderPointer = document.elementFromPoint(e.clientX, e.clientY);
              const draggableItem: DraggableItem = {
                itemId: image.id,
                itemType: DraggableItemType.image,
                itemThumbnail: image.url,
                sourceId: image.source,
                dimensions: image,
                isMultipleItems: false,
              };
              elementUnderPointer?.dispatchEvent(
                new CustomEvent(DROP_EVENT, {
                  bubbles: true,
                  detail: draggableItem,
                }),
              );
            } else {
              imagesToUpload.push(file);
            }
          } catch (e) {
            captureException(e, { extra: { file } });
          }
        }
      }

      if (imagesToUpload.length) {
        const posX = e.clientX;
        const posY = e.clientY;

        const uploads = uploader.upload(imagesToUpload);
        await Promise.all(
          uploads.map(async ({ file, id }) => {
            const imgDimensions = await loadImageFileDimensions(file);
            dispatch(
              addImageAction({
                id,
                uploaderId: id,
                type: getMimeTypeEnum(file.type),
                width: imgDimensions?.width || DEFAULT_DIM,
                height: imgDimensions?.height || DEFAULT_DIM,
                name: file.name,
                hasAssetDimensions: !!imgDimensions?.width && !!imgDimensions.height,
                state: ImageState.LOCAL_DROP,
              }),
            );
            const elementUnderPointer = document.elementFromPoint(posX, posY);
            const draggableItem: DraggableItem = {
              itemId: id,
              itemType: DraggableItemType.image,
              itemThumbnail: imagePlaceholder,
              isMultipleItems: false,
            };
            elementUnderPointer?.dispatchEvent(
              new CustomEvent(DROP_EVENT, {
                bubbles: true,
                detail: draggableItem,
              }),
            );
          }),
        );
        logEvent('file dropped', {
          count: uploads.length,
          types: uploads.map(({ file }) => file.type),
        });
        dispatch(setDragStateAction(undefined));
      } else {
        dispatch(setDragStateAction(undefined));
      }
    }

    let dragEndTimeout: number | undefined;
    function onDragOver(e: DragEvent) {
      e.preventDefault();
      window.clearTimeout(dragEndTimeout);
      dragEndTimeout = window.setTimeout(() => {
        dispatch(setDragStateAction(undefined));
        dragStarted = false;
      }, 200);
    }

    function onDragEnter() {
      if (dragStarted) {
        return;
      }
      dragStarted = true;
      if (uploader.isOpen()) {
        uploader.close();
      }
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.GALLERY));
      dispatch(setDragStateAction('system'));
    }

    function onDragEnd() {
      window.clearTimeout(dragEndTimeout);
      dispatch(setDragStateAction(undefined));
      dragStarted = false;
    }

    window.addEventListener('drop', onDrop);
    window.addEventListener('dragover', onDragOver);
    window.addEventListener('dragenter', onDragEnter);
    window.addEventListener('dragend', onDragEnd);

    return () => {
      window.clearTimeout(dragEndTimeout);
      window.removeEventListener('drop', onDrop);
      window.removeEventListener('dragover', onDragOver);
      window.removeEventListener('dragenter', onDragEnter);
      window.removeEventListener('dragend', onDragEnd);
    };
  }, [canUploadImage]);
}

export default useSystemDrag;

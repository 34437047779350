import React, { useCallback, useMemo } from 'react';

import CMYK from 'editor/src/util/color/cmykUtils';
import { parseColorToCMYK } from 'editor/src/util/color/colorUtils';

import TextInput from '../../PersonalizationContent/PersonalizationElements/TextInput';

import { IS_ALLOWED_CHAR } from './RGBInput';

interface Props {
  color: string | undefined;
  onColorChange: (color: string) => void;
  className: string;
}

function CMYKInput({ color, onColorChange, className }: Props) {
  const cmykColor = useMemo(() => (color ? parseColorToCMYK(color) : undefined), [color]);

  const onCMYKChanged = useCallback((unparsedCMYK: string) => {
    // replace spaces by commas then remove duplicate commas and trim
    const normalizedInput = unparsedCMYK.replace(/\s+/g, ',').replace(/,+/g, ',').trim();
    const parts = normalizedInput.split(',');
    if (parts.length !== 4) {
      return;
    }

    const cmykValues = [];
    for (let i = 0; i < parts.length; i += 1) {
      const value = parseInt(parts[i], 10);

      if (Number.isNaN(value) || value < 0 || value > 100) {
        return;
      }

      cmykValues.push(value);
    }

    onColorChange(new CMYK({ c: cmykValues[0], m: cmykValues[1], y: cmykValues[2], k: cmykValues[3] }).toString());
  }, []);

  return (
    <TextInput
      className={className}
      onChange={onCMYKChanged}
      value={
        cmykColor
          ? `${Math.round(cmykColor.c)}, ${Math.round(cmykColor.m)}, ${Math.round(cmykColor.y)}, ${Math.round(cmykColor.k)}`
          : ''
      }
      isAllowedChar={IS_ALLOWED_CHAR}
    />
  );
}

export default React.memo(CMYKInput);

import { useMemo } from 'react';

import { MultiOption, ProductControlMulti, SingleOptions } from 'editor/src/store/variants/types';

function useMultiValues(
  control: ProductControlMulti,
  multiOptions: MultiOption[],
  singleOptions: SingleOptions,
  singleMode: boolean,
) {
  const options = useMemo(() => {
    const filteredOptions =
      !singleMode && control.dependsOnSingleControls
        ? multiOptions.filter((option) =>
            option.dependedOptions.every((depOption) => depOption.value === singleOptions[depOption.key]),
          )
        : multiOptions;

    return filteredOptions.map((option) => option.value);
  }, [multiOptions, singleOptions]);

  return { selectedOptions: options, totalCount: multiOptions.length };
}

export default useMultiValues;

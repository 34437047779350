import inRange from 'lodash/inRange';

const FONT_SIZES = [
  6, 8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 56, 64, 72, 80, 88, 96, 104, 120, 144, 168, 192, 216, 240,
];

function getFontSizes(min: number, max: number): number[] {
  return FONT_SIZES.filter((fontSize) => inRange(fontSize, min, max));
}

export default getFontSizes;

import { GridDesign } from '@gelatoas/design-editor-calendar';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import setGridDesignOperation from 'editor/src/store/design/operation/calendarGrid/setGridDesignOperation';
import updateAllGridElementsOperation from 'editor/src/store/design/operation/calendarGrid/updateAllGridElementsOperation';
import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconGrid from 'editor/src/component/Icon/IconGrid';

import GridDesignButton from './GridDesignButton';

import styles from './index.module.scss';

function GridDesignsTabContent({ title, icon }: TabContentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { gridElement, gridDesigns } = useSelector(
    (state) => ({
      gridElement: getMediaElementByUuid(state, state.editor.selectedElementUuids[0]),
      gridDesigns: state.gridDesigns.grids,
    }),
    shallowEqual,
  );

  const selectGridDesign = useCallback((gridDesign: GridDesign) => {
    void dispatch(setGridDesignOperation(gridDesign));
  }, []);

  if (!gridElement || gridElement.type !== 'grid') {
    return null;
  }
  const { designName } = gridElement.grid;

  function onApplyToAllClick() {
    void dispatch(updateAllGridElementsOperation({ designName }));
  }

  return (
    <>
      <TabContentHeader
        className={styles.gridContentHeader}
        title={title || t('editor-grids')}
        icon={icon || <IconGrid />}
      />
      <ContentBlock className={styles.applyToAll}>
        <Button
          secondary
          stopPropagation
          className={cn(styles.applyToAllButton, 'cy-apply-to-all')}
          onClick={onApplyToAllClick}
        >
          {t('editor-apply-to-all')}
        </Button>
      </ContentBlock>
      <ContentBlock scroll className={styles.wrapper}>
        {gridDesigns
          .filter((grid) => !grid.deprecated)
          .map((gridDesign) => (
            <GridDesignButton
              key={gridDesign.name}
              selected={designName === gridDesign.name}
              selectGridDesign={selectGridDesign}
              gridDesign={gridDesign}
            />
          ))}
      </ContentBlock>
    </>
  );
}

export default React.memo(GridDesignsTabContent);

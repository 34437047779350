import { MediaElement } from 'editor/src/store/design/types';

function isElementOutOfRange(element: MediaElement, maxElementSize: number): boolean {
  if (element?.type === 'line') {
    if (
      !Number.isFinite(element.x1) ||
      !Number.isFinite(element.y1) ||
      !Number.isFinite(element.x2) ||
      !Number.isFinite(element.y2)
    ) {
      return true;
    }
  } else {
    if (
      !Number.isFinite(element.x) ||
      !Number.isFinite(element.y) ||
      !Number.isFinite(element.width) ||
      !Number.isFinite(element.height) ||
      !Number.isFinite(element.r)
    ) {
      return true;
    }

    if (
      element.width > maxElementSize ||
      element.height > maxElementSize ||
      element.width < 0 ||
      element.height < 0 ||
      element.x < -maxElementSize ||
      element.x > maxElementSize ||
      element.y < -maxElementSize ||
      element.y > maxElementSize
    ) {
      return true;
    }

    if (element.type === 'image' || element.type === 'addon') {
      if (
        !Number.isFinite(element.px) ||
        !Number.isFinite(element.py) ||
        !Number.isFinite(element.pw) ||
        !Number.isFinite(element.ph) ||
        !Number.isFinite(element.pr)
      ) {
        return true;
      }

      if (
        element.pw > maxElementSize ||
        element.ph > maxElementSize ||
        element.pw < 0 ||
        element.ph < 0 ||
        element.px < -maxElementSize ||
        element.px > maxElementSize ||
        element.py < -maxElementSize ||
        element.py > maxElementSize
      ) {
        return true;
      }
    }
  }
  return false;
}

export default isElementOutOfRange;

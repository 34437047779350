import React from 'react';

import { StructureIndex } from 'editor/src/store/design/types';

import IconAlignMiddle from 'editor/src/component/Icon/IconAlignMiddle';
import AlignActionButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignActionButton';

interface Props {
  structureIndexes: StructureIndex[];
}

function AlignMiddleButton({ structureIndexes }: Props) {
  return (
    <AlignActionButton
      structureIndexes={structureIndexes}
      icon={<IconAlignMiddle />}
      alignTo="middle"
      keepTopDropdown
    />
  );
}

export default React.memo(AlignMiddleButton);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconAlignBottom({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M3 22C2.71667 22 2.47933 21.904 2.288 21.712C2.096 21.5207 2 21.2833 2 21C2 20.7167 2.096 20.4793 2.288 20.288C2.47933 20.096 2.71667 20 3 20H21C21.2833 20 21.5207 20.096 21.712 20.288C21.904 20.4793 22 20.7167 22 21C22 21.2833 21.904 21.5207 21.712 21.712C21.5207 21.904 21.2833 22 21 22H3ZM8.5 18C8.08333 18 7.72933 17.854 7.438 17.562C7.146 17.2707 7 16.9167 7 16.5V3.5C7 3.08333 7.146 2.72933 7.438 2.438C7.72933 2.146 8.08333 2 8.5 2C8.91667 2 9.27067 2.146 9.562 2.438C9.854 2.72933 10 3.08333 10 3.5V16.5C10 16.9167 9.854 17.2707 9.562 17.562C9.27067 17.854 8.91667 18 8.5 18ZM15.5 18C15.0833 18 14.7293 17.854 14.438 17.562C14.146 17.2707 14 16.9167 14 16.5V9.5C14 9.08333 14.146 8.72933 14.438 8.438C14.7293 8.146 15.0833 8 15.5 8C15.9167 8 16.2707 8.146 16.562 8.438C16.854 8.72933 17 9.08333 17 9.5V16.5C17 16.9167 16.854 17.2707 16.562 17.562C16.2707 17.854 15.9167 18 15.5 18Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconAlignBottom;

import React from 'react';
import { useTranslation } from 'react-i18next';

import setSettingsValueOperation from 'editor/src/store/editor/operation/setSettingsValueOperation';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Checkbox from 'editor/src/component/Checkbox';
import SettingsItem from 'editor/src/component/DesktopSidebar/TabContents/SettingsTabContent/SettingsItem';

function ShowGuidesCheckbox() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isChecked = useSelector((state) => getSettingsValue(state, SettingsProperty.showGuides));

  const handleChange = () => {
    dispatch(setSettingsValueOperation(SettingsProperty.showGuides, !isChecked));
  };

  return (
    <SettingsItem descriptionTranslationKey="editor-settings-guides-description">
      <Checkbox
        on={isChecked}
        onChange={handleChange}
        label={t('editor-settings-guides')}
        className="cy-show-guides-checkbox"
      />
      <svg width="286" height="1" viewBox="0 0 286 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="286" height="1" fill="#E500E5" />
      </svg>
    </SettingsItem>
  );
}

export default React.memo(ShowGuidesCheckbox);

import { fabric } from 'fabric';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import { Coords, ElementAddress, MediaMockupPlaceholder, ProductImage } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import FabricImage from 'editor/src/fabric/FabricImage';
import FabricRect from 'editor/src/fabric/FabricRect';
import { emptyGreyImageIcon } from 'editor/src/util/emptyImageIcon';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import FabricImageComponent from 'editor/src/component/EditorArea/fabricComponents/FabricImageComponent';
import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';
import FabricSVGComponent from 'editor/src/component/EditorArea/fabricComponents/FabricSVGComponent';
import { ELEMENT_FRAME_COLOR } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/config';
import fabricElementToObjectRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/fabricElementToObjectRect';
import getElementRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getElementRect';
import getEmptyImageDimensions from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/getEmptyImageDimensions';
import ImageShadow, {
  Ref as ShadowRef,
} from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/ImageShadow';
import getImageShadow from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/ImageShadow/getImageShadow';
import LeaningShadowSvg, {
  LeaningShadowRef,
} from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/ImageShadow/LeaningShadow';
import useFilters from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/useFilters';
import useFrameLoading from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/useFrameLoading';
import usePerspectiveTranform from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/usePerspectiveTranform';
import useHoverBox from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useHoverBox';
import useIsInteractable from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useIsInteractable';
import useStoreSelection from 'editor/src/component/EditorArea/Spread/Page/MediaElement/useStoreSelection';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

import { CONTROLS_VISIBILITY, CONTROLS_VISIBILITY_FIT } from './LocalPlaceholder';

interface Props {
  elementData: MediaMockupPlaceholder;
  productImage: ProductImage | undefined;
  elementAddress: ElementAddress;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
  selected: boolean;
  isMobile: boolean;
  ignorePersonalizationLock: boolean;
}

function PreflightPlaceholder({
  elementData,
  productImage,
  elementAddress,
  pageCoords,
  canvasRotation,
  selected,
  isMobile,
  ignorePersonalizationLock,
}: Props) {
  const { mm2px, px2mm } = useFabricUtils();
  const shadowRef = useRef<ShadowRef>(null);
  const leaningShadowRef = useRef<LeaningShadowRef>(null);
  const emptyImageRef = useRef<FabricImage>(null);
  const frameRef = useRef<FabricRect>(null);
  const areaRef = useRef<FabricRect>(null);
  const placeholderImageRef = useRef<FabricImage>(null);
  const dispatch = useDispatch();
  const { liveElement: element } = useMediaElementLiveUpdates(elementData);
  const filters = useFilters(element);

  const [source, setSource] = useState<HTMLImageElement | HTMLVideoElement | null>(null);

  const sceneImages = useSelector(
    (state) => state.mockup.productPlaceholder.sceneImages[elementData.variant.productUid],
  );
  const foreground = sceneImages?.foregrounds[0];

  const elementRect = useMemo(
    () => getElementRect(element, pageCoords, canvasRotation, mm2px),
    [element.x, element.y, element.r, element.width, element.height, pageCoords, canvasRotation, mm2px],
  );

  useStoreSelection(element.area_fit ? areaRef : placeholderImageRef, element.uuid, element.type, selected);
  const isInteractable = useIsInteractable(element, ignorePersonalizationLock);
  const hoverBox = useHoverBox(elementRect, isMobile, selected || !!element.hidden, canvasRotation);
  const perspectiveTransform = usePerspectiveTranform(
    element,
    elementAddress,
    false,
    selected,
    elementRect,
    placeholderImageRef,
    false,
  );

  function computeProductPosition(fit: true | undefined, left: number, top: number, width: number, height: number) {
    const productPlacement = {
      width,
      height,
      innerWidth: width,
      innerHeight: height,
      innerLeft: 0,
      innerTop: 0,
      left,
      top,
      angle: 0,
    };

    if (fit) {
      if (element.variant.width / width > element.variant.height / height) {
        productPlacement.width = width;
        productPlacement.height = (element.variant.height * width) / element.variant.width;
      } else {
        productPlacement.height = height;
        productPlacement.width = (element.variant.width * height) / element.variant.height;
      }

      switch (element.anchoring_x) {
        case 'left':
          productPlacement.left = left;
          break;
        case 'right':
          productPlacement.left = left + width - productPlacement.width;
          break;
        default:
          productPlacement.left = left + (width - productPlacement.width) / 2;
          break;
      }

      switch (element.anchoring_y) {
        case 'top':
          productPlacement.top = top;
          break;
        case 'bottom':
          productPlacement.top = top + height - productPlacement.height;
          break;
        default:
          productPlacement.top = top + (height - productPlacement.height) / 2;
          break;
      }
    }
    productPlacement.innerWidth = productPlacement.width;
    productPlacement.innerHeight = productPlacement.height;
    return productPlacement;
  }

  const onObjectModified = useCallback(() => {
    const controlRef = areaRef.current || placeholderImageRef.current;
    if (controlRef) {
      const frameRect = fabricElementToObjectRect(controlRef, elementRect);
      const unrotatedFrameCoords = fabric.util.rotatePoint(
        new fabric.Point(frameRect.left, frameRect.top),
        canvasRotation.canvasCenter,
        -canvasRotation.angleRad,
      );

      dispatch(
        updateMediaElementOperation(
          elementAddress,
          {
            x: px2mm(unrotatedFrameCoords.x - pageCoords.left),
            y: px2mm(unrotatedFrameCoords.y - pageCoords.top),
            width: px2mm(frameRect.width),
            height: px2mm(frameRect.height),
            pw: px2mm(frameRect.width),
            ph: px2mm(frameRect.height),
            r: frameRect.angle - canvasRotation.angleDeg,
          },
          MediaUpdateActionName.MOCKUP_UPDATED,
        ),
      );
    }
  }, [px2mm, pageCoords, elementAddress, elementRect]);

  const onMouseMove = useCallback(
    (e: fabric.IEvent<MouseEvent>) => {
      const controlRef = areaRef.current || placeholderImageRef.current;
      if (!e.transform || !controlRef) {
        return;
      }

      const productPlacement = computeProductPosition(
        element.area_fit,
        controlRef.left ?? 0,
        controlRef.top ?? 0,
        controlRef.getScaledWidth(),
        controlRef.getScaledHeight(),
      );

      if (frameRef.current) {
        frameRef.current.set(elementRect);
      }
      if (areaRef.current && placeholderImageRef.current) {
        const img = placeholderImageRef.current.getElement();
        placeholderImageRef.current.set({
          left: productPlacement.left,
          top: productPlacement.top,
          scaleX: productPlacement.width / img.width,
          scaleY: productPlacement.height / img.height,
        });
      }

      if (shadowRef.current) {
        shadowRef.current.udpate(productPlacement);
      }

      if (leaningShadowRef.current) {
        leaningShadowRef.current.update(productPlacement);
      }
    },
    [foreground, element.area_fit, element.variant, element.anchoring_x, element.anchoring_y],
  );

  const fabricZIndex = zIndex.MEDIA + elementAddress.elementIndex;

  const controlProps = {
    onMouseMove,
    onMouseOver: hoverBox.onMouseOver,
    onMouseOut: hoverBox.onMouseOut,
    uuid: element.uuid,
    selectable: !isMobile,
    evented: isInteractable,
    scaleX: 1,
    scaleY: 1,
    lockScalingFlip: true,
    hoverCursor: selected ? 'move' : 'pointer',
    controlVisibility: element.area_fit ? CONTROLS_VISIBILITY_FIT : CONTROLS_VISIBILITY,
    borderColor: ELEMENT_FRAME_COLOR,
    cornerColor: ELEMENT_FRAME_COLOR,
    cornerStrokeColor: ELEMENT_FRAME_COLOR,
    onModified: onObjectModified,
    hasControls: !perspectiveTransform.isActive,
  };

  const shadow = useMemo(() => getImageShadow(element.shadow, mm2px), [element.shadow, mm2px]);

  const [imageLoading, setImageLoading] = useState(true);
  useEffect(() => {
    setImageLoading(true);
  }, [productImage?.url]);

  const onImageLoaded = useCallback(() => {
    setImageLoading(false);
    setSource(placeholderImageRef.current?.getElement() ?? null);
  }, []);

  const getEmptyIconOnUpdate = useCallback(
    (element: fabric.Object) => getEmptyImageDimensions(element, elementRect),
    [elementRect],
  );

  const fabricCanvas = useFabricCanvas();
  useFrameLoading(imageLoading, frameRef, fabricCanvas);

  const productPlacement = computeProductPosition(
    element.area_fit,
    elementRect.left,
    elementRect.top,
    elementRect.width,
    elementRect.height,
  );

  const getProductImageProps = useCallback(
    (fabricImage: FabricImage) => {
      const img = fabricImage.getElement();
      const containerWidth = productPlacement.width;
      const containerHeight = productPlacement.height;

      const scaleToFitWidth = containerWidth / img.width;
      const scaleToFitHeight = containerHeight / img.height;

      const scale = Math.min(scaleToFitWidth, scaleToFitHeight);
      const scaledWidth = img.width * scale;
      const scaledHeight = img.height * scale;

      const left = productPlacement.left + (productPlacement.width - scaledWidth) / 2;
      const top = productPlacement.top + (productPlacement.height - scaledHeight) / 2;

      return {
        scaleX: scale,
        scaleY: scale,
        left,
        top,
      };
    },
    [productPlacement.width, productPlacement.height, productPlacement.left, productPlacement.top],
  );

  return (
    <>
      {element.area_fit && (
        <FabricRectComponent
          left={elementRect.left}
          top={elementRect.top}
          width={elementRect.width}
          height={elementRect.height}
          angle={elementRect.angle}
          objectCaching={false}
          fill="rgba(170, 192, 236, 0.5)"
          zIndex={fabricZIndex}
          strokeWidth={0}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...controlProps}
          ref={areaRef}
        />
      )}
      {shadow && source && !imageLoading && (
        <ImageShadow
          ref={shadowRef}
          source={source}
          crossOrigin="anonymous"
          element={element}
          zIndex={fabricZIndex}
          frameRect={productPlacement}
          shadow={shadow}
          contentClipPath={undefined}
        />
      )}
      {element.shadow && element.perspective_transform && element.shadow.type === 'leaning-shadow' && (
        <LeaningShadowSvg
          ref={leaningShadowRef}
          shadow={element.shadow}
          perspectiveTransform={element.perspective_transform}
          zIndex={fabricZIndex}
          rect={productPlacement}
        />
      )}
      {productImage && (
        <FabricImageComponent
          left={productPlacement.left}
          top={productPlacement.top}
          key={productImage.url}
          source={productImage.url}
          crossOrigin="anonymous"
          visible={!imageLoading}
          zIndex={fabricZIndex + 0.1}
          ref={placeholderImageRef}
          onImageLoaded={onImageLoaded}
          getFabricOptionsOnUpdate={getProductImageProps}
          filters={filters}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(element.area_fit ? { evented: false } : controlProps)}
        />
      )}
      {imageLoading && (
        <>
          <FabricRectComponent
            objectCaching={false}
            fill="white"
            evented={false}
            zIndex={fabricZIndex + 0.2}
            strokeWidth={0}
            ref={frameRef}
            opacity={0.2}
            left={elementRect.left}
            top={elementRect.top}
            width={elementRect.width}
            height={elementRect.height}
          />
          <FabricSVGComponent
            svg={emptyGreyImageIcon}
            evented={false}
            originX="left"
            originY="top"
            zIndex={fabricZIndex + 0.2}
            ref={emptyImageRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            getFabricOptionsOnUpdate={getEmptyIconOnUpdate}
          />
        </>
      )}
      {hoverBox.render()}
      {perspectiveTransform.render()}
    </>
  );
}

export default React.memo(PreflightPlaceholder);

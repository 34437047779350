import cn from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'editor/src/component/Button';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import {
  useDispatch as useLocalDispatch,
  useSelector as useLocalSelector,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store';
import applyActiveFiltersOperation from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/operation/applyActiveFiltersOperation';
import resetActiveFiltersOperation from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/operation/resetActiveFiltersOperation';
import resetSearchFiltersOperation from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/operation/resetSearchFiltersOperation';
import setActiveFilterControlOperation from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/operation/setActiveFilterControlOperation';
import getActiveFilters from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getActiveFilters';
import getFilterControls from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getFilterControls';
import getIsControlsModified from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getIsControlsModified';
import { setActiveTabAction as setActiveTabOperation } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/slice';
import { ContentProviderTabNames } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconArrowLeft from 'editor/src/component/Icon/IconArrowLeft';

import ControlContainer from './ControlContainer';

import styles from './index.module.scss';

function FiltersTabContent() {
  const activeFilters = useLocalSelector(getActiveFilters);
  const filterControls = useLocalSelector(getFilterControls);
  const isControlsModified = useLocalSelector(getIsControlsModified);
  const dispatch = useLocalDispatch();
  const { t } = useTranslation();

  useEffect(() => () => goToMainTab(), []);

  const goToMainTab = useCallback(() => {
    dispatch(setActiveTabOperation(ContentProviderTabNames.MAIN));
  }, []);

  const onControlChange = useCallback((key: string, value: string | string[] | undefined) => {
    dispatch(setActiveFilterControlOperation(key, value));
  }, []);

  const onReset = useCallback(() => {
    dispatch(resetSearchFiltersOperation());
    goToMainTab();
  }, [goToMainTab]);

  const onCancel = useCallback(() => {
    dispatch(resetActiveFiltersOperation());
  }, []);

  const onApply = useCallback(() => {
    dispatch(applyActiveFiltersOperation());
    goToMainTab();
  }, [goToMainTab]);

  return (
    <>
      <TabContentHeader
        className={cn(styles.header, 'py-0')}
        closeBtnClassName={styles.closeBtn}
        title={t('editor-back')}
        icon={
          <Button onClick={goToMainTab} className={cn('cy-button-back', 'mr-1', styles.backButton)}>
            <IconArrowLeft />
          </Button>
        }
      >
        <div className={cn('row-flex row-flex--end', styles.resetButtonContainer)}>
          <Button onClick={onReset} className={cn('cy-button-reset', styles.resetButton)}>
            {t('editor-reset')}
          </Button>
        </div>
      </TabContentHeader>
      <ContentBlock className={cn('px-0', styles.filtersContainer)}>
        {filterControls?.map((control, idx) => (
          <div key={control.key}>
            <ControlContainer control={control} value={activeFilters[control.key]} onControlChange={onControlChange} />
            {idx !== (filterControls?.length ?? 0) - 1 && <div className={styles.separator} />}
          </div>
        ))}
        {isControlsModified && (
          <div className={cn('row-flex', 'row-flex--end', 'cy-action-bar', styles.actionBar)}>
            <Button onClick={onCancel} className={cn(styles.actionButton, 'cy-cancel')}>
              {t('Cancel')}
            </Button>
            <Button onClick={onApply} primary className={cn(styles.actionButton, 'ml-1', 'cy-apply')}>
              {t('Apply')}
            </Button>
          </div>
        )}
      </ContentBlock>
    </>
  );
}

export default React.memo(FiltersTabContent);

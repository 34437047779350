import { CanShow } from 'editor/src/component/Menu/type';

import ButtonTextFitting from './ButtonTextFitting';
import ButtonTextFittingDropDown from './ButtonTextFittingDropDown';

export const canShow: CanShow = () => true;

export { ButtonTextFittingDropDown, ButtonTextFitting };

export default ButtonTextFitting;

import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import groupLayouts from 'editor/src/util/layouts/groupLayouts';

import applyGroupedLayoutToAllSpreadsOperation from './applyGroupedLayoutToAllSpreadsOperation';
import applyLayoutToAllSpreadsOperation from './applyLayoutToAllSpreadsOperation';
import getApplyableSchemaNames from './getApplyableSchemaNames';

const autoApplyLayoutSchemasToDesignDataOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const { designData } = state.design;
  if (!designData) {
    return;
  }

  const schemaNamesToApply = getApplyableSchemaNames(
    designData,
    state.variants.layoutPerProductUids,
    state.design.autoApplyLayoutSchemaNames,
  );
  if (!schemaNamesToApply) {
    return;
  }

  const force = state.variants.configuration.forceLayout;

  if (designData?.spread_groups) {
    const layoutGroups = groupLayouts.filter((groupLayout) => schemaNamesToApply.includes(groupLayout.name));
    if (layoutGroups.length) {
      dispatch(applyGroupedLayoutToAllSpreadsOperation(layoutGroups[0], false, force ? 'layout-applied' : undefined));
    }
  } else {
    const layoutSchemas = state.editorModules.layouts.schemas.filter((schema) =>
      schemaNamesToApply.includes(schema.name),
    );
    if (layoutSchemas.length) {
      dispatch(applyLayoutToAllSpreadsOperation(layoutSchemas, false, force ? 'layout-applied' : undefined));
    }
  }
};

export default autoApplyLayoutSchemasToDesignDataOperation;

import cn from 'classnames';
import React from 'react';

import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import useTabState from 'editor/src/component/DesktopSidebar/Tabs/useTabState';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

interface Props {
  name: TAB_NAMES;
  badge?: React.ReactElement;
  icon: React.ReactElement;
  title: string;
  onSelect: (name: TAB_NAMES) => void;
  pluginName?: string;
}

function ButtonTab({ onSelect, icon, title, badge, name, pluginName }: Props) {
  const isMobile = useIsMobile();
  const { enabled, pluginDisabled, active } = useTabState(name, pluginName);

  const handleTabClick = () => {
    if (pluginDisabled && pluginName) {
      sendPostMessage('plugins.disabledPluginClick', { name: pluginName });
    } else {
      onSelect(name);
    }
  };

  return (
    <div
      className={cn('cy-sidebar-tab', `cy-sidebar-tab-${name.toLowerCase()}`, styles.tab, {
        [styles.active]: active,
        [styles.mobile]: isMobile,
        [styles.disabled]: !enabled,
      })}
      onClick={handleTabClick}
    >
      <div className={styles.tabContent}>
        {badge}
        {icon}
        <span className={styles.title}>{title}</span>
      </div>
    </div>
  );
}

export default React.memo(ButtonTab);

import cloneDeep from 'lodash/cloneDeep';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import setDesignDataOperation from 'editor/src/store/design/operation/setDesignDataOperation';
import setSpreadBackgroundsOperation from 'editor/src/store/design/operation/setSpreadBackgroundsOperation';
import setSpreadForegroundsOperation from 'editor/src/store/design/operation/setSpreadForegroundsOperation';
import { DesignData, SpreadGroundImage } from 'editor/src/store/design/types';
import getLayoutSchemaByName from 'editor/src/store/editor/selector/getLayoutSchemaByName';
import type { Thunk } from 'editor/src/store/hooks';
import getDesignKey, { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';
import getResizableProductForGivenVariant from 'editor/src/store/variants/selector/getResizableProductForGivenVariant';
import { setGroupDesignDataAction } from 'editor/src/store/variants/slice';

import applyResizableProductToDesign from 'editor/src/util/design/applyResizableProductToDesign';
import applyLayoutPerProductUid from 'editor/src/util/layouts/applyLayoutPerProductUid';
import reflectDesignData from 'editor/src/util/reflectDesignData';
import getReflectContext from 'editor/src/util/reflectDesignData/getReflectContext';

import { UNSELECTED_PAGE_COUNT } from '../reducer/setPageCountReducer';

import sendProductSwitchedOperation from './sendProductSwitchedOperation';
import setupProductSizeOperation from './setupProductSizeOperation';

export type DesignTemplate = {
  designData: DesignData;
  spreadBackgrounds?: SpreadGroundImage[];
  spreadForegrounds?: SpreadGroundImage[];
};

// used to differientiate between different photobook designs where the spine grows with the page count
function areDesignCoverTheSameSize(d1: DesignData, d2: DesignData) {
  return (
    d1.spreads[0].pages[0].width === d2.spreads[0].pages[0].width &&
    d1.spreads[0].pages[0].height === d2.spreads[0].pages[0].height
  );
}

export function haveSameNumberOfSpreads(d1?: DesignData, d2?: DesignData) {
  return d1?.spreads.length === d2?.spreads.length;
}

const applyTemplatesIfNeededOperation =
  (templates?: DesignTemplate[]): Thunk =>
  (dispatch, getState, { i18n }) => {
    batch(() => {
      const state = getState();
      const { selectedGroupKey, variationGroups, designTemplates, spreadImages, product, selectedPageCount } =
        state.variants;

      const sourceGroup = selectedGroupKey
        ? variationGroups.find((group) => group.key === selectedGroupKey)
        : variationGroups[0];
      const sourceDesignData =
        sourceGroup?.variationsInfo.find((variationInfo) => !!variationInfo.designData)?.designData ||
        state.design.designData;
      const reflectContext = getReflectContext(state);

      variationGroups.forEach((group) => {
        const variationInfo = group.variationsInfo.find((info) => !!info.designData) || group.variationsInfo[0];

        const designKey = getDesignKey(variationInfo.variation.productUid, {
          ...variationInfo,
          pageCount: selectedPageCount === UNSELECTED_PAGE_COUNT ? undefined : selectedPageCount,
        });
        const templateData = designTemplates[designKey];
        if (!variationInfo.designData && templateData) {
          let designData = sourceDesignData
            ? reflectDesignData(sourceDesignData, templateData, reflectContext)
            : templateData;
          designData = applyLayoutPerProductUid(designData, state, !!sourceDesignData, i18n);

          const resizableElement = getResizableProductForGivenVariant(state, designData.product_uid);
          const layoutName = designData.spreads[0].layoutSchemaName;
          const layout = layoutName ? getLayoutSchemaByName(state, layoutName) : undefined;

          if (resizableElement && layout && designData.related_dimensions) {
            designData = applyResizableProductToDesign(state, designData, resizableElement, layout, i18n);
          }

          dispatch(
            setGroupDesignDataAction({
              groupKey: group.key,
              designData,
              reflectContext,
            }),
          );
        } else if (variationInfo.designData && templateData) {
          dispatch(
            setGroupDesignDataAction({
              groupKey: group.key,
              designData: reflectDesignData(variationInfo.designData, templateData, reflectContext),
              reflectContext,
            }),
          );
        }

        if (selectedGroupKey === group.key) {
          const spreadImage = spreadImages[designKey];
          if (spreadImage) {
            void dispatch(setSpreadBackgroundsOperation(spreadImage.spreadBackgrounds));
            void dispatch(setSpreadForegroundsOperation(spreadImage.spreadForegrounds));
          }
        }
      });

      if (variationGroups.length === 0 && templates?.length && product.externalProductControls.length) {
        const { designData } = templates[0];
        if (designData) {
          product.externalProductControls.forEach((control) => {
            const dimensions = designData.related_dimensions || designData.dimensions;
            if (control.key === 'product-size' && dimensions) {
              dispatch(setupProductSizeOperation(dimensions));
            }
          });
        }
      }

      const selectedGroup = getState().variants.variationGroups.find(
        (group) => !selectedGroupKey || group.key === selectedGroupKey,
      );
      const variantInfo = selectedGroup?.variationsInfo.find((group) => group.designData);

      if (
        variantInfo &&
        variantInfo.designData &&
        (!state.design.designData ||
          getDesignKeyFromDesign(state.design.designData) !== getDesignKeyFromDesign(variantInfo.designData) ||
          !areDesignCoverTheSameSize(state.design.designData, variantInfo.designData) ||
          !haveSameNumberOfSpreads(state.design.designData, variantInfo.designData))
      ) {
        void dispatch(setDesignDataOperation(cloneDeep(variantInfo.designData)));
        dispatch(sendProductSwitchedOperation(variantInfo));
        const images = spreadImages[getDesignKeyFromDesign(variantInfo.designData)];
        if (images?.spreadBackgrounds) {
          void dispatch(setSpreadBackgroundsOperation(images.spreadBackgrounds));
        }
        if (images?.spreadForegrounds) {
          void dispatch(setSpreadForegroundsOperation(images.spreadForegrounds));
        }
      }
    });
  };

export default applyTemplatesIfNeededOperation;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updateMediaElementByUuidOperation from 'editor/src/store/design/operation/updateMediaElementByUuidOperation';
import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import { ImageManipulationPluginData } from 'editor/src/store/design/types';
import setFocusedMediaElementUuidOperation from 'editor/src/store/editor/operation/setFocusedMediaElementUuidOperation';
import { setPluginPreviewModeAction as setPluginPreviewModeOperation } from 'editor/src/store/editor/slice';
import activatePluginTabOperation from 'editor/src/store/editorModules/sidebar/operation/activatePluginTabOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import activateTabAfterImageModifiedOperation from './activateTabAfterImageModifiedOperation';

export interface ApplyModifiedImageOperationParams {
  pluginName: string;
  assetId: string;
  elementId: number;
  url?: string;
  preview?: boolean;
}

const applyModifiedImageOperation =
  ({ pluginName, assetId, elementId, url, preview }: ApplyModifiedImageOperationParams) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();

    const mediaImage = getMediaElementByUuid(state, elementId);
    if (mediaImage?.type !== 'image') {
      return;
    }

    const plugins: { [key: string]: ImageManipulationPluginData | undefined } = { ...(mediaImage.plugins || {}) };
    const sourceAssetId = mediaImage.sourceAssetId || mediaImage.imageId;

    batch(() => {
      if (preview) {
        dispatch(activatePluginTabOperation(pluginName, TAB_NAMES.IMAGE_MANIPULATION));
        dispatch(setFocusedMediaElementUuidOperation(elementId));
        dispatch(updateMediaElementByUuidOperation(elementId, { loading: undefined }));
        if (url) {
          dispatch(
            setPluginPreviewModeOperation({
              elementUuid: elementId,
              pluginName,
              url,
              assetId,
            }),
          );
        }
      } else {
        plugins[pluginName] = { imageModified: true };
        dispatch(activateTabAfterImageModifiedOperation());
        dispatch(setFocusedMediaElementUuidOperation(undefined));
        dispatch(setPluginPreviewModeOperation(undefined));
        dispatch(
          updateMediaElementByUuidOperation(elementId, {
            imageId: assetId,
            sourceAssetId,
            url: getImageById(state, assetId)?.url,
            loading: undefined,
            plugins,
          }),
        );
      }
    });
  };

export default applyModifiedImageOperation;

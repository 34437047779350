import { batch } from 'editor/src/store/batchedSubscribeEnhancer';

import { ThunkDispatch } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/index';
import {
  setActiveFiltersAction,
  setActiveGroupAction as setActiveGroupOperation,
  setSearchFiltersAction,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/slice';

import requestDataOperation from './requestDataOperation';

const resetSearchFiltersOperation = () => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(setSearchFiltersAction(undefined));
    dispatch(setActiveFiltersAction(undefined));
    dispatch(setActiveGroupOperation());
    dispatch(requestDataOperation());
  });
};

export default resetSearchFiltersOperation;

import React from 'react';

import isTopMenuDropdownVisible from 'editor/src/store/app/selector/isTopMenuDropdownVisible';
import { TopMenuDropdown } from 'editor/src/store/app/types';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useSelector } from 'editor/src/store/hooks';

import IconTextFitting from 'editor/src/component/Icon/IconTextFitting';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import TextFittingControl from 'editor/src/component/TextFittingControl';
import ButtonWithDropdownNotWrapped from 'editor/src/component/TopMenuDesktop/buttons/ButtonWithDropdownNotWrapped';
import MenuDropDownButton from 'editor/src/component/TopMenuDesktop/buttons/MenuDropDownButton';

import TextFittingTooltip from './TextFittingTooltip';

import styles from './index.module.scss';

function ButtonTextFitting({ mode, isMobile }: MenuItemProps) {
  const isActive = useSelector((state) =>
    isMobile
      ? isSidebarTabActive(state, TAB_NAMES.TEXT_FITTING)
      : isTopMenuDropdownVisible(state, TopMenuDropdown.TextFitting),
  );

  return (
    <ButtonWithDropdownNotWrapped
      dropDownName={TopMenuDropdown.TextFitting}
      buttonExtraClass="cy-button-text-fitting"
      dropDownContent={<TextFittingControl />}
      dropdownExtraClass={styles.dropdown}
      buttonContent={
        <MenuDropDownButton
          Icon={IconTextFitting}
          mode={mode}
          i18nKey="editor-text-wrapping"
          tooltipOverlay={<TextFittingTooltip />}
          tooltipStyle="rich"
          on={isActive}
        />
      }
    />
  );
}

export default React.memo(ButtonTextFitting);

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import applyCurrentLayoutSchemaToAllSpreadsOperation from 'editor/src/store/design/operation/applyCurrentLayoutSchemaToAllSpreadsOperation';
import getSpread from 'editor/src/store/design/selector/getSpread';
import getSpreadsCount from 'editor/src/store/design/selector/getSpreadsCount';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';

import styles from './index.module.scss';

function ApplyToAllButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { spreadCount, currentLayoutName, tags } = useSelector((state) => {
    const currentSpread = getSpread(state, getCurrentSpreadIndex(state));
    return {
      spreadCount: getSpreadsCount(state),
      currentLayoutName: currentSpread?.layoutSchemaName,
      tags: currentSpread?.tags,
    };
  }, shallowEqual);

  if (spreadCount < 2 || !tags?.length) {
    return null;
  }

  function onApplyToAllClick() {
    dispatch(applyCurrentLayoutSchemaToAllSpreadsOperation(true));
  }

  return (
    <ContentBlock className={styles.applyToAll}>
      <Button
        secondary
        stopPropagation
        disabled={!currentLayoutName}
        className={cn(styles.applyToAllButton, 'cy-apply-to-all')}
        onClick={onApplyToAllClick}
      >
        {t('editor-apply-to-all')}
      </Button>
    </ContentBlock>
  );
}

export default React.memo(ApplyToAllButton);

import colorParser from 'color-string';

import { ColorOverrides } from 'editor/src/store/design/types';

export const COLORS_THRESHOLD = {
  DESKTOP: 6, // EMB has 6 colors
  MOBILE: 3,
};

export function extractColors(colors: string[] | undefined) {
  const colorSet: Set<string> = new Set(colors);
  const lookupColors: ColorOverrides[] = [];

  colorSet.forEach((color) => {
    const rgbColor = colorParser.get(color);
    if (rgbColor) {
      const hexCode = colorParser.to.hex(rgbColor?.value);
      const colorObj = {
        previous: color,
        new: hexCode,
      };
      lookupColors.push(colorObj);
    }
  });

  return lookupColors;
}

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import updateAllGridElementsOperation from 'editor/src/store/design/operation/calendarGrid/updateAllGridElementsOperation';
import { MediaGrid } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import CarouselContainer from 'editor/src/component/Carousel/CarouselContainer';
import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';

import { gridDesignImages } from '../../../GridDesignsTabContent/GridDesignButton';

import styles from './index.module.scss';

import controlStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControl.module.scss';

interface Props {
  gridElement: MediaGrid;
}

function GridDesignControl({ gridElement }: Props) {
  const { t } = useTranslation();
  const gridDesigns = useSelector((state) => state.gridDesigns.grids);
  const { designName } = gridElement.grid;
  const dispatch = useDispatch();

  const setGridDesign = (designName: string) => {
    void dispatch(updateAllGridElementsOperation({ designName }));
  };

  return (
    <>
      <PropertySeparator bigMargin />
      <div className={controlStyles.controlTitle}>{t('grid-type')}</div>
      <CarouselContainer>
        {gridDesigns
          .filter((grid) => !grid.deprecated)
          .map((gridDesign) => (
            <div
              className={cn(styles.gridContainer, `cy-${gridDesign.name}`, {
                [styles.selected]: designName === gridDesign.name,
              })}
              onClick={() => setGridDesign(gridDesign.name)}
              key={gridDesign.name}
            >
              <img src={gridDesignImages[gridDesign.name]} alt={gridDesign.name} />
            </div>
          ))}
      </CarouselContainer>
    </>
  );
}

export default React.memo(GridDesignControl);

import cn from 'classnames';
import React, { useState } from 'react';

import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import ProductControlContent from './ProductControlContent';
import ProductDescriptionContent from './ProductDescriptionContent';

import styles from './index.module.scss';

interface Props {
  closeMenuCb?: () => void;
}

function ProductTabContent({ closeMenuCb }: Props) {
  const [showInfo, setShowInfo] = useState(false);
  const isMobile = useIsMobile();

  function openInfo() {
    setShowInfo(true);
  }

  function closeInfo() {
    setShowInfo(false);
  }

  return (
    <div
      className={cn('cy-tab-content', 'cy-tab-product', styles.tabContainer, { [styles.mobile]: isMobile })}
      data-ignore-outside-click
    >
      {showInfo ? (
        <ProductDescriptionContent closeInfo={closeInfo} />
      ) : (
        <ProductControlContent openInfo={openInfo} closeMenuCb={closeMenuCb} />
      )}
    </div>
  );
}

export default React.memo(ProductTabContent);

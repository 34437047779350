import React from 'react';

import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import { useSelector } from 'editor/src/store/hooks';

import MockupDisplayModeControl from './MockupDisplayModeControl';
import MockupShadowSideControl from './MockupShadowSideControl';

function MockupLeaningControls() {
  const placeholder = useSelector((state) =>
    state.design.designData?.spreads[0].pages[0].groups.media?.find(isMediaMockupPlaceholder),
  );
  if (!placeholder) {
    return null;
  }

  return (
    <>
      <MockupDisplayModeControl displayMode={placeholder.display_mode} />
      {placeholder.shadow?.type === 'leaning-shadow' && (
        <MockupShadowSideControl shadowSide={placeholder.shadow.side} />
      )}
    </>
  );
}

export default React.memo(MockupLeaningControls);

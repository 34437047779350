import React from 'react';

import { Coords, Dimensions } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import BottomControls from './BottomControls';
import Scrollbar from './Scrollbar';

interface Props {
  spreadIndex: number;
  isMobile: boolean;
  spreadCoords: Coords;
  spreadWidth: number;
  spreadHeight: number;
  canvasDim: Dimensions;
  isFocusedMode: boolean;
}

function EditorAreaControls({
  spreadCoords,
  spreadWidth,
  spreadHeight,
  canvasDim,
  spreadIndex,
  isMobile,
  isFocusedMode,
}: Props) {
  const showScroll = useSelector((state) => getHostSetting(state, 'showDesignScroll'));
  const [bottomBarLocation, setBottomBarLocation] = React.useState({
    bottomOffset: 0,
    height: 0,
  });

  const onPageSelectorUpdate = (bottomOffset: number, height: number) => {
    if ((!height && !bottomBarLocation.height) || !showScroll) {
      return;
    }
    setBottomBarLocation({ bottomOffset, height });
  };

  return (
    <>
      {!isFocusedMode && (
        <BottomControls
          currentSpreadIndex={spreadIndex}
          isMobile={isMobile}
          onPageSelectorUpdate={onPageSelectorUpdate}
        />
      )}
      {showScroll && !isMobile && (
        <Scrollbar
          spreadCoords={spreadCoords}
          canvasDim={canvasDim}
          spreadWidth={spreadWidth}
          spreadHeight={spreadHeight}
          bottomBarHeight={bottomBarLocation.height}
          bottomBarOffset={bottomBarLocation.bottomOffset}
        />
      )}
    </>
  );
}

export default React.memo(EditorAreaControls);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { MediaImage, MediaAddon } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import resetImagePositionOperation from './resetImagePositionOperation';

interface ResetAreasConfig {
  adjustments?: true;
  position?: true;
}

const resetImageStateOperation =
  (mediaImage: MediaImage | MediaAddon, resetAreasConfig: ResetAreasConfig = {}) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const elementAddress = getStructureIndexByElementUuid(getState().design.designData, mediaImage.uuid);
    if (!elementAddress) {
      return;
    }

    batch(() => {
      if (resetAreasConfig.adjustments) {
        dispatch(
          updateMediaElementAction({
            elementAddress,
            elementUpdate: { filters: [], adjustments: [] },
          }),
        );
      }

      if (resetAreasConfig.position) {
        void dispatch(resetImagePositionOperation(elementAddress));
      }
    });
  };

export default resetImageStateOperation;

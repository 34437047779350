import React from 'react';
import { useTranslation } from 'react-i18next';

import PersonalizationContent from 'editor/src/component/PersonalizationContent';

import ContentBlock from '../DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from '../DesktopSidebar/TabContents/TabContentHeader';

function PersonalizeLayersContent() {
  const { t } = useTranslation();
  return (
    <>
      <TabContentHeader title={t('editor-personalization')} />
      <ContentBlock scrollAlways fill className="py-2">
        <PersonalizationContent />
      </ContentBlock>
    </>
  );
}
export default PersonalizeLayersContent;

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import isPersonalizationLockElementsAllowed from 'editor/src/store/editor/selector/isPersonalizationLockElementsAllowed';
import { PluginName } from 'editor/src/store/plugins/types';

import { CanShow } from 'editor/src/component/Menu/type';

import ButtonPersonalization from './ButtonPersonalization';
import ButtonPersonalizationDropDown from './ButtonPersonalizationDropDown';

export const canShow: CanShow = (state, { hasSelection, structureIndexes }) => {
  if (!hasSelection || !isPersonalizationLockElementsAllowed(state) || !structureIndexes.length) {
    return false;
  }
  const element = getMediaElement(state, structureIndexes[0]);
  return (
    !!element &&
    (element.type !== 'image' ||
      (!element.plugins?.[PluginName.AdvancedPersonalisation] && !element.plugins?.[PluginName.personalizationStudio]))
  );
};

export { ButtonPersonalizationDropDown, ButtonPersonalization };

export default ButtonPersonalization;

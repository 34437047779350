import cn from 'classnames';
import React from 'react';

import { MinifiedProductData } from 'editor/src/component/ProductVariantList/ProductSwitcher/index';

import styles from './index.module.scss';

interface Props {
  productData: MinifiedProductData;
  Icon?: React.FC;
  onClick?: (productData: MinifiedProductData) => void;
  className?: string;
}
function ProductSwitcherItem({ productData, Icon, className, onClick }: Props) {
  return (
    <div className={cn(styles.productSwitcherItem, className)} onClick={() => onClick?.(productData)}>
      {productData.previewUrl && (
        <div className={styles.productSwitcherItemPreview}>
          <img src={productData.previewUrl} alt="Product preview" />
        </div>
      )}
      <div className="row-flex row-flex--column">
        <div className={styles.productSwitcherItemTitle}>{productData.title}</div>
        <div className={styles.productSwitcherSubtitle}>Variants: {productData.variantGroups.length || 0}</div>
      </div>
      {Icon && (
        <div className={styles.icon}>
          <Icon />
        </div>
      )}
    </div>
  );
}

export default React.memo(ProductSwitcherItem);

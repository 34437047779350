export const NO_FILTER_FILTER_NAME = 'none';

export type FilterInfo = {
  empty?: true;
  name: string;
  translationKey: string;
  default: number;
  adjustments: Array<{ name: string; value: number }>;
};

const FILTERS: FilterInfo[] = [
  {
    empty: true,
    name: NO_FILTER_FILTER_NAME,
    translationKey: 'editor-filter-none',
    default: 50,
    adjustments: [],
  },
  {
    name: 'old-sepia',
    translationKey: 'editor-filter-old-sepia',
    default: 100,
    adjustments: [
      {
        name: 'saturation',
        value: 50,
      },
      {
        name: 'contrast',
        value: -20,
      },
      {
        name: 'sepia',
        value: 100,
      },
    ],
  },
  {
    name: 'brownie',
    translationKey: 'editor-filter-brownie',
    default: 50,
    adjustments: [
      {
        name: 'brownie',
        value: 100,
      },
      {
        name: 'contrast',
        value: 20,
      },
      {
        name: 'brightness',
        value: 10,
      },
    ],
  },
  {
    name: 'polaroid',
    translationKey: 'editor-filter-polaroid',
    default: 50,
    adjustments: [
      {
        name: 'polaroid',
        value: 100,
      },
      {
        name: 'contrast',
        value: -20,
      },
      {
        name: 'temperature',
        value: 10,
      },
    ],
  },
  {
    name: 'technicolor',
    translationKey: 'editor-filter-technicolor',
    default: 50,
    adjustments: [
      {
        name: 'technicolor',
        value: 100,
      },
      {
        name: 'brightness',
        value: 10,
      },
    ],
  },
  {
    name: 'vintage',
    translationKey: 'editor-filter-vintage',
    default: 50,
    adjustments: [
      {
        name: 'vintage',
        value: 80,
      },
      {
        name: 'saturation',
        value: -40,
      },
      {
        name: 'contrast',
        value: 10,
      },
      {
        name: 'vibrance',
        value: 40,
      },
    ],
  },
  {
    name: 'frost',
    translationKey: 'editor-filter-frost',
    default: 50,
    adjustments: [
      {
        name: 'temperature',
        value: -50,
      },
      {
        name: 'brightness',
        value: 20,
      },
      {
        name: 'vibrance',
        value: 20,
      },
    ],
  },
  {
    name: 'grayscale',
    translationKey: 'editor-filter-grayscale',
    default: 100,
    adjustments: [
      {
        name: 'saturation',
        value: -100,
      },
      {
        name: 'contrast',
        value: 10,
      },
    ],
  },
  {
    name: 'black-and-white',
    translationKey: 'editor-filter-black-and-white',
    default: 100,
    adjustments: [
      {
        name: 'saturation',
        value: -100,
      },
      {
        name: 'contrast',
        value: 30,
      },
      {
        name: 'brightness',
        value: -10,
      },
    ],
  },
];

export default FILTERS;

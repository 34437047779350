import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import { getRectangleBoundingBox } from 'editor/src/util/reflectDesignData/utils';

import getFocusBox from './getFocusBox';

const alignMediaToSpreadLeftOperation =
  (elementAddress: ElementAddress) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const element = getMediaElement(state, elementAddress);
    if (!element) {
      return;
    }
    const focusBox = getFocusBox(state, elementAddress.spreadIndex);

    if (element.type === 'line') {
      const leftestX = Math.min(element.x1, element.x2);
      const xOffset = focusBox.x - leftestX;
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: { x1: element.x1 + xOffset, x2: element.x2 + xOffset },
        }),
      );
    } else {
      const elementBox = getRectangleBoundingBox(element.x, element.y, element.width, element.height, element.r);
      const xOffset = focusBox.x - elementBox.x;
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: { x: element.x + xOffset },
        }),
      );
    }
  };

export default alignMediaToSpreadLeftOperation;

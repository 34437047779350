import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ImageManipulationPlugin } from 'editor/src/store/plugins/types';

import ControlButton from 'editor/src/component/ControlButton';
import useImageManipulationButton from 'editor/src/component/DesktopSidebar/TabContents/EditImageTabContent/ImageManipulationContainer/ImageManipulationButton/useImageManipulationButton';
import { ButtonMode } from 'editor/src/component/Menu/type';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

type Props = {
  plugin: ImageManipulationPlugin;
  mode: ButtonMode;
};

function ButtonImageManipulation({ plugin, mode }: Props) {
  const { handleClick, disabled, premiumEnabled, PremiumIcon, selectedImage, element, isActive, tooltip } =
    useImageManipulationButton(plugin.name);

  const { t } = useTranslation();

  if (!selectedImage?.id || element?.type !== 'image') {
    return null;
  }

  const label = t(plugin.extra.modifyImageLabel);
  return (
    <WithTooltip overlay={tooltip} disabled={!tooltip}>
      <ControlButton
        disabled={disabled}
        on={isActive}
        onClick={handleClick}
        className={cn(
          styles.button,
          `${styles[mode]}`,
          'cy-button-request-modified-image',
          `cy-button-request-modified-image-${plugin.name}`,
        )}
      >
        <div className={styles.iconContainer}>
          <div className={styles.icon} style={{ backgroundImage: `url(${plugin.icon})` }} />
          {premiumEnabled && (
            <div className={styles.iconPremiumBox}>
              <PremiumIcon className={styles.iconPremium} />
            </div>
          )}
        </div>
        <div className={styles.label}>{label}</div>
      </ControlButton>
    </WithTooltip>
  );
}

export default React.memo(ButtonImageManipulation);

import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import { MediaText } from 'editor/src/store/design/types';

import IconText from 'editor/src/component/Icon/IconText';

import abstractStyles from '../AbstractLayerItem/index.module.scss';

interface Props {
  element: MediaText;
  isEditing: boolean;
  name: string;
  onNameChange?: (name: string) => void;
  finishEditing?: (name: string) => void;
  onEdit?: () => void;
}

function TextLayerItem({ element, isEditing, finishEditing, name, onNameChange, onEdit }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  function onChange(e: React.ChangeEvent) {
    const { value } = e.target as HTMLInputElement;
    onNameChange?.(value);
  }

  function onKeyUp(e: React.KeyboardEvent) {
    e.stopPropagation();
    if (e.key === 'Escape' || e.key === 'Enter') {
      finishEditing?.(name);
    }
  }

  function onElNameClick() {
    if (!isEditing && onEdit) {
      onEdit();
    }
  }

  useEffect(() => {
    if (!isEditing || !inputRef.current) {
      return undefined;
    }

    inputRef.current.select();
    const handle = window.setTimeout(() => inputRef.current?.focus(), 0);
    return () => window.clearTimeout(handle);
  }, [isEditing]);
  return (
    <>
      <div className={abstractStyles.thumb}>
        <IconText />
      </div>
      <div
        onClick={onElNameClick}
        className={cn(abstractStyles.text, 'cy-layer-text', { [abstractStyles.clickable]: !isEditing })}
      >
        {isEditing ? <input ref={inputRef} value={name} onKeyUp={onKeyUp} onChange={onChange} /> : element.name}
      </div>
    </>
  );
}

export default React.memo(TextLayerItem);

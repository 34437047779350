import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getDesignDataForExport from 'editor/src/store/design/selector/getDesignDataForExport';
import {
  setActivePreviewAction,
  setFlatPreviewsAction,
  setPreviewStatusAction,
} from 'editor/src/store/editorModules/preview/slice';
import { PreviewStatus, PreviewType } from 'editor/src/store/editorModules/preview/types';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import calculateDesignDataWarnings from 'editor/src/store/editorModules/warnings/utils/calculateDesignDataWarnings';
import { extendedWarningsToExportedWarnings } from 'editor/src/store/editorModules/warnings/utils/warningMapper';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { PluginName } from 'editor/src/store/plugins/types';

import isPluginEnabled from 'editor/src/util/plugins/isPluginEnabled';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

export type PreviewExportType = 'flat' | '3d' | 'mockup';

function previewTypeToExportType(previewType: PreviewType): PreviewExportType {
  switch (previewType) {
    case PreviewType.PREVIEW_FLAT:
      return 'flat';
    case PreviewType.PREVIEW_3D:
      return '3d';
    default:
      return 'mockup';
  }
}

const requestPreviewsOperation = (previewType: PreviewType) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  batch(() => {
    const state = getState();
    const { designData } = state.design;
    const { activeTabIndex, tabs } = state.editorModules.sidebar;
    const openTab = tabs[activeTabIndex];
    if (openTab && isPluginEnabled(PluginName.Shutterstock, openTab)) {
      dispatch(setSidebarActiveTabOperation(0));
    }
    if (previewType === PreviewType.PREVIEW_FLAT) {
      dispatch(setFlatPreviewsAction([]));
    }

    const extendedWarnings = designData ? calculateDesignDataWarnings(designData, state) : [];

    sendPostMessage('preview.loadPreviews', {
      designData: designData
        ? getDesignDataForExport(
            designData,
            state.gallery.images,
            state.editorModules.addons.inUse,
            extendedWarningsToExportedWarnings(extendedWarnings),
          )
        : undefined,
      type: previewTypeToExportType(previewType),
    });
    dispatch(setPreviewStatusAction(PreviewStatus.LOADING));
    dispatch(setActivePreviewAction(previewType));
  });
};

export default requestPreviewsOperation;

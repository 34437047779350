import React, { useCallback } from 'react';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import { Coords, ElementAddress, MediaRectangle } from 'editor/src/store/design/types';
import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import { useDispatch } from 'editor/src/store/hooks';

import FabricRect from 'editor/src/fabric/FabricRect';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import fabricPropsToRectangleElementData from './fabricPropsToRectangleElementData';

function useRectangleUpdates(
  pageCoords: Coords,
  rectangleData: MediaRectangle,
  elementAddress: ElementAddress,
  rectangleRef: React.RefObject<FabricRect>,
) {
  const dispatch = useDispatch();
  const { px2mm } = useFabricUtils();

  const onObjectModified = useCallback(() => {
    if (rectangleRef.current) {
      const rectangleUpdate = fabricPropsToRectangleElementData(px2mm, pageCoords, rectangleRef.current);
      dispatch(
        updateMediaElementOperation(elementAddress, rectangleUpdate, MediaUpdateActionName.RECTANGLE_POSITION_UPDATED),
      );
    }
  }, [px2mm, pageCoords, elementAddress]);

  const onSelected = useCallback(() => {
    if (rectangleRef.current) {
      dispatch(addSelectedMediaElementOperation(rectangleData.uuid));
    }
  }, [rectangleData.uuid]);

  return { onObjectModified, onSelected };
}

export default useRectangleUpdates;

import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';
import fetchProductScenesOperation from 'editor/src/store/mockup/operation/fetchProductScenesOperation';
import setVariantAsPlaceholderOperation from 'editor/src/store/mockup/operation/setVariantAsPlaceholderOperation';
import {
  MockupPreviewProduct,
  MockupPreviewVariant,
  MockupProductEntry,
  MockupProductProduct,
} from 'editor/src/store/mockup/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import BackHeader from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/BackHeader';
import MockupList from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/MockupList';
import SceneModelsSelection from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/SceneModelsSelection';
import MockupStoreContext from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/StoreList/MockupStoreContext';

import StoreProductVariantEntry from './StoreProductVariantEntry';

interface Props {
  storeId: string;
  product: MockupPreviewProduct;
  listClassName?: string;
  backClassName?: string;
  back: () => void;
}

function findMatchingProduct(
  catalogueId: string,
  productCategories: MockupProductEntry[],
): MockupProductProduct | undefined {
  // eslint-disable-next-line no-restricted-syntax
  for (const category of productCategories) {
    if (category.type === 'product' && category.id === catalogueId) {
      return category;
    }

    if (category.type === 'category') {
      const result = findMatchingProduct(catalogueId, category.children);
      if (result) {
        return result;
      }
    }
  }

  return undefined;
}

function StoreProductVariantList({ product, storeId, back, listClassName, backClassName }: Props) {
  const variants = useSelector((state) => state.mockup.preview.variants[product.id]);
  const [loadingVariant, setLoadingVariant] = useState<MockupPreviewVariant>();
  const appliedVariant = useSelector((state) => state.mockup.preview.appliedVariant);
  const mode = useContext(MockupStoreContext);
  const [catalogueProduct, setCatalogueProduct] = useState<{
    product: MockupProductProduct;
    variant: MockupPreviewVariant;
  }>();
  const dispatch = useDispatch();
  const store = useStore();

  useEffect(() => {
    if (loadingVariant?.id === appliedVariant?.id) {
      setLoadingVariant(undefined);
    }
  }, [appliedVariant]);

  const loadStoreProductVariants = useCallback(
    (offset: number) => {
      sendPostMessage('mockup.getStoreProductVariants', {
        storeId,
        productId: product.id,
        offset,
        limit: 15,
      });
    },
    [storeId, product.id],
  );

  const onSelectVariant = useCallback((variant: MockupPreviewVariant) => {
    if (mode === 'preview') {
      sendPostMessage('mockup.getVariantPreview', {
        width: 1000,
        height: 1000,
        variant,
      });
      setLoadingVariant(variant);
    } else {
      const { productCategories } = store.getState().mockup.scenePlaceholder;
      if (productCategories.length) {
        const matchingProduct = findMatchingProduct(
          variant.catalogueId,
          store.getState().mockup.scenePlaceholder.productCategories,
        );
        if (matchingProduct) {
          dispatch(fetchProductScenesOperation(matchingProduct.productUid));
          setCatalogueProduct({ product: matchingProduct, variant });
        }
      } else {
        dispatch(setVariantAsPlaceholderOperation(variant));
        setLoadingVariant(variant);
      }
    }
  }, []);

  return catalogueProduct ? (
    <SceneModelsSelection
      product={catalogueProduct.product}
      variant={catalogueProduct.variant}
      back={() => setCatalogueProduct(undefined)}
    />
  ) : (
    <>
      <BackHeader className={backClassName} title={product.name} onClick={back} />
      <MockupList
        className={listClassName}
        entries={variants.entries}
        hasMore={variants.hasMore}
        EntryElt={StoreProductVariantEntry}
        loadEntries={loadStoreProductVariants}
        onSelect={onSelectVariant}
        loadingEntry={loadingVariant}
      />
    </>
  );
}

export default React.memo(StoreProductVariantList);

import React from 'react';
import { useTranslation } from 'react-i18next';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import addNewLineToPageOperation from 'editor/src/store/design/operation/shapes/addNewLineToPageOperation';
import addNewRectangleToPageOperation from 'editor/src/store/design/operation/shapes/addNewRectangleToPageOperation';
import { MediaShape } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { SHAPE_PRESETS, ShapeStylesFromType } from 'editor/src/util/shapePresets';

import DrawerHeader from 'editor/src/component/BottomBarMobile/DrawerHeader';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import ShapesPresetCarousel from './ShapesPresetCarousel';

import styles from './index.module.scss';

function ShapesTabContent({ title, closeMenuCb }: TabContentProps) {
  const dispatch = useDispatch();
  const spreadIndex = useSelector(getCurrentSpreadIndex);
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const onAddShape = <T extends MediaShape['type']>(type: T, shapeStyles: ShapeStylesFromType<T>) =>
    batch(() => {
      if (type === 'line') {
        dispatch(addNewLineToPageOperation(spreadIndex, 0, shapeStyles));
      } else if (type === 'rectangle') {
        dispatch(addNewRectangleToPageOperation(spreadIndex, 0, shapeStyles));
      }

      if (isMobile) {
        dispatch(setSidebarActiveTabOperation(-1));
      }
    });

  return (
    <div data-ignore-outside-click className={styles.wrapper}>
      {isMobile ? (
        <DrawerHeader title={title || t('Shapes')} closeMenu={closeMenuCb} />
      ) : (
        <TabContentHeader title={title || t('Shapes')} />
      )}
      <ContentBlock scroll fillFlex>
        {SHAPE_PRESETS.map(({ type, titlei18nKey, presetItems }) => (
          <ShapesPresetCarousel
            key={type}
            type={type}
            title={t(titlei18nKey)}
            className="mt-3"
            presets={presetItems}
            onAddShapeClick={onAddShape}
          />
        ))}
      </ContentBlock>
    </div>
  );
}

export default React.memo(ShapesTabContent);

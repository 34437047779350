import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import IconSearch from '../Icon/IconSearch';
import Input from '../Input';

import styles from './index.module.scss';

interface Props {
  onChange: (value: string) => void;
}

function SearchControl({ onChange }: Props) {
  const [searchString, setSearchString] = useState<string>('');
  const { t } = useTranslation();

  const onKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      onChange(searchString);
    }
  };

  return (
    <div className={styles.searchBlock}>
      <Input
        className={cn(styles.input, styles.searchInput)}
        placeholder={t('editor-search')}
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        onKeyDown={onKeyDown}
        type="string"
      />
      <Button primary onClick={() => onChange(searchString)} className={styles.button}>
        <IconSearch />
      </Button>
    </div>
  );
}

export default React.memo(SearchControl);

import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DropDown from 'editor/src/component/DropDown';
import IconMore from 'editor/src/component/Icon/IconMore';
import WithTooltip from 'editor/src/component/WithTooltip';

import { MenuItemContext } from './SettingsMenuItem';

import styles from './index.module.scss';

interface Props {
  className: string;
  children: React.ReactNode;
}

function SettingsMenu({ className, children }: Props) {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const { t } = useTranslation();

  function openDropdown() {
    setIsDropDownVisible(true);
  }

  function closeDropDown() {
    setIsDropDownVisible(false);
  }

  function onMouseDown(e: React.MouseEvent) {
    e.stopPropagation();
  }

  const context = useMemo(() => ({ close: closeDropDown }), []);

  return (
    <MenuItemContext.Provider value={context}>
      <button className={cn(styles.SettingsMenuBtn, className)} onMouseDown={onMouseDown} onClick={openDropdown}>
        <WithTooltip overlay={t('editor-more-options')} placement="bottom">
          <IconMore />
        </WithTooltip>
        <DropDown
          isVisible={isDropDownVisible}
          wrapperClassName={styles.wrapper}
          className={styles.dropdown}
          onClickOutside={closeDropDown}
        >
          {children}
        </DropDown>
      </button>
    </MenuItemContext.Provider>
  );
}

export default React.memo(SettingsMenu);

import cn from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { getImageUrl } from 'editor/src/store/design/selector/getImageElementUrl';
import getImageCrossOriginByState from 'editor/src/store/gallery/selector/getImageCrossOriginByState';
import { GalleryImage, ImageState } from 'editor/src/store/gallery/types';
import { useSelector } from 'editor/src/store/hooks';
import shouldDigitizeCurrentSpread from 'editor/src/store/utils/shouldDigitizeCurrentSpread';

import useEditorModeTranslations from 'editor/src/util/useEditorModeTranslations';

import Button from 'editor/src/component/Button';
import IconChevronLeft from 'editor/src/component/Icon/IconChevronLeft';
import { UploaderContext } from 'editor/src/component/Uploader/useUploader';

import styles from './index.module.scss';

interface Props {
  close: () => void;
  onImageSelected: (image: GalleryImage) => void;
}

function ImagePicker({ close, onImageSelected }: Props) {
  const { t } = useTranslation();
  const images = useSelector((state) => state.gallery.images);
  const digitizeCurrentSpread = useSelector(shouldDigitizeCurrentSpread);

  const uploader = useContext(UploaderContext);
  const { galleryFilesButton } = useEditorModeTranslations();

  function openUploader() {
    uploader.open();
  }

  return (
    <div className={cn(styles.ImagePicker, 'cy-image-picker')}>
      {images.length === 0 ? (
        <div className={styles.noImages}>
          <div className={styles.imageText}>{t('editor-no-images')}</div>
          <Button primary onClick={openUploader}>
            {galleryFilesButton}
          </Button>
        </div>
      ) : (
        <div className={styles.images}>
          {images.map((image) => (
            <img
              key={image.uuid + image.state}
              className={cn(styles.image, 'cy-image', {
                [styles.disabled]: image.state !== ImageState.UPLOADED,
              })}
              crossOrigin={getImageCrossOriginByState(image.state)}
              src={getImageUrl(image, false, digitizeCurrentSpread)}
              alt={image.name}
              onClick={() => onImageSelected(image)}
            />
          ))}
        </div>
      )}
      <div className={styles.back} onClick={close}>
        <IconChevronLeft />
      </div>
    </div>
  );
}

export default React.memo(ImagePicker);

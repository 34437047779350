import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import styles from './index.module.scss';

export interface ToggleOption {
  content: JSX.Element;
  value: string;
  callback: () => void;
}

interface Props {
  options: ToggleOption[];
  selected?: string;
}

function Toggle({ options, selected }: Props) {
  const [selectedOption, setSelectedOption] = useState(selected || options[0].value);

  useEffect(() => {
    if (selected !== undefined) {
      setSelectedOption(selected);
    }
  }, [selected]);

  function handleOptionClick(option: ToggleOption) {
    setSelectedOption(option.value);
    option.callback();
  }

  return (
    <div className={cn(styles.wrapper, 'cy-toggle')}>
      {options.map((option) => (
        <button
          className={cn(styles.optionButton, `cy-toggle-option-${option.value}`, {
            [styles.selected]: selectedOption === option.value,
          })}
          key={option.value}
          onClick={() => handleOptionClick(option)}
        >
          {option.content}
        </button>
      ))}
    </div>
  );
}

export default React.memo(Toggle);

import cn from 'classnames';
import React from 'react';

import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import { isPluginTab } from 'editor/src/store/editorModules/sidebar/types';
import { useSelector } from 'editor/src/store/hooks';

import TabContents from 'editor/src/component/DesktopSidebar/TabContents';

import hasSidebarContent from './hasSidebarContent';
import tabContents from './tabContents';

import styles from './index.module.scss';

function SidebarMobile() {
  const activeTab = useSelector(getSidebarActiveTab);
  const showSidebar = activeTab && hasSidebarContent(activeTab);
  const isDragging = useSelector((state) => state.editor.dragState !== undefined);

  return (
    <div
      className={cn(styles.SidebarMobile, {
        [styles.visible]: showSidebar,
        [styles.hidden]: activeTab && isPluginTab(activeTab) && isDragging,
      })}
    >
      {showSidebar && <TabContents tabContents={tabContents} activeTab={activeTab} />}
    </div>
  );
}

export default React.memo(SidebarMobile);

import React from 'react';
import { useTranslation } from 'react-i18next';

import { MaxStitchCountWarning } from 'editor/src/store/editorModules/warnings/types';

import AbstractWarningItem from './AbstractWarningItem';

interface Props {
  warning: MaxStitchCountWarning;
  selected: boolean;
}

function MaxStitchCountWarningItem({ warning, selected }: Props) {
  const { t } = useTranslation();

  return (
    <AbstractWarningItem warning={warning} selected={selected} title={t('exceeded limitation of stitches')}>
      {t('your image has exceeded limitation of stitches')}
    </AbstractWarningItem>
  );
}

export default React.memo(MaxStitchCountWarningItem);

import { setSettingsValueAction } from 'editor/src/store/editor/slice';
import { Settings } from 'editor/src/store/editor/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const setSettingsValueOperation =
  <K extends keyof Settings>(property: K, value: Settings[K]) =>
  (dispatch: ThunkDispatch) => {
    dispatch(setSettingsValueAction({ property, value }));
  };

export default setSettingsValueOperation;

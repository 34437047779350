import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setDesignDataAction } from 'editor/src/store/design/slice';
import getLayoutSchemaByName from 'editor/src/store/editor/selector/getLayoutSchemaByName';
import type { Thunk } from 'editor/src/store/hooks';
import getDesignKey from 'editor/src/store/variants/helpers/getDesignKey';
import getResizableProductForGivenVariant from 'editor/src/store/variants/selector/getResizableProductForGivenVariant';
import getVariationGroupsFromDesignOptions from 'editor/src/store/variants/selector/getVariationGroupsFromDesignOptions';
import { setGroupDesignDataAction, toggleGroupLinkAction } from 'editor/src/store/variants/slice';
import { VariationGroup } from 'editor/src/store/variants/types';

import applyResizableProductToDesign from 'editor/src/util/design/applyResizableProductToDesign';
import applyLayoutPerProductUid from 'editor/src/util/layouts/applyLayoutPerProductUid';
import reflectDesignData from 'editor/src/util/reflectDesignData';
import getReflectContext from 'editor/src/util/reflectDesignData/getReflectContext';

const toggleLinkVariantGroupOperation =
  (group: VariationGroup): Thunk =>
  (dispatch, getState, { i18n }) => {
    batch(() => {
      const state = getState();
      const { variationGroups, selectedGroupKey, designTemplates, selectedDesignOptionValue } = state.variants;
      const variationGroupsFromDesignOptions = getVariationGroupsFromDesignOptions(state);
      const selectedGroupDesignOption = variationGroupsFromDesignOptions?.find(
        (group) => group.key === selectedDesignOptionValue,
      );
      const selectedGroupKeys = selectedGroupDesignOption?.value.map((group) => group.key);
      const firstLinkedGroup = variationGroups.find(
        (group) =>
          (selectedGroupKeys && selectedGroupKeys.find((groupKey) => groupKey === group.key) && group.linked) ||
          (!selectedGroupKeys && group.linked),
      );
      dispatch(toggleGroupLinkAction(group.key));

      const firstVariant = group.variationsInfo[0];
      const template = designTemplates[getDesignKey(firstVariant.variation.productUid, firstVariant)];
      const linkedDesignData = firstLinkedGroup?.variationsInfo[0].designData;
      // when re-linking, we re-apply the design from any existing linked variant
      if (linkedDesignData && template && !group.linked) {
        const reflectContext = getReflectContext(state);
        let designData = reflectDesignData(linkedDesignData, template, reflectContext);
        designData = applyLayoutPerProductUid(designData, state, true, i18n);

        const resizableElement = getResizableProductForGivenVariant(state, designData.product_uid);
        const layoutName = designData.spreads[0]?.layoutSchemaName;
        const layout = layoutName ? getLayoutSchemaByName(state, layoutName) : undefined;

        if (resizableElement && layout && designData.related_dimensions) {
          designData = applyResizableProductToDesign(state, designData, resizableElement, layout, i18n);
        }

        dispatch(
          setGroupDesignDataAction({
            groupKey: group.key,
            designData,
            reflectContext,
          }),
        );
        if (selectedGroupKey === group.key) {
          dispatch(setDesignDataAction(designData));
        }
      }
    });
  };

export default toggleLinkVariantGroupOperation;

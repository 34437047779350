import { VariationProductControl, MultiOptions, VariantInfo } from 'editor/src/store/variants/types';

function getNotGroupedValues(
  allSelectedOptions: MultiOptions,
  groupBy: string[],
  productControls: VariationProductControl[],
  variationsInfo: VariantInfo[],
): string | null {
  if (!groupBy.length) {
    return null;
  }

  const joinedValues = productControls.reduce<string[]>((values, control) => {
    const valuesName = values.map((value) => control.options.find((option) => option.value === value)?.title ?? value);

    if (groupBy.includes(control.key)) {
      return valuesName;
    }

    const selectedOptions = allSelectedOptions[control.key];
    if (!selectedOptions) {
      return valuesName;
    }

    const optionValues = variationsInfo.reduce<Set<string>>((acc, variationInfo) => {
      const value = variationInfo.variation[control.key];
      if (value) {
        const valueName = control.options.find((option) => option.value === value)?.title ?? value;
        acc.add(valueName);
      }
      return acc;
    }, new Set<string>());

    if (optionValues.size > 0) {
      valuesName.push([...optionValues].join(', '));
    }
    return valuesName;
  }, []);

  return joinedValues.join(' - ');
}

export default getNotGroupedValues;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import {
  setActiveProductAction,
  setMockupPreviewAction,
  updateMockupDataWithVariantAction,
} from 'editor/src/store/mockup/slice';
import { MockupPreviewVariant, ProductScene } from 'editor/src/store/mockup/types';
import getVariantProductImageKey from 'editor/src/store/mockup/utils/getVariantProductImageKey';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import setSelectedProductSceneOperation from './setSelectedProductSceneOperation';
import updatePlaceholderOperation from './updatePlaceholderOperation';

const setVariantAsPlaceholderOperation =
  (variant: MockupPreviewVariant, scene?: ProductScene) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const variantWithScene = { ...variant, scene: scene?.id };
    const productImage =
      state.mockup.productPlaceholder.variantProductImages[getVariantProductImageKey(variantWithScene)];

    batch(() => {
      if (!scene) {
        dispatch(setActiveProductAction(variant.productUid));
        dispatch(updateMockupDataWithVariantAction(variant.productUid));
      }

      dispatch(setMockupPreviewAction(variantWithScene));

      if (!productImage) {
        sendPostMessage('mockup.getProductPreview', {
          width: 1000,
          height: 1000,
          variant: variantWithScene,
        });
      }

      if (scene) {
        dispatch(setSelectedProductSceneOperation(scene, variant.productUid, true));
      } else {
        dispatch(updatePlaceholderOperation());
      }
    });
  };

export default setVariantAsPlaceholderOperation;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconHeight({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M11.0001 17.175V6.82499L10.1001 7.72499C9.91678 7.90832 9.68761 7.99999 9.41261 7.99999C9.13761 7.99999 8.90011 7.89999 8.70011 7.69999C8.51678 7.51665 8.42511 7.28332 8.42511 6.99999C8.42511 6.71665 8.51678 6.48332 8.70011 6.29999L11.3001 3.69999C11.4001 3.59999 11.5084 3.52915 11.6251 3.48749C11.7418 3.44582 11.8668 3.42499 12.0001 3.42499C12.1334 3.42499 12.2584 3.44582 12.3751 3.48749C12.4918 3.52915 12.6001 3.59999 12.7001 3.69999L15.3001 6.29999C15.4834 6.48332 15.5751 6.71249 15.5751 6.98749C15.5751 7.26249 15.4834 7.49999 15.3001 7.69999C15.1001 7.89999 14.8626 7.99999 14.5876 7.99999C14.3126 7.99999 14.0751 7.89999 13.8751 7.69999L13.0001 6.82499V17.175L13.9001 16.275C14.0834 16.0917 14.3126 16 14.5876 16C14.8626 16 15.1001 16.1 15.3001 16.3C15.4834 16.4833 15.5751 16.7167 15.5751 17C15.5751 17.2833 15.4834 17.5167 15.3001 17.7L12.7001 20.3C12.6001 20.4 12.4918 20.4708 12.3751 20.5125C12.2584 20.5542 12.1334 20.575 12.0001 20.575C11.8668 20.575 11.7418 20.5542 11.6251 20.5125C11.5084 20.4708 11.4001 20.4 11.3001 20.3L8.70011 17.7C8.51678 17.5167 8.42094 17.2875 8.41261 17.0125C8.40428 16.7375 8.50011 16.5 8.70011 16.3C8.88344 16.1167 9.11678 16.025 9.40011 16.025C9.68344 16.025 9.91678 16.1167 10.1001 16.3L11.0001 17.175Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconHeight;

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import ControlButton from 'editor/src/component/ControlButton';
import IconCross from 'editor/src/component/Icon/IconCross';
import IconFeedback from 'editor/src/component/Icon/IconFeedback';
import IconHelp from 'editor/src/component/Icon/IconHelp';

import MenuButton from './MenuButton';

import styles from './index.module.scss';

interface Props {
  close: () => void;
}

const HELP_ICON = <IconHelp />;
const FEEDBACK_ICON = <IconFeedback />;

function HelpMenuContent({ close }: Props) {
  const { t } = useTranslation();

  const openHelpCenter = useCallback(() => {
    sendPostMessage('help.openHelperCenter', undefined);
    close();
  }, [close]);

  const giveFeedback = useCallback(() => {
    sendPostMessage('help.giveFeedback', undefined);
    close();
  }, [close]);

  return (
    <div className={styles.MenuContent}>
      <div className={styles.header}>
        {t('Help')}
        <ControlButton onClick={close}>
          <IconCross />
        </ControlButton>
      </div>
      <div className={styles.content}>
        <MenuButton
          Icon={HELP_ICON}
          title={t('Help center')}
          onClick={openHelpCenter}
          className="cy-button-help-center"
        />
        <MenuButton
          Icon={FEEDBACK_ICON}
          title={t('Give us feedback')}
          onClick={giveFeedback}
          className="cy-button-feedback"
        />
      </div>
    </div>
  );
}

export default React.memo(HelpMenuContent);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import getCurrentDpiLevels from 'editor/src/store/design/selector/getCurrentDpiLevels';
import { MediaElement } from 'editor/src/store/design/types';
import { DPIWarning, WarningLevel } from 'editor/src/store/editorModules/warnings/types';
import { useSelector } from 'editor/src/store/hooks';
import { DEFAULT_MEDIUM_DPI } from 'editor/src/store/hostSettings/slice';
import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import { getDPIWarningTooltip } from 'editor/src/component/TopMenuDesktop/buttons/ButtonWarnings/useElementWarnings';

import AbstractWarningItem from './AbstractWarningItem';

interface Props {
  warning: DPIWarning;
  element: MediaElement;
  selected: boolean;
}

function LowDpiWarningItem({ warning, element, selected }: Props) {
  const { t } = useTranslation();
  const dpiLevelsSettings = useSelector(getCurrentDpiLevels, shallowEqual);
  const i18nKey = warning.level === WarningLevel.Medium ? 'editor-average-quality-info' : 'editor-poor-quality-info';
  const title = isEmbedded ? t('Low quality image') : getDPIWarningTooltip(warning.dpi, warning.level, t);
  const message = isEmbedded
    ? t('Low quality image message')
    : t(i18nKey, {
        dpi: Math.round(dpiLevelsSettings?.medium ?? DEFAULT_MEDIUM_DPI),
      });

  return (
    <AbstractWarningItem warning={warning} element={element} selected={selected} title={title}>
      {message}
    </AbstractWarningItem>
  );
}

export default React.memo(LowDpiWarningItem);

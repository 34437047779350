import { ElementAddress, MediaImage } from 'editor/src/store/design/types';
import { RootState } from 'editor/src/store/index';

import calculateCurrentCropZoomScale from 'editor/src/util/design/crop/calculateCurrentCropZoomScale';
import { MIN_CROP_ZOOM_LEVEL } from 'editor/src/util/design/crop/constants';

import getMediaElement from './getMediaElement';

const isCropZoomOutAvailable = (state: RootState, elementAddress: ElementAddress) => {
  const element = getMediaElement<MediaImage>(state, elementAddress);
  if (!element) {
    return false;
  }

  const currentCropSoomScale = calculateCurrentCropZoomScale(element);
  return Math.abs(currentCropSoomScale - MIN_CROP_ZOOM_LEVEL) > 0.01;
};

export default isCropZoomOutAvailable;

/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

function IconNoStrokeColor({ className }: Props) {
  return (
    <Icon className={cn(className, styles.iconNoColor, 'cy-icon-no-stroke-color')}>
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="32" height="32" rx="16" fill="white" />
        <g clipPath="url(#clip0_2654_7518)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 4.5C9.37258 4.5 4 9.87258 4 16.5C4 23.1274 9.37258 28.5 16 28.5C22.6274 28.5 28 23.1274 28 16.5C28 9.87258 22.6274 4.5 16 4.5ZM16 9.5C12.134 9.5 9 12.634 9 16.5C9 20.366 12.134 23.5 16 23.5C19.866 23.5 23 20.366 23 16.5C23 12.634 19.866 9.5 16 9.5Z"
            fill="url(#paint0_linear_2654_7518)"
          />
          <path
            d="M4.5 16.5C4.5 10.1487 9.64873 5 16 5C22.3513 5 27.5 10.1487 27.5 16.5C27.5 22.8513 22.3513 28 16 28C9.64873 28 4.5 22.8513 4.5 16.5ZM16 9C11.8579 9 8.5 12.3579 8.5 16.5C8.5 20.6421 11.8579 24 16 24C20.1421 24 23.5 20.6421 23.5 16.5C23.5 12.3579 20.1421 9 16 9Z"
            stroke="black"
            strokeOpacity="0.1"
          />
        </g>
        <rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="black" strokeOpacity="0.3" />
        <defs>
          <linearGradient
            id="paint0_linear_2654_7518"
            x1="7.75"
            y1="8.25"
            x2="24"
            y2="24.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF0000" stopOpacity="0" />
            <stop offset="0.483487" stopColor="#FF0000" stopOpacity="0" />
            <stop offset="0.487551" stopColor="#FF0000" />
            <stop offset="0.552798" stopColor="#FF0000" />
            <stop offset="0.557481" stopColor="#FF0000" stopOpacity="0" />
            <stop offset="1" stopColor="#FF0000" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_2654_7518">
            <rect width="24" height="24" fill="white" transform="translate(4 4.5)" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}

export default IconNoStrokeColor;

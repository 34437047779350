import { useCallback, useEffect, useMemo, useRef } from 'react';

import { MediaElement } from 'editor/src/store/design/types';

import useForceUpdate from 'editor/src/component/useForceUpdate';

const updateMethods: Map<number, Set<React.Dispatch<React.SetStateAction<any>>>> = new Map();

export function useLiveUpdateMediaElement() {
  const liveUpdate = useCallback((element: MediaElement) => {
    updateMethods.get(element.uuid)?.forEach((update) => {
      update(element);
    });
  }, []);
  return liveUpdate;
}

function useMediaElementLiveUpdates<T extends MediaElement | undefined>(element: T) {
  const liveElementRef = useRef<T>();

  const forceUpdate = useForceUpdate();
  const onElementUpdate = useCallback((updatedElement: T) => {
    liveElementRef.current = updatedElement;
    forceUpdate();
  }, []);

  useMemo(() => {
    // reset the live element ref when the actual element gets updated by the store
    liveElementRef.current = undefined;
  }, [element]);

  useEffect(() => {
    if (!element) {
      return undefined;
    }

    if (!updateMethods.has(element.uuid)) {
      updateMethods.set(element.uuid, new Set());
    }
    updateMethods.get(element.uuid)?.add(onElementUpdate);

    return () => {
      updateMethods.get(element.uuid)?.delete(onElementUpdate);
    };
  }, [element?.uuid]);

  const liveUpdate = useCallback((element: Exclude<T, undefined>) => {
    updateMethods.get(element.uuid)?.forEach((update) => {
      if (onElementUpdate !== update) {
        update(element);
      }
    });
  }, []);

  return { liveElement: liveElementRef.current ?? element, liveUpdate };
}

export default useMediaElementLiveUpdates;

import React from 'react';

import { MediaText, StructureIndex } from 'editor/src/store/design/types';

import IconText from 'editor/src/component/Icon/IconText';
import ElementName from 'editor/src/component/PersonalizationContent/PersonalizationElements/ElementName';

import styles from './index.module.scss';

interface Props {
  element: MediaText;
  address: StructureIndex;
}
function TextLayer({ element, address }: Props) {
  return (
    <ElementName
      element={element}
      address={address}
      ImageElement={<IconText className={styles.icon} />}
      label={element.extra.text}
      isBuilding
      className={styles.elementContainer}
      isNameEditable={false}
    />
  );
}

export default React.memo(TextLayer);

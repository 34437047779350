import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import calendar from './calendar';
import phonecases from './phonecases';
import photobook from './photobook';
import wallarts from './wallarts';

export type LayoutSetName = 'phonecases' | 'wallarts' | 'calendar' | 'photobook';

const schemaSets: { [L in LayoutSetName]: LayoutSchema[] } = {
  phonecases,
  wallarts,
  calendar,
  photobook,
};

export default schemaSets;

import { RootState } from 'editor/src/store/index';

import { isEmbedded } from '../../watchers/useDisplayMode';

const isAddElementsAllowed = (state: RootState) =>
  isEmbedded
    ? !!state.design?.designData?.personalizationSettings?.allowAddElements
    : state.hostSettings.allowAddElements;

export default isAddElementsAllowed;

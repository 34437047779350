import React from 'react';

import imageCropRotate90DegreesOperation from 'editor/src/store/design/operation/crop/imageCropRotate90Operation';
import { useDispatch } from 'editor/src/store/hooks';

import IconRotate90 from 'editor/src/component/Icon/IconRotate90';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection, isElementEmpty }) => {
  const { imageCropRotate } = state.hostSettings.coreFeatures;
  return !isElementEmpty && hasSelection && imageCropRotate;
};

type Props = Pick<MenuItemProps, 'context' | 'mode'>;

function ButtonCropRotate90({ context, mode }: Props) {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(imageCropRotate90DegreesOperation(context.structureIndexes[0]));
  };

  return (
    <MenuButton
      Icon={IconRotate90}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-rotate-90-degrees"
      className="cy-button-crop-rotate-90"
    />
  );
}

export default React.memo(ButtonCropRotate90);

import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import { DraggableItemType, DraggableSingleItem } from 'editor/src/store/editor/types';
import { ImageAction } from 'editor/src/store/gallery/selector/getImageAction';
import { PluginIcon } from 'editor/src/store/plugins/selector/getPluginIconByLevel';
import { PluginState } from 'editor/src/store/plugins/types';

import useDrag, { DRAG_ELMT_CLASSNAME } from 'editor/src/util/dnd/useDrag';

import { ImageElement } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';
import IconPlus from 'editor/src/component/Icon/IconPlus';
import IconRefresh from 'editor/src/component/Icon/IconRefresh';

import styles from './index.module.scss';

interface Props {
  element: ImageElement;
  pluginName: string;
  PremiumIcon?: PluginIcon;
  onSelect: (element: ImageElement) => void;
  imageAction: ImageAction;
  className?: string;
  pluginState: PluginState;
}

function ElementImage({ element, pluginName, onSelect, PremiumIcon, imageAction, className, pluginState }: Props) {
  const [loaded, setLoaded] = useState(false);

  const getDraggedElement = useCallback(
    (): DraggableSingleItem => ({
      itemId: element.id,
      itemThumbnail: element.thumbnail,
      sourceId: pluginName,
      dimensions: element.width && element.height ? { width: element.width, height: element.height } : undefined,
      itemType: DraggableItemType.assetImage,
      isMultipleItems: false,
    }),
    [element],
  );

  const { elementRef } = useDrag(
    getDraggedElement,
    !!element.width && !!element.height && pluginState === PluginState.ENABLED,
  );

  const onLoad = () => {
    setLoaded(true);
  };

  return (
    <div
      className={cn(styles.elementImage, 'cy-plugin-image', DRAG_ELMT_CLASSNAME, className)}
      onClick={() => onSelect(element)}
      ref={elementRef}
    >
      <div className={styles.actionIcon}>
        {imageAction === ImageAction.ADD && <IconPlus />}
        {imageAction === ImageAction.REPLACE && <IconRefresh />}
      </div>
      {loaded && PremiumIcon && <PremiumIcon className={styles.premium} />}
      <img
        className={cn(styles.image, { [styles.loading]: !loaded })}
        src={element.thumbnail}
        alt="thumbnail"
        onLoad={onLoad}
        draggable={false}
      />
    </div>
  );
}

export default React.memo(ElementImage);

import { SpotFinishingType } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { RootState } from 'editor/src/store/index';

function geActiveSpreadSpotFinishingType(state: RootState): SpotFinishingType | undefined {
  const spreadIndex = getCurrentSpreadIndex(state);
  return state.design.designData?.spreads[spreadIndex]?.spot_finishing_type;
}

export default geActiveSpreadSpotFinishingType;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setCurrentStyleAction } from 'editor/src/store/editor/slice';
import { setDefaultFontStylesAction } from 'editor/src/store/fonts/slice';
import { getDefaultFontPerLocale } from 'editor/src/store/fonts/utils/defaultFontStyles';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const setDefaultFontOperation = (locale: string) => (dispatch: ThunkDispatch) => {
  const fontFamily = getDefaultFontPerLocale(locale);
  batch(() => {
    dispatch(setDefaultFontStylesAction({ fontFamily }));
    dispatch(setCurrentStyleAction({ type: 'text', update: { fontFamily } }));
  });
};

export default setDefaultFontOperation;

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconLayersLine({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 19L19 1" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export default IconLayersLine;

import loadExternalScript from './loadExternalScript';

declare global {
  interface Window {
    _uxa: any[];
    CS_CONF?: any;
  }
}

const loadContentSquareScript = (scriptUrl: string): void => {
  const handleUxaLogic = () => {
    window._uxa = window._uxa || [];
    if (typeof window.CS_CONF === 'undefined') {
      window._uxa.push(['setPath', window.location.pathname + window.location.hash.replace('#', '?__')]);
      loadExternalScript(scriptUrl);
    } else {
      window._uxa.push(['trackPageview', window.location.pathname + window.location.hash.replace('#', '?__')]);
    }
  };

  handleUxaLogic();
};

export default loadContentSquareScript;

import React from 'react';

import { setActiveTopMenuDropdownAction } from 'editor/src/store/app/slice';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import setElementManipulationModeOperation from 'editor/src/store/editor/operation/setElementManipulationModeOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { useDispatch } from 'editor/src/store/hooks';

import IconTick from 'editor/src/component/Icon/IconTick';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = () => true;

type Props = Pick<MenuItemProps, 'mode' | 'isMobile'>;

function ButtonPerspectiveTransformDone({ mode, isMobile }: Props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    batch(() => {
      dispatch(setElementManipulationModeOperation(undefined));
      if (isMobile) {
        dispatch(setSidebarActiveTabOperation(-1));
      } else {
        dispatch(setActiveTopMenuDropdownAction());
      }
    });
  };

  return (
    <MenuButton
      Icon={IconTick}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-crop-done"
      className="cy-button-done"
    />
  );
}

export default React.memo(ButtonPerspectiveTransformDone);

import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import isHiddenAddonsSearch from 'editor/src/store/editor/selector/isHiddenAddonsSearch';
import requestAddonsOperation from 'editor/src/store/editorModules/addons/operation/requestAddonsOperation';
import setSearchValueOperation from 'editor/src/store/editorModules/addons/operation/setSearchValueOperation';
import getAddonsCategory from 'editor/src/store/editorModules/addons/selector/getAddonsCategory';
import getAddonsSearch from 'editor/src/store/editorModules/addons/selector/getAddonsSearch';
import getRequestedVersion from 'editor/src/store/editorModules/addons/selector/getRequestedVersion';
import getResultsVersion from 'editor/src/store/editorModules/addons/selector/getResultsVersion';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import showPremiumPrompt from 'editor/src/store/plugins/selector/showPremiumPrompt';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import DrawerHeader from 'editor/src/component/BottomBarMobile/DrawerHeader';
import ButtonPremium from 'editor/src/component/Button/ButtonPremium';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import Search from 'editor/src/component/DesktopSidebar/TabContents/Elements/Search';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import GelatoPrompt from 'editor/src/component/GelatoPrompt';
import H1 from 'editor/src/component/Header/H1';
import IconArrowLeft from 'editor/src/component/Icon/IconArrowLeft';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import AddonsList from './AddonsList';
import AddonsLoader from './AddonsLoader';

import styles from './index.module.scss';

interface Props extends TabContentProps {}

function AddonsTabContent({ title, closeMenuCb }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const addonsCategory = useSelector(getAddonsCategory);
  const attributionImage = useSelector((store) => store.editorModules.addons.attributionImage);
  const search = useSelector(getAddonsSearch);
  const requestedVersion = useSelector(getRequestedVersion);
  const resultsVersion = useSelector(getResultsVersion);
  const hideAddonsSearch = useSelector(isHiddenAddonsSearch);
  const { gelatoPrompt, showPremiumButton } = useSelector((state) => {
    const plugin = getPlugin(state, PluginName.Graphics);
    return {
      gelatoPrompt: plugin && showPremiumPrompt(plugin),
      showPremiumButton: plugin && plugin.state !== PluginState.ENABLED && !plugin.extra?.gelatoPlusPrompt,
    };
  }, shallowEqual);

  const handleBackClick = () => {
    dispatch(requestAddonsOperation(addonsCategory?.parentId));
  };

  const handleSearchChange = (value: string) => {
    dispatch(setSearchValueOperation(value));
    dispatch(requestAddonsOperation());
  };

  const onPremiumClick = useCallback(() => {
    sendPostMessage('plugins.disabledPluginClick', {
      name: PluginName.Graphics,
    });
  }, []);

  const attributionContent = useMemo(() => {
    return (
      attributionImage && (
        <div
          style={{
            backgroundImage: `url(${attributionImage.url})`,
            backgroundSize: attributionImage.backgroundSize,
          }}
          className={cn(styles.attributionImage, 'cy-attribution-image')}
        />
      )
    );
  }, [attributionImage]);

  const premiumContent = useMemo(() => {
    return (
      showPremiumButton && (
        <>
          <div className={styles.placeholder} />
          <ButtonPremium onClick={onPremiumClick} />
        </>
      )
    );
  }, [showPremiumButton, onPremiumClick]);

  const headerContent = useMemo(() => {
    if (addonsCategory?.parentId) {
      return (
        <div onClick={handleBackClick} className={styles.backButton}>
          <H1>
            <IconArrowLeft />
            {t('editor-back')}
          </H1>
        </div>
      );
    }
    if (isMobile) {
      return (
        <DrawerHeader title={title || t('editor-stickers')} closeMenu={closeMenuCb}>
          {attributionContent}
          {premiumContent}
        </DrawerHeader>
      );
    }
    return (
      <TabContentHeader title={title || t('editor-stickers')} className="cy-addons-tab-header">
        {attributionContent}
        {premiumContent}
      </TabContentHeader>
    );
  }, [addonsCategory?.parentId, isMobile, handleBackClick, title, closeMenuCb, attributionContent, premiumContent]);

  return (
    <div data-ignore-outside-click className={styles.wrapper}>
      <ContentBlock>{headerContent}</ContentBlock>
      {!hideAddonsSearch && (
        <ContentBlock noBorder>
          <Search isMobile={isMobile} onChange={handleSearchChange} value={search} />
        </ContentBlock>
      )}
      <ContentBlock scroll noBorder>
        {gelatoPrompt && (
          <GelatoPrompt className={styles.prompt} prompt={gelatoPrompt} pluginName={PluginName.Graphics} />
        )}
        {requestedVersion === resultsVersion ? <AddonsList /> : <AddonsLoader />}
      </ContentBlock>
    </div>
  );
}

export default AddonsTabContent;

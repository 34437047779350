/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
  color: string | undefined;
  opacity?: number;
}

function IconStrokeColor({ className, color, opacity = 0.3 }: Props) {
  return (
    <Icon className={cn(className, 'cy-icon-stroke-color')}>
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 4.5C9.37258 4.5 4 9.87258 4 16.5C4 23.1274 9.37258 28.5 16 28.5C22.6274 28.5 28 23.1274 28 16.5C28 9.87258 22.6274 4.5 16 4.5ZM16 9.5C12.134 9.5 9 12.634 9 16.5C9 20.366 12.134 23.5 16 23.5C19.866 23.5 23 20.366 23 16.5C23 12.634 19.866 9.5 16 9.5Z"
          fill={color}
          strokeOpacity={opacity}
        />
      </svg>
    </Icon>
  );
}

export default IconStrokeColor;

import cn from 'classnames';
import React, { useContext, useRef } from 'react';

import getTextEditMode from 'editor/src/store/editor/selector/getTextEditMode';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useOnClickOutside from 'editor/src/util/useOnClickOutside';

import { UploaderContext } from 'editor/src/component/Uploader/useUploader';

import { TAB_NAMES } from '../../store/editorModules/sidebar/types';
import SidebarMobile from '../SidebarMobile';

import ActionBarSection from './ActionBarSection';
import ControlsSection from './ControlsSection';
import MainMenuContent from './MainMenuContent';

import styles from './index.module.scss';

function BottomBarMobile() {
  const divRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const uploader = useContext(UploaderContext);

  const isEditing = useSelector((state) => getTextEditMode(state));
  const dragging = useSelector((state) => state.editor.dragState !== undefined);
  const {
    sidebar: { activeTabIndex, tabs },
  } = useSelector((state) => state.editorModules);

  useOnClickOutside(
    divRef,
    () => {
      return (
        !uploader.isOpen() &&
        tabs[activeTabIndex] !== TAB_NAMES.IMAGE_COLOR &&
        dispatch(setSidebarActiveTabOperation(-1))
      );
    },
    isEditing,
  );

  return (
    <div className={cn(styles.BottomBarMobile, 'cy-bottom-bar-mobile')} ref={divRef}>
      <div className={styles.mainMenu}>
        <MainMenuContent />
      </div>
      <div
        className={cn(styles.controlsSection, {
          [styles.hide]: dragging,
        })}
      >
        <ActionBarSection />
        <SidebarMobile />
      </div>
      <ControlsSection />
    </div>
  );
}

export default React.memo(BottomBarMobile);

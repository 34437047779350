import cn from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import linkAllVariantsOperation from 'editor/src/store/variants/operation/linkAllVariantsOperation';
import setSelectedVariationGroupOperation from 'editor/src/store/variants/operation/setSelectedVariationGroupOperation';
import toggleLinkVariantGroupOperation from 'editor/src/store/variants/operation/toggleLinkVariantGroupOperation';
import getVariationGroupsFromDesignOptions from 'editor/src/store/variants/selector/getVariationGroupsFromDesignOptions';
import { toggleMultiOptionAction, updateVariationGroupsAction } from 'editor/src/store/variants/slice';
import { VariationGroup } from 'editor/src/store/variants/types';

import { RootState } from 'editor/src/store';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { PopupName } from 'editor/src/component/Popup/popups';
import { useCanvasRendering } from 'editor/src/component/SpreadPreview';

import DesignOptionsVariationGroupList from './DesignOptionsVariationGroupList';
import ProductSwitcher from './ProductSwitcher';
import ProductVariantEntry, { Ref as EntryRef } from './ProductVariantEntry';
import VariantLock from './VariantLock';

import styles from './index.module.scss';

function selector(state: RootState) {
  const { variationGroups, selectedGroupKey, selectedMultiOptions, product, configuration } = state.variants;
  return {
    variationGroups,
    selectedGroupKey,
    options: selectedMultiOptions,
    product,
    configuration,
    unit: getSettingsValue(state, SettingsProperty.units),
    spreadIndex: state.editor.currentSpreadIndex,
  };
}

function ProductVariantList() {
  const { variationGroups, selectedGroupKey, options, product, configuration, unit, spreadIndex } = useSelector(
    selector,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allLinked = useMemo(
    () => variationGroups.reduce((linked, group) => linked && group.linked, true),
    [variationGroups],
  );
  const { requestRender } = useCanvasRendering();
  const groupsFromDesignOptions = useSelector(getVariationGroupsFromDesignOptions);
  const selectedDesignOptionValue = useSelector((state) => state.variants.selectedDesignOptionValue);
  const unavailableProducts = useSelector((state) => state.variants.product.unavailableProducts);
  const selectedMultiOptions = useSelector((state) => state.variants.selectedMultiOptions);

  const linkAllVariants = useCallback(() => {
    dispatch(
      openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
        popupTitle: t('editor-link-all-variants-confirmation-title'), // txt_link_all_variants_confirmation_title
        textLines: [t('editor-link-all-variants-confirmation-text')], // txt_link_all_variants_confirmation_text
        options: [
          { title: t('editor-cancel') }, // txt_cancel
          {
            title: t('editor-link-all-variants-confirmation-yes-option'),
            onConfirm: () => dispatch(linkAllVariantsOperation()), // txt_link_all_variants_confirmation_yes_option
          },
        ],
      }),
    );
  }, []);

  const onLinkToggle = useCallback((group: VariationGroup) => {
    if (group.linked) {
      dispatch(toggleLinkVariantGroupOperation(group));
      return;
    }

    dispatch(
      openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
        popupTitle: t('editor-link-variant-confirmation-title', {
          formatName: group.title,
        }),
        textLines: [t('editor-link-variant-confirmation-text')],
        options: [
          { title: t('editor-cancel') },
          {
            title: t('editor-link-all-variants-confirmation-yes-option'),
            onConfirm: () => {
              dispatch(toggleLinkVariantGroupOperation(group));
            },
          },
        ],
      }),
    );
  }, []);

  const onSelect = useCallback((groupKey: string) => {
    dispatch(setSelectedVariationGroupOperation(groupKey));
  }, []);

  const refs = useMemo(() => variationGroups.map(() => React.createRef<EntryRef>()), [variationGroups.length]);

  useEffect(() => {
    const handle = window.setTimeout(() => {
      let y = 0;
      refs.forEach((ref) => {
        ref.current?.setY(y);
        y += ref.current?.getHeight() || 0;
      });
    }, 0);

    return () => window.clearTimeout(handle);
  }, [variationGroups]);

  const { disableLinkFeature } = configuration;

  const onDeleteVariant = (variantKey: string) => {
    const variantKeyWithoutGroupIndex = variantKey.replace(/-\d$/, '');
    // filter the variant from all groups
    const newVariationGroups = variationGroups.filter(
      ({ key }) => key.replace(/-\d$/, '') !== variantKeyWithoutGroupIndex,
    );

    // filter the selected options base on variants list
    const multiOptions = cloneDeep(selectedMultiOptions);
    Object.keys(selectedMultiOptions).forEach((optionKey) => {
      multiOptions[optionKey] = selectedMultiOptions[optionKey].filter(({ value, dependedOptions }) => {
        return newVariationGroups.some(({ variationsInfo }) => {
          return variationsInfo.some(({ variation }) => {
            return (
              variation[optionKey] === value && dependedOptions.every(({ key, value }) => variation[key] === value)
            );
          });
        });
      });
    });

    dispatch(updateVariationGroupsAction(newVariationGroups));
    dispatch(toggleMultiOptionAction({ multiOptions }));

    // set to focus other variant if the selected one got deleted
    if (selectedGroupKey?.replace(/-\d$/, '') === variantKeyWithoutGroupIndex) {
      dispatch(setSelectedVariationGroupOperation(newVariationGroups[0].key));
    }
  };

  return (
    <div
      className={cn(styles.ProductVariantList, 'cy-variant-list', {
        [styles.linkDisabled]: disableLinkFeature,
      })}
    >
      <ProductSwitcher />
      <ContentBlock fill scroll noBorder={disableLinkFeature}>
        {groupsFromDesignOptions?.length && selectedDesignOptionValue ? (
          <DesignOptionsVariationGroupList
            selectedDesignOptionValue={selectedDesignOptionValue}
            groups={groupsFromDesignOptions}
            onDelete={groupsFromDesignOptions.length > 0 ? onDeleteVariant : undefined}
          />
        ) : (
          <>
            {!disableLinkFeature && (
              <div className={cn(styles.header, 'cy-link-all')}>
                {t('editor-link-all-variants') /* txt_link_all_variants_button */}
                <VariantLock isLinked={allLinked} disabled={allLinked} onToggle={linkAllVariants} />
              </div>
            )}
            <div className={styles.separator} />
            <div className={styles.list}>
              {variationGroups.map((group, i) => (
                <ProductVariantEntry
                  key={`${group.key}-${group.designOptions?.join('-')}`}
                  group={group}
                  isSelected={group.key === selectedGroupKey}
                  options={options}
                  linkEnabled={!disableLinkFeature}
                  onSelect={onSelect}
                  onLinkToggle={onLinkToggle}
                  requestRender={requestRender}
                  product={product}
                  unit={unit}
                  ref={refs[i]}
                  spreadIndex={spreadIndex}
                  onDelete={variationGroups.length > 1 ? onDeleteVariant : undefined}
                  unavailableProducts={unavailableProducts}
                />
              ))}
            </div>
          </>
        )}
      </ContentBlock>
    </div>
  );
}

export default React.memo(ProductVariantList);

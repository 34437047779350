/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconReupload({ className }: Props) {
  return (
    <Icon className={cn(className, styles.iconReupload)}>
      <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 6.74996L17.182 9.93194M14 6.74996L10.818 9.93194M14 6.74996L14 14.25"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8 17.25H20" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M1.78317 8.69232L4.76675 11.6759L7.75034 8.69232"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.6685 10.1841C22.6685 5.2407 18.661 1.23328 13.7176 1.23328C8.77421 1.23328 4.76678 5.2407 4.76678 10.1841V10.9301"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

export default IconReupload;

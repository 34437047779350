import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import applyPatternOnImageOperation from 'editor/src/store/design/operation/applyPatternOnImageOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { DEFAULT_PATTERN_TYPE } from 'editor/src/store/gallery/utils/getPatternDefaults';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import DrawerHeader from 'editor/src/component/BottomBarMobile/DrawerHeader';
import EffectsElement from 'editor/src/component/DesktopSidebar/TabContents/EffectsTabContent/EffectsElement';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconPattern from 'editor/src/component/Icon/IconPattern';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import ContentBlock from '../Elements/ContentBlock';

import BrickPatternControls from './BrickPatternControls';

import styles from './index.module.scss';

interface Props {
  closeMenuCb?: () => void;
}

function PatternTabContent({ closeMenuCb }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { address, pattern: patternData } = useSelector((state) => {
    const scaleBases = state.editor.imagePatternScaleBases;
    if (!state.editor.selectedElementUuids[0]) {
      return { address: undefined, pattern: undefined, scaleBases };
    }
    const address = getStructureIndexByElementUuidMemoized(state, state.editor.selectedElementUuids[0]);
    const element = address ? getMediaElement(state, address) : undefined;
    return {
      address,
      pattern: element?.type === 'image' ? element.pattern : undefined,
      scaleBases,
    };
  }, shallowEqual);

  const onToggled = useCallback(() => {
    if (address) {
      dispatch(applyPatternOnImageOperation(address, patternData ? undefined : DEFAULT_PATTERN_TYPE));
    }
  }, [address, patternData]);

  if (!address) {
    return null;
  }

  return (
    <div className={cn({ [styles.mobile]: isMobile })}>
      {isMobile ? (
        <DrawerHeader title={t('Pattern')} closeMenu={closeMenuCb} />
      ) : (
        <TabContentHeader title={t('Pattern')} />
      )}
      <ContentBlock scroll fillFlex>
        <EffectsElement
          controlId="pattern"
          title={t('Tiling')}
          icon={<IconPattern />}
          switchStatus={!!patternData}
          onToggled={onToggled}
          pluginName={undefined}
        >
          {patternData?.type === DEFAULT_PATTERN_TYPE ? (
            <BrickPatternControls address={address} pattern={patternData} />
          ) : null}
        </EffectsElement>
      </ContentBlock>
    </div>
  );
}

export default PatternTabContent;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'editor/src/store/hooks';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import ProductInfo from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductInfo';
import H1 from 'editor/src/component/Header/H1';
import IconArrowLeft from 'editor/src/component/Icon/IconArrowLeft';

import styles from './index.module.scss';

interface Props {
  closeInfo(): void;
}

function ProductDescriptionContent({ closeInfo }: Props) {
  const product = useSelector((state) => state.variants.product);
  const { t } = useTranslation();

  return (
    <>
      <ContentBlock noBorder>
        <H1 className={styles.title}>
          <span onClick={closeInfo} className={cn(styles.back, 'cy-back-btn')}>
            <IconArrowLeft />
          </span>
          {t('editor-back')}
        </H1>
      </ContentBlock>

      <ContentBlock scroll fill>
        <ProductInfo
          productTitle={product.title}
          productDescription={product.description}
          categoryTitle={product.categoryTitle}
          previewUrls={product.previewUrls}
        />
      </ContentBlock>
    </>
  );
}

export default React.memo(ProductDescriptionContent);

import closePopupOperation from 'editor/src/store/app/module/popup/operation/closePopupOperation';
import openPopupOperation from 'editor/src/store/app/module/popup/operation/openPopupOperation';
import { DesignData } from 'editor/src/store/design/types';
import type { Thunk } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import { InboundPostMessage } from 'editor/src/util/postMessages/messages';

import { PopupName } from 'editor/src/component/Popup/popups';

import requestEcommerceVariantOperation from './requestEcommerceVariantOperation';

const setVariantDesignToValidateOperation =
  (variantDesignToValidate: InboundPostMessage['variants.setECommerceVariantDesignToValidate']): Thunk =>
  (dispatch, getState: () => RootState, { i18n }) => {
    if (!variantDesignToValidate) {
      dispatch(closePopupOperation());
      return;
    }

    const state = getState();
    const currentDesignData = state.design.designData;
    if (currentDesignData && !isConfirmationNeeded(currentDesignData, variantDesignToValidate.design)) {
      dispatch(requestEcommerceVariantOperation(variantDesignToValidate.variantId));
      return;
    }

    const confirmModalData = {
      messageTitle: i18n.t('ecommerce-variant-switch-confirmation-title'),
      message: i18n.t('ecommerce-variant-switch-confirmation-description'),
    };

    dispatch(
      openPopupOperation(PopupName.LOCAL_CONFIRMATION_POPUP, {
        popupTitle: confirmModalData.messageTitle,
        textLines: [confirmModalData.message],
        options: [
          {
            title: i18n.t('editor-cancel'),
          },
          {
            title: i18n.t('Continue'),
            onConfirm: () => dispatch(requestEcommerceVariantOperation(variantDesignToValidate.variantId)),
          },
        ],
        hideTitleIcon: true,
      }),
    );
  };

const isConfirmationNeeded = (currentDesign: DesignData, nextDesign: DesignData | undefined) => {
  if (!nextDesign) {
    return false;
  }

  // new design should contain all spreads from existing to avoid confirmation
  const currentDesignSpreadNames = currentDesign.spreads.map((spread) => spread.name).sort();
  const nextDesignSpreadNames = nextDesign.spreads.map((spread) => spread.name).sort();
  return currentDesignSpreadNames.some(
    (currentDesignSpreadName, i) => currentDesignSpreadName !== nextDesignSpreadNames[i],
  );
};

export default setVariantDesignToValidateOperation;

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const stretchToFitOperation =
  (elementAddress: ElementAddress) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    dispatch(saveUndoRedoStateOperation('stretch to fit'));
    const element = getMediaElement(state, elementAddress);
    if (element?.type === 'line') {
      return;
    }
    if (element) {
      const elementUpdate = {
        px: 0,
        py: 0,
        pw: element.width,
        ph: element.height,
        pr: 0,
      };
      dispatch(updateMediaElementAction({ elementAddress, elementUpdate }));
    }
  };

export default stretchToFitOperation;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { setMockupPreviewAction, setMultiOptionAction } from 'editor/src/store/mockup/slice';
import { ProductControlMulti } from 'editor/src/store/variants/types';

import updatePlaceholderOperation from './updatePlaceholderOperation';

const setMockupMultiOptionOperation = (control: ProductControlMulti, value: string) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(setMultiOptionAction({ control, value }));
    dispatch(setMockupPreviewAction(undefined));
    dispatch(updatePlaceholderOperation());
  });
};

export default setMockupMultiOptionOperation;

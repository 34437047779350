import React from 'react';

import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import getCurrentFontStylesProperty from 'editor/src/store/fonts/selector/getCurrentFontStylesProperty';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconStrikethrough from 'editor/src/component/Icon/IconStrikethrough';
import { MenuItemProps, CanShow } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

type Props = Pick<MenuItemProps, 'mode'>;

export const canShow: CanShow = () => true;

function ButtonStrikethrough({ mode }: Props) {
  const dispatch = useDispatch();
  const currentLinethroughStatus = useSelector((state) => getCurrentFontStylesProperty(state, 'linethrough'));

  const onLinethroughStatusSwitched = () => {
    dispatch(
      applyFontStylesToSelectionOperation({
        linethrough: !currentLinethroughStatus,
      }),
    );
  };

  return (
    <MenuButton
      Icon={IconStrikethrough}
      mode={mode}
      on={currentLinethroughStatus}
      handleClick={onLinethroughStatusSwitched}
      i18nKey="editor-strikethrough"
      className="cy-button-strikethrough"
    />
  );
}

export default React.memo(ButtonStrikethrough);

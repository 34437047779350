import { RootState } from 'editor/src/store/index';

import getCurrentSpreadGroup from './getCurrentSpreadGroup';
import getSpread from './getSpread';
import getSpreadWidthFromSpread from './getSpreadWidthFromSpread';

const getSpreadWidth = (state: RootState, spreadIndex: number) => {
  const spread = getSpread(state, spreadIndex);
  const currentSpreadGroup = getCurrentSpreadGroup(state);
  const spreadWidth = spread ? getSpreadWidthFromSpread(spread) : 0;
  // we assume all grouped spreads have the same dimensions
  return currentSpreadGroup?.position === 'horizontal'
    ? spreadWidth * currentSpreadGroup.spreadIndexes.length
    : spreadWidth;
};

export default getSpreadWidth;

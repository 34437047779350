import type { ThunkDispatch } from 'editor/src/store/hooks';

import defaultSchemas from 'editor/src/util/layouts/layoutSchemas/defaultSchemas';
import sets, { LayoutSetName } from 'editor/src/util/layouts/layoutSchemas/schemaSets';

import setLayoutsOperation from '../../editorModules/layouts/operation/setLayoutsOperation';

const loadSchemaSetOperation = (setName: LayoutSetName) => (dispatch: ThunkDispatch) => {
  const set = sets[setName] ?? defaultSchemas;
  dispatch(setLayoutsOperation(set));
};

export default loadSchemaSetOperation;

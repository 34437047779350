import cn from 'classnames';
import React, { useCallback } from 'react';

import { TextEffectControl } from 'editor/src/store/app/types';
import { useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import getPluginIconByLevel from 'editor/src/store/plugins/selector/getPluginIconByLevel';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import SwitchControl from 'editor/src/component/SwitchControl';

import styles from './index.module.scss';

interface Props {
  controlId: TextEffectControl;
  children: React.ReactNode;
  icon: React.ReactNode;
  title: string;
  switchStatus: boolean;
  pluginName?: PluginName | undefined;
  onToggled: () => void;
}

function EffectsElement({ controlId, children, title, icon, switchStatus, onToggled, pluginName }: Props) {
  const plugin = useSelector((state) => (pluginName ? getPlugin(state, pluginName) : undefined));
  const nonFunctional = plugin?.state === PluginState.NON_FUNCTIONAL;
  const PremiumIcon = controlId !== 'pattern' && plugin && getPluginIconByLevel(plugin?.extra?.premiumLevel);

  const onSwitch = useCallback(() => {
    if (!pluginName || !nonFunctional) {
      onToggled();
    }
  }, [onToggled, nonFunctional, pluginName]);

  const onRowClick = useCallback(() => {
    if (nonFunctional && pluginName) {
      sendPostMessage('plugins.disabledPluginClick', { name: pluginName });
    }
  }, [nonFunctional, pluginName]);

  return (
    <div
      className={cn(
        styles.TextEffectsContainer,
        'px-3 py-2 my-2',
        { [styles.TextEffectEnableBackground]: switchStatus },
        `cy-effects-${controlId}`,
      )}
      onClick={onRowClick}
    >
      <div
        className={cn('row-flex row-flex--middle cy-switcher-container', {
          'mb-2': switchStatus,
        })}
      >
        <div className={styles.iconContainer}>{icon}</div>
        <SwitchControl
          title={title}
          on={switchStatus}
          onSwitch={onSwitch}
          className={styles.switchControl}
          PremiumIcon={PremiumIcon || undefined}
        />
      </div>
      {switchStatus && children}
    </div>
  );
}

export default EffectsElement;

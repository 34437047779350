import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { setECommerceProductIdAction } from 'editor/src/store/variants/slice';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const requestEcommerceVariantOperation = (newVariantId: string) => (dispatch: ThunkDispatch) => {
  sendPostMessage('variants.requestECommerceVariant', { gelatoVariantId: newVariantId });
  batch(() => {
    dispatch(saveUndoRedoStateOperation('ecommerce variant changed'));
    dispatch(setECommerceProductIdAction(newVariantId));
  });
};

export default requestEcommerceVariantOperation;

import { fabric } from 'fabric';
import { Polygon } from 'polygon-clipping';
import React from 'react';

import { LineEdge } from 'editor/src/store/design/types';

import FabricCircle from 'editor/src/fabric/FabricCircle';
import FabricLine from 'editor/src/fabric/FabricLine';
import FabricObject from 'editor/src/fabric/FabricObject';
import FabricRect from 'editor/src/fabric/FabricRect';

import { setClipPath } from 'editor/src/component/EditorArea/fabricComponents/fabricUtils';
import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';

import getLineRectFromRef from './getLineRectFromRef';
import { getArrowBoundingRectFromRef, getEdgeOffset, getLineCapOffset } from './utils';

const onStartPointMove = (
  rectRef: React.RefObject<FabricRect>,
  lineRef: React.RefObject<FabricLine>,
  startPointRef: React.RefObject<FabricCircle>,
  endPointRef: React.RefObject<FabricCircle>,
  edge1Ref: React.RefObject<FabricObject>,
  edge2Ref: React.RefObject<FabricObject>,
  edge1: LineEdge,
  edge2: LineEdge,
  contentClipPolygons: Polygon[],
  contentClipPath: fabric.Object | undefined,
) => {
  if (!rectRef.current) {
    return;
  }
  const { left = 0, top = 0, width = 0, height = 0, angle = 0 } = rectRef.current;

  const rounded = lineRef.current?.strokeLineCap === 'round';
  const lineCapOffset = getLineCapOffset(rounded, height, angle);

  const strokeWidthOffset = fabric.util.rotatePoint(
    new fabric.Point(height / 2, 0),
    new fabric.Point(0, 0),
    fabric.util.degreesToRadians(angle + 90),
  );

  const endPoint = fabric.util.rotatePoint(
    new fabric.Point(left + width, top),
    new fabric.Point(left, top),
    fabric.util.degreesToRadians(angle),
  );

  const edge1Offset = getEdgeOffset(edge1, height, angle);
  const edge2Offset = getEdgeOffset(edge2, height, angle);
  startPointRef.current?.set({
    left: left + strokeWidthOffset.x,
    top: top + strokeWidthOffset.y,
  });
  endPointRef.current?.set({
    left: endPoint.x + strokeWidthOffset.x,
    top: endPoint.y + strokeWidthOffset.y,
  });
  lineRef.current?.set({
    x1: left + strokeWidthOffset.x + lineCapOffset.x,
    y1: top + strokeWidthOffset.y + lineCapOffset.y,
    x2: endPoint.x + strokeWidthOffset.x - lineCapOffset.x,
    y2: endPoint.y + strokeWidthOffset.y - lineCapOffset.y,
  });

  const lineBBox = getLineRectFromRef(lineRef, height);
  const clipPath = getClipPath(lineBBox, contentClipPolygons, false, contentClipPath);
  setClipPath(lineRef.current, clipPath);

  edge1Ref.current?.set({
    left: left + strokeWidthOffset.x,
    top: top + strokeWidthOffset.y,
  });
  edge2Ref.current?.set({
    left: endPoint.x + strokeWidthOffset.x,
    top: endPoint.y + strokeWidthOffset.y,
  });

  setClipPath(
    edge1Ref.current,
    getClipPath(getArrowBoundingRectFromRef(edge1Ref, height, angle), contentClipPolygons, false, contentClipPath),
  );
  setClipPath(
    edge2Ref.current,
    getClipPath(
      getArrowBoundingRectFromRef(edge2Ref, height, -180 + angle),
      contentClipPolygons,
      false,
      contentClipPath,
    ),
  );

  // need to remove the edge offset from each side
  if (lineRef.current) {
    const { x1 = 0, y1 = 0, x2 = 0, y2 = 0 } = lineRef.current;
    lineRef.current?.set({
      x1: x1 + edge1Offset.x,
      y1: y1 + edge1Offset.y,
      x2: x2 - edge2Offset.x,
      y2: y2 - edge2Offset.y,
    });
  }
};

export default onStartPointMove;

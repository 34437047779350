/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconAlignCenter({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M12 22C11.7167 22 11.4793 21.904 11.288 21.712C11.096 21.5207 11 21.2833 11 21V17H7.5C7.08333 17 6.72933 16.854 6.438 16.562C6.146 16.2707 6 15.9167 6 15.5C6 15.0833 6.146 14.7293 6.438 14.438C6.72933 14.146 7.08333 14 7.5 14H11V10H4.5C4.08333 10 3.72933 9.854 3.438 9.562C3.146 9.27067 3 8.91667 3 8.5C3 8.08333 3.146 7.72933 3.438 7.438C3.72933 7.146 4.08333 7 4.5 7H11V3C11 2.71667 11.096 2.479 11.288 2.287C11.4793 2.09567 11.7167 2 12 2C12.2833 2 12.521 2.09567 12.713 2.287C12.9043 2.479 13 2.71667 13 3V7H19.5C19.9167 7 20.2707 7.146 20.562 7.438C20.854 7.72933 21 8.08333 21 8.5C21 8.91667 20.854 9.27067 20.562 9.562C20.2707 9.854 19.9167 10 19.5 10H13V14H16.5C16.9167 14 17.2707 14.146 17.562 14.438C17.854 14.7293 18 15.0833 18 15.5C18 15.9167 17.854 16.2707 17.562 16.562C17.2707 16.854 16.9167 17 16.5 17H13V21C13 21.2833 12.9043 21.5207 12.713 21.712C12.521 21.904 12.2833 22 12 22Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconAlignCenter;

import cn from 'classnames';
import React from 'react';

import updateMediaElementSpotFinishingTypeOperation from 'editor/src/store/design/operation/updateMediaElementSpotFinishingTypeOperation';
import {
  MediaAddon,
  MediaElement,
  MediaImage,
  MediaLine,
  MediaRectangle,
  MediaText,
} from 'editor/src/store/design/types';
import isMediaElementSelectedByUuid from 'editor/src/store/editor/selector/isMediaElementSelectedByUuid';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import SwitchButton from 'editor/src/component/SwitchButton';

import styles from './index.module.scss';

export interface ItemProps<A extends MediaElement> {
  element: A;
}

interface Props extends ItemProps<MediaImage | MediaAddon | MediaText | MediaRectangle | MediaLine> {
  children?: React.ReactNode;
  className?: string;
}

function AbstractFoilLayerItem({ element, children, className }: Props) {
  const dispatch = useDispatch();
  const selected = useSelector((state) => isMediaElementSelectedByUuid(state, element.uuid));

  const handleSwitch = (on: boolean) => {
    dispatch(updateMediaElementSpotFinishingTypeOperation(element.uuid, on));
  };

  return (
    <div
      className={cn(styles.AbstractFoilLayerItem, 'cy-layers-list-item', 'my-2', className, {
        [styles.selected]: selected,
        'cy-selected': selected,
      })}
    >
      <div className={styles.left}>{children}</div>
      <div className={cn(styles.right)}>
        <SwitchButton on={!!element.has_spot_finishing} onSwitch={handleSwitch} />
      </div>
    </div>
  );
}

export default React.memo(AbstractFoilLayerItem);

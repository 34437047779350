import { RootState } from 'editor/src/store/index';

import getImageIdsUsage from '../../design/util/getImageIdsUsage';

const getIsAllImagesSelected = (state: RootState) => {
  const usedImages = getImageIdsUsage(state.design.designData);
  const filteredImages = state.gallery.hideUsedPhotos
    ? state.gallery.images.filter((image) => !image.hidden && !usedImages.has(image.id))
    : state.gallery.images.filter((image) => !image.hidden);

  return state.gallery.selectedImagesIds.length >= filteredImages.length;
};

export default getIsAllImagesSelected;

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import applyPatternOnImageOperation from 'editor/src/store/design/operation/applyPatternOnImageOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { MediaImage } from 'editor/src/store/design/types';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { DEFAULT_PATTERN_TYPE } from 'editor/src/store/gallery/utils/getPatternDefaults';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';

import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import PatternTabContent from '../../DesktopSidebar/TabContents/PatternTabContent';
import IconPattern from '../../Icon/IconPattern';
import useMobileMenu from '../../useMobileMenu';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { selectedElementType, isElementEmpty }) =>
  selectedElementType === 'image' && !isElementEmpty;

type Props = Pick<MenuItemProps, 'mode' | 'context' | 'close' | 'isMobile'>;

function ButtonPattern({ mode, context, close, isMobile }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const store = useStore();
  const isActive = useSelector((state) => isSidebarTabActive(state, TAB_NAMES.PATTERN));
  const patternMenu = useMobileMenu(false, true);

  const handleClick = () => {
    if (isMobile) {
      patternMenu.open();
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.PATTERN));
    } else {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.PATTERN));
      close?.();
    }

    const address = context.structureIndexes[0];
    const element = getMediaElement<MediaImage>(store.getState(), address);
    if (!element?.pattern) {
      dispatch(applyPatternOnImageOperation(address, DEFAULT_PATTERN_TYPE));
    }
  };

  const closeMenu = useCallback(() => {
    patternMenu.close();
    dispatch(setSidebarActiveTabOperation(-1));
  }, []);

  return (
    <>
      <MenuButton
        className="cy-button-pattern"
        Icon={IconPattern}
        mode={mode}
        on={isActive}
        handleClick={handleClick}
        label={t('Pattern')}
      />
      {patternMenu.render(<PatternTabContent closeMenuCb={closeMenu} />)}
    </>
  );
}

export default React.memo(ButtonPattern);

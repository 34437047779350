import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { MediaMockupPlaceholder } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const updatePlaceholderAnchoringOperation =
  (anchoring: Pick<MediaMockupPlaceholder, 'anchoring_x' | 'anchoring_y'>) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    getState().design.designData?.spreads.forEach((spread, spreadIndex) => {
      const page = spread.pages[0];
      page.groups.media?.forEach((media, elementIndex) => {
        if (isMediaMockupPlaceholder(media)) {
          dispatch(
            updateMediaElementAction({
              elementAddress: { spreadIndex, pageIndex: 0, elementIndex },
              elementUpdate: anchoring,
            }),
          );
        }
      });
    });
  };

export default updatePlaceholderAnchoringOperation;

const AVAILABLE_COLORS = [
  '#000000',
  '#434343',
  '#999999',
  '#B7B7B7',
  '#CCCCCC',
  '#D9D9D9',
  '#F3F3F3',
  '#FFFFFF',
  '#EB3223',
  '#F29D38',
  '#FFFD54',
  '#75F94C',
  '#73FBFD',
  '#5686E1',
  '#8B2EF5',
  '#EA3FF7',
  '#EDCDCD',
  '#F7E0C5',
  '#FDF1D0',
  '#DCE9D5',
  '#D3DFE2',
  '#CCDAF5',
  '#D8D2E7',
  '#E6D2DB',
  '#DE9D9B',
  '#F2CCA2',
  '#FBE5A2',
  '#BCD5AC',
  '#A9C3C8',
  '#A9C2F0',
  '#B2A8D2',
  '#CEA8BC',
  '#D16D6A',
  '#EDB476',
  '#FAD978',
  '#9DC284',
  '#80A4AD',
  '#779FE5',
  '#8A7FBE',
  '#B87F9E',
  '#BC271A',
  '#DB944B',
  '#EAC251',
  '#78A55A',
  '#53808C',
  '#497AD1',
  '#6252A2',
  '#9B5377',
  '#8C1A11',
  '#A96224',
  '#B99130',
  '#48742C',
  '#254E5A',
  '#2458C5',
  '#312170',
  '#6A2346',
  '#5D0E07',
  '#704116',
  '#785B13',
  '#314C1C',
  '#18333C',
  '#254682',
  '#1D154A',
  '#46172F',
];

export default AVAILABLE_COLORS;

import cn from 'classnames';
import React from 'react';

import { Coords, DesignDimensions } from 'editor/src/store/design/types';
import { Unit } from 'editor/src/store/editor/types';

import { convertMmToUnit } from 'editor/src/util/math';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import styles from './index.module.scss';

interface Props {
  spreadWidth: number;
  spreadHeight: number;
  dimensions: DesignDimensions;
  spreadCoords: Coords;
  xDelimiters: number[];
  yDelimiters: number[];
  zoom: number;
  unit: Unit;
  offsetX: number;
}

function RulersCss({
  spreadCoords,
  dimensions,
  spreadWidth,
  spreadHeight,
  xDelimiters,
  yDelimiters,
  zoom,
  unit,
  offsetX,
}: Props) {
  const fabricCanvas = useFabricCanvas();
  const {
    tl: { x: left, y: top },
  } = fabricCanvas.calcViewportBoundaries();
  const { mm2px } = useFabricUtils();

  return (
    <>
      <div className={styles.axisHorizontal}>
        <div
          className={styles.axisHorizontalSpread}
          style={{
            left: (spreadCoords.left - left) * zoom - 1.5,
            width: mm2px(spreadWidth * zoom) + 1,
          }}
        >
          {xDelimiters.map((delimiter, index) => (
            <div
              key={index}
              className={cn(styles.delimiterNumber, styles.delimiterNumberHorizontal, {
                [styles.spreadSize]: delimiter + offsetX === 0,
              })}
              style={{
                left: mm2px((delimiter + offsetX) * zoom),
                opacity: mm2px(Math.abs(delimiter + offsetX - spreadWidth) * zoom) < 30 ? 0 : 1,
              }}
            >
              {+convertMmToUnit(delimiter, unit).toFixed(1)}
              <div className={styles.delimiter} />
            </div>
          ))}
          <div
            className={cn(styles.delimiterNumber, styles.delimiterNumberHorizontal, styles.spreadSize)}
            style={{ left: mm2px(spreadWidth * zoom) }}
          >
            {+convertMmToUnit(dimensions.width - offsetX, unit).toFixed(1)}
          </div>
        </div>
      </div>
      <div className={styles.axisVertical}>
        <div
          className={styles.axisVerticalSpread}
          style={{
            top: (spreadCoords.top - top) * zoom - 1,
            height: mm2px(spreadHeight * zoom),
          }}
        >
          {yDelimiters.map((delimiter, index) => (
            <div
              key={index}
              className={cn(styles.delimiterNumber, styles.delimiterNumberVertical, {
                [styles.spreadSize]: index === 0,
              })}
              style={{
                top: mm2px(delimiter * zoom),
                opacity: mm2px(Math.abs(delimiter - dimensions.height) * zoom) < 25 ? 0 : 1,
              }}
            >
              {+convertMmToUnit(delimiter, unit).toFixed(1)}
              <div className={styles.delimiter} />
            </div>
          ))}
          <span
            className={cn(styles.delimiterNumber, styles.delimiterNumberVertical, styles.spreadSize)}
            style={{ top: mm2px(spreadHeight * zoom) }}
          >
            {+convertMmToUnit(dimensions.height, unit).toFixed(1)}
          </span>
        </div>
      </div>
      <div className={styles.crossing} />
    </>
  );
}

export default React.memo(RulersCss);

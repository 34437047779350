import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

interface Props {
  price: string;
  originalPrice: string | undefined;
}

function ProductPrice({ price, originalPrice }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.priceWrapper}>
      <div className={styles.priceLabel}>{t('Price')}</div>
      <div key={price || originalPrice} className={styles.priceValue}>
        {originalPrice && <span className={styles.originalValue}>{originalPrice}</span>}
        <span className={styles.currentValue}>{price}</span>
      </div>
    </div>
  );
}
export default ProductPrice;

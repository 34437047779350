import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import defineLayoutSchema from '../../test/defineLayoutSchema';

import schemas from './defaultSchemas';

const layoutSchemasPhotobook: LayoutSchema[] = [
  defineLayoutSchema(
    'pb_i2_1',
    2,
    1,
    10,
    0,
    0,
    0,
    [
      [
        { width: 1, height: 1 },
        { width: 1, height: 1 },
      ],
    ],
    true,
  ),
  defineLayoutSchema(
    'pb_i2_2',
    2,
    7,
    10,
    0,
    0,
    0,
    [
      [],
      [
        { width: 1, height: 5 },
        { width: 1, height: 5 },
      ],
    ],
    true,
  ),
  defineLayoutSchema(
    'pb_i2_3',
    12,
    6,
    10,
    0,
    0,
    0,
    [[], [{}, { width: 4, height: 4 }, {}, {}, { width: 4, height: 4 }]],
    true,
  ),
  defineLayoutSchema(
    'pb_i2_4',
    12,
    7,
    10,
    0,
    0,
    0,
    [[], [], [{}, { width: 4, height: 3 }, {}, {}, { width: 4, height: 3 }]],
    true,
  ),
  // half photobook page layouts
  defineLayoutSchema('pb_i1_2', 1, 7, 10, 0, 0, 0, [[], [{ width: 1, height: 5 }]]),
  defineLayoutSchema('pb_i1_3', 6, 6, 10, 0, 0, 0, [[], [{}, { width: 4, height: 4 }]]),
  defineLayoutSchema('pb_i1_4', 6, 7, 10, 0, 0, 0, [[], [], [{}, { width: 4, height: 3 }]]),
  defineLayoutSchema('pb_i6_1', 8, 11, 10, 4, 0, 0, [
    [],
    [...Array(1).fill({}), { width: 3, height: 3 }, { width: 3, height: 3 }],
    [],
    [],
    [...Array(1).fill({}), { width: 3, height: 3 }, { width: 3, height: 3 }],
    [],
    [],
    [...Array(1).fill({}), { width: 3, height: 3 }, { width: 3, height: 3 }],
  ]),
  defineLayoutSchema('pb_i6_2', 11, 8, 10, 4, 0, 0, [
    [],
    [...Array(1).fill({}), { width: 3, height: 3 }, { width: 3, height: 3 }, { width: 3, height: 3 }],
    [],
    [],
    [...Array(1).fill({}), { width: 3, height: 3 }, { width: 3, height: 3 }, { width: 3, height: 3 }],
  ]),
  ...schemas,
];

export default layoutSchemasPhotobook;

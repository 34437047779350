/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
  isActive?: boolean;
}

function IconDiamond({ className, isActive }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        {isActive ? (
          <path
            d="M10.7166 8.91228C10.1653 8.91228 9.80239 8.33742 10.0395 7.83971L11.8118 4.11959C11.8466 4.04653 11.9203 4 12.0012 4C12.0822 4 12.1559 4.04653 12.1907 4.11959L13.963 7.83971C14.2001 8.33742 13.8372 8.91228 13.2859 8.91228H10.7166ZM11.3389 17.8333C11.3389 18.5453 10.4396 18.8566 9.9994 18.2969L4.6766 11.5295C4.2896 11.0374 4.64011 10.3158 5.26611 10.3158H10.5889C11.0031 10.3158 11.3389 10.6516 11.3389 11.0658V17.8333ZM14.0031 18.2969C13.5629 18.8566 12.6636 18.5453 12.6636 17.8333V11.0658C12.6636 10.6516 12.9994 10.3158 13.4136 10.3158H18.7364C19.3624 10.3158 19.7129 11.0374 19.3259 11.5295L14.0031 18.2969ZM16.4084 8.91228C16.1172 8.91228 15.8523 8.74369 15.729 8.47987L14.134 5.06759C13.9016 4.57031 14.2645 4 14.8135 4H17.7077C17.9979 4 18.2621 4.16745 18.3859 4.42992L19.9963 7.8422C20.231 8.3397 19.8681 8.91228 19.318 8.91228H16.4084ZM4.6845 8.91228C4.13439 8.91228 3.77146 8.3397 4.00623 7.8422L5.61654 4.42991C5.74041 4.16745 6.00458 4 6.29481 4H9.18903C9.73794 4 10.1009 4.57031 9.86847 5.06758L8.27349 8.47987C8.15018 8.74369 7.88528 8.91228 7.59405 8.91228H4.6845Z"
            fill="#212121"
          />
        ) : (
          <path
            d="M12.0008 19.4996C11.7508 19.4996 11.5091 19.4454 11.2758 19.3371C11.0424 19.2288 10.8341 19.0746 10.6508 18.8746L3.15078 10.4246C3.00078 10.2579 2.88411 10.0704 2.80078 9.86211C2.71745 9.65378 2.67578 9.44128 2.67578 9.22461C2.67578 9.10794 2.69245 8.99961 2.72578 8.89961C2.75911 8.79961 2.79245 8.69128 2.82578 8.57461L4.35078 4.72461C4.48411 4.39128 4.70495 4.12044 5.01328 3.91211C5.32161 3.70378 5.65911 3.59961 6.02578 3.59961H17.9758C18.3424 3.59961 18.6799 3.70378 18.9883 3.91211C19.2966 4.12044 19.5174 4.39128 19.6508 4.72461L21.1758 8.57461C21.2091 8.69128 21.2424 8.79961 21.2758 8.89961C21.3091 8.99961 21.3258 9.10794 21.3258 9.22461C21.3258 9.44128 21.2841 9.65378 21.2008 9.86211C21.1174 10.0704 21.0008 10.2579 20.8508 10.4246L13.3508 18.8746C13.1674 19.0746 12.9591 19.2288 12.7258 19.3371C12.4924 19.4454 12.2508 19.4996 12.0008 19.4996ZM9.85078 8.69961H14.1508L12.5008 5.39961H11.5008L9.85078 8.69961ZM11.1008 16.6746V10.4996H5.60078L11.1008 16.6746ZM12.9008 16.6746L18.4008 10.4996H12.9008V16.6746ZM16.1508 8.69961H19.3008L18.0008 5.39961H14.5008L16.1508 8.69961ZM4.70078 8.69961H7.85078L9.50078 5.39961H6.02578L4.70078 8.69961Z"
            fill="#212121"
          />
        )}
      </svg>
    </Icon>
  );
}

export default React.memo(IconDiamond);

import getDesignDataForExport from 'editor/src/store/design/selector/getDesignDataForExport';
import calculateDesignDataWarnings from 'editor/src/store/editorModules/warnings/utils/calculateDesignDataWarnings';
import { extendedWarningsToExportedWarnings } from 'editor/src/store/editorModules/warnings/utils/warningMapper';
import { ImageState } from 'editor/src/store/gallery/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const sendDesignDataPostMessage =
  (checkConditionsAndFillMissing = true) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const { images } = state.gallery;
    const addons = state.editorModules.addons.inUse;

    if (checkConditionsAndFillMissing) {
      if (images.find((image) => image.state !== ImageState.UPLOADED)) {
        // eslint-disable-next-line no-alert
        alert('Image upload in progress, please wait and try again later');
        return false;
      }
    }

    const extendedWarnings = state.design.designData ? calculateDesignDataWarnings(state.design.designData, state) : [];

    const designData = state.design.designData
      ? getDesignDataForExport(
          state.design.designData,
          images,
          addons,
          extendedWarningsToExportedWarnings(extendedWarnings),
        )
      : undefined;
    sendPostMessage('design.setDesignData', designData);
    return true;
  };

export default sendDesignDataPostMessage;

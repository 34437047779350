import React from 'react';
import { useTranslation } from 'react-i18next';

import { MediaText } from 'editor/src/store/design/types';

import { useFontSizeActions } from 'editor/src/util/useFontSizeActions';

import SliderWithInput from 'editor/src/component/SliderWithInput';

export const MIN_FONT_SIZE = 6;
export const MAX_FONT_SIZE = 240;

export interface Props {
  element: MediaText;
}

function FontSizeActionBar({ element }: Props) {
  const { onFontSizeChange, updateElementFontSize } = useFontSizeActions(element);
  const { t } = useTranslation();

  return (
    <SliderWithInput
      inputClassName="cy-font-size-input"
      Label={t('editor-font-size')}
      onChange={onFontSizeChange}
      onAfterChange={updateElementFontSize}
      min={MIN_FONT_SIZE}
      max={MAX_FONT_SIZE}
      step={1}
      startPoint={0}
      value={element.extra.fontSize}
      position="twoRows"
    />
  );
}

export default React.memo(FontSizeActionBar);

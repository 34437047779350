import React from 'react';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import isTopMenuDropdownVisible from 'editor/src/store/app/selector/isTopMenuDropdownVisible';
import { TopMenuDropdown } from 'editor/src/store/app/types';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getCurrentFontStylesProperty from 'editor/src/store/fonts/selector/getCurrentFontStylesProperty';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { MenuItemProps } from 'editor/src/component/Menu/type';
import MenuButton, { Props as MenuProps } from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';

import TextAlignIcon from './TextAlignIcon';

const MenuAlignButton = MenuButton as React.FC<MenuProps<{ align: string }>>;

type Props = Pick<MenuItemProps, 'mode' | 'close' | 'isMobile'>;

function ButtonTextAlignment({ mode, close, isMobile }: Props) {
  const currentTextAlign = useSelector((state) => getCurrentFontStylesProperty(state, 'textAlign'));
  const isActive = useSelector((state) =>
    isMobile
      ? isSidebarTabActive(state, TAB_NAMES.TEXT_ALIGN)
      : isTopMenuDropdownVisible(state, TopMenuDropdown.TextAlign),
  );
  const dispatch = useDispatch();

  const handleClick = () => {
    if (isMobile) {
      if (isActive) {
        dispatch(setSidebarActiveTabOperation(-1));
      } else {
        dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.TEXT_ALIGN));
      }
    } else {
      dispatch(setActiveTopMenuDropdownOperation(TopMenuDropdown.TextAlign));
      close?.();
    }
  };

  return (
    <MenuAlignButton
      Icon={TextAlignIcon}
      iconProps={{ align: currentTextAlign }}
      on={isActive}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-text-alignment"
      className="cy-button-text-alignment"
    />
  );
}

export default React.memo(ButtonTextAlignment);

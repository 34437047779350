import cn from 'classnames';
import React from 'react';

import { TopMenuDropdown } from 'editor/src/store/app/types';
import { useSelector } from 'editor/src/store/hooks';

import isElementWithSpotFinishingType from 'editor/src/util/design/isElementWithSpotFinishingType';

import IconPosition from 'editor/src/component/Icon/IconPosition';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import ButtonWithDropdownNotWrapped from 'editor/src/component/TopMenuDesktop/buttons/ButtonWithDropdownNotWrapped';
import MenuDropDownButton from 'editor/src/component/TopMenuDesktop/buttons/MenuDropDownButton';
import SplitterVertical from 'editor/src/component/TopMenuDesktop/buttons/SplitterHorizontal';

import AlignBottomButton from './AlignBottomButton';
import AlignCenterButton from './AlignCenterButton';
import AlignLeftButton from './AlignLeftButton';
import AlignMiddleButton from './AlignMiddleButton';
import AlignRightButton from './AlignRightButton';
import AlignTopButton from './AlignTopButton';
import DistributeBackwardButton from './DistributeBackwardButton';
import DistributeForwardButton from './DistributeForwardButton';
import DistributeToBackButton from './DistributeToBackButton';
import DistributeToFrontButton from './DistributeToFrontButton';

import styles from './index.module.scss';

function ButtonPositionDropDown({ mode, context }: MenuItemProps) {
  const { isSelectionLocked, structureIndexes } = context;
  const structureIndex = structureIndexes[0];

  const distributionActionsDisabled = useSelector((state) => {
    const spread = state.design?.designData?.spreads[structureIndex.spreadIndex];
    const element = spread?.pages[structureIndex.pageIndex]?.groups?.media?.[structureIndex.elementIndex];

    return element && isElementWithSpotFinishingType(element);
  });

  return (
    <ButtonWithDropdownNotWrapped
      dropDownName={TopMenuDropdown.Position}
      buttonContent={<MenuDropDownButton Icon={IconPosition} mode={mode} i18nKey="editor-position" />}
      buttonExtraClass={cn('cy-button-position', styles.button)}
      dropDownContent={
        <div className={styles.dropdown}>
          {!isSelectionLocked && (
            <>
              <div className={styles.half}>
                <AlignLeftButton structureIndexes={structureIndexes} />
                <AlignCenterButton structureIndexes={structureIndexes} />
                <AlignRightButton structureIndexes={structureIndexes} />
              </div>
              <div className={styles.half}>
                <AlignTopButton structureIndexes={structureIndexes} />
                <AlignMiddleButton structureIndexes={structureIndexes} />
                <AlignBottomButton structureIndexes={structureIndexes} />
              </div>
              <SplitterVertical />
            </>
          )}
          <div className={styles.half}>
            <DistributeToFrontButton structureIndexes={structureIndexes} disabled={distributionActionsDisabled} />
            <DistributeForwardButton structureIndexes={structureIndexes} disabled={distributionActionsDisabled} />
          </div>
          <div className={styles.half}>
            <DistributeToBackButton structureIndexes={structureIndexes} disabled={distributionActionsDisabled} />
            <DistributeBackwardButton structureIndexes={structureIndexes} disabled={distributionActionsDisabled} />
          </div>
        </div>
      }
    />
  );
}

export default React.memo(ButtonPositionDropDown);

import { PayloadAction } from '@reduxjs/toolkit';

import {
  AppliedFilters,
  DataState,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const getNotEmptyFilters = (appliedFilters: AppliedFilters): AppliedFilters => {
  const clonedFilters = { ...appliedFilters };
  return Object.keys(clonedFilters).reduce<AppliedFilters>((acc, key) => {
    const value = clonedFilters[key];
    if (value === undefined || value?.length === 0) {
      return acc;
    }

    acc[key] = Array.isArray(value) ? value.slice() : value;
    return acc;
  }, {});
};

const setActiveFiltersReducer = (state: DataState, { payload }: PayloadAction<AppliedFilters | undefined>) => {
  state.activeFilters = payload === undefined ? payload : getNotEmptyFilters(payload);
};

export default setActiveFiltersReducer;

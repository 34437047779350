/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconFit({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 5.8125H1V18.1875H17V5.8125Z" strokeWidth="0.5" strokeLinecap="round" />
        <path d="M17 1H1V23H17V1Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export default IconFit;

import { captureException } from '@sentry/react';

import sendDesignDataPostMessage from 'editor/src/store/app/operation/sendDesignDataPostMessage';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getDesignData from 'editor/src/store/design/selector/getDesignData';
import { setDesignDataAction } from 'editor/src/store/design/slice';
import { DesignData } from 'editor/src/store/design/types';
import removeAllSelectedMediaElementsOperation from 'editor/src/store/editor/operation/removeAllSelectedMediaElementsOperation';
import stopCropModeOperation from 'editor/src/store/editor/operation/stopCropModeOperation';
import { setCurrentSpreadIndexAction } from 'editor/src/store/editor/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import { logEvent, setProductUid } from 'editor/src/amplitude';
import getDesignDataStats from 'editor/src/amplitude/getDesignDataStats';
import reflectDesignData from 'editor/src/util/reflectDesignData';
import getReflectContext from 'editor/src/util/reflectDesignData/getReflectContext';

const changeFormatOperation =
  (newProductUid: string, designTemplate?: DesignData, tryToMatchLayoutBySlotNumbersIgnoreProductUidMatch = false) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const designData = getDesignData(state);

    if (!designTemplate || !designData) {
      return false;
    }

    // so a bug on sentry where there is no spread on this object..
    if (!designTemplate?.spreads) {
      captureException(new Error('No spread in the template data'), {
        extra: {
          newProductUid,
          designTemplate,
          currentProductUid: designData.product_uid,
        },
      });
      return false;
    }

    if (!tryToMatchLayoutBySlotNumbersIgnoreProductUidMatch && designData.product_uid === newProductUid) {
      return false;
    }

    batch(() => {
      dispatch(stopCropModeOperation());
      dispatch(removeAllSelectedMediaElementsOperation());
      const newTemplate = reflectDesignData(designData, designTemplate, getReflectContext(state));
      dispatch(setDesignDataAction(newTemplate));
      dispatch(setCurrentSpreadIndexAction(0));
      dispatch(sendDesignDataPostMessage(false));
    });

    setProductUid(newProductUid);
    // eslint-disable-next-line no-console
    console.log(`Product uid: ${designData.product_uid}`);
    logEvent('design changed', getDesignDataStats(getState()));
    return true;
  };

export default changeFormatOperation;

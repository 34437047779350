import hideLoaderOperation from 'editor/src/store/app/operation/hideLoaderOperation';
import { LoaderType } from 'editor/src/store/app/types';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import resetSessionImageOperation from 'editor/src/store/gallery/operation/resetSessionImageOperation';
import { replaceImageAction, replaceSessionImageAction } from 'editor/src/store/gallery/slice';
import { ImageState, UploadedAsset } from 'editor/src/store/gallery/types';
import getMimeTypeEnum from 'editor/src/store/gallery/utils/getMimeTypeEnum';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import isWebPSupported from 'editor/src/util/isWebPSupported';

import checkToResizeProductOperation from './checkToResizeProductOperation';
import replaceImageAfterUploadOperation from './replaceImageAfterUploadOperation';

const pluginAssetUploadedOperation = (data: UploadedAsset) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  batch(() => {
    const { pluginAssetId, assetId, url, thumbnailUrl, mimeType, colors, quantizedColors, width, height, label } = data;
    let state = getState();
    const image = state.gallery.images.find((image) => image.id === pluginAssetId);
    if (!image) {
      return;
    }

    const previousDimensions = { width: image.width, height: image.height };
    const uniqColors = new Set([...(colors?.fill ?? []), ...(colors?.stroke ?? [])]);
    dispatch(
      replaceImageAction({
        prevImageId: pluginAssetId,
        image: {
          ...image,
          state: ImageState.UPLOADED,
          id: assetId,
          url,
          thumbnailUrl: isWebPSupported ? thumbnailUrl || url : url,
          type: getMimeTypeEnum(mimeType),
          colors: uniqColors.size > 0 ? [...uniqColors] : undefined,
          quantizedColors,
          width,
          height,
          label,
        },
      }),
    );

    dispatch(replaceImageAfterUploadOperation(pluginAssetId, assetId, url, { width, height }, previousDimensions));

    const dimensionChanged = previousDimensions.width !== width || previousDimensions.height !== height;
    if (dimensionChanged) {
      dispatch(checkToResizeProductOperation());
    }

    dispatch(
      replaceSessionImageAction({
        prevImageId: pluginAssetId,
        currentImageId: assetId,
      }),
    );
    state = getState();
    const uploadingImagesCount = state.gallery.images.filter(
      (image) =>
        state.gallery.sessionImages.includes(image.id) &&
        (image.state === ImageState.LOCAL_PREVIEW || image.state === ImageState.REMOTE_PREVIEW),
    ).length;
    if (uploadingImagesCount === 0) {
      dispatch(resetSessionImageOperation());
    }

    dispatch(hideLoaderOperation(LoaderType.EditorArea));
  });
};

export default pluginAssetUploadedOperation;

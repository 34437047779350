import cn from 'classnames';
import React, { useCallback } from 'react';

import { TopMenuDropdown } from 'editor/src/store/app/types';
import updatePlaceholderFitOperation from 'editor/src/store/design/operation/updatePlaceholderFitOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { AnchoringX, AnchoringY } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconAlignBottom from 'editor/src/component/Icon/IconAlignBottom';
import IconAlignCenter from 'editor/src/component/Icon/IconAlignCenter';
import IconAlignLeft from 'editor/src/component/Icon/IconAlignLeft';
import IconAlignMiddle from 'editor/src/component/Icon/IconAlignMiddle';
import IconAlignRight from 'editor/src/component/Icon/IconAlignRight';
import IconAlignTop from 'editor/src/component/Icon/IconAlignTop';
import IconPosition from 'editor/src/component/Icon/IconPosition';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import SwitchControl from 'editor/src/component/SwitchControl';
import ButtonWithDropdownNotWrapped from 'editor/src/component/TopMenuDesktop/buttons/ButtonWithDropdownNotWrapped';
import MenuDropDownButton from 'editor/src/component/TopMenuDesktop/buttons/MenuDropDownButton';
import SplitterVertical from 'editor/src/component/TopMenuDesktop/buttons/SplitterHorizontal';

import AnchorActionButton from './AnchorActionButton';

import styles from './index.module.scss';

function ButtonProductFittingDropDown({ mode, context }: MenuItemProps) {
  const { structureIndexes } = context;
  const element = useSelector((state) => getMediaElement(state, structureIndexes[0]));
  const dispatch = useDispatch();

  const onFitSwitch = useCallback((status: boolean) => {
    dispatch(updatePlaceholderFitOperation(status));
  }, []);

  if (!element || !isMediaMockupPlaceholder(element)) {
    return null;
  }

  return (
    <ButtonWithDropdownNotWrapped
      dropDownName={TopMenuDropdown.ProductFitting}
      buttonContent={<MenuDropDownButton Icon={IconPosition} mode={mode} i18nKey="Product fitting" />}
      buttonExtraClass={cn('cy-button-product-fitting', styles.button)}
      dropDownContent={
        <div className={cn(styles.dropdown, 'cy-product-fitting')}>
          <SwitchControl
            className="cy-switch-area-fit"
            title="Fit product in area"
            on={!!element.area_fit}
            onSwitch={onFitSwitch}
          />
          <SplitterVertical />
          <div className={styles.title}>Anchor to:</div>
          <div className={styles.alignments}>
            <div className={styles.half}>
              <AnchorActionButton
                on={element.anchoring_x === AnchoringX.Left}
                icon={<IconAlignLeft />}
                anchorTo="left"
              />
              <AnchorActionButton
                on={!element.anchoring_x || element.anchoring_x === AnchoringX.Center}
                icon={<IconAlignCenter />}
                anchorTo="center"
              />
              <AnchorActionButton
                on={element.anchoring_x === AnchoringX.Right}
                icon={<IconAlignRight />}
                anchorTo="right"
              />
            </div>
            <div className={styles.half}>
              <AnchorActionButton on={element.anchoring_y === AnchoringY.Top} icon={<IconAlignTop />} anchorTo="top" />
              <AnchorActionButton
                on={!element.anchoring_y || element.anchoring_y === AnchoringY.Middle}
                icon={<IconAlignMiddle />}
                anchorTo="middle"
              />
              <AnchorActionButton
                on={element.anchoring_y === AnchoringY.Bottom}
                icon={<IconAlignBottom />}
                anchorTo="bottom"
              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default React.memo(ButtonProductFittingDropDown);

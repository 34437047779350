import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

type InputAttr = React.InputHTMLAttributes<HTMLInputElement>;

interface Props {
  value: InputAttr['value'];
  onChange?: InputAttr['onChange'];
  onBlur?: InputAttr['onBlur'];
  onKeyDown?: InputAttr['onKeyDown'];
  type: InputAttr['type'];
  disabled?: boolean;
  label?: string;
  className?: string;
  placeholder?: string;
}

function Input({ label, disabled, className, value, onChange, onBlur, onKeyDown, type, placeholder }: Props) {
  return (
    <div className={cn(className, { [styles.inputWrap]: true })}>
      <input
        className={cn(styles.input, {
          [styles.disabled]: disabled,
          [styles.withLabel]: label,
        })}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
      />
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
}

export default Input;

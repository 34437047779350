import React from 'react';

import { MediaElement } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';

import AddonLayerItem from './AddonLayerItem';
import GridLayerItem from './GridLayerItem';
import LineLayerItem from './LineLayerItem';
import PlaceholderLayerItem from './PlaceholderLayerItem';
import RectangleLayerItem from './RectangleLayerItem';

interface Props {
  element: MediaElement;
}

function LayerItem({ element }: Props) {
  switch (element.type) {
    case 'addon':
      return <AddonLayerItem element={element} />;
    case 'image':
      if (isMediaMockupPlaceholder(element)) {
        return <PlaceholderLayerItem element={element} />;
      }

      // return null for image element as it should be handled manually by parent component
      return null;
    case 'text':
      // return null for text element as it should be handled manually by parent component
      return null;
    case 'grid':
      return <GridLayerItem />;
    case 'line':
      return <LineLayerItem element={element} />;
    case 'rectangle':
      return <RectangleLayerItem element={element} />;
    default:
      return null;
  }
}

export default React.memo(LayerItem);

import loadImageDimensions from 'editor/src/util/loadImageDimensions';

import {
  Element,
  ELEMENT_TYPE,
  ResultsData,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const mapResultsData = async (data: ResultsData): Promise<void> => {
  data.elements = await getElements(data.elements);
};

const getElements = async <E extends Element>(elements: E[]): Promise<E[]> => {
  const dimensions = await getDimensions(elements);
  return Promise.all(
    elements.map(async (element, i) => {
      if (element.type === ELEMENT_TYPE.IMAGE) {
        Object.assign(element, dimensions[i]);
      } else {
        element.children = await getElements(element.children);
      }
      return element;
    }),
  );
};

const getDimensions = (elements: Element[]): Promise<({ width: number; height: number } | undefined)[]> =>
  Promise.all(
    elements.map(async (element) => {
      if (element.type === ELEMENT_TYPE.GROUP) {
        return undefined;
      }
      if (element.width && element.height) {
        return { width: element.width, height: element.height };
      }
      try {
        return await loadImageDimensions(element.thumbnail, undefined, {
          executor: 'mapResultsData',
          element,
        });
      } catch {
        /* */
      }

      return undefined;
    }),
  );

export default mapResultsData;

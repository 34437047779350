import React, { useCallback, useState } from 'react';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import applyOrFetchSceneStructureOperation from 'editor/src/store/mockup/operation/applyOrFetchSceneStructureOperation';
import getSelectedSceneId from 'editor/src/store/mockup/selector/getSelectedSceneId';
import { Scene } from 'editor/src/store/mockup/types';

import GroupedScenesContent from './GroupedScenesContent';
import ScenesCategoryContent from './ScenesCategoryContent';

function SceneTabContent() {
  const scenesCategories = useSelector((state) => state.mockup.scenes.categories);
  const selectedSceneId = useSelector(getSelectedSceneId);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(-1);
  const dispatch = useDispatch();

  const onSceneSelect = useCallback((scene: Scene) => {
    batch(() => {
      dispatch(saveUndoRedoStateOperation('Apply Scene'));
      dispatch(applyOrFetchSceneStructureOperation(scene.id));
    });
  }, []);

  const goToMainTab = useCallback(() => setActiveCategoryIndex(-1), []);

  const activeCategory = scenesCategories[activeCategoryIndex];

  if (activeCategory) {
    return (
      <ScenesCategoryContent
        category={activeCategory}
        selectedSceneId={selectedSceneId}
        onSceneSelect={onSceneSelect}
        back={goToMainTab}
      />
    );
  }

  return (
    <GroupedScenesContent
      scenesCategories={scenesCategories}
      selectedSceneId={selectedSceneId}
      onSceneSelect={onSceneSelect}
      openCategory={setActiveCategoryIndex}
    />
  );
}

export default React.memo(SceneTabContent);

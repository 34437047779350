import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import requestAddonsOperation from 'editor/src/store/editorModules/addons/operation/requestAddonsOperation';
import { Addon, AddonCategory, AddonType } from 'editor/src/store/editorModules/addons/types';
import { useDispatch } from 'editor/src/store/hooks';

import AddonsListItem, {
  VectorOrBitmap,
} from 'editor/src/component/DesktopSidebar/TabContents/AddonsTabContent/AddonsList/AddonListItem';
import { useObserverCategory } from 'editor/src/component/DesktopSidebar/TabContents/AddonsTabContent/AddonsList/useCategoriesObserver';

import styles from './index.module.scss';

interface Props {
  category: AddonCategory;
  isMobile: boolean;
  addAddon(addonId: string): void;
  addonInUse: Map<Addon, number>;
  observer: IntersectionObserver | null;
  visible: boolean;
  index: number;
  showPremiumIcon: boolean;
  pluginDisabled: boolean;
}

function AddonsListItemCategory({
  category,
  isMobile,
  addAddon,
  addonInUse,
  observer,
  visible,
  index,
  showPremiumIcon,
  pluginDisabled,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSeeAllClick = () => {
    dispatch(requestAddonsOperation(category.id));
  };

  const targetDivRef = useObserverCategory(observer, index);

  return (
    <div className={styles.addonsListItemCategory} ref={targetDivRef}>
      <div className={styles.topBar}>
        <h3 className={styles.header}>
          {category.name}
          {category.count ? ` (${category.count})` : ''}
        </h3>
        <div className={cn(styles.seeAll, 'cy-addons-see-all')} onClick={handleSeeAllClick}>
          {t('editor-see-all')}
        </div>
      </div>
      <div className={styles.list}>
        {category?.elements.map((addon) => {
          switch (addon.type) {
            case AddonType.ADDON_VECTOR:
            case AddonType.ADDON_BITMAP:
              return (
                <AddonsListItem
                  visible={visible}
                  addAddon={addAddon}
                  isMobile={isMobile}
                  addon={addon as VectorOrBitmap}
                  key={addon.id}
                  usage={addonInUse.get(addon) ?? 0}
                  showPremiumIcon={showPremiumIcon}
                  pluginDisabled={pluginDisabled}
                />
              );
            case AddonType.ADDON_DESIGN:
              return null;
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
}

export default React.memo(AddonsListItemCategory);

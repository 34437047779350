import cn from 'classnames';
import React from 'react';

import { FormatOption } from 'editor/src/store/editorModules/formats/types';

import ColorDot from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/elements/ColorElement/ColorDot';

import styles from './index.module.scss';

interface Props {
  option: FormatOption;
  selectOption: (callback: FormatOption['callback']) => void;
}

function ColorOption({ option, selectOption }: Props) {
  const onClick = () => {
    selectOption(option.callback);
  };

  return (
    <div className={cn(styles.colorOption, 'cy-format-option')}>
      <ColorDot
        texture={option.texture}
        colorHex={option.hexColor}
        toggle={onClick}
        disabled={option.disabled}
        selected={!!option.active}
      />
    </div>
  );
}

export default React.memo(ColorOption);

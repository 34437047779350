/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconThreadColor({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M5.02088 4.1028C5.10376 3.74956 5.41713 3.5 5.77785 3.5H18.2222C18.5829 3.5 18.8962 3.74956 18.9791 4.1028C19.062 4.45605 18.8926 4.82028 18.57 4.9826L17.0144 5.7652C16.9064 5.81954 16.7874 5.84783 16.6666 5.84783H7.33338C7.21264 5.84783 7.09355 5.81954 6.98555 5.7652L5.43002 4.9826C5.10739 4.82028 4.93801 4.45605 5.02088 4.1028Z"
          fill="#212121"
        />
        <path
          d="M18.9791 20.8972C18.8962 21.2504 18.5829 21.5 18.2222 21.5L5.77785 21.5C5.41713 21.5 5.10376 21.2504 5.02088 20.8972C4.93801 20.544 5.10739 20.1797 5.43002 20.0174L6.98556 19.2348C7.09355 19.1805 7.21264 19.1522 7.33338 19.1522L16.6666 19.1522C16.7874 19.1522 16.9064 19.1805 17.0144 19.2348L18.57 20.0174C18.8926 20.1797 19.062 20.5439 18.9791 20.8972Z"
          fill="#212121"
        />
        <path
          d="M16.9126 11.6773C17.3201 11.5406 17.5403 11.0974 17.4045 10.6873C17.2686 10.2773 16.8282 10.0557 16.4207 10.1924L7.08743 13.3228C6.67993 13.4595 6.45969 13.9027 6.59553 14.3127C6.73136 14.7228 7.17183 14.9444 7.57934 14.8077L16.9126 11.6773Z"
          fill="#212121"
        />
        <path
          d="M17.3436 7.57915C17.3882 7.65829 17.4195 7.74603 17.4345 7.83928C17.4494 7.93144 17.4475 8.02357 17.4306 8.11192C17.4095 8.22372 17.3647 8.32708 17.3017 8.41655C17.2342 8.51283 17.1446 8.59443 17.0374 8.65277C16.9929 8.67713 16.9457 8.69724 16.8964 8.71255L7.57934 11.8376C7.17183 11.9742 6.73136 11.7526 6.59553 11.3426C6.45969 10.9325 6.67993 10.4893 7.08743 10.3527L11.8738 8.74726H7.33339C6.90384 8.74726 6.55562 8.39687 6.55562 7.96465C6.55562 7.53243 6.90384 7.18204 7.33339 7.18204H16.6496C16.7008 7.1809 16.7515 7.1849 16.8011 7.1937C16.9227 7.21502 17.0345 7.26474 17.1297 7.33581C17.2156 7.39978 17.2891 7.48198 17.3436 7.57915Z"
          fill="#212121"
        />
        <path
          d="M17.4045 13.7024C17.5403 14.1124 17.3201 14.5556 16.9126 14.6923L12.1263 16.2976H16.6666C17.0962 16.2976 17.4444 16.648 17.4444 17.0802C17.4444 17.5125 17.0962 17.8629 16.6666 17.8629H7.3522C7.2935 17.8643 7.2354 17.859 7.17891 17.8474C7.07251 17.8258 6.97397 17.7824 6.88812 17.722C6.79167 17.6544 6.7098 17.5644 6.65117 17.4564C6.60951 17.38 6.58014 17.296 6.56574 17.2068C6.55078 17.1157 6.55234 17.0245 6.56861 16.937C6.59033 16.8189 6.63842 16.71 6.70651 16.6169C6.76966 16.5302 6.85094 16.4561 6.94709 16.4008C6.99664 16.3723 7.04962 16.349 7.10525 16.3318L16.4207 13.2074C16.8282 13.0707 17.2686 13.2923 17.4045 13.7024Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default React.memo(IconThreadColor);

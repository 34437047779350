import { batch } from 'editor/src/store/batchedSubscribeEnhancer';

import { ThunkDispatch } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/index';
import getRequestVersion from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/selector/getRequestVersion';
import {
  setResultsAction,
  setRequestVersionAction,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/slice';
import {
  ResultsData,
  ContentPluginRootState,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const appendResultsOperation =
  (data: ResultsData) => (dispatch: ThunkDispatch, getState: () => ContentPluginRootState) => {
    const state = getState();
    const oldResults = state.data.results;
    const requestVersion = getRequestVersion(state);

    if (requestVersion === data.resultsVersion || data.resultsVersion === 0) {
      batch(() => {
        if (data.resultsVersion === 0) {
          dispatch(setRequestVersionAction(0));
        }
        const newData = {
          ...data,
          elements: [...(oldResults?.elements || []), ...data.elements],
        };
        dispatch(setResultsAction(newData));
      });
    }
  };

export default appendResultsOperation;

import cn from 'classnames';
import React from 'react';

import { MenuContext } from 'editor/src/component/Menu/type';
import ButtonCropDone from 'editor/src/component/TopMenuDesktop/buttons/ButtonCropDone';
import ButtonCropRotate90 from 'editor/src/component/TopMenuDesktop/buttons/ButtonCropRotate90';
import ButtonCropZoom from 'editor/src/component/TopMenuDesktop/buttons/ButtonCropZoom';
import SplitterVertical from 'editor/src/component/TopMenuDesktop/buttons/SplitterVertical';

import styles from './index.module.scss';

interface Props {
  menuContext: MenuContext;
}

function CropControls({ menuContext }: Props) {
  return (
    <div className={cn(styles.cropControls, 'cy-controls-crop-mode')}>
      <ButtonCropDone mode="label-icon" isMobile />
      <SplitterVertical />
      <ButtonCropZoom isMobile mode="icon" context={menuContext} />
      <ButtonCropRotate90 mode="icon" context={menuContext} />
    </div>
  );
}

export default React.memo(CropControls);

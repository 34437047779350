import React from 'react';

import { MediaElement, StructureIndex } from 'editor/src/store/design/types';

import HorizontalScrollableArea from 'editor/src/component/BottomBarMobile/ActionBarSection/HorizontalScrollableArea';
import AlignBottomButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignBottomButton';
import AlignCenterButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignCenterButton';
import AlignLeftButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignLeftButton';
import AlignMiddleButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignMiddleButton';
import AlignRightButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignRightButton';
import AlignTopButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignTopButton';
import DistributeBackwardButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/DistributeBackwardButton';
import DistributeForwardButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/DistributeForwardButton';
import DistributeToBackButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/DistributeToBackButton';
import DistributeToFrontButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/DistributeToFrontButton';
import SplitterVertical from 'editor/src/component/TopMenuDesktop/buttons/SplitterHorizontal';

interface Props {
  structureIndexes: StructureIndex[];
  selectedElements: (MediaElement | undefined)[];
}

function ButtonPosition({ structureIndexes, selectedElements }: Props) {
  const isElementLocked = selectedElements ? Boolean(selectedElements[0]?.locked) : false;

  return (
    <>
      {!isElementLocked && (
        <>
          <HorizontalScrollableArea>
            <AlignLeftButton structureIndexes={structureIndexes} />
            <AlignCenterButton structureIndexes={structureIndexes} />
            <AlignRightButton structureIndexes={structureIndexes} />
            <AlignTopButton structureIndexes={structureIndexes} />
            <AlignMiddleButton structureIndexes={structureIndexes} />
            <AlignBottomButton structureIndexes={structureIndexes} />
          </HorizontalScrollableArea>
          <SplitterVertical />
        </>
      )}
      <HorizontalScrollableArea>
        <DistributeToFrontButton structureIndexes={structureIndexes} />
        <DistributeForwardButton structureIndexes={structureIndexes} />
        <DistributeToBackButton structureIndexes={structureIndexes} />
        <DistributeBackwardButton structureIndexes={structureIndexes} />
      </HorizontalScrollableArea>
    </>
  );
}

export default React.memo(ButtonPosition);

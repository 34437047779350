import React from 'react';

import styles from './index.module.scss';

export const CONTAINER_ID = 'abstract-popup-container-id';

function AbstractPopupContainer() {
  return <div id={CONTAINER_ID} className={styles.AbstractPopupContainer} />;
}

export default AbstractPopupContainer;

/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

function IconElevateSilver({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={cn(className, 'cy-icon-premium', 'cy-icon-elevate-silver')}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C10.3208 2 10.6083 2.19781 10.723 2.49737L12.8307 8.00237L16.6605 3.91037C16.8863 3.66918 17.2402 3.59779 17.5418 3.73263C17.8434 3.86746 18.0262 4.17881 17.997 4.50787L17.1469 14.0828C17.1114 14.4823 16.7767 14.7885 16.3757 14.7885H3.6243C3.22326 14.7885 2.88861 14.4823 2.85314 14.0828L2.00305 4.50787C1.97384 4.17881 2.15664 3.86746 2.45822 3.73263C2.7598 3.59779 3.11372 3.66918 3.33945 3.91037L7.16925 8.00237L9.27699 2.49737C9.39169 2.19781 9.67923 2 10 2ZM10 4.93937L8.17273 9.71184C8.07732 9.96103 7.86032 10.1435 7.59846 10.1948C7.33661 10.2461 7.06681 10.1589 6.88448 9.96405L3.74403 6.6086L4.3328 13.2401H15.6672L16.256 6.6086L13.1155 9.96405C12.9332 10.1589 12.6634 10.2461 12.4015 10.1948C12.1397 10.1435 11.9227 9.96103 11.8273 9.71184L10 4.93937ZM2.80298 17.2258C2.80298 16.7982 3.1496 16.4516 3.57717 16.4516H16.423C16.8506 16.4516 17.1972 16.7982 17.1972 17.2258C17.1972 17.6534 16.8506 18 16.423 18H3.57717C3.1496 18 2.80298 17.6534 2.80298 17.2258Z"
        fill="#BDBDBD"
      />
    </svg>
  );
}

export default React.memo(IconElevateSilver);

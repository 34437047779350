import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { ElementAddress, MediaElement } from 'editor/src/store/design/types';
import getContentAreaFromSpread from 'editor/src/store/design/util/getContentAreaFromSpread';
import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import getFocusedContent from 'editor/src/store/editor/selector/getFocusedContent';
import type { Thunk } from 'editor/src/store/hooks';

import { elementUuids } from 'editor/src/util/uuids';

import addMediaElementOperation from './addMediaElementOperation';

const copyMediaElementOperation =
  (elementAddress: ElementAddress, destinationAddress?: { spreadIndex: number; pageIndex?: number }): Thunk =>
  (dispatch, getState, { i18n }) => {
    const state = getState();
    const element = getMediaElement(state, elementAddress);

    if (!element) {
      return;
    }

    const uuid = elementUuids.generate();
    const newElement: MediaElement = {
      ...element,
      uuid,
      name: i18n.t('editor-copy-of', { name: element.name }),
    };

    const destinationSpreadIndex = destinationAddress?.spreadIndex ?? elementAddress.spreadIndex;
    const destinationPageIndex = destinationAddress?.pageIndex ?? elementAddress.pageIndex;
    const focusedContent = getFocusedContent(state);
    const elementContentArea = getContentAreaFromSpread(
      state.design.designData?.spreads[elementAddress.spreadIndex],
      focusedContent,
    );
    const destinationContentArea = getContentAreaFromSpread(
      state.design.designData?.spreads[destinationSpreadIndex],
      focusedContent,
    );

    if (!elementContentArea || !destinationContentArea) {
      return;
    }

    let positioningType: 'shift' | 'fit' | 'same';
    if (destinationAddress && destinationSpreadIndex !== elementAddress.spreadIndex) {
      const areEqualAreas =
        elementContentArea.width === destinationContentArea.width &&
        elementContentArea.height === destinationContentArea.height &&
        elementContentArea.left === destinationContentArea.left &&
        elementContentArea.top === destinationContentArea.top;

      positioningType = areEqualAreas ? 'same' : 'fit';
    } else {
      positioningType = 'shift';
    }

    if (positioningType === 'shift') {
      if (newElement.type === 'line') {
        newElement.x1 += 10;
        newElement.x2 += 10;
        newElement.y1 += 10;
        newElement.y2 += 10;
      } else {
        newElement.x += 10;
        newElement.y += 10;
      }
    } else if (positioningType === 'fit') {
      if (newElement.type === 'line') {
        newElement.x1 = destinationContentArea.left;
        newElement.x2 = destinationContentArea.left + destinationContentArea.width;
        newElement.y1 = destinationContentArea.top + destinationContentArea.height / 2;
        newElement.y2 = destinationContentArea.top + destinationContentArea.height / 2;
      } else {
        const scale = Math.min(
          destinationContentArea.width / newElement.width,
          destinationContentArea.height / newElement.height,
        );
        newElement.width *= scale;
        newElement.height *= scale;
        if (newElement.width > newElement.height) {
          newElement.x = destinationContentArea.left;
          newElement.y = destinationContentArea.top + (destinationContentArea.height - newElement.height) / 2;
        } else {
          newElement.x = destinationContentArea.left + (destinationContentArea.width - newElement.width) / 2;
          newElement.y = destinationContentArea.top;
        }

        if (newElement.type === 'image' || newElement.type === 'addon') {
          newElement.pw *= scale;
          newElement.ph *= scale;
        }

        if (newElement.type === 'text') {
          newElement.extra = {
            ...newElement.extra,
            fontSize: newElement.extra.fontSize * scale,
          };
        }
      }
    }

    batch(() => {
      dispatch(addMediaElementOperation(destinationSpreadIndex, destinationPageIndex, newElement));
      dispatch(addSelectedMediaElementOperation(newElement.uuid));
    });
  };

export default copyMediaElementOperation;

import React from 'react';
import { useTranslation } from 'react-i18next';

import getImageProgressById from 'editor/src/store/gallery/selector/getImageProgressById';
import { useSelector } from 'editor/src/store/hooks';

import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  imageId: string;
}

function UploadingBadge({ imageId }: Props) {
  const progress = useSelector((state) => getImageProgressById(state, imageId));
  const { t } = useTranslation();
  if (progress !== 0 && !progress) {
    return null;
  }

  return (
    <WithTooltip overlay={t('editor-uploading-...')} className={styles.uploadingBadgeWrap}>
      <div className={styles.uploadingBadge}>
        {progress !== 1 ? (
          <div className={styles.progress} style={{ width: `${progress * 100}%` }} />
        ) : (
          <div className={styles.infiniteProgress} />
        )}
      </div>
    </WithTooltip>
  );
}

export default React.memo(UploadingBadge);

import cn from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';

import { CollectionAsset } from 'editor/src/store/collections/types';
import useCollectionFolder from 'editor/src/store/collections/utils/useCollectionFolder';
import updateCollectionImageOperation from 'editor/src/store/design/operation/updateCollectionImageOperation';
import { MediaImage, StructureIndex } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';
import {
  AdvancedPersonalisationElementData,
  CollectionDisplayOption,
} from 'editor/src/store/plugins/utils/advPersoUtils';

import useDragHandle from 'editor/src/component/ConditionGroupBuilder/ConditionElements/ReorganizableList/useDragHandle';
import useReorganizableListUpdate from 'editor/src/component/ConditionGroupBuilder/ConditionElements/ReorganizableList/useReorganizableListUpdate';
import useOverlay from 'editor/src/component/ConditionGroupBuilder/Overlay/useOverlay';
import ElementName from 'editor/src/component/PersonalizationContent/PersonalizationElements/ElementName';
import useFocusElementOnFlag from 'editor/src/component/PersonalizationContent/PersonalizationElements/useFocusElementOnFlag';

import PersonalisationCollectionDropdown from './PersonalisationCollectionDropdown';
import PersonalisationCollectionThumbnails from './PersonalisationCollectionThumbnails';

import styles from './index.module.scss';

interface Props {
  image: MediaImage;
  collectionInfo: AdvancedPersonalisationElementData;
  address: StructureIndex;
  isSelected: boolean;
  isBuilding?: boolean;
  className?: string;
}

function PersonalisationCollection({ image, collectionInfo, address, isSelected, isBuilding, className }: Props) {
  const folder = useCollectionFolder(collectionInfo.folderId);

  const dispatch = useDispatch();

  const selectAsset = useCallback(
    (assetId: string) => {
      const asset = folder?.assets.find((asset) => asset.id === assetId) as CollectionAsset | undefined;
      if (address && asset) {
        dispatch(updateCollectionImageOperation(address, assetId, asset.url, asset.width, asset.height));
      }
    },
    [address, folder],
  );

  const updateList = useReorganizableListUpdate();
  useEffect(() => updateList(), [folder?.assets, image.name, collectionInfo.displayOption]);

  const dragHandler = useDragHandle();

  let assetsElement: JSX.Element | null = null;
  if (folder) {
    switch (collectionInfo.displayOption) {
      case CollectionDisplayOption.Color:
        assetsElement = (
          <PersonalisationCollectionThumbnails
            display="color"
            image={image}
            folder={folder}
            selectAsset={selectAsset}
          />
        );
        break;
      case CollectionDisplayOption.Dropdown:
        assetsElement = <PersonalisationCollectionDropdown image={image} folder={folder} selectAsset={selectAsset} />;
        break;
      default:
        assetsElement = (
          <PersonalisationCollectionThumbnails
            display="thumbnail"
            image={image}
            folder={folder}
            selectAsset={selectAsset}
          />
        );
        break;
    }
  }

  const collectionEditorOverlay = useOverlay();
  const divRef = useRef<HTMLDivElement>(null);

  const active = collectionEditorOverlay.visible || isSelected;
  useFocusElementOnFlag(active, divRef);

  return (
    <div
      className={cn(className, styles.PersonalisationCollection, 'cy-personalization-collection', {
        [styles.building]: isBuilding,
        [styles.active]: active,
        [styles.static]: !isBuilding,
      })}
      onMouseDown={dragHandler.onMouseDown}
      ref={divRef}
    >
      <ElementName element={image} isBuilding={isBuilding} address={address} />
      <div
        className={cn(styles.assetElements, {
          [styles.isBuilding]: isBuilding,
        })}
      >
        {assetsElement}
      </div>
    </div>
  );
}

export default React.memo(PersonalisationCollection);

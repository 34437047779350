import isLocalStorageAccessible from 'editor/src/util/isLocalStorageAccessible';

import { BannerType } from './banner-type.enum';

const MAX_DISMISS_BANNER_COUNT = 3;
const MIN_DAYS_TO_PASS_TO_NEGLECT_BANNER_DISMISSAL = 2;
const MIN_EDITOR_VISITS_TO_NEGLECT_DISMISSAL = 2;

const getBannerDismissCounterKey = (bannerType: BannerType) => `${bannerType}_DISMISS_COUNTER`;
const getBannerDismissalTimestampKey = (bannerType: BannerType) => `${bannerType}_DISMISS_TIMESTAMP`;
const getEditorVisitsAfterBannerDismissalKey = (bannerType: BannerType) => `EDITOR_VISITS_AFTER_${bannerType}_DISMISS`;

const getBannerDismissedCount = (bannerType: BannerType) => {
  if (!isLocalStorageAccessible) {
    return 0;
  }
  const key = getBannerDismissCounterKey(bannerType);
  return Number(window.localStorage.getItem(key)) || 0; // Default to 0 if not found
};

export const isBannerDismissedEnoughToHideIt = (bannerType: BannerType) => {
  return getBannerDismissedCount(bannerType) >= MAX_DISMISS_BANNER_COUNT;
};

export const isBannerDismissalTimeAndVisitsPassed = (bannerType: BannerType) => {
  const dismissalTimestamp = getDismissalTimestamp(bannerType);
  const editorVisitsAfterDismissal = getEditorVisitsAfterBannerDismissal(bannerType);

  return !dismissalTimestamp && !editorVisitsAfterDismissal;
};

export const increaseBannerDismissedCounter = (bannerType: BannerType) => {
  if (!isLocalStorageAccessible) {
    return;
  }
  const currentCount = getBannerDismissedCount(bannerType);
  const key = getBannerDismissCounterKey(bannerType);
  window.localStorage.setItem(key, (currentCount + 1).toString());
};

export const setDismissalTimestamp = (bannerType: BannerType) => {
  if (!isLocalStorageAccessible) {
    return;
  }
  const currentCount = getBannerDismissedCount(bannerType);
  const key = getBannerDismissalTimestampKey(bannerType);

  if (currentCount >= MAX_DISMISS_BANNER_COUNT) {
    window.localStorage.removeItem(key);
    return;
  }

  window.localStorage.setItem(key, Date.now().toString());
};

export const getDismissalTimestamp = (bannerType: BannerType) => {
  if (!isLocalStorageAccessible) {
    return 0;
  }
  const key = getBannerDismissalTimestampKey(bannerType);
  return Number(window.localStorage.getItem(key));
};

export const getEditorVisitsAfterBannerDismissal = (bannerType: BannerType) => {
  if (!isLocalStorageAccessible) {
    return 0;
  }
  const key = getEditorVisitsAfterBannerDismissalKey(bannerType);
  return Number(window.localStorage.getItem(key));
};

export const increaseEditorVisitsAfterBannerDismissal = (bannerType: BannerType) => {
  if (!isLocalStorageAccessible) {
    return;
  }
  const key = getEditorVisitsAfterBannerDismissalKey(bannerType);
  const currentCount = Number(window.localStorage.getItem(key)) || 0;
  window.localStorage.setItem(key, (currentCount + 1).toString());
};

export const checkForPromotionBannersDismissalConditions = () => {
  const checkBanners = [
    BannerType.IMAGE_PERSONALIZATION_PROMOTION_BANNER,
    BannerType.TEXT_PERSONALIZATION_PROMOTION_BANNER,
  ];

  checkBanners.forEach((bannerType) => {
    if (!isLocalStorageAccessible) {
      return;
    }

    const dismissalTimestamp = getDismissalTimestamp(bannerType);

    if (dismissalTimestamp) {
      const currentTimestamp = Date.now();
      const daysPassed = Math.floor((currentTimestamp - dismissalTimestamp) / (1000 * 60 * 60 * 24));
      const editorVisitsAfterDismissal = getEditorVisitsAfterBannerDismissal(bannerType);

      // if 48 hrs passed and user visited editor at least 3 times after dismissal - reset the dismissal
      if (
        daysPassed >= MIN_DAYS_TO_PASS_TO_NEGLECT_BANNER_DISMISSAL &&
        editorVisitsAfterDismissal > MIN_EDITOR_VISITS_TO_NEGLECT_DISMISSAL
      ) {
        window.localStorage.removeItem(getBannerDismissalTimestampKey(bannerType));
        window.localStorage.removeItem(getEditorVisitsAfterBannerDismissalKey(bannerType));
      } else {
        increaseEditorVisitsAfterBannerDismissal(bannerType);
      }
    }
  });
};

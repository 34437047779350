/* eslint-disable react/jsx-props-no-spreading */
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonMode } from 'editor/src/component/Menu/type';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './MenuButton.module.scss';

export interface Props<IP> {
  Icon: React.FC<{ className?: string } & Omit<IP, 'className'>>;
  iconProps?: IP;
  i18nKey: string;
  tooltipStyle?: 'default' | 'rich';
  tooltipOverlay?: React.ReactNode;
  className?: string;
  mode: ButtonMode;
  on?: boolean;
}

function MenuDropDownButton<T>({
  Icon,
  on,
  i18nKey,
  mode,
  className,
  iconProps,
  tooltipStyle,
  tooltipOverlay,
}: Props<T>) {
  const { t } = useTranslation();

  const props = iconProps || ({} as T);

  if (mode === 'icon') {
    return (
      <WithTooltip
        disabled={on}
        className={cn(styles.button, styles[mode], className)}
        overlay={tooltipOverlay ?? t(i18nKey)}
        tooltipStyle={tooltipStyle}
      >
        <Icon className={styles.icon} {...props} />
      </WithTooltip>
    );
  }

  return (
    <div className={cn(styles.button, styles[mode], className)}>
      {mode === 'label-icon' && <Icon className={styles.icon} {...props} />}
      {(mode === 'label' || mode === 'label-icon') && <div className={styles.label}>{t(i18nKey)}</div>}
    </div>
  );
}

export default React.memo(MenuDropDownButton);

import { createSlice } from '@reduxjs/toolkit';

import addDesignTemplateReducer from './reducer/addDesignTemplateReducer';
import removeDesignTemplateReducer from './reducer/removeDesignTemplateReducer';
import setTemplatesReducer from './reducer/setTemplatesReducer';
import { TemplatesState } from './types';

export const templateInitialState: TemplatesState = {
  designTemplates: [],
};

export const slice = createSlice({
  name: 'template',
  initialState: templateInitialState,
  reducers: {
    setTemplatesAction: setTemplatesReducer,
    addDesignTemplateAction: addDesignTemplateReducer,
    removeDesignTemplateAction: removeDesignTemplateReducer,
  },
});

export const { setTemplatesAction, addDesignTemplateAction, removeDesignTemplateAction } = slice.actions;

export default slice.reducer;

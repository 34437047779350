import { fabric } from 'fabric';

import { MediaText } from 'editor/src/store/design/types';

import isShadowEmpty from 'editor/src/util/isShadowEmpty';

// as design data follow the SVG specification we arranged rates to make the condition
// `value * fontSize` works on preflight without additional conversions
const SVG_TO_CANVAS_BLUR = 4;

function getTextShadow(
  shadow: MediaText['extra']['shadow'],
  fontSize: number,
  mm2px: (size: number) => number,
): fabric.Shadow | undefined {
  if (!shadow || isShadowEmpty(shadow)) {
    return undefined;
  }

  return new fabric.Shadow({
    color: shadow.color,
    // use mm2px function to consider canvas size and multiply to fontSize to keep offset the same with the different font sizes
    offsetX: mm2px(shadow.offsetX * fontSize),
    offsetY: mm2px(shadow.offsetY * fontSize),
    blur: mm2px(shadow.blur * SVG_TO_CANVAS_BLUR * fontSize),
  });
}

export default getTextShadow;

import cn from 'classnames';
import React from 'react';

import Button from '../Button';
import IconBack from '../Icon/IconBack';
import IconCart from '../Icon/IconCart';
import ProductPrice from '../ProductPrice';

import styles from './index.module.scss';

interface Props {
  children?: React.ReactNode;
  onBackButtonClick: () => void;
  onCartButtonClick: () => void;
  productPrice: {
    price: string | undefined;
    originalPrice: string | undefined;
  };
}

function MobileHeader({ children, onBackButtonClick, onCartButtonClick, productPrice }: Props) {
  return (
    <div className={styles.header}>
      <Button
        className={cn(styles.mobileButton, styles.leftElementsWrapper, 'cy-header-back-button')}
        transparent
        rounded
        onClick={onBackButtonClick}
      >
        <IconBack className="backIcon" />
      </Button>

      <div className={styles.childrenWrapper}>{children}</div>

      <div className={styles.rightElementsWrapper}>
        {productPrice.price && <ProductPrice price={productPrice.price} originalPrice={productPrice.originalPrice} />}
        <Button
          className={cn(styles.mobileButton, 'cy-header-proceed-button')}
          primary
          rounded
          onClick={onCartButtonClick}
        >
          <IconCart className="cartIcon" />
        </Button>
      </div>
    </div>
  );
}

export default React.memo(MobileHeader);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import type { Thunk } from 'editor/src/store/hooks';
import { setMockupPreviewAction, setSingleOptionAction } from 'editor/src/store/mockup/slice';

import updatePlaceholderOperation from './updatePlaceholderOperation';

const setMockupSingleOptionOperation =
  (controlKey: string, value: string): Thunk =>
  (dispatch) => {
    batch(() => {
      dispatch(setSingleOptionAction({ key: controlKey, value }));
      dispatch(setMockupPreviewAction(undefined));
      dispatch(updatePlaceholderOperation());
    });
  };

export default setMockupSingleOptionOperation;

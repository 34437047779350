import { MediaUpdateActionName } from 'editor/src/store/design/operation/updateMediaElementOperation';

function getElementOperationByAction(action: fabric.Action | undefined): MediaUpdateActionName {
  if (!action) {
    return MediaUpdateActionName.UNKNOWN;
  }
  switch (action) {
    case 'drag':
      return MediaUpdateActionName.MOVED;
    case 'scaleX':
    case 'scaleY':
      return MediaUpdateActionName.CROPPED;
    case 'rotate':
      return MediaUpdateActionName.ROTATED;
    case 'scale':
      return MediaUpdateActionName.RESIZED;
    default:
      return MediaUpdateActionName.UNKNOWN;
  }
}

export default getElementOperationByAction;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconZoomOut({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M31.5 33.8333L22.1667 24.5C21.3333 25.1667 20.375 25.6944 19.2917 26.0833C18.2083 26.4722 17.0556 26.6667 15.8333 26.6667C12.8056 26.6667 10.2433 25.6183 8.14667 23.5217C6.04889 21.4239 5 18.8611 5 15.8333C5 12.8056 6.04889 10.2428 8.14667 8.145C10.2433 6.04833 12.8056 5 15.8333 5C18.8611 5 21.4239 6.04833 23.5217 8.145C25.6183 10.2428 26.6667 12.8056 26.6667 15.8333C26.6667 17.0556 26.4722 18.2083 26.0833 19.2917C25.6944 20.375 25.1667 21.3333 24.5 22.1667L33.875 31.5417C34.1806 31.8472 34.3267 32.2289 34.3133 32.6867C34.2989 33.1456 34.1389 33.5278 33.8333 33.8333C33.5278 34.1389 33.1389 34.2917 32.6667 34.2917C32.1944 34.2917 31.8056 34.1389 31.5 33.8333ZM15.8333 23.3333C17.9167 23.3333 19.6878 22.6044 21.1467 21.1467C22.6044 19.6878 23.3333 17.9167 23.3333 15.8333C23.3333 13.75 22.6044 11.9789 21.1467 10.52C19.6878 9.06222 17.9167 8.33333 15.8333 8.33333C13.75 8.33333 11.9789 9.06222 10.52 10.52C9.06222 11.9789 8.33333 13.75 8.33333 15.8333C8.33333 17.9167 9.06222 19.6878 10.52 21.1467C11.9789 22.6044 13.75 23.3333 15.8333 23.3333ZM13.2917 17.5C12.8194 17.5 12.4306 17.34 12.125 17.02C11.8194 16.7011 11.6667 16.3056 11.6667 15.8333C11.6667 15.3611 11.8261 14.965 12.145 14.645C12.465 14.3261 12.8611 14.1667 13.3333 14.1667H18.375C18.8472 14.1667 19.2361 14.3261 19.5417 14.645C19.8472 14.965 20 15.3611 20 15.8333C20 16.3056 19.8406 16.7011 19.5217 17.02C19.2017 17.34 18.8056 17.5 18.3333 17.5H13.2917Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconZoomOut;

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress } from 'editor/src/store/design/types';
import type { Thunk } from 'editor/src/store/hooks';

const flipImageXOperation =
  (elementAddress: ElementAddress): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const element = getMediaElement(state, elementAddress);

    if (element && (element.type === 'image' || element.type === 'addon')) {
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: { flipX: !element.flipX || undefined },
        }),
      );
    }
  };

export default flipImageXOperation;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { DesignData, Dimensions } from 'editor/src/store/design/types';
import type { Thunk } from 'editor/src/store/hooks';
import { saveMockupStructureAction } from 'editor/src/store/mockup/slice';
import getMockupId from 'editor/src/store/mockup/utils/getMockupId';

import applyMockupStructureOperation from './applyMockupStructureOperation';
import { mockupFetching } from './applyOrFetchMockupStructureOperation';

const loadMockupOperation =
  ({ size, structure }: { size: Dimensions; structure: DesignData }): Thunk =>
  (dispatch) => {
    batch(() => {
      const sizeId = getMockupId(size);
      mockupFetching.delete(sizeId);
      dispatch(saveMockupStructureAction({ sizeId, structure }));
      dispatch(applyMockupStructureOperation(structure));
    });
  };

export default loadMockupOperation;

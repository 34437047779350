import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getMultiOption from 'editor/src/store/variants/helpers/getMultiOption';
import { ProductControlMulti } from 'editor/src/store/variants/types';

import updateMultiOptionsOperation from './updateMultiOptionsOperation';

const deselectAllMultiOptionsOperation =
  (productControl: ProductControlMulti) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const variantState = state.variants;
    const { configuration, selectedMultiOptions, product, selectedSingleOptions } = variantState;

    if (configuration.singleSelection) {
      throw new Error('singleSelection configuration is not applicable to deselect all operation');
    }

    const firstMultiOption = getMultiOption(
      productControl.options[0].value,
      product,
      selectedSingleOptions,
      !configuration.singleSelection && productControl.dependsOnSingleControls,
    );

    let newMultiOptions = [];
    const selectedSingleOptionKeys = Object.keys(selectedSingleOptions);
    if (selectedMultiOptions[productControl.key]?.length) {
      newMultiOptions = selectedMultiOptions[productControl.key].filter((option) => {
        return (
          option.dependedOptions.length &&
          selectedSingleOptionKeys.some((selectedSingleOptionKey) => {
            const optionValue = option.dependedOptions.find(
              (dependedOption) => dependedOption.key === selectedSingleOptionKey,
            )?.value;
            const selectedOptionValue = selectedSingleOptions[selectedSingleOptionKey];
            return optionValue !== selectedOptionValue;
          })
        );
      });

      if (newMultiOptions.length === 0) {
        newMultiOptions.push(firstMultiOption);
      }
    } else {
      newMultiOptions.push(firstMultiOption);
    }

    const multiOptions = {
      ...selectedMultiOptions,
      ...{ [productControl.key]: newMultiOptions },
    };

    dispatch(updateMultiOptionsOperation(multiOptions));
  };

export default deselectAllMultiOptionsOperation;

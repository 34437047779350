import cn from 'classnames';
import React, { useState } from 'react';

import openElementConditionTreeOperation from 'editor/src/store/design/operation/openElementConditionTreeOperation';
import isElementInteractable from 'editor/src/store/design/selector/isElementInteractable';
import { MediaElement } from 'editor/src/store/design/types';
import isShape from 'editor/src/store/design/util/isShape';
import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import isMediaElementSelectedByUuid from 'editor/src/store/editor/selector/isMediaElementSelectedByUuid';
import isPersonalizationLockElementsAllowed from 'editor/src/store/editor/selector/isPersonalizationLockElementsAllowed';
import { WarningType } from 'editor/src/store/editorModules/warnings/types';
import getNotVisibleWarning from 'editor/src/store/editorModules/warnings/utils/getNotVisibleWarning';
import getOutOrRangeWarning from 'editor/src/store/editorModules/warnings/utils/getOutOrRangeWarning';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { getAdvPersoDataFromElement } from 'editor/src/store/plugins/utils/advPersoUtils';

import useDragHandle from 'editor/src/component/ConditionGroupBuilder/ConditionElements/ReorganizableList/useDragHandle';
import useLinkedCondition from 'editor/src/component/DesktopSidebar/TabContents/LayerItem/useLinkedCondition';
import { SpreadDisplay } from 'editor/src/component/EditorArea/useSpreadDisplay';

import CollectionBadge from './badges/CollectionBadge';
import DPIBadge from './badges/IndicatorBadges/DPIBadge';
import WarningBadge from './badges/IndicatorBadges/WarningBadge';
import LinkedBadge from './badges/LinkedBadge';
import PersonalisationBadge from './badges/PersonalisationBadge';
import SampleBadge from './badges/SampleBadge';
import SpotFinishingBadge from './badges/SpotFinishing';
import StaticBadge from './badges/StaticBadge';
import ButtonMore from './ButtonMore';

import styles from './index.module.scss';

export interface ItemProps<A extends MediaElement> {
  element: A;
  spreadDisplay: SpreadDisplay;
}

interface Props extends ItemProps<MediaElement> {
  children?: React.ReactNode;
  className?: string;
  onEdit?: () => void;
  elementRef?: React.RefObject<HTMLDivElement>;
  error?: string;
}

function AbstractLayerItem({ element, spreadDisplay, children, onEdit, elementRef, className, error }: Props) {
  const linkedCondition = useLinkedCondition(element.uuid);
  const dragHandle = useDragHandle();
  const [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const selected = useSelector((state) => isMediaElementSelectedByUuid(state, element.uuid));
  const personalizationLockElementsAllowed = useSelector(isPersonalizationLockElementsAllowed);
  const hiddenWarnings = useSelector((state) => state.hostSettings.hiddenWarnings);
  const isInteractable = useSelector((state) => isElementInteractable(state, element));
  const notVisibleWarning =
    !hiddenWarnings.NotVisible &&
    getNotVisibleWarning(
      element,
      spreadDisplay.spreadIndex,
      spreadDisplay.spreadCoords,
      spreadDisplay.spreadWidth,
      spreadDisplay.spreadHeight,
    );
  const outOfRangeWarning =
    !hiddenWarnings.OutOfRange &&
    getOutOrRangeWarning(element, spreadDisplay.spreadIndex, spreadDisplay.spreadWidth, spreadDisplay.spreadHeight);

  const handleClick = () => {
    if (linkedCondition) {
      dispatch(openElementConditionTreeOperation(linkedCondition.condition, element.uuid));
    }

    dispatch(addSelectedMediaElementOperation(element.uuid, false));
  };

  const badges = [];

  if (!element.personalizationLocked && personalizationLockElementsAllowed) {
    badges.push(<PersonalisationBadge key="pers" />);
  }
  if (linkedCondition) {
    badges.push(
      <LinkedBadge key="linked" condition={linkedCondition.condition} optionName={linkedCondition.optionName} />,
    );
  }

  if (!isShape(element) && element.sample) {
    badges.push(<SampleBadge key="sample" />);
  }

  if (element.static) {
    badges.push(<StaticBadge key="static" />);
  }

  const collectionInfo = getAdvPersoDataFromElement(element);
  if (collectionInfo) {
    badges.push(<CollectionBadge key="collection" folderId={collectionInfo.folderId} />);
  }

  if (element.has_spot_finishing) {
    badges.push(<SpotFinishingBadge key="spot-finishing" />);
  }

  if (element.type === 'image' && !hiddenWarnings.LowDPI) {
    badges.push(<DPIBadge image={element} key="dpi" />);
  }

  if (notVisibleWarning) {
    badges.push(<WarningBadge key="not-visible" type={WarningType.NotVisible} />);
  }

  if (outOfRangeWarning) {
    badges.push(<WarningBadge key="out-of-range" type={WarningType.OutOfRange} />);
  }

  return (
    <div
      className={cn(styles.AbstractLayerItem, 'cy-layers-list-item', className, {
        [styles.nonInteractable]: !isInteractable,
        [styles.selected]: selected,
        'cy-selected': selected,
      })}
      onMouseDown={dragHandle.onMouseDown}
      ref={elementRef}
      onClick={handleClick}
    >
      <div className={styles.element}>
        <div className={styles.content}>
          <div className={styles.left}>{children}</div>
          <div className={cn(styles.right, { [styles.isVisible]: isMoreMenuOpen })}>
            <ButtonMore element={element} setVisible={setMoreMenuOpen} isVisible={isMoreMenuOpen} onEdit={onEdit} />
          </div>
        </div>
        {error && <div className={cn(styles.error, 'cy-error')}>{error}</div>}
        {!!badges.length && <div className={styles.badges}>{badges}</div>}
      </div>
    </div>
  );
}

export default React.memo(AbstractLayerItem);

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import resetImagePositionOperation from 'editor/src/store/design/operation/resetImagePositionOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import getStructureIndexesOfSelectedElements from 'editor/src/store/design/selector/getStructureIndexesOfSelectedElements';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';

import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import DrawerHeader from 'editor/src/component/BottomBarMobile/DrawerHeader';
import ButtonBackToTab from 'editor/src/component/DesktopSidebar/TabContents/Buttons/ButtonBackToTab';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import FittingsRow from 'editor/src/component/FittingsRow';
import LinkButton from 'editor/src/component/LinkButton';
import useMenuContext from 'editor/src/component/Menu/useMenuContext';
import { canShow as fillCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonFill';
import { canShow as fitCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonFit';
import { canShow as canShowProperties } from 'editor/src/component/TopMenuDesktop/buttons/ButtonProperties';
import { canShow as stretchToFitCanShow } from 'editor/src/component/TopMenuDesktop/buttons/ButtonStretchToFit';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import AngleProperty from './AngleProperty';
import PositionProperty from './PositionProperty';
import PropertySeparator from './PropertySeparator';
import SizeProperty from './SizeProperty';
import { isSupportedElement, titleKey } from './utils';

import styles from './index.module.scss';

function PropertiesTabContent({ title, icon, closeMenuCb }: TabContentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const structureIndexes = useSelector(getStructureIndexesOfSelectedElements);
  const element = useSelector((state) =>
    structureIndexes.length ? getMediaElement(state, structureIndexes[0]) : undefined,
  );
  const { liveElement } = useMediaElementLiveUpdates(element);
  const editImageFlag = useSelector((state) => state.hostSettings.editImageFlag);
  const store = useStore();
  const menuContext = useMenuContext();
  const isMobile = useIsMobile();
  const state = store.getState();

  const fillVisible = fillCanShow(state, menuContext);
  const fitVisible = fitCanShow(state, menuContext);
  const stretchVisible = stretchToFitCanShow(state, menuContext);
  const propertiesVisible = canShowProperties(state, menuContext);

  const resetImageClick = () => {
    if (structureIndexes[0]) {
      void dispatch(resetImagePositionOperation(structureIndexes[0]));
    }
  };

  if (!structureIndexes[0] || !liveElement || !isSupportedElement(liveElement)) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {isMobile ? (
        <DrawerHeader title={title || t('editor-properties')} closeMenu={closeMenuCb} />
      ) : (
        <TabContentHeader
          title={title || t('editor-properties')}
          icon={editImageFlag ? <ButtonBackToTab mode="icon" tabName={TAB_NAMES.EDIT_IMAGE} /> : icon}
        />
      )}
      <ContentBlock scroll fillFlex>
        {editImageFlag && (
          <FittingsRow fillVisible={fillVisible} fitVisible={fitVisible} stretchVisible={stretchVisible} />
        )}
        {structureIndexes[0] && element && propertiesVisible ? (
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <h3 className={styles.title}>{t(titleKey[liveElement.type])}</h3>
              {(element.type === 'image' || element.type === 'addon') && (
                <LinkButton className={cn(styles.title, 'cy-reset-image')} onClick={resetImageClick}>
                  {t('editor-reset')}
                </LinkButton>
              )}
            </div>
            <SizeProperty address={structureIndexes[0]} element={liveElement} />
            <PropertySeparator />
            <AngleProperty address={structureIndexes[0]} element={liveElement} />
            <PropertySeparator />
            <PositionProperty address={structureIndexes[0]} element={liveElement} />
          </div>
        ) : null}
      </ContentBlock>
    </div>
  );
}

export default React.memo(PropertiesTabContent);

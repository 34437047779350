import { PayloadAction } from '@reduxjs/toolkit';

import { DataState } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const setSearchSortReducer = (state: DataState, action: PayloadAction<string | undefined>) => {
  if (state.results) {
    state.results.search.sort = action.payload;
  }
};

export default setSearchSortReducer;

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}
/* eslint-disable max-len */
function IconArrow1({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2042_11397)">
          <path
            d="M8.29289 15.2929C7.90237 15.6834 7.90237 16.3166 8.29289 16.7071L14.6569 23.0711C15.0474 23.4616 15.6805 23.4616 16.0711 23.0711C16.4616 22.6805 16.4616 22.0474 16.0711 21.6569L10.4142 16L16.0711 10.3431C16.4616 9.95262 16.4616 9.31946 16.0711 8.92893C15.6805 8.53841 15.0474 8.53841 14.6569 8.92893L8.29289 15.2929ZM32 17L33 17L33 15L32 15L32 17ZM9 17L32 17L32 15L9 15L9 17Z"
            fill="#383838"
          />
        </g>
        <defs>
          <clipPath id="clip0_2042_11397">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}

export default IconArrow1;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconArrowForward({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M11.2752 19.2744C11.0584 19.0584 10.946 18.8168 10.938 18.5496C10.9292 18.2832 11.0332 18.0416 11.25 17.8248L16.0248 13.05H5.0496C4.7664 13.05 4.5208 12.946 4.3128 12.738C4.104 12.5292 3.9996 12.2832 3.9996 12C3.9996 11.7168 4.104 11.4708 4.3128 11.262C4.5208 11.054 4.7664 10.95 5.0496 10.95H16.0248L11.25 6.20039C11.0332 5.98359 10.9292 5.73759 10.938 5.46239C10.946 5.18719 11.0584 4.94159 11.2752 4.72559C11.492 4.50879 11.7336 4.40039 12 4.40039C12.2664 4.40039 12.508 4.50879 12.7248 4.72559L19.2504 11.25C19.3672 11.35 19.4504 11.4668 19.5 11.6004C19.5496 11.7332 19.5744 11.8664 19.5744 12C19.5744 12.1336 19.5496 12.2628 19.5 12.3876C19.4504 12.5124 19.3672 12.6332 19.2504 12.75L12.7248 19.2744C12.508 19.4912 12.2664 19.5996 12 19.5996C11.7336 19.5996 11.492 19.4912 11.2752 19.2744Z"
          fill="#999999"
        />
      </svg>
    </Icon>
  );
}

export default IconArrowForward;

import React from 'react';

import { TopMenuDropdown } from 'editor/src/store/app/types';
import getSelectedLineStylesProperty from 'editor/src/store/design/selector/shapes/getSelectedLineStylesProperty';
import { useSelector } from 'editor/src/store/hooks';

import IconFlatLine from 'editor/src/component/Icon/IconFlatLine1';
import LineEdgeControl, { LINE_EDGE_2_OPTIONS } from 'editor/src/component/LineEdgeControl';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import ButtonWithDropdownNotWrapped from 'editor/src/component/TopMenuDesktop/buttons/ButtonWithDropdownNotWrapped';
import MenuDropDownButton from 'editor/src/component/TopMenuDesktop/buttons/MenuDropDownButton';

function ButtonLineEdge2DropDown({ mode }: MenuItemProps) {
  const currentEdge = useSelector((state) => getSelectedLineStylesProperty(state, 'edge2'));
  const selectedIconOption = LINE_EDGE_2_OPTIONS.find((option) => option.value === currentEdge);

  return (
    <ButtonWithDropdownNotWrapped
      dropDownName={TopMenuDropdown.LineEdge2}
      buttonExtraClass="cy-button-line-edge-2"
      dropDownContent={<LineEdgeControl currentEdge={currentEdge} edge="edge2" />}
      buttonContent={
        <MenuDropDownButton
          Icon={selectedIconOption ? selectedIconOption.Icon : IconFlatLine}
          mode={mode}
          i18nKey="Line Edge"
        />
      }
    />
  );
}

export default React.memo(ButtonLineEdge2DropDown);

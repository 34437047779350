import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import getSelectedElementUuids from 'editor/src/store/editor/selector/getSelectedElementUuids';
import isWallpaperEmptyScreenVisible from 'editor/src/store/editor/selector/isWallpaperEmptyScreenVisible';

import useShowRollerStripesControl from 'editor/src/util/useShowRollerStripesControl';

import Button from 'editor/src/component/Button';
import IconRolls from 'editor/src/component/Icon/IconRolls';
import { CanShow } from 'editor/src/component/Menu/type';

import styles from './RulerRollsButton.module.scss';

export const canShow: CanShow = (state) => {
  const { allowShowRollerStripesChange } = state.hostSettings;
  const dimensionsExist = !!state.design.designData?.dimensions;
  const showedWallpaperEmptyScreen = isWallpaperEmptyScreenVisible(state);
  const hasSelectedElement = getSelectedElementUuids(state).length > 0;

  return allowShowRollerStripesChange && dimensionsExist && !showedWallpaperEmptyScreen && !hasSelectedElement;
};

function ButtonRolls() {
  const { t } = useTranslation();
  const { showStripes, showControl, toggleStripes } = useShowRollerStripesControl();

  if (!showControl) {
    return null;
  }

  return (
    <Button onClick={toggleStripes} className={cn('px-2', styles.Button, { [styles.active]: showStripes })}>
      <IconRolls />
      <span className="ml-2">{t('editor-rolls-stripes-button')}</span>
    </Button>
  );
}

export default React.memo(ButtonRolls);

import { fabric } from 'fabric';
import { useState, useEffect } from 'react';

import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import removeAllSelectedMediaElementsOperation from 'editor/src/store/editor/operation/removeAllSelectedMediaElementsOperation';
import getSelectedElementUuids from 'editor/src/store/editor/selector/getSelectedElementUuids';
import isAnyMediaElementInCropMode from 'editor/src/store/editor/selector/isAnyMediaElementInCropMode';
import { setZoomModeAction as setZoomModeOperation } from 'editor/src/store/editor/slice';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';

import orderObjects from './orderObjects';
import useRenderCanvasOnPageVisible from './useRenderCanvasOnPageVisible';

function useCreateFabricCanvas(canvasId: string) {
  const [fabricCanvas, setFabricCanvas] = useState<fabric.Canvas>();
  const dispatch = useDispatch();
  const store = useStore();

  const isAnyImageInCropMode = useSelector(isAnyMediaElementInCropMode);

  useEffect(() => {
    if (fabricCanvas) {
      fabricCanvas.uniScaleKey = isAnyImageInCropMode ? 'shiftKey' : 'none';
      fabricCanvas.requestRenderAll();
    }
  }, [isAnyImageInCropMode]);

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasId, {
      selection: false,
      renderOnAddRemove: false,
      preserveObjectStacking: true, // to avoid having the active elements always on top
      uniScaleKey: 'none',
      centeredKey: 'none',
    });

    (window as any).fabricCanvas = canvas;

    canvas.on('selection:cleared', () => {
      const selectedMediaElements = getSelectedElementUuids(store.getState());
      if (selectedMediaElements.length) {
        dispatch(removeAllSelectedMediaElementsOperation());
        dispatch(setZoomModeOperation(false)); // what is that for ?
      }
    });

    canvas.on('selection:created', (event) => {
      const element = (event as any).selected?.[0];
      if (element && element.uuid) {
        dispatch(addSelectedMediaElementOperation(element.uuid));
      }
    });

    canvas.on('selection:updated', (event) => {
      const element = (event as any).selected?.[0];
      if (element && element.uuid) {
        dispatch(addSelectedMediaElementOperation(element.uuid));
      }
    });

    canvas.on('before:render', () => {
      orderObjects(canvas);
    });

    setFabricCanvas(canvas);
  }, []);

  useRenderCanvasOnPageVisible(fabricCanvas);

  return fabricCanvas;
}

export default useCreateFabricCanvas;

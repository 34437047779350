import React from 'react';

import noop from 'editor/src/util/noop';

import { ReorganizableElementContext } from './Element';

function useReorganizableListUpdate() {
  const context = React.useContext(ReorganizableElementContext);
  return context?.onUpdate || noop;
}

export default useReorganizableListUpdate;

/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

function IconSample() {
  return (
    <Icon className={styles.icon}>
      <svg width="60%" height="100%" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.5 1H4.5V7.5C4.5 8.32843 3.82843 9 3 9V9C2.17157 9 1.5 8.32843 1.5 7.5V1Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M1 1H5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.5 3.5L2.5 3.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.5 6H2.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export default React.memo(IconSample);

import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Condition } from 'editor/src/store/design/types';

import DropDown from 'editor/src/component/DropDown';
import IconArrowDown from 'editor/src/component/Icon/IconArrowDown';

import styles from './index.module.scss';

interface Props {
  options: Condition['options'];
  activeOptionId: string;
  selectOption: (optionId: string) => void;
}

function OptionsDropDown({ options, activeOptionId, selectOption }: Props) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { t } = useTranslation();
  const close = useCallback(() => setDropdownVisible(false), []);

  function onSelect(optionId: string) {
    selectOption(optionId);
    close();
  }

  const activeOption = options.find((option) => option.id === activeOptionId);

  return (
    <>
      <button
        className={cn(styles.OptionsDropDownBtn, 'cy-options-dropdown-button')}
        onClick={() => setDropdownVisible(true)}
      >
        {`${t('editor-switch-selected-option')}:`}
        <span>{activeOption?.label}</span>
        <IconArrowDown />
      </button>
      <DropDown
        isVisible={dropdownVisible}
        onClickOutside={close}
        openPosition="bottom-left"
        className={cn(styles.dropdown, 'cy-dropdown')}
        matchWidth
      >
        {options.map((option) => (
          <button
            key={option.id}
            className={cn(styles.dropDownOption, 'cy-dropdown-option', {
              [styles.active]: option.id === activeOptionId,
            })}
            onClick={() => onSelect(option.id)}
          >
            {option.label}
          </button>
        ))}
      </DropDown>
    </>
  );
}

export default React.memo(OptionsDropDown);

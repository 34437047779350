import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';

import getFocusCenter from './getFocusCenter';

const alignMediaToSpreadMiddleOperation =
  (elementAddress: ElementAddress) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const element = getMediaElement(state, elementAddress);
    if (!element) {
      return;
    }

    const focusCenter = getFocusCenter(state, elementAddress.spreadIndex);

    if (element.type === 'line') {
      const elementCenterY = (element.y1 + element.y2) / 2;
      const offsetY = focusCenter.y - elementCenterY - element.strokeWidth / 2;
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: { y1: element.y1 + offsetY, y2: element.y2 + offsetY },
        }),
      );
    } else {
      const elementCenter = getPointPositionRotatedOnPoint(
        element.x + element.width / 2,
        element.y + element.height / 2,
        element.x,
        element.y,
        element.r,
      );
      const offsetY = focusCenter.y - elementCenter[1];
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: { y: element.y + offsetY },
        }),
      );
    }
  };
export default alignMediaToSpreadMiddleOperation;

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { Adjustment, ElementAddress } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import ADJUSTMENTS, {
  AdjustmentType,
} from 'editor/src/component/DesktopSidebar/TabContents/AdjustmentsTabContent/adjustments';

import updateMediaElementOperation, { MediaUpdateActionName } from './updateMediaElementOperation';

export function getUpdatedAdjustments(
  elementAdjustments: Adjustment[] | undefined,
  adjustment: AdjustmentType,
  value: number,
) {
  const newAdjustments = elementAdjustments?.filter((adj) => adj.name !== adjustment.name) || [];
  // when the user selects the default value, we just remove the adjustment from the data
  if (value !== adjustment.default) {
    newAdjustments.push({ name: adjustment.name, value });
  }
  newAdjustments.sort((a, b) => {
    const aIndex = ADJUSTMENTS.findIndex((adj) => adj.name === a.name);
    const bIndex = ADJUSTMENTS.findIndex((adj) => adj.name === b.name);
    return aIndex - bIndex;
  });

  if (newAdjustments.length === 0) {
    return undefined;
  }
  return newAdjustments;
}

const updateMediaElementAdjustmentsOperation =
  (elementAddress: ElementAddress, adjustment: AdjustmentType, value: number) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const element = getMediaElement(state, elementAddress);
    if (!element || element.type !== 'image') {
      return;
    }

    const adjustments = getUpdatedAdjustments(element.adjustments, adjustment, value);
    dispatch(updateMediaElementOperation(elementAddress, { adjustments }, MediaUpdateActionName.ADJUSTMENT_APPLIED));
  };

export default updateMediaElementAdjustmentsOperation;

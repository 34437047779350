import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { conditionGroupChildenKey } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginName } from 'editor/src/store/plugins/types';

import ConditionGroupBuilder from 'editor/src/component/ConditionGroupBuilder';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import ButtonAddLayer from 'editor/src/component/DesktopSidebar/TabContents/LayersTabContent/ButtonAddLayer';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';

import DrawerHeader from '../../../BottomBarMobile/DrawerHeader';
import { useIsMobile } from '../../../useDetectDeviceType';

import AIToggle from './AIToggle';
import CustomerSettings from './CustomerSettings';
import DefaultPersonalizationPlugin from './DefaultPersonalizationPlugin';
import NonPersonalizableElements from './NonPersonalizableElements';
import ShopifyPersonalizationOptions from './ShopifyPersonalizationOptions';

import styles from './index.module.scss';

interface Props {
  closeMenuCb?: () => void;
}

function PersonalizationTabContent({ closeMenuCb }: Props) {
  const { t } = useTranslation();
  const defaultPersonalizationPlugin = useSelector((state) => getHostSetting(state, 'defaultPersonalizationPlugin'));
  const isMobile = useIsMobile();

  const {
    conditionGroup,
    aiPersonalization,
    aiPersonalizationOn,
    shopifyPersonalization,
    shopifyPersonalizationON,
    shopifyPersonalizationInEditor,
    emailWorkflowPersonalization,
    personalizationStudio,
    personalizationPlugin,
  } = useSelector((state) => {
    const spreadIndex = getCurrentSpreadIndex(state);
    return {
      conditionGroup: state.design.designData?.spreads[spreadIndex]?.conditionGroup,
      aiPersonalization: !!getPlugin(state, PluginName.AIPersonalization),
      aiPersonalizationOn: state.design.globalPlugins.includes(PluginName.AIPersonalization),
      shopifyPersonalization: !!getPlugin(state, PluginName.shopifyPersonalization),
      shopifyPersonalizationON: state.design.globalPlugins.includes(PluginName.shopifyPersonalization),
      shopifyPersonalizationInEditor: !!getPlugin(state, PluginName.shopifyPersonalizationInEditor),
      emailWorkflowPersonalization: !!getPlugin(state, PluginName.emailWorkflowPersonalization),
      personalizationStudio: !!getPlugin(state, PluginName.personalizationStudio),
      personalizationPlugin: getPlugin(state, PluginName.personalizationStudio),
    };
  }, shallowEqual);

  const personalizedElements = conditionGroup?.children[conditionGroupChildenKey];

  const isShopifyConnected = useMemo(
    () => shopifyPersonalization && shopifyPersonalizationInEditor,
    [shopifyPersonalization, shopifyPersonalizationInEditor],
  );

  const isEtsyConnected = useMemo(
    () => aiPersonalization && emailWorkflowPersonalization,
    [aiPersonalization, emailWorkflowPersonalization],
  );

  const isTemplateFlow = useMemo(
    () => defaultPersonalizationPlugin && personalizationStudio && (isShopifyConnected || isEtsyConnected),
    [defaultPersonalizationPlugin, personalizationStudio, isShopifyConnected, isEtsyConnected],
  );

  return (
    <div className={cn(styles.nonPersonalizedItems, { [styles.mobile]: isMobile })}>
      {isMobile ? (
        <DrawerHeader title={t('Personalization')} closeMenu={closeMenuCb} />
      ) : (
        <TabContentHeader title={t('Personalization')} />
      )}

      {isTemplateFlow && (!aiPersonalizationOn || !shopifyPersonalizationON) && (
        <DefaultPersonalizationPlugin isEtsyConnected isShopifyConnected />
      )}

      {!isTemplateFlow && aiPersonalization && <AIToggle />}
      {!isTemplateFlow && shopifyPersonalization && <ShopifyPersonalizationOptions />}
      {!aiPersonalizationOn && !shopifyPersonalizationON && <CustomerSettings />}
      <ContentBlock>
        <ButtonAddLayer
          showCondition
          showCollection={!personalizationPlugin?.extra?.hideCollections}
          isPersonalized
          title={t('Add Personalized Layer')}
        />
      </ContentBlock>
      <ContentBlock>
        {personalizedElements?.length !== 0 && (
          <div className={cn('mt-2', styles.personalizedLayerHeader)}>
            {t('Personalized layers')}
            <div className={cn('mt-1', 'mb-2', styles.personalizedLayerInfo)}>
              {t('How personalization options will be displayed on your product page')}
            </div>
          </div>
        )}
        {conditionGroup && <ConditionGroupBuilder conditionGroup={conditionGroup} />}
      </ContentBlock>
      <NonPersonalizableElements personalizedElements={personalizedElements} />
    </div>
  );
}

export default PersonalizationTabContent;

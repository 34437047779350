/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconCalendar({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="40" height="40" viewBox="-4 -4 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.39998 19.6C1.90498 19.6 1.48123 19.4209 1.12873 19.0625C0.776225 18.7042 0.599976 18.2834 0.599976 17.8V4.60002C0.599976 4.11669 0.776225 3.69586 1.12873 3.33752C1.48123 2.97919 1.90498 2.80002 2.39998 2.80002H4.19998V1.30002C4.19998 1.04502 4.28572 0.831274 4.4572 0.658774C4.6287 0.486274 4.8412 0.400024 5.0947 0.400024C5.34822 0.400024 5.56248 0.486274 5.73748 0.658774C5.91248 0.831274 5.99998 1.04502 5.99998 1.30002V2.80002H12V1.30002C12 1.04502 12.0857 0.831274 12.2572 0.658774C12.4287 0.486274 12.6412 0.400024 12.8947 0.400024C13.1482 0.400024 13.3625 0.486274 13.5375 0.658774C13.7125 0.831274 13.8 1.04502 13.8 1.30002V2.80002H15.6C16.095 2.80002 16.5187 2.97919 16.8712 3.33752C17.2237 3.69586 17.4 4.11669 17.4 4.60002V17.8C17.4 18.2834 17.2237 18.7042 16.8712 19.0625C16.5187 19.4209 16.095 19.6 15.6 19.6H2.39998ZM2.39998 17.8H15.6V8.80002H2.39998V17.8Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconCalendar;

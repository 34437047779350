import { fabric } from 'fabric';
import { Polygon } from 'polygon-clipping';

import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';
import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';
import { POINT_0_0 } from 'editor/src/component/EditorArea/useCanvasRotation';

function getTextClipPath(
  textRect: ObjectRect,
  fontSize: number,
  contentClipPolygons: Polygon[],
  contentClipPath: fabric.Object | undefined,
) {
  // some fonts are rendered outside of the selection box, so we make it 2x bigger
  const rotatedVector = fabric.util.rotatePoint(
    new fabric.Point(-fontSize / 2, -fontSize / 2),
    POINT_0_0,
    fabric.util.degreesToRadians(textRect.angle),
  );

  const extendedTextRect = {
    left: textRect.left + rotatedVector.x,
    top: textRect.top + rotatedVector.y,
    width: textRect.width + fontSize,
    height: textRect.height + fontSize,
    angle: textRect.angle,
  };

  return getClipPath(extendedTextRect, contentClipPolygons, false, contentClipPath);
}

export default getTextClipPath;

import React from 'react';

import Icon from './index';

interface Props {
  className?: string;
}

function IconScene({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6.5" cy="6" r="2" fill="#212121" />
        <rect
          x="1.5"
          y="1"
          width="18"
          height="15"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5 16L7.63636 10.9444L11.7273 13.2778L16.2273 9L19.5 12.1111"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}

export default React.memo(IconScene);

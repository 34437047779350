import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import addMediaElementOperation from 'editor/src/store/design/operation/addMediaElementOperation';
import getNewElementDisplay from 'editor/src/store/design/operation/getNewElementDisplay';
import { MediaLine } from 'editor/src/store/design/types';
import getNewLineDisplaySize from 'editor/src/store/design/util/getNewLineDisplaySize';
import getVisibleColorAtElementPosition from 'editor/src/store/design/util/getVisibleColorAtElementPosition';
import addSelectedMediaElementOperation from 'editor/src/store/editor/operation/addSelectedMediaElementOperation';
import getDefaultPersonalizationLockState from 'editor/src/store/editor/selector/getDefaultPersonalizationLockState';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import { logEvent } from 'editor/src/amplitude';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import { LineConfigurableStyles } from 'editor/src/util/shapePresets';
import { elementUuids } from 'editor/src/util/uuids';

const DEFAULT_STROKE_WIDTH = 4;

const addNewLineToPageOperation =
  (spreadIndex: number, pageIndex: number, styles: LineConfigurableStyles) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();

    const display = getNewElementDisplay(state, spreadIndex, getNewLineDisplaySize);
    if (!display) {
      return;
    }

    const { center, width, height } = display;

    const newElementUuid = elementUuids.generate();
    const defaultPersonalizationLockState = getDefaultPersonalizationLockState(state);

    const lineElement: MediaLine = {
      type: 'line',
      name: `line ${newElementUuid}`,
      x1: center.x,
      y1: center.y,
      uuid: newElementUuid,
      personalizationLocked: defaultPersonalizationLockState || undefined,
      stroke: '#000',
      strokeWidth: DEFAULT_STROKE_WIDTH,
      x2: center.x + width,
      y2: center.y + height,
      edge1: 'flat',
      edge2: 'flat',
      rounded: false,
      ...styles,
    };

    lineElement.stroke = getVisibleColorAtElementPosition(lineElement, spreadIndex, state);

    batch(() => {
      dispatch(addMediaElementOperation(spreadIndex, pageIndex, lineElement, true));
      dispatch(addSelectedMediaElementOperation(newElementUuid, false));
      sendPostMessage('log.lineAdded', lineElement);
      logEvent('element added', { type: 'line' });
    });
  };

export default addNewLineToPageOperation;

import React from 'react';

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconProperties from 'editor/src/component/Icon/IconProperties';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection, structureIndexes }) => {
  if (structureIndexes[0]) {
    const element = getMediaElement(state, structureIndexes[0]);
    return element?.type === 'image' && !element.role && state.hostSettings.editImageFlag && hasSelection;
  }
  return false;
};

type Props = Pick<MenuItemProps, 'mode' | 'isMobile' | 'close'>;

function ButtonEditImage({ mode, isMobile, close }: Props) {
  const dispatch = useDispatch();
  const isActive = useSelector((state) => isSidebarTabActive(state, TAB_NAMES.EDIT_IMAGE));

  const handleClick = () => {
    if (isMobile) {
      if (isActive) {
        dispatch(setSidebarActiveTabOperation(-1));
      } else {
        dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.EDIT_IMAGE));
      }
    } else {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.EDIT_IMAGE));
      close?.();
    }
  };

  return (
    <MenuButton
      Icon={IconProperties}
      on={isActive}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-edit"
      className="cy-button-edit-image"
    />
  );
}

export default React.memo(ButtonEditImage);

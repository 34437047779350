import { useRef } from 'react';
import { shallowEqual } from 'react-redux';

import { useSelector } from 'editor/src/store/hooks';

import { MenuItem } from './type';
import useMenuContext from './useMenuContext';

function useFilteredMenuItems<T extends MenuItem>(items: T[]) {
  const menuContext = useMenuContext();

  const prevItems = useRef(items);
  prevItems.current = useSelector((state) => {
    let i = 0;
    let changed = false;
    const filteredItems: T[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      if (item.canShow(state, menuContext)) {
        filteredItems.push(item);
        if (item.Component !== prevItems.current[i]?.Component) {
          changed = true;
        }
        i += 1;
      }
    }
    return changed || filteredItems.length !== prevItems.current.length ? filteredItems : prevItems.current;
  }, shallowEqual);
  return { items: prevItems.current, menuContext };
}

export default useFilteredMenuItems;

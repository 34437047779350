export interface CanvasRotation {
  angleDeg: number;
  angleRad: number;
  canvasCenter: fabric.Point;
}

export const VIEWPORT_CHANGED_EVENT = 'viewportChanged';
export const FABRIC_SCROLL_EVENT = 'fabricScrollEvent';

export const RIGHT_CONTROL_BOTTOM_OFFSET = 20;

export type onPageSelectorUpdateFn = (bottomOffset: number, height: number) => void;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  active?: boolean;
}

function IconLayouts({ className, active }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        {!active ? (
          <path
            d="M13 8V4C13 3.71667 13.096 3.479 13.288 3.287C13.4793 3.09567 13.7167 3 14 3H20C20.2833 3 20.5207 3.09567 20.712 3.287C20.904 3.479 21 3.71667 21 4V8C21 8.28333 20.904 8.52067 20.712 8.712C20.5207 8.904 20.2833 9 20 9H14C13.7167 9 13.4793 8.904 13.288 8.712C13.096 8.52067 13 8.28333 13 8ZM3 12V4C3 3.71667 3.096 3.479 3.288 3.287C3.47933 3.09567 3.71667 3 4 3H10C10.2833 3 10.521 3.09567 10.713 3.287C10.9043 3.479 11 3.71667 11 4V12C11 12.2833 10.9043 12.5207 10.713 12.712C10.521 12.904 10.2833 13 10 13H4C3.71667 13 3.47933 12.904 3.288 12.712C3.096 12.5207 3 12.2833 3 12ZM13 20V12C13 11.7167 13.096 11.479 13.288 11.287C13.4793 11.0957 13.7167 11 14 11H20C20.2833 11 20.5207 11.0957 20.712 11.287C20.904 11.479 21 11.7167 21 12V20C21 20.2833 20.904 20.5207 20.712 20.712C20.5207 20.904 20.2833 21 20 21H14C13.7167 21 13.4793 20.904 13.288 20.712C13.096 20.5207 13 20.2833 13 20ZM3 20V16C3 15.7167 3.096 15.479 3.288 15.287C3.47933 15.0957 3.71667 15 4 15H10C10.2833 15 10.521 15.0957 10.713 15.287C10.9043 15.479 11 15.7167 11 16V20C11 20.2833 10.9043 20.5207 10.713 20.712C10.521 20.904 10.2833 21 10 21H4C3.71667 21 3.47933 20.904 3.288 20.712C3.096 20.5207 3 20.2833 3 20ZM5 11H9V5H5V11ZM15 19H19V13H15V19ZM15 7H19V5H15V7ZM5 19H9V17H5V19Z"
            fill="#212121"
          />
        ) : (
          <path
            d="M13.5 8V4C13.5 3.71667 13.596 3.479 13.788 3.287C13.9793 3.09567 14.2167 3 14.5 3H20.5C20.7833 3 21.0207 3.09567 21.212 3.287C21.404 3.479 21.5 3.71667 21.5 4V8C21.5 8.28333 21.404 8.52067 21.212 8.712C21.0207 8.904 20.7833 9 20.5 9H14.5C14.2167 9 13.9793 8.904 13.788 8.712C13.596 8.52067 13.5 8.28333 13.5 8ZM3.5 12V4C3.5 3.71667 3.596 3.479 3.788 3.287C3.97933 3.09567 4.21667 3 4.5 3H10.5C10.7833 3 11.021 3.09567 11.213 3.287C11.4043 3.479 11.5 3.71667 11.5 4V12C11.5 12.2833 11.4043 12.5207 11.213 12.712C11.021 12.904 10.7833 13 10.5 13H4.5C4.21667 13 3.97933 12.904 3.788 12.712C3.596 12.5207 3.5 12.2833 3.5 12ZM13.5 20V12C13.5 11.7167 13.596 11.479 13.788 11.287C13.9793 11.0957 14.2167 11 14.5 11H20.5C20.7833 11 21.0207 11.0957 21.212 11.287C21.404 11.479 21.5 11.7167 21.5 12V20C21.5 20.2833 21.404 20.5207 21.212 20.712C21.0207 20.904 20.7833 21 20.5 21H14.5C14.2167 21 13.9793 20.904 13.788 20.712C13.596 20.5207 13.5 20.2833 13.5 20ZM3.5 20V16C3.5 15.7167 3.596 15.479 3.788 15.287C3.97933 15.0957 4.21667 15 4.5 15H10.5C10.7833 15 11.021 15.0957 11.213 15.287C11.4043 15.479 11.5 15.7167 11.5 16V20C11.5 20.2833 11.4043 20.5207 11.213 20.712C11.021 20.904 10.7833 21 10.5 21H4.5C4.21667 21 3.97933 20.904 3.788 20.712C3.596 20.5207 3.5 20.2833 3.5 20Z"
            fill="#212121"
          />
        )}
      </svg>
    </Icon>
  );
}

export default IconLayouts;

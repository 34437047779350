import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import { LeaningShadow, MockupDisplayMode, PerspectiveTransform } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import defaultImageStyles from 'editor/src/store/fonts/utils/defaultImageStyles';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const defaultLeaningShadow: LeaningShadow = {
  type: 'leaning-shadow',
  blur: 0.2,
  color: 'rgba(0, 0, 0, 0.3)',
};

const defaultLeaningPerspectiveTransformation: PerspectiveTransform = {
  tl_x: 0.02,
  tl_y: 0,
  tr_x: 0.98,
  tr_y: 0,
  bl_x: 0,
  bl_y: 1,
  br_x: 1,
  br_y: 1,
};

const setMockupDisplayModeOperation =
  (displayMode: MockupDisplayMode) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    batch(() => {
      const state = getState();
      const placeholderIndex =
        state.design.designData?.spreads[0].pages[0].groups.media?.findIndex(isMediaMockupPlaceholder);
      const placeholder =
        placeholderIndex !== undefined
          ? state.design.designData?.spreads[0].pages[0].groups.media?.[placeholderIndex]
          : undefined;
      if (!placeholder || placeholderIndex === undefined) {
        return;
      }

      dispatch(
        updateMediaElementOperation(
          { spreadIndex: 0, pageIndex: 0, elementIndex: placeholderIndex },
          {
            display_mode: displayMode,
            shadow: displayMode === MockupDisplayMode.Hanging ? defaultImageStyles.shadow : defaultLeaningShadow,
            perspective_transform:
              displayMode === MockupDisplayMode.Hanging ? undefined : defaultLeaningPerspectiveTransformation,
          },
          MediaUpdateActionName.PLACEHOLDER_UPDATE,
        ),
      );
    });
  };

export default setMockupDisplayModeOperation;

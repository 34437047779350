import updateMediaElementByUuidOperation from 'editor/src/store/design/operation/updateMediaElementByUuidOperation';
import { Dimensions, MediaImage } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getFullSizeImagePosition from 'editor/src/util/resizableElement/getFullSizeImagePosition';

const placeResizableFullSizeImageOperation =
  (imageElement: MediaImage, dimensions: Dimensions) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const galleryImage = imageElement.imageId
      ? state.gallery.images.find((image) => image.id === imageElement.imageId)
      : undefined;
    const fullSizeImagePosition = getFullSizeImagePosition(imageElement, dimensions, galleryImage);

    if (fullSizeImagePosition) {
      dispatch(updateMediaElementByUuidOperation(imageElement.uuid, fullSizeImagePosition));
    }
  };

export default placeResizableFullSizeImageOperation;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import type { Thunk } from 'editor/src/store/hooks';
import getAllMatchingVariations from 'editor/src/store/variants/helpers/getAllMatchingVariations';
import changeEcommerceVariantPageCountOperation from 'editor/src/store/variants/operation/changeEcommerceVariantPageCountOperation';
import checkForMissingTemplatesOperation from 'editor/src/store/variants/operation/checkForMissingTemplatesOperation';
import { setPageCountAction } from 'editor/src/store/variants/slice';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import saveUndoRedoStateOperation from '../../../editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import getSpread from '../../selector/getSpread';
import { copySpreadAction } from '../../slice';

const addSpreadOperation =
  (spreadIndex: number, duplicate = false): Thunk =>
  (dispatch, getState) => {
    let state = getState();
    const { designData } = state.design;
    if (!designData) {
      return;
    }

    batch(() => {
      let spreadIndexTemplate = spreadIndex;
      const spreadMediaIndexTemplate = spreadIndex;
      const spread = getSpread(state, spreadIndex);
      if (!spread?.isTemplate) {
        const index = designData.spreads.findIndex((spread) => spread.isTemplate);
        if (index === undefined || index === -1) {
          throw new Error('No template spread found');
        }
        spreadIndexTemplate = index;
      }

      dispatch(saveUndoRedoStateOperation('add spread'));

      const positionAtIndex = spreadIndex + 1;

      dispatch(
        copySpreadAction({
          copyFromIndex: spreadIndexTemplate,
          positionAtIndex,
          empty: !duplicate,
          mediaFromIndex: spreadMediaIndexTemplate,
        }),
      );

      state = getState();
      if (state.design.designData?.page_count) {
        dispatch(setPageCountAction(state.design.designData.page_count));
        dispatch(changeEcommerceVariantPageCountOperation(state.design.designData.page_count));
        sendPostMessage('design.switchToProduct', {
          productUid: state.design.designData.product_uid,
          pageCount: state.design.designData.page_count,
        });

        state = getState();
        const { product, configuration, selectedMultiOptions, selectedSingleOptions, replaceControlKeys } =
          state.variants;
        if (!configuration.singleSelection) {
          const matchingVariations = getAllMatchingVariations(
            product.productVariations,
            selectedMultiOptions,
            selectedSingleOptions,
            false,
            replaceControlKeys,
          );
          dispatch(checkForMissingTemplatesOperation(matchingVariations));
        }
      }
    });
  };

export default addSpreadOperation;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SavingDesignStatus } from 'editor/src/store/design/types';

import IconCheck from '../../Icon/IconCheck';
import IconLoading from '../../Icon/IconLoading';
import IconOffline from '../../Icon/IconOffline';

import styles from './index.module.scss';

const savingStatusTranslationsKeys: { [key in SavingDesignStatus]: string } = {
  saving: 'Saving design',
  saved: 'Design saved',
  offline: 'Offline mode',
};

const savingStatusIcons: { [key in SavingDesignStatus]: JSX.Element } = {
  saving: <IconLoading className={cn(styles.statusIcon, styles.loadingStatus)} />,
  saved: <IconCheck className={styles.statusIcon} />,
  offline: <IconOffline className={styles.statusIcon} />,
};

interface Props {
  status: SavingDesignStatus;
}
function Status({ status }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.statusWrapper}>
      {savingStatusIcons[status]}
      <span className={styles.status}>{t(savingStatusTranslationsKeys[status])}</span>
    </div>
  );
}
export default Status;

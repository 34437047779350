import { RootState } from 'editor/src/store/index';

import getImageById from './getImageById';

const getImageProgressById = (state: RootState, imageId: string) => {
  if (!imageId) {
    return undefined;
  }
  const image = getImageById(state, imageId);
  const progress = (image?.bytesUploaded || 1) / (image?.bytesTotal || 0);
  return image?.bytesTotal ? progress : 1;
};

export default getImageProgressById;

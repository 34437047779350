import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'editor/src/component/Button';
import DropDown from 'editor/src/component/DropDown';

import styles from './index.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
  isVisible: boolean;
  triggerBtnRef: React.RefObject<HTMLButtonElement | HTMLDivElement>;
  pointerOffset: number;
  onClose: () => void;
  className?: string;
}
function RichTooltip({ title, children, className, onClose, isVisible, triggerBtnRef, pointerOffset }: Props) {
  const { t } = useTranslation();
  const onClickOutside = (e?: MouseEvent | TouchEvent) => {
    if (e && triggerBtnRef.current && e.composedPath && e.composedPath().includes(triggerBtnRef.current)) {
      return;
    }

    onClose();
  };

  return (
    <DropDown
      isVisible={isVisible}
      className={cn(className, styles.richTooltip)}
      onClickOutside={onClickOutside}
      openPosition="right-fromtop"
    >
      <div className={styles.pointer} style={{ top: `${pointerOffset}px` }} />
      <div className={styles.title}>
        <b>{title}</b>
      </div>
      <div className={styles.content}>{children}</div>
      <div className={styles.bottomBar}>
        <Button secondary className={styles.actionButton} onClick={onClose}>
          {t('Close')}
        </Button>
      </div>
    </DropDown>
  );
}

export default React.memo(RichTooltip);

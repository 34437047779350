import React from 'react';
import { shallowEqual } from 'react-redux';

import addSpreadOperation from 'editor/src/store/design/operation/spreadManipulation/addSpreadOperation';
import getSpread from 'editor/src/store/design/selector/getSpread';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { RootState } from 'editor/src/store';

import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';
import usePageCountLimit from 'editor/src/component/TopMenuDesktop/usePageCountLimit';

import IconPlus from '../../Icon/IconPlus';

import MenuButton from './MenuButton';

export function canShowAddRemovePages(state: RootState) {
  if (!state.design.designData?.page_count_limit) {
    return false;
  }

  if (state.hostSettings.ignorePersonalizationLock) {
    return true;
  }

  // temporary fix until everyone can publish multiple variants for each photobook pages
  // there are 85 possible pages compbination
  return (state.variants.eCommerceProduct?.variants?.length ?? 0) >= 85;
}

export const canShow: CanShow = (state, { hasSelection }) => {
  return !hasSelection && canShowAddRemovePages(state);
};

type Props = Pick<MenuItemProps, 'close' | 'mode' | 'spreadIndex'>;

function ButtonAddPages({ close, mode, spreadIndex }: Props) {
  const dispatch = useDispatch();
  const { canAddAfter, pageCount } = useSelector((state) => {
    const { designData } = state.design;

    if (spreadIndex === undefined || spreadIndex === null || spreadIndex < 0) {
      throw new Error('spreadIndex is required');
    }

    const spread = getSpread(state, spreadIndex);
    return {
      canAddAfter: !!spread?.canAddAfter,
      pageCount: designData?.page_count ?? 0,
    };
  }, shallowEqual);

  const pageCountLimit = usePageCountLimit();
  const enable = !!pageCountLimit && canAddAfter && pageCount < pageCountLimit.max;

  const handleClick = () => {
    if (spreadIndex === undefined || spreadIndex === null || spreadIndex < 0) {
      throw new Error('spreadIndex is required');
    }
    dispatch(addSpreadOperation(spreadIndex));
    close?.();
  };

  return (
    <MenuButton
      mode={mode}
      Icon={IconPlus}
      handleClick={handleClick}
      i18nKey="Add pages"
      className="cy-button-add-pages"
      disabled={!enable}
    />
  );
}

export default React.memo(ButtonAddPages);

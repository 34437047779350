import { MediaImage } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { GalleryImage } from 'editor/src/store/gallery/types';
import { RootState } from 'editor/src/store/index';

const getFirstImageElementAndAsset = (
  state: RootState,
): {
  imageElement: MediaImage | undefined;
  imageAsset: GalleryImage | undefined;
} => {
  const spreadIndex = getCurrentSpreadIndex(state);
  const media = state.design.designData?.spreads[spreadIndex]?.pages[0].groups.media;
  const imageElement = media?.find((element) => element.type === 'image') as MediaImage | undefined;
  return {
    imageElement,
    imageAsset: imageElement?.imageId
      ? state.gallery.images.find((image) => image.id === imageElement.imageId)
      : undefined,
  };
};

export default getFirstImageElementAndAsset;

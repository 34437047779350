import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

function AddImagesHint() {
  const { t } = useTranslation();
  return (
    <div className={styles.addImagesHint}>
      <div className={styles.addImagesText}>{t('editor-add-images-start-design')}</div>
    </div>
  );
}

export default React.memo(AddImagesHint);

import getConditionChildrenKey from 'editor/src/store/design/reducer/getConditionChildrenKey';
import { ConditionGroup, conditionGroupChildenKey } from 'editor/src/store/design/types';

function getConditionLayerIds(
  conditionId: string,
  conditionGroup: ConditionGroup,
  exceptConditionId: string,
  ids = new Set<number>(),
) {
  conditionGroup.conditions[conditionId].options.forEach((option) => {
    const children = conditionGroup.children[getConditionChildrenKey(conditionId, option.id)] || [];

    children.forEach((child) => {
      if (child.type === 'condition') {
        getConditionLayerIds(child.id, conditionGroup, exceptConditionId, ids);
      } else if (exceptConditionId !== conditionId) {
        ids.add(child.id);
      }
    });
  });

  return ids;
}

function getGroupActiveLayerIds(conditionGroup: ConditionGroup, exceptConditionId: string) {
  const ids = new Set<number>();
  const topChildren = conditionGroup.children[conditionGroupChildenKey] || [];
  topChildren.forEach((child) => {
    if (child.type === 'condition') {
      getConditionLayerIds(child.id, conditionGroup, exceptConditionId, ids);
    }
  });

  return ids;
}

export default getGroupActiveLayerIds;

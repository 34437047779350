import type { Thunk } from 'editor/src/store/hooks';

import toastController from 'editor/src/util/toastController';

const onMockupGenerationFailedOperation =
  (): Thunk =>
  (_, _1, { i18n }) => {
    toastController.errorPersist(
      i18n.t('Generating the mockup failed'),
      i18n.t('We were unable to generate the mockup. Please try again later or contact our support.'),
    );
  };

export default onMockupGenerationFailedOperation;

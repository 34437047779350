import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconPattern({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6" r="2" fill="#212121" />
        <circle cx="12" cy="6" r="2" fill="#212121" />
        <circle cx="18" cy="6" r="2" fill="#212121" />
        <circle cx="6" cy="12" r="2" fill="#212121" />
        <circle cx="12" cy="12" r="2" fill="#212121" />
        <circle cx="18" cy="12" r="2" fill="#212121" />
        <circle cx="6" cy="18" r="2" fill="#212121" />
        <circle cx="12" cy="18" r="2" fill="#212121" />
        <circle cx="18" cy="18" r="2" fill="#212121" />
      </svg>
    </Icon>
  );
}

export default IconPattern;

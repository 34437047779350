import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DrawerHeader from '../../DrawerHeader';
import FiltersList from '../FiltersList';

import styles from './index.module.scss';

interface Props {
  closeMenuCb?: () => void;
}
function FiltersMobileMenu({ closeMenuCb }: Props) {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.FiltersMobileMenu, 'cy-tab-filters')}>
      <DrawerHeader title={t('editor-filters')} closeMenu={closeMenuCb} />
      <div className={styles.filtersListWrapper}>
        <FiltersList />
      </div>
    </div>
  );
}

export default React.memo(FiltersMobileMenu);

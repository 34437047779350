import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { shallowEqual } from 'react-redux';

import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { MediaText } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

import useDragHandle from 'editor/src/component/ConditionGroupBuilder/ConditionElements/ReorganizableList/useDragHandle';
import useReorganizableListUpdate from 'editor/src/component/ConditionGroupBuilder/ConditionElements/ReorganizableList/useReorganizableListUpdate';
import ElementName from 'editor/src/component/PersonalizationContent/PersonalizationElements/ElementName';
import useFocusElementOnFlag from 'editor/src/component/PersonalizationContent/PersonalizationElements/useFocusElementOnFlag';

import styles from './index.module.scss';

interface Props {
  elementId: number;
  isBuilding?: boolean;
  className?: string;
}

function PersonalizationShape({ elementId, isBuilding, className }: Props) {
  const { element, address, isSelected } = useSelector(
    (state) => ({
      isSelected: state.editor.selectedElementUuids.includes(elementId),
      element: getMediaElementByUuid(state, elementId) as MediaText | undefined,
      address: getStructureIndexByElementUuidMemoized(state, elementId),
    }),
    shallowEqual,
  );

  const dragHandle = useDragHandle();

  useEffect(() => {
    updateList();
  }, [!!element?.name]);

  const updateList = useReorganizableListUpdate();

  const divRef = useRef<HTMLDivElement>(null);

  useFocusElementOnFlag(isSelected, divRef);

  if (!element || !address || element?.personalizationLocked) {
    return null;
  }

  return (
    <div
      ref={divRef}
      className={cn(styles.PersonalizationShape, className, `cy-personalization-${element.type}`, {
        [styles.building]: isBuilding,
        [styles.active]: isSelected,
      })}
      onMouseDown={dragHandle.onMouseDown}
    >
      <ElementName element={element} isBuilding={isBuilding} address={address} />
    </div>
  );
}

export default React.memo(PersonalizationShape);

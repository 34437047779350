import React, { useMemo } from 'react';

import { Coords } from 'editor/src/store/design/types';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricLineComponent, {
  Props as FabricLineComponentProps,
} from 'editor/src/component/EditorArea/fabricComponents/FabricLineComponent';

import { calculateGridPosition, calculateLineCoords, getBasePropsForLine } from './helpers';

interface Props {
  spreadWidth: number;
  spreadHeight: number;
  spreadCoords: Coords;
}

function Grid({ spreadCoords, spreadWidth, spreadHeight }: Props) {
  const { mm2px } = useFabricUtils();

  const lines: FabricLineComponentProps[] = useMemo(() => {
    const lines: FabricLineComponentProps[] = [];
    const { left, top } = spreadCoords;
    const { start: horizontalStart, end: horizontalEnd } = calculateGridPosition(spreadHeight);
    const { start: verticalStart, end: verticalEnd } = calculateGridPosition(spreadWidth);

    for (let i = horizontalStart, lineCounter = 0; i <= horizontalEnd; i += 10, lineCounter += 1) {
      lines.push({
        ...calculateLineCoords({
          i,
          mm2px,
          left,
          top,
          end: verticalEnd,
          start: verticalStart,
          isVertical: false,
        }),
        ...getBasePropsForLine(lineCounter),
      });
    }
    for (let i = verticalStart, lineCounter = 0; i <= verticalEnd; i += 10, lineCounter += 1) {
      lines.push({
        ...calculateLineCoords({
          i,
          mm2px,
          left,
          top,
          end: horizontalEnd,
          start: horizontalStart,
          isVertical: true,
        }),
        ...getBasePropsForLine(lineCounter),
      });
    }

    return lines;
  }, [mm2px, spreadCoords, spreadWidth, spreadHeight]);

  return (
    <>
      {lines.map((item, index) => (
        <FabricLineComponent
          x1={item.x1}
          y1={item.y1}
          x2={item.x2}
          y2={item.y2}
          stroke={item.stroke}
          strokeWidth={item.strokeWidth}
          selectable={item.selectable}
          evented={item.evented}
          strokeDashArray={item.strokeDashArray}
          zIndex={item.zIndex}
          key={index}
        />
      ))}
    </>
  );
}

export default React.memo(Grid);

import React from 'react';
import { useTranslation } from 'react-i18next';

import IconSample from 'editor/src/component/Icon/IconSample';

import Badge from './Badge';

function SampleBadge() {
  const { t } = useTranslation();
  return (
    <Badge tooltip={t('editor-is-sample')} className="cy-sample-badge">
      <IconSample />
    </Badge>
  );
}

export default React.memo(SampleBadge);

import React from 'react';

import { MediaElement } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';

import LayerItem from 'editor/src/component/DesktopSidebar/TabContents/LayerItem';
import AbstractImageLayerItem from 'editor/src/component/DesktopSidebar/TabContents/LayerItem/AbstractImageLayerItem/AbstractImageLayerItem';
import AbstractLayerItem from 'editor/src/component/DesktopSidebar/TabContents/LayerItem/AbstractLayerItem';
import AbstractTextLayerItem from 'editor/src/component/DesktopSidebar/TabContents/LayerItem/AbstractTextLayerItem/AbstractTextLayerItem';
import { SpreadDisplay } from 'editor/src/component/EditorArea/useSpreadDisplay';

export interface Props {
  element: MediaElement;
  spreadDisplay: SpreadDisplay;
}

function AbstractLayerItemContent({ element, spreadDisplay }: Props) {
  switch (element.type) {
    case 'addon':
    case 'grid':
    case 'line':
    case 'rectangle':
      return (
        <AbstractLayerItem key={element.uuid} spreadDisplay={spreadDisplay} element={element}>
          <LayerItem element={element} />
        </AbstractLayerItem>
      );
    case 'image':
      if (isMediaMockupPlaceholder(element)) {
        return (
          <AbstractLayerItem
            key={element.uuid}
            spreadDisplay={spreadDisplay}
            element={element}
            className="cy-placeholder"
          >
            <LayerItem element={element} />
          </AbstractLayerItem>
        );
      }

      return <AbstractImageLayerItem key={element.uuid} spreadDisplay={spreadDisplay} element={element} />;
    case 'text':
      return <AbstractTextLayerItem key={element.uuid} spreadDisplay={spreadDisplay} element={element} />;
    default:
      return null;
  }
}

export default React.memo(AbstractLayerItemContent);

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import IconBack from '../Icon/IconBack';
import IconCart from '../Icon/IconCart';
import ProductPrice from '../ProductPrice';

import styles from '../MobileHeader/index.module.scss';

interface Props {
  onBackButtonClick: () => void;
  onCartButtonClick: () => void;
  productPrice: {
    price: string | undefined;
    originalPrice: string | undefined;
  };
}

function DesktopHeader({ onBackButtonClick, onCartButtonClick, productPrice }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <Button transparent className={cn(styles.desktopButton, 'cy-header-back-button')} onClick={onBackButtonClick}>
        <IconBack className="backIcon" />
        <span>{t('Exit')}</span>
      </Button>
      <div className={styles.rightElementsWrapper}>
        {productPrice?.price && <ProductPrice price={productPrice.price} originalPrice={productPrice.originalPrice} />}
        <Button primary className={cn(styles.desktopButton, 'cy-header-proceed-button')} onClick={onCartButtonClick}>
          <IconCart className="cartIcon" />
          <span>{t('Continue to cart')}</span>
        </Button>
      </div>
    </div>
  );
}

export default React.memo(DesktopHeader);

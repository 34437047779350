import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import applyLayoutSchemaToSpreadOperation from 'editor/src/store/editor/operation/applyLayoutSchemaToSpreadOperation';
import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const applyLayoutToAllSpreadsOperation =
  (
    layoutSchemas: LayoutSchema[],
    saveUndoRedo = true,
    force: 'layout-applied' | 'same-layout' | undefined = undefined,
  ) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const { designData } = state.design;

    batch(() => {
      saveUndoRedo && dispatch(saveUndoRedoStateOperation('Apply layout to all spreads'));

      designData?.spreads.forEach((spread, spreadIndex) => {
        if (spread.layoutSchemaName && !force) {
          return;
        }

        const layout = spread.pages.length > 1 && layoutSchemas.length > 1 ? layoutSchemas[1] : layoutSchemas[0];
        if (
          (spread.layoutSchemaName === layout.name && force !== 'same-layout') ||
          (spread.tags && !spread.tags.some((tag) => !layout.tags || layout.tags.includes(tag)))
        ) {
          return;
        }

        dispatch(applyLayoutSchemaToSpreadOperation(spreadIndex, layout));
      });
    });
  };

export default applyLayoutToAllSpreadsOperation;

import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import setSelectedProductSceneOperation from 'editor/src/store/mockup/operation/setSelectedProductSceneOperation';
import setVariantAsPlaceholderOperation from 'editor/src/store/mockup/operation/setVariantAsPlaceholderOperation';
import { MockupPreviewVariant, MockupProductProduct, ProductScene } from 'editor/src/store/mockup/types';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import BackHeader from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/BackHeader';
import IconLoading from 'editor/src/component/Icon/IconLoading';
import IconPremium from 'editor/src/component/Icon/IconPremium';

import styles from './index.module.scss';

interface Props {
  product: MockupProductProduct;
  variant?: MockupPreviewVariant;
  back: () => void;
}

function SceneModelsSelection({ product, variant, back }: Props) {
  const sceneCategories = useSelector((state) => state.mockup.scenePlaceholder.sceneCategories[product.productUid]);
  const selectedSceneId = useSelector(
    (state) =>
      state.design.designData?.spreads[0]?.pages[0].groups.media?.find(isMediaMockupPlaceholder)?.variant.scene,
  );
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => setSelectedIndex(0), [sceneCategories]);

  const onSceneChange = (scene: ProductScene) => {
    if (variant) {
      dispatch(setVariantAsPlaceholderOperation(variant, scene));
    } else {
      dispatch(setSelectedProductSceneOperation(scene, product.productUid, false));
    }
  };

  const selectedSceneCategory = sceneCategories?.[selectedIndex];

  return (
    <>
      <BackHeader title={product.name} onClick={back} />
      <ContentBlock scroll>
        {!sceneCategories && (
          <div className={cn(styles.loader, 'mt-1', 'cy-scroll-loader')}>
            <IconLoading />
          </div>
        )}
        <div className={styles.categoryHeader}>
          {sceneCategories?.map((sceneCategory, i) => (
            <div
              key={sceneCategory.name}
              className={cn(styles.sceneCategory, {
                [styles.selected]: selectedSceneCategory?.name === sceneCategory.name,
              })}
              onClick={() => setSelectedIndex(i)}
            >
              {sceneCategory.name}
            </div>
          ))}
        </div>
        <div className={cn(styles.scenes, 'mt-1')}>
          {selectedSceneCategory?.scenes.map((scene) => (
            <div key={scene.productImage.url} className={styles.sceneItemContainer}>
              <div
                className={cn(styles.sceneItemImageContainer, {
                  [styles.selected]: selectedSceneId === scene.id,
                })}
              >
                <img
                  className={cn(styles.SceneItem)}
                  crossOrigin="anonymous"
                  src={scene.productImage.url}
                  alt={scene.productImage.url}
                  onClick={() => onSceneChange(scene)}
                />
                {scene.isPremium && <IconPremium className={styles.iconPremium} />}
              </div>
            </div>
          ))}
        </div>
      </ContentBlock>
    </>
  );
}

export default React.memo(SceneModelsSelection);

import cn from 'classnames';
import React, { JSX, useMemo } from 'react';

import IconCheckCircle from 'editor/src/component/Icon/IconCheckCircle';
import IconInfo from 'editor/src/component/Icon/IconInfo';

import { ToastrType } from './useCustomToastr';

import styles from './index.module.scss';

interface Props {
  type: ToastrType;
  message: string;
  ActionButton?: JSX.Element;
  remove?: () => void;
}

function CustomToastrComponent({ message, type, ActionButton, remove }: Props) {
  const Icon = useMemo(() => {
    if (type === 'info') {
      return IconInfo;
    }
    if (type === 'success') {
      return IconCheckCircle;
    }

    return undefined;
  }, [type]);

  return (
    <div
      className={cn(styles.CustomToastrComponent, {
        [styles.info]: type === 'info',
        [styles.success]: type === 'success',
      })}
    >
      {!!Icon && <Icon className={styles.icon} />}
      {message}
      {!!ActionButton && (
        <div className={styles.actionButton} onClick={() => remove && remove()}>
          {ActionButton}
        </div>
      )}
    </div>
  );
}

export default React.memo(CustomToastrComponent);

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useSelector } from 'editor/src/store/hooks';

import CalendarEventsMobileMenu from 'editor/src/component//BottomBarMobile/ActionBarSection/CalendarEventsMobileMenu';
import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconCalendar from 'editor/src/component/Icon/IconCalendar';
import useMobileMenu from 'editor/src/component/useMobileMenu';

function MobileCalendarEventsTab() {
  const { t } = useTranslation();
  const activeTab = useSelector(getSidebarActiveTab);
  const isDraggingImage = useSelector((state) => state.editor.dragState === 'local');
  const calendarEventsMenu = useMobileMenu(false);

  useEffect(() => {
    calendarEventsMenu.hide(isDraggingImage);
  }, [isDraggingImage]);

  useEffect(() => {
    if (activeTab === TAB_NAMES.CALENDAR_EVENTS) {
      calendarEventsMenu.open();
    }
  }, [activeTab]);

  const closeCalendarEventsMenu = () => {
    calendarEventsMenu.close();
  };

  return (
    <>
      <Tab
        key={TAB_NAMES.CALENDAR_EVENTS}
        name={TAB_NAMES.CALENDAR_EVENTS}
        title={t('calendar-events')}
        icon={<IconCalendar />}
      />
      {calendarEventsMenu.render(<CalendarEventsMobileMenu closeCalendarEventsMenuCb={closeCalendarEventsMenu} />)}
    </>
  );
}

export default React.memo(MobileCalendarEventsTab);

import React from 'react';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import isTopMenuDropdownVisible from 'editor/src/store/app/selector/isTopMenuDropdownVisible';
import { TopMenuDropdown } from 'editor/src/store/app/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconElevateGold from 'editor/src/component/Icon/IconElevateGold';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import MenuButton from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';

import { canShow as canShowDropDown } from './index';

export const canShow = canShowDropDown;

type Props = Pick<MenuItemProps, 'mode' | 'close'>;

function ButtonImageEffects({ mode, close }: Props) {
  const isActive = useSelector((state) => isTopMenuDropdownVisible(state, TopMenuDropdown.ImageEffects));
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setActiveTopMenuDropdownOperation(TopMenuDropdown.ImageEffects));
    close?.();
  };

  return (
    <MenuButton
      premium
      PremiumIcon={IconElevateGold}
      on={isActive}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-effects"
      className="cy-button-image-effects"
    />
  );
}

export default React.memo(ButtonImageEffects);

import React from 'react';

import { MediaShape, StructureIndex } from 'editor/src/store/design/types';

import ElementName from 'editor/src/component/PersonalizationContent/PersonalizationElements/ElementName';

import styles from './index.module.scss';

interface Props {
  element: MediaShape;
  address: StructureIndex;
  icon: JSX.Element;
}
function ShapeLayer({ element, address, icon }: Props) {
  return (
    <ElementName
      element={element}
      address={address}
      ImageElement={icon}
      label={element.name}
      isBuilding
      className={styles.elementContainer}
      isNameEditable={false}
    />
  );
}

export default React.memo(ShapeLayer);

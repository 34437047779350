import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'editor/src/store/hooks';
import getPluginIcon from 'editor/src/store/plugins/selector/getPluginIcon';
import { PluginName } from 'editor/src/store/plugins/types';

import ControlButton from 'editor/src/component/ControlButton';
import useFilterPreview from 'editor/src/component/DesktopSidebar/TabContents/FiltersTabContent/Filter/useFilterPreview';
import { FilterInfo } from 'editor/src/component/DesktopSidebar/TabContents/FiltersTabContent/filters';

import styles from './index.module.scss';

interface Props {
  filter: FilterInfo;
  imageUrl: string;
  on: boolean;
  onClick: (filter: FilterInfo) => void;
  disabled?: boolean;
  showPremiumIcon?: boolean;
}
function FilterButton({ filter, imageUrl, on, onClick, disabled, showPremiumIcon }: Props) {
  const { t } = useTranslation();
  const imageWithFilterUrl = useFilterPreview(imageUrl, filter.adjustments, 150, 150, 'fill');
  const PremiumIcon = useSelector((state) => getPluginIcon(state, PluginName.Filters));

  return (
    <ControlButton
      key={filter.name}
      className={cn(styles.button, 'cy-filter-button', `cy-filter-button-${filter.name}`)}
      onClick={() => onClick(filter)}
      on={on}
    >
      <div className={styles.imagePlaceholder}>
        {imageWithFilterUrl && (
          <img crossOrigin="anonymous" className={styles.image} src={imageWithFilterUrl} alt="filter" />
        )}

        {(disabled || showPremiumIcon) && <PremiumIcon className={cn(styles.premium, 'cy-premium-icon')} />}
      </div>
      <span className={styles.label}>{t(filter.translationKey)}</span>
    </ControlButton>
  );
}

export default React.memo(FilterButton);

import { RootState } from 'editor/src/store/index';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import getUsedPersonalizationPlugins from '../../design/selector/getUsedPersonalizationPlugins';
import getSidebarActiveTab from '../../editorModules/sidebar/selector/getSidebarActiveTab';
import { ThunkDispatch } from '../../hooks';

const logPersonalizationLayerActivatedOperation =
  (personalizationCategory: string, origin?: string) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();

    const activeTab = getSidebarActiveTab(state);
    const usedPersonalizationPlugin = getUsedPersonalizationPlugins(state)[0];

    const activeTabName = typeof activeTab === 'string' ? activeTab : `${activeTab?.id.toLocaleLowerCase() || ''}_tab`;

    sendPostMessage('log.personalizationStudioEvent', {
      eventName: 'Personalization Studio Personalized Layer Activated',
      data: {
        origin: origin || activeTabName,
        plugin: usedPersonalizationPlugin,
        personalization_category: personalizationCategory,
      },
    });
  };

export default logPersonalizationLayerActivatedOperation;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import type { Thunk } from 'editor/src/store/hooks';
import { removeControlOptionAction } from 'editor/src/store/variants/slice';
import { ProductControlSize } from 'editor/src/store/variants/types';

import displaySelectedVariationOperation from './displaySelectedVariationOperation';
import selectExternalOptionOperation from './selectExternalOptionOperation';

const removeControlOptionOperation =
  (productControl: ProductControlSize, index: number): Thunk =>
  (dispatch, getState) => {
    batch(() => {
      const { selectedExternalOptions } = getState().variants;
      const controlOption = productControl.options[index];

      const controlOptions = productControl.options.filter((option) => option.value === controlOption.value);
      if (
        controlOptions.length === 1 &&
        selectedExternalOptions[productControl.key].some((option) => option.value === controlOption.value)
      ) {
        dispatch(selectExternalOptionOperation(productControl, controlOption));
        dispatch(displaySelectedVariationOperation());
      }
      dispatch(removeControlOptionAction({ controlKey: productControl.key, index }));
    });
  };

export default removeControlOptionOperation;

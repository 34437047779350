import { MediaElement } from 'editor/src/store/design/types';
import fontManager from 'editor/src/store/fonts/fontManager';

const containsPremiumFont = (element: MediaElement) => {
  if (element.type !== 'text') {
    return false;
  }

  const font = fontManager.loadedFontMap[element.extra.fontFamily];
  return !!font?.definition.metadata.isPremium;
};

export default containsPremiumFont;

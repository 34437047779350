import React from 'react';
import { useTranslation } from 'react-i18next';

import imageCropZoomInOperation from 'editor/src/store/design/operation/crop/imageCropZoomInOperation';
import isCropZoomInAvailable from 'editor/src/store/design/selector/isCropZoomInAvailable';
import { StructureIndex } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import IconZoomIn from 'editor/src/component/Icon/IconZoomIn';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  structureIndexes: StructureIndex[];
}

function ButtonCropZoomIn({ structureIndexes }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const enabled = useSelector((state) => isCropZoomInAvailable(state, structureIndexes[0]));

  const handleClick = () => {
    dispatch(imageCropZoomInOperation(structureIndexes[0]));
  };

  return (
    <WithTooltip overlay={t('editor-crop-zoom-in')}>
      <ControlButton onClick={handleClick} className="cy-button-crop-zoom-in" disabled={!enabled}>
        <IconZoomIn className={styles.zoomInIcon} />
      </ControlButton>
    </WithTooltip>
  );
}

export default React.memo(ButtonCropZoomIn);

import cn from 'classnames';
import React, { useEffect } from 'react';

import useListManager, { ListManagerContext } from './useListManager';
import useReorganizableListUpdate from './useReorganizableListUpdate';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  margin?: number;
  disableDragEffect?: boolean;
  indexOffset?: number;
  onIndexUpdate: (prevIndex: number, nextIndex: number) => void;
}

function ReorganizableList({ children, className, margin, onIndexUpdate, disableDragEffect, indexOffset = 0 }: Props) {
  const parentManager = React.useContext(ListManagerContext);
  const updateList = useReorganizableListUpdate();
  const manager = useListManager(margin, indexOffset, updateList, onIndexUpdate, !!parentManager, {
    disableDragEffect,
  });

  useEffect(() => {
    manager.update();
    updateList();
  });

  return (
    <div className={cn(styles.ReorganizableList, className)}>
      <ListManagerContext.Provider value={manager}>{children}</ListManagerContext.Provider>
    </div>
  );
}

export default ReorganizableList;

import cn from 'classnames';
import React, { useCallback } from 'react';

import applyLineStyleToSelectionOperation from 'editor/src/store/design/operation/shapes/applyLineStyleToSelectionOperation';
import getSelectedLineStylesProperty from 'editor/src/store/design/selector/shapes/getSelectedLineStylesProperty';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import ColorPicker from 'editor/src/component/ColorPicker';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from 'editor/src/component/DesktopSidebar/TabContents/colorTabContent.module.scss';

const DEFAULT_COLOR = '#000000';

function LineStrokeColorTabContent() {
  const currentColor = useSelector((state) => getSelectedLineStylesProperty(state, 'stroke'));
  const colorPickerConfig = useSelector((state) => getHostSetting(state, 'colorPickerConfig'));

  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const clickHandler = useCallback((stroke: string | undefined) => {
    stroke && dispatch(applyLineStyleToSelectionOperation({ stroke }));
  }, []);

  return (
    <ContentBlock scroll>
      <div
        className={cn('cy-line-stroke-color-tab-content', styles.wrapper, {
          [styles.mobile]: isMobile,
        })}
      >
        <ColorPicker
          color={currentColor || DEFAULT_COLOR}
          onColorChange={clickHandler}
          allowOpacity={colorPickerConfig.enableOpacity}
          colors={colorPickerConfig.allowedColors}
        />
      </div>
    </ContentBlock>
  );
}

export default React.memo(LineStrokeColorTabContent);

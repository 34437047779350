import { Area } from 'editor/src/store/design/operation/getNewElementDisplay';
import { MediaImage } from 'editor/src/store/design/types';

import { getRectangleBoundingBox } from 'editor/src/util/reflectDesignData/utils';

import getScaleAndCenterImageUpdates from './getScaleAndCenterImageUpdates';

const applyFitImageToSpread = (element: MediaImage, area: Area): Partial<MediaImage> => {
  const elementBbox = getRectangleBoundingBox(element.x, element.y, element.width, element.height, element.r);
  const scale = Math.min(area.width / elementBbox.width, area.height / elementBbox.height);

  return getScaleAndCenterImageUpdates(element, scale, area);
};

export default applyFitImageToSpread;

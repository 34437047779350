import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { Dimensions, ElementAddress } from 'editor/src/store/design/types';
import getAddonUrlById from 'editor/src/store/editorModules/addons/selector/getAddonUrlById';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import loadImageDimensions from 'editor/src/util/loadImageDimensions';

import getNewElementDisplay from './getNewElementDisplay';
import getNewImageDisplaySize from './getNewImageDisplaySize';

const resetImagePositionOperation =
  (elementAddress: ElementAddress) => async (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const element = getMediaElement(state, elementAddress);
    if (!element || (element.type !== 'image' && element.type !== 'addon')) {
      return;
    }

    let imageDimensions: Dimensions;
    if (element.type === 'image') {
      const galleryImage = getImageById(state, element.imageId);
      if (!galleryImage) {
        return;
      }
      imageDimensions = {
        width: galleryImage.width,
        height: galleryImage.height,
      };
    } else {
      const addonUrl = getAddonUrlById(state, element.addonId);
      if (!addonUrl) {
        return;
      }
      try {
        imageDimensions = await loadImageDimensions(addonUrl, 'anonymous', {
          executor: 'resetImageOperation',
          element,
        });
      } catch {
        return;
      }
    }

    const display = getNewElementDisplay(state, elementAddress.spreadIndex, (area) =>
      getNewImageDisplaySize(area, imageDimensions.width, imageDimensions.height),
    );
    if (!display) {
      return;
    }

    const { center, rotation, width, height } = display;

    const elementUpdate = {
      x: center.x,
      y: center.y,
      width,
      height,
      r: rotation,
      px: 0,
      py: 0,
      pw: width,
      ph: height,
      pr: 0,
    };

    batch(() => {
      dispatch(saveUndoRedoStateOperation('reset image'));
      dispatch(updateMediaElementAction({ elementAddress, elementUpdate }));
    });
  };

export default resetImagePositionOperation;

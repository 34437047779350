import { captureException } from '@sentry/react';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef } from 'react';
import { shallowEqual } from 'react-redux';

import { lastActions } from 'editor/src/store/actionLogListener';
import getDesignDataForExport from 'editor/src/store/design/selector/getDesignDataForExport';
import isElementOutOfRange from 'editor/src/store/design/util/isElementOutOfRange';
import { setWarningsAction } from 'editor/src/store/editorModules/warnings/slice';
import calculateDesignDataWarnings from 'editor/src/store/editorModules/warnings/utils/calculateDesignDataWarnings';
import {
  extendedWarningsToExportedWarnings,
  extendedWarningsToWarnings,
} from 'editor/src/store/editorModules/warnings/utils/warningMapper';
import fontManager from 'editor/src/store/fonts/fontManager';
import updateFontStatusOperation from 'editor/src/store/fonts/operation/updateFontStatusOperation';
import { FontStatus } from 'editor/src/store/fonts/types';
import deleteFont from 'editor/src/store/fonts/utils/deleteFont';
import getGalleryImages from 'editor/src/store/gallery/selector/getGalleryImages';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';

import formatDesignDataForLogging from 'editor/src/util/formatDesignDataForLogging';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import { getElementMaxSize } from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/SizeProperty/sizeUtils';

import { setDesignSavingStatusAction } from '../design/slice';

const outElementLogged = new Set<string>();

export function formatPreviousActionsForLogging(state: RootState, numOfActions: number) {
  return state.editorModules.undoRedo.previous.slice(numOfActions).map((previous) => ({
    selectedMediaElements: previous.selectedMediaElements,
    design: {
      ...previous.design,
      designData: formatDesignDataForLogging(previous.design.designData),
    },
  }));
}

function useDesignWatcher() {
  const store = useStore();
  const dispatch = useDispatch();

  const { designData, trackDesignChanges, enabledWarnings, digitizationRules } = useSelector(
    (state) => ({
      designData: state.design.designData,
      trackDesignChanges: state.editor.trackDesignChanges,
      enabledWarnings: state.hostSettings.enabledWarnings,
      digitizationRules: state.hostSettings.digitizationRules,
    }),
    shallowEqual,
  );

  const previousDesignData = useRef(designData);
  const galleryInitialized = useSelector((state) => getGalleryImages(state).length > 0);

  // tell the host the design structure changed
  useEffect(() => {
    // do not trigger if we had no design data previously or if the product was changed
    const state = store.getState();
    const { isVariantFlow } = state.variants;
    const extendedWarnings = designData ? calculateDesignDataWarnings(designData, state) : [];

    const warnings = extendedWarningsToWarnings(extendedWarnings);
    const warningsChanged = !isEqual(warnings, state.editorModules.warnings.list);

    if (warningsChanged) {
      dispatch(setWarningsAction(warnings));
    }

    if (
      designData &&
      previousDesignData.current &&
      (getDesignKeyFromDesign(previousDesignData.current) === getDesignKeyFromDesign(designData) || !isVariantFlow)
    ) {
      dispatch(setDesignSavingStatusAction('saving'));
      const exportDesignData = trackDesignChanges
        ? getDesignDataForExport(
            designData,
            state.gallery.images,
            state.editorModules.addons.inUse,
            extendedWarningsToExportedWarnings(extendedWarnings),
          )
        : undefined;
      sendPostMessage('design.structureChanged', exportDesignData || undefined);
    }

    previousDesignData.current = designData;
  }, [trackDesignChanges, designData, enabledWarnings, galleryInitialized]);

  useEffect(() => {
    const usedFont = new Set();

    designData?.spreads.forEach((spread) => {
      const firstPage = spread.pages[0];
      const elementMaxSize = getElementMaxSize(spread);

      firstPage?.groups.media?.forEach((media) => {
        if (media.type === 'text') {
          usedFont.add(media.extra.fontFamily);
        }

        if (isElementOutOfRange(media, elementMaxSize) && !outElementLogged.has(media.uuid + designData.product_uid)) {
          outElementLogged.add(media.uuid + designData.product_uid);
          captureException(new Error('Element out of range'), {
            extra: {
              element: media,
              designData: JSON.stringify(formatDesignDataForLogging(designData)),
              lastActions,
            },
          });
        }
      });
    });

    const state = store.getState();
    const defaultFontFamilies = new Set([
      state.fonts.defaultFontStyles.fontFamily,
      state.editor.currentStyles.text.fontFamily,
    ]);
    Object.keys(fontManager.loadedFontMap).forEach((font) => {
      if (!usedFont.has(font) && !defaultFontFamilies.has(font) && !fontManager.fallbackFonts.has(font)) {
        try {
          const deleted = deleteFont(font);
          if (deleted) {
            dispatch(
              updateFontStatusOperation({
                fontFile: font,
                fontStatus: FontStatus.new,
              }),
            );
          }
        } catch (e) {
          captureException(new Error(e || 'Error in deleting the font'), {
            extra: { font },
          });
        }
      }
    });
  }, [designData, digitizationRules]);
}

export default useDesignWatcher;

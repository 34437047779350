import { DesignDimensions } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getSizeOptionValue from 'editor/src/store/variants/helpers/getSizeOptionValue';
import { addControlOptionAction } from 'editor/src/store/variants/slice';
import { ProductControlSize, ProductSizeControlKey } from 'editor/src/store/variants/types';

import selectExternalOptionOperation from './selectExternalOptionOperation';

const setupProductSizeOperation = (dim?: DesignDimensions) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const { design, variants } = getState();
  const sizeControl = variants.product.externalProductControls.find(
    (control) => control.key === ProductSizeControlKey,
  ) as ProductControlSize;
  const dimensions = dim || design.designData?.dimensions;
  if (!sizeControl || !dimensions) {
    return;
  }

  const option = {
    width: dimensions.width,
    height: dimensions.height,
    value: getSizeOptionValue(dimensions.width, dimensions.height),
  };
  const isDefaultDimensions = dimensions.width === dimensions.min_width && dimensions.height === dimensions.min_height;
  if (
    !isDefaultDimensions &&
    !sizeControl.options.some((option) => option.width === dimensions.width && option.height === dimensions.height)
  ) {
    dispatch(addControlOptionAction({ controlKey: ProductSizeControlKey, option }));
  }

  const selectedOptions = variants.selectedExternalOptions[sizeControl.key];
  if (selectedOptions && selectedOptions.length === 0) {
    dispatch(selectExternalOptionOperation(sizeControl, option));
  }
};

export default setupProductSizeOperation;

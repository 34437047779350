import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconInfo from 'editor/src/component/Icon/IconInfo';

import styles from './index.module.scss';

function Information() {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.Information, 'mt-2')}>
      <IconInfo className="mr-2" />
      {t('Select a product or template to preview and download the mockup.')}
    </div>
  );
}

export default Information;

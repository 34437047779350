/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconCart({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3052 19.6389C19.3052 19.1172 19.1184 18.668 18.7475 18.2966C18.376 17.9258 17.9265 17.7389 17.4047 17.7389C16.8828 17.7389 16.4337 17.9257 16.0628 18.2965C15.6913 18.6679 15.5041 19.1172 15.5041 19.6389C15.5041 20.1607 15.6913 20.61 16.0628 20.9814C16.4337 21.3522 16.8828 21.5389 17.4047 21.5389C17.9265 21.5389 18.376 21.3521 18.7475 20.9813C19.1184 20.6099 19.3052 20.1607 19.3052 19.6389Z"
          fill="white"
          strokeWidth={0}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.9296 11.6365L6.73895 13.7966C6.40672 14.4134 6.42318 15.0399 6.78514 15.6611C7.1478 16.284 7.69052 16.5999 8.402 16.5999H18.3049C18.5852 16.5999 18.8247 16.5039 19.0166 16.3114C19.2092 16.1196 19.3052 15.8801 19.3052 15.5999C19.3052 15.3211 19.2139 15.0817 19.0298 14.889C18.8445 14.6957 18.6077 14.5999 18.3275 14.5999H8.57115L9.45141 12.9999H16.0993C16.4611 12.9999 16.787 12.9132 17.0742 12.7377C17.3603 12.5629 17.5896 12.3171 17.7624 12.0031L20.9582 6.24346L20.9601 6.23963C21.1377 5.88473 21.1483 5.53643 20.9827 5.20517C20.8991 5.03802 20.7781 4.90983 20.62 4.82443C20.4631 4.73961 20.275 4.6999 20.0605 4.6999H6.84479L6.26453 3.47527C6.18143 3.29287 6.05553 3.14983 5.88767 3.04915C5.72306 2.95041 5.54786 2.8999 5.36359 2.8999H3.90066C3.62046 2.8999 3.38094 2.99557 3.18897 3.18748C2.99647 3.37992 2.90039 3.61966 2.90039 3.8999C2.90039 4.1787 2.99154 4.41766 3.17571 4.60983C3.36099 4.80379 3.59784 4.8999 3.87815 4.8999H4.73765L7.9296 11.6365ZM7.79571 6.6999L9.86033 10.9999H16.0402L18.4059 6.6999H7.79571Z"
          fill="white"
          stroke="none"
          strokeWidth={0}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.06012 20.9814C7.43105 21.3522 7.88015 21.5389 8.402 21.5389C8.92384 21.5389 9.37295 21.3522 9.74388 20.9814C10.1154 20.61 10.3025 20.1607 10.3025 19.6389C10.3025 19.1172 10.1154 18.6679 9.74388 18.2965C9.37295 17.9257 8.92384 17.7389 8.402 17.7389C7.88015 17.7389 7.43105 17.9257 7.06012 18.2965C6.68863 18.6679 6.50146 19.1172 6.50146 19.6389C6.50146 20.1607 6.68863 20.61 7.06012 20.9814Z"
          fill="white"
          stroke="none"
          strokeWidth={0}
        />
      </svg>
    </Icon>
  );
}

export default IconCart;

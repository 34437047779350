import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'editor/src/component/Button';
import H1 from 'editor/src/component/Header/H1';
import IconArrowLeft from 'editor/src/component/Icon/IconArrowLeft';
import IconFilter from 'editor/src/component/Icon/IconFilter';
import RadioButton from 'editor/src/component/RadioButton';
import SearchInput from 'editor/src/component/SearchInput';

import ContentBlock from '../../Elements/ContentBlock';
import { filterOptions } from '../constants';
import { SearchParams } from '../types';

import styles from './index.module.scss';

interface Props {
  activeFilters: SearchParams;
  onChange: (searchParams: SearchParams) => void;
}

function FilterBlock({ activeFilters, onChange }: Props) {
  const { t } = useTranslation();
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  const onFilterOptionSelect = (filterKey: keyof SearchParams, optionValue?: string) => {
    onChange({
      ...activeFilters,
      [filterKey]: optionValue,
    });
  };
  const onSearchStringChange = (searchString: string) => {
    onChange({
      ...activeFilters,
      query: searchString,
    });
  };

  return (
    <div className={styles.filterBlock}>
      <Button secondary className={styles.filterButton} onClick={() => setIsFilterPanelOpen(true)}>
        <IconFilter />
      </Button>
      <SearchInput onChange={onSearchStringChange} />

      {isFilterPanelOpen && (
        <div className={cn('cy-tab-content', styles.filterModal)}>
          <ContentBlock noBorder>
            <H1 className={styles.title}>
              <span onClick={() => setIsFilterPanelOpen(false)} className={cn(styles.back, 'cy-back-btn')}>
                <IconArrowLeft />
              </span>
              {t('editor-back')}
            </H1>
          </ContentBlock>

          {filterOptions.map((filter) => (
            <ContentBlock key={filter.key}>
              <div className={styles.filterTitle}>{t(filter.label)}</div>
              {filter.options.map((option) => (
                <RadioButton
                  key={option.key}
                  type="button"
                  label={t(option.label)}
                  on={activeFilters[filter.key] === option.value}
                  disabled={false}
                  onChange={() => onFilterOptionSelect(filter.key, option.value)}
                />
              ))}
            </ContentBlock>
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(FilterBlock);

import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import doesSpreadHasMissingImages from 'editor/src/store/design/selector/doesSpreadHasMissingImages';
import { ContentAddress, Spread, SpreadGroup } from 'editor/src/store/design/types';
import loadFontOperation from 'editor/src/store/fonts/operation/loadFontOperation';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';

import useIsMounted from 'editor/src/util/useIsMounted';

import { createSpreadPreview, RequestRenderFn, SpreadPreviewDataURL } from 'editor/src/component/SpreadPreview';
import {
  PreviewOutput,
  SpreadPreviewBlob,
  SpreadPreviewCanvas,
  SpreadPreviewOptions,
} from 'editor/src/component/SpreadPreview/createSpreadPreview';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import getGroupedSpreadInfo from './getGroupedSpreadInfo';

function useSpreadPreview<Type extends PreviewOutput = 'dataURL'>(
  requestRender: RequestRenderFn,
  spread: Spread | undefined,
  designKey: string,
  spreadIndex: number,
  size: { dimension: 'height' | 'width' | 'both'; value: number },
  options: SpreadPreviewOptions<Type>,
  focusedContent?: ContentAddress,
  spreadGroup?: SpreadGroup,
  skipIsMounted?: boolean,
) {
  const store = useStore();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const { spreadBackgrounds, spreadForegrounds, gridDesigns, images, addons, digitizedAssets, calendar } = useSelector(
    (state) => {
      const spreadImages = state.variants.spreadImages[designKey];
      return {
        spreadBackgrounds: spreadImages ? spreadImages.spreadBackgrounds : state.design.spreadBackgrounds,
        spreadForegrounds: spreadImages ? spreadImages.spreadForegrounds : state.design.spreadForegrounds,
        gridDesigns: state.gridDesigns.grids,
        images: state.gallery.images,
        addons: state.editorModules.addons.inUse,
        digitizedAssets: state.design.digitizedAssets,
        calendar: state.design.designData?.calendar,
      };
    },
    shallowEqual,
  );
  const [preview, setPreview] =
    useState<
      Type extends 'blob' ? SpreadPreviewBlob : Type extends 'canvas' ? SpreadPreviewCanvas : SpreadPreviewDataURL
    >();

  const hasMissingImages = useMemo(() => doesSpreadHasMissingImages(spread, images), [spread, images]);
  const isMounted = skipIsMounted ? () => true : useIsMounted();

  function loadFont(fontFamily: string) {
    return dispatch(loadFontOperation(fontFamily));
  }

  useEffect(() => {
    if (!skipIsMounted) {
      setPreview(undefined);
    }
  }, [spreadIndex, skipIsMounted]);

  const version = useRef(0);
  const currentVersion = version.current;
  useMemo(() => {
    if (!spread) {
      return;
    }

    version.current += 1;
    const foregroundImage = spreadForegrounds?.find((image) => image.name === spread.name);
    const backgroundImage = spreadBackgrounds?.find((image) => image.name === spread.name);

    const spreads = store.getState().design.designData?.spreads;
    const spreadGroupInfo =
      spreads && spreadGroup
        ? getGroupedSpreadInfo(spreadGroup, spreads, spreadBackgrounds, spreadForegrounds)
        : undefined;

    void createSpreadPreview(
      designKey,
      spread,
      {
        backgroundImage,
        foregroundImage,
        gridDesigns,
        images,
        addons,
      },
      spreadIndex,
      focusedContent,
      size,
      requestRender,
      digitizedAssets,
      loadFont,
      isMobile,
      t,
      options,
      spreadGroup,
      spreadGroupInfo,
      calendar,
    ).then((preview) => {
      if (isMounted() && preview && currentVersion + 1 === version.current) {
        setPreview(preview as any);
      }
    });
  }, [spread, focusedContent, hasMissingImages, addons, designKey, spreadBackgrounds, spreadForegrounds, spreadGroup]);

  return { preview, isRendering: currentVersion !== version.current };
}

export default useSpreadPreview;

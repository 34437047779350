import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import imageCropZoomScaleOperation from 'editor/src/store/design/operation/crop/imageCropZoomScaleOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { MediaImage, StructureIndex } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import calculateCurrentCropZoomScale from 'editor/src/util/design/crop/calculateCurrentCropZoomScale';
import { MIN_CROP_ZOOM_LEVEL, MAX_CROP_ZOOM_LEVEL } from 'editor/src/util/design/crop/constants';
import getCropZoomScale from 'editor/src/util/design/crop/getCropZoomScale';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import Slider from 'editor/src/component/Slider';

import styles from './index.module.scss';

interface Props {
  structureIndexes: StructureIndex[];
}

function ZoomSlider({ structureIndexes }: Props) {
  const dispatch = useDispatch();
  const element = useSelector((state) => getMediaElement<MediaImage>(state, structureIndexes[0]));

  const currentZoom = useMemo(() => (element ? calculateCurrentCropZoomScale(element) : 0), [element]);

  const [zoomLevel, setZoomLevel] = useState(currentZoom);
  const lastSentLevel = useRef<number>(1);

  useEffect(() => {
    // if received from store currentCropZoom value equal last sent level do not update slider
    // it means that crop zoom currently drived by slider
    if (!currentZoom || Math.abs(lastSentLevel.current - (currentZoom || 0)) < 0.00001) {
      return;
    }
    setZoomLevel(currentZoom);
  }, [currentZoom]);

  const { liveUpdate } = useMediaElementLiveUpdates(element);

  const onChange = (level: number) => {
    lastSentLevel.current = level;
    setZoomLevel(level);
    if (element) {
      liveUpdate({ ...element, ...getCropZoomScale(element, level) });
    }
  };

  const handleAfterChange = () => {
    if (structureIndexes[0]) {
      dispatch(imageCropZoomScaleOperation(structureIndexes[0], lastSentLevel.current));
    }
  };

  const sliderStep = (MAX_CROP_ZOOM_LEVEL - MIN_CROP_ZOOM_LEVEL) / 100;

  return (
    <Slider
      min={MIN_CROP_ZOOM_LEVEL}
      max={MAX_CROP_ZOOM_LEVEL}
      step={sliderStep}
      value={zoomLevel}
      onChange={onChange}
      className={cn(styles.ZoomSlider, 'cy-slider-crop-zoom')}
      onAfterChange={handleAfterChange}
    />
  );
}

export default React.memo(ZoomSlider);

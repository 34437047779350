import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Coords, Page } from 'editor/src/store/design/types';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import { CanvasRotation, VIEWPORT_CHANGED_EVENT } from 'editor/src/component/EditorArea/types';
import useCanvasRotation from 'editor/src/component/EditorArea/useCanvasRotation';
import IconInfo from 'editor/src/component/Icon/IconInfo';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  page: Page;
  spreadCoords: Coords;
  spreadHeight: number;
  canvasRotation: CanvasRotation;
}

const INFO_ICON_SIZE = 20;
const INFO_ICON_PADDING = 6;

function InfoIcon({ page, spreadCoords, spreadHeight, canvasRotation }: Props) {
  const infoContainerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const fabricCanvas = useFabricCanvas();
  const { mm2px } = useFabricUtils();
  const contentX = spreadCoords.left + mm2px(page?.x ?? 0);
  const contentY = spreadCoords.top + mm2px(page?.y ?? 0);
  const rotatedPoint = useCanvasRotation(canvasRotation, contentX, contentY);

  const getPosition = useCallback(() => {
    const offsetX = fabricCanvas.viewportTransform ? fabricCanvas?.viewportTransform[4] : 0;
    const offsetY = fabricCanvas.viewportTransform ? fabricCanvas?.viewportTransform[5] : 0;
    const zoom = fabricCanvas.getZoom();
    const left = (rotatedPoint.x - INFO_ICON_SIZE + INFO_ICON_PADDING) * zoom + offsetX;
    const top = (rotatedPoint.y + spreadHeight - INFO_ICON_SIZE) * zoom + offsetY;
    return { top, left, zoom };
  }, [rotatedPoint, canvasRotation, spreadHeight]);

  useEffect(() => {
    const onViewportChange = () => {
      if (!infoContainerRef.current) {
        return;
      }
      const { top, left, zoom } = getPosition();
      infoContainerRef.current.style.transform = `translate3d(${left}px,${top}px,0) scale(${zoom})`;
    };

    fabricCanvas.on(VIEWPORT_CHANGED_EVENT, onViewportChange);
    return () => {
      fabricCanvas.off(VIEWPORT_CHANGED_EVENT, onViewportChange);
    };
  }, [getPosition]);

  const position = getPosition();

  return (
    <div
      className={styles.infoIconContainer}
      ref={infoContainerRef}
      style={{
        width: `${INFO_ICON_SIZE}px`,
        height: `${INFO_ICON_SIZE}px`,
        padding: `0 ${INFO_ICON_PADDING}px`,
        transform: `translate3d(${position.left}px,${position.top}px,0) scale(${position.zoom})`,
      }}
    >
      <WithTooltip
        overlay={t('This area may be cropped depending on the calendar size for the order region.')}
        placement="top"
        tappable
      >
        <IconInfo className={styles.infoIcon} />
      </WithTooltip>
    </div>
  );
}

export default React.memo(InfoIcon);

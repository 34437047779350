import React, { useEffect, useRef } from 'react';

import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import getStructureIndexesOfSelectedElements from 'editor/src/store/design/selector/getStructureIndexesOfSelectedElements';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import getSidebarActiveTab from 'editor/src/store/editorModules/sidebar/selector/getSidebarActiveTab';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import RectangleFillColorTabContent from 'editor/src/component/DesktopSidebar/TabContents/RectangleFillColorTabContent';
import RectangleStrokeColorTabContent from 'editor/src/component/DesktopSidebar/TabContents/RectangleStrokeColorTabContent';
import LineStylesControl from 'editor/src/component/LineStylesControl';
import RectangleStylesControl from 'editor/src/component/RectangleStylesControl';
import TextSpacingControl from 'editor/src/component/TextSpacingControl';

import TextFittingControl from '../../TextFittingControl';

import ActionBar from './ActionBar';
import CropZoomActionBar from './CropZoomActionBar';
import LineEdgeActionBar from './LineEdgeActionBar';
import PositionActionBar from './PositionActionBar';
import LineStrokeColorActionBar from './ShapeStrokeColorActionBar';
import TextAlignActionBar from './TextAlignActionBar';

import styles from './index.module.scss';

function ActionBarSection() {
  const hideWhenSelectionLost = useRef(false);
  const hideWhenSelectionMade = useRef(false);
  const hideWhenNoImageSelected = useRef(false);
  const actionBarSectionRef = useRef<HTMLDivElement>(null);

  const structureIndexes = useSelector(getStructureIndexesOfSelectedElements);
  const activeTab = useSelector(getSidebarActiveTab);
  const selectedElements = useSelector(getSelectedMediaElementsObjects);
  const firstElement = selectedElements[0];
  const dispatch = useDispatch();

  // THIS WHOLE STUFF SHOULD BE DONE IN THE OPERATIONS THAT TWEAK SELECTION...
  useEffect(() => {
    if (
      (!selectedElements.length && hideWhenSelectionLost.current) ||
      (selectedElements.length && hideWhenSelectionMade.current)
    ) {
      dispatch(setSidebarActiveTabOperation(-1));
      return;
    }
    if (
      selectedElements.length &&
      hideWhenNoImageSelected.current &&
      !selectedElements.find((el) => el && (el.type === 'image' || el.type === 'addon'))
    ) {
      dispatch(setSidebarActiveTabOperation(-1));
    }
  }, [selectedElements.length]);

  const defineHideConditions = (
    hideWhenNoSelection: boolean,
    hideWhenSelection: boolean,
    hideWhenNoImageSelection: boolean,
  ) => {
    hideWhenSelectionLost.current = hideWhenNoSelection;
    hideWhenSelectionMade.current = hideWhenSelection;
    hideWhenNoImageSelected.current = hideWhenNoImageSelection;
  };

  const updateActionBarHideConditions = () => {
    switch (activeTab) {
      case TAB_NAMES.LAYOUTS:
        defineHideConditions(false, true, false);
        break;
      case TAB_NAMES.GALLERY:
        defineHideConditions(true, false, true);
        break;
      default:
        defineHideConditions(true, false, false);
        break;
    }
  };

  updateActionBarHideConditions();

  return (
    <div ref={actionBarSectionRef} className={styles.actionBarSection}>
      <ActionBar name={TAB_NAMES.POSITION} noPreserve activeTab={activeTab}>
        <PositionActionBar structureIndexes={structureIndexes} selectedElements={selectedElements} />
      </ActionBar>

      <ActionBar name={TAB_NAMES.TEXT_ALIGN} noPreserve activeTab={activeTab}>
        <TextAlignActionBar />
      </ActionBar>

      <ActionBar name={TAB_NAMES.TEXT_SPACING} noPreserve activeTab={activeTab}>
        <TextSpacingControl />
      </ActionBar>

      <ActionBar name={TAB_NAMES.TEXT_FITTING} noPreserve activeTab={activeTab}>
        <TextFittingControl />
      </ActionBar>

      <ActionBar name={TAB_NAMES.CROP_ZOOM} noPreserve activeTab={activeTab}>
        {structureIndexes.length > 0 && <CropZoomActionBar structureIndexes={structureIndexes} />}
      </ActionBar>

      <ActionBar name={TAB_NAMES.LINE_STYLES} noPreserve activeTab={activeTab}>
        {firstElement?.type === 'line' && <LineStylesControl selectedElement={firstElement} />}
      </ActionBar>

      <ActionBar name={TAB_NAMES.LINE_STROKE_COLOR} noPreserve activeTab={activeTab}>
        <LineStrokeColorActionBar />
      </ActionBar>

      <ActionBar name={TAB_NAMES.LINE_EDGE_1} noPreserve activeTab={activeTab}>
        {firstElement?.type === 'line' && <LineEdgeActionBar currentEdge={firstElement.edge1} edge="edge1" />}
      </ActionBar>

      <ActionBar name={TAB_NAMES.LINE_EDGE_2} noPreserve activeTab={activeTab}>
        {firstElement?.type === 'line' && <LineEdgeActionBar currentEdge={firstElement.edge2} edge="edge2" />}
      </ActionBar>

      <ActionBar name={TAB_NAMES.RECTANGLE_FILL_COLOR} noPreserve activeTab={activeTab}>
        <RectangleFillColorTabContent />
      </ActionBar>

      <ActionBar name={TAB_NAMES.RECTANGLE_STROKE_COLOR} noPreserve activeTab={activeTab}>
        <RectangleStrokeColorTabContent />
      </ActionBar>

      <ActionBar name={TAB_NAMES.RECTANGLE_STYLES} fullWidth noPreserve activeTab={activeTab}>
        {firstElement?.type === 'rectangle' && <RectangleStylesControl selectedElement={firstElement} />}
      </ActionBar>
    </div>
  );
}

export default React.memo(ActionBarSection);

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import getDesignColorSpace from 'editor/src/store/design/selector/getDesignColorSpace';
import { useSelector } from 'editor/src/store/hooks';

import ColorControllers from 'editor/src/component/ColorPicker/ColorControllers';
import ResetButton from 'editor/src/component/ColorPicker/ResetButton';

interface Props {
  color: string | undefined;
  onColorChange: (color: string | undefined) => void;
  allowNoColor?: true;
}

function ColorControl({ color, onColorChange, allowNoColor }: Props) {
  const { t } = useTranslation();
  const colorSpace = useSelector(getDesignColorSpace);

  const onResetColor = useCallback(() => {
    if (allowNoColor) {
      onColorChange(undefined);
    }
  }, [allowNoColor]);

  return (
    <div className="cy-color-picker">
      {allowNoColor && <ResetButton label={t('Any color')} active={!color} onReset={onResetColor} />}
      <ColorControllers
        colorSpace={colorSpace}
        color={color}
        hideRGB={(allowNoColor && !color) || undefined}
        onColorChange={onColorChange}
        allowOpacity={false}
      />
    </div>
  );
}

export default React.memo(ColorControl);

import React from 'react';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import isTopMenuDropdownVisible from 'editor/src/store/app/selector/isTopMenuDropdownVisible';
import { TopMenuDropdown } from 'editor/src/store/app/types';
import getSelectedLineStylesProperty from 'editor/src/store/design/selector/shapes/getSelectedLineStylesProperty';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import IconFlatLine from 'editor/src/component/Icon/IconFlatLine1';
import { LINE_EDGE_1_OPTIONS } from 'editor/src/component/LineEdgeControl';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import MenuButton from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';

type Props = Pick<MenuItemProps, 'mode' | 'close' | 'isMobile'>;

function ButtonLineEdge1({ mode, close, isMobile }: Props) {
  const isActive = useSelector((state) =>
    isMobile
      ? isSidebarTabActive(state, TAB_NAMES.LINE_EDGE_1)
      : isTopMenuDropdownVisible(state, TopMenuDropdown.LineEdge1),
  );

  const dispatch = useDispatch();

  const handleClick = () => {
    if (isMobile) {
      if (isActive) {
        dispatch(setSidebarActiveTabOperation(-1));
      } else {
        dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.LINE_EDGE_1));
      }
    } else {
      dispatch(setActiveTopMenuDropdownOperation(TopMenuDropdown.LineEdge1));
      close?.();
    }
  };

  const currentEdge = useSelector((state) => getSelectedLineStylesProperty(state, 'edge1'));
  const selectedIconOption = LINE_EDGE_1_OPTIONS.find((option) => option.value === currentEdge);

  return (
    <MenuButton
      Icon={selectedIconOption ? selectedIconOption.Icon : IconFlatLine}
      on={isActive}
      mode={mode}
      handleClick={handleClick}
      label="Edge Left"
      className="cy-button-line-edge-1"
    />
  );
}

export default React.memo(ButtonLineEdge1);

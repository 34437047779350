import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import getImageElementUrl from 'editor/src/store/design/selector/getImageElementUrl';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { MimeType } from 'editor/src/store/gallery/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import isPremiumIconVisible from 'editor/src/store/plugins/selector/isPremiumIconVisible';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import CarouselContainer from 'editor/src/component/Carousel/CarouselContainer';
import Filter from 'editor/src/component/DesktopSidebar/TabContents/FiltersTabContent/Filter';
import FILTERS from 'editor/src/component/DesktopSidebar/TabContents/FiltersTabContent/filters';

import styles from './index.module.scss';

const FILTERED_FILTERS = FILTERS.slice(0, 5);
const ALLOW_FREE_FILTERS = 1;

function FiltersCarousel() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const uuid = useSelector(getSelectedImageUuid);
  const elements = useSelector(getSelectedMediaElementsObjects);
  const imageType = useSelector(
    (state) => getImageById(state, elements[0]?.type === 'image' ? elements[0].imageId : '')?.type,
  );
  const { imageUrl, image, address } = useSelector((state) => {
    const firstElement = getSelectedMediaElementsObjects(state)[0];
    if (firstElement?.type !== 'image') {
      return {};
    }
    const imageInfo = getImageElementUrl(state, firstElement);
    return {
      address: uuid ? getStructureIndexByElementUuidMemoized(state, uuid) : undefined,
      image: firstElement,
      imageUrl: imageInfo?.crossOrigin === 'anonymous' ? imageInfo.imageUrl : undefined,
    };
  }, shallowEqual);
  const nonFunctional = useSelector(
    (state) => getPlugin(state, PluginName.Filters)?.state === PluginState.NON_FUNCTIONAL,
  );
  const premiumIconVisible = useSelector((state) => isPremiumIconVisible(state, PluginName.Filters));

  const [active, setActive] = useState((image?.filters || [])[0]?.name);

  if (!uuid || !address || !imageUrl || imageType === MimeType.SVG) {
    return null;
  }

  const showAll = () => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.FILTERS));
  };

  return (
    <div className="cy-filter-carousel">
      <CarouselContainer title={t('editor-filters')} showAll={showAll}>
        {FILTERED_FILTERS.map((filter, index) => (
          <Filter
            element={image}
            filter={filter}
            address={address}
            imageUrl={imageUrl}
            key={filter.name}
            active={filter.name === active}
            onClick={setActive}
            rowActive={false}
            disabled={nonFunctional && index >= ALLOW_FREE_FILTERS}
            showPremiumIcon={premiumIconVisible && index >= ALLOW_FREE_FILTERS}
            showAdjustmentSlider={false}
            filterClassName={styles.filterItemEditImage}
          />
        ))}
      </CarouselContainer>
    </div>
  );
}

export default FiltersCarousel;

import { MediaLine } from 'editor/src/store/design/types';

export type LineStyles = Pick<MediaLine, 'stroke' | 'strokeWidth' | 'edge1' | 'edge2' | 'rounded'>;

const defaultLineStyles: LineStyles = {
  stroke: '#000',
  strokeWidth: 1,
  edge1: 'flat',
  edge2: 'flat',
  rounded: false,
};

export default defaultLineStyles;

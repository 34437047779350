import React from 'react';

import getTextEditMode from 'editor/src/store/editor/selector/getTextEditMode';
import { setTextEditModeAction as setTextEditModeOperation } from 'editor/src/store/editor/slice';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import IconEdit from 'editor/src/component/Icon/IconEdit';

import styles from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignActionButton/index.module.scss';

function ButtonEnterEditTextMode() {
  const textEditMode = useSelector(getTextEditMode);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setSidebarActiveTabOperation(-1));
    dispatch(setTextEditModeOperation(!textEditMode));
  };

  return (
    <ControlButton className={styles.alignTabButton} onClick={handleClick} on={textEditMode}>
      <IconEdit />
    </ControlButton>
  );
}

export default React.memo(ButtonEnterEditTextMode);

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconArrowFill1({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2042_11415)">
          <path
            d="M9 16L19 21.7735L19 10.2265L9 16ZM32 17L33 17L33 15L32 15L32 17ZM18 17L32 17L32 15L18 15L18 17Z"
            fill="#383838"
          />
        </g>
        <defs>
          <clipPath id="clip0_2042_11415">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}

export default IconArrowFill1;

import { MimeType } from 'editor/src/store/gallery/types';
import { ImageManipulationPlugin } from 'editor/src/store/plugins/types';

import { MenuContext } from 'editor/src/component/Menu/type';

import canUsePluginToEditImage from './canUsePluginToEditImage';

export default function canUseModificationOnImage(context: MenuContext, plugin: ImageManipulationPlugin) {
  const { exceptionalImageTypes } = plugin.extra;
  const canUseModificationOnImageType =
    context.imageType &&
    context.imageType !== MimeType.SVG &&
    (!exceptionalImageTypes?.length || !exceptionalImageTypes.includes(context.imageType));

  return !!canUseModificationOnImageType && canUsePluginToEditImage(plugin.name, context);
}

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconOffline({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        style={{ strokeWidth: 0 }}
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M17.0909 19L14.6818 16.7677H5C3.60606 16.7677 2.42424 16.3098 1.45455 15.3939C0.484848 14.4781 0 13.362 0 12.0455C0 10.9436 0.359848 9.96338 1.07955 9.1048C1.79924 8.24621 2.72727 7.69529 3.86364 7.45202C3.90909 7.33754 3.95455 7.22664 4 7.11932C4.04545 7.012 4.09091 6.89394 4.13636 6.76515L0.363636 3.20202L1.63636 2L18.3636 17.798L17.0909 19ZM5 15.0505H12.8636L5.54545 8.13889C5.51515 8.2963 5.49242 8.44655 5.47727 8.58965C5.46212 8.73274 5.45455 8.883 5.45455 9.0404H5C4.12121 9.0404 3.37121 9.33375 2.75 9.92045C2.12879 10.5072 1.81818 11.2155 1.81818 12.0455C1.81818 12.8754 2.12879 13.5838 2.75 14.1705C3.37121 14.7572 4.12121 15.0505 5 15.0505ZM18.7273 15.6944L17.4091 14.4924C17.6667 14.2921 17.8598 14.0596 17.9886 13.7948C18.1174 13.5301 18.1818 13.2332 18.1818 12.904C18.1818 12.303 17.9621 11.795 17.5227 11.3801C17.0833 10.9651 16.5455 10.7576 15.9091 10.7576H14.5455V9.0404C14.5455 7.85269 14.1023 6.84028 13.2159 6.00316C12.3295 5.16604 11.2576 4.74747 10 4.74747C9.59091 4.74747 9.19697 4.79398 8.81818 4.887C8.43939 4.98001 8.07576 5.12668 7.72727 5.32702L6.40909 4.08207C6.93939 3.73864 7.50379 3.47748 8.10227 3.29861C8.70076 3.11974 9.33333 3.0303 10 3.0303C11.7727 3.0303 13.2765 3.61343 14.5114 4.77967C15.7462 5.94592 16.3636 7.36616 16.3636 9.0404C17.4091 9.15488 18.2765 9.5806 18.9659 10.3176C19.6553 11.0545 20 11.9167 20 12.904C20 13.4621 19.8864 13.9809 19.6591 14.4602C19.4318 14.9396 19.1212 15.351 18.7273 15.6944Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}

export default IconOffline;

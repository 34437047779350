import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setStateAction } from 'editor/src/store/design/slice';
import { setSelectedElementUuidsAction } from 'editor/src/store/editor/slice';
import { SavedState } from 'editor/src/store/editorModules/undoRedo/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { applyStateAction, setECommerceProductAction, setPageCountAction } from 'editor/src/store/variants/slice';

import { RootState } from 'editor/src/store';

import sendPostMessage from '../../../../util/postMessages/sendPostMessage';

const applySavedStoreStateOperation = (savedData: SavedState, state: RootState) => (dispatch: ThunkDispatch) => {
  const {
    design,
    selectedMediaElements,
    variantsProduct,
    variantsSelectedSingleOptions,
    variantsSelectedMultiOptions,
    variantsVariationGroups,
    variantsSelectedGroupKey,
    designOptionsEnabled,
    designOptionsControl,
  } = savedData;

  batch(() => {
    dispatch(setStateAction(design));

    if (state.editor.selectedElementUuids !== selectedMediaElements) {
      dispatch(setSelectedElementUuidsAction(selectedMediaElements));
    }

    if (savedData.selectedPageCount && savedData.selectedPageCount !== state.variants.selectedPageCount) {
      dispatch(setPageCountAction(savedData.selectedPageCount));
    }

    if (
      savedData.ecommerceProduct &&
      savedData.ecommerceProduct.selectedVariantId !== state.variants.eCommerceProduct?.selectedVariantId
    ) {
      dispatch(setECommerceProductAction(savedData.ecommerceProduct));
      sendPostMessage('variants.changeECommerceVariant', {
        gelatoVariantId: savedData.ecommerceProduct.selectedVariantId,
      });
    }

    dispatch(
      applyStateAction({
        product: variantsProduct,
        selectedGroupKey: variantsSelectedGroupKey,
        variationGroups: variantsVariationGroups,
        selectedMultiOptions: variantsSelectedMultiOptions,
        selectedSingleOptions: variantsSelectedSingleOptions,
        designOptionsEnabled,
        designOptionsControl,
      }),
    );
  });
};

export default applySavedStoreStateOperation;

import { MultiOptions, Product, ProductControlOptionColor } from 'editor/src/store/variants/types';

function getUnavailableVariations(product: Product, options: MultiOptions) {
  const { unAvailableVariantsGroupBy } = product;
  if (!unAvailableVariantsGroupBy) {
    return {};
  }
  const optionSelectedByGroupKey = options[unAvailableVariantsGroupBy];

  const unAvailableOptions: {
    [key: string]: ProductControlOptionColor[];
  } = {};
  optionSelectedByGroupKey?.forEach((groupByOption) => {
    Object.keys(options).forEach((key) => {
      if (key !== unAvailableVariantsGroupBy) {
        const controls = product.productControls.find((control) => control.key === key);
        options[key].forEach((otherOption) => {
          const otherOptionValue = otherOption.value;
          const groupByOptionValue = groupByOption.value;
          const variantExists = product.productVariations.find(
            (variation) =>
              variation[unAvailableVariantsGroupBy] === groupByOptionValue && variation[key] === otherOptionValue,
          );
          if (!variantExists) {
            const unAvailableOption = unAvailableOptions?.[groupByOptionValue] ?? [];
            const option = controls?.options.find((option) => option.value === otherOptionValue);
            if (option && controls?.uiType === 'color') {
              unAvailableOption.push(option as ProductControlOptionColor);
              unAvailableOptions[groupByOptionValue] = unAvailableOption;
            }
          }
        });
      }
    });
  });

  return unAvailableOptions;
}

export default getUnavailableVariations;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import getStructureIndexesOfSelectedElements from 'editor/src/store/design/selector/getStructureIndexesOfSelectedElements';
import { useSelector } from 'editor/src/store/hooks';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';

import DrawerHeader from '../../DrawerHeader';
import PersonalizationActionBar from '../PersonalizationActionBar';

import styles from './index.module.scss';

interface Props {
  closeMenuCb?: () => void;
}
function PersonalizationMobileMenu({ closeMenuCb }: Props) {
  const { t } = useTranslation();
  const structureIndexes = useSelector(getStructureIndexesOfSelectedElements);
  const selectedElements = useSelector(getSelectedMediaElementsObjects);
  const firstElement = selectedElements[0];

  if (structureIndexes.length === 0 || !firstElement) {
    return null;
  }

  return (
    <div className={cn(styles.PersonalizationMobileMenu, 'cy-tab-personalization')}>
      <DrawerHeader title={t('editor-personalization')} closeMenu={closeMenuCb} />
      <ContentBlock fillFlex scroll>
        <div className={styles.PersonalizationActionBarWrapper}>
          <PersonalizationActionBar structureIndex={structureIndexes[0]} selectedElement={firstElement!} />
        </div>
      </ContentBlock>
    </div>
  );
}

export default React.memo(PersonalizationMobileMenu);

import React, { useCallback } from 'react';

import isAnyMediaElementInCropMode from 'editor/src/store/editor/selector/isAnyMediaElementInCropMode';
import { setSelectedElementUuidsAction } from 'editor/src/store/editor/slice';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';

import useMenuContext from 'editor/src/component/Menu/useMenuContext';

import ControlButton from '../../ControlButton';
import IconCross from '../../Icon/IconCross';

import CropControls from './CropControls';
import ImageControls from './ImageControls';
import LineControls from './LineControls';
import RectangleControls from './RectangleControls';
import TextControls from './TextControls';

import styles from './index.module.scss';

interface Props {
  controls: React.ComponentType[];
  overwrite: boolean;
}

function SectionContent({ controls, overwrite }: Props) {
  const dispatch = useDispatch();
  const menuContext = useMenuContext();
  const store = useStore();
  const rootState = store.getState();
  const isInCropMode = useSelector((state) => isAnyMediaElementInCropMode(state));

  const imageOrAddonSelected =
    menuContext.selectedElementType === 'image' || menuContext.selectedElementType === 'addon';

  const closeSectionContent = useCallback(() => {
    dispatch(setSelectedElementUuidsAction([]));
  }, []);

  return (
    <>
      <ControlButton onClick={closeSectionContent} className={styles.closeBtn}>
        <IconCross />
      </ControlButton>
      {!overwrite && (
        <>
          {isInCropMode ? (
            <CropControls menuContext={menuContext} />
          ) : (
            imageOrAddonSelected && <ImageControls rootState={rootState} menuContext={menuContext} />
          )}
          {menuContext.selectedElementType === 'text' && (
            <TextControls rootState={rootState} menuContext={menuContext} />
          )}
          {menuContext.selectedElementType === 'line' && (
            <LineControls rootState={rootState} menuContext={menuContext} />
          )}
          {menuContext.selectedElementType === 'rectangle' && (
            <RectangleControls rootState={rootState} menuContext={menuContext} />
          )}
        </>
      )}
      {controls.map((PluginControls, idx) => (
        <PluginControls key={idx} />
      ))}
    </>
  );
}

export default React.memo(SectionContent);

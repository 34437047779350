import { selectImagesAction } from 'editor/src/store/gallery/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import { RootState } from '../..';
import getImageIdsUsage from '../../design/util/getImageIdsUsage';

const selectAllImagesOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const usedImagesIds = getImageIdsUsage(state.design?.designData);
  const imageToSelect = state.gallery.hideUsedPhotos
    ? state.gallery.images.filter((image) => !image.hidden && !usedImagesIds.has(image.id))
    : state.gallery.images.filter((image) => !image.hidden);

  dispatch(selectImagesAction(imageToSelect.map((image) => image.id)));
};

export default selectAllImagesOperation;

import { DimensionRestrictions, Dimensions } from 'editor/src/store/design/types';
import getSizeOptionValue from 'editor/src/store/variants/helpers/getSizeOptionValue';

import { Size } from './ProductSize';

export interface AdditionalSizeInfo {
  maxImageDimensions: Dimensions;
  dpi: number;
}

export function getSizeValue(size: Size) {
  return getSizeOptionValue(size.width, size.height);
}

export function sizeMeetsRestrictions(size: Dimensions, restrictions: DimensionRestrictions): boolean {
  return (
    size.height >= restrictions.min_height &&
    size.height <= restrictions.max_height &&
    size.width >= restrictions.min_width &&
    size.width <= restrictions.max_width
  );
}

import cn from 'classnames';
import React, { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { TopMenuDropdown } from 'editor/src/store/app/types';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import getActiveImageManipulationPlugins from 'editor/src/util/plugins/getActiveImageManipulationPlugins';

import IconProperties from 'editor/src/component/Icon/IconProperties';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';
import ButtonAdjustments, {
  canShowInTopBar as adjustmentsCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonAdjustments';
import ButtonFilters, {
  canShowInTopBar as filtersCanShow,
} from 'editor/src/component/TopMenuDesktop/buttons/ButtonFilters';
import ButtonImageManipulation from 'editor/src/component/TopMenuDesktop/buttons/ButtonImageManipulation';
import ButtonResetImageEffects from 'editor/src/component/TopMenuDesktop/buttons/ButtonResetImageEffects';
import ResetEffectsModal from 'editor/src/component/TopMenuDesktop/buttons/ButtonResetImageEffects/ResetEffectsModal';
import ButtonWithDropdownNotWrapped from 'editor/src/component/TopMenuDesktop/buttons/ButtonWithDropdownNotWrapped';
import MenuDropDownButton from 'editor/src/component/TopMenuDesktop/buttons/MenuDropDownButton';

import useResetEffectsModal from './useResetEffectsModal';

import styles from './index.module.scss';

export const canShow: CanShow = (state, context) => {
  const showFilters = filtersCanShow(state, context);
  const showAdjustments = adjustmentsCanShow(state, context);
  const activeManipulationPlugins = getActiveImageManipulationPlugins(context);
  return (
    !getHostSetting(state, 'editImageFlag') && (showFilters || showAdjustments || activeManipulationPlugins.length > 0)
  );
};

function ButtonImageEffectsDropDown({ mode, context, isMobile }: MenuItemProps) {
  const { showFilters, showAdjustments } = useSelector(
    (state) => ({
      showFilters: filtersCanShow(state, context),
      showAdjustments: adjustmentsCanShow(state, context),
    }),
    shallowEqual,
  );

  const element = useSelector((state) => getMediaElement(state, context.structureIndexes[0]));
  const { openConfirmModal, closeConfirmModal, resetEffects, hasEffectsApplied } = useResetEffectsModal(
    element,
    context.plugins,
  );

  const activeManipulationPlugins = useMemo(() => getActiveImageManipulationPlugins(context), [context]);
  if (!showFilters && !showAdjustments && activeManipulationPlugins.length === 0) {
    return null;
  }

  return (
    <>
      <ButtonWithDropdownNotWrapped
        dropDownName={TopMenuDropdown.ImageEffects}
        buttonContent={<MenuDropDownButton Icon={IconProperties} mode={mode} i18nKey="editor-effects" />}
        buttonExtraClass={cn('cy-button-image-effects', styles.button)}
        keepTabOpenedOnClose
        premium
        dropdownExtraClass={cn('noButtonPadding', 'cy-image-effects-dropdown')}
        dropDownContent={
          <div className={styles.dropdownContent}>
            {showFilters && <ButtonFilters isMobile={isMobile} mode="label-icon" context={context} />}
            {showAdjustments && <ButtonAdjustments isMobile={isMobile} mode="label-icon" context={context} />}
            {activeManipulationPlugins.map((plugin) => (
              <ButtonImageManipulation key={plugin.name} plugin={plugin} mode="label-icon" />
            ))}
            <ButtonResetImageEffects mode="label-icon" disabled={!hasEffectsApplied} onClick={openConfirmModal} />
          </div>
        }
      />
      <ResetEffectsModal onClose={closeConfirmModal} onAccept={() => resetEffects()} />
    </>
  );
}

export default React.memo(ButtonImageEffectsDropDown);

import React from 'react';
import { useTranslation } from 'react-i18next';

import IconPersonsalisation from 'editor/src/component/Icon/IconPersonsalisation';

import Badge from './Badge';

function PersonalisationBadge() {
  const { t } = useTranslation();
  return (
    <Badge tooltip={t('editor-customer-can-personalize')} className="cy-personalisation-badge">
      <IconPersonsalisation />
    </Badge>
  );
}

export default React.memo(PersonalisationBadge);

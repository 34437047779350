/* eslint-disable max-len */
import React from 'react';

function IconCondition() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9155 10.6914C16.6501 10.6914 17.2476 10.0939 17.2476 9.35938C17.2476 8.6248 16.6501 8.02734 15.9155 8.02734C15.181 8.02734 14.5835 8.6248 14.5835 9.35938C14.5835 10.0939 15.181 10.6914 15.9155 10.6914ZM15.9155 8.91537C16.1604 8.91537 16.3595 9.11464 16.3595 9.35938C16.3595 9.60411 16.1604 9.80339 15.9155 9.80339C15.6706 9.80339 15.4715 9.60411 15.4715 9.35938C15.4715 9.11464 15.6706 8.91537 15.9155 8.91537Z"
        fill="black"
      />
      <path
        d="M9.88349 21.4238L9.25566 22.0517L8.62783 21.4238L8 22.0517L8.62783 22.6795L8 23.3073L8.62783 23.9352L9.25566 23.3073L9.88349 23.9352L10.5113 23.3073L9.88349 22.6795L10.5113 22.0517L9.88349 21.4238Z"
        fill="black"
      />
      <path
        d="M22.3539 22.7174L21.7799 22.1432L21.1519 22.7712L22.3539 23.9731L23.9999 22.3272L23.3719 21.6992L22.3539 22.7174Z"
        fill="black"
      />
      <path
        d="M20.4606 17.3522H22.1318V20.9043H23.0199V16.4642H20.4606L16.3597 14.4138V11.5801H15.4717V14.4138L11.3708 16.4642H8.81152V20.9043H9.69954V17.3522H11.3708L15.9157 19.6247L20.4606 17.3522ZM15.9157 15.1846L19.363 16.9082L15.9157 18.6319L12.4684 16.9082L15.9157 15.1846Z"
        fill="black"
      />
    </svg>
  );
}

export default React.memo(IconCondition);

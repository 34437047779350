import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { removeConditionOptionAction } from 'editor/src/store/design/slice';
import { ConditionAddress } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import updateHiddenElementsOperation from './updateHiddenElementsOperation';

const removeConditionOptionOperation = (address: ConditionAddress, optionId: string) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(removeConditionOptionAction({ address, optionId }));
    dispatch(updateHiddenElementsOperation());
  });
};

export default removeConditionOptionOperation;

import { Area } from 'editor/src/store/design/operation/getNewElementDisplay';
import { MediaImage } from 'editor/src/store/design/types';
import { GalleryImage } from 'editor/src/store/gallery/types';

import applyFillImageToSpread from './applyFillImageToSpread';

const applyFillImageToElementPlaceholder = (
  placeholderElement: MediaImage,
  imageToApply: GalleryImage,
): Partial<MediaImage> => {
  const areaToFit: Area = {
    left: placeholderElement.x || 0,
    top: placeholderElement.y || 0,
    width: placeholderElement.width,
    height: placeholderElement.height,
  };

  const updatedElement = applyFillImageToSpread(
    {
      ...placeholderElement,
      width: imageToApply.width,
      height: imageToApply.height,
      pw: imageToApply.width,
      ph: imageToApply.height,
    },
    areaToFit,
  );

  return {
    imageId: imageToApply.id,
    url: imageToApply.url,
    sample: undefined,
    px: (updatedElement.px || 0) - ((updatedElement.width || 0) - placeholderElement.width) / 2,
    py: (updatedElement.py || 0) - ((updatedElement.height || 0) - placeholderElement.height) / 2,
    pw: updatedElement.pw,
    ph: updatedElement.ph,
  };
};

export default applyFillImageToElementPlaceholder;

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorSpace } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import { EMPTY_RGBA_STRING } from 'editor/src/util/color/rgbaUtils';

import { parseColorToRGBA } from '../../util/color/colorUtils';
import SplitterHorizontal from '../TopMenuDesktop/buttons/SplitterHorizontal';

import ColorControllers from './ColorControllers';
import colors from './colors';
import Palette from './Palette';
import ResetButton from './ResetButton';

import styles from './index.module.scss';

interface Props {
  color: string | undefined;
  onColorChange: (color: string | undefined, opacity?: number) => void;
  onColorChanging?: (color: string | undefined, opacity?: number) => void;
  allowNoColor?: boolean;
  allowNoColorLabel?: string;
  allowOpacity?: boolean;
  colors?: string[];
  paletteColorsInRowCount?: number;
  hidePickFromPaletteNote?: true;
  suggestedColors?: string[];
}

function ColorPicker({
  color,
  onColorChanging,
  onColorChange,
  allowNoColor,
  allowOpacity,
  colors: availableColors,
  suggestedColors,
  allowNoColorLabel,
  paletteColorsInRowCount = 8,
  hidePickFromPaletteNote,
}: Props) {
  const { t } = useTranslation();
  const colorPickerConfig = useSelector((state) => getHostSetting(state, 'colorPickerConfig'));
  const colorSpace = useSelector((state) => state.design.designData?.color_space) ?? ColorSpace.sRGB;

  const onResetColor = useCallback(() => {
    if (allowNoColor) {
      onColorChange(undefined);
    }
  }, [allowNoColor, onColorChange]);

  const noColor = !color || color === EMPTY_RGBA_STRING;

  return (
    <div className="cy-color-picker">
      {allowNoColor && (
        <ResetButton
          className="mb-2"
          label={allowNoColorLabel || t('No color')}
          active={noColor}
          onReset={onResetColor}
        />
      )}
      {colorSpace === ColorSpace.sRGB && (
        <div className={styles.palette}>
          {!hidePickFromPaletteNote && <span className={styles.label}>{t('editor-pick-from-palette')}</span>}
          <Palette
            colors={availableColors || colors}
            selectedColor={!noColor ? parseColorToRGBA(color).toHex() : undefined}
            onColorSelect={onColorChange}
            suggestedColors={suggestedColors}
            colorsInRowCount={paletteColorsInRowCount}
            colorsInfo={colorPickerConfig?.colorsInfo}
          />
        </div>
      )}
      {!availableColors?.length && (
        <>
          <SplitterHorizontal />
          <ColorControllers
            color={color}
            colorSpace={colorSpace}
            hideRGB={(allowNoColor && noColor) || undefined}
            onColorChange={onColorChange}
            onColorChanging={onColorChanging}
            className={styles.picker}
            label={t('editor-create-custom-color')}
            allowOpacity={!!allowOpacity}
          />
        </>
      )}
    </div>
  );
}

export default React.memo(ColorPicker);

import cn from 'classnames';
import React from 'react';

import { ColorInfo } from 'editor/src/store/hostSettings/types';

import ColorTileControl from 'editor/src/component/ColorPicker/ColorTileControl';

import styles from './index.module.scss';

interface Props {
  selectedColor: string | undefined;
  onColorSelect: (color: string) => void;
  colors: string[];
  colorsInfo?: ColorInfo;
  suggestedColors?: string[];
  colorsInRowCount?: number;
}

function Palette({
  selectedColor,
  onColorSelect,
  colors,
  suggestedColors,
  colorsInRowCount = 8,
  colorsInfo = {},
}: Props) {
  return (
    <div
      className={cn(styles.Palette, 'cy-color-picker-palette')}
      style={{ gridTemplateColumns: `repeat(${colorsInRowCount}, 1fr)` }}
    >
      {colors.map((color) => (
        <ColorTileControl
          key={color}
          onClick={onColorSelect}
          color={color}
          isSuggested={suggestedColors?.includes(color)}
          isActive={color.toUpperCase() === selectedColor?.toUpperCase()}
          colorName={colorsInfo[color]?.name}
        />
      ))}
    </div>
  );
}

export default React.memo(Palette);

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconLeftPosition({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M5.14285 4C5.77403 3.99999 6.28571 4.51166 6.28571 5.14285L6.28577 10.8571L16.098 10.8571L13.4776 8.23669C13.0313 7.79038 13.0313 7.06676 13.4776 6.62045C13.9239 6.17414 14.6475 6.17414 15.0938 6.62045L19.6653 11.1919C19.8796 11.4062 20 11.6969 20 12C20 12.3031 19.8796 12.5938 19.6653 12.8081L15.0938 17.3796C14.6475 17.8259 13.9239 17.8259 13.4776 17.3796C13.0313 16.9332 13.0313 16.2096 13.4776 15.7633L16.098 13.1429L6.2858 13.1429L6.28585 18.8571C6.28586 19.4883 5.77419 20 5.14301 20C4.51183 20 4.00015 19.4883 4.00014 18.8572L4 5.14287C3.99999 4.51169 4.51166 4.00001 5.14285 4Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconLeftPosition;

import cn from 'classnames';
import React from 'react';

import Button from 'editor/src/component/Button';
import IconChevronRight from 'editor/src/component/Icon/IconChevronRight';

import styles from './index.module.scss';

interface Props {
  onClick: () => void;
}

function ScrollRightArrow({ onClick }: Props) {
  return (
    <Button className={cn(styles.ScrollRightArrow, 'cy-button-scroll-controls-right')} onClick={onClick}>
      <IconChevronRight />
    </Button>
  );
}

export default React.memo(ScrollRightArrow);

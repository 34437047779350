import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import IconExpandLess from 'editor/src/component/Icon/IconExpandLess';
import IconExpandMore from 'editor/src/component/Icon/IconExpandMore';

import styles from './index.module.scss';

export interface Props {
  headerText: string;
  children: React.ReactNode;
  className?: string;
  isOpenInitially?: boolean;
  Header?: JSX.Element;
}

function Widget({ headerText, children, className, Header, isOpenInitially = false }: Props) {
  const [isWidgetOpen, setIsWidgetOpen] = useState(isOpenInitially);
  const contentRef = useRef<HTMLDivElement>(null);

  const onClick = () => {
    setIsWidgetOpen(!isWidgetOpen);
  };

  useEffect(() => {
    if (isWidgetOpen && contentRef.current) {
      setTimeout(() => contentRef.current?.scrollIntoView({ behavior: 'smooth' }), 200);
    }
  }, [isWidgetOpen]);

  const Icon = isWidgetOpen ? <IconExpandLess /> : <IconExpandMore />;

  return (
    <div className={cn(styles.widget, className)}>
      {Header ? (
        <div className={styles.widgetHeader} onClick={onClick}>
          <div>{Icon}</div>
          {Header}
        </div>
      ) : (
        <div className={styles.header} onClick={onClick}>
          <div>{headerText}</div>
          <div>{Icon}</div>
        </div>
      )}
      <div className={cn(styles.content, { [styles.open]: isWidgetOpen })} ref={contentRef}>
        <div className={styles.contentBlock}>{children}</div>
      </div>
    </div>
  );
}

export default Widget;

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import getPersonalizationStudioBannerData from 'editor/src/store/editor/selector/getPersonalizationStudioBannerData';
import isTextPersonalizationPromotionBannerDismissed from 'editor/src/store/editorModules/sidebar/selector/isTextPersonalizationPromotionBannerDismissed';
import { setIsTextPersonalizationPromotionBannerDismissedAction } from 'editor/src/store/editorModules/sidebar/slice';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import IconPersonsalisation from 'editor/src/component/Icon/IconPersonsalisation';

import { BannerType } from '../banner-type.enum';
import InfoBanner from '../InfoBanner';
import {
  increaseBannerDismissedCounter,
  isBannerDismissalTimeAndVisitsPassed,
  isBannerDismissedEnoughToHideIt,
  setDismissalTimestamp,
} from '../utils';

import styles from './index.module.scss';

function IconPersonalization() {
  return (
    <div className={styles.iconContainer}>
      <IconPersonsalisation className={styles.icon} />
    </div>
  );
}

function TextPersonalizationPromotionBanner() {
  const { t } = useTranslation();
  const isBannerDismissed = useSelector(isTextPersonalizationPromotionBannerDismissed);
  const personalizationStudioBannerData = useSelector(getPersonalizationStudioBannerData);
  const isBannerHidden = useMemo(
    () => isBannerDismissedEnoughToHideIt(BannerType.TEXT_PERSONALIZATION_PROMOTION_BANNER),
    [],
  );
  const isTextBannerDismissalTimePassed = useMemo(
    () => isBannerDismissalTimeAndVisitsPassed(BannerType.TEXT_PERSONALIZATION_PROMOTION_BANNER),
    [],
  );
  const showBanner =
    personalizationStudioBannerData && !isBannerDismissed && !isBannerHidden && isTextBannerDismissalTimePassed;
  const dispatch = useDispatch();
  const onBannerDismiss = () => {
    increaseBannerDismissedCounter(BannerType.TEXT_PERSONALIZATION_PROMOTION_BANNER);
    setDismissalTimestamp(BannerType.TEXT_PERSONALIZATION_PROMOTION_BANNER);
    dispatch(setIsTextPersonalizationPromotionBannerDismissedAction(true));
  };

  const onBannerCtaClick = () => {
    sendPostMessage('log.personalizationStudioEvent', {
      eventName: 'Personalization Studio Promotion Clicked',
      data: { origin: 'text_tab' },
    });
  };

  useEffect(() => {
    if (showBanner) {
      sendPostMessage('log.personalizationStudioEvent', {
        eventName: 'Personalization Studio Introduced In Editor',
        data: { origin: 'text_tab' },
      });
    }
  }, [showBanner]);

  if (!showBanner) {
    return null;
  }

  return (
    <InfoBanner
      icon={<IconPersonalization />}
      onDismiss={onBannerDismiss}
      className="cy-personalization-promotion-banner"
    >
      <div className={styles.bannerContent}>
        {t('editor-text-personalization-example')}&nbsp;
        <a
          className={styles.bannerLink}
          href={personalizationStudioBannerData.productExamplesLink}
          target="_blank"
          rel="noreferrer"
          onClick={onBannerCtaClick}
        >
          {t('See examples')}.
        </a>
      </div>
    </InfoBanner>
  );
}

export default React.memo(TextPersonalizationPromotionBanner);

import React, { useEffect, useRef, useState } from 'react';

import SkeletonBlock from 'editor/src/component/SkeletonBlock';

import styles from './index.module.scss';

interface Props {
  src: string;
  alt: string;
  loaderMinHeight?: number;
  className?: string;
}

function ImageWithLoader({ src, alt, className, loaderMinHeight }: Props) {
  const imgRef = useRef(new Image());
  const [isLoaded, setIsLoaded] = useState(false);
  imgRef.current.src = src;

  function onLoad() {
    setIsLoaded(true);
  }

  useEffect(() => {
    const img = imgRef.current;

    if (img.complete) {
      setIsLoaded(true);
      return undefined;
    }

    const handle = window.setInterval(() => {
      if (img.width !== 0 || img.height !== 0) {
        setIsLoaded(true);
        clearLoaders();
      }
    }, 50);
    img.addEventListener('load', onLoad);

    function clearLoaders() {
      window.clearInterval(handle);
      img.removeEventListener('load', onLoad);
    }

    return clearLoaders;
  }, [src]);

  if (isLoaded) {
    return <img className={className} src={src} alt={alt} />;
  }

  return (
    <div className={styles.Image} style={{ minHeight: isLoaded ? undefined : `${loaderMinHeight || 0}px` }}>
      {!isLoaded && <SkeletonBlock className={styles.loader} />}
      <img className={className} src={src} alt={alt} />
    </div>
  );
}

export default React.memo(ImageWithLoader);

import { addConditionGroupAction, createEmptyConditionAction } from 'editor/src/store/design/slice';
import { ConditionOption } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import type { Thunk } from 'editor/src/store/hooks';

import { conditionOptionUuids, conditionUuids } from 'editor/src/util/uuids';

const createConditionOperation =
  (): Thunk =>
  (dispatch, getState, { i18n }) => {
    const state = getState();
    const spreadIndex = getCurrentSpreadIndex(state);
    const spread = state.design.designData?.spreads[spreadIndex];
    if (!spread) {
      return null;
    }

    if (!spread.conditionGroup) {
      dispatch(addConditionGroupAction({ spreadIndex }));
    }

    const conditionId = conditionUuids.generate();
    const address = { spreadIndex, conditionId };
    const option1: ConditionOption = {
      id: conditionOptionUuids.generate(),
      imageId: undefined,
      label: `${i18n.t('editor-default-option-num', { num: 1 })}`,
    };
    const option2: ConditionOption = {
      id: conditionOptionUuids.generate(),
      imageId: undefined,
      label: `${i18n.t('editor-default-option-num', { num: 2 })}`,
    };

    dispatch(
      createEmptyConditionAction({
        address,
        name: i18n.t('editor-default-condition-name'),
        option1,
        option2,
      }),
    );
    return conditionId;
  };

export default createConditionOperation;

import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

import { LAYOUT_TAG_GROUPED } from 'editor/src/util/layouts/layoutSchemas/defaultSchemas';

function getAllowedLayouts(schemas: LayoutSchema[], spreadTags: string[] | undefined) {
  const spreadTagsSet = new Set(spreadTags);
  if (!spreadTagsSet.size) {
    return schemas.filter((layout) => !layout.tags || layout.tags.some((tag) => tag === '*'));
  }

  return schemas.filter(
    (layout) =>
      !layout.tags || (layout.tags.some((tag) => spreadTagsSet.has(tag)) && !layout.tags.includes(LAYOUT_TAG_GROUPED)),
  );
}

export default getAllowedLayouts;

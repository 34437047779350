import chunk from 'lodash/chunk';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import getImageElementUrl from 'editor/src/store/design/selector/getImageElementUrl';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { MimeType } from 'editor/src/store/gallery/types';
import { useSelector } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import isPremiumIconVisible from 'editor/src/store/plugins/selector/isPremiumIconVisible';
import showPremiumPrompt from 'editor/src/store/plugins/selector/showPremiumPrompt';
import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import ButtonBackToTab from 'editor/src/component/DesktopSidebar/TabContents/Buttons/ButtonBackToTab';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import GelatoPrompt from 'editor/src/component/GelatoPrompt';
import IconFilters from 'editor/src/component/Icon/IconFilters';

import Filter from './Filter';
import FILTERS, { NO_FILTER_FILTER_NAME } from './filters';

import styles from './index.module.scss';

export const ALLOW_FREE = 1;

function FiltersTabContent({ title }: TabContentProps) {
  const { t } = useTranslation();
  const uuid = useSelector(getSelectedImageUuid);
  const elements = useSelector(getSelectedMediaElementsObjects);
  const firstElement = elements[0];
  const imageType = useSelector(
    (state) => getImageById(state, elements[0]?.type === 'image' ? elements[0].imageId : '')?.type,
  );
  const { imageUrl, image, address } = useSelector((state) => {
    const isImage = firstElement?.type === 'image';
    const imageInfo = isImage ? getImageElementUrl(state, firstElement) : undefined;
    return {
      address: isImage && uuid ? getStructureIndexByElementUuidMemoized(state, uuid) : undefined,
      image: isImage ? firstElement : undefined,
      imageUrl: imageInfo?.crossOrigin === 'anonymous' ? imageInfo.imageUrl : undefined,
    };
  }, shallowEqual);
  const nonFunctional = useSelector(
    (state) => getPlugin(state, PluginName.Filters)?.state === PluginState.NON_FUNCTIONAL,
  );
  const { gelatoPrompt, premiumIconVisible, editImageFlag } = useSelector((state) => {
    const plugin = getPlugin(state, PluginName.Filters);
    return {
      gelatoPrompt: plugin && showPremiumPrompt(plugin),
      premiumIconVisible: isPremiumIconVisible(state, PluginName.Filters),
      editImageFlag: state.hostSettings.editImageFlag,
    };
  }, shallowEqual);

  const [active, setActive] = useState(image?.filters?.[0]?.name ?? NO_FILTER_FILTER_NAME);
  useEffect(() => {
    if (active !== NO_FILTER_FILTER_NAME && !image?.filters?.length) {
      setActive(NO_FILTER_FILTER_NAME);
    }
  }, [image, active]);

  const activeIndex = FILTERS.findIndex((filter) => filter.name === active);
  const activeFilter = FILTERS[activeIndex];

  if (firstElement?.type !== 'image' || !address || !imageUrl || imageType === MimeType.SVG) {
    return null;
  }

  return (
    <ContentBlock>
      <TabContentHeader
        title={title || t('editor-filters')}
        icon={editImageFlag ? <ButtonBackToTab mode="icon" tabName={TAB_NAMES.EDIT_IMAGE} /> : <IconFilters />}
      />
      {gelatoPrompt && <GelatoPrompt className={styles.prompt} prompt={gelatoPrompt} pluginName={PluginName.Filters} />}
      <div className={styles.wrapper}>
        {chunk(FILTERS, 3).map((chunk, idx) => (
          <div className={styles.chunk} key={idx}>
            {chunk.map((filter, index) => (
              <Filter
                filter={filter}
                address={address}
                element={firstElement}
                imageUrl={imageUrl}
                key={filter.name}
                active={filter.name === active}
                onClick={setActive}
                rowActive={activeIndex / 3 >= idx && activeIndex / 3 < idx + 1 && !activeFilter.empty}
                disabled={nonFunctional && idx * 3 + index >= ALLOW_FREE}
                showPremiumIcon={premiumIconVisible && idx * 3 + index >= ALLOW_FREE}
                filterClassName={styles.filterTabOptions}
              />
            ))}
          </div>
        ))}
      </div>
    </ContentBlock>
  );
}

export default FiltersTabContent;

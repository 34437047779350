import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

function IconX({ className, onClick }: Props) {
  return (
    <Icon className={className} onClick={(e) => onClick?.(e)}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13M1 13L13 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export default IconX;

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { ElementAddress, ImagePattern } from 'editor/src/store/design/types';
import getPatternDefault from 'editor/src/store/gallery/utils/getPatternDefaults';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getCenteredImagePosition from 'editor/src/util/2d/getCenteredImagePosition';

import updateMediaElementOperation, { MediaUpdateActionName } from './updateMediaElementOperation';

const applyPatternOnImageOperation =
  (address: ElementAddress, patternType: ImagePattern['type'] | undefined) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const element = getMediaElement(state, address);
    if (!element || element.type !== 'image') {
      return;
    }

    const image = state.gallery.images.find((image) => image.id === element.imageId);
    if (!image) {
      return;
    }

    const pattern = getPatternDefault(patternType, state.editor.imagePatternScaleBases);

    const { pw, ph } = getCenteredImagePosition({ ...element, pattern }, image);

    dispatch(
      updateMediaElementOperation(
        address,
        {
          pattern,
          px: 0,
          py: 0,
          pw,
          ph,
          pr: 0,
        },
        MediaUpdateActionName.PATTERN_APPLIED,
      ),
    );
  };

export default applyPatternOnImageOperation;

import { CanShow } from 'editor/src/component/Menu/type';

import ButtonTextSpacing from './ButtonTextSpacing';
import ButtonTextSpacingDropDown from './ButtonTextSpacingDropDown';

export const canShow: CanShow = () => true;

export { ButtonTextSpacingDropDown, ButtonTextSpacing };

export default ButtonTextSpacing;

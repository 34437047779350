/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconAngle({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M15.9905 6.60185C16.0563 6.60185 16.1145 6.55895 16.1339 6.49605C16.1533 6.43316 16.1294 6.36497 16.075 6.3279C13.3487 4.46959 9.58349 4.73718 7.15638 7.13204C4.4225 9.8296 4.4225 14.204 7.15638 16.9016C9.88914 19.598 14.319 19.598 17.0518 16.9016C17.4339 16.5246 18.0542 16.5246 18.4363 16.9016C18.8172 17.2775 18.8172 17.8861 18.4363 18.262C14.9393 21.7125 9.26883 21.7125 5.77189 18.262C2.27607 14.8127 2.27607 9.22096 5.77189 5.77159C9.01783 2.56878 14.137 2.33872 17.6518 5.08231C17.697 5.11759 17.7583 5.12401 17.8099 5.09887C17.8614 5.07372 17.8941 5.0214 17.8941 4.96406V3.11289C17.8941 2.58293 18.33 2.1499 18.8721 2.1499C19.4141 2.1499 19.85 2.58293 19.85 3.11289V7.56484C19.85 8.09479 19.4141 8.52782 18.8721 8.52782H14.3602C13.8181 8.52782 13.3822 8.09479 13.3822 7.56484C13.3822 7.03488 13.8181 6.60185 14.3602 6.60185H15.9905Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.87029 12.0168C8.87029 10.2575 10.3163 8.82782 12.1042 8.82782C13.8922 8.82782 15.3381 10.2575 15.3381 12.0168C15.3381 13.7761 13.8922 15.2057 12.1042 15.2057C10.3163 15.2057 8.87029 13.7761 8.87029 12.0168ZM12.1042 10.7538C11.4003 10.7538 10.8262 11.3174 10.8262 12.0168C10.8262 12.7162 11.4003 13.2798 12.1042 13.2798C12.8081 13.2798 13.3822 12.7162 13.3822 12.0168C13.3822 11.3174 12.8081 10.7538 12.1042 10.7538Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconAngle;

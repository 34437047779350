/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconDottedLines({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="286" height="1" viewBox="0 0 286 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0" y1="0" x2="286" y2="0" stroke="white" />
        <line x1="0" y1="0" x2="286" y2="0" stroke="#666666" strokeDasharray="5" />
      </svg>
    </Icon>
  );
}

export default IconDottedLines;

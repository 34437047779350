import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { Spread } from 'editor/src/store/design/types';
import { Addon } from 'editor/src/store/editorModules/addons/types';
import { useSelector } from 'editor/src/store/hooks';
import { getGraphicDataFromElement } from 'editor/src/store/plugins/utils/graphicPluginUtils';

export function getUsedAddons(spreads: Spread[], inUse: Addon[]) {
  const addons = new Map<Addon, number>();

  spreads.forEach((spread) => {
    spread.pages[0].groups.media?.forEach((element) => {
      let addonId: string | undefined;
      const graphicsPlugin = getGraphicDataFromElement(element);
      if (graphicsPlugin) {
        addonId = graphicsPlugin.addonId;
      } else if (element.type === 'addon') {
        addonId = element.addonId;
      }
      const addon = addonId ? inUse.find((addon) => addon.id === addonId) : undefined;
      if (addon) {
        addons.set(addon, (addons.get(addon) ?? 0) + 1);
      }
    });
  });

  return addons;
}

function useAddonsInUse() {
  const { spreads, inUse } = useSelector(
    (state) => ({
      spreads: state.design.designData?.spreads,
      inUse: state.editorModules.addons.inUse,
    }),
    shallowEqual,
  );
  return useMemo(() => (spreads && inUse ? getUsedAddons(spreads, inUse) : new Map<Addon, number>()), [spreads, inUse]);
}

export default useAddonsInUse;

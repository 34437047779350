import { DesignData, Spread } from 'editor/src/store/design/types';

import isImagePlaceholder from 'editor/src/util/design/isImagePlaceholder';

const findEmptySpreadIndexes = (design: DesignData) => {
  let result: number[] = design.spreads.reduce((acc: number[], spread: Spread, spreadIndex: number) => {
    const { media } = spread.pages[0].groups;
    // spread is empty
    if (!media?.length) {
      acc.push(spreadIndex);
      return acc;
    }

    // if spread contains empty placeholder we can use it
    if (media.some(isImagePlaceholder)) {
      acc.push(spreadIndex);
    }

    return acc;
  }, []);

  // if spread_groups exists leave only 1 spread of each group
  if (design.spread_groups?.length) {
    const firstSpreadIndexesPerGroup = design.spread_groups.map((spreadGroup) => spreadGroup.spreadIndexes[0]);
    result = result.filter((emptySpreadIndex) => firstSpreadIndexesPerGroup.includes(emptySpreadIndex));
  }

  return result;
};

export default findEmptySpreadIndexes;

import cn from 'classnames';
import React from 'react';

import isMultipleImageSelectAllowed from 'editor/src/store/editor/selector/isMultipleImageSelectAllowed';
import getGalleryImagesCount from 'editor/src/store/gallery/selector/getGalleryImagesCount';
import getSelectedGalleryImagesCount from 'editor/src/store/gallery/selector/getSelectedGalleryImagesCount';
import { useSelector } from 'editor/src/store/hooks';

import useEditorModeTranslations from 'editor/src/util/useEditorModeTranslations';

import AddSelectedImagesToDesign from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/AddSelectedImagesToDesign';
import AllFilesUploadProgress from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/AllFilesUploadProgress';
import ButtonAutoFill from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/ButtonAutoFill';
import ButtonOpenUploader from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/ButtonOpenUploader';
import ButtonToggleSelectAll from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/ButtonToggleSelectAll';
import DeleteSelectedImages, {
  DeleteSelectedImagesHeight,
} from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/DeleteSelectedImages';
import GalleryImageList from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/GalleryImageList';
import HideUsedCheckbox from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/HideUsedCheckbox';
import ReuploadFailed from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/ReuploadFailed';

import DrawerHeader from '../../DrawerHeader';

import AddImagesHint from './AddImagesHint';

import styles from './index.module.scss';

interface Props {
  closeGalleryMenuCb?: () => void;
}
function GalleryMobileMenu({ closeGalleryMenuCb }: Props) {
  const galleryImagesCount = useSelector(getGalleryImagesCount);
  const isMultipleImageSelectMode = useSelector((state) => isMultipleImageSelectAllowed(state));
  const selectedImagesCount = useSelector((state) => getSelectedGalleryImagesCount(state));
  const { galleryTab } = useEditorModeTranslations();

  return (
    <div className={cn(styles.GalleryMobileMenu, 'cy-tab-gallery')}>
      <DrawerHeader title={galleryTab} closeMenu={closeGalleryMenuCb} />
      <div className="px-2">
        <ButtonOpenUploader />
        <div className="row-flex row-flex--center">
          <ButtonAutoFill onAutofillCb={closeGalleryMenuCb} />
        </div>
      </div>
      <div className={styles.imageGalleryControls}>
        <div className="row-flex row-flex--between row-flex--middle" />
        <div className={styles.options}>
          <HideUsedCheckbox />
          <ButtonToggleSelectAll />
        </div>
        <AllFilesUploadProgress />
        <ReuploadFailed />
      </div>
      {galleryImagesCount === 0 && <AddImagesHint />}
      {galleryImagesCount > 0 && (
        <div className={styles.galleryImageListWrapper}>
          <GalleryImageList
            onAddImageCb={closeGalleryMenuCb}
            extraBottomPadding={isMultipleImageSelectMode ? DeleteSelectedImagesHeight : 0}
          />
        </div>
      )}
      {isMultipleImageSelectMode && (
        <div
          className={cn(styles.BottomBar, 'row-flex', 'row-flex--between', 'py-1', 'px-2', {
            [styles.visible]: selectedImagesCount,
          })}
        >
          <AddSelectedImagesToDesign onAddImagesCb={closeGalleryMenuCb} />
          <DeleteSelectedImages />
        </div>
      )}
    </div>
  );
}

export default React.memo(GalleryMobileMenu);

import { createListenerMiddleware } from '@reduxjs/toolkit';

import applyResizableProductToDesign from 'editor/src/util/design/applyResizableProductToDesign';
import applyLayoutPerProductUid from 'editor/src/util/layouts/applyLayoutPerProductUid';
import reflectDesignData from 'editor/src/util/reflectDesignData';
import getReflectContext from 'editor/src/util/reflectDesignData/getReflectContext';

import { canShowAddRemovePages } from 'editor/src/component/TopMenuDesktop/buttons/ButtonAddPages';

import { addCalendarPublicEventsAction } from './design/slice';
import { DesignData } from './design/types';
import getLayoutSchemaByName from './editor/selector/getLayoutSchemaByName';
import getDesignKey, { getDesignKeyFromDesign } from './variants/helpers/getDesignKey';
import getResizableProductForGivenVariant from './variants/selector/getResizableProductForGivenVariant';
import getVariationGroupsFromDesignOptions from './variants/selector/getVariationGroupsFromDesignOptions';
import { setGroupDesignDataAction } from './variants/slice';
import { VariationGroup } from './variants/types';
import checkPluginUse from './watchers/checkPluginUse';

import type { ThunkDispatch } from './hooks';
import type { RootState, ThunkArg } from './index';
import type { i18n } from 'i18next';

function createListener(i18n: i18n) {
  const listenerMiddleware = createListenerMiddleware<RootState, ThunkDispatch, ThunkArg>({ extra: { i18n } });

  listenerMiddleware.startListening({
    predicate: (action, currentState, previousState) =>
      !!action.type &&
      (currentState.design.designData !== previousState.design.designData ||
        currentState.design.globalPlugins !== previousState.design.globalPlugins),
    effect: (action, listenerApi) => {
      let state = listenerApi.getState();
      const { designData: originalDesignData } = listenerApi.getOriginalState().design;

      // when checkPluginUse in the bottom dispatches setInstalledPluginsAction, it will trigger this effect again with the previous state
      if (action.type === 'design/setInstalledPluginsAction') {
        return;
      }

      if (state.design.designData) {
        checkPluginUse(
          state.design.designData,
          state.gallery.images,
          listenerApi.dispatch,
          state.plugins.list,
          state.design.globalPlugins,
          state.hostSettings.defaultPersonalizationPlugin,
        );
      }

      state = listenerApi.getState();
      const { designData } = state.design;

      // for some products we can add/remove pages dynamically and should reflect design in variationGroup
      const canAddRemovePages = canShowAddRemovePages(state);
      const shouldConsiderDesignUpdate =
        designData &&
        (!originalDesignData ||
          getDesignKeyFromDesign(designData, canAddRemovePages) ===
            getDesignKeyFromDesign(originalDesignData, canAddRemovePages));

      if (!originalDesignData && designData && state.gridDesigns.gridEvents.length) {
        listenerApi.dispatch(addCalendarPublicEventsAction(state.gridDesigns.gridEvents));
      }

      if (state.variants.isVariantFlow && shouldConsiderDesignUpdate) {
        const reflectContext = getReflectContext(state);
        const { selectedGroupKey, variationGroups, selectedDesignOptionValue } = state.variants;
        const variationGroupsFromDesignOptions = getVariationGroupsFromDesignOptions(state);
        const selectedGroupDesignOption = variationGroupsFromDesignOptions?.find(
          (group) => group.key === selectedDesignOptionValue,
        );
        const selectedGroupKeys = selectedGroupDesignOption?.value.map((group) => group.key);
        const selectedGroup = variationGroups.find((group) => group.key === selectedGroupKey);

        if (!selectedGroup) {
          return;
        }
        if (!selectedGroup.linked) {
          listenerApi.dispatch(
            setGroupDesignDataAction({
              groupKey: selectedGroup.key,
              designData,
              reflectContext,
            }),
          );
          return;
        }

        variationGroups.forEach((group) => {
          if (
            (selectedGroupKeys && selectedGroupKeys.find((selectedkey) => selectedkey === group.key) && group.linked) ||
            (!selectedGroupKeys && group.linked)
          ) {
            listenerApi.dispatch(
              setGroupDesignDataAction({
                groupKey: group.key,
                designData: getNewDesignDataForGroup(group, state, listenerApi.extra.i18n),
                reflectContext,
              }),
            );
          }
        });
      }
    },
  });

  const getNewDesignDataForGroup = (group: VariationGroup, state: RootState, i18n: i18n): DesignData | undefined => {
    const { designData } = state.design;
    const { selectedGroupKey, designTemplates } = state.variants;
    const reflectContext = getReflectContext(state);

    if (group.key === selectedGroupKey) {
      return designData;
    }

    const firstVariant = group.variationsInfo[0];
    const designKey = getDesignKey(firstVariant.variation.productUid, firstVariant);
    const template = designTemplates[designKey];
    let newDesignData = template && designData ? reflectDesignData(designData, template, reflectContext) : undefined;
    if (newDesignData) {
      newDesignData = applyLayoutPerProductUid(newDesignData, state, true, i18n);

      const resizableElement = getResizableProductForGivenVariant(state, newDesignData.product_uid);
      const layoutName = newDesignData?.spreads[0].layoutSchemaName;
      const layout = layoutName ? getLayoutSchemaByName(state, layoutName) : undefined;

      if (resizableElement && layout && newDesignData.related_dimensions) {
        newDesignData = applyResizableProductToDesign(state, newDesignData, resizableElement, layout, i18n);
      }
    }

    return newDesignData;
  };

  return listenerMiddleware;
}

export default createListener;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconTextAlignCenter({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.6666 10C36.6666 10.9205 35.9204 11.6667 34.9999 11.6667H4.99992C4.07944 11.6667 3.33325 10.9205 3.33325 10C3.33325 9.07957 4.07944 8.33337 4.99992 8.33337H34.9999C35.9204 8.33337 36.6666 9.07957 36.6666 10Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.6666 16.6667C30.6666 17.5872 29.9204 18.3334 29 18.3334H11C10.0795 18.3334 9.33329 17.5872 9.33329 16.6667C9.33329 15.7462 10.0795 15 11 15H29C29.9204 15 30.6666 15.7462 30.6666 16.6667Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.6666 23.3334C33.6666 24.2538 32.9204 25 31.9999 25H7.99994C7.07947 25 6.33327 24.2538 6.33327 23.3334C6.33327 22.4129 7.07947 21.6667 7.99994 21.6667H31.9999C32.9204 21.6667 33.6666 22.4129 33.6666 23.3334Z"
          fill="#212121"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.1666 30C26.1666 30.9205 25.4204 31.6667 24.4999 31.6667H15.4999C14.5795 31.6667 13.8333 30.9205 13.8333 30C13.8333 29.0796 14.5795 28.3334 15.4999 28.3334H24.4999C25.4204 28.3334 26.1666 29.0796 26.1666 30Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconTextAlignCenter;

import React, { useCallback } from 'react';

import setActivePreviewOperation from 'editor/src/store/editorModules/preview/operation/setActivePreviewOperation';
import { PreviewType } from 'editor/src/store/editorModules/preview/types';
import { useStore, useDispatch } from 'editor/src/store/hooks';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import IconEye from 'editor/src/component/Icon/IconEye';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state) => state.mockup.mockup.sizes.length > 0;

type Props = Pick<MenuItemProps, 'mode'>;

function ButtonMockupPreview({ mode }: Props) {
  const dispatch = useDispatch();
  const store = useStore();

  const handleClick = useCallback(() => {
    const state = store.getState();
    sendPostMessage('mockup.getPreview', {
      width: 1500,
      height: 1500,
      productUid: state.mockup.productPlaceholder.activeProductUid,
    });
    dispatch(setActivePreviewOperation(PreviewType.MOCKUP));
  }, []);

  return (
    <MenuButton
      Icon={IconEye}
      mode={mode}
      handleClick={handleClick}
      i18nKey="Preview products"
      className="cy-button-mockup-preview"
    />
  );
}

export default React.memo(ButtonMockupPreview);

import React, { useMemo } from 'react';

import setCurrentSpreadIndexOperation from 'editor/src/store/editor/operation/setCurrentSpreadIndexOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import getSpreadName from 'editor/src/util/design/getSpreadName';

import Button from '../Button';
import IconChevronLeft from '../Icon/IconChevronLeft';
import IconChevronRight from '../Icon/IconChevronRight';
import useGetSpreadList from '../MobileLayout/useGetSpreadList';
import useBottomPadding from '../SpreadListView/calculateBottom';

import styles from './index.module.scss';

function PageSwitcher() {
  const dispatch = useDispatch();
  const bottomPadding = useBottomPadding();
  const currentSpreadIndex = useSelector((state) => state.editor.currentSpreadIndex) || 0;
  const { spreadsList } = useGetSpreadList();

  const spread = useMemo(() => spreadsList[currentSpreadIndex], [spreadsList, currentSpreadIndex]);
  const isLastDisabled = useMemo(
    () => currentSpreadIndex === spreadsList.length - 1,
    [currentSpreadIndex, spreadsList],
  );
  const isFirstDisabled = useMemo(() => currentSpreadIndex === 0, [currentSpreadIndex]);

  const name = getSpreadName(spread).join(' - ');

  function handleClickBack() {
    dispatch(setCurrentSpreadIndexOperation(spread.spreadIndex - 1));
  }

  function handleClickForward() {
    dispatch(setCurrentSpreadIndexOperation(spread.spreadIndex + 1));
  }

  return (
    <div className={styles.pageSwitcherWrapper} style={{ paddingBottom: `${bottomPadding}px` }}>
      <Button rounded onClick={handleClickBack} disabled={isFirstDisabled}>
        <IconChevronLeft />
      </Button>
      <span className={styles.spreadName}>{name}</span>
      <Button rounded onClick={handleClickForward} disabled={isLastDisabled}>
        <IconChevronRight />
      </Button>
    </div>
  );
}

export default React.memo(PageSwitcher);

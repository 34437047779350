import fontManager from 'editor/src/store/fonts/fontManager';

const deleteFont = (fontFile: string) => {
  const fontExists = fontManager.loadedFontMap[fontFile];
  if (!fontExists) {
    return false;
  }

  try {
    URL.revokeObjectURL(fontExists.localUrl);
    delete fontManager.loadedFontMap[fontFile];
    document?.getElementById(fontFile)?.remove();
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error deleting font ', err);
    throw err;
  }
};

export default deleteFont;

import cn from 'classnames';
import React from 'react';

import isMultipleImageSelectAllowed from 'editor/src/store/editor/selector/isMultipleImageSelectAllowed';
import getSelectedGalleryImagesCount from 'editor/src/store/gallery/selector/getSelectedGalleryImagesCount';
import { useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import useEditorModeTranslations from 'editor/src/util/useEditorModeTranslations';

import ImagePersonalizationPromotionBanner from 'editor/src/component/Banners/ImagePersonalizationPromotionBanner';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents/index';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import UploaderDropZone from 'editor/src/component/Uploader/UploaderDropZone';

import AddSelectedImagesToDesign from './AddSelectedImagesToDesign';
import AllFilesUploadProgress from './AllFilesUploadProgress';
import ButtonAutoFill from './ButtonAutoFill';
import ButtonOpenUploader from './ButtonOpenUploader';
import ButtonToggleSelectAll from './ButtonToggleSelectAll';
import DeleteSelectedImages, { DeleteSelectedImagesHeight } from './DeleteSelectedImages';
import DigitizationGuidelines from './DigitizationGuidelines';
import GalleryImageList from './GalleryImageList';
import HideUsedCheckbox from './HideUsedCheckbox';
import ReuploadFailed from './ReuploadFailed';

import styles from './index.module.scss';

function GalleryTabContent({ title }: TabContentProps) {
  const isDraggingFileOver = useSelector((state) => state.editor.dragState === 'system');
  const digitizationRules = useSelector((state) => getHostSetting(state, 'digitizationRules'));
  const isMultipleImageSelectMode = useSelector(isMultipleImageSelectAllowed);
  const hasSelectedImages = useSelector((state) => !!getSelectedGalleryImagesCount(state));
  const { galleryTab } = useEditorModeTranslations();

  return (
    <div className={styles.GalleryTabContent}>
      <TabContentHeader title={title || galleryTab} />
      <ContentBlock>
        {digitizationRules?.guidelinesInfo && digitizationRules?.priceInfo && (
          <DigitizationGuidelines
            guidelinesInfo={digitizationRules.guidelinesInfo}
            priceInfo={digitizationRules?.priceInfo}
          />
        )}
        <ButtonOpenUploader />
        <div className="row-flex row-flex--center">
          <ButtonAutoFill />
        </div>
      </ContentBlock>
      <ImagePersonalizationPromotionBanner />

      <ContentBlock className="row-flex row-flex--between row-flex--middle py-1 px-2">
        <HideUsedCheckbox />
        <ButtonToggleSelectAll />
      </ContentBlock>
      <ContentBlock scrollAlways fill>
        <GalleryImageList extraBottomPadding={isMultipleImageSelectMode ? DeleteSelectedImagesHeight : 0} />
      </ContentBlock>
      <ContentBlock>
        <ReuploadFailed />
      </ContentBlock>
      <ContentBlock noBorder>
        <AllFilesUploadProgress />
      </ContentBlock>
      {isMultipleImageSelectMode && (
        <div className={cn(styles.addToDesign, { [styles.visible]: hasSelectedImages })}>
          <ContentBlock className="row-flex row-flex--between py-2 px-1">
            <AddSelectedImagesToDesign />
            <DeleteSelectedImages />
          </ContentBlock>
        </div>
      )}
      {isDraggingFileOver && (
        <UploaderDropZone title="Drop anywhere to upload to your file tray" className={styles.dropZone} />
      )}
    </div>
  );
}

export default React.memo(GalleryTabContent);

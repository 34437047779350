import { RootState } from 'editor/src/store/index';

import getCurrentSpreadIndex from './getCurrentSpreadIndex';
import getReplacableImageElement from './getReplacableImageElement';

const isDragElementToSpreadOnlyAllowed = (state: RootState) => {
  const currentSpreadIndex = getCurrentSpreadIndex(state);
  const isImagePlaceholderAdded = Boolean(getReplacableImageElement(state, currentSpreadIndex, true)?.uuid);

  return isImagePlaceholderAdded;
};

export default isDragElementToSpreadOnlyAllowed;

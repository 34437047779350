import { MediaElement } from 'editor/src/store/design/types';
import { PluginName } from 'editor/src/store/plugins/types';

export interface GraphicsElementData {
  addonId: string;
}

export function hasGraphicData(plugins: any) {
  return !!plugins?.[PluginName.Graphics];
}

export function getGraphicDataFromElement(element: MediaElement): GraphicsElementData | undefined {
  return element.type === 'image' && element.plugins?.[PluginName.Graphics];
}

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import setActiveRightbarOperation from 'editor/src/store/editorModules/rightbar/operation/setActiveRightbarOperation';
import { RightTab } from 'editor/src/store/editorModules/rightbar/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getAllMatchingVariants from 'editor/src/store/variants/helpers/getAllMatchingVariants';
import getAllMatchingVariations from 'editor/src/store/variants/helpers/getAllMatchingVariations';
// import getMultiOption from '../helpers/getMultiOption';
import { selectSingleOptionAction } from 'editor/src/store/variants/slice';
import { ProductControlOption, VariationProductControl } from 'editor/src/store/variants/types';

import sendSwitchProductOperation from './sendSwitchProductOperation';
import updateMultiOptionsOperation from './updateMultiOptionsOperation';

const selectSingleOptionOperation =
  (control: VariationProductControl, option: ProductControlOption) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();

    const {
      product,
      configuration,
      selectedExternalOptions,
      selectedSingleOptions,
      selectedMultiOptions,
      replaceControlKeys,
    } = state.variants;

    batch(() => {
      if (!configuration.singleSelection && control.behavior !== 'replace') {
        dispatch(selectSingleOptionAction({ key: control.key, value: option.value }));
        return;
      }

      if (control.behavior === 'replace') {
        const multiOptions = { ...selectedMultiOptions };
        Object.keys(multiOptions).forEach((key) => {
          const multiControl = product.productControls.find((control) => control.key === key);
          if (multiControl && multiControl.type === 'multi' && multiControl.dependsOnSingleControls) {
            multiOptions[key] = multiOptions[key].map((multiOption) => ({
              ...multiOption,
              dependedOptions: multiOption.dependedOptions.map((depOption) =>
                depOption.key === control.key ? { key: control.key, value: option.value } : depOption,
              ),
            }));
          }
        });

        dispatch(saveUndoRedoStateOperation('Replace single option'));
        dispatch(selectSingleOptionAction({ key: control.key, value: option.value }));
        dispatch(updateMultiOptionsOperation(multiOptions, true));
        return;
      }

      const singleOptions = {
        ...selectedSingleOptions,
        [control.key]: option.value,
      };
      const matchingVariations = getAllMatchingVariations(
        product.productVariations,
        selectedMultiOptions,
        singleOptions,
        !!configuration.singleSelection,
        replaceControlKeys,
      );
      const matchingVariantInfos = getAllMatchingVariants(
        matchingVariations,
        selectedExternalOptions,
        product.externalProductControls,
        state.variants.selectedPageCount,
      );
      const variant = matchingVariantInfos[0] || {
        variation: matchingVariations[0],
      };
      if (!variant.variation) {
        dispatch(selectSingleOptionAction({ key: control.key, value: option.value }));
        return;
      }

      dispatch(saveUndoRedoStateOperation('Toggle single option'));
      dispatch(selectSingleOptionAction({ key: control.key, value: option.value }));
      dispatch(sendSwitchProductOperation(variant));
      dispatch(setActiveRightbarOperation(RightTab.Variants));
    });
  };

export default selectSingleOptionOperation;

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';

import setGridFirstDayOfWeekOperation from 'editor/src/store/design/operation/calendarGrid/setGridFirstDayOfWeekOperation';
import { MediaGrid } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import { getWeekDays, WeekDay } from 'editor/src/util/dateUtils';

import controlStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControl.module.scss';

interface Props {
  gridElement: MediaGrid;
}

function FirstDayOfWeekControl({ gridElement }: Props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { firstDayOfWeek = 1 } = gridElement.grid;

  const onSelect = useCallback((option: SingleValue<WeekDay>) => {
    if (option) {
      dispatch(setGridFirstDayOfWeekOperation(option.value));
    }
  }, []);

  const options = useMemo(() => getWeekDays(i18n), [i18n.language]);

  const selectedOption = useMemo(
    () => options.find(({ value }) => value === firstDayOfWeek),
    [options, firstDayOfWeek],
  );

  return (
    <>
      <div className={controlStyles.controlTitle}>{t('start-week-on')}</div>
      <Select
        placeholder=""
        options={options}
        onChange={onSelect}
        value={selectedOption}
        className="cy-first-day-of-week"
      />
    </>
  );
}

export default React.memo(FirstDayOfWeekControl);

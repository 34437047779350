import React from 'react';

import { TopMenuDropdown } from 'editor/src/store/app/types';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { useSelector } from 'editor/src/store/hooks';

import IconStrokeWidth from 'editor/src/component/Icon/IconStrokeWidth';
import { MenuItemProps } from 'editor/src/component/Menu/type';
import RectangleStylesControl from 'editor/src/component/RectangleStylesControl';
import ButtonWithDropdownNotWrapped from 'editor/src/component/TopMenuDesktop/buttons/ButtonWithDropdownNotWrapped';
import MenuDropDownButton from 'editor/src/component/TopMenuDesktop/buttons/MenuDropDownButton';

function ButtonRectangleStylesDropDown({ mode, context }: MenuItemProps) {
  const selectedElement = useSelector((state) =>
    context.structureIndexes[0] ? getMediaElement(state, context.structureIndexes[0]) : undefined,
  );
  if (selectedElement?.type !== 'rectangle') {
    return null;
  }

  return (
    <ButtonWithDropdownNotWrapped
      dropDownName={TopMenuDropdown.RectangleStyles}
      buttonExtraClass="cy-button-rectangle-styles"
      dropdownExtraClass="px-0"
      dropDownContent={<RectangleStylesControl selectedElement={selectedElement} />}
      buttonContent={<MenuDropDownButton Icon={IconStrokeWidth} mode={mode} i18nKey="Rectangle Styles" />}
    />
  );
}

export default React.memo(ButtonRectangleStylesDropDown);

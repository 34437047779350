import Hammer from 'hammerjs';
import React, { useCallback, useEffect } from 'react';

import getBoxedValue from 'editor/src/util/getBoxedValue';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import { VIEWPORT_CHANGED_EVENT } from 'editor/src/component/EditorArea/types';

import {
  getCurrentCanvasPosition,
  getDeltaOnWrapperClick,
  getFullCanvasWidth,
  Range,
  ScrollLocation,
  ScrollLocations,
} from './scrollbarUtils';

function useScrollbarUpdates(
  controlRef: React.RefObject<HTMLDivElement>,
  moveScrollControl: (scrollDelta: number, scrollWidth?: number) => void,
  hammerDirection: number,
  getPanDelta: (event: HammerInput) => number,
  fireScrollEvent: (canvasDelta: number) => void,
  getScrollLocations: () => ScrollLocations | undefined,
  getTransformBoundaryCoordinates: () => Range,
  canvasSize: number,
) {
  const fabricCanvas = useFabricCanvas();

  useEffect(() => {
    const onViewPortChanged = () => {
      const { position, width } = getScrollControlLocation();
      moveScrollControl(position, width);
    };

    fabricCanvas?.on(VIEWPORT_CHANGED_EVENT, onViewPortChanged);

    return () => {
      fabricCanvas?.off(VIEWPORT_CHANGED_EVENT, onViewPortChanged);
    };
  }, [getTransformBoundaryCoordinates, canvasSize, getScrollLocations]);

  const scrollDelta2CanvasDelta = useCallback(
    (scrollDelta: number) => {
      const scrollLocations = getScrollLocations();
      if (!scrollLocations) {
        return 0;
      }

      const { min: transformMin, max: transformMax } = getTransformBoundaryCoordinates();
      const fullCanvasWidth = getFullCanvasWidth(transformMin, transformMax, canvasSize) - canvasSize;
      const maxWrapperPosition = scrollLocations.wrapper.width - scrollLocations.control.width;
      const transformedScrollControlPosition = getBoxedValue(
        scrollLocations.control.position - scrollLocations.wrapper.position + scrollDelta,
        0,
        maxWrapperPosition,
      );

      const transformedX = (transformedScrollControlPosition / maxWrapperPosition) * fullCanvasWidth;
      const currentCanvasPosition =
        getCurrentCanvasPosition(transformMin, transformMax, scrollLocations.viewportTransform) * fullCanvasWidth;
      return currentCanvasPosition - transformedX;
    },
    [getTransformBoundaryCoordinates, getScrollLocations, canvasSize],
  );

  const handleWrapperClick = useCallback(
    (clickPosition: number) => {
      const scrollLocations = getScrollLocations();
      if (!scrollLocations) {
        return;
      }

      const scrollDelta = getDeltaOnWrapperClick(
        clickPosition,
        scrollLocations.control.position,
        scrollLocations.control.width,
      );
      const canvasDelta = scrollDelta2CanvasDelta(scrollDelta);
      canvasDelta && fireScrollEvent(canvasDelta);
    },
    [scrollDelta2CanvasDelta],
  );

  useEffect(() => {
    if (!controlRef?.current) {
      return undefined;
    }

    const hammer = new Hammer(controlRef.current);
    hammer.get('pan').set({
      enable: true,
      threshold: 7,
      pointers: 1,
      direction: hammerDirection,
    });

    hammer.on('panstart', () => {
      let lastDelta = 0;
      hammer.on('pan', (event) => {
        const panDelta = getPanDelta(event);
        const delta = panDelta - lastDelta;
        if (!delta) {
          return;
        }

        lastDelta = panDelta;
        fireScrollEvent(scrollDelta2CanvasDelta(delta));
      });

      hammer.on('panend', () => {
        hammer.off('pan');
        hammer.off('panend');
      });
    });

    return () => {
      hammer.off('panstart');
      hammer.off('pan');
      hammer.off('panend');
    };
  }, [controlRef?.current, scrollDelta2CanvasDelta, hammerDirection]);

  const getScrollControlLocation = (): ScrollLocation => {
    const { min: transformMin, max: transformMax } = getTransformBoundaryCoordinates();
    const scrollLocations = getScrollLocations();
    if (!scrollLocations || transformMax === transformMin) {
      return { width: 0, position: 0 };
    }

    const fullWidth = getFullCanvasWidth(transformMin, transformMax, canvasSize);

    const currentCanvasPosition = getCurrentCanvasPosition(
      transformMin,
      transformMax,
      scrollLocations.viewportTransform,
    );
    const controlWidth = (canvasSize / fullWidth) * scrollLocations.wrapper.width;
    return {
      width: controlWidth,
      position: currentCanvasPosition * (scrollLocations.wrapper.width - controlWidth),
    };
  };

  return { handleWrapperClick };
}

export default useScrollbarUpdates;

import type { ThunkDispatch } from 'editor/src/store/hooks';

import { setIsLoadingAction } from '../slice';

const setLoadingWithTimeoutOperation = () => (dispatch: ThunkDispatch) => {
  dispatch(setIsLoadingAction(true));

  window.setTimeout(() => {
    dispatch(setIsLoadingAction(false));
  }, 10000);
};
export default setLoadingWithTimeoutOperation;

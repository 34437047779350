import { fabric } from 'fabric';
import Hammer from 'hammerjs';
import React, { RefObject, useCallback, useEffect, useRef } from 'react';

import { Coords, Dimensions } from 'editor/src/store/design/types';
import removeAllSelectedMediaElementsOperation from 'editor/src/store/editor/operation/removeAllSelectedMediaElementsOperation';
import { useDispatch } from 'editor/src/store/hooks';

import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import { FABRIC_SCROLL_EVENT, VIEWPORT_CHANGED_EVENT } from 'editor/src/component/EditorArea/types';
import removeTextEditing, { MAX_ZOOM } from 'editor/src/component/EditorArea/utils/zoomUtils';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import useGetCenter from './useGetCenter';
import useMinZoom from './useMinZoom';

interface Props {
  canvasDivRef: RefObject<HTMLDivElement>;
  spreadIndex: number;
  spreadCoords: Coords;
  spreadWidth: number;
  spreadHeight: number;
  canvasDim: Dimensions;
}

const DRAG_VIEWPORT_MODE_CLASS = 'dragViewportMode';
const DRAGGING_VIEWPORT_CLASS = 'dragging';

export const DEFAULT_TRANSFORM = [0, 0, 0, 0, 0, 0];
function getInitialTransform(viewportTransform: number[] | undefined) {
  return viewportTransform ? [...viewportTransform] : DEFAULT_TRANSFORM;
}

interface FabricScrollEvent {
  deltaX: number;
  deltaY: number;
}

function Gestures({ canvasDivRef, spreadIndex, spreadWidth, spreadHeight, spreadCoords, canvasDim }: Props) {
  const fabricCanvas = useFabricCanvas();
  const interactingElement = useRef(false);
  const interactingCanvas = useRef(false);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { mm2px } = useFabricUtils();
  const { minZoom } = useMinZoom(canvasDim, spreadIndex, spreadWidth, spreadHeight, mm2px);
  const { getVtpX, getVtpY } = useGetCenter(
    fabricCanvas,
    canvasDim,
    spreadIndex,
    spreadCoords,
    spreadWidth,
    spreadHeight,
    minZoom,
    mm2px,
  );

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        activateDragViewportMode();
      }
    };

    const onKeyUp = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        deactivateDragViewportMode();
      }
    };

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  const activateDragViewportMode = useCallback(
    () => fabricCanvas.getElement().parentElement?.classList.add(DRAG_VIEWPORT_MODE_CLASS),
    [],
  );
  const activateDraggingViewportMode = useCallback(
    () => fabricCanvas.getElement().parentElement?.classList.add(DRAGGING_VIEWPORT_CLASS),
    [],
  );
  const deactivateDragViewportMode = useCallback(() => {
    fabricCanvas.getElement().parentElement?.classList.remove(DRAG_VIEWPORT_MODE_CLASS);
    fabricCanvas.getElement().parentElement?.classList.remove(DRAGGING_VIEWPORT_CLASS);
  }, []);

  // detect if we're clicking on an interactable fabric element or not
  useEffect(() => {
    const onMouseDown = (e: fabric.IEvent) => {
      interactingElement.current = !!e.target && (!isMobile || fabricCanvas.getActiveObject() === e.target);
    };

    const onMouseUp = (e: fabric.IEvent) => {
      if (isMobile && e.target && !interactingCanvas.current) {
        fabricCanvas.setActiveObject(e.target);
      }
      interactingElement.current = false;
      interactingCanvas.current = false;
    };

    fabricCanvas.on('mouse:down', onMouseDown);
    fabricCanvas.on('mouse:up', onMouseUp);

    return () => {
      fabricCanvas.off('mouse:down', onMouseDown);
      fabricCanvas.off('mouse:up', onMouseUp);
    };
  }, []);

  useEffect(() => {
    if (!canvasDivRef?.current) {
      return undefined;
    }

    const onZoom = (zoom: number, centerX: number, centerY: number) => {
      let newZoom = zoom;
      if (newZoom < minZoom) {
        newZoom = minZoom;
      }
      if (newZoom > MAX_ZOOM) {
        newZoom = MAX_ZOOM;
      }

      fabricCanvas.zoomToPoint({ x: centerX, y: centerY }, newZoom);
      const vpt = fabricCanvas.viewportTransform || DEFAULT_TRANSFORM;
      vpt[4] = getVtpX(vpt[4], newZoom);
      vpt[5] = getVtpY(vpt[5], newZoom);
      fabricCanvas.setViewportTransform(vpt);
      fabricCanvas.fire(VIEWPORT_CHANGED_EVENT);
      fabricCanvas.requestRenderAll();
    };

    const onMouseWheel = (e: WheelEvent) => {
      e.preventDefault();
      if (
        canvasDivRef.current &&
        (canvasDivRef.current.contains(e.target as Node) || canvasDivRef.current === e.target)
      ) {
        // zoom action (when doing a pinch with the trackpad, ctrlKey is true)
        if (e.ctrlKey || e.metaKey) {
          removeTextEditing(fabricCanvas);
          const zoomFactor = 0.995;
          const bbox = canvasDivRef.current.getBoundingClientRect();
          onZoom(fabricCanvas.getZoom() * zoomFactor ** e.deltaY, e.clientX - bbox.left, e.clientY - bbox.top);
        } else {
          // scroll action
          transformViewport(getInitialTransform(fabricCanvas.viewportTransform), -e.deltaX, -e.deltaY);
        }
      }
    };

    canvasDivRef.current.addEventListener('wheel', onMouseWheel, {
      passive: false,
    });

    const hammer = new Hammer(canvasDivRef.current);
    hammer.get('pinch').set({ enable: true, threshold: 0.1 });
    hammer.get('pan').set({
      enable: true,
      threshold: 7,
      pointers: 1,
      direction: Hammer.DIRECTION_ALL,
    });

    let initialZoom = 1;
    hammer.on('pinchstart', () => {
      if (interactingElement.current) {
        return;
      }
      interactingCanvas.current = true;

      dispatch(removeAllSelectedMediaElementsOperation());
      fabricCanvas.discardActiveObject();
      initialZoom = fabricCanvas.getZoom() || 1;

      hammer.on('pinch', (e) => {
        onZoom(initialZoom * e.scale, e.center.x, e.center.y);
      });

      hammer.on('pinchend', () => {
        hammer.off('pinch');
        hammer.off('pinchend');
      });
    });

    hammer.on('panstart', () => {
      if (interactingElement.current) {
        return;
      }

      activateDragViewportMode();
      interactingCanvas.current = true;
      const initialTransform = getInitialTransform(fabricCanvas.viewportTransform);

      hammer.on('pan', (e) => {
        activateDraggingViewportMode();
        transformViewport(initialTransform, e.deltaX, e.deltaY);
      });

      hammer.on('panend', () => {
        deactivateDragViewportMode();
        hammer.off('pan');
        hammer.off('panend');
      });
    });

    return () => {
      hammer.off('pinch');
      hammer.off('pinchstart');
      hammer.off('pinchend');
      hammer.off('pan');
      hammer.off('panstart');
      hammer.off('panend');
      hammer.destroy();
      canvasDivRef.current?.removeEventListener('wheel', onMouseWheel);
    };
  }, [canvasDivRef?.current, minZoom, getVtpX, getVtpY]);

  const transformViewport = useCallback(
    (initialTransform: number[], deltaX: number, deltaY: number) => {
      const transform = fabricCanvas.viewportTransform || [1, 0, 0, 1, 0, 0];
      transform[4] = getVtpX(initialTransform[4] + deltaX);
      transform[5] = getVtpY(initialTransform[5] + deltaY);
      fabricCanvas.setViewportTransform(transform);
      fabricCanvas.fire(VIEWPORT_CHANGED_EVENT);
      fabricCanvas.requestRenderAll();
    },
    [getVtpX, getVtpY],
  );

  useEffect(() => {
    const scrollEventHandler = (e: fabric.IEvent<Event>) => {
      const { deltaX, deltaY } = e as unknown as FabricScrollEvent;
      transformViewport(getInitialTransform(fabricCanvas.viewportTransform), deltaX, deltaY);
    };

    fabricCanvas.on(FABRIC_SCROLL_EVENT, scrollEventHandler);
    return () => {
      fabricCanvas.off(FABRIC_SCROLL_EVENT, scrollEventHandler);
    };
  }, [transformViewport]);

  return null;
}

export default React.memo(Gestures);

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import Badge from 'editor/src/component/DesktopSidebar/Tabs/Tab/Badge';
import IconCross from 'editor/src/component/Icon/IconCross';
import IconHelp from 'editor/src/component/Icon/IconHelp';
import IconSettings from 'editor/src/component/Icon/IconSettings';
import IconWarning from 'editor/src/component/Icon/IconWarning';

import MenuButton from './MenuButton';

import styles from './index.module.scss';

interface Props {
  close: () => void;
  openHelpMenu: () => void;
  showSettings: boolean;
  showWarnings: boolean;
  warningsCount: number;
  showHelpCenter: boolean;
}

const SETTINGS_ICON = <IconSettings className={styles.buttonIconSettings} />;
const WARNINGS_ICON = <IconWarning className={styles.buttonIconWarnings} />;
const HELP_ICON = <IconHelp />;

function MoreMenuContent({ close, openHelpMenu, showSettings, showWarnings, warningsCount, showHelpCenter }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openSettings = useCallback(() => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.SETTINGS));
    close();
  }, [close]);

  const openWarnings = useCallback(() => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.WARNINGS));
    close();
  }, [close]);

  return (
    <div className={styles.MenuContent}>
      <div className={styles.header}>
        {t('editor-more-options')}
        <ControlButton onClick={close}>
          <IconCross />
        </ControlButton>
      </div>
      <div className={styles.content}>
        {showSettings && <MenuButton Icon={SETTINGS_ICON} title={t('editor-settings')} onClick={openSettings} />}
        {showHelpCenter && (
          <MenuButton Icon={HELP_ICON} title={t('Help')} onClick={openHelpMenu} className="cy-button-help-menu" />
        )}
        {showWarnings && !!warningsCount && (
          <MenuButton Icon={WARNINGS_ICON} title={t('editor-warnings')} onClick={openWarnings}>
            {warningsCount ? <Badge className={styles.badge}>{warningsCount}</Badge> : null}
          </MenuButton>
        )}
      </div>
    </div>
  );
}

export default React.memo(MoreMenuContent);

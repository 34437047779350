import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { PreviewFormat } from 'editor/src/store/mockup/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const startMockupDownloadOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  const state = getState();
  const { appliedVariant } = state.mockup.preview;
  const { designData } = state.design;

  if (appliedVariant && designData) {
    sendPostMessage('mockup.downloadPreview', {
      designData,
      variant: appliedVariant,
      format: PreviewFormat.Jpg,
    });
  }
};

export default startMockupDownloadOperation;

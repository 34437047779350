import { GridDesign } from '@gelatoas/design-editor-calendar';
import { captureException } from '@sentry/react';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updateGridElementsOnSpreadOperation from 'editor/src/store/design/operation/updateGridElementOnSpreadOperation';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import loadGridDesignFonts from 'editor/src/store/fonts/utils/loadGridDesignFonts';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import { setPageBackgroundColorAction } from '../../slice';

const setGridDesignOperation = (design: GridDesign) => async (dispatch: ThunkDispatch, getState: () => RootState) => {
  let state = getState();
  let fonts;
  try {
    fonts = await loadGridDesignFonts(design, state.fonts.availableFonts);
  } catch (e) {
    captureException(new Error(e || 'setGridDesignNameOperation: failed to load grid design fonts'), {
      extra: { fonts: fonts?.map((font) => font.definition.metadata) },
    });
  }

  batch(() => {
    dispatch(saveUndoRedoStateOperation('Set Grid Design'));

    state = getState();
    const spreadIndex = getCurrentSpreadIndex(state);

    dispatch(
      setPageBackgroundColorAction({
        spreadIndex,
        pageIndex: undefined,
        backgroundColor: design.presentationBackgroundColor,
      }),
    );

    dispatch(
      updateGridElementsOnSpreadOperation({
        gridDesigns: state.gridDesigns.grids,
        spreadIndex,
        override: { designName: design.name },
        fonts: state.fonts.availableFonts,
      }),
    );
  });
};

export default setGridDesignOperation;

import React from 'react';

import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';

const MAX_WIDTH = 4000;
const FILL_COLOR = 'rgba(0, 0, 0, 0.1)';

interface Props {
  zIndex: number;
}

function ActiveImageOverlay({ zIndex }: Props) {
  return (
    <FabricRectComponent
      left={-MAX_WIDTH}
      top={-MAX_WIDTH}
      width={MAX_WIDTH * 2}
      height={MAX_WIDTH * 2}
      evented={false}
      fill={FILL_COLOR}
      zIndex={zIndex}
      objectCaching={false}
    />
  );
}

export default React.memo(ActiveImageOverlay);

import React from 'react';
import { useTranslation } from 'react-i18next';

import getSelectedElementProperty from 'editor/src/store/design/selector/getSelectedElementProperty';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import isSidebarTabActive from 'editor/src/store/editorModules/sidebar/selector/isSidebarTabActive';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useBrowserColor from 'editor/src/util/useBrowserColor';

import IconFillColor from 'editor/src/component/Icon/IconFillColor';
import IconNoColor from 'editor/src/component/Icon/IconNoColor';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton, { Props as MenuProps } from './MenuButton';

const MenuColorButton = MenuButton as React.FC<MenuProps<{ color: string | undefined }>>;
type Props = Pick<MenuItemProps, 'mode' | 'close' | 'isMobile'>;

export const canShow: CanShow = (state, { isPersonalizationLocked }) => !isPersonalizationLocked;

function ButtonRectangleFillColor({ mode, close, isMobile }: Props) {
  const currentColor = useSelector((state) => getSelectedElementProperty(state, 'fill', 'rectangle'));
  const color = useBrowserColor(currentColor);
  const isActive = useSelector((state) => isSidebarTabActive(state, TAB_NAMES.RECTANGLE_FILL_COLOR));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = () => {
    if (isMobile) {
      if (isActive) {
        dispatch(setSidebarActiveTabOperation(-1));
      } else {
        dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.RECTANGLE_FILL_COLOR));
      }
    } else {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.RECTANGLE_FILL_COLOR));
      close?.();
    }
  };

  return (
    <MenuColorButton
      Icon={currentColor ? IconFillColor : IconNoColor}
      iconProps={{ color }}
      on={isActive}
      mode={mode}
      handleClick={handleClick}
      className="cy-button-rectangle-fill-color"
      label={t('Fill color')}
    />
  );
}

export default React.memo(ButtonRectangleFillColor);

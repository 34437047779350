import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setDynamicTabsAction } from 'editor/src/store/editorModules/sidebar/slice';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import { RootState } from 'editor/src/store';

import updateSidebarTabsOperation from './updateSidebarTabsOperation';

const toggleDynamicTabOperation =
  (tab: TAB_NAMES, on: boolean, position: number | undefined) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    batch(() => {
      const dynamicTabs = getState().editorModules.sidebar.dynamicTabs.filter((t) => t.tab !== tab);
      if (on) {
        dynamicTabs.push({ tab, position });
      }
      dispatch(setDynamicTabsAction(dynamicTabs));
      dispatch(updateSidebarTabsOperation());
    });
  };

export default toggleDynamicTabOperation;

import { createSlice } from '@reduxjs/toolkit';

import increaseRequestVersionReducer from './reducer/increaseRequestVersionReducer';
import resetSearchReducer from './reducer/resetSearchReducer';
import setActiveFiltersReducer from './reducer/setActiveFiltersReducer';
import setActiveGroupReducer from './reducer/setActiveGroupReducer';
import setActiveTabReducer from './reducer/setActiveTabReducer';
import setLoadingMoreReducer from './reducer/setLoadingMoreReducer';
import setRequestVersionReducer from './reducer/setRequestVersionReducer';
import setResultsReducer from './reducer/setResultsReducer';
import setSearchFiltersReducer from './reducer/setSearchFiltersReducer';
import setSearchSortReducer from './reducer/setSearchSortReducer';
import setSearchValueReducer from './reducer/setSearchValueReducer';
import { ContentProviderTabNames, DataState } from './types';

export const dataInitState: DataState = {
  requestVersion: -1,
  loadingMore: false,
  activeTab: ContentProviderTabNames.MAIN,
  results: {
    groupId: undefined,
    search: {
      value: '',
      filters: undefined,
      sort: undefined,
    },
    resultsVersion: -1,
    elements: [],
    error: false,
  },
  activeGroup: undefined,
};

const slice = createSlice({
  name: 'AssetSourcePluginData',
  initialState: dataInitState,
  reducers: {
    setResultsAction: setResultsReducer,
    setActiveGroupAction: setActiveGroupReducer,
    increaseRequestVersionAction: increaseRequestVersionReducer,
    setRequestVersionAction: setRequestVersionReducer,
    setSearchValueAction: setSearchValueReducer,
    setSearchFiltersAction: setSearchFiltersReducer,
    setSearchSortAction: setSearchSortReducer,
    setLoadingMoreAction: setLoadingMoreReducer,
    resetSearchAction: resetSearchReducer,
    setActiveFiltersAction: setActiveFiltersReducer,
    setActiveTabAction: setActiveTabReducer,
  },
});

export const {
  setResultsAction,
  setActiveGroupAction,
  increaseRequestVersionAction,
  setRequestVersionAction,
  setSearchValueAction,
  setSearchFiltersAction,
  setSearchSortAction,
  setLoadingMoreAction,
  resetSearchAction,
  setActiveFiltersAction,
  setActiveTabAction,
} = slice.actions;

export default slice;

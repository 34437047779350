import { setPageBackgroundColorAction } from 'editor/src/store/design/slice';

import { batch } from '../../batchedSubscribeEnhancer';
import saveUndoRedoStateOperation from '../../editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import { Thunk } from '../../hooks';

const setPageBackgroundColorOperation =
  (spreadIndex: number, pageIndex: number | undefined, backgroundColor: string | undefined): Thunk =>
  (dispatch) => {
    batch(() => {
      dispatch(saveUndoRedoStateOperation('Set Page Background Color'));
      dispatch(setPageBackgroundColorAction({ spreadIndex, pageIndex, backgroundColor }));
    });
  };

export default setPageBackgroundColorOperation;

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconWidth({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          // eslint-disable-next-line max-len
          d="M6.8249 13L7.6999 13.925C7.88324 14.1083 7.9749 14.3375 7.9749 14.6125C7.9749 14.8875 7.88324 15.1167 7.6999 15.3C7.4999 15.5 7.2624 15.6 6.9874 15.6C6.7124 15.6 6.48324 15.5 6.2999 15.3L3.6999 12.725C3.5999 12.625 3.5249 12.5125 3.4749 12.3875C3.4249 12.2625 3.3999 12.1333 3.3999 12C3.3999 11.8667 3.4249 11.7417 3.4749 11.625C3.5249 11.5083 3.5999 11.4 3.6999 11.3L6.2999 8.69999C6.48324 8.51665 6.7124 8.42499 6.9874 8.42499C7.2624 8.42499 7.4999 8.51665 7.6999 8.69999C7.8999 8.89999 7.9999 9.13749 7.9999 9.41249C7.9999 9.68749 7.8999 9.92499 7.6999 10.125L6.8249 11H17.1749L16.2749 10.1C16.0916 9.91665 15.9999 9.68749 15.9999 9.41249C15.9999 9.13749 16.0999 8.89999 16.2999 8.69999C16.4832 8.51665 16.7124 8.42499 16.9874 8.42499C17.2624 8.42499 17.4999 8.51665 17.6999 8.69999L20.2749 11.3C20.3749 11.4 20.4499 11.5083 20.4999 11.625C20.5499 11.7417 20.5749 11.8667 20.5749 12C20.5749 12.1333 20.5499 12.2625 20.4999 12.3875C20.4499 12.5125 20.3749 12.625 20.2749 12.725L17.6749 15.325C17.4916 15.5083 17.2624 15.6 16.9874 15.6C16.7124 15.6 16.4749 15.5 16.2749 15.3C16.0916 15.1167 15.9999 14.8875 15.9999 14.6125C15.9999 14.3375 16.0916 14.1083 16.2749 13.925L17.1749 13H6.8249Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconWidth;

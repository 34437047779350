import React from 'react';

interface Props {
  className: string;
}

/* eslint-disable max-len */
function EmptyPlaceholderIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ strokeWidth: 0 }}
    >
      <path
        d="M29.45 6V9H9V39H39V18.6H42V39C42 39.8 41.7 40.5 41.1 41.1C40.5 41.7 39.8 42 39 42H9C8.2 42 7.5 41.7 6.9 41.1C6.3 40.5 6 39.8 6 39V9C6 8.2 6.3 7.5 6.9 6.9C7.5 6.3 8.2 6 9 6H29.45ZM38 6V10.05H42.05V13.05H38V17.1H35V13.05H30.95V10.05H35V6H38ZM12 33.9H36L28.8 24.3L22.45 32.65L17.75 26.45L12 33.9Z"
        fill="#999999"
      />
    </svg>
  );
}

export default React.memo(EmptyPlaceholderIcon);

import { PayloadAction } from '@reduxjs/toolkit';

import {
  ContentProviderTabNames,
  DataState,
} from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

const setActiveTabReducer = (state: DataState, { payload }: PayloadAction<ContentProviderTabNames>) => {
  state.activeTab = payload;
};

export default setActiveTabReducer;

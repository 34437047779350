import { CanShow } from 'editor/src/component/Menu/type';

import ButtonCropZoom from './ButtonCropZoom';
import ButtonCropZoomDropDown from './ButtonCropZoomDropDown';

export const canShow: CanShow = (state, { hasSelection, isElementEmpty }) => {
  const { imageCropZoom } = state.hostSettings.coreFeatures;
  return hasSelection && !isElementEmpty && imageCropZoom;
};

export { ButtonCropZoomDropDown, ButtonCropZoom };

export default ButtonCropZoom;

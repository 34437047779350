import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { shallowEqual } from 'react-redux';

import getImageElementUrl from 'editor/src/store/design/selector/getImageElementUrl';
import { MediaImage } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

import { ItemProps } from 'editor/src/component/DesktopSidebar/TabContents/LayerItem/AbstractLayerItem';

import abstractStyles from 'editor/src/component/DesktopSidebar/TabContents/LayerItem/AbstractLayerItem/index.module.scss';

interface Props extends Omit<ItemProps<MediaImage>, 'spreadDisplay'> {
  isEditing: boolean;
  name: string;
  onNameChange?: (name: string) => void;
  finishEditing?: (name: string) => void;
  onEdit?: () => void;
}

function ImageLayerItem({ element, isEditing, finishEditing, name, onNameChange, onEdit }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { imageUrl, crossOrigin } = useSelector((state) => getImageElementUrl(state, element), shallowEqual);

  function onChange(e: React.ChangeEvent) {
    const { value } = e.target as HTMLInputElement;
    onNameChange?.(value);
  }

  function onKeyUp(e: React.KeyboardEvent) {
    e.stopPropagation();
    if (e.key === 'Escape' || e.key === 'Enter') {
      finishEditing?.(name);
    }
  }

  function onElNameClick() {
    if (!isEditing) {
      onEdit?.();
    }
  }

  useEffect(() => {
    inputRef.current?.select();
    window.setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [isEditing]);

  return (
    <>
      <div className={abstractStyles.thumb}>
        <div className={abstractStyles.image}>
          <img
            key={imageUrl + (crossOrigin || '')} // needed to avoid some CORS issue with safari
            crossOrigin={crossOrigin}
            draggable={false}
            src={imageUrl}
            alt={element.name}
          />
        </div>
      </div>
      <div
        onClick={onElNameClick}
        className={cn(abstractStyles.text, 'cy-layer-text', { [abstractStyles.clickable]: !isEditing })}
      >
        {isEditing ? <input ref={inputRef} value={name} onKeyUp={onKeyUp} onChange={onChange} /> : element.name}
      </div>
    </>
  );
}

export default React.memo(ImageLayerItem);

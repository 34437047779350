import React, { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import getCurrentSpreadGroup from 'editor/src/store/design/selector/getCurrentSpreadGroup';
import getSpread from 'editor/src/store/design/selector/getSpread';
import getCurrentSpreadContentElements from 'editor/src/store/editor/selector/getCurrentSpreadContentElements';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getAllowedLayouts from 'editor/src/store/editorModules/layouts/selector/getAllowedLayouts';
import { useSelector } from 'editor/src/store/hooks';

import getBoxesMinMax from 'editor/src/util/getBoxesMinMax';
import filterGroupLayouts from 'editor/src/util/layouts/filterGroupLayouts';

import SchemaItem from 'editor/src/component/DesktopSidebar/TabContents/LayoutsTabContent/SchemaItem';

import styles from './index.module.scss';

function SchemasList() {
  const { layoutSchemas, currentLayoutName, currentSpreadContentElements, spreadTags, spreadGroup } = useSelector(
    (state) => {
      const spread = getSpread(state, getCurrentSpreadIndex(state));
      return {
        layoutSchemas: state.editorModules.layouts.schemas,
        currentLayoutName: spread?.layoutSchemaName,
        currentSpreadContentElements: getCurrentSpreadContentElements(state),
        spreadTags: spread?.tags,
        spreadGroup: getCurrentSpreadGroup(state),
      };
    },
    shallowEqual,
  );

  const groupLayouts = spreadGroup ? filterGroupLayouts(spreadGroup) : [];

  const layoutApplyArea = useMemo(() => getBoxesMinMax(currentSpreadContentElements), [currentSpreadContentElements]);

  const schemas = useMemo(() => getAllowedLayouts(layoutSchemas, spreadTags), [layoutSchemas, spreadTags]);

  if (layoutApplyArea.width === 0) {
    return null;
  }

  return (
    <div className={styles.SchemasList}>
      {!groupLayouts.length
        ? schemas.map((schema) => (
            <SchemaItem
              key={schema.name}
              schema={schema}
              schemaSize={layoutApplyArea}
              isActive={schema.name === currentLayoutName}
              layoutSchemas={layoutSchemas}
            />
          ))
        : groupLayouts.map((layout, index) => (
            <SchemaItem
              key={index}
              schemaSize={layoutApplyArea}
              isActive={layout.name === currentLayoutName}
              layoutSchemas={layoutSchemas}
              groupLayout={layout}
              spreadGroup={spreadGroup}
            />
          ))}
    </div>
  );
}

export default React.memo(SchemasList);

import cn from 'classnames';
import React from 'react';

import { MediaLine } from 'editor/src/store/design/types';

import IconLayersLine from 'editor/src/component/Icon/IconLayersLine';

import abstractStyles from './AbstractLayerItem/index.module.scss';

interface Props {
  element: MediaLine;
}

function LineLayerItem({ element }: Props) {
  return (
    <>
      <div className={abstractStyles.thumb}>
        <IconLayersLine />
      </div>
      <div className={cn(abstractStyles.text, 'cy-layer-text')}>{`Line ${element.uuid}`}</div>
    </>
  );
}

export default React.memo(LineLayerItem);

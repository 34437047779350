import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import doesSpreadHasMissingImages from 'editor/src/store/design/selector/doesSpreadHasMissingImages';
import { Spread } from 'editor/src/store/design/types';
import loadFontOperation from 'editor/src/store/fonts/operation/loadFontOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useIsMounted from 'editor/src/util/useIsMounted';

import { createSpreadPreview, RequestRenderFn, SpreadPreviewDataURL } from 'editor/src/component/SpreadPreview';
import {
  PreviewOutput,
  SpreadPreviewBlob,
  SpreadPreviewCanvas,
  SpreadPreviewOptions,
} from 'editor/src/component/SpreadPreview/createSpreadPreview';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

function useSpreadTemplatePreview<Type extends PreviewOutput = 'dataURL'>(
  requestRender: RequestRenderFn,
  spread: Spread | undefined,
  designKey: string,
  spreadIndex: number,
  size: { dimension: 'height' | 'width' | 'both'; value: number },
  options: SpreadPreviewOptions<Type>,
  skipIsMounted?: boolean,
) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const { images, addons } = useSelector((state) => {
    return {
      images: state.gallery.images,
      addons: state.editorModules.addons.inUse,
    };
  }, shallowEqual);
  const [preview, setPreview] =
    useState<
      Type extends 'blob' ? SpreadPreviewBlob : Type extends 'canvas' ? SpreadPreviewCanvas : SpreadPreviewDataURL
    >();

  const hasMissingImages = useMemo(() => doesSpreadHasMissingImages(spread, images), [spread, images]);
  const isMounted = skipIsMounted ? () => true : useIsMounted();
  const mounted = isMounted();

  function loadFont(fontFamily: string) {
    return dispatch(loadFontOperation(fontFamily));
  }

  useEffect(() => {
    if (!skipIsMounted) {
      setPreview(undefined);
    }
  }, [spreadIndex, skipIsMounted]);

  const version = useRef(0);
  const currentVersion = version.current;
  useMemo(() => {
    if (!spread) {
      return;
    }

    version.current += 1;

    void createSpreadPreview(
      designKey,
      spread,
      {
        backgroundImage: undefined,
        foregroundImage: undefined,
        gridDesigns: [],
        images,
        addons,
      },
      spreadIndex,
      undefined,
      size,
      requestRender,
      {},
      loadFont,
      isMobile,
      t,
      options,
    ).then((preview) => {
      if (isMounted() && preview && currentVersion + 1 === version.current) {
        setPreview(preview as any);
      }
    });
  }, [spread, hasMissingImages, addons, designKey, mounted]);

  return { preview, isRendering: currentVersion !== version.current };
}

export default useSpreadTemplatePreview;

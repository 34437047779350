import applyToSelectedMediaTypeOperation from 'editor/src/store/design/operation/applyToSelectedMediaTypeOperation';
import { MediaText, TextWrapping } from 'editor/src/store/design/types';
import { setCurrentStyleAction } from 'editor/src/store/editor/slice';
import { FontStyles } from 'editor/src/store/fonts/types';
import isFontFileLoaded from 'editor/src/store/fonts/utils/isFontFileLoaded';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import { logEvent } from 'editor/src/amplitude';
import isShadowEmpty from 'editor/src/util/isShadowEmpty';

import { extractTextProperties } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Text/updateTextElementWithoutRender';

const applyFontStylesToSelectionOperation =
  ({ color, angle, ...extra }: Partial<FontStyles>, preventSavingState = false) =>
  (dispatch: ThunkDispatch) => {
    if (extra.fontFamily && !isFontFileLoaded(extra.fontFamily)) {
      throw new Error(`Tried to apply a font which has not been loaded - ${extra.fontFamily}`);
    }

    dispatch(
      applyToSelectedMediaTypeOperation(
        'text',
        (element) => {
          const textElementUpdate: Partial<MediaText> = {
            fill: color ?? element.fill,
            r: angle ?? element.r,
            extra: { ...element.extra, ...extra },
          };

          if (extra.fontFamily) {
            dispatch(
              setCurrentStyleAction({
                type: 'text',
                update: { fontFamily: extra.fontFamily },
              }),
            );
          }

          if (extra.letterSpacing) {
            logEvent('element modified', {
              type: 'text',
              property: 'letter-spacing',
              value: extra.letterSpacing,
            });
          }

          if ('shadow' in extra) {
            logEvent('element modified', {
              type: 'text',
              property: 'shadow',
              value: !isShadowEmpty(extra.shadow),
            });
            dispatch(
              setCurrentStyleAction({
                type: 'text',
                update: { shadow: extra.shadow ?? element.extra.shadow },
              }),
            );
          }

          if ('stroke' in extra) {
            logEvent('element modified', {
              type: 'text',
              property: 'stroke',
              value: !!extra.stroke,
            });
            dispatch(
              setCurrentStyleAction({
                type: 'text',
                update: { stroke: extra.stroke ?? element.extra.stroke },
              }),
            );
          }

          const newElement = { ...element, ...textElementUpdate };

          if (newElement.extra.wrapping !== TextWrapping.Fit && newElement.extra.wrapping !== TextWrapping.FitOneLine) {
            const recalculateTextWidth = 'curve' in extra;
            const { width, height, sx, sy, x, y } = extractTextProperties(
              { ...element, ...textElementUpdate },
              undefined,
              recalculateTextWidth,
            );
            textElementUpdate.width = width;
            textElementUpdate.height = height;
            textElementUpdate.sx = sx;
            textElementUpdate.sy = sy;
            textElementUpdate.x = x;
            textElementUpdate.y = y;
          }

          return textElementUpdate;
        },
        preventSavingState,
      ),
    );
  };

export default applyFontStylesToSelectionOperation;

import React, { useCallback, useState } from 'react';

import { useDispatch } from 'editor/src/store/hooks';
import fetchProductScenesOperation from 'editor/src/store/mockup/operation/fetchProductScenesOperation';
import { MockupProductCategory, MockupProductEntry } from 'editor/src/store/mockup/types';

import noop from 'editor/src/util/noop';

import BackHeader from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/BackHeader';
import MockupList from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/MockupList';
import SceneModelsSelection from 'editor/src/component/DesktopSidebar/TabContents/PlaceholderTabContent/SceneModelsSelection';

import ProductCategoryEntry from './ProductCategoryEntry';

interface Props {
  category: MockupProductCategory;
  back: () => void;
}

function ProductCategoryList({ category, back }: Props) {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dispatch = useDispatch();

  const unselectCategory = useCallback(() => setSelectedIndex(-1), []);
  const selectedCategory = category.children[selectedIndex];

  const setSelectedCategory = useCallback((entry: MockupProductEntry) => {
    setSelectedIndex(category.children.findIndex((child) => child.id === entry.id));

    if (entry.type === 'product') {
      dispatch(fetchProductScenesOperation(entry.productUid));
    }
  }, []);

  if (selectedCategory?.type === 'product') {
    return <SceneModelsSelection product={selectedCategory} back={unselectCategory} />;
  }

  if (selectedCategory?.type === 'category') {
    return <ProductCategoryList category={selectedCategory} back={unselectCategory} />;
  }

  return (
    <>
      <BackHeader title={category.name} onClick={back} />
      <MockupList
        entries={category.children}
        hasMore={false}
        EntryElt={ProductCategoryEntry}
        loadEntries={noop}
        onSelect={setSelectedCategory}
      />
    </>
  );
}

export default React.memo(ProductCategoryList);

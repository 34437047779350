import degrees2Radians from 'editor/src/util/degrees2Radians';

interface GetDigitizedTextPositionParams {
  originalLeft: number;
  originalTop: number;
  scaleX: number;
  scaleY: number;
  angle: number;
  margin: number;
  originalWidth?: number;
  originalHeight?: number;
}

function getDigitizedTextPosition({
  originalLeft,
  originalTop,
  scaleX,
  scaleY,
  angle = 0,
  margin = 0,
  originalWidth,
  originalHeight,
}: GetDigitizedTextPositionParams): { top: number; left: number; width?: number; height?: number } {
  const angleRad = degrees2Radians(angle);
  const marginX = (margin * Math.cos(angleRad) - margin * Math.sin(angleRad)) * scaleX;
  const marginY = (margin * Math.cos(angleRad) + margin * Math.sin(angleRad)) * scaleY;
  const position = {
    left: originalLeft - marginX,
    top: originalTop - marginY,
  };

  if (!originalWidth || !originalHeight) {
    return position;
  }

  return {
    ...position,
    width: originalWidth + Math.abs(marginX) * 2,
    height: originalHeight + Math.abs(marginY) * 2,
  };
}

export default getDigitizedTextPosition;

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch } from 'editor/src/store/hooks';

import useBrowserColor from 'editor/src/util/useBrowserColor';

import ColorTileControl from 'editor/src/component/ColorPicker/ColorTileControl';

export const DEFAULT_TEXT_OUTLINE_COLOR = '#FF0000';

interface Props {
  strokeColor: string;
}

function ButtonTextOutlineColor({ strokeColor }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const color = useBrowserColor(strokeColor || DEFAULT_TEXT_OUTLINE_COLOR);

  const handleClick = useCallback(() => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.TEXT_OUTLINE_COLOR_PICKER));
  }, []);

  return (
    <div className="row-flex row-flex--between mt-2 row-flex--middle">
      <span>{t('editor-display-option-color')}</span>
      <ColorTileControl onClick={handleClick} isActive color={color} />
    </div>
  );
}

export default React.memo(ButtonTextOutlineColor);

import React from 'react';

import { Dimensions } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

import MockupSizeComponent from './MockupSizeComponent';

import styles from './index.module.scss';

interface Props {
  activeSize: Dimensions;
  onSelect: (size: Dimensions) => void;
}

function MockupList({ activeSize, onSelect }: Props) {
  const { categories, sizes } = useSelector((state) => state.mockup.mockup);

  return (
    <div className={styles.MockupList}>
      {categories.map((category) => {
        const categorySizes = sizes.filter((size) => size.categoryId === category.id);

        return (
          <div key={category.id} className={styles.category}>
            <div className={styles.categoryName}>{category.name}</div>
            <div className={styles.sizes}>
              {categorySizes.map((size, i) => (
                <MockupSizeComponent
                  key={i}
                  size={size}
                  selected={activeSize.width === size.width && activeSize.height === size.height}
                  onSelect={onSelect}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(MockupList);

import { FontDefinition } from 'editor/src/store/fonts/types';

export type SearchableToken = { token: string; font: FontDefinition };

export function extractSearchableTokens(fonts: FontDefinition[]): SearchableToken[] {
  return fonts.reduce<SearchableToken[]>((acc, font) => {
    acc.push({ token: font.metadata.family.toLowerCase(), font });
    if (font.metadata.weight) {
      acc.push({ token: font.metadata.weight.toLowerCase(), font });
    }
    if (font.metadata.style) {
      acc.push({ token: font.metadata.style.toLowerCase(), font });
    }
    font.metadata.name
      .toLowerCase()
      .split(' ')
      .forEach((token) => acc.push({ token, font }));
    return acc;
  }, []);
}

export function getFilteredFonts(tokens: SearchableToken[], search: string): FontDefinition[] {
  const formattedSearch = search.toLowerCase();

  const fontSet = tokens.reduce((fonts, { token, font }) => {
    if (token.startsWith(formattedSearch)) {
      fonts.add(font);
    }
    return fonts;
  }, new Set<FontDefinition>());

  return [...fontSet];
}

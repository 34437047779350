import cn from 'classnames';
import React from 'react';

import IconLoading from '../Icon/IconLoading';

import styles from './index.module.scss';

function LoadingBackdrop() {
  return (
    <div className={cn(styles.backdrop, 'cy-loading-backdrop')}>
      <IconLoading className={cn(styles.loadingIcon)} />
    </div>
  );
}

export default LoadingBackdrop;

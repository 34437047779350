import cn from 'classnames';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import updateMediaElementNameOperation from 'editor/src/store/design/operation/updateMediaElementNameOperation';
import getStructureIndexByElementUuidMemoized from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { ElementAddressesIndexedByNames, MediaImage, StructureIndex } from 'editor/src/store/design/types';
import getElementAddressesIndexedByNames from 'editor/src/store/design/util/getElementAddressesIndexedByNames';
import getElementWithDuplicatedName from 'editor/src/store/design/util/getElementWithDuplicatedName';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';

import useOnClickOutside from 'editor/src/util/useOnClickOutside';

import AbstractLayerItem, {
  ItemProps,
} from 'editor/src/component/DesktopSidebar/TabContents/LayerItem/AbstractLayerItem';

import ImageLayerItem from './ImageLayerItem';

interface Props extends ItemProps<MediaImage> {}

function AbstractImageLayerItem({ element, spreadDisplay }: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const structureIndex = useSelector((state) => getStructureIndexByElementUuidMemoized(state, element.uuid));
  const [name, setName] = useState(element.name);
  const [error, setError] = useState<string>();
  const dispatch = useDispatch();
  const store = useStore();
  const { t } = useTranslation();

  useEffect(() => setName(element.name), [element.name]);

  const elementRef = useRef<ElementAddressesIndexedByNames>();
  useEffect(() => {
    if (isEditing) {
      const state = store.getState();
      if (state.design.designData) {
        elementRef.current = getElementAddressesIndexedByNames(state.design.designData);
      }
    }
  }, [isEditing]);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onNameUpdateDispatch = useCallback(
    debounce(
      (address: StructureIndex, name: string) => {
        dispatch(updateMediaElementNameOperation(address, name));
      },
      300,
      { leading: true, trailing: true },
    ),
    [],
  );

  const finishEditing = useCallback(
    (name: string) => {
      if (structureIndex && !error) {
        onNameUpdateDispatch(structureIndex, name);
      } else {
        setName(element.name);
      }

      setIsEditing(false);
      setError(undefined);
    },
    [structureIndex, error, onNameUpdateDispatch, element.name],
  );

  useOnClickOutside(divRef, () => finishEditing(name), !isEditing);

  const onNameChange = useCallback(
    (value: string) => {
      setName(value);

      const elementWithDuplicatedNameAddress = getElementWithDuplicatedName(
        elementRef.current,
        value.trim(),
        structureIndex,
      );

      if (elementWithDuplicatedNameAddress) {
        setError(t('This name is already taken'));
      } else {
        setError(undefined);
      }
    },
    [structureIndex],
  );

  return (
    <AbstractLayerItem
      className={cn('cy-image', { 'cy-empty': !element.imageId })}
      element={element}
      spreadDisplay={spreadDisplay}
      onEdit={onEdit}
      error={error}
      elementRef={divRef}
    >
      <ImageLayerItem
        element={element}
        name={name}
        isEditing={isEditing}
        onEdit={onEdit}
        onNameChange={onNameChange}
        finishEditing={finishEditing}
      />
    </AbstractLayerItem>
  );
}

export default React.memo(AbstractImageLayerItem);

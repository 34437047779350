import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import Accordion from 'editor/src/component/Accordion';
import { FilterControl } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';

import ButtonControl from './ButtonControl';
import ColorControl from './ColorControl';

interface Props {
  control: FilterControl;
  value: string | string[] | undefined;
  onControlChange: (key: string, value: string | string[] | undefined) => void;
}
function ControlContainer({ control, value, onControlChange }: Props) {
  const [isCollapsed, setCollapsed] = useState(false);
  const toggleCollapsed = useCallback(() => setCollapsed((prevState) => !prevState), []);

  return (
    <div className={cn('px-2 mt-2', `cy-filter-control-${control.uiType}-${control.key}`)}>
      <Accordion header={control.title} collapsed={isCollapsed} setCollapsed={toggleCollapsed}>
        {control.uiType === 'button' && (
          <ButtonControl
            className="pt-3 pb-1"
            control={control}
            appliedValues={value}
            onChange={(value) => onControlChange(control.key, value)}
          />
        )}
        {control.uiType === 'color' && (
          <div className="pt-3 pb-2">
            <ColorControl
              color={!Array.isArray(value) ? value : undefined}
              onColorChange={(value) => onControlChange(control.key, value)}
              allowNoColor
            />
          </div>
        )}
      </Accordion>
    </div>
  );
}

export default React.memo(ControlContainer);

import { Coords, MediaRectangle } from 'editor/src/store/design/types';

import FabricRect from 'editor/src/fabric/FabricRect';

const fabricPropsToRectangleElementData = (
  px2mm: (size: number) => number,
  pageCoords: Coords,
  rect: FabricRect,
): Partial<MediaRectangle> => {
  const { left = 0, top = 0, angle = 0 } = rect;

  const strokeWidth = rect?.strokeWidth || 0;
  return {
    x: px2mm(left - pageCoords.left),
    y: px2mm(top - pageCoords.top),
    width: px2mm(rect.getScaledWidth() - strokeWidth),
    height: px2mm(rect.getScaledHeight() - strokeWidth),
    r: angle,
  };
};

export default fabricPropsToRectangleElementData;

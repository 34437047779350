import React from 'react';

import geActiveSpreadSpotFinishingType from 'editor/src/store/design/selector/geActiveSpreadSpotFinishingType';
import getSpreadPageMediaElements from 'editor/src/store/design/selector/getSpreadPageMediaElements';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useSelector } from 'editor/src/store/hooks';

import LayerItem from 'editor/src/component/DesktopSidebar/TabContents/LayerItem';
import ImageLayerItem from 'editor/src/component/DesktopSidebar/TabContents/LayerItem/AbstractImageLayerItem/ImageLayerItem';
import TextLayerItem from 'editor/src/component/DesktopSidebar/TabContents/LayerItem/AbstractTextLayerItem/TextLayerItem';

import AbstractFoilLayerItem from './AbstractFoilLayerItem';

function FoilLayerItemsList() {
  const spotFinishingType = useSelector(geActiveSpreadSpotFinishingType);
  const mediaElements = useSelector((state) => getSpreadPageMediaElements(state, getCurrentSpreadIndex(state), 0));

  if (!spotFinishingType || !mediaElements) {
    return null;
  }

  return (
    <>
      {[...mediaElements]
        .sort((a, b) => a.uuid - b.uuid)
        .map((element) => {
          switch (element.type) {
            case 'rectangle':
            case 'addon':
              return (
                <AbstractFoilLayerItem key={element.uuid} element={element}>
                  <LayerItem element={element} />
                </AbstractFoilLayerItem>
              );
            case 'text':
              return (
                <AbstractFoilLayerItem key={element.uuid} element={element}>
                  <TextLayerItem element={element} isEditing={false} name={element.name} />
                </AbstractFoilLayerItem>
              );
            case 'image':
              return (
                <AbstractFoilLayerItem key={element.uuid} element={element}>
                  <ImageLayerItem element={element} isEditing={false} name={element.name} />
                </AbstractFoilLayerItem>
              );
            default:
              return null;
          }
        })}
    </>
  );
}

export default React.memo(FoilLayerItemsList);

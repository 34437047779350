import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { MediaText, Stroke } from 'editor/src/store/design/types';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import { useDispatch } from 'editor/src/store/hooks';

import limitPrecision from 'editor/src/util/limitPrecision';

import RangeControl from 'editor/src/component/DesktopSidebar/TabContents/PatternTabContent/BrickPatternControls/RangeControl';

import ButtonTextOutlineColorButtonText from './ButtonTextOutlineColor';
import { calculateSVGStrokeWidth, CANVAS_TO_SVG_STROKE, MAX_TEXT_OUTLINE_WIDTH, MIN_TEXT_OUTLINE_WIDTH } from './utils';

import styles from './index.module.scss';

interface Props {
  selectedElement: MediaText;
  stroke: Stroke;
}

function TextOutlineControls({ selectedElement, stroke }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getUpdatedTextOutlineWidth = useCallback(
    (strokeWidth: number) => {
      if (!selectedElement) {
        return undefined;
      }
      return {
        ...selectedElement,
        extra: {
          ...selectedElement.extra,
          stroke: {
            color: stroke.color,
            width: calculateSVGStrokeWidth(strokeWidth),
          },
        },
      };
    },
    [selectedElement, stroke.color],
  );

  const onChange = useCallback(
    (strokeWidth: number) => {
      dispatch(
        applyFontStylesToSelectionOperation({
          stroke: {
            color: stroke.color,
            width: calculateSVGStrokeWidth(strokeWidth),
          },
        }),
      );
    },
    [stroke.color],
  );

  return (
    <div className={styles.TextOutlineControlsContainer}>
      <ButtonTextOutlineColorButtonText strokeColor={stroke.color} />
      <RangeControl
        title={t('editor-outline-thickness')}
        value={limitPrecision(stroke.width * CANVAS_TO_SVG_STROKE, 1)}
        min={MIN_TEXT_OUTLINE_WIDTH}
        max={MAX_TEXT_OUTLINE_WIDTH}
        step={0.1}
        onChange={onChange}
        getUpdatedElement={getUpdatedTextOutlineWidth}
        className="cy-outline-width"
        sliderClassName="p-0"
      />
    </div>
  );
}

export default React.memo(TextOutlineControls);

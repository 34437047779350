import React, { useCallback, useEffect } from 'react';

import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { CustomTabInfo } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch } from 'editor/src/store/hooks';
import { PluginName } from 'editor/src/store/plugins/types';

import useMobileMenu from 'editor/src/component/useMobileMenu';

import ShapesTabContent from '../../../TabContents/ShapesTabContent';
import ShapesTab from '../ShapesTab';

interface Props {
  pluginName?: PluginName;
  customTabInfo?: CustomTabInfo;
  active?: boolean;
}

function ShapesMobileTab({ pluginName, customTabInfo, active }: Props) {
  const menu = useMobileMenu(false, true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (active) {
      menu.open();
    } else {
      menu.close();
    }
  }, [active]);

  const closeMenu = useCallback(() => {
    menu.close();
    dispatch(setSidebarActiveTabOperation(-1));
  }, []);

  return (
    <>
      <ShapesTab pluginName={pluginName} customTabInfo={customTabInfo} />
      {menu.render(<ShapesTabContent title={customTabInfo?.title} closeMenuCb={closeMenu} />)}
    </>
  );
}

export default React.memo(ShapesMobileTab);

import { GridDesign } from '@gelatoas/design-editor-calendar';

import { updateElementsOnSpreadAction } from 'editor/src/store/design/slice';
import { MediaGridInfo } from 'editor/src/store/design/types';
import getSpreadGridElements from 'editor/src/store/design/util/getSpreadGridElements';
import { FontDefinition } from 'editor/src/store/fonts/types';
import type { Thunk } from 'editor/src/store/hooks';

const updateGridElementOnSpreadOperation =
  (data: {
    spreadIndex: number;
    override: Partial<MediaGridInfo>;
    gridDesigns: GridDesign[];
    fonts: FontDefinition[];
  }): Thunk =>
  (dispatch, getState, { i18n }) => {
    const state = getState();
    const { designData } = state.design;
    const { gridDesigns, override, spreadIndex, fonts } = data;
    const spread = designData?.spreads[spreadIndex];
    if (!spread) {
      return;
    }

    const mediaGridMap = getSpreadGridElements(spread, override, fonts, gridDesigns, i18n, designData?.calendar);

    dispatch(updateElementsOnSpreadAction({ elementMap: mediaGridMap, spreadIndex }));
  };

export default updateGridElementOnSpreadOperation;

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import getPointPositionRotatedOnPoint from 'editor/src/util/getPointPositionRotatedOnPoint';

import getFocusCenter from './getFocusCenter';

const alignMediaToSpreadCenterOperation =
  (elementAddress: ElementAddress) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const element = getMediaElement(state, elementAddress);
    if (!element) {
      return;
    }

    const focusCenter = getFocusCenter(state, elementAddress.spreadIndex);

    if (element.type === 'line') {
      const elementCenterX = (element.x1 + element.x2) / 2;
      const offsetX = focusCenter.x - elementCenterX;
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: { x1: element.x1 + offsetX, x2: element.x2 + offsetX },
        }),
      );
    } else {
      const elementCenter = getPointPositionRotatedOnPoint(
        element.x + element.width / 2,
        element.y + element.height / 2,
        element.x,
        element.y,
        element.r,
      );
      const offsetX = focusCenter.x - elementCenter[0];
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: { x: element.x + offsetX },
        }),
      );
    }
  };

export default alignMediaToSpreadCenterOperation;

import React from 'react';

import { StructureIndex } from 'editor/src/store/design/types';

import IconAlignTop from 'editor/src/component/Icon/IconAlignTop';
import AlignActionButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignActionButton';

interface Props {
  structureIndexes: StructureIndex[];
}

function AlignTopButton({ structureIndexes }: Props) {
  return (
    <AlignActionButton structureIndexes={structureIndexes} icon={<IconAlignTop />} alignTo="top" keepTopDropdown />
  );
}

export default React.memo(AlignTopButton);

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CalendarEventsTabContent from 'editor/src/component/DesktopSidebar/TabContents/CalendarEventsTabContent';

import DrawerHeader from '../../DrawerHeader';

import styles from './index.module.scss';

interface Props {
  closeCalendarEventsMenuCb?: () => void;
}
function CalendarEventsMobileMenu({ closeCalendarEventsMenuCb }: Props) {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.calendarEventsMobileMenu, 'cy-tab-calendar-events')}>
      <DrawerHeader title={t('calendar-events')} closeMenu={closeCalendarEventsMenuCb} />
      <CalendarEventsTabContent />
    </div>
  );
}

export default React.memo(CalendarEventsMobileMenu);

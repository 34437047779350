import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EMPTY_RGBA_STRING } from 'editor/src/util/color/rgbaUtils';

import IconCheck from 'editor/src/component/Icon/IconCheck2';
import IconNoColor from 'editor/src/component/Icon/IconNoColor';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  color: string;
  isActive: boolean;
  noBorder?: boolean;
  suggested?: boolean;
  i18ColorName?: string;
}

function ColorTile({ color, isActive, noBorder, suggested, i18ColorName }: Props) {
  const { t } = useTranslation();
  const noColor = !color || color === EMPTY_RGBA_STRING;

  const tooltipOverlay = useMemo(() => {
    if (noColor) {
      return t('No color');
    }
    if (i18ColorName) {
      return `${t(i18ColorName)}, ${color.toUpperCase()}`;
    }
    return color.toUpperCase();
  }, [i18ColorName, color, noColor]);

  return (
    <WithTooltip
      overlay={tooltipOverlay}
      className={cn(styles.container, {
        [styles.active]: isActive,
        [styles.noBorder]: noBorder,
        [styles.suggested]: suggested,
        'cy-suggested': suggested,
      })}
    >
      {noColor ? (
        <>
          <IconNoColor className={styles.noColorIcon} />
          {isActive && <IconCheck className={styles.checkIcon} />}
        </>
      ) : (
        <div className={styles.circle} style={{ background: color }}>
          {isActive && <IconCheck className={styles.checkIcon} />}
        </div>
      )}
    </WithTooltip>
  );
}

export default React.memo(ColorTile);

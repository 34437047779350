import cn from 'classnames';
import React from 'react';

import IconDashedLine from 'editor/src/component/Icon/IconDashedLine';
import IconLine from 'editor/src/component/Icon/IconLine';
import IconNoOutline from 'editor/src/component/Icon/IconNoOutline';

import styles from './index.module.scss';

export enum STROKE_STYLE {
  NO_OUTLINE = 'no-outline',
  FLAT = 'flat',
  DASHED = 'dashed',
}

const STROKE_STYLE_TABS = [
  {
    key: STROKE_STYLE.FLAT,
    Icon: IconLine,
    isDashed: false,
  },
  {
    key: STROKE_STYLE.DASHED,
    Icon: IconDashedLine,
    isDashed: true,
  },
];

interface Props {
  hasDashedPattern: boolean;
  onTabChange: (tab: STROKE_STYLE) => void;
  showNoOutline?: true;
  noOutlineSelected?: boolean;
}

function StrokeStylesTab({ hasDashedPattern, onTabChange, showNoOutline, noOutlineSelected }: Props) {
  return (
    <div className={styles.tabContainer}>
      {showNoOutline && (
        <div
          className={cn({ [styles.selected]: noOutlineSelected }, styles.tab, `cy-tab-${STROKE_STYLE.NO_OUTLINE}-line`)}
          onClick={() => onTabChange(STROKE_STYLE.NO_OUTLINE)}
        >
          <IconNoOutline />
        </div>
      )}
      {STROKE_STYLE_TABS.map(({ key, Icon, isDashed }) => (
        <div
          className={cn(
            {
              [styles.selected]: isDashed === hasDashedPattern && !noOutlineSelected,
            },
            styles.tab,
            `cy-tab-${key}-line`,
          )}
          key={key}
          onClick={() => onTabChange(key)}
        >
          <Icon />
        </div>
      ))}
    </div>
  );
}

export default React.memo(StrokeStylesTab);

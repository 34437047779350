import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import requestModifiedImageOperation from 'editor/src/store/plugins/operation/imageManipulation/requestModifiedImageOperation';
import getActiveImageManipulationPlugin from 'editor/src/store/plugins/selector/getActiveImageManipulationPlugin';

import IconTick from 'editor/src/component/Icon/IconTick';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = () => true;

type Props = Pick<MenuItemProps, 'mode' | 'isMobile'>;

function ButtonAcceptModifiedImage({ mode }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const element = useSelector((state) => {
    const elementId = getSelectedImageUuid(state);
    return elementId !== undefined ? getMediaElementByUuid(state, elementId) : undefined;
  });

  const activePlugin = useSelector((state) => getActiveImageManipulationPlugin(state, element));

  const handleClick = useCallback(() => {
    element?.type === 'image' &&
      activePlugin &&
      dispatch(
        requestModifiedImageOperation({
          pluginName: activePlugin.name,
          assetId: element.imageId,
          elementId: element.uuid,
        }),
      );
  }, [element, activePlugin]);

  if (element?.type !== 'image') {
    return null;
  }

  return (
    <MenuButton
      Icon={IconTick}
      mode={mode}
      handleClick={handleClick}
      label={activePlugin?.extra.acceptModificationLabel || t('Done')}
      className="cy-button-accept-modified-image"
      disabled={element.loading}
    />
  );
}

export default React.memo(ButtonAcceptModifiedImage);

import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { RootState, ThunkArg } from 'editor/src/store';
import { OutboundPostMessage } from 'editor/src/util/postMessages/messages';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

type PromiseRes = (res: any) => void;

class RequestHandler {
  private requests = new Map<number, PromiseRes>();

  private counter = 0;

  private static INSTANCE = new RequestHandler();

  static getInstance() {
    return RequestHandler.INSTANCE;
  }

  createRequest<T>(
    type: keyof OutboundPostMessage,
    data: any,
  ): ThunkAction<Promise<T>, RootState, ThunkArg, AnyAction> {
    const requestId = this.counter;
    this.counter += 1;

    return () => {
      sendPostMessage('editor.request', { type, data, requestId });
      return new Promise<T>((res) => {
        this.requests.set(requestId, res);
      });
    };
  }

  onRequestAnswer(requestId: number, data: any) {
    const request = this.requests.get(requestId);
    if (request) {
      request(data);
      this.requests.delete(requestId);
    }
  }
}

export default RequestHandler;

import React from 'react';

import { StructureIndex } from 'editor/src/store/design/types';

import IconAlignLeft from 'editor/src/component/Icon/IconAlignLeft';
import AlignActionButton from 'editor/src/component/TopMenuDesktop/buttons/ButtonPosition/AlignActionButton';

interface Props {
  structureIndexes: StructureIndex[];
}

function AlignLeftButton({ structureIndexes }: Props) {
  return (
    <AlignActionButton structureIndexes={structureIndexes} icon={<IconAlignLeft />} alignTo="left" keepTopDropdown />
  );
}

export default React.memo(AlignLeftButton);

const getLineIntersection = (
  line1Start: { x: number; y: number },
  line1End: { x: number; y: number },
  line2Start: { x: number; y: number },
  line2End: { x: number; y: number },
) => {
  const denominator =
    (line2End.y - line2Start.y) * (line1End.x - line1Start.x) -
    (line2End.x - line2Start.x) * (line1End.y - line1Start.y);
  if (denominator === 0) {
    return null;
  }
  let a = line1Start.y - line2Start.y;
  let b = line1Start.x - line2Start.x;
  const numerator1 = (line2End.x - line2Start.x) * a - (line2End.y - line2Start.y) * b;
  const numerator2 = (line1End.x - line1Start.x) * a - (line1End.y - line1Start.y) * b;
  a = numerator1 / denominator;
  b = numerator2 / denominator;
  return {
    x: line1Start.x + a * (line1End.x - line1Start.x),
    y: line1Start.y + a * (line1End.y - line1Start.y),
    onSection1: a > 0 && a < 1,
    onSection2: b > 0 && b < 1,
  };
};

export default getLineIntersection;

import { PluginName, PluginState } from 'editor/src/store/plugins/types';

import { MenuContext } from 'editor/src/component/Menu/type';

export default function canUsePluginToEditImage(
  pluginName: PluginName | string,
  { plugins, selectedElementType, isElementEmpty }: MenuContext,
) {
  return (
    !isElementEmpty &&
    selectedElementType === 'image' &&
    plugins.some((plugin) => plugin.name === pluginName && plugin.state !== PluginState.NOT_VISIBLE)
  );
}

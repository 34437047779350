import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DraggableLocation } from 'react-beautiful-dnd';

import reOrderSpreadsOperation from 'editor/src/store/design/operation/spreadManipulation/reoderSpreadsOperation';
import getSpreadGroups from 'editor/src/store/design/selector/getSpreadGroups';
import { useDispatch, useSelector, useStore } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import { getDesignKeyFromDesign } from 'editor/src/store/variants/helpers/getDesignKey';

import retryPromiseFn from 'editor/src/util/retryPromiseFn';

import { onPageSelectorUpdateFn, RIGHT_CONTROL_BOTTOM_OFFSET } from 'editor/src/component/EditorArea/types';
import { extractDisplayPages } from 'editor/src/component/EditorArea/utils/displayPageUtils';

import { THUMBNAILS_HEIGHT_MOBILE, useBottomBarHeight } from './BottomBarHeightProvider';
import useBottomRightControlWidth from './BottomRightControls/useBottomRightControlWidth';
import { PageSelectorRef } from './PageSelector';

const PageSelector = React.lazy(
  retryPromiseFn(() => import('editor/src/component/EditorArea/EditorAreaControls/BottomControls/PageSelector')),
);
const BottomRightControls = React.lazy(
  retryPromiseFn(() => import('editor/src/component/EditorArea/EditorAreaControls/BottomControls/BottomRightControls')),
);

interface Props {
  currentSpreadIndex: number;
  isMobile: boolean;
  onPageSelectorUpdate?: onPageSelectorUpdateFn;
}

const RIGHT_CTRL_MARGIN = 40;

function BottomControls({ currentSpreadIndex, isMobile, onPageSelectorUpdate }: Props) {
  const store = useStore();
  const dispatch = useDispatch();
  const [rightControlsBottomOffset, setRightControlsBottomOffset] = useState(RIGHT_CONTROL_BOTTOM_OFFSET);

  const spreads = store.getState().design.designData?.spreads ?? [];
  const pageSelectorRef = useRef<PageSelectorRef>(null);
  const pageSelectorSize = useRef({ height: 0, bottomOffset: 0 });
  const { updateBottomBarHeight } = useBottomBarHeight();

  const designKey = useSelector((state) =>
    state.design.designData ? getDesignKeyFromDesign(state.design.designData) : '',
  );
  const spreadGroups = useSelector(getSpreadGroups);

  const splitDisplayPerContent = useSelector((state) => getHostSetting(state, 'splitDisplayPerContent'));
  const displayPages = useMemo(() => {
    const spreads = store.getState().design.designData?.spreads ?? [];
    return extractDisplayPages(spreads, splitDisplayPerContent, spreadGroups);
  }, [designKey, splitDisplayPerContent, spreadGroups, spreads.length]);

  const bottomBarWidth = useBottomRightControlWidth();

  const onPageWidthUpdate = useCallback(
    (availableSpace: number) => {
      let newRightControlsBottomOffset = RIGHT_CONTROL_BOTTOM_OFFSET;
      const pageSelectorFullHeight = pageSelectorSize.current.height + pageSelectorSize.current.bottomOffset;
      if (availableSpace < bottomBarWidth + RIGHT_CTRL_MARGIN && pageSelectorSize.current.height > 0) {
        const offsetFromSelectorToRightControls = 17;
        newRightControlsBottomOffset = pageSelectorFullHeight + offsetFromSelectorToRightControls;
      }

      updateBottomBarHeight(isMobile ? THUMBNAILS_HEIGHT_MOBILE : pageSelectorFullHeight);
      setRightControlsBottomOffset(newRightControlsBottomOffset);
    },
    [bottomBarWidth, updateBottomBarHeight],
  );

  const showPageSelector = displayPages.length > 1;

  const pageSelectorUpdated: onPageSelectorUpdateFn = (bottomOffset: number, height: number) => {
    if (pageSelectorSize.current.bottomOffset === bottomOffset && pageSelectorSize.current.height === height) {
      return;
    }

    pageSelectorSize.current = { height, bottomOffset };
    onPageSelectorUpdate?.(bottomOffset, height);
  };

  useEffect(() => {
    if (!showPageSelector) {
      onPageSelectorUpdate?.(0, 0);
    }
  }, [showPageSelector]);

  const handleOrderChange = useCallback((source: DraggableLocation, destination?: DraggableLocation | null) => {
    // dropped outside the list
    if (!destination) {
      return;
    }

    const spreads = store.getState().design.designData?.spreads ?? [];

    const destinationSpreadIndex = displayPages[destination.index - 1]?.spreadIndex;
    const isDestinationStart = destination.index === 0;
    const isDestinationEnd = destination.index === displayPages.length - 1;

    const canAddAfter = destinationSpreadIndex && spreads[destinationSpreadIndex].canAddAfter;

    if (isDestinationStart || isDestinationEnd || !canAddAfter) {
      return;
    }

    dispatch(reOrderSpreadsOperation(source.index, destination.index, displayPages));
  }, []);

  return (
    <Suspense fallback="">
      {showPageSelector && (
        <PageSelector
          ref={pageSelectorRef}
          currentSpreadIndex={currentSpreadIndex}
          isMobile={isMobile}
          displayPages={displayPages}
          onPageWidthUpdate={onPageWidthUpdate}
          onPageSelectorUpdate={pageSelectorUpdated}
          onOrderChange={handleOrderChange}
        />
      )}
      {!isMobile && <BottomRightControls bottomOffset={rightControlsBottomOffset} />}
    </Suspense>
  );
}

export default React.memo(BottomControls);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setPluginTabsAction } from 'editor/src/store/editorModules/sidebar/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import updateSidebarTabsOperation from './updateSidebarTabsOperation';

const updatePluginsTabsOperation = () => (dispatch: ThunkDispatch, getState: () => RootState) => {
  batch(() => {
    dispatch(setPluginTabsAction(getState().plugins.list));
    dispatch(updateSidebarTabsOperation());
  });
};

export default updatePluginsTabsOperation;

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconLine({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="112" height="4" viewBox="0 0 112 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2H110" stroke="#383838" strokeWidth="3" strokeLinecap="round" />
      </svg>
    </Icon>
  );
}

export default IconLine;

import { useMemo } from 'react';

import setActiveTopMenuDropdownOperation from 'editor/src/store/app/operation/setActiveTopMenuDropdownOperation';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { ImageState } from 'editor/src/store/gallery/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import requestModifiedImageOperation from 'editor/src/store/plugins/operation/imageManipulation/requestModifiedImageOperation';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { getImageManipulationPluginData } from 'editor/src/store/plugins/utils/imageManipulationUtils';

import isImageManipulationPlugin from 'editor/src/util/plugins/isImageManipulationPlugin';

import usePluginButton from 'editor/src/component/TopMenuDesktop/usePluginButton';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

function useImageManipulationButton(pluginName: string, close?: () => void) {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const plugin = useSelector((state) => {
    const plugin = getPlugin(state, pluginName);
    return plugin && isImageManipulationPlugin(plugin) ? plugin : undefined;
  });

  const { isActive, premiumEnabled, PremiumIcon } = usePluginButton({
    close,
    isMobile,
    plugin,
    tabName: TAB_NAMES.IMAGE_MANIPULATION,
  });

  const element = useSelector((state) => {
    const elementId = getSelectedImageUuid(state);
    return elementId !== undefined ? getMediaElementByUuid(state, elementId) : undefined;
  });
  const selectedImage = useSelector((state) =>
    element?.type === 'image' && element.imageId ? getImageById(state, element.imageId) : undefined,
  );
  const { imageModified, loading } = useMemo(() => {
    const isImage = element?.type === 'image';
    if (!isImage) {
      return {};
    }

    const imageManipulationPluginData = getImageManipulationPluginData(pluginName, element);

    return {
      imageModified: !!imageManipulationPluginData?.imageModified,
      loading: element.loading,
    };
  }, [element]);

  const handleClick = () =>
    batch(() => {
      dispatch(setActiveTopMenuDropdownOperation(undefined, false));
      const assetId = selectedImage?.id;
      const elementId = element?.uuid;

      if (!assetId || !elementId || imageModified) {
        return;
      }

      dispatch(
        requestModifiedImageOperation({
          pluginName,
          assetId,
          elementId,
          preview: true,
        }),
      );
    });

  const { dimensionRestrictions } = plugin?.extra || {};
  const meetRestrictions =
    !dimensionRestrictions ||
    (selectedImage &&
      selectedImage.width < dimensionRestrictions.maxWidth &&
      selectedImage.height < dimensionRestrictions.maxHeight);

  return {
    handleClick,
    disabled: selectedImage?.state !== ImageState.UPLOADED || loading || imageModified || !meetRestrictions,
    premiumEnabled,
    PremiumIcon,
    isActive,
    selectedImage,
    imageModified,
    element,
    tooltip: !meetRestrictions ? dimensionRestrictions.description : undefined,
  };
}

export default useImageManipulationButton;

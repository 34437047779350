import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  noMargin?: boolean;
  noRightMargin?: boolean;
}

function H2({ children, className, noMargin, noRightMargin }: Props) {
  return (
    <h1
      className={cn(styles.h2, className, {
        [styles.noMargin]: noMargin,
        [styles.noRightMargin]: noRightMargin,
      })}
    >
      {children}
    </h1>
  );
}

export default H2;

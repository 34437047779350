import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress, MediaImage } from 'editor/src/store/design/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

import calculateCurrentCropZoomScale from 'editor/src/util/design/crop/calculateCurrentCropZoomScale';
import { CROP_ZOOM_STEP, MIN_CROP_ZOOM_LEVEL } from 'editor/src/util/design/crop/constants';
import getCropZoomScale from 'editor/src/util/design/crop/getCropZoomScale';

const imageCropZoomOutOperation =
  (elementAddress: ElementAddress) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const image = getMediaElement<MediaImage>(state, elementAddress);
    if (!image) {
      return;
    }

    const currentZoomScale = calculateCurrentCropZoomScale(image);
    if (currentZoomScale <= MIN_CROP_ZOOM_LEVEL) {
      return;
    }
    const newZoomScale = Math.max(MIN_CROP_ZOOM_LEVEL, currentZoomScale - CROP_ZOOM_STEP);

    batch(() => {
      dispatch(saveUndoRedoStateOperation('Zoom out'));
      dispatch(
        updateMediaElementAction({
          elementAddress,
          elementUpdate: getCropZoomScale(image, newZoomScale),
        }),
      );
    });
  };

export default imageCropZoomOutOperation;

import { removeElementFromConditionAction } from 'editor/src/store/design/slice';
import { ConditionAddress, MediaElement } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import updateHiddenElementsOperation from './updateHiddenElementsOperation';

const removeElementFromConditionOperation =
  (address: ConditionAddress, optionId: string, element: MediaElement) => (dispatch: ThunkDispatch) => {
    dispatch(removeElementFromConditionAction({ address, optionId, element }));
    dispatch(updateHiddenElementsOperation());
  };

export default removeElementFromConditionOperation;

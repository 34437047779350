import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MediaText } from 'editor/src/store/design/types';

import { useFontSizeActions } from 'editor/src/util/useFontSizeActions';

import SliderWithInput from 'editor/src/component/SliderWithInput';

import DrawerHeader from '../../DrawerHeader';
import { MIN_FONT_SIZE, MAX_FONT_SIZE } from '../FontSizeActionBar';

import styles from './index.module.scss';

export interface Props {
  element: MediaText;
  closeMenuCb: () => void;
}

function FontSizeActionBarMobile({ element, closeMenuCb }: Props) {
  const { onFontSizeChange, updateElementFontSize } = useFontSizeActions(element);
  const { t } = useTranslation();

  return (
    <div className={cn(styles.MobileMenu, 'cy-tab-font-size')}>
      <DrawerHeader title={t('Font size')} closeMenu={closeMenuCb} />
      <SliderWithInput
        inputClassName="cy-font-size-input"
        Label={t('editor-font-size')}
        onChange={onFontSizeChange}
        onAfterChange={updateElementFontSize}
        min={MIN_FONT_SIZE}
        max={MAX_FONT_SIZE}
        step={1}
        startPoint={0}
        value={element.extra.fontSize}
        position="twoRows"
      />
    </div>
  );
}

export default React.memo(FontSizeActionBarMobile);

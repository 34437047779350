/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconFormats({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 5L8.3941 5M5 5L5 8.3941M5 5L9 9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M17 17L13.6059 17M17 17L17 13.6059M17 17L13 13"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 5L17 8.3941M17 5L13.6059 5M17 5L13 9"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 17L5 13.6059M5 17L8.3941 17M5 17L9 13"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="1" y="1" width="20" height="20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export default IconFormats;

/* eslint-disable max-len */
import React from 'react';

interface Props {
  className?: string;
}

function IconExclamation({ className }: Props) {
  return (
    <svg className={className} width="12" height="13" viewBox="0 0 12 13" fill="red" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99999 7.08338C6.18471 7.08338 6.3426 7.02019 6.47365 6.8938C6.6051 6.76741 6.67082 6.61185 6.67082 6.42713V4.15213C6.67082 3.96741 6.6051 3.81438 6.47365 3.69305C6.3426 3.57133 6.18471 3.51047 5.99999 3.51047C5.81527 3.51047 5.65738 3.57366 5.52632 3.70005C5.39488 3.82644 5.32915 3.98199 5.32915 4.16672V6.44171C5.32915 6.62644 5.39488 6.77966 5.52632 6.90138C5.65738 7.02272 5.81527 7.08338 5.99999 7.08338ZM5.99999 9.53338C6.19443 9.53338 6.35738 9.46766 6.48882 9.33622C6.61988 9.20516 6.6854 9.04241 6.6854 8.84797C6.6854 8.65352 6.61988 8.49077 6.48882 8.35971C6.35738 8.22827 6.19443 8.16255 5.99999 8.16255C5.80554 8.16255 5.64279 8.22827 5.51174 8.35971C5.38029 8.49077 5.31457 8.65352 5.31457 8.84797C5.31457 9.04241 5.38029 9.20516 5.51174 9.33622C5.64279 9.46766 5.80554 9.53338 5.99999 9.53338ZM5.99999 12.45C5.1736 12.45 4.39815 12.2945 3.67365 11.9834C2.94954 11.6723 2.32013 11.2494 1.7854 10.7146C1.25068 10.1799 0.827766 9.55049 0.516654 8.82638C0.205543 8.10188 0.0499878 7.32644 0.0499878 6.50005C0.0499878 5.67366 0.205543 4.89822 0.516654 4.17372C0.827766 3.4496 1.25068 2.82019 1.7854 2.28547C2.32013 1.75074 2.94954 1.32783 3.67365 1.01672C4.39815 0.705604 5.1736 0.550049 5.99999 0.550049C6.82638 0.550049 7.60182 0.705604 8.32632 1.01672C9.05043 1.32783 9.67985 1.75074 10.2146 2.28547C10.7493 2.82019 11.1722 3.4496 11.4833 4.17372C11.7944 4.89822 11.95 5.67366 11.95 6.50005C11.95 7.32644 11.7944 8.10188 11.4833 8.82638C11.1722 9.55049 10.7493 10.1799 10.2146 10.7146C9.67985 11.2494 9.05043 11.6723 8.32632 11.9834C7.60182 12.2945 6.82638 12.45 5.99999 12.45Z"
        stroke="none"
      />
    </svg>
  );
}

export default React.memo(IconExclamation);

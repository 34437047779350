import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setPreviewStatusAction, setFlatPreviewReadyAction } from 'editor/src/store/editorModules/preview/slice';
import { FlatPreview, PreviewStatus } from 'editor/src/store/editorModules/preview/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const setFlatPreviewReadyOperation = (data: { index: number; preview: FlatPreview }) => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(setFlatPreviewReadyAction(data));
    dispatch(setPreviewStatusAction(PreviewStatus.LOADED));
  });
};

export default setFlatPreviewReadyOperation;

import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { shallowEqual } from 'react-redux';

import getSpread from 'editor/src/store/design/selector/getSpread';
import getSpreadGroups from 'editor/src/store/design/selector/getSpreadGroups';
import { useSelector } from 'editor/src/store/hooks';

import getSpreadName from 'editor/src/util/design/getSpreadName';
import useSpreadPreview from 'editor/src/util/design/useSpreadPreview';
import getSpreadGroup from 'editor/src/util/getSpreadGroup';
import useLongPress from 'editor/src/util/useLongPress';

import { RequestRenderFn } from 'editor/src/component/SpreadPreview';

import Button from '../../Button';
import { DisplayPage } from '../../EditorArea/utils/displayPageUtils';
import IconDragAndDrop from '../../Icon/IconDragAndDrop';
import IconMoreHorizontal from '../../Icon/IconMoreHorizontal';
import { SpreadPreviewOptions } from '../../SpreadPreview/createSpreadPreview';

import styles from './index.module.scss';

interface Props {
  displayPage: DisplayPage;
  requestRender: RequestRenderFn;
  designKey: string;
  active: boolean;
  canAddAfter: boolean;
  canAddOrRemoveSpreads: boolean;
  onMoreClick: (spreadIndex: number) => void;
  onSpreadClick: (spreadIndex: number) => void;
  onSpreadLongPress: (spreadIndex: number) => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  isDragDisabled?: boolean;
}

function Spread({
  displayPage,
  requestRender,
  designKey,
  active,
  canAddAfter,
  canAddOrRemoveSpreads,
  onMoreClick,
  onSpreadClick,
  onSpreadLongPress,
  dragHandleProps,
  isDragDisabled = false,
}: Props) {
  const spread = useSelector((state) => getSpread(state, displayPage.spreadIndex), shallowEqual);
  const { spreadIndex, focusedContent } = displayPage;
  const spreadGroups = useSelector(getSpreadGroups);
  const longPressTriggeredRef = React.useRef(false);
  const divRef = React.useRef<HTMLDivElement>(null);

  const longPressEventHandlers = useLongPress(
    () => {
      longPressTriggeredRef.current = true;
      onSpreadLongPress(displayPage.spreadIndex);
    },
    500,
    () => {
      longPressTriggeredRef.current = false;
    },
  );

  function handleClick() {
    if (!longPressTriggeredRef.current) {
      onSpreadClick(displayPage.spreadIndex);
    }
  }

  function handleAddSpread() {
    onMoreClick(displayPage.spreadIndex);
  }

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (!active) {
      return undefined;
    }

    const handle = window.setTimeout(() => {
      divRef.current?.scrollIntoView({ block: 'center' });
    }, 100);

    return () => {
      window.clearTimeout(handle);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const previewOptions: SpreadPreviewOptions = {
    showBlanks: true,
    showEmptyImages: true,
  };

  const currSpreadGroup = getSpreadGroup(spreadIndex, spreadGroups);

  const { preview } = useSpreadPreview(
    requestRender,
    spread,
    designKey,
    spreadIndex,
    { dimension: 'width', value: windowDimensions.width },
    previewOptions,
    focusedContent,
    currSpreadGroup,
  );

  const spreadNames = getSpreadName(displayPage);

  return (
    <>
      <div onClick={handleClick} className={styles.spreadPreview}>
        <div // eslint-disable-next-line react/jsx-props-no-spreading
          {...dragHandleProps}
          className={styles.dragAndDropWrapper}
        >
          <Button className={styles.dragAndDrop} rounded disabled={isDragDisabled}>
            <IconDragAndDrop />
          </Button>
        </div>

        <div
          className={cn(styles.image, {
            [styles.active]: active,
            [styles.notLoaded]: !preview,
          })}
          ref={divRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(canAddOrRemoveSpreads ? longPressEventHandlers : {})}
        >
          {preview ? <img src={preview.dataURL} alt="" /> : <div style={{ height: 200 }} />}
        </div>
        <div className={styles.name}>
          {spreadNames.map((name, index) => (
            <div key={index}>{name}</div>
          ))}
        </div>
      </div>
      {canAddAfter && canAddOrRemoveSpreads && (
        <div className={styles.dividerWrapper}>
          <div className={styles.divider} />
          <Button secondary rounded onClick={handleAddSpread} className={styles.addButton}>
            <IconMoreHorizontal />
          </Button>
          <div className={styles.divider} />
        </div>
      )}
    </>
  );
}

export default React.memo(Spread);

import updateMediaElementByUuidOperation from 'editor/src/store/design/operation/updateMediaElementByUuidOperation';
import { Dimensions, MediaText } from 'editor/src/store/design/types';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';
import type { Thunk } from 'editor/src/store/hooks';

import getResizableImageLabelText from 'editor/src/util/resizableElement/getResizableImageLabelText';

const updateResizableLayoutAreaLabelsOperation =
  (labelElement: MediaText, layout: LayoutSchema, dimensions: Dimensions): Thunk =>
  (dispatch, getState, { i18n }) => {
    const state = getState();
    const unit = getSettingsValue(state, SettingsProperty.units);
    const text = getResizableImageLabelText(labelElement, layout, dimensions, unit, i18n.t);

    if (!text) {
      return;
    }
    dispatch(
      updateMediaElementByUuidOperation(labelElement.uuid, {
        extra: { ...labelElement.extra, text },
      }),
    );
  };

export default updateResizableLayoutAreaLabelsOperation;

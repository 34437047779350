import React from 'react';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import applyToMultipleMediaOperation from 'editor/src/store/design/operation/applyToMultipleMediaOperation';
import copyMediaElementOperation from 'editor/src/store/design/operation/copyMediaElementOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import { useDispatch } from 'editor/src/store/hooks';

import IconCopy from 'editor/src/component/Icon/IconCopy';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import MenuButton from './MenuButton';

export const canShow: CanShow = (state, { hasSelection, isElementEmpty, structureIndexes }) => {
  if (!hasSelection || isElementEmpty || !isAddElementsAllowed(state)) {
    return false;
  }
  const element = getMediaElement(state, structureIndexes[0]);
  return !!element && !element.unduplicable;
};

type Props = Pick<MenuItemProps, 'context' | 'mode' | 'close'>;

function ButtonDuplicate({ context, mode, close }: Props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    batch(() => {
      dispatch(saveUndoRedoStateOperation('Duplicate element'));
      dispatch(applyToMultipleMediaOperation(context.structureIndexes, copyMediaElementOperation));
    });
    close?.();
  };

  return (
    <MenuButton
      Icon={IconCopy}
      mode={mode}
      handleClick={handleClick}
      i18nKey="editor-duplicate"
      className="cy-button-duplicate"
    />
  );
}

export default React.memo(ButtonDuplicate);

import React from 'react';

import noop from 'editor/src/util/noop';

import { ReorganizableElementContext } from './Element';

function useDragHandle() {
  const context = React.useContext(ReorganizableElementContext);
  return { onMouseDown: context?.onMouseDown || noop };
}

export default useDragHandle;

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import getSpread from 'editor/src/store/design/selector/getSpread';
import getSpreadContentElementsWithPossibleMirroring from 'editor/src/store/design/selector/getSpreadContentElementsWithPossibleMirroring';
import { updateMediaElementAction } from 'editor/src/store/design/slice';
import { ElementAddress, MediaImage } from 'editor/src/store/design/types';
import type { Thunk } from 'editor/src/store/hooks';

import applyFitImageToSpread from 'editor/src/util/design/applyFitImageToSpread';
import getBoxesMinMax from 'editor/src/util/getBoxesMinMax';

const fitMediaToSpreadOperation =
  (elementAddress: ElementAddress): Thunk =>
  (dispatch, getState) => {
    const state = getState();

    const spread = getSpread(state, elementAddress.spreadIndex);
    const contentElements = spread ? getSpreadContentElementsWithPossibleMirroring(spread) : undefined;
    const element = getMediaElement<MediaImage>(state, elementAddress);

    if (!contentElements?.length || !element) {
      return;
    }

    const bbox = getBoxesMinMax(contentElements);
    const elementUpdate = applyFitImageToSpread(element, bbox);
    dispatch(updateMediaElementAction({ elementAddress, elementUpdate }));
  };

export default fitMediaToSpreadOperation;

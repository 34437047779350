import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import applyShadowToSelectionOperation from 'editor/src/store/design/operation/applyShadowToSelectionOperation';
import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import getCurrentShadow from 'editor/src/store/fonts/selector/getCurrentShadow';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import RGBA, { EMPTY_RGBA } from 'editor/src/util/color/rgbaUtils';
import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import ColorPicker from 'editor/src/component/ColorPicker';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconArrowLeft from 'editor/src/component/Icon/IconArrowLeft';
import MenuButton from 'editor/src/component/TopMenuDesktop/buttons/MenuButton';

function ShadowColorPickerTabContent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shadow = useSelector((state) => getCurrentShadow(state));

  const element = useSelector((state) => getSelectedMediaElementsObjects(state)[0]);
  const colorPickerConfig = useSelector((state) => getHostSetting(state, 'colorPickerConfig'));

  const { liveUpdate } = useMediaElementLiveUpdates(element);

  const changeColor = (color: string | undefined) => {
    if (color && shadow) {
      const rgba = element?.type === 'image' ? (RGBA.fromHex(color) ?? EMPTY_RGBA).toString() : color;
      dispatch(applyShadowToSelectionOperation({ ...shadow, color: rgba }));
    }
  };

  const changingColor = (color: string | undefined) => {
    if (color && shadow && element?.type === 'image') {
      const rgba = (RGBA.fromHex(color) ?? EMPTY_RGBA).toString();
      liveUpdate({ ...element, shadow: { ...shadow, color: rgba } });
    }
  };

  const openShadowControlsTab = useCallback(() => {
    dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.EFFECTS));
  }, []);

  if (!shadow) {
    return null;
  }

  const ButtonBack = (
    <MenuButton Icon={IconArrowLeft} mode="icon" handleClick={openShadowControlsTab} className="cy-button-back" />
  );

  return (
    <>
      <TabContentHeader title={t('Shadow color')} icon={ButtonBack} />
      <ContentBlock scroll>
        <ColorPicker
          color={shadow.color}
          onColorChange={changeColor}
          onColorChanging={changingColor}
          colors={colorPickerConfig.allowedColors}
          // TODO remove when feature will be enabled on dashboard
          allowOpacity={element?.type === 'image' || colorPickerConfig.enableOpacity}
        />
      </ContentBlock>
    </>
  );
}

export default React.memo(ShadowColorPickerTabContent);

import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import requestPreviewsOperation from 'editor/src/store/editorModules/preview/operation/requestPreviewsOperation';
import { PreviewType } from 'editor/src/store/editorModules/preview/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { PluginName } from 'editor/src/store/plugins/types';
import updatePersonalizationOperation, {
  PersonalizationType,
} from 'editor/src/store/variants/operation/updateProductPersonalizationOperation';

import Accordion from 'editor/src/component/Accordion';
import Button from 'editor/src/component/Button';
import RadioButtonElement, { ElementOption } from 'editor/src/component/RadioButtonElement';

import { isShopifyPersonalizationOptionsModalClose } from '../ShopifyPersonalizationOptions/utils';

import { setIsEtsyPersonalizationOptionsModalClose } from './utils';

import styles from './index.module.scss';

function AIToggle() {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(isShopifyPersonalizationOptionsModalClose());
  const { t } = useTranslation();
  const handleClick = useCallback(() => {
    dispatch(requestPreviewsOperation(PreviewType.PREVIEW_FLAT));
  }, []);
  const aiPersonalizationEnabled = useSelector((state) =>
    state.design.globalPlugins.includes(PluginName.AIPersonalization),
  );

  const [selectedOption, setSelectedOption] = useState(
    aiPersonalizationEnabled ? 'ai-personalization' : 'email-workflow-personalization',
  );

  const aiPersonalizationContent = (
    <div>
      <div className={cn(styles.content)}>{t('Ai personalization toggle-on content')}</div>
      <Button
        className={cn(styles.testButton, 'cy-try-it-out')}
        disabled={selectedOption === 'email-workflow-personalization'}
        secondary
        onClick={handleClick}
      >
        {t('Try it out')}
      </Button>
    </div>
  );

  const articleUrl =
    'https://support.gelato.com/en/articles/8996353-non-automated-email-workflow-for-product-personalization';

  const learnMoreLink = (
    <div>
      <div className={cn(styles.content)}>{t('Email workflow personalization toggle-on content')}</div>
      <a className={cn(styles.learnMoreLink)} href={articleUrl} target="_blank" rel="noreferrer">
        {t('Learn more')}
      </a>
    </div>
  );

  const options: ElementOption<PersonalizationType>[] = [
    {
      value: 'ai-personalization',
      label: t('Automated Etsy personalization'),
      disabled: false,
      content: <div className={cn('cy-ai-personalization-option')}>{aiPersonalizationContent}</div>,
      badge: (
        <div className={cn(styles.badgeContainer, 'cy-new-badge')}>
          <div className={cn(styles.badge)}>{t('New')}</div>
        </div>
      ),
    },
    {
      value: 'email-workflow-personalization',
      label: t('Email workflow personalization'),
      disabled: false,
      content: learnMoreLink,
    },
  ];

  const setModalCollapsed = useCallback((isCollapsed: boolean) => {
    setCollapsed(isCollapsed);
    setIsEtsyPersonalizationOptionsModalClose(isCollapsed);
  }, []);

  const onChange = useCallback((option: PersonalizationType) => {
    dispatch(updatePersonalizationOperation(option));
    setSelectedOption(option);
  }, []);

  return (
    <div
      className={cn(styles.AIToggle, 'mb-2', 'pl-2', 'pr-2', {
        'pb-2': !collapsed,
      })}
    >
      <Accordion
        header={t('Type')}
        headerClassName={styles.header}
        collapsed={collapsed}
        setCollapsed={setModalCollapsed}
        subheader={t('Automated Etsy personalization')}
        subheaderClassName={styles.subheader}
      >
        <RadioButtonElement options={options} selected={selectedOption} onChange={onChange} />
      </Accordion>
    </div>
  );
}

export default React.memo(AIToggle);

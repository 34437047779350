import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import setActiveRightbarOperation from 'editor/src/store/editorModules/rightbar/operation/setActiveRightbarOperation';
import { RightTab } from 'editor/src/store/editorModules/rightbar/types';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { Thunk } from 'editor/src/store/hooks';
import getAllMatchingVariants from 'editor/src/store/variants/helpers/getAllMatchingVariants';
import getAllMatchingVariations from 'editor/src/store/variants/helpers/getAllMatchingVariations';
import { toggleMultiOptionAction } from 'editor/src/store/variants/slice';
import { MultiOptions } from 'editor/src/store/variants/types';

import { getAvailableOptions } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/utils';

import checkForMissingTemplatesOperation from './checkForMissingTemplatesOperation';
import displaySelectedVariationOperation from './displaySelectedVariationOperation';
import sendSwitchProductOperation from './sendSwitchProductOperation';

const updateMultiOptionsOperation =
  (multiOptions: MultiOptions, noUndo = false): Thunk =>
  (dispatch, getState, { i18n }) => {
    const state = getState();
    const { product, configuration, selectedExternalOptions, selectedSingleOptions, replaceControlKeys } =
      state.variants;

    let matchingVariations = getAllMatchingVariations(
      product.productVariations,
      multiOptions,
      selectedSingleOptions,
      !!configuration.singleSelection,
      replaceControlKeys,
    );

    // filter variants with unavailable options
    if (matchingVariations.length > 0 && product.controlsUIOrder?.length) {
      const availableOptions = getAvailableOptions(
        multiOptions,
        selectedSingleOptions,
        product.productVariations,
        product.controlsUIOrder,
        product.unavailableProducts,
      );
      matchingVariations = matchingVariations.filter((matchingVariation) => {
        return Object.entries(availableOptions).every(([property, availableValues]: [string, Set<string>]) => {
          // check if all variation properties are available
          return availableValues.has(matchingVariation[property]);
        });
      });
    }

    const multiOptionKeys = Object.keys(multiOptions);
    if (matchingVariations.length > 0) {
      // remove options that do not existing in the selected variations
      multiOptionKeys.forEach((key) => {
        multiOptions[key] = multiOptions[key].filter((option) =>
          matchingVariations.some((variation) => variation[key] === option.value),
        );
      });
    } else if (
      multiOptionKeys.filter((key) => multiOptions[key].length).length ===
      product.productControls.filter((c) => c.type === 'multi').length
    ) {
      // no matching variation, we need to select the first option that matches
      const singleKeys = Object.keys(selectedSingleOptions);
      // find a variation that matches the selected single options
      const variation = product.productVariations.find((variation) =>
        singleKeys.every((key) => variation[key] === selectedSingleOptions[key]),
      );
      if (variation) {
        multiOptionKeys.forEach((controlKey) => {
          const control = product.productControls.find((control) => control.key === controlKey);
          if (control?.type === 'multi' && multiOptions[controlKey].length) {
            multiOptions[controlKey] = [
              {
                value: variation[controlKey],
                dependedOptions: control.dependsOnSingleControls
                  ? singleKeys.map((key) => ({ key, value: variation[key] }))
                  : [],
              },
            ];
          }
        });
      }
      matchingVariations = getAllMatchingVariations(
        product.productVariations,
        multiOptions,
        selectedSingleOptions,
        !!configuration.singleSelection,
        replaceControlKeys,
      );
    }

    const matchingVariantInfos = getAllMatchingVariants(
      matchingVariations,
      selectedExternalOptions,
      product.externalProductControls,
      state.variants.selectedPageCount,
    );

    batch(() => {
      if (!noUndo) {
        dispatch(saveUndoRedoStateOperation('Toggle multi options'));
      }
      dispatch(
        toggleMultiOptionAction({
          multiOptions,
          matchingVariantInfos,
          rootState: state,
          i18n,
        }),
      );

      if (configuration.singleSelection) {
        const variant = matchingVariantInfos[0] || {
          variation: matchingVariations[0],
        };
        if (variant?.variation) {
          dispatch(sendSwitchProductOperation(variant));
        }
      } else {
        dispatch(displaySelectedVariationOperation());
        dispatch(checkForMissingTemplatesOperation(matchingVariations));
      }

      dispatch(setActiveRightbarOperation(RightTab.Variants));
    });
  };

export default updateMultiOptionsOperation;

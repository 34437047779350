import React from 'react';
import { useTranslation } from 'react-i18next';

import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import ButtonTab from 'editor/src/component/DesktopSidebar/Tabs/ButtonTab';
import IconCollections from 'editor/src/component/Icon/IconCollections';

interface Props {
  pluginName?: string;
}

function CollectionsTab({ pluginName }: Props) {
  const { t } = useTranslation();

  return (
    <ButtonTab
      key={TAB_NAMES.COLLECTIONS}
      name={TAB_NAMES.COLLECTIONS}
      title={t('editor-collections')}
      icon={<IconCollections />}
      onSelect={() => sendPostMessage('advancedPersonalisation.openLibrary', undefined)}
      pluginName={pluginName}
    />
  );
}

export default React.memo(CollectionsTab);

import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import selectElementOperation from 'editor/src/store/editor/operation/selectElementOperation';
import { ElementWarning, WarningType } from 'editor/src/store/editorModules/warnings/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import LowDpiWarningItem from './LowDpiWarningItem';
import NotVisibleWarningItem from './NotVisibleWarningItem';
import OutOfRangeWarningItem from './OutOfRangeWarningItem';
import SampleElementNotReplacedWarningItem from './SampleElementNotReplacedWarningItem';

import styles from './index.module.scss';

interface Props {
  warning: ElementWarning;
}

function ElementWarningItemContainer({ warning }: Props) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<boolean>(false);

  const { selected, element } = useSelector((state) => {
    const element = getMediaElementByUuid(state, warning.uuid);
    return {
      element,
      selected: state.editor.selectedElementUuids.includes(warning.uuid),
    };
  }, shallowEqual);

  useEffect(() => {
    if (selected) {
      setExpanded(true);
    }
  }, []);

  const handleClick = (uuid: number) => {
    setExpanded(!expanded);
    dispatch(selectElementOperation(uuid));
  };

  if (!element || !warning) {
    return null;
  }

  return (
    <div
      className={cn(styles.WarningItemContainer, 'cy-warning-item', {
        [styles.selected]: expanded,
      })}
      onClick={() => handleClick(element.uuid)}
    >
      {warning.type === WarningType.LowDPI && (
        <LowDpiWarningItem warning={warning} element={element} selected={expanded} />
      )}
      {warning.type === WarningType.NotVisible && (
        <NotVisibleWarningItem warning={warning} element={element} selected={expanded} />
      )}
      {warning.type === WarningType.OutOfRange && (
        <OutOfRangeWarningItem warning={warning} element={element} selected={expanded} />
      )}
      {warning.type === WarningType.SampleElementNotReplaced && (
        <SampleElementNotReplacedWarningItem warning={warning} element={element} selected={expanded} />
      )}
    </div>
  );
}

export default React.memo(ElementWarningItemContainer);

import { EnvEnum, Environment } from 'editor/src/environment/types';

const env: Environment = {
  production: false,
  env: EnvEnum.test,
  translationsUrl: 'https://design-editor.test.gelato.tech/translations/',
  amplitudeKey: '',
  shutterstockKeys: {
    essentials: 'G1PTOJzNrrvaESrDlGCeGrGpu7XdSICQ',
    full: 'nNJwspGEM8GtBdqnU2FyGk1Qcr8fQXf0',
  },
};

export default env;

import getSpread from 'editor/src/store/design/selector/getSpread';
import { RootState } from 'editor/src/store/index';

import getCurrentSpreadIndex from './getCurrentSpreadIndex';

const isWallpaperEmptyScreenVisible = (state: RootState) => {
  const { activateWallpaperEmptyScreen } = state.hostSettings;

  const spread = getSpread(state, getCurrentSpreadIndex(state));
  const hasOneNonEmptyImage = spread?.pages[0].groups.media?.some(
    (element) => element.type === 'image' && element.imageId,
  );
  return activateWallpaperEmptyScreen && !hasOneNonEmptyImage;
};

export default isWallpaperEmptyScreenVisible;

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';

import updateAllGridElementsOperation from 'editor/src/store/design/operation/calendarGrid/updateAllGridElementsOperation';
import { MediaGrid } from 'editor/src/store/design/types';
import { useDispatch } from 'editor/src/store/hooks';

import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';

import controlStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControl.module.scss';

interface Props {
  noTopBar?: boolean;
  gridElement: MediaGrid;
}

type Option = { label: string; value: string; year: number; month: number };
const CURRENT_YEAR = new Date().getFullYear();

function StartDateControl({ noTopBar, gridElement }: Props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { gridDate } = gridElement.grid;

  const onSelect = useCallback((option: SingleValue<Option>) => {
    if (option) {
      void dispatch(
        updateAllGridElementsOperation({
          gridDate: { year: option.year, month: option.month },
        }),
      );
    }
  }, []);

  const options = useMemo(() => {
    const options: Option[] = [];
    for (let i = 0; i < 36; i += 1) {
      const year = CURRENT_YEAR + Math.floor(i / 12);
      const month = i % 12;
      const dateOption = new Date(year, i % 12, 1);
      const monthName = dateOption.toLocaleString(i18n.languageWithDash, {
        month: 'long',
      });
      options.push({
        label: `${monthName} ${year}`,
        value: `${month}-${year}`,
        year,
        month,
      });
    }

    return options;
  }, [i18n.language]);

  const selectedOption = useMemo(
    () => options.find(({ year, month }) => year === gridDate.year && month === gridDate.month),
    [options, gridDate],
  );

  return (
    <>
      {!noTopBar && <PropertySeparator bigMargin />}
      <div className={controlStyles.controlTitle}>{t('editor-start-month-on')}</div>
      <Select placeholder="" options={options} onChange={onSelect} value={selectedOption} className="cy-start-date" />
    </>
  );
}

export default React.memo(StartDateControl);

import { fabric } from 'fabric';

import FabricImage from 'editor/src/fabric/FabricImage';

const getImageUpdateOnDrag = (
  _: fabric.Transform,
  mask: fabric.Object,
  maskOrigin: fabric.Object,
  imageOrigin: fabric.Object,
): Partial<FabricImage> => ({
  left: (imageOrigin.left || 0) + ((mask.left || 0) - (maskOrigin.left || 0)),
  top: (imageOrigin.top || 0) + ((mask.top || 0) - (maskOrigin.top || 0)),
});

export default getImageUpdateOnDrag;

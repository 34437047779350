import React from 'react';
import { useTranslation } from 'react-i18next';

import setSettingsValueOperation from 'editor/src/store/editor/operation/setSettingsValueOperation';
import getSettingsValue from 'editor/src/store/editor/selector/getSettingsValue';
import { SettingsProperty } from 'editor/src/store/editor/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Checkbox from 'editor/src/component/Checkbox';
import SettingsItem from 'editor/src/component/DesktopSidebar/TabContents/SettingsTabContent/SettingsItem';

function ShowGridCheckbox() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isChecked = useSelector((state) => getSettingsValue(state, SettingsProperty.showGrid));

  const handleChange = () => {
    dispatch(setSettingsValueOperation(SettingsProperty.showGrid, !isChecked));
  };

  return (
    <SettingsItem descriptionTranslationKey="editor-settings-grid-description">
      <Checkbox
        on={isChecked}
        onChange={handleChange}
        label={t('editor-settings-grid')}
        className="cy-show-grid-checkbox"
      />
      <div>
        <svg
          width="271"
          height="12"
          viewBox="0 0 271 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <rect y="6" width="271" height="6" fill="url(#svgPatternGrid1)" />
          <rect width="271" height="6" transform="matrix(1 0 0 -1 0 6)" fill="url(#svgPatternGrid2)" />
          <defs>
            <pattern id="svgPatternGrid1" patternContentUnits="objectBoundingBox" width="0.369004" height="16.6667">
              <use xlinkHref="#image0" transform="scale(0.00369004 0.166667)" />
            </pattern>
            <pattern id="svgPatternGrid2" patternContentUnits="objectBoundingBox" width="0.369004" height="16.6667">
              <use xlinkHref="#image0" transform="scale(0.00369004 0.166667)" />
            </pattern>
            <image
              id="image0"
              width="100"
              height="100"
              // eslint-disable-next-line max-len
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAARhJREFUeNrs17EKgzAURuGrCF1d3IOrW5/A938Iye7S1a0JdK6DBDJ8B0qWfzvI7RlSSntEnPGf1++97JrulihCtptR3cz1Z9d8t42BriCEEBBCCAghBC0Yyn/fd3kPgdbFbvWFdMZUreWcP3elWV+75rvLF+KogxBCQAghIESpK2ulrtSVulKHo04ICCEEhBACpa7UodTtlLqjDkIIASGEgBClrpiVOpS6UoejTggIIQSEEAKlbqfUlbqdUnfUQQghIIQQEAKlrtSh1JU6HHVCQAghIASEKHU7pa7U7ZS6ow5CCAEhIIQQKHWlDqWu1OGoEwJCQAghIESp2yl1pW6n1B11EAJCCAEhhOBRqe/lPRVzF7vlK8AAnlb3J1pQRuoAAAAASUVORK5CYII="
            />
          </defs>
        </svg>
      </div>
    </SettingsItem>
  );
}

export default React.memo(ShowGridCheckbox);

import React from 'react';
import { useTranslation } from 'react-i18next';

import { MediaText } from 'editor/src/store/design/types';
import applyFontStylesToSelectionOperation from 'editor/src/store/fonts/operation/applyFontStylesToSelectionOperation';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import { PluginName } from 'editor/src/store/plugins/types';

import EffectsElement from 'editor/src/component/DesktopSidebar/TabContents/EffectsTabContent/EffectsElement';
import IconCurve from 'editor/src/component/Icon/IconCurve';

import TextCurveControls from './TextCurveControls';

interface Props {
  selectedElement: MediaText;
}

function TextCurve({ selectedElement }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hasCurve = selectedElement.extra.curve !== undefined;
  const defaultCurve = useSelector((state) => state.editor.currentStyles.text.curve);

  const onSwitched = () => {
    dispatch(
      applyFontStylesToSelectionOperation({
        curve: hasCurve ? undefined : defaultCurve,
      }),
    );
  };

  return (
    <EffectsElement
      controlId="curve"
      title={t('Curve')}
      icon={<IconCurve />}
      switchStatus={hasCurve}
      pluginName={PluginName.TextEffects}
      onToggled={onSwitched}
    >
      <TextCurveControls selectedElement={selectedElement} curve={selectedElement.extra.curve ?? defaultCurve} />
    </EffectsElement>
  );
}

export default React.memo(TextCurve);

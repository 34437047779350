import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconNoOutline({ className }: Props) {
  return (
    <Icon className={className}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5834 18.75L19.0834 5.25" stroke="#383838" strokeWidth="2" strokeLinecap="round" />
        <circle cx="12.3333" cy="12" r="11" stroke="#383838" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </Icon>
  );
}

export default IconNoOutline;

import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DraggableItemType, DraggableSingleItem } from 'editor/src/store/editor/types';
import { AddonBitmap, AddonVector } from 'editor/src/store/editorModules/addons/types';
import { useSelector } from 'editor/src/store/hooks';
import getPluginIcon from 'editor/src/store/plugins/selector/getPluginIcon';
import { PluginName } from 'editor/src/store/plugins/types';

import useDrag, { DRAG_ELMT_CLASSNAME } from 'editor/src/util/dnd/useDrag';

import IconCheck from 'editor/src/component/Icon/IconCheck';

import useImageLoader from './useImageLoader';

import styles from './index.module.scss';

interface Props {
  addon: VectorOrBitmap;
  isMobile: boolean;
  addAddon: (addonId: string) => void;
  usage?: number;
  visible: boolean;
  showPremiumIcon: boolean;
  pluginDisabled: boolean;
}

export type VectorOrBitmap = AddonVector | AddonBitmap;

function AddonsListItem({ addon, isMobile, addAddon, usage, visible, showPremiumIcon, pluginDisabled }: Props) {
  const { t } = useTranslation();
  const imageLoaded = useImageLoader(addon.preview, visible);
  const PremiumIcon = useSelector((state) => getPluginIcon(state, PluginName.Graphics));

  const getDraggedElement = useCallback(
    (): DraggableSingleItem => ({ itemId: addon.id, itemType: DraggableItemType.addon, isMultipleItems: false }),
    [addon.id],
  );

  const { elementRef } = useDrag(getDraggedElement, !pluginDisabled);

  function onClick() {
    addAddon(addon.id);
  }

  const isFree = addon.price === '0' || addon.price === 0;

  return (
    <div
      className={cn(styles.addonListItem, 'cy-addon-item', DRAG_ELMT_CLASSNAME, {
        [styles.mobile]: isMobile,
      })}
      draggable={false}
      onClick={onClick}
      ref={elementRef}
    >
      {imageLoaded ? <img crossOrigin="anonymous" src={addon.preview} alt={addon.name} draggable={false} /> : null}
      <div className={styles.badges}>
        {isFree && <div className={styles.free}>{t('editor-free')}</div>}
        {addon.price && !isFree && !showPremiumIcon && <div className={styles.price}>{addon.price}</div>}
        {showPremiumIcon && !isFree && <PremiumIcon className={styles.premium} />}
        {!!usage && (
          <div className={cn(styles.usage, 'cy-addon-usage')}>
            <IconCheck />
            <span>{usage}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(AddonsListItem);

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'editor/src/store/hooks';
import setMockupMultiOptionOperation from 'editor/src/store/mockup/operation/setMockupMultiOptionOperation';
import setMockupSingleOptionOperation from 'editor/src/store/mockup/operation/setMockupSingleOptionOperation';
import { ProductControlMulti, ProductControlOption, ProductControlSingle } from 'editor/src/store/variants/types';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import {
  getControlElement,
  getMultiControl,
  getSingleControl,
} from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/utils';

import BackHeader from './BackHeader';
import MockupLeaningControls from './MockupLeaningControls';

interface Props {
  back: () => void;
}

function ProductPlaceholderTabContent({ back }: Props) {
  const { t } = useTranslation();
  const { controls, variations, singleOptions, multiOptions } = useSelector((state) => state.mockup.productPlaceholder);
  const enableMockupLeaning = useSelector((state) => state.hostSettings.enableMockupLeaning);
  const dispatch = useDispatch();

  const toggleOptionMulti = useCallback((control: ProductControlMulti, option: ProductControlOption) => {
    dispatch(setMockupMultiOptionOperation(control, option.value));
  }, []);

  const selectOption = useCallback((control: ProductControlSingle, option: ProductControlOption) => {
    dispatch(setMockupSingleOptionOperation(control.key, option.value));
  }, []);

  return (
    <>
      <BackHeader title={t('Placeholder')} onClick={back} />
      <ContentBlock scroll>
        {enableMockupLeaning && <MockupLeaningControls />}
        {controls.map((productControl, i) => {
          if (productControl.type === 'multi') {
            const Control = getMultiControl(productControl.uiType);
            return (
              <Control
                key={productControl.key}
                control={productControl}
                element={getControlElement(productControl.uiType, false)}
                multiMode={false}
                toggleOption={toggleOptionMulti}
                variations={variations}
                multiOptions={multiOptions[productControl.key]}
                singleOptions={singleOptions}
                noTopBar={i === 0 && !enableMockupLeaning}
              />
            );
          }

          if (productControl.type === 'single') {
            const Control = getSingleControl(productControl.uiType);
            return (
              <Control
                key={productControl.key}
                control={productControl}
                element={getControlElement(productControl.uiType, false)}
                toggleOption={selectOption}
                value={singleOptions[productControl.key]}
                noTopBar={i === 0 && !enableMockupLeaning}
              />
            );
          }

          return null;
        })}
      </ContentBlock>
    </>
  );
}

export default React.memo(ProductPlaceholderTabContent);

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { Dimensions } from 'editor/src/store/design/types';
import getReplacableImageElement from 'editor/src/store/editor/selector/getReplacableImageElement';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import type { AsyncThunk } from 'editor/src/store/hooks';
import getPlugin from 'editor/src/store/plugins/selector/getPlugin';
import { PluginState } from 'editor/src/store/plugins/types';
import { setAssetProviderDataOnElement } from 'editor/src/store/plugins/utils/assetProviderUtils';

import getNewImage from 'editor/src/util/getNewImage';
import loadImageDimensions from 'editor/src/util/loadImageDimensions';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';
import toastController from 'editor/src/util/toastController';
import { elementUuids } from 'editor/src/util/uuids';

import addMediaElementOperation from './addMediaElementOperation';
import updateImageWithAssetOperation from './updateImageWithAssetOperation';
import uploadImageAssetOperation from './uploadImageAssetOperation';

const addNewImageAssetToPageOperation =
  (
    spreadIndex: number,
    pageIndex: number,
    assetId: string,
    pluginName: string,
    assetThumbnail: string,
    drop?: { x: number; y: number },
    dimensions?: Dimensions,
  ): AsyncThunk =>
  async (dispatch, getStore, { i18n }) => {
    const state = getStore();

    if (getPlugin(state, pluginName)?.state !== PluginState.ENABLED) {
      return;
    }

    let imageDimensions: Dimensions;
    try {
      imageDimensions =
        dimensions ||
        (await loadImageDimensions(assetThumbnail, undefined, {
          executor: 'addNewImageAssetToPageOperation',
          assetId,
          pluginName,
        }));
    } catch {
      toastController.warningPersist(
        i18n.t('editor-image-loading-failed'),
        i18n.t('editor-image-loading-failed-message'),
      );
      return;
    }

    if (!isAddElementsAllowed(state)) {
      const element = getReplacableImageElement(state, spreadIndex);
      if (element) {
        await dispatch(
          updateImageWithAssetOperation(element.uuid, assetId, pluginName, assetThumbnail, imageDimensions, true),
        );
      }

      return;
    }

    const uuid = elementUuids.generate();
    const image = getNewImage(
      state,
      {
        ...{
          id: assetId,
          url: assetThumbnail,
          name: `${i18n.t('editor-image')} ${uuid}`,
        },
        ...imageDimensions,
      },
      undefined,
      spreadIndex,
      uuid,
      drop?.x,
      drop?.y,
    );
    if (!image) {
      return;
    }

    setAssetProviderDataOnElement(image, pluginName, assetId);

    batch(() => {
      dispatch(addMediaElementOperation(spreadIndex, pageIndex, image, true));
      dispatch(uploadImageAssetOperation(image, assetId, pluginName, assetThumbnail, imageDimensions, !!dimensions));
    });

    sendPostMessage('log.imageAdded', image);
  };

export default addNewImageAssetToPageOperation;

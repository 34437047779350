import cn from 'classnames';
import React, { useCallback } from 'react';

import { ControlButton } from 'editor/src/component/DesktopSidebar/TabContents/Plugins/ContentProviderPlugin/store/types';
import RadioButton from 'editor/src/component/RadioButton';

import styles from './index.module.scss';

interface Props {
  control: ControlButton;
  appliedValues: string | string[] | undefined;
  onChange: (value: string | string[] | undefined) => void;
  className?: string;
}

function ButtonControl({ control, appliedValues, onChange, className }: Props) {
  const isOptionSelected = useCallback(
    (value: string): boolean => {
      if (!appliedValues) {
        return !!(control.allValuesOptionKey && control.allValuesOptionKey === value);
      }

      return (
        (control.type === 'single' && appliedValues === value) ||
        (control.type === 'multi' && appliedValues?.includes(value))
      );
    },
    [appliedValues],
  );

  const onControlChange = (on: boolean, key: string, value: string): void => {
    if (value === control.allValuesOptionKey) {
      return onChange(undefined);
    }

    if (control.type === 'single') {
      if (appliedValues !== value) {
        return onChange(value);
      }

      return undefined;
    }

    let newValue = Array.isArray(appliedValues) ? appliedValues.slice() : [];
    if (!on) {
      newValue = newValue.filter((appliedValue) => appliedValue !== value);
    } else {
      newValue.push(value);
    }

    return onChange(newValue);
  };

  return (
    <div className={cn('row-flex row-flex--between', className)}>
      {control.options.map((option) => (
        <RadioButton
          key={option.value}
          className={cn(styles.buttonControlOption, 'mb-1', `cy-filter-option-${option.value}`, {
            [styles.active]: isOptionSelected(option.value),
            'cy-active': isOptionSelected(option.value),
          })}
          label={option.title}
          on={isOptionSelected(option.value)}
          onChange={(on) => onControlChange(on, control.key, option.value)}
        />
      ))}
    </div>
  );
}

export default React.memo(ButtonControl);

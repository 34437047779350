/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconShutterstock({ className }: Props) {
  return (
    <Icon className={cn(className, styles.iconShutterstock)}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M22.9047 10.6081H15.7651C14.631 10.6081 13.7088 11.5609 13.7088 12.7288V20.7313H6.66675V12.7288C6.66659 11.4946 6.90186 10.2724 7.35908 9.13221C7.81631 7.99197 8.48654 6.95599 9.33149 6.08346C10.1764 5.21092 11.1795 4.51893 12.2834 4.04704C13.3874 3.57515 14.5704 3.3326 15.7651 3.33325H22.9047V10.6081ZM17.0943 29.3905H24.2386C24.783 29.3888 25.3046 29.1645 25.6893 28.7666C26.074 28.3688 26.2905 27.8297 26.2914 27.2673V19.2686H33.3334V27.2686C33.3334 29.7607 32.3753 32.1507 30.6698 33.9131C28.9642 35.6755 26.6509 36.6659 24.2386 36.6666H17.0943V29.3905Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconShutterstock;

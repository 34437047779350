import cn from 'classnames';
import React, { useEffect } from 'react';

import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import hasSidebarTab from 'editor/src/store/editorModules/sidebar/selector/hasSidebarTab';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import IndicatorBadge from 'editor/src/component/IndicatorBadge';
import { CanShow, MenuItemProps } from 'editor/src/component/Menu/type';

import useElementWarnings from './useElementWarnings';

import styles from './index.module.scss';

export const canShow: CanShow = (state, { hasSelection }) => hasSelection && hasSidebarTab(state, TAB_NAMES.WARNINGS);

type Props = Pick<MenuItemProps, 'context' | 'close' | 'refresh'>;

function ButtonWarnings({ context, close, refresh }: Props) {
  const dispatch = useDispatch();
  const element = useSelector((state) => getMediaElement(state, context.structureIndexes[0]));
  const { liveElement } = useMediaElementLiveUpdates(element);

  const handleClick = () => {
    if (level) {
      dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.WARNINGS));
      close?.();
    }
  };

  const { level, tooltipLabel, warnings, text } = useElementWarnings(liveElement);

  useEffect(() => {
    refresh?.();
  }, [warnings.length]);

  if (level || tooltipLabel) {
    return (
      <div
        onClick={handleClick}
        className={cn(styles.ButtonWarning, 'cy-button-warnings', `cy-warning-level-${level || 'None'}`, {
          [styles.button]: !!level,
        })}
      >
        <IndicatorBadge className={styles.indicator} tooltip={tooltipLabel} level={level} text={text} />
      </div>
    );
  }

  return null;
}

export default React.memo(ButtonWarnings);

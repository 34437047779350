import React from 'react';
import { useTranslation } from 'react-i18next';

import closePopupOperation from 'editor/src/store/app/module/popup/operation/closePopupOperation';
import getPopupData from 'editor/src/store/app/module/popup/selector/getPopupData';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import Button from 'editor/src/component/Button';
import AbstractPopup, { PopupType } from 'editor/src/component/Popup/AbstractPopup';
import Section from 'editor/src/component/Popup/AbstractPopup/Section';
import { PopupName } from 'editor/src/component/Popup/popups';

export const POPUP_NAME = 'confirmation-popup';

export interface ApplyTemplateData {
  onConfirm: () => void;
}

function ApplyTemplatePopup() {
  const popupData = useSelector((state) => getPopupData(state, PopupName.APPLY_TEMPLATE_POPUP));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!popupData) {
    return null;
  }

  const handleCancelCLick = () => {
    dispatch(closePopupOperation());
  };

  const handleConfirmClick = () => {
    popupData.onConfirm();
    dispatch(closePopupOperation());
  };

  return (
    <AbstractPopup
      onClickOutside={handleCancelCLick}
      name={PopupName.APPLY_TEMPLATE_POPUP}
      type={PopupType.INFO}
      title={t('editor-info')}
      className="cy-popup-apply-template"
      buttons={
        <>
          <Button secondary onClick={handleCancelCLick} className="cy-popup-option-cancel">
            {t('editor-cancel')}
          </Button>
          <Button primary onClick={handleConfirmClick} className="cy-popup-option-confirm">
            {t('editor-apply-new')}
          </Button>
        </>
      }
    >
      <Section>{t('editor-confirm-apply-layout')}</Section>
    </AbstractPopup>
  );
}

export default ApplyTemplatePopup;

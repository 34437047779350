import React from 'react';

import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';

import { TabContentProps } from 'editor/src/component/DesktopSidebar/TabContents';
import EditImageTabContent from 'editor/src/component/DesktopSidebar/TabContents/EditImageTabContent';
import EffectsTabContent from 'editor/src/component/DesktopSidebar/TabContents/EffectsTabContent';
import FoilLayersTabContent from 'editor/src/component/DesktopSidebar/TabContents/FoilLayersTabContent';
import FormatsTabContent from 'editor/src/component/DesktopSidebar/TabContents/FormatsTabContent';
import PageBackgroundColorPickerTabContent from 'editor/src/component/DesktopSidebar/TabContents/PageBackgroundColorPickerTabContent';
import SettingsTabContent from 'editor/src/component/DesktopSidebar/TabContents/SettingsTabContent';
import ShadowColorPickerTabContent from 'editor/src/component/DesktopSidebar/TabContents/ShadowColorPickerTabContent';
import TemplatesTabContent from 'editor/src/component/DesktopSidebar/TabContents/TemplatesTabContent';
import TextOutlineColorPickerTabContent from 'editor/src/component/DesktopSidebar/TabContents/TextOutlineColorPickerTabContent';
import ThreadColorTabContent from 'editor/src/component/DesktopSidebar/TabContents/ThreadColorTabContent';
import WarningTabContent from 'editor/src/component/DesktopSidebar/TabContents/WarningsTabContent';

import ShutterstockTabContent from '../DesktopSidebar/TabContents/ShutterstockTabContent';

import PersonalisationFlowContent from './PersonalisationFlowContent';

const tabContents: { [Tab in TAB_NAMES]?: React.FC<TabContentProps> } = {
  [TAB_NAMES.WARNINGS]: WarningTabContent,
  [TAB_NAMES.SETTINGS]: SettingsTabContent,
  [TAB_NAMES.FORMATS]: FormatsTabContent,
  [TAB_NAMES.COLOR_PICKER]: PageBackgroundColorPickerTabContent,
  [TAB_NAMES.PERSONALIZATION_FLOW]: PersonalisationFlowContent,
  [TAB_NAMES.THREAD_COLOR]: ThreadColorTabContent,
  [TAB_NAMES.EFFECTS]: EffectsTabContent,
  [TAB_NAMES.IMAGE_SHADOW]: EffectsTabContent,
  [TAB_NAMES.EDIT_IMAGE]: EditImageTabContent,
  [TAB_NAMES.TEXT_OUTLINE_COLOR_PICKER]: TextOutlineColorPickerTabContent,
  [TAB_NAMES.SHADOW_COLOR_PICKER]: ShadowColorPickerTabContent,
  [TAB_NAMES.FOIL_LAYERS]: FoilLayersTabContent,
  [TAB_NAMES.SHUTTERSTOCK]: ShutterstockTabContent,
  [TAB_NAMES.DESIGN_TEMPLATES]: TemplatesTabContent,
};

export default tabContents;

import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  noMargin?: boolean;
  noPadding?: boolean;
  noRightMargin?: boolean;
  className?: string;
}

function H1({ children, noRightMargin, noMargin, noPadding, className }: Props) {
  return (
    <h1
      className={cn(
        styles.h1,
        {
          [styles.noRightMargin]: noRightMargin,
          [styles.noMargin]: noMargin,
          [styles.noPadding]: noPadding,
        },
        className,
      )}
    >
      {children}
    </h1>
  );
}

export default H1;

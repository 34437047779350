import cn from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props {
  label: string;
}

function LabelBadge({ label }: Props) {
  return <div className={cn(styles.labelBadge, 'cy-label-badge')}>{label}</div>;
}

export default LabelBadge;

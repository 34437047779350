import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import { DropShadow, LeaningShadow } from 'editor/src/store/design/types';
import defaultFontStyles from 'editor/src/store/fonts/utils/defaultFontStyles';
import defaultImageStyles from 'editor/src/store/fonts/utils/defaultImageStyles';
import { RootState } from 'editor/src/store/index';

const getCurrentShadow = (state: RootState): DropShadow | LeaningShadow | undefined => {
  const { selectedElementUuids } = state.editor;

  const element = getMediaElementByUuid(state, selectedElementUuids[0]);
  if (!element || (element.type !== 'text' && element.type !== 'image')) {
    return undefined;
  }

  switch (element.type) {
    case 'text':
      return element.extra.shadow ?? defaultFontStyles.shadow;
    default:
      return element.shadow ?? defaultImageStyles.shadow;
  }
};

export default getCurrentShadow;

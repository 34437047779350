import cn from 'classnames';
import React, { useCallback, useRef } from 'react';

import HSVA from 'editor/src/util/color/hsvUtils';
import limitPrecision from 'editor/src/util/limitPrecision';

import styles from './index.module.scss';

interface Props {
  hsva: HSVA;
  onChange: (hsva: HSVA) => void;
  onChanged: (hsva: HSVA) => void;
  className?: string;
  hideCursor?: boolean;
}

function ShadeSelector({ hsva, onChange, onChanged, className, hideCursor }: Props) {
  const divRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const containerBbox = useRef<DOMRect>();
  const { h: hue, s: saturation, v: lightness, a: alpha } = hsva;

  const onPointerStart = useCallback(
    (initX: number, initY: number) => {
      containerBbox.current = divRef.current?.getBoundingClientRect();
      let lastSaturation = saturation;
      let lastLightness = lightness;

      const onMove = (posX: number, posY: number) => {
        if (!containerBbox.current || !handleRef.current) {
          return;
        }
        const x = Math.min(Math.max(posX - containerBbox.current.left, 0), containerBbox.current.width);
        const y = Math.min(Math.max(posY - containerBbox.current.top, 0), containerBbox.current.height);

        const newSaturation = limitPrecision((x / containerBbox.current.width) * 100, 2);
        const newLightness = limitPrecision((1 - y / containerBbox.current.height) * 100, 2);

        if (newSaturation === lastSaturation && newLightness === lastLightness) {
          return;
        }
        lastSaturation = newSaturation;
        lastLightness = newLightness;

        handleRef.current.style.left = `${newSaturation}%`;
        handleRef.current.style.top = `${100 - newLightness}%`;
        handleRef.current.style.backgroundColor = `hsl(${hue}, ${newSaturation}%, ${newLightness}%)`;
        onChange(new HSVA({ h: hue, s: newSaturation, v: newLightness, a: alpha }));
      };

      const onMouseMove = (e: MouseEvent) => {
        e.preventDefault();
        onMove(e.clientX, e.clientY);
      };

      const onTouchMove = (e: TouchEvent) => {
        e.preventDefault();
        onMove(e.touches[0].clientX, e.touches[0].clientY);
      };

      const onPointerUp = () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onPointerUp);
        window.removeEventListener('touchmove', onTouchMove);
        window.removeEventListener('touchend', onPointerUp);
        onChanged(new HSVA({ h: hue, s: lastSaturation, v: lastLightness, a: alpha }));
      };

      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onPointerUp);
      window.addEventListener('touchmove', onTouchMove);
      window.addEventListener('touchend', onPointerUp);
      onMove(initX, initY);
    },
    [hue, alpha, onChange, onChanged],
  );

  const onTouchDown = useCallback(
    (e: React.TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onPointerStart(e.touches[0].clientX, e.touches[0].clientY);
    },
    [onPointerStart],
  );

  const onMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onPointerStart(e.clientX, e.clientY);
    },
    [onPointerStart],
  );

  return (
    <div className={cn(styles.SaturationSelector, className, 'cy-shade-selector')}>
      <div
        className={styles.inside}
        style={{
          backgroundColor: `hsl(${hue}, 100%, 50%)`,
        }}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchDown}
        ref={divRef}
      >
        <div
          className={styles.handle}
          ref={handleRef}
          style={{
            display: hideCursor ? 'none' : '',
            left: `${saturation}%`,
            top: `${100 - lightness}%`,
            backgroundColor: `hsl(${hue}, ${saturation}%, ${lightness / (saturation / 100 + 1)}%)`,
          }}
        />
      </div>
    </div>
  );
}

export default React.memo(ShadeSelector);

import React from 'react';

import useMultiValues from 'editor/src/store/variants/selector/useMultiValues';

import MultiSelectProductControl from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/multi/MultiSelectProductControl';
import useFilteredOptionsWithVariations from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/multi/useFilteredOptionsWithVariations';
import { MultiProductControlProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';

import styles from './index.module.scss';

function MultiControl({
  toggleOption,
  element,
  multiMode,
  control,
  selectAll,
  deselectAll,
  variations,
  multiOptions,
  singleOptions,
  noTopBar,
  IconElement,
  availableOptions,
  unAvailableOptions,
  disabled,
  disabledControlDescription,
}: MultiProductControlProps) {
  const { selectedOptions, totalCount } = useMultiValues(control, multiOptions, singleOptions, !multiMode);
  const options = useFilteredOptionsWithVariations(control, variations, singleOptions, disabledControlDescription);

  const allowSelectAll = control.uiType === 'checkbox';
  return (
    <MultiSelectProductControl
      className={control.uiType === 'button' ? styles.ButtonControl : undefined}
      title={control.title}
      options={options}
      control={control}
      toggleOption={toggleOption}
      selectAll={selectAll}
      deselectAll={deselectAll}
      selectedOptions={selectedOptions}
      availableOptions={availableOptions}
      selectedCount={totalCount}
      multiMode={multiMode}
      Element={element}
      noTopBar={noTopBar}
      IconElement={IconElement}
      unAvailableOptions={unAvailableOptions?.[control.key]}
      disabled={disabled}
      allowSelectAll={allowSelectAll}
    />
  );
}

export default React.memo(MultiControl);

import { Direction, ImagePattern } from 'editor/src/store/design/types';
import { EditorState } from 'editor/src/store/editor/types';

export const DEFAULT_PATTERN_TYPE: ImagePattern['type'] = 'brick';

function getPatternDefault(
  type: ImagePattern['type'] | undefined,
  scaleBases: EditorState['imagePatternScaleBases'],
): ImagePattern | undefined {
  switch (type) {
    case 'brick':
      return {
        type: 'brick',
        direction: Direction.Horizontal,
        scale: scaleBases[0] ? 1 : 2,
        scaleBase: scaleBases[0]
          ? { type: scaleBases[0].dimension, value: scaleBases[0].value }
          : { type: 'element', value: 'width' },
      };
    default:
      return undefined;
  }
}

export default getPatternDefault;

import { useMemo } from 'react';

import { conditionGroupChildenKey } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useSelector } from 'editor/src/store/hooks';

function useLinkedCondition(elementId: number) {
  const conditionGroup = useSelector(
    (state) => state.design.designData?.spreads[getCurrentSpreadIndex(state)].conditionGroup,
  );

  return useMemo(() => {
    if (!conditionGroup) {
      return undefined;
    }

    const childrenKey = Object.keys(conditionGroup.children).find(
      (childrenKey) =>
        !!conditionGroup.children[childrenKey].find((child) => child.type !== 'condition' && child.id === elementId),
    );

    if (childrenKey && childrenKey !== conditionGroupChildenKey) {
      const [conditionId, optionId] = childrenKey.split('-');
      const condition = conditionGroup.conditions[conditionId];
      return {
        condition,
        optionName: condition.options.find((option) => option.id === optionId)?.label || '',
      };
    }

    return undefined;
  }, [conditionGroup]);
}

export default useLinkedCondition;

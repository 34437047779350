import cn from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import getSpreadHeight from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidth from 'editor/src/store/design/selector/getSpreadWidth';
import { ElementAddress } from 'editor/src/store/design/types';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { RectMediaElement } from 'editor/src/util/reflectDesignData/utils';

import {
  getSanitizeValue,
  round2Decimals,
} from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/utils';
import IconLeftPosition from 'editor/src/component/Icon/IconLeftPosition';
import IconTopPosition from 'editor/src/component/Icon/IconTopPosition';
import Input from 'editor/src/component/Input';
import WithTooltip from 'editor/src/component/WithTooltip';

import styles from './index.module.scss';

interface Props {
  address: ElementAddress;
  element: RectMediaElement;
}

function PositionProperty({ address, element }: Props) {
  const { t } = useTranslation();
  const spreadWidth = useSelector((state) => getSpreadWidth(state, getCurrentSpreadIndex(state)));
  const spreadHeight = useSelector((state) => getSpreadHeight(state, getCurrentSpreadIndex(state)));
  const dispatch = useDispatch();

  const [x, setX] = useState<string>('');
  const [y, setY] = useState<string>('');

  const resetToElementX = () => {
    setX(`${round2Decimals(element.x)}`);
  };

  const handleXChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setX(inputValue);
    const value = getSanitizeValue(inputValue, element.x, -element.width, spreadWidth + element.width);
    if (value !== element.x) {
      const imageUpdate: Partial<RectMediaElement> = { x: value };
      dispatch(updateMediaElementOperation(address, imageUpdate, MediaUpdateActionName.MOVED));
    }
  };

  const resetToElementY = () => {
    setY(`${round2Decimals(element.y)}`);
  };

  const handleYChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setY(inputValue);
    const value = getSanitizeValue(inputValue, element.y, -element.height, spreadHeight + element.height);
    if (value !== element.y) {
      const imageUpdate: Partial<RectMediaElement> = { y: value };
      dispatch(updateMediaElementOperation(address, imageUpdate, MediaUpdateActionName.MOVED));
    }
  };

  useEffect(() => {
    if (element.x !== 0 || parseFloat(x) !== 0) {
      // prevent changing 00 to 0 in input
      resetToElementX();
    }
  }, [element.x]);

  useEffect(() => {
    if (element.y !== 0 || parseFloat(y) !== 0) {
      // prevent changing 00 to 0 in input
      resetToElementY();
    }
  }, [element.y]);

  return (
    <div className={styles.positionProperty}>
      <div className={styles.inputs}>
        <div className={styles.inputWrap}>
          <WithTooltip overlay={t('editor-position-left')}>
            <IconLeftPosition />
          </WithTooltip>
          <Input
            className={cn(styles.input, 'cy-input-left')}
            label={t('editor-mm')}
            value={x}
            onChange={handleXChange}
            onBlur={resetToElementX}
            type="number"
          />
        </div>
        <div className={styles.inputWrap}>
          <WithTooltip overlay={t('editor-position-top')}>
            <IconTopPosition />
          </WithTooltip>
          <Input
            className={cn(styles.input, 'cy-input-top')}
            label={t('editor-mm')}
            value={y}
            onChange={handleYChange}
            onBlur={resetToElementY}
            type="number"
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(PositionProperty);

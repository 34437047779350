/* eslint-disable max-len */
import cn from 'classnames';
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconPremium({ className }: Props) {
  return (
    <Icon className={cn(className, styles.iconPremium)}>
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          className={styles.bg}
          stroke="none"
          d="M1 9.5C1 5.08172 4.58172 1.5 9 1.5C13.4183 1.5 17 5.08172 17 9.5C17 13.9183 13.4183 17.5 9 17.5C4.58172 17.5 1 13.9183 1 9.5Z"
          fill="#E18700"
        />
        <path
          stroke="none"
          d="M4 11.5L4 12C3.8495 12 3.70701 11.9322 3.61206 11.8154C3.51712 11.6987 3.47982 11.5454 3.51051 11.398L4 11.5ZM5.66667 11.5L5.66667 11L5.66667 11L5.66667 11.5ZM4.83333 7.5L4.34384 7.39802C4.37795 7.23428 4.49168 7.09845 4.64687 7.03607C4.80206 6.9737 4.97816 6.99305 5.1161 7.08764L4.83333 7.5ZM7.48485 9.31818L7.86896 9.63827C7.70377 9.8365 7.41488 9.87647 7.20208 9.73055L7.48485 9.31818ZM9 7.5L8.61589 7.17991C8.71088 7.06591 8.85161 7 9 7C9.14839 7 9.28911 7.06591 9.38411 7.17991L9 7.5ZM10.5153 9.31832L10.798 9.73069C10.5852 9.87661 10.2963 9.83664 10.1312 9.63842L10.5153 9.31832ZM13.167 7.5L12.8842 7.08764C13.0222 6.99305 13.1983 6.9737 13.3535 7.03607C13.5086 7.09845 13.6224 7.23429 13.6565 7.39802L13.167 7.5ZM14.0003 11.5L14.4898 11.398C14.5205 11.5454 14.4832 11.6987 14.3883 11.8154C14.2933 11.9322 14.1508 12 14.0003 12L14.0003 11.5ZM12.3333 11.5L12.3333 12L12.3333 11.5ZM10.6667 11.5L10.6667 11L10.6667 11.5ZM7.33366 11.5L7.33366 12L7.33366 11.5ZM4 11L5.66667 11L5.66667 12L4 12L4 11ZM5.32282 7.60198L4.48949 11.602L3.51051 11.398L4.34384 7.39802L5.32282 7.60198ZM7.20208 9.73055L4.55057 7.91237L5.1161 7.08764L7.76761 8.90582L7.20208 9.73055ZM9.38411 7.82009L7.86896 9.63827L7.10074 8.99809L8.61589 7.17991L9.38411 7.82009ZM10.1312 9.63842L8.61589 7.82009L9.38411 7.17991L10.8994 8.99823L10.1312 9.63842ZM13.4498 7.91237L10.798 9.73069L10.2325 8.90596L12.8842 7.08764L13.4498 7.91237ZM13.5108 11.602L12.6775 7.60198L13.6565 7.39802L14.4898 11.398L13.5108 11.602ZM12.3333 11L14.0003 11L14.0003 12L12.3333 12L12.3333 11ZM10.6667 11L12.3333 11L12.3333 12L10.6667 12L10.6667 11ZM7.33366 11L10.6667 11L10.6667 12L7.33366 12L7.33366 11ZM5.66667 11L7.33366 11L7.33366 12L5.66667 12L5.66667 11Z"
          fill="white"
        />
        <path
          stroke="none"
          d="M9 16.5C5.13401 16.5 2 13.366 2 9.5H0C0 14.4706 4.02944 18.5 9 18.5V16.5ZM16 9.5C16 13.366 12.866 16.5 9 16.5V18.5C13.9706 18.5 18 14.4706 18 9.5H16ZM9 2.5C12.866 2.5 16 5.63401 16 9.5H18C18 4.52944 13.9706 0.5 9 0.5V2.5ZM9 0.5C4.02944 0.5 0 4.52944 0 9.5H2C2 5.63401 5.13401 2.5 9 2.5V0.5Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
}

export default IconPremium;

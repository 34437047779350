import getFontSizes from 'editor/src/component/TopMenuDesktop/buttons/ButtonFontSize/getFontSizes';

import { Area } from './getNewElementDisplay';

const INIT_TEXT_WIDTH_MULTIPLER = 2;
const INIT_TEXT_HEIGHT_MULTIPLER = 0.7;

function getNewTextDisplaySize(minFontSize: number, maxFontSize: number) {
  return (area: Area) => {
    const minDimension = Math.min(area.width, area.height);
    const fontSizes = getFontSizes(minFontSize, maxFontSize);
    const fontSize = fontSizes.find((val) => val > minDimension / 3) || Math.max(...fontSizes);

    const width = INIT_TEXT_WIDTH_MULTIPLER * fontSize;
    const height = INIT_TEXT_HEIGHT_MULTIPLER * fontSize;

    return { width, height, fontSize };
  };
}

export default getNewTextDisplaySize;

import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useRef } from 'react';

type Element = {
  getHeight: () => number;
  setY: (y: number) => void;
  isDragging: (dragging: boolean) => void;
};

export type ListManager = {
  register: (element: Element) => {
    startMove: () => void;
    moveElement: (y: number) => void;
    endMove: () => void;
    unregister: () => void;
  };
  update: () => void;
  getAvailableHeight: () => number;
  margin: number;
};

export const ListManagerContext = React.createContext<ListManager>(null as any);

function useListManager(
  margin = 0,
  indexOffset = 0,
  onUpdate: () => void,
  onIndexUpdate: (prevIndex: number, newIndex: number) => void,
  isNested?: boolean,
  options?: { disableDragEffect?: boolean },
) {
  const elementList = useRef<Array<{ element: Element; y: number; height: number }>>([]);
  const currentElementList = useRef<Array<{ y: number; height: number }>>([]);
  const currentElement = useRef<Element>();
  const initialIndex = useRef(0);
  const totalHeight = useRef(0);

  const updatePosition = useCallback(() => {
    let y = isNested ? margin : 0;
    elementList.current.forEach((entry) => {
      if (currentElement.current !== entry.element) {
        entry.element.setY(y);
      }
      entry.y = y;
      entry.height = entry.element.getHeight();
      if (entry.height > 0) {
        y += entry.height + margin;
      }
    });

    totalHeight.current = y + (isNested ? -margin : 0);
    onUpdate();
  }, [margin, onUpdate, isNested]);

  const manager: ListManager = useMemo(
    () => ({
      register: (element: Element) => {
        const entry = { element, y: 0, height: 0 };
        elementList.current.push(entry);

        return {
          unregister: () => {
            const index = elementList.current.indexOf(entry);
            if (index !== -1) {
              elementList.current.splice(index, 1);
            }
          },
          startMove: () => {
            currentElement.current = element;
            initialIndex.current = elementList.current.indexOf(entry);
            currentElementList.current = elementList.current.map((elt) => ({
              y: elt.y,
              height: elt.height,
            }));
            if (!options?.disableDragEffect) {
              elementList.current.forEach((element) => element.element.isDragging(true));
            }
          },
          endMove: () => {
            currentElement.current = undefined;
            const newIndex = elementList.current.indexOf(entry);
            if (newIndex !== initialIndex.current) {
              onIndexUpdate(initialIndex.current + indexOffset, newIndex + indexOffset);
            }
            updatePosition();
            if (!options?.disableDragEffect) {
              elementList.current.forEach((element) => element.element.isDragging(false));
            }
          },
          moveElement: (y: number) => {
            let i = 0;
            for (; i < currentElementList.current.length; i += 1) {
              const loopEntry = currentElementList.current[i];
              if (y < loopEntry.y + loopEntry.height / 2) {
                break;
              }
            }

            const index = elementList.current.indexOf(entry);
            if (i !== index) {
              elementList.current.splice(index, 1);
              elementList.current.splice(i, 0, entry);
            }

            updatePosition();
          },
        };
      },
      update: debounce(updatePosition, 0),
      getAvailableHeight: () => totalHeight.current,
      margin,
    }),
    [updatePosition, onIndexUpdate, margin],
  );

  return manager;
}

export default useListManager;

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import { MediaMockupPlaceholder, ShadowSide } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';

const setMockupShadowSideOperation = (side: ShadowSide) => (dispatch: ThunkDispatch, getState: () => RootState) => {
  batch(() => {
    const state = getState();
    const placeholderIndex =
      state.design.designData?.spreads[0].pages[0].groups.media?.findIndex(isMediaMockupPlaceholder);
    const placeholder = (
      placeholderIndex !== undefined
        ? state.design.designData?.spreads[0].pages[0].groups.media?.[placeholderIndex]
        : undefined
    ) as MediaMockupPlaceholder | undefined;
    if (!placeholder || placeholder.shadow?.type !== 'leaning-shadow' || placeholderIndex === undefined) {
      return;
    }

    dispatch(
      updateMediaElementOperation(
        { spreadIndex: 0, pageIndex: 0, elementIndex: placeholderIndex },
        { shadow: { ...placeholder.shadow, side } },
        MediaUpdateActionName.PLACEHOLDER_UPDATE,
      ),
    );
  });
};

export default setMockupShadowSideOperation;

import React, { useMemo } from 'react';

import { useDispatch, useSelector } from 'editor/src/store/hooks';
import setECommerceProductIdOperation from 'editor/src/store/variants/operation/setECommerceProductIdOperation';
import getECommerceProduct from 'editor/src/store/variants/selector/getECommerceProduct';

import ECommerceProductControl from './ECommerceProductControl';
import { getFormattedVariantOptions, getSelectedVariant } from './eCommerceVariantUtils';

function ECommerceProductContent() {
  const eCommerceProduct = useSelector(getECommerceProduct);
  const dispatch = useDispatch();

  const selectedVariant = useMemo(
    () =>
      eCommerceProduct &&
      eCommerceProduct.variants.find((variant) => variant.id === eCommerceProduct?.selectedVariantId),
    [eCommerceProduct],
  );

  const formattedVariantControls = useMemo(() => {
    if (!eCommerceProduct?.options || !selectedVariant) {
      return null;
    }

    return getFormattedVariantOptions(eCommerceProduct.options, eCommerceProduct.variants, selectedVariant);
  }, [eCommerceProduct, selectedVariant]);

  const onChange = (controlIndex: number, value: string) => {
    if (!eCommerceProduct || !selectedVariant) {
      return;
    }

    const newVariant = getSelectedVariant(eCommerceProduct.variants, selectedVariant, controlIndex, value);
    if (!newVariant) {
      return;
    }

    dispatch(setECommerceProductIdOperation(newVariant.id));
  };

  if (!formattedVariantControls) {
    return null;
  }

  return (
    <div>
      {selectedVariant &&
        formattedVariantControls.map(
          ({ name, hidden, values }, i) =>
            !hidden && (
              <ECommerceProductControl
                key={name}
                title={name}
                options={values}
                selectedValue={selectedVariant.optionValues[i]}
                onChange={(value) => onChange(i, value)}
              />
            ),
        )}
    </div>
  );
}

export default React.memo(ECommerceProductContent);

/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import Icon from 'editor/src/component/Icon/index';

import styles from './index.module.scss';

function IconStatic() {
  return (
    <Icon className={styles.icon}>
      <svg width="83%" height="100%" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 6V3C7 1.89543 6.10457 1 5 1V1C3.89543 1 3 1.89543 3 3V3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="1" y="6" width="8" height="5" rx="1" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export default React.memo(IconStatic);

import { CanShow } from 'editor/src/component/Menu/type';

import ButtonPosition from './ButtonPosition';
import ButtonPositionDropDown from './ButtonPositionDropDown';

export const canShow: CanShow = (_, { isSelectionLocked, hasSelection }) => !isSelectionLocked && hasSelection;

export { ButtonPositionDropDown, ButtonPosition };

export default ButtonPosition;

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MediaAddon } from 'editor/src/store/design/types';
import getAddonUrlById from 'editor/src/store/editorModules/addons/selector/getAddonUrlById';
import { useSelector } from 'editor/src/store/hooks';

import abstractStyles from './AbstractLayerItem/index.module.scss';

interface Props {
  element: MediaAddon;
}

function AddonLayerItem({ element }: Props) {
  const { t } = useTranslation();
  const previewUrl = useSelector((state) => getAddonUrlById(state, element.addonId));

  return (
    <>
      <div className={abstractStyles.thumb}>
        <div className={abstractStyles.image}>
          <img
            crossOrigin="anonymous"
            src={previewUrl}
            draggable={false}
            alt={`${element.name} - ${element.type}_${element.uuid}`}
          />
        </div>
      </div>
      <div className={cn(abstractStyles.text, 'cy-layer-text')}>{`${t('editor-sticker')} ${element.uuid}`}</div>
    </>
  );
}

export default React.memo(AddonLayerItem);

import React from 'react';

import getSelectedMediaElementsObjects from 'editor/src/store/design/selector/getSelectedMediaElementsObjects';
import { MediaText } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';

import useMediaElementLiveUpdates from 'editor/src/util/useMediaElementLiveUpdates';

import IconLetterSpacing from 'editor/src/component/Icon/IconLetterSpacing';
import IconLineSpacing from 'editor/src/component/Icon/IconLineSpacing';
import SliderWithInput from 'editor/src/component/SliderWithInput';

import { useIsMobile } from '../useDetectDeviceType';

import useSliderChangeHandlers from './useSliderChangeHandlers';

function TextSpacingControl() {
  const element = useSelector((state) => getSelectedMediaElementsObjects(state)[0]);
  const { liveUpdate } = useMediaElementLiveUpdates(element);
  const isMobile = useIsMobile();

  const selectedElement = element as MediaText | undefined;

  const {
    value: letterSpacing,
    onChange: onLetterSpacingChange,
    onChanged: onLetterSpacingChanged,
  } = useSliderChangeHandlers(selectedElement, 'letterSpacing', liveUpdate);

  const {
    value: lineHeight,
    onChange: onLineHeightChange,
    onChanged: onLineHeightChanged,
  } = useSliderChangeHandlers(selectedElement, 'lineHeight', liveUpdate);

  return (
    <>
      <div className="mb-3">
        <SliderWithInput
          inputClassName="cy-line-height-input"
          Label={IconLineSpacing}
          onChange={onLineHeightChange}
          onAfterChange={onLineHeightChanged}
          min={0.5}
          max={2.5}
          step={0.01}
          startPoint={1}
          value={lineHeight}
          position={isMobile ? 'twoRows' : 'singleRow'}
        />
      </div>
      <SliderWithInput
        inputClassName="cy-letter-spacing-input"
        Label={IconLetterSpacing}
        onChange={onLetterSpacingChange}
        onAfterChange={onLetterSpacingChanged}
        min={-200}
        max={800}
        step={10}
        startPoint={0}
        value={letterSpacing}
        position={isMobile ? 'twoRows' : 'singleRow'}
      />
    </>
  );
}

export default React.memo(TextSpacingControl);

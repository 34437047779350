import { ProductControlOption, ProductControlOptionSize } from 'editor/src/store/variants/types';

function groupOptionsByUnit(options: ProductControlOptionSize[]) {
  return options.reduce<{
    imperial: ProductControlOption[];
    metric: ProductControlOption[];
  }>(
    (lists, option) => {
      if (option.imperial) {
        lists.imperial.push(option);
      } else {
        lists.metric.push(option);
      }
      return lists;
    },
    { imperial: [], metric: [] },
  );
}

export default groupOptionsByUnit;

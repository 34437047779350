import { Spread } from 'editor/src/store/design/types';
import { GalleryImage } from 'editor/src/store/gallery/types';

function doesSpreadHasMissingImages(spread: Spread | undefined, images: GalleryImage[]) {
  return (
    spread?.pages[0].groups.media?.some((media) => {
      if (media.type !== 'image') {
        return false;
      }
      return !images.some((image) => image.id === media.imageId);
    }) || false
  );
}

export default doesSpreadHasMissingImages;

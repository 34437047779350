import React from 'react';

export interface Props {
  title: string;
  children: React.ReactElement;
}

function Section({ children }: Props) {
  return children;
}

export default Section;

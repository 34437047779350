import { useMemo } from 'react';

import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import getSelectedImageUuid from 'editor/src/store/design/selector/getSelectedImageUuid';
import { ColorOverrides, MediaImage } from 'editor/src/store/design/types';
import getImageById from 'editor/src/store/gallery/selector/getImageById';
import { useSelector } from 'editor/src/store/hooks';
import shouldDigitizeCurrentSpread from 'editor/src/store/utils/shouldDigitizeCurrentSpread';

import { extractColors } from './utils';

function useCurrentElementColors(): ColorOverrides[] {
  const selectedMediaElementId = useSelector(getSelectedImageUuid);
  const mediaObject = useSelector((state) =>
    selectedMediaElementId ? getMediaElementByUuid(state, selectedMediaElementId) : undefined,
  ) as MediaImage | undefined;
  const digitizeDesignElements = useSelector(shouldDigitizeCurrentSpread);
  const imageColors = useSelector((state) => {
    if (!mediaObject) {
      return undefined;
    }
    const image = getImageById(state, mediaObject.imageId);
    return digitizeDesignElements ? image?.quantizedColors : image?.colors;
  });

  return useMemo(() => {
    const elementColors = extractColors(imageColors);
    if (!mediaObject?.colorOverrides) {
      return elementColors;
    }

    const overrides: { [color: string]: string } = {};
    mediaObject.colorOverrides.fill?.forEach((c) => {
      overrides[c.previous] = c.new;
    });
    mediaObject.colorOverrides.stroke?.forEach((c) => {
      overrides[c.previous] = c.new;
    });

    elementColors.forEach((color) => {
      if (overrides[color.previous]) {
        color.new = overrides[color.previous];
      }
    });
    return elementColors;
  }, [mediaObject, imageColors]);
}

export default useCurrentElementColors;

/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconChevronDown({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M10 12.458C9.88871 12.458 9.78104 12.4373 9.67704 12.396C9.57304 12.354 9.47237 12.2847 9.37504 12.188L5.60404 8.41699C5.43737 8.25032 5.36104 8.04199 5.37504 7.79199C5.38904 7.54199 5.47237 7.34066 5.62504 7.18799C5.80571 7.00733 6.01404 6.92032 6.25004 6.92699C6.48604 6.93432 6.68737 7.02133 6.85404 7.18799L10 10.354L13.167 7.18799C13.3337 7.02133 13.5314 6.93799 13.76 6.93799C13.9894 6.93799 14.1944 7.02799 14.375 7.20799C14.5417 7.37466 14.625 7.57966 14.625 7.82299C14.625 8.06632 14.5417 8.27132 14.375 8.43799L10.625 12.188C10.5277 12.2847 10.427 12.354 10.323 12.396C10.219 12.4373 10.1114 12.458 10 12.458Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconChevronDown;

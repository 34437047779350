/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props {
  className?: string;
}

function IconAlignMiddle({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          d="M8.5 21C8.08333 21 7.72933 20.854 7.438 20.562C7.146 20.2707 7 19.9167 7 19.5V13H3C2.71667 13 2.47933 12.904 2.288 12.712C2.096 12.5207 2 12.2833 2 12C2 11.7167 2.096 11.479 2.288 11.287C2.47933 11.0957 2.71667 11 3 11H7V4.5C7 4.08333 7.146 3.72933 7.438 3.438C7.72933 3.146 8.08333 3 8.5 3C8.91667 3 9.27067 3.146 9.562 3.438C9.854 3.72933 10 4.08333 10 4.5V11H14V7.5C14 7.08333 14.146 6.72933 14.438 6.438C14.7293 6.146 15.0833 6 15.5 6C15.9167 6 16.2707 6.146 16.562 6.438C16.854 6.72933 17 7.08333 17 7.5V11H21C21.2833 11 21.5207 11.0957 21.712 11.287C21.904 11.479 22 11.7167 22 12C22 12.2833 21.904 12.5207 21.712 12.712C21.5207 12.904 21.2833 13 21 13H17V16.5C17 16.9167 16.854 17.2707 16.562 17.562C16.2707 17.854 15.9167 18 15.5 18C15.0833 18 14.7293 17.854 14.438 17.562C14.146 17.2707 14 16.9167 14 16.5V13H10V19.5C10 19.9167 9.854 20.2707 9.562 20.562C9.27067 20.854 8.91667 21 8.5 21Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconAlignMiddle;

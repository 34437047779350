/* eslint-disable max-len */
import React from 'react';

import Icon from 'editor/src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function IconPersonalizationLocked({ className }: Props) {
  return (
    <Icon className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ strokeWidth: 0 }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.08696 3C6.14195 3 4.56522 4.57674 4.56522 6.52174V7.69565C3.70077 7.69565 3 8.39642 3 9.26087V13.1739C3 14.0384 3.70077 14.7391 4.56522 14.7391H6.06093L5.23531 15.6014C5.11643 15.7255 5.04174 15.8854 5.02275 16.0562L4.57 20.131C4.54375 20.3673 4.62633 20.6027 4.79444 20.7708C4.96255 20.9389 5.19796 21.0215 5.43425 20.9952L9.509 20.5425C9.68562 20.5228 9.85031 20.4437 9.97597 20.318L20.7708 9.52321C20.9175 9.37645 21 9.17739 21 8.96983C21 8.76227 20.9175 8.56321 20.7708 8.41644L17.1488 4.79444C16.8431 4.48881 16.3476 4.48881 16.042 4.79444L13.1525 7.68393L12.6958 8.13475C12.4142 7.86289 12.031 7.69565 11.6087 7.69565V6.52174C11.6087 4.57674 10.032 3 8.08696 3ZM13.1739 9.86209V13.1739C13.1739 14.0384 12.4731 14.7391 11.6087 14.7391H8.22796L6.89547 16.1308L9.42258 18.6579L16.2193 11.8611L13.7005 9.34232L13.1739 9.86209ZM10.0435 6.52174V7.69565H6.13043V6.52174C6.13043 5.44118 7.0064 4.56522 8.08696 4.56522C9.16751 4.56522 10.0435 5.44118 10.0435 6.52174ZM17.3261 10.7544L14.8109 8.23913L16.5954 6.4546L19.1106 8.96983L17.3261 10.7544ZM6.39858 17.8474L7.7178 19.1666L6.23368 19.3315L6.39858 17.8474Z"
          fill="#212121"
        />
      </svg>
    </Icon>
  );
}

export default IconPersonalizationLocked;
